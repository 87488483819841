<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Установить статус</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <form>

            <div class="form-group">
              <label for="scheduleVariantStatusId">Статус варианта расписания:</label><ReqField />
              <schedule-variant-statuses-select
                  id="scheduleVariantStatusId"
                  v-model="scheduleVariantStatusId"
                  class="form-control"
                  ref="scheduleVariantStatusId"
                  required
                  error="Выберите статус варианта расписания"
              />
            </div>

          </form>

        </modal-body>
        <modal-footer>
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import ScheduleVariantStatusesSelect from "@/components/rmt/schedule_variant_statuses/ScheduleVariantStatusesSelect";

export default {
  components: {
    ScheduleVariantStatusesSelect,
    ReqField
  },
  props: ['item'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
      return {
        scheduleVariantId: this.item.schedule_variant_id,
        scheduleVariantStatusId: this.item.schedule_variant_status_id,
      };
  },
  methods: {
        onSubmit() {
          if (this.validateForm(this.$refs)) {
            this.$emit('onOK', {
              schedule_variant_id: this.scheduleVariantId,
              schedule_variant_status_id: this.scheduleVariantStatusId,
            })
          }
        },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>