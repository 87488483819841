import {DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ КОНТРОЛИРУЕМЫХ ТИПОВ ИНТЕРВАЛЬНЫХ РЕЙСОВ

export default {
    actions: {
        // запрос объекта
        doFetchScheduleGraphStepTripTypes: (context, payload) => {
            return DoGet(
                '/rmt/scheduleGraphStepTripTypes?schedule_graph_step_id='+payload.schedule_graph_step_id,
                null,
                [],
                'Запрос типов интервальных рейсов',
                false,
                true,
            );
        },

        // запрос списка объектов
        doFetchScheduleGraphStepsTripTypes: (context, payload) => {
            return DoPost(
                '/rmt/scheduleGraphStepTripTypes',
                payload,
                null,
                'Запрос типов интервальных рейсов',
                false
            );
        },

        // изменение объекта
        doEditScheduleGraphStepTripTypes: (context, payload) => {
            return DoPut(
                '/rmt/scheduleGraphStepTripTypes/'+payload.schedule_graph_step_id,
                payload.tripTypes,
                null,
                'Установка типов интервальных рейсов'
            )
        },

    }
}
