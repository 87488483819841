import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ОРГАНИЗАЦИЙ

export default {
    state() {
        return {
            // список объектов
            organizations: [],
            // время обновления кэша new Date().getTime()
            organizationsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            organizationsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getOrganizations(state) {
            return state.organizations;
        },
    },
    mutations: {
        // установить новый список объектов
        setOrganizations(state, payload) {
            state.organizations = payload;
            state.organizationsCache = new Date().getTime()
        },
        // добавить объект
        addOrganization(state, payload) {
            state.organizations.push(payload)
        },
        // изменить объект
        editOrganization(state, payload) {
            const index = state.organizations.findIndex(org => org.org_id == payload.org_id);
            if (index >= 0) {
                state.organizations[index] = payload;
            }
        },
        // удалить объект
        delOrganization(state, payload) {
            const index = state.organizations.findIndex(org => org.org_id == payload.org_id);
            state.organizations.splice(index, 1);
        }
    },
    actions: {
        // запрос объекта
        doFetchOrganization: (context, payload) => {
            return DoGet(
                '/ref/organizations/' + payload.org_id,
                null,
                {},
                'Запрос организации',
                false,
                true
            )
        },

        // запрос списка объектов
        doFetchOrganizations: (context, payload) => {
            return DoGet(
                '/ref/organizations',
                'ref/setOrganizations',
                [],
                'Запрос организаций',
                false,
                payload && payload.force,
                context.state.organizationsCache,
                context.state.organizationsCacheTimeout
            )
        },

        // очищает кэш
        doClearOrganizations: (context, payload) => {

            return DoClearCache(
                'ref/setOrganizations',
                [],
                payload && payload.force,
                context.state.organizationsCache,
                context.state.organizationsCacheTimeout
            )
        },

        // добавление объекта
        doAddOrganization: (context, payload) => {
            return DoPost(
                '/ref/organizations',
                payload,
                'ref/addOrganization',
                'Добавление организации'
            )
        },

        // изменение объекта
        doEditOrganization: (context, payload) => {
            return DoPut(
                '/ref/organizations/'+payload.org_id,
                payload,
                'ref/editOrganization',
                'Изменение организации'
            )
        },

        // удаление объекта
        doDelOrganization: (context, payload) => {
            return DoDelete(
                '/ref/organizations/'+payload.org_id,
                'ref/delOrganization',
                'Удаление организации'
            )
        }
    }
}