<template>
  <div
      style="width: 1610px;"
      @click.right.prevent=" onContextMenu($event)"
      @contextmenu.prevent
  >
    <div>
      <ruler-s-v-g
          :hoursArr="hoursArray"
          :tsCountArr="tsCountArr"
          :oneDiv="oneDiv"
          :start_x="start_x"
          :start_y="start_y"
          :width="width"
          :height="height"
          @updateRulerWidth="updateRulerWidth"
          style=" margin-left: 17px; margin-bottom: 3px; margin-right: 17px;"
      >
      </ruler-s-v-g>
    </div>
    <div>
      <align-container
          :style="'overflow-y: auto; margin-left: 17px; margin-top:60px; overflow-x: hidden;' + ' width: ' + ( width ) +'px' "
      >
        <align-panel align="all">
          <schedule-graph-s-v-g
              v-for="(graph, index) in graphsArr"
              :key="index"
              :graph="graph"
              :height="height"
              :width="width"
              :start_x="start_x"
              :oneDiv="oneDiv"
              :isTuprShow="isTuprShow"
          >
          </schedule-graph-s-v-g>
        </align-panel>
      </align-container>
    </div>
  </div>
</template>

<script>
import RulerSVG from "@/components/ui/svg/schedule_graph/RulerSVG";
import ScheduleGraphSVG from "@/components/ui/svg/schedule_graph/ScheduleGraphSVG";
import {getTimeFormat} from "@/lib";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";

export default {
  name: "GraphPanel",
  components: {AlignPanel, AlignContainer, ScheduleGraphSVG, RulerSVG},
  props: ['schedule_graph_set_id', 'schedulesArr', 'hoursArray'],
  data() {
    return {
      // массив всех графиков для отрисовки
      graphsArr: [],
      // массив часов для верхней шкалы
      //hoursArr: [],
      //  массив количества ТС в каждый час
      tsCountArr: [],
      // ширина графика
      width: 1576,
      // высота графика
      height: 60,
      // отступ слева начала линейки часов и он же задает начальную точку отсчета линии графика
      start_x: 50,
      // высота линии графика; от нее в компонентах также может отсчитываться местоположение подписей к графику
      start_y: 32,
      // сколько пикселей занимает одно деление линейки вверху графика (соотв. 6 минут)
      //oneDiv: 3,
      // отображение Тупр.
      isTuprShow: false,
      // массив рейсов расписания
      //TripsArr: [],
    }
  },

  computed: {
    oneDiv() {
      return this.width / (12 * this.hoursArray.length)
    },
  },

  methods: {

    updateRulerWidth(newValue) {
      this.width = newValue;
    },

    // открываем контекстное меню
    onContextMenu(e) {
      let contextMenuItems = [];
      if (this.isTuprShow) {
        contextMenuItems.push(
            {
              label: "Скрыть Tупр.",
              onClick: () => {
                this.isTuprShow = false;
              }
            }
        )
      } else {
        contextMenuItems.push(
            {
              label: "Отобразить Tупр.",
              onClick: () => {
                this.isTuprShow = true;
              }
            }
        )
      }
      // передаем в перент строку для которой открыли контекстное меню
      this.$contextmenu({
        customClass: "class-a",
        zIndex: 1051,
        x: e.x,
        y: e.y,
        items: contextMenuItems,
      });
    },

  },

  async created() {
    // получаем графики
    /*let graphs = await this.$store.dispatch('rmtgen/doFetchGraphs');
    if (graphs)
      this.graphsArr = graphs;*/
    let graphs = [];
    let graphLine = [];
    let graph = 0;
    let duration = 0;
    let nextElemantBegin = 0;
    let lastScheduleEventId = 0;
    let elemantEnd = 0;
    let typeElemant = "";
    let stayTime = 0;
    let nullTrip = "";
    let change = false;
    let workTimeFirstShift = 0;
    let workTimeSecondShift = 0;
    //let TripsArr = await this.$store.dispatch('rmtgen/doFetchScheduleGraphSetTable', {
    /*let TripsArr = await this.$store.dispatch('rmtgen/doFetchScheduleGraphSetGraphic', {
      schedule_graph_set_id: this.schedule_graph_set_id,
    })*/
    //if (TripsArr) {
    if (this.schedulesArr) {
      //this.TripsArr = TripsArr;
      //for (let tripsRow of TripsArr) {
      for (let tripsRow of this.schedulesArr) {
        let columnArray = tripsRow.trip_table_columns;
        for (let colItem of columnArray) {
          if (colItem.trip_complex.graph != graph) {
            if (graph > 0) {
              if (workTimeSecondShift > 0) {
                typeElemant = '(' + getTimeFormat(workTimeFirstShift * 60) + ', ' + getTimeFormat(workTimeSecondShift * 60) + ')';
                workTimeFirstShift = workTimeFirstShift + workTimeSecondShift;
              } else {
                typeElemant = '';
              }
              graphs.push({
                graphLine: graphLine,
                number: graph,
                stayTime: stayTime,
                workTime: workTimeFirstShift,
                nullTrip: nullTrip,
                textAfter: typeElemant,
              });
              stayTime = 0;
              nullTrip = "";
              change = false;
              workTimeFirstShift = 0;
              workTimeSecondShift = 0;
            }
            graphLine = [];
            nextElemantBegin = colItem.trip_complex.step_time_begin;
            duration = (colItem.trip_complex.step_time_begin / 60) - (4 * 60);
            graphLine.push({
              type: 'before',
              length: duration,
            });
            lastScheduleEventId = colItem.trip_complex.schedule_event_id;
          }
          if (colItem.trip_complex.schedule_event_id != lastScheduleEventId) {
            if (lastScheduleEventId == 1) {
              typeElemant = "line";
            } else if (lastScheduleEventId == 4) {
              typeElemant = "planstay";
            } else if (lastScheduleEventId == 5) {
              typeElemant = "dinner";
            } else if (lastScheduleEventId == 6) {
              typeElemant = "maintenance";
            } else if (lastScheduleEventId == 7) {
              typeElemant = "special";
            } else if (lastScheduleEventId == 8) {
              typeElemant = "refuel";
            } else if (lastScheduleEventId == 9) {
              typeElemant = "switching";
            } else if (lastScheduleEventId == 10) {
              typeElemant = "change";
            } else if (lastScheduleEventId == 11) {
              typeElemant = "res";
            } else if (lastScheduleEventId == 12) {
              typeElemant = "stay";
            } else if (lastScheduleEventId == 13) {
              typeElemant = "inttrips";
            } else {
              typeElemant = "";
            }
            if (colItem.trip_complex.schedule_event_id == 0) {
              if (colItem.trip_complex.null_trip_type_id > 0) {
                nullTrip = ">";
              }
            } else {
              nullTrip = "";
            }
            if ((change) && (lastScheduleEventId != 10)) {
              typeElemant = typeElemant + "-gray";
            }
            if ((typeElemant != "") || (lastScheduleEventId == 0)) {
              elemantEnd = (colItem.trip_complex.null_step_time_end > 0 ? colItem.trip_complex.null_step_time_end : colItem.trip_complex.step_time_begin);
              duration = (elemantEnd / 60) - (nextElemantBegin / 60);
              if ((lastScheduleEventId != 1) && (lastScheduleEventId != 2) && (lastScheduleEventId != 3)) {
                if (lastScheduleEventId != 10) {
                  stayTime = stayTime + duration;
                } else {
                  workTimeSecondShift = workTimeSecondShift + duration;
                }
              } else {
                if (change) {
                  workTimeSecondShift = workTimeSecondShift + duration;
                } else {
                  workTimeFirstShift = workTimeFirstShift + duration;
                }
              }
              nextElemantBegin = (colItem.trip_complex.null_step_time_end > 0 ? colItem.trip_complex.null_step_time_end : colItem.trip_complex.step_time_begin);
              graphLine.push({
                type: typeElemant,
                length: duration,
              });
              lastScheduleEventId = colItem.trip_complex.schedule_event_id;
            }
            if (colItem.trip_complex.schedule_event_id == 10) {
              change = true
            }
          }
          graph = colItem.trip_complex.graph;
        }
      }
      if (graph > 0) {
        if (workTimeSecondShift > 0) {
          typeElemant = '(' + getTimeFormat(workTimeFirstShift * 60) + ', ' + getTimeFormat(workTimeSecondShift * 60) + ')';
          workTimeFirstShift = workTimeFirstShift + workTimeSecondShift;
        } else {
          typeElemant = '';
        }
        graphs.push({
          graphLine: graphLine,
          number: graph,
          stayTime: stayTime,
          workTime: workTimeFirstShift,
          nullTrip: nullTrip,
          textAfter: typeElemant,
        });
      }
    }
    if (graphs)
      this.graphsArr = graphs;

    // получаем массив часов для верхней шкалы
    /*let hours = await this.$store.dispatch('rmtgen/doFetchHours');
    if (hours)
      this.hoursArr = hours;*/

    let tsArr = await this.$store.dispatch('rmtgen/doFetchTsCountArr');
    if (tsArr)
      this.tsCountArr = tsArr;
  }

}
</script>

<style scoped>

</style>