import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ЭКОЛОГИЧЕСКИХ КЛАССОВ

export default {
    state() {
        return {
            // список объектов
            emissionStandards: [],
            // время обновления кэша new Date().getTime()
            emissionStandardsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            emissionStandardsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getEmissionStandards(state) {
            return state.engineFuelTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setEmissionStandards(state, payload) {
            state.engineFuelTypes = payload;
            state.emissionStandardsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchEmissionStandards: (context, payload) => {
            return DoGet(
                '/ref/emissionStandards',
                'ref/setEmissionStandards',
                [],
                'Запрос экологических классов',
                false,
                payload && payload.force,
                context.state.emissionStandardsCache,
                context.state.emissionStandardsCacheTimeout
            )
        },

        // очищает кэш
        doClearEmissionStandards: (context, payload) => {

            return DoClearCache(
                'ref/setEmissionStandards',
                [],
                payload && payload.force,
                context.state.emissionStandardsCache,
                context.state.emissionStandardsCacheTimeout
            )
        },
    }
}