<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/stops">Остановочные пункты</router-link></span>
        <span> / </span>
        <span>Документы остановочного пункта {{ stopPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Связь
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="stopDocFilesLink">Прикрепленные файлы</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <docs-edit-table
              :filterText="filterText"
              :selectedRow="selectedRow"
              :rowList="rowList"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></docs-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <doc-dialog
      table_name='tb_stop'
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </doc-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить документ с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import DocsEditTable from "@/components/doc/documents/DocsEditTable";
import DocDialog from "@/components/doc/documents/DocDialog";
import {getDocType} from "@/store/DocHelpers";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    DocDialog,
    DocsEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id",
    // идентификатор остановки
    "stop_id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // остановка
      stop: {},
      // документы
      docs: [],
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {doc: {}}
      } else return this.selectedRow;
    },

    // получаем строки таблицы в формате общего документа
    rowList() {
      if (this.isLoading === 'fetch') return [];

      return this.docs.map(doc => {
        const docType = getDocType(doc.doc.doc_type_id);

        doc.__id = doc.stop_doc_id;
        doc.doc.__docTypeName = docType.doc_type_name;
        return doc;
      });
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    //путь к файлам документа
    stopDocFilesLink() {
      if (this.selectedRow) return '/multipass/stops/' + this.stop_id + '/stopDocs/' + this.selectedRow.stop_doc_id + '/stopDocFiles';
      else return '';
    },

  },

  methods: {
    // нажата кнопка "Добавить"
    onTableAdd() {
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopDocs/add");
    },

    // "нажата кнопка Изменить"
    onTableEdit() {
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopDocs/edit/" + this.selectedRow.stop_doc_id);
    },

    // нажата кнопка "Удалить"
    onTableDelete() {
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopDocs/del/" + this.selectedRow.stop_doc_id);
    },

    // нажата конпка "Просмотр"
    onTableShow() {
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopDocs/show/" + this.selectedRow.stop_doc_id);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      this.refreshData();
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      }
      else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopDocs");
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopDocs");
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopDocs");
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // запрос остановки
        const stop = await this.$store.dispatch('multipass/doFetchStop', {
          stop_id: this.stop_id,
        }); if (!stop) return;
        this.stop = stop;

        // типы документов
        await this.$store.dispatch('doc/doFetchDocumentTypes');

        // документы
        const docs = await this.$store.dispatch('multipass/doFetchStopDocs', {
          stop_id: this.stop_id
        }); if (!docs) return;
        this.docs = docs;

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        // добавляем поля
        item.stop_doc_id = null;
        item.stop_id = Number(this.stop_id);
        // запрос на сервер
        const doc = await this.$store.dispatch('multipass/doAddStopDoc', item);
        // мутируем массив
        this.selectedRow = addMutation(this.docs, 'stop_doc_id', doc);

      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        // добавляем поля
        item.stop_doc_id = this.selectedRow.stop_doc_id;
        item.stop_id = this.selectedRow.stop_id;
        // запрос на сервер
        const doc = await this.$store.dispatch('multipass/doEditStopDoc', item);
        // мутируем массив
        this.selectedRow = editMutation(this.docs, 'stop_doc_id', doc);

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрос на сервер
        const doc = await this.$store.dispatch('multipass/doDelStopDoc', this.selectedRow)
        // мутируем массив
        this.selectedRow = delMutation(this.docs, 'stop_doc_id', doc);

      }
      finally {
        this.isLoading = false;
      }
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
