<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" :disableSettings="true" name="tbl-asmpp-routes"></edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading', 'filterText', 'chosenRows', 'routes', 'columnList'],
  emits: ['selection'],
  data() {
    return {

    };
  },

  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      // получаем все маршруты
      let routes = this.routes;
      routes.map(route => {
        // если маршруты только получены или взведен тригер снятия всех галочек
        if ((route.__isSelected == null && route.__isSelected == undefined) || this.chosenRows == 'clearAll') {
          // снимаем все галочки слева вкл
          route.__isSelected = false;
          // снимаем все галочки слева зоны
          route.__isZones = false;
          route.__id = route.route_id;
          route.__cellStyle = {
            paddingTop: '0',
            paddingBottom: '0',
            paddingLeft: '6px',
            paddingRight: '6px'
          };
        }
      });


      // в случае, если нажата кнопка "выбрать все строки на экране" т.е. отфильтрованные строки
      // сетаем отфильтрованные маршруты выбранными
      if (this.chosenRows == 'selectFiltered') {
        routes.map(value => {
          value.__isSelected = true;
        })
      }
      // сбрасываем режим для галочек (поле __isSelected)
      this.$emit('selection', routes)

      return routes;
    },

  },
};
</script>
