import {DoPost} from "@/store/Actions";
import store from "@/store/Store";


// ХРАНИЛИЩЕ ОТЧЕТОВ МОДУЛЯ KVR-EXPERT

export default {
    actions: {

        // запрос отчета 0032
        doMakeReport0032: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0032',
                payload,
                null,
                'Сформировать отчет об осуществлении регулярных перевозок по регулируемым тарифам (кэ-10).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0033
        doMakeReport0033: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0033',
                payload,
                null,
                'Сформировать отчет о фактической транспортной работе (кэ-11).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0034
        doMakeReport0034: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0034',
                payload,
                null,
                'Сформировать отчет о фактической транспортной работе (кэ-11и).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0035
        doMakeReport0035: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0035',
                payload,
                null,
                'Сформировать итоговый отчет о выполнении плана перевозок (кэ-12).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0036
        doMakeReport0036: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0036',
                payload,
                null,
                'Сформировать итоговый отчет о работе маршрутов (кэ-13).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0037
        doMakeReport0037: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0037',
                payload,
                null,
                'Сформировать итоговый отчет о работе маршрутов (кэ-14).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0038
        doMakeReport0038: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0038',
                payload,
                null,
                'Сформировать итоговый отчет о работе предприятий на маршрутах (кэ-15).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0039
        doMakeReport0039: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0039',
                payload,
                null,
                'Сформировать итоговый отчет о выполнении работы на маршрутах (кэ-16).',
                false,
                true,
            );
            console.log(response)
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0040
        doMakeReport0040: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0040',
                payload,
                null,
                'Сформировать итоговый отчет о выполнении работы предприятиями на маршрутах (кэ-17).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0041
        doMakeReport0041: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0041',
                payload,
                null,
                'Сформировать отчет об объеме выполненной транспортной работы (кэ-19).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0042
        doMakeReport0042: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0042',
                payload,
                null,
                'Сформировать отчет по выполнению плана перевозок. Сводная по предприятиям (кэ-21).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0043
        doMakeReport0043: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr-expert/reports/makeReport0043',
                payload,
                null,
                'Сформировать оперативную справку выполнения плана перевозок. Сводная по предприятиям (кэ-22).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

    }
}
