<template>
  <div class="collapse navbar-collapse" :class="{show: isActive}">
    <slot></slot>
  </div>
</template>

<script>
import ActiveMixin from "@/components/ui/bootstrap/mixins/ActiveMixin";

export default {
  mixins: [ActiveMixin]
}
</script>