
// ХРАНИЛИЩЕ ФАКТИЧЕСКИХ ОТМЕТОК КВР


import {DoGet} from "@/store/Actions";

export default {

    actions: {

        // запрос списка заданий
        getWorkTaskFactTrips: (context, payload) => {
            return DoGet(
                '/kvr/getWorkTaskFactTrips/' + payload.date_index +'/' + payload.work_task_id,
                null,
                [],
                'Запрос списка фактических рейсов при выполнении задания',
                false,
            )
        }

    }
}