<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">

      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span><router-link to="/asmpp/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span>Маршрутный пассажиропоток {{ routesWithDatePageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">

          <crud-form
              :buttonsExist="{ refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка перехода к Рейсовому пассажиропотоку по часам суток  -->
            <!--            Аналитические формы ({{ getSelectedRoutes.length }} {{ getRouteTitle }} из {{ routes.length }})-->
            <template #leftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary">
                  Аналитические формы
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="passengerTrafficFactTripByHoursLink">Рейсовый пассажиропоток по часам
                    суток
                  </dropdown-item>
                  <dropdown-item router-link :to="getFactStopsAggregatedLink"
                                 :disabled="factStopsAggregatedLinkDisabled">Остановочный пассажиропоток по трассе
                  </dropdown-item>
                  <dropdown-item router-link :to="factStopsLink" :disabled="factStopsLinkDisabled">
                    {{ factStopsTitle }}
                  </dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <template #leftCustomButton2>
              <input-select class="form-control" ref="select" @change="getFilteredRouteVariants" v-model="filterRoute"
                            caption="Выберите маршрут..." :itemList="getFilteredRoutes"></input-select>
            </template>

            <template #leftCustomButton3 v-if="filterRouteVariants.length > 1">
              <input-select class="form-control" ref="select" @change="getFilteredTripTypes"
                            v-model="filterRouteVariant" caption="Выберите период..."
                            :itemList="filterRouteVariants"></input-select>
            </template>

            <template #leftCustomButton4>
              <input-select class="form-control" ref="select" v-model="filterTrip" @change="changeTripType"
                            caption="Выберите трассу..." :itemList="filterTripTypes"></input-select>
            </template>

          </crud-form>

        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <fact-trip-exts-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :rowArr="rowList"
              :routes="routes"
              :filterRoute="filterRoute"
              :filterRouteVariant="filterRouteVariant"
              :filterTrip="filterTrip"
              @onRowSelect="onRowSelect"
              @setLoading="setLoading"
          ></fact-trip-exts-edit-table>
        </align-panel>
        <align-panel align="bottom">
          <!-- Форма с кнопками под правой таблицей-->
          <card>
            <card-body class="p-1">
              <table class="table table-sm" style="margin-bottom:0">
                <thead class="thead-dark">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Кол. пасс.</th>
                  <th scope="col">Пасс.*Км</th>
                  <th scope="col">Сумма оплат</th>
                  <th scope="col">Кол оплат</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in getBottomPanelItems" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.passenger_count }}</td>
                  <td>{{ item.passenger_traffic }}</td>
                  <td>{{ item.pay_sum }}</td>
                  <td>{{ item.pay_tran_count }}</td>
                </tr>
                </tbody>
              </table>
            </card-body>
          </card>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>


  <!-- Диалог со списком ошибок -->
  <errors-with-status-dialog
      v-if="isErrorsDialogVisible"
      :rowList="routeVariantsErrorArr"
      @onClose="routeVariantsErrorArr=[]"
      @onContinue="routeVariantsErrorArr=[]"
      caption="Варианты маршрута"
  >
  </errors-with-status-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>


import PageMixin from "@/pages/share/PageMixin";

import {getSignTitle} from "@/lib";
import FactTripExtsEditTable from "@/components/asmpp/fact_trip_exts/FactTripExtsEditTable";

export default {
  components: {FactTripExtsEditTable},
  mixins: [PageMixin],

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрутов
    "routes_id",
    // дата начала периода
    "date_index_begin",
    // дата конца периода
    "date_index_end",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // маршруты
      routes: [],
      // тип рейса
      tripType: null,
      // список ошибок вариантов маршрута
      routeVariantsErrorArr: [],
      // список рейсов
      rows: [],
      // список RouteVariants
      routeVariantArr: [],
      // список трасс
      tripTypes: [],
      // периоды для подстановки в комбобокс
      filterRouteVariants: [],
      // трассы для подстановки в комбобокс
      filterTripTypes: [],
      // выбранный в фильтре маршрут
      filterRoute: null,
      // выбранный в фильтре период
      filterRouteVariant: null,
      // выбранная в фильтре трасса
      filterTrip: null,
    }
  },

  computed: {

    // расчет параметров для таблицы "Итого" снизу
    getBottomPanelItems(){
      let items = [];
      let passenger_count = 0;
      let passenger_traffic = 0;
      let pay_sum = 0;
      let pay_tran_count = 0;

      for(let i = 0; i < this.rows.length; i++) {
        passenger_count = passenger_count + this.rows[i].passenger_count;
        passenger_traffic = passenger_traffic + this.rows[i].passenger_traffic;
        pay_sum = pay_sum + this.rows[i].pay_sum;
        pay_tran_count = pay_tran_count +  this.rows[i].pay_tran_count
        ;
      }
      items.push({
        name: "Суммарно",
        passenger_count: passenger_count,
        passenger_traffic: Number(passenger_traffic).toFixed(1),
        pay_sum: pay_sum,
        pay_tran_count: pay_tran_count,
      })
      return items;
    },

    // получение маршрутов для фильтрации
    getFilteredRoutes() {
      // формируем массив маршрутов в комбобокс
      let filterRoutes = []
      this.routes.map(value => {
        filterRoutes.push({
          id: value.route_id,
          title: value.route_num,
        });
      })
      return filterRoutes;
    },

    // видимость диалога с ошибками
    isErrorsDialogVisible() {
      return this.routeVariantsErrorArr.length != 0;
    },

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // условие отключения кнопки перехода к рейсовому пассажиропотоку
    factStopsAggregatedLinkDisabled() {
      return !this.filterTrip;
    },

    // условие отключения кнопки перехода к рейсовому пассажиропотоку
    factStopsLinkDisabled() {
      return !this.selectedRow;
    },

    // условие отключения кнопки перехода к рейсовому пассажиропотоку
    factStopsTitle() {
      return !this.selectedRow ? "Остановочный пассажиропоток (выберите рейс)" : "Остановочный пассажиропоток";
    },

    rowList() {

      let rows = this.rows;
      // фильтруем по выбранному маршруту
      if (this.filterRoute) {
        rows = rows.filter((row) => {
          return row.route_id == this.filterRoute;
        })
      }

      // фильтруем по выбранному варианту маршруту
      if (this.filterRouteVariant) {
        rows = rows.filter((row) => {
          return row.route_variant_id == this.filterRouteVariant;
        })
      }

      // фильтруем по выбранной трассе
      if (this.filterTrip) {
        rows = rows.filter((row) => {
          return row.trip_type_id == this.filterTrip;
        })
      }
      return rows;
    },

    passengerTrafficFactTripByHoursLink() {
      let routeForm = '';
      this.routes.map(rt => {
        routeForm = routeForm + '/' + rt.route_id;
      })

      let factTrips = '';
      this.rowList.map(row => {
        factTrips = factTrips + '/' + row.fact_trip_ext_id;
      })

      return '/asmpp/routes/' + this.date_index_begin + '/' + this.date_index_end + routeForm + '/factTripExts' + factTrips + '/passengerTrafficFactTripByHours';
    },

    factStopsLink() {
      if (!this.factStopsLinkDisabled) {
        let routeForm = '';
        this.routes_id.map(rt => {
          routeForm = routeForm + '/' + rt;
        })

        return '/asmpp/routes/' + this.date_index_begin + '/' + this.date_index_end + routeForm + '/factTripExts/' + this.selectedRow.fact_trip_ext_id + '/factTripCardExts';
      } else {
        return '';
      }
    },

    getFactStopsAggregatedLink() {
      if (!this.factStopsAggregatedLinkDisabled) {
        let routeForm = '';
        this.routes_id.map(rt => {
          routeForm = routeForm + '/' + rt;
        })

        let factTrips = '';
        this.rowList.map(row => {
          factTrips = factTrips + '/' + row.fact_trip_ext_id;
        })

        return '/asmpp/routes/' + this.date_index_begin + '/' + this.date_index_end + routeForm + '/factTripExts' + factTrips + '/factTripCardExtsAggregated';
      } else {
        return '';
      }
    },

  },

  methods: {

    // получение вариантов маршрутов для фильтрации
    getFilteredTripTypes() {
      this.selectedRow = null;
      // формируем массив маршрутов в комбобокс
      if (this.filterRouteVariant != null) {
        let filterTripTypes = []
        this.tripTypes.map(value => {
          if (value.route_variant_id == this.filterRouteVariant) {
            filterTripTypes.push({
              id: value.trip_type_id,
              title: getSignTitle(value.trip_type_sign),
            });
          }
        })
        this.filterTripTypes = filterTripTypes;
        if (this.filterTripTypes.length == 1) {
          this.filterTrip = this.filterTripTypes[0].id;
        }
      }
    },

    // получение вариантов маршрутов для фильтрации
    getFilteredRouteVariants() {
      this.filterRouteVariant = null;
      this.filterTrip = null;
      this.selectedRow = null;
      this.filterRouteVariants = [];
      this.filterTripTypes = [];
      // формируем массив вариантов маршрутов в комбобокс
      if (this.filterRoute != null) {
        let filterRouteVariants = []
        this.routeVariantArr.map(value => {
          if (value.route_id == this.filterRoute) {
            filterRouteVariants.push({
              id: value.route_variant_id,
              title: value.date_begin + ' - ' + (value.date_end == null ? 'действ.' : value.date_end),
            });
          }
        })
        this.filterRouteVariants = filterRouteVariants;
        if (this.filterRouteVariants.length == 1) {
          this.filterRouteVariant = this.filterRouteVariants[0].id;
          this.getFilteredTripTypes();
        }
      }
    },

    setLoading(item) {
      this.isLoading = item;
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    changeTripType() {

      // сохраняем в сторик выбранную трассу для фильтрации
      this.$store.commit('asmpp/setFilterTrip', this.filterTrip);

      if (this.selectedRow && this.selectedRow.trip_type_id != this.filterTrip) {
        this.selectedRow = null;
      }
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {
        this.routes = [];
        let rows = [];
        this.routeVariantArr = [];
        let mvByM = {};
        for (let i = 0; i < this.routes_id.length; i++) {
          // добавляем в объект пустой массив с именем поля route_id
          mvByM[this.routes_id[i]] = [];
          // загружаем пассажиропоток по маршруту
          let routeRows = await this.$store.dispatch('asmpp/doFetchFactTripExts', {
            route_id: this.routes_id[i],
            date_index_begin: this.date_index_begin,
            date_index_end: this.date_index_end,
          });
          if (routeRows.length != 0) {
            rows.push(...routeRows)
            // запрос маршрута
            const route = await this.$store.dispatch('multipass/doFetchRoute', {
              route_id: this.routes_id[i],
            });
            if (!route) return;
            this.routes.push(route);
          }
        }

        //получили все строки, заполняем (цикл по рейсам)
        for (let i = 0; i < rows.length; i++) {
          let tripType = this.tripTypes.find(value => value.trip_type_id == rows[i].trip_type_id);
          if (!tripType) {
            tripType = await this.$store.dispatch('multipass/doFetchTripType', {
              trip_type_id: rows[i].trip_type_id,
            });
            this.tripTypes.push(tripType)
          }

          rows[i].route_variant_id = tripType.route_variant_id;
          rows[i].__tripType = tripType.trip_type_sign;

          //зполняем варианты маршрута
          let routeVariant = this.routeVariantArr.find(value => value.route_variant_id == tripType.route_variant_id);
          if (!routeVariant) {
            routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
              route_variant_id: tripType.route_variant_id,
            });
            this.routeVariantArr.push(routeVariant)
            mvByM[routeVariant.route_id].push(routeVariant);
          }
          rows[i].__routeVariant = routeVariant.date_begin + ' - ' + (routeVariant.date_end == null ? 'действ.' : routeVariant.date_end);
          rows[i].__route = this.routes.find(route => route.route_id == routeVariant.route_id).route_num;
          rows[i].route_id = routeVariant.route_id;
        }

        this.routeVariantsErrorArr = [];
        for (let i = 0; i < this.routes_id.length; i++) {
          if (mvByM[this.routes_id[i]].length >= 2) {
            let error_message = "Маршрут: " + this.routes.find(route => route.route_id == this.routes_id[i]).route_num + ". Период: ";
            for (let z = 0; z < mvByM[this.routes_id[i]].length; z++) {
              error_message = error_message + mvByM[this.routes_id[i]][z].date_begin + ' - ' + (mvByM[this.routes_id[i]][z].date_end == null ? 'действ.' : mvByM[this.routes_id[i]][z].date_end);

              if (z == mvByM[this.routes_id[i]].length - 1) {
                error_message = error_message + ';'
              } else {
                error_message = error_message + ' <==> '
              }

            }
            this.routeVariantsErrorArr.push({
              error_status: 'ALARM',
              error_message: error_message,
            })
          }
        }

        // в случае, если пришли данные по одному маршруту, делаем его выбранным
        if (this.routes.length == 1) {
          this.filterRoute = this.routes[0].route_id;
          this.getFilteredRouteVariants();
        }

        this.rows = rows;

        await this.$store.dispatch('ref/doFetchDepots');
        await this.$store.dispatch('ref/doFetchTransport');
        await this.$store.dispatch('ref/doFetchTransportClasses');

        let filterTrip = this.$store.getters['asmpp/getFilterTrip'];
        if (filterTrip) {
          this.filterTrip = filterTrip;
        }

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
