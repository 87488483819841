import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ТРАНСПОРТНЫХ ПРЕДПРИЯТИЙ ДЛЯ МОДУЛЯ КВР

export default {
    state() {
        return {
            // список объектов
            depots: [],
            // время обновления кэша new Date().getTime()
            depotsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            depotsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getDepots(state) {
            return state.depots;
        },
    },
    mutations: {
        // установить новый список объектов
        setDepots(state, payload) {
            state.depots = payload;
            state.depotsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchDepots: (context, payload) => {
            return DoGet(
                '/kvr/getDepots',
                'kvr/setDepots',
                [],
                'Запрос транспортных предприятий для модуля КВР',
                false,
                payload && payload.force,
                context.state.depotsCache,
                context.state.depotsCacheTimeout
            )
        },

        // запрос списка объектов
        doFetchDepotsByOrg: (context, payload) => {
            return DoGet(
                '/kvr/getDepots?org_id=' + payload.org_id,
                'kvr/setDepots',
                [],
                'Запрос транспортных предприятий для модуля КВР',
                false,
                payload && payload.force,
                context.state.depotsCache,
                context.state.depotsCacheTimeout
            )
        },

        // очищает кэш
        doClearDepots: (context, payload) => {
            return DoClearCache(
                'kvr/setDepots',
                [],
                payload && payload.force,
                context.state.depotsCache,
                context.state.depotsCacheTimeout
            )
        },
    }
}