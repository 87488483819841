<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Отметки на рейсе</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>

        <div class="form-group" style="margin-top: 5px; margin-bottom: 5px; margin-left: 5px; display: flex; ">
          <button
              type="button"
              class="btn btn-secondary btn-sm"
              @click="onRefresh"
              title="Обновить"
              style="height: 31px"
          >
            <i class="fas fa-sync-alt" style="right: 7px"></i>
          </button>

          <dropdown v-if="isDropdownActive" style="height: 31px; margin-left: 5px">
            <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary"
                            style="height: 31px; padding-top: 2px" :disabled="!selectedRow"
            >
              Связь
            </button-toggler>
            <dropdown-menu ref="collapse2">
              <dropdown-item @click="openReports">Отчеты по остановке</dropdown-item>
            </dropdown-menu>
          </dropdown>
        </div>

        <modal-body>
          <form>
            <div class="table-container">
              <trip-card-items-edit-table
                  :selectedRow="selectedRow"
                  :rowList="rows.length == 0 ? [] : rows"
                  @onRowSelect="onRowSelect"
              ></trip-card-items-edit-table>
            </div>
          </form>
        </modal-body>

        <modal-footer>
          <button @click="$emit('onCancel')" class="btn btn-primary">Отмена</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import TripCardItemsEditTable from "@/components/kvr/trip_card_items/TripCardItemsEditTable";



export default {
  components: {TripCardItemsEditTable},
  props: ['rows'],
  emits: ['onRefresh', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      // выбранная строка
      selectedRow: null,
      activeOnly: false,

    };
  },

  computed: {

    scheduleVariantArr() {
      if (this.activeOnly == false){
        return this.scheduleVariants
      } else {
        return this.scheduleVariants.filter(schVar => {
          return schVar.is_working == true;
        })
      }
    },

    isDropdownActive() {
      if(this.$route.path.substring(0,14) == '/kvr/workTasks') {
        return true;
      }
      return false;
    }

  },

  methods: {

    openReports() {
      this.$store.dispatch('settings/doSetSelectedStation', this.selectedRow);
      this.$router.push('/kvr/reports/0045');
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    onRefresh() {
      this.$emit('onRefresh');
    }

  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}

.table-container {
  height: 500px;
}
</style>