
// ГЛАВНОЕ ХРАНИЛИЩЕ SETTINGS

import {DoGetRaw} from "@/store/Actions";
import store from "@/store/Store";

export default {
    namespaced: true,
    state() {
        return {
            baseUrl: '',
            //baseUrl: 'http://192.168.20.179:7001',  // Отладочный сервер (база 177)
            //baseUrl: 'http://192.168.20.201:7001',  // Москва - презентация
            //baseUrl: 'http://192.168.20.202:7001',  // Тверь - презентация
            //baseUrl: 'http://192.168.25.71:7001',
            //baseUrl: 'http://192.168.25.93:7001',   // Кирилл
            // начальный масштаб
            zoom: 15,
            // центр карты
            //center: [56.859847, 35.911995],
            center: [55.725937300000005, 37.65464410000001],
            // минимальный масштаб при котором отображаются остановки
            minStopZoom: 14,
            // минимальный масштаб при котором отображается граф
            minGraphZoom: 16,
            // стандартный масштаб для отображения одного транспортного средства
            defaultVehicleZoom: 16,
            // список базовых слоев
            baseLayers: [
                {
                    title: "OpenStreetMap",
                    urlTemplate: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    options: { attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'},
                    type: "",
                },
                {
                    title: "Яндекс.Карты",
                    options: {},
                    type: "yandex",
                },
                // {
                //     title: "Спутник.Карты",
                //     urlTemplate: "http://{s}.tiles.maps.sputnik.ru/{z}/{x}/{y}.png",
                //     options: { attribution: "&copy; <a href='http://corp.sputnik.ru/maps'>Спутник</a> contributors"},
                //     type: "",
                // },
            ],
            // Основной цвет производственного рейса (четные)
            productionReisColor : '#f0ffe1',
            // Дополнительный цвет производственного рейса (нечетные)
            productionReisColor2 : '#defdb5',
            // цвет технического рейса
            technicalReisColor : '#ffddc3',
            // цвет краткого события
            shortEventColor : '#fcfab6',
            // цвет длительного события
            longEventColor : '#f8f19b',
            // цвет события пробег с временем
            lengthWithTimeEventColor : '#dec3a9',
            // цвет события пробег с временем и количеством рейсов
            lengthWithTimeAndReisCountEventColor : '#d59542',
            // цвет начальной и конечной строки
            startEndStopColor: '#27a443',
            // цвет контрольного пункта
            сPColor: '#dcffba',
            //цвет строки суммы
            summRowColor : '#343a40',
            // серый цвет (например
            graySystemColor: '#cecece',

            // массив цветов для отображения зон тарифных зон
            zonesColors: [
                '#fd0000',
                '#ff00f2',
                '#0082b7',
                '#00ff33',
                '#0011d0',
                '#7a4800',
                '#fff200',
                '#155900',
                '#860000',
                '#00eaff',
                '#ff9100',
                '#9f39ff',
            ],

            // запоминаем выбранную остановку для перехода к отчету по остановке
            selectedStation: null,

        }
    },
    getters: {
        getBaseUrl(state) {
            return state.baseUrl;
        },
        getZoom(state) {
            return state.zoom;
        },
        getCenter(state) {
            return state.center;
        },
        getMinStopZoom(state) {
            return state.minStopZoom;
        },
        getMinGraphZoom(state) {
            return state.minGraphZoom;
        },
        getMinStopstationZoom(state) {
            return state.minStopZoom;
        },
        getMinStopPavilionZoom(state) {
            return state.minStopZoom;
        },
        getDefaultVehicleZoom(state) {
            return state.defaultVehicleZoom;
        },
        getBaseLayers(state) {
            return state.baseLayers;
        },
        getProductionReisColor(state){
            return state.productionReisColor
        },
        getProductionReisColor2(state){
            return state.productionReisColor2
        },
        getTechnicalReisColor(state){
            return state.technicalReisColor
        },
        getShortEventColor(state){
            return state.shortEventColor
        },
        getLongEventColor(state){
            return state.longEventColor
        },
        getLengthWithTimeEventColor(state){
            return state.lengthWithTimeEventColor
        },
        getLengthWithTimeAndReisCountEventColor(state){
            return state.lengthWithTimeAndReisCountEventColor
        },
        getStartEndStopColor(state){
            return state.startEndStopColor
        },
        getCPColor(state){
            return state.сPColor
        },
        getSummRowColor(state){
            return state.summRowColor
        },
        getZonesColors(state){
            return state.zonesColors
        },
        getGrayZones(state) {
            return state.graySystemColor
        },

        // возвращаем выбранную остановку
        getSelectedStop(state) {
            return state.selectedStation
        },

    },
    mutations: {
        setSettings(state, payload) {
            if (payload.baseUrl)
                state.baseUrl = payload.baseUrl;
            if (payload.zoom)
                state.zoom = payload.zoom;
            if (payload.center)
                state.center = payload.center;
            if (payload.minStopZoom)
                state.minStopZoom = payload.minStopZoom;
        },

        setSelectedStation(state, payload) {
            state.selectedStation = payload
        }
    },
    actions: {
        // запрос настроек из json-файла
        async doGetSettings({state}) {
            const result = await DoGetRaw(
                '/settings.json',
                'settings/setSettings',
                'Загрузка настроек',
                false
            );
            // должно быть подключение
            return result && state.baseUrl;
        },

        doSetSelectedStation(state, payload){
            store.commit('settings/setSelectedStation', payload);
        }
    }
}
