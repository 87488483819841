<template>


  <accordion mode="single">

    <ul class="p-0 m-0" style="list-style-type: none;">
      <li>
        <full-left-menu-item to="/admin/users">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Пользователи
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/admin/roles">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Роли
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/admin/userGroups">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Группы пользователей
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/multipass/routeGroups">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Группы маршрутов
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/ref/depotGroups">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Группы предприятий
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/ref/transportGroups">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Группы транспортных средств
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/ref/transportTypeGroups">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Группы видов транспорта
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/admin/methods">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Методы
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/admin/modules">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Модули
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/admin/accessCategories">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Категории доступа
            </div>
          </div>
        </full-left-menu-item>
      </li>
    </ul>

  </accordion>
</template>
