import {DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ ПРОКЛАДКИ МАРШРУТА

export default {
    actions: {
        // прокладка маршрута
        doRouting: (context, payload) => {
            return DoPost(
                '/gis/routing',
                payload,
                null,
                'Прокладка маршрута',
                false
            )
        }
    }
}