<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Тип маршрута</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="routeTypeId">Код:</label>
              <input-integer
                id="routeTypeId"
                class="form-control w-25"
                v-model="routeTypeId"
                readonly
              />
            </div>
            <div class="form-group">
              <label for="longName">Полное наименование:</label><req-field />
              <input-string
                  class="form-control"
                  id="longName"
                  v-model="longName"
                  ref="longName"
                  :readonly="op === 'show'"
                  required
                  error="Введите полное наименование"
              />
            </div>
            <div class="form-group">
              <label for="shortName">Краткое наименование:</label><req-field/>
              <input-string
                  class="form-control"
                  id="shortName"
                  v-model="shortName"
                  ref="shortName"
                  :readonly="op === 'show'"
                  required
                  error="Введите краткое наименование"
              />
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";

export default {
  components: {ReqField},
  props: ['item', 'op'],
    emits: ['onOK', 'onCancel'],
    mixins: [ValidateFormMixin],
    data() {
        return {
            routeTypeId: this.item.route_type_id,
            shortName: this.item.short_name,
            longName: this.item.long_name,
        };
    },
    methods: {
        onSubmit() {
          if (this.validateForm(this.$refs)) {
            this.$emit('onOK', {
              route_type_id: this.op === 'add' ? undefined : this.routeTypeId,
              short_name: this.shortName,
              long_name: this.longName,
            })
          }
        },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>