<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Глобальный параметр</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="moduleId">Модуль:</label>
              <modules-select
                  id="moduleId"
                  class="form-control"
                  v-model="moduleId"
                  readonly
              />
            </div>

            <div class="form-group">
              <label for="srcNum">Наименование:</label>
              <input-string
                  class="form-control"
                  id="key"
                  v-model="key"
                  ref="key"
                  readonly
              />
            </div>

            <div class="form-group">
              <label for="value">Значение параметра:</label>
              <input-string
                  id="value"
                  class="form-control"
                  v-model="value"
              />
            </div>

            <div class="form-group">
              <label for="desc">Хеш-сумма сущности в локальной системе:</label>
              <input-textarea
                  id="desc"
                  class="form-control"
                  v-model="desc"
                  readonly
              />
            </div>

          </form>
        </modal-body>
        <modal-footer>
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
          <button @click="$emit('onCancel')" class="btn btn-primary">Отмена</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputString from "@/components/ui/custom/InputString";
import ModulesSelect from "@/components/system/modules/ModulesSelect";
import InputTextarea from "@/components/ui/custom/InputTextarea";

export default {
  components: {InputTextarea, ModulesSelect, InputString},
  props: ['item'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      moduleId: this.item.module_id,
      key: this.item.key,
      value: this.item.value,
      desc: this.item.desc,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          module_id: this.moduleId,
          key: this.key,
          value: this.value,
          desc: this.desc,
        });
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>