import store from './Store';

// вернуть пользователя
export function getUser(uid) {
    return store.getters["auth/getUsers"].find(user => user.uid == uid);
}

// вернуть пользователя
export function getAllowedModules(module_id) {
    return store.getters["auth/getAllowedModules"].find(module => module.module_id == module_id);
}
