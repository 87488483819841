<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-nav-radio"></edit-table-panel>
</template>

<script>


import {getRadioModel, getRadioProtocol} from "@/store/NavHelpers";
import {getDepot, getTransport} from "@/store/RefHelpers";
import {secondsToDateAndTimeWithoutSeconds} from "@/lib";

export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "radio_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "radio_num",
          caption: "Номер радиостанции",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "radio_protocol",
          caption: "Эфирный протокол",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "radio_gate_num",
          caption: "Номер шлюза",
          sortType: 'string',
          isFilterable: true
        },
        {
          field: "radio_model",
          caption: "Модель",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "depot",
          caption: "Предприятие",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "transport",
          caption: "ТС",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "radio_priority",
          caption: "Приоритетность",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "is_disabled",
          caption: "Откл.",
          sortType: 'boolean',
          dataAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_disabled) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "time_index_begin",
          caption: "Начало действия",
          sortType: 'string',
          displayFn(row) {
            if (Number.isInteger(row.time_index_begin)) {
              return secondsToDateAndTimeWithoutSeconds(row.time_index_begin);
            }
            else return '';
          },
          show: true,
        },
        {
          field: "time_index_end",
          caption: "Окончание действия",
          sortType: 'string',
          displayFn(row) {
            if (Number.isInteger(row.time_index_end)) {
              return secondsToDateAndTimeWithoutSeconds(row.time_index_end);
            }
            else return '';
          },
          show: false,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["nav/getRadios"].map(radio => {

        radio.__id = radio.radio_id;
        // загружаем эфирный протокол
        let radio_protocol = getRadioProtocol(radio.radio_protocol_id);
        if(radio_protocol)
          radio.radio_protocol = radio_protocol.radio_protocol_title;
        // загружаем модель радиостанции
        let radio_model = getRadioModel(radio.radio_model_id);
        if(radio_model)
          radio.radio_model = radio_model.radio_model_title;
        // загружаем транспорт
        let ts = getTransport(radio.transport_id);
        if(ts)
          radio.transport = ts.state_num + ' / ' + ts.garage_num;
        // загружаем предприятие
        let depot = getDepot(radio.depot_id);
        if(depot)
          radio.depot = depot.long_name;

        // задаем стиль строк
        // делаем списанными серые ТС
        if (radio.time_index_begin && radio.time_index_end) {
          radio.__retired = true;
          radio.__rowStyle = {
            color: '#a1a1a1'
          };
        } else {
          radio.__retired = false;
        }

        return radio;
      });
    },
  }
};
</script>
