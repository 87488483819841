import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ТИПОВ РАСПИСАНИЙ

export default {
    state() {
        return {
            // список объектов
            scheduleTypes: [],
            // время обновления кэша new Date().getTime()
            scheduleTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            scheduleTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getScheduleTypes(state) {
            return state.scheduleTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setScheduleTypes(state, payload) {
            state.scheduleTypes = payload;
            state.scheduleTypesCache = new Date().getTime()
        },
        // добавить объект
        addScheduleType(state, payload) {
            state.scheduleTypes.push(payload)
        },
        // изменить объект
        editScheduleType(state, payload) {
            const index = state.scheduleTypes.findIndex(type => type.schedule_type_id == payload.schedule_type_id);
            if (index >= 0) {
                state.scheduleTypes[index] = payload;
            }
        },
        // удалить объект
        delScheduleType(state, payload) {
            const index = state.scheduleTypes.findIndex(type => type.schedule_type_id == payload.schedule_type_id);
            state.scheduleTypes.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchScheduleTypes: (context, payload) => {
            return DoGet(
                '/rmt/scheduleTypes',
                'rmt/setScheduleTypes',
                [],
                'Запрос типов расписаний',
                false,
                payload && payload.force,
                context.state.scheduleTypesCache,
                context.state.scheduleTypesCacheTimeout
            )
        },

        // очищает кэш
        doClearScheduleTypes: (context, payload) => {
            return DoClearCache(
                'rmt/setScheduleTypes',
                [],
                payload && payload.force,
                context.state.scheduleTypesCache,
                context.state.scheduleTypesCacheTimeout
            )
        },

        // добавление объекта
        doAddScheduleType: (context, payload) => {
            return DoPost(
                '/rmt/scheduleTypes',
                payload,
                'rmt/addScheduleType',
                'Добавление типа расписания'
            )
        },

        // изменение объекта
        doEditScheduleType: (context, payload) => {
            return DoPut(
                '/rmt/scheduleTypes/'+payload.schedule_type_id,
                payload,
                'rmt/editScheduleType',
                'Изменение типа расписания'
            )
        },

        // удаление объекта
        doDelScheduleType: (context, payload) => {
            return DoDelete(
                '/rmt/scheduleTypes/'+payload.schedule_type_id,
                'rmt/delScheduleType',
                'Удаление типа расписания'
            )
        }
    }
}
