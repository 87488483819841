<template>

<align-container name="main">
  <align-panel align="left" width="40%">
    <align-container name="lft">
        <align-panel align="top" height="40%" :gap="5">
          <vehicle-button-panel>
            <template #header>
              Вне наряда:
            </template>

            <vehicle-button :vehicle="vehicle" buttonState="call" :contextMenuItems="items"></vehicle-button>
            <vehicle-button :vehicle="vehicle" buttonState="sos"></vehicle-button>
            <vehicle-button :vehicle="vehicle" buttonState="message"></vehicle-button>
            <vehicle-button :vehicle="vehicle" buttonState="photo"></vehicle-button>
            <vehicle-button :vehicle="vehicle" title="123"></vehicle-button>
            <vehicle-button :vehicle="vehicle" title="p123gg111"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
          </vehicle-button-panel>
        </align-panel>
        <align-panel align="top" height="40%">
          <vehicle-button-panel>
            <template #header>
              Специальные:
            </template>

            <vehicle-button :vehicle="vehicle" buttonState="call"></vehicle-button>
            <vehicle-button :vehicle="vehicle" buttonState="sos"></vehicle-button>
            <vehicle-button :vehicle="vehicle" buttonState="message"></vehicle-button>
            <vehicle-button :vehicle="vehicle" buttonState="photo"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
            <vehicle-button :vehicle="vehicle"></vehicle-button>
          </vehicle-button-panel>
        </align-panel>
    </align-container>
  </align-panel>
</align-container>

</template>

<script>
import VehicleButtonPanel from '@/components/nav/map/VehicleButtonPanel'
import VehicleButton from "@/components/nav/map/VehicleButton";
export default {
  components: {
    VehicleButtonPanel,
    VehicleButton
  },
  name: "TestPage3",
  data() {
    return {
      vehicle: {
        "is_invalid": true,
        "time_delta": 10,
        "garage_num": "e312oo197"
      },
      items: [
        {
          label: "На карту",
          customClass: "vehicle-context-menu",
          onClick: () => {

          }
        },
        {
          label: "Группа на карту",
          customClass: "vehicle-context-menu",
          onClick: () => {

          }
        },
        {
          label: "Все на карту",
          customClass: "vehicle-context-menu",
          divided: true,
          onClick: () => {

          }
        },
        {
          label: "Информация",
          customClass: "vehicle-context-menu",
          onClick: () => {

          }
        },

      ]
    }
  }
}
</script>

<style scoped>

</style>
