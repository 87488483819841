<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" :disableSettings="true"
                    :rowsPerPage="100000" name="tbl-kvr-work-task-cp-nav"></edit-table-panel>
</template>

<script>

import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {secondsToTime} from "@/lib";
import {getRoute} from "@/store/KvrHelpers";

export default {
  components: {EditTablePanel},
  props:['rows'],
  data(){
    return {
      // колонки
      columnList: [
        {
          field: 'route_num',
          caption: '№ Маршрута',
          sortType: 'routeNum',
          isFilterable: true,
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'graph',
          caption: 'График',
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'time_index_begin',
          caption: 'Начало',
          sortType: 'number',
          sorted: 'asc',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.time_index_begin) {
              return secondsToTime(row.time_index_begin);
            }
            return ''
          },
        },
        {
          field: 'time_index_end',
          caption: 'Окончание',
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.time_index_end) {
              return secondsToTime(row.time_index_end);
            }
            return ''
          },
        },
        {
          field: '__id',
          caption: 'Код',
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        }
      ]
    }
  },

  computed:{

    rowList(){
      return this.rows.map(task =>{
        task.__id = task.work_task_id;
        task.route_num = getRoute(task.route_id).route_num;
        task.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px'
        };
        return task;
      });
    }

  }
}
</script>

<style scoped>

</style>