<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span>Тарифные зональные системы</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">Связь</button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="routeVariantsLink">Тарифные зоны</dropdown-item>
                  <dropdown-item router-link :to="tariffZoneFaresLink">Стоимости проезда между зонами</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <tariff-zone-systems-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :routeGroupId="routeGroupId"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></tariff-zone-systems-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <tariff-zone-system-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </tariff-zone-system-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить тарифную зональную систему с кодом {{ id }} ?</confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import TariffZoneSystemDialog from "@/components/multipass/tariff_zone_systems/TariffZoneSystemDialog";
import TariffZoneSystemsEditTable from "@/components/multipass/tariff_zone_systems/TariffZoneSystemsEditTable";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    TariffZoneSystemsEditTable,
    TariffZoneSystemDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op", 
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      //группа
      routeGroupId: null,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }    
  },

  computed: {
      // состояние crud-кнопок
      crudButtonsEnabled() {
        return {
          add: true,
          edit: !!this.selectedRow,
          delete: !!this.selectedRow,
          refresh: true
        }
      },

      // ссылка для перехода к Тарифным зонам
      routeVariantsLink() {
        if (this.selectedRow) return 'tariffZoneSystems/' + this.selectedRow.tariff_zone_system_id + '/tariffZones';
        else return '';
      },

      // ссылка для перехода к  cтоимости проезда между зонами
      tariffZoneFaresLink() {
        if (this.selectedRow) return 'tariffZoneSystems/' + this.selectedRow.tariff_zone_system_id + '/tariffZoneFares';
        else return '';
      },

      // условие отображения диалогового окна
      isDialogVisible() {
        return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
      },

      // условие отображения подтверждения удаления
      isDelConfirmVisible() {
        return this.op === 'del' && !!this.selectedRow;
      },

      // условие отключения кнопки - Связь
      linkDisabled() {
        return !this.selectedRow;
      },

      // запись для подстановки в диалог
      dialogRow() {
        if (this.op === 'add') return {}
          else return this.selectedRow;
      }
  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
        this.$router.replace("/multipass/tariffZoneSystems/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/multipass/tariffZoneSystems/edit/" + this.selectedRow.tariff_zone_system_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/multipass/tariffZoneSystems/del/" + this.selectedRow.tariff_zone_system_id);
    },

    // нажата кнопка Показать
    onTableShow() {
      this.$router.replace("/multipass/tariffZoneSystems/show/" + this.selectedRow.tariff_zone_system_id);
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/multipass/tariffZoneSystems");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      }
      else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/multipass/tariffZoneSystems");
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/multipass/tariffZoneSystems");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('multipass/doFetchTariffZoneSystems', {force})

      }
      finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('multipass/doAddTariffZoneSystem', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('multipass/doEditTariffZoneSystem', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('multipass/doDelTariffZoneSystem', this.selectedRow);
        this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
