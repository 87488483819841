<template>
<!--  <input-select ref="select" v-model="value" caption="Выберите ТС..." :itemList="items" error=""></input-select>-->
  <lookup-input-select ref="select" v-model="value" caption="" :itemList="items" error=""></lookup-input-select>
</template>

<script>
import LookupInputSelect from "@/components/ui/custom/LookupInputSelect";
export default {
  components: {LookupInputSelect},
  props: ['model-value', 'depot_id', 'isThisRowSelected'],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:model-value', value);
      }
    },
    items() {
      let arr = [];
      if (this.$store.getters['kvr/getOrderVehiclesByDepotId'][this.depot_id]) {
        // если эта строка в режиме редактирования и выбранная, то заполняем выпадающий список
        if (this.isThisRowSelected) {
          this.$store.getters['kvr/getOrderVehiclesByDepotId'][this.depot_id].map(item => {
            arr.push({
              id: item.transport_id,
              title: item.garage_num,
              value: item.garage_num
            });
          });
        } else {
          let ts = this.$store.getters['kvr/getOrderVehiclesByDepotId'][this.depot_id].find(value => this.modelValue == value.transport_id);
          if (ts) {
            arr.push({
              id: ts.transport_id,
              title: ts.garage_num,
              value: ts.garage_num
            });
          }
        }
      }
      return arr;
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
