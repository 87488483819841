<template>
  <div :class="classValue" ref="collapse">
    <slot></slot>
  </div>
</template>

<script>
import ActiveMixin from "@/components/ui/bootstrap/mixins/ActiveMixin";
import ChildMixin from "@/components/ui/bootstrap/mixins/ChildMixin";
import {reflow} from "@/components/ui/bootstrap/lib";

export default {
  data() {
    return {
      isCollapsing: false,
    }
  },
  mixins: [ActiveMixin, ChildMixin],
  computed: {
    classValue() {
      if (this.isCollapsing) {
        return 'collapsing'
      }
      else
      if (this.isActive) {
        return 'collapse show'
      }
      else {
        return 'collapse'
      }
    }
  },
  watch: {
    isActive(value) {
      const collapse = this.$refs.collapse;
      // определяем внутреннюю часть
      const child = collapse.children[0];
      // если нет - выхродим
      if (!child) return

      // появление
      if (value) {
        this.isCollapsing = true;
        collapse.style['height'] = "0px"
        this.$nextTick(() => {
          const scrollHeight = child.scrollHeight;
          collapse.style['height'] = `${scrollHeight}px`
          this.$refs.collapse.addEventListener('transitionend', () => {
            this.isCollapsing = false;
            collapse.style['height'] = ''
          }, {once: true});
        });
      }
      // пропадание
      else {
        // меняем класс и стили
        this.isCollapsing = true;
        collapse.style['height'] = `${child.getBoundingClientRect().height}px`;
        // что-то там перезапускаем
        reflow(collapse)
        // ждем обновления
        this.$nextTick(() => {
          // меняем стиль
          collapse.style['height'] = '';
          // подписываемся на событие
          this.$refs.collapse.addEventListener('transitionend', () => {
            this.isCollapsing = false;
          },{once: true});
        });
      }
    }
  }
}
</script>
