import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ РЕКВЕЗИТОВ ОРГАНИЗАЦИЙ

export default {

    actions: {

        // запрос списка объектов
        doFetchOrganizationDetails: (context, payload) => {
            if (payload.org_id) {
                return DoGet(
                    '/ref/organizationDetails?org_id=' + payload.org_id,
                    null,
                    [],
                    'Запрос реквизитов организации',
                    false,
                    true
                )
            } else {
                return DoGet(
                    '/ref/organizationDetails',
                    null,
                    [],
                    'Запрос реквизитов организации',
                    false,
                    true
                )

            }
        },

        // добавление объекта
        doAddOrganizationDetail: (context, payload) => {
            return DoPost(
                '/ref/organizationDetails',
                payload,
                null,
                'Добавление реквизита организации'
            )
        },

        // изменение объекта
        doEditOrganizationDetail: (context, payload) => {
            return DoPut(
                '/ref/organizationDetails/'+payload.org_detail_id,
                payload,
                null,
                'Изменение реквизита организации'
            )
        },

        // удаление объекта
        doDelOrganizationDetail: (context, payload) => {
            return DoDelete(
                '/ref/organizationDetails/'+payload.org_detail_id,
                null,
                'Удаление реквизита организации'
            )
        }
    }
}
