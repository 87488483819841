<template>
  <div>
    <h2 class="text-center">Модуль "Генератор"</h2>
    <p class="text-center">Расчет планового расписания движения общественного транспорта.</p>
  </div>
</template>

<script>
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixin: [PageMixin],
}

</script>