import { DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОКУМЕНТОВ ВАРИАНТОВ РАСПИСАНИЙ

export default {
    actions: {
        // запрос объекта
        doFetchScheduleVariantDoc: (context, payload) => {
            return DoGet(
                '/rmt/scheduleVariantDocs/' + payload.schedule_variant_doc_id,
                null,
                {},
                'Запрос документа варианта расписания',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchScheduleVariantDocs: (context, payload) => {
            return DoGet(
                '/rmt/scheduleVariantDocs?schedule_variant_id=' + payload.schedule_variant_id,
                null,
                [],
                'Запрос документов варианта расписания',
                false,
                true,
            )
        },

        // добавление объекта
        doAddScheduleVariantDoc: (context, payload) => {
            return DoPost(
                '/rmt/scheduleVariantDocs',
                payload,
                null,
                'Добавление документа варианта расписания'
            )
        },

        // изменение объекта
        doEditScheduleVariantDoc: (context, payload) => {
            return DoPut(
                '/rmt/scheduleVariantDocs/'+ payload.schedule_variant_doc_id,
                payload,
                null,
                'Изменение документа варианта расписания'
            )
        },

        // удаление объекта
        doDelScheduleVariantDoc: (context, payload) => {
            return DoDelete(
                '/rmt/scheduleVariantDocs/'+ payload.schedule_variant_doc_id,
                null,
                'Удаление документа варианта расписания'
            )
        }
    }
}