import SystemEventsLoggerStore from "@/store/logger/SystemEventsLoggerStore";
import SystemEventDetailsLoggerStore from "@/store/logger/SystemEventDetailsLoggerStore";


//  ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ KVR

export default {
    namespaced: true,
    modules:{
        SystemEventsLoggerStore,
        SystemEventDetailsLoggerStore
    }

}