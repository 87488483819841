<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" :disableSettings="true"
                    :rowsPerPage='5000'  name="tbl-rmt-schedule-variants"></edit-table-panel>
</template>

<script>

import {getLength, getSignTitle, getTimeFormat} from "@/lib";
import {getStop} from "@/store/MultipassHelpers";
import {getScheduleEvent} from "@/store/RmtHelpers";

export default {
  props: ['isLoading', 'scheduleTimeTables'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__stop_name",
          caption: "Остановка",
          isSortable: false
        },
        {
          field: "plan_time",
          caption: "Время",
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false,
          show: true,
          displayFn(row){
            return getTimeFormat(row.plan_time)
          }
        },
        {
          field: "__event_name",
          caption: "Событие",
          dataAlign: 'left',
          headerAlign: 'left',
          isSortable: false,
        },
        {
          field: "trip_type_sign",
          caption: "Тип рейса",
          sortType: 'sign',
          dataAlign: 'left',
          headerAlign: 'left',
          isSortable: false,
          displayFn(row) {
            return getSignTitle(row.trip_type_sign)
          },
        },
        {
          field: "trip_num",
          caption: "№ рейса",
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false,
        },
        {
          field: "__next_stop_length",
          caption: "Пробег",
          dataAlign: 'right',
          headerAlign: 'right',
          isSortable: false
        },
        {
          field: "stop_id",
          caption: "Код",
          sortType: 'string',
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false
        },
        {
          field: '__is_control_point',
          caption: 'КП',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          isSortable: false,
          displayFn(row) {
            if (row.__is_control_point) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        }
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      const scheduleTimeTables = this.scheduleTimeTables;
      return scheduleTimeTables.map(schTimeTable => {

        schTimeTable.__id = schTimeTable.schedule_time_table_id;
        // получаем остановку
        let stop = getStop(schTimeTable.stop_id);
        if (stop != null) {
          schTimeTable.__stop_name = stop.long_name;
        }
        schTimeTable.__next_stop_length = getLength(schTimeTable.next_stop_length)
        schTimeTable.__is_control_point = schTimeTable.is_control_point;

        // получаем ивент
        let event = getScheduleEvent(schTimeTable.schedule_event_id);
        // прописываем название события для всех типов событий кроме 1 и 2
        if(event.schedule_event_type_id != 1 && event.schedule_event_type_id != 2 ) {
          schTimeTable.__event_name = event.long_name;
        }

        // задаем стиль строк
        if (event.schedule_event_type_id == 1) {
          if ((schTimeTable.trip_num % 2) === 0) {
            schTimeTable.__rowStyle = {
              backgroundColor: this.$store.getters['settings/getProductionReisColor2']
            }
          } else {
            schTimeTable.__rowStyle = {
              backgroundColor: this.$store.getters['settings/getProductionReisColor']
            }
          }
        } else if (event.schedule_event_type_id == 2) {
          schTimeTable.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getTechnicalReisColor']
          }
        } else if (event.schedule_event_type_id == 3) {
          schTimeTable.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getShortEventColor']
          }
        } else if (event.schedule_event_type_id == 4) {
          schTimeTable.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getLongEventColor']
          }
        } else if (event.schedule_event_type_id == 5) {
          schTimeTable.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getLengthWithTimeEventColor']
          }
        } else if (event.schedule_event_type_id == 6) {
          schTimeTable.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getLengthWithTimeEventColor']
          }
        }
        return schTimeTable
      });
    },
  },
};
</script>
