<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Ребро графа</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>
                <div class="row">
                  <div class="form-group col">
                    <label for="edgeId">Код:</label>
                    <input-integer
                        id="edgeId"
                        class="form-control"
                        v-model="edgeId"
                        readonly
                    />
                  </div>

                  <div class="form-group col">
                    <label for="length">Длина ребра (метры):</label>
                    <input-integer
                        id="length"
                        v-model="length"
                        class="form-control"
                        ref="length"
                        autocomplete="off"
                        readonly
                    />
                  </div>

                </div>

                <div class="form-group row" style="margin-bottom: 0px">
                  <div class="form-group col">
                    <label for="roadId">Дорога:</label>
                    <roads-select
                        id="roadId"
                        v-model="roadId"
                        class="form-control"
                        ref="roadId"
                        autocomplete="off"
                        :readonly="op === 'show'"
                    />
                  </div>
                </div>


                <div class="form-group row" style="margin-bottom: 0px">
                  <div class="form-group col">
                    <label for="orderNum">Позиция ребра:</label>
                    <input-integer
                        id="orderNum"
                        v-model="orderNum"
                        class="form-control"
                        ref="orderNum"
                        autocomplete="off"
                        :readonly="op === 'show'"
                    />
                  </div>

                  <div class="form-group col">
                    <label for="direction">Направление:</label>
                    <input-button-option
                        id="direction"
                        v-model="direction"
                        ref="direction"
                        autocomplete="off"
                        :readonly="op === 'show'"
                        :itemList="[
                        {
                          id:1,
                          title:'Прямое'
                        },
                        {
                          id:-1,
                          title:'Обратное'
                        }
                      ]"
                    />
                  </div>
                </div>

                <div class="form-group row" style="margin-bottom: 0px">
                  <div class="form-group col">
                    <label for="maxSpeed">Макс. скорость (км/ч):</label>
                    <input-integer
                        id="maxSpeed"
                        v-model="maxSpeed"
                        class="form-control"
                        ref="maxSpeed"
                        :readonly="op === 'show'"
                    />
                  </div>

                  <div class="form-group col">
                    <label for="lanesCount">Количество полос:</label>
                    <input-integer
                        id="lanesCount"
                        v-model="lanesCount"
                        class="form-control"
                        ref="lanesCount"
                        :readonly="op === 'show'"
                    />
                  </div>
                </div>

              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputButtonOption from "@/components/ui/custom/InputButtonOption";
import RoadsSelect from "@/components/gis/graph/RoadsSelect";

export default {
  components: {RoadsSelect, InputButtonOption},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      edgeId: this.item.edge_id,
      roadId: this.item.road_id,
      orderNum: this.item.order_num,
      nodeIdBegin: this.item.node_id_begin,
      nodeIdEnd: this.item.node_id_end,
      direction: this.item.direction,
      length: this.item.length,
      maxSpeed: this.item.max_speed,
      lanesCount: this.item.lanes_count,
      edgeType: this.item.edge_type,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {

        this.$emit('onOK', {
          edge_id: this.edgeId,
          road_id: this.roadId,
          order_num: this.orderNum,
          node_id_begin: this.nodeIdBegin,
          node_id_end: this.nodeIdEnd,
          direction: this.direction,
          length: this.length,
          max_speed: this.maxSpeed,
          lanes_count: this.lanesCount,
          edge_type: this.edgeType
        })
      }
    },
  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>