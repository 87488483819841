import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ПРАЗДНИЧНЫХ ДНЕЙ

export default {
    state() {
        return {
            // список объектов
            holidays: [],
            // время обновления кэша new Date().getTime()
            holidaysCache: null,
            // время жизни кэша в мс (null - бесконечно)
            holidaysCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getHolidays(state) {
            return state.holidays;
        },
    },
    mutations: {
        // установить новый список объектов
        setHolidays(state, payload) {
            state.holidays = payload;
            state.holidaysCache = new Date().getTime()
        },
        // добавить объект
        addHoliday(state, payload) {
            state.holidays.push(payload)
        },
        // изменить объект
        editHoliday(state, payload) {
            const index = state.holidays.findIndex(holiday => holiday.holiday_id == payload.holiday_id);
            if (index >= 0) {
                state.holidays[index] = payload;
            }
        },
        // удалить объект
        delHoliday(state, payload) {
            const index = state.holidays.findIndex(holiday => holiday.holiday_id == payload.holiday_id);
            state.holidays.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchHolidays: (context, payload) => {
            return DoGet(
                '/rmt/holidays',
                'rmt/setHolidays',
                [],
                'Запрос праздничных дней',
                false,
                payload && payload.force,
                context.state.holidaysCache,
                context.state.holidaysCacheTimeout
            )
        },

        // очищает кэш
        doClearHolidays: (context, payload) => {
            return DoClearCache(
                'rmt/setHolidays',
                [],
                payload && payload.force,
                context.state.holidaysCache,
                context.state.holidaysCacheTimeout
            )
        },

        // добавление объекта
        doAddHoliday: (context, payload) => {
            return DoPost(
                '/rmt/holidays',
                payload,
                'rmt/addHoliday',
                'Добавление праздничного дня'
            )
        },

        // изменение объекта
        doEditHoliday: (context, payload) => {
            return DoPut(
                '/rmt/holidays/'+payload.holiday_id,
                payload,
                'rmt/editHoliday',
                'Изменение праздничного дня'
            )
        },

        // удаление объекта
        doDelHoliday: (context, payload) => {
            return DoDelete(
                '/rmt/holidays/'+payload.holiday_id,
                'rmt/delHoliday',
                'Удаление праздничного дня'
            )
        }
    }
}
