<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-roles"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
export default {
  components: {EditTablePanel},
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "role_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "role_name",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "desc",
          caption: "Описание",
          sortType: 'string',
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["auth/getRoles"].map( role => {
        role.__id = role.role_id;
        return role;
      });
    },
  },
};
</script>
