<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Описание документа</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <form>

            <div class="form-group">
              <label for="id">Код:</label>
              <input-integer
                  id="id"
                  class="form-control w-25"
                  v-model="id"
                  readonly
              />
            </div>

            <div class="form-group">
              <label for="docTypeId">Тип документа:</label>
              <req-field/>
              <doc-types-select
                  :table_name="table_name"
                  id="docTypeId"
                  v-model="docTypeId"
                  class="form-control"
                  ref="docTypeId"
                  :readonly="op === 'show'"
                  required
                  error="Выберите тип документа"
              />
            </div>

            <div class="form-group">
              <label for="title">Заголовок:</label>
              <req-field/>
              <input-string
                  id="title"
                  v-model="title"
                  class="form-control"
                  ref="title"
                  :readonly="op === 'show'"
                  required
                  error="Введите заголовок"
              />
            </div>

            <div class="form-group">
              <label for="text">Содержание:</label>
              <req-field/>
              <input-textarea
                  id="text"
                  v-model="text"
                  class="form-control"
                  ref="text"
                  :readonly="op === 'show'"
                  rows="5"
                  required
                  error="Введите содержание"
              />
            </div>

            <div class="form-group">
              <label for="docDate">Дата документа:</label>
              <req-field/>
              <input-date
                  id="docDate"
                  v-model="docDate"
                  class="form-control"
                  ref="docDate"
                  :readonly="op === 'show'"
                  required
              />
            </div>

            <div class="form-group row ">
              <div class="form-group col ">
                <label for="timeInsert">Время внесения документа:</label>
                <input-string
                    id="timeInsert"
                    v-model="timeInsert"
                    class="form-control"
                    ref="timeInsert"
                    readonly
                />
              </div>

              <div class="form-group col ">
                <label for="timeUpdate">Время обновления документа:</label>
                <input-string
                    id="timeUpdate"
                    v-model="timeUpdate"
                    class="form-control"
                    ref="timeUpdate"
                    readonly
                />
              </div>
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import InputInteger from "@/components/ui/custom/InputInteger";
import InputDate from "@/components/ui/custom/InputDate";
import InputString from "@/components/ui/custom/InputString";
import DocTypesSelect from "@/components/doc/doc_types/DocTypesSelect";
import InputTextarea from "@/components/ui/custom/InputTextarea";
import {getDateTimeFormat} from "@/lib";

export default {
  components: {
    InputTextarea,
    DocTypesSelect,
    InputString,
    InputDate,
    InputInteger,
    ReqField
  },
  props: ['item', 'op', 'table_name'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      id: this.item.__id,
      docId: this.item.doc.doc_id,
      docTypeId: this.item.doc.doc_type_id,
      title: this.item.doc.title,
      text: this.item.doc.text,
      docDate: this.item.doc.doc_date,
      timeInsert: getDateTimeFormat(this.item.doc.time_insert),
      timeUpdate: getDateTimeFormat(this.item.doc.time_update),
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          doc: {
            doc_id: this.docId,
            doc_type_id: this.docTypeId,
            title: this.title,
            text: this.text,
            doc_date: this.docDate,
          }
        })
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>