
// ХРАНИЛИЩЕ БУФЕР ОБМЕНА

export default {
    state() {
        return {
            // тип объекта
            type: '',
            // объект
            object: null,
        }
    },
    getters: {

        // вернуть тип
        getType(state) {
            return state.type;
        },

        // вернуть объект
        getObject(state) {
            return state.object;
        },
    },
    mutations: {

        // установить тип
        setType(state, payload) {
            state.type = payload;
        },

        // установить объект
        setObject(state, payload) {
            state.object = payload;
        },

    },
    actions: {
        // запрос списка объектов
        doSetClipboard: (context, payload) => {
            context.commit('setType', payload.type);
            context.commit('setObject', payload.object);
        },

    }
}
