import RmtGenTitlePage from '../pages/rmtgen/RmtGenTitlePage.vue';
import RoutesPage from "@/pages/rmtgen/RoutesPage";
import RouteVariantsPage from "@/pages/rmtgen/routes/RouteVariantsPage";
import ScheduleVariantsPage from "@/pages/rmtgen/routes/routes_variants/ScheduleVariantsPage";
import ScheduleGraphSetsPage from "@/pages/rmtgen/routes/routes_variants/schedule_variants/ScheduleGraphSetsPage";
import ScheduleGraphSetTablePage from "@/pages/rmtgen/routes/routes_variants/schedule_variants/schedule_graph_sets/ScheduleGraphSetTablePage";
import MainPage from "@/pages/main/MainPage";


export default {
    path: '/rmtgen',
    component: MainPage,
    children: [
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleGraphSets/:schedule_graph_set_id(\\d+)?/scheduleGraphSetTable',
            component: ScheduleGraphSetTablePage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleGraphSets/:op(show|add|del|edit|activate|deactivate|paste)/:id(\\d+)?',
            component: ScheduleGraphSetsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleGraphSets',
            component: ScheduleGraphSetsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/ScheduleVariants/:op(show|add|del|edit|status|activate|deactivate|paste|change)/:id(\\d+)?',
            component: ScheduleVariantsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/ScheduleVariants',
            component: ScheduleVariantsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:op(show)/:id(\\d+)?',
            component: RouteVariantsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants',
            component: RouteVariantsPage,
            props: true
        },
        {
            path:'routes/:op(show)/:id(\\d+)?',
            component: RoutesPage,
            props: true
        },
        {
            path:'routes',
            component: RoutesPage
        },
        {
            path: '',
            component: RmtGenTitlePage
        },
        {
            path: ':pathMatch(.*)*',
            component: RmtGenTitlePage
        }                
    ]
}