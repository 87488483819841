import {DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ ТЕКУЩИХ МЕСТОПОЛОЖЕНИЙ ТРАНСПОРТНЫХ СРЕДСТВ НА МАРШРУТАХ

export default {
    state() {
        return {
            // список транмпортных средств на маршрутах
            curWorkNav: [],
            // время обновления кэша new Date().getTime()
            curWorkNavCache: null,
            // время жизни кэша в мс (null - бесконечно)
            curWorkNavCacheTimeout: null
        }
    },
    getters: {
        // вернуть список маршрутов
        getCurWorkNav(state) {
            return state.curWorkNav
        },
    },
    mutations: {
        // установить новый список маршрутов
        setCurWorkNav(state, payload) {
            state.curWorkNav.push(payload);
            state.curWorkNavCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка
        doFetchCurWorkNav: (context, payload) => {
            return DoPost(
                '/kvr/getCurWorkNav',
                null,
                'kvr/setCurWorkNav',
                'Запрос списка транспортных средств на маршрутах',
                false,
                payload && payload.force,
                context.state.curWorkNavCache,
                context.state.curWorkNavCacheTimeout
            )
        },
    }
}