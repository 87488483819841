<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Тип рейса</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>

            <div class="form-group">
              <label for="tripTypeId">Код:</label>
              <input-integer
                  id="tripTypeId"
                  class="form-control w-25"
                  v-model="tripTypeId"
                  readonly
              />
            </div>

            <div class="form-group">
              <label for="title">Наименование:</label>
              <input-string
                  id="title"
                  v-model="title"
                  ref="title"
                  class="form-control"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="tripTypeSign">Тип рейса:</label><ReqField />
              <input-sign
                  id="tripTypeSign"
                  v-model="tripTypeSign"
                  ref="tripTypeSign"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="weight">Вес рейса:</label>
              <input-float
                  id="weight"
                  class="form-control w-25"
                  v-model="weight"
                  ref="weight"
                  :readonly="op === 'show'"
                  :min-value="0"
              />
            </div>

            <div class="form-group">
              <label for="note">Примечание:</label>
              <input-string
                  id="note"
                  class="form-control"
                  v-model="note"
                  ref="note"
                  :readonly="op === 'show'"
              />
            </div>

          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import InputInteger from "@/components/ui/custom/InputInteger";
import InputString from "@/components/ui/custom/InputString";
import InputFloat from "@/components/ui/custom/InputFloat";

export default {
    components: {
      InputFloat,
      InputString,
      InputInteger,
      ReqField
    },
    props: ['item', 'op'],
    emits: ['onOK', 'onCancel'],
    mixins: [ValidateFormMixin],
    data() {
        return {
          tripTypeId: this.item.trip_type_id,
          title: this.item.title,
          tripTypeSign: this.item.trip_type_sign,
          direction: this.item.trip_type_sign.substr(-2) ==='BA' ? 1 : 0,
          isProduction: this.item.is_production,
          weight: this.item.weight,
          note: this.item.note
        };
    },
    methods: {
        onSubmit() {
          if (this.validateForm(this.$refs)) {
            this.$emit('onOK', {
              trip_type_id: this.op === 'add' ? undefined : this.tripTypeId,
              title: this.title,
              trip_type_sign: this.tripTypeSign,
              direction: this.direction,
              is_production: this.$refs.tripTypeSign.isProduction(),
              weight: this.weight,
              note: this.note,
            })
          }
        },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>