<template>
<slot></slot>
</template>

<script>
import {LayerGroup} from "leaflet";
import {toRaw} from "vue";
import LeafletOverlayMixin from "@/components/ui/leaflet/mixins/LeafletOverlayMixin";
import LeafletGroupMixin from "@/components/ui/leaflet/mixins/LeafletGroupMixin";

// ГРУППА СЛОЕВ

export default {
  mixins: [LeafletGroupMixin, LeafletOverlayMixin],
  props: {
    // опции группы
    options: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletLayerGroup',
    }
  },
  methods: {
    // создание слоя
    createLayer() {
      // создаем слой
      const layer = new LayerGroup([], toRaw(this.options));
      layer.__meta = this.meta;
      // подписываемся на события слоя
      this.subscribe(layer);
      // возвращаем слой
      this.proxyOrigin = layer;
      return layer
    },
    // уничтожение слоя
    destroyLayer() {
      // удаляем подписку на события
      this.unsubscribe(this.origin);
      // убиваем ссылку
      this.proxyOrigin = null;
    },
  },
  // монтируем слой
  mounted() {
    // ждем создания родителя
    this.getParent().parentReady().then(() => {
        // наблюдатель за опциями
        this.$watch(() => this.options, () => {
          //console.log('Требуется изменить опции группы: '+ this.origin)
          this.reCreateLayer();
        }, {deep: false});
    })
  },
}
</script>
