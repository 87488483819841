<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/admin">Администрирование</router-link></span>
        <span> / </span>
        <span>Системные события</span>
      </page-header>
    </align-panel>

    <!-- нижняя панель с таблицами  -->
    <align-panel align="all">
      <!-- "делим" панель с таблицами на две панели  -->
      <align-container>
        <align-panel align="left" width="65%" :gap="5">
          <align-container>
            <!-- Форма с кнопками -->
            <align-panel align="top">
              <crud-form
                  :buttonsEnabled="crudButtonsEnabled"
                  :buttonsExist="{add: false, edit: false, delete: false, refresh: false}"
                  :filterExists="false"
              >

                <template #preLeftCustomButton>
                  <div class="form-group row" style="margin-bottom: 0; margin-right: 0px">
                    <p style="margin-bottom: 0; margin-left: 14px; margin-right: 10px; margin-top: 6px">Кол-во
                      записей:</p>
                    <input-integer
                        class="form-control"
                        id="lastСount"
                        v-model="lastСount"
                        style="width: 55px"
                    >
                    </input-integer>
                  </div>
                </template>

                <template #leftCustomButton>
                  <div class="form-group row" style="margin-bottom: 0; margin-right: 0px">
                    <p style="margin-bottom: 0; margin-left: 14px; margin-right: 10px; margin-top: 6px">Модуль:</p>
                    <modules-select
                        class="form-control"
                        v-model="selected_module_id"
                        :is-pref="true"
                        style="width: 220px"
                        @change="changeModule()"
                    >
                    </modules-select>
                  </div>
                </template>

                <template #leftCustomButton2>
                  <div class="form-group row" style="margin-bottom: 0; margin-right: 0px">
                    <p style="margin-bottom: 0; margin-left: 14px; margin-right: 10px; margin-top: 6px">Содержание:</p>
                    <input-string
                        class="form-control"
                        id="titleFilterText"
                        v-model="titleFilterText"
                        style="width: 170px"
                    >
                    </input-string>
                    <div
                        style="width: 20px; margin-left:-20px;"
                    >
                      <i style="padding-top: 10px;" class="button fas fa-times" @click="titleFilterText = ''" title="Очистить"></i>
                    </div>
                  </div>
                </template>

                <template #leftCustomButton3>
                  <div class="form-group row" style="margin-bottom: 0; margin-right: 0px">
                    <p style="margin-bottom: 0; margin-left: 14px; margin-right: 10px; margin-top: 6px">Сервис:</p>
                    <input-string
                      class="form-control"
                      id="serviceFilterText"
                      v-model="serviceFilterText"
                      style="width: 170px; padding-right: 20px !important;"
                    >
                    </input-string>
                    <div
                       style="width: 20px; margin-left:-20px;"
                    >
                      <i style="padding-top: 10px;" class="button fas fa-times" @click="serviceFilterText = ''" title="Очистить"></i>
                    </div>
                  </div>
                </template>

                <template #leftCustomButton4>
                  <input-checkbox class="mt-2" v-model="isAutoUpdate" id="isAutoUpdate">Автообновление</input-checkbox>
                </template>

              </crud-form>
            </align-panel>

            <align-panel align="all">
              <!-- Таблица "Системные события" -->
              <system-events-edit-table
                  :isLoading="isLoading"
                  :selectedRow="selectedRow"
                  :rowList="systemEventsRows"
                  @onSetModule="onSetModule"
                  @onSetService="onSetService"
                  @onRowSelect="onRowSelect"
              ></system-events-edit-table>
            </align-panel>
          </align-container>
        </align-panel>

        <align-panel align="all">
          <align-container>
            <!-- Форма с кнопками -->
            <align-panel align="top">
              <crud-form
                  :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
                  :buttonsEnabled="crudButtonsEnabled"
                  :filterExists="false"
                  @onRefresh="onTableRefresh"
              >
                <!-- Событие -->
                <template #leftCustomButton>
                  <div class="form-group row" style="margin-bottom: 0; margin-right: 0px">
                    <p style="margin-bottom: 0; margin-left: 14px; margin-right: 10px; margin-top: 6px">Событие:</p>
                    <input-string
                        class="form-control"
                        id="serviceFilterText"
                        v-model="system_event_id"
                        style="width: 100px"
                    >
                    </input-string>
                  </div>
                </template>

                <!-- Фильтр      -->
                <template #leftCustomButton2>
                  <div class="form-group row" style="margin-bottom: 0; margin-right: 0px; margin-left: 0px" @click.right.prevent=" headerContextMenu($event)">
                    <input-string
                        class="form-control"
                        id="serviceFilterText"
                        v-model="filterText"
                        :placeholder="isFilter == true ? 'Фильтр...' : 'Поиск...' "
                        style="width: 274px"
                    >
                    </input-string>

                    <div
                        v-if="isFilter == false"
                        style="width: 20px; margin-left:-60px;"
                    >
                      <i style="padding-top: 10px;padding-right: 5px" class="button fas fa-chevron-up" @click="scrollToPrevRow()" title="Назад"></i>
                    </div>
                    <div
                        v-if="isFilter == false"
                        style="width: 20px; "
                    >
                      <i style="padding-top: 10px;padding-right: 5px" class="button fas fa-chevron-down" @click="scrollToNextRow()" title="Вперед"></i>
                    </div>
                    <div
                        :style="'width: 20px;' + (isFilter == false ? '' : 'margin-left:-20px;') "
                    >
                      <i style="padding-top: 10px;" class="button fas fa-times" title="Очистить" @click="filterText = ''"></i>
                    </div>
                  </div>
                </template>

                <template #rightCustomButton2>
                  <button v-if="isLoadingCrud && !isCancelButtonVisible" class="btn btn-primary" type="button"
                          @mouseover="isCancelButtonVisible = true">
                    <span class="spinner-border spinner-border-sm"></span>
                    Загрузка...
                  </button>
                  <button v-if="isLoadingCrud && isCancelButtonVisible" class="btn btn-danger" type="button"
                          style="width: 120px" @mouseleave="isCancelButtonVisible = false" @click="cancelLoading">
                    <span class="spinner-border spinner-border-sm"></span>
                    Отмена
                  </button>
                  <div
                      v-else
                      style="width: 120px"
                  >
                  </div>
                </template>

              </crud-form>
            </align-panel>

            <align-panel align="all">
              <system-event-details-edit-table
                  :isLoading="isLoading"
                  :filterText="fText"
                  :selectedRow="selectedDetailRow"
                  :selRow="selectedDetailRow"
                  :rowList="systemEventDetailsRows"
                  @onRowSelect="onRowDetailSelect"
              ></system-event-details-edit-table>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>

    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import PageMixin from "@/pages/share/PageMixin";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import SystemEventsEditTable from "@/components/logger/system_events/SystemEventsEditTable";
import SystemEventDetailsEditTable from "@/components/logger/system_events/SystemEventDetailsEditTable";
import InputInteger from "@/components/ui/custom/InputInteger";
import InputString from "@/components/ui/custom/InputString";
import ModulesSelect from "@/components/system/modules/ModulesSelect";

export default {
  mixins: [PageMixin],
  components: {
    ModulesSelect,
    InputString,
    InputInteger,
    SystemEventDetailsEditTable,
    SystemEventsEditTable,
    AlignPanel,
  },
  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      fText: '',
      // текст поиска
      findText: '',
      // выбранная строка
      selectedRow: null,
      // выбранная строка в таблице справа
      selectedDetailRow: null,
      // отображать спиннер
      isLoading: false,
      // отображать спинер в круд форме
      isLoadingCrud: false,
      // видимость кнопки отмена
      isCancelButtonVisible: false,
      // количество последних записей
      lastСount: 50,
      // выбранный модуль
      selected_module_id: null,
      // текст фильтра сообщения
      titleFilterText: '',
      // текст фильтра сервиса
      serviceFilterText: '',
      // автообновление
      isAutoUpdate: true,
      // строки системных событий
      systemEventsRows: [],
      // строки элементов системного события
      systemEventDetailsRows: [],
      // таймер
      mainTimer: null,
      // system_event_id для запроса и отображения в поле
      system_event_id: '',
      // режим работы фильтра над правой таблицей
      isFilter: true,
    }
  },

  watch: {

    filterText(newValue) {
      const value = newValue.trim();
      setTimeout(()=>{
        if (value === newValue.trim()) {
          this.onFilterChanged(newValue);
        }
      }, 300);
    }

  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: false,
        edit: false,
        delete: false,
        refresh: true
      }
    },

    contextMenuItems() {
      let arr = [];
      // для данных значений вообще не добавляем
      if (this.isFilter == true) {
        arr.push(
            {
              label: "Поиск",
              onClick: () => {
                this.changeMode();
              }
            }
        )
      } else {
        arr.push(
            {
              label: "Фильтр",
              onClick: () => {
                this.changeMode();
              }
            }
        )
      }
      return arr;
    },

  },

  methods: {

    async scrollToNextRow() {
      if (this.filterText != '') {
        let selRowIndex = -1;
        if(this.selectedDetailRow){
          // пробуем найти строку с текстом, которая уже выделена
          selRowIndex = this.systemEventDetailsRows.findIndex(value =>
            this.selectedDetailRow.__id === value.__id
          )
        }
        if (selRowIndex < 0) {
          selRowIndex = 0;
        }

        //ищем строку с введенным текстом
        for (let i = selRowIndex+1; i < this.systemEventDetailsRows.length; i++) {
          let value = this.systemEventDetailsRows[i];
          if (value.event_result.toLowerCase().includes(this.filterText.toLowerCase()) || value.title.toLowerCase().includes(this.filterText.toLowerCase())) {
            this.selectedDetailRow = value;
            break;
          }
        }

        // скроллим до выбранной строки
        if (this.selectedDetailRow) {
          let el = await document.getElementById(this.selectedDetailRow.__id);
          if (el) {
            // Use el.scrollIntoView() to instantly scroll to the element
            el.scrollIntoView({behavior: 'auto', block: 'center'});
          }
        }

      }

    },

    async scrollToPrevRow() {
      if (this.filterText != '') {
        let selRowIndex = -1;
        if(this.selectedDetailRow){
          // пробуем найти строку с текстом, которая уже выделена
          selRowIndex = this.systemEventDetailsRows.findIndex(value =>
              this.selectedDetailRow.__id === value.__id
          )
        }
        if (selRowIndex < 0) {
          selRowIndex = this.systemEventDetailsRows.length - 1;
        }

        //ищем строку с введенным текстом
        for (let i = selRowIndex-1; i > 0; i--) {
          let value = this.systemEventDetailsRows[i];
          if (value.event_result.toLowerCase().includes(this.filterText.toLowerCase()) || value.title.toLowerCase().includes(this.filterText.toLowerCase())) {
            this.selectedDetailRow = value;
            break;
          }
        }

        // скроллим до выбранной строки
        if (this.selectedDetailRow) {
          let el = await document.getElementById(this.selectedDetailRow.__id);
          if (el) {
            // Use el.scrollIntoView() to instantly scroll to the element
            el.scrollIntoView({behavior: 'auto', block: 'center'});
          }
        }

      }

    },


    changeModule() {
      this.titleFilterText = '';
      this.serviceFilterText = '';
    },

    changeMode() {
      this.isFilter = !this.isFilter;
      this.onFilterChanged(this.filterText)
    },

    setFilterClear() {
      let val = document.getElementById('inlineFormInput');
      val.value = '';
      this.filterText = '';
    },

    // сетаем выбранный модуль
    onSetModule(module_id) {
      this.selected_module_id = module_id;
    },

    // сетаем выбранный модуль
    onSetService(service_name) {
      this.serviceFilterText = service_name;
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.updateSystemEvents(true);
    },

    // изменился фильтр
    onFilterChanged(newValue) {
      if(this.isFilter){
        this.fText = newValue;
        this.$nextTick(async () => {
          if (this.selectedDetailRow) {
            let el = await document.getElementById(this.selectedDetailRow.__id);
            if (el) {
              // Use el.scrollIntoView() to instantly scroll to the element
              el.scrollIntoView({behavior: 'auto', block: 'center'});
            }
          }
        })
      } else {
        this.fText = '';
        this.selectedDetailRow = null;
        this.scrollToNextRow();
      }
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      // если клиент не ждет ответа (нажата кнопка "отмена")
      if (this.isLoadingCrud != true) {
        // запоминаем строку
        this.selectedRow = item;
        // очищаем строки таблицы справа
        this.systemEventDetailsRows = [];
        // очищаем выбранную строку таблицы справа
        this.selectedDetailRow = null;
        // зпоминаем system_event_id, чтобы сделать по нему запрос и отобразить в поле
        this.system_event_id = this.selectedRow.system_event_id;
        // запрашиваем элементы системного события
        this.getSystemEventDetails();
      }
    },

    // отменяем загрузку строк для правой таблицы
    cancelLoading() {
      this.isLoadingCrud = false;
      // видимость кнопки Отмена
      this.isCancelButtonVisible = false;
    },

    // выбрана новая строка в правой таблице
    onRowDetailSelect(item) {
      this.selectedDetailRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/admin/systemEvents");
    },

    // перезапрашиваем строки
    async updateSystemEvents(force) {
      let params = {
        force: force
      };

      // перед запросом обновления строк формируем объект в зависимости от выбранных выше параметров
      // количество записей
      if (this.lastСount) {
        params['last_count'] = this.lastСount;
      }
      // выбранный модуль
      if (this.selected_module_id) {
        params['module_id'] = this.selected_module_id;
      }
      // Содержание
      if (this.titleFilterText != '') {
        params['title'] = this.titleFilterText;
      }
      // Сервис
      if (this.serviceFilterText != '') {
        params['service_name'] = this.serviceFilterText
      }
      // делаем запрос на сервер
      let systemEventsRows = await this.$store.dispatch('logger/doFetchSystemEventsFiltered', params);
      if (systemEventsRows)
        this.systemEventsRows = systemEventsRows;

      // если была выбранная строка
      if (this.selectedRow) {
        // если была выбранная строка ищем её индекс в новом массиве
        let index = this.systemEventsRows.findIndex(value => {
          return value.system_event_id == this.selectedRow.system_event_id;
        });

        // если индекс найден (строка есть в новом массиве)
        if (index != -1) {
          // делаем строку в массиве выбранной
          this.selectedRow = this.systemEventsRows[index];
          // если клиент не ждет ответа (нажата кнопка "отмена")
          if (this.isLoadingCrud != true) {
            // запрашиваем элементы системного события
            await this.getSystemEventDetails();
          }
          if (this.selectedDetailRow) {
            let ind = this.systemEventDetailsRows.findIndex(value => {
              return value.system_event_detail_id == this.selectedDetailRow.system_event_detail_id;
            });
            this.selectedDetailRow = this.systemEventDetailsRows[ind];
          }
        } else {
          this.selectedRow = null;
          this.system_event_id = '';
          this.systemEventDetailsRows = [];
        }
        // если строка не была выбрана, но был введен id вручную
      } else if (this.system_event_id) {
        if (this.isLoadingCrud != true) {
          // запрашиваем элементы системного события
          await this.getSystemEventDetails();
        }
      } else {
        this.systemEventDetailsRows = [];
      }

    },

    // запрашиваем элементы системного события
    async getSystemEventDetails() {
      // взводим параметр, что ждем ответ
      this.isLoadingCrud = true;
      // переменная полученных строк
      let systemEventDetailsRows = [];
      try {
        // делаем запрос на сервер
        systemEventDetailsRows = await this.$store.dispatch('logger/doFetchSystemEventDetails', {
          system_event_id: this.system_event_id
        });
        // если ответ пришел, взведен параметр, что клиент ждет ответ, и ответ соответствует выбранной сейчас строке (не нажата кнопка отмена)
        if (systemEventDetailsRows && this.isLoadingCrud && this.system_event_id == systemEventDetailsRows[0].system_event_id) {
          this.systemEventDetailsRows = systemEventDetailsRows;
        }
      } finally {
        // если ответ пришел, и ответ соответствует выбранной сейчас строке (не нажата кнопка отмена)
        // проверка this.selectedRow != null может быть нужна т.к. возможна ситуация, что пока мы ждали ответ по выбраной строке,
        // данные обновились и она  пропала из левой таблицы, значит ответ нам больше не нужен
        // if (systemEventDetailsRows && this.selectedRow != null && this.selectedRow.system_event_id == systemEventDetailsRows[0].system_event_id) {
        this.isLoadingCrud = false;
        this.isCancelButtonVisible = false;
        // }
      }
    },

    // запускаем обновление данных
    async refreshData(force = true) {
      this.isLoading = 'fetch';
      try {

        // запрос модулей
        await this.$store.dispatch('system/doFetchModules', {force});
        // запрашиваем строки системных событий, строки правой таблице
        await this.updateSystemEvents(force);

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // открываем контекстное меню по клику на заголовок
    headerContextMenu(e) {
      // передаем в перент строку для которой открыли контекстное меню
      this.$contextmenu({
        x: e.x,
        y: e.y,
        items: this.contextMenuItems,
      });
    },

  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },

  mounted() {
    // взваодим интервал, который будет перезапрашивать строки левой таблицы каждые 10 секунд
    this.mainTimer = setInterval(async () => {
      if (this.isAutoUpdate == true) {
        await this.updateSystemEvents(true);
      }
    }, 10000);
  },

  unmounted() {
    // убиваем интервал при уходе со страницы
    if (this.mainTimer) {
      clearInterval(this.mainTimer);
      this.mainTimer = null;
    }
  }
};
</script>

<style>

.button {
  color: gray;
  cursor: pointer;
}

.button:hover {
  color: #000000;
}

</style>