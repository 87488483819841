import {DoClearCache, DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ МАСТЕР НАРЯДОВ

export default {
    state() {
        return {
            // список объектов
            masterOrders: [],
            // время обновления кэша new Date().getTime()
            masterOrdersCache: null,
            // время жизни кэша в мс (null - бесконечно)
            masterOrdersCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getMasterOrders(state) {
            return state.masterOrders;
        },
    },
    mutations: {

        // установить новый список объектов
        setMasterOrders(state, payload) {
            state.masterOrders = payload;
            state.masterOrdersCache = new Date().getTime()
        },

        // изменить объект
        editMasterOrder(state, payload) {
            const index = state.masterOrders.findIndex(masterOrder => (masterOrder.date_index == payload.date_index) && (masterOrder.route_id == payload.route_id));
            if (index >= 0) {
                state.masterOrders[index] = payload;
            }
        },
    },

    actions: {

        // запрос списка объектов (за указанный день)
        doFetchMasterOrders: (context, payload) => {
            return DoGet(
                '/kvr/masterOrders/' + payload.date_index,
                'kvr/setMasterOrders',
                [],
                'Получить мастер-наряды за указанный день',
                false,
                payload && payload.force,
                context.state.masterOrdersCache,
                context.state.masterOrdersCacheTimeout
            )
        },

        // запрос мастер-наряда по маршруту за указанный день
        doFetchMasterOrder: (context, payload) => {
            return DoGet(
                '/kvr/masterOrders/' + payload.date_index + '/' + payload.route_id,
                'kvr/setMasterOrders',
                [],
                'Запрос мастер-наряда по маршруту за указанный день',
                false,
                payload && payload.force,
                context.state.masterOrdersCache,
                context.state.masterOrdersCacheTimeout
            )
        },

        // очищает кэш
        doClearMasterOrders: (context, payload) => {
            return DoClearCache(
                'kvr/setMasterOrders',
                [],
                payload && payload.force,
                context.state.masterOrdersCache,
                context.state.masterOrdersCacheTimeout
            )
        },

        // изменение объекта
        doEditMasterOrder: (context, payload) => {
            return DoPut(
                '/kvr/masterOrders/' + payload.date_index + '/' + payload.route_id,
                {schedule_variant_id: payload.schedule_variant_id},
                'kvr/editMasterOrder',
                'Изменение варианта расписания по маршруту в мастер-наряде',
                true
            )
        },

        // Очистить мастер-наряд за указанный день
        doClearMasterOrderByDay: (context, payload) => {
            return DoPut(
                '/kvr/clearMasterOrder/' + payload.date_index,
                null,
                'kvr/setMasterOrders',
                'Очистить мастер-наряд за указанный день',
                true
            )
        },

        // Очистить мастер-наряд по маршруту за указанный день
        doClearMasterOrderByDayAndRoute: (context, payload) => {
            return DoPut(
                '/kvr/clearMasterOrder/' + payload.date_index + '/' + payload.route_id,
                null,
                'kvr/editMasterOrder',
                'Очистить мастер-наряд по маршруту за указанный день',
                true
            )
        },

        // Создать мониторинговую таблицу за указанный день
        doCreateMonitorTableByDay: (context, payload) => {
            return DoPut(
                '/kvr/createMonitorTable/' + payload.date_index,
                null,
                null,
                'Создать мониторинговую таблицу за указанный день',
                true
            )
        },

        // Создать мониторинговую таблицу по маршруту за указанный день
        doCreateMonitorTableByDayAndRoute: (context, payload) => {
            return DoPut(
                '/kvr/createMonitorTable/' + payload.date_index + '/' + payload.route_id,
                null,
                null,
                'Создать мониторинговую таблицу по маршруту за указанный день',
                true
            )
        },

        // Получить действующие варианты расписания для мастер-наряда
        getMasterOrderScheduleVariants(context, payload) {
            return DoGet(
                '/kvr/getMasterOrderScheduleVariants/' + payload.date_index + '/' + payload.route_id,
                null,
                [],
                'Получить действующие варианты расписания для мастер-наряда',
                false,
                payload && payload.force,
                context.state.masterOrdersCache,
                context.state.masterOrdersCacheTimeout
            )
        }

    }
}
