import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ГРУПП ТРАНСПОРТНЫХ ПРЕДПРИЯТИЙ

export default {
    state() {
        return {
            // список объектов
            depotGroups: [],
            // время обновления кэша new Date().getTime()
            depotGroupsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            depotGroupsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getDepotGroups(state) {
            return state.depotGroups;
        },
    },
    mutations: {
        // установить новый список объектов
        setDepotGroups(state, payload) {
            state.depotGroups = payload;
            state.depotGroupsCache = new Date().getTime()
        },
        // добавить объект
        addDepotGroup(state, payload) {
            state.depotGroups.push(payload)
        },
        // изменить объект
        editDepotGroup(state, payload) {
            const index = state.depotGroups.findIndex(group => group.depot_group_id == payload.depot_group_id);
            if (index >= 0) {
                state.depotGroups[index] = payload;
            }
        },
        // удалить объект
        delDepotGroup(state, payload) {
            const index = state.depotGroups.findIndex(group => group.depot_group_id == payload.depot_group_id);
            state.depotGroups.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchDepotGroups: (context, payload) => {
            return DoGet(
                '/ref/depotGroups',
                'ref/setDepotGroups',
                [],
                'Запрос групп транспортных предприятий',
                false,
                payload && payload.force,
                context.state.depotGroupsCache,
                context.state.depotGroupsCacheTimeout
            )
        },

        // очищает кэш
        doClearDepotGroups: (context, payload) => {

            return DoClearCache(
                'ref/setDepotGroups',
                [],
                payload && payload.force,
                context.state.depotGroupsCache,
                context.state.depotGroupsCacheTimeout
            )
        },

        // добавление объекта
        doAddDepotGroup: (context, payload) => {
            return DoPost(
                '/ref/depotGroups',
                payload,
                'ref/addDepotGroup',
                'Добавление группы транспортных предприятий'
            )
        },

        // изменение объекта
        doEditDepotGroup: (context, payload) => {
            return DoPut(
                '/ref/depotGroups/'+payload.depot_group_id,
                payload,
                'ref/editDepotGroup',
                'Изменение группы транспортных предприятий'
            )
        },

        // удаление объекта
        doDelDepotGroup: (context, payload) => {
            return DoDelete(
                '/ref/depotGroups/'+payload.depot_group_id,
                'ref/delDepotGroup',
                'Удаление группы транспортных предприятий'
            )
        }
    }
}
