import SensorsNavStore from "@/store/nav/SensorsNavStore";
import SensorGroupsNavStore from "@/store/nav/SensorGroupsNavStore";
import ParamGroupsNavStore from "@/store/nav/ParamGroupsNavStore";
import ParamsNavStore from "@/store/nav/ParamsNavStore";
import RadioProtocolsNavStore from "@/store/nav/RadioProtocolsNavStore";
import RadioModelsNavStore from "@/store/nav/RadioModelsNavStore";
import RadiosNavStore from "@/store/nav/RadiosNavStore";
import RadioStatesNavStore from "@/store/nav/RadioStatesNavStore";
import CurNavNavStore from "@/store/nav/CurNavNavStore";
import HistNavStore from "@/store/nav/HistNavStore";
import DayStatesNavStore from "@/store/nav/DayStatesNavStore";

// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ NAV

export default {
    namespaced: true,
    modules: {
        SensorsNavStore,
        SensorGroupsNavStore,
        ParamGroupsNavStore,
        ParamsNavStore,
        RadioProtocolsNavStore,
        RadioModelsNavStore,
        RadiosNavStore,
        RadioStatesNavStore,
        CurNavNavStore,
        HistNavStore,
        DayStatesNavStore,
    }
}
