import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ РЕЖИМОВ РАБОТЫ ВОДИТЕЛЯ

export default {
    state() {
        return {
            // список объектов
            regimes: [],
            // время обновления кэша new Date().getTime()
            regimesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            regimesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRegimes(state) {
            return state.regimes;
        },
    },
    mutations: {
        // установить новый список объектов
        setRegimes(state, payload) {
            state.regimes = payload;
            state.regimesCache = new Date().getTime()
        },
        // добавить объект
        addRegime(state, payload) {
            state.regimes.push(payload)
        },
        // изменить объект
        editRegime(state, payload) {
            const index = state.regimes.findIndex(regime => regime.regime_id == payload.regime_id);
            if (index >= 0) {
                state.regimes[index] = payload;
            }
        },
        // удалить объект
        delRegime(state, payload) {
            const index = state.regimes.findIndex(regime => regime.regime_id == payload.regime_id);
            state.regimes.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchRegimes: (context, payload) => {
            return DoGet(
                '/rmt/regimes',
                'rmt/setRegimes',
                [],
                'Запрос режимов работы водителя',
                false,
                payload && payload.force,
                context.state.regimesCache,
                context.state.regimesCacheTimeout
            )
        },

        // очищает кэш
        doClearRegimes: (context, payload) => {
            return DoClearCache(
                'rmt/setRegimes',
                [],
                payload && payload.force,
                context.state.regimesCache,
                context.state.regimesCacheTimeout
            )
        },

        // добавление объекта
        doAddRegime: (context, payload) => {
            return DoPost(
                '/rmt/regimes',
                payload,
                'rmt/addRegime',
                'Добавление режима работы водителя'
            )
        },

        // изменение объекта
        doEditRegime: (context, payload) => {
            return DoPut(
                '/rmt/regimes/'+payload.regime_id,
                payload,
                'rmt/editRegime',
                'Изменение режима работы водителя'
            )
        },

        // удаление объекта
        doDelRegime: (context, payload) => {
            return DoDelete(
                '/rmt/regimes/'+payload.regime_id,
                'rmt/delRegime',
                'Удаление режима работы водителя'
            )
        }
    }
}
