<template>
  <!--  ТАБЛИЦА ВИДОВ ТРАНСПОРТА С ПОМЕТКОЙ ДЛЯ КАЖДОЙ ГРУППЫ-->
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-group-transport-type-links"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
export default {
  components: {EditTablePanel},
  props: ['id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__checked",
          caption: "Вкл",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.__checked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "transport_type_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
          show: false
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      const types = this.$store.getters["ref/getTransportTypes"];
      const links = this.$store.getters["ref/getTransportTypeGroupLinks"];
      return types.map(type => {

        type.__id = type.transport_type_id;
        type.__checked = links.some(link => link.transport_type_group_id == this.id && link.transport_type_id == type.transport_type_id);

        return type;
      });
    },
  },
};
</script>
