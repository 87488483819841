import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПНЫХ ДЛЯ ФОРМИРОВАНИЯ НАРЯДА ТРАНСПОРТНЫХ ПРЕДПРИЯТИЙ

export default {
    state() {
        return {
            // список объектов
            orderDepots: [],
            // время обновления кэша new Date().getTime()
            orderDepotsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            orderDepotsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getOrderDepots(state) {
            return state.orderDepots;
        },
    },
    mutations: {

        // установить новый список объектов
        setOrderDepots(state, payload) {
            state.orderDepots = payload;
            state.orderDepotsCache = new Date().getTime()
        },
    },
    actions: {

        // запрос списка объектов
        doFetchOrderDepots: (context, payload) => {
            return DoGet(
                '/kvr/getOrderDepots',
                'kvr/setOrderDepots',
                [],
                'Получить список доступных для формирования наряда транспортных предприятий',
                false,
                payload && payload.force,
                context.state.orderDepotsCache,
                context.state.orderDepotsCacheTimeout
            )
        },

        // очищает кэш
        doClearOrderDepots: (context, payload) => {
            return DoClearCache(
                'kvr/setOrderDepots',
                [],
                payload && payload.force,
                context.state.orderDepotsCache,
                context.state.orderDepotsCacheTimeout
            )
        },
    }
}
