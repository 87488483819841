import KvrTitlePage from '../pages/kvr/KvrTitlePage.vue';
import ContractsPage from "@/pages/kvr/ContractsPage";
import ContractRouteLinksPage from "@/pages/kvr/contracts/СontractRouteLinksPage";
import ContractDocsPage from "@/pages/kvr/contracts/ContractDocsPage";
import ContractDocFilesPage from "@/pages/kvr/contracts/contract_docs/ContractDocFilesPage";
import MasterOrdersPage from "@/pages/kvr/MasterOrdersPage";
import RoutesPage from "@/pages/kvr/RoutesPage";
import WorkTasksPage from "@/pages/kvr/WorkTasksPage";
import MapPage from "@/pages/kvr/MapPage";
import ReportsPage from "@/pages/kvr/ReportsPage";
import InformationByTsPage from "@/pages/kvr/work_tasks/InformationByTsPage";
import InformationByRoutePage from "@/pages/kvr/work_tasks/InformationByRoutePage";
import MapHistoryPage from "@/pages/kvr/MapHistoryPage";
import ManualOrdersPage from "@/pages/kvr/ManualOrdersPage";
import KvrDayStatesPage from "@/pages/kvr/KvrDayStatesPage";
import MainPage from "@/pages/main/MainPage";
import KvrExpertReportsPage from "@/pages/kvr/KvrExpertReportsPage";
import AnalysisPage from "@/pages/kvr/AnalysisPage";
import CommandLogPage from "@/pages/kvr/CommandLogPage";

export default {
    path: '/kvr', 
    component: MainPage,
    children: [
        {
            path: 'kvrExpertReports/:id(\\d+)?',
            component: KvrExpertReportsPage,
            props: true,
        },
        {
            path: 'kvrExpertReports',
            component: KvrExpertReportsPage,
        },
        {
            path: 'kvrExpertAnalysis/:op(show)/:plan_trip_id(\\d+)?',
            component: AnalysisPage,
            props: true,
        },
        {
            path: 'kvrExpertCommandLog',
            component: CommandLogPage,
        },
        {
            path: 'kvrExpertAnalysis',
            component: AnalysisPage,
        },



        {
            path: 'kvrDayStates',
            component: KvrDayStatesPage,
            props: true,
        },
        {
            path: 'reports/:id(\\d+)?',
            component: ReportsPage,
            props: true,
        },
        {
            path: 'reports',
            component: ReportsPage,
            props: true,
        },
        {
            path: 'history/:date_index(\\d+)?/:transport_id(\\d+)?/:route_id(\\d+)?/:fact_trip_time_index_begin(\\d+)?/:fact_trip_time_index_end(\\d+)?',
            component: MapHistoryPage,
            props: true
        },
        {
            path: 'history/:op(show)/:id(\\d+)?',
            component: MapHistoryPage,
            props: true
        },
        {
            path: 'history',
            component: MapHistoryPage
        },
        {
            path: 'map/:op(show)/:id(\\d+)?',
            component: MapPage,
            props: true
        },
        {
            path: 'map',
            component: MapPage
        },
        {
            path: 'workTasks/:date_index(\\d+)?/:route_id(\\d+)?/informationByRoute/:op(show)/:plan_trip_id(\\d+)?',
            component: InformationByRoutePage,
            props: true
        },
        {
            path: 'workTasks/:date_index(\\d+)?/:route_id(\\d+)?/informationByRoute',
            component: InformationByRoutePage,
            props: true
        },
        {
            path: 'workTasks/:date_index(\\d+)?/:transport_id(\\d+)?/informationByTs/:op(show)/:fact_trip_id(\\d+)?',
            component: InformationByTsPage,
            props: true
        },
        {
            path: 'workTasks/:date_index(\\d+)?/:transport_id(\\d+)?/informationByTs',
            component: InformationByTsPage,
            props: true
        },
        {
            path: 'workTasks',
            component: WorkTasksPage
        },
        {
            path: 'routes',
            component: RoutesPage
        },
        {
            path: 'manualOrders/:op(split)/:manual_order_id(\\d+)?',
            component: ManualOrdersPage,
            props: true,
        },
        {
            path: 'manualOrders/:op(clear|apply)/:date_index(\\d+)?/:plan_depot_id(\\d+)?/:route_id(\\d+)?/:manual_order_id(\\d+)?',
            component: ManualOrdersPage,
            props: true,
        },
        {
            path: 'manualOrders',
            component: ManualOrdersPage
        },
        {
            path: 'masterOrders/:op(clear|edit|create)/:date_index(\\d+)?/:route_id(\\d+)?',
            component: MasterOrdersPage,
            props: true,
        },
        {
            path: 'masterOrders',
            component: MasterOrdersPage
        },
        {  path: 'contracts/:contract_id(\\d+)?/contractDocs/:contract_doc_id(\\d+)?/contractDocFiles/:op(show|add|del)/:id(\\d+)?',
            component: ContractDocFilesPage,
            props: true,
        },
        {  path: 'contracts/:contract_id(\\d+)?/contractDocs/:contract_doc_id(\\d+)?/contractDocFiles',
            component: ContractDocFilesPage,
            props: true,
        },
        {
            path: 'contracts/:contract_id(\\d+)?/contractDocs/:op(show|add|del|edit)/:id(\\d+)?',
            component: ContractDocsPage,
            props: true,
        },
        {
            path: 'contracts/:contract_id(\\d+)?/contractDocs',
            component: ContractDocsPage,
            props: true,
        },
        {
            path: 'contracts/:contract_id(\\d+)?/contractRouteLinks',
            component: ContractRouteLinksPage,
            props: true,
        },
        {
            path: 'contracts/:op(show|add|del|edit)/:id(\\d+)?',
            component: ContractsPage,
            props: true,
        },
        {
            path: 'contracts',
            component: ContractsPage
        },
        {
            path: '',
            component: KvrTitlePage
        },
        {
            path: ':pathMatch(.*)*',
            component: KvrTitlePage
        }                
    ]
}
