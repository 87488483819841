import store from './Store';
import {
    deleteRequest,
    getFileRequest, getFileRequestForShowing,
    getRawRequest,
    getRequest,
    postFileRequest,
    postRequest,
    putRequest
} from './Requests';

// отправка post-запроса
export async function DoPost(
    // url для отправки (относительно корня)
    url,
    // передаваемые данные
    json,
    // мьютейшен
    mutation,
    // заголовок сообщения
    caption,
    // показывать сообщение об успешной операции
    showOk = true
) {
    try {
        // базовый адрес
        const baseUrl = store.getters['settings/getBaseUrl'];

        // отправляем запрос
        const response = await postRequest (
            baseUrl + url,
            json,
            store.getters.getTokenId
        );

        // парсируем JSON
        if (typeof(response.data) === 'string') {
            try {
                response.data = JSON.parse(response.data);
            }
            catch {
                throw new Error('Ошибка парсирования JSON-документа');
            }
        }

        // проверяем ответ на корректность
        if (response.data && typeof(response.data.result) !== 'undefined') {

            // запоминаем новую строку
            if (mutation) {
                store.commit(mutation, response.data.result);
            }

            // подтверждаем
            if (showOk) {
                await store.dispatch('doAddMessage', {
                    caption,
                    message: 'Команда успешно выполнена',
                    type: 'ok'
                }, {root: true})
            }

            // фиксируем успех
            return response.data.result;
        }
        else {
            // ругаемся
            await store.dispatch('doAddMessage', {
                caption,
                message: 'Неправильная структура ответа от сервера',
                type: 'error'
            },{root: true});

            // выходим с ошибкой
            return false;
        }
    }
    catch(e) {
        // ругаемся
        await store.dispatch('doAddMessage', {
            caption,
            message: e.message + ' (tid = ' + e.tid + ')',
            type: 'error'
        },{root: true});

        // выходим с ошибкой
        return false;
    }
}

// отправка post-запроса
export async function DoPostFile(
    // url для отправки (относительно корня)
    url,
    // передаваемый файл
    file,
    // мьютейшен
    mutation,
    // заголовок сообщения
    caption,
    // показывать сообщение об успешной операции
    showOk = true
) {
    try {
        // базовый адрес
        const baseUrl = store.getters['settings/getBaseUrl'];

        // отправляем запрос
        const response = await postFileRequest (
            baseUrl + url,
            [file],
            store.getters.getTokenId
        );

        // проверяем ответ на корректность
        if (response.data && response.data.result) {

            // запоминаем новую строку
            if (mutation) {
                store.commit(mutation, response.data.result[0]);
            }

            // подтверждаем
            if (showOk) {
                await store.dispatch('doAddMessage', {
                    caption,
                    message: 'Команда успешно выполнена',
                    type: 'ok'
                }, {root: true})
            }

            // фиксируем успех
            return response.data.result;
        }
        else {
            // ругаемся
            await store.dispatch('doAddMessage', {
                caption,
                message: 'Неправильная структура ответа от сервера',
                type: 'error'
            },{root: true});

            // выходим с ошибкой
            return false;
        }
    }
    catch(e) {
        // ругаемся
        await store.dispatch('doAddMessage', {
            caption,
            message: e.message + ' (tid = ' + e.tid + ')',
            type: 'error'
        },{root: true});

        // выходим с ошибкой
        return false;
    }
}

// отправка put-запроса
export async function DoPut(
    // url для отправки (относительно корня)
    url,
    // передаваемые данные
    json,
    // мьютейшен
    mutation,
    // заголовок сообщения
    caption,
    // показывать сообщение об успешной операции
    showOk = true
) {
    try {
        // базовый адрес
        const baseUrl = store.getters['settings/getBaseUrl'];

        // отправляем запрос
        const response = await putRequest (
            baseUrl + url,
            json,
            store.getters.getTokenId
        );
        // парсируем JSON
        if (typeof(response.data) === 'string') {
            try {
                response.data = JSON.parse(response.data);
            }
            catch {
                throw new Error('Ошибка парсирования JSON-документа');
            }
        }

        // проверяем ответ на корректность
        if (response.data && typeof(response.data.result) !== 'undefined') {

            // запоминаем измененную строку
            if (mutation) {
                store.commit(mutation, response.data.result);
            }

            // подтверждаем
            if (showOk) {
                await store.dispatch('doAddMessage', {
                    caption,
                    message: 'Команда успешно выполнена',
                    type: 'ok'
                }, {root: true})
            }

            // фиксируем успех
            return response.data.result;
        }
        else {
            // ругаемся
            await store.dispatch('doAddMessage', {
                caption,
                message: 'Неправильная структура ответа от сервера',
                type: 'error'
            },{root: true});

            // выходим с ошибкой
            return false;
        }
    }
    catch(e) {
        // ругаемся
        await store.dispatch('doAddMessage', {
            caption,
            message: e.message + ' (tid = ' + e.tid + ')',
            type: 'error'
        },{root: true});

        // выходим с ошибкой
        return false;
    }
}

// отправка delete-запроса
export async function DoDelete(
    // url для отправки (относительно корня)
    url,
    // мьютейшен
    mutation,
    // заголовок сообщения
    caption,
    // показывать сообщение об успешной операции
    showOk = true
) {
    try {
        // базовый адрес
        const baseUrl = store.getters['settings/getBaseUrl'];

        // отправляем запрос
        const response = await deleteRequest (
            baseUrl + url,
            store.getters.getTokenId
        );

        // парсируем JSON
        if (typeof(response.data) === 'string') {
            try {
                response.data = JSON.parse(response.data);
            }
            catch {
                throw new Error('Ошибка парсирования JSON-документа');
            }
        }

        // проверяем ответ на корректность
        if (response.data && typeof(response.data.result) !== 'undefined') {

            // запоминаем измененную строку
            if (mutation) {
                store.commit(mutation, response.data.result);
            }

            // подтверждаем
            if (showOk) {
                await store.dispatch('doAddMessage', {
                    caption,
                    message: 'Команда успешно выполнена',
                    type: 'ok'
                }, {root: true})
            }

            // фиксируем успех
            return response.data.result;
        }
        else {
            // ругаемся
            await store.dispatch('doAddMessage', {
                caption,
                message: 'Неправильная структура ответа от сервера',
                type: 'error'
            },{root: true});

            // выходим с ошибкой
            return false;
        }
    }
    catch(e) {
        // ругаемся
        await store.dispatch('doAddMessage', {
            caption,
            message: e.message + ' (tid = ' + e.tid + ')',
            type: 'error'
        },{ root: true });

        // выходим с ошибкой
        return false;
    }
}

// отправка get-запроса
export async function DoGet(
    // url для отправки (относительно корня)
    url,
    // мьютейшен
    mutation,
    // начальное значение
    initValue,
    // заголовок сообщения
    caption,
    // показывать сообщение об успешной операции
    showOk = true,
    // принудительный запрос (без кэша)
    force = false,
    // время обновления кэша (мс с начала эпохи)
    cache = null,
    // время жизни кэша (мс)
    cacheTimeOut =  null,
) {
    try {
        // базовый адрес
        const baseUrl = store.getters['settings/getBaseUrl'];

        // кэш есть и нет принуждения
        if (!force && cache) {
            // timeout отсуствует
            if (!cacheTimeOut) {
                return store.getters[String(mutation).replace('/set', '/get')];
            }
            // timeout еще не закончился
            if (new Date().getTime() <= cache + cacheTimeOut) {
                return store.getters[String(mutation).replace('/set', '/get')];
            }
        }

        // очищаем значение
        if (mutation) {
            store.commit(mutation, initValue);
        }

        // отправляем запрос
        const response = await getRequest (
            baseUrl + url,
            store.getters.getTokenId
        );

        // проверяем ответ на корректность
        if (response.data && typeof(response.data.result) !== 'undefined') {

            // запоминаем список
            if (mutation) {
                // если мы ждем массив, но пришел объект - превращаем его в массив
                if (Array.isArray(initValue) && !Array.isArray(response.data.result)) {
                    store.commit(mutation, [response.data.result]);
                }else
                    store.commit(mutation, response.data.result);
            }

            // подтверждаем
            if (showOk) {
                await store.dispatch('doAddMessage', {
                    caption,
                    message: 'Данные успешно получены',
                    type: 'ok'
                }, {root: true})
            }

            // фиксируем успех
            return response.data.result;
        }
        else {
            // ругаемся
            await store.dispatch('doAddMessage', {
                caption,
                message: 'Неправильная структура ответа от сервера',
                type: 'error'
            },{root: true});

            // выходим с ошибкой
            return false;
        }
    }
    catch(e) {
        // ругаемся
        await store.dispatch('doAddMessage', {
            caption,
            message: e.message + ' (tid = ' + e.tid + ')',
            type: 'error'
        },{root: true});

        // выходим с ошибкой
        return false;
    }
}

// отправка get-запроса
export async function DoGetFile(
    // url для отправки (относительно корня)
    url,
    // мьютейшен
    mutation,
    // заголовок сообщения
    caption,
    // показывать сообщение об успешной операции
    showOk = true,
    //имя запрашиваемого файла
    fileName = '',
) {
    try {
        // базовый адрес
        const baseUrl = store.getters['settings/getBaseUrl'];

        // отправляем запрос
       await getFileRequest (
            baseUrl + url,
            store.getters.getTokenId,
            fileName
        );

        // подтверждаем
        if (showOk) {
            await store.dispatch('doAddMessage', {
                caption,
                message: 'Данные успешно получены',
                type: 'ok'
            }, {root: true})
        }

        // выходим с успехом
        return true;
    }
    catch(e) {
        // ругаемся
        await store.dispatch('doAddMessage', {
            caption,
            message: e.message  + ' (tid = ' + e.tid + ')',
            type: 'error'
        },{root: true});

        // выходим с ошибкой
        return false;
    }
}


// отправка get-запроса
export async function DoGetFileForShowing(
    // url для отправки (относительно корня)
    url,
    // мьютейшен
    mutation,
    // заголовок сообщения
    caption,
    // показывать сообщение об успешной операции
    showOk = true,
    //имя запрашиваемого файла
    fileName = '',
    // формат запрашиваемого файла
    fmt = '',
) {
    try {
        // базовый адрес
        const baseUrl = store.getters['settings/getBaseUrl'];

        // отправляем запрос
        await getFileRequestForShowing (
            baseUrl + url,
            store.getters.getTokenId,
            fileName,
            fmt
        );

        // подтверждаем
        if (showOk) {
            await store.dispatch('doAddMessage', {
                caption,
                message: 'Данные успешно получены',
                type: 'ok'
            }, {root: true})
        }

        // выходим с успехом
        return true;
    }
    catch(e) {
        // ругаемся
        await store.dispatch('doAddMessage', {
            caption,
            message: e.message  + ' (tid = ' + e.tid + ')',
            type: 'error'
        },{root: true});

        // выходим с ошибкой
        return false;
    }
}


// очистка кэша
export async function DoClearCache(
    // мьютейшен
    mutation,
    // начальное значение
    initValue,
    // принудительная очистка кэша
    force = false,
    // время обновления кэша (мс с начала эпохи)
    cache = null,
    // время жизни кэша (мс)
    cacheTimeOut =  null,
) {
    // условие очистки кэша
    if (force || (cache && cacheTimeOut && new Date().getTime() > cache + cacheTimeOut)) {
        store.commit(mutation, initValue);
        return true
    }
    return false;
}

// отправка get-запроса на получение чистого json
export async function DoGetRaw(
    // полный путь
    fullUrl,
    // мьютейшен
    mutation,
    // заголовок сообщения
    caption,
    // показывать сообщение об успешной операции
    showOk = true,
    // токен (можно не передавать)
    tid = null,
) {
    try {

        // отправляем запрос
        const response = await getRawRequest (
            fullUrl,
            tid
        );

        // парсируем JSON
        if (typeof(response.data) === 'string') {
            try {
                response.data = JSON.parse(response.data);
            }
            catch {
                throw new Error('Ошибка парсирования JSON-документа');
            }
        }

        // проверяем ответ на корректность
        if (response.data) {

            // запоминаем список
            if (mutation) {
                store.commit(mutation, response.data);
            }

            // подтверждаем
            if (showOk) {
                await store.dispatch('doAddMessage', {
                    caption,
                    message: 'Данные успешно получены',
                    type: 'ok'
                }, {root: true})
            }

            // фиксируем успех
            return response.data;
        }
        else {
            // ругаемся
            await store.dispatch('doAddMessage', {
                caption,
                message: 'Неправильная структура ответа от сервера',
                type: 'error'
            },{root: true});

            // выходим с ошибкой
            return false;
        }
    }
    catch(e) {
        // ругаемся
        await store.dispatch('doAddMessage', {
            caption,
            message: e.message + ' (tid = ' + e.tid + ')',
            type: 'error'
        },{root: true});

        // выходим с ошибкой
        return false;
    }
}

// мутация на добавление данных в массив
export function addMutation(arr, field, value) {
    if (!value) return null;

    arr.push(value)
    return value;
}

// мутация на изменение данных в массиве
export function editMutation(arr, field, value) {
    if (!value) return null;

    const index = arr.findIndex(item => item[field] == value[field]);
    if (index >= 0) {
        arr[index] = value;
        return value;
    }
    return null;
}

// мутация на удаление данных из массива
export function delMutation(arr, field, value) {
    if (!value) return null;

    const index = arr.findIndex(item => item[field] == value[field]);
    if (index>=0) {
        arr.splice(index, 1);
    }
    return null;
}
