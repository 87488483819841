import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОКУМЕНТОВ ОСТАНОВОЧНЫХ ПАВИЛИОНОВ

export default {
    actions: {
        // запрос объекта
        doFetchStopPavilionDoc: (context, payload) => {
            return DoGet(
                '/multipass/stopPavilionDocs/' + payload.stop_pavilion_doc_id,
                null,
                {},
                'Запрос документа остановочного павилиона',
                false,
                true,
                null,
                null
            )
        },

        // запрос списка объектов
        doFetchStopPavilionDocs: (context, payload) => {
            return DoGet(
                '/multipass/stopPavilionDocs?stop_pavilion_id=' + payload.stop_pavilion_id,
                null,
                [],
                'Запрос документов остановочного павилиона',
                false,
                true,
                null,
                null
            )
        },

        // добавление объекта
        doAddStopPavilionDoc: (context, payload) => {
            return DoPost(
                '/multipass/stopPavilionDocs',
                payload,
                null,
                'Добавление документа остановочного павилиона'
            )
        },

        // изменение объекта
        doEditStopPavilionDoc: (context, payload) => {
            return DoPut(
                '/multipass/stopPavilionDocs/'+ payload.stop_pavilion_doc_id,
                payload,
                null,
                'Изменение документа остановочного павилиона'
            )
        },

        // удаление объекта
        doDelStopPavilionDoc: (context, payload) => {
            return DoDelete(
                '/multipass/stopPavilionDocs/'+ payload.stop_pavilion_doc_id,
                null,
                'Удаление документа остановочного павилиона'
            )
        }
    }
}