import store from './Store';

// вернуть маршрут

export function getRouteExt(route_id) {
    return store.getters["kvr/getRoutesExt"].find(route => route.route_id == route_id);
}

// вернуть перевозчика для модуля КВР
export function getAgency(org_id) {
    return store.getters["kvr/getAgencies"].find(org => org.org_id == org_id);
}

// вернуть транспортное предприятие
export function getDepot(depot_id) {
    return store.getters["kvr/getDepots"].find(depot => depot.depot_id == depot_id);
}

// вернуть маршрут для модуля КВР
export function getRoute(route_id) {
    return store.getters["kvr/getRoutes"].find(route => route.route_id == route_id);
}

// вернуть танспортное средство на маршруте за указанный день
export function getWorkTransportOnDay(transport_id) {
    return store.getters["kvr/getWorkTransportOnDay"].find(value => value.transport_id == transport_id);
}

// вернуть доступное для формирования наряда транспортное предприятие
export function getOrderDepot(depot_id) {
    return store.getters["kvr/getOrderDepots"].find(depot => depot.depot_id == depot_id);
}

// вернуть доступного для формирования наряда перевозчика
export function getOrderAgency(org_id) {
    return store.getters["kvr/getOrderAgencies"].find(org => org.org_id == org_id);
}

// вернуть доступный для формирования наряда маршрут
export function getOrderRoute(route_id) {
    return store.getters["kvr/getOrderRoutes"].find(route => route.route_id == route_id);
}

// вернуть наряд
export function getManualOrder(manual_order_id) {
    return store.getters["kvr/getManualOrders"].find(mo => mo.manual_order_id == manual_order_id);
}

// вернуть доступное для формирования наряда ТС
export function getOrderVehicle(transport_id) {
    return store.getters["kvr/getOrderVehicles"].find(ts => ts.transport_id == transport_id);
}

// вернуть доступного для формирования наряда водителя
export function getOrderDriver(driver_id) {
    return store.getters["kvr/getOrderDrivers"].find(driver => driver.driver_id == driver_id);
}

// вернуть причину незачета рейса
export function getMarkReasons(mark_reason_id) {
    return store.getters["kvr/getMarkReasons"].find(driver => driver.mark_reason_id == mark_reason_id);
}


