import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ТИПОВ ДОКУМЕНТОВ

export default {
    state() {
        return {
            // список объектов
            documentTypes: [],
            // время обновления кэша new Date().getTime()
            documentTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            documentTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getDocumentTypes(state) {
            return state.documentTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setDocumentTypes(state, payload) {
            state.documentTypes = payload;
            state.documentTypesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchDocumentTypes: (context, payload) => {
            return DoGet(
                '/doc/documentTypes',
                'doc/setDocumentTypes',
                [],
                'Запрос типов документов',
                false,
                payload && payload.force,
                context.state.documentTypesCache,
                context.state.documentTypesCacheTimeout
            )
        },

        // очищает кэш
        doClearDocumentTypes: (context, payload) => {
            return DoClearCache(
                'multipass/setDocumentTypes',
                [],
                payload && payload.force,
                context.state.documentTypesCache,
                context.state.documentTypesCacheTimeout
            )
        },
    }
}