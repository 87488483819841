<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header class="page-header">
        <span><router-link to="/rmt">Расписания</router-link></span>
        <span> / </span>
        <span><router-link to="/rmt/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to=routeVariantsLink>Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleVariantsLink>Варианты расписаний {{
            routeVariantPageHeader
          }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleGraphSetLink>Наборы графиков {{ scheduleVariantPageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleGraphLink>Графики движения {{ scheduleGraphSetPageHeader }}</router-link></span>
        <span> / </span>
        <span>Распорядок графика {{ scheduleGraphPageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <align-panel align="left" width="55%" :gap="3">
          <align-container>
            <!-- Форма с кнопками над левой таблицей -->
            <align-panel align="top">
              <crud-form
                  :buttonsExist="leftPanelButtonsExist"
                  :buttonsEnabled="leftPanelButtonsEnabled"
                  :filterExists="false"
                  @onRefresh="onTableRefresh"
              >

                <!-- Кнопка Добавить-->
                <template #preLeftCustomButton>
                  <dropdown>
                    <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-success" title="Добавить">
                      <i class="fas fa-plus"></i>
                    </button-toggler>
                    <dropdown-menu ref="collapse">
                      <dropdown-item
                          v-for="event in graphEvents"
                          :key="event.schedule_event_id"
                          @click="onTableAdd(event)">
                        {{ event.long_name }}
                      </dropdown-item>
                    </dropdown-menu>
                  </dropdown>
                </template>

                <!-- Кнопка Вставить-->
                <template #preLeftCustomButton2>
                  <dropdown>
                    <button-toggler target-ref="collapse1" class="btn dropdown-toggle btn-success" title="Вставить"
                                    :disabled="!selectedRow">
                      <i class="fas fa-file-import"></i>
                    </button-toggler>
                    <dropdown-menu ref="collapse1">
                      <dropdown-item
                          v-for="event in graphEvents"
                          :key="event.schedule_event_id"
                          @click="onTableInsert(event)">
                        {{ event.long_name }}
                      </dropdown-item>
                    </dropdown-menu>
                  </dropdown>
                </template>

                <!-- Кнопка Изменить-->
                <template #leftCustomButton>
                  <dropdown>
                    <button @click="onTableEdit" :disabled="!selectedRow" class="btn btn-warning" title="Изменить">
                      <i class="fas fa-pen"></i>
                    </button>
                  </dropdown>
                </template>

                <!-- Кнопка Удалить-->
                <template #leftCustomButton2>
                  <dropdown>
                    <button @click="onTableDelete" :disabled="!selectedRow" class="btn btn-danger" title="Удалить">
                      <i class="fas fa-trash"></i>
                    </button>
                  </dropdown>
                </template>

                <!-- Кнопка Команда-->
                <template #leftCustomButton3>
                  <dropdown>
                    <button-toggler target-ref="collapse3" class="btn dropdown-toggle btn-primary">
                      Команда
                    </button-toggler>
                    <dropdown-menu ref="collapse3">
                      <dropdown-item @click="onTableCalc">Пересчет графика</dropdown-item>
                      <dropdown-divider></dropdown-divider>
                      <dropdown-item @click="onTableCheck">Проверка графика</dropdown-item>
                    </dropdown-menu>
                  </dropdown>
                </template>

                <!-- Кнопка связь-->
                <template #leftCustomButton4>
                  <dropdown>
                    <button-toggler target-ref="collapse4" class="btn dropdown-toggle btn-primary">
                      Связь
                    </button-toggler>
                    <dropdown-menu ref="collapse4">
                      <dropdown-item router-link :to="scheduleTimeTablesLink">Расписание</dropdown-item>
                    </dropdown-menu>
                  </dropdown>
                </template>

                <!-- Переключатель графиков -->
                <template #leftCustomButton5>
                  <input-select
                      class="form-control"
                      v-model="selectedScheduleGraphId"
                      :itemList="scheduleGraphSelectedInputArr"
                      caption="Выберите график..."
                      @change="changeScheduleGraph"
                  >
                  </input-select>
                </template>

              </crud-form>
            </align-panel>

            <!-- Таблица шагов распорядка (слева)  -->
            <align-panel align="all">
              <schedule-graph-steps-edit-table
                  :isLoading="isLoading"
                  :scheduleGraphSteps="scheduleGraphSteps"
                  :selectedRow="selectedRow"
                  :tripTypes="extTripTypes"
                  @onRowSelect="onRowSelect"
                  @onRowDblClick="onTableShow"
              ></schedule-graph-steps-edit-table>
            </align-panel>
          </align-container>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <!-- Форма с кнопками над правой таблицей-->
            <align-panel align="top">
              <crud-form
                  :buttonsExist="rightPanelButtonsExist"
                  :buttonsEnabled="rightPanelButtonsEnabled"
                  :filterExists="false"
                  @onCancel="onTableCancel"
                  @onSave="onTableSave"
                  @onEdit="onIntTripTypesEditMode"
                  @onRefresh="onRowSelect(selectedRow)"
              >

                <!-- Режимы редактирования карточки рейса -->
                <template #leftCustomButton v-if="!isEditMode && showModeList.length > 0">
                  <input-button-option
                      :itemList="showModeList"
                      v-model="cardEditMode"
                      unactiveClass="btn-warning"
                      required
                  >
                  </input-button-option>
                </template>

                <!-- Галочка автоматического пересчета -->
                <template #rightCustomButton>
                  <input-checkbox
                      id="isAutoRecalcEnable"
                      v-model="isAutoRecalcEnable"
                      style="margin-top: 0.3rem"
                  ><label>Авт. пересчет</label>
                  </input-checkbox>
                </template>

              </crud-form>
            </align-panel>

            <!-- Правая таблица (карточка шага / список интервальных типов рейсов)  -->
            <align-panel align="all">
              <schedule-graph-step-card-edit-table
                  v-if="isCurStepIsTrip"
                  :rowList="cardRows"
                  :cardEditMode="cardEditMode"
              ></schedule-graph-step-card-edit-table>
              <trip-types-count-edit-table
                  v-if="isCurStepIsIntTrips"
                  :rowList="intTripRows"
                  :isEditMode="isEditMode"
              ></trip-types-count-edit-table>
            </align-panel>

          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

  <!-- Диалог для типов ивента 1 и 2 -->
  <schedule-graph-step-trip-dialog
      v-if="isTripDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
      :item="dialogRow"
      :op="op"
      :route="route"
      :tripTypes="tripTypes"
      :schedule_variant_id="schedule_variant_id"
  >
  </schedule-graph-step-trip-dialog>

  <!-- Диалог для типов ивента 3 и 4 -->
  <schedule-graph-step-time-dialog
      v-if="isTimeDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
      :item="dialogRow"
      :op="op"
  >
  </schedule-graph-step-time-dialog>

  <!-- Диалог для типа ивента 5 -->
  <schedule-graph-step-time-and-length-dialog
      v-if="isTimeAndLengthDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
      :item="dialogRow"
      :op="op"
  >
  </schedule-graph-step-time-and-length-dialog>

  <!-- Подтверждение удаления для трассы -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить событие "{{ curEventName }}"?
  </confirm-message-box>

  <!-- Диалог со списком ошибок -->
  <errors-dialog
      v-if="isErrorsDialogVisible"
      :rowList="scheduleGraphErrors"
      @onClose="scheduleGraphErrors=[]"
      caption="Ошибки графика"
  >
  </errors-dialog>

  <!--    Окно с ошибкой   -->
  <error-message-box
      v-if="errorMessage"
      @onClose="errorMessage=''"
  > {{ errorMessage }}
  </error-message-box>

</template>

<script>

import {getStop} from "@/store/MultipassHelpers";
import {getScheduleEvent} from "@/store/RmtHelpers";
import ScheduleGraphStepsEditTable from "@/components/rmt/schedule_graph_steps/ScheduleGraphStepsEditTable";
import ScheduleGraphStepCardEditTable from "@/components/rmt/schedule_graph_steps/ScheduleGraphStepCardEditTable";
import ScheduleGraphStepTimeDialog from "@/components/rmt/schedule_graph_steps/ScheduleGraphStepTimeDialog";
import ScheduleGraphStepTripDialog from "@/components/rmt/schedule_graph_steps/ScheduleGraphStepTripDialog";
import TripTypesCountEditTable from "@/components/rmt/schedule_graph_steps/TripTypesCountEditTable";
import {getLength, quickSort, scheduleGraphSortFn, signSortFn} from "@/lib";
import PageMixin from "@/pages/share/PageMixin";
import ScheduleGraphStepTimeAndLengthDialog
  from "@/components/rmt/schedule_graph_steps/ScheduleGraphStepTimeAndLengthDialog";

export default {
  mixins: [PageMixin],

  components: {
    ScheduleGraphStepTimeAndLengthDialog,
    ScheduleGraphStepCardEditTable,
    ScheduleGraphStepsEditTable,
    ScheduleGraphStepTimeDialog,
    ScheduleGraphStepTripDialog,
    TripTypesCountEditTable
  },
  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // код события (передается через роутер)
    "event_id",
    // порядковый номер события (передается через роутер)
    "order_num",
    // идентификатор типа рейсов (передается через роутер)
    "trip_type_id",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
    // идентификатор варианта расписания
    "schedule_variant_id",
    // идентификатор набора графиков
    "schedule_graph_set_id",
    // идентификатор графика
    "schedule_graph_id",
  ],

  data() {
    return {
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // вариант расписания
      scheduleVariant: {},
      // набор графиков
      scheduleGraphSet: {},
      // график
      scheduleGraph: {},
      // шаги графика
      scheduleGraphSteps: [],
      // виды рейсов
      tripTypes: [],
      //расширенный список видов рейсов, включает в себя виды рейса с переключениями
      extTripTypes: [],
      // карточка степа для рейсов (расчетная для таблицы)
      cardRows: [],
      // автоматический пересчет
      isAutoRecalcEnable: false,
      // режим редактирования
      isEditMode: false,
      // режим редактирования карточки
      cardEditMode: null,
      // список ошибок графика
      scheduleGraphErrors: [],
      // список интервальных рейсов (расчетная для таблицы)
      intTripRows: [],
      // полученные интервальные типы рейсов
      scheduleGraphStepTripTypes: [],
      // // карточка типа рейса
      // tripTypeCard: [],
      // карточка шага
      scheduleGraphStepCard: [],
      // текст ошибки
      errorMessage: '',
      // выбранный график движения
      selectedScheduleGraphId: null,
      // массив всех scheduleGraphs
      scheduleGraphsArr: [],
    }
  },

  computed: {

    scheduleGraphSelectedInputArr() {
      return this.scheduleGraphsArr.map(value => {
        return {
          title: value.graph + ' - ' + value.shift,
          id: value.schedule_graph_id,
        }
      })
    },

    // список событий
    graphEvents() {
      return this.$store.getters['rmt/getScheduleEvents'];
    },

    // возвращает текущее событие
    curEvent() {
      return this.selectedRow ? getScheduleEvent(this.selectedRow.schedule_event_id) : null;
    },

    // возвращает код типа текущего события
    curEventTypeId() {
      const curEvent = this.curEvent;
      return curEvent ? curEvent.schedule_event_type_id : 0;
    },

    // возвращает имя текущего события
    curEventName() {
      const curEvent = this.curEvent;
      return curEvent ? curEvent.long_name : '';
    },

    // проверяет, что текущий шаг - это рейс
    isCurStepIsTrip() {
      return this.selectedRow && (this.curEventTypeId === 1 || this.curEventTypeId === 2)
    },

    // проверяет, что текущий шаг - это интервальные рейсы
    isCurStepIsIntTrips() {
      return this.selectedRow && (this.curEventTypeId === 6)
    },

    // включенные кнопки для левой таблицы
    leftPanelButtonsExist() {
      return {
        refresh: true,
      }
    },

    // активные кнопки для левой таблицы
    leftPanelButtonsEnabled() {
      return {
        refresh: true,
      }
    },

    // включенные кнопки для правой таблицы
    rightPanelButtonsExist() {
      return {
        edit: this.isCurStepIsIntTrips && !this.isEditMode,
        save: this.isEditMode,
        cancel: this.isEditMode,
        refresh: true,
      }
    },

    // активные кнопки для правой таблицы
    rightPanelButtonsEnabled() {
      return {
        edit: true,
        save: this.isEditMode,
        cancel: this.isEditMode,
        refresh: this.isCurStepIsTrip || this.isCurStepIsIntTrips,
      }
    },

    // возвращает список режима редактирования
    showModeList() {
      // отображаем кнопки только при выборе рейса
      if (!this.isCurStepIsTrip)
        return [];

      const modes = [];
      if (this.selectedRow.is_blocked_norm) {
        modes.push(
            {
              id: 1,
              title: 'Раздвинуть время'
            })
        modes.push({
              id: 2,
              title: 'Изменить время'
            }
        )
      }
      if (this.selectedRow.is_blocked_stop && !this.selectedRow.is_blocked_norm) {
        modes.push(
            {
              id: 0,
              title: 'Время стоянок'
            }
        )
      }

      return modes;
    },

    // диалоговое окно события с рейсом (тип 1 и 2)
    isTripDialogVisible() {
      return !this.isLoading &&
          (this.op === 'show' || this.op === 'edit' || this.op === 'add') &&
          (this.selectedRow || this.op === 'add') &&
          (
              getScheduleEvent(this.event_id).schedule_event_type_id === 1 ||
              getScheduleEvent(this.event_id).schedule_event_type_id === 2
          )
    },

    // диалоговое окно события с временем (тип 3 и 4 и 6)
    isTimeDialogVisible() {
      return !this.isLoading &&
          (this.op === 'show' || this.op === 'edit' || this.op === 'add') &&
          (this.selectedRow || this.op === 'add') &&
          (
              getScheduleEvent(this.event_id).schedule_event_type_id === 3 ||
              getScheduleEvent(this.event_id).schedule_event_type_id === 4 ||
              getScheduleEvent(this.event_id).schedule_event_type_id === 6
          )
    },

    // диалоговое окно события с временем (тип 5)
    isTimeAndLengthDialogVisible() {
      return !this.isLoading &&
          (this.op === 'show' || this.op === 'edit' || this.op === 'add') &&
          (this.selectedRow || this.op === 'add') &&
          (
              getScheduleEvent(this.event_id).schedule_event_type_id === 5
          )
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return !this.isLoading && this.op === 'del' && !!this.selectedRow;
    },

    // условие отображения списка ошибок графика
    isErrorsDialogVisible() {
      return this.scheduleGraphErrors.length > 0;
    },

    // ссылка для перехода к вариантам маршрутов
    routeVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants`
    },

    // ссылка для перехода к вариантам расписаний
    scheduleVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`;
    },

    // ссылка для перехода к наборам графиков
    scheduleGraphSetLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets`;
    },

    // ссылка для перехода к наборам графиков
    scheduleGraphLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs`;
    },

    // ссылка для перехода к расписанию
    scheduleTimeTablesLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.schedule_graph_id}/scheduleTimeTables`;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add' || this.op === 'ins') {
        return {
          schedule_graph_id: Number(this.schedule_graph_id),
          order_num: Number(this.order_num) || null,
          schedule_event_id: Number(this.event_id),
          transportation_kind_id: this.scheduleGraph.transportation_kind_id,
          is_manual_norm: false,
        }
      } else return this.selectedRow;
    },
  },

  methods: {

    // перезагружаем страницу с новым выбранным распорядка графика
    async changeScheduleGraph() {
      this.isLoading = 'fetch';
      try {
        if (this.selectedScheduleGraphId != null) {
          this.$router.push(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.selectedScheduleGraphId}/scheduleGraphSteps`);
          await this.$nextTick(async () => {
            await this.getTableData();
          });
        }
      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // вызывается при нажатии Обновить
    onTableRefresh() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      this.cardEditMode = null;
      // перезапрашиваются данные
      this.refreshData();
    },

    // вызывается при выборе шага
    onRowSelect(item) {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      this.cardEditMode = null;
      // устанавливаем новую активную запись
      this.selectedRow = item;
      // обновляем карточку шага
      this.refreshScheduleGraphCard();
    },

    // Вызывается при нажатии Добавить
    onTableAdd(event) {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.schedule_graph_id}/scheduleGraphSteps/add/${event.schedule_event_id}/0`);
    },

    // Вызывается при нажатии Вставить
    onTableInsert(event) {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.schedule_graph_id}/scheduleGraphSteps/add/${event.schedule_event_id}/${this.selectedRow.order_num}`);
    },

    // Вызывается при нажатии Изменить
    onTableEdit() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.schedule_graph_id}/scheduleGraphSteps/edit/${this.selectedRow.schedule_event_id}`);
    },

    // Вызывается при нажатии Удалить
    onTableDelete() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.schedule_graph_id}/scheduleGraphSteps/del/${this.selectedRow.schedule_event_id}`);
    },

    // Вызывается при нажатии Просмотр
    onTableShow() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.schedule_graph_id}/scheduleGraphSteps/show/${this.selectedRow.schedule_event_id}`);
    },

    // Вызывается при нажатии Пересчет
    onTableCalc() {
      this.calcGraph();
    },

    // Вызывается при нажатии Проверка
    onTableCheck() {
      this.checkGraph();
    },

    // Вызывается при включении режима редактирования типов рейса
    onIntTripTypesEditMode() {
      this.isEditMode = true;
    },

    // вызывается при отмене режима редактирования таблицы
    onTableCancel() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      this.cardEditMode = null;

      // восстанавливаем данные
      if (this.isCurStepIsIntTrips)
        this.updateIntTripRows();
      else if (this.isCurStepIsTrip)
        this.updateCardRows();
    },

    // вызывается при сохранении данных в режиме редактирования
    onTableSave() {
      const mode = this.cardEditMode;

      // сбрасываем режим редактирования
      this.isEditMode = false;
      this.cardEditMode = null;

      // сохраняем данные
      if (this.isCurStepIsIntTrips)
        this.saveStepIntTripsCard();
      else if (this.isCurStepIsTrip)
        this.saveStepTripCard(mode);
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      this.confirmDialog(item);
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.schedule_graph_id}/scheduleGraphSteps`);
    },

    async confirmDialog(item) {
      if (this.op === 'add') {
        await this.addItem(item)
      } else if (this.op === 'edit') {
        await this.editItem(item)
      }

      // после добавления нового элемента или редактирования элемента, в случае если элемент с переключением необходимо перезаполнить TripTypes
      if (item.recipient_route_id) {
        await this.getExtTripTypes();
      }

    },

    // подтверждено изменения в диалоге
    onConfirmDelete(item) {
      this.delItem(item)
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.schedule_graph_id}/scheduleGraphSteps`);
    },

    // Отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.schedule_graph_id}/scheduleGraphSteps`);
    },

    // формирует итоговую таблицу по интервальным рейсам для сохранения на сервере
    createIntTripsResult() {
      const result = [];
      this.intTripRows.forEach(row => {
        if (row.__checked) {
          // корректируем количество рейсов
          if (!row.__tripCount || row.__tripCount < 0)
            row.__tripCount = 0

          result.push({
            schedule_graph_step_id: this.selectedRow.schedule_graph_step_id,
            trip_type_id: row.trip_type_id,
            trip_count: row.__tripCount
          })
        }
      });

      return result
    },

    // проверяем карточку рейса перед сохранением
    checkTripCardResult() {
      for (let i = 0; i < this.cardRows.length; i++) {
        const row = this.cardRows[i];
        // все времена должны быть заполнены, только если заблокированы нормы
        if (row.plan_time === null && this.selectedRow.is_blocked_norm) {
          this.errorMessage = 'Все времена в каточке должны быть заполнены.';
          return false;
        }
      }
      return true;
    },

    // формируем итоговую таблицу по карточке рейса
    createTripCardResult() {
      return this.cardRows.map((row) => {
        return {
          schedule_graph_step_id: this.selectedRow.schedule_graph_step_id,
          trip_type_id: this.selectedRow.trip_type_id,
          trip_type_order_num: row.trip_type_order_num,
          plan_time: row.plan_time,
          next_stop_duration: null,
          stop_time: row.stop_time,
          end_stop_sign: row.end_stop_sign,
        }
      })
    },

    // обновляет строки в таблице карточка шага
    updateCardRows() {

      // // сравниваем карточки между собой
      // // все что есть в карточке шага должно быть и в карточке типа рейса
      // if (!this.scheduleGraphStepCard.every((stepItem => {
      //   return this.tripTypeCard.findIndex(tripItem => tripItem.order_num === stepItem.trip_type_order_num) >= 0
      // }))) {
      //   // сбрасываем карточку
      //   this.cardRows = [];
      //   // ругаемся
      //   this.errorMessage = 'Распорядок не соответствует трассе рейса'
      //   // выходим
      //   return;
      // }

      // формируем строки
      this.cardRows = this.scheduleGraphStepCard.map(stepCardItem => {

        // запрашиваем элемент карточки рейса
        // const tripCardItem = this.tripTypeCard.find(item => item.order_num === stepCardItem.trip_type_order_num);

        // запрашиваем остановку
        const stop = stepCardItem ? getStop(stepCardItem.stop_id) : null;

        const row = {
          __id: stepCardItem.trip_type_order_num,
          __length: stepCardItem ? getLength(stepCardItem.length) : null,
          __name: stop ? stop.long_name : null,
          __name__cellAttrs: {
            title: stop ? stop.long_name : null
          },
          __stop_id: stepCardItem ? stepCardItem.stop_id : null,
          __is_control_point: stepCardItem ? stepCardItem.is_control_point : null,
          trip_type_order_num: stepCardItem.trip_type_order_num,
          plan_time: stepCardItem.plan_time,
          stop_time: stepCardItem.stop_time,
          end_stop_sign: stepCardItem.end_stop_sign,
          next_stop_duration: stepCardItem.next_stop_duration,
        }

        //задаем стиль строк
        if (stepCardItem && (stepCardItem.end_stop_sign === 1 || stepCardItem.end_stop_sign === 2)) {
          row.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getStartEndStopColor'],
            color: '#ffffff'
          }
          // блокируем возможность редактировать стоянки и время начала и конца
          row.stop_time__inputAttrs = {
            disabled: true
          }
          row.plan_time__inputAttrs = {
            disabled: true
          }
        } else if (stepCardItem.is_control_point) {
          row.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getCPColor']
          }
        } else {
          row.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getProductionReisColor']
          }
        }

        return row;
      });
    },

    // обновляет строки в таблице интервальные рейсы
    updateIntTripRows() {
      this.intTripRows = this.tripTypes.filter(trip => trip.is_production).map(trip => {
        const row = {...trip};
        const stepTrip = this.scheduleGraphStepTripTypes.find(stepTrip => stepTrip.trip_type_id === trip.trip_type_id);

        row.__id = trip.trip_type_id;
        if (stepTrip) {
          row.__checked = true;
          row.__tripCount = stepTrip.trip_count;
        } else {
          row.__checked = false;
          row.__tripCount = 0;
        }

        row.title__cellAttrs = {
          title: trip.title
        }

        row.__rowStyle = {
          backgroundColor: this.$store.getters['settings/getProductionReisColor']
        }

        return row;
      })
    },

    async getTableData() {
      await this.$nextTick(async () => {

        let scheduleGraph = this.scheduleGraphsArr.find(value => value.schedule_graph_id == this.selectedScheduleGraphId);
        if (scheduleGraph) {
          this.scheduleGraph = scheduleGraph;
        }

        // загружаем шаги распорядка
        const scheduleGraphSteps = await this.$store.dispatch('rmt/doFetchScheduleGraphSteps', {
          schedule_graph_id: this.selectedScheduleGraphId,
        });
        if (!scheduleGraphSteps) return;
        this.scheduleGraphSteps = scheduleGraphSteps;

        // в случае если есть шаги с переключением, необходимо загрузить дополнительные TripType
        await this.getExtTripTypes();
      });
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // очищаем правую таблицу
        this.cardRows = [];
        this.intTripRows = [];

        // загружаем второстепенные справочники
        await this.$store.dispatch('ref/doFetchDepots');
        await this.$store.dispatch('multipass/doFetchStops');
        await this.$store.dispatch('rmt/doFetchScheduleEvents');
        await this.$store.dispatch('rmt/doFetchTransportationKinds');

        // запрос маршрута
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        });
        if (!route) return;
        this.route = route;

        // запрос варианта маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id,
        });
        if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // запрос варианта расписания
        const scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
          schedule_variant_id: this.schedule_variant_id,
        });
        if (!scheduleVariant) return;
        this.scheduleVariant = scheduleVariant;

        // запрос набора графиков
        const scheduleGraphSet = await this.$store.dispatch('rmt/doFetchScheduleGraphSet', {
          schedule_graph_set_id: this.schedule_graph_set_id,
        });
        if (!scheduleGraphSet) return;
        this.scheduleGraphSet = scheduleGraphSet;

        // загружаем типы рейса
        let tripTypes = await this.$store.dispatch('multipass/doFetchTripTypes', {
          route_variant_id: this.route_variant_id,
          force: true,
        });
        if (!tripTypes) return;
        tripTypes.sort(signSortFn);
        this.tripTypes = tripTypes;

        // запрос графика
        const scheduleGraphs = await this.$store.dispatch('rmt/doFetchScheduleGraphs', {
          schedule_graph_set_id: this.schedule_graph_set_id,
        });

        if (!scheduleGraphs) return;

        // сортируем полученный массив
        quickSort(scheduleGraphs, (leftItem, rightItem) => {
          return 1 * scheduleGraphSortFn(leftItem, rightItem);
        });

        this.scheduleGraphsArr = scheduleGraphs;
        this.selectedScheduleGraphId = this.schedule_graph_id;

        await this.getTableData();

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // в случае если есть шаги с переключением,необходимо загрузить дополнительные TripType
    async getExtTripTypes() {

      // проверяем каких tripType из массива нет в расширенном массиве
      this.tripTypes.map(type => {
        if (this.extTripTypes.length != 0) {
          let tripType = this.extTripTypes.find(tType => type.trip_type_id == tType.trip_type_id);
          if (!tripType) {
            // записываем полученный tripType в расширенный массив tripType
            this.extTripTypes.push(type)
          }
        } else {
          this.extTripTypes.push(type)
        }
      });

      // цикл по полученным шагам графика
      for (let i = 0; i < this.scheduleGraphSteps.length; i++) {
        if (this.scheduleGraphSteps[i].trip_type_id) {
          // проверяем есть ли необюходимый trip_type_id в расширенном массиве tripType-ов (полученного путем добавления
          // tripType-ов из шагов графика с переключением)
          let tripType = this.extTripTypes.find(type => type.trip_type_id == this.scheduleGraphSteps[i].trip_type_id);
          // в случае, если в обоих массивах tripType нет, необходимо его получить
          if (!tripType) {
            // получаем необходимый tripType

            tripType = await this.$store.dispatch('multipass/doFetchTripType', {
              trip_type_id: this.scheduleGraphSteps[i].trip_type_id
            })

            // записываем полученный tripType в расширенный массив tripType
            this.extTripTypes.push(tripType);
          }
        }
      }

    },

    // запускаем обновление карточки распорядка
    async refreshScheduleGraphCard() {
      // очищаем таблицу
      this.cardRows = [];
      this.intTripRows = [];

      // формируем результат в случае, если это рейс
      if (this.selectedRow && this.selectedRow.trip_type_id && this.isCurStepIsTrip) {
        // // запрашиваем карточку текущего рейса
        // const tripTypeCard = await this.$store.dispatch('multipass/doFetchTripTypeCard', {
        //   trip_type_id: this.selectedRow.trip_type_id,
        //   crop_turn: true,
        //   force: true
        // });
        // if (!tripTypeCard) return;
        // this.tripTypeCard = tripTypeCard;

        // запрашиваем карточку текущего шага
        const scheduleGraphStepCard = await this.$store.dispatch('rmt/doFetchScheduleGraphStepCard', {
          schedule_graph_step_id: this.selectedRow.schedule_graph_step_id,
        });
        if (!scheduleGraphStepCard) return;
        this.scheduleGraphStepCard = scheduleGraphStepCard;

        // формируем таблицу для отображения
        await this.updateCardRows();
      }
      // формируем результат в случае, если это интервальные рейсы
      else if (this.selectedRow && this.isCurStepIsIntTrips) {

        // запрашиваем контролируемые типы рейсов
        const scheduleGraphStepTripTypes = await this.$store.dispatch('rmt/doFetchScheduleGraphStepTripTypes', {
          schedule_graph_step_id: this.selectedRow.schedule_graph_step_id,
        });
        if (!scheduleGraphStepTripTypes) return;
        this.scheduleGraphStepTripTypes = scheduleGraphStepTripTypes;

        // формируем таблицу для отображения
        await this.updateIntTripRows();
      }
    },

    // добавляем / вставляем шаг к распорядку
    async addItem(item) {
      this.isLoading = true;
      try {
        // запрос на сервер
        const newItem = await this.$store.dispatch('rmt/doAddScheduleGraphStep', item);
        // шаг успешно добалвен
        if (newItem) {
          // вызываем пересчет графика
          if (this.isAutoRecalcEnable) {
            let scheduleGraphErrors = await this.requestCalcScheduleGraph();
            if (scheduleGraphErrors)
              this.scheduleGraphErrors = scheduleGraphErrors;
          }
          // обновляем список шагов
          const scheduleGraphSteps = await this.requestScheduleGraphSteps();
          if (!scheduleGraphSteps) return;
          this.scheduleGraphSteps = scheduleGraphSteps;
          // выделяем новый шаг
          this.selectedRow = scheduleGraphSteps.find(step => step.order_num === newItem.order_num);
          // обновляем карточку
          await this.refreshScheduleGraphCard();
        }

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        // запрос на сервер
        const newItem = await this.$store.dispatch('rmt/doEditScheduleGraphStep', item);
        // шаг успешно изменен
        if (newItem) {
          // вызываем пересчет графика
          if (this.isAutoRecalcEnable) {
            let scheduleGraphErrors = await this.requestCalcScheduleGraph();
            if (scheduleGraphErrors)
              this.scheduleGraphErrors = scheduleGraphErrors;
          }
          // обновляем список шагов
          const scheduleGraphSteps = await this.requestScheduleGraphSteps();
          if (!scheduleGraphSteps) return;
          this.scheduleGraphSteps = scheduleGraphSteps;
          // выделяем измененный шаг
          this.selectedRow = scheduleGraphSteps.find(step => step.order_num === newItem.order_num);
          // обновляем карточку
          await this.refreshScheduleGraphCard();
        }

      } finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрос на сервер
        const oldItem = await this.$store.dispatch('rmt/doDelScheduleGraphStep', this.selectedRow)
        // вызываем пересчет графика
        if (this.isAutoRecalcEnable) {
          let scheduleGraphErrors = await this.requestCalcScheduleGraph();
          if (scheduleGraphErrors)
            this.scheduleGraphErrors = scheduleGraphErrors;
        }
        // обновляем список шагов
        const scheduleGraphSteps = await this.requestScheduleGraphSteps();
        if (!scheduleGraphSteps) return;
        this.scheduleGraphSteps = scheduleGraphSteps;
        // выделяем удаленный шаг
        this.selectedRow = scheduleGraphSteps.find(step => step.order_num === oldItem.order_num);
        // обновляем карточку
        await this.refreshScheduleGraphCard();

      } finally {
        this.isLoading = false;
      }
    },

    // пересчет графика
    async calcGraph() {
      this.isLoading = true;
      try {

        // вызываем пересчет графика
        let scheduleGraphErrors = await this.requestCalcScheduleGraph();
        if (!scheduleGraphErrors) return;
        this.scheduleGraphErrors = scheduleGraphErrors;

        // обновляем список шагов
        const scheduleGraphSteps = await this.requestScheduleGraphSteps();
        if (!scheduleGraphSteps) return;
        this.scheduleGraphSteps = scheduleGraphSteps;

        // выделяем измененный шаг
        if (this.selectedRow)
          this.selectedRow = scheduleGraphSteps.find(step => step.order_num === this.selectedRow.order_num);

        // обновляем карточку
        await this.refreshScheduleGraphCard();

      } finally {
        this.isLoading = false;
      }
    },

    // проверка графика
    async checkGraph() {
      this.isLoading = true;
      try {

        // вызываем пересчет графика
        let scheduleGraphErrors = await this.requestCheckScheduleGraph();
        if (!scheduleGraphErrors) return;
        this.scheduleGraphErrors = scheduleGraphErrors;

        // обновляем список шагов
        const scheduleGraphSteps = await this.requestScheduleGraphSteps();
        if (!scheduleGraphSteps) return;
        this.scheduleGraphSteps = scheduleGraphSteps;

        // выделяем измененный шаг
        if (this.selectedRow)
          this.selectedRow = scheduleGraphSteps.find(step => step.order_num === this.selectedRow.order_num);

        // обновляем карточку
        await this.refreshScheduleGraphCard();

      } finally {
        this.isLoading = false;
      }
    },

    // сохраняем карточку для интервальных рейсов
    async saveStepIntTripsCard() {
      this.isLoading = true;
      try {

        // запрос на сервер
        const scheduleGraphStepTripTypes = await this.$store.dispatch('rmt/doEditScheduleGraphStepTripTypes', {
          schedule_graph_step_id: this.selectedRow.schedule_graph_step_id,
          tripTypes: this.createIntTripsResult()
        });
        if (!scheduleGraphStepTripTypes) {
          this.updateIntTripRows();
        }

        // вызываем пересчет графика
        if (this.isAutoRecalcEnable) {
          let scheduleGraphErrors = await this.requestCalcScheduleGraph();
          if (scheduleGraphErrors)
            this.scheduleGraphErrors = scheduleGraphErrors;
        }

        // обновляем список шагов
        const scheduleGraphSteps = await this.requestScheduleGraphSteps();
        if (!scheduleGraphSteps) return;
        this.scheduleGraphSteps = scheduleGraphSteps;

        // выделяем измененный шаг
        if (this.selectedRow)
          this.selectedRow = scheduleGraphSteps.find(step => step.order_num === this.selectedRow.order_num);

        // обновляем карточку
        await this.refreshScheduleGraphCard();

      } finally {
        this.isLoading = false;
      }
    },

    // сохраняем карточку рейса
    async saveStepTripCard(mode) {

      if (!this.checkTripCardResult()) {
        await this.updateCardRows();
        return
      }

      this.isLoading = true;
      try {

        // запрос на сервер
        const scheduleGraphStepCard = await this.$store.dispatch('rmt/doEditScheduleGraphStepCard', {
          schedule_graph_step_id: this.selectedRow.schedule_graph_step_id,
          card: this.createTripCardResult(),
          mode,
        });
        if (!scheduleGraphStepCard) {
          await this.updateCardRows();
          return;
        }

        // вызываем пересчет графика
        if (this.isAutoRecalcEnable) {
          let scheduleGraphErrors = await this.requestCalcScheduleGraph();
          if (scheduleGraphErrors)
            this.scheduleGraphErrors = scheduleGraphErrors;
        }

        // обновляем список шагов
        const scheduleGraphSteps = await this.requestScheduleGraphSteps();
        if (!scheduleGraphSteps) return;
        this.scheduleGraphSteps = scheduleGraphSteps;

        // выделяем измененный шаг
        if (this.selectedRow)
          this.selectedRow = scheduleGraphSteps.find(step => step.order_num === this.selectedRow.order_num);

        // обновляем карточку
        await this.refreshScheduleGraphCard();

      } finally {
        this.isLoading = false;
      }
    },

    // отправляем запрос на пересчет графика
    async requestCalcScheduleGraph() {
      return await this.$store.dispatch('rmt/doRecalcScheduleGraph', {
        schedule_graph_id: this.schedule_graph_id
      });
    },

    // отправляем запрос на проверку графика
    async requestCheckScheduleGraph() {
      return await this.$store.dispatch('rmt/doCheckScheduleGraph', {
        schedule_graph_id: this.schedule_graph_id
      });
    },

    // отправляем запрос на обновление списка шагов
    async requestScheduleGraphSteps() {
      return await this.$store.dispatch('rmt/doFetchScheduleGraphSteps', {
        schedule_graph_id: this.schedule_graph_id,
      });
    },
  },

  watch: {
    // фиксируем вход в режим редактирования
    cardEditMode(mode, prevMode) {
      if (prevMode === null) {
        this.isEditMode = true;
      }
    }
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
