<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-area-classifier"></edit-table-panel>
</template>

<script>

export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "area_classifier_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "parent_area_classifier_id",
          caption: "Код родительского объекта",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "level",
          caption: "Уровень вложенности",
          sortType: 'number',
          show: false
        },
        {
          field: "is_disabled",
          caption: "Откл.",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_disabled) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') return [];

      return this.$store.getters['ref/getAreaClassifier'].map(cls => {
        cls.__id = cls.area_classifier_id;

        return cls
      });
    },
  },
};
</script>
