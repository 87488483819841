<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-entity-type"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
export default {
  components: {EditTablePanel},
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "entity_type_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "entity_type_title",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["integration/getEntityTypes"].map( entityType => {
        entityType.__id = entityType.entity_type_id;
        return entityType;
      });
    },
  },
};
</script>
