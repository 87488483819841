<template>
  <!-- Карта-->
  <leaflet-map
      :zoom="zoom"
      :center="center"
      :contextMenu="mapContextMenu"
      :options="mapOptions"
      @onMapCreated="onMapCreated"
  >

    <!-- Элементы управления-->
    <leaflet-control-group>
      <!-- Базовые слои-->
      <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
    </leaflet-control-group>

    <!-- остановки -->
    <stop-marker
        visible
        :stop="stop"
        :tooltip="stop.long_name"
    ></stop-marker>

    <!-- павилионы-->
    <stop-pavilion-marker
        v-for="stopPavilion in stopPavilions"
        :key="stopPavilion.stop_pavilion_id"
        :stopPavilion="stopPavilion"
        :tooltip="stopPavilion.long_name"
        :state="stopPavilion === selectedStopPavilion && isMoveMode ? 'edit': stopPavilion === selectedStopPavilion ? 'active': 'original'"
        :contextMenu="stopPavilion === selectedStopPavilion && !isMoveMode ? stopPavilionContextMenu: noContextMenu"
        events="click dblclick"
        @onLeafletEvent="onLeafletEvent($event, stopPavilion)"
        @onStopPavilionChanged="$emit('onStopPavilionChanged', $event)"
    ></stop-pavilion-marker>

  </leaflet-map>
</template>

<script>

export default {

  props: [
    // выбранный павилион
    'selectedStopPavilion',
    // режим перемещения
    'isMoveMode',
    // список павильонов
    'stopPavilions',
    // остановка
    'stop'
  ],
  // список событий
  emits: [
    // Команда "добавить павилион"
    'onAdd',
    // Команда "изменить павилион"
    'onEdit',
    // Команда "удалить павилион"
    'onDelete',
    // Команда "переместить павилион"
    'onMove',
    // двойной щелчек на павилион
    'onShow',
    // вызывается при щелчке на павилион
    'onStopPavilionSelect',
    // вызывается при перемещении павилион
    'onStopPavilionChanged'
  ],
  computed: {

    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom']
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter']
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },

    // минимальный масштаб для отображения остановок
    minStopZoom() {
      return this.$store.getters['settings/getMinStopZoom']
    },

    // контекстное меню карты
    mapContextMenu() {
      if (this.isMoveMode) {
        return {
          contextmenu: true,
          contextmenuWidth: 140,
          contextmenuItems: []
        }
      }
      else {
        return {
          contextmenu: true,
          contextmenuWidth: 140,
          contextmenuItems: [
            {
              text: 'Добавить',
              callback: (event) => {
                const latlng = event.latlng;
                this.$emit('onAdd', {
                  latitude: latlng.lat,
                  longitude: latlng.lng
                })
              }
            }
          ]
        }
      }
    },
  },

  data() {
    return {
      // карта
      leaflet: null,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // контекстное меню павилиона
      stopPavilionContextMenu: {
        contextmenu: true,
        contextmenuInheritItems: false,
        contextmenuWidth: 140,
        contextmenuItems: [
          {
            text: 'Изменить',
            callback: () => {
              this.$emit('onEdit', this.selectedStopPavilion)
            }
          },
          {
            text: 'Удалить',
            callback: () => {
              this.$emit('onDelete', this.selectedStopPavilion)
            }
          },
          {
            separator: true
          },
          {
            text: 'Переместить',
            callback: () => {
              this.$emit('onMove', this.selectedStopPavilion)
            }
          },
        ]
      },
      // нет контекстного меню павилиона
      noContextMenu: {
        contextmenu: false,
      },
    }
  },

  methods: {

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // срабатывает при щелчке на павилион
    onLeafletEvent(event, stopPavilion) {
      // при щелчке выделяем павилион
      if (event.type === 'click') {
        this.$emit('onStopPavilionSelect', stopPavilion);
      }
      // вызываем событие при двойном щелчке на павилион
      else if (event.type === 'dblclick') {
        this.$emit('onShow', stopPavilion)
      }
    }
  },
}
</script>
