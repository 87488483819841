<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Набор графиков</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>

            <div class="form-group">
              <label for="scheduleGraphSetId">Код:</label>
              <input-integer
                  id="scheduleGraphSetId"
                  class="form-control w-25"
                  v-model="scheduleGraphSetId"
                  readonly
              />
            </div>

            <div class="form-group">
              <label for="title">Наименование набора:</label><ReqField />
              <input-string
                  id="title"
                  v-model="title"
                  class="form-control"
                  ref="title"
                  :readonly="op === 'show'"
                  required
              />
            </div>

            <div class="form-group">
              <label for="note">Примечание:</label>
              <input-string
                  id="note"
                  v-model="note"
                  class="form-control"
                  ref="note"
                  :readonly="op === 'show'"
              />
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import InputInteger from "@/components/ui/custom/InputInteger";

export default {
  components: {
    InputInteger,
    ReqField
  },
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      scheduleGraphSetId: this.item.schedule_graph_set_id,
      title: this.item.title,
      note: this.item.note,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {

        this.$emit('onOK', {
          schedule_graph_set_id: this.scheduleGraphSetId,
          title: this.title,
          note: this.note,
        })
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>