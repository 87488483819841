<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-asmpp-fact-trip-card-exts-aggregated"> </edit-table-panel>
</template>

<script>

import {getStop} from "@/store/MultipassHelpers";
import {getSignTitle} from "@/lib";

export default {
  props: ['isLoading','rowArr', 'tripTypes'],
  emits: ['setLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "tripTypeSign",
          caption: "Тип рейса",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          show: false
        },
        {
          field: "order_num",
          caption: "№",
          sortType: 'number',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center'
        },
        {
          field: "__stop",
          caption: "Остановка",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'left',
          headerAlign: 'left'
        },
        {
          field: "passenger_in_avg",
          caption: "Вход (средн.)",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.passenger_in_avg) {
              return Number(row.passenger_in_avg).toFixed(1);
            }
            return 0;
          },
        },
        {
          field: "passenger_in",
          caption: "Вход (сумм.)",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "passenger_out_avg",
          caption: "Выход (средн.)",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.passenger_out_avg) {
              return Number(row.passenger_out_avg).toFixed(1);
            }
            return 0;
          },
        },
        {
          field: "passenger_out",
          caption: "Выход (сумм.)",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "passenger_inside_avg",
          caption: "Наполнение (средн.)",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.passenger_inside_avg) {
              return Number(row.passenger_inside_avg).toFixed(1);
            }
            return 0;
          },
        },
        {
          field: "fact_trip_count",
          caption: "Количество рейсов",
          sortType: 'number',
          isFilterable: true
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      let rows = this.rowArr;

      rows.map(row => {
        row.__id = row.trip_id;

        row.__stop = getStop(row.stop_id).long_name;
        row.tripTypeSign = getSignTitle(this.tripTypes.find(value => row.trip_type_id == value.trip_type_id).trip_type_sign);
        // рассчитываем дополнительные поля
        // Среднее количество вошедших
        row.passenger_in_avg = row.passenger_in / row.fact_trip_count;
        // среднее количество вышедших
        row.passenger_out_avg = row.passenger_out / row.fact_trip_count;
        // среднее наполнение
        row.passenger_inside_avg = row.passenger_inside / row.fact_trip_count;

        return row;
      });


      return rows;
    },
  }
};
</script>
