<template>
  <div class="container position-relative h-100">
    <h2 class="text-center">Модуль "Администрирование"</h2>
    <p class="text-center">Администрирование работы системы.</p>
  </div>
</template>

<script>
  import PageMixin from "@/pages/share/PageMixin";

  export default {
    mixin: [PageMixin],
  }

</script>
