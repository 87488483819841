
// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ ASMPP

import BalanceTypesAsmppStore from "@/store/asmpp/BalanceTypesAsmppStore";
import TripsAsmppStore from "@/store/asmpp/FactTripsExtAsmppStore";
import TripTypeCompositesAsmppStore from "@/store/asmpp/TripTypeCompositesAsmppStore";
import TripTypeCompositeCardsAsmppStore from "@/store/asmpp/TripTypeCompositeCardsAsmppStore";
import PassengerTrafficFactTripByHoursAsmppStore from "@/store/asmpp/PassengerTrafficFactTripByHoursAsmppStore";
import PassengerTrafficDayByTransportAsmppStore from "@/store/asmpp/PassengerTrafficDayByTransportAsmppStore";
import PassengerTrafficDayByGraphAsmppStore from "@/store/asmpp/PassengerTrafficDayByGraphAsmppStore";
import FactStopsAsmppStore from "@/store/asmpp/FactTripCardExtsAsmppStore";
import FactStopsAggregatedAsmppStore from "@/store/asmpp/FactTripCardItemExtAggregatedAsmppStore";
import FactStopsAggregatedNetworkAsmppStore from "@/store/asmpp/FactTripCardExtsAggregatedNetworkAsmppStore";
import PassengerTrafficDayByTransportNavSumAsmppStore from "@/store/asmpp/PassengerTrafficDayByTransportNavSumAsmppStore";
import ReportsAsmppStore from "@/store/asmpp/ReportsAsmppStore";
import PayTransProcStatusByTransportAsmppStore from "@/store/asmpp/PayTransProcStatusByTransportAsmppStore";
import PayTransByDepotAsmppStore from "@/store/asmpp/PayTransByDepotAsmppStore";
import PayTransByDepotDayAsmppStore from "@/store/asmpp/PayTransByDepotDayAsmppStore";
import PayTransByRouteAsmppStore from "@/store/asmpp/PayTransByRouteAsmppStore";
import PayTransByRouteDayAsmppStore from "@/store/asmpp/PayTransByRouteDayAsmppStore";
import PayTransByGraphAsmppStore from "@/store/asmpp/PayTransByGraphAsmppStore";
import PayTransByGraphDayAsmppStore from "@/store/asmpp/PayTransByGraphDayAsmppStore";
import TempDataAsmppStore from "@/store/asmpp/temp_data/TempDataAsmppStore";


export default {
    namespaced: true,
    modules: {
        TempDataAsmppStore,

        BalanceTypesAsmppStore,
        TripsAsmppStore,
        TripTypeCompositesAsmppStore,
        TripTypeCompositeCardsAsmppStore,
        PassengerTrafficFactTripByHoursAsmppStore,
        PassengerTrafficDayByTransportAsmppStore,
        PassengerTrafficDayByGraphAsmppStore,
        FactStopsAsmppStore,
        FactStopsAggregatedAsmppStore,
        FactStopsAggregatedNetworkAsmppStore,
        PassengerTrafficDayByTransportNavSumAsmppStore,
        ReportsAsmppStore,
        PayTransProcStatusByTransportAsmppStore,
        PayTransByDepotAsmppStore,
        PayTransByDepotDayAsmppStore,
        PayTransByRouteAsmppStore,
        PayTransByRouteDayAsmppStore,
        PayTransByGraphAsmppStore,
        PayTransByGraphDayAsmppStore,
    }

}