<template>
  <card>
    <card-header class="bg-info text-white py-1 px-3">
      <div class="d-flex justify-content-between align-items-center">
        <span>Информация о пользователе</span>
        <button
            type="button"
            class="btn btn-secondary d-inline-block"
            @click="onRefresh()"
            title="Обновить"
        >
          <i class="fas fa-sync-alt"></i>
        </button>
      </div>
    </card-header>
    <card-body>
      <form>
        <div class="form-group">
          <label for="uid">Код:</label>
          <input-integer
              id="uid"
              class="form-control w-25"
              v-model="uid"
              readonly
          />
        </div>

        <div class="form-group">
          <label for="login">Логин:</label>
          <input-string
              id="login"
              type="text"
              class="form-control"
              autocomplete="off"
              v-model="login"
              readonly
          />
        </div>

        <div class="form-group">
          <label for="last_name">Фамилия:</label>
          <input-string
              id="last_name"
              class="form-control"
              autocomplete="off"
              v-model="last_name"
              readonly
          />
        </div>

        <div class="form-group">
          <label for="first_name">Имя:</label>
          <input-string
              id="first_name"
              class="form-control"
              autocomplete="off"
              v-model="first_name"
              readonly
          />
        </div>

        <div class="form-group">
          <label for="middle_name">Отчество:</label>
          <input-string
              id="middle_name"
              class="form-control"
              autocomplete="off"
              v-model="middle_name"
              readonly
          />
        </div>

        <div class="form-group">
          <label for="org">Организация:</label>
          <input-string
              id="org"
              class="form-control"
              autocomplete="off"
              v-model="org"
              readonly
          />
        </div>

        <div class="form-group">
          <label for="email">Email:</label>
          <input-string
              id="email"
              class="form-control"
              autocomplete="off"
              v-model="email"
              readonly
          />
        </div>

        <div class="form-group">
          <label for="phone_num">Телефон:</label>
          <input-string
              id="phone_num"
              class="form-control"
              autocomplete="off"
              v-model="phone_num"
              readonly
          />
        </div>

        <div class="form-group">
          <label for="birthday">Дата рождения:</label>
          <input-date
              id="birthday"
              class="form-control w-25"
              autocomplete="off"
              v-model="birthday"
              readonly
          />
        </div>

        <label for="timeZone">Часовой пояс:</label>
        <div class="form-group ">
          <div class="form-group row" style="padding:  0 15px 0 15px">
            <div class="form-group col-3" style="padding:  0px">
              <input-integer
                  id="timeZone"
                  class="form-control"
                  autocomplete="off"
                  ref="timeZone"
                  v-model="timeZone"
                  :min-value="-1440"
                  :max-value="1440"
                  required
                  :readonly="isEditMode == false ? true : false"
              />
            </div>
            <div class="form-group col">
              <button
                  type="button"
                  class="btn btn-secondary"
                  @click="onClick"
              >
                <i class="fas fa-pen"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </card-body>
  </card>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  emits: ['onRefresh'],
  mixins: [ValidateFormMixin],
  props:
      {
        item: {
          type: Object,
          default: function () {
            return {}
          },
        }
      },
  data() {
    return {
      isEditMode: false,

      u_id: this.uid,
      log: this.login,
      firstName: this.first_name,
      middleName: this.middle_name,
      lastName: this.last_name,
      organization: this.org,
      e_mail: this.email,
      phoneNum: this.phone_num,
      birth: this.birthday,
      timeZ: this.timeZone,

    };
  },
  computed: {
    uid: {
      get() {
        if (!this.u_id) {
          return this.item.uid;
        } else {
          return this.u_id;
        }
      },
      set(value) {
        this.u_id = value;
      }
    },
    login: {
      get() {
        if (!this.log) {
          return this.item.login;
        } else {
          return this.log;
        }
      },
      set(value) {
        this.log = value;
      }
    },
    first_name: {
      get() {
        if (!this.firstName) {
          return this.item.first_name;
        } else {
          return this.firstName;
        }
      },
      set(value) {
        this.firstName = value;
      }
    },
    middle_name: {
      get() {
        if (!this.middleName) {
          return this.item.middle_name;
        } else {
          return this.middleName;
        }
      },
      set(value) {
        this.middleName = value;
      }
    },
    last_name: {
      get() {
        if (!this.lastName) {
          return this.item.last_name;
        } else {
          return this.lastName;
        }
      },
      set(value) {
        this.lastName = value;
      }
    },
    birthday: {
      get() {
        if (!this.birth) {
          return this.item.birthday;
        } else {
          return this.birth;
        }
      },
      set(value) {
        this.birth = value;
      }
    },
    email: {
      get() {
        if (!this.e_mail) {
          return this.item.email;
        } else {
          return this.e_mail;
        }
      },
      set(value) {
        this.e_mail = value;
      }
    },
    phone_num: {
      get() {
        if (!this.phoneNum) {
          return this.item.phone_num;
        } else {
          return this.phoneNum;
        }
      },
      set(value) {
        this.phoneNum = value;
      }
    },
    org: {
      get() {
        if (!this.organization) {
          return this.item.org;
        } else {
          return this.organization;
        }
      },
      set(value) {
        this.organization = value;
      }
    },
    timeZone: {
      get() {
          return this.timeZ;
      },
      set(value) {
        this.timeZ = value;
      }
    }
  },
  methods: {
    async onClick() {

      if (this.isEditMode) {
        if (this.validateForm(this.$refs)) {
          let item = this.item;
          // сетаем введенное значение часового пояса
          item['time_zone'] = this.timeZ;
//    на случай, если понадобится редактировать другие поля:
          // item.login = this.log;
          // item.first_name = this.firstName;
          // item.middle_name = this.middleName;
          // item.last_name = this.lastName;
          // item.birthday = this.birthday;
          // item.email = this.e_mail;
          // item.phone_num = this.phoneNum;
          // item.org = this.organization;

          // отправляем запрос на изменение часового пояса
          await this.$store.dispatch('auth/doEditUser', item);
          // изменяем текущий профиль
          this.$store.commit('auth/setProfile', item);
          // отключаем режим редактирования
          this.isEditMode = !this.isEditMode;
        }
      } else {
        // отключаем режим редактирования
        this.isEditMode = !this.isEditMode;
      }
    },

    onRefresh(){
      this.timeZ = this.item.time_zone;
      this.isEditMode = false;
      this.$emit('onRefresh');
    }

  },

  created() {

    // добавляем watch чтобы как только заполнится item подставить значение time_zone
    // в интерфейс, если подставлять через get - не работает редактирование
    this.$watch(() => this.item, ()=> {
      if (this.item) {
        this.timeZone = this.item.time_zone;
      }
    }, {immediate: true});
  }

}
</script>
