<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-asmpp-passenger-traffic-day-by-transport-raw"> </edit-table-panel>
</template>

<script>


import { getTransport} from "@/store/RefHelpers";
import {secondsToDate} from "@/lib";

export default {
  props: ['isLoading','rowArr', 'routes', 'scheduleGraphs'],
  emits: ['setLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__date",
          caption: "Дата",
          sortType: 'string',
          isFilterable: true,
          show: true,
        },
        {
          field: "__week_day",
          caption: "День нед.",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "passenger_in_sum",
          caption: "Перевезено (вход)",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "passenger_out_sum",
          caption: "Перевезено (выход)",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "passengers_by_km",
          caption: "КПД-Пасс/км",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.passengers_by_km) {
              return Number(row.passengers_by_km).toFixed(1);
            }
            return 0;
          },
        },
        {
          field: "passengers_by_hour",
          caption: "КПД-Пасс/час",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.passengers_by_hour) {
              return Number(row.passengers_by_hour).toFixed(1);
            }
            return 0;
          },
        },
        {
          field: "pay_tran_count,",
          caption: "Кол-во оплат",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "state_num",
          caption: "Гос.№",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "garage_num",
          caption: "Гар.№",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__disb",
          caption: "Дисб.(%)",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.__disb) {
              return Number(Math.abs(row.__disb)*100).toFixed(1);
            }
            return 0;
          },
        },
        {
          field: "apc_sabotage_count",
          caption: "Саботаж",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "apc_fault_count",
          caption: "Датчик недоступен",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "transport_work_comments",
          caption: "Наряд ТС",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      let rows = this.rowArr;

      rows.map(async row => {
        row.__id = row.trip_id;
        let transport = getTransport(row.transport_id);
        row.garage_num = transport.garage_num;
        row.state_num = transport.state_num;
        row.__date = secondsToDate(row.date_index*24*60*60);

        if (row.weekday_num == 1) {
          row.__week_day = "Пн."
        } else if (row.weekday_num == 2) {
          row.__week_day = "Вт."
        } else if (row.weekday_num == 3) {
          row.__week_day = "Ср."
        } else if (row.weekday_num == 4) {
          row.__week_day = "Чт."
        } else if (row.weekday_num == 5) {
          row.__week_day = "Пт."
        } else if (row.weekday_num == 6) {
          row.__week_day = "Сб."
        } else if (row.weekday_num == 7) {
          row.__week_day = "Вс."
        }

        row.__disb = ((row.passenger_in_sum - row.passenger_out_sum)/(row.passenger_in_sum + row.passenger_out_sum))*2;

        return row;
      });


      return rows;
    },
  }
};
</script>
