<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-users"></edit-table-panel>
</template>

<script>
export default {
  props: ['user_group_id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "uid",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "login",
          caption: "Логин",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "last_name",
          caption: "Фамилия",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "first_name",
          caption: "Имя",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "middle_name",
          caption: "Отчество",
          sortType: 'string',
        },
        {
          field: "org",
          caption: "Организация",
          sortType: 'string',
        },
        {
          field: "birthday",
          caption: "День рождения",
          sortType: 'string',
          show: false,
        },
        {
          field: "email",
          caption: "Email",
          sortType: 'string',
          show: false,
        },
        {
          field: "phone_num",
          caption: "Телефон",
          sortType: 'string',
          show: false,
        },        
        {
          field: "is_blocked",
          caption: "Блок.",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_blocked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }        
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      // если группа не указана - то выводим всех пользователей
      if (!this.user_group_id) {
        return this.$store.getters["auth/getUsers"];
      }
      // если группа указана - фильтруем
      const users = this.$store.getters["auth/getUsers"];
      const links = this.$store.getters["auth/getUserGroupLinks"];
      return users.filter(user => {
        user.__id = user.uid;

        return links.some( link => link.uid == user.uid && link.user_group_id == this.user_group_id)
      });
    },
  },
};
</script>
