<template>
  <card class="text-left" style="height: 30px">
    <card-body id="routesPanel" class="p-1" style="padding-top: 0px !important;">
      <div class="form-row">


        <div v-for="(value,index) in buttonsRoutes" :key="index" class="col-0" style="margin-bottom: 0 !important;
                    margin-left: 5px !important; padding: 0 !important;">


          <button
              type="button"
              class="btn btn-warning btn-sm"
              @click="onSetRoutes(index)"
              :title="value"
              :style="'padding-top: 0px; padding-bottom: 2px; height: 22px; width: 100px;' + (index == selectedRouteIndex ? 'border-color: black;' : '') "
          >
            {{ value }}
          </button>

        </div>

        <dropdown v-if="dropdownRoutes.length != 0">
          <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-warning"
                          :style="'max-height: 22px; font-size: 14px; padding-top: 1px; margin-left: 5px !important;' + (selectedRouteIndex >= dropdownRoutes.length ? 'border-color: black;' :'')">
          </button-toggler>
          <dropdown-menu ref="collapse">
            <dropdown-item v-for="(value, index) in dropdownRoutes" :key="index"
                           @click="onSetRoutes(index + buttonsRoutes.length)"
                           :style="selectedRouteIndex == index + buttonsRoutes.length ? 'border: 1px solid black !important; border-radius: 8px !important;' :''"
            >{{ value }}
            </dropdown-item>
          </dropdown-menu>
        </dropdown>

      </div>
    </card-body>
  </card>


  <align-container id="tablePanel" @resize="tableCountResize" style="margin-top: 32px">

    <align-panel id="table1" align="left"
                 :width="tableCount == 1 ? '100%' : (tableCount == 2 ? '50%' : '33%')" :gap="2" style="overflow-y: hidden">
      <!--  таблица с рейсами -->
      <dispatching-edit-table
          :selectedRow="selectedRow"
          @onRowDblClick="onMonitoringTaskCardDialog"
          @onRowSelect="onRowSelect"
          :rows="table1Rows[selectedRouteIndex]? table1Rows[selectedRouteIndex] : []"
          style="overflow: hidden; margin: 0"
      >
      </dispatching-edit-table>
    </align-panel>

    <align-panel id="table2" v-if="tableCount > 1" align="all" :width="tableCount == 2 ? '50%' : '33%'" style="overflow-y: hidden">
      <!--  таблица с рейсами -->
      <dispatching-edit-table
          :selectedRow="selectedRow"
          @onRowDblClick="onMonitoringTaskCardDialog"
          @onRowSelect="onRowSelect"
          :rows="table2Rows[selectedRouteIndex]? table2Rows[selectedRouteIndex] : []"
          style="overflow: hidden; margin: 0"
      >
      </dispatching-edit-table>
    </align-panel>

    <align-panel id="table3" v-if="tableCount > 2" align="right" width="33%" :gap="2" style="overflow-y: hidden">
      <!--  таблица с рейсами -->
      <dispatching-edit-table
          :selectedRow="selectedRow"
          @onRowDblClick="onMonitoringTaskCardDialog"
          @onRowSelect="onRowSelect"
          :rows="table3Rows[selectedRouteIndex]? table3Rows[selectedRouteIndex] : []"
          style="overflow: hidden; margin: 0"
      >
      </dispatching-edit-table>
    </align-panel>

  </align-container>
</template>

<script>
import DispatchingEditTable from "@/components/trn/dispatching/DispatchingEditTable";

export default {
  name: "DispatchingPanel",
  components: {DispatchingEditTable},
  props: ['monitoringTasks', 'selectedRow'],
  emits: ['onSelectedRow', 'onMonitoringTaskCardDialog'],
  data() {
    return {
      // задания мониторинговой таблицы

      // // выбранная строка
      // selectedRow: null,
      // количество таблиц на экране
      tableCount: 3,
      // строки первой таблицы
      table1Rows: [],
      // строки второй таблицы
      table2Rows: [],
      // строки третьей таблицы
      table3Rows: [],
      // выбранная желтая кнопка маршрутов
      selectedRouteIndex: 0,
      // массив значений интервалов маршрутов
      routesArr: [],
      // интервалы маршрутов для кнопок
      buttonsRoutes: [],
      // интервалы маршрутов для выпадающего списка
      dropdownRoutes: [],
    }
  },

  computed: {},

  watch: {

    monitoringTasks() {
      this.tableCountResize();
    },


  },

  methods: {

    onMonitoringTaskCardDialog() {
      this.$emit('onMonitoringTaskCardDialog');
    },

    // выбрана строка в таблице Рейсов
    onRowSelect(item) {
      this.$emit('onSelectedRow', item);
    },

    onSetRoutes(index) {
      this.selectedRouteIndex = index;
      this.onRowSelect(null);
    },

    tableCountResize() {
      setTimeout(() => {
        let table1Panel = document.getElementById('table1');

        // let table2Panel = document.getElementById('table2');
        //
        // let table3Panel = document.getElementById('table3');

        let tablePanel = document.getElementById('tablePanel');

        if (table1Panel && tablePanel) {

          // проверяем влезает ли три таблицы
          if (tablePanel.offsetWidth > 1320 && table1Panel.offsetWidth >= 431) {
            this.tableCount = 3;
          } else if (tablePanel.offsetWidth >= 889 && table1Panel.offsetWidth <= 1320) {
            this.tableCount = 2;
          } else {
            this.tableCount = 1;
          }
        } else {
          this.tableCount = 3
        }
        this.formTableRows();

        this.buttonsRoutes = [];
        this.dropdownRoutes = [];

        this.formRoutes();
        this.selectedRouteIndex = 0;
        this.onRowSelect(null);
      }, 50)
    },

    formRoutes() {
      // определяем ширину панельки с кнопками
      let routesPanelWidth = document.getElementById('tablePanel');
      if (!routesPanelWidth)
        return 0;
      // определяем область, в которой могут располагаться карточки маршрута
      let routesButtonsWidth = routesPanelWidth.offsetWidth - 10 - 34;
      // определяем сколько кнопок с маршрутами влезает
      let count = Math.floor(routesButtonsWidth / 110);
      this.routesArr.forEach((value, index) => {

        if (index < count) {
          this.buttonsRoutes.push(value);
        } else {
          this.dropdownRoutes.push(value);
        }

      });

    },

    formTableRows() {
      this.routesArr = [];
      this.table1Rows = [];
      this.table2Rows = [];
      this.table3Rows = [];
      // определяем высоту таблицы
      let tableEl = document.getElementById('tablePanel')
      let tableHeight = tableEl.getBoundingClientRect().height;

      // максимальное количество строк в таблице
      let rowsCount = Math.floor(tableHeight / 22) - 1;
      let rows1 = [];
      let rows2 = [];
      let rows3 = [];

      // параметр в какую таблицу добавляем
      let table = 1;
      // параметр номера строки конкретной таблицы;
      let i = 0;

      let startRoute = null;

      for (let z = 0; z < this.monitoringTasks.length; z++) {
        let rowValue = this.monitoringTasks[z];
        if (table == 1) {
          if (i == 0) {
            startRoute = rowValue.__route_num;
          }

          rows1.push(rowValue);
          i++;

          if(z == this.monitoringTasks.length - 1) {
            // прерываем цикл т.к. таблицы заполнены
            this.table1Rows.push(rows1);
            this.routesArr.push(startRoute + ' - ' + rowValue.__route_num);
            break;
          }

          if (i == rowsCount && this.tableCount > 1) {
            table = 2;
            i = 0;
          } else if (i == rowsCount){
            i = 0;
            this.table1Rows.push(rows1);
            rows1 = [];
            this.routesArr.push(startRoute + ' - ' + rowValue.__route_num);
          }

        } else if (table == 2) {
          rows2.push(rowValue);
          i++;
          if(z == this.monitoringTasks.length - 1) {
            // прерываем цикл т.к. таблицы заполнены
            this.table1Rows.push(rows1);
            this.table2Rows.push(rows2);
            this.routesArr.push(startRoute + ' - ' + rowValue.__route_num);
            break;
          }
          if (i == rowsCount && this.tableCount > 2) {
            table = 3;
            i = 0;
          } else if (i == rowsCount ){
            table = 1;
            i = 0;
            this.table1Rows.push(rows1);
            this.table2Rows.push(rows2);
            rows1 = [];
            rows2 = [];
            this.routesArr.push(startRoute + ' - ' + rowValue.__route_num);
          }
        } else if (table == 3) {
          rows3.push(rowValue);
          i++;
          if (i == rowsCount || z == this.monitoringTasks.length - 1) {
            // прерываем цикл т.к. таблицы заполнены
            this.table1Rows.push(rows1);
            this.table2Rows.push(rows2);
            this.table3Rows.push(rows3);
            rows1 = [];
            rows2 = [];
            rows3 = [];
            i = 0;
            table = 1;
            this.routesArr.push(startRoute + ' - ' + rowValue.__route_num);
          }
        }
      }
    },


  },

  mounted() {
    window.addEventListener('resize', this.tableCountResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.tableCountResize);
  },


  // async created() {
  //   await this.$nextTick(() => {
  //     this.formTableRows();
  //   })
  // },

}
</script>

<style scoped>

</style>