import MessageList from './MessageList.vue';
import CrudForm from './CrudForm.vue';
import EditTableCell from './EditTableCell.vue';
import EditTableCellFooter from './EditTableCellFooter.vue';
import EditTable from './EditTable.vue';
import ConfirmMessageBox from './ConfirmMessageBox.vue';
import ErrorMessageBox from './ErrorMessageBox.vue';
import Loading from './Loading.vue';
import PageHeader from './PageHeader.vue';
import InputInteger from "@/components/ui/custom/InputInteger";
import InputFloat from "@/components/ui/custom/InputFloat";
import InputLength from "@/components/ui/custom/InputLength";
import InputCurrency from "@/components/ui/custom/InputCurrency";
import InputMinute from "@/components/ui/custom/InputMinute";
import InputString from "@/components/ui/custom/InputString";
import InputOkato from "@/components/ui/custom/InputOkato";
import InputDate from "@/components/ui/custom/InputDate";
import InputSelect from "@/components/ui/custom/InputSelect";
import InputCheckbox from "@/components/ui/custom/InputCheckbox";
import InputCheckboxSimple from "@/components/ui/custom/InputCheckboxSimple";
import InputSwitch from "@/components/ui/custom/InputSwitch";
import InputSign from "@/components/ui/custom/InputSign";
import InputFile from "@/components/ui/custom/InputFile";
import ReqField from "@/components/ui/custom/ReqField";
import TablePager from "@/components/ui/custom/TablePager";
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import InputTime from "@/components/ui/custom/InputTime";
import InputTextarea from "@/components/ui/custom/InputTextarea";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import InputButtonOption from "@/components/ui/custom/InputButtonOption";
import InputRadio from "@/components/ui/custom/InputRadio";
import LookupInputString from "@/components/ui/custom/LookupInputString";
import LookupInputSelect from "@/components/ui/custom/LookupInputSelect";
import ErrorsDialog from "@/components/ui/custom/ErrorsDialog";
import ErrorsWithStatusDialog from "@/components/ui/custom/ErrorsWithStatusDialog";
import InputDateAndTime from "@/components/ui/custom/InputDateAndTime";
import InputSelectWithCheck from "@/components/ui/custom/InputSelectWithCheck";

// регистрируем пользовательские компоненты
export default function (app) {
    app.component('message-list', MessageList);
    app.component('crud-form', CrudForm);
    app.component('edit-table-cell', EditTableCell);
    app.component('edit-table-cell-footer', EditTableCellFooter);
    app.component('edit-table', EditTable);
    app.component('confirm-message-box', ConfirmMessageBox);
    app.component('error-message-box', ErrorMessageBox);
    app.component('loading', Loading);
    app.component('page-header', PageHeader);
    app.component('input-integer', InputInteger);
    app.component('input-float', InputFloat);
    app.component('input-length', InputLength);
    app.component('input-currency', InputCurrency);
    app.component('input-minute', InputMinute);
    app.component('input-string', InputString);
    app.component('input-okato', InputOkato);
    app.component('input-date', InputDate);
    app.component('input-select', InputSelect);
    app.component('input-checkbox', InputCheckbox);
    app.component('input-checkbox-simple', InputCheckboxSimple);
    app.component('input-switch', InputSwitch);
    app.component('input-sign', InputSign);
    app.component('input-file', InputFile);
    app.component('input-textarea', InputTextarea);
    app.component('req-field', ReqField);
    app.component('table-pager', TablePager);
    app.component('edit-table-panel', EditTablePanel);
    app.component('input-time', InputTime);
    app.component('align-container', AlignContainer);
    app.component('align-panel', AlignPanel);
    app.component('input-button-option', InputButtonOption);
    app.component('input-radio', InputRadio);
    app.component('lookup-input-string', LookupInputString);
    app.component('lookup-input-select', LookupInputSelect);
    app.component('errors-dialog', ErrorsDialog);
    app.component('errors-with-status-dialog', ErrorsWithStatusDialog);
    app.component('input-date-and-time', InputDateAndTime);
    app.component('input-select-with-check', InputSelectWithCheck);
}
