import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ МОДУЛЕЙ

export default {
    state() {
        return {
            // список объектов
            modules: [],
            // время обновления кэша new Date().getTime()
            modulesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            modulesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getModules(state) {
            return state.modules
        },
    },
    mutations: {
        // установлить новый список объектов
        setModules(state, payload) {
            state.modules = payload;
            state.modulesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchModules: (context, payload) => {
            return DoGet(
                '/system/modules',
                'system/setModules',
                [],
                'Запрос модулей',
                false,
                payload && payload.force,
                context.state.modulesCache,
                context.state.modulesCacheTimeout
            )
        },

        // очищает кэш
        doClearModules: (context, payload) => {
            return DoClearCache(
                'system/setModules',
                [],
                payload && payload.force,
                context.state.modulesCache,
                context.state.modulesCacheTimeout
            )
        },
    }
}