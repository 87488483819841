import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ КАТЕГОРИЙ ДОСТУПА

export default {
    state() {
        return {
            // список объектов
            accessCategories: [],
            // время обновления кэша new Date().getTime()
            accessCategoriesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            accessCategoriesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getAccessCategories(state) {
            return state.accessCategories;
        },
    },
    mutations: {
        // установить новый список объектов
        setAccessCategories(state, payload) {
            state.accessCategories = payload;
            state.accessCategoriesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchAccessCategories: (context, payload) => {
            return DoGet(
                '/access/accessCategories',
                'access/setAccessCategories',
                [],
                'Запрос категорий доступа',
                false,
                payload && payload.force,
                context.state.accessCategoriesCache,
                context.state.accessCategoriesCacheTimeout
            )
        },

        // очищает кэш
        doClearAccessCategories: (context, payload) => {
            return DoClearCache(
                'access/setAccessCategories',
                [],
                payload && payload.force,
                context.state.accessCategoriesCache,
                context.state.accessCategoriesCacheTimeout
            )
        },
    }
}
