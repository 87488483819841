<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-access-user-accesses"> </edit-table-panel>
</template>

<script>

import { getDepotGroup, getTransportGroup, getTransportTypeGroup} from "@/store/RefHelpers";
import {getRouteGroup} from "@/store/MultipassHelpers";
import {getAccessCategory} from "@/store/AccessHelpers";

export default {
  props: ['isLoading', 'rows'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "access_category_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
          sorted: 'asc'
        },
        {
          field: "__access_category_name",
          caption: "Категория",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__transport_type_group_name",
          caption: "Гуппа видов транспорта",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__is_transport_type_black_list",
          caption: "Тип списка",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "__depot_group_name",
          caption: "Группа предприятий",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "__is_depot_black_list",
          caption: "Тип списка",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__route_group_name",
          caption: "Группа маршрутов",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__is_route_black_list",
          caption: "Тип списка",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__transport_group_name",
          caption: "Группа ТС",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__is_transport_black_list",
          caption: "Тип списка",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.rows.map(userAccess => {

        const accessCategory = getAccessCategory(userAccess.access_category_id);
        const transportTypeGroup = getTransportTypeGroup(userAccess.transport_type_group_id);
        const transportGroup = getTransportGroup(userAccess.transport_group_id);
        const depotGroup = getDepotGroup(userAccess.depot_group_id);
        const routeGroup = getRouteGroup(userAccess.route_group_id);
        let isTransportTypeBlackList = "";
        let isTransportBlackList = "";
        let isDepotBlackList = "";
        let isRouteBlackList = "";

        if(userAccess.is_transport_type_black_list === true){
          isTransportTypeBlackList = "ч/с";
        } else if (userAccess.is_transport_type_black_list === false) {
          isTransportTypeBlackList = "б/с";
        } else  {
          isTransportTypeBlackList = "";
        }

        if(userAccess.is_transport_black_list === true){
          isTransportBlackList = "ч/с";
        } else if (userAccess.is_transport_black_list === false) {
          isTransportBlackList = "б/с";
        } else  {
          isTransportBlackList = "";
        }

        if(userAccess.is_depot_black_list === true){
          isDepotBlackList = "ч/с";
        } else if (userAccess.is_depot_black_list === false) {
          isDepotBlackList = "б/с";
        } else  {
          isDepotBlackList = "";
        }

        if(userAccess.is_route_black_list === true){
          isRouteBlackList = "ч/с";
        } else if (userAccess.is_route_black_list === false) {
          isRouteBlackList = "б/с";
        } else  {
          isRouteBlackList = "";
        }

        userAccess.__id = userAccess.access_category_id;
        userAccess.__access_category_name = accessCategory ? accessCategory.long_name : "";
        userAccess.__transport_type_group_name = transportTypeGroup ? transportTypeGroup.transport_type_group_name : "";
        userAccess.__transport_group_name = transportGroup ? transportGroup.transport_group_name : "";
        userAccess.__route_group_name = routeGroup ? routeGroup.route_group_name : "";
        userAccess.__depot_group_name = depotGroup ? depotGroup.depot_group_name : "";
        userAccess.__is_transport_type_black_list = isTransportTypeBlackList;
        userAccess.__is_transport_black_list = isTransportBlackList;
        userAccess.__is_depot_black_list= isDepotBlackList;
        userAccess.__is_route_black_list = isRouteBlackList;

        return userAccess;
      });
    },
  }
};
</script>
