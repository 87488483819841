<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to="routeVariantsLink">Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span>Зоны посадки-высадки {{ routeVariantPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="tableButtonsExist"
              :buttonsEnabled="tableButtonsEnabled"
              :filterExists="false"
              @onRefresh="onTableRefresh"
              @onDelete="onTableDelete"
              @onEdit="onTableEdit"
              @onSave="onTableSave"
              @onCancel="onTableCancel"
          >
          </crud-form>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <!-- Таблица  -->
            <align-panel align="left" width="30%" :gap="3">
              <trip-type-zones-edit-table
                  :rowList="tripTypeZones"
                  :selectedRow="selectedRow"
                  :isLoading="isLoading"
                  @onRowSelect="onRowSelect"
              ></trip-type-zones-edit-table>
            </align-panel>
            <!-- Карта  -->
            <align-panel align="all">
              <trip-type-zones-map
                  :tripTypeLines="tripTypeLines"
                  :tripTypeZones="tripTypeZones"
                  :stops="stops"
                  :selectedTripTypeZone="selectedRow"
                  :isEditMode="isEditMode"
                  @onMapCreated="onMapCreated"
                  @onAddTripTypeZone="onTableAdd"
                  @onZoneChanged="onZoneChanged"
              >
              </trip-type-zones-map>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить зону посадки-высадки с кодом {{ id }} ?</confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import { getStop} from "@/store/MultipassHelpers";
import TripTypeZonesMap from "@/components/multipass/trip_type_zones/TripTypeZonesMap";
import TripTypeZonesEditTable from "@/components/multipass/trip_type_zones/TripTypeZonesEditTable";
import {latLngBounds} from "leaflet";
import {addLat, addLong} from "@/components/ui/leaflet/LeafletHelpers";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    TripTypeZonesEditTable,
    TripTypeZonesMap,
  },

  props: [
    // операция
    "op",
    // идентификатор зоны посадки-высадки
    "id",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
  ],

  data() {
    return {
      // карта
      leaflet: null,
      // отображать спиннер
      isLoading: false,
      // активный элемент таблицы
      selectedRow: null,
      // режим редактирования
      isEditMode: false,
      // текущий маршрут
      route: {},
      // текущий вариант маршрута
      routeVariant: {},
      // список конечных остановок
      stops: [],
      // линии типов рейсов
      tripTypeLines: [],
      // зоны посадки высадки
      tripTypeZones: [],
      // исходная зона для восстановления
      originZone: null,
      // признак модификации
      isModified: false,
    }
  },

  computed: {
    // включенные кнопки для таблицы
    tableButtonsExist() {
      return {
        delete: !this.isEditMode,
        edit: !this.isEditMode,
        save: this.isEditMode,
        cancel: this.isEditMode,
        refresh: true,
      }
    },

    // активные кнопки для таблицы
    tableButtonsEnabled() {
      return {
        delete: !!this.selectedRow,
        edit: !!this.selectedRow,
        save: this.isModified,
        cancel: true,
        refresh: true,
      }
    },

    // ссылка на варианты маршрута
    routeVariantsLink() {
      return `/multipass/routes/${this.route_id}/routeVariants`
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },
  },

  methods: {

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // перезапрашиваются данные
      this.refreshData();
    },

    // вызывается, когда добавляется объект
    onTableAdd(item) {
      this.addItem(item)
    },

    // вызывается, когда нажата кнопка Изменить
    onTableEdit() {
      // входим в режим редактирования
      this.isEditMode = true;
      // сбрасываем признак изменения
      this.isModified = false;
      // запоминаем исходную зону
      this.originZone = {...this.selectedRow.zone}
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypeZones/del/${this.selectedRow.trip_type_zone_id}`);
    },

    // нажата кнопка подтверждения удаления
    onConfirmDelete() {
      // возвращаемся к таблице
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypeZones`);
      // удаляем текущий элемент
      this.delItem()
    },

    // отменили операцию
    onRejectOperation() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypeZones`);
    },

    // нажата кнопка Сохранить в режиме редактирования
    onTableSave() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // сохраняем текущий элемент
      this.saveItem();
    },

    // нажата кнопка Отменить в режиме редактирования
    onTableCancel() {
      // выходим из режима редактирования
      this.isEditMode = false;
      // восстанавливаем текущий элемент
      this.restoreItem();
    },

    // вызывается при редактировании зоны
    onZoneChanged(zone) {
      this.selectedRow.zone = zone;
      this.isModified = true;
    },

    // вызывается при выделении строки
    async onRowSelect(item) {

      // сменился элемент в режиме редактирования
      if (this.selectedRow && item !== this.selectedRow && this.isEditMode) {
        // восстанавливаем элемент
        await this.restoreItem();
        // сбрасываем режим редактирования
        this.isEditMode = false;
      }

      // определяем активный элемент таблицы
      this.selectedRow = item;

      // перемещаем карту к активной зоне
      if (this.leaflet && item && item.zone && item.zone.latitude && item.zone.longitude) {
        const minStopZoom = this.$store.getters['settings/getMinStopZoom'];
        const zoom = this.leaflet.getZoom();
        if (zoom >= minStopZoom)
          this.leaflet.setView([item.zone.latitude, item.zone.longitude], zoom)
        else
          this.leaflet.setView([item.zone.latitude, item.zone.longitude], minStopZoom)
      }
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // загружаем список остановок
        await this.$store.dispatch('multipass/doFetchStops');

        // загружаем маршрут
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        }); if (!route) return;
        this.route = route;

        // загружаем вариант маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id
        }); if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // загружаем типы рейса
        let tripTypes = await this.$store.dispatch('multipass/doFetchTripTypes', {
          route_variant_id: this.route_variant_id,
          force: true,
        }); if (!tripTypes) return;

        // нам нужны только производственные
        tripTypes = tripTypes.filter(typ => typ.is_production);

        // загружаем карточки типов рейсов
        const tripCards = await this.$store.dispatch('multipass/doFetchTripTypeCards', {
          trip_type_id_list: tripTypes.map(typ => typ.trip_type_id),
          crop_turn: true,
          force: true,
        }); if (!tripCards) return;

        // загружаем линии типов рейсов
        const tripTypeLines = await this.$store.dispatch('multipass/doFetchTripTypeLines', {
          trip_type_id_list: tripTypes.map(typ => typ.trip_type_id),
          force: true,
        }); if (!tripTypeLines) return;
        this.tripTypeLines = tripTypeLines;

        // загружаем зоны посадки-высадки
        const tripTypeZones = await this.$store.dispatch('multipass/doFetchTripTypeZones', {
          route_variant_id: this.route_variant_id,
          force: true,
        }); if (!tripTypeZones) return;
        this.tripTypeZones = tripTypeZones;

        // определяем первый и последний КП, формируем список конечных остановок
        const stopSet = new Set();
        tripCards.forEach(card => {
          for (let i=0; i<card.length; i++) {
            if (card[i].is_control_point && !card[i].is_skipped) {
              stopSet.add(card[i].stop_id);
              break;
            }
          }
          for (let i=card.length - 1; i>=0; i--) {
            if (card[i].is_control_point && !card[i].is_skipped) {
              stopSet.add(card[i].stop_id);
              break;
            }
          }
        });
        this.stops = [...stopSet].map(stop_id => getStop(stop_id));

        // масштабировать линию типа рейса в экран
        if (this.leaflet) {
          const points = [];
          tripTypeLines.forEach(line => {
            line.points.forEach(point => {
              points.push([point.latitude, point.longitude]);
            })
          });
          const bounds = latLngBounds(points)
          this.leaflet.fitBounds(bounds);
        }
      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // добавляем объект
    async addItem(stop) {
      this.isLoading = true;
      try {

        // координаты остановки
        const coord = [stop.latitude, stop.longitude];

        // координаты точек
        const point1 = addLong(addLat(coord, -50), -50);
        const point2 = addLong(addLat(coord, 50), -50);
        const point3 = addLong(addLat(coord, 50), 50);
        const point4 = addLong(addLat(coord, -50), 50);

        // новая зона
        const tripTypeZone = {
          trip_type_zone_id: null,
          route_variant_id: Number(this.route_variant_id),
          zone: {
            zone_id: null,
            zone_type_id: 2,
            zone_title: stop.long_name,
            latitude: null,
            longitude: null,
            note: '',
            elements: [
              {
                zone_element_id: null,
                zone_element_type_id: 3,
                zone_id: null,
                order_num: 1,
                parameter_size: 0,
                points: [
                  {
                    zone_element_id: null,
                    order_num: 1,
                    latitude: point1[0],
                    longitude: point1[1],
                  },
                  {
                    zone_element_id: null,
                    order_num: 2,
                    latitude: point2[0],
                    longitude: point2[1],
                  },
                  {
                    zone_element_id: null,
                    order_num: 3,
                    latitude: point3[0],
                    longitude: point3[1],
                  },
                  {
                    zone_element_id: null,
                    order_num: 4,
                    latitude: point4[0],
                    longitude: point4[1],
                  }
                ]
              }
            ]
          }
        }

        // запрос на сервер
        const newTripTypeZone = await this.$store.dispatch('multipass/doAddTripTypeZone', tripTypeZone);
        // мутируем массив
        this.selectedRow = addMutation(this.tripTypeZones, 'trip_type_zone_id', newTripTypeZone);
      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем текущий объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрос на сервер
        const newTripTypeZone = await this.$store.dispatch('multipass/doDelTripTypeZone', this.selectedRow);
        // мутируем массив
        this.selectedRow = delMutation(this.tripTypeZones, 'trip_type_zone_id', newTripTypeZone);

      }
      finally {
        this.isLoading = false;
      }
    },

    // восстанавливаем текущий объект
    restoreItem() {
      this.selectedRow.zone = this.originZone;
    },

    // сохраняем текущий объект
    async saveItem() {
      this.isLoading = true;
      try {

        // запрос на сервер
        const newTripTypeZone = await this.$store.dispatch('multipass/doEditTripTypeZone', {
          ...this.selectedRow
        });
        // мутируем массив
        this.selectedRow = editMutation(this.tripTypeZones, 'trip_type_zone_id', newTripTypeZone);

      }
      finally {
        this.isLoading = false;
      }
    }
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

