<template>


  <accordion mode="single">

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group1">
          Основные
        </button-target>
      </card-header>
    </card>
    <collapse ref="group1" active>
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/rmt/routes"
          >Список маршрутов</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/rmt/seasons"
          >Сезоны</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/rmt/scheduleTypes"
          >Типы расписаний</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/rmt/regimes"
          >Режимы работы водителя</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/rmt/scheduleEvents"
          >События</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/rmt/holidays"
          >Праздничные дни</left-menu-item>
        </li>
      </ul>
    </collapse>

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group2">
          Дополнительные
        </button-target>
      </card-header>
    </card>
    <collapse ref="group2">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/rmt/movementKinds"
          >Виды движения</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/rmt/transportationKinds"
          >Виды перевозок</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/rmt/scheduleEventTypes"
          >Типы событий</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/rmt/indicators"
          >Показатели</left-menu-item>
        </li>
      </ul>
    </collapse>

  </accordion>
</template>
