import TransportTypesRefStore from './TransportTypesRefStore'
import TransportClassesRefStore from "@/store/ref/TransportClassesRefStore";
import EngineFuelTypesRefStore from "@/store/ref/EngineFuelTypesRefStore";
import EmissionStandardsRefStore from "@/store/ref/EmissionStandardsRefStore";
import LegalFormsRefStore from "@/store/ref/LegalFormsRefStore";
import TransportTypeGroupsRefStore from "@/store/ref/TransportTypeGroupsRefStore";
import TransportGroupsRefStore from "@/store/ref/TransportGroupsRefStore";
import DepotGroupsRefStore from "@/store/ref/DepotGroupsRefStore";
import DepotsRefStore from "@/store/ref/DepotsRefStore";
import OrganizationsRefStore from "@/store/ref/OrganizationsRefStore";
import ModelsRefStore from "@/store/ref/ModelsRefStore";
import PersonnelJobsRefStore from "@/store/ref/PersonnelJobsRefStore";
import PersonnelRefStore from "@/store/ref/PersonnelRefStore";
import TransportRefStore from "@/store/ref/TransportRefStore";
import TransportTypeGroupLinksRefStore from "@/store/ref/TransportTypeGroupLinksRefStore";
import TransportGroupLinksRefStore from "@/store/ref/TransportGroupLinksRefStore";
import DepotGroupLinksRefStore from "@/store/ref/DepotGroupLinksRefStore";
import AreaClassifierRefStore from "@/store/ref/AreaClassifierRefStore";
import TransportStatesRefStore from "@/store/ref/TransportStatesRefStore";
import PersonnelStatesRefStore from "@/store/ref/PersonnelStatesRefStore";
import DepotDetailsRefStore from "@/store/ref/DepotDetailsRefStore";
import OrganizationDetailsRefStore from "@/store/ref/OrganizationDetailsRefStore";

// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ REF

export default {
    namespaced: true,
    modules: {
        TransportTypesRefStore,
        TransportClassesRefStore,
        EngineFuelTypesRefStore,
        EmissionStandardsRefStore,
        LegalFormsRefStore,
        TransportTypeGroupsRefStore,
        TransportGroupsRefStore,
        DepotGroupsRefStore,
        DepotsRefStore,
        OrganizationsRefStore,
        ModelsRefStore,
        PersonnelJobsRefStore,
        PersonnelRefStore,
        TransportRefStore,
        TransportTypeGroupLinksRefStore,
        TransportGroupLinksRefStore,
        DepotGroupLinksRefStore,
        AreaClassifierRefStore,
        TransportStatesRefStore,
        PersonnelStatesRefStore,
        DepotDetailsRefStore,
        OrganizationDetailsRefStore
    }
}
