<template>
  <align-container>
    <align-panel align="top">
      <page-header>
        <span
            v-if="('Генератор' + ' / ' + 'Список маршрутов' + ' / ' + 'Варианты маршрута ' + routePageHeader + ' / ' + 'Варианты расписаний ' + routeVariantPageHeader + ' / ' + 'Наборы графиков ' + scheduleVariantPageHeader + ' / ' + 'Графики движения ' + scheduleGraphSetPageHeader).length < 225"><router-link
            to="/rmtgen">Генератор</router-link></span>
        <span
            v-if="('Генератор' + ' / ' + 'Список маршрутов' + ' / ' + 'Варианты маршрута ' + routePageHeader + ' / ' + 'Варианты расписаний ' + routeVariantPageHeader + ' / ' + 'Наборы графиков ' + scheduleVariantPageHeader + ' / ' + 'Графики движения ' + scheduleGraphSetPageHeader).length < 225"> / </span>
        <span
            v-if="('Список маршрутов' + ' / ' + 'Варианты маршрута ' + routePageHeader + ' / ' + 'Варианты расписаний ' + routeVariantPageHeader + ' / ' + 'Наборы графиков ' + scheduleVariantPageHeader + ' / ' + 'Графики движения ' + scheduleGraphSetPageHeader).length < 225"><router-link
            to="/rmtgen/routes">Список маршрутов</router-link></span>
        <span
            v-if="('Список маршрутов' + ' / ' + 'Варианты маршрута ' + routePageHeader + ' / ' + 'Варианты расписаний ' + routeVariantPageHeader + ' / ' + 'Наборы графиков ' + scheduleVariantPageHeader + ' / ' + 'Графики движения ' + scheduleGraphSetPageHeader).length < 225"> / </span>
        <span
            v-if="('Варианты маршрута ' + routePageHeader + ' / ' + 'Варианты расписаний ' + routeVariantPageHeader + ' / ' + 'Наборы графиков ' + scheduleVariantPageHeader + ' / ' + 'Графики движения ' + scheduleGraphSetPageHeader).length < 225"><router-link
            :to=routeVariantsLink>Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span
            v-if="('Варианты маршрута ' + routePageHeader + ' / ' + 'Варианты расписаний ' + routeVariantPageHeader + ' / ' + 'Наборы графиков ' + scheduleVariantPageHeader + ' / ' + 'Графики движения ' + scheduleGraphSetPageHeader).length < 225"> / </span>
        <span
            v-if="('Варианты расписаний ' + routeVariantPageHeader + ' / ' + 'Наборы графиков ' + scheduleVariantPageHeader + ' / ' + 'Графики движения ' + scheduleGraphSetPageHeader).length < 225"><router-link
            :to=scheduleVariantsLink>Варианты расписаний {{ routeVariantPageHeader }}</router-link></span>
        <span
            v-if="('Варианты расписаний ' + routeVariantPageHeader + ' / ' + 'Наборы графиков ' + scheduleVariantPageHeader + ' / ' + 'Графики движения ' + scheduleGraphSetPageHeader).length < 225"> / </span>
        <span
            v-if="('Наборы графиков ' + scheduleVariantPageHeader + ' / ' + 'Графики движения ' + scheduleGraphSetPageHeader).length < 225"><router-link
            :to=scheduleGraphSetLink>Наборы графиков {{ scheduleVariantPageHeader }}</router-link></span>
        <span
            v-if="('Наборы графиков ' + scheduleVariantPageHeader + ' / ' + 'Графики движения ' + scheduleGraphSetPageHeader).length < 225"> / </span>
        <span>Графики движения {{ scheduleGraphSetPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <align-panel align="top" :gap="0">
          <div class="row" style="margin-bottom: 4px">

            <input-button-option
                v-model="viewType"
                :itemList="[{id:0, title: 'fas fa-table', tooltipText: 'Горизонтальная сетка'}, {id:1, title: 'fas fa-grip-lines', tooltipText: 'Линии'}, {id:2, title: 'fas fa-chart-area', tooltipText: 'Пила'}]"
                required
                style="margin-left: 15px"
                :buttonsStyle="'height:31px; padding-top: 3px;'"
                :inputType="'button'"
                @change="getLineScheduleGraphs"
            ></input-button-option>

            <button
                type="button"
                class="btn btn-success btn-sm"
                title="Очистить все"
                style="height: 31px; margin-left: 5px"
            >
              <i class="fas fa-edit"></i>
            </button>
            <dropdown style="z-index: 100; margin-left: 5px;" title="Выпуск">
              <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                              style="height: 31px; padding: 1px 6px !important;">
                <i class="fas fa-edit btn-sm"></i>
              </button-toggler>
              <dropdown-menu ref="collapse">
                <dropdown-item>Выпустить</dropdown-item>
                <dropdown-divider></dropdown-divider>
                <dropdown-item>Из парка</dropdown-item>
                <dropdown-item>С тех.операции</dropdown-item>
              </dropdown-menu>
            </dropdown>
            <dropdown style="z-index: 100; margin-left: 5px;" title="Информация">
              <button-toggler target-ref="collapse1" class="btn dropdown-toggle btn-primary"
                              style="height: 31px;  padding: 1px 6px !important;">
                <i class="fas fa-edit btn-sm"></i>
              </button-toggler>
              <dropdown-menu ref="collapse1">
                <dropdown-item @click="setTableMode('stand')">
                  Стоянки
                  <i v-if="tableMode == 'stand'" class="fas fa-check btn-sm"></i>
                </dropdown-item>
                <!--                <dropdown-divider></dropdown-divider>-->
                <dropdown-item @click="setTableMode('interval')">
                  Интервал
                  <i v-if="tableMode == 'interval'" class="fas fa-check btn-sm"></i>
                </dropdown-item>
                <dropdown-item @click="setTableMode('standAndInterval')">
                  Стоянки и интервалы
                  <i v-if="tableMode == 'standAndInterval'" class="fas fa-check btn-sm"></i>
                </dropdown-item>
              </dropdown-menu>
            </dropdown>

            <button
                type="button"
                class="btn btn-primary btn-sm"
                title="Очистить все"
                style="height: 31px; margin-left: 5px"
                @click="reduceIndex"
            >
              <i class="fas fa-arrow-alt-circle-left"></i>
            </button>

            <div
                v-for="value in itemsToShow"
                :key="value"
                style="height: 31px; width: 40px; text-align: center; border: solid black 1px; font-size: 0.75rem; margin: 0;"
                class="row"
            >
              <div
                  style="width: 40px; height: 12px"
              >
                {{ value.id }}
              </div>
              <div
                  style="width: 40px; height: 15px"
              >
                {{ value.title }}
              </div>
            </div>

            <button
                type="button"
                class="btn btn-primary btn-sm"
                title="Очистить все"
                style="height: 31px;"
                @click="increaseIndex"
            >
              <i class="fas fa-arrow-alt-circle-right"></i>
            </button>

            <button
                type="button"
                class="btn btn-primary btn-sm"
                title="Очистить все"
                style="height: 31px; margin-left: 5px"
                @click="reduceIndex"
            >
              <i class="fas fa-arrow-alt-circle-left"></i>
            </button>

            <div
                v-for="value in itemsToShow"
                :key="value"
                style="height: 31px; width: 40px; text-align: center; border: solid black 1px; font-size: 0.75rem; margin: 0;"
                class="row"
            >
              <div
                  style="width: 40px; height: 12px"
              >
                {{ value.id }}
              </div>
              <div
                  style="width: 40px; height: 15px"
              >
                {{ value.title }}
              </div>
            </div>

            <button
                type="button"
                class="btn btn-primary btn-sm"
                title="Очистить все"
                style="height: 31px;"
                @click="increaseIndex"
            >
              <i class="fas fa-arrow-alt-circle-right"></i>
            </button>

            <div class="col" style="margin-bottom: 0px; padding: 1px;"></div>

            <button
                v-if="viewType == 2 || viewType == 1"
                class="btn btn-outline-dark"
                style="padding: 0px; position: relative; width: 31px; border-radius: 0.25rem; border: 1px solid black;"
                @click="changeSawViewType"
            >
              <i
                  v-if="sawViewType == 1 || sawViewType == 2"
                  class='fas fa-check'
                  style='color: rgb(229, 41, 255); position: absolute;  top: 9px; left:7px;z-index: 1;'
              ></i>
              <i
                  v-if="sawViewType == 0 || sawViewType == 2"
                  class='fas fa-check'
                 style='color: rgb( 35, 152, 0); position: absolute;  top: 4px; left: 7px;'
              ></i>

            </button>

            <input-select
                v-if="viewType == 2"
                v-model="sawOneDiv"
                class="form-control"
                :itemList="[{id: 3, title: '10ч'}, {id: 6, title: '5ч'}, {id: 7, title: '4ч'}, {id: 10, title: '3ч'}]"
                style="width: 60px; margin-bottom: 0px; margin-left: 5px; height: 31px; padding-left: 0px; padding-right: 0px; padding-top: 3px"
                title="Масштаб"
            ></input-select>

            <button
                type="button"
                class="btn btn-primary btn-sm"
                title="Настройки"
                style="margin-left: 5px; height: 31px; right: 5px; margin-right: 15px;"
                @click="onDialogOpen"
            >
              Настройки
            </button>

          </div>
        </align-panel>
        <align-panel align="all" v-if="viewType == 0">
          <!--  табличное представление расписания -->
          <schedule-graph-set-table
              :rows="tripsArr"
              :tableMode="tableMode"
              :route_variant_id="route_variant_id"
              :dialogTitle="dialogTitle"
              :tripTypes="tripTypes"
              :greenStop="greenStop"
              :pinkStop="pinkStop"
              :tripTypeCards="tripTypeCard"
              @doEditGraph="doEditGraph"
              @doEditTripBegin="doEditTripBegin"
              @doEditStop="doEditStop"
              @doDelLeftTrip="doDelLeftTrip"
              @doStartTrafficGraph="doStartTrafficGraph"
              @doMinusMin="doMinusMin"
              @doPlusMin="doPlusMin"
              @doChangeTripType="doChangeTripType"
              @doExtensionTrafficGraph="doExtensionTrafficGraph"
          >
          </schedule-graph-set-table>
        </align-panel>
        <align-panel v-else-if="viewType == 1" id="scrollPanel" align="all" style="overflow-x: auto;overflow-y: auto" @scroll="onScroll">
          <line-schedule-graph-panel
            :schedule_graph_set_id="schedule_graph_set_id"
            :schedulesArr="schedulesArr"
            :tripTypeCards="tripTypeCard"
            :tripTypes="tripTypes"
            :route_variant_id="route_variant_id"
            :hoursArray="hoursArray"
            :cpArr="cpItems"
            :selectedDirection="sawViewType"
            @doEditGraph="doEditGraph"
            @doMinusMin="doMinusMin"
            @doPlusMin="doPlusMin"
            @doDelLeftTrip="doDelLeftTrip"
            @doEditTripBegin="doEditTripBegin"
            @doEditStop="doEditStop"
            @doExtensionTrafficGraph="doExtensionTrafficGraph"
            @doChangeTripType="doChangeTripType"
          >
          </line-schedule-graph-panel>
        </align-panel>
        <align-panel v-else-if="viewType == 2" id="scrollPanel" align="all" style="overflow-x: auto;overflow-y: auto">

          <saw-schedule-graph-panel
            :schedulesArr="schedulesArr"
            :oneDiv="sawOneDiv"
            :hoursArray="hoursArray"
            :sawViewType="sawViewType"
            :cpArr="cpItems"
          >
          </saw-schedule-graph-panel>

        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <schedule-analysis-dialog
      v-if="isInfoDialogVisible"
      @onCancel="changeInfoDialogVisible"
      :schedule_graph_set_id="schedule_graph_set_id"
      :schedulesArr="schedulesArr"
      :hoursArray="hoursArray"
  >
  </schedule-analysis-dialog>

  <matrix-dialog
    :base_trip_type_short="base_trip_type_short"
    :isMatrixDialogVisible="isMatrixDialogVisible"
    :itemsList="cpItems"
    :tripTypes="tripTypes"
    @onClose="onDialogOpen"
  >
  </matrix-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import PageMixin from "@/pages/share/PageMixin";
import ScheduleGraphSetTable from "@/components/rmtgen/horizontal_table/ScheduleGraphSetTable";
import {preventDefault} from "leaflet/src/dom/DomEvent";
import {quickSort, signSortFn} from "@/lib";
import ScheduleAnalysisDialog from "@/components/rmtgen/ScheduleAnalysisDialog";
import LineScheduleGraphPanel from "@/components/rmtgen/LineScheduleGraphPanel";
import SawScheduleGraphPanel from "@/components/rmtgen/SawScheduleGraphPanel";
import InputSelect from "@/components/ui/custom/InputSelect";
import MatrixDialog from "@/components/rmtgen/MatrixDialog";


export default {
  name: "ScheduleGraphSetTablePage",
  mixins: [PageMixin],
  components: {
    MatrixDialog,
    InputSelect,
    LineScheduleGraphPanel,
    SawScheduleGraphPanel,
    ScheduleAnalysisDialog,
    ScheduleGraphSetTable,
    AlignPanel, AlignContainer
  },
  props: [
    'op',
    'route_id',
    "route_variant_id",
    "schedule_variant_id",
    "schedule_graph_set_id",
    "id",
  ],
  data() {
    return {
      // отображать спиннер
      isLoading: false,
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // вариант расписания
      scheduleVariant: {},
      // наборы графиков
      scheduleGraphSet: {},
      // массив рейсов расписания
      tripsArr: [],
      // режим отображения таблицы
      tableMode: '',
      // видна ли матрица настроек
      isMatrixDialogVisible: false,
      // массив значений для отображения в окошках сверху
      itemList: [
        {
          id: 11,
          title: "111:11"
        },
        {
          id: 22,
          title: "222:22"
        },
        {
          id: 33,
          title: "333:33"
        },
        {
          id: 44,
          title: "444:44"
        },
        {
          id: 55,
          title: "555:55"
        },
        {
          id: 66,
          title: "666:66"
        },
        {
          id: 77,
          title: "777:77"
        },
        {
          id: 88,
          title: "888:88"
        },
        {
          id: 99,
          title: "999:99"
        },
      ],
      // индекс начального элемента отображения
      startIndex: 0,
      // видимость инфорамционного диалога (открывается по нажатию кнопки F1 на клавиатуре)
      isInfoDialogVisible: false,
      // название диалога
      dialogTitle: 'Выпуск из парка',
      // массив триптайпов
      tripTypes: [],
      // массив tripTypeCard
      tripTypeCard: [],
      // остановка зеленой колонки в таблице
      greenStop: null,
      // остановка розовой колонки в таблице
      pinkStop: null,
      // тип отображения
      viewType: 0,
      // значение скролла для корректного отображения цифр номера расписания
      scrollValue: 0,
      // массив расписаний
      schedulesArr: [],
      // величина одного деления в графике Пила
      sawOneDiv: 3,
      // отображение рейсов на графике Пила (0 - direction 0; 1 - direction 1; 2 - все рейсы)
      sawViewType: 2,
      // массив часов для верхней шкалы
      hoursArray: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28],
      // массив КПшек для отображения горизонтальных линий КП
      cpArr: [
        {
          direction: 0,
          percent: 0,
          isFinal: true,
          stop_id: 10152,
          tripTypes: ['00', ]
        },
        {
          direction: 0,
          percent:10,
          isFinal: true,
          stop_id: 11299,
          tripTypes: ['00', 'И01', 'И02', 'И03','03',]
        },
        {
          direction: 0,
          percent: 23,
          isFinal: false,
          stop_id: 11299,
          tripTypes: ['00', 'И01', 'И02', 'И03','У02','У03','03',]
        },
        {
          direction: 0,
          percent: 45,
          isFinal: false,
          stop_id: 11299,
          tripTypes: ['00', 'И01', 'И02', 'У03','03',]
        },
        {
          direction: 0,
          percent: 51,
          isFinal: false,
          stop_id: 10152,
          tripTypes: ['00', 'И01', 'И02', 'И03','У02','У03','03',]
        },
        {
          direction: 0,
          percent: 65,
          isFinal: false,
          stop_id: 11299,
          tripTypes: ['00', 'И01', 'И02', 'И03',]
        },
        {
          direction: 0,
          percent: 95,
          isFinal: true,
          stop_id: 10151,
          tripTypes: ['00', 'И01', 'И02', 'И03','03',]
        },
        {
          direction: 0,
          percent: 100,
          isFinal: true,
          stop_id: 10152,
          tripTypes: ['00', 'И01', 'И02', 'И03','У02','У03','03',]
        },
        {
          direction: 1,
          percent: 100,
          isFinal: true,
          stop_id: 10151,
          tripTypes: ['00', 'И01', 'И02', 'У03','03',]
        },

        {
          direction: 1,
          percent: 95,
          isFinal: true,
          stop_id: 11299,
          tripTypes: ['00', 'И01', 'И02', '03',]
        },
        {
          direction: 1,
          percent: 90,
          isFinal: true,
          stop_id: 10151,
          tripTypes: ['00', 'И01', 'И02', ]
        },
        // {
        //   direction: 0,
        //   percent: 90,
        //   isFinal: true,
        // },
        {
          direction: 1,
          percent: 88,
          isFinal: false,
          stop_id: 10152,
          tripTypes: ['00', 'И01', 'И02', 'И03',]
        },
        {
          direction: 1,
          percent: 82,
          isFinal: false,
          stop_id: 11299,
          tripTypes: ['00', 'И01', 'И02', 'И03','У02','У03','03',]
        },
        {
          direction: 1,
          percent: 53,
          isFinal: false,
          stop_id: 10152,
          tripTypes: ['У02','У03','03',]
        },
        {
          direction: 1,
          percent: 50,
          isFinal: false,
          stop_id: 10152,
          tripTypes: ['00', 'И01', 'И02', 'И03','У02','У03','03',]
        },
        {
          direction: 1,
          percent: 30,
          isFinal: false,
          stop_id: 10151,
          tripTypes: ['00', 'И01', 'И02', 'И03','У02','У03','03',]
        },
        {
          direction: 1,
          percent: 10,
          isFinal: true,
          stop_id: 11299,
          tripTypes: ['00', 'И01', 'У03','03',]
        },

        {
          direction: 1,
          percent: 5,
          isFinal: true,
          stop_id: 10152,
          tripTypes: ['00', 'У02','У03','03',]
        },
        {
          direction: 1,
          percent: 0,
          isFinal: true,
          stop_id: 10151,
          tripTypes: ['00', 'И01', 'И02', 'И03','У02','У03','03',]
        },
        // {
        //   direction: 0,
        //   percent: 5,
        //   isFinal: true,
        // },
      ],
      base_trip_type_short: null,
    }
  },

  computed: {

    cpItems() {
      return this.cpArr.map(value => {
        value.__isVisible = true;
        return value;
      })
    },

    // конструкция для отображения только выбранного диапазона значений в окнах сверху
    itemsToShow() {
      let resultArr = [];
      for (let i = this.startIndex; i < this.startIndex + 5; i++) {
        if (this.itemList[i]) {
          resultArr.push(this.itemList[i]);
        }
      }
      return resultArr;
    },

    // ссылка для перехода к вариантам маршрутов
    routeVariantsLink() {
      return `/rmtgen/routes/${this.route_id}/routeVariants`
    },

    // ссылка для перехода к вариантам расписаний
    scheduleVariantsLink() {
      return `/rmtgen/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`;
    },

    // ссылка для перехода к наборам графиков
    scheduleGraphSetLink() {
      return `/rmtgen/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets`;
    },

  },

  methods: {

    changeSawViewType(){
      if (this.viewType == 2) {
        if(this.sawViewType == 2) {
          this.sawViewType = 0;
        } else if (this.sawViewType == 1) {
          this.sawViewType = 2;
        } else if (this.sawViewType == 0) {
          this.sawViewType = 1;
        }
      } else if (this.viewType == 1) {
        if (this.sawViewType == 1) {
          this.sawViewType = 0;
        } else if (this.sawViewType == 0) {
          this.sawViewType = 1;
        }
      }
    },

    async getLineScheduleGraphs() {
      if(this.viewType == 1 && this.sawViewType == 2) {
        this.sawViewType = 0;
      }
      //this.schedulesArr = await this.$store.dispatch('rmtgen/doFetchLineScheduleGraphs');
      //let schedulesArr = await this.$store.dispatch('rmtgen/doFetchScheduleGraphSetTable', {
      let schedulesArr = await this.$store.dispatch('rmtgen/doFetchScheduleGraphSetGraphic', {
        schedule_graph_set_id: this.schedule_graph_set_id,
      });
      if (schedulesArr) {
        this.schedulesArr = schedulesArr;
        if (this.schedulesArr.length > 0) {
          this.base_trip_type_short = this.schedulesArr[0].base_trip_type_short;
          let hoursArray = [];
          let minHours = 0;
          let maxHours = 14400;
          let graph_time = 0;
          this.schedulesArr.forEach(graph => {
            // задаем параметры КП (когда данные будут приходить с КП данный раздел удалить)
            graph.trip_table_columns.forEach(value => {
              if(value.trip_complex.direction == 0
                  // && getSignTitle(value.trip_complex.trip_type_sign).replaceAll('_', '').replaceAll('<', '').replaceAll('>', '').replaceAll(' ', '') == "00"
              ) {
                value.trip_complex.cp_length_list = [0,10,23,45,51,65,95,100];
                value.trip_complex.cp_time_list = [value.trip_complex.step_time_begin,
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.10),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.40),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.45),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.51),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.65),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.95),
                  (value.trip_complex.step_time_end)]
              } else if (value.trip_complex.direction == 1
                  // && getSignTitle(value.trip_complex.trip_type_sign).replaceAll('_', '').replaceAll('<', '').replaceAll('>', '').replaceAll(' ', '') == "00"
              ) {
                value.trip_complex.cp_length_list = [100,95,90,88,82,53,50,30,10,5,0];
                value.trip_complex.cp_time_list = [value.trip_complex.step_time_begin,
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.05),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.10),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.30),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.45),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.55),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.60),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.70),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.90),
                  value.trip_complex.step_time_begin + ((value.trip_complex.step_time_end - value.trip_complex.step_time_begin)*0.95),
                  (value.trip_complex.step_time_end)]
              }
            })

            graph_time = 0;
            if (graph.trip_table_columns[0].trip_complex.step_time_begin > 0) {
              graph_time = graph.trip_table_columns[0].trip_complex.step_time_begin;
            }
            if (graph.trip_table_columns[0].trip_complex.null_step_time_begin < graph_time) {
              graph_time = graph.trip_table_columns[0].trip_complex.null_step_time_begin;
            }
            if (graph_time > 0) {
              if ((minHours > graph_time) || (minHours === 0)) {
                minHours = graph_time;
                if (minHours >= maxHours) {
                  maxHours = minHours + 3600;
                }
              }
            }
            graph_time = 0;
            if (graph.trip_table_columns[graph.trip_table_columns.length - 1].trip_complex.step_time_end > 0) {
              graph_time = graph.trip_table_columns[graph.trip_table_columns.length - 1].trip_complex.step_time_end;
            }
            if (graph.trip_table_columns[graph.trip_table_columns.length - 1].trip_complex.null_step_time_end > graph_time) {
              graph_time = graph.trip_table_columns[graph.trip_table_columns.length - 1].trip_complex.null_step_time_end;
            }
            if (graph_time > 0) {
              if (maxHours < graph_time) {
                maxHours = graph_time;
              }
            }
          })
          minHours = Math.trunc(minHours / 3600);
          maxHours = Math.trunc(maxHours / 3600);
          for (let i = minHours; i < maxHours + 1; i++) {
            hoursArray.push(i);
          }
          this.hoursArray = hoursArray
        }
      }
    },

    onScroll(){
      let value = document.getElementById('scrollPanel').scrollLeft;
      this.schedulesArr.forEach(graph => {
        document.getElementById('scheduleNumber'+ graph.trip_table_columns[0].trip_complex.graph).setAttribute('x', value);
      })
    },

    reduceIndex() {
      if (this.startIndex > 0) {
        this.startIndex = this.startIndex - 1;
      }
    },

    increaseIndex() {
      if ((this.startIndex + 5) < this.itemList.length) {
        this.startIndex = this.startIndex + 1;
      }
    },

    onDialogOpen() {
      this.isMatrixDialogVisible = !this.isMatrixDialogVisible;
    },

    // задаем режим отображения таблицы
    setTableMode(value) {
      if (this.tableMode == value) {
        this.tableMode = '';
      } else {
        this.tableMode = value;
      }
    },

    // редактирование строки наряда
    async doEditGraph(specifiedTrip, rowNum, colNum) {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение номера графика
        /*await this.$store.dispatch('kvr/doEditManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          manual_order_id: row.manual_order_id,
          value: row,
        });*/
        console.log(specifiedTrip, rowNum, colNum, "API запрос на редактирование номера графика");
      } finally {
        this.isLoading = false;
      }
    },

    async doEditTripBegin(specifiedTrip, rowNum, colNum) {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение времени начала рейса
        /*await this.$store.dispatch('kvr/doEditManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          manual_order_id: row.manual_order_id,
          value: row,
        });*/
        console.log(specifiedTrip, rowNum, colNum, "API запрос на редактирование времени начала рейса");
      } finally {
        this.isLoading = false;
      }
    },

    async doEditStop(specifiedTrip, rowNum, colNum) {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение времени остановки
        /*await this.$store.dispatch('kvr/doEditManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          manual_order_id: row.manual_order_id,
          value: row,
        });*/
        console.log(specifiedTrip, rowNum, colNum, "API запрос на редактирование времени остановки");
      } finally {
        this.isLoading = false;
      }
    },

    async doDelLeftTrip(specifiedTrip, rowNum, colNum) {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение времени остановки
        /*await this.$store.dispatch('kvr/doEditManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          manual_order_id: row.manual_order_id,
          value: row,
        });*/
        console.log(specifiedTrip, rowNum, colNum, "API запрос на удаление рейса слева");
      } finally {
        this.isLoading = false;
      }
    },

    async doStartTrafficGraph(specifiedTrip, rowNum, colNum) {
      try {
        this.isLoading = true;
        // отправляем запрос на запуск движения на графике
        /*await this.$store.dispatch('kvr/doEditManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          manual_order_id: row.manual_order_id,
          value: row,
        });*/
        console.log(specifiedTrip, rowNum, colNum, "API запрос на запуск движения на графике");
      } finally {
        this.isLoading = false;
      }
    },

    async doMinusMin(specifiedTrip, rowNum, colNum) {
      try {
        this.isLoading = true;
        // отправляем запрос на уменьшение стоянки
        /*await this.$store.dispatch('kvr/doEditManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          manual_order_id: row.manual_order_id,
          value: row,
        });*/
        console.log(specifiedTrip, rowNum, colNum, "API запрос на уменьшение стоянки");
      } finally {
        this.isLoading = false;
      }
    },

    async doPlusMin(specifiedTrip, rowNum, colNum) {
      try {
        this.isLoading = true;
        // отправляем запрос на увеличение стоянки
        /*await this.$store.dispatch('kvr/doEditManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          manual_order_id: row.manual_order_id,
          value: row,
        });*/
        console.log(specifiedTrip, rowNum, colNum, "API запрос на увеличение стоянки");
      } finally {
        this.isLoading = false;
      }
    },

    async doChangeTripType(specifiedTrip, changedTripType, selected_schedule_event_id, selected_null_trip_type_id, selected_production_trip_type_id) {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение типа рейса
        /*await this.$store.dispatch('kvr/doEditManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          manual_order_id: row.manual_order_id,
          value: row,
        });*/
        console.log(specifiedTrip, changedTripType, selected_schedule_event_id, selected_null_trip_type_id, selected_production_trip_type_id, "API запрос на изменение типа рейса");
      } finally {
        this.isLoading = false;
      }
    },

    async doExtensionTrafficGraph(specifiedTrip, rowNum, colNum) {
      try {
        this.isLoading = true;
        // отправляем запрос на продление движения по графику
        /*await this.$store.dispatch('kvr/doEditManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          manual_order_id: row.manual_order_id,
          value: row,
        });*/
        console.log(specifiedTrip, rowNum, colNum, "API запрос на продление движения по графику");
      } finally {
        this.isLoading = false;
      }
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = true;
      try {

        // запрос маршрута
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        });
        if (!route) return;
        this.route = route;

        // запрос варианта маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id,
        });
        if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // запрос варианта расписания
        const scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
          schedule_variant_id: this.schedule_variant_id,
        });
        if (!scheduleVariant) return;
        this.scheduleVariant = scheduleVariant;

        // запрос набора графиков
        const scheduleGraphSet = await this.$store.dispatch('rmt/doFetchScheduleGraphSet', {
          schedule_graph_set_id: this.schedule_graph_set_id,
        });
        if (!scheduleGraphSet) return;
        this.scheduleGraphSet = scheduleGraphSet;

        // по route_variant_id получаем tripType-ы
        let tripTypes = await this.$store.dispatch('multipass/doFetchTripTypes', {
          route_variant_id: this.route_variant_id
        });
        if(tripTypes) {
          quickSort(tripTypes, (l, r) => {
            const leftItem = l.trip_type_sign;
            const rightItem = r.trip_type_sign;
            return signSortFn(leftItem, rightItem);
          });
          this.tripTypes = tripTypes;

          for (const tripType of this.tripTypes) {
            // если есть tripTypes запрашиваем трассу типа рейса
            let tripTypeCards = await this.$store.dispatch('multipass/doFetchTripTypeCard', {
              trip_type_id: tripType.trip_type_id,
            });
            if(tripTypeCards)
              this.tripTypeCard[tripType.trip_type_id] = tripTypeCards;
          }

          // получаем массив остановок первого tripType
          let ttCards = this.tripTypeCard[this.tripTypes[0].trip_type_id];

          if(this.tripTypes[0].direction == 0){
            this.greenStop = ttCards[0];
            this.pinkStop = ttCards[ttCards.length-1];
          } else {
            this.greenStop = ttCards[ttCards.length-1];
            this.pinkStop = ttCards[0];
          }
        }

      } finally {
        this.isLoading = false;
      }
    },

    // запрос данных для таблицы
    async getScheduleGraphSetTable() {
      this.isLoading = true;
      try {
        let tripsArr = await this.$store.dispatch('rmtgen/doFetchScheduleGraphSetTable', {
          schedule_graph_set_id: this.schedule_graph_set_id,
        })
        if (tripsArr) {
          this.tripsArr = tripsArr;
        }
      } finally {
        this.isLoading = false;
      }
    },

    // отменяем скролл на странице при нажатии стрелочек
    preventDefaultForKeys(e) {
      let keys = {112: 1,};
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    },

    changeInfoDialogVisible() {
      this.isInfoDialogVisible = !this.isInfoDialogVisible;
    }

  },

  async created() {
    // загрузка остановок
    await this.$store.dispatch('multipass/doFetchStops');
    // загрузка событий
    await this.$store.dispatch('rmt/doFetchScheduleEvents');
    await this.refreshData();
    await this.getScheduleGraphSetTable();
    await this.getLineScheduleGraphs();
  },

  mounted() {
    // если это загрузка истории по нажатию кнопки
    // добавляем eventListener, который отменяет скролл на странице при нажатии стрелочек
    window.addEventListener('keydown', this.preventDefaultForKeys, false);
    // переопределяем действия при нажатии стрелочек
    window.addEventListener('keydown', (event) => {
      if (event.code == 'F1') {
        this.changeInfoDialogVisible();
      } else if (event.code == 'Escape') {
        if(this.isInfoDialogVisible){
          this.changeInfoDialogVisible();
        }
      }
    });
  },

  unmounted() {
    // убираем eventListener -ы
    window.removeEventListener('keydown', this.preventDefaultForKeys, false);
    window.removeEventListener('keydown', (event) => {

      if (event.code == 'F1') {
        this.changeInfoDialogVisible();
      } else if (event.code == 'Escape') {
        if (this.isInfoDialogVisible) {
          this.changeInfoDialogVisible();
        }
      }
    });
  }

}
</script>

