import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ МАРШРУТОВ ДЛЯ РАСЧЕТА МОДУЛЯ КВР

export default {
    state() {
        return {
            // список маршрутов
            routes: [],
            // время обновления кэша new Date().getTime()
            routesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            routesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список маршрутов
        getRoutes(state) {
            return state.routes
        },
    },
    mutations: {
        // установить новый список маршрутов
        setRoutes(state, payload) {
            state.routes = payload;
            state.routesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка
        doFetchRoutes: (context, payload) => {
            return DoGet(
                '/kvr/getRoutes',
                'kvr/setRoutes',
                [],
                'Запрос списка маршрутов для модуля КВР',
                false,
                payload && payload.force,
                context.state.routesCache,
                context.state.routesCacheTimeout
            )
        },
    }
}