// ХРАНИЛИЩЕ ЗАДАНИЙ КВР


import {DoGet} from "@/store/Actions";

export default {

    state() {
        return {
            // список объектов
            workTasks: [],
            // время обновления кэша new Date().getTime()
            workTasksCache: null,
            // время жизни кэша в мс (null - бесконечно)
            workTasksCacheTimeout: null
        }
    },

    getters: {
        // вернуть список объектов
        getWorkTasks(state) {
            return state.workTasks;
        },
    },

    mutations: {

        // установить новый список объектов
        setWorkTasks(state, payload) {
            state.workTasks = payload;
            state.workTasksCache = new Date().getTime()
        },

    },

    actions: {

        // запрос списка заданий
        doFetchWorkTasks: (context, payload) => {
            return DoGet(
                '/kvr/getWorkTasks/' + payload.date_index,
                'kvr/setWorkTasks',
                [],
                'Запрос списка заданий на работу по транспортным средствам за указанный день',
                false,
            )
        },

        // запрос списка заданий
        doFetchWorkTasksByDateAndTsId: (context, payload) => {
            return DoGet(
                '/kvr/getWorkTasks/' + payload.date_index + '?transport_id=' + payload.transport_id,
                null,
                [],
                'Запрос списка заданий на работу по транспортному средству за указанный день',
                false,
            )
        }
    },
}