import {DoClearCache, DoDelete, DoGet, DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ СВЯЗЕЙ ВИДОВ ТРАНСПОРТА С ГРУППАМИ

export default {
    state() {
        return {
            // список связей
            transportTypeGroupLinks: [],
            // время обновления кэша new Date().getTime()
            transportTypeGroupLinksCache: null,
            // время жизни кэша в мс (null - бесконечно)
            transportTypeGroupLinksCacheTimeout: null
        }
    },
    getters: {
        // вернуть список связей
        getTransportTypeGroupLinks(state) {
            return state.transportTypeGroupLinks
        },
    },
    mutations: {
        // установить новый список связей
        setTransportTypeGroupLinks(state, payload) {
            state.transportTypeGroupLinks = payload;
            state.transportTypeGroupLinksCache = new Date().getTime()
        },
        // связать объекты
        addTransportTypeToGroup(state, payload) {
            state.transportTypeGroupLinks.push(payload)
        },
        // удалить связь объектов
        delTransportTypeFromGroup(state, payload) {
            const index = state.transportTypeGroupLinks.findIndex(
                link => link.transport_type_group_id == payload.transport_type_group_id && link.transport_type_id == payload.transport_type_id
            );
            state.transportTypeGroupLinks.splice(index, 1);
        },
    },
    actions: {
        // запрос списка связей
        doFetchTransportTypeGroupLinks: (context, payload) => {
            return DoGet(
                '/ref/transportTypeGroupLinks',
                'ref/setTransportTypeGroupLinks',
                [],
                'Запрос связей видов транспорта с группами',
                false,
                payload && payload.force,
                context.state.transportTypeGroupLinksCache,
                context.state.transportTypeGroupLinksCacheTimeout
            )
        },

        // очищает кэш
        doClearTransportTypeGroupLinks: (context, payload) => {

            return DoClearCache(
                'ref/setTransportTypeGroupLinks',
                [],
                payload && payload.force,
                context.state.transportTypeGroupLinksCache,
                context.state.transportTypeGroupLinksCacheTimeout
            )
        },

        // связывание объектов
        doAddTransportTypeToGroup: (context, payload) => {
            return DoPost(
                '/ref/transportTypeGroupLinks',
                payload,
                'ref/addTransportTypeToGroup',
                'Добавление вида транспорта в группу'
            )
        },

        // удаление связи объектов
        doDelTransportTypeFromGroup: (context, payload) => {
            return DoDelete(
                '/ref/transportTypeGroupLinks/'+payload.transport_type_group_id+'/'+payload.transport_type_id,
                'ref/delTransportTypeFromGroup',
                'Удаление вида транспорта из группы'
            )
        },
    }
}