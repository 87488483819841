import ReporterReporterStore from "@/store/reporter/ReportsReporterStore";

// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ REPORTER



export default {
    namespaced: true,
    modules: {
        ReporterReporterStore
    }
}