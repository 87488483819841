<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/admin">Администрирование</router-link></span>
        <span> / </span>
        <span>Связи с сущностями внешних систем</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDel"
          >

            <template #leftCustomButton>
              <external-systems-select
                  class="form-control"
                  @change="getLinkedEntities"
                  v-model="extSystem"
              ></external-systems-select>
            </template>

            <template #leftCustomButton2>
              <entity-types-select
                  class="form-control"
                  v-model="entType"
                  @change="getLinkedEntities"
                  :readonly="!extSystem"
              ></entity-types-select>
            </template>

          </crud-form>
        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <linked-entities-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :selectedEntType="entType"
              :tempArr="tempArr"
              @onRowSelect="onRowSelect"
          ></linked-entities-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <linked-entity-dialog
    v-if="isDialogVisible && dialogRow"
    :item="dialogRow"
    @onOK="onOK"
    @onCancel="onRejectOperation"
  >
  </linked-entity-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить связь с кодом {{ selectedRow.__id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import LinkedEntitiesEditTable from "@/components/integration/linked_entities/LinkedEntitiesEditTable";
import ExternalSystemsSelect from "@/components/integration/external_systems/ExternalSystemsSelect";
import EntityTypesSelect from "@/components/integration/entity_types/EntityTypesSelect";
import LinkedEntityDialog from "@/components/integration/linked_entities/LinkedEntityDialog";

export default {
  mixins: [PageMixin],

  components: {
    LinkedEntityDialog,
    EntityTypesSelect,
    ExternalSystemsSelect,
    LinkedEntitiesEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // выбранная внешняя система
      extSystem: null,
      // выбранный тип связанных сущностей
      entType: null,
      // временный массив, который хранит строки для таблицы, если в сторике нет возможности их хранить
      tempArr: [],
    }
  },

  computed: {

    // данные, которые отображаются в диалоговом окне
    dialogRow() {
      return this.op === 'add' ? {local_id: this.id,} : (this.selectedRow ? this.selectedRow : null);
    },

    // видимость диалога удаления связи
    isDelDialogVisible() {
      return this.op == 'del';
    },

    // видимость диалога удаления связи
    isDialogVisible() {
      return (this.op == 'add' || this.op == 'edit');
    },

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: !!this.selectedRow,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

  },
  methods: {

    onOK(entity) {
      if(this.op == 'add') {
        this.onSave(entity);
      } else {
        this.onEdit(entity);
      }
    },

    // сохраняем новую связь с сущностью внешней системы
    async onSave(entity) {
      this.isLoading = true;
      try {

        // формируем сущность для сохранения
        entity.external_system_id = this.extSystem;
        entity.entity_type_id = this.entType;
        entity.local_id = String(entity.local_id);
        // сохраняем
        await this.$store.dispatch('integration/doAddLinkedEntity', entity);

      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    // сохраняем новую связь с сущностью внешней системы
    async onEdit(entity) {
      this.isLoading = true;
      try {

        // формируем сущность для сохранения
        entity.external_system_id = this.extSystem;
        entity.entity_type_id = this.entType;
        entity.local_id = String(entity.local_id);
        // добавляем новую связь
        await this.$store.dispatch('integration/doEditLinkedEntity', entity);

      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/admin/linkedEntities/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/admin/linkedEntities/edit/" + this.selectedRow.linked_entity_id);
    },

    // нажата кнопка Изменить
    onTableDel() {
      this.$router.replace("/admin/linkedEntities/del/" + this.selectedRow.linked_entity_id);
    },

    onRejectOperation() {
      this.$router.replace("/admin/linkedEntities");
    },

    // удаляем новую связь с сущностью внешней системы
    async onConfirmDelete() {
      this.isLoading = true;
      try {

        // удаляем связь
        await this.$store.dispatch('integration/doDelLinkedEntity', this.selectedRow);

      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    // получаем данные для заполнения таблицы
    async getLinkedEntities() {
      if(this.extSystem && this.entType) {

      // получаем список строк всех сущностей заданного типа (в компоненте таблицы будет заполнено
      // поле связи тех сущностей их этого массива для которых связи есть)
      ///// REF /////
      if (this.entType == 101001) {
        await this.$store.dispatch('ref/doFetchDepots');
      }

        // получаем связи с сущностью внешней системы
        await this.$store.dispatch('integration/doFetchFilteredLinkedEntity', {
          external_system_id: this.extSystem,
          entity_type_id: this.entType,
        });
      }
      //  else if (this.entType == 101002) {
      //   await this.$store.dispatch('ref/doFetchTransportTypes');
      // } else if (this.entType == 101003) {
      //   await this.$store.dispatch('ref/doFetchTransportClasses');
      // } else if (this.entType == 101004) {
      //   await this.$store.dispatch('ref/doFetchModels');
      // } else if (this.entType == 101005) {
      //   await this.$store.dispatch('ref/doFetchTransport');
      // } else if (this.entType == 101006) {
      //   await this.$store.dispatch('ref/doFetchPersonnel');
      // }
      // ///// MULTIPASS /////
      // else if (this.entType == 102001) {
      //   await this.$store.dispatch('multipass/doFetchStops');
      // } else if (this.entType == 102002) {
      //   await this.$store.dispatch('multipass/doFetchTransferKinds');
      // } else if (this.entType == 102003) {
      //   await this.$store.dispatch('multipass/doFetchRoutes');
      // } else if (this.entType == 102004) {
      //   this.tempArr = await this.$store.dispatch('multipass/doFetchAllRouteVariants');
      //   await this.$store.dispatch('multipass/doFetchRouteVariantStatuses');
      // } else if (this.entType == 102005) {
      //   this.tempArr = await this.$store.dispatch('multipass/doFetchAllTripTypes');
      // } else if (this.entType == 102006) {
      //   let rvArr = await this.$store.dispatch('multipass/doFetchAllRouteVariants');
      //   for (const value of rvArr) {
      //     this.tempArr.push(
      //         await this.$store.dispatch('multipass/doFetchTripTypeZones', {
      //           route_variant_id: value.route_variant_id
      //         })
      //     )
      //   }
      // } else if (this.entType == 102007) {
      //   await this.$store.dispatch('multipass/doFetchRouteTypes');
      // } else if (this.entType == 102008) {
      //   await this.$store.dispatch('multipass/doFetchStops');
      //   let stop_id_list = [];
      //   this.$store.getters['multipass/getStops'].forEach(value => {
      //     stop_id_list.push(value.stop_id);
      //   });
      //   this.tempArr = await this.$store.dispatch('multipass/doFetchStopZones', {stop_id_list: stop_id_list});
      // } else if (this.entType == 102009) {
      //   await this.$store.dispatch('multipass/doFetchRouteVariantDepotLinks');
      // }
      // ///// RMT /////
      // else if (this.entType == 103001) {
      //   await this.$store.dispatch('rmt/doFetchSeasons');
      // } else if (this.entType == 103002) {
      //   await this.$store.dispatch('rmt/doFetchScheduleTypes');
      // } else if (this.entType == 103003) {
      //   await this.$store.dispatch('rmt/doFetchSeasons');
      //   await this.$store.dispatch('rmt/doFetchScheduleTypes');
      //   this.tempArr = await this.$store.dispatch('rmt/doFetchAllScheduleVariants');
      // }  else if (this.entType == 103004) {
      //   await this.$store.dispatch('multipass/doFetchRoutes');
      //   await this.$store.dispatch('rmt/doFetchHolidays');
      // } else if (this.entType == 103005) {
      //   await this.$store.dispatch('rmt/doFetchRegimes');
      // } else if (this.entType == 103006) {
      //   await this.$store.dispatch('rmt/doFetchScheduleEvents');
      // } else if (this.entType == 103007) {
      //   this.tempArr = await this.$store.dispatch('rmt/doFetchAllScheduleGraphSets');
      // } else if (this.entType == 103008) {
      //   this.tempArr = await this.$store.dispatch('rmt/doFetchAllScheduleGraphs');
      // } else if (this.entType == 103009) {
      //   this.tempArr = await this.$store.dispatch('rmt/doFetchT');
      // }
      // //// NAV ////
      // else if (this.entType == 104001) {
      //   await this.$store.dispatch('nav/doFetchRadioProtocols');
      // } else if (this.entType == 104002) {
      //   await this.$store.dispatch('nav/doFetchRadioModels');
      // } else if (this.entType == 104003) {
      //   await this.$store.dispatch('nav/doFetchRadios');
      //   await this.$store.dispatch('nav/doFetchRadioModels');
      //   await this.$store.dispatch('nav/doFetchRadioProtocols');
      // }

    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      if(this.op == 'add' || this.op == 'edit') {
        await this.$router.replace("/admin/linkedEntities");

      }

      this.isLoading = 'fetch';
      try {
        // получаем типы связанной сущности
        await this.$store.dispatch('integration/doFetchEntityTypes', {force});
        // получаем внешние системы
        await this.$store.dispatch('integration/doFetchExternalSystems', {force});
      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

