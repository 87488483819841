<template>
  <input-select ref="select" v-model="value" caption="Выберите предприятие..." :itemList="items"></input-select>
</template>

<script>
export default {
  props: [
    'model-value',
    // из какого справочника брать данные (по умолчанию из ref)
    'mode'
  ],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      if(this.mode && this.mode == 'kvr') {
        return this.$store.getters['kvr/getDepots'].map(item => {
          return {
            id: item.depot_id,
            title: item.long_name
          }
        });
      } else {
        return this.$store.getters['ref/getDepots'].map(item => {
          return {
            id: item.depot_id,
            title: item.long_name
          }
        });
      }
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
