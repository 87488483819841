<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-transport-types"></edit-table-panel>
</template>

<script>
export default {
  props: ['isLoading', 'transportTypeGroupId'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "transport_type_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      let types;
      const links = this.$store.getters["ref/getTransportTypeGroupLinks"];
      if (this.transportTypeGroupId) {
        types = this.$store.getters["ref/getTransportTypes"].filter(type => {
          type.__id = type.transport_type_id;
          return links.some(link => link.transport_type_id == type.transport_type_id && link.transport_type_group_id == this.transportTypeGroupId)
        });
      }
      else {
        types = this.$store.getters["ref/getTransportTypes"].map(type => {
          type.__id = type.transport_type_id;
          return type;
        });
      }

      return types;
    },
  },
};
</script>
