<template>
  <leaflet-polyline
      v-if="isCorrect"
      :latlngs="points"
      :options="options"
      :targetComponent="this"
      :contextMenu="contextMenu"
      :tooltip="tooltip"
      :events="events"
      :meta="meta"
      :logTitle="logTitle"
      position="back"
      @onLeafletEvent="$emit('onLeafletEvent', $event)"
  >
  </leaflet-polyline>

  <leaflet-polyline-decorator
      v-if="isCorrect"
      :latlngs="points"
      :patterns="patterns"
      :logTitle="logTitle"
  >
  </leaflet-polyline-decorator>
</template>

<script>
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";
import {LatLng} from "leaflet";
import {shiftLine} from "@/components/ui/leaflet/LeafletHelpers";
import * as L from 'leaflet'

// РЕБРО ГРАФА

// interface GRAPH_EDGE {
//   edge_id: number;
//   road_id: number;
//   order_num: number;
//   node_id_begin: number;
//   node_id_end: number;
//   direction: number;
//   length: number;
//   max_speed: number;
//   lanes_count: number;
//   edge_type: number;
// }

export default {
    mixins: [LeafletMapMixin, LeafletEventedMixin],
    emits: ['onLeafletEvent'],
    props: {
      // состояние ребра графа: original - обычное, active - активное, edit - редактируемое, disabled - отключенное
      state: {
        type: String,
        default: 'original'
      },
      // начальный узел
      node_begin: {
        type: Object,
        required: true,
      },
      // конечный узел
      node_end: {
        type: Object,
        required: true,
      },
      // расстояние от центра до стрелки (пиксели)
      deltaPixels: {
        type: Number,
        default: 0
      },
      // всплывающая подсказка слоя
      tooltip: {
        type: String
      },
      // контекстное меню слоя
      contextMenu: {
        type: Object
      },
      // произвольные данные - добавляются к leaflet-компоненту в поле __meta
      meta: {
        required: false
      },
      // опции прямой линии в стандартном режиме
      originalFrwdOptions: {
        type: Object,
        default: () => {
          return {
            color: 'green',
            opacity: 1.0,
            draggable: false,
            weight: 3.0
          }
        }
      },
      // опции обратной линии в стандартном режиме
      originalBackOptions: {
        type: Object,
        default: () => {
          return {
            color: 'blue',
            opacity: 1.0,
            draggable: false,
            weight: 3.0
          }
        }
      },
      // опции прямой линии в активном режиме
      activeFrwdOptions: {
        type: Object,
        default: () => {
          return {
            color: 'red',
            opacity: 1.0,
            draggable: false,
            weight: 3.0
          }
        }
      },
      // опции обратной линии в активном режиме
      activeBackOptions: {
        type: Object,
        default: () => {
          return {
            color: 'red',
            opacity: 1.0,
            draggable: false,
            weight: 3.0
          }
        }
      },
      // опции прямой линии в отключенном режиме
      disabledFrwdOptions: {
        type: Object,
        default: () => {
          return {
            color: 'black',
            opacity: 1.0,
            draggable: false,
            weight: 2.0
          }
        }
      },
      // опции обратной линии в отключенном режиме
      disabledBackOptions: {
        type: Object,
        default: () => {
          return {
            color: 'black',
            opacity: 1.0,
            draggable: false,
            weight: 2.0
          }
        }
      },
    },

    data() {
      return {
        // тип объекта
        leafletObjectType: 'GraphEdge',
        // карта
        leafletMap: null,
      }
    },

    computed: {
      // логирование
      logTitle() {
        return `GraphEdge(#${this.node_begin.node_id} - #${this.node_end.node_id})`
      },

      // корректность данных
      isCorrect() {
        return this.leafletMap &&
            this.node_begin &&
            this.node_begin.latitude &&
            this.node_begin.longitude &&
            this.node_end &&
            this.node_end.latitude &&
            this.node_end.longitude
      },

      // признак прямого направления
      isFrwdDirection() {
        return this.node_end.latitude > this.node_begin.latitude
      },

      // опции линии
      options() {
        if (this.state === 'disabled')
          return this.isFrwdDirection ? this.disabledFrwdOptions : this.disabledBackOptions
        else if (this.state === 'active' || this.state === 'edit')
          return this.isFrwdDirection ? this.activeFrwdOptions : this.activeBackOptions
        else
          return this.isFrwdDirection ? this.originalFrwdOptions : this.originalBackOptions
      },

      // точки линии
      points() {
        // формируем смещенную стрелку
        if (this.deltaPixels > 0) {
          const p1 = this.leafletMap.origin.project(new LatLng(this.node_begin.latitude, this.node_begin.longitude))
          const p2 = this.leafletMap.origin.project(new LatLng(this.node_end.latitude, this.node_end.longitude))
          const [r1, r2] = shiftLine(this.deltaPixels, p1, p2)
          const latLng1 = this.leafletMap.origin.unproject(r1)
          const latLng2 = this.leafletMap.origin.unproject(r2)
          return [
            [latLng1.lat, latLng1.lng],
            [latLng2.lat, latLng2.lng],
          ]
        }
        // формируем стрелку без смещения
        return [
          [this.node_begin.latitude, this.node_begin.longitude],
          [this.node_end.latitude, this.node_end.longitude],
        ]
      },

      // шаблоны декоратора
      patterns() {
        return [
          {
            offset: '100%',
            repeat: 0,
            symbol: L.Symbol.arrowHead({
                  pixelSize: 15,
                  headAngle: 40,
                  pathOptions: {
                    fillOpacity: 1,
                    weight: 0,
                    color: this.options.color,
                  }
                })
          }
        ]
      },
    },

    // монтируем слой
    mounted() {
      // ждем создание родителя
      this.getParent().parentReady().then((parent_list) => {
        this.leafletMap = this.getMap(parent_list);
      })
    },
}
</script>
