<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-rmt-trip-type-composites"></edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading', 'rowList', 'isTableEditMode'],
  computed: {

    // колонки
    columnList() {
      return [
        {
          field: "__delCard",
          caption: "Удаление",
          displayType: 'button',
          buttonCaption: '<i class="fas fa-trash"></i>',
          dataAlign: 'center',
          headerAlign: 'center',
          buttonAttrs: {
            'class': "btn btn-danger btn-sm",
            'title': 'Удалить рейс'
          },
          buttonClick: (row) => {
            this.$emit('onDeleteCard', row.order_num);
          },
          show: this.isTableEditMode,
          isSortable: false,
        },
        {
          field: "order_num",
          caption: "Номер",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "trip_type_composite_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
          show: false
        },
        {
          field: "__tripType",
          caption: "Тип рейса",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "trip_type_order_num_begin",
          caption: "Код нач. ОП",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__tripTypeOrderNumBegin",
          caption: "Начальный ОП",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "trip_type_order_num_end",
          caption: "Код конеч. ОП",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__tripTypeOrderNumEnd",
          caption: "Конечный ОП",
          sortType: 'string',
          isFilterable: true,
        },
      ]
    }
  },
};
</script>
