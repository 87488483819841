<template>
  <div>
    <!--    Карточка с отчетами раздела "Маршруты"-->
    <div class="card bg-light">
      <div class="card-header" style="font-size: 18px; font-weight: bold; padding: 0px 0px 0px 8px;">
        Маршруты
      </div>
      <div style="padding-right: 8px; padding-left: 8px">

        <!--    подраздел "Оперативные"-->
        <div class="form">
          <label style="margin-top: 8px; font-weight: bold;">Оперативные</label>
          <div :class="id == '0027' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0027')"
               title="Оперативная справка о работе пассажирского транспорта. Сводная по маршрутам">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Оперативная справка о работе пассажирского транспорта. Сводная по маршрутам.</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-23</a>
            </div>
          </div>
          <div :class="id == '0044' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0044')"
               title="Оперативная справка о работе пассажирского транспорта. Сводная по графикам">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Оперативная справка о работе пассажирского транспорта. Сводная по графикам.</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-25</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Пробеги"-->
        <div class="form">
          <label style="margin-top: 8px; font-weight: bold;">Пробеги</label>
          <div :class="id == '0020' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0020')"
               title="Итоговый отчет о выполнении работы на маршрутах">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о выполнении работы на маршрутах</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-16</a>
            </div>
          </div>
          <div :class="id == '0021' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0021')"
               title="Итоговый отчет о выполнении работы предприятиями на маршрутах">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о выполнении работы предприятиями на маршрутах</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-17</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Рейсы"-->
        <div class="form">
          <label style="margin-top: 8px; font-weight: bold;">Рейсы</label>
          <div :class="id == '0018' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0018')"
               title="Итоговый отчет о работе маршрутов">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о работе маршрутов</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-14</a>
            </div>
          </div>
          <div :class="id == '0019' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0019')"
               title="Итоговый отчет о работе предприятий на маршрутах">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о работе предприятий на маршрутах</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-15</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Сводные"-->
        <div class="form" style="margin-bottom: 8px">
          <label style="margin-top: 8px; font-weight: bold;">Сводные</label>
          <div :class="id == '0016' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0016')"
               title="Итоговый отчет о выполнении плана перевозок">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о выполнении плана перевозок</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-12</a>
            </div>
          </div>
          <div :class="id == '0022' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0022')"
               title="Отчет о количественных и качественных показателях выполнения работы">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет о количественных и качественных показателях выполнения работ</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-18</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--    Карточка с отчетами раздела "Перевозчики"  -->
    <div class="card bg-light">
      <div class="card-header" style="font-size: 18px; font-weight: bold; padding: 0px 0px 0px 8px;">
        Перевозчики
      </div>
      <div style="padding-right: 8px; padding-left: 8px ">

        <!--    подраздел "Выпуск на линию"  -->
        <div class="form">
          <label style="margin-top: 8px; font-weight: bold;">Выпуск на линию</label>
          <div :class="id == '0005' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0005')"
               title="Отчет о выполнении параметров пассажирских перевозок (количество и класс транспортных средств на маршруте)">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет о выполнении параметров пассажирских перевозок (количество и класс транспортных средств на
                маршруте)</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-1</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Нарушение движения"  -->
        <div class="form">
          <label style="margin-top: 8px; font-weight: bold;">Нарушение движения</label>
          <div :class="id == '0006' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0006')"
               title="Сводный отчет нарушений параметров пассажирских перевозок">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Сводный отчет нарушений параметров пассажирских перевозок</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-2</a>
            </div>
          </div>
          <div :class="id == '0010' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0010')"
               title="Отчет нарушений отправления в рейс по маршруту">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет нарушений отправления в рейс по маршруту</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-6</a>
            </div>
          </div>
          <div :class="id == '0011' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0011')"
               title="Отчет нарушений открытия движения на маршруте">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет нарушений открытия движения на маршруте</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-7</a>
            </div>
          </div>
          <div :class="id == '0012' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0012')"
               title="Отчет нарушений закрытия движения на маршруте">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет нарушений закрытия движения на маршруте</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-8</a>
            </div>
          </div>
          <div :class="id == '0013' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0013')"
               title="Отчет нарушений схемы движения на маршруте">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет нарушений схемы движения на маршруте</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-9</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Оперативные"  -->
        <div class="form">
          <label style="margin-top: 8px; font-weight: bold;">Оперативные</label>
          <div :class="id == '0024' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0024')"
               title="Оперативная справка о работе пассажирского транспорта. Сводная по предприятиям">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Оперативная справка о работе пассажирского транспорта. Сводная по предприятиям</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-20</a>
            </div>
          </div>
          <div :class="id == '0026' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0026')"
               title="Оперативная справка выполнения плана перевозок. Сводная по предприятиям">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Оперативная справка выполнения плана перевозок. Сводная по предприятиям</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-22</a>
            </div>
          </div>
          <div :class="id == '0028' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0028')"
               title="Оперативная справка о работе пассажирского транспорта. Сводная по регионам">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Оперативная справка о работе пассажирского транспорта. Сводная по регионам</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-24</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Пробеги"-->
        <div class="form" style="margin-bottom: 8px">
          <label style="margin-top: 8px; font-weight: bold;">Пробеги</label>
          <div :class="id == '0007' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0007')"
               title="Отчет о выполнении автобусо-километров в наряде(вагоно-километров в наряде) по маршрутам">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет о выполнении автобусо-километров в наряде(вагоно-километров в наряде) по маршрутам</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-3</a>
            </div>
          </div>
          <div :class="id == '0014' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0014')"
               title="Отчет об осуществлении регулярных перевозок по регулируемым тарифам пассажиров и багажа по муниципальным маршрутам регулярных перевозок">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет об осуществлении регулярных перевозок по регулируемым тарифам пассажиров и багажа по
                муниципальным маршрутам регулярных перевозок</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-10</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Рейсы"  -->
        <div class="form">
          <label style="margin-top: 8px; font-weight: bold;">Рейсы</label>
          <div :class="id == '0008' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0008')"
               title="Отчет точности выполнения расписания отправления в рейс (регулярность движения) на маршруте">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет точности выполнения расписания отправления в рейс (регулярность движения) на маршруте</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КВР-4</a>
            </div>
          </div>
          <div :class="id == '0009' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0009')"
               title="Отчет точности выполнения расписания отправления в рейс (интервальность движения) на маршруте">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет точности выполнения расписания отправления в рейс (интервальность движения) на маршруте</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КВР-5</a>
            </div>
          </div>
          <div :class="id == '0015' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0015')"
               title="Отчет о фактической транспортной работе">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет о фактической транспортной работе</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КВР-11</a>
            </div>
          </div>
          <div :class="id == '0031' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0031')"
               title="Отчет о фактической транспортной работе">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет о фактической транспортной работе</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КВР-11и</a>
            </div>
          </div>
          <div :class="id == '0017' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0017')"
               title="Итоговый отчет о работе маршрутов">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о работе маршрутов</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КВР-13</a>
            </div>
          </div>
          <div :class="id == '0025' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0025')"
               title="Отчет по выполнению плана перевозок">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет по выполнению плана перевозок. Сводная по предприятиям</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КВР-21</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Сводные"  -->
        <div class="form" style="margin-bottom: 8px">
          <label style="margin-top: 8px; font-weight: bold;">Сводные</label>
          <div :class="id == '0023' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0023')"
               title="Отчет об объеме выполненной транспортной работы">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет об объеме выполненной транспортной работы</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КВР-19</a>
            </div>
          </div>
          <div :class="id == '0029' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0029')"
               title="Отчет об объеме выполненной транспортной работы">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет об объеме выполненной транспортной работы</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КВР-19к</a>
            </div>
          </div>
          <div :class="id == '0030' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0030')"
               title="Отчет об объеме выполненной транспортной работы">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет об объеме выполненной транспортной работы</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КВР-19п</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="card bg-light">
      <div class="card-header" style="font-size: 18px; font-weight: bold; padding: 0px 0px 0px 8px;">
        Остановки
      </div>
      <div style="padding-right: 8px; padding-left: 8px">

        <!--    подраздел "Оперативные"-->
        <div class="form">
          <div :class="id == '0045' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0045')"
               title="Отчет о фактическом прохождении транспортными средствами остановочного пункта">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет о фактическом прохождении транспортными средствами остановочного пункта</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КВР-26</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  props: [
    // идентификатор выбранного отчета
    "id",
  ],

  computed: {

    selectedStation(){
      return this.$store.getters['settings/getSelectedStop'];
    }

  },

  methods: {

    setRowSelected(id) {
      this.$router.replace('/kvr/reports/' + id);
    }

  }
}
</script>

<style scoped>

a {
  font-size: small;
  white-space: nowrap;
  padding: 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  border-top: 1px solid #bdbdbd;
  /*#817e7e;*/
  cursor: pointer;
}

.selected-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  border-top: 1px solid #bdbdbd;
  background-color: #9adef5;
  cursor: pointer;
}

.col {
  padding-right: 0;
  margin-right: 10px !important;
  padding-left: 10px !important;
}

.col-1 {
  margin-right: 5px !important;
  min-width: 44px;
  max-width: 44px;
}

.form {
  border-bottom: 2px solid #bdbdbd;
  margin-bottom: 0px;
}

.card {
  margin-bottom: 5px;
}

</style>