<template>
  <input-select ref="select" v-model="value" caption="Выберите класс ТС..." :itemList="items"></input-select>
</template>

<script>
export default {
  props: ['model-value'],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      return this.$store.getters['ref/getTransportClasses'].map(item => {
        return {
          id: item.transport_class_id,
          title: item.long_name
        }
      });
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
