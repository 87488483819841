import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ РОЛЕЙ

export default {
    state() {
        return {
            // список объектов
            roles: [],
            // время обновления кэша new Date().getTime()
            rolesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            rolesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRoles(state) {
            return state.roles;
        },
    },
    mutations: {
        // установить новый список объектов
        setRoles(state, payload) {
            state.roles = payload;
            state.rolesCache = new Date().getTime()
        },
        // добавить объект
        addRole(state, payload) {
            state.roles.push(payload)
        },
        // изменить объект
        editRole(state, payload) {
            const index = state.roles.findIndex(rl => rl.role_id == payload.role_id);
            if (index >= 0) {
                state.roles[index] = payload;
            }
        },
        // удалить объект
        delRole(state, payload) {
            const index = state.roles.findIndex(rl => rl.role_id == payload.role_id);
            state.roles.splice(index, 1);
        },
    },
    actions: {
        // получить список объектов
        doFetchRoles: (context, payload) => {
            return DoGet(
                '/auth/roles',
                'auth/setRoles',
                [],
                'Запрос ролей',
                false,
                payload && payload.force,
                context.state.rolesCache,
                context.state.rolesCacheTimeout
            )
        },

        // очищает кэш
        doClearRoles: (context, payload) => {
            return DoClearCache(
                'auth/setRoles',
                [],
                payload && payload.force,
                context.state.rolesCache,
                context.state.rolesCacheTimeout
            )
        },

        // добавление объекта
        doAddRole: (context, payload) => {
            return DoPost(
                '/auth/roles',
                payload,
                'auth/addRole',
                'Добавление роли'
            )
        },

        // изменение объекта
        doEditRole: (context, payload) => {
            return DoPut(
                '/auth/roles/'+payload.role_id,
                payload,
                'auth/editRole',
                'Изменение данных роли'
            )
        },

        // удаление объекта
        doDelRole: (context, payload) => {
            return DoDelete(
                '/auth/roles/'+payload.role_id,
                'auth/delRole',
                'Удаление роли'
            )
        },
    }
}