<template>
  <div>

    <!-- Главное Меню -->
    <div>
      <main-menu
          id="menu"
          :hideMenu="isMenuHide"
      ></main-menu>
    </div>
    <!-- Основное окно -->
    <div id="main-div">
      <router-view></router-view>
    </div>

    <!-- Боковое меню -->
    <!-- Закоментированно второе левое меню, которе появляется при
    уменьшении окна (при раскоментировании в left-menu нужно добавить v-else)
    а также необходимо раскоментирвоать mounted и компоненты в components и import
    -->
<!--    <full-left-menu-->
<!--        v-if="isMenuHide"-->
<!--    ></full-left-menu>-->
    <left-menu
    ></left-menu>

    <reporter-panel></reporter-panel>

    <!-- Окно с сообщениями -->
    <message-list></message-list>

<!--    <reporter-button></reporter-button>-->

  </div>
</template>

<script>

import MainMenu from './components/ui/menu/MainMenu.vue';
import LeftMenu from './components/ui/menu/LeftMenu.vue';
import ReporterPanel from "@/components/reporter/ReporterPanel";
// import FullLeftMenu from "@/components/ui/menu/FullLeftMenu";

export default {
  components: {

    ReporterPanel,
    // FullLeftMenu,
    'main-menu': MainMenu,
    'left-menu': LeftMenu,
    // 'full-left-menu': FullLeftMenu
  },

  data() {
    return {
      // скрыто ли верхнее меню (перенесено в левое меню)
      isMenuHide: false,
      // значение скролла, при котором верхнее меню скрылось
      scrlWidth: 0
    }
  },

  // mounted() {
  //   // при установлении компонента добавляем обсервер, который будет следить за изменением ширины
  //   // компонента с id="menu"
  //   const ro = new ResizeObserver(() => {
  //     var element = document.getElementById('menu');
  //     // если текущая ширина компонента element.clientWidth меньше ширины
  //     // необходимой чтобы компонент полностью поместился на экране
  //     if (element.clientWidth < element.scrollWidth) {
  //       // скрываем верхнеее меню
  //       this.isMenuHide = true
  //       // запоминаем при какой ширине мы скрыли верхнее меню
  //       this.scrlWidth = element.scrollWidth
  //       // если текущая ширина элемента больше ширины, при которой мы скрыли меню
  //     } else if (element.clientWidth > this.scrlWidth) {
  //       // отображаем верхнее меню
  //       this.isMenuHide = false
  //     }
  //   })
  //   // следим за элементом
  //   ro.observe(document.getElementById('menu'))
  //
  //   // без задания таймаута не возвращается корректное значение scrollWidth
  //   setTimeout(() => {
  //     // необходимо задать отображаем ли мы верхнее меню изначально
  //     var el = document.getElementById('menu');
  //     if (el.clientWidth < el.scrollWidth) {
  //       // скрываем верхнеее меню
  //       this.isMenuHide = true
  //     } else {
  //       // отображаем верхнее меню
  //       this.isMenuHide = false
  //     }
  //     // запоминаем при какой ширине мы скрываем верхнее меню
  //     this.scrlWidth = el.scrollWidth
  //   }, 1)
  // },
}
</script>

<style>
* {
  box-sizing: border-box;
}

html, body {
  height: 100vh;
  overflow: hidden;
}

#main-div {
  position: fixed;
  top: 60px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}

</style>
