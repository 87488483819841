import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ТИПОВ МАРШРУТА

export default {
    state() {
        return {
            // список объектов
            routeTypes: [],
            // время обновления кэша new Date().getTime()
            routeTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            routeTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRouteTypes(state) {
            return state.routeTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setRouteTypes(state, payload) {
            state.routeTypes = payload;
            state.routeTypesCache = new Date().getTime()
        },
        // добавить объект
        addRouteType(state, payload) {
            state.routeTypes.push(payload)
        },
        // изменить объект
        editRouteType(state, payload) {
            const index = state.routeTypes.findIndex(type => type.route_type_id == payload.route_type_id);
            if (index >= 0) {
                state.routeTypes[index] = payload;
            }
        },
        // удалить объект
        delRouteType(state, payload) {
            const index = state.routeTypes.findIndex(type => type.route_type_id == payload.route_type_id);
            state.routeTypes.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchRouteTypes: (context, payload) => {
            return DoGet(
                '/multipass/routeTypes',
                'multipass/setRouteTypes',
                [],
                'Запрос типов маршрутов',
                false,
                payload && payload.force,
                context.state.routeTypesCache,
                context.state.routeTypesCacheTimeout
            )
        },

        // очищает кэш
        doClearRouteTypes: (context, payload) => {
            return DoClearCache(
                'multipass/setRouteTypes',
                [],
                payload && payload.force,
                context.state.routeTypesCache,
                context.state.routeTypesCacheTimeout
            )
        },

        // добавление объекта
        doAddRouteType: (context, payload) => {
            return DoPost(
                '/multipass/routeTypes',
                payload,
                'multipass/addRouteType',
                'Добавление типа маршрута'
            )
        },

        // изменение объекта
        doEditRouteType: (context, payload) => {
            return DoPut(
                '/multipass/routeTypes/'+payload.route_type_id,
                payload,
                'multipass/editRouteType',
                'Изменение типа маршрута'
            )
        },

        // удаление объекта
        doDelRouteType: (context, payload) => {
            return DoDelete(
                '/multipass/routeTypes/'+payload.route_type_id,
                'multipass/delRouteType',
                'Удаление типа маршрута'
            )
        }
    }
}
