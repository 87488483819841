<template>
  <confirm-message-box
      @onCancel="$emit('onCancel')"
      @onOK="delItem"
  ><p>Списать радиостанцию c кодом {{ id }} ?</p>
    <form class="form-inline">
      <label for="discardDate" class="mr-2">Дата и время:</label>
      <input-date-and-time
          v-model="discardDate"
          ref="discardDate"
          required
          error="Введите дату списания"
      />
    </form>
  </confirm-message-box>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputDateAndTime from "@/components/ui/custom/InputDateAndTime";
import {dateToSeconds} from "@/lib";

export default {
  components: {InputDateAndTime},
  emits: ['onCancel', 'onOK'],
  props: ['id'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      discardDate: dateToSeconds(),
    }
  },
  methods: {
    delItem() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', this.discardDate)
      }
    }
  },
}
</script>
