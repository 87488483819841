<template>
  <svg id="graph" :height="height" :width="width">
    <line
        v-for="(value, index) in (graphLine)"
        v-show="!isTechOp(value)"
        :key="index"
        :x1="getXLineStartCoord(index)"
        :y1="height - 30"
        :x2="getXLineEndCoord(index)"
        :y2="height - 30"
        :style="getStyle(value)"/>
    <text
        v-for="(value, index) in (graphLine)"
        :key="index"
        :x="getXTextCraphCoord(value, index)"
        :y="height - 25"
        style="font-weight: bold"
        :fill="getColor(value)"
    >{{ value.text }}
    </text>
    <!--      текст с длительностью каждого отрезка (располагается примерно по центру)-->
    <text
        v-for="(value, index) in (graphLine)"
        v-show="value.type != 'before'"
        :key="index"
        :x="getXTextLengthCoord(value, index)"
        :y="getYTextLengthCoord(value, index)"
        :fill="getColor(value)"
    >
      <tspan
          v-if="value"
          :x="(index != 0 && graphLine[index-1].type == 'change') ? getXTextLengthCoord(value, index) - 9 : getXTextLengthCoord(value, index)"
          :y=" getYTextLengthCoord(value, index)"
      >{{
          (index != 0 && graphLine[index - 1].type == 'change') ? "+" + getTimeFormat(value.length * 60) : getTimeFormat(value.length * 60)
        }}
      </tspan>
      <tspan
          v-if="(index != 0 && graphLine[index-1].type == 'change')"
          :x="getXTextLengthCoord(value, index) - 9"
          :y="(Number(getYTextLengthCoord(value, index)) + 28)"
          fill="red"
      >{{ '=' + getTimeFormat((value.length + graphLine[index - 1].length) * 60) }}
      </tspan>
    </text>

    <!--      текст после графика     -->
    <text
        :x="getXTextAfterGraphCoord()"
        :y="height - 24"
        fill="black"
        style="font-weight: bold"
    >{{ getTimeFormat(graph.workTime * 60) }} К {{graph.nullTrip}} {{graph.textAfter}} {{ (isTuprShow &&  graph.textAfterAddition) ? '/ ' + graph.textAfterAddition : ''}}
    </text>

    <!--      текст номера     -->
    <text
        :x="getNumberX() - 65"
        :y="height - 30"
        fill="black"
        style="font-weight: bold;"
        font-size=" x-large"
    >{{ graph.number }}
    </text>

    <!--      текст суммы стоянок     -->
    <text
        :x="getNumberX() - 65"
        y="50px"
        fill="red"
        style="font-weight: bold"
    >{{ getTimeFormat(graph.stayTime * 60) }}
    </text>
  </svg>
</template>

<script>
export default {
  name: "ScheduleGraphSVG",
  props: ['graph', 'width', 'height', 'start_x', 'oneDiv', 'isTuprShow'],
  data() {
    return {
      graphLine: this.graph.graphLine,
      // объект в котором хранятся координаты начала всех длительностей линий верхней линии
      topTextLine: [],
      // объект в котором хранятся координаты начала всех длительностей линий нижней линии
      bottomTextLine: []
    }
  },

  watch: {

    width (newValue){
      let graph = document.getElementById("graph");
      graph.setAttribute("width", newValue + "px");

    }
  },

  methods: {

    isTechOp(graph){
      if (graph.type.includes('change') ||
          graph.type.includes('planstay') || graph.type.includes('dinner') || graph.type.includes('maintenance') ||
          graph.type.includes('special') || graph.type.includes('refuel') || graph.type.includes('switching') ||
          graph.type.includes('res') || graph.type.includes('stay') || graph.type.includes('inttrips')) {
        return true;
      } else {
        return false;
      }
    },

    getColor(value) {
      if (value.type.includes('change')) {
        return 'red';
      } else if (value.type.includes('gray')) {
        return 'gray';
      } else {
        return 'black';
      }
    },

    getStyle(value) {

      if (this.isTechOp(value)) {
        // делаем линии поверх которых будет текст невидимыми (белыми)
        return 'stroke-linecap: square; stroke:rgb(255,255,255);stroke-width:2';
      } else {
        if (value.type == 'line') {
          return 'stroke-linecap: square; stroke:rgb(0,0,0);stroke-width:2';
        } else if (value.type == 'line-gray') {
          return 'stroke-linecap: square; stroke:rgb(129,129,129);stroke-width:2';
        }
      }
    },
// определяем местоположение по x для надписи длительности отрезка,
    // она должна располагаться примерно в центре каждого отрезка и копировать его цвет
    getXTextLengthCoord(value, index) {

      let lineStart = this.getLineStartInPx(index - 1);
      let lineLenght = ((this.graphLine[index].length / 60) * 10 * this.oneDiv);
      let lineX = lineStart + (lineLenght / 2) - 18;
      if (this.isTechOp(value)) {
        // проверяем не пересекаются ли надписи с предыдущей
        if (index > 1) {
          let previousXIndex = null;
          // определяем предыдущую остановку stop или change или иную техоперацию
          for (let i = index-1; i >= 0; i--) {
            if(this.isTechOp(this.graphLine[i])) {
              if (this.graphLine[i].type.includes('change') && ((index) - i) > 1) {
                previousXIndex = index - 1;
                break;
              }
              previousXIndex = i;
              break;
            }
          }
          let previousX = this.bottomTextLine[previousXIndex];
          if (this.graphLine[index - 1].type != 'change') {
            if (lineX - previousX < 44) {
              lineX = previousX + 45;
            }
          } else {
            if (lineX - previousX < 52) {
              lineX = previousX + 53;
            }
          }
        }
        this.bottomTextLine[index] = lineX;
        return lineX;
      } else {
        // проверяем не пересекаются ли надписи с предыдущей
        if (index > 1) {
          let previousXIndex = null;
          // определяем предыдущую остановку stop или change или иную техоперацию
          for (let i = index-1; i >= 0; i--) {
            if(!this.isTechOp(this.graphLine[i])) {
              previousXIndex = i;
              break;
            } else if (this.graphLine[i].type.includes('change')){
              previousXIndex = i;
              break;
            }
          }

          let previousX = 0;
          if (this.graphLine[previousXIndex].type.includes('change')){
            previousX = this.bottomTextLine[previousXIndex];
          } else {
            previousX = this.topTextLine[previousXIndex];
          }

          if (this.graphLine[index - 1].type != 'change') {
            if (lineX - previousX < 44) {
              lineX = previousX + 45;
            }
          } else {
            if (lineX - previousX < 52) {
              lineX = previousX + 54;
            }
          }
        }
        this.topTextLine[index] = lineX;

        if (index > 1 && this.graphLine[index-1].type.includes('change')){
          this.bottomTextLine[index] = lineX;
        }
        return lineX;
      }
    },

    getYTextLengthCoord(value, index) {
      if (this.isTechOp(value)) {
        return '50';
      } else {
        if (index != 0 && this.graphLine[index - 1].type == 'change') {
          return '22';
        }
        return '22';
      }
    },

    // получаем местоположение по X для текста с номером графика
    getNumberX() {
      // координата Х привязана к точке начала линии графика, поэтому определяем длинну невидимой линии начала
      let lenght = ((this.graphLine[0].length / 60) * 10 * this.oneDiv);
      return this.start_x + lenght;
    },

    //////////////////////
    getXTextCraphCoord(value, index) {
      if (index == 0) {
        return this.start_x;
      } else {
        return this.getLineStartInPx(index - 1);
      }
    },

    getXLineStartCoord(index) {
      if (index == 0) {
        return this.start_x;
      } else {
        return this.getLineStartInPx(index - 1);
      }
    },

    getXTextAfterGraphCoord() {
      // так как текст располагается после графика, просто ищем последнюю точку графика и прибавляем к ней интервал
      return (this.getLineStartInPx(this.graphLine.length - 1) + 20);
    },

    getXLineEndCoord(index) {
      if (index == 0) {
        return this.start_x + ((this.graphLine[index].length / 60) * 10 * this.oneDiv);
      } else {
        return this.getLineStartInPx(index - 1) + ((this.graphLine[index].length / 60) * 10 * this.oneDiv);
      }
    },

    getLineStartInPx(index) {
      let startX = this.start_x;
      for (let i = 0; i <= index; i++) {
        startX = startX + ((this.graphLine[i].length / 60) * 10 * this.oneDiv);
      }
      return startX;
    },

    //Конвертирует и возвращает время без секунд из значения в секундах
    getTimeFormat(time) {
      if (time != null && time !== '' && Number.isFinite(Number(time))) {
        time = Number(time);
        // часы
        let hours = Math.trunc(time / 3600);
        // минуты
        let minutes = Math.trunc((time - (hours * 3600)) / 60);
        //Возвращаем отформатированное время
        let result = (hours === 0 ? '00' : hours < 10 ? ('0' + hours) : hours) + ':' + (minutes === 0 ? '00' : (minutes < 10 ? ('0' + minutes) : minutes));
        return result;
      } else {
        return '';
      }
    }
  },

  created() {
    this.graphLine = this.graph.graphLine;
    // this.oneDiv = (this.width - (this.start_x * 2)) / (this.hoursArray.length * 10);
    this.graphLine.forEach((value, index) => {
      if (this.isTechOp(value) && !value.type.includes('change')) {
        let letter = '';
        if (value.type.includes('planstay')) {
          letter = 'Т';
        } else if (value.type.includes('dinner')) {
          letter = 'О';
        } else if (value.type.includes('maintenance')) {
          letter = 'Р';
        } else if (value.type.includes('special')) {
          letter = 'П';
        } else if (value.type.includes('refuel')) {
          letter = 'З';
        } else if (value.type.includes('switching')) {
          letter = 'К';
        } else if (value.type.includes('res')) {
          letter = 'Е';
        } else if (value.type.includes('stay')) {
          letter = 'В';
        } else if (value.type.includes('inttrips')) {
          letter = 'И';
        }
        let valueLength = ((this.graphLine[index].length / 60) * 10 * this.oneDiv);
        // если обед, то первая буква "О" 12px, пробел 5 px, остальное заполняем * (8px)
        if (valueLength < 12) {
          value.text = letter;
        } else if (valueLength < 16) {
          value.text = letter + ' ';
        } else {
          value.text = letter + '  ';
          let count = (valueLength - 12) / 9;
          for (let i = 1; i <= count; i++) {
            value.text = value.text + '∗';
          }
        }
        // this.stopSumm += value.length;
      } else if (value.type == 'change') {
        value.text = 'C';
        // this.stopSumm += value.length;
      }
    });

  }
}
</script>

<style scoped>

</style>