import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ГРУПП ТРАНСПОРТНЫХ СРЕДСТВ

export default {
    state() {
        return {
            // список объектов
            transportGroups: [],
            // время обновления кэша new Date().getTime()
            transportGroupsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            transportGroupsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getTransportGroups(state) {
            return state.transportGroups;
        },
    },
    mutations: {
        // установить новый список объектов
        setTransportGroups(state, payload) {
            state.transportGroups = payload;
            state.transportGroupsCache = new Date().getTime()
        },
        // добавить объект
        addTransportGroup(state, payload) {
            state.transportGroups.push(payload)
        },
        // изменить объект
        editTransportGroup(state, payload) {
            const index = state.transportGroups.findIndex(group => group.transport_group_id == payload.transport_group_id);
            if (index >= 0) {
                state.transportGroups[index] = payload;
            }
        },
        // удалить объект
        delTransportGroup(state, payload) {
            const index = state.transportGroups.findIndex(group => group.transport_group_id == payload.transport_group_id);
            state.transportGroups.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchTransportGroups: (context, payload) => {
            return DoGet(
                '/ref/transportGroups',
                'ref/setTransportGroups',
                [],
                'Запрос групп транспортных средств',
                false,
                payload && payload.force,
                context.state.transportGroupsCache,
                context.state.transportGroupsCacheTimeout
            )
        },

        // очищает кэш
        doClearTransportGroups: (context, payload) => {

            return DoClearCache(
                'ref/setTransportGroups',
                [],
                payload && payload.force,
                context.state.transportGroupsCache,
                context.state.transportGroupsCacheTimeout
            )
        },

        // добавление объекта
        doAddTransportGroup: (context, payload) => {
            return DoPost(
                '/ref/transportGroups',
                payload,
                'ref/addTransportGroup',
                'Добавление группы транспортных средств'
            )
        },

        // изменение объекта
        doEditTransportGroup: (context, payload) => {
            return DoPut(
                '/ref/transportGroups/'+payload.transport_group_id,
                payload,
                'ref/editTransportGroup',
                'Изменение группы транспортных средств'
            )
        },

        // удаление объекта
        doDelTransportGroup: (context, payload) => {
            return DoDelete(
                '/ref/transportGroups/'+payload.transport_group_id,
                'ref/delTransportGroup',
                'Удаление группы транспортных средств'
            )
        }
    }
}