import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ГРАФИКОВ ДВИЖЕНИЯ

export default {
    actions: {

        // запрос графика
        doFetchScheduleGraph: (context, payload) => {
            return DoGet(
                '/rmt/scheduleGraphs/'+payload.schedule_graph_id,
                null,
                {},
                'Запрос графика движения',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchScheduleGraphs: (context, payload) => {
            return DoGet(
                '/rmt/scheduleGraphs?schedule_graph_set_id=' + payload.schedule_graph_set_id,
                null,
                [],
                'Запрос графиков движения',
                false,
                true,
            )
        },

        // запрос списка всех объектов
        doFetchAllScheduleGraphs: () => {
            return DoGet(
                '/rmt/scheduleGraphs',
                null,
                [],
                'Запрос графиков движения',
                false,
                true,
            )
        },

        // добавление объекта
        doAddScheduleGraph: (context, payload) => {
            return DoPost(
                '/rmt/scheduleGraphs',
                payload,
                null,
                'Добавление графика движения'
            )
        },

        // изменение объекта
        doEditScheduleGraph: (context, payload) => {
            return DoPut(
                '/rmt/scheduleGraphs/' + payload.schedule_graph_id,
                payload,
                null,
                'Изменение графика движения'
            )
        },

        // удаление объекта
        doDelScheduleGraph: (context, payload) => {
            return DoDelete(
                '/rmt/scheduleGraphs/' + payload.schedule_graph_id,
                null,
                'Удаление графика движения'
            )
        },

        // принудительный пересчет графика (выхода) движения.
        doRecalcScheduleGraph: (context, payload) => {
            return DoPut(
                '/rmt/recalcScheduleGraph/' + payload.schedule_graph_id,
                null,
                null,
                'Пересчет графика движения',
                false,
            )
        },

        // проверка графика движения на ошибки
        doCheckScheduleGraph: (context, payload) => {
            context.state.scheduleGraphsErrors = [];
            return DoPut(
                '/rmt/checkScheduleGraph/' + payload.schedule_graph_id,
                null,
                null,
                'Проверка графика движения',
                false
            )
        },

        // копирование объекта
        doCopyScheduleGraph: (context, payload) => {
            return DoPost(
                '/rmt/copyScheduleGraph/' + payload.schedule_graph_id ,
                payload,
                null,
                'Копирование графика движения'
            )
        },

    }
}
