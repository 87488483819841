<template>
  <align-container>
    <align-panel align="all">
      <div class="card h-100 vehicle-button-panel">
        <align-container>
          <align-panel align="all">
            <align-container>
              <div class="card-body">
                <align-panel id="table" align="all" style="overflow-y: auto; overflow-x: auto">
                  <table class="table" style="overflow-y: auto;">
                    <thead class="thead-dark">
                    <tr>
                      <th
                          class="text-center" scope="col"
                          :colspan="5"
                          style="border-right: 1px solid #ffffff; border-bottom: 2px solid #ffffff; padding: 0.1rem"
                      >План
                      </th>
                      <th class="text-center" scope="col"
                          :colspan="11"
                          style="border-right: 1px solid #ffffff; border-bottom: 2px solid #ffffff; padding: 0.1rem"
                      >Факт
                      </th>
                      <th class="text-center" scope="col"
                          :colspan="2"
                          style="border-right: 1px solid #ffffff; border-bottom: 2px solid #ffffff; padding: 0.1rem"
                      >Доп.
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" scope="col">Вкл.</th>
                      <th class="text-center" scope="col">Марш.</th>
                      <th class="text-center" scope="col">Вид тр-та</th>
                      <th class="text-center" scope="col">Граф</th>
                      <th class="text-center" scope="col">См.</th>
<!--                      <th class="text-center" scope="col">Парк</th>-->
                      <th class="text-center" scope="col">Нач.</th>
                      <th class="text-center" scope="col">Окн.</th>
                      <th class="text-center" scope="col">Гар.ном.</th>
                      <th class="text-center" scope="col">Гос.ном.</th>
                      <th class="text-center" scope="col">Таб.Вод.</th>
                      <th class="text-center" scope="col">Ф.И.О.</th>
                      <th class="text-center" scope="col">Таб.Конд.</th>
                      <th class="text-center" scope="col">Ф.И.О.</th>
                      <th class="text-center" scope="col">Парк</th>
                      <th class="text-center" scope="col"></th>
                      <th class="text-center" scope="col"></th>
                      <th class="text-center" scope="col">Код</th>
                      <th class="text-center" scope="col" style="text-align: left !important;">Наимен.марш.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="baseOrder in rowsOnPage"
                        :key="baseOrder.base_order_id"
                        :class="{'bg-primary text-white':(selectedRow && selectedRow.base_order_id == baseOrder.base_order_id)}"
                        @click="select($event, baseOrder)"
                        :style="getRowStyle(baseOrder)"
                    >
                      <td style="padding-top: 2px !important; padding-bottom: 2px !important; text-align: center;"
                      >
                        <input-checkbox
                            :id="'isEnable' + baseOrder.base_order_id"
                            v-model="baseOrder.is_enabled"
                            :readonly="!baseOrder.isEdit"
                        ></input-checkbox>
                      </td>
                      <td style="padding-top: 2px !important; padding-bottom: 2px !important;"
                      >{{ baseOrder.__route_num }}
                      </td>
                      <td class="text-left" style="padding-top: 2px !important; padding-bottom: 2px !important;"
                      >{{ baseOrder.__transport_type }}
                      </td>
                      <td class="text-left" style="padding-top: 2px !important; padding-bottom: 2px !important;"
                      >{{ baseOrder.graph }}
                      </td>
                      <td class="text-left" style="padding-top: 2px !important; padding-bottom: 2px !important;"
                      >{{ baseOrder.shift }}
                      </td>
<!--                      <td style="padding-top: 2px !important; padding-bottom: 2px !important;"-->
<!--                      >{{ baseOrder.plan_depot_title }}-->
<!--                      </td>-->
                      <td class="text-center" style="padding-top: 2px !important; padding-bottom: 2px !important;">
                        <span>
                          {{ getTimeInFormat(baseOrder.time_index_begin) }}
                        </span>
                      </td>
                      <td class="text-center" style="padding-top: 2px !important; padding-bottom: 2px !important;">
                        <span>
                          {{ getTimeInFormat(baseOrder.time_index_end) }}
                        </span>
                      </td>
                      <td style="padding-top: 2px !important; padding-bottom: 2px !important;"
                      >
                        <order-vehicles-by-garage-num-select
                            v-if="baseOrder.isEdit"
                            v-model="baseOrder.transport_id"
                            :class="'form-control ' + isManualOrderInvalid(baseOrder)"
                            :isThisRowSelected="selectedRow && selectedRow.base_order_id == baseOrder.base_order_id"
                            :depot_id="baseOrder.fact_depot_id"
                            style="height: 30px; width: 130px; padding: 0 !important; z-index: 1 !important;"
                            @change="changeVehicle(baseOrder)"
                        ></order-vehicles-by-garage-num-select>
                        <span v-else>{{ baseOrder.garage_num }}</span>
                      </td>
                      <td class="text-left" style="padding-top: 2px !important; padding-bottom: 2px !important;">
                        <order-vehicles-by-state-num-select
                            v-if="baseOrder.isEdit"
                            v-model="baseOrder.transport_id"
                            :isThisRowSelected="selectedRow && selectedRow.base_order_id == baseOrder.base_order_id"
                            :class="'form-control ' + isManualOrderInvalid(baseOrder)"
                            :depot_id="baseOrder.fact_depot_id"
                            style="height: 30px; width: 130px; padding: 0 !important"
                        >
                        </order-vehicles-by-state-num-select>
                        <span v-else>{{ baseOrder.state_num }}</span>
                      </td>
                      <td class="text-left" style="padding-top: 2px !important; padding-bottom: 2px !important;">
                        <order-drivers-by-personnel-num-select
                            v-if="baseOrder.isEdit"
                            v-model="baseOrder.driver_id"
                            :isThisRowSelected="selectedRow && selectedRow.base_order_id == baseOrder.base_order_id"
                            class="form-control"
                            :depot_id="baseOrder.fact_depot_id"
                            style="height: 30px; width: 180px; padding: 0 !important"
                        >
                        </order-drivers-by-personnel-num-select>
                        <span v-else>{{ baseOrder.driver_num }}</span>
                      </td>
                      <td style="padding-top: 2px !important; padding-bottom: 2px !important;">
                        <order-drivers-by-name-select
                            v-if="baseOrder.isEdit"
                            v-model="baseOrder.driver_id"
                            :isThisRowSelected="selectedRow && selectedRow.base_order_id == baseOrder.base_order_id"
                            class="form-control"
                            :depot_id="baseOrder.fact_depot_id"
                            style="height: 30px; width: 180px; padding: 0 !important"
                        >
                        </order-drivers-by-name-select>
                        <span v-else>{{ baseOrder.__driver_name }}</span>
                      </td>

                      <td class="text-left" style="padding-top: 2px !important; padding-bottom: 2px !important;">
                        <order-conductors-by-personnel-num-select
                            v-if="baseOrder.isEdit"
                            v-model="baseOrder.conductor_id"
                            :isThisRowSelected="selectedRow && selectedRow.base_order_id == baseOrder.base_order_id"
                            class="form-control"
                            :depot_id="baseOrder.fact_depot_id"
                            style="height: 30px; width: 180px; padding: 0 !important"
                        >
                        </order-conductors-by-personnel-num-select>
                        <span v-else>{{ baseOrder.conductor_num }}</span>
                      </td>
                      <td style="padding-top: 2px !important; padding-bottom: 2px !important;">
                        <order-conductors-by-name-select
                            v-if="baseOrder.isEdit"
                            v-model="baseOrder.conductor_id"
                            :isThisRowSelected="selectedRow && selectedRow.base_order_id == baseOrder.base_order_id"
                            class="form-control"
                            :depot_id="baseOrder.fact_depot_id"
                            style="height: 30px; width: 180px; padding: 0 !important"
                        >
                        </order-conductors-by-name-select>
                        <span v-else>{{ baseOrder.__conductor_name }}</span>
                      </td>

                      <td class="text-left" style="padding-top: 2px !important; padding-bottom: 2px !important;"
                      >
                        <order-depots-select
                            v-if="baseOrder.isEdit"
                            v-model="baseOrder.fact_depot_id"
                            :isThisRowSelected="selectedRow && selectedRow.base_order_id == baseOrder.base_order_id"
                            class="form-control"
                            style="height: 30px; padding: 0 !important; min-width: 150px"
                            @change="onChangeDepot(baseOrder)"
                        >
                        </order-depots-select>
                        <span v-else>{{ baseOrder.fact_depot_title }}</span>
                      </td>
                      <td class="text-left"
                          style="padding-top: 2px !important; padding-bottom: 2px !important; width: 30px"
                      >
                        <button
                            v-if="baseOrder.isEdit"
                            class="btn btn-success btn-sm"
                            :title="'Сохранить'"
                            style="width: 30px !important; height: 30px !important; paddingTop: 3px; paddingBottom: 3px; paddingRight: 3px; paddingLeft: 3px;"
                            @click="onFirstButton(baseOrder)"
                            :disabled="(baseOrder.conductor_id || baseOrder.driver_id) && !baseOrder.transport_id"
                        >
                          <i class="fas fa-save"></i>
                        </button>
                        <button
                            v-else
                            class="btn btn-warning btn-sm"
                            :title="'Редактировать'"
                            style="width: 30px !important; height: 30px !important; paddingTop: 3px; paddingBottom: 3px; paddingRight: 3px; paddingLeft: 3px;"
                            @click="onFirstButton(baseOrder)"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                      </td>
                      <td class="text-left" style="padding-top: 2px !important; padding-bottom: 2px !important;"
                      >
                        <button
                            v-if="baseOrder.isEdit"
                            class="btn btn-danger btn-sm"
                            :title="'Отмена'"
                            style="width: 30px !important; height: 30px !important; paddingTop: 3px; paddingBottom: 3px; paddingRight: 3px; paddingLeft: 3px;"
                            @click="onSecondButton(baseOrder)"
                        >
                          <i class="fas fa-ban"></i>
                        </button>
                      </td>
                      <td class="text-left" style="padding-top: 2px !important; padding-bottom: 2px !important;"
                      >{{ baseOrder.route_id }}
                      </td>
                      <td class="text-left" style="padding-top: 2px !important; padding-bottom: 2px !important;"
                      >{{ baseOrder.route_title }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </align-panel>
                <align-panel v-if="pageCount > 1" align="bottom">
                  <table-pager
                      :pageCount="pageCount"
                      :curPage="curPage"
                      @onPageChanged="onPageChanged"
                  ></table-pager>
                </align-panel>
              </div>
            </align-container>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>
</template>

<script>

import {quickSort, secondsToTime, workTaskSortFn} from "@/lib";
import {getTransportType} from "@/store/RefHelpers";
import OrderVehiclesByGarageNumSelect from "@/components/trn/base_orders/OrderVehiclesByGarageNumSelect";
import OrderVehiclesByStateNumSelect from "@/components/trn/base_orders/OrderVehiclesByStateNumSelect";
import OrderDriversByPersonnelNumSelect from "@/components/trn/base_orders/OrderDriversByPersonnelNumSelect";
import OrderDriversByNameSelect from "@/components/trn/base_orders/OrderDriversByNameSelect";
import OrderDepotsSelect from "@/components/trn/base_orders/OrderDepotsSelect";
import TablePager from "@/components/ui/custom/TablePager";
import {getOrderDepotTrn, getOrderRouteTrn} from "@/store/TrnHelpers";
import OrderConductorsByNameSelect from "@/components/trn/base_orders/OrderConductorsByNameSelect";
import OrderConductorsByPersonnelNumSelect from "@/components/trn/base_orders/OrderConductorsByPersonnelNumSelect";
import InputCheckbox from "@/components/ui/custom/InputCheckbox";

export default {
  components: {
    InputCheckbox,
    OrderConductorsByPersonnelNumSelect,
    OrderConductorsByNameSelect,
    TablePager,
    OrderDepotsSelect,
    OrderDriversByNameSelect,
    OrderDriversByPersonnelNumSelect,
    OrderVehiclesByStateNumSelect,
    OrderVehiclesByGarageNumSelect,
  },
  emits: ['onRowSelect', 'doEdit', 'saveAllChangedRows'],
  props: ['rows', 'selectedRow', 'isEditAllEnable', 'isSaveAll', 'originalBaseOrdersArr', 'filterText'],
  data() {
    return {
      // Количество строк на странице
      rowsPerPage: 50,
      // текущая страница
      curPage: 1,
    }
  },

  watch: {

    // если на странице нажаса кнопка сохранения всех, анализируем все строки и передаем на страницу те, в которых были изменения
    isSaveAll(newValue) {
      if (newValue) {
        let changedRows = [];
        this.rowList.forEach(baseOrder => {
          let oldValue = this.originalBaseOrdersArr.find(value => value.base_order_id == baseOrder.base_order_id);
          if (oldValue.transport_id != baseOrder.transport_id
              || oldValue.driver_id != baseOrder.driver_id
              || oldValue.fact_depot_id != baseOrder.fact_depot_id) {
            changedRows.push(baseOrder);
          }
        })
        this.$emit('saveAllChangedRows', changedRows)
      }
    },

    // если включено или выключено редактирование всех строк, необходимо во всех строках поменять триггер режима отображения
    isEditAllEnable(newValue) {
      this.rows.forEach(baseOrder => {
        if (newValue) {
          baseOrder.isEdit = true;
        } else {
          baseOrder.isEdit = false;
        }
      })
    }

  },

  computed: {

    // строки для отображения
    rowsOnPage() {
      // определяем начальный и конечный индексы
      let startIndex = (this.curPage - 1) * this.rowsPerPage;
      let endIndex = startIndex + this.rowsPerPage - 1;
      if (endIndex >= this.rowList.length) endIndex = this.rowList.length - 1;

      // формируем массив
      const rows = [];
      for (let i = startIndex; i <= endIndex; i++) {
        rows.push(this.rowList[i])
      }

      // возвращаем результат
      return rows;
    },

    // количество страниц
    pageCount() {
      return this.rowList.length % this.rowsPerPage === 0
          ? Math.floor(this.rowList.length / this.rowsPerPage)
          : Math.ceil(this.rowList.length / this.rowsPerPage);
    },

    // невозможно выбрать водителя и не выбрать ТС, поэтому запрещаем редактирование
    isButtonDisabled(baseOrder) {
      if (baseOrder.driver_id && !baseOrder.transport_id) {
        return true;
      }
      return false
    },


    // isSaveButtonDisabled() {
    //   let isDisabled = false;
    //   for (let i = 0; i < this.manualOrdersArr.length; i++) {
    //     if (this.manualOrdersArr[i].driver_id && !this.manualOrdersArr[i].transport_id) {
    //       isDisabled = true;
    //       break;
    //     }
    //   }
    //   return isDisabled;
    // },

    // возвращаем массив всех строк
    rowList() {

      // // итоговый список строк
      let resultArr = [];
      // формируем итоговый список строк
      for(let i = 0; i < this.rows.length; i++){

        let baseOrder = this.rows[i];

        baseOrder.__id = baseOrder.base_order_id;

        // определяем маршрут
        let route = getOrderRouteTrn(baseOrder.route_id);
        if (route) {
          baseOrder.__route_num = route.route_num;
          baseOrder.route_title = route.route_title;
        }

        // т.к. фильтруем только по __routeNum фильтр находится тут
        if(this.filterText != '') {
          if (!baseOrder.__route_num.includes(this.filterText.toLowerCase())) {
            continue;
          }
        }

        // определяем тип транспорта
        let trType = getTransportType(baseOrder.transport_type_id);
        if (trType) {
          baseOrder.__transport_type = trType.short_name
        }

        // определяем плановый парк
        let depot = getOrderDepotTrn(baseOrder.plan_depot_id);
        if (depot) {
          baseOrder.plan_depot_title = depot.long_name;
        }

        // // определяем ТС, (если есть)
        // let ts = getOrderVehicle(baseOrder.transport_id);
        // if (ts) {
        //   baseOrder.__garage_num = ts.garage_num;
        //   baseOrder.__state_num = ts.state_num;
        // }
        // // определяем водителя (если выбран)
        // let driver = getOrderDriver(baseOrder.driver_id);
        // if (driver) {
        //   baseOrder.__personnel_num = driver.personnel_num;
        //   baseOrder.__driver_name = driver.last_name + ' ' + driver.first_name.substring(0, 1) + '.' + driver.middle_name.substring(0, 1) + '.';
        // }
        // // определяем фактический парк
        let fact_depot = getOrderDepotTrn(baseOrder.fact_depot_id);
        if (fact_depot) {
          baseOrder.fact_depot_title = fact_depot.long_name;
        }

        if(baseOrder.driver_id && baseOrder.driver_last_name) {
          baseOrder.__driver_name = baseOrder.driver_last_name + ' ' + baseOrder.driver_first_name.substring(0, 1) + '.' + baseOrder.driver_middle_name.substring(0, 1) + '.';
        } else {
          baseOrder.__driver_name = '';
        }

        if(baseOrder.conductor_id && baseOrder.conductor_last_name) {
          baseOrder.__conductor_name = baseOrder.conductor_last_name + ' ' + baseOrder.conductor_first_name.substring(0, 1) + '.' + baseOrder.conductor_middle_name.substring(0, 1) + '.';
        } else {
          baseOrder.__conductor_name = '';
        }

        baseOrder.__time_index_begin = (baseOrder.time_index_begin) - Math.floor((baseOrder.time_index_begin) / (24 * 60 * 60)) * 24 * 60 * 60;
        baseOrder.__time_index_end = (baseOrder.time_index_end) - Math.floor((baseOrder.time_index_end) / (24 * 60 * 60)) * 24 * 60 * 60;
        resultArr.push(baseOrder);
      }
      // сортируем массив
      quickSort(resultArr, (leftItem, rightItem) => {
        return 1 * workTaskSortFn(leftItem, rightItem);
      });

      return resultArr;
    }
  },

  methods: {

    changeVehicle(baseOrder) {
      if(!baseOrder.is_enabled) {
        baseOrder.is_enabled = true;
      }
    },

    // вызывается при переходе на новую страницу таблицы
    onPageChanged(newPage) {
      this.curPage = newPage;
    },

    // если выбран водитель, но не выбрано ТС, подсвечиваем ошибку
    isManualOrderInvalid(baseOrder) {
      if (baseOrder.driver_id && !baseOrder.transport_id) {
        return 'is-invalid';
      }
      return '';
    },

    // при изменении парка необходимо сбросить выбранные значения в выпадающих списках
    onChangeDepot(baseOrder) {
      baseOrder.transport_id = null;
      baseOrder.driver_id = null;
    },

    // получаем время текстом для таблицы
    getTimeInFormat(seconds) {
      return secondsToTime(seconds)
    },

    // делаем строку выбранной
    select(e, nav_id) {
      if (!this.isLoading) {
        this.$emit('onRowSelect', nav_id);
      }
    },

    // получаем стиль строк
    getRowStyle(baseOrder) {

      if(baseOrder.is_enabled){
        return 'cursor: pointer; user-select: none; background-color: ' + this.$store.getters['settings/getProductionReisColor2'];
      } else {
        return 'cursor: pointer; user-select: none;';
      }
    },

    // нажата кнопка в первом столбце c кнопками (действия отличаются в зависимости от того редактируется ли строка)
    onFirstButton(row) {
      // если строка в списке редактируемых:
      if (row.isEdit) {
        row.isEdit = false;
        // // отправляем запрос на редактирование
        this.$emit('doEdit', row);
        //если строки нет в списке редактируемых
      } else {
        row.isEdit = true;
      }
    },

    // нажата кнопка во втором столбце c кнопками
    onSecondButton(row) {
      row.isEdit = false;
      let oldValue = this.originalBaseOrdersArr.find(value => value.base_order_id == row.base_order_id);
      row.driver_id = oldValue.driver_id;
      row.transport_id = oldValue.transport_id;
      row.fact_depot_id = oldValue.fact_depot_id;
    },

  }
}
</script>

<style scoped>


span {
  margin: 0;
  white-space: nowrap;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

th {
  margin: 0;
  white-space: nowrap;
  position: sticky;
  top: 0px;
  padding: 0.1rem 0.2rem;
  z-index: 1000 !important;
}

td {
  vertical-align: middle;
  white-space: nowrap;
  padding: 0 2px;
}
</style>