import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ОРГАНИЗАЦИЙ

export default {
    state() {
        return {
            // список объектов
            agencies: [],
            // время обновления кэша new Date().getTime()
            agenciesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            agenciesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getAgencies(state) {
            return state.agencies;
        },
    },
    mutations: {
        // установить новый список объектов
        setAgencies(state, payload) {
            state.agencies = payload;
            state.agenciesCache = new Date().getTime()
        },
    },
    actions: {

        // запрос списка объектов
        doFetchAgencies: (context, payload) => {
            return DoGet(
                '/kvr/getAgencies',
                'kvr/setAgencies',
                [],
                'Запрос списока доступных перевозчиков для модуля КВР',
                false,
                payload && payload.force,
                context.state.agenciesCache,
                context.state.agenciesCacheTimeout
            )
        },

        // очищает кэш
        doClearAgencies: (context, payload) => {

            return DoClearCache(
                'kvr/setAgencies',
                [],
                payload && payload.force,
                context.state.agenciesCache,
                context.state.agenciesCacheTimeout
            )
        },
    }
}