import {DoDelete, DoGet, DoGetFile, DoPostFile} from "@/store/Actions";

// ХРАНИЛИЩЕ ФАЙЛОВ ДОКУМЕНТОВ ОСТАНОВОЧНЫХ ПУНКТОВ

export default {
    actions: {
        // запрос списка объектов
        doFetchStopDocFile: (context, payload) => {
            return DoGetFile(
                '/multipass/stopDocFiles/' + payload.stop_doc_id + "/" + payload.document_file_id,
                null,
                'Загрузка файла',
                false,
                payload.file_name,
            )
        },

        // запрос списка объектов
        doFetchStopDocFiles: (context, payload) => {
            return DoGet(
                '/multipass/stopDocFiles/' + payload.stop_doc_id,
                null,
                [],
                'Запрос файлов документа остановочного пункта',
                false,
                true,
            )
        },

        // добавление объекта
        doAddStopDocFile: (context, payload) => {
            return DoPostFile(
                '/multipass/stopDocFiles/' + payload.stop_doc_id,
                payload.file.file,
                null,
                'Добавление файла документа остановочного пункта'
            )
        },

        // удаление объекта
        doDelStopDocFile: (context, payload) => {
            return DoDelete(
                '/multipass/stopDocFiles/' + payload.stop_doc_id + "/" + payload.document_file_id,
                null,
                'Удаление файла документа остановочного пункта'
            )
        }
    },
}