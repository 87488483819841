<template>
  <div class="input-group" style="max-width: 280px">
    <div class="input-group-prepend">
      <select
          v-model="raceKind"
          :disabled="readonly"
          class="form-control"
      >
        <option value="1">Производ.</option>
        <option value="2">Нулевой</option>
        <option value="3">Холостой</option>
      </select>
      <dropdown>
        <dropdown-menu ref="collapse">
          <dropdown-item
              v-for="letter in raceLetters"
              :key="letter.id"
              @click="onSetRaceLetter(letter.letter)"
          >{{ letter.title }}</dropdown-item>
        </dropdown-menu>
      </dropdown>
      <button-toggler
          target-ref="collapse"
          class="btn btn-light dropdown-toggle-split border rounded"
          :disabled="!raceLettersEnabled || readonly"
      >{{ raceLetter || '...'}}</button-toggler>
    </div>
    <input
        type="text"
        maxlength="2"
        v-model="raceNumber"
        class="form-control"
        :readonly="!raceLetter || readonly"
        @blur="onBlur"
    >
    <div class="input-group-append">
      <button
          class="btn btn-outline-secondary"
          type="button"
          @click="onSetDirection"
          :disabled="readonly"
      >{{ directionSign }}</button>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/ui/bootstrap/dropdown/Dropdown";
export default {
  components: {Dropdown},
  props: {
    'model-value': {
      required: true
    },
    'readonly': {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      // вид рейса 1 - производственный, 2 - нулевой, 3 - холостой
      raceKind: 1,
      // буква рейса
      raceLetter: '',
      // номер рейса
      raceNumber: '00',
      // направление AB - прямое, BA - обратное
      direction: 'AB'
    }
  },
  computed: {

    // буквы рейсов
    raceLetters() {
      if (this.raceKind == 1) {
        return [
          {id: 1, title: 'ОСНОВНОЙ', letter: ''},
          {id: 2, title: 'Укороченный', letter: 'У'},
          {id: 3, title: 'Удлинённый', letter: 'Д'},
          {id: 4, title: 'Экспрессный', letter: 'Э'},
          {id: 5, title: 'Изменённый', letter: 'И'},
          {id: 6, title: 'Маневровый', letter: 'М'}
        ];
      }
      return [];
    },

    // буквы рейсов включены
    raceLettersEnabled() {
      return this.raceKind == 1;
    },

    // символ направления
    directionSign() {
      if (this.raceKind == 2) {
        return this.direction === 'BA' ? '<<<' : '>>>';
      }
      else if (this.raceKind == 3) {
        return this.direction === 'BA' ? '<<' : '>>';
      }
      else {
        return this.direction == 'BA' ? '<' : '>';
      }
    }
  },
  methods: {

    // формирует результат
    emitValue() {
      this.$emit('update:model-value', this.raceLetter + this.raceNumber + this.direction);
    },

    // установка новой буквы
    onSetRaceLetter(letter) {
      this.raceLetter = letter;
      if (letter)
        this.raceNumber = '01';
      else
        this.raceNumber = '00';
      // оповещаем
      this.emitValue();
    },

    // установка нового направления
    onSetDirection() {
      if (this.direction === 'BA')
        this.direction = 'AB'
      else
        this.direction = 'BA'
      // оповещаем
      this.emitValue();
    },

    // при потере фокуса
    onBlur() {
      // недопустимое значение
      if (this.raceNumber === '' || this.raceNumber === '0') {
        this.raceNumber = '01'
      }
      // длина не соответсвует
      else if (this.raceNumber.length === 1) {
        this.raceNumber = '0' + this.raceNumber;
      }
    },

    // расшифровываем значение
    decodeModelValue(value) {

      // направление
      this.direction = 'AB';
      if (value && (value.substr(-2) === 'AB' || value.substr(-2) === 'BA')) {
        if (value.substr(-2) === 'BA')
          this.direction = 'BA'
        value = value.substr(0, value.length - 2);
      }

      // нулевой рейс
      if (value && value[0] === '_') {
        this.raceKind = '2';
        this.raceLetter = '_';
        this.raceNumber = value.substr(1);
        //выходим
        return;
      }

      // холостой рейс
      if (value && value[0] === '-') {
        this.raceKind = '3';
        this.raceLetter = '-';
        this.raceNumber = value.substr(1)
        //выходим
        return;
      }

      // производственный не основной
      if (value && (value[0] === 'У' || value[0] === 'Д' || value[0] === 'Э' || value[0] === 'И' || value[0] === 'М')) {
        this.raceKind = '1';
        this.raceLetter = value[0];
        this.raceNumber = value.substr(1);
        //выходим
        return;
      }

      // все остальное - основной
      this.raceKind = '1';
      this.raceLetter = '';
      this.raceNumber = '00'
      // оповещаем
      this.emitValue();
    },

    // возвращает признак производственного рейса
    isProduction() {
      return this.raceKind == 1;
    }
  },

  watch: {
    // следим за изменением вида рейса
    raceKind(newValue) {
      // производственный
      if (newValue == 1) {
        this.raceLetter = '';
        this.raceNumber = '00';
      }
      // нулевой
      else if (newValue == 2) {
        this.raceLetter = '_';
        this.raceNumber = '01';
      }
      // холостой
      else if (newValue == 3) {
        this.raceLetter = '-';
        this.raceNumber = '01';
      }
      // оповещаем
      this.emitValue();
    },

    // следим за изменением номера рейса
    raceNumber(newValue) {

      // для основных рейсов ничего не меняем
      if (!this.raceLetter) return;

      // удаляем пробелы
      if (newValue !== newValue.trim()) {
        this.raceNumber = newValue.trim();
      }

      // должно быть числовое значение
      if ((newValue !== '' && !Number.isFinite(Number(newValue))) || newValue === '00' || Number(newValue) >= 100 || Number(newValue) < 0) {
        this.raceNumber = '01';
      }

      // оповещаем
      this.emitValue();
    },
  },

  created() {
    // следим за свойством
    this.$watch(() => this.modelValue, ()=> {
      this.decodeModelValue(this.modelValue);
    }, {immediate: true});
  }
}
</script>
