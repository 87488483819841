<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/ref">Справочники</router-link></span>
        <span> / </span>
        <span>Классификатор объектов административно-территориального деления</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{edit: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onEdit="onTableEdit"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >
          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <area-classifier-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></area-classifier-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <area-classifier-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </area-classifier-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import AreaClassifierEditTable from "@/components/ref/area_classifier/AreaClassifierEditTable";
import AreaClassifierDialog from "@/components/ref/area_classifier/AreaClassifierDialog";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    AreaClassifierDialog,
    AreaClassifierEditTable
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op", 
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }    
  },

  computed: {
      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            add: true, 
            edit: !!this.selectedRow, 
            delete: !!this.selectedRow, 
            refresh: true
          }
      },

      // условие отображения диалогового окна
      isDialogVisible() {
          return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
      },

      // данные, которые отображаются в диалоговом окне
      dialogRow() {
        return this.op === 'add' ? {} : this.selectedRow;
      }
  },
  methods: {

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/ref/areaClassifier/edit/" + this.selectedRow.area_classifier_id);
    },

    // выполнено двойное нажатие строки в таблице (открываем просмотр)
    onTableShow(item) {
      this.$router.replace("/ref/areaClassifier/show/" + item.area_classifier_id);
    },
    
    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/ref/areaClassifier");
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },
    
    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/ref/areaClassifier");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchAreaClassifier', { force });

      }
      finally {
        this.selectedRow=null;
        this.isLoading=false;
      }
    },

    // модифицируем объект
    editItem(item) {
      this.isLoading = true;
      try {

        const row = this.$store.dispatch('ref/doEditAreaClassifier', item);
        if (row) this.selectedRow = row;
          else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

