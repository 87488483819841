<template>
  <align-container>
    <align-panel align="top">
      <page-header>
        <span><router-link to="/kvr">КВР</router-link></span>
        <span> / </span>
        <span><router-link to="/kvr/workTasks">Журнал</router-link></span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <align-panel align="top" :gap="3">
          <!-- Форма с кнопками над левой таблицей-->
          <card class="text-left">
            <card-body class="p-1">
              <div class="form-row">

                <div class="col-0" style="margin-bottom: 0 !important;">
                  <button
                      type="button"
                      class="btn btn-success btn-sm"
                      @click="onRefreshExpResultTrips"
                      title="Загрузить"
                      style="padding-top: 0; padding-bottom: 0; height: 31px;"
                  >
                    <a style="font-size: 16px">Загрузить</a>
                  </button>
                </div>

                <!--             дата с                -->
                <div class="col-0" style="margin-bottom: 0 !important; padding-right: 0; vertical-align: middle">
                  <p style="margin: 4px 0 0 0;"> Дата c</p>
                </div>
                <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                  <input-date
                      class="form-control"
                      v-model="startDate"
                      style="padding: 0 5px; height: 31px; width: 115px;"
                      @change="changeStartDate()"
                  >
                  </input-date>
                </div>

                <!--             дата по                -->
                <div class="col-0"
                     style="margin-bottom: 0 !important; padding-right: 0; padding-left: 0; vertical-align: middle">
                  <p style="margin: 4px 0 0 0;">по</p>
                </div>
                <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                  <input-date
                      class="form-control"
                      v-model="endDate"
                      style="padding: 0 5px; height: 31px; width: 115px;"
                      @change="changeEndDate()"
                  >
                  </input-date>
                </div>


                <!--             выбор маршрута     -->
                <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                  <routes-select
                      id="route"
                      v-model="kvrRoute"
                      class="form-control"
                      style="padding: 0 5px; height: 31px; width: 200px"
                      mode="kvr"
                  >
                  </routes-select>
                </div>

                <!--             выбор эксперта     -->
                <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                  <experts-select
                      id="uid"
                      v-model="uid"
                      class="form-control"
                      style="padding: 0 5px; height: 31px; width: 200px"
                  >
                  </experts-select>
                </div>

                <input-button-option
                    v-model="is_trip_date_index"
                    :itemList="[{id: true, title: 'Дата наряда'}, {id: false, title: 'Дата журнала'}]"
                    required
                    style="margin-left: 15px"
                    :buttonsStyle="'height:31px; padding-top: 3px;'"
                    :inputType="'text'"
                ></input-button-option>

<!--                  Заглушка, которая занимает пустое место между элементами, для правильной расстановки элементов-->
                <div class="col"></div>

              </div>
            </card-body>
          </card>
        </align-panel>
        <align-panel align="all">
          <!--  таблица с логами -->
          <command-logs-edit-table
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
              :rows="resultTrips"
          >
          </command-logs-edit-table>
        </align-panel>
      </align-container>
    </align-panel>

  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>
</template>

<script>

import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import PageMixin from "@/pages/share/PageMixin";
import InputDate from "@/components/ui/custom/InputDate";
import RoutesSelect from "@/components/multipass/routes/RoutesSelect";
import {dateToND, dateToString, stringToDate} from "@/lib";
import ExpertsSelect from "@/components/kvr/experts/ExpertsSelect";
import CommandLogsEditTable from "@/components/kvr/kvr_expert_command_log/CommandLogsEditTable";

export default {
  name: "informationByTsPage",
  mixins: [PageMixin],
  components: {
    CommandLogsEditTable,
    ExpertsSelect,
    RoutesSelect,
    InputDate,
    AlignPanel, AlignContainer
  },
  props: [
    // операция show передается через роутер
    'op',
    // идентификатор планового рейса
    'plan_trip_id',
  ],
  data() {
    return {
      // отображать спиннер
      isLoading: false,
      // дата с
      startDate: dateToString(),
      // дата по
      endDate: dateToString(),
      // эксперт
      uid: null,
      // выбранный маршрут
      kvrRoute: null,
      // фильтрация по дате рейса или фильтрация осуществляется по дате журнала
      is_trip_date_index: false,
      // строки таблицы
      resultTrips: [],
      // выделенные строки
      selectedRow: null,

    }
  },

  computed: {



  },

  methods: {

    onRowSelect(row) {
      this.selectedRow = row;
    },


    async onRefreshExpResultTrips() {
      try{
        this.isLoading = true;
        let payload = {};
        payload['date_index_begin'] = dateToND(stringToDate(this.startDate));
        payload['date_index_end'] = dateToND(stringToDate(this.endDate));
        if (this.kvrRoute) {
          payload['route_id'] = this.kvrRoute;
        }
        if (this.uid) {
          payload['uid'] = this.uid;
        }
        if (this.is_trip_date_index) {
          payload['is_trip_date_index'] = this.is_trip_date_index;
        }

        this.resultTrips = await this.$store.dispatch('kvr-expert/doFetchExpCommandLogs', payload);
      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // Изменение даты начала
    changeStartDate() {
      this.endDate = this.startDate;
    },

    // Изменение даты начала
    changeEndDate() {
      if (dateToND(stringToDate(this.startDate)) > dateToND(stringToDate(this.endDate))) {
        this.startDate = this.endDate;
      }
    },

    clearSelection() {
      this.selectedRow = null;
    },

    // выбрана строка в таблице Рейсов
    onRowGraphSelect(item) {
      this.selectedGraphRow = item;
    },

    // нажата конпка "Просмотр"
    async onShowTripCardItems() {
      await this.getTripCard();
      let plan_trip_id = (this.selectedRows[0].plan_trip_id != null || this.selectedRows[0].plan_trip_id != undefined) ? this.selectedRows[0].plan_trip_id : "";
      await this.$router.replace('/kvr/kvrExpertAnalysis/show/' + plan_trip_id);
    },

    onRejectOperation() {
      this.tripCardItems = [];
      this.$router.replace('/kvr/kvrExpertAnalysis');
    },

    // получение карточки фактического рейса
    async getTripCard() {
      this.isLoading = true;
      try {
        let plan_trip_id = (this.selectedRows[0].plan_trip_id != null || this.selectedRows[0].plan_trip_id != undefined) ? this.selectedRows[0].plan_trip_id : "";
        let tripType = await this.$store.dispatch('kvr/doFetchTripCardByPlanTripId', {
          plan_trip_id: plan_trip_id,
          date_index: this.selectedRows[0].date_index
        });
        if (tripType) {
          this.tripCardItems = tripType;
        }
      } finally {
        this.isLoading = false;
      }
    },

  },

  async created() {
    // загружаем перевозчиков
    await this.$store.dispatch('kvr/doFetchAgencies');
    // загружаем маршруты
    await this.$store.dispatch('kvr/doFetchRoutes');
    // загружаем транспортные предприятия
    await this.$store.dispatch('kvr/doFetchDepots');
    // загружаем перевозчики
    await this.$store.dispatch('kvr/doFetchAgencies');
    // загружаем виды транспорта
    await this.$store.dispatch('ref/doFetchTransportTypes');
    // загружаем экспертов
    await this.$store.dispatch('kvr-expert/doFetchExpCommands');
    // загружаем экспертов
    await this.$store.dispatch('kvr-expert/doFetchExpCommandReasons');
    // загружаем класс ТС
    await this.$store.dispatch('ref/doFetchTransportClasses');
    // // получаем данные по итоговым графикам
    // await this.onRefresfResultGraphs();
    // загрузка маршрутов
    await this.$store.dispatch('kvr/doFetchRoutes');

    await this.$store.dispatch('kvr-expert/doFetchExperts');
  }

}
</script>

<style scoped>

</style>
