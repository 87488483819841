import axios from 'axios';
import store from './Store';
import router from '../router/Router';
import {fromBase64} from "js-base64";

// Выполнение post-запроса
export async function postRequest(url, json, tid) {

    try {
        // выполняем запрос
        const response = await axios.post(url, json, {
            headers: {
                Authorization: tid ? `Bearer ${tid}` : null
            }
        });

        // определяем коды
        const httpCode = parseInt(response.status);
        const httpStatus = response.statusText;

        // логируем
        if (process.env.NODE_ENV === 'development') {
            console.debug('-----')
            console.debug(`${httpCode} ${httpStatus}`)
            console.debug(response.data)
        }

        // возвращаем результат
        return {
            httpCode,
            httpStatus,
            data: response.data
        }
    } catch (e) {
        // вернулся ответ с ошибкой
        if (e.response) {
            // запрашиваем статусы http
            const httpCode = parseInt(e.response.status);
            const httpStatus = e.response.statusText;

            // определяем внутренние ошибки
            let error = 0;
            let errorMessage = '';
            if (e.response.data && e.response.data['error'] && e.response.data['error_message']) {
                error = e.response.data['error'];
                errorMessage = e.response.data['error_message'];
            }

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.error('-----')
                console.error(`${httpCode} ${httpStatus}`)
                console.error(e.response.data)
            }

            // если сервер вернул - требуется аутентификация
            if (httpCode == 401 && router.currentRoute.value.path != "/auth") {
                // запоминаем откуда уходим
                await store.dispatch('doSetLoadedUrl', {
                    loadedUrl: router.currentRoute.value
                });
                // очищаем данные аутентификации
                await store.commit('clearAuth');
                // переходим на страницу авторизации
                await router.replace('/auth');
            }

            // формируем ошибку
            const newError = new Error(errorMessage || httpStatus);
            newError.httpCode = httpCode;
            newError.httpStatus = httpStatus;
            newError.error = error;
            newError.tid = e.response.data.tid;
            newError.errorMessage = errorMessage;
            throw newError;
        }
        // вернулся ответ без ошибки
        else {
            // формируем ошибку
            const newError = new Error('Ошибка сети');
            newError.httpCode = 0;
            newError.httpStatus = '';
            newError.error = 0;
            newError.errorMessage = '';
            throw newError;
        }
    }
}

// Выполнение put-запроса
export async function putRequest(url, json, tid) {

    // axios.defaults.timeout = 500;
    try {
        // выполняем запрос
        const response = await axios.put(url, json, {
            headers: {
                Authorization: tid ? `Bearer ${tid}` : null
            }
        });

        // определяем коды
        const httpCode = parseInt(response.status);
        const httpStatus = response.statusText;

        // логируем
        if (process.env.NODE_ENV === 'development') {
            console.debug('-----')
            console.debug(`${httpCode} ${httpStatus}`)
            console.debug(response.data)
        }

        // возвращаем результат
        return {
            httpCode,
            httpStatus,
            data: response.data
        }
    } catch (e) {
        // вернулся ответ с ошибкой
        if (e.response) {
            // запрашиваем статусы http
            const httpCode = parseInt(e.response.status);
            const httpStatus = e.response.statusText;

            // определяем внутренние ошибки
            let error = 0;
            let errorMessage = '';
            if (e.response.data && e.response.data['error'] && e.response.data['error_message']) {
                error = e.response.data['error'];
                errorMessage = e.response.data['error_message'];
            }

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.error('-----')
                console.error(`${httpCode} ${httpStatus}`)
                console.error(e.response.data)
            }

            // если сервер вернул - требуется аутентификация
            if (httpCode == 401 && router.currentRoute.value.path != "/auth") {
                // запоминаем откуда уходим
                await store.dispatch('doSetLoadedUrl', {
                    loadedUrl: router.currentRoute.value
                });
                // очищаем данные аутентификации
                await store.commit('clearAuth');
                // переходим на страницу авторизации
                await router.replace('/auth');
            }
            // формируем ошибку
            const newError = new Error(errorMessage || httpStatus);
            newError.httpCode = httpCode;
            newError.httpStatus = httpStatus;
            newError.error = error;
            newError.tid = e.response.data.tid;
            newError.errorMessage = errorMessage;
            throw newError;
        }
        // вернулся ответ без ошибки
        else {
            // формируем ошибку
            const newError = new Error('Ошибка сети');
            newError.httpCode = 0;
            newError.httpStatus = '';
            newError.error = 0;
            newError.errorMessage = '';
            throw newError;
        }
    }
}

// Выполнение delete-запроса
export async function deleteRequest(url, tid) {

    try {
        // выполняем запрос
        const response = await axios.delete(url, {
            headers: {
                Authorization: tid ? `Bearer ${tid}` : null
            }
        });

        // определяем коды
        const httpCode = parseInt(response.status);
        const httpStatus = response.statusText;

        // логируем
        if (process.env.NODE_ENV === 'development') {
            console.debug('-----')
            console.debug(`${httpCode} ${httpStatus}`)
            console.debug(response.data)
        }

        // возвращаем результат
        return {
            httpCode,
            httpStatus,
            data: response.data
        }
    } catch (e) {
        // вернулся ответ с ошибкой
        if (e.response) {
            // запрашиваем статусы http
            const httpCode = parseInt(e.response.status);
            const httpStatus = e.response.statusText;

            // определяем внутренние ошибки
            let error = 0;
            let errorMessage = '';
            if (e.response.data && e.response.data['error'] && e.response.data['error_message']) {
                error = e.response.data['error'];
                errorMessage = e.response.data['error_message'];
            }

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.error('-----')
                console.error(`${httpCode} ${httpStatus}`)
                console.error(e.response.data)
            }

            // если сервер вернул - требуется аутентификация
            if (httpCode == 401 && router.currentRoute.value.path != "/auth") {
                // запоминаем откуда уходим
                await store.dispatch('doSetLoadedUrl', {
                    loadedUrl: router.currentRoute.value
                });
                // очищаем данные аутентификации
                await store.commit('clearAuth');
                // переходим на страницу авторизации
                await router.replace('/auth');
            }

            // формируем ошибку
            const newError = new Error(errorMessage || httpStatus);
            newError.httpCode = httpCode;
            newError.httpStatus = httpStatus;
            newError.error = error;
            newError.tid = e.response.data.tid;
            newError.errorMessage = errorMessage;
            throw newError;
        }
        // вернулся ответ без ошибки
        else {
            // формируем ошибку
            const newError = new Error('Ошибка сети');
            newError.httpCode = 0;
            newError.httpStatus = '';
            newError.error = 0;
            newError.errorMessage = '';
            throw newError;
        }
    }
}

// Выполнение get-запроса
export async function getRequest(url, tid) {

    try {
        // выполняем запрос
        const response = await axios.get(url, {
            headers: {
                Authorization: tid ? `Bearer ${tid}` : null,
            },
        });
        // определяем коды
        const httpCode = parseInt(response.status);
        const httpStatus = response.statusText;

        // логируем
        if (process.env.NODE_ENV === 'development') {
            console.debug('-----')
            console.debug(`${httpCode} ${httpStatus}`)
            console.debug(response.data)
        }

        // возвращаем результат
        return {
            httpCode,
            httpStatus,
            data: response.data
        }
    } catch (e) {
        // вернулся ответ с ошибкой
        if (e.response) {
            // запрашиваем статусы http
            const httpCode = parseInt(e.response.status);
            const httpStatus = e.response.statusText;

            // определяем внутренние ошибки
            let error = 0;
            let errorMessage = '';
            if (e.response.data && e.response.data['error'] && e.response.data['error_message']) {
                error = e.response.data['error'];
                errorMessage = e.response.data['error_message'];
            }

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.error('-----')
                console.error(`${httpCode} ${httpStatus}`)
                console.error(e.response.data)
            }

            // если сервер вернул - требуется аутентификация
            if (httpCode == 401 && router.currentRoute.value.path != "/auth") {
                // запоминаем откуда уходим
                await store.dispatch('doSetLoadedUrl', {
                    loadedUrl: router.currentRoute.value
                });
                // очищаем данные аутентификации
                await store.commit('clearAuth');
                // переходим на страницу авторизации
                await router.replace('/auth');
            }

            // формируем ошибку
            const newError = new Error(errorMessage || httpStatus);
            newError.httpCode = httpCode;
            newError.httpStatus = httpStatus;
            newError.error = error;
            newError.tid = e.response.data.tid;
            newError.errorMessage = errorMessage;
            throw newError;
        }
        // вернулся ответ без ошибки
        else {
            // формируем ошибку
            const newError = new Error('Ошибка сети');
            newError.httpCode = 0;
            newError.httpStatus = '';
            newError.error = 0;
            newError.errorMessage = '';
            throw newError;
        }
    }
}

// Выполнение сырой get-запрос
export async function getRawRequest(url, tid) {

    try {
        // выполняем запрос
        const response = await axios.get(url, {
            headers: {
                Authorization: tid ? `Bearer ${tid}` : null,
            },
        });
        // определяем коды
        const httpCode = parseInt(response.status);
        const httpStatus = response.statusText;

        // логируем
        if (process.env.NODE_ENV === 'development') {
            console.debug('-----')
            console.debug(`${httpCode} ${httpStatus}`)
            console.debug(response.data)
        }

        // возвращаем результат
        return {
            httpCode,
            httpStatus,
            data: response.data
        }
    } catch (e) {
        // вернулся ответ с ошибкой
        if (e.response) {
            // запрашиваем статусы http
            const httpCode = parseInt(e.response.status);
            const httpStatus = e.response.statusText;

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.error('-----');
                console.error(`${httpCode} ${httpStatus}`);
                console.error(e.response.data);
            }

            // формируем ошибку
            const newError = new Error(httpStatus);
            newError.httpCode = httpCode;
            newError.httpStatus = httpStatus;
            throw newError;
        }
        // вернулся ответ без ошибки
        else {
            // формируем ошибку
            const newError = new Error('Ошибка сети');
            newError.httpCode = 0;
            newError.httpStatus = '';
            throw newError;
        }
    }
}

// Выполнение get-запроса для получения файла
export async function getFileRequest(url, tid, fileName) {

    try {

        let httpCode = '';
        let httpStatus = '';
        let resp = ''

        resp = axios({
            url: url,
            method: 'GET',
            headers: {
                Authorization: tid ? `Bearer ${tid}` : null
            },
            responseType: 'blob',
        }).then((response) => {
            console.log('responseRequests', response);
            // определяем коды
            httpCode = parseInt(response.status);
            httpStatus = response.statusText;

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.debug('-----')
                console.debug(`${httpCode} ${httpStatus}`)
                console.debug(response.data)
            }

            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');

            // в случае если имя файла не задано на клиенте
            if (!fileName) {
                let contentDisposition = response.headers['content-disposition'];
                fileName = fromBase64(contentDisposition.split(';')[1].split('=')[2].split('?')[3]);
                // fileName = fromBase64(contentDisposition.split(';')[1].split('=')[1].replace('"', '').replace('"', ''));
            }
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName);
            document.body.appendChild(fileLink);
            // window.URL.revokeObjectURL(fileURL);
            fileLink.click();
            resp = response.data;

        });
        return resp;
        // return {
        //     httpCode,
        //     httpStatus,
        //     data: resp
        // }
    } catch (e) {
        // вернулся ответ с ошибкой
        if (e.response) {
            // запрашиваем статусы http
            const httpCode = parseInt(e.response.status);
            const httpStatus = e.response.statusText;

            // определяем внутренние ошибки
            let error = 0;
            let errorMessage = '';
            if (e.response.data && e.response.data['error'] && e.response.data['error_message']) {
                error = e.response.data['error'];
                errorMessage = e.response.data['error_message'];
            }

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.error('-----')
                console.error(`${httpCode} ${httpStatus}`)
                console.error(e.response.data)
            }

            // если сервер вернул - требуется аутентификация
            if (httpCode == 401 && router.currentRoute.value.path != "/auth") {
                // запоминаем откуда уходим
                await store.dispatch('doSetLoadedUrl', {
                    loadedUrl: router.currentRoute.value
                });
                // очищаем данные аутентификации
                await store.commit('clearAuth');
                // переходим на страницу авторизации
                await router.replace('/auth');
            }

            // формируем ошибку
            const newError = new Error(errorMessage || httpStatus);
            newError.httpCode = httpCode;
            newError.httpStatus = httpStatus;
            newError.error = error;
            newError.tid = e.response.data.tid;
            newError.errorMessage = errorMessage;
            throw newError;
        }
        // вернулся ответ без ошибки
        else {
            // формируем ошибку
            const newError = new Error('Ошибка сети');
            newError.httpCode = 0;
            newError.httpStatus = '';
            newError.error = 0;
            newError.errorMessage = '';
            throw newError;
        }
    }
}


// Выполнение get-запроса для получения файла
export async function getFileRequestForShowing(url, tid, fileName, fmt) {

    try {

        let httpCode = '';
        let httpStatus = '';
        let resp = ''

        resp = axios({
            url: url,
            method: 'GET',
            headers: {
                Authorization: tid ? `Bearer ${tid}` : null
            },
            responseType: 'blob',
        }).then((response) => {
            // определяем коды
            httpCode = parseInt(response.status);
            httpStatus = response.statusText;

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.debug('-----')
                console.debug(`${httpCode} ${httpStatus}`)
                console.debug(response.data)
            }
            let fileURL = '';
            // просмотр файла html
            if (fmt === 'html'){
                fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: 'text/html' }));
            } else if (fmt === 'pdf') {
            // проcмотр файла pdf
                fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/pdf;base64' }));
            } else if (fmt === 'json') {
                // проcмотр файла json
                fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/json' }));
            }

            window.open(fileURL,fileName);
            // URL.revokeObjectURL(fileURL); //Releases the resources
        });
        return resp;
    } catch (e) {
        // вернулся ответ с ошибкой
        if (e.response) {
            // запрашиваем статусы http
            const httpCode = parseInt(e.response.status);
            const httpStatus = e.response.statusText;

            // определяем внутренние ошибки
            let error = 0;
            let errorMessage = '';
            if (e.response.data && e.response.data['error'] && e.response.data['error_message']) {
                error = e.response.data['error'];
                errorMessage = e.response.data['error_message'];
            }

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.error('-----')
                console.error(`${httpCode} ${httpStatus}`)
                console.error(e.response.data)
            }

            // если сервер вернул - требуется аутентификация
            if (httpCode == 401 && router.currentRoute.value.path != "/auth") {
                // запоминаем откуда уходим
                await store.dispatch('doSetLoadedUrl', {
                    loadedUrl: router.currentRoute.value
                });
                // очищаем данные аутентификации
                await store.commit('clearAuth');
                // переходим на страницу авторизации
                await router.replace('/auth');
            }

            // формируем ошибку
            const newError = new Error(errorMessage || httpStatus);
            newError.httpCode = httpCode;
            newError.httpStatus = httpStatus;
            newError.error = error;
            newError.tid = e.response.data.tid;
            newError.errorMessage = errorMessage;
            throw newError;
        }
        // вернулся ответ без ошибки
        else {
            // формируем ошибку
            const newError = new Error('Ошибка сети');
            newError.httpCode = 0;
            newError.httpStatus = '';
            newError.error = 0;
            newError.errorMessage = '';
            throw newError;
        }
    }
}


// Выполнение post-запроса для передачи файлов
export async function postFileRequest(url, fileList, tid) {
    try {

        // формируем список файлов
        const formData = new FormData();
        fileList.forEach((file, index) => {
            formData.append('files[' + (index) + ']', file)
        });

        // выполняем запрос
        const response = await axios.post(url, formData, {
            headers: {
                Authorization: tid ? `Bearer ${tid}` : null,
                'Content-Type': 'multipart/form-data'
            }
        });

        // определяем коды
        const httpCode = parseInt(response.status);
        const httpStatus = response.statusText;

        // логируем
        if (process.env.NODE_ENV === 'development') {
            console.debug('-----')
            console.debug(`${httpCode} ${httpStatus}`)
            console.debug(response.data)
        }

        // возвращаем результат
        return {
            httpCode,
            httpStatus,
            data: response.data
        }
    } catch (e) {
        // вернулся ответ с ошибкой
        if (e.response) {
            // запрашиваем статусы http
            const httpCode = parseInt(e.response.status);
            const httpStatus = e.response.statusText;

            // определяем внутренние ошибки
            let error = 0;
            let errorMessage = '';
            if (e.response.data && e.response.data['error'] && e.response.data['error_message']) {
                error = e.response.data['error'];
                errorMessage = e.response.data['error_message'];
            }

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.error('-----')
                console.error(`${httpCode} ${httpStatus}`)
                console.error(e.response.data)
            }

            // если сервер вернул - требуется аутентификация
            if (httpCode == 401 && router.currentRoute.value.path != "/auth") {
                // запоминаем откуда уходим
                await store.dispatch('doSetLoadedUrl', {
                    loadedUrl: router.currentRoute.value
                });
                // очищаем данные аутентификации
                await store.commit('clearAuth');
                // переходим на страницу авторизации
                await router.replace('/auth');
            }

            // формируем ошибку
            const newError = new Error(errorMessage || httpStatus);
            newError.httpCode = httpCode;
            newError.httpStatus = httpStatus;
            newError.error = error;
            newError.errorMessage = errorMessage;
            throw newError;
        }
        // вернулся ответ без ошибки
        else {
            // формируем ошибку
            const newError = new Error('Ошибка сети');
            newError.httpCode = 0;
            newError.httpStatus = '';
            newError.error = 0;
            newError.errorMessage = '';
            throw newError;
        }
    }
}