import {getSignTitle, secondsToDate} from "@/lib";

export default {
    computed: {

        // описание маршрута в заголовке
        routePageHeader() {
            if (this.route && this.route.route_id != null) {
                return `(мрш: #${this.route.route_id} - №${this.route.route_num})`
            } else return '';
        },

        // описание варианта маршрута в заголовке
        routeVariantPageHeader() {
            if (this.routeVariant && this.routeVariant.route_variant_id != null) {
                return `(вар.м: #${this.routeVariant.route_variant_id} - ${this.routeVariant.date_begin})`
            } else return '';
        },

        // описание типа рейса в заголовке
        tripTypePageHeader() {
            if (this.tripType && this.tripType.trip_type_id != null) {
                return `(тип.р: #${this.tripType.trip_type_id} - ${this.tripType.trip_type_sign})`
            } else return '';
        },

        // описание типа рейса в заголовке
        tripTypeSignTitlePageHeader() {
            if (this.tripType && this.tripType.trip_type_id != null) {
                return `(тип.р: #${this.tripType.trip_type_id} - ${getSignTitle(this.tripType.trip_type_sign)})`
            } else return '';
        },

        // описание группы предприятий в заголовке
        depotGroupPageHeader() {
            if (this.depotGroup && this.depotGroup.depot_group_id != null) {
                return `(грп: #${this.depotGroup.depot_group_id} - ${this.depotGroup.depot_group_name})`
            } else return '';
        },

        // описание предприятия в заголовке
        depotPageHeader() {
            if (this.depot && this.depot.depot_id != null) {
                return `(предп: #${this.depot.depot_id} - ${this.depot.long_name})`
            } else return '';
        },

        // описание организации в заголовке
        organizationPageHeader() {
            if (this.organization && this.organization.org_id != null) {
                return `(орг: #${this.organization.org_id} - ${this.organization.long_name})`
            } else return '';
        },

        // описание работника в заголовке
        personnelPageHeader() {
            if (this.personnel && this.personnel.personnel_id != null) {
                return `(работ: #${this.personnel.personnel_id} - ${this.personnel.last_name})`;
            } else return '';
        },

        // описание транспортного средства в заголовке
        transportPageHeader() {
            if (this.transport && this.transport.transport_id != null) {
                return `(трн: #${this.transport.transport_id} - ${this.transport.garage_num} / ${this.transport.state_num})`;
            } else return '';
        },

        // описание группы транспортных средств в заголовке
        transportGroupPageHeader() {
            if (this.transportGroup && this.transportGroup.transport_group_id != null) {
                return `(грп: #${this.transportGroup.transport_group_id} - ${this.transportGroup.transport_group_name})`
            } else return '';
        },

        // описание группы видов транспорта в заголовке
        transportTypeGroupPageHeader() {
            if (this.transportTypeGroup && this.transportTypeGroup.transport_type_group_id != null) {
                return `(грп: #${this.transportTypeGroup.transport_type_group_id} - ${this.transportTypeGroup.transport_type_group_name})`;
            } else return '';
        },

        // описание видов транспорта в заголовке
        transportTypePageHeader() {
            if (this.transportType && this.transportType.transport_type_id != null) {
                return `(вид.трн: #${this.transportType.transport_type_id} - ${this.transportType.long_name})`
            } else return '';
        },

        // описание документов маршрута в заголовке
        routeDocPageHeader() {
            if (this.routeDoc && this.routeDoc.route_doc_id != null) {
                return `(док: #${this.routeDoc.route_doc_id})`
            } else return '';
        },

        // описание документов варианта маршрута в заголовке
        routeVariantDocPageHeader() {
            if (this.routeVariantDoc && this.routeVariantDoc.route_variant_doc_id != null) {
                return `(док: #${this.routeVariantDoc.route_variant_doc_id})`
            } else return '';
        },

        // описание группы маршрута в заголовке
        routeGroupPageHeader() {
            if (this.routeGroup && this.routeGroup.route_group_id != null) {
                return `(грп: #${this.routeGroup.route_group_id} - ${this.routeGroup.route_group_name})`;
            } else return '';
        },

        // описание остановочного пункта в заголовке
        stopPageHeader() {
            if (this.stop && this.stop.stop_id != null) {
                return `(ост: #${this.stop.stop_id} - ${this.stop.long_name})`;
            } else return '';
        },

        // описание документов остановочного пункта в заголовке
        stopDocPageHeader() {
            if (this.stopDoc && this.stopDoc.stop_doc_id != null) {
                return `(док: #${this.stopDoc.stop_doc_id})`
            } else return '';
        },

        // описание остановочного павильона в заголовке
        stopPavilionPageHeader() {
            if (this.stopPavilion && this.stopPavilion.stop_pavilion_id != null) {
                return `(пав: #${this.stopPavilion.stop_pavilion_id} - ${this.stopPavilion.long_name})`;
            } else return '';
        },

        // описание документов остановочного павилиона в заголовке
        stopPavilionDocPageHeader() {
            if (this.stopPavilionDoc && this.stopPavilionDoc.stop_pavilion_doc_id != null) {
                return `(док: #${this.stopPavilionDoc.stop_pavilion_doc_id})`
            } else return '';
        },

        // описание автостанции в заголовке
        stopstationPageHeader() {
            if (this.stopstation && this.stopstation.stopstation_id != null) {
                return `(авт: #${this.stopstation.stopstation_id} - ${this.stopstation.long_name})`;
            } else return '';
        },

        // описание документов автостанции в заголовке
        stopstationDocPageHeader() {
            if (this.stopstationDoc && this.stopstationDoc.stopstation_doc_id != null) {
                return `(док: #${this.stopstationDoc.stopstation_doc_id})`
            } else return '';
        },

        // описание тарифной зональной системы в заголовке
        tariffZoneSystemPageHeader() {
            if (this.tariffZoneSystem && this.tariffZoneSystem.tariff_zone_system_id != null) {
                return `(сист: #${this.tariffZoneSystem.tariff_zone_system_id} - ${this.tariffZoneSystem.long_name})`;
            } else return '';
        },

        // описание метода в заголовке
        methodPageHeader() {
            if (this.method && this.method.method_id != null) {
                return `(метод: #${this.method.method_id} - ${this.method.method_name})`;
            } else return '';
        },

        // описание роли в заголовке
        rolePageHeader() {
            if (this.role && this.role.role_id != null) {
                return `(роль: #${this.role.role_id} - ${this.role.role_name})`;
            } else return '';
        },

        // описание группы пользователей
        userGroupPageHeader() {
            if (this.userGroup && this.userGroup.user_group_id != null) {
                return `(грп: #${this.userGroup.user_group_id} - ${this.userGroup.user_group_name})`;
            } else return '';
        },

        // описание пользователя
        userPageHeader() {
            if (this.user && this.user.uid != null) {
                return `(логин: #${this.user.uid} - ${this.user.login})`;
            } else return '';
        },

        // описание варианта расписания в заголовке
        scheduleVariantPageHeader() {
            if (this.scheduleVariant && this.scheduleVariant.schedule_variant_id != null) {
                return `(вар.р: #${this.scheduleVariant.schedule_variant_id} - ${this.scheduleVariant.date_begin})`
            } else return '';
        },

        // описание документов варианта расписания в заголовке
        scheduleVariantDocPageHeader() {
            if (this.scheduleVariantDoc && this.scheduleVariantDoc.schedule_variant_doc_id != null) {
                return `(док: #${this.scheduleVariantDoc.schedule_variant_doc_id})`
            } else return '';
        },

        // описание набора графиков в заголовке
        scheduleGraphSetPageHeader() {
            if (this.scheduleGraphSet && this.scheduleGraphSet.schedule_graph_set_id != null) {
                return `(набор: #${this.scheduleGraphSet.schedule_graph_set_id} - ${this.scheduleGraphSet.title})`
            } else return '';
        },

        // описание графика в заголовке
        scheduleGraphPageHeader() {
            if (this.scheduleGraph && this.scheduleGraph.schedule_graph_id != null) {
                return `(график: #${this.scheduleGraph.schedule_graph_id} - гр: ${this.scheduleGraph.graph} - см: ${this.scheduleGraph.shift})`
            } else return '';
        },

        // описание графика в заголовке
        radioPageHeader() {
            if (this.radio && this.radio.radio_id != null) {
                return `(радиостанция: #${this.radio.radio_id} - ${this.radio.radio_num})`
            } else return '';
        },

        // описание графика в заголовке
        tripTypeCompositesHeader() {
            if (this.tripTypeComposite && this.tripTypeComposite.trip_type_composite_id != null) {
                return `(композит: #${this.tripTypeComposite.trip_type_composite_id})`
            } else return '';
        },

        // описание маршрутов в заголовке
        routesWithDatePageHeader() {
            if (this.routes) {
                let title = `(маршруты:`;

                for (let n = 0; n < this.routes.length; n++) {
                    title = title + ` #${this.routes[n].route_id} - ${this.routes[n].route_num}`;
                    if (n != this.routes.length - 1) {
                        title = title + `, `
                    } else {
                        title = title + `; `
                    }
                }
                title = title + ` за период дат ` + secondsToDate(this.date_index_begin * 24 * 60 * 60) + ` - ` + secondsToDate(this.date_index_end * 24 * 60 * 60) + `)`
                return title;
            } else return '';
        },

        // описание предприятий в заголовке
        depotsPageHeader() {
            if (this.depots) {
                let title = `( предприятий:`;

                for (let n = 0; n < this.depots.length; n++) {
                    if (this.depots[n].depot_id) {
                        title = title + ` #${this.depots[n].depot_id} - ${this.depots[n].short_name}`;
                        if (n != this.depots.length - 1) {
                            title = title + `, `
                        } else {
                            title = title + `; `
                        }
                    }
                }
                title = title + ` за период дат ` + secondsToDate(this.date_index_begin * 24 * 60 * 60) + ` - ` + secondsToDate(this.date_index_end * 24 * 60 * 60) + `)`
                return title;
            } else return '';
        },

        // описание контракта в заголовке
        contractPageHeader() {
            if (this.contract && this.contract.contract_id != null) {
                return `(контракт: #${this.contract.contract_id})`
            } else return '';
        },

        // описание документов контракта в заголовке
        contractDocPageHeader() {
            if (this.contractDoc && this.contractDoc.contract_doc_id != null) {
                return `(док: #${this.contractDoc.contract_doc_id})`
            } else return '';
        },

        // описание маршрута в заголовке
        informationByRouteHeader() {
            if (this.kvrRoute && this.kvrRoute.route_id) {
                return `(мрш: #${this.kvrRoute.route_id}- №${this.kvrRoute.route_num})`
            } else return '';
        },

    }
}