<template>
  <align-container>
    <align-panel align="top">
      <page-header>
        <span><router-link to="/kvr">КВР</router-link></span>
        <span> / </span>
        <span><router-link to="/kvr/workTasks">Мониторинг</router-link></span>
        <span> / </span>
        <span>Информация по маршруту {{ informationByRouteHeader }} за {{ getDateForHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <!--    разделяем страницу на верхнюю и нижнюю часть  -->
      <align-container>
        <align-panel align="top" height="70%" :gap="3">

          <align-container>
            <align-panel align="top" :gap="3">
              <!-- Форма с кнопками над верхней таблицей-->
              <card class="text-left">
                <card-body class="p-1">
                  <div class="form-row">
                    <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                      <p style="margin: 0px; font-size: 1.2rem; font-weight: bold;">Рейсы</p>
                    </div>
                    <div class="col-0" style="margin-bottom: 0 !important;">
                      <input-select
                          class="form-control"
                          :itemList="trip_type_signs_arr"
                          v-model="selectedTripTypeSign"
                          style="padding-top: 0; padding-bottom: 0; height: 31px;"
                          caption="Все"
                          @change="clearSelection"
                      >
                      </input-select>
                    </div>

                    <!-- Кнопка Команда-->
                    <div class="col-0" style="margin-bottom: 0 !important;">
                      <dropdown>
                        <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                        :disabled="commandDisabled"
                                        style="padding-top: 0; padding-bottom: 0; height: 31px;">
                          Команда
                        </button-toggler>
                        <dropdown-menu ref="collapse">
                          <dropdown-item @click.prevent="onShowTripCardItems">Отметки на рейсе
                          </dropdown-item>
                          <dropdown-item @click.prevent="onTripTypeOnMap" :disabled="isDropDownDisabled">Рейс на карту
                          </dropdown-item>
                        </dropdown-menu>
                      </dropdown>
                    </div>

                    <div class="col"></div>
                    <div class="col-0" style="margin-bottom: 0 !important;">
                      <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          @click="onRefreshResultTrips"
                          title="Обновить"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                    </div>
                  </div>
                </card-body>
              </card>
            </align-panel>
            <align-panel align="all">
              <!--  таблица с рейсами -->
              <result-trips-edit-table
                :selectedRow="selectedRow"
                @onRowSelect="onRowSelect"
                :rows="resultTrips"
                :selectedTripTypeSign="selectedTripTypeSign"
              >
              </result-trips-edit-table>
            </align-panel>

            <align-panel align="bottom">
              <!-- Форма с кнопками под левой таблицей-->
              <card class="text-right">
                <card-body class="p-1">
                  <div class="form-row">
                    <!--          кладем div с классом "col", чтобы он заполнил пространство слева от "Итого распознано"-->
                    <div class="col"></div>
                    <div class="input-group mb-3 col-0"
                         style="margin-bottom: 0 !important; width: 335px; padding-right: 4px">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-total-ind-time"
                              style="width: 206px; height: 31px">Итого: плановый пробег</span>
                      </div>
                      <input disabled="true" style="min-width: 120px; max-width: 120px; height: 31px" type="text"
                             class="form-control"
                             aria-describedby="inputGroup-sizing-default" v-model="lengthPlanSumm">
                    </div>
                    <div class="input-group mb-3 col-0"
                         style="margin-bottom: 0 !important; width: 285px; padding-right: 4px">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-total-ind-time"
                              style="width: 176px; height: 31px">Фактический пробег</span>
                      </div>
                      <input disabled="true" style="min-width: 100px; max-width: 100px; height: 31px" type="text"
                             class="form-control"
                             aria-describedby="inputGroup-sizing-default" v-model="lengthFactSumm">
                    </div>
                    <div class="input-group mb-3 col-0"
                         style="margin-bottom: 0 !important; width: 193px; padding-right: 4px; height: 31px">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-total-ind-time"
                              style="width: 94px; height: 31px">По плану</span>
                      </div>
                      <input disabled="true" style="min-width: 90px; max-width: 90px; height: 31px" type="text"
                             class="form-control"
                             aria-describedby="inputGroup-sizing-default" v-model="tripsPlanCount">
                    </div>
                    <div class="input-group mb-3 col-0"
                         style="margin-bottom: 0 !important; width: 273px; padding-right: 4px">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-total-ind-time"
                              style="width: 174px; height: 31px">Зачтено фактически</span>
                      </div>
                      <input disabled="true" style="min-width: 90px; max-width: 90px; height: 31px" type="text"
                             class="form-control"
                             aria-describedby="inputGroup-sizing-default" v-model="actuallyCreditedCount">
                    </div>
                    <div class="input-group mb-3 col-0" style="margin-bottom: 0 !important; width: 263px">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-total-ind-time"
                              style="width: 164px; height: 31px">Зачтено регулярно</span>
                      </div>
                      <input disabled="true" style="min-width: 90px; max-width: 90px; height: 31px" type="text"
                             class="form-control"
                             aria-describedby="inputGroup-sizing-default" v-model="creditedRegularlyCount">
                    </div>
                  </div>
                </card-body>
              </card>
            </align-panel>
          </align-container>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <align-panel align="top" :gap="3">
              <!-- Форма с кнопками над нижней таблицей-->
              <card class="text-left">
                <card-body class="p-1">
                  <div class="form-row">
                    <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                      <p style="margin: 0px; font-size: 1.2rem; font-weight: bold;">Выпуск на маршрут</p>
                    </div>
                    <div class="col"></div>
                    <div class="col-0" style="margin-bottom: 0 !important;">
                      <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          @click="onRefresfResultGraphs"
                          title="Обновить"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                    </div>
                  </div>
                </card-body>
              </card>
            </align-panel>
            <align-panel align="all">
              <!--  таблица с графиками -->
              <result-graphs-edit-table
                  :rows="resultGraphs"
                  :selectedRow="selectedGraphRow"
                  @onRowSelect="onRowGraphSelect"
              >
              </result-graphs-edit-table>
            </align-panel>

            <align-panel align="bottom">
              <!-- Форма с кнопками под левой таблицей-->
              <card class="text-right">
                <card-body class="p-1">
                  <div class="form-row">
                    <!--          кладем div с классом "col", чтобы он заполнил пространство слева от "Итого распознано"-->
                    <div class="col"></div>
                    <div class="input-group col-0"
                         style="margin-bottom: 0 !important; width: 211px; padding-right: 4px">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-total-ind-time"
                              style="height: 31px">Итого: план</span>
                      </div>
                      <input disabled="true" style="min-width: 90px; max-width: 90px; height: 31px" type="text"
                             class="form-control"
                             aria-describedby="inputGroup-sizing-default" v-model="graphPlanCount">
                    </div>
                    <div class="input-group col-0"
                         style="margin-bottom: 0 !important; width: 159px; padding-right: 4px">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-total-ind-time" style="height: 31px">Факт</span>
                      </div>
                      <input disabled="true" style="min-width: 90px; max-width: 90px; height: 31px" type="text"
                             class="form-control"
                             aria-describedby="inputGroup-sizing-default" v-model="graphFactCount">
                    </div>
                    <div class="input-group col-0"
                         style="margin-bottom: 0 !important; width: 286px; padding-right: 4px">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-total-ind-time"
                              style="width: 187px; height: 31px">С нарушением класса</span>
                      </div>
                      <input disabled="true" style="min-width: 90px; max-width: 90px; height: 31px " type="text"
                             class="form-control"
                             aria-describedby="inputGroup-sizing-default" v-model="classViolationCount">
                    </div>
                  </div>
                </card-body>
              </card>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <trip-card-items-dialog
      v-if="isDialogVisible"
      :rows="tripCardItems"
      @onCancel="onRejectOperation"
      @onRefresh="getTripCard"
  ></trip-card-items-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>
</template>

<script>

import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import {getLength, getSignTitle, graphSortFn, ndToDateString, quickSort} from "@/lib";
import PageMixin from "@/pages/share/PageMixin";
import InputSelect from "@/components/ui/custom/InputSelect";
import TripCardItemsDialog from "@/components/kvr/trip_card_items/TripCardItemsDialog";
import ResultTripsEditTable from "@/components/kvr/information_by_route/ResultTripsEditTable";
import ResultGraphsEditTable from "@/components/kvr/information_by_route/ResultGraphsEditTable";
import {getRoute} from "@/store/KvrHelpers";

export default {
  name: "informationByTsPage",
  mixins: [PageMixin],
  components: {
    ResultTripsEditTable,
    ResultGraphsEditTable,
    TripCardItemsDialog,
    InputSelect,
    AlignPanel, AlignContainer
  },
  props: [
    // индекс даты в часовом поясе системы (количество дней с 01.01.2000)
    'date_index',
    // идентификатор маршрута
    'route_id',
    // операция show передается через роутер
    'op',
    // идентификатор планового рейса
    'plan_trip_id',
  ],
  data() {
    return {
      // отображать спиннер
      isLoading: false,
      // выбранный маршрут
      kvrRoute: null,
      // выбранный тип рейса (для фильтрации)
      selectedTripTypeSign: null,
      // выбранная строка в таблице рейсов
      selectedRow: null,
      // выбранная строка в таблице графиков
      selectedGraphRow: null,
      // строки карточки фактического рейса
      tripCardItems: [],
      // итоговые данные по рейсам
      resultTrips: [],
      // итоговые данные по графикам
      resultGraphs: [],
      // типы рейсов
      trip_type_signs_arr: [],
    }
  },

  computed: {

    isDropDownDisabled() {
      if (this.selectedRow && this.selectedRow.trip_mark == 0 && this.selectedRow.fact_trip_id == null) {
        return true;
      }
      return false;
    },

    // возвращает дату для заголовка
    getDateForHeader() {
      return ndToDateString(this.date_index);
    },

    // условие отключения кнопки "Команда"
    commandDisabled() {
      return !this.selectedRow;
    },

    isDialogVisible() {
      return this.op === 'show';
    },

    // плановый пробег сумма по рейсам
    lengthPlanSumm() {
      let lengthPlan = 0;
      this.resultTrips.map(value => {
        lengthPlan += value.length_plan;
      })
      return getLength(lengthPlan);
    },

    // фактический пробег сумма по рейсам
    lengthFactSumm() {
      let lengthFact = 0;
      this.resultTrips.map(value => {
        lengthFact += value.length_fact;
      })
      return getLength(lengthFact);
    },

    // плановое количество рейсов
    tripsPlanCount() {
      return this.resultTrips.length
    },

    // количество зачтенных фактически
    actuallyCreditedCount() {
      let actuallyCredited = 0;
      this.resultTrips.map(value => {
        if (value.trip_mark == 1 || value.trip_mark == 2) {
          actuallyCredited += 1;
        }
      })
      return actuallyCredited;
    },

    // количество зачтенных регулярно
    creditedRegularlyCount() {
      let creditedRegularly = 0;
      this.resultTrips.map(value => {
        if (value.trip_mark == 2) {
          creditedRegularly += 1;
        }
      })
      return creditedRegularly;
    },

    // план графики
    graphPlanCount() {
      return this.resultGraphs.length
    },

    // факт графики
    graphFactCount() {
      let count = 0;
      this.resultGraphs.map(value => {
        if (value.is_done == 1) {
          count++;
        }
      })
      return count;
    },

    // С нарушением класса
    classViolationCount() {
      let count = 0;
      this.resultGraphs.map(value => {
        if (value.is_done == 1 && value.is_class == 0) {
          count++;
        }
      })
      return count;
    }

  },

  methods: {

    clearSelection() {
      this.selectedRow = null;
    },

    // выбрана строка в таблице Рейсов
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // выбрана строка в таблице Рейсов
    onRowGraphSelect(item) {
      this.selectedGraphRow = item;
    },

    // нажата конпка "Просмотр"
    async onShowTripCardItems() {
      await this.getTripCard();
      let plan_trip_id = (this.selectedRow.plan_trip_id != null || this.selectedRow.plan_trip_id != undefined) ? this.selectedRow.plan_trip_id : "";
      await this.$router.replace('/kvr/workTasks/' + this.date_index + '/' + this.route_id + '/informationByRoute/show/' + plan_trip_id);
    },

    // нажата кнопка "Рейс на карту"
    onTripTypeOnMap() {
      this.$router.push('/kvr/history/' + this.date_index + '/' + this.selectedRow.transport_id + '/' + this.route_id + '/' + this.selectedRow.fact_trip_time_index_begin + '/' + this.selectedRow.fact_trip_time_index_end);
    },

    // получение карточки фактического рейса
    async getTripCard() {
      this.isLoading = true;
      try {
        let plan_trip_id = (this.selectedRow.plan_trip_id != null || this.selectedRow.plan_trip_id != undefined) ? this.selectedRow.plan_trip_id : "";
        let tripType = await this.$store.dispatch('kvr/doFetchTripCardByPlanTripId', {
          plan_trip_id: plan_trip_id,
          date_index: this.selectedRow.date_index
        });
        if (tripType) {
          this.tripCardItems = tripType;
        }
      } finally {
        this.isLoading = false;
      }
    },

    // получаем данные по итоговым рейсам
    async onRefreshResultTrips() {
      this.resultTrips = [];
      this.isLoading = true;
      try {
        // получаем данные по итоговым рейсам
        let resultTrips = await this.$store.dispatch('kvr/doFetchResultTrips', {
          date_index: this.date_index,
          route_id: this.route_id
        });
        if (resultTrips) {
          this.resultTrips = resultTrips;
        }
      } finally {
        this.isLoading = false;
      }
    },

    // получаем данные по итоговым графикам
    async onRefresfResultGraphs() {
      this.resultGraphs = [];
      this.isLoading = true;
      try {
        let resultGraphs = await this.$store.dispatch('kvr/doFetchResultGraphs', {
          date_index: this.date_index,
          route_id: this.route_id
        });
        if (resultGraphs) {
          this.resultGraphs = resultGraphs;
          // сортируем массив
          quickSort(this.resultGraphs, (leftItem, rightItem) => {
            return 1 * graphSortFn(leftItem.plan_graph, rightItem.plan_graph);
          });
        }
      } finally {
        this.isLoading = false;
      }
    },

    onRejectOperation() {
      this.tripCardItems = [];
      this.$router.replace('/kvr/workTasks/' + this.date_index + '/' + this.route_id + '/informationByRoute');
    },

  },

  async created() {
    // загружаем перевозчиков
    await this.$store.dispatch('kvr/doFetchDepots');
    // загружаем транспортные предприятия
    await this.$store.dispatch('kvr/doFetchAgencies');
    // загружаем класс ТС
    await this.$store.dispatch('ref/doFetchTransportClasses');
    // загружаем причины незачета рейса
    await this.$store.dispatch('kvr/doFetchMarkReasons');
    // получаем данные по итоговым рейсам
    await this.onRefreshResultTrips();
    // получаем данные по итоговым графикам
    await this.onRefresfResultGraphs();
    // загрузка маршрутов
    await this.$store.dispatch('kvr/doFetchRoutes');
    // сетаем выбранный маршрут
    this.kvrRoute = getRoute(this.route_id);
    // заполняем комбобокс с сигнатурами рейсов для фильтрации
    let trip_type_signs = [];
    this.resultTrips.map(value => {
      if (!trip_type_signs.includes(value.trip_type_sign)) {
        trip_type_signs.push(value.trip_type_sign);
      }
    });
    this.trip_type_signs_arr = [];
    trip_type_signs.map(value => {
      this.trip_type_signs_arr.push({
        id: value,
        title: getSignTitle(value),
      })
    });
    // если открыт диалог к карточкой типа рейса, загружаем её
    if (this.op == 'show') {
      let row = this.resultTrips.find(value => value.plan_trip_id == this.plan_trip_id);
      if (row) {
        this.selectedRow = row;
      }
      let tripType = await this.$store.dispatch('kvr/doFetchTripCardByPlanTripId', {
        plan_trip_id: this.plan_trip_id,
        date_index: this.selectedRow.date_index
      });
      if (tripType) {
        this.tripCardItems = tripType;
      }
    }

  }

}
</script>

<style scoped>

</style>
