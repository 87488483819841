<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to="routeVariantsLink">Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to="tripTypesLink">Типы рейсов {{ routeVariantPageHeader }}</router-link></span>
        <span> / </span>
        <span>Трасса {{ tripTypePageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <align-panel align="left" width="50%" :gap="3">
          <align-container>
            <!-- Форма с кнопками -->
            <align-panel align="top">
              <crud-form
                  :buttonsExist="tableButtonsExist"
                  :buttonsEnabled="tableButtonsEnabled"
                  :filterExists="false"
                  @onEdit="onTableEdit"
                  @onSave="onTableSave"
                  @onCancel="onTableCancel"
                  @onRefresh="onTableRefresh"
              >

                <!-- Кнопка связь-->
                <template #leftCustomButton>
                  <dropdown>
                    <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="isTableEditMode || isMapEditMode">Связь</button-toggler>
                    <dropdown-menu ref="collapse2">
                      <dropdown-item router-link to="/multipass/graph">Граф УДС</dropdown-item>
                    </dropdown-menu>
                  </dropdown>
                </template>

                <!-- Кнопка связь-->
                <template #leftCustomButton2>
                  <input-select
                      class="form-control"
                      v-model="selectedTripTypeId"
                      :itemList="ttSelectedInputArr"
                      caption="Выберите тип рейса..."
                      @change="changeTripType"
                  >
                  </input-select>
                </template>

                <template #rightCustomButton>
                  <span style="cursor: default" class="btn">Общий пробег (км.): <span
                      class="badge badge-info">{{ totalLength }}</span></span>
                </template>

              </crud-form>
            </align-panel>
            <!-- Таблица  -->
            <align-panel align="all">
              <trip-type-card-edit-table
                  :rowList="tripTypeCard"
                  :isEditMode="isTableEditMode || isMapEditMode"
                  :rowsPerPage="1000"
                  @onRowSelect="onRowSelect"
                  @onDeleteStop="onDeleteStop"
              ></trip-type-card-edit-table>
            </align-panel>
          </align-container>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <!-- Форма с кнопками -->
            <align-panel align="top">
              <crud-form
                  :buttonsExist="mapButtonsExist"
                  :buttonsEnabled="mapButtonsEnabled"
                  :filterExists="false"
                  @onEdit="onMapEdit"
                  @onSave="onMapSave"
                  @onCancel="onMapCancel"
              >

                <template #leftCustomButton>
                  <button
                      type="button"
                      class="btn btn-secondary"
                      @click="tripTypeLineToMap(tripTypeLine)"
                      title="Линию типа рейса на карту"
                  >
                    <i class="fas fa-arrows-alt"></i>
                  </button>
                </template>

                <template #rightCustomButton>
                  <input-checkbox class="mt-2" v-model="isAllStops" id="allStops">Все остановки</input-checkbox>
                </template>

              </crud-form>
            </align-panel>
            <!-- Карта  -->
            <align-panel align="all">
              <trip-type-card-map
                  :tripTypeLine="tripTypeLine"
                  :tripTypeBaseLine="tripTypeBaseLine"
                  :routingLine="routingLine"
                  :tripTypeCard="tripTypeCard"
                  :isMapEditMode="isMapEditMode"
                  :isAllStops="isAllStops"
                  :selectedRow="selectedRow"
                  @onMapCreated="onMapCreated"
                  @onBaseLineChanged="onBaseLineChanged"
                  @onDeleteStop="onDeleteStop"
                  @onStopSelect="onRowSelect"
              >
              </trip-type-card-map>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

  <!--    Окно с ошибкой-->
  <error-message-box
      v-if="errorMessage"
      @onClose="errorMessage = ''"
  >{{ errorMessage }}
  </error-message-box>

</template>

<script>

import {getStop} from "@/store/MultipassHelpers";
import TripTypeCardMap from "@/components/multipass/trip_type_card/TripTypeCardMap";
import TripTypeCardEditTable from "@/components/multipass/trip_type_card/TripTypeCardEditTable";
import {latLngBounds} from 'leaflet';
import PageMixin from "@/pages/share/PageMixin";
import InputSelect from "@/components/ui/custom/InputSelect";
import {getSignTitle} from "@/lib";

export default {
  mixins: [PageMixin],

  components: {
    InputSelect,
    TripTypeCardEditTable,
    TripTypeCardMap
  },

  props: [
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
    // идентификатор типа рейса
    "trip_type_id",
  ],

  data() {
    return {
      // карта
      leaflet: null,
      // отображать спиннер
      isLoading: false,
      // активный элемент таблицы
      selectedRow: null,
      // отображать все все остановки
      isAllStops: false,
      // таблица в режиме редактирования
      isTableEditMode: false,
      // карта в режиме редактирования
      isMapEditMode: false,
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // тип рейса
      tripType: {},
      // линия типа рейса
      tripTypeLine: null,
      // изначальное значение линии типа рейса
      originalTripTypeLine: null,
      // базовая линия типа рейса
      tripTypeBaseLine: null,
      // изначальное значение базовой линии типа рейса
      originalTripTypeBaseLine: null,
      // признак изменения базовой линии
      isModified: false,
      // трасса типа рейса
      tripTypeCard: [],
      // изначальное значение трассы типа рейса
      originalTripTypeCard: null,
      // линия прокладки
      routingLine: {},
      // текст ошибки
      errorMessage: '',
      // выбранный trip type в выпадающем списке
      selectedTripTypeId: null,
      // массив всех triptype
      tripTypesArr: [],
    }
  },

  computed: {

    ttSelectedInputArr() {
      return this.tripTypesArr.map(value => {
        return {
          title: getSignTitle(value.trip_type_sign),
          id: value.trip_type_id,
        }
      })
    },

    // включенные кнопки для таблицы
    tableButtonsExist() {
      return {
        edit: !this.isTableEditMode,
        save: this.isTableEditMode,
        cancel: this.isTableEditMode,
        refresh: true,
      }
    },

    // активные кнопки для таблицы
    tableButtonsEnabled() {
      return {
        edit: !this.isTableEditMode && !this.isMapEditMode,
        save: this.isTableEditMode,
        cancel: this.isTableEditMode,
        refresh: true,
      }
    },

    // включенные кнопки для карты
    mapButtonsExist() {
      return {
        edit: !this.isMapEditMode,
        save: this.isMapEditMode,
        cancel: this.isMapEditMode,
      }
    },

    // активные кнопки для карты
    mapButtonsEnabled() {
      return {
        edit: !this.isMapEditMode && !this.isTableEditMode,
        save: this.isMapEditMode,
        cancel: this.isMapEditMode,
      }
    },

    // ссылка на варианты маршрута
    routeVariantsLink() {
      return `/multipass/routes/${this.route_id}/routeVariants`
    },

    // ссылка на типы рейса
    tripTypesLink() {
      return `/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes`
    },

    // общий пробег
    totalLength() {
      if (this.tripTypeCard && Array.isArray(this.tripTypeCard)) {
        let l = 0;
        this.tripTypeCard.forEach(item => l = l + item.length);
        return Number(l / 1000).toFixed(3);
      }
      return '';
    },
  },

  methods: {

    // перезагружаем страницу с новым выбранным типом рейса
    async changeTripType() {
      this.isLoading = 'fetch';
      try {
        if (this.selectedTripTypeId != null) {
          this.$router.push(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes/${this.selectedTripTypeId}/tripTypeCard`);
          await this.tripTypeCardUpdate();
        }
      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // вызывается, когда нажата кнопка Обновить
    onTableRefresh() {
      // сбрасываем режимы редактирования
      this.isTableEditMode = false;
      this.isMapEditMode = false;
      // перезапрашиваются данные
      this.refreshData();
    },

    // вызывается, когда таблица переходит в режим редактирования
    onTableEdit() {
      this.isTableEditMode = true;
    },

    // вызывается при отмене режима редактирования таблицы
    onTableCancel() {
      // сбрасываем режим редактирования
      this.isTableEditMode = false;

      // восстанавливаем данные
      this.tripTypeCard = this.originalTripTypeCard.map(item => {
        return {...item}
      });
      this.selectedRow = null;
    },

    // проверяем карточку перед сохранением
    checkTripTypeCard() {

      // проверяем что количество КП не менее 2-х
      let count = 0;
      this.tripTypeCard.forEach(stop => {
        if (stop.is_control_point) count++;
      })
      if (count < 2) {
        this.errorMessage = 'В трассе должно быть как минимум 2 контрольных пункта.'
        return false;
      }

      // провереям, чтобы разворот не был в середине трассы
      let firstIndex = 0;
      while (firstIndex < this.tripTypeCard.length && this.tripTypeCard[firstIndex].is_skipped) firstIndex++;
      let lastIndex = this.tripTypeCard.length - 1;
      while (lastIndex >= 0 && this.tripTypeCard[lastIndex].is_skipped) lastIndex--;
      for (let i = firstIndex + 1; i < lastIndex; i++) {
        if (this.tripTypeCard[i].is_skipped) {
          this.errorMessage = 'Разворот не может быть в середине трассы.'
          return false;
        }
      }

      // все ОК
      return true;
    },

    // вызывается при сохранении таблицы
    onTableSave() {
      // проверяем карточку перед сохранением
      if (!this.checkTripTypeCard()) return;

      // снимаем режим редактирования
      this.isTableEditMode = false;
      // сохраняем карточку на сервере
      this.saveTripTypeCard();
    },

    // вызывается при выделении строки
    onRowSelect(item) {
      // определяем активный элемент таблицы
      this.selectedRow = item;

      // определяем активную  остановку
      const stop = getStop(item.stop_id)

      // перемещаем карту к активной остановке
      if (this.leaflet && stop) {
        const minStopZoom = this.$store.getters['settings/getMinStopZoom'];
        const zoom = this.leaflet.getZoom();
        if (zoom >= minStopZoom)
          this.leaflet.setView([stop.latitude, stop.longitude], zoom)
        else
          this.leaflet.setView([stop.latitude, stop.longitude], minStopZoom)
      }
    },

    // вызывается, когда карта переходит в режим реактирования
    onMapEdit() {
      this.isMapEditMode = true;
    },

    // вызывается при отмене режима редактирования карты
    onMapCancel() {
      // сбрасываем режим редактирования
      this.isMapEditMode = false;

      // восстанавливаем данные
      this.tripTypeCard = this.originalTripTypeCard.map(item => {
        return {...item}
      });
      this.tripTypeLine = this.originalTripTypeLine;
      this.tripTypeBaseLine = this.originalTripTypeBaseLine;
      this.routingLine = null;
      this.selectedRow = null;
    },

    // вызывается при сохранении карты
    onMapSave() {
      // проверяем карточку перед сохранением
      if (!this.checkTripTypeCard()) return;

      // сбрасываем режим редактирования
      this.isMapEditMode = false;
      // сохраняем все данные
      this.saveAll();
    },

    // вызывается при изменении базовой линии
    onBaseLineChanged(polyline, isEnd) {
      // запоминаем новую линию
      this.tripTypeBaseLine = polyline;
      // фиксируем модификацию
      this.isModified = true;
      // запускаем прокладку, только когда есть линия из двух точек
      if (isEnd && polyline && polyline.points && polyline.points.length >= 2) {
        this.routing(polyline)
      }
    },

    // удаляем остановку из трассы
    onDeleteStop(order_num) {

      // ищем индекс
      const index = this.tripTypeCard.findIndex(
          item => item.order_num === order_num
      );
      if (index < 0) return;

      // нельзя удалять первую и последнюю остановки
      if (index === 0 || index === this.tripTypeCard.length - 1) {
        this.errorMessage = "Нельзя удалить первую и последнюю остановку из трассы.";
        return;
      }
      // запоминаем длину удаляемой остановки
      const delLength = this.tripTypeCard[index].length;
      // добавляем пробег предыдущей
      this.tripTypeCard[index - 1].length += delLength;
      // удаляем остановку из трассы
      this.tripTypeCard.splice(index, 1);
      // сбрасываем курсор
      this.selectedRow = null;
    },

    // масштабировать линию типа рейса в экран
    tripTypeLineToMap(tripTypeLine) {
      if (tripTypeLine && tripTypeLine.points && tripTypeLine.points.length > 0 && this.leaflet) {
        const bounds = latLngBounds(
            tripTypeLine.points.map(point => [point.latitude, point.longitude])
        )
        this.leaflet.fitBounds(bounds);
      }
    },

    async tripTypeCardUpdate() {
      // загружаем тип рейса
      const tripTypes = await this.$store.dispatch('multipass/doFetchTripTypes', {
        route_variant_id: this.route_variant_id,
      });
      if (!tripTypes) return;
      this.tripTypesArr = tripTypes;
      let tripType = this.tripTypesArr.find(value => value.trip_type_id == this.trip_type_id);
      if (!tripType) return;
      this.tripType = tripType;
      this.selectedTripTypeId = this.trip_type_id;

      // загружаем карточку типа рейса
      const tripTypeCard = await this.$store.dispatch('multipass/doFetchTripTypeCard', {
        trip_type_id: this.trip_type_id,
        force: true
      });
      if (!tripTypeCard) return;
      this.tripTypeCard = tripTypeCard;
      this.originalTripTypeCard = tripTypeCard.map(item => {
        return {...item}
      });

      // загружаем базовую линию типа рейса
      const tripTypeBaseLine = await this.$store.dispatch('multipass/doFetchTripTypeBaseLine', {
        trip_type_id: this.trip_type_id
      });
      if (!tripTypeBaseLine) return;
      this.tripTypeBaseLine = tripTypeBaseLine;
      this.originalTripTypeBaseLine = {...tripTypeBaseLine};

      // загружаем линию типа рейса
      const tripTypeLine = await this.$store.dispatch('multipass/doFetchTripTypeLine', {
        trip_type_id: this.trip_type_id,
      });
      if (!tripTypeLine) return;
      this.tripTypeLine = tripTypeLine;
      this.originalTripTypeLine = {...tripTypeLine};
      // сбрасываем линию прокладки
      this.routingLine = {};

      // масштабируем карту
      this.tripTypeLineToMap(tripTypeLine);
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // загружаем список остановок
        await this.$store.dispatch('multipass/doFetchStops');

        // загружаем маршрут
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id
        });
        if (!route) return
        this.route = route;

        // загружаем вариант маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id
        });
        if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // // загружаем тип рейса
        // const tripType = await this.$store.dispatch('multipass/doFetchTripType', {
        //   trip_type_id: this.trip_type_id,
        // });
        // if (!tripType) return;
        // this.tripType = tripType;

        await this.tripTypeCardUpdate();

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // проложить маршрут
    async routing(polyline) {

      // запрашиваем данные с сервера
      const data = await this.$store.dispatch('gis/doRouting', {
        points: polyline.points.map(point => {
          return {
            latitude: point.latitude,
            longitude: point.longitude
          }
        }),
        is_begin_cropped: true,
        is_end_cropped: true,
        is_points: true,
        is_stops: true,
        is_nocropped: true
      });

      // формируем новую линию
      if (data && data.points) {
        this.tripTypeLine = {
          ...this.originalTripTypeLine,
          points: data.points.map((point, index) => {
            return {
              polyline_id: this.tripTypeLine.polyline_id,
              order_num: Number(index) + 1,
              latitude: Number(point.latitude),
              longitude: Number(point.longitude),
            }
          })
        }
      }

      // формируем новую линию прокладки
      if (data && data.nocropped_points) {
        this.routingLine = {
          points: data.nocropped_points.map((point, index) => {
            return {
              order_num: Number(index) + 1,
              latitude: Number(point.latitude),
              longitude: Number(point.longitude),
            }
          })
        }
      }

      // формируем новую трассу
      if (data && data.stops) {
        this.tripTypeCard = data.stops.map((stop, index) => {
          return {
            trip_type_id: Number(this.trip_type_id),
            order_num: index + 1,
            stop_id: stop.stop_id,
            length: Math.round(stop.stop_length),
            is_control_point: index === 0 || index === data.stops.length - 1 ? true : false,
            is_skipped: false,
          }
        });
      }
    },

    // сохраняем все данные
    async saveAll() {
      this.isLoading = true;
      try {

        // сохраняем карточку
        const tripTypeCard = await this.$store.dispatch('multipass/doSaveTripTypeCard', {
          trip_type_id: Number(this.trip_type_id),
          tripTypeCard: this.tripTypeCard.map((item, index) => {
            return {
              trip_type_id: Number(this.trip_type_id),
              order_num: index + 1,
              stop_id: item.stop_id,
              length: item.length,
              is_control_point: item.is_control_point,
              is_skipped: item.is_skipped,
            }
          })
        });
        if (tripTypeCard) {
          this.tripTypeCard = tripTypeCard;
          this.originalTripTypeCard = tripTypeCard.map(item => {
            return {...item}
          });
        }

        // сохраняем базовую линию
        if (this.isModified) {
          const tripTypeBaseLine = await this.$store.dispatch('multipass/doSaveTripTypeBaseLine', {
            trip_type_id: Number(this.trip_type_id),
            polyline: this.tripTypeBaseLine
          });
          if (tripTypeBaseLine) {
            this.tripTypeBaseLine = tripTypeBaseLine;
            this.originalTripTypeBaseLine = {...tripTypeBaseLine};
          }
        }

        // сохраняем линию
        const tripTypeLine = await this.$store.dispatch('multipass/doSaveTripTypeLine', {
          trip_type_id: Number(this.trip_type_id),
          polyline: this.tripTypeLine,
        });
        if (tripTypeLine) {
          this.tripTypeLine = tripTypeLine;
          this.originalTripTypeLine = {...tripTypeLine};

          // масштабируем карту
          this.tripTypeLineToMap(tripTypeLine);
        }
        // сбрасываем линию прокладки
        this.routingLine = null

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // сохраняем карточку
    async saveTripTypeCard() {
      this.isLoading = true;
      try {

        // сохраняем карточку
        const tripTypeCard = await this.$store.dispatch('multipass/doSaveTripTypeCard', {
          trip_type_id: Number(this.trip_type_id),
          tripTypeCard: this.tripTypeCard.map((item, index) => {
            return {
              trip_type_id: Number(this.trip_type_id),
              order_num: index + 1,
              stop_id: item.stop_id,
              length: item.length,
              is_control_point: item.is_control_point,
              is_skipped: item.is_skipped,
            }
          })
        });
        // если на сервере все сохранилось
        if (tripTypeCard) {
          this.tripTypeCard = tripTypeCard;
          this.originalTripTypeCard = tripTypeCard.map(item => {
            return {...item}
          });
          // если сервер вернул ошибку
        } else {
          // восстанавливаем данные
          this.tripTypeCard = this.originalTripTypeCard.map(item => {
            return {...item}
          });
        }

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    }
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

