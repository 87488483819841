import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПНЫХ ДЛЯ ФОРМИРОВАНИЯ НАРЯДА КОНДУТОРОВ


export default {
    state() {
        return {
            // список объектов
            orderConductors: [],
            // список объектов отсортированных по depot_id
            orderConductorsByDepot: {},
            // время обновления кэша new Date().getTime()
            orderConductorsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            orderConductorsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getOrderConductors(state) {
            return state.orderConductors;
        },

        // вернуть список объектов, отфильтрованный по conductor_id
        getOrderConductorsByDepotId(state) {
            return state.orderConductorsByDepot;
        },
    },
    mutations: {
        // установить новый список объектов
        setOrderConductors(state, payload) {
            state.orderConductors = payload;
            let arr = [];
            payload.map(value => {
                if (arr[value.depot_id]) {
                    arr[value.depot_id].push(value);
                } else {
                    arr[value.depot_id] = [];
                    arr[value.depot_id].push(value);
                }
            });
            state.orderConductorsByDepot = arr;
            state.orderConductorsCache = new Date().getTime();
        },
    },
    actions: {
        // запрос списка объектов
        doFetchOrderConductors: (context, payload) => {
            return DoGet(
                '/trn/getOrderConductors/' + payload.date_index,
                'trn/setOrderConductors',
                [],
                'Запрос списка доступных для формирования наряда кондукторов',
                false,
                payload && payload.force,
                context.state.orderConductorsCache,
                context.state.orderConductorsCacheTimeout
            )
        },

        // очищает кэш
        doClearOrderConductors: (context, payload) => {
            return DoClearCache(
                'trn/setOrderConductors',
                [],
                payload && payload.force,
                context.state.orderConductorsCache,
                context.state.orderConductorsCacheTimeout
            )
        },
    }
}