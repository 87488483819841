
// ХРАНИЛИЩЕ ДАННЫХ ПАССАЖИРОПОТОКА ЗА СУТКИ ПО ГРАФИКАМ РАСПИСАНИЯ МАРШРУТА ЗА ПЕРИОД ДАТ

import {DoGet} from "@/store/Actions";

export default {
    actions: {
        // запрос списка объектов
        doFetchPassengerTrafficDayByGraph: (context, payload) => {
            return DoGet(
                '/asmpp/passengerTrafficDayByGraph?route_id=' + payload.route_id + '&date_index_begin=' + payload.date_index_begin + '&date_index_end=' + payload.date_index_end,
                null,
                [],
                'Запрос пассажиропотока за сутки по графикам расписания маршрута',
                false,
                true
            )

        },
    }
}
