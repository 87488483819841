import {DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ СОСТОЯНИЙ ДНЯ

export default {
    actions: {
        // запрос списка
        doFetchAllDayStates: () => {
            return DoGet(
                '/nav/dayStates',
                '',
                null,
                'Запрос списка состояний всех дней',
                false,
            );
        },

        doFetchDayStates: (context, payload) => {
            return DoGet(
                '/nav/dayStates?section=' + payload.section,
                '',
                null,
                'Запрос отфильтрованного списка состояний дней',
                false,
            );
        },

        doFetchDayStatesByDate: (context, payload) => {
            return DoGet(
                '/nav/dayStates/' + payload.date_index,
                '',
                null,
                'Запрос состояния дня',
                false,
            );
        },

        doMoveDayStates: (context, payload) => {
            return DoPut(
                '/nav/moveDay',
                payload,
                null,
                'Запрос перемещения дня между секциями',
                false,
            );
        },

        doCloseNavDayStates: (context, payload) => {
            let caption = '';
            if(payload.nav_closed == true) {
                caption = 'Запрос закрытия навигации';
            } else if (payload.nav_closed == false) {
                caption = 'Запрос открытия навигации';
            }
            return DoPut(
                '/nav/closeNav',
                payload,
                null,
                caption,
                false,
            );
        },

    }
}