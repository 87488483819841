import store from './Store';

// вернуть сезон
export function getSeason(season_id) {
    return store.getters["rmt/getSeasons"].find(season => season.season_id == season_id);
}

// вернуть тип расписания
export function getScheduleType(schedule_type_id) {
    return store.getters["rmt/getScheduleTypes"].find(scheduleType => scheduleType.schedule_type_id == schedule_type_id);
}

// вернуть тип события
export function getScheduleEventType(schedule_event_type_id) {
    return store.getters["rmt/getScheduleEventTypes"].find(scheduleEventType => scheduleEventType.schedule_event_type_id == schedule_event_type_id);
}

// вернуть событие
export function getScheduleEvent(schedule_event_id) {
    return store.getters["rmt/getScheduleEvents"].find(scheduleEvent => scheduleEvent.schedule_event_id == schedule_event_id);
}

// вернуть статус варианта расписания
export function getScheduleVariantStatus(schedule_variant_status_id) {
    return store.getters["rmt/getScheduleVariantStatuses"].find(scheduleVariantStatus => scheduleVariantStatus.schedule_variant_status_id == schedule_variant_status_id);
}

// вернуть вид движения
export function getMovementKind(movement_kind_id) {
    return store.getters["rmt/getMovementKinds"].find(movementKind=> movementKind.movement_kind_id == movement_kind_id);
}

// вернуть вид перевозок
export function getTransportationKind(transportation_kind_id) {
    return store.getters["rmt/getTransportationKinds"].find(transportationKind=> transportationKind.transportation_kind_id == transportation_kind_id);
}

// вернуть режим работы водителей
export function getRegime(regime_id) {
    return store.getters["rmt/getRegimes"].find(regime => regime.regime_id == regime_id);
}
