import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ РЕЙСОВ С ДАННЫМИ РЕЙСОВОГО ПАССАЖИРОПОТОКА

export default {
    actions: {
        // запрос списка объектов
        doFetchFactTripExts: (context, payload) => {
            return DoGet(
                '/asmpp/factTripExts?route_id=' + payload.route_id + '&date_index_begin=' + payload.date_index_begin + '&date_index_end=' + payload.date_index_end,
                null,
                [],
                'Запрос списка рейсов с данными рейсового пассажиропотока по маршруту за период дат',
                false,
                true
            )
        },
    }
}
