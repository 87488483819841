import store from './Store';

// вернуть организационно-правовую форму
export function getLegalForm(legal_form_id) {
    return store.getters["ref/getLegalForms"].find(form => form.legal_form_id == legal_form_id);
}

// вернуть организацию
export function getOrganization(org_id) {
    return store.getters["ref/getOrganizations"].find(org => org.org_id == org_id)
}

// вернуть модель
export function getModel(model_id) {
    return store.getters["ref/getModels"].find(model => model.model_id == model_id);
}

// вернуть вид транспорта
export function getTransportType(transport_type_id) {
    return store.getters["ref/getTransportTypes"].find(type => type.transport_type_id == transport_type_id);
}

// вернуть группу вида транспорта
export function getTransportTypeGroup(transport_type_group_id) {
    return store.getters["ref/getTransportTypeGroups"].find(group => group.transport_type_group_id == transport_type_group_id);
}

// вернуть транспортное средство
export function getTransport(transport_id) {
    return store.getters["ref/getTransport"].find(tr => tr.transport_id == transport_id);
}

// вернуть группу транспортных средств
export function getTransportGroup(transport_group_id) {
    return store.getters["ref/getTransportGroups"].find(group => group.transport_group_id == transport_group_id);
}

// вернуть класс транспортного средства
export function getTransportClass(transport_class_id) {
    return store.getters["ref/getTransportClasses"].find(cls => cls.transport_class_id == transport_class_id);
}

// вернуть тип топлива
export function getEngineFuelType(engine_fuel_type_id) {
    return store.getters["ref/getEngineFuelTypes"].find(type => type.engine_fuel_type_id == engine_fuel_type_id);
}

// вернуть экологический класс
export function getEmissionStandard(emission_standard_id) {
    return store.getters["ref/getEmissionStandards"].find(standard => standard.emission_standard_id == emission_standard_id);
}

// вернуть транспортное предприятие
export function getDepot(depot_id) {
    return store.getters["ref/getDepots"].find(depot => depot.depot_id == depot_id);
}

// вернуть группу транспортных предприятий
export function getDepotGroup(depot_group_id) {
    return store.getters["ref/getDepotGroups"].find(group => group.depot_group_id == depot_group_id);
}

// вернуть работника
export function getPersonnel(personnel_id) {
    return store.getters["ref/getPersonnel"].find(pers => pers.personnel_id == personnel_id);
}

// вернуть профессию
export function getPersonnelJob(personnel_job_id) {
    return store.getters["ref/getPersonnelJobs"].find(job => job.personnel_job_id == personnel_job_id);
}

