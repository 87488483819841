<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">

      <page-header class="page-header">
        <span><router-link to="/rmt">Расписания</router-link></span>
        <span> / </span>
        <span><router-link to="/rmt/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to=routeVariantsLink>Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleVariantsLink>Варианты расписаний {{ routeVariantPageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleGraphSetLink>Наборы графиков {{ scheduleVariantPageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleGraphLink>Графики движения {{ scheduleGraphSetPageHeader }}</router-link></span>
        <span> / </span>
        <span>Расписание графика {{ scheduleGraphPageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">

          <crud-form
              :buttonsExist="{refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              :filterExists="false"
              @onRefresh="onTableRefresh"
          >

            <!-- Кнопка связь-->
            <template #preLeftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse4" class="btn dropdown-toggle btn-primary">
                  Связь
                </button-toggler>
                <dropdown-menu ref="collapse4">
                  <dropdown-item router-link :to="scheduleGraphStepsLink">Распорядок</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Нарядное время -->
            <template #leftCustomButton>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-total-ind-time">Нарядное время</span>
                </div>
                <input disabled="true" style="width: 75px" type="text" class="form-control"
                       aria-describedby="inputGroup-sizing-default" v-model="totalIndTime">
              </div>
            </template>

            <!-- Линейное время -->
            <template #leftCustomButton2>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-line-time">Линейное время</span>
                </div>
                <input disabled="true" style="width: 75px" type="text" class="form-control"
                       aria-describedby="inputGroup-line-time" v-model="lineIndTime">
              </div>
            </template>

            <!-- Время отстоя -->
            <template #leftCustomButton3>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-stop-time">Время отстоя</span>
                </div>
                <input disabled="true" style="width: 75px" type="text" class="form-control"
                       aria-describedby="inputGroup-stop-time" v-model="stayIndTime">
              </div>
            </template>

            <!-- Общий пробег -->
            <template #leftCustomButton4>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-length-time">Общий пробег</span>
                </div>
                <input disabled="true" style="width: 100px" type="text" class="form-control"
                       aria-describedby="inputGroup-length-time" v-model="totalIndLength">
              </div>
            </template>

            <!-- Линейный пробег -->
            <template #leftCustomButton5>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-line-length-time">Линейный пробег</span>
                </div>
                <input disabled="true" style="width: 100px" type="text" class="form-control"
                       aria-describedby="inputGroup-line-length-time"
                       v-model="lineIndLength">
              </div>
            </template>

          </crud-form>

        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">

          <schedule-time-tables-edit-table
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :scheduleTimeTables="scheduleTimeTables"
              @onRowSelect="onRowSelect"
          ></schedule-time-tables-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>


</template>

<script>

import ScheduleTimeTablesEditTable from "@/components/rmt/schedule_time_tables/ScheduleTimeTablesEditTable";
import {getLength, getTimeFormat} from "@/lib";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    ScheduleTimeTablesEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
    // идентификатор варианта расписания
    "schedule_variant_id",
    // идентификатор набора графиков
    "schedule_graph_set_id",
    // идентификатор графика
    "schedule_graph_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // вариант расписания
      scheduleVariant: {},
      // набор графиков
      scheduleGraphSet: {},
      // график
      scheduleGraph: {},
      // итоговое расписание
      scheduleTimeTables: [],
    }
  },

  computed: {

    // Нарядное время
    totalIndTime() {
      if (this.isLoading != 'fetch') {
        return getTimeFormat(this.scheduleGraph.total_ind_time)
      }
      return '';
    },

    // Линейное время
    lineIndTime() {
      if (this.isLoading != 'fetch') {
        return getTimeFormat(this.scheduleGraph.line_ind_time)
      }
      return '';
    },

    // Время отстоя
    stayIndTime() {
      if (this.isLoading != 'fetch') {
        return getTimeFormat(this.scheduleGraph.stay_ind_time)
      }
      return '';
    },

    // Общий пробег
    totalIndLength() {
      if (this.isLoading != 'fetch') {
        return getLength(this.scheduleGraph.total_ind_length)
      }
      return '';
    },

    // Линейный пробег
    lineIndLength() {
      if (this.isLoading != 'fetch') {
        return getLength(this.scheduleGraph.line_ind_length)
      }
      return '';
    },

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // ссылка для перехода к вариантам маршрутов
    routeVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants`
    },

    // ссылка для перехода к вариантам расписаний
    scheduleVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`;
    },

    // ссылка для перехода к наборам графиков
    scheduleGraphSetLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets`;
    },

    // ссылка для перехода к наборам графиков
    scheduleGraphLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs`;
    },

    scheduleGraphStepsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.schedule_graph_id}/scheduleGraphSteps`;
    },
  },

  methods: {

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // запрос маршрута
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        }); if (!route) return;
        this.route = route;

        // запрос варианта маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id,
        }); if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // запрос варианта расписания
        const scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
          schedule_variant_id: this.schedule_variant_id,
        }); if (!scheduleVariant) return;
        this.scheduleVariant = scheduleVariant;

        // запрос набора графиков
        const scheduleGraphSet = await this.$store.dispatch('rmt/doFetchScheduleGraphSet', {
          schedule_graph_set_id: this.schedule_graph_set_id,
        }); if (!scheduleGraphSet) return;
        this.scheduleGraphSet = scheduleGraphSet;

        // запрос графика
        const scheduleGraph = await this.$store.dispatch('rmt/doFetchScheduleGraph', {
          schedule_graph_id: this.schedule_graph_id,
        }); if (!scheduleGraph) return;
        this.scheduleGraph = scheduleGraph;

        // запрос итогового расписания
        const scheduleTimeTables = await this.$store.dispatch('rmt/doFetchScheduleTimeTables', {
          schedule_graph_id: this.schedule_graph_id,
        }); if (!scheduleTimeTables) return;
        this.scheduleTimeTables = scheduleTimeTables;

        await this.$store.dispatch('rmt/doFetchScheduleEvents'),
        await this.$store.dispatch('multipass/doFetchStops')

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData(true);
  },
};
</script>

<style scoped>

.mb-3 {
  margin-bottom: 0px !important;
}

</style>
