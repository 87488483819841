<template>
    <modal>
      <modal-dialog class="modal-dialog-centered">
        <modal-content>
          <modal-header class="bg-primary text-white">
            <modal-title>Вход в систему</modal-title>
          </modal-header>
          <modal-body>
            <form>
              <div class="form-group">
                <label for="login">Логин:</label><req-field />
                <input
                    id="login"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': isFormValidated && !isLoginValid }"
                    @focus="onFocus"
                    @keydown.enter="onSubmit"
                    autocomplete="username"
                    v-model.trim="login"
                />
                <div class="invalid-feedback">Введите логин</div>
              </div>
              <div class="form-group">
                <label for="password">Пароль:</label><req-field />
                <input
                    id="password"
                    type="password"
                    class="form-control"
                    :class="{ 'is-invalid': isFormValidated && !isPasswordValid }"
                    @focus="onFocus"
                    @keydown.enter="onSubmit"
                    autocomplete="current-password"
                    v-model.trim="password"
                />
                <div class="invalid-feedback">Введите пароль</div>
              </div>
            </form>
          </modal-body>
          <modal-footer>
            <button @click="onSubmit" class="btn btn-primary">Вход</button>
          </modal-footer>
        </modal-content>
      </modal-dialog>
    </modal>
</template>

<script>
export default {
  emits: ['onEnter'],

  data() {
    return {
      login: "",
      password: "",
      isFormValidated: false,
      isLoginValid: true,
      isPasswordValid: true,
    };
  },

  methods: {
    // проверить форму на ошибки
    validateForm() {
      this.isLoginValid = true;
      this.isPasswordValid = true;
      this.isFormValidated = true;
      if (this.login === "") {
        this.isLoginValid = false;
        return false;
      }
      if (this.password === "") {
        this.isPasswordValid = false;
        return false;
      }
      return true;
    },
    onFocus() {
      // сбрасываем признак проверки (при фокусировки любого элемента)
      this.isFormValidated = false;
    },
    onSubmit() {
      // проверяем форму
      if (this.validateForm()) {
        // формируем событие
        this.$emit('onEnter', {
          login: this.login,
          password: this.password,
        });
        // сбрасываем значения
        this.login = "";
        this.password = "";
      }
    },
  },
};
</script>
