import MainPage from '../pages/main/MainPage.vue';
import AsipTitlePage from '../pages/asip/AsipTitlePage.vue';

export default {
    path: '/asip',
    component: MainPage,
    children: [
        {
            path: ':pathMatch(.*)*',
            component: AsipTitlePage
        }                
    ]
}