import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ СПИСКА РЕЖИМОВ НА ОСТАНОВОЧНОМ ПУНКТЕ


export default {
    state() {
        return {
            // список объектов
            pointModes: [],
            // время обновления кэша new Date().getTime()
            pointModesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            pointModesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getPointModes(state) {
            return state.pointModes;
        },
    },
    mutations: {
        // установить новый список объектов
        setPointModes(state, payload) {
            state.pointModes = payload;
            state.pointModesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchPointModes: (context, payload) => {
            return DoGet(
                '/trn/pointModes',
                'trn/setPointModes',
                [],
                'Запрос списка режимов на остановочном пункте',
                false,
                payload && payload.force,
                context.state.pointModesCache,
                context.state.pointModesCacheTimeout
            )
        },

        // запрос списка объектов
        doFetchPointMode: (context, payload) => {
            return DoGet(
                '/trn/pointModes/' + payload.point_mode_id,
                'trn/setPointModes',
                [],
                'Запрос режима на остановочном пункте',
                false,
                payload && payload.force,
                context.state.pointModesCache,
                context.state.pointModesCacheTimeout
            )
        },

        // очищает кэш
        doClearPointModes: (context, payload) => {
            return DoClearCache(
                'trn/setPointModes',
                [],
                payload && payload.force,
                context.state.pointModesCache,
                context.state.pointModesCacheTimeout
            )
        },
    }
}