import {DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ НОРМ ВРЕМЕНИ НА ДВИЖЕНИЕ

export default {

    actions: {

        // запрос списка объектов
        doFetchTimeNormTimes: (context, payload) => {

            let url = '/rmt/timeNormTimes?schedule_variant_id=' + payload.schedule_variant_id;
            if (payload.time_norm_period_id)
                url += '&time_norm_period_id=' + payload.time_norm_period_id;
            if (payload.trip_type_id)
                url += '&trip_type_id=' + payload.trip_type_id;

            return DoGet(
                url,
                null,
                [],
                'Запрос норм на движение',
                false,
                true
            )
        },

        // изменение объекта
        doEditTimeNormTimes: (context, payload) => {
            return DoPut(
                '/rmt/timeNormTimes/' + payload.schedule_variant_id + '/' + payload.trip_type_id,
                payload.norms,
                null,
                'Изменение норм на движение'
            )
        },

        // 	очистка норм на движение
        doClearTimeNormTime: (context, payload) => {

            let url = '/rmt/clearTimeNormTime/' + payload.schedule_variant_id;
            if (payload.trip_type_id)
                url += '/' + payload.trip_type_id

            return DoPut (
                url,
                payload,
                null,
                'Очистка норм на движение'
            )
        },

        // 	обновление норм на движение
        doUpdateTimeNormTime: (context, payload) => {

            let url = '/rmt/softClearTimeNormTime/' + payload.schedule_variant_id;
            if (payload.trip_type_id)
                url += '/' + payload.trip_type_id

            return DoPut (
                url,
                payload,
                null,
                'Обновление норм на движение'
            )
        },

        // 	проверка норм на движение по варианту расписания
        doCheckTimeNormTime: (context, payload) => {
            return DoPut(
                '/rmt/checkTimeNormTime/' + payload.schedule_variant_id,
                payload,
                null,
                'Проверка норм на движение',
                false,
            )
        },
    }
}
