<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/ref">Справочники</router-link></span>
        <span> / </span>
        <span><router-link to="/ref/organizations">Организации</router-link></span>
        <span> / </span>
        <span>Реквизиты организации {{ organizationPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <template #leftCustomButton>
              <input-checkbox class="mt-2" v-model="isIndividual" id="isIndividual">Физ. лицо</input-checkbox>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <details-edit-table-ind
              v-if="isIndividual"
              :filterText="filterText"
              :selectedRow="selectedRow"
              :rowList="rowList"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></details-edit-table-ind>
          <details-edit-table-org
              v-else
              :filterText="filterText"
              :selectedRow="selectedRow"
              :rowList="rowList"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></details-edit-table-org>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <detail-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      :isIndividual="isIndividual"
      :parentList="parentList"
      parentField="parent_org_detail_id"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </detail-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить реквезиты с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import DetailsEditTableOrg from "@/components/ref/details/DetailsEditTableOrg";
import DetailsEditTableInd from "@/components/ref/details/DetailsEditTableInd";
import DetailDialog from "@/components/ref/details/DetailDialog";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  inheritAttrs: false,

  components: {
    DetailDialog,
    DetailsEditTableOrg,
    DetailsEditTableInd
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id",
    // идентификатор организации
    "org_id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // строки с данными
      rows: [],
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // отображение данных по физ. лицам
      isIndividual: false,
      // организация
      organization: {},
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {detail:{}}
      } else return this.selectedRow;
    },

    //получаем строки таблицы в формате общего документа
    rowList() {
      if (this.isLoading === 'fetch') return [];

      return this.rows.filter(detail => {
        const parent = this.rows.find(dt => dt.org_detail_id === detail.parent_org_detail_id);

        detail.__id =  detail.org_detail_id;
        detail.__parent_detail_title = parent ? (parent.detail.is_individual ? parent.detail.ind_last_name : parent.detail.org_title) : '';
        if (detail.detail.ind_identity_type == 1) {
          detail.detail.__indIdentityType = 'Паспорт';
        } else if (detail.detail.ind_identity_type == 2) {
          detail.detail.__indIdentityType = 'Военный билет';
        }
        else {
          detail.detail.__indIdentityType = '';
        }
        return this.isIndividual === detail.detail.is_individual;
      })
    },

    // список родительских реквизитов
    parentList() {
      return this.rows.map(detail => {
        return {
          id: detail.org_detail_id,
          title: detail.detail.is_individual ? detail.detail.ind_last_name : detail.detail.org_title
        }
      }).filter(detail => detail.id !== this.selectedRow.org_detail_id || this.op !== 'edit')
    },
  },

  methods: {

    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/ref/organizations/" + this.org_id + "/organizationDetails/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/ref/organizations/" + this.org_id + "/organizationDetails/edit/" + this.selectedRow.org_detail_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/ref/organizations/" + this.org_id + "/organizationDetails/del/" + this.selectedRow.org_detail_id);
    },

    // нажата кнопка Просмотр
    onTableShow() {
      this.$router.replace("/ref/organizations/" + this.org_id + "/organizationDetails/show/" + this.selectedRow.org_detail_id);
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/ref/organizations/" + this.org_id + "/organizationDetails");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      }
      else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/ref/organizations/" + this.org_id + "/organizationDetails");
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      this.refreshData();
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },
    
    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/ref/organizations/" + this.org_id + "/organizationDetails");
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // запрос организации
        const organization = await this.$store.dispatch('ref/doFetchOrganization', {
          org_id: this.org_id
        });if (!organization) return;
        this.organization = organization;

        // запрос реквизитов
        const rows = await this.$store.dispatch('ref/doFetchOrganizationDetails', {
          org_id: this.org_id
        }); if (!rows) return;
        this.rows = rows;

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        // заполняем значения
        item.org_detail_id = null;
        item.org_id = Number(this.org_id);
        item.detail.is_individual = this.isIndividual;
        // запрашиваем сервер
        const newItem = await this.$store.dispatch('ref/doAddOrganizationDetail', item);
        // мутируем массив
        this.selectedRow = addMutation(this.rows, 'org_detail_id', newItem);

      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        // заполняем значения
        item.org_detail_id = this.selectedRow.org_detail_id;
        item.org_id = Number(this.org_id);
        item.detail.is_individual = this.isIndividual;
        // запрашиваем сервер
        const newItem = await this.$store.dispatch('ref/doEditOrganizationDetail', item);
        // мутируем массив
        this.selectedRow = editMutation(this.rows, 'org_detail_id', newItem);

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрашиваем сервер
        const oldItem = await this.$store.dispatch('ref/doDelOrganizationDetail', this.selectedRow);
        // мутируем массив
        this.selectedRow = delMutation(this.rows, 'org_detail_id', oldItem);

      }
      finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

