<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-rmt-trip-types"> </edit-table-panel>
</template>

<script>

import {getSignTitle} from "@/lib";

export default {
  props: ['tripTypes'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "trip_type_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "title",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true
        },
        {
          field: "trip_type_sign",
          caption: "Тип рейса",
          sortType: 'sign',
          sorted: 'asc',
          dataAlign: 'left',
          headerAlign: 'left',
          isFilterable: true,
          displayFn(row) {
            return getSignTitle(row.trip_type_sign)
          },
        },
        {
          field: "is_production",
          caption: "Производ.",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_production) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show:  false,
        },
      ],
    };
  },
  computed: {
    rowList() {

      return this.tripTypes.map(type => {
        type.__id = type.trip_type_id;

        if (type.is_production == true) {
          type["__rowStyle"] = {
            backgroundColor: this.$store.getters["settings/getProductionReisColor"],
          }
        } else {
          type["__rowStyle"] = {
            backgroundColor: this.$store.getters["settings/getTechnicalReisColor"],
          }
        }

        return type
      });
    },
  },
};
</script>
