import {DoClearCache, DoGet} from "@/store/Actions";

// СТАТУСЫ ВАРИАНТОВ МАРШРУТОВ

export default {
    state() {
        return {
            // список объектов
            routeVariantStatuses: [],
            // время обновления кэша new Date().getTime()
            routeVariantStatusesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            routeVariantStatusesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRouteVariantStatuses(state) {
            return state.routeVariantStatuses;
        },
    },
    mutations: {
        // установить новый список объектов
        setRouteVariantStatuses(state, payload) {
            state.routeVariantStatuses = payload;
            state.routeVariantStatusesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос список объектов
        doFetchRouteVariantStatuses: (context, payload) => {
            return DoGet(
                '/multipass/routeVariantStatuses',
                'multipass/setRouteVariantStatuses',
                [],
                'Запрос статусов вариантов маршрутов',
                false,
                payload && payload.force,
                context.state.routeVariantStatusesCache,
                context.state.routeVariantStatusesCacheTimeout
            )
        },

        // очищает кэш
        doClearRouteVariantStatuses: (context, payload) => {
            return DoClearCache(
                'multipass/setRouteVariantStatuses',
                [],
                payload && payload.force,
                context.state.routeVariantStatusesCache,
                context.state.routeVariantStatusesCacheTimeout
            )
        },
    }
}