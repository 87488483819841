import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПНЫХ ДЛЯ УПРАВЛЕНИЯ ТРАНСОПРТНЫХ ПРЕДПРИЯТИЙ


export default {
    state() {
        return {
            // список объектов
            manageDepots: [],
            // время обновления кэша new Date().getTime()
            manageDepotsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            manageDepotsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getManageDepots(state) {
            return state.manageDepots;
        },
    },
    mutations: {
        // установить новый список объектов
        setManageDepots(state, payload) {
            state.manageDepots = payload;
            state.manageDepotsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchManageDepots: (context, payload) => {
            return DoGet(
                '/trn/getManageDepots',
                'trn/setManageDepots',
                [],
                'Запрос списка доступных для управления транспортных предприятий',
                false,
                payload && payload.force,
                context.state.manageDepotsCache,
                context.state.manageDepotsCacheTimeout
            )
        },

    }
}