import {DoClearCache, DoDelete, DoGet, DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ СВЯЗЕЙ МАРШРУТОВ С ГРУППАМИ

export default {
    state() {
        return {
            // список связей
            routeGroupLinks: [],
            // время обновления кэша new Date().getTime()
            routeGroupLinksCache: null,
            // время жизни кэша в мс (null - бесконечно)
            routeGroupLinksCacheTimeout: null
        }
    },
    getters: {
        // вернуть список связей
        getRouteGroupLinks(state) {
            return state.routeGroupLinks
        },
    },
    mutations: {
        // установить новый список связей
        setRouteGroupLinks(state, payload) {
            state.routeGroupLinks = payload;
            state.routeGroupLinksCache = new Date().getTime()
        },
        // связать объекты
        addRouteToGroup(state, payload) {
            state.routeGroupLinks.push(payload)
        },
        // удалить связь объектов
        delRouteFromGroup(state, payload) {
            const index = state.routeGroupLinks.findIndex(
                link => link.route_group_id == payload.route_group_id && link.route_id == payload.route_id
            );
            state.routeGroupLinks.splice(index, 1);
        },
    },
    actions: {
        // запрос списка связей
        doFetchRouteGroupLinks: (context, payload) => {
            return DoGet(
                '/multipass/routeGroupLinks',
                'multipass/setRouteGroupLinks',
                [],
                'Запрос связей маршрутов с группами',
                false,
                payload && payload.force,
                context.state.routeGroupLinksCache,
                context.state.routeGroupLinksCacheTimeout
            )
        },

        // очищает кэш
        doClearRouteGroupLinks: (context, payload) => {

            return DoClearCache(
                'multipass/setRouteGroupLinks',
                [],
                payload && payload.force,
                context.state.routeGroupLinksCache,
                context.state.routeGroupLinksCacheTimeout
            )
        },

        // связывание объектов
        doAddRouteToGroup: (context, payload) => {
            return DoPost(
                '/multipass/routeGroupLinks',
                payload,
                'multipass/addRouteToGroup',
                'Добавление маршрута в группу'
            )
        },

        // удаление связи объектов
        doDelRouteFromGroup: (context, payload) => {
            return DoDelete(
                '/multipass/routeGroupLinks/'+payload.route_group_id+'/'+payload.route_id,
                'multipass/delRouteFromGroup',
                'Удаление маршрута из группы'
            )
        },
    }
}