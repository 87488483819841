<template>

  <align-container>

    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/trn">Диспетчерская</router-link></span>
        <span> / </span>
        <span>Мастер-наряд {{ pageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">

      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: true, delete: false, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onEdit="onTableEdit"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка Очистить -->
            <template #leftCustomButton>
              <div class="btn-group">
                <button
                    type="button"
                    class="btn btn-danger"
                    title="Очистить"
                    @click="onClear"
                    :disabled="!orderDate"
                >
                  <i class="fas fa-broom"></i>
                </button>

                <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-danger"
                                :disabled="!selectedRow">Очистить
                </button-toggler>
                <dropdown-menu ref="collapse" style="margin-left: 38px">
                  <dropdown-item @click="onConfirmClearByDayAndRoute">Очистить по маршруту</dropdown-item>
                </dropdown-menu>

              </div>
            </template>

<!--            &lt;!&ndash; Кнопка Мониторинговая таблица&ndash;&gt;-->
<!--            <template #leftCustomButton2>-->
<!--              <div class="btn-group">-->
<!--                <button-->
<!--                    type="button"-->
<!--                    class="btn btn-primary"-->
<!--                    title="Мониторинговая таблица"-->
<!--                    :disabled="!orderDate"-->
<!--                    @click="createMonitorTableByDay"-->
<!--                >-->
<!--                  <i class="fas fa-table"></i>-->
<!--                </button>-->

<!--                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary"-->
<!--                                :disabled="!selectedRow">Мониторинговая таблица-->
<!--                </button-toggler>-->
<!--                <dropdown-menu ref="collapse2" style="margin-left: 38px">-->
<!--                  <dropdown-item @click="createMonitorTableByRouteAndDay">Мониторинговая таблица по маршруту-->
<!--                  </dropdown-item>-->
<!--                </dropdown-menu>-->

<!--              </div>-->
<!--            </template>-->


            <template #leftCustomButton3>
                <p style="margin-bottom: 0; margin-top: 6px;">Дата наряда</p>
            </template>

            <!--        Выбор даты наряда  -->
            <template #leftCustomButton4>
                <input-date
                    id="startDate"
                    v-model="selectedOrderDate"
                    class="form-control"
                    style="max-width: 151px"
                >
                </input-date>
            </template>

            <!-- Кнопка загрузить мастер-наряд-->
            <template #leftCustomButton5>
              <button
                  type="button"
                  class="btn btn-primary"
                  title="Загрузить наряд"
                  :disabled="!selectedOrderDate"
                  @click="downloadMasterOrder"
              >
                <i class="fas fa-download"></i>
              </button>
            </template>

          </crud-form>
        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <master-orders-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
          ></master-orders-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <master-orders-dialog
      v-if="isScheduleConfirmVisible"
      :scheduleVariants="scheduleVariants"
      @onCancel="onRejectOperation"
      @onOK="setSchedule"
      @onClear="setSchedule"
  >
  </master-orders-dialog>

  <!-- Подтверждение очищения мастер-наряда за указанный день -->
  <confirm-message-box
      v-if="isClearConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmClear"
  >Вы уверены, что хотите очистить мастер наряд за {{ orderDate }} ?
  </confirm-message-box>




  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import MasterOrdersEditTable from "@/components/trn/master_orders/MasterOrdersEditTable";
import {dateToND, dateToString, ndToDate, secondsToDate, stringToDate} from "@/lib";
import MasterOrdersDialog from "@/components/trn/master_orders/MasterOrdersDialog";

export default {
  mixins: [PageMixin],

  components: {
    MasterOrdersDialog,
    MasterOrdersEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id",
    //  индекс даты
    "date_index",
    // id маршрута
    "route_id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // дата в выбранная в комбобоксе
      selectedOrderDate: dateToString(ndToDate(dateToND()+1)),
      // дата по которой был сделан запрос (запоминаем для обновления)
      orderDate: null,
      // варианты расписаний
      scheduleVariants: [],
    }
  },

  computed: {

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh:  !!this.orderDate
      }
    },

    // условие отображения подтверждения очищения
    isClearConfirmVisible() {
      return this.op === 'clear';
    },

    pageHeader() {
      if (this.orderDate) {
        return ' за ' + this.orderDate;
      } else {
        return ''
      }
    },

    // видимость диалога выбора варианта расписания
    isScheduleConfirmVisible() {
      if (this.op == 'edit' && this.date_index && this.route_id) {
        return true;
      } else {
        return false;
      }
    },



  },

  methods: {

    // нажата кнопка очистить
    onClear () {
      this.$router.replace('/trn/masterOrders/clear/' + dateToND(stringToDate(this.orderDate)))
    },

    // редактируем вариант расписания в мастер-наряде
    async onTableEdit() {
      // делаем запрос на получение вариантов расписаний
      let schVar = await this.$store.dispatch('trn/getMasterOrderScheduleVariants', {
        date_index: dateToND(stringToDate(this.orderDate)),
        route_id: this.selectedRow.route_id,
        force: true,
      })
      if (schVar != false) {
        this.scheduleVariants = schVar;
      }
      // делаем видимым диалог выбор вариантов расписаний
      await this.$router.replace('/trn/masterOrders/edit/' + dateToND(stringToDate(this.orderDate)) + '/' + this.selectedRow.route_id);
    },

    // сетаем вариант расписания
    async setSchedule(scheduleVariantId) {
      // отправляем запрос на сервер на изменение варианта расписания по маршруту в мастер-наряду
      let response = await this.$store.dispatch('trn/doEditMasterOrder', {
        schedule_variant_id: scheduleVariantId,
        date_index: dateToND(stringToDate(this.selectedOrderDate)),
        route_id: this.selectedRow.route_id
      })
      if (response){
        this.selectedRow = response;
      }
      //делаем диалог невидимым
      await this.$router.replace("/trn/masterOrders");
    },

    // подтверждено удаление элемента
    onConfirmClear() {
      // удаляем элемент
      this.clearItem();
      // возвращаемся на страницу
      this.$router.replace("/trn/masterOrders");
    },

    // подтверждено удаление элемента
    onConfirmClearByDayAndRoute() {
      // удаляем элемент
      this.clearItemByDayAndRoute();
      // возвращаемся на страницу
      this.$router.replace("/trn/masterOrders");
    },

    // загрузка мастернарядов за сутки
    async downloadMasterOrder() {
      this.selectedRow = null;
      let date_index = dateToND(stringToDate(this.selectedOrderDate));
      await this.$store.dispatch('trn/doFetchMasterOrders', {
        date_index: date_index,
        force: true
      })
      // "запоминаем" дату по которой был сделан запрос
      this.orderDate = this.selectedOrderDate;
    },

    // нажата кнопка Обновить
    async onTableRefresh() {
      try{
      let date_index = dateToND(stringToDate(this.orderDate));
      await this.$store.dispatch('trn/doFetchMasterOrders', {
        date_index: date_index,
        force: true
      })
      } finally {
        this.selectedRow = null;
      }
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // // скрываем диалог
      this.$router.replace("/trn/masterOrders");
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('rmt/doFetchMovementKinds')
        await this.$store.dispatch('rmt/doFetchSeasons')
        await this.$store.dispatch('rmt/doFetchScheduleTypes')
        await this.$store.dispatch('rmt/doFetchScheduleVariantStatuses')

        if (this.date_index) {
          // задаем выбранный день в выборе даты
          this.selectedOrderDate = secondsToDate(this.date_index * 24 * 60 * 60);
          // задаем выбранный день, по которому был сделан запрос мастер-нарядов
          this.orderDate = secondsToDate(this.date_index * 24 * 60 * 60);
          // запрашиваем мастер-наряды, в случае если при обновлении страницы в роутере есть дата
          await this.$store.dispatch('trn/doFetchMasterOrders', {
            date_index: this.date_index,
            force: true
          })
        }

        // в случае если окно изменения варианта расписания открыто
        if (this.op == 'edit') {
          // задаем выбранный день в выборе даты
          this.selectedOrderDate = secondsToDate(this.date_index * 24 * 60 * 60);
          // задаем выбранный день, по которому был сделан запрос мастер-нарядов
          this.orderDate = secondsToDate(this.date_index * 24 * 60 * 60);
          // делаем запрос вариантов расписаний
          let schVar = await this.$store.dispatch('trn/getMasterOrderScheduleVariants', {
            date_index: this.date_index,
            route_id: this.route_id,
            force: true,
          })
          if (schVar != false) {
            this.scheduleVariants.push(...schVar);
          }

          // задаем выбранную строку
          this.selectedRow = this.$store.getters["trn/getMasterOrders"].find(value => {
            return ((value.date_index == this.date_index) && (value.route_id == this.route_id))
          });
        }

      } finally {
        this.isLoading = false;
        if (this.op != 'edit') {
          this.selectedRow = null;
        }
      }
    },

    // Очистить мастер-наряд за указанный день
    async clearItem() {
      this.isLoading = true;
      try {
        let date_index = dateToND(stringToDate(this.orderDate));
        await this.$store.dispatch('trn/doClearMasterOrderByDay', {
          date_index: date_index
        })
      } finally {
        this.isLoading = false;
      }
    },

    // Очистить мастер-наряд за указанный день
    async clearItemByDayAndRoute() {
      this.isLoading = true;
      try {
        let date_index = dateToND(stringToDate(this.orderDate));
        await this.$store.dispatch('trn/doClearMasterOrderByDayAndRoute', {
          date_index: date_index,
          route_id: this.selectedRow.route_id,
        })
      } finally {
        this.isLoading = false;
      }
    },

  },

  // вызывается при создании компонента
  created() {
    let arr = this.$store.getters['trn/getMasterOrders'];
    if(arr && arr.length != 0) {
      this.orderDate = dateToString(ndToDate(arr[0].date_index));
    }
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

