import store from './Store';


// вернуть доступное для формирования наряда транспортное предприятие
export function getOrderDepotTrn(depot_id) {
    return store.getters["trn/getOrderDepots"].find(depot => depot.depot_id == depot_id);
}

// вернуть доступный для формирования наряда маршрут
export function getOrderRouteTrn(route_id) {
    return store.getters["trn/getOrderRoutes"].find(route => route.route_id == route_id);
}

// вернуть наряд
export function getBaseOrder(base_order_id) {
    return store.getters["trn/getBaseOrders"].find(bo => bo.base_order_id == base_order_id);
}

// вернуть доступное для формирования наряда ТС
export function getOrderVehicleTrn(transport_id) {
    return store.getters["trn/getOrderVehicles"].find(ts => ts.transport_id == transport_id);
}

// вернуть доступного для формирования наряда водителя
export function getOrderDriverTrn(driver_id) {
    return store.getters["trn/getOrderDrivers"].find(driver => driver.driver_id == driver_id);
}

// вернуть доступное для формирования наряда транспортное предприятие
export function getManageDepotTrn(depot_id) {
    return store.getters["trn/getManageDepots"].find(depot => depot.depot_id == depot_id);
}

// вернуть доступный для формирования наряда маршрут
export function getManageRouteTrn(route_id) {
    return store.getters["trn/getManageRoutes"].find(route => route.route_id == route_id);
}

// вернуть наряд
export function getManageOrder(base_order_id) {
    return store.getters["trn/getManageOrders"].find(bo => bo.base_order_id == base_order_id);
}

// вернуть доступное для формирования наряда ТС
export function getManageVehicleTrn(transport_id) {
    return store.getters["trn/getManageVehicles"].find(ts => ts.transport_id == transport_id);
}

// вернуть доступного для формирования наряда водителя
export function getManageDriverTrn(driver_id) {
    return store.getters["trn/getManageDrivers"].find(driver => driver.driver_id == driver_id);
}

// вернуть управляющее воздействие
export function getManagementAction(management_action_id) {
    return store.getters["trn/getManagementActions"].find(action => action.management_action_id == management_action_id);
}
