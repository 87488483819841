<template>

  <align-container>

    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/nav">Телематика</router-link></span>
        <span> / </span>
        <span>Датчики</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <template #leftCustomButton>
              <sensor-groups-select class="form-control" v-model="sensorGroupId"></sensor-groups-select>
            </template>
          </crud-form>

        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <sensors-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :sensorGroupId="sensorGroupId"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
          ></sensors-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import SensorsEditTable from "@/components/nav/sensors/SensorsEditTable";
import SensorGroupsSelect from "@/components/nav/sensor_groups/SensorGroupsSelect";

export default {
  mixins: [PageMixin],

  components: {
    SensorGroupsSelect,
    SensorsEditTable,
  },

  data() {
    return {
      //группа
      sensorGroupId: null,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }
  },
  methods: {

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('nav/doFetchSensors', {force})
        await this.$store.dispatch('nav/doFetchSensorGroups', {force})

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

