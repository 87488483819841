<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/routeGroups">Группы маршрутов</router-link></span>
        <span> / </span>
        <span>Маршруты в группе {{ routeGroupPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{check: true, uncheck: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onCheck="onTableCheck"
              @onUncheck="onTableUncheck"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          ></crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <group-route-links-edit-table
              :id="id"
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
          ></group-route-links-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import GroupRouteLinksEditTable
  from "@/components/multipass/route_group_links/GroupRouteLinksEditTable";

import {getRouteGroup} from "@/store/MultipassHelpers";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    GroupRouteLinksEditTable
  },

  props: [
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false
    }    
  },

  computed: {
      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            check: !!this.routeGroup && !!this.selectedRow && !this.selectedRow.__checked,
            uncheck: !!this.routeGroup &&  !!this.selectedRow && this.selectedRow.__checked,
            refresh: true
          }
      },

      // текущая группа машрутов
      routeGroup() {
        return getRouteGroup(this.id);
      }
  },
  methods: {

    // нажата кнопка Включить
    onTableCheck() {
      this.linkObjects();
    },

    // нажата кнопка Выключить
    onTableUncheck() {
      this.unlinkObjects();
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchTransportTypes')
        await this.$store.dispatch('multipass/doFetchRouteKinds')
        await this.$store.dispatch('multipass/doFetchRouteTypes')
        await this.$store.dispatch('multipass/doFetchTransferKinds')
        await this.$store.dispatch('multipass/doFetchRouteStatuses')
        await this.$store.dispatch('multipass/doFetchRoutes');
        await this.$store.dispatch('multipass/doFetchRouteGroups');
        await this.$store.dispatch('multipass/doFetchRouteGroupLinks', { force });

      }
      finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // связываем объекты
    async linkObjects() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('multipass/doAddRouteToGroup', {
          route_group_id: this.routeGroup.route_group_id,
          route_id: this.selectedRow.route_id
        });

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем связь объектов
    async unlinkObjects() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('multipass/doDelRouteFromGroup', {
          route_group_id: this.routeGroup.route_group_id,
          route_id: this.selectedRow.route_id
        })

      }
      finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
