<template>
  <!-- Карта-->
  <leaflet-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @onMapCreated="onMapCreated"
  >

    <!-- Элементы управления-->
    <leaflet-control-group>
      <!-- Базовые слои-->
      <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
    </leaflet-control-group>

    <!--  Зона остановки -->
    <base-zone
        v-for="(zone) in zones"
        :key="zone.zone_id"
        :zone="zone.stop_zone"
        :tooltip="'#'+ zone.stop_id"
    >
    </base-zone>

    <!-- Линия типа рейса-->
    <trip-type-line-polyline
        v-for="(line) in marshLine"
        :key="line.polyline_id"
        :polyline="line"
        :originalOptions="{
          color: line.direction == 0 ? 'red' : 'blue',
          opacity: 1.0,
          draggable: false,
          weight: 5.0,
        }"
        :position="line.direction == 0 ? 'front' : 'back'"
    >
    </trip-type-line-polyline>

    <!--  Обычные остановки-->
    <stop-marker
        v-for="stop in stops"
        :key="stop.stop_id"
        :stop="stop"
        :tooltip="stop.long_name + ' (#'+ stop.stop_id+')'"
        type="0"
        state="original"
    ></stop-marker>

    <!-- Отметка авто -->
    <vehicle-marker
        v-for="vehicle in vehicles"
        :vehicle="vehicle"
        :key="vehicle.nav_id"
        :tab="1"
        color="2"
        events="dblclick"
        @onLeafletEvent="onLeafletEvent($event, vehicle)"
        :titleType="'timenav'"
    ></vehicle-marker>

  </leaflet-map>
</template>

<script>

export default {
  // список ТС
  props: [
    'vehicles',
    'zones',
    'marshLine',
    'stops',
  ],
  // список событий
  emits: ['onVehicleInfo'],
  computed: {

    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom']
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter']
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },
  },

  data() {
    return {
      // карта
      leaflet: null,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // границы карты
      minLat: 0,
      maxLat: 0,
      minLong: 0,
      maxLong: 0,
      // текущий масштаб
      curZoom: 0,
      // объект со слоями для элемента управления
      baseLayersObj: {},
      // нет контекстного меню остановки
      noContextMenu: {
        contextmenu: false,
      },
      vehicle: {
        latitude: 55.725937300000005,
        longitude: 37.65464410000001,
        angle: 45
      }
    }
  },

  methods: {

    // вызывается при создании базового слоя
    onBaseLayerCreated(layer) {
      this.$nextTick(() => {
        this.baseLayersObj = {
          ...this.baseLayersObj,
          [layer.title]: layer.origin
        }
      })
    },

    // срабатывает при щелчке на ТС
    onLeafletEvent(event, vehicle) {
      // вызываем событие при двойном щелчке на ТС
      if (event.type === 'dblclick') {
        this.$emit('onVehicleInfo', vehicle)
      }
    },

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;

      // // функция определяет границы карты и текущий zoom
      // const getMapValues = () => {
      //   // определяем границы карты
      //   const bounds = this.leaflet.getBounds();
      //   const northEast = bounds.getNorthEast();
      //   const southWest = bounds.getSouthWest();
      //   this.minLat = southWest.lat;
      //   this.maxLat =  northEast.lat;
      //   this.minLong = southWest.lng;
      //   this.maxLong = northEast.lng;
      //   // определяем текущий zoom
      //   this.curZoom = leaflet.getZoom();
      // }
      //
      // getMapValues();
      // leaflet.on('moveend', () => {
      //   getMapValues();
      // });
    },

  },
}
</script>
