<template>
  <edit-table-panel :columnList="columnList" :headerGroupList="headerGroupList" :disableSettings="true" :rowList="rowList"
                    :rowsPerPage="100" name="tbl-kvr-exp-result-trips"></edit-table-panel>
</template>

<script>

import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {
  getLength,
  getSignTitle,
  ndToDateString,
  secondsToTime
} from "@/lib";
import {getTransportClass, getTransportType} from "@/store/RefHelpers";
import {getAgency, getDepot, getMarkReasons, getRoute} from "@/store/KvrHelpers";
import {getCommand, getCommandReason, getExpert} from "@/store/KvrExpertHelpers";

export default {
  components: {EditTablePanel},
  props: ['rows', 'selectedTripTypeSign'],
  data() {
    return {
      // колонки
      columnList: [
        // Дата
        {
          field: '__date',
          caption: '',
          headerAlign: 'left',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        // Маршрут
        {
          field: '__route_num',
          caption: 'Маршрут',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__route_title',
          caption: 'Наименование маршрута',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__trType',
          caption: 'Тип транспорта',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__route_id',
          caption: 'Код',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__regNum',
          caption: 'Реестр',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        // План
        {
          field: 'sign',
          caption: 'Тип',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'length_plan',
          caption: 'Пробег',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.length_plan) {
              return getLength(row.length_plan);
            } else if (row.length_plan == 0) {
              return 0;
            }
            return ''
          },
        },
        {
          field: 'plan_transport_class',
          caption: 'Класс ТС',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
        },
        {
          field: 'plan_graph',
          caption: 'Граф.',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
        },
        {
          field: 'plan_int',
          caption: 'Инт.',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.plan_int) {
              return Math.round((row.plan_int/60));
            } else if (row.plan_int == 0){
              return 0;
            }
            return ''
          },
        },
        {
          field: 'plan_trip_time_index_begin',
          caption: 'Отпр.',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.plan_trip_time_index_begin) {
              return secondsToTime(row.plan_trip_time_index_begin);
            }
            return ''
          },
        },
        // Факт
        {
          field: 'fact_trip_time_index_begin',
          caption: 'Отпр.',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.fact_trip_time_index_begin) {
              return secondsToTime(row.fact_trip_time_index_begin);
            }
            return ''
          },
        },
        {
          field: 'delta',
          caption: 'Откл.',
          sortType: 'number',
          isSortable: false,
          show: true,
          displayFn(row) {
            if (row.delta) {
              return Math.round((row.delta/60));
            } else if (row.delta == 0){
              return 0;
            }
            return ''
          },
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'fact_int',
          caption: 'Инт.',
          sortType: 'number',
          isSortable: false,
          show: true,
          displayFn(row) {
            if (row.fact_int) {
              return Math.round((row.fact_int/60));
            } else if (row.fact_int == 0){
              return 0;
            }
            return ''
          },
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'fact_graph',
          caption: 'Граф.',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'fact_transport_class',
          caption: 'Класс ТС',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'garage_num',
          caption: 'Гар. №',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'state_num',
          caption: 'Гос. №',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'length_fact',
          caption: 'Пробег',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.length_fact) {
              return getLength(row.length_fact);
            } else if (row.length_fact == 0) {
              return 0;
            }
            return ''
          },
        },
        {
          field: 'fact_org_title',
          caption: 'Перевозчик',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'fact_depot_title',
          caption: 'Парк',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        // Зачет
        {
          field: 'plan_trip_count',
          caption: 'План',
          sortType: 'String',
          isSortable: false,
          dataAlign: 'center',
          headerAlign: 'center',
          show: true,
          displayType: 'html',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.plan_trip_count) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: 'fact_trip_count',
          caption: 'Факт',
          sortType: 'String',
          isSortable: false,
          dataAlign: 'center',
          headerAlign: 'center',
          show: true,
          displayType: 'html',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.fact_trip_count) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: 'regl_trip_count',
          caption: 'Рег.',
          sortType: 'String',
          isSortable: false,
          show: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.regl_trip_count) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        // Нарушения
        {
          field: 'is_track_penalty',
          caption: 'Трасса',
          sortType: 'String',
          isSortable: false,
          dataAlign: 'center',
          headerAlign: 'center',
          show: true,
          displayType: 'html',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.is_track_penalty) {
              return '<i class="fas fa-check">'
            } else if (row.is_track_penalty == null){
              return 'н/д'
            }
            return ''
          },
        },
        {
          field: 'is_speed_penalty',
          caption: 'Скорость',
          sortType: 'String',
          isSortable: false,
          dataAlign: 'center',
          headerAlign: 'center',
          show: true,
          displayType: 'html',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.is_speed_penalty) {
              return '<i class="fas fa-check">'
            } else if (row.is_speed_penalty == null){
              return 'н/д'
            }
            return ''
          },
        },
        {
          field: 'is_class_penalty',
          caption: 'Класс',
          sortType: 'String',
          isSortable: false,
          show: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.is_class_penalty) {
              return '<i class="fas fa-check">'
            } else if (row.is_class_penalty == null){
              return 'н/д'
            }
            return ''
          },
        },
        // Причина незачета
        {
          field: 'mark_reason_title',
          caption: '',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        // Экспертиза
        {
          field: '__exp_command',
          caption: 'Команда',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__exp_command_reason',
          caption: 'Причина',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'expert',
          caption: 'Эксперт',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__exp_command_length_fact',
          caption: 'Факт. пробег',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'exp_command_comment',
          caption: 'Комментарий',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
      ],
      headerGroupList: [
        {
          headerGroupCaption: 'Дата',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Маршрут',
          headerGroupSpan: 5,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'План',
          headerGroupSpan: 6,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Факт',
          headerGroupSpan: 10,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Зачет',
          headerGroupSpan: 3,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Нарушения',
          headerGroupSpan: 3,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Причина незачета',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Экспертиза',
          headerGroupSpan: 5,
          headerGroupAlign: 'center',
        }
      ],
    }
  },

  computed: {

    rowList() {

      let rowsArr = [];

      if(!this.rows) {
        return [];
      }

       this.rows.map(resultTrip => {
        resultTrip.__id = resultTrip.plan_trip_id;

        // определяем плановый класс ТС
        if(resultTrip.plan_transport_class_id == null) {
          resultTrip.plan_transport_class = '-';
        } else {
          let plan_transport_class = getTransportClass(resultTrip.plan_transport_class_id);
          if (plan_transport_class) {
            resultTrip.plan_transport_class = plan_transport_class.short_name;
          }
        }

        // определяем фактический класс ТС
        if(resultTrip.fact_transport_class_id == null) {
          resultTrip.fact_transport_class = '-';
        } else {
          let fact_transport_class = getTransportClass(resultTrip.fact_transport_class_id);
          if (fact_transport_class){
            resultTrip.fact_transport_class = fact_transport_class.short_name;
          }
        }

        // определяем организацию
        let agency = getAgency(resultTrip.fact_org_id);
        if(agency){
          resultTrip.fact_org_title = agency.short_name;
        }

        // определяем парк
        let depot = getDepot(resultTrip.fact_depot_id);
        if(depot){
          resultTrip.fact_depot_title = depot.short_name;
        }

         // определяем причину незачета рейса
         let mark_reason = getMarkReasons(resultTrip.mark_reason_id);
         if(mark_reason && resultTrip.mark_reason_id != 0){
           resultTrip.mark_reason_title = mark_reason.mark_reason_title;
         } else if (resultTrip.mark_reason_id == 0){
           resultTrip.mark_reason_title = '';
         } else if (resultTrip.mark_reason_id == null){
           resultTrip.mark_reason_title = 'н/д';
         }

         // определяем маршрут
        let route = getRoute(resultTrip.route_id);
        if(route){
          resultTrip.__route_num = route.route_num;
          resultTrip.__route_title = route.route_title;
          resultTrip.__regNum = route.reg_num;
          resultTrip.__route_id = route.route_id;
          // получаем тип транспорта
          let trType = getTransportType(route.transport_type_id);
          resultTrip.__trType = trType.short_name
        }

        // получаем команду
        let command = getCommand(resultTrip.exp_command_id);
        if(command) {
          resultTrip.__exp_command = command.long_name;
        }

        // получаем причины
        let reason = getCommandReason(resultTrip.exp_command_reason_id);
        if(reason) {
          resultTrip.__exp_command_reason = reason.long_name;
        }

        // получаем эксперт
        let expert = getExpert(resultTrip.uid);

        if(expert) {
          let last_name = expert.last_name ? expert.last_name : '';
          let first_name = expert.first_name ? expert.first_name.substring(0,1) : '';
          let middle_name = expert.middle_name ? expert.middle_name.substring(0,1) : '';
          resultTrip.expert = last_name + '' + first_name.substring(0,1) + '.' + middle_name.substring(0,1) + '.';
        }

        //определяем дату
        resultTrip.__date = ndToDateString(resultTrip.date_index);

        // задаем цвет заднего фона строк
        if (resultTrip.trip_mark == 0) {
          if (resultTrip.fact_trip_id) {
            resultTrip.__rowStyle = {
              backgroundColor: '#ffece8'
            }
          } else {
            resultTrip.__rowStyle = {
              backgroundColor: '#f8f19b'
            }
          }
        } else if (resultTrip.trip_mark == 1) {
          resultTrip.__rowStyle = {
            backgroundColor: '#daffd7'
          }
        } else if (resultTrip.trip_mark == 2) {
          resultTrip.__rowStyle = {
            backgroundColor: '#a4ff9c'
          }
        } else {
          resultTrip.__rowStyle = {};
        }
         console.log('resultTrip.trip_mark', resultTrip.trip_mark)
        // если "Отмена планового рейса" - делаем блеклый шрифт
        if(resultTrip.exp_command_id == 5){
          resultTrip.__rowStyle.color = '#8d8d8d'
        }

        // делаем пробег в километрах
         if(resultTrip.exp_command_length_fact) {
          resultTrip.__exp_command_length_fact = resultTrip.exp_command_length_fact / 1000;
         } else {
           resultTrip.__exp_command_length_fact = resultTrip.exp_command_length_fact;
         }

        resultTrip.__rowStyle['userSelect'] = 'none';
        if(resultTrip.exp_command_id != null) {
          resultTrip.__rowStyle.fontWeight = 'bold'
        }

        // получаем сигнатуру рейса
        resultTrip.sign = getSignTitle(resultTrip.trip_type_sign);

        // задаем стиль ячеек
        resultTrip.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px'
        };

         rowsArr.push(resultTrip);
      });

      // // сортируем массив
      // quickSort(rowsArr, (leftItem, rightItem) => {
      //   return 1 * expResultTripSortFn(leftItem, rightItem);
      // });

      return rowsArr;
    }

  }
}
</script>

<style scoped>

</style>