<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span>Список маршрутов</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка Команда-->
            <template #leftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                :disabled="commandDisabled">Команда
                </button-toggler>
                <dropdown-menu ref="collapse">
                  <dropdown-item @click="onSetStatus" v-if="setStatusExists">Установить статус</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Связь
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="routeVariantsLink">Варианты маршрута</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item router-link :to="routeGroupesLink">Группы маршрутов</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item router-link :to="routeDocsLink">Документы</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <template #leftCustomButton3>
              <route-groups-select class="form-control" v-model="routeGroupId"></route-groups-select>
            </template>

            <template #rightCustomButton>
              <input-checkbox class="mt-2" v-model="isClosedRoutesHidden" id="isClosedRoutesHidden">Скрыть закрытые</input-checkbox>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <routes-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :routeGroupId="routeGroupId"
              :selectedRow="selectedRow"
              :isClosedRoutesHidden="isClosedRoutesHidden"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></routes-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <route-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </route-dialog>

  <!-- Установка статуса -->
  <route-status-dialog
      v-if="isStatusDialogVisible"
      :item="selectedRow"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </route-status-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить маршрут с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Диалог со списком ошибок -->
  <errors-with-status-dialog
      v-if="isErrorsDialogVisible"
      :rowList="errors"
      @onClose="errors=[]"
      @onContinue="onContinue"
      caption="Ошибки маршрута"
  >
  </errors-with-status-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import RoutesEditTable from "@/components/multipass/routes/RoutesEditTable";
import RouteDialog from "@/components/multipass/routes/RouteDialog";
import RouteStatusDialog from "@/components/multipass/routes/RouteStatusDialog";
import RouteGroupsSelect from "@/components/multipass/route_groups/RouteGroupsSelect";
import PageMixin from "@/pages/share/PageMixin";
import {editMutation} from "@/store/Actions";
import store from "@/store/Store";

export default {
  mixins: [PageMixin],

  components: {
    RoutesEditTable,
    RouteDialog,
    RouteStatusDialog,
    RouteGroupsSelect
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      //группа
      routeGroupId: null,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // список ошибок маршрута
      errors: [],
      //значение статуса (заполняется, для повторного вызова команды)
      route_status_id: null,
      // отображение закрытых маршрутов
      isClosedRoutesHidden: true,
    }
  },

  computed: {

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения диалогового окна установки статуса
    isStatusDialogVisible() {
      return (this.op === 'status' && !!this.selectedRow)
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // условие отображения команды Установить статус
    setStatusExists() {
      return this.selectedRow
    },

    // условие отключения кнопки - Команды
    commandDisabled() {
      return !this.setStatusExists;
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // ссылка для перехода к вариантам маршрута
    routeVariantsLink() {
      if (this.selectedRow) return '/multipass/routes/' + this.selectedRow.route_id + '/routeVariants'
      else return '';
    },

    // ссылка для перехода к группам маршрута
    routeGroupesLink() {
      if (this.selectedRow) return '/multipass/routes/' + this.selectedRow.route_id + '/routeGroupLinks'
      else return '';
    },

    // ссылка для перехода к документам маршрута
    routeDocsLink() {
      if (this.selectedRow) return '/multipass/routes/' + this.selectedRow.route_id + '/routeDocs/';
      else return '';
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') return {}
      else return this.selectedRow;
    },

// условие отображения диалога со списком ошибок
    isErrorsDialogVisible() {
      if (this.errors.length > 0) {
        for(let i = 0; i < this.errors.length; i++) {
          if (this.errors[i].error_status == 'ALARM') {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },

  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/multipass/routes/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/multipass/routes/edit/" + this.selectedRow.route_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/multipass/routes/del/" + this.selectedRow.route_id);
    },

    // нажата кнопка Показать
    onTableShow() {
      this.$router.replace("/multipass/routes/show/" + this.selectedRow.route_id);
    },

    // команда установить статус
    onSetStatus() {
      this.$router.replace("/multipass/routes/status/" + this.selectedRow.route_id);
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/multipass/routes");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      } else if (this.op === 'status') {
        this.setItemStatus(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/multipass/routes");
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/multipass/routes");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchTransportTypes')
        await this.$store.dispatch('multipass/doFetchRouteKinds')
        await this.$store.dispatch('multipass/doFetchRouteTypes')
        await this.$store.dispatch('multipass/doFetchTransferKinds')
        await this.$store.dispatch('multipass/doFetchRouteStatuses')
        await this.$store.dispatch('multipass/doFetchRouteGroups')
        await this.$store.dispatch('multipass/doFetchRouteGroupLinks')
        await this.$store.dispatch('multipass/doFetchRoutes', {force})

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('multipass/doAddRoute', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('multipass/doEditRoute', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // устанавливаем статус
    async setItemStatus(item) {
      this.isLoading = true;
      try {
        // после установки статуса получаем ошибки
        let errors = await this.$store.dispatch('multipass/doSetRouteStatus', item);

        // если массив ошибок не пуст
        // отображаем их в соответствующем диалоге
        if (errors.length > 0) {
          this.errors = errors;
          this.route_status_id = item.route_status_id;
        } else {
          // в случае, если ошибок нет, делаем запрос на сервер для получения обновленного значения статуса
          // получаем "обновленный маршрут"
          const route = await this.$store.dispatch('multipass/doFetchRoute', {
            route_id: this.selectedRow.route_id
          });
          // мутируем массив
          this.selectedRow = editMutation(this.$store.getters["multipass/getRoutes"], 'route_id', route);
          // отображаем сообщение об успешном выполнении операции
          await store.dispatch('doAddMessage', {
            caption: 'Установка статуса маршрута',
            message: 'Команда успешно выполнена',
            type: 'ok'
          }, {root: true})

        }
      } finally {
        this.isLoading = false;
      }
    },

    // если не смотря на предупреждения пользователь нажал "продолжить"
    // отправляем повторный запрос с force == true
    async onContinue() {
      let item = {
        route_id: this.selectedRow.route_id,
        route_status_id: this.route_status_id,
        force: true,
      }
      // запрос на сервер
      let errors = await this.$store.dispatch('multipass/doSetRouteStatus', item);
      // параметр, который задает отображать ли окно с ошибками повторно
      let isErrorsDialogShowing = 0;
      // проверяем есть в вернувшемся массиве ошибки, если да, выводим сообщение повторно
      for (let i = 0; i < errors.length; i++) {
        if (errors[i].error_status == 'ALARM') {
          isErrorsDialogShowing = 1;
          break;
        }
      }
      if (isErrorsDialogShowing == 1) {
        this.errors = errors;
      } else {
        //делаем запрос на сервер для получения обновленного значения статуса
        // получаем "обновленный маршрут"
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.selectedRow.route_id
        });
        // мутируем массив
        this.selectedRow = editMutation(this.$store.getters["multipass/getRoutes"], 'route_id', route);
        // если ошибок нет, следовательно можно очистить массив сохраненных ошибок
        // и сохраненный статус
        this.errors = [];
        this.route_status_id = null;
        // отображаем сообщение об успешном выполнении операции
        await store.dispatch('doAddMessage', {
          caption: 'Установка статуса маршрута',
          message: 'Команда успешно выполнена',
          type: 'ok'
        }, {root: true})
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('multipass/doDelRoute', this.selectedRow);
        this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

