<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-nav-radio-model"> </edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "radio_model_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "radio_model_title",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "radio_protocol_id",
          caption: "Код эфирного протокола",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "__radio_model_media",
          caption: "Среда передачи данных",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "manufacturer",
          caption: "Производитель",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "is_driver_display",
          caption: "Дисплей водителя",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_driver_display) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_photo_request",
          caption: "Запрос фотографии",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_photo_request) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_temperature_sensor",
          caption: "Датчик температуры",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_temperature_sensor) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_noise_sensor",
          caption: "Датчик шума",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_noise_sensor) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_smoke_sensor",
          caption: "Датчика дыма",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_smoke_sensor) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_fuel_sensor",
          caption: "Датчик топлива",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_fuel_sensor) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_pay_system",
          caption: "Система оплаты проезда",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_pay_system) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_passenger_count",
          caption: "Подсчет пассажиров",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_passenger_count) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_record_system",
          caption: "Система видеонаблюдения",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_record_system) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_multimedia",
          caption: "Мультимедийная система",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_multimedia) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_eboard",
          caption: "Эл. маршрутоуказатели / информационное табло",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_eboard) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_autoinformer",
          caption: "Автоинформатор",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_autoinformer) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_tachograph",
          caption: "Тахограф",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_tachograph) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["nav/getRadioModels"].map(radioModel => {
        radioModel.__id = radioModel.radio_model_id;
        let rmm = '';
        if (radioModel.radio_model_media == 0) {
          rmm = 'GSM/GPRS';
        } else if (radioModel.radio_model_media == 1) {
          rmm = 'УКВ';
        }
        radioModel.__radio_model_media = rmm;
        return radioModel;
      });
    },
  }
};
</script>
