
import DocumentTypesDocStore from "@/store/doc/DocumentTypesDocStore";
import FileFormatsDocStore from "@/store/doc/FileFormatsDocStore";

// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ DOC

export default {
    namespaced: true,
    modules: {
        DocumentTypesDocStore,
        FileFormatsDocStore

    }
}
