import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ПЕРИОДОВ СУТОК (для норм времени на движение)

export default {
    actions: {

        // запрос списка объектов
        doFetchTimeNormPeriods: (context, payload) => {
            return DoGet(
                '/rmt/timeNormPeriods?schedule_variant_id=' + payload.schedule_variant_id,
                null,
                [],
                'Запрос периодов суток',
                false,
                true,
            )
        },

        // добавление объекта
        doAddTimeNormPeriod: (context, payload) => {
            return DoPost(
                '/rmt/timeNormPeriods',
                payload,
                null,
                'Добавление периода суток'
            )
        },

        // изменение объекта
        doEditTimeNormPeriod: (context, payload) => {
            return DoPut(
                '/rmt/timeNormPeriods/' + payload.time_norm_period_id,
                payload,
                null,
                'Изменение периода суток'
            )
        },

        // удаление объекта
        doDelTimeNormPeriod: (context, payload) => {
            return DoDelete(
                '/rmt/timeNormPeriods/' + payload.time_norm_period_id,
                null,
                'Удаление периода суток'
            )
        }
    }
}
