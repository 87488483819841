<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/rmtgen">Генератор</router-link></span>
        <span> / </span>
        <span>Список маршрутов</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">

          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >
            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Связь
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="routeVariantsLink">Варианты маршрута</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <template #leftCustomButton3>
              <route-groups-select class="form-control" v-model="routeGroupId"></route-groups-select>
            </template>

          </crud-form>

        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <routes-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :routeGroupId="routeGroupId"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></routes-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр  -->
  <route-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
  >
  </route-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import RoutesEditTable from "@/components/multipass/routes/RoutesEditTable";
import RouteDialog from "@/components/multipass/routes/RouteDialog";
import RouteGroupsSelect from "@/components/multipass/route_groups/RouteGroupsSelect";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    RoutesEditTable,
    RouteDialog,
    RouteGroupsSelect
  },

  props: [
    // операция show передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      //группа
      routeGroupId: null,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show')
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // ссылка для перехода к вариантам расписаний
    routeVariantsLink() {
      if (this.selectedRow) return '/rmtgen/routes/' + this.selectedRow.route_id + '/routeVariants'
      else return '';
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {}
      } else return this.selectedRow;
    }
  },
  methods: {

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // выполнено двойное нажатие строки в таблице (открываем просмотр)
    onTableShow(item) {
      this.$router.replace("/rmtgen/routes/show/" + item.route_id);
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/rmtgen/routes");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchTransportTypes');
        await this.$store.dispatch('multipass/doFetchRouteKinds');
        await this.$store.dispatch('multipass/doFetchRouteTypes');
        await this.$store.dispatch('multipass/doFetchTransferKinds');
        await this.$store.dispatch('multipass/doFetchRouteStatuses');
        await this.$store.dispatch('multipass/doFetchRouteGroups');
        await this.$store.dispatch('multipass/doFetchRouteGroupLinks');
        await this.$store.dispatch('multipass/doFetchRoutes', {force});

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

