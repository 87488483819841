import {DoClearCache, DoDelete, DoGet, DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ СВЯЗЕЙ КОНТРАКТОВ С МАРШРУТАМИ

export default {
    state() {
        return {
            // список связей
            contractRouteLinks: [],
            // время обновления кэша new Date().getTime()
            contractRouteLinksCache: null,
            // время жизни кэша в мс (null - бесконечно)
            contractRouteLinksCacheTimeout: null
        }
    },
    getters: {
        // вернуть список связей
        getContractRouteLinks(state) {
            return state.contractRouteLinks
        },
    },
    mutations: {
        // установить новый список связей
        setContractRouteLinks(state, payload) {
            state.contractRouteLinks = payload;
            state.contractRouteLinksCache = new Date().getTime()
        },
        // связать объекты
        addRouteToContract(state, payload) {
            state.contractRouteLinks.push(payload)
        },
        // удалить связь объектов
        delRouteFromContract(state, payload) {
            const index = state.contractRouteLinks.findIndex(
                link => link.contract_id == payload.contract_id && link.route_id == payload.route_id
            );
            state.contractRouteLinks.splice(index, 1);
        },
    },
    actions: {
        // запрос списка связей
        doFetchContractRouteLinks: (context, payload) => {
            return DoGet(
                '/kvr/contractRouteLinks',
                'kvr/setContractRouteLinks',
                [],
                'Запрос связей маршрутов с контрактом',
                false,
                payload && payload.force,
                context.state.contractRouteLinksCache,
                context.state.contractRouteLinksCacheTimeout
            )
        },

        // очищает кэш
        doClearContractRouteLinks: (context, payload) => {
            return DoClearCache(
                'kvr/setContractRouteLinks',
                [],
                payload && payload.force,
                context.state.contractRouteLinksCache,
                context.state.contractRouteLinksCacheTimeout
            )
        },

        // связывние объектов
        doAddRouteToContract: (context, payload) => {
            return DoPost(
                '/kvr/contractRouteLinks',
                payload,
                'kvr/addRouteToContract',
                'Добавление маршрута в контракт'
            )
        },

        // удаление связи объектов
        doDelRouteFromGroup: (context, payload) => {
            return DoDelete(
                '/kvr/contractRouteLinks/'+payload.contract_id+'/'+payload.route_id,
                'kvr/delRouteFromContract',
                'Удаление маршрута из контракта'
            )
        },
    }
}