<template>
  <!--  Линия-->
  <leaflet-polyline
      v-if="isCorrect"
      :latlngs="points"
      :options="options"
      :contextMenu="contextMenu"
      :tooltip="tooltip"
      :events="events + ' drag dragend dblclick'"
      :targetComponent="this"
      :position="position"
      @onLeafletEvent="onLeafletPolylineEvent($event)"
  >
  </leaflet-polyline>

  <!--  Точки редактирования-->
  <template
      v-if="isCorrect && isEdited"
  >
    <leaflet-marker
        v-for="(point, index) in points"
        :key="index"
        :latlng="point"
        :icon="Array.isArray(editPointIconList) && editPointIconList[index] ? editPointIconList[index] : editPointIcon"
        :options="Array.isArray(editPointOptionsList) && editPointOptionsList[index] ? editPointOptionsList[index] : editPointOptions"
        events="drag dragend dblclick"
        @onLeafletEvent="onLeafletMarkerEvent($event, index)"
    >
    </leaflet-marker>
  </template>

</template>

<script>

// interface POLYLINE {
//   polyline_id: number;
//   polyline_type_id: number;
//   polyline_title: string;
//   length: number;
//   note: string;
//   points: POLYLINE_POINT[]
// }

// interface POLYLINE_POINT {
//   polyline_id: number;
//   order_num: number;
//   latitude: number;
//   longitude: number;
// }

// опции линии
import {getCloseLineIndex, toLatLongArr} from "@/components/ui/leaflet/LeafletHelpers";
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import {divIcon} from "leaflet";
import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";

export default {
  mixins: [LeafletMapMixin, LeafletEventedMixin],
  emits: ['onPolylineChanged'],
  props: {
    // состояние элемента зоны: original - обычное, active - активное, edit - редактируемое, disabled - отключенное
    state: {
      type: String,
      default: 'original'
    },
    // описание линии
    polyline: {
      required: true,
    },
    // всплывающая подсказка слоя
    tooltip: {
      type: String
    },
    // контекстное меню слоя
    contextMenu: {
      type: Object
    },
    // опции линии в стандартном режиме
    originalOptions: {
      type: Object,
      default: () => {
        return {
          color: 'red',
          opacity: 1.0,
          draggable: false,
          weight: 5.0
        }
      }
    },
    // опции линии в активном режиме
    activeOptions: {
      type: Object,
      default: () => {
        return {
          color: 'red',
          opacity: 0.8,
          draggable: false,
          weight: 8.0
        }
      }
    },
    // опции линии в режиме редактирования
    editOptions: {
      type: Object,
      default: () => {
        return {
          color: 'red',
          opacity: 1.0,
          draggable: false,
          weight: 5.0
        }
      }
    },
    // опции линии в отключенном режиме
    disabledOptions: {
      type: Object,
      default: () => {
        return {
          color: 'grey',
          opacity: 0.3,
          draggable: false,
          weight: 5.0
        }
      }
    },
    // иконка маркеров редактирования
    editPointIcon: {
      default: () => {
        return divIcon({
          html: '<div style="width: 100%; height: 100%; border: 0px black solid; margin : 0px; padding: 0px; background-color: white"></div>',
          iconSize: [10, 10],
          iconAnchor: [5, 5]
        })
      }
    },
    // массив иконок маркеров редактирования
    editPointIconList: {
      type: Array,
      default: null,
    },
    // опции маркеров редактирования
    editPointOptions: {
      default: () => {
        return {
          draggable: true,
          clickable: true
        }
      }
    },
    // массив опция маркеров редактирования
    editPointOptionsList: {
      type: Array,
      default: null,
    },
    // положение слоя - front - на передний край, back - на задний (работает не для всех)
    position: {
      type: String,
      default: 'back'
    },
  },

  data() {
    return {
      // тип объекта
      leafletObjectType: 'BasePolyline',
      // карта
      leafletMap: null,
    }
  },

  computed: {
    // корректность данных
    isCorrect() {
      return this.leafletMap &&
          this.polyline &&
          this.polyline.points &&
          Array.isArray(this.polyline.points)
    },
    // точки линии
    points() {
      return this.polyline.points.map((item) => [item.latitude, item.longitude])
    },
    // опции линии
    options() {
      if (this.state === 'disabled') return this.disabledOptions
        else if (this.state === 'edit') return this.editOptions
          else if (this.state === 'active') return this.activeOptions
            else return this.originalOptions;
    },
    // признак режима редактирования
    isEdited() {
      return this.state === 'edit';
    },
  },

  methods: {
    // события от маркеров редактирования
    onLeafletMarkerEvent(event, index) {
      // перемещение точки редактирвания
      if (event.type === 'drag' && this.isEdited) {
        const points = [...this.points];
        points[index] =  toLatLongArr(event.target.getLatLng());
        // информируем об изменении
        this.$emit('onPolylineChanged', this.getPolyline(points), false);
      }
      else if (event.type === 'dragend' && this.isEdited) {
        const points = [...this.points];
        points[index] =  toLatLongArr(event.target.getLatLng());
        // информируем об изменении
        this.$emit('onPolylineChanged', this.getPolyline(points), true);
      }
      // двойной щелчок на точку - удаляем её
      else if (event.type === 'dblclick' && this.isEdited) {
        this.onDelPoint(index);
      }
    },

    // события от самой линии
    onLeafletPolylineEvent(event) {
      // двойной щелчок на линии - вставляем точку
      if (event.type === 'dblclick' && this.isEdited) {
        this.onInsertPoint(event);
      }

      // проверяем и вызываем внешние события
      if (this.eventsToArray(this.events).includes(event.type)) {
        this.$emit('onLeafletEvent', event);
      }
    },

    // вызываем для удаления точки
    onDelPoint(index) {
      //мы не можем оставить меньше 2-х точек
      if (this.points.length <= 2) return;

      // формируем список точек
      const points = [...this.points];
      //удаляем точку
      points.splice(index, 1);
      // информируем об изменении
      this.$emit('onPolylineChanged', this.getPolyline(points), true);
    },

    // вызывается для добавления точки
    onAddPoint(event) {
      // запрашиваем координату
      const latlng = event.latlng;
      // формируем список точек
      const points = [...this.points];

      // добавляем в конец
      points.push(toLatLongArr(latlng))
      // информируем об изменении
      this.$emit('onPolylineChanged', this.getPolyline(points), true);
    },

    // вызывается для вставки точки
    onInsertPoint(event) {
      // запрашиваем координату
      const latlng = event.latlng;
      // формируем список точек
      const points = [...this.points];

      // неполноценная линия
      if (points.length < 2) {
        points.push(toLatLongArr(latlng))
        // информируем об изменении
        this.$emit('onPolylineChanged', this.getPolyline(points), true);
        // выходим
        return;
      }
      // ищем индекс точки за которой надо вставлять
      const index = getCloseLineIndex(latlng, points);
      // индекс нашли
      if (index >= 0) {
        // добавляем точку
        points.splice(index + 1, 0, toLatLongArr(latlng));
        // информируем об изменении
        this.$emit('onPolylineChanged', this.getPolyline(points), true);
      }
    },

    // возвращает структуру polyline
    getPolyline(points) {
      if (!points) points = this.points

      return {
        polyline_id: this.polyline.polyline_id,
        polyline_type_id: this.polyline.polyline_type_id,
        polyline_title: this.polyline.polyline_title,
        length: this.polyline.length,
        note: this.polyline.note,
        points: points.map((point, index) => {
          return {
            polyline_id: this.polyline.polyline_id,
            order_num: Number(index) + 1,
            latitude: Number(point[0]),
            longitude: Number(point[1]),
          }
        })
      }
    }
  },

  // монтируем слой
  mounted() {
    // ждем создание родителя
    this.getParent().parentReady().then((parent_list) => {
      this.leafletMap = this.getMap(parent_list);
      const map = this.leafletMap.origin;

      // следим за изменением режима редактирования
      this.$watch(() => this.isEdited, (newValue) => {

        if (newValue) {
          // подписываемся на событие карты
          map.on('dblclick', this.onAddPoint);
        }
        else  {
          // отписываемся от события карты
          map.off('dblclick', this.onAddPoint);
        }
      }, {immediate: true});
    })
  },

  // демонтируем слой
  unmounted() {
    if (this.leafletMap) {
      // отписываемся от событий
      this.leafletMap.origin.off('dblclick', this.onAddPoint);
    }
  },
}
</script>
