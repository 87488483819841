<template>
    <!-- Карта-->
    <leaflet-map
        :zoom="zoom"
        :center="center"
        :contextMenu="mapContextMenu"
        :options="mapOptions"
        @onMapCreated="onMapCreated"
    >

      <!-- Элементы управления-->
      <leaflet-control-group>
        <!-- Базовые слои-->
        <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
      </leaflet-control-group>

      <!-- Остановки-->
      <stop-marker
          v-for="stop in stops"
          :key="stop.stop_id"
          :stop="stop"
          :tooltip="stop.long_name + ' (#'+stop.stop_id+')'"
          :state="stop === selectedStop && isMoveMode ? 'edit': stop === selectedStop ? 'active': 'original'"
          :contextMenu="stop === selectedStop && !isMoveMode ? stopContextMenu: noContextMenu"
          events="click dblclick"
          @onLeafletEvent="onLeafletEvent($event, stop)"
          @onStopChanged="$emit('onStopChanged', $event)"
      ></stop-marker>

    </leaflet-map>
</template>

<script>

import {getStopsInRectangle} from "@/store/MultipassHelpers";

export default {
  // список остановок
  props: [
    // отображать все остановки
    'isAllStops',
    // выбранная остановка
    'selectedStop',
    // режим перемещения
    'isMoveMode'
  ],
  // список событий
  emits: [
    // Команда "добавить остановку"
    'onAdd',
    // Команда "изменить остановку"
    'onEdit',
    // Команда "удалить остановку"
    'onDelete',
    // Команда "переместить остановку"
    'onMove',
    // двойной щелчек на остановке
    'onShow',
    // вызывается при щелчке на остановке
    'onStopSelect',
    // вызывается при перемещении остановки
    'onStopChanged'
  ],
  computed: {

    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom']
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter']
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },

    // минимальный масштаб для отображения остановок
    minStopZoom() {
      return this.$store.getters['settings/getMinStopZoom']
    },

    // список остановок
    stops() {
      const stops = [];
      // при отображении всех остановок
      if (this.curZoom >= this.minStopZoom && this.isAllStops) {
        getStopsInRectangle(this.minLat, this.maxLat, this.minLong, this.maxLong).forEach(stop => {
          if (!stop.latitude || !stop.longitude) return;
          stops.push(stop);
        });
      }
      else if (this.selectedStop) {
        stops.push(this.selectedStop);
      }

      return stops;
    },

    // контекстное меню карты
    mapContextMenu() {
      if (this.isMoveMode) {
        return {
          contextmenu: true,
          contextmenuWidth: 140,
          contextmenuItems: []
        }
      }
      else {
        return {
          contextmenu: true,
          contextmenuWidth: 140,
          contextmenuItems: [
            {
              text: 'Добавить',
              callback: (event) => {
                const latlng = event.latlng;
                this.$emit('onAdd', {
                  latitude: latlng.lat,
                  longitude: latlng.lng
                })
              }
            }
          ]
        }
      }
    },
  },

  data() {
    return {
      // карта
      leaflet: null,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // границы карты
      minLat: 0,
      maxLat: 0,
      minLong: 0,
      maxLong: 0,
      // текущий масштаб
      curZoom: 0,
      // контекстное меню остановки
      stopContextMenu: {
        contextmenu: true,
        contextmenuInheritItems: false,
        contextmenuWidth: 140,
        contextmenuItems: [
          {
            text: 'Изменить',
            callback: () => {
              this.$emit('onEdit', this.selectedStop)
            }
          },
          {
            text: 'Удалить',
            callback: () => {
              this.$emit('onDelete', this.selectedStop)
            }
          },
          {
            separator: true
          },
          {
            text: 'Переместить',
            callback: () => {
              this.$emit('onMove', this.selectedStop)
            }
          },
        ]
      },
      // нет контекстного меню остановки
      noContextMenu: {
        contextmenu: false,
      },
    }
  },

  methods: {

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;

      // функция определяет границы карты и текущий zoom
      const getMapValues = () => {
        // определяем границы карты
        const bounds = this.leaflet.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        this.minLat = southWest.lat;
        this.maxLat =  northEast.lat;
        this.minLong = southWest.lng;
        this.maxLong = northEast.lng;
        // определяем текущий zoom
        this.curZoom = leaflet.getZoom();
      }

      getMapValues();
      leaflet.on('moveend', () => {
        getMapValues();
      });
    },

    // срабатывает при щелчке на остановку
    onLeafletEvent(event, stop) {
      // при щелчке выделяем выделяем остановку
      if (event.type === 'click') {
        this.$emit('onStopSelect', stop);
      }
      // вызываем событие при двойном щелчке на остановке
      else if (event.type === 'dblclick') {
        this.$emit('onShow', stop)
      }
    }
  },
}
</script>
