import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ГРАФА

export default {

    actions: {
        // запрос списка объектов
        doFetchGraphElements: (context, payload) => {
            return DoGet(
                `/gis/graphElements?min_lat=${payload.min_lat}&max_lat=${payload.max_lat}&min_long=${payload.min_long}&max_long=${payload.max_long}`,
                null,
                {},
                'Запрос элементов графа',
                false,
                true
            )
        },

        // изменение узла графа
        doEditGraphNode: (context, payload) => {
            return DoPut (
                '/gis/graphNodes/'+payload.node_id,
                payload,
                null,
                'Изменение узла графа',
                false
            )
        },

        // удаление узла графа
        doDelGraphNode: (context, payload) => {
            return DoDelete (
                '/gis/graphNodes/'+payload.node_id,
                null,
                'Удаление узла графа',
                false
            )
        },

        // изменение ребра графа
        doEditGraphEdge: (context, payload) => {
            return DoPut (
                '/gis/graphEdges/'+payload.edge_id,
                payload,
                null,
                'Изменение ребра графа',
                true
            )
        },

        // удаление ребра графа
        doDelGraphEdge: (context, payload) => {
            return DoDelete (
                '/gis/graphEdges/'+payload.edge_id,
                null,
                'Удаление ребра графа',
                false
            )
        },

        // создание ребра графа
        doCreateGraphEdge: (context, payload) => {
            return DoPost (
                '/gis/createGraphEdge',
                payload,
                null,
                'Создание ребра графа',
                false
            )
        },

        // разбитие ребра графа
        doSplitGraphEdge: (context, payload) => {
            return DoPost (
                '/gis/splitGraphEdge',
                payload,
                null,
                'Разбитие ребра графа',
                false
            )
        },

        // соединение ребер графа
        doSpliceGraphEdge: (context, payload) => {
            return DoPost (
                '/gis/spliceGraphEdge',
                payload,
                null,
                'Соединение ребер графа',
                false
            )
        },
    }
}
