<template>
  <router-view></router-view> 
</template>

<script>
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixin: [PageMixin],
}

</script>
