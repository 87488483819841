<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-organizations"></edit-table-panel>
</template>

<script>
import {getLegalForm, getOrganization} from "@/store/RefHelpers";
import EditTablePanel from "@/components/ui/custom/EditTablePanel";

export default {
  components: {EditTablePanel},
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "org_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__legalFormName",
          caption: "Организационно-правовая форма",
          sortType: 'string',
          show: false
        },
        {
          field: "is_agency",
          caption: "Перевозчик",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_agency) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "level",
          caption: "Уровень",
          sortType: 'number',
          show: false
        },
        {
          field: "__parentOrgName",
          caption: "Вышестоящая организация",
          sortType: 'string'
        }
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') return []

      // возвращаем результат
      return this.$store.getters['ref/getOrganizations'].map(org => {

        const legalForm = getLegalForm(org.legal_form_id);
        const parentOrg = getOrganization(org.parent_org_id);

        org.__id = org.organization_id;
        org.__legalFormName = legalForm && legalForm.long_name || '';
        org.__parentOrgName = parentOrg && parentOrg.long_name || '';

        return org
      });
    },
  },
};
</script>
