<template>
  <align-container>
    <align-panel align="top">
      <page-header>
        <span><router-link to="/kvr">КВР</router-link></span>
        <span> / </span>
        <span>Наряд на работу {{ dateForPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <align-panel align="top" :gap="3">
          <!-- Форма с кнопками над левой таблицей-->
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка Команда-->
            <template #preLeftCustomButton>
              <dropdown style="z-index: 100">
                <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                :disabled="isEditAllEnable">Команда
                </button-toggler>
                <dropdown-menu ref="collapse">
                  <dropdown-item @click="showDepotsForClear" :disabled="isEditAllEnable">Очистить все
                  </dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item :disabled="!selectedRow || isEditAllEnable"
                                 @click="showClearManualOrderByDepotDialog">Восстановить парк
                  </dropdown-item>
                  <dropdown-item :disabled="!selectedRow || isEditAllEnable"
                                 @click="showClearManualOrderByRouteDialog">Восстановить маршрут
                  </dropdown-item>
                  <dropdown-item :disabled="!selectedRow || isEditAllEnable"
                                 @click="showClearManualOrderByGraphDialog">Восстановить график
                  </dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item :disabled="!selectedRow || isEditAllEnable"
                                 @click="showSplitManualOrderDialog">Разбить график
                  </dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item @click="showDepotsForApply" :disabled="isEditAllEnable">Передать наряд
                  </dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!--            кнопка "редактировать / сохранить"-->
            <template #preLeftCustomButton2>
              <button
                  v-if="!isEditAllEnable"
                  type="button"
                  class="btn btn-warning"
                  title="Изменить все"
                  :disabled="manualOrdersArr.length == 0"
                  @click="onEditAllClick"
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                  v-else
                  type="button"
                  class="btn btn-success"
                  title="Сохранить все"
                  :disabled="!selectedOrderDate || isSaveButtonDisabled"
                  @click="onSaveAll"
              >
                <i class="fas fa-save"></i>
              </button>
            </template>

            <template #preLeftCustomButton3 v-if="isEditAllEnable">
              <button
                  type="button"
                  class="btn btn-danger"
                  title="Отменить"
                  :disabled="!selectedOrderDate"
                  @click="onCancelAll"
              >
                <i class="fas fa-ban"></i>
              </button>
            </template>

            <!--        Выбор даты наряда  -->
            <template #leftCustomButton>
              <input-date
                  id="startDate"
                  v-model="selectedOrderDate"
                  class="form-control"
                  style="max-width: 151px"
              >
              </input-date>
            </template>

            <!-- Кнопка загрузить мастер-наряд-->
            <template #leftCustomButton2>
              <button
                  type="button"
                  class="btn btn-primary"
                  title="Загрузить наряд"
                  :disabled="!selectedOrderDate"
                  @click="downloadManualOrders"
              >
                <i class="fas fa-download"></i>
              </button>
            </template>

          </crud-form>
        </align-panel>
        <align-panel align="all">
          <!--  таблица нарядов на работу -->
          <manual-orders-edit-table
              :rows="manualOrdersArr"
              :filterText="filterText"
              :selectedRow="selectedRow"
              :isEditAllEnable="isEditAllEnable"
              :isSaveAll="isSaveAll"
              :originalManualOrdersArr="originalManualOrdersArr"
              @doEdit="doEditRow"
              @onRowSelect="onRowSelect"
              @onRowSelectCorrecting="onRowSelect"
              @saveAllChangedRows="saveAllChangedRows"
              @setCommandButtonDisabled="setCommandButton"
              style="z-index: 1"
          >
          </manual-orders-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <order-depots-dialog
      v-if="isDialogVisible"
      @onSubmit="onClearManualOrder"
      @onCancel="onRejectOperation"
  >
  </order-depots-dialog>

  <!-- Подтверждение восстановления наряда по транспортному предприятию  -->
  <confirm-message-box
      v-if="isClearManualOrderByDepotDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmClearManualOrderByDepot"
  >Восстановить наряд по транспортному предприятию {{ depot }} за {{ date }} ?
  </confirm-message-box>

  <!-- Подтверждение восстановления наряда по маршруту  -->
  <confirm-message-box
      v-if="isClearManualOrderByRouteDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmClearManualOrderByRoute"
  >Восстановить наряд по маршруту {{ route }} предприятия {{ depot }} за {{ date }} ?
  </confirm-message-box>

  <!-- Подтверждение восстановления наряда по графику  -->
  <confirm-message-box
      v-if="isClearManualOrderByRouteGrafVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmClearManualOrderByGraph"
  >Восстановить наряд по графику {{ manualOrderGraph }} по маршруту {{ route }} предприятия {{ depot }} за {{ date }} ?
  </confirm-message-box>

  <split-manual-order-dialog
      v-if="isSplitManualOrderVisible"
      :selectedManualOrder="selectedRow"
      @onCancel="onRejectOperation"
      @onOK="onSplitManualOrder"
  >
  </split-manual-order-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import {
  dateToND,
  dateToString,
  ndToDate,
  ndToDateString,
  stringToDate,
} from "@/lib";
import PageMixin from "@/pages/share/PageMixin";
import ManualOrdersEditTable from "@/components/kvr/manual_orders/ManualOrdersEditTable";
import OrderDepotsDialog from "@/components/kvr/manual_orders/OrderDepotsDialog";
import {getManualOrder, getOrderDepot, getOrderRoute} from "@/store/KvrHelpers";
import SplitManualOrderDialog from "@/components/kvr/manual_orders/SplitManualOrderDialog";

export default {
  name: "ManualOrdersPage",
  mixins: [PageMixin],
  components: {
    SplitManualOrderDialog,
    OrderDepotsDialog,
    ManualOrdersEditTable,
    AlignPanel, AlignContainer
  },
  props: [
    'op',
    'date_index',
    'plan_depot_id',
    'route_id',
    'manual_order_id'
  ],
  data() {
    return {
      // отображать спиннер
      isLoading: false,
      // выбранная строка
      selectedRow: null,
      // текст фильтра
      filterText: '',
      // дата в селекторе даты по которой делается запрос нарядов
      selectedOrderDate: dateToString(ndToDate(dateToND() + 1)),
      // дата по которой был сделан запрос нарядов
      requestedOrderDate: '',
      // исходный массив нарядов на работу
      originalManualOrdersArr: [],
      // массив нарядов на работу
      manualOrdersArr: [],
      // если есть хоть одна строка в режиме редактирования блокируем кнопку "Команда"
      isCommandButtonDisabled: false,
      // введен ли режим "редактировать все"
      isEditAllEnable: false,
      // триггер сохранения всех строк с изменениями
      isSaveAll: false,
    }
  },

  computed: {

    isSaveButtonDisabled() {
      for (let i = 0; i < this.manualOrdersArr.length; i++) {
        if (this.manualOrdersArr[i].driver_id && !this.manualOrdersArr[i].transport_id) {
          return true;
        }
      }
      return false;
    },

    dateForPageHeader() {
      if (this.requestedOrderDate) {
        return 'за ' + this.requestedOrderDate;
      } else {
        return '';
      }
    },

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: false,
        edit: false,
        delete: false,
        refresh: true,
      }
    },

    // видимость диалога
    isDialogVisible() {
      if (this.op === 'apply' || (this.op == 'clear' && (!this.plan_depot_id) && (!this.route_id) && (!this.manual_order_id))) {
        return true;
      }
      return false;
    },

    // Нажата кнопка "Восстановить наряд по транспортному предприятию"
    isClearManualOrderByDepotDialogVisible() {
      if (this.op == 'clear' && this.plan_depot_id && (!this.route_id) && (!this.manual_order_id)) {
        return true;
      }
      return false;
    },

    // Нажата кнопка "Восстановить наряд по маршруту"
    isClearManualOrderByRouteDialogVisible() {
      if (this.op == 'clear' && this.plan_depot_id && this.route_id && (!this.manual_order_id)) {
        return true;
      }
      return false;
    },

    // Нажата кнопка "Восстановить наряд по графику"
    isClearManualOrderByRouteGrafVisible() {
      if (this.op == 'clear' && this.plan_depot_id && this.route_id && this.manual_order_id) {
        return true;
      }
      return false;
    },

    isSplitManualOrderVisible() {
      if (this.op == 'split' && this.manual_order_id) {
        return true;
      }
      return false;
    },

    // получаем транспортное предприятие
    depot() {
      let depot = getOrderDepot(this.plan_depot_id);
      if (depot) {
        return depot.long_name;
      }
      return ''
    },

    // получаем маршрут
    route() {
      let route = getOrderRoute(this.route_id);
      if (route) {
        return route.route_num + ' - ' + route.route_title;
      }
      return '';
    },

    // получаем график наряда
    manualOrderGraph() {
      let manualOrder = getManualOrder(this.manual_order_id);
      if (manualOrder) {
        return manualOrder.graph;
      }
      return '';
    },

    // получаем дату
    date() {
      return ndToDateString(this.date_index);
    }

  },

  methods: {

    // нажата кнопка сохранить все измененные строчки
    async saveAllChangedRows(rows) {
      try {
        let updated_rows = await this.$store.dispatch('kvr/doEditManualOrderArr', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          manual_orders: rows,
        });
        updated_rows.forEach(row => {
          // обновляем строку
          // this.fillData(row);
          const i = this.manualOrdersArr.findIndex(manualOrder => manualOrder.manual_order_id == row.manual_order_id);
          if (i >= 0) {
            this.manualOrdersArr[i] = row;
          }
          // обновляем строку в оригинальном массиве
          const index = this.originalManualOrdersArr.findIndex(manualOrder => manualOrder.manual_order_id == row.manual_order_id);
          if (index >= 0) {
            this.originalManualOrdersArr[index] = row;
          }
        })
      } finally {
        this.isLoading = false;
        this.isEditAllEnable = false;
        this.isSaveAll = false;
      }
    },

    // нажата кнопка "восстановить наряд" или "применить наряд"
    async onClearManualOrder(plan_depot_list) {
      try {
        this.isLoading = true;
        let plan_depot_id_list = [];
        plan_depot_list.map(value => {
          plan_depot_id_list.push(value.depot_id);
        });
        if (this.op == 'clear') {
          // отправляем запрос на изменение наряда
          await this.$store.dispatch('kvr/doClearManualOrder', {
            date_index: dateToND(stringToDate(this.selectedOrderDate)),
            plan_depot_id_list: plan_depot_id_list,
          });
        } else if (this.op == 'apply') {
          // отправляем запрос на изменение наряда
          await this.$store.dispatch('kvr/doApplyManualOrder', {
            date_index: dateToND(stringToDate(this.selectedOrderDate)),
            plan_depot_id_list: plan_depot_id_list,
          });
        }
        await this.getManualOrders();
      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    // выключаем кнопку "Команда", в случае, если в списке редактируемых строк есть хоть одна строка,
    // включаем кнопку "Команда", в случае, если в списке редактируемых строк нет ни одной строки
    setCommandButton(value) {
      this.isCommandButtonDisabled = value;
    },

    // отменили операцию
    // Возвращаемся на исходную страницу
    onRejectOperation() {
      // // скрываем диалог
      this.$router.replace("/kvr/manualOrders");
    },

    // редактирование строки наряда
    async doEditRow(row) {
      try {
        this.isLoading = true;
        // преобразуем время
        row.time_index_begin = row.__time_index_begin + row.date_index * 24 * 60 * 60;
        row.time_index_end = row.__time_index_end + row.date_index * 24 * 60 * 60;
        // отправляем запрос на изменение наряда
        let updated_row = await this.$store.dispatch('kvr/doEditManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          manual_order_id: row.manual_order_id,
          value: row,
        });

        // обновляем строку
        // this.fillData(updated_row);
        const i = this.manualOrdersArr.findIndex(manualOrder => manualOrder.manual_order_id == updated_row.manual_order_id);
        if (i >= 0) {
          this.manualOrdersArr[i] = updated_row;
        }
        // обновляем строку в оригинальном массиве
        const index = this.originalManualOrdersArr.findIndex(manualOrder => manualOrder.manual_order_id == updated_row.manual_order_id);
        if (index >= 0) {
          this.originalManualOrdersArr[index] = updated_row;
        }

      } finally {
        this.isLoading = false;
      }
    },

    // Восстановление наряда по транспортному предприятию
    async onConfirmClearManualOrderByDepot() {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение наряда
        await this.$store.dispatch('kvr/doClearManualOrderByDepot', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          plan_depot_id: this.plan_depot_id,
        });
        await this.getManualOrders();
      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    // Восстановление наряда по маршруту
    async onConfirmClearManualOrderByRoute() {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение наряда
        await this.$store.dispatch('kvr/doClearManualOrderByDepotAndRoute', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          plan_depot_id: this.plan_depot_id,
          route_id: this.route_id
        });
        await this.getManualOrders();
      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    // Восстановление наряда по графику
    async onConfirmClearManualOrderByGraph() {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение наряда
        await this.$store.dispatch('kvr/doClearManualOrderByDepotAndRouteAndManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          plan_depot_id: this.plan_depot_id,
          route_id: this.route_id,
          manual_order_id: this.manual_order_id,
        });
        await this.getManualOrders();
      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    // Разделение наряда на работу
    async onSplitManualOrder(value) {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение наряда
        await this.$store.dispatch('kvr/doSplitManualOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          split_manual_order: value,
          manual_order_id: this.manual_order_id,
        });
        await this.getManualOrders();
      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    async getManualOrders() {
      let manualOrdersArr = await this.$store.dispatch('kvr/doFetchManualOrders', {
        // т.к. дата лежит в строке, конвертируем её сначала в Date, а потом в date_index (кол-во дней с 01.01.2000)
        date_index: dateToND(stringToDate(this.selectedOrderDate)),
        force: true
      })
      if (manualOrdersArr) {
        this.manualOrdersArr = manualOrdersArr;
        manualOrdersArr.forEach(manualOrder => {
          // this.fillData(manualOrder);
          this.originalManualOrdersArr.push({...manualOrder});
        })
      }
    },

    // нажата кнопка "Очистить всё"
    showDepotsForClear() {
      this.$router.push('/kvr/manualOrders/clear/' + dateToND(stringToDate(this.selectedOrderDate)));
    },

    // нажата кнопка "Передать наряд"
    showDepotsForApply() {
      this.$router.push('/kvr/manualOrders/apply/' + dateToND(stringToDate(this.selectedOrderDate)));
    },

    // Нажата кнопка "Разделить график"
    showSplitManualOrderDialog() {
      this.$router.replace('/kvr/manualOrders/split/' + this.selectedRow.manual_order_id);
    },

    // Нажата кнопка "Восстановить парк"
    showClearManualOrderByDepotDialog() {
      this.$router.replace('/kvr/manualOrders/clear/' + dateToND(stringToDate(this.selectedOrderDate)) + '/' + this.selectedRow.plan_depot_id);
    },

    // Нажата кнопка "Восстановить маршрут"
    showClearManualOrderByRouteDialog() {
      this.$router.replace('/kvr/manualOrders/clear/' + dateToND(stringToDate(this.selectedOrderDate)) + '/' + this.selectedRow.plan_depot_id + '/' + this.selectedRow.route_id);
    },

    // Нажата кнопка "Восстановить график"
    showClearManualOrderByGraphDialog() {
      this.$router.replace('/kvr/manualOrders/clear/' + dateToND(stringToDate(this.selectedOrderDate)) + '/' + this.selectedRow.plan_depot_id
          + '/' + this.selectedRow.route_id + '/' + this.selectedRow.manual_order_id);
    },

    fillData(manualOrder) {
      // // определяем ТС, (если есть)
      // let ts = getOrderVehicle(manualOrder.transport_id);
      // if (ts) {
      //   manualOrder.__garage_num = ts.garage_num;
      //   manualOrder.__state_num = ts.state_num;
      // }
      // // определяем водителя (если выбран)
      // let driver = getOrderDriver(manualOrder.driver_id);
      // if (driver) {
      //   manualOrder.__personnel_num = driver.personnel_num;
      //   manualOrder.__driver_name = driver.last_name + ' ' + driver.first_name.substring(0, 1) + '.' + driver.middle_name.substring(0, 1) + '.';
      // }

      // определяем фактический парк
      let fact_depot = getOrderDepot(manualOrder.fact_depot_id);
      if (fact_depot) {
        manualOrder.fact_depot_title = fact_depot.long_name;
      }
    },

    // нажата кнопка "Обновить"
    onTableRefresh() {
      // загружаем повторно наряды на работу
      this.downloadManualOrders();
    },

    // загрузка нарядов на работу
    async downloadManualOrders() {
      try {
        this.isLoading = true;
        this.selectedRow = null;
        this.isEditAllEnable = false;
        // this.manualOrdersArr = this.$store.getters['kvr/getManualOrders'];

        // делаем запрос доступных для формирования наряда транспортных средств
        await this.$store.dispatch('kvr/doFetchOrderVehicles', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
        });
        // загрузка доступных для формирования наряда водителей
        await this.$store.dispatch('kvr/doFetchOrderDrivers', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
        });
        this.requestedOrderDate = this.selectedOrderDate;

        // делаем запрос
        await this.getManualOrders();

      } finally {
        this.isLoading = false;
      }
    },

    // выбрана новая строка в таблице
    onRowSelect(selectedRow) {
      this.selectedRow = selectedRow;
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    onEditAllClick() {
      this.isEditAllEnable = true;
    },

    async onSaveAll() {
      this.isLoading = true;
      this.isSaveAll = true;
    },

    onCancelAll() {
      this.isEditAllEnable = false;
      this.manualOrdersArr = [];
      this.originalManualOrdersArr.forEach(value =>{
          this.manualOrdersArr.push({...value});
      });
    }

  },

  async created() {
    // загружаем предприятия
    await this.$store.dispatch('kvr/doFetchOrderDepots');
    // загружаем транспортные предприятия
    await this.$store.dispatch('kvr/doFetchOrderAgencies');
    // загрузка маршрутов
    await this.$store.dispatch('kvr/doFetchOrderRoutes');
    // загрузка организационно-правовых форм
    await this.$store.dispatch('ref/doFetchLegalForms');
    // загрузка типов транспорта
    await this.$store.dispatch('ref/doFetchTransportTypes');
  }

}
</script>

<style scoped>

</style>
