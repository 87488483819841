import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ФОРМАТОВ ФАЙЛОВ

export default {
    state() {
        return {
            // список объектов
            fileFormats: [],
            // время обновления кэша new Date().getTime()
            fileFormatsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            fileFormatsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getFileFormats(state) {
            return state.fileFormats;
        },
    },
    mutations: {
        // установить новый список объектов
        setFileFormats(state, payload) {
            state.fileFormats = payload;
            state.fileFormatsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchFileFormats: (context, payload) => {
            return DoGet(
                '/doc/fileFormats',
                'doc/setFileFormats',
                [],
                'Запрос форматов файлов',
                false,
                payload && payload.force,
                context.state.fileFormatsCache,
                context.state.fileFormatsCacheTimeout
            )
        },

        // очищает кэш
        doClearFileFormats: (context, payload) => {
            return DoClearCache(
                'multipass/setFileFormats',
                [],
                payload && payload.force,
                context.state.fileFormatsCache,
                context.state.fileFormatsCacheTimeout
            )
        },
    }
}