import {DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ЗОН ТАРИФНЫХ ЗОН

export default {

    actions: {

        // запрос объектов
        doFetchTariffZoneZones: (context, payload) => {
            return DoPost(
                '/multipass/tariffZoneZones',
                payload.tariff_zone_id_list,
                null,
                'Запрос зон тарифных зон',
                false,
            )
        },

        // запрос объекта
        doFetchTariffZoneZone: (context, payload) => {
            return DoGet(
                '/multipass/tariffZoneZones?tariff_zone_id=' + payload.tariff_zone_id,
                null,
                [],
                'Запрос зон тарифной зоны',
                false,
                true
            )
        },

        // установка объекта
        doSetUpTariffZoneZone: (context, payload) => {
            return DoPut(
                '/multipass/tariffZoneZones/'+payload.tariff_zone_id,
                payload.zone,
                null,
                'Изменение зоны тарифной зоны'
            )
        },
    }
}
