import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ВНЕШНИХ СИСТЕМ

export default {
    state() {
        return {
            // список объектов
            externalSystems: [],
            // время обновления кэша new Date().getTime()
            externalSystemsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            externalSystemsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getExternalSystems(state) {
            return state.externalSystems;
        },
    },
    mutations: {
        // установить новый список объектов
        setExternalSystems(state, payload) {
            state.externalSystems = payload;
            state.externalSystemsCache = new Date().getTime()
        },
    },
    actions: {

        // запрос списка объектов
        doFetchExternalSystems: (context, payload) => {
            return DoGet(
                '/integration/externalSystems',
                'integration/setExternalSystems',
                [],
                'Запрос внешних систем',
                false,
                payload && payload.force,
                context.state.externalSystemsCache,
                context.state.externalSystemsCacheTimeout
            )
        },

        // запрос списка объектов
        doFetchExternalSystem: (context, payload) => {
            return DoGet(
                '/integration/externalSystems/' + payload.external_system_id,
                'integration/setExternalSystems',
                [],
                'Запрос внешней системы',
                false,
                payload && payload.force,
                context.state.externalSystemsCache,
                context.state.externalSystemsCacheTimeout
            )
        },
    }
}