<template>
  <confirm-message-box
      @onCancel="$emit('onCancel')"
      @onOK="delItem"
  ><p>Списать транспортное средство c кодом {{ id }} ?</p>
    <form class="form-inline">
      <label for="discardDate" class="mr-2">Дата списания:</label>
      <input-date
          class="form-control"
          id="discardDate"
          v-model="discardDate"
          ref="discardDate"
          required
          error="Введите дату списания"
      />
    </form>
  </confirm-message-box>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import {dateToND, dateToString} from "@/lib";

export default {
  emits: ['onCancel', 'onOK'],
  props: ['id'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      discardDate: dateToString()
    }
  },
  methods: {
    delItem() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          transport_id: this.id,
          date_index_begin: dateToND(new Date(this.discardDate))
        })
      }
    }
  },
}
</script>
