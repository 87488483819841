<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-nav-sensor-groups"> </edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "sensor_group_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "sensor_group_name",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },

      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["nav/getSensorGroups"].map(sensorGroup => {
        return sensorGroup;
      });
    },
  }
};
</script>
