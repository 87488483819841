<template>
  <edit-table-panel :columnList="columnList" :rowList="rows" :disableSettings="true" name="tbl-multipass-trip-type-zones"> </edit-table-panel>
</template>

<script>

export default {
  props: ['isLoading', 'rowList'],
  computed: {
    columnList() {
      return [
        {
          field: "trip_type_zone_id",
          caption: "Код",
          sortType: 'number',
          isSortable: false,
        },
        {
          field: "zone.zone_title",
          caption: "Наименование",
          sortType: 'string',
          isSortable: false,
          displayFn(row) {
            return row.zone.zone_title
          }
        },
      ]
    },
    rows() {
      if (this.isLoading === 'fetch') {
        return []
      }
      return this.rowList.map(zone => {
        zone.__id = zone.trip_type_zone_id;
        return zone
      });
    }
  },
};
</script>
