<template>

  <!-- html -->
  <td
      class="text-nowrap"
      :style="{...(col.footerCellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-if="col.footerDisplayType === 'html' && col.footerDisplayFn"
      :class="{'text-left': col.footerAlign === 'left', 'text-right': col.footerAlign === 'right', 'text-center': col.footerAlign === 'center'}"
  ><span v-html="col.footerDisplayFn(row)"></span></td>


  <td
      class="text-nowrap"
      :style="{...(col.footerCellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.footerDisplayType === 'html'"
      :class="{'text-left': col.footerAlign === 'left', 'text-right': col.footerAlign === 'right', 'text-center': col.footerAlign === 'center'}"
  ><span v-html="row[col.field]"></span></td>

  <!-- text -->
  <td
      class="text-nowrap"
      :style="{...(col.footerCellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.footerDisplayType === 'text' && col.footerDisplayFn"
      :class="{'text-left': col.footerAlign === 'left', 'text-right': col.footerAlign === 'right', 'text-center': col.footerAlign === 'center'}"
  ><span>{{ col.footerDisplayFn(row, rowIndex) }}</span></td>

  <td
      class="text-nowrap"
      :style="{...(col.footerCellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.footerDisplayType === 'text'"
      :class="{'text-left': col.footerAlign === 'left', 'text-right': col.footerAlign === 'right', 'text-center': col.footerAlign === 'center'}"
  ><span>{{ row[col.field] }}</span></td>

</template>

<script>
export default {
  props: [
      // колонка
      'col',
      // строка
      'row',
  ],
}
</script>
