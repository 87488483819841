
import ReportsKvrExpertStore from "@/store/kvr_expert/ReportsKvrExpertStore";
import ResultTripsKvrExpertStore from "@/store/kvr_expert/ResultTripsKvrExpertStore";
import CommandReasonsKvrExpertStore from "@/store/kvr_expert/CommandReasonsKvrExpertStore";
import CommandsKvrExpertStore from "@/store/kvr_expert/CommandsKvrExpertStore";
import ExpertsKvrExpertStore from "@/store/kvr_expert/ExpertsKvrExpertStore";
import ExecCommandKvrExpertStore from "@/store/kvr_expert/ExecCommandKvrExpertStore";
import CommandLogsKvrExpertStore from "@/store/kvr_expert/CommandLogsKvrExpertStore";

//  ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ KVR-EXPERT

export default {
    namespaced: true,
    modules:{
        ReportsKvrExpertStore,
        ResultTripsKvrExpertStore,
        CommandReasonsKvrExpertStore,
        CommandsKvrExpertStore,
        ExpertsKvrExpertStore,
        ExecCommandKvrExpertStore,
        CommandLogsKvrExpertStore,
    }

}