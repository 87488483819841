<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable modal-lg">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>{{caption}}</modal-title>
          <button-close class="text-white" @click="$emit('onClose')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <!-- Таблица  -->
            <div class="table-container">
              <edit-table-panel :headerVisible="false" :columnList="columnList" :rowList="rowList" name="tbl-rmt-errors"></edit-table-panel>
            </div>
          </form>
        </modal-body>
        <modal-footer>
          <button @click="$emit('onClose')" class="btn btn-primary">Закрыть</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

export default {
  props: ['rowList', 'caption'],
  emits: ['onClose'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "error_message",
          caption: "Ошибка",
          sortType: 'string',
          isFilterable: false,
          isSortable: false
        },
      ],
    };
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}

.table-container{
  height: 400px;
}
</style>