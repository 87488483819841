<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Рейс</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>
                <div class="row">
                  <div class="form-group col">
                    <label for="tripTypeCompositeId">Код композита:</label>
                    <input-integer
                        id="tripTypeCompositeId"
                        class="form-control w-25"
                        v-model="tripTypeCompositeId"
                        readonly
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="routeId">Маршрут:</label>
                  <routes-select
                      id="routeId"
                      ref="routeId"
                      class="form-control"
                      v-model="routeId"
                      @change="getRouteVariants"
                      required
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="scheduleVariantId">Вариант:</label>
                  <route-variants-select
                      id="scheduleVariantId"
                      ref="scheduleVariantId"
                      class="form-control"
                      v-model="routeVariantId"
                      caption="Вариант"
                      :rows="routeVariants"
                      @change="getTripTypes"
                      required
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="tripTypeId">Тип рейса:</label>
                  <req-field/>
                  <input-select
                      id="tripTypeId"
                      class="form-control"
                      v-model="tripTypeId"
                      ref="tripTypeId"
                      caption="Выберите тип рейса..."
                      @change="getStops"
                      :itemList=tripTypes
                      :readonly="op === 'show'"
                      required
                      error="Выберите тип рейса"
                  />
                </div>

                <div class="form-group">
                  <label for="orderNum">Порядковый номер:</label>
                  <req-field/>
                  <input-integer
                      id="orderNum"
                      v-model="orderNum"
                      class="form-control"
                      ref="orderNum"
                      autocomplete="off"
                      required
                      readonly
                      error="Введите краткое наименование"
                  />
                </div>

                <div class="form-group">
                  <label for="tripTypeOrderNumBegin">Начало высадки:</label>
                  <input-select
                      id="tripTypeOrderNumBegin"
                      class="form-control"
                      v-model="tripTypeOrderNumBegin"
                      ref="tripTypeOrderNumBegin"
                      caption="Выберите остановочный пункт..."
                      :itemList=stops
                      :readonly="op === 'show'"
                      error="Выберите начало высадки"
                  />
                </div>

                <div class="form-group">
                  <label for="tripTypeOrderNumEnd">Конец высадки:</label>
                  <input-select
                      id="tripTypeOrderNumEnd"
                      class="form-control"
                      v-model="tripTypeOrderNumEnd"
                      ref="tripTypeOrderNumEnd"
                      caption="Выберите остановочный пункт..."
                      :itemList=stops
                      :readonly="op === 'show'"
                      error="Выберите конец высадки"
                  />
                </div>

              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import RoutesSelect from "@/components/multipass/routes/RoutesSelect";
import RouteVariantsSelect from "@/components/multipass/route_variants/RouteVariantsSelect";
import {getStop} from "@/store/MultipassHelpers";
import {getSignTitle, signSortFn} from "@/lib";

export default {
  components: {RouteVariantsSelect, RoutesSelect},
  props: ['item', 'op', 'order', 'trip_type_composite_id'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      tripTypeCompositeId: this.trip_type_composite_id,
      tripTypeId: this.item.trip_type_id,
      orderNum: this.item.order_num ? this.item.order_num : this.order,
      tripTypeOrderNumBegin: this.trip_type_order_num_begin,
      tripTypeOrderNumEnd: this.trip_type_order_num_end,

      routeId: null,
      routeVariantId: null,
      routeVariants: [],
      tripTypes: [],
      stops: [],

      //загрузка
      isLoading: false,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {

        this.$emit('onOK', {
          trip_type_composite_id: this.tripTypeCompositeId,
          trip_type_id: this.tripTypeId,
          order_num: this.orderNum,
          trip_type_order_num_begin: this.tripTypeOrderNumBegin,
          trip_type_order_num_end: this.tripTypeOrderNumEnd,

        })
      }
    },

    // выбран маршрут, запрашиваем варианты маршрута, при этом очищаем массивы типами рейса и остановками
    async getRouteVariants() {
      // отображаем загрузку
      this.isLoading = true;
      // получаем варианты маршрута
      this.routeVariants = await this.$store.dispatch('multipass/doFetchRouteVariants', {
        route_id: this.routeId
      });
      this.routeVariants.sort(function (a, b) {
        return (new Date(a.date_begin) - new Date(b.date_begin))
      });
      this.routeVariants.reverse();

      // очищаем выбранный вариант маршрута
      this.routeVariantId = null;
      // очищаем массив типов рейса
      this.tripTypes = [];
      // сбрасываем выбранный tripType
      this.tripTypeId = null;
      // очищаем массив остановок
      this.stops = [];
      // сбрасываем выбранные остановки начала и конца рейса
      this.tripTypeOrderNumBegin = null;
      this.tripTypeOrderNumEnd = null;
      // скрываем загрузку
      this.isLoading = false;
    },

    async getTripTypes() {

      // отображаем загрузку
      this.isLoading = true;

      let ttypes = await this.$store.dispatch('multipass/doFetchTripTypes', {
        route_variant_id: this.routeVariantId
      });

      ttypes.sort(function (a, b) {
        return signSortFn(getSignTitle(a.trip_type_sign), getSignTitle(b.trip_type_sign))
      });

      this.tripTypes = ttypes.map(item => {
        return {
          id: item.trip_type_id,
          title: getSignTitle(item.trip_type_sign),
        }
      });
      // сбрасываем выбранный tripType
      this.tripTypeId = null;
      // очищаем массив остановок
      this.stops = [];
      // сбрасываем выбранные остановки начала и конца рейса
      this.tripTypeOrderNumBegin = null;
      this.tripTypeOrderNumEnd = null;

      // скрываем загрузку
      this.isLoading = false;

    },

    async getStops() {

      // отображаем загрузку
      this.isLoading = true;

      let stops = await this.$store.dispatch('multipass/doFetchTripTypeCard', {
        trip_type_id: this.tripTypeId
      });
      let i = 0;
      this.stops = stops.map(item => {
        let stop = getStop(item.stop_id);
        i++;
        return {
          id: stop.stop_id,
          title: i + '-' + stop.long_name,
        }
      });
      // сбрасываем выбранные остановки начала и конца рейса
      this.tripTypeOrderNumBegin = null;
      this.tripTypeOrderNumEnd = null;

      // скрываем загрузку
      this.isLoading = false;

    }
  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>