import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ЭФИРНЫХ ПРОТОКОЛОВ

export default {
    state() {
        return {
            // список объектов
            radioProtocols: [],
            // время обновления кэша new Date().getTime()
            radioProtocolsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            radioProtocolsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRadioProtocols(state) {
            return state.radioProtocols;
        }
    },
    mutations: {
        // установить новый список объектов
        setRadioProtocols(state, payload) {
            state.radioProtocols = payload;
            state.radioProtocolsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchRadioProtocols: (context, payload) => {
            return DoGet(
                '/nav/radioProtocols',
                'nav/setRadioProtocols',
                [],
                'Запрос эфирных протоколов',
                false,
                payload && payload.force,
                context.state.radioProtocolsCache,
                context.state.radioProtocolsCacheTimeout
            )
        },

        // запрос объекта
        doFetchRadioProtocol: (context, payload) => {
            return DoGet(
                '/nav/radioProtocols/' + payload.radio_protocol_id,
                null,
                [],
                'Запрос эфирного протокола',
                false,
                payload && payload.force,
                context.state.radioProtocolsCache,
                context.state.radioProtocolsCacheTimeout
            )
        },

        // очищает кэш
        doClearRadioProtocols: (context, payload) => {
            return DoClearCache(
                'nav/setRadioProtocols',
                [],
                payload && payload.force,
                context.state.radioProtocolsCache,
                context.state.radioProtocolsCacheTimeout
            )
        },

    }
}