<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Маршрут</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <nav-menu class="nav-tabs" mode="single">
            <nav-item>
              <nav-link target-ref="pane1" target-method="select" active>Основные</nav-link>
            </nav-item>
            <nav-item>
              <nav-link target-ref="pane2" target-method="select">Дополнительные</nav-link>
            </nav-item>
          </nav-menu>
          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>
                <div class="row">

                  <div class="form-group col">
                    <label for="routeId">Код:</label>
                    <input-integer
                        id="routeId"
                        class="form-control"
                        v-model="routeId"
                        readonly
                    />
                  </div>

                  <div class="form-group col">
                    <label for="regNum">Рег.номер:</label>
                    <input-string
                        id="regNum"
                        v-model="regNum"
                        class="form-control"
                        ref="regNum"
                        :readonly="op === 'show'"
                    />
                  </div>

                </div>

                <div class="form-group">
                  <label for="routeNum">Номер маршрута:</label><req-field/>
                  <input-string
                      id="routeNum"
                      v-model="routeNum"
                      class="form-control"
                      ref="routeNum"
                      :readonly="op === 'show'"
                      required
                      error="Введите номер маршрута"
                  />
                </div>

                <div class="form-group">
                  <label for="routeTitle">Наименование маршрута:</label><req-field />
                  <input-string
                      id="routeTitle"
                      v-model="routeTitle"
                      class="form-control"
                      ref="routeTitle"
                      :readonly="op === 'show'"
                      required
                      error="Введите наименование маршрута"
                  />
                </div>

                <div class="form-group">
                  <label for="transportTypeId">Вид транспорта:</label><req-field />
                  <transport-types-select
                      id="transportTypeId"
                      v-model="transportTypeId"
                      class="form-control"
                      ref="transportTypeId"
                      :readonly="op === 'show'"
                      required
                      error="Выберите вид транспорта"
                  />
                </div>

                <div class="row">

                  <div class="form-group col">
                    <label for="openDate">Дата открытия:</label><ReqField />
                    <input-date
                        id="openDate"
                        v-model="openDate"
                        class="form-control"
                        ref="openDate"
                        :readonly="op === 'show'"
                        required
                        error="Укажите дату открытия маршрута"
                    />
                  </div>

                  <div class="form-group col">
                    <label for="closeDate">Дата закрытия:</label>
                    <input-date
                        id="closeDate"
                        v-model="closeDate"
                        class="form-control"
                        ref="closeDate"
                        :readonly="op === 'show'"
                    />
                  </div>

                </div>

                <div class="form-group">
                  <input-checkbox
                      id="isSocial"
                      v-model="isSocial"
                      :readonly="op === 'show'"
                  >Социальный маршрут</input-checkbox>
                </div>

              </form>
            </tab-pane>
            <tab-pane ref="pane2" class="p-2">
              <form>

                <div class="form-group">
                  <label for="routeKindId">Вид маршрута:</label>
                  <route-kinds-select
                      id="routeKindId"
                      v-model="routeKindId"
                      class="form-control"
                      ref="routeKindId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="routeTypeId">Тип маршрута:</label>
                  <route-types-select
                      id="routeTypeId"
                      v-model="routeTypeId"
                      class="form-control"
                      ref="routeTypeId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="transferKindId">Вид сообщения:</label>
                  <transfer-kinds-select
                      id="transferKindId"
                      v-model="transferKindId"
                      class="form-control"
                      ref="transferKindId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="areaClassifierId">Код ОКАТО (ОКТМО):</label>
                  <input-okato
                      id="areaClassifierId"
                      v-model="areaClassifierId"
                      class="form-control"
                      ref="areaClassifierId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="note">Примечание:</label>
                  <input-string
                      id="note"
                      v-model="note"
                      class="form-control"
                      ref="note"
                      :readonly="op === 'show'"
                  />
                </div>

              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import TransportTypesSelect from "@/components/ref/transport_types/TransportTypesSelect";
import RouteKindsSelect from "@/components/multipass/route_kinds/RouteKindsSelect";
import RouteTypesSelect from "@/components/multipass/route_types/RouteTypesSelect";
import TransferKindsSelect from "@/components/multipass/transfer_kinds/TransferKindsSelect";
import InputInteger from "@/components/ui/custom/InputInteger";

export default {
  components: {
    InputInteger,
    TransferKindsSelect, RouteTypesSelect, RouteKindsSelect, TransportTypesSelect, ReqField},
  props: ['item', 'op'],
    emits: ['onOK', 'onCancel'],
    mixins: [ValidateFormMixin],
    data() {
        return {
          routeId: this.item.route_id,
          routeNum: this.item.route_num,
          routeTitle: this.item.route_title,
          regNum: this.item.reg_num,
          transportTypeId: this.item.transport_type_id,
          routeKindId: this.item.route_kind_id,
          routeTypeId: this.item.route_type_id,
          transferKindId: this.item.transfer_kind_id,
          isSocial: this.item.is_social,
          openDate: this.item.open_date,
          closeDate: this.item.close_date,
          note: this.item.note,
          areaClassifierId: this.item.area_classifier_id,
        };
    },
    methods: {
        onSubmit() {
          if (this.validateForm(this.$refs)) {

            // проверяем дату закрытия
            if (this.openDate && this.closeDate && this.closeDate < this.openDate) {
              this.$refs.closeDate.alarm('Дата закрытия не может быть меньше даты открытия.');
              return
            }

            this.$emit('onOK', {
              route_id: this.op === 'add' ? undefined : this.routeId,
              route_num: this.routeNum,
              route_title: this.routeTitle,
              reg_num: this.regNum,
              transport_type_id: this.transportTypeId,
              route_kind_id: this.routeKindId,
              route_type_id: this.routeTypeId,
              transfer_kind_id: this.transferKindId,
              is_social: this.isSocial,
              open_date: this.openDate,
              close_date: this.closeDate,
              note: this.note,
              area_classifier_id: this.areaClassifierId
            })
          }
        },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>