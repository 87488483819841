import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ГРУПП ИЗМЕРЯЕМЫХ ПАРАМЕТРОВ

export default {
    state() {
        return {
            // список объектов
            paramGroups: [],
            // время обновления кэша new Date().getTime()
            paramGroupsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            paramGroupsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getParamGroups(state) {
            return state.paramGroups;
        }
    },
    mutations: {
        // установить новый список объектов
        setParamGroups(state, payload) {
            state.paramGroups = payload;
            state.paramGroupsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchParamGroups: (context, payload) => {
            return DoGet(
                '/nav/paramGroups',
                'nav/setParamGroups',
                [],
                'Запрос групп параметров',
                false,
                payload && payload.force,
                context.state.paramGroupsCache,
                context.state.paramGroupsCacheTimeout
            )
        },

        // запрос объекта
        doFetchParamGroup: (context, payload) => {
            return DoGet(
                '/nav/paramGroups/' + payload.param_group_id,
                null,
                [],
                'Запрос группы параметров',
                false,
                payload && payload.force,
                context.state.paramGroupsCache,
                context.state.paramGroupsCacheTimeout
            )
        },

        // очищает кэш
        doClearParamGroups: (context, payload) => {
            return DoClearCache(
                'nav/setParamGroups',
                [],
                payload && payload.force,
                context.state.paramGroupsCache,
                context.state.paramGroupsCacheTimeout
            )
        },

    }
}