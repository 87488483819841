<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Остановочный павильон</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <nav-menu class="nav-tabs" mode="single">
            <nav-item>
              <nav-link target-ref="pane1" target-method="select" active>Основные</nav-link>
            </nav-item>
            <nav-item>
              <nav-link target-ref="pane2" target-method="select">Дополнительные</nav-link>
            </nav-item>
          </nav-menu>
          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>

                <div class="row">

                  <div class="form-group col">
                    <label for="stopPavilionId">Код:</label>
                    <input-integer
                        id="stopPavilionId"
                        type="number"
                        v-model="stopPavilionId"
                        class="form-control w-25"
                        readonly
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="stopPavilionKindId">Вид остановочного павильона:</label>
                  <stop-pavilion-kinds-select
                      id="stopPavilionKindId"
                      v-model="stopPavilionKindId"
                      class="form-control"
                      ref="stopPavilionKindId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="stopPavilionTypeId">Тип остановочного павильона:</label>
                  <stop-pavilion-types-select
                      id="stopPavilionTypeId"
                      v-model="stopPavilionTypeId"
                      class="form-control"
                      ref="stopPavilionTypeId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="longName">Полное наименование:</label>
                  <input-string
                      id="longName"
                      v-model="longName"
                      class="form-control"
                      ref="longName"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="shortName">Краткое наименование:</label>
                  <input-string
                      id="shortName"
                      v-model="shortName"
                      class="form-control"
                      ref="shortName"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="latitude">Широта:</label>
                  <input-float
                      id="latitude"
                      v-model="latitude"
                      class="form-control"
                      ref="latitude"
                      :readonly="op === 'show'"
                      :min-value="-90"
                      :max-value="90"
                      error="Введите широту от -90 до 90"
                  />
                </div>

                <div class="form-group">
                  <label for="longitude">Долгота:</label>
                  <input-float
                      id="longitude"
                      v-model="longitude"
                      class="form-control"
                      ref="longitude"
                      :readonly="op === 'show'"
                      :min-value="-180"
                      :max-value="180"
                      error="Введите долготу от -180 до 180"
                  />
                </div>


              </form>
            </tab-pane>
            <tab-pane ref="pane2" class="p-2">
              <form>
                <div class="row">
                  <div class="form-group col">
                    <label for="length">Длина:</label>
                    <input-float
                        id="length"
                        v-model="length"
                        class="form-control"
                        ref="length"
                        :readonly="op === 'show'"
                        :min-value="0"
                    />
                  </div>

                  <div class="form-group col">
                    <label for="width">Ширина:</label>
                    <input-float
                        id="width"
                        v-model="width"
                        class="form-control"
                        ref="width"
                        :readonly="op === 'show'"
                        :min-value="0"
                    />
                  </div>

                  <div class="form-group col">
                    <label for="height">Высота:</label>
                    <input-float
                        id="height"
                        v-model="height"
                        class="form-control"
                        ref="height"
                        :readonly="op === 'show'"
                        :min-value="0"
                    />
                  </div>

                </div>
                <div class="form-group">
                  <label for="insideAreaUseful">Площадь для пассажиров:</label>
                  <input-float
                      id="insideAreaUseful"
                      v-model="insideAreaUseful"
                      class="form-control"
                      ref="insideAreaUseful"
                      :readonly="op === 'show'"
                      :min-value="0"
                  />
                </div>

                <div class="form-group">
                  <label for="totalArea">Общая площадь:</label>
                  <input-integer
                      id="totalArea"
                      v-model="totalArea"
                      class="form-control"
                      ref="totalArea"
                      :readonly="op === 'show'"
                      :min-value="0"
                  />
                </div>

                <input-checkbox
                    id="isVideoRecordSystem"
                    v-model="isVideoRecordSystem"
                    :readonly="op === 'show'"
                >Система видеонаблюдения
                </input-checkbox>

                <input-checkbox
                    id="isMultimedia"
                    v-model="isMultimedia"
                    :readonly="op === 'show'"
                >Мультимедийная система
                </input-checkbox>

                <input-checkbox
                    id="isEboard"
                    v-model="isEboard"
                    :readonly="op === 'show'"
                >Информационное табло
                </input-checkbox>

                <input-checkbox
                    id="isHandicapped"
                    v-model="isHandicapped"
                    :readonly="op === 'show'"
                >Адаптирован для маломобильных групп
                </input-checkbox>

              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputFloat from "@/components/ui/custom/InputFloat";
import InputString from "@/components/ui/custom/InputString";
import InputInteger from "@/components/ui/custom/InputInteger";
import StopPavilionKindsSelect from "@/components/multipass/stop_pavilion_kinds/StopPavilionKindsSelect";
import StopPavilionTypesSelect from "@/components/multipass/stop_pavilion_types/StopPavilionTypesSelect";

export default {
  components: {
    StopPavilionTypesSelect,
    StopPavilionKindsSelect,
    InputInteger, InputString, InputFloat
  },
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      stopPavilionId: this.item.stop_pavilion_id,
      stopPavilionKindId: this.item.stop_pavilion_kind_id,
      stopPavilionTypeId: this.item.stop_pavilion_type_id,
      shortName: this.item.short_name,
      longName: this.item.long_name,
      latitude: this.item.latitude,
      longitude: this.item.longitude,
      length: this.item.length,
      width: this.item.width,
      height: this.item.height,
      insideAreaUseful: this.item.inside_area_useful,
      totalArea: this.item.total_area,
      isVideoRecordSystem: this.item.is_video_record_system,
      isMultimedia: this.item.is_multimedia,
      isEboard: this.item.is_eboard,
      isHandicapped: this.item.is_handicapped,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          stop_pavilion_id: this.stopPavilionId,
          stop_pavilion_kind_id: this.stopPavilionKindId,
          stop_pavilion_type_id: this.stopPavilionTypeId,
          short_name: this.shortName,
          long_name: this.longName,
          latitude: this.latitude,
          longitude: this.longitude,
          length: this.length,
          width: this.width,
          height: this.height,
          inside_area_useful: this.insideAreaUseful,
          total_area: this.totalArea,
          is_video_record_system: this.isVideoRecordSystem,
          is_multimedia: this.isMultimedia,
          is_eboard: this.isEboard,
          is_handicapped: this.isHandicapped,
        })
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>