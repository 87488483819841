<template>
  <leaflet-marker
      v-if="isCorrect"
      :latlng="latlng"
      :options="options"
      :icon="icon"
      :tooltip="tooltip"
      :events="events"
      :targetComponent="this"
      :logTitle="vehicleTitle"
      @onLeafletEvent="onLeafletEvent($event)"
  ></leaflet-marker>
</template>

<script>
// МАРКЕР ТРАНСПОРТНОГО СРЕДСТВА

// interface CUR_NAV {
//   transport_id: number;
//   transport_type_id: number;
//   transport_type_title: string;
//   depot_id: number;
//   depot_title: string;
//   model_id: number;
//   model_title: string;
//   transport_class_id: number;
//   transport_class_title: string;
//   state_num: string;
//   garage_num: string;
//   special_header: string;
//   radio_id: number;
//   radio_num: string;
//   radio_model_id: number;
//   radio_model_title: string;
//   time_nav: string;
//   time_insert: string;
//   latitude: number;
//   longitude: number;
//   speed: number;
//   angle: number;
//   is_invalid: boolean;
//   is_history: boolean;
//   is_ign: boolean;
//   is_call: boolean;
//   is_sos: boolean;
// }

import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";
import {divIcon, Point, Tooltip } from "leaflet";
import {getTimeFormatFromDateString} from "@/lib";

export default {
  mixins: [LeafletMapMixin, LeafletEventedMixin],
  props: {
    // тип транспортного средства: 1 - (автобусы - по-умолчанию), 2 - (троллейбусы), 3 - (трамваи), (прочее)
    type: {
      required: false
    },
    // цвет транспортного средства (0..10)
    color: {
      default: 0
    },
    // описание транспортного средства
    vehicle: {
      type: Object,
    },
    // Заголовок транспортного средства
    title: {
      type: String,
      required: false
    },
    // тип заголовка:
    // 'auto'     - автоматическое формирование заголовка: title -> vehicle['special_header'] -> (vehicle['garage_num'] | vehicle['state_num'])
    // 'garage'   - vehicle['garage_num']
    // 'state'    - vehicle['state_num']
    // 'timenav'  - vehicle['time_nav']
    // 'special'  - vehicle['special_header']
    // 'none'     - не отображать подсказку
    titleType: {
      type: String,
      default: 'auto'
    },
    // какой номер имеет приоритет:
    // 'garage' - гаражный номер
    // 'state'  - государственный номер
    priorityNum: {
      type: String,
      default: 'garage'
    }
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'VehicleMarker',
    }
  },
  computed: {
    // признак корректности
    isCorrect() {
      return this.icon && this.vehicle && Number.isFinite(this.vehicle.latitude) && Number.isFinite(this.vehicle.longitude)
    },
    // опции
    options() {
      return {
        zIndexOffset: 3000
      }
    },
    // иконка
    icon() {
      return divIcon({
        html: '',
        iconSize: [60, 60],
        iconAnchor: [30, 30],
        tooltipAnchor: [8, 0],
        shadowSize: [0, 0],
        shadowAnchor: [0, 0],
        popupAnchor: [0, -15],
        bgPos: [this.angleIndex, this.yIndex * 60],
        className: 'vehicle-icon'
      })
    },
    // координаты точки
    latlng() {
      return [this.vehicle.latitude, this.vehicle.longitude];
    },
    // подсказка
    tooltip() {
      if (this.titleType === 'none') {
        return null
      } else {
        const t = new Tooltip({
          direction: 'bottom',
          permanent: true,
          offset: new Point(-6, 10),
          opacity: 1,
          className: 'vehicle-tooltip'
        })
        t.setContent(this.vehicleTitle)
        return t
      }
    },
    // формируем заголовок транспортного средства
    vehicleTitle() {
      if (this.titleType === 'timenav') {
        return getTimeFormatFromDateString(this.vehicle['time_nav'])
      } else if (this.titleType === 'garage') {
        return this.vehicle['garage_num'] || ''
      } else if (this.titleType === 'state') {
        return this.vehicle['state_num'] || ''
      } else if (this.titleType === 'special') {
        return this.vehicle['special_header'] || ''
      } else if(this.titleType === 'none') {
          return null;
      } else {
        if (this.priorityNum === 'state') {
          return this.title || this.vehicle['special_header'] || this.vehicle['state_num'] || ''
        } else {
          return this.title || this.vehicle['special_header'] || this.vehicle['garage_num'] || ''
        }
      }
    },
    // смещение иконки по оси Y
    yIndex() {
      let type = this.type ? this.type : this.vehicle.transport_type_id
      type = (type && Number(type) > 0 && Number(type) < 4) ? Number(type) : 0
      const color = Number(this.color > 0) && Number(this.color < 11) ? Number(this.color) : 0;
      return type * 11 + color
    },
    // угол поворота
    angle() {
      return this.vehicle.angle && Number.isFinite(this.vehicle.angle) && this.vehicle.angle >= 0 ? this.vehicle.angle % 360 : 0
    },
    // угол поворота в виде индекса
    angleIndex() {
      return (Math.floor(this.angle / 15) * 15) * 60 / 15
    },
  },
  methods: {
    // события маркера
    onLeafletEvent(event) {
      // проверяем и вызываем внешние события
      if (this.eventsToArray(this.events).includes(event.type)) {
        this.$emit('onLeafletEvent', event);
      }
    },
  },
};

</script>

<style>
/* Иконка транспортного средства */
.vehicle-icon {
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: url("/src/components/ui/leaflet/img/vehicle.png");
}

.vehicle-tooltip {
  padding: 1px;
  font-size: x-small;
  border: black 0px solid;
}

.vehicle-tooltip:before,
.vehicle-tooltip:before,
.vehicle-tooltip:before,
.vehicle-tooltip:before {
  border: none !important;
}
</style>
