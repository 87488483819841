import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ КАРТОЧЕК ФАКТИЧЕКОГО РЕЙСА

export default {

    actions: {
        // запрос списка для диалога на странице "Информация по маршруту"
        doFetchTripCardByFactTripId: (context, payload) => {
            return DoGet(
                '/kvr/getTripCard/' + payload.date_index + '?fact_trip_id=' + payload.fact_trip_id,
                null,
                [],
                'Запрос карточки фактического рейса',
                false,
                true
            );
        },

        // запрос списка для диалога на странице "Информация по ТС"
        doFetchTripCardByPlanTripId: (context, payload) => {
            return DoGet(
                '/kvr/getTripCard/' + payload.date_index + '?plan_trip_id=' + payload.plan_trip_id,
                null,
                [],
                'Запрос карточки фактического рейса',
                false,
                true
            );
        },
    }
}