<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/ref">Справочники</router-link></span>
        <span> / </span>
        <span>Транспортные предприятия</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: true, delete: false, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onEdit="onTableEdit"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Группы-->
            <template #leftCustomButton2>
              <modules-select class="form-control" v-model="moduleId" @change="moduleChange"></modules-select>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <main-params-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :moduleId="moduleId"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
          ></main-params-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <main-param-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </main-param-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import MainParamDialog from "@/components/system/main_params/MainParamDialog";
import MainParamsEditTable from "@/components/auth/main_params/MainParamsEditTable";
import ModulesSelect from "@/components/system/modules/ModulesSelect";

export default {
  mixins: [PageMixin],

  components: {
    ModulesSelect,
    MainParamsEditTable,
    MainParamDialog,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      moduleId: null,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }
  },

  computed: {

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: false,
        edit: !!this.selectedRow,
        delete: false,
        refresh: true
      }
    },

    // условие отображения диалогового окна
   isDialogVisible() {
      return (this.op === 'edit');
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // ссылка для перехода к группам ТС
    depotGroupLink() {
      if (this.selectedRow) return '/admin/depots/' + this.selectedRow.depot_id+'/depotGroupLinks'
      else return '';
    },

    // ссылка для перехода к реквизитам транпортных предприятий
    depotDetailsLink() {
      if (this.selectedRow) return '/ref/depots/' + this.selectedRow.depot_id +'/depotDetails'
      else return '';
    },

    // данные, которые отображаются в диалоговом окне
    dialogRow() {
      return this.selectedRow;
    },
  },
  methods: {

    moduleChange() {
      this.selectedRow = null;
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/admin/mainParams/edit");
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/admin/mainParams");
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/admin/mainParams");
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = "fetch";
      try {

        await this.$store.dispatch('system/doFetchModules');
        await this.$store.dispatch('system/doFetchMainParams');

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('system/doEditMainParam', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

  },
  // вызывается при создании компонента
  created() {

    console.log(12333333333333333333)
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
