<template>
  <transition name="slide">
    <div class="report-panel overflow-hidden" v-if="isReportsShow" v-click-away="onAutoHide">
      <reporter-dialog
          :rowList="rowList"
          @setMenu="setMenu"
      ></reporter-dialog>
    </div>
  </transition>
</template>

<script>
import {directive} from "vue3-click-away";
import ReporterDialog from "@/components/reporter/ReporterDialog";

export default {
  components: {ReporterDialog},
  directives: {
    ClickAway: directive
  },
  data() {
    return {
      // определяем текущий путь, для определения текущего раздела
      curMenu: null,
      // список строк со сформированными отчетами,
      rowList: [],
      // текущий путь
      path: '',
    }
  },

  computed: {

    // условие отображения панели "Сформированные отчеты"
    isReportsShow() {

      if(this.$store.getters.getIsReportsShow) {
        this.setMenu();
        if (this.rowList.length == 0){
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  },

  methods: {

    // убирать окно при щелчке в другое место (пока не будем это делать)
    onAutoHide() {
      //this.$store.commit('hideLeftMenu');
    },

    async setMenu() {

      if (this.path.substring(0, 4) === '/ref') {
        this.rowList = this.$store.getters["reporter/getRefReports"]
      } else if (this.path.substring(0, 10) === '/multipass') {
        this.rowList = this.$store.getters["reporter/getMultipassReports"]
      } else if (this.path.substring(0, 4) === '/rmt') {
        this.rowList = this.$store.getters["reporter/getRmtReports"]
      } else if (this.path.substring(0, 4) === '/trn') {
        this.rowList = this.$store.getters["reporter/getTrnReports"]
      } else if (this.path.substring(0, 4) === '/nav') {
        this.rowList = this.$store.getters["reporter/getNavReports"]
      } else if (this.path.substring(0, 4) === '/kvr') {
        this.rowList = this.$store.getters["reporter/getKvrReports"]
      } else if (this.path.substring(0, 6) === '/asmpp') {
        this.rowList = this.$store.getters["reporter/getAsmppReports"]
      } else if (this.path.substring(0, 5) === '/asip') {
        this.rowList = this.$store.getters["reporter/getAsipReports"]
      } else if (this.path.substring(0, 9) === '/priority') {
        this.rowList = this.$store.getters["reporter/getPriorityReports"]
      } else if (this.path.substring(0, 4) === '/admin') {
        this.rowList = this.$store.getters["reporter/getAdminReports"]
      } else {
        this.curMenu = null
      }
      if (this.rowList.length == 0) {
        this.$store.commit('hideReports')
      }
    },
  },

  created() {
    // следим за изменением меню
    this.$watch(() => this.$route.path, () => {
      this.path = this.$route.path;
      this.setMenu();
    })
  }

}
</script>

<style scoped>
.report-panel {
  position: fixed;
  max-height: 250px;
  height: 250px;
  bottom: 5px;
  right: 5px;
  width: 800px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Параметры тени */
  font-size: 90%;
  z-index: 100;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  right: -800px;
}

.slide-enter-to,
.slide-leave-from {
  right: 5px;
}
</style>