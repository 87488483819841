<template>

    <div :style="'overflow-y: hidden; overflow-x: hidden; width: ' + width + 'px'">
      <saw-schedule-graph-s-v-g
          :graphs="schedulesArr"
          :hoursArray="hoursArray"
          :width="width"
          :start_x="start_x"
          :oneDiv="oneDiv"
          :cpArr="cpArr"
          :sawViewType="sawViewType"
      >
      </saw-schedule-graph-s-v-g>
    </div>

</template>

<script>

import SawScheduleGraphSVG from "@/components/ui/svg/saw_shedule_graph/SawScheduleGraphSVG";
import {directive} from "vue3-click-away";

export default {
  name: "LineScheduleGraphPanel",
  components: {SawScheduleGraphSVG},
  props: ['oneDiv', 'schedulesArr', 'tripTypeCards', 'hoursArray', 'sawViewType', 'cpArr'],
  emits: ['doDelLeftTrip', 'doStartTrafficGraph', 'doEditGraph', 'doMinusMin', 'doEditTripBegin', 'doEditStop', 'doPlusMin', 'doExtensionTrafficGraph', 'doChangeTripType'],
  directives: {
    ClickAway: directive
  },
  data() {
    return {
      // массив часов для верхней шкалы
      //hoursArray: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,/* 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52*/],
      // ширина графика
      //width: 10000,
      // отступ слева начала линейки часов и он же задает начальную точку отсчета линии графика
      start_x: 5,
      // высота линии графика; от нее в компонентах также может отсчитываться местоположение подписей к графику
      start_y: 32,

      // массив КП direction 0
      direction0Arr: [0, 5, 10,  35, 55, 77, 90, 95, 100],
      // массив КП direction 1
      direction1Arr: [0, 5, 10, 30, 50, 82,90, 95, 88, 100],
    }
  },

  computed: {
    width() {
      return this.oneDiv * 60 * this.hoursArray.length
    },
  },

  methods: {

    doDelLeftTrip(specifiedTrip, row, col) {
      this.$emit('doDelLeftTrip',specifiedTrip, row, col);
    },

    doMinusMin(specifiedTrip, row, col) {
      this.$emit('doMinusMin',specifiedTrip, row, col);
    },

    doEditTripBegin(specifiedTrip, row, col) {
      this.$emit('doEditTripBegin',specifiedTrip, row, col);
    },

    doEditStop(specifiedTrip, row, col) {
      this.$emit('doEditStop',specifiedTrip, row, col);
    },

    doPlusMin(specifiedTrip, row, col) {
      this.$emit('doPlusMin',specifiedTrip, row, col);
    },

    doExtensionTrafficGraph(specifiedTrip, row, col) {
      this.$emit('doExtensionTrafficGraph',specifiedTrip, row, col);
    },

    changeTripType(specifiedTrip, changedTripType, selected_schedule_event_id, selected_null_trip_type_id, selected_production_trip_type_id) {
      // let specifiedTrip = this.colItem.trip_complex;
      console.log(specifiedTrip, "отправляем запрос на изменение типа рейса");
      this.$emit('doChangeTripType', specifiedTrip, changedTripType, selected_schedule_event_id, selected_null_trip_type_id, selected_production_trip_type_id);
    },

    startTrafficGraph(e, colItem) {

      let specifiedTrip = colItem.trip_complex;
      // переменная, в которой хранится в какую четверть экрана кликнул пользователь
      let quarter = 0;
      // определяем куда пользователь кликнул
      let domRect = e.srcElement.getBoundingClientRect();
      // определяем размеры окна
      // определяем ширину
      let windowWidth = window.innerWidth;
      // определяем высоту
      let windowHeight = window.innerHeight;
      // определяем в какую четверть экрана кликнул пользователь
      if (domRect.left < (windowWidth / 2)) {
        // если по ширине пользователь кликнул на левую половину экрана, значит исходя из высоты он может кликнуть в II или III четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это III четверть
          quarter = 3;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это II четверть
          quarter = 2;
        }
      } else {
        // если по ширине пользователь кликнул на правую половину экрана, значит исходя из высоты он может кликнуть в I или IV четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это IV четверть
          quarter = 4;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это I четверть
          quarter = 1;
        }
      }

      let rulerEl = document.getElementById('schedule_ruler');

      // исходя из того в какую четверть экрана кликнул пользователь задаем настройки отображения всплывающего диалога
      if (quarter == 1) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top + 26 - rulerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue - 340) + 'px;';
      } else if (quarter == 2) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top + 26 - rulerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue) + 'px;';
      } else if (quarter == 3) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top - 187 - rulerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue) + 'px;';
      } else if (quarter == 4) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top - 187 - rulerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue - 340) + 'px;';
      }

      if (colItem.column_num % 2 == 0) {
        this.direction = 'AB';
      } else {
        this.direction = 'BA';
      }

      this.dialogSpecifiedTrip = specifiedTrip.trip_complex;

      this.isAppointmentDialogVisible = false;
      this.isRealeaseDialogVisible = true;

      console.log(specifiedTrip, "отправляем запрос на запуск движения на графике");
      this.$emit('doStartTrafficGraph', specifiedTrip, this.selectedRow, this.selectedCol);
    },

    openTripTypeDialog(e, colItem) {
      // переменная, в которой хранится в какую четверть экрана кликнул пользователь
      let quarter = 0;
      // определяем куда пользователь кликнул
      let domRect = e.srcElement.getBoundingClientRect();
      // определяем размеры окна
      // определяем ширину
      let windowWidth = window.innerWidth;
      // определяем высоту
      let windowHeight = window.innerHeight;
      // определяем в какую четверть экрана кликнул пользователь
      if (domRect.left < (windowWidth / 2)) {
        // если по ширине пользователь кликнул на левую половину экрана, значит исходя из высоты он может кликнуть в II или III четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это III четверть
          quarter = 3;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это II четверть
          quarter = 2;
        }
      } else {
        // если по ширине пользователь кликнул на правую половину экрана, значит исходя из высоты он может кликнуть в I или IV четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это IV четверть
          quarter = 4;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это I четверть
          quarter = 1;
        }
      }

      let containerEl = document.getElementById('schedule_ruler');

      // исходя из того в какую четверть экрана кликнул пользователь задаем настройки отображения всплывающего диалога
      if (quarter == 1) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top + 26 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue - 340) + 'px;';
      } else if (quarter == 2) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top + 26 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue) + 'px;';
      } else if (quarter == 3) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top - 187 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue) + 'px;';
      } else if (quarter == 4) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top - 187 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue - 340) + 'px;';
      }

      if (colItem.column_num % 2 == 0) {
        this.direction = 'AB';
      } else {
        this.direction = 'BA';
      }

      this.dialogSpecifiedTrip = colItem.trip_complex;

      this.isRealeaseDialogVisible = false;
      this.isAppointmentDialogVisible = true;

    },

    setGraph(specifiedTrip, graph, startTime, continuationTripsCount) {
      if (!this.isLoading) {
        if (!Number.isInteger(Number(graph)) ) {
          specifiedTrip.graph = this.selectedTrip.graph;
        } else {
          if ((Number(graph) > 0) && (Number(graph) <= 999) && (graph != specifiedTrip.graph)) {
            // отправляем запрос на редактирование
            console.log("отправляем запрос на редактирование номера графика");
            this.$emit('doEditGraph', specifiedTrip, this.selectedRow, this.selectedCol);
          } else {
            console.log(graph, startTime, continuationTripsCount);
            specifiedTrip.graph = this.selectedTrip.graph;
          }
        }
        // this.selectedTrip = null;
        // this.selectedRow = 0;
        // this.selectedCol = 0;
        // скрываем диалог
        this.isRealeaseDialogVisible = false;
      }
    },

    // диалог редактирования рейса  &&
    hideDialog(e) {
      if(!document.getElementById('release_from_park_dialog') && !document.getElementById('release_from_park_dialog')) {
        this.selectedReis = null;
        this.isAppointmentDialogVisible = false;
        this.isRealeaseDialogVisible = false;
        this.leftValue = null;
      } else if ((document.getElementById('release_from_park_dialog') && !document.getElementById('release_from_park_dialog').contains(e.target)) ||
          (document.getElementById('release_from_park_dialog') && !document.getElementById('release_from_park_dialog').contains(e.target))
      ) {
        this.selectedReis = null;
        this.isAppointmentDialogVisible = false;
        this.isRealeaseDialogVisible = false;
        this.leftValue = null;
      }
    },

    // скрываем диалог Выпуска из парка
    hideReleaseDialog() {
      this.isRealeaseDialogVisible = false;
      this.realeaseDialogStyle = '';
    },

    // скрываем диалог Выпуска из парка
    hideAppointmentDialog() {
      this.isAppointmentDialogVisible = false;
      this.appointmentDialogStyle = '';
    },

    onClick(e, index) {
      // определяем координату х клика пользователя, чтобы определить в какой рейс нажал пользователь
      let x = e.offsetX;
      // получаем график на который кликнул пользователь
      let reises = this.schedulesArr[index].trip_table_columns;
      // определяем в цикле на какой рейс кликнул пользователь
      let layoutStartTime = (this.hoursArray[0] * 60);

      for (let i = 0; i < reises.length; i++) {
        let selReis = ((( reises[i].trip_complex.step_time_begin / 60) - layoutStartTime) * this.oneDiv) + this.start_x;
        let nextReis = null;
        if(i != (reises.length - 1)) {
          nextReis = ((( reises[i+1].trip_complex.step_time_begin / 60) - layoutStartTime) * this.oneDiv) + this.start_x;
        }
        if (i == 0 && x < selReis) {
          console.log('Ничего не делаем');
          break;
        }
        if ((x > selReis && x < nextReis) || ((i == (reises.length - 1)) && x >= selReis)) {
          console.log('Выбранный рейс под номером: ', i);
          this.leftValue = selReis;
          // по индексу можем получить html элемент по которому кликнул пользователь, для определения отступа сверху
          let el = document.getElementById('svg' + index);
          let rulerEl = document.getElementById('schedule_ruler');
          let topCoord = el.getBoundingClientRect().top;
          this.lineGraphDialogStyle = 'top: ' + Math.round(topCoord - 9 - rulerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(selReis) + 'px;';
          this.selectedReis = reises[i];
          break;
        }
      }
    },

    mousemoveEvent(event) {
      // this.cursorPosition = event.offsetX;
      if (event.offsetX > this.start_x) {
        document.getElementById('svgCursor').setAttribute('x1', event.offsetX);
        document.getElementById('svgCursor').setAttribute('x2', event.offsetX);
      } else {
        document.getElementById('svgCursor').setAttribute('x1', this.start_x);
        document.getElementById('svgCursor').setAttribute('x2', this.start_x);
      }
      // window.requestAnimationFrame(this.mousemoveEvent);
    },

    updateWidth(newValue) {
      this.width = newValue;
    },

  },

  created() {

  }

}
</script>

<style scoped>

</style>