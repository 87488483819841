import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДАННЫХ ОСТАНОВОЧНОГО ПАССАЖИРОПОТОКА

export default {
    actions: {
        // запрос списка объектов
        doFetchFactTripCardExts: (context, payload) => {
            return DoGet(
                '/asmpp/factTripCardExts?fact_trip_ext_id=' + payload.fact_trip_ext_id,
                null,
                [],
                'Запрос остановочного пассажиропотока по выбранному фактическому рейсу',
                false,
                true
            )
        },
    }
}
