import {DoClearCache, DoGet, DoPut} from "@/store/Actions";
import {clearArrContains, clearObjContains} from "@/lib";

// ХРАНИЛИЩЕ НАРЯДОВ НА РАБОТУ

export default {
    state() {
        return {
            // список объектов
            baseOrders: [],
            // время обновления кэша new Date().getTime()
            baseOrdersCache: null,
            // время жизни кэша в мс (null - бесконечно)
            baseOrdersCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getBaseOrders(state) {
            return state.baseOrders;
        },
    },
    mutations: {

        // установить новый список объектов
        setBaseOrders(state, payload) {
            state.baseOrders = payload;
            state.baseOrdersCache = new Date().getTime();
        },
        // добавить объект
        addBaseOrder(state, payload) {
            state.baseOrders.push(payload);
        },
        // изменить объект
        editBaseOrder(state, payload) {
            const index = state.baseOrders.findIndex(baseOrder => baseOrder.base_order_id == payload.base_order_id);
            if (index >= 0) {
                state.baseOrders[index] = payload;
            }
        },
        // удалить объект
        delBaseOrder(state, payload) {
            const index = state.baseOrders.findIndex(baseOrder => baseOrder.base_order_id == payload.base_order_id);
            state.baseOrders.splice(index, 1);
        }
    },
    actions: {

        // запрос списка объектов
        doFetchBaseOrders: (context, payload) => {
            return DoGet(
                '/trn/baseOrders/' + payload.date_index,
                'trn/setBaseOrders',
                [],
                'Запрос суточного наряда за указанный день',
                false,
                payload && payload.force,
                context.state.baseOrdersCache,
                context.state.baseOrdersCacheTimeout
            )
        },

        // запрос объекта
        doFetchBaseOrder: (context, payload) => {
            return DoGet(
                '/trn/baseOrders/' + payload.date_index + '/' + payload.base_order_id,
                null,
                [],
                'Запрос суточного наряда',
                false,
                payload && payload.force,
                context.state.baseOrdersCache,
                context.state.baseOrdersCacheTimeout
            )
        },

        // очищает кэш
        doClearBaseOrders: (context, payload) => {
            return DoClearCache(
                'trn/setBaseOrders',
                [],
                payload && payload.force,
                context.state.baseOrdersCache,
                context.state.baseOrdersCacheTimeout
            )
        },

        // Изменить наряд на работу
        doEditBaseOrder: (context, payload) => {
            return DoPut(
                '/trn/baseOrders/' + payload.date_index + '/' + payload.base_order_id,
                clearObjContains(payload.value),
                'trn/editBaseOrder',
                'Изменение суточного наряда'
            )
        },

        // Изменить наряды на работу
        doEditBaseOrderArr: (context, payload) => {
            return DoPut(
                '/trn/baseOrders/' + payload.date_index,
                clearArrContains(payload.base_orders),
                'trn/setBaseOrders',
                'Изменение суточного наряда (по нескольким строкам)'
            )
        },

        // Очистить наряд за указанный день
        doClearBaseOrder: (context, payload) => {
            return DoPut(
                '/trn/clearBaseOrder/' + payload.date_index,
                {plan_depot_id_list: payload.plan_depot_id_list},
                'trn/editBaseOrder',
                'Очистить суточный наряд за указанный день'
            )
        },

        // Восстановить наряд по транспортному предприятию
        doClearBaseOrderByDepot: (context, payload) => {
            return DoPut(
                '/trn/clearBaseOrder/' + payload.date_index + '/' + payload.plan_depot_id,
                null,
                null,
                'Очистить суточный наряд по транспортному предприятию'
            )
        },

        // Восстановить наряд по маршруту
        doClearBaseOrderByDepotAndRoute: (context, payload) => {
            return DoPut(
                '/trn/clearBaseOrder/' + payload.date_index + '/' + payload.plan_depot_id + '/' + payload.route_id,
                null,
                null,
                'Очистить суточный наряд по маршруту'
            )
        },

        // Восстановить наряд по графику
        doClearBaseOrderByDepotAndRouteAndBaseOrder: (context, payload) => {
            return DoPut(
                '/trn/clearBaseOrder/' + payload.date_index + '/' + payload.plan_depot_id + '/' + payload.route_id + '/' + payload.base_order_id,
                null,
                null,
                'Очистить суточный наряд по графику'
            )
        },

    }
}