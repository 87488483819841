<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to="routeVariantsLink">Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span>Типы рейсов {{ routeVariantPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка Команда-->
            <template #leftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary" :disabled="commandDisabled" >Команда</button-toggler>
                <dropdown-menu ref="collapse">
                  <dropdown-item @click="onCopy" v-if="selectedRow">Копировать</dropdown-item>
                  <dropdown-item @click="onPaste" v-if="deactivatePaste">Вставить</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">Связь</button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="tripTypeCardLink">Трасса типа рейса</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <trip-types-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :rows="rows"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></trip-types-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <trip-type-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </trip-type-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить тип рейса с кодом {{ id }} ?</confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import TripTypesEditTable from "@/components/multipass/trip_types/TripTypesEditTable";
import TripTypeDialog from "@/components/multipass/trip_types/TripTypeDialog";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";
import store from "@/store/Store";

export default {
  mixins: [PageMixin],

  components: {
    TripTypesEditTable,
    TripTypeDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // список типов рейсов
      rows: [],
      // тип объекта для определения активности кнопки вставить
      objType: 'TripType',
    }    
  },

  computed: {
      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            add: true, 
            edit: !!this.selectedRow, 
            delete: !!this.selectedRow, 
            refresh: true
          }
      },

      // условие отображения диалогового окна
      isDialogVisible() {
          return (this.op === 'show' || this.op === 'edit' || this.op === 'add' || this.op === 'paste') && (!!this.selectedRow || this.op === 'add')
      },

      // условие отображения подтверждения удаления
      isDelConfirmVisible() {
          return this.op === 'del' && !!this.selectedRow;
      },

      // ссылка на варианты маршрута
      routeVariantsLink() {
        return `/multipass/routes/${this.route_id}/routeVariants`
      },

      // условие отображения кнопки "вставить"
      // если тип объекта в буфере обмена совпадает с типом объекта на текущей странице
      deactivatePaste() {
        return (store.getters.getType == this.objType);
      },

      // условие отключения кнопки - Связь
      linkDisabled() {
        return !this.selectedRow;
      },

      // условие отключения кнопки - Команды
      commandDisabled() {
        return (!this.selectedRow && !this.deactivatePaste);
      },

      // ссылка для перехода к трассе типа рейса
      tripTypeCardLink() {
        if (this.selectedRow)
          return `/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes/${this.selectedRow.trip_type_id}/tripTypeCard`
        else
          return '';
      },

      // запись для подстановки в диалог
      dialogRow() {
        if (this.op === 'add') {
          return {
            weight: 1.0,
            trip_type_sign: '00',
            direction: 0
          }
        }
        else return this.selectedRow;
      }
  },
  methods: {

    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes/add`);
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes/edit/${this.selectedRow.trip_type_id}`);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes/del/${this.selectedRow.trip_type_id}`);
    },

    // нажата кнопка Просмотр
    onTableShow() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes/show/${this.selectedRow.trip_type_id}`);
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes`);
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      }
      else if (this.op === 'edit') {
        this.editItem(item)
      }
      else if (this.op === 'paste') {
        this.pasteItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes`);
    },
    
    // нажата кнопка Обновить
    onTableRefresh() {
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // нажата кнопка "копировать"
    onCopy(){
      // сохраняем type и выбранную строку в store
      store.dispatch('doSetClipboard', {
        type: 'TripType',
        object: this.selectedRow
      });

      // выводим сообщение о том, что данные сохранены в буфер обмена
      store.dispatch('doAddMessage', {
        caption: 'Тип рейса',
        message: 'Данные сохранены в буфер обмена',
        type: 'ok'
      }, {root: true});
    },

    // нажата кнопка "вставить"
    onPaste(){
      // получаем из стора сохраненный объект
      this.selectedRow = store.getters.getObject;
      // открываем диалог копирования (редактирования)
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes/paste`);
    },
    
    // отменили операцию
    onRejectOperation() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes`);
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // загружаем список остановок
        await this.$store.dispatch('multipass/doFetchStops');

        // загружаем маршрут
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id
        }); if (!route) return;
        this.route = route;

        // загружаем вариант маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id
        }); if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // загружаем типы рейсов
        const rows = await this.$store.dispatch('multipass/doFetchTripTypes', {
          route_variant_id: this.route_variant_id,
          force: true
        }); if (!rows) return;
        this.rows = rows;

      }
      finally {
        this.isLoading = false;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        item['route_variant_id'] = Number(this.route_variant_id);
        // запрашиваем сервер
        const newItem = await this.$store.dispatch('multipass/doAddTripType', item);
        // мутируем массив
        this.selectedRow = addMutation(this.rows, 'trip_type_id', newItem);

      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        item['route_variant_id'] = Number(this.route_variant_id);
        // запрашиваем сервер
        const newItem = await this.$store.dispatch('multipass/doEditTripType', item);
        // мутируем массив
        this.selectedRow = editMutation(this.rows, 'trip_type_id', newItem);

      }
      finally {
        this.isLoading = false;
      }
    },

    // добавляем объект при вставке
    async pasteItem(item) {
      this.isLoading = true;
      try {
        // сетаем текущий route_variant_id
        item.route_variant_id = Number(this.route_variant_id);
        // запрашиваем сервер
        const copiedItem = await this.$store.dispatch('multipass/doCopyTripType', item);
        // мутируем массив
        this.selectedRow = addMutation(this.rows, 'trip_type_id', copiedItem);
      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрашиваем сервер
        const oldItem = await this.$store.dispatch('multipass/doDelTripType', this.selectedRow);
        // мутируем массив
        this.selectedRow = delMutation(this.rows, 'trip_type_id', oldItem);

      }
      finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
