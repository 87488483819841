import {DoDelete, DoGet, DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ СВЯЗЕЙ ВАРИАНТОВ МАРШРУТОВ С ПРЕДПРИЯТИМИ

export default {
    actions: {
        // запрос списка связей
        doFetchRouteVariantDepotLinks: (context, payload) => {
            return DoGet(
                '/multipass/routeVariantDepotLinks?route_variant_id='+payload.route_variant_id,
                null,
                [],
                'Запрос связей предприятий с вариантом маршрута',
                false,
                true
            )
        },

        // связывание объектов
        doAddDepotToRouteVariant: (context, payload) => {
            return DoPost(
                '/multipass/routeVariantDepotLinks',
                payload,
                null,
                'Добавление предприятия в вариант маршрута'
            )
        },

        // удаление связи объектов
        doDelDepotFromRouteVariant: (context, payload) => {
            return DoDelete(
                '/multipass/routeVariantDepotLinks/'+payload.route_variant_id+'/'+payload.depot_id,
                null,
                'Удаление предприятия из варианта маршрута'
            )
        },
    }
}