import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ВИДОВ ДВИЖЕНИЯ

export default {
    state() {
        return {
            // список объектов
            movementKinds: [],
            // время обновления кэша new Date().getTime()
            movementKindsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            movementKindsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getMovementKinds(state) {
            return state.movementKinds;
        },
    },
    mutations: {
        // установить новый список объектов
        setMovementKinds(state, payload) {
            state.movementKinds = payload;
            state.movementKindsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchMovementKinds: (context, payload) => {
            return DoGet(
                '/rmt/movementKinds',
                'rmt/setMovementKinds',
                [],
                'Запрос видов движения',
                false,
                payload && payload.force,
                context.state.movementKindsCache,
                context.state.movementKindsCacheTimeout
            )
        },

        // очищает кэш
        doClearMovementKinds: (context, payload) => {
            return DoClearCache(
                'rmt/setMovementKinds',
                [],
                payload && payload.force,
                context.state.movementKindsCache,
                context.state.movementKindsCacheTimeout
            )
        },
    }
}
