<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">

      <page-header class="page-header">
        <span><router-link to="/rmt">Расписания</router-link></span>
        <span> / </span>
        <span><router-link to="/rmt/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to=routeVariantsLink>Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleVariantsLink>Варианты расписаний {{ routeVariantPageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleGraphSetLink>Наборы графиков {{ scheduleVariantPageHeader }}</router-link></span>
        <span> / </span>
        <span>Графики движения {{ scheduleGraphSetPageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">

            <crud-form
                :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
                :buttonsEnabled="crudButtonsEnabled"
                @onAdd="onTableAdd"
                @onEdit="onTableEdit"
                @onDelete="onTableDelete"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            >

              <!-- Кнопка Отчеты-->
              <template #leftCustomButton>
                <dropdown>
                  <button-toggler target-ref="collapse3" class="btn dropdown-toggle btn-primary"
                                  :disabled="linkDisabled">
                    Отчеты
                  </button-toggler>
                  <dropdown-menu ref="collapse3">
                    <dropdown-item @click="onReport0002">Плановое расписание</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>

              <!-- Кнопка Команда-->
              <template #leftCustomButton2>
                <dropdown>
                  <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                  :disabled="commandDisabled">
                    Команда
                  </button-toggler>
                  <dropdown-menu ref="collapse">
                    <dropdown-item @click="onTableCalc" v-if="graphCalcExists">Пересчет графика</dropdown-item>
                    <dropdown-divider v-if="graphCalcExists"></dropdown-divider>
                    <dropdown-item @click="onTableCheck" v-if="graphCheckExists">Проверка графика</dropdown-item>
                    <dropdown-divider v-if="graphCheckExists"></dropdown-divider>
                    <dropdown-item @click="onCopy" v-if="selectedRow">Копировать</dropdown-item>
                    <dropdown-item @click="onPaste" v-if="deactivatePaste">Вставить</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>

              <!-- Кнопка связь-->
              <template #leftCustomButton3>
                <dropdown>
                  <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary"
                                  :disabled="linkDisabled">
                    Связь
                  </button-toggler>
                  <dropdown-menu ref="collapse2">
                    <dropdown-item router-link :to="scheduleGraphStepsLink">Распорядок</dropdown-item>
                    <dropdown-divider></dropdown-divider>
                    <dropdown-item router-link :to="scheduleTimeTablesLink">Расписание</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>
            </crud-form>
          </align-panel>

          <!-- Таблица  -->
          <align-panel align="all">
            <schedule-graphs-edit-table
                :filterText="filterText"
                :isLoading="isLoading"
                :selectedRow="selectedRow"
                :scheduleGraphs="scheduleGraphs"
                @onRowSelect="onRowSelect"
                @onRowDblClick="onTableShow"
            ></schedule-graphs-edit-table>
          </align-panel>
        </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <schedule-graph-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </schedule-graph-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить график движения с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Диалог со списком ошибок -->
  <errors-dialog
      v-if="isErrorsDialogVisible"
      :rowList="scheduleGraphErrors"
      @onClose="scheduleGraphErrors=[]"
      caption="Ошибки графика"
  >
  </errors-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import ScheduleGraphsEditTable from "@/components/rmt/schedule_graphs/ScheduleGraphsEditTable";
import ScheduleGraphDialog from "@/components/rmt/schedule_graphs/ScheduleGraphDialog";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";
import store from "@/store/Store";

export default {
  mixins: [PageMixin],

  components: {
    ScheduleGraphsEditTable,
    ScheduleGraphDialog,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
    // идентификатор варианта расписания
    "schedule_variant_id",
    // идентификатор набора графиков
    "schedule_graph_set_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // список ошибок графика
      scheduleGraphErrors: [],
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // вариант расписания
      scheduleVariant: {},
      // набор графиков
      scheduleGraphSet: {},
      // графики движения
      scheduleGraphs: [],
      // тип объекта для определения активности кнопки вставить
      objType: 'ScheduleGraph'
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add' || this.op === 'paste') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // условие отключения кнопки - Команды
    commandDisabled() {
      return (!this.selectedRow && !this.deactivatePaste);
    },

    // условие отображения кнопки Пересчет графика
    graphCalcExists() {
      return this.selectedRow
    },

    // условие отображения кнопки Проверка графика
    graphCheckExists() {
      return this.selectedRow
    },

    // условие отображения кнопки "вставить"
    // если тип объекта в буфере обмена совпадает с типом объекта на текущей странице
    deactivatePaste() {
      return (store.getters.getType == this.objType);
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {
          graph_control_mode: 1,
        }
      } else return this.selectedRow;
    },

    // условие отображения списка ошибок графика
    isErrorsDialogVisible() {
      return this.scheduleGraphErrors.length > 0;
    },

    // ссылка для перехода к вариантам маршрутов
    routeVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants`
    },

    // ссылка для перехода к вариантам расписаний
    scheduleVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`;
    },

    // ссылка для перехода к наборам графиков
    scheduleGraphSetLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets`;
    },

    // ссылка для перехода к графикам
    scheduleGraphStepsLink() {
      if (this.selectedRow) return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.selectedRow.schedule_graph_id}/scheduleGraphSteps`;
      else return '';
    },

    // ссылка для перехода к расписанию
    scheduleTimeTablesLink() {
      if (this.selectedRow) return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/${this.selectedRow.schedule_graph_id}/scheduleTimeTables`;
      else return '';
    },

  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/add`);
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/edit/${this.selectedRow.schedule_graph_id}`);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/del/${this.selectedRow.schedule_graph_id}`);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      this.refreshData();
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // выполнено двойное нажатие строки в таблице (открываем просмотр)
    onTableShow() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/show/${this.selectedRow.schedule_graph_id}`);
    },

    // Вызывается при нажатии Пересчет
    onTableCalc() {
      this.calcGraph();
    },

    // Вызывается при нажатии Проверка
    onTableCheck() {
      this.checkGraph();
    },

    // нажата кнопка "копировать"
    onCopy(){
      // сохраняем type и выбранную строку в store
      store.dispatch('doSetClipboard', {
        type: 'ScheduleGraph',
        object: this.selectedRow
      });

      // выводим сообщение о том, что данные сохранены в буфер обмена
      store.dispatch('doAddMessage', {
        caption: 'График',
        message: 'Данные сохранены в буфер обмена',
        type: 'ok'
      }, {root: true});
    },

    // нажата кнопка "вставить"
    onPaste(){
      // получаем из стора сохраненный объект
      this.selectedRow = store.getters.getObject;
      // открываем диалог копирования (редактирования)
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs/paste`);
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs`);
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchDepots')
        await this.$store.dispatch('ref/doFetchTransportClasses')
        await this.$store.dispatch('rmt/doFetchTransportationKinds')
        await this.$store.dispatch('rmt/doFetchRegimes')

        // запрос набора графиков
        const scheduleGraphSet = await this.$store.dispatch('rmt/doFetchScheduleGraphSet', {
          schedule_graph_set_id: this.schedule_graph_set_id
        }); if (!scheduleGraphSet) return;
        this.scheduleGraphSet = scheduleGraphSet;

        // запрос маршрута
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        }); if (!route) return;
        this.route = route;

        // запрос варианта маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id,
        }); if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // запрос варианта расписания
        const scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
          schedule_variant_id: this.schedule_variant_id,
        }); if (!scheduleVariant) return;
        this.scheduleVariant = scheduleVariant;

        // список графиков
        const scheduleGraphs = await this.$store.dispatch('rmt/doFetchScheduleGraphs', {
          schedule_graph_set_id: this.schedule_graph_set_id,
        }); if (!scheduleGraphs) return;
        this.scheduleGraphs = scheduleGraphs;

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // нажата кнопка отчета "Плановое расписание"
    onReport0002(){
      // вносим временный отчет
      this.$store.commit('reporter/addReport', {report_id: 2});
      // отправляем на сервер запрос на формирование отчета
      this.$store.dispatch('rmt/doMakeReport0002', {schedule_graph_id: this.selectedRow.schedule_graph_id});
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      } else if (this.op === 'paste') {
        this.pasteItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs`);
    },

    // подтверждено изменения в диалоге
    onConfirmDelete(item) {
      this.delItem(item)
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/${this.schedule_graph_set_id}/scheduleGraphs`);
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        // запрос на сервер
        item['schedule_graph_set_id'] = Number(this.schedule_graph_set_id);
        const newItem = await this.$store.dispatch('rmt/doAddScheduleGraph', item);
        // мутируем массив
        this.selectedRow = addMutation(this.scheduleGraphs, 'schedule_graph_id', newItem);

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        // запрос на сервер
        item['schedule_graph_set_id'] = Number(this.schedule_graph_set_id);
        const newItem = await this.$store.dispatch('rmt/doEditScheduleGraph', item);
        // мутируем массив
        this.selectedRow = editMutation(this.scheduleGraphs, 'schedule_graph_id', newItem);

      } finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрос на сервер
        const oldItem = await this.$store.dispatch('rmt/doDelScheduleGraph', this.selectedRow);
        this.selectedRow = delMutation(this.scheduleGraphs, 'schedule_graph_id', oldItem);

      } finally {
        this.isLoading = false;
      }
    },

    // пересчет графика
    async calcGraph() {
      this.isLoading = true;
      try {

        // вызываем пересчет графика
        let scheduleGraphErrors = await this.$store.dispatch('rmt/doRecalcScheduleGraph', {
          schedule_graph_id: this.selectedRow.schedule_graph_id
        }); if (!scheduleGraphErrors) return;
        this.scheduleGraphErrors = scheduleGraphErrors;

        // обновляем текущий график
        const item = await this.$store.dispatch('rmt/doFetchScheduleGraph', {
          schedule_graph_id: this.selectedRow.schedule_graph_id
        });
        // мутируем массив
        this.selectedRow = editMutation(this.scheduleGraphs, 'schedule_graph_id', item);

      } finally {
        this.isLoading = false;
      }
    },

    // добавляем объект при вставке
    async pasteItem(item) {
      this.isLoading = true;
      try {

        // запрашиваем сервер
        item['schedule_graph_set_id'] = Number(this.schedule_graph_set_id);
        const copiedItem = await this.$store.dispatch('rmt/doCopyScheduleGraph', item);
        // мутируем массив
        this.selectedRow = addMutation(this.scheduleGraphs, 'schedule_graph_id', copiedItem);
      }
      finally {
        this.isLoading = false;
      }
    },

    // проверка графика
    async checkGraph() {
      this.isLoading = true;
      try {

        // вызываем проверку графика
        let scheduleGraphErrors = await this.$store.dispatch('rmt/doCheckScheduleGraph', {
          schedule_graph_id: this.selectedRow.schedule_graph_id
        }); if (!scheduleGraphErrors) return;
        this.scheduleGraphErrors = scheduleGraphErrors;

        // обновляем текущий график
        const item = await this.$store.dispatch('rmt/doFetchScheduleGraph', {
          schedule_graph_id: this.selectedRow.schedule_graph_id
        });
        // мутируем массив
        this.selectedRow = editMutation(this.scheduleGraphs, 'schedule_graph_id', item);

      } finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

