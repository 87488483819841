<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-nav-params"> </edit-table-panel>
</template>

<script>


import {getParamGroup} from "@/store/NavHelpers";

export default {
  props: ['isLoading', 'paramGroupId'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "param_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "param_group",
          caption: "Группа",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "param_sign",
          caption: "Сигнатура параметра",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__param_type",
          caption: "Тип",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "param_title",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "param_desc",
          caption: "Описание",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "param_units",
          caption: "Единицы измерения",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      let params = [];
      if (this.paramGroupId) {
        params =  this.$store.getters["nav/getParams"].filter(param =>{
          return param.param_group_id == this.paramGroupId;
        });
      } else {
        params = this.$store.getters["nav/getParams"];
      }

      return params.map(param => {
        param.__id = param.param_id;
        if (param.param_type_id == 0){
          param.__param_type = 'Аналоговый';
        } else if (param.param_type_id == 1){
          param.__param_type = 'Дискретный';
        }
        // присваиваем название группы
        if (getParamGroup(param.param_group_id)) {
          param.param_group = getParamGroup(param.param_group_id).param_group_name;
        }
        return param;
      });
    },
  }
};
</script>
