import RmtTitlePage from '../pages/rmt/RmtTitlePage.vue';
import RoutesPage from "@/pages/rmt/RoutesPage";
import RouteVariantsPage from "@/pages/rmt/routes/RouteVariantsPage";
import ScheduleVariantsPage from "@/pages/rmt/routes/routes_variants/ScheduleVariantsPage";
import SeasonsPage from "@/pages/rmt/SeasonsPage";
import ScheduleTypesPage from "@/pages/rmt/ScheduleTypesPage";
import MovementKindsPage from "@/pages/rmt/MovementKindsPage";
import ScheduleGraphSetsPage from "@/pages/rmt/routes/routes_variants/schedule_variants/ScheduleGraphSetsPage";
import TransportationKindsPage from "@/pages/rmt/TransportationKindsPage";
import RegimesPage from "@/pages/rmt/RegimesPage";
import ScheduleEventTypesPage from "@/pages/rmt/ScheduleEventTypesPage";
import ScheduleGraphsPage from "@/pages/rmt/routes/routes_variants/schedule_variants/schedule_graph_sets/ScheduleGraphsPage";
import ScheduleEventsPage from "@/pages/rmt/ScheduleEventsPage";
import IndicatorsPage from "@/pages/rmt/IndicatorsPage";
import ScheduleVariantDocsPage from "@/pages/rmt/routes/routes_variants/schedule_variants/ScheduleVariantDocsPage";
import ScheduleVariantDocFilesPage
    from "@/pages/rmt/routes/routes_variants/schedule_variants/schedule_variant_docs/ScheduleVariantDocFilesPage";
import ScheduleGraphStepsPage
    from "@/pages/rmt/routes/routes_variants/schedule_variants/schedule_graph_sets/schedule_graphs/ScheduleGraphStepsPage";
import TimeNormTimesPage from "@/pages/rmt/routes/routes_variants/schedule_variants/TimeNormTimesPage";
import TimeNormPeriodsPage from "@/pages/rmt/routes/routes_variants/schedule_variants/TimeNormPeriodsPage";
import IntNormPeriodsPage from "@/pages/rmt/routes/routes_variants/schedule_variants/IntNormPeriodsPage";
import IntNormIntsPage from "@/pages/rmt/routes/routes_variants/schedule_variants/IntNormIntsPage";
import ScheduleTimeTablesPage
    from "@/pages/rmt/routes/routes_variants/schedule_variants/schedule_graph_sets/schedule_graphs/ScheduleTimeTablesPage";
import HolidaysPage from "@/pages/rmt/HolidaysPage";
import MainPage from "@/pages/main/MainPage";


export default {
    path: '/rmt',
    component: MainPage,
    children: [
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleGraphSets/:schedule_graph_set_id(\\d+)?/scheduleGraphs/:schedule_graph_id(\\d+)?/scheduleGraphSteps/:op(show|add|del|edit)/:event_id(\\d+)?/:order_num(\\d+)?/:trip_type_id(\\d+)?',
            component: ScheduleGraphStepsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleGraphSets/:schedule_graph_set_id(\\d+)?/scheduleGraphs/:schedule_graph_id(\\d+)?/scheduleTimeTables',
            component: ScheduleTimeTablesPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleGraphSets/:schedule_graph_set_id(\\d+)?/scheduleGraphs/:schedule_graph_id(\\d+)?/scheduleGraphSteps',
            component: ScheduleGraphStepsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleGraphSets/:schedule_graph_set_id(\\d+)?/scheduleGraphs/:op(show|add|del|edit|recalc|check|paste)/:id(\\d+)?',
            component: ScheduleGraphsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleGraphSets/:schedule_graph_set_id(\\d+)?/scheduleGraphs',
            component: ScheduleGraphsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleVariantDocs/:schedule_variant_doc_id(\\d+)?/scheduleVariantDocFiles/:op(show|add|del|edit)/:id(\\d+)?',
            component: ScheduleVariantDocFilesPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleVariantDocs/:schedule_variant_doc_id(\\d+)?/scheduleVariantDocFiles',
            component: ScheduleVariantDocFilesPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleVariantDocs/:op(show|add|del|edit)/:id(\\d+)?',
            component: ScheduleVariantDocsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleVariantDocs',
            component: ScheduleVariantDocsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleGraphSets/:op(show|add|del|edit|activate|deactivate|paste)/:id(\\d+)?',
            component: ScheduleGraphSetsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/scheduleGraphSets',
            component: ScheduleGraphSetsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/timeNormPeriods',
            component: TimeNormPeriodsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/timeNormPeriods/:op(show|add|del|edit)/:id(\\d+)?',
            component: TimeNormPeriodsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/intNormPeriods',
            component: IntNormPeriodsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/intNormPeriods/:op(show|add|del|edit)/:id(\\d+)?',
            component: IntNormPeriodsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/timeNormTimes',
            component: TimeNormTimesPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/timeNormTimes/:op(clr|upd)/:id(\\d+)?',
            component: TimeNormTimesPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/intNormInts',
            component: IntNormIntsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/scheduleVariants/:schedule_variant_id(\\d+)?/intNormInts/:op(clr|upd)',
            component: IntNormIntsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/ScheduleVariants/:op(show|add|del|edit|status|activate|deactivate|paste|change)/:id(\\d+)?',
            component: ScheduleVariantsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/ScheduleVariants',
            component: ScheduleVariantsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants/:op(show)/:id(\\d+)?',
            component: RouteVariantsPage,
            props: true
        },
        {
            path:'routes/:route_id(\\d+)?/routeVariants',
            component: RouteVariantsPage,
            props: true
        },
        {
            path:'routes/:op(show)/:id(\\d+)?',
            component: RoutesPage,
            props: true
        },
        {
            path:'routes',
            component: RoutesPage
        },
        {
            path:'holidays/:op(show|add|del|edit)/:id(\\d+)?',
            component: HolidaysPage,
            props: true
        },
        {
            path:'holidays',
            component: HolidaysPage
        },
        {
            path:'seasons/:op(show|add|del|edit)/:id(\\d+)?',
            component: SeasonsPage,
            props: true
        },
        {
            path:'seasons',
            component: SeasonsPage
        },
        {
            path:'scheduleTypes/:op(show|add|del|edit)/:id(\\d+)?',
            component: ScheduleTypesPage,
            props: true
        },
        {
            path:'scheduleTypes',
            component: ScheduleTypesPage
        },
        {
            path:'regimes/:op(show|add|del|edit)/:id(\\d+)?',
            component: RegimesPage,
            props: true
        },
        {
            path:'regimes',
            component: RegimesPage
        },
        {
            path: 'scheduleEvents/:op(show|add|del|edit)/:id(\\d+)?',
            component: ScheduleEventsPage,
            props: true
        },
        {
            path: 'scheduleEvents',
            component: ScheduleEventsPage,
        },
        {
            path:'movementKinds/:op(show)/:id(\\d+)?',
            component: MovementKindsPage,
            props: true
        },
        {
            path:'movementKinds',
            component: MovementKindsPage
        },
        {
            path:'transportationKinds/:op(show)/:id(\\d+)?',
            component: TransportationKindsPage,
            props: true
        },
        {
            path:'transportationKinds',
            component: TransportationKindsPage
        },

        {
            path:'scheduleEventTypes/:op(show)/:id(\\d+)?',
            component: ScheduleEventTypesPage,
            props: true
        },
        {
            path:'scheduleEventTypes',
            component: ScheduleEventTypesPage
        },
        {
            path: 'indicators/:any(.*)*',
            component: IndicatorsPage,
        },
        {
            path: '',
            component: RmtTitlePage
        },
        {
            path: ':pathMatch(.*)*',
            component: RmtTitlePage
        }
    ]
}