<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-rmt-schedule-graph-sets"></edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading', 'scheduleGraphSets'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "schedule_graph_set_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "schedule_variant_id",
          caption: "Код варианта маршрута",
          sortType: 'number',
          show: false
        },
        {
          field: "title",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "is_working",
          caption: "Отметка",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_working) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      const scheduleGraphSets = this.scheduleGraphSets;
      return scheduleGraphSets.map(schGrSet => {
        schGrSet.__id = schGrSet.schedule_graph_set_id;
        return schGrSet
      });
    },
  },
};
</script>
