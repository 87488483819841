<template>
  <svg id="schedule_ruler" :height="height" :width="width" style=" background-color: white"
       @mousemove="mousemoveEvent">
    <!--      линии разметки шкалы  -->
    <line
        v-for="(value, index) in (hoursArray.length * 60)"
        :key="index"
        :x1="getXCoord(index)"
        :y1="getYCoord(index)"
        :x2="getXCoord(index)"
        :y2="start_y"
        style="stroke:rgb(0,0,0);stroke-width:1"/>

    <!--      значение часов из массива -->
    <text
        v-for="(value, index) in (hoursArray)"
        :key="index"
        :x="getXTextCoord(value, index, 0)"
        :y="start_y - 10"
        fill="black"
        style="font-weight: bold"
    >{{ value }}
    </text>

    <!--      заполняем промежуточные значения минут между часами -->
    <text
        v-for="(value, index) in (hoursArray)"
        :key="index"
        :x="getXTextCoord(10, index, 10)"
        :y="start_y - 10"
        fill="black"
        font-size="14px"
    >10
    </text>
    <text
        v-for="(value, index) in (hoursArray)"
        :key="index"
        :x="getXTextCoord(20, index, 20)"
        :y="start_y - 10"
        fill="black"
        font-size="14px"
    >20
    </text>
    <text
        v-for="(value, index) in (hoursArray)"
        :key="index"
        :x="getXTextCoord(30, index, 30)"
        :y="start_y - 10"
        fill="black"
        font-size="14px"
    >30
    </text>
    <text
        v-for="(value, index) in (hoursArray)"
        :key="index"
        :x="getXTextCoord(40, index, 40)"
        :y="start_y - 10"
        fill="black"
        font-size="14px"
    >40
    </text>
    <text
        v-for="(value, index) in (hoursArray)"
        :key="index"
        :x="getXTextCoord(50, index, 50)"
        :y="start_y - 10"
        fill="black"
        font-size="14px"
    >50
    </text>

    <line
        id="svgCursor"
        :y1="29"
        :y2="24"
        style="stroke:rgb(255,0,0);stroke-width:2"/>


    <!--      время между рейсами текст сверху (интервалы)    -->
    <text
        v-for="(value, index) in (intervals)"
        :key="index"
        :x="value.coord"
        :y="46"
        fill="red"
        font-size="12px"
        style="font-weight: bold; z-index: 101 !important"
    >{{ (value.value) }}
    </text>

    <!--      Линии интервалов -->
    <line
        v-for="(line,index) in intervalLines"
        :key="index"
        :x1="getXStart(line)"
        :y1="height"
        :x2="getXStart(line)"
        :y2="start_y"
        style="stroke-dasharray: 5 2 ;stroke-width:1; stroke-linecap:butt; stroke:rgb(255,0,0)"
    />


  </svg>

</template>

<script>

// отрисовка панельки с линейкой часов для графика во всплывающем окне в rmtGen

import {nextTick} from "vue";

export default {
  name: "LineScheduleRulerSVG",
  emits: ['updateWidth'],
  props: [
    // массив часов верхней линейки
    'hoursArray',
// (получаем некоторые из значений ниже из родителя т.к. это значение нужно передать и в панельку на которой отрисовываются графики)
    // размер одного деления линейки часов (одно деление - 6 минут)
    'oneDiv',
    // Отступ слева для начала линейки
    'start_x',
    // Отступ сверху для начала линейки
    'start_y',
    // ширина окна c графиком
    'width',
    // высота окна с графиком
    'height',
    // массив интервалов между рейсами
    'intervals',
    // массив линий интервалов между рейсами
    'intervalLines',
  ],
  data(){
    return {
      layoutStartTime: null,
    }
  },
  watch: {

    async hoursArray() {
      await nextTick();
      // проверяем полностью ли влезла линейка сверху
      // графики никогда по x не будут выходить за значения линейки
      let svg1 = document.getElementById("schedule_ruler");
      let bbox1 = svg1.getBBox();
      this.$emit('updateWidth', (bbox1.width + this.start_x));
      svg1.setAttribute("width", (bbox1.width + this.start_x) + "px");
    }

  },
  methods: {

    mousemoveEvent(event) {
      if (event.offsetX > this.start_x) {
        document.getElementById('svgCursor').setAttribute('x1', event.offsetX);
        document.getElementById('svgCursor').setAttribute('x2', event.offsetX);
      } else {
        document.getElementById('svgCursor').setAttribute('x1', this.start_x);
        document.getElementById('svgCursor').setAttribute('x2', this.start_x);
      }
    },

    // получаем x координаты для шкалы с учетом количества часов
    getXCoord(index) {
      return ((index * this.oneDiv) + this.start_x);
    },

    getXStart(value) {
      return (((value / 60) - this.layoutStartTime) * this.oneDiv) + this.start_x;
    },

    // получаем x координаты для шкалы с учетом количества часов
    getYCoord(index) {
      if (index == 0 || (index % 10 === 0)) {
        return this.start_y - 7 + 'px';
      } else if (index % 5 === 0) {
        return this.start_y - 5 + 'px';
      } else {
        return this.start_y - 3 + 'px';
      }
    },

    getXTextCoord(value, index, addValue) {
      let val = (this.start_x + (index * this.oneDiv * 60) + (this.oneDiv * addValue));
      if (value < 10) {
        return (val - 4) + 'px';
      } else {
        return (val - 8) + 'px';
      }
    },

  },

  mounted() {
    // крайняя левая точка линейки часов т.е. это как бы ноль по шкале x
    this.layoutStartTime = (this.hoursArray[0] * 60);
    // проверяем полностью ли влезла линейка сверху
    // графики никогда по x не будут выходить за значения линейки
    let svg1 = document.getElementById("schedule_ruler");
    let bbox1 = svg1.getBBox();
    this.$emit('updateWidth', (bbox1.width + this.start_x));
    svg1.setAttribute("width", (bbox1.width + this.start_x) + "px");
  }

}
</script>

<style scoped>

</style>