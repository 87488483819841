<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-rmt-norm-periods"></edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading', 'rowList'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
          show: false
        },
        {
          field: "__time_begin",
          caption: "Время начала",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__time_end",
          caption: "Время окончания ",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
}
</script>
