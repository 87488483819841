<template>
  <edit-table-panel :columnList="columnList" :disableSettings="true" :rowList="rowList" :rowsPerPage="100000"
                    name="tbl-kvr-vehicle-result-graphs"></edit-table-panel>
</template>

<script>

import EditTablePanel from "@/components/ui/custom/EditTablePanel";

import {
  getManageDriverTrn,
  getManageVehicleTrn,
} from "@/store/TrnHelpers";
import {getTimeFormat} from "@/lib";

export default {
  components: {EditTablePanel},
  props: ['rows'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: '__route_num',
          caption: 'Мрш',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
            'font-size': '0.85rem',
          }
        },
        {
          field: 'graph',
          caption: 'Вых',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
            'font-size': '0.85rem',
          }
        },
        {
          field: 'shift',
          caption: 'См',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
            'font-size': '0.85rem',
          }
        },
        {
          field: '__ts',
          caption: 'ТС',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
            'font-size': '0.85rem',
          }
        },
        {
          field: 'inf',
          caption: 'Инф',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
            'font-size': '0.85rem',
          }
        },
        {
          field: '__driver',
          caption: 'Таб',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
            'font-size': '0.85rem',
          }
        },
        {
          field: '__work_time_begin',
          caption: 'Нач',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
            'font-size': '0.85rem',
          }
        },
        {
          field: '__work_time_end',
          caption: 'Кон',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
            'font-size': '0.85rem',
          }
        },
        {
          field: '',
          caption: 'Р',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
            'font-size': '0.85rem',
          }
        },
      ],
    }
  },

  computed: {

    rowList() {
      return this.rows.map(monitoringTask => {
        monitoringTask.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px',
          fontSize: '0.85rem',
        };

        monitoringTask.__work_time_begin = getTimeFormat(monitoringTask.work_time_begin);
        monitoringTask.__work_time_end = getTimeFormat(monitoringTask.work_time_end);

        //  вносим транспорт
        let ts = getManageVehicleTrn(monitoringTask.transport_id);
        if(ts){
          monitoringTask.__ts = ts.garage_num
        }

        // вноисим водителя
        let driver = getManageDriverTrn(monitoringTask.driver_id)
        if(driver)
          monitoringTask.__driver = driver.personnel_num

        return monitoringTask;
      });
    }

  },

  methods: {

    handleWindowSizeChange() {
      this.$emit('resize');
    }

  },

  mounted() {
  window.addEventListener("resize", this.handleWindowSizeChange);

  },

  unmounted() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

}
</script>

<style scoped>

</style>