<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Контракты</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>
                <label for="seasonId">Код:</label>
                <div class="row">
                  <div class="form-group col-3 ">

                    <input-integer
                        id="contractId"
                        class="form-control"
                        v-model="contractId"
                        readonly
                    />
                  </div>

                  <div class="form-group col">
                    <input-checkbox
                        id="isDisabled"
                        v-model="isDisabled"
                        :readonly="op === 'show'"
                        style="margin-top: 7px"
                    >Приостановлен
                    </input-checkbox>
                  </div>

                </div>

                <div class="form-group">
                  <label for="orgId">Организация:</label>
                  <req-field/>
                  <agencies-select
                      id="orgId"
                      v-model="orgId"
                      class="form-control"
                      ref="orgId"
                      :readonly="op === 'show'"
                      required
                      error="Выберите организацию"
                  />
                </div>

                <div class="form-group">
                  <label for="contractNum">Номер контракта:</label>
                  <req-field/>
                  <input-string
                      id="contractNum"
                      v-model="contractNum"
                      class="form-control"
                      ref="contractNum"
                      autocomplete="off"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="contractNum">Идентификатор вида тарифа:</label>
                  <req-field/>
                  <tariff-kinds-select
                      id="tariffKindId"
                      v-model="tariffKindId"
                      class="form-control"
                      ref="tariffKindId"
                      autocomplete="off"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="contractDate">Дата заключения контракта:</label>
                  <input-date
                      id="contractDate"
                      v-model="contractDate"
                      class="form-control"
                      ref="contractDate"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="dateBegin">Дата начала действия:</label>
                  <req-field/>
                  <input-date
                      id="dateBegin"
                      v-model="dateBegin"
                      class="form-control"
                      ref="dateBegin"
                      autocomplete="off"
                      :readonly="op === 'show'"
                      required
                      error="Введите дату начала действия"
                  />
                </div>

                <div class="form-group">
                  <label for="dateEnd">Дата окончания действия:</label>
                  <input-date
                      id="dateEnd"
                      v-model="dateEnd"
                      class="form-control"
                      ref="dateEnd"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="method">Методика расчета:</label>
                  <input-integer
                      id="method"
                      v-model="method"
                      class="form-control"
                      ref="method"
                      :exclude-negative="true"
                      :readonly="op === 'show'"
                  />
                </div>

              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputDate from "@/components/ui/custom/InputDate";
import TariffKindsSelect from "@/components/multipass/tariff_kinds/TariffKindsSelect";
import InputInteger from "@/components/ui/custom/InputInteger";
import AgenciesSelect from "@/components/kvr/agencies/AgenciesSelect";

export default {
  components: {AgenciesSelect, InputInteger, TariffKindsSelect, InputDate},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      contractId: this.item.contract_id,
      orgId: this.item.org_id,
      contractNum: this.item.contract_num,
      contractDate: this.item.contract_date,
      dateBegin: this.item.date_begin,
      dateEnd: this.item.date_end,
      isDisabled: this.item.is_disabled,
      tariffKindId: this.item.tariff_kind_id,
      method: this.item.method
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {

        this.$emit('onOK', {
          contract_id: this.contractId,
          org_id: this.orgId,
          contract_num: this.contractNum,
          contract_date: this.contractDate,
          date_begin: this.dateBegin,
          date_end: this.dateEnd,
          is_disabled: this.isDisabled,
          tariff_kind_id: this.tariffKindId,
          method: this.method,
        })
      }
    },
  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>