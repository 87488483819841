<template>
  <transition name="slide">
    <div class="routes-panel overflow-hidden" v-if="isRoutesPanelShow">
      <align-container>
        <align-panel align="top">
          <div class="form-group row" style="margin-left: 5px; margin-top: 4px; margin-bottom: 0; margin-bottom: 2px ">

            <!--          Поставить галочки всем ТС на экране-->
            <button style="padding: 0px; border-width: 0px; background-color: white; margin-right: 5px "
                    @click="setAllSelected">
              <i :class='selectedItems.length === routes.length ? "fas fa-check-square fa-2xl fa-2x" : "far fa-check-square fa-2xl fa-2x"'
                 title="Выделить всё"
                 :style='selectedItems.length === routes.length ? "color: #007BFF" : "color: #000000"'
              ></i>
            </button>

            <!--            Снять галочки со всех ТС -->
            <button style="padding: 0px; border-width: 0px; background-color: white; margin-right: 5px "
                    @click="setAllClear">
              <i :class='selectedItems.length === 0 ? "fas fa-square fa-2x" : "far fa-square fa-2x"'
                 title="Снять все"
                 :style='selectedItems.length === 0 ? "color: #007BFF" : "color: #000000"'
              ></i>
            </button>

            <!--            Снять галочки с тех ТС, что были выделенны, проставить на те, где были сняты -->
            <button style="padding: 0px; border-width: 0px; background-color: white;"
                    @click="setAllSwitch">
              <i :class='(selectedItems.length !== 0 && selectedItems.length !== routes.length) ?  "fab fa-flipboard fa-2x" : "fab fa-flipboard fa-2x"'
                 :style='(selectedItems.length !== 0 && selectedItems.length !== routes.length) ? "color: #007BFF" : "color: #000000"'
              ></i>
            </button>

            <input
                type="text"
                class="form-control"
                id="inlineFormInput"
                placeholder="Фильтр..."
                style="width: 76%; height: 26px;margin-left: 5px; margin-right: 10px"
                @input="onFilterChanged"
            />

            <button
                style="padding: 0px; border-width: 0px; background-color: white;"
                @click="closeRoutePanel"
                title="Снять все"
            >
              <i class="fas fa-times fa-2x"></i>
            </button>

          </div>

        </align-panel>

        <align-panel align="all" style="overflow-y: auto; overflow-x: hidden">

              <form class="form">

                <!-- Таблица  -->
                <routes-edit-table
                    :routes="filteredRoutes"
                    :columnList="columnList"
                    :selectedRow="selectedRow"
                    @onRowSelect="onRowSelect"
                ></routes-edit-table>

              </form>

        </align-panel>
      </align-container>
    </div>

  </transition>
</template>

<script>

import {directive} from "vue3-click-away";
import RoutesEditTable from "@/components/kvr/map/RoutesEditTable";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import AlignContainer from "@/components/ui/custom/AlignContainer";

export default {
  components: {AlignContainer, AlignPanel, RoutesEditTable},
  directives: {
    ClickAway: directive
  },
  emits: ['closeRoutesDialog', 'onRouteSelected'],
  props: ['isRoutesPanelShow', 'routes'],
  data() {
    return {
      // текст фильтра
      filterText: '',
      // задержка при изменении фильтра (мс)
      filterDelay: 300,
      // выбранная строка
      selectedRow: {},
      // колонки
      columnList: [
        {
          field: "__isSelected",
          caption: "Вкл.",
          isSortable: false,
          sortType: 'boolean',
          displayType: 'checkboxIsolated',
          headerAlign: 'center',
          dataAlign: 'center',
          inputStyle: {
            width: '18px',
            height: '18px',
            marginTop: '5px'
          },
        },
        {
          field: "__isZones",
          caption: "Зона",
          isSortable: false,
          sortType: 'boolean',
          displayType: 'checkboxIsolated',
          headerAlign: 'center',
          dataAlign: 'center',
          inputStyle: {
            width: '18px',
            height: '18px',
            marginTop: '5px'
          },

        },
        {
          field: "route_num",
          caption: "№",
          sortType: 'routeNum',
          isFilterable: true,
          show: true,
          sorted: 'asc',
          cellStyle: {
            verticalAlign: 'middle',
          },
        },
        {
          field: "route_title",
          caption: "Наименование",
          sortType: 'string',
          show: true,
          isFilterable: true,
          cellStyle: {
            verticalAlign: 'middle',
          },
        },
        {
          field: "reg_num",
          caption: "Рег.номер",
          sortType: 'string',
          show: true,
          cellStyle: {
            verticalAlign: 'middle',
          },
        },
        {
          field: "route_id",
          caption: "Код",
          sortType: 'number',
          show: true,
          isFilterable: true,
          cellStyle: {
            verticalAlign: 'middle',
          },
        },
      ],
    }
  },

  computed: {
    // выбранные маршруты
    selectedItems() {
      let arr = []
      for (let i = 0; i < this.routes.length; i++) {
        if (this.routes[i].__isSelected) {
          arr.push(this.routes[i]);
        }
      }
      return arr;
    },

    filteredRoutes(){
      // отфильтрованные маршруты
      let filteredRoutes = [];
      // в случае текста в поле "фильтр" фильтруем маршруты
      if (this.filterText != '') {
        let str;
        filteredRoutes = this.routes.filter((row) => {
          return this.columnList.some((col) => {
            let item = col.subField ? row[col.subField][col.field] : row[col.field];
            if (col.isFilterable && col.show) {
              if (item == null) {
                str = '';
              } else {
                str = String(item).toLowerCase();
              }

              return str.includes(this.filterText.toLowerCase());
            } else {
              return false;
            }
          });
        });
      } else {
        filteredRoutes = [];
        this.routes.map(value => {
          filteredRoutes.push(value)
        })
      }
      return filteredRoutes;
    }
  },
  methods: {

    // выбрана новая строка в таблице
    async onRowSelect(item) {
      this.selectedRow = item;
      if (item.__isSelected == true) {
        this.$emit('onRouteSelected', item);
      }
    },

    // закрытие диалога
    closeRoutePanel() {
      this.$emit('closeRoutesDialog');
    },

    // установить все галочки
    setAllSelected() {
      if (this.filterText != ''){
        this.filteredRoutes.map(value => {
          value.__isSelected = true;
        })
      } else {
        this.routes.map(value => {
          value.__isSelected = true;
        })
      }
    },

    // снять все галочки
    setAllClear() {
      if (this.filterText != ''){
        this.filteredRoutes.map(value => {
          value.__isSelected = false;
        })
      } else {
        this.routes.map(value => {
          value.__isSelected = false;
        })
      }
    },

    // переключить галочки
    setAllSwitch() {
      if (this.filterText != ''){
        this.filteredRoutes.map(value => {
          value.__isSelected = !value.__isSelected;
        });
      } else {
        this.routes.map(value => {
          value.__isSelected = !value.__isSelected;
        });
      }
    },

    // вызывается при изменении фильтра
    onFilterChanged(e) {
      const value = e.target.value.trim();
      setTimeout(() => {
        if (value === e.target.value.trim()) {
          // текст фильтра
          this.filterText = e.target.value.trim();
        }
      }, this.filterDelay);
    }
  },
}
</script>

<style scoped>

.routes-panel {
  position: fixed;
  top: 180px;
  bottom: 5px;
  left: 5px;
  width: 600px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Параметры тени */
  font-size: 90%;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  left: -600px;
}

.slide-enter-to,
.slide-leave-from {
  left: 5px;
}

</style>