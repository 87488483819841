<template>
  <align-container>
    <align-panel align="top">
      <page-header>
        <span><router-link to="/trn">Диспетчерская</router-link></span>
        <span> / </span>
        <span>Наряд на работу {{ dateForPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <align-panel align="top" :gap="3">
          <!-- Форма с кнопками над левой таблицей-->
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка Команда-->
            <template #preLeftCustomButton>

              <div class="btn-group">
                <button
                    type="button"
                    class="btn btn-danger"
                    title="Очистить все"
                    @click="showDepotsForClear"
                    :disabled="isEditAllEnable"
                >
                  <i class="fas fa-broom"></i>
                </button>

                <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-danger"
                                :disabled="!selectedRow">Очистить
                </button-toggler>
                <dropdown-menu ref="collapse">
                  <dropdown-item :disabled="!selectedRow || isEditAllEnable"
                                 @click="showClearBaseOrderByDepotDialog">Очистить парк
                  </dropdown-item>
                  <dropdown-item :disabled="!selectedRow || isEditAllEnable"
                                 @click="showClearBaseOrderByRouteDialog">Очистить маршрут
                  </dropdown-item>
                  <dropdown-item :disabled="!selectedRow || isEditAllEnable"
                                 @click="showClearBaseOrderByGraphDialog">Очистить график
                  </dropdown-item>
                </dropdown-menu>

              </div>

            </template>

            <!-- Кнопка Мониторинговая таблица-->
            <template #preLeftCustomButton2>
              <div class="btn-group">
                <button
                    type="button"
                    class="btn btn-primary"
                    title="Мониторинговая таблица"
                    :disabled="!selectedOrderDate"
                    @click="createMonitorTableByDay"
                >
                  <i class="fas fa-table"></i>
                </button>

                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary"
                                :disabled="!selectedRow">Мониторинговая таблица
                </button-toggler>
                <dropdown-menu ref="collapse2" style="margin-left: 38px">
                  <dropdown-item @click="createMonitorTableByRouteAndDay">Мониторинговая таблица по маршруту
                  </dropdown-item>
                </dropdown-menu>

              </div>
            </template>

            <!--            кнопка "редактировать / сохранить"-->
            <template #preLeftCustomButton3>
              <button
                  v-if="!isEditAllEnable"
                  type="button"
                  class="btn btn-warning"
                  title="Изменить все"
                  :disabled="baseOrdersArr.length == 0"
                  @click="onEditAllClick"
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                  v-else
                  type="button"
                  class="btn btn-success"
                  title="Сохранить все"
                  :disabled="!selectedOrderDate || isSaveButtonDisabled"
                  @click="onSaveAll"
              >
                <i class="fas fa-save"></i>
              </button>
            </template>

            <template #leftCustomButton v-if="isEditAllEnable">
              <button
                  type="button"
                  class="btn btn-danger"
                  title="Отменить"
                  :disabled="!selectedOrderDate"
                  @click="onCancelAll"
              >
                <i class="fas fa-ban"></i>
              </button>
            </template>

            <!--        Выбор даты наряда  -->
            <template #leftCustomButton2>
              <input-date
                  id="startDate"
                  v-model="selectedOrderDate"
                  class="form-control"
                  style="max-width: 151px"
              >
              </input-date>
            </template>

            <!-- Кнопка загрузить мастер-наряд-->
            <template #leftCustomButton3>
              <button
                  type="button"
                  class="btn btn-primary"
                  title="Загрузить наряд"
                  :disabled="!selectedOrderDate"
                  @click="downloadBaseOrders"
              >
                <i class="fas fa-download"></i>
              </button>
            </template>

          </crud-form>
        </align-panel>
        <align-panel align="all">
          <!--  таблица нарядов на работу -->
          <base-orders-edit-table
              :rows="baseOrdersArr"
              :filterText="filterText"
              :selectedRow="selectedRow"
              :isEditAllEnable="isEditAllEnable"
              :isSaveAll="isSaveAll"
              :originalBaseOrdersArr="originalBaseOrdersArr"
              @doEdit="doEditRow"
              @onRowSelect="onRowSelect"
              @onRowSelectCorrecting="onRowSelect"
              @saveAllChangedRows="saveAllChangedRows"
              @setCommandButtonDisabled="setCommandButton"
              style="z-index: 1"
          >
          </base-orders-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Подтверждение очищения наряда  -->
  <confirm-message-box
      v-if="isDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onClearBaseOrder"
  >Очистить наряд за {{ date }} ?
  </confirm-message-box>

  <!-- Подтверждение очищения наряда по транспортному предприятию  -->
  <confirm-message-box
      v-if="isClearBaseOrderByDepotDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmClearBaseOrderByDepot"
  >Очистить наряд по транспортному предприятию {{ depot }} за {{ date }} ?
  </confirm-message-box>

  <!-- Подтверждение очищения наряда по маршруту  -->
  <confirm-message-box
      v-if="isClearBaseOrderByRouteDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmClearBaseOrderByRoute"
  >Очистить наряд по маршруту {{ route }} предприятия {{ depot }} за {{ date }} ?
  </confirm-message-box>

  <!-- Подтверждение восстановления наряда по графику  -->
  <confirm-message-box
      v-if="isClearBaseOrderByRouteGrafVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmClearBaseOrderByGraph"
  >Очистить наряд по графику {{ baseOrderGraph }} по смене {{ baseOrderShift }} по маршруту {{ route }} предприятия {{ depot }} за {{ date }} ?
  </confirm-message-box>


  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

  <!-- Подтверждение создания мониторинговой таблицы за указанную дату -->
  <confirm-message-box
      v-if="isMonitorTableByDayCreateDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmCreate"
  >Вы уверены, что хотите cоздать мониторинговую таблицу за {{ selectedOrderDate }} ?
  </confirm-message-box>

  <!-- Подтверждение создания мониторинговой таблицы по маршруту за указанную дату  -->
  <confirm-message-box
      v-if="isMonitorTableByDayAndRouteCreateDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmCreateByDayAndRoute"
  >Вы уверены, что хотите cоздать мониторинговую таблицу по маршруту {{selectedRow.route_title}} за {{ selectedOrderDate }} ?
  </confirm-message-box>

</template>

<script>

import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import {
  dateToND,
  dateToString,
  ndToDate,
  ndToDateString,
  stringToDate,
} from "@/lib";
import PageMixin from "@/pages/share/PageMixin";
import BaseOrdersEditTable from "@/components/trn/base_orders/BaseOrdersEditTable";
import {getBaseOrder, getOrderDepotTrn, getOrderRouteTrn} from "@/store/TrnHelpers";

export default {
  name: "BaseOrdersPage",
  mixins: [PageMixin],
  components: {
    BaseOrdersEditTable,
    AlignPanel, AlignContainer
  },
  props: [
    'op',
    'date_index',
    'plan_depot_id',
    'route_id',
    'base_order_id'
  ],
  data() {
    return {
      // отображать спиннер
      isLoading: false,
      // выбранная строка
      selectedRow: null,
      // текст фильтра
      filterText: '',
      // дата в селекторе даты по которой делается запрос нарядов
      selectedOrderDate: dateToString(ndToDate(dateToND() + 1)),
      // дата по которой был сделан запрос нарядов
      requestedOrderDate: '',
      // исходный массив нарядов на работу
      originalBaseOrdersArr: [],
      // массив нарядов на работу
      baseOrdersArr: [],
      // если есть хоть одна строка в режиме редактирования блокируем кнопку "Команда"
      isCommandButtonDisabled: false,
      // введен ли режим "редактировать все"
      isEditAllEnable: false,
      // триггер сохранения всех строк с изменениями
      isSaveAll: false,
    }
  },

  computed: {

    isMonitorTableByDayCreateDialogVisible() {
      if (this.op == 'create' && this.date_index && (!this.route_id)) {
        return true;
      } else {
        return false;
      }
    },

    isMonitorTableByDayAndRouteCreateDialogVisible() {
      if (this.op == 'create' && this.date_index && this.route_id) {
        return true;
      } else {
        return false;
      }
    },

    isSaveButtonDisabled() {
      for (let i = 0; i < this.baseOrdersArr.length; i++) {
        if (this.baseOrdersArr[i].driver_id && !this.baseOrdersArr[i].transport_id) {
          return true;
        }
      }
      return false;
    },

    dateForPageHeader() {
      if (this.requestedOrderDate) {
        return 'за ' + this.requestedOrderDate;
      } else {
        return '';
      }
    },

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: false,
        edit: false,
        delete: false,
        refresh: true,
      }
    },

    // видимость диалога
    isDialogVisible() {
      if ((this.op == 'clear' && (!this.plan_depot_id) && (!this.route_id) && (!this.base_order_id))) {
        return true;
      }
      return false;
    },

    // Нажата кнопка "Восстановить наряд по транспортному предприятию"
    isClearBaseOrderByDepotDialogVisible() {
      if (this.op == 'clear' && this.plan_depot_id && (!this.route_id) && (!this.base_order_id)) {
        return true;
      }
      return false;
    },

    // Нажата кнопка "Восстановить наряд по маршруту"
    isClearBaseOrderByRouteDialogVisible() {
      if (this.op == 'clear' && this.plan_depot_id && this.route_id && (!this.base_order_id)) {
        return true;
      }
      return false;
    },

    // Нажата кнопка "Восстановить наряд по графику"
    isClearBaseOrderByRouteGrafVisible() {
      if (this.op == 'clear' && this.plan_depot_id && this.route_id && this.base_order_id) {
        return true;
      }
      return false;
    },

    // получаем транспортное предприятие
    depot() {
      let depot = getOrderDepotTrn(this.plan_depot_id);
      if (depot) {
        return depot.long_name;
      }
      return ''
    },

    // получаем маршрут
    route() {
      let route = getOrderRouteTrn(this.route_id);
      if (route) {
        return route.route_num + ' - ' + route.route_title;
      }
      return '';
    },

    // получаем график наряда
    baseOrderGraph() {
      let baseOrder = getBaseOrder(this.base_order_id);
      if (baseOrder) {
        return baseOrder.graph;
      }
      return '';
    },

    // получаем смену наряда
    baseOrderShift() {
      let baseOrder = getBaseOrder(this.base_order_id);
      if (baseOrder) {
        return baseOrder.shift;
      }
      return '';
    },

    // получаем дату
    date() {
      return ndToDateString(this.date_index);
    }

  },

  methods: {

    // подтверждено создание мониторинговой таблицы
    onConfirmCreate() {
      // удаляем элемент
      this.createMonitorTable();
      // возвращаемся на страницу
      this.$router.replace("/trn/baseOrders");
    },

    // создать мониторинговую таблицу за указанный день
    async createMonitorTable() {
      this.isLoading = true;
      try {
        let date_index = dateToND(stringToDate(this.selectedOrderDate));
        await this.$store.dispatch('trn/doCreateMonitorTableByDay', {
          date_index: date_index,
        })
      } finally {
        this.isLoading = false;
      }
    },

    // подтверждено создание мониторинговой таблицы по маршруту за указанный день
    onConfirmCreateByDayAndRoute() {
      // удаляем элемент
      this.createMonitorTableByDayAndRoute();
      // возвращаемся на страницу
      this.$router.replace("/trn/baseOrders");
    },

    // Очистить мастер-наряд за указанный день
    async createMonitorTableByDayAndRoute() {
      this.isLoading = true;
      try {
        let date_index = dateToND(stringToDate(this.selectedOrderDate));
        await this.$store.dispatch('trn/doCreateMonitorTableByDayAndRoute', {
          date_index: date_index,
          route_id: this.selectedRow.route_id,
        })
      } finally {
        this.isLoading = false;
      }
    },

    // Нажата кнопка Создать мониторинговую таблицу за день
    createMonitorTableByRouteAndDay() {
      this.$router.replace('/trn/baseOrders/create/' + dateToND(stringToDate(this.selectedOrderDate)) + '/' + this.selectedRow.route_id);
    },

    // Нажата кнопка Создать мониторинговую таблицу за день
    createMonitorTableByDay() {
      this.$router.replace('/trn/baseOrders/create/' + dateToND(stringToDate(this.selectedOrderDate)))
    },

    // нажата кнопка сохранить все измененные строчки
    async saveAllChangedRows(rows) {
      try {
        let updated_rows = await this.$store.dispatch('trn/doEditBaseOrderArr', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          base_orders: rows,
        });
        updated_rows.forEach(row => {
          // обновляем строку
          const i = this.baseOrdersArr.findIndex(baseOrder => baseOrder.base_order_id == row.base_order_id);
          if (i >= 0) {
            this.baseOrdersArr[i] = row;
          }
          // обновляем строку в оригинальном массиве
          const index = this.originalBaseOrdersArr.findIndex(baseOrder => baseOrder.base_order_id == row.base_order_id);
          if (index >= 0) {
            this.originalBaseOrdersArr[index] = row;
          }
        })
      } finally {
        this.isLoading = false;
        this.isEditAllEnable = false;
        this.isSaveAll = false;
      }
    },

    // нажата кнопка "восстановить наряд" или "применить наряд"
    async onClearBaseOrder() {
      try {
        this.isLoading = true;
        // let plan_depot_id_list = [];
        // plan_depot_list.map(value => {
        //   plan_depot_id_list.push(value.depot_id);
        // });
        if (this.op == 'clear') {
          // отправляем запрос на изменение наряда
          await this.$store.dispatch('trn/doClearBaseOrder', {
            date_index: dateToND(stringToDate(this.selectedOrderDate)),
            // plan_depot_id_list: plan_depot_id_list,
          });
        }
        await this.getBaseOrders();
      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    // выключаем кнопку "Команда", в случае, если в списке редактируемых строк есть хоть одна строка,
    // включаем кнопку "Команда", в случае, если в списке редактируемых строк нет ни одной строки
    setCommandButton(value) {
      this.isCommandButtonDisabled = value;
    },

    // отменили операцию
    // Возвращаемся на исходную страницу
    onRejectOperation() {
      // // скрываем диалог
      this.$router.replace("/trn/baseOrders");
    },

    // редактирование строки наряда
    async doEditRow(row) {
      try {
        this.isLoading = true;
        // преобразуем время
        row.time_index_begin = row.__time_index_begin + row.date_index * 24 * 60 * 60;
        row.time_index_end = row.__time_index_end + row.date_index * 24 * 60 * 60;
        // отправляем запрос на изменение наряда
        let updated_row = await this.$store.dispatch('trn/doEditBaseOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          base_order_id: row.base_order_id,
          value: row,
        });

        // обновляем строку
        // this.fillData(updated_row);
        const i = this.baseOrdersArr.findIndex(baseOrder => baseOrder.base_order_id == updated_row.base_order_id);
        if (i >= 0) {
          this.baseOrdersArr[i] = updated_row;
        }
        // обновляем строку в оригинальном массиве
        const index = this.originalBaseOrdersArr.findIndex(baseOrder => baseOrder.base_order_id == updated_row.base_order_id);
        if (index >= 0) {
          this.originalBaseOrdersArr[index] = updated_row;
        }

      } finally {
        this.isLoading = false;
      }
    },

    // Восстановление наряда по транспортному предприятию
    async onConfirmClearBaseOrderByDepot() {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение наряда
        await this.$store.dispatch('trn/doClearBaseOrderByDepot', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          plan_depot_id: this.plan_depot_id,
        });
        await this.getBaseOrders();
      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    // Восстановление наряда по маршруту
    async onConfirmClearBaseOrderByRoute() {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение наряда
        await this.$store.dispatch('trn/doClearBaseOrderByDepotAndRoute', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          plan_depot_id: this.plan_depot_id,
          route_id: this.route_id
        });
        await this.getBaseOrders();
      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    // Восстановление наряда по графику
    async onConfirmClearBaseOrderByGraph() {
      try {
        this.isLoading = true;
        // отправляем запрос на изменение наряда
        await this.$store.dispatch('trn/doClearBaseOrderByDepotAndRouteAndBaseOrder', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
          plan_depot_id: this.plan_depot_id,
          route_id: this.route_id,
          base_order_id: this.base_order_id,
        });
        await this.getBaseOrders();
      } finally {
        this.isLoading = false;
        this.onRejectOperation();
      }
    },

    async getBaseOrders() {
      let baseOrdersArr = await this.$store.dispatch('trn/doFetchBaseOrders', {
        // т.к. дата лежит в строке, конвертируем её сначала в Date, а потом в date_index (кол-во дней с 01.01.2000)
        date_index: dateToND(stringToDate(this.selectedOrderDate)),
        force: true
      })
      if (baseOrdersArr) {
        this.baseOrdersArr = baseOrdersArr;
        baseOrdersArr.forEach(baseOrder => {
          // this.fillData(baseOrder);
          this.originalBaseOrdersArr.push({...baseOrder});
        })
      }
    },

    // нажата кнопка "Очистить всё"
    showDepotsForClear() {
      this.$router.push('/trn/baseOrders/clear/' + dateToND(stringToDate(this.selectedOrderDate)));
    },

    // Нажата кнопка "Разделить график"
    showSplitBaseOrderDialog() {
      this.$router.replace('/trn/baseOrders/split/' + this.selectedRow.base_order_id);
    },

    // Нажата кнопка "Восстановить парк"
    showClearBaseOrderByDepotDialog() {
      this.$router.replace('/trn/baseOrders/clear/' + dateToND(stringToDate(this.selectedOrderDate)) + '/' + this.selectedRow.plan_depot_id);
    },

    // Нажата кнопка "Восстановить маршрут"
    showClearBaseOrderByRouteDialog() {
      this.$router.replace('/trn/baseOrders/clear/' + dateToND(stringToDate(this.selectedOrderDate)) + '/' + this.selectedRow.plan_depot_id + '/' + this.selectedRow.route_id);
    },

    // Нажата кнопка "Восстановить график"
    showClearBaseOrderByGraphDialog() {
      this.$router.replace('/trn/baseOrders/clear/' + dateToND(stringToDate(this.selectedOrderDate)) + '/' + this.selectedRow.plan_depot_id
          + '/' + this.selectedRow.route_id + '/' + this.selectedRow.base_order_id);
    },

    fillData(baseOrder) {
      // // определяем ТС, (если есть)
      // let ts = getOrderVehicle(manualOrder.transport_id);
      // if (ts) {
      //   manualOrder.__garage_num = ts.garage_num;
      //   manualOrder.__state_num = ts.state_num;
      // }
      // // определяем водителя (если выбран)
      // let driver = getOrderDriver(manualOrder.driver_id);
      // if (driver) {
      //   manualOrder.__personnel_num = driver.personnel_num;
      //   manualOrder.__driver_name = driver.last_name + ' ' + driver.first_name.substring(0, 1) + '.' + driver.middle_name.substring(0, 1) + '.';
      // }

      // определяем фактический парк
      let fact_depot = getOrderDepotTrn(baseOrder.fact_depot_id);
      if (fact_depot) {
        baseOrder.fact_depot_title = fact_depot.long_name;
      }
    },

    // нажата кнопка "Обновить"
    onTableRefresh() {
      // загружаем повторно наряды на работу
      this.downloadBaseOrders();
    },

    // загрузка нарядов на работу
    async downloadBaseOrders() {
      try {
        this.isLoading = true;
        this.selectedRow = null;
        this.isEditAllEnable = false;
        // this.manualOrdersArr = this.$store.getters['kvr/getManualOrders'];

        // делаем запрос доступных для формирования наряда транспортных средств
        await this.$store.dispatch('trn/doFetchOrderVehicles', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
        });
        // загрузка доступных для формирования наряда водителей
        await this.$store.dispatch('trn/doFetchOrderDrivers', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
        });
        // загрузка доступных для формирования наряда кондуктора
        await this.$store.dispatch('trn/doFetchOrderConductors', {
          date_index: dateToND(stringToDate(this.selectedOrderDate)),
        });
        this.requestedOrderDate = this.selectedOrderDate;

        // делаем запрос
        await this.getBaseOrders();

      } finally {
        this.isLoading = false;
      }
    },

    // выбрана новая строка в таблице
    onRowSelect(selectedRow) {
      this.selectedRow = selectedRow;
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    onEditAllClick() {
      this.isEditAllEnable = true;
    },

    async onSaveAll() {
      this.isLoading = true;
      this.isSaveAll = true;
    },

    onCancelAll() {
      this.isEditAllEnable = false;
      this.baseOrdersArr = [];
      this.originalBaseOrdersArr.forEach(value =>{
          this.baseOrdersArr.push({...value});
      });
    }

  },

  async created() {
    // загружаем предприятия
    await this.$store.dispatch('trn/doFetchOrderDepots');
    // // загружаем транспортные предприятия
    // await this.$store.dispatch('trn/doFetchOrderAgencies');
    // загрузка маршрутов
    await this.$store.dispatch('trn/doFetchOrderRoutes');
    // загрузка организационно-правовых форм
    await this.$store.dispatch('ref/doFetchLegalForms');
    // загрузка типов транспорта
    await this.$store.dispatch('ref/doFetchTransportTypes');
  }

}
</script>

<style scoped>

</style>
