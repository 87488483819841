import { DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОКУМЕНТОВ МАРШРУТОВ

export default {

    actions: {
        // запрос объекта
        doFetchRouteDoc: (context, payload) => {
            return DoGet(
                '/multipass/routeDocs/' + payload.route_doc_id,
                null,
                {},
                'Запрос документа маршрута',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchRouteDocs: (context, payload) => {
            return DoGet(
                '/multipass/routeDocs?route_id=' + payload.route_id,
                null,
                [],
                'Запрос документов маршрута',
                false,
                true,
            )
        },

        // добавление объекта
        doAddRouteDoc: (context, payload) => {
            return DoPost(
                '/multipass/routeDocs',
                payload,
                null,
                'Добавление документа машрута'
            )
        },

        // изменение объекта
        doEditRouteDoc: (context, payload) => {
            return DoPut(
                '/multipass/routeDocs/'+ payload.route_doc_id,
                payload,
                null,
                'Изменение документа маршрута'
            )
        },

        // удаление объекта
        doDelRouteDoc: (context, payload) => {
            return DoDelete(
                '/multipass/routeDocs/'+ payload.route_doc_id,
                null,
                'Удаление документа маршрута'
            )
        }
    }
}
