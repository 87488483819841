<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Группа видов транспорта</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="transportTypeGroupId">Код:</label>
              <input-integer
                id="transportTypeGroupId"
                class="form-control w-25"
                v-model="transportTypeGroupId"
                readonly
              />
            </div>
            <div class="form-group">
              <label for="transportTypeGroupName">Наименование:</label><req-field />
              <input-string
                  class="form-control"
                  id="transportTypeGroupName"
                  v-model="transportTypeGroupName"
                  ref="transportTypeGroupName"
                  autocomplete="off"
                  :readonly="op === 'show'"
                  required
                  error="Введите наименование"
              />
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  mixins: [ValidateFormMixin],
  props: ['item', 'op'],
    emits: ['onOK', 'onCancel'],
    data() {
      return {
        transportTypeGroupId: this.item.transport_type_group_id,
        transportTypeGroupName: this.item.transport_type_group_name,
      };
    },
    methods: {
      onSubmit() {
        if (this.validateForm(this.$refs)) {
            this.$emit('onOK', {
                transport_type_group_id: this.op === 'add' ? undefined : this.transportTypeGroupId,
                transport_type_group_name: this.transportTypeGroupName
            })
        }
      },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>