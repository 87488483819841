import {DoClearCache, DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ГЛОБАЛЬНЫХ ПАРАМЕТРОВ

export default {
    state() {
        return {
            // список объектов
            mainParams: [],
            // время обновления кэша new Date().getTime()
            mainParamsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            mainParamsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getMainParams(state) {
            return state.mainParams;
        },
    },
    mutations: {
        // установить новый список объектов
        setMainParams(state, payload) {
            state.mainParams = payload;
            state.mainParamsCache = new Date().getTime()
        },
        // изменить объект
        editMainParam(state, payload) {
            const index = state.mainParams.findIndex(param => param.key == payload.key);
            if (index >= 0) {
                state.mainParams[index] = payload;
            }
        },
    },
    actions: {
        // получить список объектов
        doFetchMainParams: (context, payload) => {
            return DoGet(
                '/system/mainParams',
                'system/setMainParams',
                [],
                'Запрос списка всех параметров',
                false,
                payload && payload.force,
                context.state.mainParamsCache,
                context.state.mainParamsCacheTimeout
            )
        },

        // очищает кэш
        doClearMainParams: (context, payload) => {
            return DoClearCache(
                'system/setMainParams',
                [],
                payload && payload.force,
                context.state.mainParamsCache,
                context.state.mainParamsCacheTimeout
            )
        },

        // изменение объекта
        doEditMainParam: (context, payload) => {
            return DoPut(
                '/system/mainParams/' + payload.module_id + '/' + payload.key,
                payload,
                'system/editMainParam',
                'Изменение параметра'
            )
        },

    }
}