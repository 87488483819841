<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-multipass-route-variants"></edit-table-panel>
</template>

<script>

import {getRouteVariantStatus, getTariffType, getTariffZoneSystem} from "@/store/MultipassHelpers";
import {getOrganization} from "@/store/RefHelpers";

export default {
  props: ['isLoading', 'rows'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "route_variant_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "date_begin",
          caption: "Дата начала",
          sortType: 'string',
          sorted: 'dsc'
        },
        {
          field: "date_end",
          caption: "Дата окончания",
          sortType: 'string',
        },
        {
          field: "__routeVariantStatusName",
          caption: "Статус",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__tariffTypeName",
          caption: "Тип тарифа",
          sortType: 'string',
        },
        {
          field: "__tariffZoneSystemName",
          caption: "Зональная система",
          sortType: 'string',
        },
        {
          field: "__orgName",
          caption: "Организация",
          sortType: 'string',
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          show: false
        },
        {
          field: "is_working",
          caption: "Действует",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_working) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.rows.map(routeVar => {

        routeVar.__id = routeVar.route_variant_id;

        const routeVariantStatus = getRouteVariantStatus(routeVar.route_variant_status_id);
        const tariffType = getTariffType(routeVar.tariff_type_id);
        const tariffZoneSystem = getTariffZoneSystem(routeVar.tariff_zone_system_id);
        const org = getOrganization(routeVar.org_id);

        routeVar.__routeVariantStatusName = routeVariantStatus && routeVariantStatus.long_name || '';
        routeVar.__tariffTypeName = tariffType && tariffType.long_name || '';
        routeVar.__tariffZoneSystemName = tariffZoneSystem && tariffZoneSystem.long_name || '';
        routeVar.__orgName = org && org.long_name || '';

        return routeVar
      });
    },
  },
};
</script>
