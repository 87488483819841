// ХРАНИЛИЩЕ ГРАФИКОВ

export default {
    actions: {
        // запрос набора графиков (для табличного отображения)
        // doFetchGraphs (context, payload) {
        /*doFetchLineScheduleGraphs() {
            return [
                {
                "row_num": 1,
                "trip_table_columns": [{
                    "column_num": 1,
                    "trip_complex": {
                        "chain_id": 1,
                        "chain_item_num": 1,
                        "schedule_graph_step_id": 135315,
                        "graph": 1,
                        "graph_title": "1",
                        "shift": "1",
                        "order_num": 3,
                        "schedule_event_id": 1,
                        "trip_type_id": 5574,
                        "trip_type_sign": "00AB",
                        "direction": 0,
                        "stop_id_begin": 3114,
                        "stop_id_end": 7171,
                        "step_time_begin": 18000,
                        "step_time_end": 20640,
                        "cp_time_list": [18200, 18900, 19560, 20400],
                        "interval_before": 0,
                        "interval_after": 0,
                        "stop_time": 4,
                        "null_trip_type_id": 5586,
                        "null_trip_type_sign": "_01BA",
                        "null_stop_id_begin": 4365,
                        "null_stop_id_end": 3114,
                        "null_step_time_begin": 17460,
                        "null_step_time_end": 17760,
                        "null_stop_time": 0,
                        "null_direction": 1
                    }
                }, {
                    "column_num": 2,
                    "trip_complex": {
                        "chain_id": 1,
                        "chain_item_num": 2,
                        "schedule_graph_step_id": 135317,
                        "graph": 1,
                        "graph_title": "",
                        "shift": "1",
                        "order_num": 5,
                        "schedule_event_id": 1,
                        "trip_type_id": 5573,
                        "trip_type_sign": "00BA",
                        "direction": 1,
                        "stop_id_begin": 7171,
                        "stop_id_end": 3114,
                        "step_time_begin": 20700,
                        "step_time_end": 24360,
                        "cp_time_list": [20700, 21060, 21860, 22400, 22960, 23600],
                        "interval_before": 62,
                        "interval_after": 0,
                        "stop_time": 1,
                        "null_trip_type_id": 0,
                        "null_trip_type_sign": "",
                        "null_stop_id_begin": 0,
                        "null_stop_id_end": 0,
                        "null_step_time_begin": 0,
                        "null_step_time_end": 0,
                        "null_stop_time": 0,
                        "null_direction": 0
                    }
                }, {
                    "column_num": 3,
                    "trip_complex": {
                        "chain_id": 1,
                        "chain_item_num": 3,
                        "schedule_graph_step_id": 135319,
                        "graph": 1,
                        "graph_title": "",
                        "shift": "1",
                        "order_num": 7,
                        "schedule_event_id": 1,
                        "trip_type_id": 5574,
                        "trip_type_sign": "00AB",
                        "direction": 0,
                        "stop_id_begin": 3114,
                        "stop_id_end": 7171,
                        "step_time_begin": 24480,
                        "step_time_end": 27840,
                        "cp_time_list": [25000, 25600, 26860, 27300],
                        "interval_before": 58,
                        "interval_after": 0,
                        "stop_time": 2,
                        "null_trip_type_id": 0,
                        "null_trip_type_sign": "",
                        "null_stop_id_begin": 0,
                        "null_stop_id_end": 0,
                        "null_step_time_begin": 0,
                        "null_step_time_end": 0,
                        "null_stop_time": 0,
                        "null_direction": 0
                    }
                }, {
                    "column_num": 4,
                    "trip_complex": {
                        "chain_id": 1,
                        "chain_item_num": 4,
                        "schedule_graph_step_id": 135321,
                        "graph": 1,
                        "graph_title": "",
                        "shift": "1",
                        "order_num": 9,
                        "schedule_event_id": 1,
                        "trip_type_id": 5573,
                        "trip_type_sign": "00BA",
                        "direction": 1,
                        "stop_id_begin": 7171,
                        "stop_id_end": 3114,
                        "step_time_begin": 27900,
                        "step_time_end": 31680,
                        "cp_time_list": [28000, 28600, 29360, 29960, 30360, 31160],
                        "interval_before": 64,
                        "interval_after": 0,
                        "stop_time": 1,
                        "null_trip_type_id": 0,
                        "null_trip_type_sign": "",
                        "null_stop_id_begin": 0,
                        "null_stop_id_end": 0,
                        "null_step_time_begin": 0,
                        "null_step_time_end": 0,
                        "null_stop_time": 0,
                        "null_direction": 0
                    }
                }, {
                    "column_num": 5,
                    "trip_complex": {
                        "chain_id": 1,
                        "chain_item_num": 5,
                        "schedule_graph_step_id": 135323,
                        "graph": 1,
                        "graph_title": "",
                        "shift": "1",
                        "order_num": 11,
                        "schedule_event_id": 1,
                        "trip_type_id": 5574,
                        "trip_type_sign": "00AB",
                        "direction": 0,
                        "stop_id_begin": 3114,
                        "stop_id_end": 7171,
                        "step_time_begin": 31980,
                        "step_time_end": 35280,
                        "cp_time_list": [32100, 33200, 34160, 34900],
                        "interval_before": 60,
                        "interval_after": 0,
                        "stop_time": 5,
                        "null_trip_type_id": 0,
                        "null_trip_type_sign": "",
                        "null_stop_id_begin": 0,
                        "null_stop_id_end": 0,
                        "null_step_time_begin": 0,
                        "null_step_time_end": 0,
                        "null_stop_time": 0,
                        "null_direction": 0
                    }
                }, {
                    "column_num": 6,
                    "trip_complex": {
                        "chain_id": 1,
                        "chain_item_num": 6,
                        "schedule_graph_step_id": 135325,
                        "graph": 1,
                        "graph_title": "",
                        "shift": "1",
                        "order_num": 13,
                        "schedule_event_id": 1,
                        "trip_type_id": 5573,
                        "trip_type_sign": "00BA",
                        "direction": 1,
                        "stop_id_begin": 7171,
                        "stop_id_end": 3114,
                        "step_time_begin": 35340,
                        "step_time_end": 39000,
                        "cp_time_list": [35700, 36100, 36860, 37300, 37960, 38200, 38860],
                        "interval_before": 62,
                        "interval_after": 0,
                        "stop_time": 1,
                        "null_trip_type_id": 0,
                        "null_trip_type_sign": "",
                        "null_stop_id_begin": 0,
                        "null_stop_id_end": 0,
                        "null_step_time_begin": 0,
                        "null_step_time_end": 0,
                        "null_stop_time": 0,
                        "null_direction": 0
                    }
                }, {
                    "column_num": 7,
                    "trip_complex": {
                        "chain_id": 1,
                        "chain_item_num": 7,
                        "schedule_graph_step_id": 135326,
                        "graph": 1,
                        "graph_title": "",
                        "shift": "1",
                        "order_num": 14,
                        "schedule_event_id": 5,
                        "trip_type_id": 0,
                        "trip_type_sign": "",
                        "direction": 0,
                        "stop_id_begin": 3114,
                        "stop_id_end": 3114,
                        "step_time_begin": 39000,
                        "step_time_end": 44820,
                        "interval_before": 0,
                        "interval_after": 0,
                        "stop_time": 0,
                        "null_trip_type_id": 0,
                        "null_trip_type_sign": "",
                        "null_stop_id_begin": 0,
                        "null_stop_id_end": 0,
                        "null_step_time_begin": 0,
                        "null_step_time_end": 0,
                        "null_stop_time": 0,
                        "null_direction": 0
                    }
                },
                    {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 2,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135327,
                            "graph": 1,
                            "graph_title": "1",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 44820,
                            "step_time_end": 48120,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 2,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135329,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 48180,
                            "step_time_end": 51840,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 2,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135331,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 52080,
                            "step_time_end": 55380,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 2,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135333,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 55440,
                            "step_time_end": 59160,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 2,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135334,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 1,
                            "schedule_event_id": 10,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 59160,
                            "step_time_end": 59460,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 3,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135335,
                            "graph": 1,
                            "graph_title": "1",
                            "shift": "2",
                            "order_num": 2,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "У03BA",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 59460,
                            "step_time_end": 62880,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 3,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135337,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 62940,
                            "step_time_end": 66720,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 3,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135339,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 67020,
                            "step_time_end": 70440,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 3,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135341,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 8,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 70500,
                            "step_time_end": 73740,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 3,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135342,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 9,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 73740,
                            "step_time_end": 77880,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 4,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135343,
                            "graph": 1,
                            "graph_title": "1",
                            "shift": "2",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 77880,
                            "step_time_end": 80520,
                            "interval_before": 44,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 4,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135345,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 80580,
                            "step_time_end": 83580,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 4,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135347,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 14,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 83820,
                            "step_time_end": 86460,
                            "interval_before": 48,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 4,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135349,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 86520,
                            "step_time_end": 89520,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 4,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 1,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 19,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 89760,
                            "step_time_end": 90240,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 89760,
                            "null_step_time_end": 90240,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                ]
            },
                {
                    "row_num": 2,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 5,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135354,
                            "graph": 2,
                            "graph_title": "2",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5579,
                            "trip_type_sign": "У01BA",
                            "direction": 1,
                            "stop_id_begin": 4280,
                            "stop_id_end": 3114,
                            "step_time_begin": 18660,
                            "step_time_end": 20520,
                            "cp_time_list": [19000, 19320, 19960, 20000, 20230],
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 5590,
                            "null_trip_type_sign": "_02BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 4280,
                            "null_step_time_begin": 17220,
                            "null_step_time_end": 18600,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 6,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135356,
                            "graph": 2,
                            "graph_title": "2",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 21480,
                            "step_time_end": 24780,
                            "cp_time_list": [21780, 22380, 23160, 23700],
                            "interval_before": 71,
                            "interval_after": 0,
                            "stop_time": 16,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 6,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135358,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 24840,
                            "step_time_end": 28620,
                            "cp_time_list": [25000, 25680, 26160, 26800, 27160, 27900],
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 6,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135360,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 28920,
                            "step_time_end": 32280,
                            "cp_time_list": [29000, 29380, 30160, 31800],
                            "interval_before": 61,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 6,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135362,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 32340,
                            "step_time_end": 36000,
                            "cp_time_list": [33000, 33480, 33960, 34500, 35160, 35700],
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 6,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135363,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 36000,
                            "step_time_end": 40140,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 7,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135364,
                            "graph": 2,
                            "graph_title": "2",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 40140,
                            "step_time_end": 43440,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 7,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135366,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 43500,
                            "step_time_end": 47160,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 7,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135368,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 47460,
                            "step_time_end": 50760,
                            "interval_before": 60,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 7,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135370,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 50820,
                            "step_time_end": 54480,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 7,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135371,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 1,
                            "schedule_event_id": 10,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 54480,
                            "step_time_end": 55260,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 8,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135372,
                            "graph": 2,
                            "graph_title": "2",
                            "shift": "2",
                            "order_num": 2,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 55260,
                            "step_time_end": 58560,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 8,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135374,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 58620,
                            "step_time_end": 62400,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 8,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135376,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 62760,
                            "step_time_end": 66180,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 8,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135378,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 8,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 66240,
                            "step_time_end": 70020,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 8,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135379,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 9,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 70020,
                            "step_time_end": 73140,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 9,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135380,
                            "graph": 2,
                            "graph_title": "2",
                            "shift": "2",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 73140,
                            "step_time_end": 75780,
                            "interval_before": 44,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 9,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135382,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 75840,
                            "step_time_end": 78840,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 9,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135384,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 14,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 78960,
                            "step_time_end": 81600,
                            "interval_before": 46,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 9,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135386,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 81660,
                            "step_time_end": 84660,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 9,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135388,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 18,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 85560,
                            "step_time_end": 88200,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 15,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 6,
                        "trip_complex": {
                            "chain_id": 9,
                            "chain_item_num": 6,
                            "schedule_graph_step_id": 135390,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 20,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 88260,
                            "step_time_end": 91260,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 7,
                        "trip_complex": {
                            "chain_id": 9,
                            "chain_item_num": 7,
                            "schedule_graph_step_id": 0,
                            "graph": 2,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 23,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 91500,
                            "step_time_end": 91980,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 91500,
                            "null_step_time_end": 91980,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                },
                {
                    "row_num": 3,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 10,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135395,
                            "graph": 3,
                            "graph_title": "3",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 18600,
                            "step_time_end": 21240,
                            "cp_time_list": [18900, 19100, 19800, 2500],
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 18180,
                            "null_step_time_end": 18480,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 10,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135397,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 21300,
                            "step_time_end": 25080,
                            "cp_time_list": [21600, 22300, 22900, 23300, 23900, 24500],
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 10,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135399,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 25260,
                            "step_time_end": 28620,
                            "cp_time_list": [25600, 26300, 26900, 27900],
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 10,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135401,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 28680,
                            "step_time_end": 32460,
                            "cp_time_list": [29000, 29500, 30100, 30900, 31600, 32160],
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 10,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135403,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 32700,
                            "step_time_end": 36000,
                            "cp_time_list": [33100, 34500, 35100, 35600],
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 6,
                        "trip_complex": {
                            "chain_id": 10,
                            "chain_item_num": 6,
                            "schedule_graph_step_id": 135405,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 36060,
                            "step_time_end": 39720,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 7,
                        "trip_complex": {
                            "chain_id": 10,
                            "chain_item_num": 7,
                            "schedule_graph_step_id": 135406,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 39720,
                            "step_time_end": 43740,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 11,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135407,
                            "graph": 3,
                            "graph_title": "3",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 43740,
                            "step_time_end": 47040,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 11,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135409,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 47100,
                            "step_time_end": 50760,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 11,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135411,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 51000,
                            "step_time_end": 54300,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 11,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135413,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 54360,
                            "step_time_end": 58020,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 11,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135414,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 1,
                            "schedule_event_id": 10,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 58020,
                            "step_time_end": 58200,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 12,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135415,
                            "graph": 3,
                            "graph_title": "3",
                            "shift": "2",
                            "order_num": 2,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 58200,
                            "step_time_end": 61620,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 12,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135417,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 61680,
                            "step_time_end": 65460,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 12,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135419,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 65820,
                            "step_time_end": 69240,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 12,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135421,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 8,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 69300,
                            "step_time_end": 72780,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 12,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135422,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 9,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 72780,
                            "step_time_end": 76620,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 13,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135423,
                            "graph": 3,
                            "graph_title": "3",
                            "shift": "2",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 76620,
                            "step_time_end": 79260,
                            "interval_before": 44,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 13,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135425,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 79320,
                            "step_time_end": 82320,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 13,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135427,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 14,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 82620,
                            "step_time_end": 85260,
                            "interval_before": 49,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 13,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135429,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 85320,
                            "step_time_end": 88320,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 13,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135431,
                            "graph": 3,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 18,
                            "schedule_event_id": 1,
                            "trip_type_id": 5578,
                            "trip_type_sign": "У01AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4280,
                            "step_time_begin": 88500,
                            "step_time_end": 90360,
                            "interval_before": 34,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 14,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135433,
                            "graph": 3,
                            "graph_title": "3",
                            "shift": "2",
                            "order_num": 20,
                            "schedule_event_id": 1,
                            "trip_type_id": 5579,
                            "trip_type_sign": "У01BA",
                            "direction": 1,
                            "stop_id_begin": 4280,
                            "stop_id_end": 3114,
                            "step_time_begin": 90420,
                            "step_time_end": 92280,
                            "interval_before": 32,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 15,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 0,
                            "graph": 3,
                            "graph_title": "3",
                            "shift": "2",
                            "order_num": 23,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 92520,
                            "step_time_end": 93000,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 92520,
                            "null_step_time_end": 93000,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 4,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 16,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135438,
                            "graph": 4,
                            "graph_title": "4",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5581,
                            "trip_type_sign": "У02BA",
                            "direction": 1,
                            "stop_id_begin": 4375,
                            "stop_id_end": 3114,
                            "step_time_begin": 19860,
                            "step_time_end": 21960,
                            "cp_time_list": [19960, 20200, 20600, 20910, 21500, 21760],
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 5588,
                            "null_trip_type_sign": "_03BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 4375,
                            "null_step_time_begin": 18300,
                            "null_step_time_end": 19800,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 17,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135440,
                            "graph": 4,
                            "graph_title": "4",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 22140,
                            "step_time_end": 25500,
                            "cp_time_list": [22240, 23200, 23900, 24700],
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 17,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135442,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 25560,
                            "step_time_end": 29340,
                            "cp_time_list": [25960, 26600, 27300, 27910, 28400, 29000],
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 17,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135444,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 29820,
                            "step_time_end": 33180,
                            "cp_time_list": [30000, 31200, 31900, 32700],
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 8,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 17,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135446,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 33240,
                            "step_time_end": 36900,
                            "cp_time_list": [25960, 26600, 27300, 27910, 28400, 29000],
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 17,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135447,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 36900,
                            "step_time_end": 42060,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 18,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135448,
                            "graph": 4,
                            "graph_title": "4",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 42060,
                            "step_time_end": 45360,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 18,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135450,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 45420,
                            "step_time_end": 49080,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 18,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135452,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 49320,
                            "step_time_end": 52620,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 18,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135454,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 52680,
                            "step_time_end": 56340,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 18,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135455,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 1,
                            "schedule_event_id": 10,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 56340,
                            "step_time_end": 56520,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 19,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135456,
                            "graph": 4,
                            "graph_title": "4",
                            "shift": "2",
                            "order_num": 2,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 56520,
                            "step_time_end": 59940,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 19,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135458,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 60000,
                            "step_time_end": 63780,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 19,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135460,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 64380,
                            "step_time_end": 67800,
                            "interval_before": 67,
                            "interval_after": 0,
                            "stop_time": 10,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 19,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135462,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 8,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 67860,
                            "step_time_end": 71640,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 19,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135463,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 9,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 71640,
                            "step_time_end": 74160,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 20,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135464,
                            "graph": 4,
                            "graph_title": "4",
                            "shift": "2",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 74160,
                            "step_time_end": 76800,
                            "interval_before": 44,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 20,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135466,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 76860,
                            "step_time_end": 79860,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 20,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135468,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 14,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 80280,
                            "step_time_end": 82920,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 7,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 20,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135470,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 82980,
                            "step_time_end": 85980,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 20,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135472,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 18,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 87300,
                            "step_time_end": 89940,
                            "interval_before": 66,
                            "interval_after": 0,
                            "stop_time": 22,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 6,
                        "trip_complex": {
                            "chain_id": 20,
                            "chain_item_num": 6,
                            "schedule_graph_step_id": 135474,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 20,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 90000,
                            "step_time_end": 93000,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 7,
                        "trip_complex": {
                            "chain_id": 20,
                            "chain_item_num": 7,
                            "schedule_graph_step_id": 0,
                            "graph": 4,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 23,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 93240,
                            "step_time_end": 93720,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 93240,
                            "null_step_time_end": 93720,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                },
                {
                    "row_num": 5,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 21,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135479,
                            "graph": 5,
                            "graph_title": "5",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 19140,
                            "step_time_end": 21840,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 18600,
                            "null_step_time_end": 18900,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 21,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135481,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 21900,
                            "step_time_end": 25680,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 21,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135483,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 26220,
                            "step_time_end": 29580,
                            "interval_before": 65,
                            "interval_after": 0,
                            "stop_time": 9,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 21,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135485,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 29640,
                            "step_time_end": 33420,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 21,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135486,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 33420,
                            "step_time_end": 36000,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 22,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135487,
                            "graph": 5,
                            "graph_title": "5",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 36000,
                            "step_time_end": 39300,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 22,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135489,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 39360,
                            "step_time_end": 43020,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 22,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135491,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 43200,
                            "step_time_end": 46500,
                            "interval_before": 58,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 22,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135493,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 46560,
                            "step_time_end": 50220,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 22,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135495,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 50460,
                            "step_time_end": 53760,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 6,
                        "trip_complex": {
                            "chain_id": 22,
                            "chain_item_num": 6,
                            "schedule_graph_step_id": 135497,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 53820,
                            "step_time_end": 57480,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 7,
                        "trip_complex": {
                            "chain_id": 22,
                            "chain_item_num": 7,
                            "schedule_graph_step_id": 135498,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 1,
                            "schedule_event_id": 10,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 57480,
                            "step_time_end": 57660,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 23,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135499,
                            "graph": 5,
                            "graph_title": "5",
                            "shift": "2",
                            "order_num": 2,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 57660,
                            "step_time_end": 61080,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 23,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135501,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 61140,
                            "step_time_end": 64920,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 23,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135503,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 65520,
                            "step_time_end": 68940,
                            "interval_before": 67,
                            "interval_after": 0,
                            "stop_time": 10,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 23,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135505,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 8,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 69000,
                            "step_time_end": 72600,
                            "interval_before": 61,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 23,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135506,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 9,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 72600,
                            "step_time_end": 75360,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 24,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135507,
                            "graph": 5,
                            "graph_title": "5",
                            "shift": "2",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 75360,
                            "step_time_end": 78000,
                            "interval_before": 44,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 24,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135509,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 78060,
                            "step_time_end": 81060,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 24,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135511,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 14,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 81420,
                            "step_time_end": 84060,
                            "interval_before": 50,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 24,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135513,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 84120,
                            "step_time_end": 87120,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 24,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 5,
                            "graph_title": "",
                            "shift": "2",
                            "order_num": 19,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 87360,
                            "step_time_end": 87840,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 87360,
                            "null_step_time_end": 87840,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 6,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 25,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135518,
                            "graph": 6,
                            "graph_title": "6",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5579,
                            "trip_type_sign": "У01BA",
                            "direction": 1,
                            "stop_id_begin": 4280,
                            "stop_id_end": 3114,
                            "step_time_begin": 20460,
                            "step_time_end": 22320,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 5590,
                            "null_trip_type_sign": "_02BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 4280,
                            "null_step_time_begin": 19020,
                            "null_step_time_end": 20400,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 26,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135520,
                            "graph": 6,
                            "graph_title": "6",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 22860,
                            "step_time_end": 26220,
                            "interval_before": 65,
                            "interval_after": 0,
                            "stop_time": 9,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 26,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135522,
                            "graph": 6,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 26280,
                            "step_time_end": 30060,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 26,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135523,
                            "graph": 6,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 8,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 30060,
                            "step_time_end": 40860,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 27,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135524,
                            "graph": 6,
                            "graph_title": "6",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 40860,
                            "step_time_end": 44160,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 27,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135526,
                            "graph": 6,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 44220,
                            "step_time_end": 47880,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 27,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135528,
                            "graph": 6,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 48120,
                            "step_time_end": 51420,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 27,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135530,
                            "graph": 6,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 51480,
                            "step_time_end": 55140,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 27,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135531,
                            "graph": 6,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 16,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 55140,
                            "step_time_end": 57120,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 28,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135532,
                            "graph": 6,
                            "graph_title": "6",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 57120,
                            "step_time_end": 60540,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 28,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135534,
                            "graph": 6,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 60600,
                            "step_time_end": 64380,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 28,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135536,
                            "graph": 6,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 64740,
                            "step_time_end": 68160,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 28,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135538,
                            "graph": 6,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 23,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 68220,
                            "step_time_end": 72000,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 28,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 6,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 26,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 72120,
                            "step_time_end": 72600,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 72120,
                            "null_step_time_end": 72600,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 7,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 29,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135543,
                            "graph": 7,
                            "graph_title": "7",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 19740,
                            "step_time_end": 22620,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 19320,
                            "null_step_time_end": 19620,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 29,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135545,
                            "graph": 7,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 22680,
                            "step_time_end": 26460,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 29,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135547,
                            "graph": 7,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 26640,
                            "step_time_end": 30000,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 29,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135549,
                            "graph": 7,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 30060,
                            "step_time_end": 33780,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 29,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135550,
                            "graph": 7,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 33780,
                            "step_time_end": 39060,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 30,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135551,
                            "graph": 7,
                            "graph_title": "7",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 39060,
                            "step_time_end": 42360,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 30,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135553,
                            "graph": 7,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 42420,
                            "step_time_end": 46080,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 30,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135554,
                            "graph": 7,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 46080,
                            "step_time_end": 53280,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 31,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135556,
                            "graph": 7,
                            "graph_title": "7",
                            "shift": "1",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 53520,
                            "step_time_end": 56820,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 31,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135558,
                            "graph": 7,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 18,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 56880,
                            "step_time_end": 60660,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 31,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135560,
                            "graph": 7,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 20,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 61200,
                            "step_time_end": 64620,
                            "interval_before": 66,
                            "interval_after": 0,
                            "stop_time": 9,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 31,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135562,
                            "graph": 7,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 22,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 64680,
                            "step_time_end": 68460,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 31,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135564,
                            "graph": 7,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 1,
                            "trip_type_id": 5580,
                            "trip_type_sign": "У02AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4375,
                            "step_time_begin": 68640,
                            "step_time_end": 70740,
                            "interval_before": 38,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 32,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 0,
                            "graph": 7,
                            "graph_title": "7",
                            "shift": "1",
                            "order_num": 27,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 1,
                            "stop_id_begin": 4375,
                            "stop_id_end": 4365,
                            "step_time_begin": 70860,
                            "step_time_end": 72600,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 5589,
                            "null_trip_type_sign": "_03AB",
                            "null_stop_id_begin": 4375,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 70860,
                            "null_step_time_end": 72600,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 8,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 33,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135569,
                            "graph": 8,
                            "graph_title": "8",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 20280,
                            "step_time_end": 23340,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 19740,
                            "null_step_time_end": 20040,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 33,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135570,
                            "graph": 8,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 23340,
                            "step_time_end": 27120,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 33,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135572,
                            "graph": 8,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 27480,
                            "step_time_end": 30840,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 33,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135574,
                            "graph": 8,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 8,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 30900,
                            "step_time_end": 34620,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 33,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135575,
                            "graph": 8,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 34620,
                            "step_time_end": 39660,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 34,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135576,
                            "graph": 8,
                            "graph_title": "8",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 39660,
                            "step_time_end": 42960,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 34,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135578,
                            "graph": 8,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 43020,
                            "step_time_end": 46680,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 34,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135579,
                            "graph": 8,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 46680,
                            "step_time_end": 53880,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 35,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135581,
                            "graph": 8,
                            "graph_title": "8",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 54840,
                            "step_time_end": 58140,
                            "interval_before": 71,
                            "interval_after": 0,
                            "stop_time": 16,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 35,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135583,
                            "graph": 8,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 58200,
                            "step_time_end": 61980,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 35,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135585,
                            "graph": 8,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 62160,
                            "step_time_end": 65580,
                            "interval_before": 60,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 35,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135587,
                            "graph": 8,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 65640,
                            "step_time_end": 69420,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 35,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 8,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 69660,
                            "step_time_end": 70140,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 69660,
                            "null_step_time_end": 70140,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 9,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 36,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135812,
                            "graph": 9,
                            "graph_title": "9",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5579,
                            "trip_type_sign": "У01BA",
                            "direction": 1,
                            "stop_id_begin": 4280,
                            "stop_id_end": 3114,
                            "step_time_begin": 21540,
                            "step_time_end": 23400,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 5590,
                            "null_trip_type_sign": "_02BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 4280,
                            "null_step_time_begin": 20100,
                            "null_step_time_end": 21480,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 37,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135814,
                            "graph": 9,
                            "graph_title": "9",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 23700,
                            "step_time_end": 27060,
                            "interval_before": 61,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 37,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135816,
                            "graph": 9,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 27120,
                            "step_time_end": 30900,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 37,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135817,
                            "graph": 9,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 8,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 30900,
                            "step_time_end": 42660,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 38,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135818,
                            "graph": 9,
                            "graph_title": "9",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 42660,
                            "step_time_end": 45960,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 38,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135820,
                            "graph": 9,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 46020,
                            "step_time_end": 49680,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 38,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135822,
                            "graph": 9,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 49920,
                            "step_time_end": 53220,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 38,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135824,
                            "graph": 9,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 53280,
                            "step_time_end": 56940,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 38,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135825,
                            "graph": 9,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 16,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 56940,
                            "step_time_end": 59040,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 39,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135826,
                            "graph": 9,
                            "graph_title": "9",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 59040,
                            "step_time_end": 62460,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 39,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135828,
                            "graph": 9,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 62520,
                            "step_time_end": 66300,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 39,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135830,
                            "graph": 9,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 66600,
                            "step_time_end": 70020,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 39,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135832,
                            "graph": 9,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 23,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 70080,
                            "step_time_end": 73500,
                            "interval_before": 58,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 39,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 9,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 26,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 73740,
                            "step_time_end": 74220,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 73740,
                            "null_step_time_end": 74220,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 10,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 40,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135617,
                            "graph": 10,
                            "graph_title": "10",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 20880,
                            "step_time_end": 24060,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 20340,
                            "null_step_time_end": 20640,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 40,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135619,
                            "graph": 10,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 24120,
                            "step_time_end": 27900,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 40,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135621,
                            "graph": 10,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 28440,
                            "step_time_end": 31800,
                            "interval_before": 65,
                            "interval_after": 0,
                            "stop_time": 9,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 40,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135623,
                            "graph": 10,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 31860,
                            "step_time_end": 35520,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 40,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135624,
                            "graph": 10,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 35520,
                            "step_time_end": 44280,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    },
                        {
                            "column_num": 1,
                            "trip_complex": {
                                "chain_id": 41,
                                "chain_item_num": 1,
                                "schedule_graph_step_id": 135625,
                                "graph": 10,
                                "graph_title": "10",
                                "shift": "1",
                                "order_num": 11,
                                "schedule_event_id": 1,
                                "trip_type_id": 5574,
                                "trip_type_sign": "00AB",
                                "direction": 0,
                                "stop_id_begin": 3114,
                                "stop_id_end": 7171,
                                "step_time_begin": 44280,
                                "step_time_end": 47580,
                                "interval_before": 55,
                                "interval_after": 0,
                                "stop_time": 0,
                                "null_trip_type_id": 0,
                                "null_trip_type_sign": "",
                                "null_stop_id_begin": 0,
                                "null_stop_id_end": 0,
                                "null_step_time_begin": 0,
                                "null_step_time_end": 0,
                                "null_stop_time": 0,
                                "null_direction": 0
                            }
                        }, {
                            "column_num": 2,
                            "trip_complex": {
                                "chain_id": 41,
                                "chain_item_num": 2,
                                "schedule_graph_step_id": 135627,
                                "graph": 10,
                                "graph_title": "",
                                "shift": "1",
                                "order_num": 13,
                                "schedule_event_id": 1,
                                "trip_type_id": 5573,
                                "trip_type_sign": "00BA",
                                "direction": 1,
                                "stop_id_begin": 7171,
                                "stop_id_end": 3114,
                                "step_time_begin": 47640,
                                "step_time_end": 51300,
                                "interval_before": 62,
                                "interval_after": 0,
                                "stop_time": 1,
                                "null_trip_type_id": 0,
                                "null_trip_type_sign": "",
                                "null_stop_id_begin": 0,
                                "null_stop_id_end": 0,
                                "null_step_time_begin": 0,
                                "null_step_time_end": 0,
                                "null_stop_time": 0,
                                "null_direction": 0
                            }
                        }, {
                            "column_num": 3,
                            "trip_complex": {
                                "chain_id": 41,
                                "chain_item_num": 3,
                                "schedule_graph_step_id": 135628,
                                "graph": 10,
                                "graph_title": "",
                                "shift": "1",
                                "order_num": 14,
                                "schedule_event_id": 5,
                                "trip_type_id": 0,
                                "trip_type_sign": "",
                                "direction": 0,
                                "stop_id_begin": 3114,
                                "stop_id_end": 3114,
                                "step_time_begin": 51300,
                                "step_time_end": 56700,
                                "interval_before": 0,
                                "interval_after": 0,
                                "stop_time": 0,
                                "null_trip_type_id": 0,
                                "null_trip_type_sign": "",
                                "null_stop_id_begin": 0,
                                "null_stop_id_end": 0,
                                "null_step_time_begin": 0,
                                "null_step_time_end": 0,
                                "null_stop_time": 0,
                                "null_direction": 0
                            }
                        }, {
                            "column_num": 1,
                            "trip_complex": {
                                "chain_id": 42,
                                "chain_item_num": 1,
                                "schedule_graph_step_id": 135630,
                                "graph": 10,
                                "graph_title": "10",
                                "shift": "1",
                                "order_num": 16,
                                "schedule_event_id": 1,
                                "trip_type_id": 5571,
                                "trip_type_sign": "И02AB",
                                "direction": 0,
                                "stop_id_begin": 3114,
                                "stop_id_end": 20157,
                                "step_time_begin": 57300,
                                "step_time_end": 60180,
                                "interval_before": 58,
                                "interval_after": 0,
                                "stop_time": 10,
                                "null_trip_type_id": 0,
                                "null_trip_type_sign": "",
                                "null_stop_id_begin": 0,
                                "null_stop_id_end": 0,
                                "null_step_time_begin": 0,
                                "null_step_time_end": 0,
                                "null_stop_time": 0,
                                "null_direction": 0
                            }
                        }, {
                            "column_num": 1,
                            "trip_complex": {
                                "chain_id": 43,
                                "chain_item_num": 1,
                                "schedule_graph_step_id": 135632,
                                "graph": 10,
                                "graph_title": "10",
                                "shift": "1",
                                "order_num": 18,
                                "schedule_event_id": 1,
                                "trip_type_id": 5577,
                                "trip_type_sign": "И02BA",
                                "direction": 1,
                                "stop_id_begin": 20157,
                                "stop_id_end": 3114,
                                "step_time_begin": 60300,
                                "step_time_end": 63360,
                                "interval_before": 53,
                                "interval_after": 0,
                                "stop_time": 2,
                                "null_trip_type_id": 0,
                                "null_trip_type_sign": "",
                                "null_stop_id_begin": 0,
                                "null_stop_id_end": 0,
                                "null_step_time_begin": 0,
                                "null_step_time_end": 0,
                                "null_stop_time": 0,
                                "null_direction": 0
                            }
                        }, {
                            "column_num": 1,
                            "trip_complex": {
                                "chain_id": 44,
                                "chain_item_num": 1,
                                "schedule_graph_step_id": 135634,
                                "graph": 10,
                                "graph_title": "10",
                                "shift": "1",
                                "order_num": 20,
                                "schedule_event_id": 1,
                                "trip_type_id": 5574,
                                "trip_type_sign": "00AB",
                                "direction": 0,
                                "stop_id_begin": 3114,
                                "stop_id_end": 7171,
                                "step_time_begin": 64020,
                                "step_time_end": 67440,
                                "interval_before": 68,
                                "interval_after": 0,
                                "stop_time": 11,
                                "null_trip_type_id": 0,
                                "null_trip_type_sign": "",
                                "null_stop_id_begin": 0,
                                "null_stop_id_end": 0,
                                "null_step_time_begin": 0,
                                "null_step_time_end": 0,
                                "null_stop_time": 0,
                                "null_direction": 0
                            }
                        }, {
                            "column_num": 2,
                            "trip_complex": {
                                "chain_id": 44,
                                "chain_item_num": 2,
                                "schedule_graph_step_id": 135636,
                                "graph": 10,
                                "graph_title": "",
                                "shift": "1",
                                "order_num": 22,
                                "schedule_event_id": 1,
                                "trip_type_id": 5573,
                                "trip_type_sign": "00BA",
                                "direction": 1,
                                "stop_id_begin": 7171,
                                "stop_id_end": 3114,
                                "step_time_begin": 67500,
                                "step_time_end": 71280,
                                "interval_before": 64,
                                "interval_after": 0,
                                "stop_time": 1,
                                "null_trip_type_id": 0,
                                "null_trip_type_sign": "",
                                "null_stop_id_begin": 0,
                                "null_stop_id_end": 0,
                                "null_step_time_begin": 0,
                                "null_step_time_end": 0,
                                "null_stop_time": 0,
                                "null_direction": 0
                            }
                        }, {
                            "column_num": 3,
                            "trip_complex": {
                                "chain_id": 44,
                                "chain_item_num": 3,
                                "schedule_graph_step_id": 0,
                                "graph": 10,
                                "graph_title": "",
                                "shift": "1",
                                "order_num": 25,
                                "schedule_event_id": 0,
                                "trip_type_id": 0,
                                "trip_type_sign": "",
                                "direction": 0,
                                "stop_id_begin": 3114,
                                "stop_id_end": 4365,
                                "step_time_begin": 71520,
                                "step_time_end": 72000,
                                "interval_before": 0,
                                "interval_after": 0,
                                "stop_time": 4,
                                "null_trip_type_id": 5587,
                                "null_trip_type_sign": "_01AB",
                                "null_stop_id_begin": 3114,
                                "null_stop_id_end": 4365,
                                "null_step_time_begin": 71520,
                                "null_step_time_end": 72000,
                                "null_stop_time": 0,
                                "null_direction": 0
                            }
                        },
                    ]
                }, {
                    "row_num": 11,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 45,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135592,
                            "graph": 11,
                            "graph_title": "11",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 26700,
                            "step_time_end": 30480,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 5572,
                            "null_trip_type_sign": "_04BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 7171,
                            "null_step_time_begin": 25620,
                            "null_step_time_end": 26640,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 45,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135594,
                            "graph": 11,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 30780,
                            "step_time_end": 34080,
                            "interval_before": 60,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 45,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135596,
                            "graph": 11,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 34140,
                            "step_time_end": 37800,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 45,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135597,
                            "graph": 11,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 8,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 37800,
                            "step_time_end": 45360,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 46,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135598,
                            "graph": 11,
                            "graph_title": "11",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 45360,
                            "step_time_end": 48660,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 46,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135600,
                            "graph": 11,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 48720,
                            "step_time_end": 52380,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 46,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135601,
                            "graph": 11,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 52380,
                            "step_time_end": 55980,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 47,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135602,
                            "graph": 11,
                            "graph_title": "11",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 55980,
                            "step_time_end": 59340,
                            "interval_before": 56,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 47,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135604,
                            "graph": 11,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 59400,
                            "step_time_end": 63180,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 47,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135606,
                            "graph": 11,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 63300,
                            "step_time_end": 66720,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 47,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135608,
                            "graph": 11,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 66780,
                            "step_time_end": 70560,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 47,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135610,
                            "graph": 11,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 70740,
                            "step_time_end": 73680,
                            "interval_before": 52,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 6,
                        "trip_complex": {
                            "chain_id": 47,
                            "chain_item_num": 6,
                            "schedule_graph_step_id": 135612,
                            "graph": 11,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 23,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 73740,
                            "step_time_end": 76740,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 7,
                        "trip_complex": {
                            "chain_id": 47,
                            "chain_item_num": 7,
                            "schedule_graph_step_id": 0,
                            "graph": 11,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 26,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 76860,
                            "step_time_end": 77340,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 76860,
                            "null_step_time_end": 77340,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 12,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 48,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135641,
                            "graph": 12,
                            "graph_title": "12",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 30300,
                            "step_time_end": 33600,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 29760,
                            "null_step_time_end": 30060,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 48,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135643,
                            "graph": 12,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 33720,
                            "step_time_end": 37380,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 48,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135645,
                            "graph": 12,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 37620,
                            "step_time_end": 40920,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 48,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135647,
                            "graph": 12,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 40980,
                            "step_time_end": 44640,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 48,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135648,
                            "graph": 12,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 44640,
                            "step_time_end": 51540,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 49,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135649,
                            "graph": 12,
                            "graph_title": "12",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 51540,
                            "step_time_end": 54840,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 49,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135651,
                            "graph": 12,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 54900,
                            "step_time_end": 58560,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 49,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135652,
                            "graph": 12,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 58560,
                            "step_time_end": 62580,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 50,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135653,
                            "graph": 12,
                            "graph_title": "12",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 62580,
                            "step_time_end": 66000,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 50,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135655,
                            "graph": 12,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 66060,
                            "step_time_end": 69840,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 50,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135657,
                            "graph": 12,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 69960,
                            "step_time_end": 73020,
                            "interval_before": 53,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 50,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135659,
                            "graph": 12,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 73080,
                            "step_time_end": 76080,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 50,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 12,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 76320,
                            "step_time_end": 76800,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 76320,
                            "null_step_time_end": 76800,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 13,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 51,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135664,
                            "graph": 13,
                            "graph_title": "13",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 25800,
                            "step_time_end": 29160,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 25380,
                            "null_step_time_end": 25680,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 51,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135666,
                            "graph": 13,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 29220,
                            "step_time_end": 33000,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 51,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135668,
                            "graph": 13,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 33540,
                            "step_time_end": 36840,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 9,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 51,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135670,
                            "graph": 13,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 36900,
                            "step_time_end": 40560,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 51,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135673,
                            "graph": 13,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 6,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 4365,
                            "stop_id_end": 4365,
                            "step_time_begin": 40860,
                            "step_time_end": 52080,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 40680,
                            "null_step_time_end": 40860,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 6,
                        "trip_complex": {
                            "chain_id": 52,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135676,
                            "graph": 13,
                            "graph_title": "13",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 52620,
                            "step_time_end": 55920,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 52080,
                            "null_step_time_end": 52380,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 7,
                        "trip_complex": {
                            "chain_id": 52,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135678,
                            "graph": 13,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 55980,
                            "step_time_end": 59700,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 8,
                        "trip_complex": {
                            "chain_id": 52,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135679,
                            "graph": 13,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 18,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 59700,
                            "step_time_end": 61680,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 53,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135680,
                            "graph": 13,
                            "graph_title": "13",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 61680,
                            "step_time_end": 65100,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 53,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135682,
                            "graph": 13,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 65160,
                            "step_time_end": 68940,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 53,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135684,
                            "graph": 13,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 23,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 69120,
                            "step_time_end": 72420,
                            "interval_before": 58,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 53,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135686,
                            "graph": 13,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 25,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 72480,
                            "step_time_end": 75480,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 53,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 13,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 28,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 75720,
                            "step_time_end": 76200,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 75720,
                            "null_step_time_end": 76200,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 14,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 54,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135691,
                            "graph": 14,
                            "graph_title": "14",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 31200,
                            "step_time_end": 34500,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 30660,
                            "null_step_time_end": 30960,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 54,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135693,
                            "graph": 14,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 34560,
                            "step_time_end": 38220,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 54,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135695,
                            "graph": 14,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 38460,
                            "step_time_end": 41760,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 54,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135697,
                            "graph": 14,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 41820,
                            "step_time_end": 45480,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 54,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135698,
                            "graph": 14,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 45480,
                            "step_time_end": 54420,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 55,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135699,
                            "graph": 14,
                            "graph_title": "14",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 54420,
                            "step_time_end": 57720,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 55,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135701,
                            "graph": 14,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 57780,
                            "step_time_end": 61560,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 55,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135702,
                            "graph": 14,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 61560,
                            "step_time_end": 65100,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 56,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135703,
                            "graph": 14,
                            "graph_title": "14",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 65100,
                            "step_time_end": 68520,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 56,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135705,
                            "graph": 14,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 68580,
                            "step_time_end": 72300,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 56,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135707,
                            "graph": 14,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 72480,
                            "step_time_end": 75120,
                            "interval_before": 47,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 56,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135709,
                            "graph": 14,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 75180,
                            "step_time_end": 78180,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 56,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 14,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 78420,
                            "step_time_end": 78900,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 78420,
                            "null_step_time_end": 78900,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 15,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 57,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135714,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 23280,
                            "step_time_end": 24720,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 22740,
                            "null_step_time_end": 23040,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 58,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135715,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 24720,
                            "step_time_end": 26580,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 59,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135717,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 26820,
                            "step_time_end": 28260,
                            "interval_before": 28,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 60,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135718,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 28260,
                            "step_time_end": 30120,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 61,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135720,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 30540,
                            "step_time_end": 31980,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 7,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 62,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135721,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 31980,
                            "step_time_end": 33780,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 63,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135723,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 33960,
                            "step_time_end": 35400,
                            "interval_before": 27,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 64,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135724,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 35400,
                            "step_time_end": 37200,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 65,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135725,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 37200,
                            "step_time_end": 46800,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 66,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135726,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 46800,
                            "step_time_end": 48240,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 67,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135727,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 48240,
                            "step_time_end": 50040,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 68,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135729,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 18,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 50220,
                            "step_time_end": 51660,
                            "interval_before": 27,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 69,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135730,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 51660,
                            "step_time_end": 53460,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 70,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135732,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 54240,
                            "step_time_end": 55680,
                            "interval_before": 37,
                            "interval_after": 0,
                            "stop_time": 13,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 71,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135733,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 22,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 55680,
                            "step_time_end": 57480,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 72,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135735,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 57960,
                            "step_time_end": 59400,
                            "interval_before": 32,
                            "interval_after": 0,
                            "stop_time": 8,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 73,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135736,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 25,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 59400,
                            "step_time_end": 61260,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 74,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135737,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 26,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 61260,
                            "step_time_end": 64200,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 75,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135738,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 27,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 64200,
                            "step_time_end": 65640,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 76,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135739,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 28,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 65640,
                            "step_time_end": 67500,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 77,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135741,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 30,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 68160,
                            "step_time_end": 69600,
                            "interval_before": 35,
                            "interval_after": 0,
                            "stop_time": 11,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 78,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135742,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 31,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 69600,
                            "step_time_end": 71460,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 79,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135744,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 33,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 71820,
                            "step_time_end": 73080,
                            "interval_before": 27,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 80,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135745,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 34,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 73080,
                            "step_time_end": 74460,
                            "interval_before": 23,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 81,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 0,
                            "graph": 15,
                            "graph_title": "15",
                            "shift": "1",
                            "order_num": 37,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 74700,
                            "step_time_end": 75180,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 74700,
                            "null_step_time_end": 75180,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                },

                {
                    "row_num": 16,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 82,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135750,
                            "graph": 17,
                            "graph_title": "17",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 27060,
                            "step_time_end": 30420,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 26520,
                            "null_step_time_end": 26820,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 82,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135752,
                            "graph": 17,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 30480,
                            "step_time_end": 34200,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 82,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135754,
                            "graph": 17,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 34380,
                            "step_time_end": 37680,
                            "interval_before": 58,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 82,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135756,
                            "graph": 17,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 37740,
                            "step_time_end": 41400,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 82,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135758,
                            "graph": 17,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 6,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 4365,
                            "stop_id_end": 4365,
                            "step_time_begin": 41400,
                            "step_time_end": 53700,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 41400,
                            "null_step_time_end": 41580,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 83,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135760,
                            "graph": 17,
                            "graph_title": "17",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 54000,
                            "step_time_end": 57300,
                            "interval_before": 60,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 53700,
                            "null_step_time_end": 54000,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 83,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135762,
                            "graph": 17,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 57360,
                            "step_time_end": 61140,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 83,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135763,
                            "graph": 17,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 16,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 61140,
                            "step_time_end": 63420,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 84,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135765,
                            "graph": 17,
                            "graph_title": "17",
                            "shift": "1",
                            "order_num": 18,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 63660,
                            "step_time_end": 67080,
                            "interval_before": 61,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 84,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135767,
                            "graph": 17,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 20,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 67140,
                            "step_time_end": 70920,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 84,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135769,
                            "graph": 17,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 22,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 71520,
                            "step_time_end": 74280,
                            "interval_before": 56,
                            "interval_after": 0,
                            "stop_time": 10,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 84,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135771,
                            "graph": 17,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 74340,
                            "step_time_end": 77340,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 84,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 17,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 27,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 77580,
                            "step_time_end": 78060,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 77580,
                            "null_step_time_end": 78060,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 17,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 85,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135776,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 24840,
                            "step_time_end": 26280,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 24300,
                            "null_step_time_end": 24600,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 86,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135777,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 26280,
                            "step_time_end": 28140,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 87,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135779,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 28680,
                            "step_time_end": 30120,
                            "interval_before": 33,
                            "interval_after": 0,
                            "stop_time": 9,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 88,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135780,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 30120,
                            "step_time_end": 31980,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 89,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135782,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 32340,
                            "step_time_end": 33780,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 90,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135783,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 33780,
                            "step_time_end": 35580,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 91,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135785,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 36360,
                            "step_time_end": 37800,
                            "interval_before": 37,
                            "interval_after": 0,
                            "stop_time": 13,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 92,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135786,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 37800,
                            "step_time_end": 39600,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 93,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135787,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 39600,
                            "step_time_end": 45060,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 94,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135788,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 45060,
                            "step_time_end": 46500,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 95,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135789,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 46500,
                            "step_time_end": 48300,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 96,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135791,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 18,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 49020,
                            "step_time_end": 50460,
                            "interval_before": 36,
                            "interval_after": 0,
                            "stop_time": 12,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 97,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135792,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 50460,
                            "step_time_end": 52260,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 98,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135794,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 52380,
                            "step_time_end": 53820,
                            "interval_before": 26,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 99,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135795,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 22,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 53820,
                            "step_time_end": 55620,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 100,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135797,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 56220,
                            "step_time_end": 57660,
                            "interval_before": 34,
                            "interval_after": 0,
                            "stop_time": 10,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 101,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135798,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 25,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 57660,
                            "step_time_end": 59520,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 102,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135799,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 26,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 59520,
                            "step_time_end": 64920,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 103,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135800,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 27,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 64920,
                            "step_time_end": 66360,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 104,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135801,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 28,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 66360,
                            "step_time_end": 68220,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 105,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135803,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 30,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 68880,
                            "step_time_end": 70320,
                            "interval_before": 35,
                            "interval_after": 0,
                            "stop_time": 11,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 106,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135804,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 31,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 70320,
                            "step_time_end": 72180,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 107,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135806,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 33,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 72900,
                            "step_time_end": 74100,
                            "interval_before": 32,
                            "interval_after": 0,
                            "stop_time": 12,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 108,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135807,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 34,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 74100,
                            "step_time_end": 75480,
                            "interval_before": 23,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 109,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 0,
                            "graph": 18,
                            "graph_title": "18",
                            "shift": "1",
                            "order_num": 37,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 75720,
                            "step_time_end": 75900,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 75720,
                            "null_step_time_end": 75900,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                },

                {
                    "row_num": 18,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 110,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135837,
                            "graph": 19,
                            "graph_title": "19",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 28200,
                            "step_time_end": 31980,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 5572,
                            "null_trip_type_sign": "_04BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 7171,
                            "null_step_time_begin": 27120,
                            "null_step_time_end": 28140,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 110,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135839,
                            "graph": 19,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5575,
                            "trip_type_sign": "И01AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7140,
                            "step_time_begin": 33120,
                            "step_time_end": 35280,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 19,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 111,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135841,
                            "graph": 19,
                            "graph_title": "19",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5576,
                            "trip_type_sign": "И01BA",
                            "direction": 1,
                            "stop_id_begin": 7140,
                            "stop_id_end": 3114,
                            "step_time_begin": 35340,
                            "step_time_end": 37500,
                            "interval_before": 37,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 112,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135842,
                            "graph": 19,
                            "graph_title": "19",
                            "shift": "1",
                            "order_num": 8,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 37500,
                            "step_time_end": 44280,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 113,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135843,
                            "graph": 19,
                            "graph_title": "19",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5575,
                            "trip_type_sign": "И01AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7140,
                            "step_time_begin": 44280,
                            "step_time_end": 46260,
                            "interval_before": 33,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 114,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135845,
                            "graph": 19,
                            "graph_title": "19",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5576,
                            "trip_type_sign": "И01BA",
                            "direction": 1,
                            "stop_id_begin": 7140,
                            "stop_id_end": 3114,
                            "step_time_begin": 46320,
                            "step_time_end": 48420,
                            "interval_before": 36,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 115,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135847,
                            "graph": 19,
                            "graph_title": "19",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5575,
                            "trip_type_sign": "И01AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7140,
                            "step_time_begin": 48780,
                            "step_time_end": 50760,
                            "interval_before": 39,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 116,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135849,
                            "graph": 19,
                            "graph_title": "19",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5576,
                            "trip_type_sign": "И01BA",
                            "direction": 1,
                            "stop_id_begin": 7140,
                            "stop_id_end": 3114,
                            "step_time_begin": 50820,
                            "step_time_end": 52920,
                            "interval_before": 36,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 117,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135851,
                            "graph": 19,
                            "graph_title": "19",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5575,
                            "trip_type_sign": "И01AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7140,
                            "step_time_begin": 53100,
                            "step_time_end": 55200,
                            "interval_before": 38,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 118,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135853,
                            "graph": 19,
                            "graph_title": "19",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5576,
                            "trip_type_sign": "И01BA",
                            "direction": 1,
                            "stop_id_begin": 7140,
                            "stop_id_end": 3114,
                            "step_time_begin": 55260,
                            "step_time_end": 57480,
                            "interval_before": 38,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 119,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135855,
                            "graph": 19,
                            "graph_title": "19",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 57660,
                            "step_time_end": 60720,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 120,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135856,
                            "graph": 19,
                            "graph_title": "19",
                            "shift": "1",
                            "order_num": 22,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 60720,
                            "step_time_end": 64140,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 120,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135858,
                            "graph": 19,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 64200,
                            "step_time_end": 67980,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 120,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135860,
                            "graph": 19,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 26,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 68340,
                            "step_time_end": 71760,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 120,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135862,
                            "graph": 19,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 28,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 71820,
                            "step_time_end": 74820,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 120,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 19,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 31,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 75060,
                            "step_time_end": 75540,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 75060,
                            "null_step_time_end": 75540,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 19,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 121,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135867,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 25500,
                            "step_time_end": 26940,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 24960,
                            "null_step_time_end": 25260,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 122,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135868,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 26940,
                            "step_time_end": 28800,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 123,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135870,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 29160,
                            "step_time_end": 30600,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 124,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135871,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 30600,
                            "step_time_end": 32460,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 125,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135872,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 8,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 32460,
                            "step_time_end": 38760,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 126,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135873,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 38760,
                            "step_time_end": 40200,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 127,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135874,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 40200,
                            "step_time_end": 42000,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 128,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135876,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 42360,
                            "step_time_end": 43800,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 129,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135877,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 43800,
                            "step_time_end": 45600,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 130,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135879,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 46200,
                            "step_time_end": 47640,
                            "interval_before": 34,
                            "interval_after": 0,
                            "stop_time": 10,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 131,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135880,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 47640,
                            "step_time_end": 49440,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 132,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135882,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 18,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 49620,
                            "step_time_end": 51060,
                            "interval_before": 27,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 133,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135883,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 51060,
                            "step_time_end": 52860,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 134,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135884,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 20,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 52860,
                            "step_time_end": 60060,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 135,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135886,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 22,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 60120,
                            "step_time_end": 61560,
                            "interval_before": 25,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 136,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135887,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 23,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 61560,
                            "step_time_end": 63420,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 137,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135889,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 25,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 63540,
                            "step_time_end": 64980,
                            "interval_before": 26,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 138,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135890,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 26,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 64980,
                            "step_time_end": 66840,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 139,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135892,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 28,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 67680,
                            "step_time_end": 69120,
                            "interval_before": 38,
                            "interval_after": 0,
                            "stop_time": 14,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 140,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135893,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 29,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 69120,
                            "step_time_end": 70980,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 141,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135895,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 31,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 71100,
                            "step_time_end": 72480,
                            "interval_before": 25,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 142,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135896,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 32,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 72480,
                            "step_time_end": 73860,
                            "interval_before": 23,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 143,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135898,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 34,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 74400,
                            "step_time_end": 75600,
                            "interval_before": 29,
                            "interval_after": 0,
                            "stop_time": 9,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 144,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135899,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 35,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 75600,
                            "step_time_end": 76980,
                            "interval_before": 23,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 145,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 0,
                            "graph": 20,
                            "graph_title": "20",
                            "shift": "1",
                            "order_num": 38,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 77220,
                            "step_time_end": 77700,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 77220,
                            "null_step_time_end": 77700,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                },

                {
                    "row_num": 20,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 146,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135904,
                            "graph": 21,
                            "graph_title": "21",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 27960,
                            "step_time_end": 31320,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 27420,
                            "null_step_time_end": 27720,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 146,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135906,
                            "graph": 21,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 31380,
                            "step_time_end": 35040,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 146,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135908,
                            "graph": 21,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 35220,
                            "step_time_end": 38520,
                            "interval_before": 58,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 146,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135910,
                            "graph": 21,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 38580,
                            "step_time_end": 42240,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 146,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135911,
                            "graph": 21,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 42240,
                            "step_time_end": 46500,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 147,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135912,
                            "graph": 21,
                            "graph_title": "21",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 46500,
                            "step_time_end": 49800,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 147,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135914,
                            "graph": 21,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 49860,
                            "step_time_end": 53520,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 147,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135915,
                            "graph": 21,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 53520,
                            "step_time_end": 60360,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 148,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135916,
                            "graph": 21,
                            "graph_title": "21",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 60360,
                            "step_time_end": 63780,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 148,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135918,
                            "graph": 21,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 63840,
                            "step_time_end": 67620,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 148,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135920,
                            "graph": 21,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 67920,
                            "step_time_end": 71340,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 148,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135922,
                            "graph": 21,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 71400,
                            "step_time_end": 74460,
                            "interval_before": 52,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 148,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135924,
                            "graph": 21,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 23,
                            "schedule_event_id": 1,
                            "trip_type_id": 5580,
                            "trip_type_sign": "У02AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4375,
                            "step_time_begin": 74700,
                            "step_time_end": 76800,
                            "interval_before": 39,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 149,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 0,
                            "graph": 21,
                            "graph_title": "21",
                            "shift": "1",
                            "order_num": 26,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 1,
                            "stop_id_begin": 4375,
                            "stop_id_end": 4365,
                            "step_time_begin": 76980,
                            "step_time_end": 78720,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 5589,
                            "null_trip_type_sign": "_03AB",
                            "null_stop_id_begin": 4375,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 76980,
                            "null_step_time_end": 78720,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 21,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 150,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135929,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 25980,
                            "step_time_end": 27420,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 25440,
                            "null_step_time_end": 25740,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 151,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135930,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 27420,
                            "step_time_end": 29280,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 152,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135932,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 29580,
                            "step_time_end": 31020,
                            "interval_before": 29,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 153,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135933,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 31020,
                            "step_time_end": 32880,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 154,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135935,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 33120,
                            "step_time_end": 34560,
                            "interval_before": 28,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 155,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135936,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 34560,
                            "step_time_end": 36360,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 156,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135938,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 37200,
                            "step_time_end": 38640,
                            "interval_before": 38,
                            "interval_after": 0,
                            "stop_time": 14,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 157,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135939,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 38640,
                            "step_time_end": 40440,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 158,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135940,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 40440,
                            "step_time_end": 48420,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 159,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135941,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 48420,
                            "step_time_end": 49860,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 160,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135942,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 49860,
                            "step_time_end": 51660,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 161,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135944,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 18,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 51840,
                            "step_time_end": 53280,
                            "interval_before": 27,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 162,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135945,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 53280,
                            "step_time_end": 55080,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 163,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135947,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 55680,
                            "step_time_end": 57120,
                            "interval_before": 34,
                            "interval_after": 0,
                            "stop_time": 10,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 164,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135948,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 22,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 57120,
                            "step_time_end": 58920,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 165,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135950,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 59280,
                            "step_time_end": 60720,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 166,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135951,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 25,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 60720,
                            "step_time_end": 62580,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 167,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135952,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 26,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 62580,
                            "step_time_end": 66840,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 168,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135953,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 27,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 66840,
                            "step_time_end": 68280,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 169,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135954,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 28,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 68280,
                            "step_time_end": 70140,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 170,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135956,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 30,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 70320,
                            "step_time_end": 71760,
                            "interval_before": 27,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 171,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135957,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 31,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 71760,
                            "step_time_end": 73200,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 172,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135959,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 33,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 73920,
                            "step_time_end": 75120,
                            "interval_before": 32,
                            "interval_after": 0,
                            "stop_time": 12,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 173,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135960,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 34,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 75120,
                            "step_time_end": 76500,
                            "interval_before": 23,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 174,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 0,
                            "graph": 22,
                            "graph_title": "22",
                            "shift": "1",
                            "order_num": 37,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 76740,
                            "step_time_end": 77220,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 76740,
                            "null_step_time_end": 77220,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                },

                {
                    "row_num": 22,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 175,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135965,
                            "graph": 23,
                            "graph_title": "23",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 29400,
                            "step_time_end": 32760,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 28860,
                            "null_step_time_end": 29160,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 175,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135967,
                            "graph": 23,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 32820,
                            "step_time_end": 36480,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 175,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135969,
                            "graph": 23,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 36780,
                            "step_time_end": 40080,
                            "interval_before": 60,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 175,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135971,
                            "graph": 23,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 40140,
                            "step_time_end": 43800,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 175,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 135972,
                            "graph": 23,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 43800,
                            "step_time_end": 47040,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 176,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135973,
                            "graph": 23,
                            "graph_title": "23",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 47040,
                            "step_time_end": 50340,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 176,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135975,
                            "graph": 23,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 50400,
                            "step_time_end": 54060,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 176,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135976,
                            "graph": 23,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 54060,
                            "step_time_end": 59880,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 177,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135977,
                            "graph": 23,
                            "graph_title": "23",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 59880,
                            "step_time_end": 63300,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 177,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 135979,
                            "graph": 23,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 63360,
                            "step_time_end": 67140,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 177,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 135981,
                            "graph": 23,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 67440,
                            "step_time_end": 70860,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 177,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 135983,
                            "graph": 23,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 70920,
                            "step_time_end": 74100,
                            "interval_before": 54,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 177,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 23,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 74400,
                            "step_time_end": 74880,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 74400,
                            "null_step_time_end": 74880,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 23,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 178,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135988,
                            "graph": 24,
                            "graph_title": "24",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5585,
                            "trip_type_sign": "И03BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 27480,
                            "step_time_end": 31260,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 5572,
                            "null_trip_type_sign": "_04BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 7171,
                            "null_step_time_begin": 26400,
                            "null_step_time_end": 27420,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 179,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135989,
                            "graph": 24,
                            "graph_title": "24",
                            "shift": "1",
                            "order_num": 4,
                            "schedule_event_id": 4,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 31260,
                            "step_time_end": 45900,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 180,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135990,
                            "graph": 24,
                            "graph_title": "24",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5584,
                            "trip_type_sign": "И03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 45900,
                            "step_time_end": 49200,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 181,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135992,
                            "graph": 24,
                            "graph_title": "24",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5585,
                            "trip_type_sign": "И03BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 49260,
                            "step_time_end": 52920,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 182,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135994,
                            "graph": 24,
                            "graph_title": "24",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5584,
                            "trip_type_sign": "И03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 53100,
                            "step_time_end": 56400,
                            "interval_before": 58,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 183,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135996,
                            "graph": 24,
                            "graph_title": "24",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5585,
                            "trip_type_sign": "И03BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 56460,
                            "step_time_end": 60240,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 184,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135997,
                            "graph": 24,
                            "graph_title": "24",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 4,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 60240,
                            "step_time_end": 72180,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 185,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 135998,
                            "graph": 24,
                            "graph_title": "24",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5584,
                            "trip_type_sign": "И03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 72180,
                            "step_time_end": 74820,
                            "interval_before": 44,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 186,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 0,
                            "graph": 24,
                            "graph_title": "24",
                            "shift": "1",
                            "order_num": 16,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 4365,
                            "step_time_begin": 74880,
                            "step_time_end": 76200,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 5591,
                            "null_trip_type_sign": "_04AB",
                            "null_stop_id_begin": 7171,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 74880,
                            "null_step_time_end": 76200,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 24,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 187,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136062,
                            "graph": 25,
                            "graph_title": "25",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5584,
                            "trip_type_sign": "И03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 41460,
                            "step_time_end": 44760,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 40920,
                            "null_step_time_end": 41220,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 188,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136064,
                            "graph": 25,
                            "graph_title": "25",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5585,
                            "trip_type_sign": "И03BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 44820,
                            "step_time_end": 48480,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 189,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136066,
                            "graph": 25,
                            "graph_title": "25",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5584,
                            "trip_type_sign": "И03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 48720,
                            "step_time_end": 52020,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 190,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136068,
                            "graph": 25,
                            "graph_title": "25",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5585,
                            "trip_type_sign": "И03BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 52080,
                            "step_time_end": 55740,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 191,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136069,
                            "graph": 25,
                            "graph_title": "25",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 55740,
                            "step_time_end": 58620,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 192,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136070,
                            "graph": 25,
                            "graph_title": "25",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5584,
                            "trip_type_sign": "И03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 58620,
                            "step_time_end": 62040,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 193,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136072,
                            "graph": 25,
                            "graph_title": "25",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5585,
                            "trip_type_sign": "И03BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 62100,
                            "step_time_end": 65880,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 194,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136074,
                            "graph": 25,
                            "graph_title": "25",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5584,
                            "trip_type_sign": "И03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 66180,
                            "step_time_end": 69600,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 195,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136076,
                            "graph": 25,
                            "graph_title": "25",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5585,
                            "trip_type_sign": "И03BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 69660,
                            "step_time_end": 73080,
                            "interval_before": 58,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 196,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136078,
                            "graph": 25,
                            "graph_title": "25",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5584,
                            "trip_type_sign": "И03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 73680,
                            "step_time_end": 76320,
                            "interval_before": 54,
                            "interval_after": 0,
                            "stop_time": 10,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 197,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 0,
                            "graph": 25,
                            "graph_title": "25",
                            "shift": "1",
                            "order_num": 22,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 4365,
                            "step_time_begin": 76380,
                            "step_time_end": 77700,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 5591,
                            "null_trip_type_sign": "_04AB",
                            "null_stop_id_begin": 7171,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 76380,
                            "null_step_time_end": 77700,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                },

                {
                    "row_num": 25,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 198,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136083,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 23040,
                            "step_time_end": 24480,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 22500,
                            "null_step_time_end": 22800,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 199,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136084,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 24480,
                            "step_time_end": 26340,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 200,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136086,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 26460,
                            "step_time_end": 27900,
                            "interval_before": 26,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 201,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136087,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 27900,
                            "step_time_end": 29760,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 202,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136089,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 30120,
                            "step_time_end": 31560,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 203,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136090,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 31560,
                            "step_time_end": 33420,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 204,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136092,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 33780,
                            "step_time_end": 35220,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 205,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136093,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 35220,
                            "step_time_end": 37020,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 206,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136094,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 37020,
                            "step_time_end": 43440,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 207,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136095,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 43440,
                            "step_time_end": 44880,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 208,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136096,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 44880,
                            "step_time_end": 46680,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 209,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136098,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 18,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 47280,
                            "step_time_end": 48720,
                            "interval_before": 34,
                            "interval_after": 0,
                            "stop_time": 10,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 210,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136099,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 48720,
                            "step_time_end": 50520,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 211,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136101,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 50760,
                            "step_time_end": 52200,
                            "interval_before": 28,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 212,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136102,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 22,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 52200,
                            "step_time_end": 54000,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 213,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136103,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 23,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 54000,
                            "step_time_end": 61200,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 214,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136105,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 25,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 61440,
                            "step_time_end": 62880,
                            "interval_before": 28,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 215,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136106,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 26,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 62880,
                            "step_time_end": 64740,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 216,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136108,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 28,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 65280,
                            "step_time_end": 66720,
                            "interval_before": 33,
                            "interval_after": 0,
                            "stop_time": 9,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 217,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136109,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 29,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 66720,
                            "step_time_end": 68580,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 218,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136111,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 31,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 69300,
                            "step_time_end": 70740,
                            "interval_before": 36,
                            "interval_after": 0,
                            "stop_time": 12,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 219,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136112,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 32,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 70740,
                            "step_time_end": 72480,
                            "interval_before": 29,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 220,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136114,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 34,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 72720,
                            "step_time_end": 73920,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 221,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136115,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 35,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 73920,
                            "step_time_end": 75300,
                            "interval_before": 23,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 222,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 0,
                            "graph": 26,
                            "graph_title": "26",
                            "shift": "1",
                            "order_num": 38,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 75540,
                            "step_time_end": 76020,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 75540,
                            "null_step_time_end": 76020,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 26,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 223,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136026,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 27240,
                            "step_time_end": 28680,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 26700,
                            "null_step_time_end": 27000,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 224,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136027,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 28680,
                            "step_time_end": 30540,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 225,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136029,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 6,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 31020,
                            "step_time_end": 32460,
                            "interval_before": 32,
                            "interval_after": 0,
                            "stop_time": 8,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 226,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136030,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 32460,
                            "step_time_end": 34260,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 227,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136032,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 34800,
                            "step_time_end": 36240,
                            "interval_before": 33,
                            "interval_after": 0,
                            "stop_time": 9,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 228,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136033,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 36240,
                            "step_time_end": 38040,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 229,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136035,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 12,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 38160,
                            "step_time_end": 39600,
                            "interval_before": 26,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 230,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136036,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 39600,
                            "step_time_end": 41400,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 231,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136037,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 41400,
                            "step_time_end": 45600,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 232,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136038,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 45600,
                            "step_time_end": 47040,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 233,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136039,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 16,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 47040,
                            "step_time_end": 48840,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 234,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136041,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 18,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 49140,
                            "step_time_end": 50580,
                            "interval_before": 29,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 235,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136042,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 50580,
                            "step_time_end": 52380,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 236,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136044,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 52860,
                            "step_time_end": 54300,
                            "interval_before": 32,
                            "interval_after": 0,
                            "stop_time": 8,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 237,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136045,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 22,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 54300,
                            "step_time_end": 56100,
                            "interval_before": 30,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 238,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136046,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 23,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 56100,
                            "step_time_end": 62940,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 239,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136047,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 62940,
                            "step_time_end": 64380,
                            "interval_before": 24,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 240,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136048,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 25,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 64380,
                            "step_time_end": 66240,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 241,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136050,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 27,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 66420,
                            "step_time_end": 67860,
                            "interval_before": 27,
                            "interval_after": 0,
                            "stop_time": 3,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 242,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136051,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 28,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 67860,
                            "step_time_end": 69720,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 243,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136053,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 30,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 69840,
                            "step_time_end": 71280,
                            "interval_before": 26,
                            "interval_after": 0,
                            "stop_time": 2,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 244,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136054,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 31,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 71280,
                            "step_time_end": 72840,
                            "interval_before": 26,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 245,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136056,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 33,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 73140,
                            "step_time_end": 74340,
                            "interval_before": 25,
                            "interval_after": 0,
                            "stop_time": 5,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 246,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136057,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 34,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 74340,
                            "step_time_end": 75720,
                            "interval_before": 23,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 247,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 0,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 37,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 75960,
                            "step_time_end": 76440,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 75960,
                            "null_step_time_end": 76440,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                }, {
                    "row_num": 27,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 248,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136003,
                            "graph": 28,
                            "graph_title": "28",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 27720,
                            "step_time_end": 31080,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 27180,
                            "null_step_time_end": 27480,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 248,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 136005,
                            "graph": 28,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 5,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 31140,
                            "step_time_end": 34860,
                            "interval_before": 63,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 248,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 136007,
                            "graph": 28,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 7,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 35580,
                            "step_time_end": 38880,
                            "interval_before": 67,
                            "interval_after": 0,
                            "stop_time": 12,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 248,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 136009,
                            "graph": 28,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 9,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 38940,
                            "step_time_end": 42600,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 248,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 136010,
                            "graph": 28,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 10,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 42600,
                            "step_time_end": 47760,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 249,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136011,
                            "graph": 28,
                            "graph_title": "28",
                            "shift": "1",
                            "order_num": 11,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 47760,
                            "step_time_end": 51060,
                            "interval_before": 55,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 249,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 136013,
                            "graph": 28,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 13,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 51120,
                            "step_time_end": 54780,
                            "interval_before": 62,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 249,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 136014,
                            "graph": 28,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 14,
                            "schedule_event_id": 5,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 3114,
                            "step_time_begin": 54780,
                            "step_time_end": 61920,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 250,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136015,
                            "graph": 28,
                            "graph_title": "28",
                            "shift": "1",
                            "order_num": 15,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 61920,
                            "step_time_end": 65340,
                            "interval_before": 57,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 2,
                        "trip_complex": {
                            "chain_id": 250,
                            "chain_item_num": 2,
                            "schedule_graph_step_id": 136017,
                            "graph": 28,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 17,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 65400,
                            "step_time_end": 69180,
                            "interval_before": 64,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 3,
                        "trip_complex": {
                            "chain_id": 250,
                            "chain_item_num": 3,
                            "schedule_graph_step_id": 136019,
                            "graph": 28,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 19,
                            "schedule_event_id": 1,
                            "trip_type_id": 5574,
                            "trip_type_sign": "00AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7171,
                            "step_time_begin": 69540,
                            "step_time_end": 72720,
                            "interval_before": 59,
                            "interval_after": 0,
                            "stop_time": 6,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 4,
                        "trip_complex": {
                            "chain_id": 250,
                            "chain_item_num": 4,
                            "schedule_graph_step_id": 136021,
                            "graph": 28,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 21,
                            "schedule_event_id": 1,
                            "trip_type_id": 5573,
                            "trip_type_sign": "00BA",
                            "direction": 1,
                            "stop_id_begin": 7171,
                            "stop_id_end": 3114,
                            "step_time_begin": 72780,
                            "step_time_end": 75780,
                            "interval_before": 51,
                            "interval_after": 0,
                            "stop_time": 1,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }, {
                        "column_num": 5,
                        "trip_complex": {
                            "chain_id": 250,
                            "chain_item_num": 5,
                            "schedule_graph_step_id": 0,
                            "graph": 28,
                            "graph_title": "",
                            "shift": "1",
                            "order_num": 24,
                            "schedule_event_id": 0,
                            "trip_type_id": 0,
                            "trip_type_sign": "",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 4365,
                            "step_time_begin": 76020,
                            "step_time_end": 76500,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5587,
                            "null_trip_type_sign": "_01AB",
                            "null_stop_id_begin": 3114,
                            "null_stop_id_end": 4365,
                            "null_step_time_begin": 76020,
                            "null_step_time_end": 76500,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    }
                    ]
                },
                {
                    "row_num": 28,
                    "trip_table_columns": [{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 15,
                            "chain_item_num":1,
                            "direction": 0,
                            "graph": 704,
                            "graph_title":"704",
                            "interval_after":0,
                            "interval_before":0,
                            "null_direction":1,
                            "null_step_time_begin": 22440,
                            "null_step_time_end":23040,
                            "null_stop_id_begin":1485,
                            "null_stop_id_end":221,
                            "null_stop_time":0,
                            "null_trip_type_id":2951,
                            "null_trip_type_sign":"_51BA",
                            "order_num":3,
                            "schedule_event_id": 1,
                            "schedule_graph_step_id":356820,
                            "shift":"1",
                            "step_time_begin" : 23100,
                            "step_time_end": 26580,
                            "stop_id_begin": 221,
                            "stop_id_end": 674,
                            "stop_time":1,
                            "trip_type_id": 130,
                            "trip_type_sign":"И01AB"
                        }
                    },{
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 223,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136026,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 3,
                            "schedule_event_id": 1,
                            "trip_type_id": 5582,
                            "trip_type_sign": "У03AB",
                            "direction": 0,
                            "stop_id_begin": 3114,
                            "stop_id_end": 7193,
                            "step_time_begin": 27240,
                            "step_time_end": 27500,//28680,
                            "interval_before": 0,
                            "interval_after": 0,
                            "stop_time": 4,
                            "null_trip_type_id": 5586,
                            "null_trip_type_sign": "_01BA",
                            "null_stop_id_begin": 4365,
                            "null_stop_id_end": 3114,
                            "null_step_time_begin": 26700,
                            "null_step_time_end": 27000,
                            "null_stop_time": 0,
                            "null_direction": 1
                        }
                    }, {
                        "column_num": 1,
                        "trip_complex": {
                            "chain_id": 224,
                            "chain_item_num": 1,
                            "schedule_graph_step_id": 136027,
                            "graph": 27,
                            "graph_title": "27",
                            "shift": "1",
                            "order_num": 4,
                            "schedule_event_id": 1,
                            "trip_type_id": 5583,
                            "trip_type_sign": "У03BA123",
                            "direction": 1,
                            "stop_id_begin": 7193,
                            "stop_id_end": 3114,
                            "step_time_begin": 27500,
                            "step_time_end": 28540,
                            "interval_before": 31,
                            "interval_after": 0,
                            "stop_time": 0,
                            "null_trip_type_id": 0,
                            "null_trip_type_sign": "",
                            "null_stop_id_begin": 0,
                            "null_stop_id_end": 0,
                            "null_step_time_begin": 0,
                            "null_step_time_end": 0,
                            "null_stop_time": 0,
                            "null_direction": 0
                        }
                    },
                        {
                            "column_num": 1,
                            "trip_complex": {
                                "chain_id": 224,
                                "chain_item_num": 1,
                                "schedule_graph_step_id": 136027,
                                "graph": 27,
                                "graph_title": "27",
                                "shift": "1",
                                "order_num": 4,
                                "schedule_event_id": 1,
                                "trip_type_id": 5583,
                                "trip_type_sign": "У03BA",
                                "direction": 1,
                                "stop_id_begin": 7193,
                                "stop_id_end": 3114,
                                "step_time_begin": 29540,
                                "step_time_end": 35540,
                                "interval_before": 31,
                                "interval_after": 0,
                                "stop_time": 2,
                                "null_trip_type_id": 0,
                                "null_trip_type_sign": "_01BA",
                                "null_stop_id_begin": 0,
                                "null_stop_id_end": 0,
                                "null_step_time_begin": 28540,
                                "null_step_time_end": 29540,
                                "null_stop_time": 0,
                                "null_direction": 1
                            }
                        },
                        {
                            "column_num": 1,
                            "trip_complex": {
                                "chain_id": 224,
                                "chain_item_num": 1,
                                "schedule_graph_step_id": 136027,
                                "graph": 27,
                                "graph_title": "27",
                                "shift": "1",
                                "order_num": 4,
                                "schedule_event_id": 1,
                                "trip_type_id": 5583,
                                "trip_type_sign": "У03BA",
                                "direction": 1,
                                "stop_id_begin": 7193,
                                "stop_id_end": 3114,
                                "step_time_begin": 35540,
                                "step_time_end": 38540,
                                "interval_before": 31,
                                "interval_after": 0,
                                "stop_time": 6,
                                "null_trip_type_id": 0,
                                "null_trip_type_sign": "",
                                "null_stop_id_begin": 0,
                                "null_stop_id_end": 0,
                                "null_step_time_begin": 0,
                                "null_step_time_end": 0,
                                "null_stop_time": 0,
                                "null_direction": 0
                            }
                        },
                    ]
                },

            ]
        }*/
    }
}