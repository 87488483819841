<template>
  <!--    Карта-->
  <leaflet-map
      :zoom="zoom"
      :center="center"
      :contextMenu="mapContextMenu"
      :options="mapOptions"
      @onMapCreated="onMapCreated"
  >

    <!-- Элементы управления-->
    <leaflet-control-group>
      <!-- Базовые слои-->
      <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
    </leaflet-control-group>

    <!-- Зоны тарифных зон -->
    <template
        v-for="(tariffZoneZone, index) in tariffZoneZones"
    >
      <base-zone
          v-if="zoneVisibles[index]"
          :state="tariffZoneZone === selectedTariffZoneZone && isEditMode ? 'edit' : tariffZoneZone === selectedTariffZoneZone ? 'active' : 'original'"
          :key="tariffZoneZone.zone_id"
          :zone="tariffZoneZone"
          @onZoneChanged="$emit('onZoneChanged', $event)"
          :originalOptions="
          {
            color: this.$store.getters['settings/getZonesColors'][index],
            weight: 0.0,
            opacity: 0.0,
            fill: true,
            fillColor: this.$store.getters['settings/getZonesColors'][index],
            fillOpacity: 0.3,
            draggable: false
          }"
          :activeOptions="
          {
            color: 'black',
            weight: 1.0,
            opacity: 1.0,
            fill: true,
            fillColor: this.$store.getters['settings/getZonesColors'][index],
            fillOpacity: 0.6,
            draggable: false
          }"
          :editOptions="
          {
            color: 'black',
            weight: 2.0,
            opacity: 1.0,
            fill: true,
            fillColor: this.$store.getters['settings/getZonesColors'][index],
            fillOpacity: 0.6,
            draggable: true
          }"
      >
      </base-zone>
    </template>
  </leaflet-map>
</template>

<script>

export default {
  props: [
    // зоны тарифных зон
    'tariffZoneZones',
    // активная зона
    'selectedTariffZoneZone',
    // режим редактирования
    'isEditMode',
    // видимлсть зон
    'zoneVisibles'
  ],
  emits: [
    // создание карты
    'onMapCreated',
    // изменение зоны
    'onZoneChanged'
  ],
  computed: {
    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom']
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter']
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },

  },
  data() {
    return {
      // карта
      leaflet: null,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // контекстное меню карты
      mapContextMenu: {
        contextmenu: true,
        contextmenuWidth: 180,
        contextmenuItems: []
      },
    }
  },

  methods: {
    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
      this.$emit('onMapCreated', this.leaflet);
    },

  }
}
</script>
