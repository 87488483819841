<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Группа транспортных предприятий</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="depotGroupId">Код:</label>
              <input-integer
                id="depotGroupId"
                class="form-control w-25"
                v-model="depotGroupId"
                readonly
              />
            </div>
            <div class="form-group">
              <label for="depotGroupName">Наименование:</label><req-field />
              <input-string
                  class="form-control"
                  id="depotGroupName"
                  v-model="depotGroupName"
                  ref="depotGroupName"
                  autocomplete="off"
                  :readonly="op === 'show'"
                  required
                  error="Введите наименование"
              />
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  mixins: [ValidateFormMixin],
  props: ['item', 'op'],
    emits: ['onOK', 'onCancel'],
    data() {
        return {
            depotGroupId: this.item.depot_group_id,
            depotGroupName: this.item.depot_group_name,
        };
    },
    methods: {
        onSubmit() {
            if (this.validateForm(this.$refs)) {
                this.$emit('onOK', {
                    depot_group_id: this.op === 'add' ? undefined : this.depotGroupId,
                    depot_group_name: this.depotGroupName
                })
            }
        },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>