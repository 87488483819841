<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-transport-states"></edit-table-panel>
</template>

<script>
import {
  getDepot,
  getEmissionStandard,
  getEngineFuelType,
  getModel,
  getTransportClass,
  getTransportType
} from "@/store/RefHelpers";
import {dateToString, ndToDate} from "@/lib";
import EditTablePanel from "@/components/ui/custom/EditTablePanel";

export default {
  components: {EditTablePanel},
  props: ['isLoading', 'rows'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "transport_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "__transportTypeName",
          caption: "Вид транспорта",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "state_num",
          caption: "Гос.номер",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "garage_num",
          caption: "Гараж.номер",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__modelName",
          caption: "Модель",
          sortType: 'string',
        },
        {
          field: "__transportClassName",
          caption: "Класс ТС",
          sortType: 'string',
        },
        {
          field: "__depotName",
          caption: "Предприятие",
          sortType: 'string',
        },
        {
          field: "is_disabled",
          caption: "Откл.",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_disabled) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "__engineFuelTypeName",
          caption: "Тип топлива",
          sortType: 'string',
          show: false
        },
        {
          field: "__emissionStandardName",
          caption: "Экологический класс",
          sortType: 'string',
          show: false
        },
        {
          field: "vin",
          caption: "VIN-номер",
          sortType: 'string',
          show: false
        },

        {
          field: "special_header",
          caption: "Спец.заголовок",
          sortType: 'string',
          show: false
        },
        {
          field: "depot_division",
          caption: "Колонна",
          sortType: 'number',
          show: false
        },
        {
          field: "door_count",
          caption: "Кол-во дверей",
          sortType: 'number',
          show: false
        },
        {
          field: "pass_count_seat",
          caption: "Кол-во сидячих мест",
          sortType: 'number',
          show: false
        },
        {
          field: "pass_count",
          caption: "Общее кол-во мест",
          sortType: 'number',
          show: false
        },
        {
          field: "inside_area_useful",
          caption: "Полезная площадь",
          sortType: 'number',
          show: false
        },
        {
          field: "weight_with_pass",
          caption: "Вес с пассажирами",
          sortType: 'number',
          show: false
        },
        {
          field: "length",
          caption: "Длина",
          sortType: 'number',
          show: false
        },
        {
          field: "width",
          caption: "Ширина",
          sortType: 'number',
          show: false
        },
        {
          field: "height",
          caption: "Высота",
          sortType: 'number',
          show: false
        },
        {
          field: "max_speed",
          caption: "Макс.скорость",
          sortType: 'number',
          show: false
        },
        {
          field: "manufacture_year",
          caption: "Год выпуска",
          sortType: 'number',
          show: false
        },
        {
          field: "is_air_conditioning",
          caption: "Кондиционер",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_air_conditioning) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_bike_mount",
          caption: "Крепление велосипедов",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_bike_mount) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_air_heating",
          caption: "Отопитель",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_air_heating) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_ramp",
          caption: "Пандус",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_ramp) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_low_floor",
          caption: "Низкопольный",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_low_floor) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_instructor_seat",
          caption: "Место инструктора",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_instructor_seat) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_temperature_sensor",
          caption: "Датчик температуры",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_temperature_sensor) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_noise_sensor",
          caption: "Датчик шума",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_noise_sensor) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_smoke_sensor",
          caption: "Датчик дыма",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_smoke_sensor) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_fuel_sensor",
          caption: "Датчик топлива",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_fuel_sensor) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_pay_system",
          caption: "Оплата проезда",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_pay_system) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_passenger_count",
          caption: "Подсчет пассажиров",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_passenger_count) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_video_record_system",
          caption: "Видеонаблюдение",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_video_record_system) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_multimedia",
          caption: "Мультимедия",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_multimedia) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_eboard",
          caption: "Информ.табло",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_eboard) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_autoinformer",
          caption: "Автоинформатор",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_autoinformer) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_tachograph",
          caption: "Тахограф",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_tachograph) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "date_index_begin",
          caption: "Начало действия",
          sortType: 'string',
          displayFn(row) {
            if (Number.isInteger(row.date_index_begin)) {
              return dateToString(ndToDate(row.date_index_begin))
            }
            else return '';
          },
        },
        {
          field: "date_index_end",
          caption: "Окончание действия",
          sortType: 'string',
          displayFn(row) {
            if (Number.isInteger(row.date_index_end)) {
              return dateToString(ndToDate(row.date_index_end))
            }
            else return '';
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      return this.rows.map(trn => {
        const model = getModel(trn.model_id);
        const transportType = getTransportType(trn.transport_type_id);
        const transportClass = getTransportClass(trn.transport_class_id);
        const engineFuelType = getEngineFuelType(trn.engine_fuel_type_id);
        const emissionStandard = getEmissionStandard(trn.emission_standard_id);
        const depot = getDepot(trn.depot_id);

        trn.__id = trn.transport_id;
        trn.__modelName = model && model.long_name || '',
        trn.__transportTypeName = transportType && transportType.long_name || '';
        trn.__transportClassName = transportClass && transportClass.short_name || '';
        trn.__engineFuelTypeName = engineFuelType && engineFuelType.long_name || '';
        trn.__emissionStandardName = emissionStandard && emissionStandard.long_name || '';
        trn.__depotName = depot && depot.long_name || '';

        return trn;
      });
    },
  },
};
</script>
