<template>

  <accordion mode="single">

    <ul class="p-0 m-0" style="list-style-type: none;">

      <li>
        <full-left-menu-item
            to="/multipass/routes"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Список маршрутов
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/multipass/stops"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Остановочные пункты
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/multipass/stopstations"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Автостанции
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/multipass/tariffZoneSystems"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Тарифные зональные системы
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/multipass/routeKinds"
        ><i class="fas fa-arrow-alt-circle-right"></i> Виды маршрутов
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/multipass/routeTypes"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Типы маршрутов
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/multipass/transferKinds"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Виды сообщений
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/multipass/tariffTypes"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Типы тарифов
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/multipass/stopstationTypes"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Типы автостанций
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/multipass/stopTypes"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Типы остановочных пунктов
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/multipass/stopPavilionKinds"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Виды остановочных павильонов
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/multipass/stopPavilionTypes"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Типы остановочных павильонов
            </div>
          </div>
        </full-left-menu-item>
      </li>
    </ul>
  </accordion>
</template>
