
// ХРАНИЛИЩЕ ИТОГОВЫХ ДАННЫХ ПО РЕЙСАМ


import {DoGet} from "@/store/Actions";

export default {

    actions: {

        // запрос данных
        doFetchResultTrips: (context, payload) => {
            return DoGet(
                '/kvr/getResultTrips/' + payload.date_index + '/' +payload.route_id,
                null,
                [],
                'Запрос итоговых данных по рейсам',
                false,
            )
        }

    }
}