import {DoClearCache, DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ КЛИЕНТСКИХ НАСТРОЕК ПОЛЬЗОВАТЕЛЯ

export default {
    state() {
        return {
            // список объектов
            clientSettings: {},
            // время обновления кэша new Date().getTime()
            clientSettingsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            clientSettingsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getClientSettings(state) {
            return state.clientSettings
        },
        // вернуть список настроек таблиц
        getEditTableClientSettings(state) {
            return state.clientSettings.editTable
        },
    },
    mutations: {
        // установлить новый список объектов
        setClientSettings(state, payload) {
            state.clientSettings = payload;
            state.clientSettingsCache = new Date().getTime();
        },
        // добавить новые настройки
        addEditTableClientSettings(state, payload) {
            if (!state.clientSettings.editTable) {
                state.clientSettings.editTable = {};
            }
            state.clientSettings.editTable[payload.name] = payload.value;
        },
        // удалить настройки
        delEditTableClientSettings(state, payload) {
            if (!state.clientSettings.editTable) {
                state.clientSettings.editTable = {};
            }
            // удаляем настройки переданной таблицы
            delete state.clientSettings.editTable[payload.name];
        },
    },
    actions: {

        // запрос списка объектов
        doFetchClientSettings: (context, payload) => {
            return DoGet(
                '/auth/clientSettings/' + payload.client_set_name,
                'auth/setClientSettings',
                {},
                'Запрос клиентских настроек',
                false,
                payload && payload.force,
                context.state.clientSettingsCache,
                context.state.clientSettingsCacheTimeout
            )
        },

        // очищает кэш
        doClearClientSettings: (context, payload) => {
            return DoClearCache(
                'auth/setClientSettings',
                [],
                payload && payload.force,
                context.state.clientSettingsCache,
                context.state.clientSettingsCacheTimeout
            )
        },

        // добавление в объект с настройками настрое конкретной таблицы
        doEditEditTableClientSettings: (context, payload) => {
            // добавляем новые настройки по таблице в объект с настройками
            context.commit('addEditTableClientSettings', payload);
            // отправляем объект с настройками на сервер
            return DoPut(
                '/auth/clientSettings/' + payload.client_set_name,
                context.getters['getClientSettings'],
                null,
                'Изменение клиентских настроек таблицы',
                false
            )
        },

        // изменение объекта (удаление настроек)
        doDeleteEditTableClientSettings: (context, payload) => {
            // удаляем из объекта с настройками поле с настройками для переданной таблицы
            context.commit('delEditTableClientSettings', payload);
            // отправляем объект с настройками на сервер
            return DoPut(
                '/auth/clientSettings/' + payload.client_set_name,
                context.getters['getClientSettings'],
                null,
                'Изменение клиентских настроек таблицы',
            )
        },
    }
}