<template>
  <Popper :hover="true" :openDelay="2000">
    <button type="button" class="btn vehicle-button" @click.prevent="onContextMenu($event)" @contextmenu.prevent
            :style="buttonStyle">
      <img :src="imgUrl" height="15" width="15"/>
      <span>{{ vehicleTitle }}</span>
    </button>
    <template #content>
      <div style="max-width: 250px; display: inline-block; white-space: nowrap; overflow: hidden;">
        <div>Парк: {{ vehicle.depot_title }}</div>
        <div>Тип ТС: {{ vehicle.transport_type_title }}</div>
        <div>Модель: {{ vehicle.model_title }}</div>
        <hr>
        <div>Гар.№: {{ vehicle.garage_num }}</div>
        <div>Гос.№: {{ vehicle.model_title }}</div>
        <hr>
        <div>Радиостанция: {{ vehicle.radio_num }}</div>
        <div>Тип радиостанции: {{ vehicle.radio_model_title }}</div>
      </div>
    </template>
  </Popper>
</template>

<script>

// КНОПКА ТРАНСПОРТНОГО СРЕДСТВА В ПАНЕЛИ КАРТЫ

// через сколько секунд кнопка синеет
import {getTimeFormatFromDateString} from "@/lib";
import call_btn from "./images/call.png";
import original_btn from "./images/original.png";
import sos_btn from "./images/sos.png";
import message_btn from "./images/message.png";
import photo_btn from "./images/photo.png";
import disabled_btn from "./images/disabled.png";
import wrong_btn from "./images/wrong.png";

const BLUE_LIMIT = 300
// через сколько секунд кнопка отключается
const DISABLED_LIMIT = 600

export default {
  emits: [
    // информация от ТС
    'onVehicleInfo',
    // транспортное средство на карту
    'onVehicleOnTheMap',
    // группу на карту
    'onSelectGroup',
    // панель на карту
    'onPanelOnTheMap',
    // все на карту
    'onAllOnTheMap',
  ],
  props: {
    // Тип кнопки:
    //  original - рабочий вариант
    //  call - вызов на связь
    //  sos - сигнал SOS
    //  message - сообщение (или фотография)
    //  photo - фотография
    buttonState: {
      type: String,
      default: "original"
    },
    // Описание текущего транспортного средства
    // структура CUR_NAV
    vehicle: {
      type: Object,
      required: true
    },
    // Заголовок транспортного средства
    title: {
      type: String,
      required: false
    },
    // тип заголовка:
    // 'auto'   - автоматическое формирование заголовка: title -> vehicle['special_header'] -> (vehicle['garage_num'] | vehicle['state_num'])
    // 'garage' - vehicle['garage_num']
    // 'state'  - vehicle['state_num']
    titleType: {
      type: String,
      default: 'auto'
    },
    // какой номер имеет приоритет:
    // 'garage' - гаражный номер
    // 'state'  - государственный номер
    priorityNum: {
      type: String,
      default: 'garage'
    },
    // элементы контекстного меню (массив Items)
    contextMenuItems: {
      type: Array,
      required: false
    }
  },
  computed: {

    // формируем заголовок кнопки
    vehicleTitle() {
      if (this.titleType === 'garage') {
        return this.vehicle['garage_num'] || ''
      } else if (this.titleType === 'state') {
        return this.vehicle['state_num'] || ''
      } else {
        if (this.priorityNum === 'state') {
          return this.title || this.vehicle['special_header'] || this.vehicle['state_num'] || ''
        } else {
          return this.title || this.vehicle['special_header'] || this.vehicle['garage_num'] || ''
        }
      }
    },
    // формирует силь кнопки
    buttonStyle() {
      if (this.buttonState === 'call') {
        return {"background-color": "rgb(248, 161, 97)"}
      } else if (this.buttonState === 'sos') {
        return {"background-color": "rgb(243, 83, 83)"}
      } else if (this.buttonState === 'message') {
        return {"background-color": "rgb(236, 243, 171)"}
      } else if (this.buttonState === 'photo') {
        return {"background-color": "rgb(236, 243, 171)"}
      } else {
        if (this.vehicle["time_delta"] && Math.abs(this.vehicle["time_delta"]) >= DISABLED_LIMIT)
          return {"background-color": "rgb(219, 219, 219)"}
        else if (this.vehicle["time_delta"] && Math.abs(this.vehicle["time_delta"]) >= BLUE_LIMIT)
          return {"background-color": "rgb(156, 214, 238)"}
        else
          return {"background-color": "rgb(181, 229, 181)"}
      }
    },
    // формирует путь к иконке
    imgUrl() {
      if (this.buttonState === 'call') {
        return call_btn;
      } else if (this.buttonState === 'sos') {
        return sos_btn;
      } else if (this.buttonState === 'message') {
        return message_btn;
      } else if (this.buttonState === 'photo') {
        return photo_btn;
      } else {
        if (this.vehicle["time_delta"] && Math.abs(this.vehicle["time_delta"]) >= DISABLED_LIMIT) {
          return disabled_btn;
        } else if (this.vehicle["is_invalid"]) {
          return wrong_btn;
        } else
          return original_btn;
      }
    }
  },
  data() {
    return {}
  },
  methods: {

    // получение времени в заданном формате
    getTimeFormat(vehicle) {
      return getTimeFormatFromDateString(vehicle.time_nav);
    },

    // открываем контекстное меню
    onContextMenu(e) {
      this.$contextmenu({
        x: e.x,
        y: e.y,
        items: [
          {
            label: "На карту",
            onClick: () => {
              this.$emit('onVehicleOnTheMap', this.vehicle);
            }
          },
          {
            label: "Группа на карту",
            onClick: () => {
              this.$emit('onSelectGroup');
            }
          },
          {
            label: "Панель на карту",
            onClick: () => {
              this.$emit('onPanelOnTheMap');
            }
          },
          {
            label: "Все на карту",
            divided: true,
            onClick: () => {
              this.$emit('onAllOnTheMap');
            }
          },
          {
            label: "Информация",
            onClick: () => {
              this.$emit('onVehicleInfo', this.vehicle);
            }
          },

        ]
      });
    }
  }
}
</script>

<style>

.vehicle-button {
  margin: 0.03rem;
  text-align: left;
  font-size: x-small;
  padding: 0.05rem;
  width: 5rem;
  height: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 0.25rem !important;
}

.vehicle-button img {
  display: inline-block;
}

.vehicle-button span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.2rem;
}

.vehicle-button .dropdown-menu {
  min-width: 7rem;
}

.vehicle-button .dropdown-item {
  font-size: x-small;
  padding: 0.1rem 1.0rem;
}

:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 0.5rem;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  --popper-theme-max-width: 100px;
}
</style>
