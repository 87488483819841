<template>
  <input-select ref="select" v-model="value" caption="Выберите модель..." :itemList="items"></input-select>
</template>

<script>
export default {
  props: ['model-value', 'transportTypeId'],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      let arr = []
      this.$store.getters['ref/getModels'].forEach(item => {
        if(this.transportTypeId) {
          if (this.transportTypeId == item.transport_type_id) {
            arr.push(
              {
                id: item.model_id,
                title: item.long_name
              }
            )
          }
        } else {
          arr.push(
            {
              id: item.model_id,
              title: item.long_name
            }
          )
        }
      });
      return arr;
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
