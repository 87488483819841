<template>
  <!--  МЕТОДЫ-->

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/admin">Администрирование</router-link></span>
        <span> / </span>
        <span>Методы</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <div class="container position-relative h-100">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">

            <crud-form
                :buttonsExist="{refresh: true}"
                :buttonsEnabled="{refresh: true}"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            >

              <template #leftCustomButton>
                <dropdown>
                  <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                  :disabled="linkDisabled">Связь
                  </button-toggler>
                  <dropdown-menu ref="collapse">
                    <dropdown-item router-link :to="roleMethodLink">Роли</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>

              <template #leftCustomButton2>
                <modules-select class="form-control" v-model="module_id" :is-pref="true"></modules-select>
              </template>

            </crud-form>

          </align-panel>

          <!-- Таблица  -->
          <align-panel align="all">
            <methods-edit-table
                :module_id="module_id"
                :filterText="filterText"
                :isLoading="isLoading"
                :selectedRow="selectedRow"
                @onRowSelect="onRowSelect"
            ></methods-edit-table>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>


  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import MethodsEditTable from "../../components/system/methods/MethodsEditTable";
import ModulesSelect from "@/components/system/modules/ModulesSelect";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    MethodsEditTable,
    ModulesSelect
  },
  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // код модуля
      module_id: null
    }
  },
  computed: {
    // ссылка на роли
    roleMethodLink() {
      if (this.selectedRow) return '/admin/methods/methodRoleLinks/' + this.selectedRow.method_id
      else return '';
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    }
  },
  methods: {
    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('system/doFetchModules');
        await this.$store.dispatch('system/doFetchMethods', {force});

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

