<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-rmt-schedule-variants"></edit-table-panel>
</template>

<script>

import {getDepot, getTransportClass} from "@/store/RefHelpers";
import {getRegime, getTransportationKind} from "@/store/RmtHelpers";
import {getTimeFormat, quickSort, scheduleGraphSortFn} from "@/lib";

export default {
  props: ['isLoading', 'scheduleGraphs'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "schedule_graph_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "schedule_graph_set_id",
          caption: "Код набора графиков",
          sortType: 'number',
          isFilterable: true,
          show: false
        },
        {
          field: "graph",
          caption: "График",
          sortType: 'graph',
        },
        {
          field: "shift",
          caption: "Смена",
          sortType: 'number',
        },
        {
          field: "time_begin",
          caption: "Нач. работы",
          headerAlign: 'center',
          dataAlign: 'center',
          sortType: 'string',
          show: true,
          displayFn(row){
            return getTimeFormat(row.time_begin)
          }
        },
        {
          field: "time_end",
          caption: "Оконч. работы",
          sortType: 'number',
          headerAlign: 'center',
          dataAlign: 'center',
          show: true,
          displayFn(row){
            return getTimeFormat(row.time_end)
          }
        },
        {
          field: "__graphControlModeName",
          caption: "Режим контроля",
          sortType: 'string',
          show: true
        },
        {
          field: "__transportClassName",
          caption: "Класс ТС",
          sortType: 'number',
          isFilterable: true,
          show: true
        },
        {
          field: "__transportationKindName",
          caption: "Вид перевозок",
          sortType: 'string',
          show: true
        },
        {
          field: "__regimeName",
          caption: "Режим работы водителей",
          sortType: 'number',
          isFilterable: true,
          show: false
        },
        {
          field: "trip_count",
          caption: "Количество рейсов",
          sortType: 'number',
          show: false,
        },
        {
          field: "trip_weight_count",
          caption: "Количество рейсов с учетом веса",
          sortType: 'number',
          show: false,
        },
        {
          field: "__depotName",
          caption: "Предприятие",
          sortType: 'number',
          show: true
        },
        {
          field: "total_ind_time",
          caption: "Суммарное нарядное время",
          sortType: 'number',
          show: false,
        },
        {
          field: "line_ind_time",
          caption: "Суммарное линейное время",
          sortType: 'number',
          show: false,
        },
        {
          field: "null_ind_time",
          caption: "Суммарное нулевое время",
          sortType: 'number',
          show: false,
        },
        {
          field: "work_ind_time",
          caption: "Суммарное рабочее время",
          sortType: 'number',
          show: false,
        },
        {
          field: "dinner_ind_time",
          caption: "Суммарное обеденное время",
          sortType: 'number',
          show: false,
        },
        {
          field: "plan_stay_ind_time",
          caption: "Суммарное время планового отстоя",
          sortType: 'number',
          show: false,
        },
        {
          field: "stay_ind_time",
          caption: "Суммарное отстойное время",
          sortType: 'number',
          show: false,
        },
        {
          field: "res_ind_time",
          caption: "Суммарное резервное время",
          sortType: 'number',
          show: false,
        },
        {
          field: "total_ind_length",
          caption: "Суммарный общий пробег",
          sortType: 'number',
          show: false,
        },
        {
          field: "line_ind_length",
          caption: "Суммарный линейный пробег",
          sortType: 'number',
          show: false,
        },
        {
          field: "null_ind_length",
          caption: "Суммарный нулевой пробег",
          sortType: 'number',
          show: false,
        },
        {
          field: "work_ind_length",
          caption: "Суммарный рабочий пробег",
          sortType: 'number',
          show: false,
        },
        {
          field: 'is_checked',
          caption: 'Некор.',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          isSortable: false,
          displayFn(row) {
            if (!row.is_checked) {
              return '<i class="fas fa-exclamation" style="color: #fc7676">'
            }
            return ''
          },
        }
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      const scheduleGraphs = this.scheduleGraphs;

      let arr = [];

      scheduleGraphs.forEach(schGr => {

        schGr.__id = schGr.route_variant_id;

        const depot = getDepot(schGr.depot_id);
        const transportClass = getTransportClass(schGr.transport_class_id);
        const transportationKind = getTransportationKind(schGr.transportation_kind_id);
        const regime = getRegime(schGr.regime_id);
        let graphControlMode = "";
        if(schGr.graph_control_mode == 1){
          graphControlMode = "По расписанию";
        } else if (schGr.graph_control_mode == 2) {
          graphControlMode = "По интервалам";
        } else if (schGr.graph_control_mode == 3) {
          graphControlMode = "Смешанный режим";
        }

        schGr.__depotName = depot && depot.long_name || '';
        schGr.__transportClassName = transportClass && transportClass.short_name || '';
        schGr.__transportationKindName = transportationKind && transportationKind.short_name || '';
        schGr.__regimeName = regime && regime.long_name || '';
        schGr.__graphControlModeName = graphControlMode;

        arr.push(schGr);
      });

      quickSort(arr, (leftItem, rightItem) => {
        return 1 * scheduleGraphSortFn(leftItem, rightItem);
      });

      return arr;
    },
  },
};
</script>
