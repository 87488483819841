import { createRouter, createWebHistory } from 'vue-router';

import RefRoute from './RefRoute';
import MultipassRoute from './MultipassRoute';
import RmtRoute from './RmtRoute';
import NavRoute from './NavRoute';
import TrnRoute from './TrnRoute';
import KvrRoute from './KvrRoute';
import AsmppRoute from './AsmppRoute';
import AsipRoute from './AsipRoute';
import PriorityRoute from './PriorityRoute';
import AdminRoute from './AdminRoute';
import RmtGenRoute from './RmtGenRoute';

import store from '../store/Store';
import AuthPage from '../pages/main/AuthPage.vue';
import PanicPage from '../pages/main/PanicPage.vue'
import MainTitle from '../pages/main/MainTitlePage.vue';
import ProfilePage from "@/pages/main/ProfilePage";
import TestPage from "../debug/TestPage";
import TestPage2 from "@/debug/TestPage2";
import TestPage3 from "@/debug/TestPage3";
import TestPage4 from "@/debug/TestPage4";
import AboutPage from "@/pages/main/AboutPage";


const router = createRouter({
    history: createWebHistory(),
    routes: [
        RefRoute,
        MultipassRoute,
        RmtRoute,
        NavRoute,
        TrnRoute,
        KvrRoute,
        AsmppRoute,
        AsipRoute,
        PriorityRoute,
        RmtGenRoute,
        AdminRoute,
        {
            path: '/profile',
            name: 'profile',
            component: ProfilePage
        },
        {
            path: '/auth',
            name: 'auth',
            component: AuthPage
        },
        {
            path: '/panic',
            name: 'panic',
            component: PanicPage
        },
        {
            path: '/about',
            name: 'about',
            component: AboutPage
        },
        {
            path: '/test',
            name: 'test',
            component: TestPage
        },
        {
            path: '/test2',
            name: 'test2',
            component: TestPage2
        },
        {
            path: '/test3',
            name: 'test3',
            component: TestPage3
        },
        {
            path: '/test4',
            name: 'test4',
            component: TestPage4
        },
        {
            path: '/:pathMatch(.*)*',
            component: MainTitle
        }
    ]
});

// процедура загрузки приложения
async function loading(to) {
    // загружаем файл настроек
    const result = await store.dispatch('settings/doGetSettings');
    // ошибка настроек
    if (!result) {
        // устанавливаем панику
        await store.dispatch('doSetPanic', {
            panicMessage: 'Файл настроек не найден или имеет неверный формат.'
        })
        // переходим на страницу паники
        return ('/panic')
    }

    // фиксируем успешную загрузку
    await store.dispatch('doSetLoaded');

    // пытаемся обновить сессию
    await store.dispatch('doAutoLogin');

    // возвращаем исходный путь
    return to;
}

// проверяем пути перед входом
router.beforeEach( (to) => {

    // запускаем загрузку приложения
    if (!store.getters.getIsLoaded) {
        // если мы идем в окно паники во время паники - все хорошо
        if (to.name === 'panic' && store.getters.getIsPanic)
            return (true)
        // запускаем загрузку приложения
        return loading(to);
    }

    // не разрешаем заходить в панику напрямую
    if (to.name === 'panic') return ('/');

    // авторизация есть, и пытаемся зайти на авторизацию
    if (store.getters.getIsAuth && to.name === 'auth') return ('/');

    // авторизации нет, но пытаемся зайти куда-то ещё
    if (!store.getters.getIsAuth && to.name !== 'auth') {
        // на тестовую страницу разрешаем всегда
        if (to.name === 'test' || to.name === 'test2' || to.name === 'test3') return (true);
        // запоминаем откуда уходим
        store.dispatch('doSetLoadedUrl', {
            loadedUrl: to
        });
        // идем авторизироваться
        return ('/auth')
    }

    let allowedModulesArr = store.getters["auth/getAllowedModules"];
    let isBlock = false;

    // проверка доступен ли пользователю модуль на который он хочет перейти
    if (to.fullPath.substring(0, 4) === '/ref') {
        isBlock = !(allowedModulesArr.find(module => module.module_id == 101));
    } else if (to.fullPath.substring(0, 10) === '/multipass') {
        isBlock = !(allowedModulesArr.find(module => module.module_id == 102));
    } else if (to.fullPath.substring(0, 7) === '/rmtgen') {
        isBlock = !(allowedModulesArr.find(module => module.module_id == 201));
    } else if (to.fullPath.substring(0, 4) === '/rmt') {
        isBlock = !(allowedModulesArr.find(module => module.module_id == 103));
    } else if (to.fullPath.substring(0, 4) === '/trn') {
        isBlock = !(allowedModulesArr.find(module => module.module_id == 105));
    } else if (to.fullPath.substring(0, 4) === '/nav') {
        isBlock = !(allowedModulesArr.find(module => module.module_id == 104));
    } else if (to.fullPath.substring(0, 4) === '/kvr') {
        isBlock = !(allowedModulesArr.find(module => module.module_id == 106));
    } else if (to.fullPath.substring(0, 6) === '/asmpp') {
        isBlock = !(allowedModulesArr.find(module => module.module_id == 107));
    } else if (to.fullPath.substring(0, 5) === '/asip') {
        isBlock = !(allowedModulesArr.find(module => module.module_id == 108));
    } else if (to.fullPath.substring(0, 9) === '/priority') {
        isBlock = !(allowedModulesArr.find(module => module.module_id == 109));
    } else if (to.fullPath.substring(0, 4) === '/admin') {
        isBlock = !(allowedModulesArr.find(module => module.module_id == 4));
    }

    // если запрашиваемый модуль не найден отправляем пользователя на начальную страницу
    if (isBlock && to.fullPath.substring(0, 4) === '/kvr') {
        store.dispatch('doAddMessage', {
            caption: 'Запрос модуля',
            message: 'Модуль недоступен',
            type: 'error'
        }, {root: true});
        return ('/');
    }

    // при переходе в корневые разделы модулей левое меню автоматически открывается
    if (
        to.path === '/ref' || to.path === '/multipass' || to.path === '/rmtgen' || to.path === '/rmt' || to.path === '/nav' || to.path === '/trn' ||
        to.path === '/kvr' || to.path === '/asmpp' || to.path === '/asip' || to.path === '/priority' || to.path === '/admin'
    ) {
        store.commit('showLeftMenu');
    }

    // разрешаем
    return true;
})

export default router;
