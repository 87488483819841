<template>
  <div class="btn-group btn-group d-block">
    <button
        type="button"
        class="btn"
        :class="modelValue == 0 ? 'activeClass' : 'inactiveClassAB'"
        style="background-color: #1EB300; height: 30px; padding-top: 2px; color: white;"
        @click="changeSelectedDir"
        :disabled="(this.required  === null) || this.readonly"
    ><h5 id="textAB" class="modal-text" style="font-size: 20px">{{ textAB }}</h5></button>
    <button
        type="button"
        class="btn"
        :class="modelValue == 1 ? 'activeClass' : 'inactiveClassBA'"
        @click="changeSelectedDir"
        style="background-color: #a800ff; height: 30px; padding-top: 2px; color: white; "
        :disabled="(this.required === null) || this.readonly"
    ><h5 id="textBA" class="modal-text" style="font-size: 20px">{{ textBA }}</h5></button>
  </div>
</template>

<script>

export default {
  emits: ['update:model-value', 'change'],
  props: {
    'model-value': {
      required: false
    },
    // требуется ввод значения т.е. кнопку нельзя отжать
    'required': {
      type: Boolean,
      default: false
    },
    // только для чтения
    'readonly': {
      type: Boolean,
      default: false
    },
  },


  data() {
    return {
      textAB: this.modelValue == 1 ? 'АБ' : '- АБ -',
      textBA: this.modelValue == 0 ? 'БА' : '- БА -',
    }
  },

  methods: {

    changeSelectedDir() {

      if(this.modelValue == 0) {

        this.$emit('update:model-value', 1);

        setTimeout(() => {
          let element = document.getElementById('textAB');
          element.innerText = "АБ";
        }, 250)
        setTimeout(() => {
          let element2 = document.getElementById('textBA');
          element2.innerText = "- БА -"
        }, 450)
      } else {

        this.$emit('update:model-value', 0);

        setTimeout(() => {
          let element = document.getElementById('textBA');
          element.innerText = "БА";
        }, 250)
        setTimeout(() => {
          let element2 = document.getElementById('textAB');
          element2.innerText = "- АБ -"
        }, 450)
      }
    }

  },

  created() {
  }

}
</script>

<style scoped>

.activeClass {
  width: 120px;
  transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out, padding-left 0.5s ease-in-out;
  opacity: 100%;
}

.inactiveClassBA {
  width: 35px;
  opacity: 90%;
  padding-left: 4px;
  background: linear-gradient(to bottom right, #a800ff 50%, #3b3b3b 50%);
  /*background: radial-gradient(#a800ff, #3b3b3b);*/
  transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out, padding-left 0.5s ease-in-out;
}

.inactiveClassAB {
  width: 35px;
  opacity: 90%;
  padding-left: 4px;  background: linear-gradient(to bottom right, #1EB300 50%, #3b3b3b 50%);
  /*background: radial-gradient(#1EB300, #3b3b3b);*/
  transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out, padding-left 0.5s ease-in-out;
}

/*.activeTextClass {*/
/*  font-size: 20px;*/
/*}*/

/*.inactiveTextClass {*/
/*  font-size: 20px;*/
/*}*/

/*@keyframes increase-font {*/
/*  from {*/
/*    font-size: 15px;*/
/*  }*/
/*  to {*/
/*    font-size: 20px;*/
/*  }*/
/*}*/

/*@keyframes decrease-font {*/
/*  from {*/
/*    font-size: 20px;*/
/*  }*/
/*  to {*/
/*    font-size: 15px;*/
/*  }*/
/*}*/

</style>