<template>
  <dropdown>
    <button-toggler class="toggler" style="border-width: 0px; background-color: white; padding: 0px; color: white;">
    <input
        class="inp"
        id="inputText"
        type="text"
        v-model.trim="value"
        v-bind="$attrs"
        :class="{ 'is-invalid': isInvalid }"
        @focus="onFocus"
        @blur="onBlur"
        autocomplete="off"
    >
    </button-toggler>
    <dropdown-menu :active="isMenuShowing" class="dropdown-results">
      <dropdown-item
          v-for="val in values"
          :key="val.id"
          @click="onAdd(val)"
          @mousedown.prevent>
        {{ val }}
      </dropdown-item>
    </dropdown-menu>
  </dropdown>
  <div class="invalid-feedback" v-if="!!errorText">{{ errorText }}</div>
  <div class="invalid-feedback" v-else>Введите значение</div>
</template>

<script>
export default {
  props: {
    'model-value': {
      required: true
    },
    'error': {
      type: String
    },
    'required': {
      type: Boolean,
      default: false
    },
    'libArr': {
      default: []
    }
  },
  emits: ['update:model-value'],
  data() {
    return {
      isInvalid: false,
      errorText: this.error,
      values: this.libArr,
      focusFlag: false,
    }
  },
  computed: {

    value: {
      get() {
        this.filter(this.modelValue)
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },

    isMenuShowing(){
      if (this.focusFlag == true && this.values.length !=0){
        return true;
      }
      return false;
    },

  },

  methods: {

    onAdd(value){
      this.$emit('update:model-value', value)
    },

    filter(value) {
      if (value == '') {
        this.values = this.libArr;
      }
      var searchvalue = value;
      this.values = [];
      this.libArr.map(v => {
        if (v.includes(searchvalue) && v != searchvalue) {
          this.values.push(v)
        }
      })
    },

    // проверка валидности
    isValid() {
      // разраешаем пустые строки, если допускается пустое значение
      if (this.modelValue === '') {
        return !this.required
      }
      // все остальное можно
      return true;
    },
    // при получении фокуса
    onFocus() {
      // сбрасываем инвалидность
      this.isInvalid = false;
      this.focusFlag = true;
      this.errorText = this.error;
    },
    // при потере фокуса
    onBlur() {
      this.validate()
      this.focusFlag = false;
    },
    // при вводе значений
    // onInput() {
    //   this.validate();
    // },
    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },
    alarm(message) {
      this.errorText = message;
      this.isInvalid = true;
    }
  },
  created() {
    // на всякий случай - меняем undefined на ''
    this.$watch(() => this.modelValue, () => {
      if (this.modelValue == null) {
        this.$emit('update:model-value', '');
      }
    }, {immediate: true});
    // следим за изменением текста
    this.$watch(() => this.error, () => {
      this.errorText = this.error;
    })
  }
}
</script>

<style>

.toggler {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.inp:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.inp {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 6px 12px;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*Задаем стиль выпадающего списка */
.dropdown-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  max-height: 250px;
  overflow: auto;
  width: 100%;
}
</style>
