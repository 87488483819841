<template>
  <align-container>
    <align-panel align="all">
      <div class="card h-100 vehicle-button-panel">
        <align-container>
          <align-panel align="all">
            <align-container>
              <div class="card-body">
                <align-panel id="table" align="all" style="overflow-y: auto; overflow-x: hidden">
                  <table class="table table-sm">
                    <thead class="thead-light sticky-top" style="font-size: small;">
                    <tr>
                      <th class="text-center" scope="col">Скор.</th>
                      <th class="text-center" scope="col">Время</th>
                      <th class="text-center" scope="col">Ист.</th>
                      <th class="text-center" scope="col">Датч.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="nav in vehicleHistory"
                        :key="nav.nav_id"
                        :id="nav.nav_id"
                        :class="{'bg-primary text-white': include(nav.nav_id)}"
                        @click="select($event, nav.nav_id)"
                        :style="getRowStyle(nav)"
                    >
                      <td style=" padding-top: 1px !important; padding-bottom: 1px !important;"
                      >{{ nav.speed }}
                      </td>
                      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
                      >{{ getTime(nav.time_nav) }}
                      </td>
                      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
                      >{{ nav.is_history == true ? getTime(nav.time_insert) : '' }}
                      </td>
                      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;">
                        <div class="form-group row" style="margin-bottom: 0px; margin-left: 5px">
                          <div v-if="nav.is_ign" style="margin-right: 1px">
                            <img :src="imgUrl('ign')"/>
                          </div>
                          <div v-if="nav.is_call" style="margin-right: 1px">
                            <img :src="imgUrl('mic')"/>
                          </div>
                          <div v-if="nav.is_sos">
                            <img :src="imgUrl('sos1')"/>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </align-panel>
              </div>
            </align-container>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>
</template>

<script>

import {dateToString, getTimeFormatFromDateString} from "@/lib";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import ign_btn from "./images/ign.png";
import mic_btn from "./images/mic.png";
import sos1_btn from "./images/sos1.png";
import {preventDefault} from "leaflet/src/dom/DomEvent";

export default {
  name: "HistPanel",
  components: {AlignPanel, AlignContainer},
  props: ['vehicleHistory', 'selectedNavs', 'isLoading', 'selectedRowNavId'],
  data() {
    return {
      date: dateToString(),
      isFirstScrollComplete: false,
    }
  },

  watch: {
    selectedRowNavId(value) {
      if (!this.isFirstScrollComplete) {
        let el = document.getElementById(value);
        if (el) {
          // Use el.scrollIntoView() to instantly scroll to the element
          el.scrollIntoView({behavior: 'auto', block: 'center'});
        }
        this.isFirstScrollComplete = true;
      }
    }

  },

  methods: {

    // получаем стиль строк
    getRowStyle(nav) {
      if (nav.is_hist && !nav.is_invalid) {
        return 'color: blue; cursor: pointer; font-size: small; user-select: none;'
      } else if (nav.is_invalid) {
        return 'color: red; cursor: pointer; font-size: small; user-select: none;'
      } else {
        return 'cursor: pointer; font-size: small; user-select: none;'
      }
    },

    select(e, nav_id) {
      if (!this.isLoading) {
        if (e.ctrlKey && !e.shiftKey) {
          this.$emit('onCtrlSelect', nav_id);
        } else if (!e.ctrlKey && e.shiftKey) {
          let index = this.vehicleHistory.findIndex(value => value.nav_id == nav_id);
          this.$emit('onShiftSelect', index);
        } else if ((!e.ctrlKey && !e.shiftKey)) {
          this.$emit('onSelect', nav_id);
        } else {
          this.$emit('onClearSelect', nav_id);
        }
      }
    },

    getNavTransportOnDay() {
      this.$emit('getNavTransportOnDay', this.date);
    },

    // получаем время из строки
    getTime(time_nav) {
      return getTimeFormatFromDateString(time_nav)
    },

    // проверка является ли строка выбранной
    include(nav_id) {
      return this.selectedNavs.includes(nav_id);
    },

    // отменяем скролл на странице при нажатии стрелочек
    preventDefaultForScrollKeys(e) {
      let keys = {37: 1, 38: 1, 39: 1, 40: 1};
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    },

    scrollToSelectedRowNavId(nav_id) {
      let el = document.getElementById(nav_id);
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'auto', block: 'center'});
      }
    },

    arrowDownPressed() {
      if (this.selectedRowNavId != null) {
        let index = this.vehicleHistory.findIndex(value => value.nav_id == this.selectedRowNavId);
        // если есть выбранная строка, переходим к следующей
        if (index != (this.vehicleHistory.length - 1)) {
          let nav_id = this.vehicleHistory[index +1].nav_id;
          this.$emit('onSelect', nav_id);
          // Скроллим до выбранной строки
          this.scrollToSelectedRowNavId(nav_id);
        }
      }
    },

    arrowUpPressed() {
      if (this.selectedRowNavId != null) {
        let index = this.vehicleHistory.findIndex(value => value.nav_id == this.selectedRowNavId);
        // если есть выбранная строка, переходим к следующей
        if (index != 0) {
          let nav_id = this.vehicleHistory[index -1].nav_id;
          this.$emit('onSelect', nav_id);
          // Скроллим до выбранной строки
          this.scrollToSelectedRowNavId(nav_id);
        }
      }
    },

    imgUrl(btn) {
      if (btn === 'ign') {
        return ign_btn;
      } else if (btn === 'mic') {
        return mic_btn;
      } else if (btn === 'sos1') {
        return sos1_btn;
      } else return '';
    }
  },


  mounted() {
    // если это загрузка истории по нажатию кнопки
    // добавляем eventListener, который отменяет скролл на странице при нажатии стрелочек
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    // переопределяем действия при нажатии стрелочек
    window.addEventListener('keydown', (event) => {
      if (event.code == 'ArrowDown') {
        this.arrowDownPressed();
      } else if (event.code == 'ArrowUp') {
        this.arrowUpPressed();
      }
    });
  },

  unmounted() {
    // убираем eventListener -ы
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    window.removeEventListener('keydown', (event) => {
      if (event.code == 'ArrowDown') {
        this.arrowDownPressed();
      } else if (event.code == 'ArrowUp') {
        this.arrowUpPressed();
      }
    });
  }

}
</script>

<style scoped>

table {
  border-collapse: separate;
  border-spacing: 0;
}

td {
  border-top: 0;
  border-bottom: 1px solid #dee2e6;
}

tbody {
  display: block;
}

thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

</style>