<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-depots"></edit-table-panel>
</template>

<script>
import {getLegalForm, getOrganization} from "@/store/RefHelpers";

export default {
  props: ['isLoading', 'depotGroupId', 'filterText', 'chosenRows', 'depots'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__isSelected",
          caption: "",
          isSortable: false,
          sortType: 'boolean',
          displayType: 'checkbox',
          headerAlign: 'center',
          dataAlign: 'center',
          inputStyle: {
            width: '18px',
            height: '18px',
            position: 'relative'
          },
          cellStyle: {
            verticalAlign: 'middle !important',
            paddingBottom: "6.5px"
          }
        },
        {
          field: "depot_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
          show: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
          show: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
          show: true,
        },
        {
          field: "__orgName",
          caption: "Организация",
          sortType: 'string',
          isFilterable: true,
          show: true,
        },
        {
          field: "__legalFormName",
          caption: "Организационно-правовая форма",
          sortType: 'string',
          show: false,
        },
        {
          field: "time_zone",
          caption: "Часовой пояс (мин)",
          sortType: 'number',
          show: false,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      // получаем все предприятия
      let allDepots = this.depots;
      allDepots.map(depot => {
        // если маршруты только получены или взведен тригер снятия всех галочек
        if ((depot.__isSelected == null && depot.__isSelected == undefined) || this.chosenRows == 'clearAll') {
          // снимаем все галочки слева
          depot.__isSelected = false;
        }
      });

      let depots;
      // фильтруем маршруты в случае выбранной группы
      const links = this.$store.getters["ref/getDepotGroupLinks"];
      if (this.depotGroupId) {
        depots = allDepots.filter(depot => {
          return links.some(link => link.depot_id == depot.depot_id && link.depot_group_id == this.depotGroupId)
        });
      }
      else {
        depots = allDepots;
      }

      // отфильтрованные маршруты
      let filteredDepots = [];
      // в случае текста в поле "фильтр" фильтруем маршруты
      if (this.filterText != '') {
        let str;
        filteredDepots = depots.filter((row) => {
          return this.columnList.some((col) => {
            let item = col.subField ? row[col.subField][col.field] : row[col.field];
            if (col.isFilterable && col.show) {
              if (item == null) {
                str = '';
              } else {
                str = String(item).toLowerCase();
              }
              return str.includes(this.filterText.toLowerCase());
            } else {
              return false;
            }
          });
        });
      } else {
        filteredDepots = [];
        depots.map(value => {
          filteredDepots.push(value)
        })
      }

      filteredDepots.map(depot => {
        const legalForm = getLegalForm(depot.legal_form_id);
        const org = getOrganization(depot.org_id);

        depot.__id = depot.depot_id;
        depot.__legalFormName = legalForm && legalForm.long_name || '';
        depot.__orgName =  org && org.long_name || '';

        return depot
      });

      // в случае, если нажата кнопка "выбрать все строки на экране" т.е. отфильтрованные строки
      // сетаем отфильтрованные маршруты выбранными
      if (this.chosenRows == 'selectFiltered'){
        filteredDepots.map(value => {
          value.__isSelected = true;
        })
      }
      // сбрасываем режим для галочек (поле __isSelected)
      this.$emit('selection', allDepots)

      return filteredDepots;
    },
  },
};
</script>
