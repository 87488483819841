<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to="routeDocumentsLink">Документы маршрута {{ routePageHeader}}</router-link></span>
        <span> / </span>
        <span>Файлы документа маршрута {{ routeDocPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: false, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <files-edit-table
              :filterText="filterText"
              :selectedRow="selectedRow"
              :rowList="rowList"
              @onDownloadFile="onDownloadFile"
              @onRowSelect="onRowSelect"
          ></files-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <file-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </file-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить файл с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import FileDialog from "@/components/doc/files/FileDialog";
import FilesEditTable from "@/components/doc/files/FilesEditTable";
import {getFileFormat} from "@/store/DocHelpers";
import {addMutation, delMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    FileDialog,
    FilesEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id",
    // идентификатор маршрута
    "route_id",
    // идентификатор  документа маршрута
    "route_doc_id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // маршрут
      route: {},
      // документ маршрута
      routeDoc: {},
      // список файлов
      files: [],
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return ( this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {}
      } else return this.selectedRow;
    },

    //переход к документам маршрута
    routeDocumentsLink(){
      return "/multipass/routes/" + this.route_id + "/routeDocs";
    },

    //получаем строки таблицы в формате общего документа
    rowList() {
      if (this.isLoading === 'fetch') return [];

      return this.files.map(file => {
        const format = getFileFormat(file.file_format_id);

        file.__id = file.document_file_id;
        file.__fileFormatName = format && format.file_format_name || '';
        return file;
      });
    },
  },

  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeDocs/" + this.route_doc_id + "/routeDocFiles/add");
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeDocs/" + this.route_doc_id + "/routeDocFiles/del/" + this.selectedRow.document_file_id);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      this.refreshData();
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeDocs/" + this.route_doc_id + "/routeDocFiles");
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeDocs/" + this.route_doc_id + "/routeDocFiles");
    },

    // загружаем файл
    onDownloadFile(item) {
      // загружаем элемент
      this.downloadItem(item);
      // возвращаемся на страницу
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeDocs/" + this.route_doc_id + "/routeDocFiles");
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeDocs/" + this.route_doc_id + "/routeDocFiles");
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // запрос остановки
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        }); if (!route) return;
        this.route = route;

        // запрос документа
        const routeDoc = await this.$store.dispatch('multipass/doFetchRouteDoc', {
          route_doc_id: this.route_doc_id
        }); if (!routeDoc) return;
        this.routeDoc = routeDoc;

        // форматы файлов
        await this.$store.dispatch('doc/doFetchFileFormats');

        // список файлов
        const files = await this.$store.dispatch('multipass/doFetchRouteDocFiles', {
          route_doc_id: this.route_doc_id
        }); if (!files) return;
        this.files = files;

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        // запрос на сервер
        const files = await this.$store.dispatch('multipass/doAddRouteDocFile', {
          route_doc_id: this.route_doc_id,
          file: item,
        });

        // мутируем массив
        this.selectedRow = addMutation(this.files, 'document_file_id', files[0]);
      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрос на сервер
        const file = await this.$store.dispatch('multipass/doDelRouteDocFile', {
          document_file_id: this.selectedRow.document_file_id,
          route_doc_id: this.route_doc_id,
        })
        // мутируем массив
        this.selectedRow = delMutation(this.files, 'document_file_id', file);

      }
      finally {
        this.isLoading = false;
      }
    },

    // загружаем объект
    async downloadItem(item) {
      this.isLoading = true;
      try {

        await this.$store.dispatch('multipass/doFetchRouteDocFile', {
          document_file_id: item.document_file_id,
          route_doc_id: this.route_doc_id,
          file_name: item.file_name
        });

      }
      finally {
        this.isLoading = false;
      }
    }
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
