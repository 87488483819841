<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span>Транспортные предприятия</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!--          Поставить галочки всем маршрутам на экране-->
            <template #preLeftCustomButton>
              <button style="padding: 0px; border-width: 0px; background-color: white; margin-top: 0.2rem"
                      @click="setAllSelected">
                <i :class='getSelectedDepots.length == depots.length ? "fas fa-check-square fa-2xl fa-2x" : "far fa-check-square fa-2xl fa-2x"'
                   title="Выделить всё"
                   :style='getSelectedDepots.length == depots.length ? "color: #007BFF" : "color: #000000"'></i>
              </button>
            </template>

            <!--            Снять галочки со всех маршщрутов   <font-awesome-icon icon="fas fa-check-square" /> -->
            <template #preLeftCustomButton2>
              <button style="padding: 0px; border-width: 0px; background-color: white; margin-top: 0.2rem"
                      @click="setAllClear">
                <i :class='getSelectedDepots.length == 0 ? "fas fa-square fa-2x" : "far fa-square fa-2x"'
                   title="Снять все" :style='getSelectedDepots.length == 0 ? "color: #007BFF" : "color: #000000"'></i>
              </button>
            </template>

            <!--        Выбор даты начала  -->
            <template #leftCustomButton>
              <input-date
                  id="startDate"
                  v-model="startDate"
                  class="form-control"
                  @change="setDate"
              >
              </input-date>
            </template>

            <!--        Снять галочки со всех маршрутов  -->
            <template #leftCustomButton2>
              <input-date
                  id="endDate"
                  v-model="endDate"
                  class="form-control"
                  @change="setDate"
              >
              </input-date>
            </template>

            <!-- Кнопка связь  -->
            <template #leftCustomButton3>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Аналитические формы ({{ getSelectedDepots.length }} {{ getDepotTitle }} из {{ depots.length }})
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="passengerTrafficDayByTransportRawLink">Диагностика оборудования
                  </dropdown-item>
                  <dropdown-item router-link :to="passengerTrafficDayByTransportLink">За сутки по ТС
                  </dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Группы-->
            <template #leftCustomButton4>
              <depot-groups-select class="form-control" v-model="depotGroupId"
                                   @change="setDepotGroupId"></depot-groups-select>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <asmpp-depots-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :depotGroupId="depotGroupId"
              :selectedRow="selectedRow"
              :chosenRows="chosenRows"
              :depots="depots"
              @selection="setSelection"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></asmpp-depots-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <depot-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
  >
  </depot-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import DepotDialog from "@/components/ref/depots/DepotDialog";
import DepotGroupsSelect from "@/components/ref/depot_groups/DepotGroupsSelect";
import PageMixin from "@/pages/share/PageMixin";
import {dateToND, dateToString, getDateMinusMonth, ndToDate} from "@/lib";
import AsmppDepotsEditTable from "@/components/asmpp/depots/AsmppDepotsEditTable";

export default {
  mixins: [PageMixin],

  components: {
    AsmppDepotsEditTable,
    DepotDialog,
    DepotGroupsSelect
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      depotGroupId: null,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // оригинальный список строк
      depots: [],
      // режим отметки строк
      chosenRows: '',
      // дата начала периода
      startDate: dateToString(getDateMinusMonth(ndToDate(dateToND() - 1))),
      // дата конца периода
      endDate: dateToString(ndToDate(dateToND() - 1)),
    }
  },

  computed: {

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show')
    },

    // данные, которые отображаются в диалоговом окне
    dialogRow() {
      return this.op === 'add' ? {} : this.selectedRow;
    },

    // получаем выбранные галочкой маршруты
    getSelectedDepots() {
      return this.depots.filter(depot => {
        if (depot.__isSelected == true) {
          return depot;
        }
      })
    },

    getDepotTitle() {
      return 'предпр.';
    },

    // условие отключения кнопки перехода к рейсам
    linkDisabled() {
      return this.getSelectedDepots.length == 0
    },

    // ссылка для перехода к рейсам
    passengerTrafficDayByTransportLink() {
      if (!this.linkDisabled) {
        let depotIDs = []
        this.depots.map(depot => {
          if (depot.__isSelected)
            depotIDs.push(depot.depot_id)
        })

        let depotForm = '';
        depotIDs.map(depot_id => {
          depotForm = depotForm + '/' + depot_id;
        })

        return '/asmpp/depots/' + dateToND(new Date(this.startDate)) + '/' + dateToND(new Date(this.endDate)) + depotForm + '/passengerTrafficDayByTransport';
      } else {
        return '';
      }
    },

    // ссылка для перехода к  необработанному пассажиропотоку
    passengerTrafficDayByTransportRawLink() {
      if (!this.linkDisabled) {
        let depotIDs = []
        this.depots.map(depot => {
          if (depot.__isSelected)
            depotIDs.push(depot.depot_id)
        })

        let depotForm = '';
        depotIDs.map(depot_id => {
          depotForm = depotForm + '/' + depot_id;
        })

        return '/asmpp/depots/' + dateToND(new Date(this.startDate)) + '/' + dateToND(new Date(this.endDate)) + depotForm + '/passengerTrafficDayByTransportNavSum';
      } else {
        return '';
      }
    },

  },
  methods: {

    // ставим все строки выбранными
    setAllSelected() {
      this.chosenRows = 'selectFiltered';
    },

    // ставим все строки невыбранными
    setAllClear() {
      this.chosenRows = 'clearAll';
    },

    // убираем режим выделения или очищения галочек строк
    setSelection() {
      this.chosenRows = '';
    },

    // сохраняем DepotGroupId в стор
    setDepotGroupId() {
      this.$store.commit('asmpp/setDepotsDepotGroupId', this.depotGroupId);
    },

    // сохраняем даты в стор
    setDate() {
      this.$store.commit('asmpp/setStartDate', this.startDate);
      this.$store.commit('asmpp/setEndDate', this.endDate);
    },

    // нажата конпка "Просмотр"
    onTableShow() {
      this.$router.replace("/asmpp/depots/show/" + this.selectedRow.depot_id);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/asmpp/depots");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = "fetch";
      try {

        // проверяем есть ли даты в сторе, если есть, то подставляем
        let startDate = this.$store.getters['asmpp/getStartDate'];
        let endDate = this.$store.getters['asmpp/getEndDate'];
        let depotGroupId = this.$store.getters['asmpp/getDepotsDepotGroupId'];
        if (startDate) {
          this.startDate = startDate;
        }
        if (endDate) {
          this.endDate = endDate;
        }
        if (depotGroupId) {
          this.depotGroupId = depotGroupId;
        }

        await this.$store.dispatch('ref/doFetchLegalForms')
        await this.$store.dispatch('ref/doFetchOrganizations')
        await this.$store.dispatch('ref/doFetchDepotGroups')
        await this.$store.dispatch('ref/doFetchDepotGroupLinks')
        let depots = await this.$store.dispatch('ref/doFetchDepots', {force})
        if (depots)
          this.depots = depots;
      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
