<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-personnel-states"></edit-table-panel>
</template>

<script>
import {
  getDepot, getPersonnelJob,
} from "@/store/RefHelpers";
import {dateToString, ndToDate} from "@/lib";
import EditTablePanel from "@/components/ui/custom/EditTablePanel";

export default {
  components: {EditTablePanel},
  props: ['rows', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "personnel_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "personnel_num",
          caption: "Табельный номер",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "last_name",
          caption: "Фамилия",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "first_name",
          caption: "Имя",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "middle_name",
          caption: "Отчество",
          sortType: 'string',
        },
        {
          field: "__personnelJobName",
          caption: "Профессия",
          sortType: 'string',
        },
        {
          field: "__depotName",
          caption: "Предприятие",
          sortType: 'string',
        },
        {
          field: "depot_division",
          caption: "№ колонны",
          sortType: 'number',
          show: false,
        },
        {
          field: "license_number",
          caption: "Номер удостоверения",
          sortType: 'string',
          show: false,
        },
        {
          field: "email",
          caption: "Email",
          sortType: 'string',
          show: false,
        },
        {
          field: "phone_num",
          caption: "Номер телефона",
          sortType: 'string',
          show: false,
        },
        {
          field: "birthday",
          caption: "Дата рождения",
          sortType: 'string',
          show: false,
        },
        {
          field: "is_foreman",
          caption: "Бригадир",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_foreman) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false,
        },
        {
          field: "is_trainee",
          caption: "Стажер",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_trainee) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false,
        },
        {
          field: "is_instructor",
          caption: "Инструктор",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_instructor) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false,
        },
        {
          field: "is_disabled",
          caption: "Откл.",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_disabled) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "date_index_begin",
          caption: "Начало действия",
          sortType: 'string',
          displayFn(row) {
            if (Number.isInteger(row.date_index_begin)) {
              return dateToString(ndToDate(row.date_index_begin))
            }
            else return '';
          },
          show: true,
        },
        {
          field: "date_index_end",
          caption: "Окончание действия",
          sortType: 'string',
          displayFn(row) {
            if (Number.isInteger(row.date_index_end)) {
              return dateToString(ndToDate(row.date_index_end))
            }
            else return '';
          },
          show: true,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      return this.rows.map(pers => {
        const depot = getDepot(pers.depot_id);
        const job = getPersonnelJob(pers.personnel_job_id);

        pers.__id = pers.personnel_id;
        pers.__depotName = depot && depot.long_name || '';
        pers.__personnelJobName = job && job.personnel_job_name;

        return pers;
      });
    },
  },
};
</script>
