import {DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ СОСТОЯНИЙ ДНЯ

export default {
    actions: {
        // запрос списка
        doFetchAllDayStates: () => {
            return DoGet(
                '/kvr/dayStates',
                null,
                [],
                'Запрос списка состояний всех дней',
                false,
            )
        },

        doFetchDayStates: (context, payload) => {
            return DoGet(
                '/kvr/dayStates?section=' + payload.section,
                null,
                [],
                'Запрос отфильтрованного списка состояний дней',
                false,
            );
        },

        doFetchDayStatesByDate: (context, payload) => {
            return DoGet(
                '/kvr/dayStates/' + payload.date_index,
                null,
                [],
                'Запрос состояния дня',
                false,
            );
        },

        doMoveDayStates: (context, payload) => {
            return DoPut(
                '/kvr/moveDay',
                payload,
                null,
                'Запрос перемещения дня между секциями',
                true,
            );
        },

        doCloseDayStates: (context, payload) => {
            let caption = '';
            if(payload.day_closed == true) {
                caption = 'Запрос закрытия дня';
            } else if (payload.day_closed == false) {
                caption = 'Запрос открытия дня';
            }
            return DoPut(
                '/kvr/closeDay',
                payload,
                null,
                caption,
                true,
            );
        },

        doReCalcDayStates: (context, payload) => {
            return DoPut(
                '/kvr/reCalcDay',
                payload,
                null,
                'Запрос пересчета дня',
                true,
            );
        },

    }
}