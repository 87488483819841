import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ КОНТРАКТОВ

export default {
    state() {
        return {
            // список объектов
            contracts: [],
            // время обновления кэша new Date().getTime()
            contractsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            contractsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getContracts(state) {
            return state.contracts;
        },
    },
    mutations: {

        // установить новый список объектов
        setContracts(state, payload) {
            state.contracts = payload;
            state.contractsCache = new Date().getTime()
        },
        // добавить объект
        addContract(state, payload) {
            state.contracts.push(payload)
        },
        // изменить объект
        editContract(state, payload) {
            const index = state.contracts.findIndex(contract => contract.contract_id == payload.contract_id);
            if (index >= 0) {
                state.contracts[index] = payload;
            }
        },
        // удалить объект
        delContract(state, payload) {
            const index = state.contracts.findIndex(contract => contract.contract_id == payload.contract_id);
            state.contracts.splice(index, 1);
        }
    },
    actions: {

        // запрос списка объектов
        doFetchContracts: (context, payload) => {
            return DoGet(
                '/kvr/contracts',
                'kvr/setContracts',
                [],
                'Запрос контрактов',
                false,
                payload && payload.force,
                context.state.contractsCache,
                context.state.contractsCacheTimeout
            )
        },

        // запрос объекта
        doFetchContract: (context, payload) => {
            return DoGet(
                '/kvr/contracts/' + payload.contract_id,
                null,
                [],
                'Запрос контракта',
                false,
                payload && payload.force,
                context.state.contractsCache,
                context.state.contractsCacheTimeout
            )
        },

        // очищает кэш
        doClearContracts: (context, payload) => {
            return DoClearCache(
                'kvr/setContracts',
                [],
                payload && payload.force,
                context.state.contractsCache,
                context.state.contractsCacheTimeout
            )
        },

        // добавление объекта
        doAddContract: (context, payload) => {
            return DoPost(
                '/kvr/contracts',
                payload,
                'kvr/addContract',
                'Добавление контракта'
            )
        },

        // изменение объекта
        doEditContract: (context, payload) => {
            return DoPut(
                '/kvr/contracts/' + payload.contract_id,
                payload,
                'kvr/editContract',
                'Изменение контракта'
            )
        },

        // удаление объекта
        doDelContract: (context, payload) => {
            return DoDelete(
                '/kvr/contracts/' + payload.contract_id,
                'kvr/delContract',
                'Удаление контракта'
            )
        }
    }
}
