<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-main-params"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getModule} from "@/store/SystemHelpers";
export default {
  components: {EditTablePanel},
  props: ['isLoading', 'moduleId'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "module_name",
          caption: "Модуль",
          sortType: 'String',
          isFilterable: true
        },
        {
          field: "key",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "value",
          caption: "Значение",
          sortType: 'string',
        },
        {
          field: "desc",
          caption: "Описание",
          sortType: 'string',
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }
      let arr = [];
      this.$store.getters["system/getMainParams"].forEach(param => {
        if (this.moduleId == param.module_id || this.moduleId == null) {
          param.__id = param.module_id + ' ' + param.key;
          param.module_name = getModule(param.module_id).module_name;
          arr.push(param);
        }
      });
      return arr;
    },
  },
};
</script>
