import RoutingGisStore from "@/store/gis/RoutingGisStore";
import GraphGisStore from "@/store/gis/GraphGisStore"
import RoadsGisStore from "@/store/gis/RoadsGisStore";

// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ GIS

export default {
    namespaced: true,
    modules: {
        RoutingGisStore,
        GraphGisStore,
        RoadsGisStore
    }
}
