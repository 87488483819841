<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/admin">Администрирование</router-link></span>
        <span> / </span>
        <span><router-link to="/admin/users">Пользователи</router-link></span>
        <span> / </span>
        <span>Доступ к данным {{ userPageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >
          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <user-accesses-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :rows="rows"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></user-accesses-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <user-access-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </user-access-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить доступ пользователя к данным категории с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import CrudForm from "@/components/ui/custom/CrudForm";
import {getUser} from "@/store/AuthHelpers";
import UserAccessesEditTable from "@/components/access/user_accesses/UserAccessesEditTable";
import UserAccessDialog from "@/components/access/user_accesses/UserAccessDialog";
import PageMixin from "@/pages/share/PageMixin";
import {addMutation, delMutation, editMutation} from "@/store/Actions";

export default {
  mixins: [PageMixin],

  components: {
    UserAccessesEditTable,
    CrudForm,
    UserAccessDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id",
    // Идентификатор пользователя
    "uid"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // список строк
      rows: [],
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {}
      } else return this.selectedRow;
    },

    //получаем пользователя
    user() {
      return getUser(this.uid);
    },
  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/admin/users/" + this.uid + "/userAccesses/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/admin/users/" + this.uid + "/userAccesses/edit/" + this.selectedRow.access_category_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/admin/users/" + this.uid + "/userAccesses/del/" + this.selectedRow.access_category_id);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // выполнено двойное нажатие строки в таблице (открываем просмотр)
    onTableShow(item) {
      this.$router.replace("/admin/users/" + this.uid + "/userAccesses/show/" + item.access_category_id);
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/admin/users/userAccesses/" + this.uid);
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('auth/doFetchUsers');
        await this.$store.dispatch('access/doFetchAccessCategories');
        await this.$store.dispatch('ref/doFetchTransportGroups');
        await this.$store.dispatch('ref/doFetchTransportTypeGroups');
        await this.$store.dispatch('ref/doFetchDepotGroups');
        await this.$store.dispatch('multipass/doFetchRouteGroups');
        const rows = await this.$store.dispatch('access/doFetchUserAccesses', {
          uid: this.uid,
          force: force
        }); if (!rows) return;
        this.rows = rows;

      }
      finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/admin/users/userAccesses/" + this.uid);
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        item['uid'] = Number(this.uid);
        const newItem = await this.$store.dispatch('access/doAddUserAccess', item);
        this.selectedRow = addMutation(this.rows, 'access_category_id', newItem);

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        item['uid'] = Number(this.uid);
        const newItem = await this.$store.dispatch('access/doEditUserAccess', item)
        this.selectedRow = editMutation(this.rows, 'access_category_id', newItem);

      } finally {
        this.isLoading = false;
      }
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/admin/users/userAccesses/" + this.uid);
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        const oldItem = await this.$store.dispatch('access/doDelUserAccess', this.selectedRow);
        this.selectedRow = delMutation(this.rows, 'access_category_id', oldItem);

      }
      finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

