<template>
    <!-- Карта-->
    <leaflet-map
        :zoom="zoom"
        :center="center"
        :contextMenu="mapContextMenu"
        :options="mapOptions"
        @onMapCreated="onMapCreated"
    >

      <!-- Элементы управления-->
      <leaflet-control-group>
        <!-- Базовые слои-->
        <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
      </leaflet-control-group>

      <!-- Базовая линия типа рейса -->
      <base-line-polyline
          v-if="isMapEditMode"
          state="edit"
          :polyline="tripTypeBaseLine"
          @onPolylineChanged="(...args) => {$emit('onBaseLineChanged', ...args)}"
      >
      </base-line-polyline>

      <!-- Линия типа рейса-->
      <trip-type-line-polyline
          :polyline="tripTypeLine"
      >
      </trip-type-line-polyline>

      <!-- Линия прокладки-->
      <routing-line-polyline
          :polyline="routingLine"
      >
      </routing-line-polyline>

      <!--  Обычные остановки-->
      <stop-marker
          v-for="stop in originalStops"
          :key="stop.stop_id"
          :stop="stop"
          :tooltip="stop.long_name + ' (#'+ stop.stop_id+')'"
          type="0"
          state="original"
      ></stop-marker>

      <!--  Остановки трассы -->
      <stop-marker
          v-for="stop in tripCardStops"
          type="3"
          :key="stop.order_num"
          :stop="stop"
          :tooltip="stop.long_name + ' (#'+ stop.stop_id+')'"
          :state="selectedRow && stop.order_num === selectedRow.order_num ? 'active' : 'original'"
          :contextMenu="isMapEditMode ? stopContextMenu : stopNoContextMenu"
          :meta="stop.order_num"
          events="click"
          @onLeafletEvent="onLeafletEvent(stop.order_num)"
      ></stop-marker>

    </leaflet-map>
</template>

<script>

import {getStop, getStopsInRectangle} from "@/store/MultipassHelpers";

export default {
  props: [
    // карта в режиме редактирования
    'isMapEditMode',
    // отображать все остановки
    'isAllStops',
    // линия типа рейса
    'tripTypeLine',
    // базовая линия типа рейса
    'tripTypeBaseLine',
    // линия для прокладки
    'routingLine',
    // карточка типа рейса
    'tripTypeCard',
    // выбранная остановка
    'selectedRow'
  ],
  emits: ['onBaseLineChanged', 'onDeleteStop', 'onStopSelect'],
  computed: {
    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom']
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter']
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },

    // минимальный масштаб для отображения остановок
    minStopZoom() {
      return this.$store.getters['settings/getMinStopZoom']
    },

    // список остановок попавших в квадрат
    originalStops() {
      const stops = [];
      if (this.curZoom >= this.minStopZoom && this.isAllStops) {
        getStopsInRectangle(this.minLat, this.maxLat, this.minLong, this.maxLong).forEach(stop => {
          if (!stop.latitude || !stop.longitude) return;

          // добавляем остановку только, если её нет в трассе
          if (!this.tripCardStops.some(s => s === stop)) {
            stops.push(stop);
          }
        });
      }
      return stops;
    },

    // список остановок трассы
    tripCardStops() {
      const stops = [];
      this.tripTypeCard.forEach((item) => {
        const stop = getStop(item.stop_id);
        if (stop) {
          if (!stop.latitude || !stop.longitude) return;
          stop.order_num = item.order_num;
          stops.push({...stop});
        }
      })
      return stops;
    }
  },

  data() {
    return {
      // карта
      leaflet: null,
      // границы карты
      minLat: 0,
      maxLat: 0,
      minLong: 0,
      maxLong: 0,
      // текущий масштаб
      curZoom: 0,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // контекстное меню карты
      mapContextMenu: {
        contextmenu: true,
        contextmenuWidth: 140,
        contextmenuItems: []
      },
      // контекстное меню остановки
      stopContextMenu: {
        contextmenu: true,
        contextmenuInheritItems: false,
        contextmenuWidth: 140,
        contextmenuItems: [
          {
            name: 'delStop',
            text: 'Удалить из трассы',
            callback: (event) => {
              if (event.relatedTarget && event.relatedTarget.__meta != null) {
                this.$emit('onDeleteStop', Number(event.relatedTarget.__meta))
              }
            }
          },
        ]
      },
      // контекстное меню остановки отсуствует
      stopNoContextMenu: {
        contextmenu: false,
      }
    }
  },

  methods: {
    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;

      // функция определяет границы карты и текущий zoom
      const getMapValues = () => {
        // определяем границы карты
        const bounds = this.leaflet.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        this.minLat = southWest.lat;
        this.maxLat =  northEast.lat;
        this.minLong = southWest.lng;
        this.maxLong = northEast.lng;
        // определяем текущий zoom
        this.curZoom = leaflet.getZoom();
      }

      getMapValues();
      leaflet.on('moveend', () => {
        getMapValues();
      });
    },

    // вызывается при щелчке на остановке
    onLeafletEvent(order_num) {
      const row = this.tripTypeCard.find(item => item.order_num === order_num);
      if (row) {
        this.$emit('onStopSelect', row);
      }
    },

  }
}
</script>
