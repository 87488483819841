export default {

    state() {
        return {
            // дата, выбранная в панельке WorkTasksPage
            selectedOrderDate: null,

        }
    },
    getters:{
        // возвращаем дату, выбранную в панельке WorkTasksPage
        getSelectedOrderDate(state){
            return state.selectedOrderDate;
        },

    },
    mutations:{

        // задаем дату, выбранную в панельке WorkTasksPage
        setSelectedOrderDate(state, payload){
            state.selectedOrderDate = payload;
        },


    }
}