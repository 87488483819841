import {DoGet} from "@/store/Actions";
import store from "@/store/Store";

// ХРАНИЛИЩЕ ТИПОВ РЕЙСОВ МАРШРУТОВ

export default {
    state() {
        return {
            // список объектов
            routeTripTypeList: [],
            // время обновления кэша new Date().getTime()
            routeTripTypeListCache: null,
            // время жизни кэша в мс (null - бесконечно)
            routeTripTypeListCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRouteTripTypeList(state) {
            return state.routeTripTypeList
        },
    },
    mutations: {
        // добавление нового объекта
        addRouteTripTypeList(state, payload) {
            state.routeTripTypeList[payload.route_id] = payload.route_lines_list;
            state.routeTripTypeListCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка
        doFetchRouteTripTypeList: async (context, payload) => {
            let response = await DoGet(
                '/kvr/getRouteTripTypeList/' + payload.date_index + '/' + payload.route_id,
                'kvr/addRouteTripTypeList',
                [],
                'Запрос списка всех типов рейсов маршрута',
                false,
                payload && payload.force,
                context.state.routeTripTypeListCache,
                context.state.routeTripTypeListCacheTimeout
            );
            store.commit('kvr/addRouteTripTypeList', {
                route_id: payload.route_id,
                route_lines_list: response,
            });
            return response;
        },

        // запрос списка
        doFetchRouteTripTypeListFiltered: async (context, payload) => {
            let response = await DoGet(
                '/kvr/getRouteTripTypeList/' + payload.date_index + '/' + payload.route_id + '?is_production=1',
                null,
                [],
                'Запрос отфильтрованного списка всех типов рейсов маршрута',
                false,
                payload && payload.force,
                context.state.routeTripTypeListCache,
                context.state.routeTripTypeListCacheTimeout
            );
            store.commit('kvr/addRouteTripTypeList', {
                route_id: payload.route_id,
                route_lines_list: response,
            });
            return response;
        },
    }
}