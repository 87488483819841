<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-report-groups"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getModule, getReportGroup} from "@/store/SystemHelpers";

export default {
  components: {EditTablePanel},
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "report_group_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "__module",
          caption: "Модуль",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "report_group_name",
          caption: "Наименование",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "report_group_desc",
          caption: "Описание",
          sortType: 'string',
          isFilterable: true,

        },
        {
          field: "__parent_report_group",
          caption: "Родительская группа отчетов",
          sortType: 'string',
          isFilterable: true
        },
        {
          field: "is_disabled",
          caption: "Заблок.",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_disabled) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["system/getReportGroups"].map(reportGroup => {
        reportGroup.__id = reportGroup.report_group_id;
        if (reportGroup.parent_report_group_id != null) {
          reportGroup.__parent_report_group = getReportGroup(reportGroup.parent_report_group_id).report_group_name;
        }
        reportGroup.__module = getModule(reportGroup.module_id).module_name;
        return reportGroup;
      });
    },
  },
};
</script>
