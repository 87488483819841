<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Связь с сущностью внешней системы</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="depotId">Код сущности в локальной системе:</label>
              <input-integer
                  id="localId"
                  type="number"
                  class="form-control"
                  v-model="localId"
                  readonly
              />
            </div>

            <div class="form-group">
              <label for="srcNum">Номер источника:</label>
              <req-field/>
              <input-select
                  class="form-control"
                  id="srcNum"
                  v-model="srcNum"
                  ref="srcNum"
                  :itemList="[{id: 0, title: 0},{id: 1, title: 1},{id: 2, title: 2},{id: 3, title: 3},{id: 4, title: 4},{id: 5, title: 5},{id: 6, title: 6},{id: 7, title: 7},{id: 8, title: 8},{id: 9, title: 9},]"
                  required
                  :readonly="op == 'edit'"
                  caption="Выберите номер источника..."
                  error="Выберите номер источника"
              />
            </div>

            <div class="form-group">
              <label for="externalId">Код сущности во внешней системе:</label>
              <req-field/>
              <input-string
                  id="externalId"
                  class="form-control"
                  v-model="externalId"
                  required
                  error="Введите код сущности во внешней системе"
              />
            </div>

            <div class="form-group">
              <label for="localСhecksum">Хеш-сумма сущности в локальной системе:</label>
              <req-field/>
              <input-string
                  id="localСhecksum"
                  class="form-control"
                  v-model="localСhecksum"
                  required
                  error="Введите хеш-сумму сущности в локальной системе"
              />
            </div>

            <div class="form-group">
              <label for="externalChecksum">Хеш-сумма сущности во внешней системе:</label>
              <req-field/>
              <input-string
                  id="externalChecksum"
                  class="form-control"
                  v-model="externalChecksum"
                  required
                  error="Введите хеш-сумму сущности во внешней системе"
              />
            </div>

          </form>
        </modal-body>
        <modal-footer>
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import InputSelect from "@/components/ui/custom/InputSelect";
import InputString from "@/components/ui/custom/InputString";

export default {
  components: {InputString, InputSelect, ReqField},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      linkedEntityId: this.item.linked_entity_id,
      localId: this.item.local_id,
      srcNum: this.item.src_num,
      externalId: this.item.external_id,
      localСhecksum: this.item.local_checksum,
      externalChecksum: this.item.external_checksum,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          linked_entity_id: this.op === 'add' ? undefined : this.linkedEntityId,
          local_id: this.localId,
          src_num: this.srcNum,
          external_id: this.externalId,
          local_checksum: this.localСhecksum,
          external_checksum: this.externalChecksum,
        });
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>