<template>
    <nav>
      <ol class="breadcrumb" v-bind="$attrs">
        <slot></slot>
      </ol>
    </nav>
</template>

<script>
export default {
  inheritAttrs: false
}
</script>
