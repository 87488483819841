<template>
    <modal backdrop-type="transparent">
        <modal-dialog class="modal-dialog-centered modal-sm">
            <modal-content>
                <modal-body class="text-center bg-bg-light border rounded">
                    <div class="spinner-border text-primary"></div>
                    <h6 class="text-primary">Пожалуйста, подождите...</h6>
                </modal-body>
            </modal-content>
        </modal-dialog>
    </modal>
</template>
