import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ОТМЕТОК НА ОСТАНОВОЧНОМ ПУНКТЕ


export default {
    state() {
        return {
            // список объектов
            pointMarks: [],
            // время обновления кэша new Date().getTime()
            pointMarksCache: null,
            // время жизни кэша в мс (null - бесконечно)
            pointMarksCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getPointMarks(state) {
            return state.pointMarks;
        },
    },
    mutations: {
        // установить новый список объектов
        setPointMarks(state, payload) {
            state.pointMarks = payload;
            state.pointMarksCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchPointMarks: (context, payload) => {
            return DoGet(
                '/trn/pointMarks',
                'trn/setPointMarks',
                [],
                'Запрос списка отметок на остановочном пункте',
                false,
                payload && payload.force,
                context.state.pointMarksCache,
                context.state.pointMarksCacheTimeout
            )
        },

        // запрос объекта
        doFetchPointMark: (context, payload) => {
            return DoGet(
                '/trn/pointMarks/' + payload.point_mark_id,
                'trn/setPointMarks',
                [],
                'Запрос отметки на остановочном пункте',
                false,
                payload && payload.force,
                context.state.pointMarksCache,
                context.state.pointMarksCacheTimeout
            )
        },

        // очищает кэш
        doClearPointMarks: (context, payload) => {
            return DoClearCache(
                'trn/setPointMarks',
                [],
                payload && payload.force,
                context.state.pointMarksCache,
                context.state.pointMarksCacheTimeout
            )
        },
    }
}