<template>
  <edit-table-panel :columnList="columnList"  :disableSettings="true" :rowList="rowList"
                    :rowsPerPage="100" name="tbl-kvr-exp-command-logs"></edit-table-panel>
</template>

<script>

import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getDateTimeFormat, getSignTitle, ndToDateString, secondsToTime} from "@/lib";
import {getTransportType} from "@/store/RefHelpers";
import { getRoute} from "@/store/KvrHelpers";
import {getCommand, getCommandReason, getExpert} from "@/store/KvrExpertHelpers";

export default {
  components: {EditTablePanel},
  props: ['rows', 'selectedTripTypeSign'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: '__date',
          caption: 'Дата',
          headerAlign: 'left',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__route_number',
          caption: 'Маршрут',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__route_title',
          caption: 'Наименование маршрута',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__trType',
          caption: 'Тип транспорта',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'plan_graph',
          caption: 'График',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'plan_shift',
          caption: 'Смена',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'sign',
          caption: 'Тип рейса',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__plan_trip_time_index_begin',
          caption: 'Начало',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__plan_trip_time_index_end',
          caption: 'Конец',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
        },

        {
          field: '__exp_command',
          caption: 'Команда',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__exp_command_reason',
          caption: 'Причина',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'expert',
          caption: 'Эксперт',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'exp_command_length_fact',
          caption: 'Факт. пробег',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'exp_command_comment',
          caption: 'Комментарий',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__date_time',
          caption: 'Время',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
      ],
    }
  },

  computed: {

    rowList() {

      if(!this.rows) {
        return [];
      }

      return this.rows.map(resultTrip => {
        resultTrip.__id = resultTrip.plan_trip_id;

        // определяем маршрут
        let route = getRoute(resultTrip.route_id);
        if(route){
          resultTrip.__route_number = route.route_num;
          resultTrip.__route_title = route.route_title;
          resultTrip.__regNum = route.reg_num;
          resultTrip.__route_id = route.route_id;
          // получаем тип транспорта
          let trType = getTransportType(route.transport_type_id);
          resultTrip.__trType = trType.short_name
        }

        // получаем команду
        let command = getCommand(resultTrip.exp_command_id);
        if(command) {
          resultTrip.__exp_command = command.long_name;
        }

        // получаем причины
        let reason = getCommandReason(resultTrip.exp_command_reason_id);
        if(reason) {
          resultTrip.__exp_command_reason = reason.long_name;
        }

        // получаем эксперт
        let expert = getExpert(resultTrip.uid);

        if(expert) {
          resultTrip.expert = (expert.last_name ? expert.last_name : '') + ' ' + (expert.first_name ? expert.first_name.substring(0,1) + '.' : '')  + (expert.middle_name ? expert.middle_name.substring(0,1) + '.' : '');
        }

        //определяем дату
        resultTrip.__date = ndToDateString(resultTrip.date_index);
        resultTrip.__date_time = getDateTimeFormat(resultTrip.exp_command_datetime);
        resultTrip.__plan_trip_time_index_begin = secondsToTime(resultTrip.plan_trip_time_index_begin)
        resultTrip.__plan_trip_time_index_end = secondsToTime(resultTrip.plan_trip_time_index_end)

        // // задаем цвет щаднего фона строк
        // if (resultTrip.trip_mark == 0) {
        //   if (resultTrip.fact_trip_id) {
        //     resultTrip.__rowStyle = {
        //       backgroundColor: '#ffece8'
        //     }
        //   } else {
        //     resultTrip.__rowStyle = {
        //       backgroundColor: '#f8f19b'
        //     }
        //   }
        // } else if (resultTrip.trip_mark == 1) {
        //   resultTrip.__rowStyle = {
        //     backgroundColor: '#daffd7'
        //   }
        // } else if (resultTrip.trip_mark == 2) {
        //   resultTrip.__rowStyle = {
        //     backgroundColor: '#a4ff9c'
        //   }
        // }
        //
        // if(resultTrip.exp_command_id != null) {
        //   resultTrip.__rowStyle.fontWeight = 'bold'
        // }

        // получаем сигнатуру рейса
        resultTrip.sign = getSignTitle(resultTrip.trip_type_sign);

        // задаем стиль ячеек
        resultTrip.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px'
        };

        return resultTrip;
      });
    }

  }
}
</script>

<style scoped>

</style>