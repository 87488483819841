<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-radio-states"></edit-table-panel>
</template>

<script>
import {
  getDepot, getTransport,

} from "@/store/RefHelpers";
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getRadioModel, getRadioProtocol} from "@/store/NavHelpers";
import {secondsToDateAndTimeWithoutSeconds} from "@/lib";

export default {
  components: {EditTablePanel},
  props: ['isLoading', 'rows'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "radio_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "radio_num",
          caption: "Номер радиостанции",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "radio_protocol",
          caption: "Код эфирного протокола",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "radio_gate_num",
          caption: "Номер шлюза",
          sortType: 'string',
          isFilterable: true
        },
        {
          field: "radio_model",
          caption: "Модель",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "depot",
          caption: "Предприятие",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "transport",
          caption: "ТС",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "radio_priority",
          caption: "Приоритетность",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "is_disabled",
          caption: "Откл.",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_disabled) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false,
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "time_index_begin",
          caption: "Начало действия",
          sortType: 'string',
          displayFn(row) {
            if (Number.isInteger(row.time_index_begin)) {
              return secondsToDateAndTimeWithoutSeconds(row.time_index_begin);
            }
            else return '';
          },
          show: true,
        },
        {
          field: "time_index_end",
          caption: "Окончание действия",
          sortType: 'string',
          displayFn(row) {
            if (Number.isInteger(row.time_index_end)) {
              return secondsToDateAndTimeWithoutSeconds(row.time_index_end);
            }
            else return '';
          },
          show: true,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      return this.rows.map(radioState => {

        radioState.__id = radioState.radio_id;
        // загружаем эфирный протокол
        let radio_protocol = getRadioProtocol(radioState.radio_protocol_id);
        radioState.radio_protocol = radio_protocol.radio_protocol_title;
        // загружаем модель радиостанции
        let radio_model = getRadioModel(radioState.radio_model_id);
        radioState.radio_model = radio_model.radio_model_title;
        // загружаем транспорт
        let ts = getTransport(radioState.transport_id);
        radioState.transport = ts.state_num + ' / ' + ts.garage_num;
        // загружаем предприятие
        let depot = getDepot(radioState.depot_id);
        radioState.depot = depot.long_name;

        return radioState;
      });
    },
  },
};
</script>
