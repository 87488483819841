<template>

  <align-container>
    <!-- Хлебные крошки       :to="factTripsPageLink"          -->
    <align-panel align="top">

      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span><router-link to="/asmpp/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span @click="passengerTrafficFactTripByHoursLink" style="cursor: pointer">Маршрутный пассажиропоток {{routesWithDatePageHeader}}</span>
        <span> / </span>
        <span>Рейсовый пассажиропоток по часам суток</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{ refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка связь  -->
            <template #leftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Аналитические формы
                </button-toggler>
                <dropdown-menu ref="collapse2">
<!--                  <dropdown-item router-link :to="tripTypeCompositeCardsLink">Карточки композитов типов рейсов</dropdown-item>-->
                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>
        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <passenger-traffic-fact-trip-by-hours-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :rowArr="rows"
              @onRowSelect="onRowSelect"
              @setLoading="setLoading"
          ></passenger-traffic-fact-trip-by-hours-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>


import PageMixin from "@/pages/share/PageMixin";
import PassengerTrafficFactTripByHoursEditTable
  from "@/components/asmpp/passenger_traffic_fact_trip_by_hours/PassengerTrafficFactTripByHoursEditTable";

export default {
  components: {PassengerTrafficFactTripByHoursEditTable},
  mixins: [PageMixin],

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрутов
    "routes_id",
    // дата начала периода
    "date_index_begin",
    // дата конца периода
    "date_index_end",
    // идентификатор рейса
    "fact_trips_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // маршруты
      routes: [],
      // тип рейса
      tripType: null,
      // список рейсов
      rows: [],

    }
  },

  computed: {

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // условие отключения кнопки - Аналитические формы
    linkDisabled() {
      return !this.selectedRow;
    },

  },
  methods: {

    passengerTrafficFactTripByHoursLink(){

      let routeForm = '';
      this.routes_id.map(rt => {
        routeForm = routeForm + '/' + rt;
      })

      let factTrips = '';
      this.rows.map(row => {
        factTrips = factTrips + '/' + row.fact_trip_id;
      })


      this.$router.push('/asmpp/routes/' + this.date_index_begin + '/' + this.date_index_end +  routeForm  + '/factTripExts')
    },

    setLoading(item) {
      this.isLoading = item;
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {
        this.routes = [];
        for (let i = 0; i < this.routes_id.length; i++) {
          // запрос маршрута
          const route = await this.$store.dispatch('multipass/doFetchRoute', {
            route_id: this.routes_id[i],
          });
          if (!route) return;
          this.routes.push(route);
        }

        let fact_trip_id_list = []
        for(let i = 0; i < this.fact_trips_id.length; i++){
          fact_trip_id_list.push(Number(this.fact_trips_id[i]));
        }

        let rows = await this.$store.dispatch('asmpp/doFetchPassengerTrafficFactTripByHours',{
          fact_trip_ext_id_list: fact_trip_id_list
        })

        if(!rows) {
          return
        } else {
          this.rows = rows
        }

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
