<template>
  <edit-table-panel :columnList="columnList" name="tbl-rmt-trip-types-count" :rowsPerPage="1000"></edit-table-panel>
</template>

<script>

import {getSignTitle} from "@/lib";

export default {
  props: ['isEditMode'],

  computed: {
    columnList() {
      if (this.isEditMode)
        return [
          {
            field: "__checked",
            caption: "Вкл",
            sortType: 'boolean',
            displayType: 'checkbox',
          },
          {
            field: "trip_type_id",
            caption: "Код",
            sortType: 'number',
            isFilterable: true,
            show: false,
          },
          {
            field: "trip_type_sign",
            caption: "Тип рейса",
            sortType: 'sign',
            sorted: 'asc',
            dataAlign: 'left',
            headerAlign: 'left',
            isFilterable: true,
            displayFn(row) {
              return getSignTitle(row.trip_type_sign)
            },
          },
          {
            field: "title",
            caption: "Наименование",
            sortType: 'string',
            isFilterable: true,
            cellStyle: {
              maxWidth: '15rem'
            },
          },
          {
            field: "__tripCount",
            caption: "Кол-во рейсов",
            sortType: "number",
            displayType: 'integer',
            inputStyle: {
              'max-width': '5rem'
            },
            inputAttrs: {
              'required': true,
              'exclude-negative': true,
              'error': 'Введите кол-во'
            }
          },
          {
            field: "weight",
            caption: "Вес",
            sortType: 'number',
          },
          {
            field: "length",
            caption: "Длина",
            sortType: 'number',
            dataAlign: 'right',
            displayFn(row) {
              if (Number.isFinite(row.length)) {
                return Number(row.length / 1000).toFixed(3);
              }
              return ''
            },
          },
        ]
      else
        return [
          {
            field: "__checked",
            caption: "Вкл",
            sortType: 'boolean',
            displayType: 'html',
            displayFn(row) {
              if (row.__checked) {
                return '<i class="fas fa-check">'
              }
              return ''
            }
          },
          {
            field: "trip_type_id",
            caption: "Код",
            sortType: 'number',
            isFilterable: true,
            show: false,
          },
          {
            field: "trip_type_sign",
            caption: "Тип рейса",
            sortType: 'sign',
            sorted: 'asc',
            dataAlign: 'left',
            headerAlign: 'left',
            isFilterable: true,
            displayFn(row) {
              return getSignTitle(row.trip_type_sign)
            },
          },
          {
            field: "title",
            caption: "Наименование",
            sortType: 'string',
            isFilterable: true,
            cellStyle: {
              maxWidth: '15rem'
            },
          },
          {
            field: "__tripCount",
            caption: "Кол-во рейсов",
            sortType: "number",
            headerAlign: 'center',
            dataAlign: 'center',
          },
          {
            field: "weight",
            caption: "Вес",
            sortType: 'number',
          },
          {
            field: "length",
            caption: "Длина",
            sortType: 'number',
            dataAlign: 'right',
            displayFn(row) {
              if (Number.isFinite(row.length)) {
                return Number(row.length / 1000).toFixed(3);
              }
              return ''
            },
          },
        ]
      }
  },
};
</script>
