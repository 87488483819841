import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПНЫХ ДЛЯ УПРАВЛЕНИЯ ТРАНСПОРТНЫХ СРЕДСТВ


export default {
    state() {
        return {
            // список объектов
            manageVehicles: [],
            // время обновления кэша new Date().getTime()
            manageVehiclesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            manageVehiclesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getManageVehicles(state) {
            return state.manageVehicles;
        },

    },
    mutations: {
        // установить новый список объектов
        setManageVehicles(state, payload) {
            state.manageVehicles = payload;
            state.manageVehiclesCache = new Date().getTime();
        },
    },
    actions: {
        // запрос списка объектов
        doFetchManageVehicles: (context, payload) => {
            return DoGet(
                '/trn/getManageVehicles/' + payload.date_index,
                'trn/setManageVehicles',
                [],
                'Запрос списка доступных для управления транспортных средств',
                false,
                payload && payload.force,
                context.state.manageVehiclesCache,
                context.state.manageVehiclesCacheTimeout
            )
        },

        // очищает кэш
        doClearManageVehicles: (context, payload) => {
            return DoClearCache(
                'trn/setManageVehicles',
                [],
                payload && payload.force,
                context.state.manageVehiclesCache,
                context.state.manageVehiclesCacheTimeout
            )
        },
    }
}