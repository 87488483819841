import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ГРУПП ОТЧЕТОВ

export default {
    state() {
        return {
            // список объектов
            reportGroups: [],
            // время обновления кэша new Date().getTime()
            reportGroupsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            reportGroupsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getReportGroups(state) {
            return state.reportGroups
        }
    },
    mutations: {
        // установить новый список объектов
        setReportGroups(state, payload) {
            state.reportGroups = payload;
            state.reportGroupsCache = new Date().getTime();
        }
    },
    actions: {
        // запрос списка объектов
        doFetchReportGroups: (context, payload) => {
            return DoGet(
                '/system/reportGroups',
                'system/setReportGroups',
                [],
                'Запрос групп отчетов',
                false,
                payload && payload.force,
                context.state.reportGroupsCache,
                context.state.reportGroupsCacheTimeout
            )
        },

        // очищает кэш
        doClearReportGroups: (context, payload) => {
            return DoClearCache(
                'system/setReportGroups',
                [],
                payload && payload.force,
                context.state.reportGroupsCache,
                context.state.reportGroupsCacheTimeout
            )
        },
    }
}