<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Объект административно-территориального деления</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>

            <div class="row">

              <div class="form-group col">
                <label for="areaClassifierId">Код:</label>
                <input-string
                    class="form-control"
                    id="areaClassifierId"
                    v-model="areaClassifierId"
                    readonly
                />
              </div>
              <div class="form-group col">
                <label for="isDisabled">Отключено:</label>
                <input-switch
                    id="isDisabled"
                    v-model="isDisabled"
                    :readonly="op === 'show'"
                >Отключено
                </input-switch>
              </div>

            </div>

            <div class="form-group">
              <label for="longName">Полное наименование:</label>
              <req-field/>
              <input-string
                  class="form-control"
                  id="longName"
                  v-model="longName"
                  ref="longName"
                  :readonly="op === 'show'"
                  required
                  error="Введите полное наименование"
              />
            </div>
            <div class="form-group">
              <label for="shortName">Краткое наименование:</label>
              <req-field/>
              <input-string
                  class="form-control"
                  id="shortName"
                  v-model="shortName"
                  ref="shortName"
                  :readonly="op === 'show'"
                  required
                  error="Введите краткое наименование"
              />
            </div>
            <div class="form-group">
              <label for="parentAreaClassifierId">Код родительского объекта:</label>
              <input-string
                  class="form-control"
                  id="parentAreaClassifierId"
                  v-model="parentAreaClassifierId"
                  readonly
              />
            </div>
            <div class="form-group w-50">
              <label for="level">Уровень вложенности:</label>
              <input-integer
                  class="form-control"
                  id="level"
                  v-model="level"
                  readonly
              />
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputString from "@/components/ui/custom/InputString";
import InputInteger from "@/components/ui/custom/InputInteger";

export default {
  components: {InputInteger, InputString},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      areaClassifierId: this.item.area_classifier_id,
      shortName: this.item.short_name,
      longName: this.item.long_name,
      parentAreaClassifierId: this.item.parent_area_classifier_id,
      level: this.item.level,
      isDisabled: this.item.is_disabled,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          area_classifier_id: this.areaClassifierId,
          short_name: this.shortName,
          long_name: this.longName,
          parent_area_classifier_id: this.parentAreaClassifierId,
          level: this.level,
          is_disabled: this.isDisabled
        })
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>