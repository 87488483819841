<template>
    <button class="navbar-toggler" type="button" @click="execTargetMethod('toggle')">
        <span class="navbar-toggler-icon"></span>
    </button>   
</template>

<script>
import TargetMixin from "@/components/ui/bootstrap/mixins/TargetMixin";

export default {
  mixins: [TargetMixin],
}
</script>
