<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-kvr-master-orders"></edit-table-panel>
</template>

<script>

import {kvrSortFn, quickSort, secondsToDate} from "@/lib";

export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "route_num",
          caption: "№ маршрута",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "route_title",
          caption: "Маршрут",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "transport_type_title",
          caption: "Вид транспорта",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "schedule_variant_id",
          caption: "Вариант расписания",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "schedule_type_title",
          caption: "Тип расписания",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "schedule_date_begin",
          caption: "Дата начала",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "schedule_date_end",
          caption: "Дата окончания",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "season_title",
          caption: "Сезон",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "route_variant_id",
          caption: "Вариант маршрута",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "route_reg_num",
          caption: "Рег.№ марш.",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "route_id",
          caption: "Код марш.",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
       let rows = this.$store.getters["kvr/getMasterOrders"].map(masterOrder => {
        masterOrder.__id = masterOrder.contract_id;
        // задаем формат даты
        masterOrder.__date = secondsToDate(masterOrder.date_index * 24 * 60 * 60);
        // сужаем строки
        masterOrder.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px'
        };
        return masterOrder;
      });

      // сортируем массив
      quickSort(rows, (leftItem, rightItem) => {
        return 1 * kvrSortFn(leftItem, rightItem);
      });

      return rows;
    },
  }
};
</script>
