import ScheduleGraphSetTableRmtGenStore from "@/store/rmtgen/ScheduleGraphSetTableRmtGenStore";
import GraphsRmtGenStore from "@/store/rmtgen/GraphsRmtGenStore";
import HoursRmtGenStore from "@/store/rmtgen/HoursRmtGenStore";
import TsCountRmtGenStore from "@/store/rmtgen/TsCountRmtGenStore";
import LineScheduleGraphsRmtGenStore from "@/store/rmtgen/LineScheduleGraphsRmtGenStore";

// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ RMTGEN

export default {
    namespaced: true,
    modules: {
        ScheduleGraphSetTableRmtGenStore,
        GraphsRmtGenStore,
        HoursRmtGenStore,
        TsCountRmtGenStore,
        LineScheduleGraphsRmtGenStore,
    }
}