<template>
  <div class="accordion">
    <slot></slot>
  </div>
</template>

<script>
import ChildContentMixin from "@/components/ui/bootstrap/mixins/ChildContentMixin";

export default {
  mixins: [ChildContentMixin]
}
</script>
