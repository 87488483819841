<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header class="page-header">
        <span><router-link to="/rmt">Расписания</router-link></span>
        <span> / </span>
        <span><router-link to="/rmt/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to=routeVariantsLink>Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleVariantsLink>Варианты расписаний {{ routeVariantPageHeader }}</router-link></span>
        <span> / </span>
        <span>Нормы на интервалы движения {{ scheduleVariantPageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">

          <crud-form
              :buttonsExist="panelButtonsExist"
              :buttonsEnabled="panelButtonsEnabled"
              :filterExists="false"
              @onEdit="onTableEdit"
              @onCancel="onTableCancel"
              @onSave="onTableSave"
              @onRefresh="onTableRefresh"
          >

            <template #leftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse1" class="btn dropdown-toggle btn-primary">Команда</button-toggler>
                <dropdown-menu ref="collapse1">
                  <dropdown-item @click="onClearIntNormInt">Очистить нормы</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item @click="onUpdateIntNormInt">Обновить нормы</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>
            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary">Связь</button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="intNormPeriodsLink">Периоды суток</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>
        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <norms-edit-table
              :rowList="normRows"
              :columnList="normColumns"
          ></norms-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Подтверждение удаления для трассы -->
  <confirm-message-box
      v-if="isClearConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmNormCommand('clr')"
  >Вы уверены, что хотите очистить нормы для варианта расписания с кодом {{ schedule_variant_id }} ?
  </confirm-message-box>

  <!-- Подтверждение удаления для трассы -->
  <confirm-message-box
      v-if="isUpdateConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmNormCommand('upd')"
  >Вы уверены, что хотите обновить нормы для варианта расписания с кодом {{ schedule_variant_id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

  <!--    Окно с ошибкой   -->
  <error-message-box
      v-if="errorCode > 0"
      @onClose="$router.go(-1)"
  >

    <template v-if="errorCode === 1" #leftCustomButton>
      <button class="btn btn-success" @click="$router.push(intNormPeriodsLink)">К периодам суток...</button>
    </template>
    <template v-else-if="errorCode === 2 || errorCode === 3" #leftCustomButton>
      <button class="btn btn-success" @click="$router.push(tripTypesLink)">К типам рейсов...</button>
    </template>
    <template v-else-if="errorCode === 4" #leftCustomButton>
      <button class="btn btn-danger" @click="onConfirmNormCommand('clr')">Очистить нормы...</button>
      <button class="btn btn-danger" @click="onConfirmNormCommand('upd')">Обновить нормы...</button>
    </template>

    {{ errorMessage }}
  </error-message-box>

</template>

<script>

import NormsEditTable from "@/components/rmt/time_norm_times/NormsEditTable";
import {getSignTitle, getTimeFormat} from "@/lib";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    NormsEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
    // идентификатор варианта расписания
    "schedule_variant_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // вариант расписания
      scheduleVariant: {},
      // периоды
      intPeriods: [],
      // типы рейсов
      tripTypes: [],
      // нормы
      intNormInts: [],
      // строки для отображения в таблице
      normRows: [],
      // режим редактирования правой таблицы
      isEditMode: false,
      // код проверки
      errorCode: 0,
      // отображать спиннер
      isLoading: false,
      // активный элемент левой таблицы
      selectedRow: null,
    }
  },

  computed: {

    // включенные кнопки для правой таблицы
    panelButtonsExist() {
      return {
        edit: !this.isEditMode,
        save: this.isEditMode,
        cancel: this.isEditMode,
        refresh: true,
      }
    },

    // активные кнопки для правой таблицы
    panelButtonsEnabled() {
      return {
        edit: !this.isEditMode,
        save: this.isEditMode,
        cancel: this.isEditMode,
        refresh: true,
      }
    },

    // ссылка для перехода к вариантам маршрутов
    routeVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants`
    },

    // ссылка для перехода к вариантам расписаний
    scheduleVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`;
    },

    // ссылка для перехода к типам рейсов
    tripTypesLink() {
      return `/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes`;
    },

    // ссылка для перехода к нормы времени на движение
    intNormPeriodsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/intNormPeriods`
    },

    // условие отображения подтверждения очистка норм по варианту расписания
    isClearConfirmVisible() {
      return this.op === 'clr'
    },

    // условие отображения подтверждения обновления норм по варианту расписания
    isUpdateConfirmVisible() {
      return this.op === 'upd'
    },

    // текст ошибки
    errorMessage() {
      if (this.errorCode === 1)
        return 'Периоды суток отсутствуют.';
      else if (this.errorCode === 2)
        return 'Типы рейсов отсутствуют.';
      else if (this.errorCode === 3)
        return 'Трасса одного из типов рейса отсутствует.';
      else if (this.errorCode === 4)
        return 'Текущая таблица норм не соответствует действительности.';
      else
        return '';
    },

    // колонки для правой таблицы
    normColumns() {

      const columns = this.intPeriods.map((period, index) => {
        return {
          field: '__int_' + index,
          caption: getTimeFormat(period.time_begin) + " - " + getTimeFormat(period.time_end),
          isSortable: false,
          displayType: 'minute',
          dataAlign: 'center',
          headerAlign: 'center',
          inputStyle: {
            maxWidth: '5rem'
          },
          footerAlign: 'center',
          footerCellStyle: {
            color: 'white',
            backgroundColor: '#343a40',
            fontWeight: 'bold',
          }
        }
      });

      columns.unshift(
          {
            field: "trip_type_id",
            caption: "Код",
            sortType: 'number',
            isSortable: false,
            isFilterable: false
          },
          {
            field: "title",
            caption: "Наименование",
            sortType: 'string',
            isSortable: false,
            isFilterable: false
          },
          {
            field: "trip_type_sign",
            caption: "Тип рейса",
            sortType: 'sign',
            dataAlign: 'left',
            headerAlign: 'left',
            isFilterable: false,
            isSortable: true,
            sorted: 'asc',
            displayFn(row) {
              return getSignTitle(row.trip_type_sign)
            },
          },
      )

      return columns;
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del';
    },

    // условие отображения подтверждения обновление
    isUpdConfirmVisible() {
      return this.op === 'upd';
    },
  },

  methods: {

    // вызывается, когда нажата кнопка Обновить
    onTableRefresh() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // перезапрашиваются данные
      this.refreshData();
    },

    // вызывается, когда таблица переходит в режим реактирования
    onTableEdit() {
      this.isEditMode = true;
    },

    // вызывается при сохранении изменений норм
    onTableSave() {
      // выходим из режима редактирования
      this.isEditMode = false;
      // сохраняем данные на сервер
      this.saveIntNormInts();
    },

    // вызывается при отмене внесенных изменений
    onTableCancel() {
      // выходим из режима редактирования
      this.isEditMode = false;
      // возвращаем строки в исходное состояние
      this.updateNormRows();
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace('/rmt/routes/' + this.route_id + '/routeVariants/' + this.route_variant_id + '/scheduleVariants/' + this.schedule_variant_id + '/intNormInts',);
    },

    // вызывается при обновлении норм по варианту расписания
    onUpdateIntNormInt() {
      this.$router.replace('/rmt/routes/' + this.route_id + '/routeVariants/' + this.route_variant_id + '/scheduleVariants/' + this.schedule_variant_id + '/intNormInts/upd');
    },

    // вызывается при очистки норм по варианту расписания
    onClearIntNormInt() {
      this.$router.replace('/rmt/routes/' + this.route_id + '/routeVariants/' + this.route_variant_id + '/scheduleVariants/' + this.schedule_variant_id + '/intNormInts/clr');
    },

    // подтверждение команды очистки или обновления норм
    async onConfirmNormCommand(op) {
      // возвращаемся на страницу
      this.$router.replace('/rmt/routes/' + this.route_id + '/routeVariants/' + this.route_variant_id + '/scheduleVariants/' + this.schedule_variant_id + '/intNormInts');

      // очистка норм по варианту расписания
      if (op === 'clr') {
        // выходим из режима редактирования
        this.isEditMode = false;
        // отправляем команду на серверу
        await this.clearIntNormInt();
        // обновляем данные
        await this.refreshData();
      }
      // обновление норм по варианту расписания
      else {
        // выходим из режима редактирования
        this.isEditMode = false;
        // отправляем команду на серверу
        await this.updateIntNormInt();
        // обновляем данные
        await this.refreshData();
      }
    },

    // формирует и возвращает результирующую таблицу для сохранения
    createResultNormTable() {
      const rows = [];
      this.tripTypes.forEach((item, itemIndex) => {
        this.intPeriods.forEach((period, periodIndex) => {
          rows.push({
            int_norm_period_id: period.int_norm_period_id,
            trip_type_id: item.trip_type_id,
            int_norm: this.normRows[itemIndex]['__int_' + periodIndex],
          });
        })
      })
      return rows;
    },

    // возвращает норматив для указанного периуда суток и типа рейса
    getIntNorm(period, tripType) {
      return this.intNormInts.find(norm => {
        return norm.int_norm_period_id === period.int_norm_period_id &&
            norm.trip_type_id === tripType.trip_type_id;
      })
    },

    // обновляет список строк таблицы
    updateNormRows() {
      const rows = [];
      this.tripTypes.forEach((item) => {

        const row = {
          trip_type_id: item.trip_type_id,
          title: item.title,
          trip_type_sign: item.trip_type_sign,
        }

        // заполняем времена нормативов
        this.intPeriods.forEach((period, periodIndex) => {
          const norm = this.getIntNorm(period, item);
          row['__int_' + periodIndex] = norm ? norm.int_norm : null;
        });

        // режим редактирования выключен
        if (!this.isEditMode) {
          // только для чтения
          row["__inputAttrs"] = {
            readonly: true,
          };
          // серый цвет фона
          row["__inputStyle"] = {
            backgroundColor: '#efefef'
          };
        }

        rows.push(row);
      })

      // формируем строки таблицы
      this.normRows = rows;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // запрос маршрута
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        }); if (!route) return;
        this.route = route;

        // запрос варианта маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id,
        }); if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // запрос варианта расписания
        const scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
          schedule_variant_id: this.schedule_variant_id,
        }); if (!scheduleVariant) return;
        this.scheduleVariant = scheduleVariant;

        // запрос типы рейса
        let tripTypes = await this.$store.dispatch('multipass/doFetchTripTypes', {
          route_variant_id: this.route_variant_id,
          force: true,
        }); if (!tripTypes) return [];
        this.tripTypes = tripTypes;

        // запрос периодов суток
        const intPeriods = await this.$store.dispatch('rmt/doFetchIntNormPeriods', {
          schedule_variant_id: this.schedule_variant_id,
        }); if (!intPeriods) return;
        this.intPeriods = intPeriods;

        // загружаем нормы
        const intNormInts = await this.$store.dispatch('rmt/doFetchIntNormInts', {
          schedule_variant_id: this.schedule_variant_id,
        }); if (!intNormInts) return;
        this.intNormInts = intNormInts;

        // запускаем обновление таблицы
        await this.updateNormRows();

        // запускаем проверку нормативов
        const checkResult = await this.checkNorms();
        // если ошибка выходим
        if (!checkResult) return;
        // формируем код ошибки
        this.errorCode = checkResult.check_code;

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // сохраняем новые нормативы на сервере
    async saveIntNormInts() {
      this.isLoading = true;
      try {

        // сохраняем данные на сервере
        const intNormInts = await this.$store.dispatch('rmt/doEditIntNormInts', {
          schedule_variant_id: this.schedule_variant_id,
          norms: this.createResultNormTable(),
        }); if (!intNormInts) return;
        this.intNormInts = intNormInts;

        // обновляем список строк
        await this.updateNormRows();

      } finally {
        this.isLoading = false;
      }
    },

    // запускаем очистку норм по варианту расписания
    async clearIntNormInt() {
      this.isLoading = true;
      try {

        // вызываем команду на сервере
        await this.$store.dispatch('rmt/doClearIntNormInt', {
          schedule_variant_id: this.schedule_variant_id,
        });

      } finally {
        this.isLoading = false;
      }
    },

    // запускаем обновление норм по варианту расписания
    async updateIntNormInt() {
      this.isLoading = true;
      try {

        // вызываем команду на сервере
        await this.$store.dispatch('rmt/doUpdateIntNormInt', {
          schedule_variant_id: this.schedule_variant_id,
        });

      } finally {
        this.isLoading = false;
      }
    },

    // запрашиваем проверку нормативов
    async checkNorms() {
      return await this.$store.dispatch('rmt/doCheckIntNormInt', {
        schedule_variant_id: this.schedule_variant_id,
      });
    },
  },

  watch: {
    // обновляем строки в случае изменения режима редактирования
    isEditMode() {
      if (!this.isLoading)
        this.updateNormRows();
    }
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
