<template>
  <div class="container position-relative h-100">
    <h2 class="text-center">О программе Навитранс.Клиент</h2>
    <p class="text-center">Название системы - {{ systemСaption }}</p>
    <p class="text-center">Версия клиента - {{ clientVersion }}</p>
    <p class="text-center">Версия сервера - {{ serverVersion }}</p>
    <p class="text-center">Время сервера - {{ serverTime }}</p>
  </div>
</template>

<script>
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixin: [PageMixin],

  computed: {
    // версия сервера
    serverVersion() {
      return this.$store.getters["getServerVersion"];
    },

    // версия клиента
    clientVersion() {
      return this.$store.getters["getClientVersion"];
    },

    // время сервера
    serverTime() {
      return this.$store.getters["getServerTime"];
    },

    // Название системы
    systemСaption() {
      return this.$store.getters["getSystemСaption"];
    },

  },
  methods: {
    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch("doFetchServerStatus", force);

      } finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
}
</script>