<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">

      <page-header>
        <span><router-link to="/rmt">Расписания</router-link></span>
        <span> / </span>
        <span><router-link to="/rmt/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to=routeVariantsLink>Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span>Варианты расписаний {{ routeVariantPageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">

          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка Команда-->
            <template #leftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                :disabled="commandDisabled">
                  Команда
                </button-toggler>
                <dropdown-menu ref="collapse">
                  <dropdown-item @click="onTableChange" v-if="selectedRow">Изменить окончание</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item @click="onSetStatus" v-if="setStatusExists">Установить статус</dropdown-item>
                  <dropdown-divider v-if="activateExists || deactivateExists" ></dropdown-divider>
                  <dropdown-item @click="onActivateScheduleVariant" v-if="activateExists">Ввести в действие
                  </dropdown-item>
                  <dropdown-item @click="onDeactivateScheduleVariant" v-if="deactivateExists">Вывести из действия</dropdown-item>
                  <dropdown-divider v-if="activateExists || deactivateExists"></dropdown-divider>
                  <dropdown-item @click="onCopy" v-if="selectedRow">Копировать</dropdown-item>
                  <dropdown-item @click="onPaste" v-if="deactivatePaste">Вставить</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Связь
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="scheduleGraphSetsLink">Наборы графиков</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item router-link :to="timeNormTimes">Нормы времени на движение</dropdown-item>
                  <dropdown-item router-link :to="intNormInts">Нормы на интервалы движения</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item router-link :to="scheduleVariantDocsLink">Документы</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>

        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <schedule-variants-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :scheduleVariants="scheduleVariants"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></schedule-variants-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <schedule-variant-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </schedule-variant-dialog>

  <!-- Установка статуса -->
  <schedule-variant-status-dialog
      v-if="isStatusDialogVisible"
      :item="selectedRow"
      @onCancel="onRejectOperation"
      @onOK="onStatusDialog"
  >
  </schedule-variant-status-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить вариант расписания с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Подтверждение ввода в действие -->
  <confirm-message-box
      v-if="isActivateConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onActivateDialog(true)"
  >Вы уверены, что хотите ввести в действие вариант расписания с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Подтверждение вывода из действия -->
  <confirm-message-box
      v-if="isDeactivateConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onActivateDialog(false)"
  >Вы уверены, что хотите вывести из действия вариант расписания с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Диалог со списком ошибок -->
  <errors-with-status-dialog
      v-if="isErrorsDialogVisible"
      :rowList="errors"
      @onClose="errors=[]"
      @onContinue="onContinue"
      caption="Ошибки варианта расписания"
  >
  </errors-with-status-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import ScheduleVariantsEditTable from "@/components/rmt/schedule_variants/ScheduleVariantsEditTable";
import ScheduleVariantDialog from "@/components/rmt/schedule_variants/ScheduleVariantDialog";
import ScheduleVariantStatusDialog from "@/components/rmt/schedule_variants/ScheduleVariantStatusDialog";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";
import store from "@/store/Store";
import ErrorsWithStatusDialog from "@/components/ui/custom/ErrorsWithStatusDialog";

export default {
  mixins: [PageMixin],

  components: {
    ErrorsWithStatusDialog,
    ScheduleVariantsEditTable,
    ScheduleVariantDialog,
    ScheduleVariantStatusDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // варианты расписаний
      scheduleVariants: [],
      // список ошибок графика
      errors: [],
      // тип объекта для определения активности кнопки вставить
      objType: 'ScheduleVariant',
      // значение статуса (заполняется, для повторного вызова команды)
      schedule_variant_status_id: null
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add' || this.op === 'paste' || this.op === 'change') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения диалогового окна установки статуса
    isStatusDialogVisible() {
      return (this.op === 'status' && !!this.selectedRow)
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // условие отображения подтверждения ввода в действие
    isActivateConfirmVisible() {
      return this.op === 'activate' && !!this.selectedRow;
    },

    // условие отображения подтверждения вывода из действия
    isDeactivateConfirmVisible() {
      return this.op === 'deactivate' && !!this.selectedRow;
    },

    // условие отключения кнопки - Команды
    commandDisabled() {
      return (!this.setStatusExists && !this.deactivatePaste);
    },

    // условие отображения команды Установить статус
    setStatusExists() {
      return this.selectedRow;
    },

    // условие отображения меню Ввести в действие
    activateExists() {
      return this.selectedRow && !this.selectedRow.is_working
    },

    // условие отображения меню Вывести из действия
    deactivateExists() {
      return this.selectedRow && !!this.selectedRow.is_working
    },

    // условие отображения кнопки "вставить"
    // если тип объекта в буфере обмена совпадает с типом объекта на текущей странице
    deactivatePaste() {
      return (store.getters.getType == this.objType);
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {}
      } else return this.selectedRow;
    },

    // ссылка для перехода к вариантам маршрута
    routeVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants`
    },

    // ссылка для перехода к наборам графиков
    scheduleGraphSetsLink() {
      if (this.selectedRow) return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.selectedRow.schedule_variant_id}/scheduleGraphSets`
      else return '';
    },

    // ссылка для перехода к нормы времени на движение
    timeNormTimes() {
      if (this.selectedRow) return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.selectedRow.schedule_variant_id}/timeNormTimes`
      else return '';
    },

    // ссылка для перехода к нормы времени на движение
    intNormInts() {
      if (this.selectedRow) return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.selectedRow.schedule_variant_id}/intNormInts`
      else return '';
    },

    // ссылка для перехода к документам
    scheduleVariantDocsLink() {
      if (this.selectedRow) return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.selectedRow.schedule_variant_id}/scheduleVariantDocs`
      else return '';
    },

    // условие отображения диалога со списком ошибок
    isErrorsDialogVisible() {
      if (this.errors.length > 0) {
        for(let i = 0; i < this.errors.length; i++) {
          if (this.errors[i].error_status == 'ALARM' || this.errors[i].error_status == 'WARN') {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },

  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/add`);
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/edit/${this.selectedRow.schedule_variant_id}`);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/del/${this.selectedRow.schedule_variant_id}`);
    },

    // выполнено двойное нажатие строки в таблице (открываем просмотр)
    onTableShow() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/show/${this.selectedRow.schedule_variant_id}`);
    },

    // нажата кнопка Изменить
    onTableChange() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/change/${this.selectedRow.schedule_variant_id}`);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      } else if (this.op === 'paste') {
        this.pasteItem(item)
      } else if (this.op === 'change') {
        this.changeItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`);
    },

    // подтверждено изменения в диалоге
    onConfirmDelete(item) {
      // удаляем вариант расписания
      this.delItem(item)
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`);
    },

    // подтверждено ввод в действие или вывод
    onActivateDialog(item) {
      if (item === true) {
        // вводим в действие вариант расписания
        this.activateScheduleVariant()
      } else if (item === false) {
        // выводим из действия вариант расписания
        this.deactivateScheduleVariant()
      }
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // команда установить статус
    onSetStatus() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/status/${this.selectedRow.schedule_variant_id}`);
    },

    // команда ввести в действие вариант расписания
    onActivateScheduleVariant() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/activate/${this.selectedRow.schedule_variant_id}`);
    },

    // команда вывести из действия вариант расписания
    onDeactivateScheduleVariant() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/deactivate/${this.selectedRow.schedule_variant_id}`);
    },

    // нажата кнопка "копировать"
    onCopy(){
      // сохраняем type и выбранную строку в store
      store.dispatch('doSetClipboard', {
        type: 'ScheduleVariant',
        object: this.selectedRow
      });

      // выводим сообщение о том, что данные сохранены в буфер обмена
      store.dispatch('doAddMessage', {
        caption: 'Вариант расписания',
        message: 'Данные сохранены в буфер обмена',
        type: 'ok'
      }, {root: true});
    },

    // нажата кнопка "вставить"
    onPaste(){
      // получаем из стора сохраненный объект
      this.selectedRow = store.getters.getObject;
      // открываем диалог копирования (редактирования)
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/paste`);
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`);
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('rmt/doFetchMovementKinds')
        await this.$store.dispatch('rmt/doFetchSeasons')
        await this.$store.dispatch('rmt/doFetchScheduleTypes')
        await this.$store.dispatch('rmt/doFetchScheduleVariantStatuses')

        // запрос маршрута
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        }); if (!route) return;
        this.route = route;

        // запрос варианта маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id,
        }); if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // запрос вариантов расписания
        const scheduleVariants = await this.$store.dispatch('rmt/doFetchScheduleVariants', {
          route_variant_id: this.route_variant_id,
        }); if (!scheduleVariants) return;
        this.scheduleVariants = scheduleVariants;

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        item['route_variant_id'] = Number(this.route_variant_id);
        // запрос на сервер
        const schVar = await this.$store.dispatch('rmt/doAddScheduleVariant', item);
        // мутируем массив
        this.selectedRow = addMutation(this.scheduleVariants, 'schedule_variant_id', schVar);

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {
        // запрос на сервер
        item['route_variant_id'] = Number(this.route_variant_id);

        const schVar = await this.$store.dispatch('rmt/doEditScheduleVariant', item);
        // мутируем массив
        this.selectedRow = editMutation(this.scheduleVariants, 'schedule_variant_id', schVar);

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async changeItem(item) {
      this.isLoading = true;
      try {
        // запрос на сервер
        item['route_variant_id'] = Number(this.route_variant_id);
        const schVar = await this.$store.dispatch('rmt/doChangeScheduleVariant', item);
        // мутируем массив
        this.selectedRow = editMutation(this.scheduleVariants, 'schedule_variant_id', schVar);

      } finally {
        this.isLoading = false;
      }
    },

    // добавляем объект при вставке
    async pasteItem(item) {
      this.isLoading = true;
      try {
        // сетаем текущий route_variant_id
        item.route_variant_id = Number(this.route_variant_id);
        // запрашиваем сервер
        const copiedItem = await this.$store.dispatch('rmt/doCopyScheduleVariant', item);
        // мутируем массив
        this.selectedRow = addMutation(this.scheduleVariants, 'schedule_variant_id', copiedItem);
      }
      finally {
        this.isLoading = false;
      }
    },

    // подтверждена смена варианта
    onStatusDialog(item) {
      this.setStatus(item);
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`);
    },

    // подтверждена смена варианта
    async setStatus(item) {
      this.isLoading = true;
      try {
        // запрашиваем сервер
        // после установки статуса получаем ошибки
        let errors = await this.$store.dispatch('rmt/doSetScheduleVariantStatus', item);

        // если массив ошибок не пуст
        // отображаем их в соответствующем диалоге
        if (errors.length > 0) {
          this.errors = errors;
          this.schedule_variant_status_id = item.schedule_variant_status_id;
        } else {
          // в случае, если ошибок нет, делаем запрос на сервер для получения обновленного значения статуса
          // получаем "обновленный" вариант расписания
          const scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
            schedule_variant_id: this.selectedRow.schedule_variant_id
          });
          // мутируем массив
          this.selectedRow = editMutation(this.scheduleVariants, 'schedule_variant_id', scheduleVariant);
          // отображаем сообщение об успешном выполнении операции
          await store.dispatch('doAddMessage', {
            caption: 'Установка статуса варианта расписания',
            message: 'Команда успешно выполнена',
            type: 'ok'
          }, {root: true})
        }
      } finally {
        this.isLoading = false;
      }
    },

    // если не смотря на предупреждения пользователь нажал "продолжить"
    // отправляем повторный запрос с force == true
    async onContinue() {
      let scheduleVariant = null;
      // список ошибокк
      let errors = [];
      if (this.schedule_variant_status_id == 'activate') {
        // активируем повторно
        let item = {
          schedule_variant_id: this.selectedRow.schedule_variant_id,
          force: true,
        }
        errors = await this.$store.dispatch('rmt/doActivateScheduleVariant', item)

      } else if (this.schedule_variant_status_id == 'deactivate') {
        // деактивируем повторно
        let item = {
          schedule_variant_id: this.selectedRow.schedule_variant_id,
          force: true,
        }
        errors = await this.$store.dispatch('rmt/doDeactivateScheduleVariant', item)
      } else {
        let item = {
          schedule_variant_id: this.selectedRow.schedule_variant_id,
          schedule_variant_status_id: this.schedule_variant_status_id,
          force: true,
        }
        // запрос на сервер
        errors = await this.$store.dispatch('rmt/doSetScheduleVariantStatus', item);
      }

      // параметр, который задает отображать ли окно с ошибками повторно
      let isErrorsDialogShowing = 0;
      // проверяем есть в вернувшемся массиве ошибки, если да, выводим сообщение повторно
      for (let i = 0; i < errors.length; i++) {
        if (errors[i].error_status == 'ALARM') {
          isErrorsDialogShowing = 1;
          break;
        }
      }
      if (isErrorsDialogShowing == 1) {
        this.errors = errors;
      } else {
        //делаем запрос на сервер для получения обновленного значения статуса
        // получаем "обновленный вариант маршрута"
        scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
          schedule_variant_id: this.selectedRow.schedule_variant_id
        });
        // мутируем массив
        this.selectedRow = editMutation(this.scheduleVariants, 'schedule_variant_id', scheduleVariant);
        // если ошибок нет, следовательно можно очистить массив сохраненных ошибок
        // и сохраненный статус
        this.errors = [];
        this.route_status_id = null;
        // отображаем сообщение об успешном выполнении операции
        await store.dispatch('doAddMessage', {
          caption: 'Установка статуса варианта расписания',
          message: 'Команда успешно выполнена',
          type: 'ok'
        }, {root: true})
      }
    },

    // вводим в действие вариант расписания
    async activateScheduleVariant() {
      this.isLoading = true;
      try {
        // запрашиваем сервер
        // после установки статуса получаем ошибки
        let errors = await this.$store.dispatch('rmt/doActivateScheduleVariant', {
          schedule_variant_id: this.selectedRow.schedule_variant_id
        });

        // если массив ошибок не пуст
        // отображаем их в соответствующем диалоге
        if (errors.length > 0) {
          this.errors = errors;
          this.schedule_variant_status_id = 'activate';
        } else {
          // в случае, если ошибок нет, делаем запрос на сервер для получения обновленного значения статуса
          // получаем "обновленный" вариант расписания
          const scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
            schedule_variant_id: this.selectedRow.schedule_variant_id
          });
          // мутируем массив
          this.selectedRow = editMutation(this.scheduleVariants, 'schedule_variant_id', scheduleVariant);
          // отображаем сообщение об успешном выполнении операции
          await store.dispatch('doAddMessage', {
            caption: 'Установка статуса варианта расписания',
            message: 'Команда успешно выполнена',
            type: 'ok'
          }, {root: true})
        }
      } finally {
        this.isLoading = false;
      }
    },

    // выводим из действия вариант расписания
    async deactivateScheduleVariant() {
      this.isLoading = true;
      try {
        // запрашиваем сервер
        // после установки статуса получаем ошибки
        let errors = await this.$store.dispatch('rmt/doDeactivateScheduleVariant', {
          schedule_variant_id: this.selectedRow.schedule_variant_id
        });

        // если массив ошибок не пуст
        // отображаем их в соответствующем диалоге
        if (errors.length > 0) {
          this.errors = errors;
          this.schedule_variant_status_id = 'deactivate';
        } else {
          // в случае, если ошибок нет, делаем запрос на сервер для получения обновленного значения статуса
          // получаем "обновленный" вариант расписания
          const scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
            schedule_variant_id: this.selectedRow.schedule_variant_id
          });
          // мутируем массив
          this.selectedRow = editMutation(this.scheduleVariants, 'schedule_variant_id', scheduleVariant);
          // отображаем сообщение об успешном выполнении операции
          await store.dispatch('doAddMessage', {
            caption: 'Установка статуса варианта расписания',
            message: 'Команда успешно выполнена',
            type: 'ok'
          }, {root: true})
        }
      } finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрос на сервер
        const schVar = await this.$store.dispatch('rmt/doDelScheduleVariant', this.selectedRow);
        // мутируем массив
        this.selectedRow = delMutation(this.scheduleVariants, 'schedule_variant_id', schVar);

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  }
}
</script>

