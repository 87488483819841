import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ПРОФЕССИЙ РАБОТНИКОВ

export default {
    state() {
        return {
            // список объектов
            personnelJobs: [],
            // время обновления кэша new Date().getTime()
            personnelJobsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            personnelJobsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getPersonnelJobs(state) {
            return state.personnelJobs;
        },
    },
    mutations: {
        // установить новый список объектов
        setPersonnelJobs(state, payload) {
            state.personnelJobs = payload;
            state.personnelJobsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списока объектов
        doFetchPersonnelJobs: (context, payload) => {
            return DoGet(
                '/ref/personnelJobs',
                'ref/setPersonnelJobs',
                [],
                'Запрос профессий работников',
                false,
                payload && payload.force,
                context.state.personnelJobsCache,
                context.state.personnelJobsCacheTimeout
            )
        },

        // очищает кэш
        doClearPersonnelJobs: (context, payload) => {

            return DoClearCache(
                'ref/setPersonnelJobs',
                [],
                payload && payload.force,
                context.state.personnelJobsCache,
                context.state.personnelJobsCacheTimeout
            )
        },
    }
}