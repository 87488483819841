import AdminTitle from '../pages/admin/AdminTitle.vue';
import UsersPage from '../pages/admin/UsersPage.vue';
import RolesPage from '../pages/admin/RolesPage.vue';
import UserGroupsPage from "../pages/admin/UserGroupsPage";
import UserRoleLinksPage from "../pages/admin/users/UserRoleLinksPage";
import UserGroupLinksPage from "@/pages/admin/users/UserGroupLinksPage";
import RoleMethodLinksPage from "@/pages/admin/roles/RoleMethodLinksPage";
import RoleUserLinksPage from "@/pages/admin/roles/RoleUserLinksPage";
import GroupUserLinksPage from "@/pages/admin/user_groups/GroupUserLinksPage";
import MethodsPage from "@/pages/admin/MethodsPage";
import MethodRoleLinksPage from "@/pages/admin/methods/MethodRoleLinksPage";
import ModulesPage from "@/pages/admin/ModulesPage";
import AccessCategoriesPage from "@/pages/admin/AccessCategoriesPage";
import UserAccessesPage from "@/pages/admin/users/UserAccessesPage";
import ReportsPage from "@/pages/admin/ReportsPage";
import ReportGroupsPage from "@/pages/admin/ReportGroupsPage";
import SystemEventsPage from "@/pages/admin/SystemEventsPage";
import ExternalSystemsPage from "@/pages/admin/ExternalSystemsPage";
import EntityTypesPage from "@/pages/admin/EntityTypesPage";
import LinkedEntitiesPage from "@/pages/admin/LinkedEntitiesPage";
import MainPage from "@/pages/main/MainPage";
import MainParamsPage from "@/pages/admin/MainParamsPage";

export default {
    path: '/admin',
    component: MainPage,
    children: [
        {
            path: 'mainParams/:op(edit)',
            component: MainParamsPage,
            props: true
        },
        {
            path: 'mainParams',
            component: MainParamsPage,
        },
        {
            path: 'linkedEntities/:op(add|edit|del)/:id(\\d+)?',
            component: LinkedEntitiesPage,
            props: true
        },
        {
            path: 'linkedEntities',
            component: LinkedEntitiesPage,
        },
        {
            path: 'externalSystems',
            component: ExternalSystemsPage,
        },
        {
            path: 'entityTypes',
            component: EntityTypesPage,
        },
        {
            path: 'systemEvents',
            component: SystemEventsPage,
        },
        {
            path: 'users/:uid(\\d+)?/userAccesses/:op(show|add|del|edit)/:id(\\d+)?',
            component: UserAccessesPage,
            props: true
        },
        {
            path: 'users/userAccesses/:uid(\\d+)?',
            component: UserAccessesPage,
            props: true
        },
        {
            path: 'accessCategories',
            component: AccessCategoriesPage,
        },
        {
            path: 'users/userRoleLinks/:id(\\d+)?',
            component: UserRoleLinksPage,
            props: true
        },
        {
            path: 'users/userGroupLinks/:id(\\d+)?',
            component: UserGroupLinksPage,
            props: true
        },
        {
            path: 'users/:op(show|add|del|edit|block|unblock|setpass)/:id(\\d+)?',
            component: UsersPage,
            props: true
        },
        {
            path: 'users/:any(.*)*',
            component: UsersPage,
        },
        {
            path: 'roles/roleUserLinks/:id(\\d+)?',
            component: RoleUserLinksPage,
            props: true
        },
        {
            path: 'roles/roleMethodLinks/:id(\\d+)?',
            component: RoleMethodLinksPage,
            props: true
        },
        {
            path: 'roles/:op(show|add|del|edit|block|unblock|setpass)/:id(\\d+)?',
            component: RolesPage,
            props: true
        },
        {
            path: 'roles/:any(.*)*',
            component: RolesPage,
        },
        {
            path: 'userGroups/groupUserLinks/:id(\\d+)?',
            component: GroupUserLinksPage,
            props: true
        },
        {
            path: 'userGroups/:op(show|add|del|edit|block|unblock|setpass)/:id(\\d+)?',
            component: UserGroupsPage,
            props: true
        },
        {
            path: 'userGroups/:any(.*)*',
            component: UserGroupsPage,
        },

        {
            path: 'methods/methodRoleLinks/:id(\\d+)?',
            component: MethodRoleLinksPage,
            props: true
        },
        {
            path: 'methods',
            component: MethodsPage
        },
        {
            path: 'modules',
            component: ModulesPage
        },
        {
            path: 'reports',
            component: ReportsPage
        },
        {
            path: 'reportGroups',
            component: ReportGroupsPage
        },
        {
            path: '',
            component: AdminTitle
        },
        {
            path: ':pathMatch(.*)*',
            component: AdminTitle
        }
    ]
}