import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ НАБОРОВ ГРАФИКОВ

export default {
    actions: {
        // запрос набора графиков (для табличного отображения)
        doFetchScheduleGraphSetTable: (context, payload) => {
            return DoGet(
                '/rmt-gen/scheduleGraphSetTable?schedule_graph_set_id='+payload.schedule_graph_set_id,
                null,
                [],
                'Запрос набора графиков (для табличного отображения)',
                false,
                true,
            )
        },

        doFetchScheduleGraphSetGraphic: (context, payload) => {
            return DoGet(
                '/rmt-gen/scheduleGraphSetGraphic?schedule_graph_set_id='+payload.schedule_graph_set_id,
                null,
                [],
                'Запрос набора графиков (для графического отображения)',
                false,
                true,
            )
        },

    }
}
