export default {
    methods: {
        // проверить форму на ошибки
        validateForm(refs) {
            let result = true;
            for (const ref in refs) {
                const obj = refs[ref];
                if (obj && obj.validate) {
                    if (!obj.validate()) {
                        result = false;
                    }
                }
            }
            return result;
        }
    }
}
