<template>
  <alert class="page-header bg-info">
    <slot></slot>
  </alert>
</template>

<style>
  .page-header {
    margin-bottom: 3px;
  }

  .page-header span {
    color: white;
  }

  .page-header span:last-of-type {
    color: white;
    font-weight: bold;
  }

  .page-header a {
    color: white;
    text-decoration: none;
  }

</style>