import {DoClearCache, DoGet, DoPut} from "@/store/Actions";
import {clearArrContains, clearObjContains} from "@/lib";

// ХРАНИЛИЩЕ НАРЯДОВ НА РАБОТУ

export default {
    state() {
        return {
            // список объектов
            manualOrders: [],
            // время обновления кэша new Date().getTime()
            manualOrdersCache: null,
            // время жизни кэша в мс (null - бесконечно)
            manualOrdersCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getManualOrders(state) {
            return state.manualOrders;
        },
    },
    mutations: {

        // установить новый список объектов
        setManualOrders(state, payload) {
            state.manualOrders = payload;
            state.manualOrdersCache = new Date().getTime();
        },
        // добавить объект
        addManualOrder(state, payload) {
            state.manualOrders.push(payload);
        },
        // изменить объект
        editManualOrder(state, payload) {
            const index = state.manualOrders.findIndex(manualOrder => manualOrder.manual_order_id == payload.manual_order_id);
            if (index >= 0) {
                state.manualOrders[index] = payload;
            }
        },
        // удалить объект
        delManualOrder(state, payload) {
            const index = state.manualOrders.findIndex(manualOrder => manualOrder.manual_order_id == payload.manual_order_id);
            state.manualOrders.splice(index, 1);
        }
    },
    actions: {

        // запрос списка объектов
        doFetchManualOrders: (context, payload) => {
            return DoGet(
                '/kvr/manualOrders/' + payload.date_index,
                'kvr/setManualOrders',
                [],
                'Запрос всего наряда на работу',
                false,
                payload && payload.force,
                context.state.manualOrdersCache,
                context.state.manualOrdersCacheTimeout
            )
        },

        // запрос объекта
        doFetchManualOrder: (context, payload) => {
            return DoGet(
                '/kvr/manualOrders/' + payload.date_index + '/' + payload.manual_order_id,
                null,
                [],
                'Запрос наряда на работу',
                false,
                payload && payload.force,
                context.state.manualOrdersCache,
                context.state.manualOrdersCacheTimeout
            )
        },

        // очищает кэш
        doClearManualOrders: (context, payload) => {
            return DoClearCache(
                'kvr/setManualOrders',
                [],
                payload && payload.force,
                context.state.manualOrdersCache,
                context.state.manualOrdersCacheTimeout
            )
        },

        // Изменить наряд на работу
        doEditManualOrder: (context, payload) => {
            return DoPut(
                '/kvr/manualOrders/' + payload.date_index + '/' + payload.manual_order_id,
                clearObjContains(payload.value),
                'kvr/editManualOrder',
                'Изменение наряда на работу'
            )
        },

        // Изменить наряд на работу
        doEditManualOrderArr: (context, payload) => {
            return DoPut(
                '/kvr/manualOrders/' + payload.date_index,
                clearArrContains(payload.manual_orders),
                'kvr/setManualOrders',
                'Изменение нарядов на работу'
            )
        },

        // Разделить наряд на работу
        doSplitManualOrder: (context, payload) => {
            return DoPut(
                '/kvr/splitManualOrder/' + payload.date_index + '/' + payload.manual_order_id,
                payload.split_manual_order,
                null,
                'Разделение наряда на работу'
            )
        },

        // Очистить наряд за указанный день
        doClearManualOrder: (context, payload) => {
            return DoPut(
                '/kvr/clearManualOrder/' + payload.date_index,
                {plan_depot_id_list: payload.plan_depot_id_list},
                'kvr/editManualOrder',
                'Очищение наряда за указанный день'
            )
        },

        // Восстановить наряд по транспортному предприятию
        doClearManualOrderByDepot: (context, payload) => {
            return DoPut(
                '/kvr/clearManualOrder/' + payload.date_index + '/' + payload.plan_depot_id,
                null,
                null,
                'Восстановление наряда по транспортному предприятию'
            )
        },

        // Восстановить наряд по маршруту
        doClearManualOrderByDepotAndRoute: (context, payload) => {
            return DoPut(
                '/kvr/clearManualOrder/' + payload.date_index + '/' + payload.plan_depot_id + '/' + payload.route_id,
                null,
                null,
                'Восстановление наряда по маршруту'
            )
        },

        // Восстановить наряд по графику
        doClearManualOrderByDepotAndRouteAndManualOrder: (context, payload) => {
            return DoPut(
                '/kvr/clearManualOrder/' + payload.date_index + '/' + payload.plan_depot_id + '/' + payload.route_id + '/' + payload.manual_order_id,
                null,
                null,
                'Восстановление наряда по графику'
            )
        },

        // Применить наряд за указанный день
        doApplyManualOrder: (context, payload) => {
            return DoPut(
                '/kvr/applyManualOrder/' + payload.date_index,
                {plan_depot_id_list: payload.plan_depot_id_list},
                null,
                'Применение наряда за указанный день'
            )
        },
    }
}