<template>
  <input-select ref="select" v-model="value" caption="Выберите организацию..." :itemList="items"></input-select>
</template>

<script>
export default {
  props: ['model-value'],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      return this.$store.getters['ref/getOrganizations'].map(org => {
        return {
          id: org.org_id,
          title: org.long_name
        }
      });
    },
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
