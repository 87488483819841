import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ТАРИФНЫХ ЗОН

export default {

    actions: {

        // запрос объекта
        doFetchTariffZone: (context, payload) => {
            return DoGet(
                '/multipass/tariffZones/' + payload.tariff_zone_id,
                null,
                [],
                'Запрос тарифной зоны',
                false,
                true
            )
        },

        // запрос списка объектов
        doFetchTariffZones: (context, payload) => {
            return DoGet(
                '/multipass/tariffZones?tariff_zone_system_id=' + payload.tariff_zone_system_id,
                null,
                [],
                'Запрос тарифных зон',
                false,
                true
            )
        },

        // добавление объекта
        doAddTariffZone: (context, payload) => {
            return DoPost(
                '/multipass/tariffZones',
                payload,
                null,
                'Добавление тарифной зоны'
            )
        },

        // изменение объекта
        doEditTariffZone: (context, payload) => {
            return DoPut(
                '/multipass/tariffZones/'+payload.tariff_zone_id,
                payload,
                null,
                'Изменение тарифной зоны'
            )
        },

        // удаление объекта
        doDelTariffZone: (context, payload) => {
            return DoDelete(
                '/multipass/tariffZones/'+payload.tariff_zone_id,
                null,
                'Удаление тарифной зоны'
            )
        }
    }
}
