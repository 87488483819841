import {DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ КАРТОЧЕК КОМПОЗИТОВ ТИПОВ РЕЙСОВ

export default {
    actions: {
        // запрос списка объектов
        doFetchTripTypeCompositeCards: (context, payload) => {
            return DoGet(
                '/asmpp/tripTypeCompositeCards?trip_type_composite_id=' + payload.trip_type_composite_id,
                null,
                [],
                'Запрос карточек композита типов рейсов',
                false,
                true,

            )
        },

         // изменение объекта
        doEditTripTypeCompositeCard: (context, payload) => {
            return DoPut(
                '/asmpp/tripTypeCompositeCards/' + payload.trip_type_composite_id,
                payload.itemArr,
                null,
                'Изменение карточки композита типов рейсов'
            )
        },

    }
}
