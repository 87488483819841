
import TrnTitlePage from '../pages/trn/TrnTitlePage.vue';
import MainPage from "@/pages/main/MainPage";
import MasterOrdersPage from "@/pages/trn/MasterOrdersPage";
import BaseOrdersPage from "@/pages/trn/BaseOrdersPage";
import MapPage from "@/pages/nav/MapPage";
import DispatchingPage from "@/pages/trn/DispatchingPage";

export default {
    path: '/trn',
    component: MainPage,
    children: [
        {
            path: 'dispatching',
            component: DispatchingPage,
            props: true
        },
        {
            path: 'dispatching/:op(monitoringTaskCard)/:monitoring_task_id(\\d+)?',
            component: DispatchingPage,
            props: true
        },
        {
            path: 'map/:op(show)/:id(\\d+)?',
            component: MapPage,
            props: true
        },
        {
            path:'map',
            component: MapPage,
        },
        {
            path: 'baseOrders/:op(create)/:date_index(\\d+)?/:route_id(\\d+)?',
            component: BaseOrdersPage,
            props: true,
        },
        {
            path: 'baseOrders/:op(clear)/:date_index(\\d+)?/:plan_depot_id(\\d+)?/:route_id(\\d+)?/:base_order_id(\\d+)?',
            component: BaseOrdersPage,
            props: true,
        },
        {
            path: 'baseOrders',
            component: BaseOrdersPage
        },
        {
            path: 'masterOrders/:op(clear|edit)/:date_index(\\d+)?/:route_id(\\d+)?',
            component: MasterOrdersPage,
            props: true,
        },
        {
            path: 'masterOrders',
            component: MasterOrdersPage
        },
        {
            path: '',
            component: TrnTitlePage
        },
        {
            path: ':pathMatch(.*)*',
            component: TrnTitlePage
        }
    ]
}
