// Шрифты
import '@fortawesome/fontawesome-free/css/all.css';
// Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
// Leaflet
import 'leaflet/dist/leaflet.css';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';
// Контекстное меню
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'
// Подсказка
import Popper from "vue3-popper";

import { createApp } from 'vue'

import App from './App.vue'
import router from './router/Router';
import store from './store/Store'
import RegisterUI from './components/ui/RegisterUI';

// создаем приложение
const app = createApp(App);

app.config.unwrapInjectedRef = true;
// регистрируем маршрутизацию
app.use(router);
// регистрируем контекстное меню
app.use(ContextMenu);
// регистрируем хранилище
app.use(store);
// регистрируем подсказку
app.component("Popper", Popper);

// регистрируем служебные компоненты
RegisterUI(app);

// запускаем главный компонент
app.mount('#app');
