import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ КАРТОЧКИ ЗАДАНИЯ МОНИТОРИНГОВОЙ ТАБЛИЦЫ


export default {
    actions: {
        // запрос списка объектов
        doFetchMonitoringTaskCard: (context, payload) => {
            return DoGet(
                '/trn/getMonitoringTaskCard/' + payload.date_index + '/' + payload.monitoring_task_id,
                null,
                [],
                'Запрос карточки задания мониторинговой таблицы',
                false,
                payload && payload.force,
            )
        },
    }
}