import MethodsSystemStore from "@/store/system/MethodsSystemStore";
import ModulesSystemStore from "@/store/system/ModulesSystemStore";
import ReportsSystemStore from "@/store/system/ReportsSystemStore";
import ReportGroupsSystemStore from "@/store/system/ReportGroupsSystemStore";
import MainParamsSystemStore from "@/store/system/MainParamsSystemStore";

// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ SYSTEM

export default {
    namespaced: true,
    modules: {
        MethodsSystemStore,
        ModulesSystemStore,
        ReportsSystemStore,
        ReportGroupsSystemStore,
        MainParamsSystemStore,
    }
}
