import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОКУМЕНТОВ ОСТАНОВОЧНЫХ ПУНКТОВ

export default {
    actions: {
        // запрос объекта
        doFetchStopDoc: (context, payload) => {
            return DoGet(
                '/multipass/stopDocs/' + payload.stop_doc_id,
                null,
                {},
                'Запрос документа остановочного пункта',
                false,
                true
            )
        },

        // запрос списка объектов
        doFetchStopDocs: (context, payload) => {
            return DoGet(
                '/multipass/stopDocs?stop_id=' + payload.stop_id,
                null,
                [],
                'Запрос документов остановочного пункта',
                false,
                true,
            )
        },

        // добавление объекта
        doAddStopDoc: (context, payload) => {
            return DoPost(
                '/multipass/stopDocs',
                payload,
                null,
                'Добавление документа остановочного пункта'
            )
        },

        // изменение объекта
        doEditStopDoc: (context, payload) => {
            return DoPut(
                '/multipass/stopDocs/'+ payload.stop_doc_id,
                payload,
                null,
                'Изменение документа остановочного пункта'
            )
        },

        // удаление объекта
        doDelStopDoc: (context, payload) => {
            return DoDelete(
                '/multipass/stopDocs/'+ payload.stop_doc_id,
                null,
                'Удаление документа остановочного пункта'
            )
        }
    }
}