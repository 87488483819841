import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ СОСТОЯНИЙ ПЕРСОНАЛА

export default {
    actions: {
        // запрос списка объектов
        doFetchPersonnelStates: (context, payload) => {
            return DoGet(
                '/ref/personnelStates?personnel_id=' + payload.personnel_id,
                null,
                [],
                'Запрос состояний по работнику',
                false,
                true,
            )
        },
    }
}