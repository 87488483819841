import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ГРУПП ПОЛЬЗОВАТЕЛЕЙ

export default {
    state() {
        return {
            // список объектов
            userGroups: [],
            // время обновления кэша new Date().getTime()
            userGroupsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            userGroupsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getUserGroups(state) {
            return state.userGroups;
        },
    },
    mutations: {
        // установить новый список объектов
        setUserGroups(state, payload) {
            state.userGroups = payload;
            state.userGroupsCache = new Date().getTime()
        },
        // добавить объект
        addUserGroup(state, payload) {
            state.userGroups.push(payload)
        },
        // изменить объект
        editUserGroup(state, payload) {
            const index = state.userGroups.findIndex(ug => ug.user_group_id == payload.user_group_id);
            if (index >= 0) {
                state.userGroups[index] = payload;
            }
        },
        // удалить объект
        delUserGroup(state, payload) {
            const index = state.userGroups.findIndex(ug => ug.user_group_id == payload.user_group_id);
            state.userGroups.splice(index, 1);
        },
    },
    actions: {
        // запрос списка объектов
        doFetchUserGroups: (context, payload) => {
            return DoGet(
                '/auth/userGroups',
                'auth/setUserGroups',
                [],
                'Запрос групп пользователей',
                false,
                payload && payload.force,
                context.state.userGroupsCache,
                context.state.userGroupsCacheTimeout
            )
        },

        // очищает кэш
        doClearUserGroups: (context, payload) => {
            return DoClearCache(
                'auth/setUserGroups',
                [],
                payload && payload.force,
                context.state.userGroupsCache,
                context.state.userGroupsCacheTimeout
            )
        },

        // добавление объекта
        doAddUserGroup: (context, payload) => {
            return DoPost(
                '/auth/userGroups',
                payload,
                'auth/addUserGroup',
                'Добавление группы пользователей'
            )
        },

        // изменение объекта
        doEditUserGroup: (context, payload) => {
            return DoPut(
                '/auth/userGroups/'+payload.user_group_id,
                payload,
                'auth/editUserGroup',
                'Изменение данных группы пользователей'
            )
        },

        // удаление объекта
        doDelUserGroup: (context, payload) => {
            return DoDelete(
                '/auth/userGroups/'+payload.user_group_id,
                'auth/delUserGroup',
                'Удаление группы пользователей'
            )
        },
    }
}