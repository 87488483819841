<template>
  <input
      type="text"
      v-bind="$attrs"
      :class="{ 'is-invalid': isInvalid && !readonly && !disabled }"
      :readonly="readonly"
      :disabled="disabled"
      ref="inputRef"
      autocomplete="off"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
  >
  <div class="invalid-feedback">{{ error }}</div>
</template>

<script>
export default {
  props: {
    // модель
    'model-value': {
      required: true
    },
    // минимальное разрешенное значение
    'min-value': {
      type: Number,
    },
    // исключать минимальное значение из диапазона
    'min-value-exclude': {
      type: Boolean,
      default: false
    },
    // максимальное разрешенное значение
    'max-value': {
      type: Number
    },
    // исключать максимальное значение из диапазона
    'max-value-exclude': {
      type: Boolean,
      default: false
    },
    // требуется ввод значения
    'required': {
      type: Boolean,
      default: false
    },
    // только для чтения
    'readonly': {
      type: Boolean,
      default: false,
    },
    // отключен
    'disabled': {
      type: Boolean,
      default: false,
    },
    // текст ошибки
    'error': {
      type: String,
      default: 'Введите стоимость'
    },
  },

  emits: ['update:model-value'],

  data() {
    return {
      // признак ошибки
      isInvalid: false,
      // последнее корректное значение
      lastText: '',
    }
  },

  methods: {
    // проверка валидности
    isValid() {
      // в этих режимах на значения не смотрим
      if (this.readonly || this.disabled) return true;

      // разраешаем пустые строки, если допускается пустое значение
      if (this.modelValue === null)
        return !this.required

      const newValue = Number(this.modelValue);
      if (!Number.isFinite(newValue))
        return false;

      // проверяем минимальные значения
      if (Number.isFinite(this.minValue)) {
        if (this.minValueExclude) {
          if (newValue <= this.minValue)
            return false;
        }
        else {
          if (newValue < this.minValue)
            return false;
        }
      }

      // проверяем максимальные значения
      if (Number.isFinite(this.maxValue)) {
        if (this.maxValueExclude) {
          if (newValue >= this.maxValue)
            return false;
        }
        else {
          if (newValue > this.maxValue)
            return false;
        }
      }

      return true;
    },

    // при получении фокуса - сбрасываем инвалидность
    onFocus() {
      this.isInvalid = false;
    },

    // при потере фокуса - проверяем значение
    onBlur() {
      this.$refs.inputRef.value = (this.lastText !== '' && Number.isFinite(Number(this.lastText))) ?  Number(this.lastText) : this.lastText;
      this.validate();
    },

    // проверяет адекватность модели
    isCorrelate(text) {
      if (text === '') {
        return this.modelValue === null;
      }
      return (Number.isFinite(Number(text)) && Number(text) * 100 === this.modelValue);
    },

    // проверяет корректность формата
    isCorrectedFormat(text) {
      const pointIndex = text.indexOf('.');

      return pointIndex < 0 || text.length - pointIndex - 1 <= 2;
    },

    // при вводе значений
    onInput() {
      // запрашиваем текущее значение
      const value = this.$refs.inputRef.value.trim();

      // пришла пустая строка
      if (value === '') {
        // запоминаем значение
        this.lastText = '';
        // если не коррелирует
        if (!this.isCorrelate(value)) {
          // отправляем новое значение
          this.$emit('update:model-value', null);
        }
        // выходим
        return;
      }

      // пришло число
      if (Number.isFinite(Number(value))) {
        // формат удовлетворяет
        if (this.isCorrectedFormat(value)) {
          // новое значение не коррелирует со старым
          if (!this.isCorrelate(value)) {
            // запоминаем новое значение
            this.lastText = value;
            // отправляем новое значение
            this.$emit('update:model-value', Number(value) * 100);
          }
          // новое значение коррелирует со старым
          else {
            // ничего не отправляем - просто запоминаем новое значение
            this.lastText = value;
          }
        }
        // формат не удовлетворяет
        else {
          // добавили что-то лишнее - возвращаемся к предыдущему значению
          if (value.indexOf(this.lastText) >= 0) {
            this.$refs.inputRef.value = this.lastText;
            return
          }
          // совершенно что-то новое - округляем
          let newValue = Math.trunc(Number(value) * 100) / 100;
          // запоминаем новое значение
          this.lastText = String(newValue);
          // отправляем новое значение
          this.$emit('update:model-value', newValue * 100);
        }
      }
      // пришло непонятно чего
      else {
        // добавили что-то лишнее - возвращаемся к предыдущему значению
        if (this.isCorrelate(this.lastText) && value.indexOf(this.lastText) >= 0) {
          this.$refs.inputRef.value = this.lastText;
          return
        }
        // сбрасываем значение полностью
        this.lastText = '';
        this.$emit('update:model-value', null);
      }
    },

    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },
  },

  mounted() {
    // следим за изменением модели
    this.$watch(() => this.modelValue, (value) => {

      // пришел null
      if (value === null) {
        // трогаем текстовую строку, только если она противоречит
        if (!this.isCorrelate(this.$refs.inputRef.value)) {
          this.$refs.inputRef.value = '';
          this.lastText = '';
        }
        // выходим
        return
      }

      // пришла строка, которую можно преобразовать в число
      if (typeof(value) === 'string' && Number.isFinite(Number(value))) {
        // отправляем изменения
        this.$emit('update:model-value', Number(value));
        // выходим
        return
      }

      // пришло число
      if (typeof(value) === 'number' && Number.isFinite(value)) {

        let changed = false;

        // не разрешаем отрицательные числа
        if (value < 0) {
          value = Math.abs(value);
          changed = true;
        }

        // если пришло дробное число - отрезаем дробную часть
        if (!Number.isInteger(value)) {
          value = Math.trunc(value);
          changed = true;
        }

        // если что-то поменялось
        if (changed) {
          // отправляем изменения
          this.$emit('update:model-value', value);
          // выходим
          return
        }

        // трогаем текстовую строку, только если она противоречит
        if (!this.isCorrelate(this.$refs.inputRef.value)) {
          this.$refs.inputRef.value = String(value / 100);
          this.lastText = String(value / 100);
        }

        return
      }

      // пришло непонятно чего - сбрасываем в null
      this.$emit('update:model-value', null);

    }, {immediate: true})
  },
}
</script>

<style scoped>

input::placeholder {
  color: #a9a9a9;
}

</style>