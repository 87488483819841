<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-transport-group-links"> </edit-table-panel>
</template>

<script>
export default {
  props: ['id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__checked",
          caption: "Вкл",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.__checked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "transport_group_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "transport_group_name",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        }
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      const groups = this.$store.getters["ref/getTransportGroups"];
      const links = this.$store.getters["ref/getTransportGroupLinks"];
      return groups.map(group => {

        group.__id = group.transport_group_id;
        group.__checked = links.some(link => link.transport_id == this.id && link.transport_group_id == group.transport_group_id)

        return group;
      });
    },
  },
};
</script>
