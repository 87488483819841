<template>
  <!--  ТАБЛИЦА ПОЛЬЗОВАТЕЛЕЙ С ПОМЕТКОЙ ДЛЯ КОНКТРЕНОЙ РОЛИ-->
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-role-user-links"> </edit-table-panel>
</template>

<script>
export default {
  props: ['id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__checked",
          caption: "Вкл",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.__checked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "uid",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "login",
          caption: "Логин",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "last_name",
          caption: "Фамилия",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "first_name",
          caption: "Имя",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "middle_name",
          caption: "Отчество",
          sortType: 'string',
        },
        {
          field: "org",
          caption: "Организация",
          sortType: 'string',
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      const users = this.$store.getters["auth/getUsers"];
      const links = this.$store.getters["auth/getUserRoleLinks"];
      return users.map(user => {

        user.__id = user.uid;
        user.__checked = links.some(link => link.uid == user.uid && link.role_id == this.id)

        return user;
      });
    },
  },
};
</script>
