import {DoDelete, DoGet, DoGetFile, DoPostFile} from "@/store/Actions";

// ХРАНИЛИЩЕ ФАЙЛОВ ДОКУМЕНТОВ МАРШРУТОВ

export default {
    actions: {
        // запрос объекта
        doFetchRouteDocFile: (context, payload) => {
            return DoGetFile(
                '/multipass/routeDocFiles/' + payload.route_doc_id + "/" + payload.document_file_id,
                null,
                'Загрузка файла',
                true,
                payload.file_name,
            )
        },

        // запрос списка объектов
        doFetchRouteDocFiles: (context, payload) => {
            return DoGet(
                '/multipass/routeDocFiles/' + payload.route_doc_id,
                null,
                [],
                'Запрос файлов документа маршрута',
                false,
                true,
            )
        },

        // добавление объекта
        doAddRouteDocFile: (context, payload) => {
            return DoPostFile(
                '/multipass/routeDocFiles/' + payload.route_doc_id,
                payload.file.file,
                null,
                'Добавление файла документа маршрута'
            )
        },

        // удаление объекта
        doDelRouteDocFile: (context, payload) => {
            return DoDelete(
                '/multipass/routeDocFiles/'+ payload.route_doc_id + "/" + payload.document_file_id,
                null,
                'Удаление файла документа маршрута'
            )
        }
    }
}