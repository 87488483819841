import MainPage from '../pages/main/MainPage.vue';
import PriorityTitlePage from '../pages/priority/PriorityTitlePage.vue';

export default{
    path: '/priority', 
    component: MainPage,
    children: [
        {
            path: ':pathMatch(.*)*',
            component: PriorityTitlePage
        }                
    ]
}
