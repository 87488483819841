<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Композиты типов рейсов</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>
                <div class="row">
                  <div class="form-group col">
                    <label for="tripTypeCompositeId">Код:</label>
                    <input-integer
                        id="tripTypeCompositeId"
                        class="form-control w-25"
                        v-model="tripTypeCompositeId"
                        readonly
                    />
                  </div>

                </div>

                <div class="form-group">
                  <label for="longName">Полное наименование:</label>
                  <input-string
                      id="longName"
                      v-model="longName"
                      class="form-control"
                      ref="longName"
                      autocomplete="off"
                      :readonly="op === 'show'"
                      error="Введите полное наименование"
                  />
                </div>

                <div class="form-group">
                  <label for="shortName">Краткое наименование:</label>
                  <input-string
                      id="shortName"
                      v-model="shortName"
                      class="form-control"
                      ref="shortName"
                      autocomplete="off"
                      :readonly="op === 'show'"
                      error="Введите краткое наименование"
                  />
                </div>

              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  components: {},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      tripTypeCompositeId: this.item.trip_type_composite_id,
      shortName: this.item.short_name,
      longName: this.item.long_name,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {

        this.$emit('onOK', {
          trip_type_composite_id: this.tripTypeCompositeId,
          short_name: this.shortName,
          long_name: this.longName,
        })
      }
    },
  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>