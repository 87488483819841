<template>
  <input-select ref="select" v-model="value" caption="Выберите эксперта..." :itemList="items"></input-select>
</template>

<script>
export default {
  props: [
    'model-value',
  ],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      return this.$store.getters['kvr-expert/getExperts'].map(item => {
        return {
          id: item.uid,
          title: (item.last_name ? item.last_name : '') + ' ' + (item.first_name ? item.first_name.substring(0,1) + '.' : '')  + (item.middle_name ? item.middle_name.substring(0,1) + '.' : '')
        }
      });
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
