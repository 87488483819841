import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ПРИЧИН КОМАНД ЭКСПЕРТА

export default {
    state() {
        return {
            // список объектов
            expCommands: [],
            // время обновления кэша new Date().getTime()
            expCommandsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            expCommandsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getExpCommands(state) {
            return state.expCommands;
        },
    },
    mutations: {
        // установить новый список объектов
        setExpCommands(state, payload) {
            state.expCommands = payload;
            state.expCommandsCache = new Date().getTime()
        },
    },
    actions: {

        // запрос списка объектов
        doFetchExpCommands: (context, payload) => {
            return DoGet(
                '/kvr-expert/expCommands',
                'kvr-expert/setExpCommands',
                [],
                'Получить список всех команд эксперта',
                false,
                payload && payload.force,
                context.state.expCommandsCache,
                context.state.expCommandsCacheTimeout
            )
        },

        // запрос списка объектов
        doFetchExpCommand: (context, payload) => {
            return DoGet(
                '/kvr-expert/expCommands/' + payload.exp_command_id,
                'kvr-expert/setExpCommands',
                [],
                'Получить команду эксперта',
                false,
                payload && payload.force,
                context.state.expCommandsCache,
                context.state.expCommandsCacheTimeout
            )
        },

        // очищает кэш
        doClearExpCommands: (context, payload) => {
            return DoClearCache(
                'kvr-expert/setExpCommands',
                [],
                payload && payload.force,
                context.state.expCommandsCache,
                context.state.expCommandsCacheTimeout
            )
        },
    }
}
