<template>
  <div class="card mainCard bg-light" >
    <div class="card-header" style="font-size: 18px; padding: 0px 0px 0px 8px;">
      <b> {{ getSelectedDatePrompt + ' '}}</b>
      <b :style="'color:' + (selectedStop ? 'black' : 'red')">
        {{selectedStationPrompt}}
      </b>
    </div>

    <div class="form-group row" style="margin-top: 5px; margin-left: 5px; margin-bottom: 0px;">
      <div class="form-group col" style="margin: 0; padding: 0; !important;">
        <div class="form-group row" style="margin-bottom: 0px; margin-left: 0px;">

          <input-button-option
              v-model="viewType"
              :itemList="[{id:0, title: 'День'}, {id:1, title: 'Период'}]"
              @change="typeChange"
              :readonly="!isViewTypeSwitchEnable"
              required
          ></input-button-option>

          <input-button-option
              v-model="tableType"
              v-if="isTableChangeVisible"
              :itemList="[{id:0, title: 'Предприятия'}, {id:1, title: 'Маршруты'}]"
              @change="tableTypeChange"
              style="margin-bottom: 0px; margin-left: 5px;"
              required
          ></input-button-option>

          <input-checkbox
              v-if="isTimePeriodButtonVisible"
              id="isTimePVisible"
              v-model="isTimePVisible"
              @change="resetTime"
              style="margin-bottom: 0px; margin-left: 5px; margin-top: 7px"
          >Анализировать работу за временной период
          </input-checkbox>

          <div v-if="isTPVisible" class="form-group row"
               style="margin-bottom: 0px; margin-left: 5px; align-content: center; margin-top: 5px">
            <label class="label" style=" margin-right: 10px">
              Временной период
            </label>
            <input-time
                id="startTime"
                class="form-control"
                autocomplete="off"
                style="max-width: 70px; max-height: 24px"
                v-model="startTime"
            ></input-time>

            <label class="label" style="margin-left: 5px; margin-right: 5px">
              -
            </label>

            <input-time
                id="startTime"
                class="form-control"
                autocomplete="off"
                style="max-width: 70px; max-height: 24px"
                v-model="endTime"
            ></input-time>

            <label v-if="isTimePeriodErrorVisible" class="form-check-label" for="radios4"
                   style="margin-left: 15px; color: red;">
              Ошибка ввода времени
            </label>
          </div>
        </div>
      </div>

      <div class="form-group col" style="margin: 0 5px 0 0; text-align: right; min-width: 200px !important;">
        <button
            type="button"
            class="btn btn-primary"
            style="right: 5px"
            @click="reportButtonClick"
            :disabled="!isReportBtnActive"
        >
          Отчет
        </button>
      </div>
    </div>

    <div v-if="viewType == 1" class="form-group" style="margin-top: 5px; margin-left: 5px; margin-bottom: 0.5px">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="radio" id="radios1" value="week"
               v-model="selectedRadioPeriod"
               @click="setWeek">
        <div class="form-group row" style="margin-bottom: 0px; max-height: 24px">
          <label class="form-check-label" for="radios1" style="margin-left: 15px;">
            Неделя
          </label>
          <div v-if="selectedRadioPeriod == 'week'" class="form-group row" style="margin-left: 15px;">
            <input-date
                id="date"
                class="form-control"
                autocomplete="off"
                style="max-width: 140px; max-height: 24px;  padding: 0;"
                v-model="selectedDate"
                @change="setWeek"
            ></input-date>
            <input-time
                v-if="isTimeEnable"
                id="startTime"
                class="form-control"
                autocomplete="off"
                style="max-width: 70px; max-height: 24px"
                v-model="startTime"
            ></input-time>
          </div>
        </div>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="radio" id="radios2" value="twoWeeks"
               v-model="selectedRadioPeriod"
               @click="setTwoWeek">
        <div class="form-group row" style="margin-bottom: 0px; max-height: 24px">
          <label class="form-check-label" for="radios2" style="margin-left: 15px;">
            Две недели
          </label>
          <div v-if="selectedRadioPeriod == 'twoWeeks'" class="form-group row" style="margin-left: 15px;">
            <input-date
                id="date"
                class="form-control"
                autocomplete="off"
                style="max-width: 140px; max-height: 24px;  padding: 0;"
                v-model="selectedDate"
                @change="setTwoWeek"
            ></input-date>
            <input-time
                v-if="isTimeEnable"
                id="startTime"
                class="form-control"
                autocomplete="off"
                style="max-width: 70px; max-height: 24px"
                v-model="startTime"
            ></input-time>
          </div>
        </div>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="radio" id="radios3" value="month"
               v-model="selectedRadioPeriod"
               @click="setMonth">
        <div class="form-group row" style="margin-bottom: 0px; max-height: 24px">
          <label class="form-check-label" for="radios3" style="margin-left: 15px;">
            Месяц
          </label>
          <div v-if="selectedRadioPeriod == 'month'" class="form-group row" style="margin-left: 15px;">
            <input-date
                v-if="isFireFox"
                id="date"
                class="form-control"
                autocomplete="off"
                style="max-width: 140px; max-height: 24px;  padding: 0;"
                v-model="selectedDate"
                @change="setMonth"
            ></input-date>
            <input-mounth
                v-else
                class="form-control"
                style="max-width: 160px; max-height: 24px;"
                v-model="selectedDate"
                @change="setMonth"
            ></input-mounth>

            <input-time
                v-if="isTimeEnable"
                id="startTime"
                class="form-control"
                autocomplete="off"
                style="max-width: 70px; max-height: 24px"
                v-model="startTime"
            ></input-time>
          </div>
        </div>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="radio" id="radios4" value="quarter"
               v-model="selectedRadioPeriod"
               @click="changeQuarters">
        <div class="form-group row" style="margin-bottom: 0px; max-height: 24px">
          <label class="form-check-label" for="radios4" style="margin-left: 15px;">
            Квартал
          </label>
          <div v-if="selectedRadioPeriod == 'quarter'" class="form-group row" style="margin-left: 15px;">
            <input-quarter
                v-model="selectedDate"
                @changeQuarters="setQuarters"
            ></input-quarter>
            <input-time
                v-if="isTimeEnable"
                id="startTime"
                class="form-control"
                autocomplete="off"
                style="max-width: 70px; max-height: 24px"
                v-model="startTime"
            ></input-time>
          </div>
        </div>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="radio" id="radios5" value="period"
               v-model="selectedRadioPeriod"
               @click="setDatePeriod">
        <div class="form-group row" style="margin-bottom: 0px; max-height: 24px;">
          <label v-if="selectedRadioPeriod != 'period'" class="form-check-label" for="radios5"
                 style="margin-left: 15px;">
            Пользовательский период
          </label>
          <div v-if="selectedRadioPeriod == 'period'" class="form-group row"
               style="margin-left: 15px; margin-bottom: 0.5rem;  min-width: 400px !important;">
            <input-date
                id="date"
                class="form-control"
                autocomplete="off"
                style="max-width: 120px; max-height: 24px;  padding: 0;"
                v-model="startDate"
            ></input-date>
            <input-time
                v-if="isTimeEnable"
                id="startTime"
                class="form-control"
                autocomplete="off"
                style="max-width: 70px; max-height: 24px"
                v-model="startTime"
            ></input-time>

            <label class="label" style="margin-left: 5px; margin-right: 5px">
              -
            </label>

            <input-date
                id="date"
                class="form-control"
                autocomplete="off"
                style="max-width: 120px; max-height: 24px; padding: 0;"
                v-model="endDate"
            ></input-date>
            <input-time
                v-if="isTimeEnable"
                id="startTime"
                class="form-control"
                autocomplete="off"
                style="max-width: 70px; max-height: 24px"
                v-model="endTime"
            ></input-time>

          </div>
        </div>
      </div>
    </div>

    <!-- ========================================================================================================= -->

    <div v-else class="form-group" style="margin-top: 5px; margin-left: 5px">

      <input-checkbox
          v-if="false"
          id="isDriverDisplay"
          v-model="isTimeSlice"
          @change="setTimeSlice"
          style:custom-control-input="height: 13px; width: 13px"
      >Временной срез
      </input-checkbox>

      <div class="form-check" style="max-height: 24px">
        <input class="form-check-input" type="radio" name="radio" id="radios1" :value="'today'"
               v-model="selectedRadioDay"
               @click="setToday">
        <div class="form-group row" style="margin-bottom: 0px; max-height: 24px">
          <label class="form-check-label" for="radios1" style="margin-left: 15px; margin-right: 15px">
            Сегодня
          </label>
          <input-time
              v-if="selectedRadioDay == 'today' && (isTimeEnable == true || isSingleTimeEnable)"
              id="startTime"
              class="form-control"
              autocomplete="off"
              style="max-width: 70px; max-height: 24px"
              v-model="startTime"
          ></input-time>
          <label v-if="selectedRadioDay == 'today' && isTimeSlice != true && isTimeEnable == true" class="label"
                 style="margin-left: 10px; margin-right: 10px">
            -
          </label>
          <input-time
              v-if="selectedRadioDay == 'today' && isTimeSlice != true && isTimeEnable == true"
              id="endTime"
              class="form-control"
              autocomplete="off"
              style="max-width: 70px; max-height: 24px"
              v-model="endTime"
          ></input-time>
        </div>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="radio" id="radios2" :value="'yesterday'"
               v-model="selectedRadioDay"
               @click="setYesterday">
        <div class="form-group row" style="margin-bottom: 0px; max-height: 24px">
          <label class="form-check-label" for="radios2" style="margin-left: 15px; margin-right: 15px">
            Вчера
          </label>
          <input-time
              v-if="selectedRadioDay == 'yesterday' && (isTimeEnable == true || isSingleTimeEnable)"
              id="startTime"
              class="form-control"
              autocomplete="off"
              style="max-width: 70px; max-height: 24px"
              v-model="startTime"
          ></input-time>
          <label v-if="selectedRadioDay == 'yesterday' && isTimeSlice != true && isTimeEnable == true" class="label"
                 style="margin-left: 10px; margin-right: 10px">
            -
          </label>
          <input-time
              v-if="selectedRadioDay == 'yesterday' && isTimeSlice != true  && isTimeEnable == true"
              id="endTime"
              class="form-control"
              autocomplete="off"
              style="max-width: 70px; max-height: 24px"
              v-model="endTime"
          ></input-time>
        </div>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="radio" id="radios3" :value="'dayBeforeYesterday'"
               v-model="selectedRadioDay" @click="setDayBeforeYesterday">
        <div class="form-group row" style="margin-bottom: 0px; max-height: 24px">
          <label class="form-check-label" for="radios3" style="margin-left: 15px; margin-right: 15px">
            Позавчера
          </label>
          <input-time
              v-if="selectedRadioDay == 'dayBeforeYesterday'  && (isTimeEnable == true || isSingleTimeEnable)"
              id="startTime"
              class="form-control"
              autocomplete="off"
              style="max-width: 70px; max-height: 24px"
              v-model="startTime"
          ></input-time>
          <label v-if="selectedRadioDay == 'dayBeforeYesterday' && isTimeSlice != true  && isTimeEnable == true"
                 class="label"
                 style="margin-left: 10px; margin-right: 10px">
            -
          </label>
          <input-time
              v-if="selectedRadioDay == 'dayBeforeYesterday' && isTimeSlice != true  && isTimeEnable == true"
              id="endTime"
              class="form-control"
              autocomplete="off"
              style="max-width: 70px; max-height: 24px"
              v-model="endTime"
          ></input-time>
        </div>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="radio" id="radios4" :value="'userDate'"
               v-model="selectedRadioDay">
        <div class="form-group row" style="margin-bottom: 0px; max-height: 24px">
          <label v-if="selectedRadioDay != 'userDate'" class="form-check-label" for="radios4"
                 style="margin-left: 15px; margin-right: 15px" @click="setCustomDate">
            Пользовательская дата
          </label>
          <div v-else class="form-group row" style="min-width: 320px">
            <input-date
                id="startTime"
                class="form-control"
                autocomplete="off"
                style="max-width: 140px; max-height: 24px; margin-left: 30px;  padding: 0;"
                v-model="selectedDate"
            ></input-date>
            <input-time
                v-if="isTimeEnable || isSingleTimeEnable"
                id="startTime"
                class="form-control"
                autocomplete="off"
                style="max-width: 70px; max-height: 24px"
                v-model="startTime"
            ></input-time>
            <label v-if="isTimeSlice != true  && isTimeEnable == true" class="label"
                   style="margin-left: 5px; margin-right: 5px">
              -
            </label>
            <input-time
                v-if="isTimeSlice != true  && isTimeEnable == true"
                id="endTime"
                class="form-control"
                autocomplete="off"
                style="max-width: 70px; max-height: 24px"
                v-model="endTime"
            ></input-time>
          </div>
        </div>
      </div>

    </div>

    <div v-if="isSignersPanelsVisible" class="parent" style=" position: absolute; left: 363px; top: 33px">
      <div  class="card bg-light" style="width: 345px; height: 116px">
        <input-checkbox
            id="isFirstSignatoryActive"
            v-model="isFirstSignatoryActive"
            @change="firstInputCheckBoxChange"
            style="margin-left: 5px;"
        >Подписант 1
        </input-checkbox>
        <div class="row" style="margin: 5px 0 5px 5px;">
          <label class="form-check-label" style="font-size: 14px; margin-top: 2px;">Ф.И.О.</label>
          <input-string
              id="firstSignatoryName"
              v-model="firstSignatoryName"
              class="form-control"
              style="height: 25px;width: 255px; margin-left: 39px; padding-left: 6px; padding-right: 6px; font-size: 14px"
              :disabled="!isFirstSignatoryActive"
          >
          </input-string>
        </div>
        <div class="row" style="margin: 0 0 0 5px;">
          <label class="form-check-label" style="font-size: 14px">Должность</label>
          <input-textarea
              id="firstSignatoryOccupation"
              v-model="firstSignatoryOccupation"
              class="form-control"
              style="height: 50px;width: 255px; max-height: 95px; min-height: 50px; max-height: 50px; margin-left: 7px; margin-bottom: 5px;padding: 0px 5px; font-size: 14px"
              :disabled="!isFirstSignatoryActive"
          >
          </input-textarea>
        </div>
      </div>


      <div v-if="isSignersPanelsVisible" class="card bg-light"
           style="width: 345px; height: 116px; min-height: 116px">
        <input-checkbox
            id="isSecondSignatoryActive"
            v-model="isSecondSignatoryActive"
            @change="secondInputCheckBoxChange"
            style="margin-left: 5px;"
        >Подписант 2
        </input-checkbox>
        <div class="row" style="margin: 5px 0 5px 5px;">
          <label class="form-check-label" style="font-size: 14px; margin-top: 2px;">Ф.И.О.</label>
          <input-string
              id="secondSignatoryName"
              v-model="secondSignatoryName"
              class="form-control"
              style="height: 25px;width: 255px; margin-left: 39px; padding-left: 6px; padding-right: 6px;font-size: 14px"
              :disabled="!isSecondSignatoryActive"
          >
          </input-string>
        </div>
        <div class="row" style="margin: 0 0 0 5px;">
          <label class="form-check-label" style="font-size: 14px">Должность</label>
          <input-textarea
              id="secondSignatoryOccupation"
              v-model="secondSignatoryOccupation"
              class="form-control"
              style="height: 50px;width: 255px; max-height: 95px; min-height: 50px; max-height: 50px; margin-left: 7px; margin-bottom: 5px;padding: 0px 5px; font-size: 14px"
              :disabled="!isSecondSignatoryActive"
          >
          </input-textarea>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import InputTime from "@/components/ui/custom/InputTime";
import InputDate from "@/components/ui/custom/InputDate";
import {dateToND, dateToString, getTimeFormat, ndToDate, quartals} from "@/lib";
import InputQuarter from "@/components/ui/custom/InputQuarter";
import InputString from "@/components/ui/custom/InputString";
import InputTextarea from "@/components/ui/custom/InputTextarea";
import InputMounth from "@/components/ui/custom/InputMounth";

export default {
  name: "DateTimeChooserPanel",
  components: {InputMounth, InputTextarea, InputString, InputQuarter, InputDate, InputTime},
  props: [
    // идентификатор выбранного отчета
    'id',
    // настройки отображения выбора времени в зависимости от выбранного отчета
    'isTimeEnable',
    // настройки отображения одного поля ввода времени
    'isSingleTimeEnable',
    // настройки отображения окон Временной период
    'isTimePeriodVisible',
    // настройки отображения переключателя отображения Временного периода
    'isTimePeriodButtonVisible',
    // настройки отображения переключения День-Период
    'isViewTypeSwitchEnable',
    // активна ли кнопка "Отчет"
    'isReportButtonActive',
    // настройки отображения переключателя таблиц
    'isTableChangeVisible',
    // настройки отображения панелек подписантов
    'isSignersPanelsVisible',
    // настройки добавления поля stop_id в запрос отчета
    'stopReports',
  ],
  emit: ['onGetReportClick', 'changeTable'],
  data() {
    return {
      // режим отображения
      viewType: 0,
      // отображаемая снизу таблица,
      tableType: 0,
      // галочка "Временной срез"
      isTimeSlice: false,
      // выбранная дата
      selectedDate: '',
      // дата начала интервала
      startDate: '',
      // дата конца интервала
      endDate: '',
      // время начала
      startTime: (this.isSingleTimeEnable == true || this.isTimePeriodVisible == true) ? (new Date().getHours() * 60 * 60) + (new Date().getMinutes() * 60) + (new Date().getSeconds()) : null,
      // время конца
      endTime: (this.isTimePeriodVisible || (this.isTimePeriodButtonVisible && this.isTimePVisible)) ? 97200 : null,
      // выбранный параметр в "День"
      selectedRadioDay: '',
      // выбранный параметр в "Период"
      selectedRadioPeriod: '',
      // видимость временного периода
      isTimePVisible: false,
      // активность окошка первого подписанта
      isFirstSignatoryActive: false,
      // имя первого подписанта
      firstSignatoryName: '',
      // должность первого подписанта
      firstSignatoryOccupation: '',
      // активность окошка второго подписанта
      isSecondSignatoryActive: false,
      // имя второго подписанта
      secondSignatoryName: '',
      // должность второго подписанта
      secondSignatoryOccupation: '',

    }
  },

  computed: {

    selectedStop() {
      return this.$store.getters['settings/getSelectedStop'];
    },

    // проверяем какой браузер у пользователя, если это firefox необходимо поменять компонент выбора месяца на выбор даты
    // т.к. firefox не поддерживает выбор месяца
    isFireFox() {
      let userAgent = navigator.userAgent.toLowerCase();
      let firefox = /firefox/.test(userAgent);
      return firefox;
    },

    // видимость ошибки ввода времени
    isTimePeriodErrorVisible() {
      if (this.endTime != null && this.endTime != null && (this.endTime < this.startTime)) {
        return true;
      }
      return false;
    },

    // отображение полей ввода временного периода
    isTPVisible() {
      // если передана видимость временного периода, однозначно отображаем поля
      if (this.isTimePeriodVisible) {
        return true;
      } else {
        // если параметр однозначного отображения не передан в случае передачи
        // параметра отображения выбора отображения, если параметра нет однозначно не отображаем паля
        if (this.isTimePeriodButtonVisible) {
          return this.isTimePVisible;
        } else {
          return false;
        }
      }
    },

    // возвращаем название остановки, если необходимо
    selectedStationPrompt(){

      if(this.id == '0045') {
        if (this.selectedStop) {
          return ' - По остановке ' + this.selectedStop.stop_title + '(#' + this.selectedStop.stop_id + ')';
        } else {
          return ' - Не выбрана остановка';
        }
      } else {
        return '';
      }
    },

    // в зависимости от выбранных даты и времени возвращается заголовок
    getSelectedDatePrompt() {
      if (this.viewType == 0) {
        let strTime = this.startTime ? ' c ' + getTimeFormat(this.startTime) : '';
        let endTime = this.endTime ? ' по ' + getTimeFormat(this.endTime) : '';
        let selectedDate = this.selectedDate ? this.selectedDate : '';
        let selectedDatePrompt = 'По состоянию на ' + selectedDate + strTime + endTime;
        return selectedDatePrompt;
      } else {
        let strDate = this.startDate ? ' c ' + this.startDate : '';
        let endDate = this.endDate ? ' по ' + this.endDate : '';
        let strTime = (this.startTime && this.startDate != '') ? ' ' + getTimeFormat(this.startTime) : '';
        let endTime = (this.endTime && this.endDate != '') ? ' ' + getTimeFormat(this.endTime) : (this.endTime ? ' ' + getTimeFormat(this.endTime) : '');
        let selectedDatePrompt = 'За период ' + strDate + strTime + endDate + endTime;
        return selectedDatePrompt;
      }
    },

    // активна ли кнопка Отчет
    isReportBtnActive() {
      // данный параметр приходит в качестве prop в зависимости от того выбраны ли строки в таблице
      // делаем кнопку неактивной в случае, если не нажата кнопка все строки и не выбрано ни одной строки
      if (!this.isReportButtonActive) {
        return false;
        // делаем кнопку неактивной в случае, если видно ошибку ввода времени
      } else if (this.isTimePeriodErrorVisible) {
        return false;
        // если видно окно ввода временного интервала
      } else if (this.isTPVisible) {
        // делаем кнопку неактивной в случае если не введено начало или конец интервала
        if ((this.startTime == null || this.startTime == '') || (this.endTime == '' || this.endTime == null)) {
          return false;
        }
      }

      // если отчет 0045, то необходимо проверить, что выбрана остановка
      if (this.stopReports && this.stopReports.includes(this.id) && !this.selectedStop) {
        return false;
      }

      // делаем кнопку активной
      return true;
    }
  },

  methods: {

    firstInputCheckBoxChange() {
      this.firstSignatoryOccupation = '';
      this.firstSignatoryName = '';
    },
    secondInputCheckBoxChange() {
      this.secondSignatoryOccupation = '';
      this.secondSignatoryName = '';
    },

    // сброс времени
    resetTime() {
      this.startTime = null;
      this.endTime = null;
    },

    // устанавливаем параметры панели (день или период, а также превыбор значений) в зависимости от id выбранного отчета
    setViewType() {
      this.isTimePVisible = false;
      this.startTime = (this.isSingleTimeEnable == true || this.isTimePeriodVisible == true) ? (new Date().getHours() * 60 * 60) + (new Date().getMinutes() * 60) + (new Date().getSeconds()) : null;
      this.endTime = (this.isTimePeriodVisible || (this.isTimePeriodButtonVisible && this.isTimePVisible)) ? 97200 : null;
      if (['0020', '0021', '0019', '0016', '0022', '0006', '0010', '0011',
        '0012', '0013', '0007', '0014', '0008', '0009', '0018', '0015', '0023',
        '0029', '0030', '0031', '0032', '0033', '0034', '0035', '0039', '0040', '0037', '0038', '0039', '0040', '0041', '0045'].includes(this.id)) {
        this.viewType = 1
        this.selectedRadioPeriod = 'month'
        this.selectedRadioDay = 'userDate'
        this.setMonth();
      } else if (['0005', '0017', '0027', '0024', '0026', '0028', '0025', '0036', '0042', '0043', '0044'].includes(this.id)) {
        this.viewType = 0;
        this.selectedRadioDay = 'yesterday'
        this.selectedRadioPeriod = 'period'
        this.setYesterday();
      }
    },

    // задаем выбранную неделю (нажата галочка "Неделя" или выбрана новая дата в селекторе даты)
    setWeek() {
      // определим день недели, чтобы понять "в какой мы неделе"
      let dayOfWeek = new Date(this.selectedDate).getDay();
      // для упрощения расчета если выбрано Вс. = 0, то делаем dayOfWeek = 7
      if (dayOfWeek == 0)
        dayOfWeek = 7
      // определяем границы недели
      this.startDate = dateToString(ndToDate(dateToND(new Date(this.selectedDate)) - (dayOfWeek - 1)));
      this.endDate = dateToString(ndToDate(dateToND(new Date(this.selectedDate)) + (7 - dayOfWeek)));
    },

    // задаем выбранные две недели (нажата галочка "Две недели" или выбрана новая дата в селекторе даты)
    setTwoWeek() {
      // определим день недели, чтобы понять "в какой мы неделе"
      let dayOfWeek = new Date(this.selectedDate).getDay();
      // для упрощения расчета если выбрано Вс. = 0, то делаем dayOfWeek = 7
      if (dayOfWeek == 0)
        dayOfWeek = 7
      // определяем границы недели
      this.startDate = dateToString(ndToDate(dateToND(new Date(this.selectedDate)) - (dayOfWeek - 1)));
      this.endDate = dateToString(ndToDate(dateToND(new Date(this.selectedDate)) + (7 - dayOfWeek) + 7));
    },

    // задаем выбранный месяц (нажата галочка "Месяц" или выбрана новая дата в селекторе даты)
    setMonth() {
      // прежде всего необходимо определеить кол-во дней в месяце
      // определяем год
      let year = new Date(this.selectedDate).getFullYear();
      // определяем месяц
      let month = new Date(this.selectedDate).getMonth();
      // сетаем границы
      this.startDate = dateToString(new Date(year, month, 1));
      this.endDate = dateToString(new Date(year, month + 1, 0));
    },

    // задаем выбранный квартал (нажата галочка "Квартал" или выбрана новая дата в селекторе даты)
    changeQuarters() {
      let quarterBorders = quartals(new Date(this.selectedDate));
      console.log('quarterBorders', quarterBorders)
      console.log('this.selectedDate',this.selectedDate)
      let year = new Date(this.selectedDate).getFullYear();
      // сетаем границы
      this.startDate = dateToString(new Date(year, quarterBorders.start.month, quarterBorders.start.date, 3));
      this.endDate = dateToString(new Date(year, quarterBorders.end.month, quarterBorders.end.date, 3));
    },

    // задаем границы квартала
    setQuarters(quarterBorders) {
      // сетаем границы
      this.startDate = dateToString(ndToDate(quarterBorders.start));
      this.endDate = dateToString(ndToDate(quarterBorders.end));
    },

    // задаем период дат
    setDatePeriod() {
      // сетаем границы, если их нет
      if (this.startDate == '') {
        this.startDate = dateToString(new Date());
      }
      if (this.endDate == '') {
        this.endDate = dateToString(new Date());
      }
    },

    // сменился тип отображения верхней панели
    typeChange() {
      if (this.viewType == 1) {
        this.selectedDate = dateToString(ndToDate(dateToND() - 1));
        // ещё не был выбран радиоботон
        if (this.selectedRadioPeriod == '') {
          this.selectedRadioPeriod = 'month'
          this.setMonth();
        }
      } else {
        this.selectedDate = dateToString(ndToDate(dateToND() - 1));
        if (this.selectedRadioDay == '') {
          this.selectedRadioDay = 'userDate'
          this.setCustomDate();
        }
      }
    },

    tableTypeChange() {
      this.$emit('tableChange', this.tableType);
    },

    // сетаем выбранную дату сегодня
    setToday() {
      this.selectedDate = dateToString();
    },

    // сетаем выбранную дату сегодня
    setYesterday() {
      // dateToND - получаем количество дней с 01.01.2000, отнимаем одни день;
      // ndToDate - конвертируем количество дней в дату;
      // dateToString - конвертируем дату в строку:
      this.selectedDate = dateToString(ndToDate(dateToND() - 1));
    },

    // сетаем выбранную дату сегодня
    setDayBeforeYesterday() {
      // dateToND - получаем количество дней с 01.01.2000, отнимаем одни день;
      // ndToDate - конвертируем количество дней в дату;
      // dateToString - конвертируем дату в строку:
      this.selectedDate = dateToString(ndToDate(dateToND() - 2));
    },

    // при клике на "Пользовательский период"
    setCustomDate() {
      this.selectedDate = dateToString();
    },

    // если для отчета нужен только срез по определенному времени
    setTimeSlice() {
      if (this.isTimeSlice == true) {
        this.endTime = null;
      }
    },

    // нажата кнопка "Отчет"
    reportButtonClick() {
      let payload = {};
      // в зависимости от отчета формируем набор парметров, которые нужно передать на сервер при запросе отчета
      // в случае, если режим отображения "День" добавляем выбранную дату
      if (this.viewType == 0) {
        payload = {dateIndex: this.selectedDate,};
      } else {
        // в случае, если режим отображения "Период" добавляем интервал дат
        payload = {date_index_begin: this.startDate, date_index_end: this.endDate};
      }
      // добавляем временной период, в случае, если отчет нужно добавить временной период
      if (this.isTimePeriodButtonVisible || this.isTimePeriodVisible) {
        payload['time_index_begin'] = this.startTime;
        payload['time_index_end'] = this.endTime;
      }
      // добавляем временной срез, в случае, если отчет нужно добавить временной срез
      if (this.isSingleTimeEnable) {
        payload['time_index'] = this.startTime;
      }
      // если есть Подписант 1
      if (this.isFirstSignatoryActive == true) {
        payload['signer_enabled'] = this.isFirstSignatoryActive;
        payload['signer_name'] = this.firstSignatoryName;
        payload['signer_occupation'] = this.firstSignatoryOccupation;
      }
      // если есть Подписант 2
      if (this.isSecondSignatoryActive == true) {
        payload['signer_enabled2'] = this.isSecondSignatoryActive;
        payload['signer_name2'] = this.secondSignatoryName;
        payload['signer_occupation2'] = this.secondSignatoryOccupation;
      }

      // если необходимо добавить поле stop_id
      if(this.stopReports.includes(this.id)) {
        payload['stop_id'] = this.$store.getters['settings/getSelectedStop'].stop_id;
      }

      // эмитим собранный набор параметров
      this.$emit('onGetReportClick', payload);
    }
  },

  watch: {

    // следим за изменением id выбранного отчета (при выборе другого отчета его id передается в router)
    id() {
      this.isFirstSignatoryActive = false;
      this.firstSignatoryName = '';
      this.firstSignatoryOccupation = '';
      this.isSecondSignatoryActive = false;
      this.secondSignatoryName = '';
      this.secondSignatoryOccupation = '';
      // сетаем выбранной датой вчера
      this.selectedDate = dateToString(ndToDate(dateToND() - 1));
      // если одно поля ввода времени
      if (this.isSingleTimeEnable) {
        this.startTime = '';
        this.endTime = '';
      } else {
        this.startTime = (this.isTimePeriodVisible || this.isTimePeriodButtonVisible) ? 72000 : (new Date().getHours() * 60 * 60) + (new Date().getMinutes() * 60) + (new Date().getSeconds());
        this.endTime = (this.isTimePeriodVisible || this.isTimePeriodButtonVisible) ? 97200 : null;
      }
      // устанавливаем параметры панели (день или период, а также превыбор значений) в зависимости от id выбранного отчета
      this.setViewType();
    }
  }
  ,

  created() {
    // сетаем выбранной датой вчера
    this.selectedDate = dateToString(ndToDate(dateToND() - 1));
    // устанавливаем параметры панели (день или период, а также превыбор значений) в зависимости от id выбранного отчета
    this.setViewType();
  }

}
</script>

<style scoped>

.parent {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.mainCard {
  min-height: 200px !important;
  height: 200px !important;
}

/*@media (max-width: 600px) {*/
/*  .parent {*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/
/*  }*/
/*}*/

/*@media (max-width: 1416px) {*/
@media (max-width: 1524px) {
  .parent {
    flex-direction: column;
    align-items: flex-start;
  }

  .mainCard {
    min-height: 272px !important;
  }
}


</style>