<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Праздничный день</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>
                <div class="row">
                  <div class="form-group col">
                    <label for="holidayId">Код:</label>
                    <input-integer
                        id="holidayId"
                        class="form-control w-25"
                        v-model="holidayId"
                        readonly
                    />
                  </div>

                </div>

                <div class="form-group">
                  <label for="dateIndex">Дата:</label>
                  <req-field/>
                  <input-date
                      id="dateIndex"
                      v-model="dateIndex"
                      class="form-control"
                      ref="dateIndex"
                      autocomplete="off"
                      :readonly="op === 'show'"
                      required
                      error="Введите дату"
                  />
                </div>

                <div class="form-group">
                  <label for="routeId">Маршрут:</label>
                  <routes-select
                      id="routeId"
                      v-model="routeId"
                      class="form-control"
                      ref="routeId"
                      autocomplete="off"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="routeGroupId">Группа маршрутов:</label>
                  <route-groups-select
                      id="routeGroupId"
                      v-model="routeGroupId"
                      class="form-control"
                      ref="routeGroupId"
                      autocomplete="off"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="weekDayNum">День недели:</label>
                  <req-field/>
                  <input-select
                      id="weekDayNum"
                      v-model="weekDayNum"
                      class="form-control"
                      ref="weekDayNum"
                      autocomplete="off"
                      :readonly="op === 'show'"
                      :itemList="week"
                      required
                      caption="Выберите день недели..."
                      error="Выберите день недели"
                  />
                </div>

                <div class="form-group">
                  <label for="note">Примечание:</label>
                  <input-string
                      id="note"
                      v-model="note"
                      class="form-control"
                      ref="note"
                      autocomplete="off"
                      :readonly="op === 'show'"
                  />
                </div>

              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputDate from "@/components/ui/custom/InputDate";
import RoutesSelect from "@/components/multipass/routes/RoutesSelect";
import RouteGroupsSelect from "@/components/multipass/route_groups/RouteGroupsSelect";
import {dateToND, secondsToDate, stringToDate} from "@/lib";

export default {
  components: {RouteGroupsSelect, RoutesSelect, InputDate},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      holidayId: this.item.holiday_id,
      dateIndex: secondsToDate(this.item.date_index * 24 * 60 * 60),
      routeId: this.item.route_id,
      routeGroupId: this.item.route_group_id,
      weekDayNum: this.item.week_day_num,
      note: this.item.note,

      week: [
        {
          id: 1,
          title: "Понедельник",
        },
        {
          id: 2,
          title: "Вторник",
        },
        {
          id: 3,
          title: "Среда",
        },
        {
          id: 4,
          title: "Четверг",
        },
        {
          id: 5,
          title: "Пятница",
        },
        {
          id: 6,
          title: "Суббота",
        },
        {
          id: 7,
          title: "Воскресенье",
        },
      ]
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {

        this.$emit('onOK', {
          holiday_id: this.holidayId,
          date_index: dateToND(stringToDate(this.dateIndex)),
          route_id: this.routeId,
          route_group_id: this.routeGroupId,
          week_day_num: this.weekDayNum,
          note: this.note,
        })
      }
    },
  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>