<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Параметры "{{ eventName }}"</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="transportationKindId">Вид перевозок:</label>
              <transportation-kinds-select
                  id="transportationKindId"
                  v-model="transportationKindId"
                  class="form-control"
                  ref="transportationKindId"
                  :readonly="op === 'show'"
              />
            </div>
            <div class="row">
              <div class="form-group col">
                <label for="eventLength">Пробег (км.):</label>
                <req-field/>
                <input-length
                    id="eventLength"
                    class="form-control"
                    ref="eventLength"
                    v-model="eventLength"
                    autocomplete="off"
                    required
                    error="Введите пробег"
                    :readonly="op === 'show'"
                />
              </div>
              <div class="form-group col">
                <label for="eventDuration">Время (мин.):</label>
                <req-field/>
                <input-minute
                    id="eventDuration"
                    class="form-control"
                    ref="eventDuration"
                    v-model="eventDuration"
                    required
                    error="Введите время"
                    :readonly="op === 'show'"
                />
              </div>
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
// Диалог для типов ивентов 5
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import {getScheduleEvent} from "@/store/RmtHelpers";
import TransportationKindsSelect from "@/components/rmt/transportation_kinds/TransportationKindsSelect";

export default {
  components: {TransportationKindsSelect},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      eventDuration: this.item.event_duration,
      eventLength: this.item.event_length,
      transportationKindId: this.item.transportation_kind_id,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          ...this.item,
          event_duration: this.eventDuration,
          event_length: this.eventLength,
          transportation_kind_id: this.transportationKindId,
        })
      }
    },
  },
  computed: {
    // наименование события для заголовка
    eventName() {
      return getScheduleEvent(this.item.schedule_event_id).long_name;
    },
  }
}
</script>

<style scoped>
form label {
  font-weight: bold;
}

</style>