<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Режим работы водителя</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="row">

              <div class="form-group col">
                <label for="regimeId">Код:</label>
                <input-integer
                    id="regimeId"
                    class="form-control w-25"
                    v-model="regimeId"
                    readonly
                />
              </div>
            </div>

            <div class="form-group">
              <label for="longName">Полное наименование:</label>
              <req-field/>
              <input-string
                  id="longName"
                  v-model="longName"
                  class="form-control"
                  ref="longName"
                  autocomplete="off"
                  :readonly="op === 'show'"
                  required
                  error="Введите полное наименование"
              />
            </div>

            <div class="form-group">
              <label for="shortName">Краткое наименование:</label>
              <req-field/>
              <input-string
                  id="shortName"
                  v-model="shortName"
                  class="form-control"
                  ref="shortName"
                  autocomplete="off"
                  :readonly="op === 'show'"
                  required
                  error="Введите краткое наименование"
              />
            </div>

            <div class="form-group">
              <label for="prefix">Префикс номера графика:</label>
              <input-integer
                  id="prefix"
                  v-model="prefix"
                  class="form-control w-25"
                  :min-value="0"
                  ref="prefix"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  components: {},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      regimeId: this.item.regime_id,
      shortName: this.item.short_name,
      longName: this.item.long_name,
      prefix: this.item.prefix
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          regime_id: this.regimeId,
          short_name: this.shortName,
          long_name: this.longName,
          prefix: this.prefix
        })
      }
    },
  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>