import {DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ЗОН ОСТАНОВОЧНЫХ ПУНКТОВ

export default {
    actions: {

        // запрос объекта
        doFetchStopZone: (context, payload) => {
            // выполняем запрос
            return DoGet(
                '/multipass/stopZones?stop_id='+payload.stop_id,
                null,
                {},
                'Запрос зоны остановочного пункта',
                false,
                true,
                null,
                null
            )
        },

        // запрос объекта
        doFetchStopZones: (context, payload) => {
            // выполняем запрос
            return DoPost(
                '/multipass/stopZones',
                payload,
                {},
                'Запрос зон остановочных пунктов',
                false,
            )
        },

        // изменение объекта
        doEditStopZone: (context, payload) => {
            return DoPut(
                '/multipass/stopZones/'+payload.stop_id,
                payload.zone,
                null,
                'Установка зоны остановочного пункта'
            )
        },
    }
}