import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ НАБОРОВ ГРАФИКОВ

export default {
    actions: {
        // запрос набора графиков
        doFetchScheduleGraphSet: (context, payload) => {
            return DoGet(
                '/rmt/scheduleGraphSets/'+payload.schedule_graph_set_id,
                null,
                [],
                'Запрос набора графиков',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchScheduleGraphSets: (context, payload) => {
            return DoGet(
                '/rmt/scheduleGraphSets?schedule_variant_id='+payload.schedule_variant_id,
                null,
                [],
                'Запрос наборов графиков',
                false,
                true,
            )
        },

        // запрос списка всех объектов
        doFetchAllScheduleGraphSets: () => {
            return DoGet(
                '/rmt/scheduleGraphSets',
                null,
                [],
                'Запрос наборов графиков',
                false,
                true,
            )
        },

        // добавление объекта
        doAddScheduleGraphSet: (context, payload) => {
            return DoPost(
                '/rmt/scheduleGraphSets',
                payload,
                null,
                'Добавление набора графиков'
            )
        },

        // изменение объекта
        doEditScheduleGraphSet: (context, payload) => {
            return DoPut(
                '/rmt/scheduleGraphSets/'+payload.schedule_graph_set_id,
                payload,
                null,
                'Изменение набора графиков'
            )
        },

        // отмечаем набор графиков
        doMarkScheduleGraphSet: (context, payload) => {
            return DoPut(
                '/rmt/markScheduleGraphSet/'+payload.schedule_graph_set_id,
                null,
                null,
                'Отметка набора графиков'
            )
        },

        // отмечаем набор графиков
        doUnmarkScheduleGraphSet: (context, payload) => {
            return DoPut(
                '/rmt/unmarkScheduleGraphSet/'+payload.schedule_graph_set_id,
                null,
                null,
                'Снятие отметки с набора графиков'
            )
        },

        // удаление объекта
        doDelScheduleGraphSet: (context, payload) => {
            return DoDelete(
                '/rmt/scheduleGraphSets/'+payload.schedule_graph_set_id,
                null,
                'Удаление набора графиков'
            )
        },

        // копирование объекта
        doCopyScheduleGraphSet: (context, payload) => {
            return DoPost(
                '/rmt/copyScheduleGraphSet/'+payload.schedule_graph_set_id,
                payload,
                null,
                'Копирование набора графиков'
            )
        }

    }
}
