import {DoDelete, DoGet, DoGetFile, DoPostFile} from "@/store/Actions";

// ХРАНИЛИЩЕ ФАЙЛОВ ДОКУМЕНТОВ КОНТРАКТА

export default {
    actions: {
        // запрос объекта
        doFetchContractDocFile: (context, payload) => {
            return DoGetFile(
                '/kvr/contractDocFiles/' + payload.contract_doc_id + "/" + payload.document_file_id,
                null,
                'Загрузка файла',
                true,
                payload.file_name,
            )
        },

        // запрос списка объектов
        doFetchContractDocFiles: (context, payload) => {
            return DoGet(
                '/kvr/contractDocFiles/' + payload.contract_doc_id,
                null,
                [],
                'Запрос файлов документа контракта',
                false,
                true,
            )
        },

        // добавление объекта
        doAddContractDocFile: (context, payload) => {
            return DoPostFile(
                '/kvr/contractDocFiles/' + payload.contract_doc_id,
                payload.file.file,
                null,
                'Добавление файла документа контракта'
            )
        },

        // удаление объекта
        doDelContractDocFile: (context, payload) => {
            return DoDelete(
                '/kvr/contractDocFiles/'+ payload.contract_doc_id + "/" + payload.document_file_id,
                null,
                'Удаление файла документа контракта'
            )
        }
    }
}