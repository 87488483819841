<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" :disableSettings="true"
                    :rowsPerPage='64'  name="tbl-cell-definitions" ></edit-table-panel>
</template>

<script>

import EditTablePanel from "@/components/ui/custom/EditTablePanel";
export default {
  components: {EditTablePanel},
  props: ['isLoading', 'rowList', 'columnList'],
  emits:['onSaveStation'],
};
</script>
