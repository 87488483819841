<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-transport-classes"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
export default {
  components: {EditTablePanel},
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "transport_class_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "desc",
          caption: "Описание",
          sortType: 'string',
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') return [];

      return this.$store.getters['ref/getTransportClasses'].map(cls => {
        cls.__id = cls.transport_class_id;

        return cls;
      });
    },
  },
};
</script>
