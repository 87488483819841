<template>

  <accordion mode="single">

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group3">
          Платежные транзакции
        </button-target>
      </card-header>
    </card>
    <collapse ref="group3">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/asmpp/asopDepots"
          >Список предприятий
          </left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/asmpp/asopRoutes"
          >Список маршрутов
          </left-menu-item>
        </li>
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group1">
          Пассажиропоток по маршруту
        </button-target>
      </card-header>
    </card>
    <collapse ref="group1">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/asmpp/routes"
          >Список маршрутов
          </left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/asmpp/factTripCardExtsAggregatedNetwork"
          >Остановочный пассажиропоток
          </left-menu-item>
        </li>
        <!--        <li>-->
        <!--          <left-menu-item-->
        <!--              icon-class="fas fa-arrow-alt-circle-right"-->
        <!--              to="/asmpp/params"-->
        <!--          >Параметры по периодам суток-->
        <!--          </left-menu-item>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <left-menu-item-->
        <!--              icon-class="fas fa-arrow-alt-circle-right"-->
        <!--              to="/asmpp/stop"-->
        <!--          >Остановочные пункты-->
        <!--          </left-menu-item>-->
        <!--        </li>-->
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group2">
          Пассажиропоток по предприятию
        </button-target>
      </card-header>
    </card>
    <collapse ref="group2">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/asmpp/depots"
          >Список предприятий
          </left-menu-item>
        </li>
        <!--        <li>-->
        <!--          <left-menu-item-->
        <!--              icon-class="fas fa-arrow-alt-circle-right"-->
        <!--              to="/asmpp/routes"-->
        <!--          >Список маршрутов-->
        <!--          </left-menu-item>-->
        <!--        </li>-->
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group4">
          Дополнительные
        </button-target>
      </card-header>
    </card>
    <collapse ref="group4">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/asmpp/balanceTypes"
          >Типы балансировки
          </left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/asmpp/tripTypeComposites"
          >Композитные типы рейсов
          </left-menu-item>
        </li>
      </ul>
    </collapse>

  </accordion>
</template>
