<template>
  <input-select ref="select" v-model="value" caption="Выберите ТС..." :itemList="items"></input-select>
</template>

<script>
export default {
  props: ['model-value', 'depot_id'],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      let arr = [];
      this.$store.getters['kvr/getOrderVehicles'].map(item => {
        if (this.depot_id == item.depot_id) {
          arr.push( {
            id: item.transport_id,
            title: item.garage_num + '/' + item.state_num,
          });
        }
      });
      return arr;
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
