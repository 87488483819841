<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-depots"></edit-table-panel>
</template>

<script>
import {getLegalForm, getOrganization} from "@/store/RefHelpers";

export default {
  props: ['isLoading', 'depotGroupId'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "depot_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__orgName",
          caption: "Организация",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__legalFormName",
          caption: "Организационно-правовая форма",
          sortType: 'string',
          show: false
        },
        {
          field: "time_zone",
          caption: "Часовой пояс (мин)",
          sortType: 'number',
          show: false
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      let depots;
      const links = this.$store.getters["ref/getDepotGroupLinks"];
      if (this.depotGroupId) {
        depots = this.$store.getters["ref/getDepots"].filter(depot => {
          return links.some(link => link.depot_id == depot.depot_id && link.depot_group_id == this.depotGroupId)
        });
      } else {
        depots = this.$store.getters["ref/getDepots"];
      }

      let result = depots.map(depot => {
        const legalForm = getLegalForm(depot.legal_form_id);
        const org = getOrganization(depot.org_id);

        depot.__id = depot.depot_id;
        depot.__legalFormName = legalForm && legalForm.long_name || '';
        depot.__orgName = org && org.long_name || '';

        return depot
      });

      if (this.$router.currentRoute._value.fullPath.substring(0, 6) === '/asmpp') {
        let selectedDepotId = this.$store.getters['asmpp/getAsopSelectedDepotId'];
        if (selectedDepotId) {
          let selectedRow = result.find(depot => depot.depot_id == selectedDepotId);
          this.$emit('onRowSelect', selectedRow)
        }
      }

      return result;
    },
  },
};
</script>
