<template>
    <li class="breadcrumb-item" :class="{active: isActive}">
      <slot></slot>
    </li>
</template>

<script>
import ActiveMixin from "@/components/ui/bootstrap/mixins/ActiveMixin";

export default {
    mixins: [ActiveMixin]
}
</script>