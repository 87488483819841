import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ТЕКУЩЕГО МЕСТОПОЛОЖЕНИЯ ТРАНСПОРТНЫХ СРЕДСТВ

export default {

    actions: {

        // запрос списка объектов
        doFetchCurNav: (context, payload) => {

            let url = '/nav/getCurNav'
            let query = ''
            if (payload.transport_type_id)
                query = query + '&transport_type_id=' + payload.transport_type_id
            if (payload.depot_id)
                query = query + '&depot_id=' + payload.depot_id
            if (payload.max_delta)
                query = query + '&max_delta=' + payload.max_delta

            if (query !== '') {
                url = url + '?' + query.substr(1)
            }

            return DoGet(
                url,
                null,
                [],
                'Запрос текущего местоположения транспортных средств',
                false,
                payload && payload.force,
            )
        },
    }
}
