<template>
  <div class="input-group">
    <input
        type="text"
        v-bind="$attrs"
        class="form-control"
        :class="{ 'is-invalid': isInvalid && !readonly && !disabled }"
        :placeholder="caption"
        :readonly="readonly"
        :disabled="disabled"
        ref="inputRef"
        autocomplete="off"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
    >
    <div
        class="dropdown-menu show"
        style=" min-width: 100%; max-height: 400px"
        :style="menuStyle"
        v-if="isMenuVisible"
    >
      <a
          v-for="item in items"
          :key="item.id"
          @mousedown="onMouseDown(item, $event)"
          class="dropdown-item"
          style="cursor: pointer"
      >{{ item.title }}</a>
    </div>
    <div class="invalid-feedback" :style="error == '' ? 'margin: 0' : ''">{{ error }}</div>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,

  emits: ['update:model-value', 'change'],

  props: {
    'model-value': {
      required: true
    },
    // массив элементов [{id: any, title: string, value: string}]
    itemList: {
      type: Array,
      required: true
    },
    // заголовок
    caption: {
      type: String,
      required: true
    },
    // максимальное количество выдаваемых данных в списке
    maxItemCount: {
      type: Number,
    },
    // максимальная высота выпадающего списка
    maxHeight: {
      type: String,
      default: null
    },
    // параметр
    // максимальная высота выпадающего списка
    isOverflowActive: {
      type: Boolean,
      default: false
    },
    // требуется ввод значения
    'required': {
      type: Boolean,
      default: false
    },
    // только для чтения
    'readonly': {
      type: Boolean,
      default: false
    },
    // отключен
    'disabled': {
      type: Boolean,
      default: false,
    },
    // ошибка
    'error': {
      type: String,
      default: 'Выберите значение'
    }
  },

  data() {
    return {
      // признак ошибки
      isInvalid: false,
      // текущее текстовое значение
      text: '',
      // признак фокуса
      isFocused: false,
      // флаг, что итемы получены, а текстовое поле обновлено
      isItemsModified:0,
    }
  },

  computed: {

    // отображать меню
    isMenuVisible() {
      return this.isFocused && this.items.length > 0 && !this.readonly && !this.disabled
    },

    // стиль меню
    menuStyle() {
      if (this.maxHeight) return {
        'max-height': this.maxHeight,
        'overflow-y': this.isOverflowActive ? 'auto' : 'hidden',
      }
      else return {
        'overflow-y': this.isOverflowActive ? 'auto' : 'hidden',
      }
    },

    // список
    items() {
      // маленькие буквы
      const text = this.text.toLowerCase().trim()
      // формируем список
      const newItems = []
      for (let i = 0; i < this.itemList.length; i++) {
        if (newItems.length >= this.maxItemCount) break;

        if (text === '' || this.itemList[i].title.toLowerCase().includes(text)) {
          newItems.push({
            ...this.itemList[i]
          })
        }
      }
      // возвращаем результат
      return newItems
    }
  },

  methods: {

    // проверка валидности
    isValid() {
      // проверяем разрешены ли пустые значения
      if (this.modelValue == null) {
        return !this.required
      }
      // все остальное можно
      return true;
    },

    // вызывается при вводе значения
    onInput() {
      // запрашиваем текущее значение
      const text = this.$refs.inputRef.value.trim();
      // запоминаем текущее значение
      this.text = text
      // пустое значение - это null
      if (text === '') {
        this.$emit('update:model-value', null);
        this.$emit('change')
      }
    },

    // вызывается при получении фокуса
    onFocus() {
      // сбрасываем ошибку
      this.isInvalid = false;
      // устанавливаем фокус
      this.isFocused = true;
    },

    // вызывается при нажатии клавиши
    onMouseDown(item, e) {
      // засылаем новое значение
      if (e.button === 0) {
        this.$emit('update:model-value', item.id);
        // запускаем ивент change только если значение поменялось
        if(item.id != this.modelValue) {
          this.$emit('change')
        }
      }
    },

    // вызывается при потере фокуса
    onBlur() {
      // восстанавливаем текстовое значение
      this.updateText(this.modelValue)
      // сбрасываем фокус
      this.isFocused = false;
      // проверяем значение
      this.validate();
    },

    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },

    // обновляем текстовое поле
    updateText(value) {
      if (value === null) {
        this.text = ''
        this.$refs.inputRef.value = '';
        return
      }
      const item = this.itemList.find(item => item.id == value)
      if (item) {
        this.$refs.inputRef.value = item.value;
        this.text = item.title;
      } else {
        this.text = ''
        this.$refs.inputRef.value = '';
      }
    }
  },

  mounted() {
    // следим за изменением модели
    this.$watch(() => this.modelValue, (value) => {

      // пришло непонятно чего
      if (typeof (value) !== 'string' && typeof (value) !== 'number' && typeof (value) !== 'boolean' && value !== null) {
        this.$emit('update:model-value', null);
        this.$emit('change')
        return
      }
      // обновляем текстовое поле
      this.updateText(value)

    }, {immediate: true})

    // следим за изменением списка итемов
    // только в первый раз, когда список итомов изменяется
    // (они подгружаются), делаем значение выбранным
    // т.е. значение пришло заранее, а итемов ещё нет,
    // соотв. нечего сделать выбранным.
    this.$watch(() => this.items, () => {
      // если итемы ещё не модифицировались, но уже пришли, пытаемся засетать сохраненное значение
      if (this.isItemsModified == 0 && this.items.length !=0) {
        // обновляем текстовое поле
        this.updateText(this.modelValue);
        // запрещаем дальнейшие модификации итемов
        this.isItemsModified = 1;
      }
    }, {immediate: true})

  },
}
</script>
