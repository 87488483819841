<template>
  <input-select ref="select" v-model="value" caption="Выберите тип связанной сущности..."
                :itemList="items"></input-select>
</template>

<script>
export default {
  props: ['model-value'],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      let result = [];
      this.$store.getters['integration/getEntityTypes'].map(item => {
        if (item.entity_type_id == 101001) {
          result.push(
              {
                id: item.entity_type_id,
                title: item.entity_type_title
              }
          )
        }
      });
      return result;
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
