<template>
  <card>
    <card-body class="p-1 d-flex justify-content-center">
      <div class="btn-toolbar">
        <div class="btn-group mr-2" role="group" aria-label="First group">
          <button type="button" class="btn btn-sm btn-secondary" :disabled="curPage <= 1" @click="onFirstPage">
            <i class="fas fa-fast-backward"></i>
          </button>
          <button type="button" class="btn btn-sm btn-secondary" :disabled="curPage <= 1" @click="onPrevPage">
            <i class="fas fa-step-backward"></i>
          </button>
        </div>

        <div class="input-group mr-2">
          <div class="input-group-prepend">
            <div class="input-group-text">Страница №</div>
          </div>
          <select
            style="background-color: #e9ecef"
            class="form-control"
            v-model="value"
            :disabled="pageCount === 0"
          >
            <option
                v-for="(num, index) in pageCount"
                :key="index"
                :value="num"
            >{{ num }}</option>
          </select>
          <div class="input-group-append">
            <div class="input-group-text">из {{pageCount}}</div>
          </div>
        </div>

        <div class="btn-group">
          <button type="button" class="btn btn-sm btn-secondary" :disabled="curPage >= pageCount" @click="onNextPage">
            <i class="fas fa-step-forward"></i>
          </button>
          <button type="button" class="btn btn-sm btn-secondary" :disabled="curPage >= pageCount" @click="onLastPage">
            <i class="fas fa-fast-forward"></i>
          </button>
        </div>
      </div>
    </card-body>
  </card>
</template>

<script>
export default {

  emits: [
    // вызывается при изменении номера страницы
    'onPageChanged',
  ],

  props: {
    // количество страниц
    pageCount: {
      type: Number,
      required: true
    },
    // номер текущей страницы
    curPage: {
      type: Number,
      required: true
    }
  },

  computed: {
    value: {
      get() {
        return this.curPage
      },
      set(value) {
        this.$emit('onPageChanged', value)
      }
    },
  },

  methods: {
    // переход на первую страницу
    onFirstPage() {
      if (this.curPage > 1) this.$emit('onPageChanged', 1);
    },

    // переход на предыдущую страницу
    onPrevPage() {
      if (this.curPage > 1) this.$emit('onPageChanged', this.curPage - 1);
    },

    // переход на следующую страницу
    onNextPage() {
      if (this.curPage < this.pageCount) this.$emit('onPageChanged', this.curPage + 1);
    },

    // переход на последнюю страницу
    onLastPage() {
      if (this.curPage < this.pageCount) this.$emit('onPageChanged', this.pageCount);
    }
  },
}
</script>
