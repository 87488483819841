<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-group-depot-links"> </edit-table-panel>
</template>

<script>
import {
  getOrganization,
} from "@/store/RefHelpers";

export default {
  props: ['id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__checked",
          caption: "Вкл",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.__checked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "depot_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
          show: false
        },
        {
          field: "__orgName",
          caption: "Организация",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      const depots = this.$store.getters["ref/getDepots"];
      const links = this.$store.getters["ref/getDepotGroupLinks"];
      return depots.map(depot => {

        const org = getOrganization(depot.org_id);

        depot.__id = depot.depot_id;
        depot.__orgName = org && org.long_name || '';
        depot.__checked = links.some(link => link.depot_group_id == this.id && link.depot_id == depot.depot_id);

        return depot;
      });
    },
  },
};
</script>
