import {DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ЛИНИЙ ТИПОВ РЕЙСОВ

export default {
    actions: {
        // запрос объекта
        doFetchTripTypeLine: (context, payload) => {
            return DoGet(
                '/multipass/tripTypeLines?trip_type_id='+payload.trip_type_id,
                null,
                {},
                'Запрос линии типа рейса',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchTripTypeLines: (context, payload) => {
            return DoPost(
                '/multipass/tripTypeLines',
                payload,
                null,
                'Запрос линиий типов рейсов',
                false,
            )
        },

        // сохранить линии типа рейса
        doSaveTripTypeLine: (context, payload) => {
            return DoPut(
                '/multipass/tripTypeLines/'+payload.trip_type_id,
                payload.polyline,
                null,
                'Установка линии типа рейса'
            )
        },
    }
}
