import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПНЫХ ДЛЯ УПРАВЛЕНИЯ КОНДУТОРОВ


export default {
    state() {
        return {
            // список объектов
            manageConductors: [],
            // время обновления кэша new Date().getTime()
            manageConductorsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            manageConductorsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getManageConductors(state) {
            return state.manageConductors;
        },

    },
    mutations: {
        // установить новый список объектов
        setManageConductors(state, payload) {
            state.manageConductors = payload;
            state.manageConductorsCache = new Date().getTime();
        },
    },
    actions: {
        // запрос списка объектов
        doFetchManageConductors: (context, payload) => {
            return DoGet(
                '/trn/getManageConductors/' + payload.date_index,
                'trn/setManageConductors',
                [],
                'Запрос списка доступных для управления кондукторов',
                false,
                payload && payload.force,
                context.state.manageConductorsCache,
                context.state.manageConductorsCacheTimeout
            )
        },

        // очищает кэш
        doClearManageConductors: (context, payload) => {
            return DoClearCache(
                'trn/setManageConductors',
                [],
                payload && payload.force,
                context.state.manageConductorsCache,
                context.state.manageConductorsCacheTimeout
            )
        },
    }
}