
// ХРАНИЛИЩЕ ОТЧЕТОВ

/*
    interface REPORT {
        uuid: string;
        user_time: string;
        title: string;
        module: string;
    }
*/

import store from "@/store/Store";
import {DoGetFile, DoGetFileForShowing} from "@/store/Actions";
import {getReport} from "@/store/SystemHelpers";
import {getAllowedModules} from "@/store/AuthHelpers";

export default {
    state() {
        return {
            // список объектов
            reports: [],
        }
    },
    getters: {
        // вернуть список объектов
        getReports(state) {
            return state.reports;
        },
        getRefReports(state) {
            return state.reports.filter(value =>{
                return value.module == 'ref'
            });
        },
        getMultipassReports(state) {
            return state.reports.filter(value =>{
                return value.module == 'multipass'
            });
        },
        getTrnReports(state) {
            return state.reports.filter(value =>{
                return value.module == 'trn'
            });
        },
        getNavReports(state) {
            return state.reports.filter(value =>{
                return value.module == 'nav'
            });
        },
        getRmtReports(state) {
            return state.reports.filter(value =>{
                return value.module == 'rmt'
            });
        },
        getKvrReports(state) {
            return state.reports.filter(value =>{
                return (value.module == 'kvr' || value.module == 'kvr-expert')
            });
        },
        getAsmppReports(state) {
            return state.reports.filter(value =>{
                return value.module == 'asmpp'
            });
        },
        getAsipReports(state) {
            return state.reports.filter(value =>{
                return value.module == 'asip'
            });
        },
        getPriorityReports(state) {
            return state.reports.filter(value =>{
                return value.module == 'priority'
            });
        },
        getAdminReports(state) {
            return state.reports.filter(value =>{
                return value.module == 'admin'
            });
        },

    },
    mutations: {
        // добавить объект
        async addReport(state, payload) {
            if(state.reports.length == 0) {
                payload.order_id = 0;
            } else {
                payload.order_id = state.reports[state.reports.length-1].order_id + 1;
            }
            payload.is_temp = true;
            payload.title = getReport(payload.report_id);
            payload.module = getAllowedModules(getReport(payload.report_id).module_id).module_name;
            state.reports.push(payload);
            store.commit('showReports')
        },

        async editReport(state, payload) {
            let index = state.reports.findIndex(report => report.order_id == payload.order_id);
            if (index >= 0) {
                payload.is_temp = false;
                payload.title = getReport(payload.report_id);
                payload.module = getAllowedModules(getReport(payload.report_id).module_id).module_name;
                state.reports[index] = payload;
            } else {
                state.reports.push(payload);
            }
            store.commit('showReports');
        },

        async editErrorReport(state, payload) {
            let index = state.reports.findIndex(report => report.order_id == payload.order_id);
            if (index >= 0) {
                state.reports[index]['is_error'] = true;
            }
            store.commit('showReports');
        },

        // удалить объект
        delReport(state, payload) {
            const index = state.reports.findIndex(rep => (rep.uuid == payload.uuid && rep.order_id == payload.order_id));
            state.reports.splice(index, 1);
        }
    },
    actions: {

        // добавление объекта
        doAddReport: (context, payload) => {
            context.commit('addReport', payload);
        },

        // удаление объекта
        doDelReport: (context, payload) => {
            context.commit('delReport', payload);
        },

        // скачивание отчета
        doFetchReport: (context, payload) => {
            let url = '';
            if (payload.fmt) {
                url = '/reporter/getReport/' + payload.uuid + '?fmt=' + payload.fmt;
            } else {
                url = '/reporter/getReport/' + payload.uuid;
            }
            return DoGetFile(
                    url,
                null,
                'Получить отчет',
                false,
            )
        },

        // скачивание отчета
        doFetchReportShowing: (context, payload) => {
            let url = '';
            if (payload.fmt) {
                url = '/reporter/getReport/' + payload.uuid + '?fmt=' + payload.fmt;
            } else {
                url = '/reporter/getReport/' + payload.uuid;
            }
            return DoGetFileForShowing(
                url,
                null,
                'Просмотр отчета',
                false,
                '',
                payload.fmt,
            )
        }
    }
}
