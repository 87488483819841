<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" :disableSort="true" name="tbl-rmt-schedule-graph-steps" :rowsPerPage="1000"></edit-table-panel>
</template>

<script>

import {getScheduleEvent, getTransportationKind} from "@/store/RmtHelpers";
import {getLength, getSignTitle, getTimeFormat} from "@/lib";

export default {
  props: ['isLoading', 'scheduleGraphSteps','tripTypes'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__id",
          caption: "Код",
          sortType: 'number',
          isSortable: false,
          show: false
        },
        {
          field: "order_num",
          caption: "№",
          sortType: 'number',
          isSortable: false
        },
        {
          field: "trip_num",
          caption: "Рейс",
          sortType: 'number',
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false
        },
        {
          field: "__event",
          caption: "Событие",
          sortType: 'string',
          isSortable: false
        },
        {
          field: "__begin_time",
          caption: "Время",
          sortType: 'string',
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false
        },
        {
          field: "__event_duration",
          caption: "Длит.",
          sortType: 'number',
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false
        },
        {
          field: "__event_length",
          caption: "Пробег",
          sortType: 'string',
          dataAlign: 'right',
          headerAlign: 'right',
          isSortable: false
        },
        {
          field: "trip_type_sign",
          caption: "Тип рейса",
          sortType: 'sign',
          dataAlign: 'left',
          headerAlign: 'left',
          isSortable: false,
          displayFn(row) {
            return getSignTitle(row.__trip_type_sign)
          },
        },
        {
          field: "__transportation_kind_name",
          caption: "Вид",
          sortType: 'string',
          isSortable: false
        },
        {
          field: 'is_blocked_norm',
          caption: 'Б.Вр',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          isSortable: false,
          displayFn(row) {
            if (row.is_blocked_norm) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: 'is_blocked_stop',
          caption: 'Б.Ст',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          isSortable: false,
          displayFn(row) {
            if (row.is_blocked_stop) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_skipped_turn_begin",
          caption: "Р.Нач",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          isSortable: false,
          displayFn(row) {
            if (!row.is_skipped_turn_begin) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_skipped_turn_end",
          caption: "Р.Кон",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          isSortable: false,
          displayFn(row) {
            if (!row.is_skipped_turn_end) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },

  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      const scheduleGraphSteps = this.scheduleGraphSteps;
      return scheduleGraphSteps.map( schGrStep => {

        schGrStep.__id = schGrStep.schedule_graph_step_id;
        // получаем событие
        let event = getScheduleEvent(schGrStep.schedule_event_id);
        schGrStep.__event = event.long_name;
        schGrStep.__begin_time = Number.isInteger(schGrStep.time_begin) ? getTimeFormat(schGrStep.time_begin) : '';
        schGrStep.__event_duration = Number.isInteger(schGrStep.event_duration) ? (schGrStep.event_duration / 60) : '';
        //получаем тип рейса
        let tripType = this.tripTypes.find(type => type.trip_type_id == schGrStep.trip_type_id);
        schGrStep.__trip_type_sign = tripType === undefined ? '' : tripType.trip_type_sign;
        schGrStep.__direction = tripType === undefined ? '' : tripType.direction;
        //получаем тип перевозок
        let transportationKind = getTransportationKind(schGrStep.transportation_kind_id);
        schGrStep.__transportation_kind_name = transportationKind.short_name;
        schGrStep.__event_length = Number.isInteger(schGrStep.time_begin) ? getLength(schGrStep.event_length) : '';

        //Задаем стиль
        if (event.schedule_event_type_id == 1) {
          schGrStep.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getProductionReisColor']
          }
        } else if (event.schedule_event_type_id == 2) {
          schGrStep.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getTechnicalReisColor']
          }
        } else if (event.schedule_event_type_id == 3) {
          schGrStep.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getShortEventColor']
          }
        } else if (event.schedule_event_type_id == 4) {
          schGrStep.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getLongEventColor']
          }
        } else if (event.schedule_event_type_id == 5) {
          schGrStep.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getLengthWithTimeEventColor']
          }
        } else if (event.schedule_event_type_id == 6) {
          schGrStep.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getLengthWithTimeAndReisCountEventColor']
          }
        }

        return schGrStep
      });
    },
  },
};
</script>
