// ХРАНИЛИЩЕ РЕЗУЛЬТАТА ОБРАБОТКИ ПЛАТЕЖНЫХ ТРАНЗАКЦИЙ ПО ТС ПАРКА ЗА СУТКИ

import {DoPost} from "@/store/Actions";

export default {
    actions: {
        // запрос списка объектов
        doFetchPayTransProcStatusByTransport: (context, payload) => {
            return DoPost(
                '/asmpp/asop/payTransProcStatusByTransport',
                payload,
                null,
                'Запрос результата обработки платежных транзакций по ТС парка за сутки',
                false,
                true
            )
        },
    }
}
