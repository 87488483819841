//import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";
import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";
import {quickSort} from "@/lib";
import {getStopIndex, latitudeSortFn, longitudeSortFn} from "@/store/MultipassHelpers";

// ХРАНИЛИЩЕ ОСТАНОВОЧНЫХ ПУНКТОВ

export default {
    state() {
        return {
            // список объектов
            stops: [],
            // список остановок отсортированных по широте
            stopLatitudes: [],
            // список остановок отсортированных по долготе
            stopLongitudes: [],
            // время обновления кэша new Date().getTime()
            stopsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            stopsCacheTimeout: null,
        }
    },
    getters: {
        // вернуть объект
        getStop(state) {
            return state.stop;
        },
        // вернуть список объектов
        getStops(state) {
            return state.stops;
        },
        // вернуть список остановок отсортированных по широте
        getStopLatitudes(state) {
            return state.stopLatitudes;
        },
        // вернуть список остановок отсортированных по долготе
        getStopLongitudes(state) {
            return state.stopLongitudes;
        }
    },
    mutations: {
        // установить новый объект
        setStop(state, payload) {
            state.stop = payload;
            state.stopCache = new Date().getTime();
        },
        // установить новый список объектов
        setStops(state, payload) {
            state.stops = payload;
            state.stopsCache = new Date().getTime();

            // сортируем по широте
            state.stopLatitudes = [...state.stops];
            quickSort(state.stopLatitudes, latitudeSortFn);

            // сортируем по долготе
            state.stopLongitudes = [...state.stops];
            quickSort(state.stopLongitudes, longitudeSortFn);
        },
        // добавить объект
        addStop(state, payload) {
            state.stops.push(payload)

            // сортируем по широте
            state.stopLatitudes = [...state.stops];
            quickSort(state.stopLatitudes, latitudeSortFn);

            // сортируем по долготе
            state.stopLongitudes = [...state.stops];
            quickSort(state.stopLongitudes, longitudeSortFn);
        },
        // изменить объект
        editStop(state, payload) {
            const index = getStopIndex(payload.stop_id);
            if (index >= 0) {
                state.stops[index] = payload;
                // сортируем по широте
                state.stopLatitudes = [...state.stops];
                quickSort(state.stopLatitudes, latitudeSortFn);
                // сортируем по долготе
                state.stopLongitudes = [...state.stops];
                quickSort(state.stopLongitudes, longitudeSortFn);
            }
        },
        // удалить объект
        delStop(state, payload) {
            const index = getStopIndex(payload.stop_id);
            if (index >= 0) {
                state.stops.splice(index, 1);
                // сортируем по широте
                state.stopLatitudes = [...state.stops];
                quickSort(state.stopLatitudes, latitudeSortFn);
                // сортируем по долготе
                state.stopLongitudes = [...state.stops];
                quickSort(state.stopLongitudes, longitudeSortFn);
            }
        },
        // передернуть состояние объекта
        updateStop(state, payload) {
            const index = getStopIndex(payload.stop_id);
            if (index >= 0) {
                const srcStop = state.stops[index];
                const dstStop = payload;
                state.stops[index] = dstStop;

                const latIndex = state.stopLatitudes.indexOf(srcStop);
                if (latIndex >= 0) {
                    state.stopLatitudes[latIndex] = dstStop;
                }

                const longIndex = state.stopLongitudes.indexOf(srcStop);
                if (longIndex >= 0) {
                    state.stopLongitudes[longIndex] = dstStop;
                }
            }
        },
    },
    actions: {
        // запрос объекта
        doFetchStop: (context, payload) => {
             // выполняем запрос
            return DoGet(
                '/multipass/stops/'+payload.stop_id,
                null,
                {},
                'Запрос остановочного пункта',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchStops: (context, payload) => {
            return DoGet(
                '/multipass/stops',
                'multipass/setStops',
                [],
                'Запрос остановочных пунктов',
                false,
                payload && payload.force,
                context.state.stopsCache,
                context.state.stopsCacheTimeout
            )
        },

        // очищает кэш
        doClearStops: (context, payload) => {
            return DoClearCache(
                'multipass/setStops',
                [],
                payload && payload.force,
                context.state.stopsCache,
                context.state.stopsCacheTimeout
            )
        },

        // добавление объекта
        doAddStop: (context, payload) => {
            return DoPost(
                '/multipass/stops',
                payload,
                'multipass/addStop',
                'Добавление остановочного пункта'
            )
        },

        // изменение объекта
        doEditStop: (context, payload) => {
            return DoPut(
                '/multipass/stops/'+payload.stop_id,
                payload,
                'multipass/editStop',
                'Изменение остановочного пункта'
            )
        },

        // удаление объекта
        doDelStop: (context, payload) => {
            return DoDelete(
                '/multipass/stops/'+payload.stop_id,
                'multipass/delStop',
                'Удаление остановочного пункта'
            )
        },

        // обновление объекта
        doUpdateStop: (context, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const updatedStop = {...payload};
                    context.commit('updateStop', updatedStop)
                    resolve(updatedStop);
                },0)
            })
        }
    }
}