import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПНЫХ ДЛЯ ФОРМИРОВАНИЯ НАРЯДА МАРШРУТОВ

export default {
    state() {
        return {
            // список объектов
            orderRoutes: [],
            // время обновления кэша new Date().getTime()
            orderRoutesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            orderRoutesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getOrderRoutes(state) {
            return state.orderRoutes;
        },
    },
    mutations: {
        // установить новый список объектов
        setOrderRoutes(state, payload) {
            state.orderRoutes = payload;
            state.orderRoutesCache = new Date().getTime()
        },
    },
    actions: {

        // запрос списка объектов
        doFetchOrderRoutes: (context, payload) => {
            return DoGet(
                '/kvr/getOrderRoutes',
                'kvr/setOrderRoutes',
                [],
                'Получить список доступных для формирования наряда маршрутов',
                false,
                payload && payload.force,
                context.state.orderRoutesCache,
                context.state.orderRoutesCacheTimeout
            )
        },

        // очищает кэш
        doClearOrderRoutes: (context, payload) => {
            return DoClearCache(
                'kvr/setOrderRoutes',
                [],
                payload && payload.force,
                context.state.orderRoutesCache,
                context.state.orderRoutesCacheTimeout
            )
        },
    }
}
