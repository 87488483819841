<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-kvr-contracts"></edit-table-panel>
</template>

<script>

import {getAgency} from "@/store/KvrHelpers";
import {getTariffKind} from "@/store/MultipassHelpers";

export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "contract_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "__org",
          caption: "Организации",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "contract_num",
          caption: "Номер",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "contract_date",
          caption: "Дата заключения",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "date_begin",
          caption: "Дата начала",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "date_end",
          caption: "Дата окончания",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "is_disabled",
          caption: "Приост.",
          isFilterable: true,
          sortType: 'boolean',
          dataAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_disabled) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "__tariff_kind",
          caption: "Вид тарифа",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "method",
          caption: "Методика расчета:",
          sortType: 'number',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      return this.$store.getters["kvr/getContracts"].map(contract => {

        contract.__id = contract.contract_id;
        // получаем организацию
        let agency = getAgency(contract.org_id);
        if (agency) {
          contract.__org = getAgency(contract.org_id).long_name;
        }
        // Получаем тип тарифа
        let tariff_kind = getTariffKind(contract.tariff_kind_id);
        if (tariff_kind) {
          contract.__tariff_kind = tariff_kind.long_name;
        }
        return contract;
      });
    },
  }
};
</script>
