import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ИСТОРИИ ДВИЖЕНИЯ

export default {

    actions: {

        // запрос списка объектов
        doFetchNavTransportOnDay: (context, payload) => {

            return DoGet(
                '/nav/getNavTransportOnDay?date_index=' + payload.date_index,
                null,
                [],
                'Запрос активных транспортных средств за указанный день',
                false,
                payload && payload.force,
            )
        },

        // запрос списка объектов
        doFetchVehicleHistory: (context, payload) => {

            return DoGet(
                '/nav/getVehicleHistory?transport_id=' + payload.transport_id + '&time_index_begin=' + payload.time_index_begin + '&time_index_end=' + payload.time_index_end,
                null,
                [],
                'Запрос истории движения транспортного средства за указанное время',
                false,
                payload && payload.force,
            )
        },

    }
}
