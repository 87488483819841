<template>
  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/kvr">КВР</router-link></span>
        <span> / </span>
        <span>Карта.История {{ requestParams }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>

        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: false}"
              :filterExists="false"
          >

            <template #preLeftCustomButton>
              <!-- Выбор режима отображения  -->
              <input-select-with-check
                  :itemList="[{id:0,title:'Текущий наряд'},{id:1,title:'История'}]"
                  v-model="viewMode"
                  title="История"
                  @change="changeValue"
              >
              </input-select-with-check>
            </template>

            <!-- Кнопка "Линии маршрутов"   -->
            <template #preLeftCustomButton2>
              <input-select-with-check
                  :itemList="marshLinesVariants"
                  v-model="selectedMarshLines"
                  title="Линии маршрутов"
              >
              </input-select-with-check>
            </template>

            <!-- Кнопка "Направления рейсов"   -->
            <template #preLeftCustomButton3>
              <input-select-with-check
                  :itemList="reisDirectionsVariants"
                  v-model="selectedReisDirections"
                  title="Направления рейсов"
              >
              </input-select-with-check>
            </template>

            <template #preLeftCustomButton4>
              <!-- Выбор даты  -->
              <input-date
                  id="date"
                  autocomplete="off"
                  class="form-control"
                  v-model="date"
              ></input-date>
            </template>

            <template #preLeftCustomButton5>
              <!-- Выбор маршрута  -->
              <input-select
                  id="route"
                  class="form-control"
                  v-model="selectedRouteId"
                  :itemList="routes"
                  caption="Маршрут..."
                  style="width: 300px"
              ></input-select>
            </template>

            <template #leftCustomButton>
              <input-select
                  id="graph"
                  class="form-control"
                  v-model="selectedGraph"
                  :itemList="graphs"
                  caption="График..."
                  style="width: 110px"
              ></input-select>
            </template>

            <template #leftCustomButton2>
              <input-select
                  id="selectedShift"
                  class="form-control"
                  v-model="selectedShift"
                  :itemList="shift"
                  caption="Смена..."
                  style="width: 110px"
              ></input-select>
            </template>

            <template #leftCustomButton3>
              <input-select
                  id="selectedTSId"
                  class="form-control"
                  v-model="selectedTSId"
                  :itemList="ts"
                  caption="ТС..."
                  style="width: 180px"
              ></input-select>
            </template>

            <template #leftCustomButton4>
              <input-checkbox
                  id="isShowRouteZonesLine"
                  v-model="isShowRouteZonesLine"
                  style="margin-top: 8px; user-select: none"
                  :readonly="selectedTSId == null"
              >Зоны
              </input-checkbox>
            </template>

            <!-- Кнопка Маршруты -->
            <template #rightCustomButton>
              <button
                  class="btn btn-primary"
                  @click="allOnMap"
              >Все на карту
              </button>
            </template>

            <template #rightCustomButton2>
              <button
                  type="button"
                  class="btn btn-secondary"
                  @click="getVehicleNav"
                  title="Обновить"
                  :disabled="isLoading || !this.selectedTSId"
              >
                <i class="fas fa-sync-alt" style="right: 7px"></i>
              </button>
            </template>


          </crud-form>
        </align-panel>
        <align-panel align="all">
          <align-container>

            <!-- Карта -->
            <align-panel align="all" style="z-index: 0 !important;">
              <nav-history-map
                  :vehicles="vehiclesOnMap"
                  :zones="zonesOnMap"
                  :stops="stopsOnMap"
                  :marshLine="marshLinesOnMap"
                  @onMapCreated="onMapCreated"
                  @onVehicleInfo="onVehicleInfo"
              >
              </nav-history-map>
            </align-panel>

            <!-- Правая панель  -->
            <align-panel align="right" width="20rem" :gap="3">
              <align-container>

                <align-panel align="all" width="3rem">

                  <tab-content mode="single">
                    <!-- ОнЛайн -->
                    <tab-pane active ref="pane1" class="p-2">

                      <align-container>

                        <!-- История -->
                        <tab-pane ref="pane2" class="p-2">
                          <align-container>
                            <align-panel align="all">
                              <hist-panel
                                  :vehicleHistory="vehicle_history"
                                  :selectedNavs="vehicleHistoryArr"
                                  :isLoading="isLoading"
                                  :selectedRowNavId="selectedRowNavId"
                                  @onCtrlSelect="onCtrlSelect"
                                  @onShiftSelect="onShiftSelect"
                                  @onClearSelect="onClearSelect"
                                  @onSelect="onSelect"
                              >
                              </hist-panel>
                            </align-panel>
                          </align-container>
                        </tab-pane>
                      </align-container>
                    </tab-pane>

                  </tab-content>
                </align-panel>
              </align-container>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!--  Диалог "Информация о ТС"  -->
  <vehicle-information-dialog
      v-if="isDialogVisible"
      :item="selectedVehicle"
      :op="op"
      @onRejectOperation="onRejectOperation"
  >
  </vehicle-information-dialog>

  <!-- Спиннер -->
  <loading v-if="(isLoading == true) || (isLoading == 'fetch')"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import VehicleInformationDialog from "@/components/kvr/map/VehicleInformationDialog";
import {latLngBounds} from "leaflet";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import HistPanel from "@/components/kvr/map/HistPanel";
import {
  dateToND, dateToSeconds,
  dateToString,
  graphSortFn, ndToDateString,
  quickSort,
  routeNumSortFn,
  stringToDate,
  strSortFn
} from "@/lib";
import NavHistoryMap from "@/components/kvr/map/NavHistoryMap";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import InputSelect from "@/components/ui/custom/InputSelect";
import {getDepot, getRoute, getWorkTransportOnDay} from "@/store/KvrHelpers";
import {getModel, getTransportType} from "@/store/RefHelpers";

export default {
  mixins: [PageMixin],

  components: {
    InputSelect,
    AlignContainer,
    NavHistoryMap,
    HistPanel,
    AlignPanel,
    VehicleInformationDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id",
    // Параметры ниже попадают в router, если нажата кнопка "Рейс на карту"
    // Дата рейса
    "date_index",
    // ТС рейса
    "transport_id",
    // Маршрут рейса
    "route_id",
    // Время начала рейса
    "fact_trip_time_index_begin",
    // Время конца рейса
    "fact_trip_time_index_end"
  ],

  data() {
    return {
      viewMode: 1,
      // id выбранного маршрута
      selectedRouteId: null,
      // выбранный график
      selectedGraph: null,
      // выбранная смена
      selectedShift: null,
      // выбранное ТС
      selectedTSId: null,
      // выбранное транспортное средство на маршрутах
      selectedTS: null,
      // выбранная дата, по которой делается запрос
      date: dateToString(),
      // выбранная дата, по которой сделан запрос
      selectedDate: null,
      // карта
      leaflet: null,
      // данные, которые отображаются в диалоговом окне
      selectedVehicle: {},
      // первый вызов после команды
      firstWatchAfterCommand: false,
      // История движения транспортного средства за указанное время
      vehicle_history: [],
      // список выбранных навигационных отметок
      vehicleHistoryArr: [],
      // переменная в которой хранится индекс строки (для корректной работы выделения строк с удержанием клавиши Shift)
      selectedRowNavId: null,
      // отображение спинера при загрузке навигации по ТС
      isLoading: false,
      // галочка загружать ли линии маршрута
      isShowRouteLine: true,
      // галочка загружать ли зоны маршрута
      isShowRouteZonesLine: false,
      // загруженная линия маршрута
      marshLine: [],
      // загруженные зоны маршрута
      marshZones: [],

      // Линии маршрутов
      selectedMarshLines: 1,
      // варианты для выпадающего списка "Линии маршрутов"
      marshLinesVariants:
          [
            {id: 0, title: 'Не отображать'},
            {id: 1, title: 'Основные рейсы'},
            {id: 2, title: 'Не основные рейсы'},
            {id: 3, title: 'Все рейсы'}
          ],

      // Направления рейсов
      selectedReisDirections: 2,
      // варианты для выпадающего списка "Направления рейсов"
      reisDirectionsVariants:
          [
            {id: 0, title: 'Прямые'},
            {id: 1, title: 'Обратные'},
            {id: 2, title: 'Все'},
          ],
    }
  },

  computed: {

    zonesOnMap() {
      let arr = [];
      let zones = this.marshZones.stop_zones;

      if (zones && zones != undefined && zones.length != 0) {
        this.stopsOnMap.forEach(stop => {
          let value = zones.find(zone => stop.stop_id == zone.stop_id);
          if (value)
            arr.push(value);
        })
      }


      return arr;
    },

    stopsOnMap() {
      let arr = [];
      let stops = this.marshLine.stops;
      let tripTypes = this.marshLine.trip_types;

      if (tripTypes != undefined && tripTypes.length != 0) {
        for (let i = 0; i < tripTypes.length; i++) {
          // не отображаем линии, если в меню сверху выбрано не отображать линии
          if (this.selectedReisDirections == tripTypes[i].trip_type.direction || this.selectedReisDirections == 2) {
            tripTypes[i].trip_type_line['direction'] = tripTypes[i].trip_type.direction;
            if (this.selectedMarshLines == 1 && tripTypes[i].trip_type.trip_type_sign.substring(0, 2) == "00" && tripTypes[i].trip_type_card) {
              for (let z = 0; z < tripTypes[i].trip_type_card.length; z++) {
                let stop = stops.find(value => tripTypes[i].trip_type_card[z].stop_id == value.stop_id);
                let checkStop = arr.find(value => stop.stop_id == value.stop_id);
                if (stop && !checkStop)
                  arr.push(stop);
              }
            } else if (this.selectedMarshLines == 2 && tripTypes[i].trip_type.trip_type_sign.substring(0, 2) != "00" && tripTypes[i].trip_type_card) {
              for (let z = 0; z < tripTypes[i].trip_type_card.length; z++) {
                let stop = stops.find(value => tripTypes[i].trip_type_card[z].stop_id == value.stop_id);
                let checkStop = arr.find(value => stop.stop_id == value.stop_id);
                if (stop && !checkStop)
                  arr.push(stop);
              }
            } else if (this.selectedMarshLines == 3 && tripTypes[i].trip_type_card) {
              for (let z = 0; z < tripTypes[i].trip_type_card.length; z++) {
                let stop = stops.find(value => tripTypes[i].trip_type_card[z].stop_id == value.stop_id);
                let checkStop = arr.find(value => stop.stop_id == value.stop_id);
                if (stop && !checkStop)
                  arr.push(stop);
              }
            }
          }
        }
      }
      return arr;
    },

    marshLinesOnMap() {
      let arr = [];
      let tripTypes = this.marshLine.trip_types;
      if (tripTypes != undefined && tripTypes.length != 0) {
        for (let i = 0; i < tripTypes.length; i++) {
          // не отображаем линии, если в меню сверху выбрано не отображать линии
          if (this.selectedReisDirections == tripTypes[i].trip_type.direction || this.selectedReisDirections == 2) {
            tripTypes[i].trip_type_line['direction'] = tripTypes[i].trip_type.direction;
            if (this.selectedMarshLines == 1 && tripTypes[i].trip_type.trip_type_sign.substring(0, 2) == "00") {
              arr.push(tripTypes[i].trip_type_line);
            } else if (this.selectedMarshLines == 2 && tripTypes[i].trip_type.trip_type_sign.substring(0, 2) != "00") {
              arr.push(tripTypes[i].trip_type_line);
            } else if (this.selectedMarshLines == 3) {
              arr.push(tripTypes[i].trip_type_line);
            }
          }
        }
      }
      return arr;
    },

    requestParams() {
      if (this.selectedTS) {
        // слудующая строчка для тестирования времени и часовых поясов, удалить, если вопрос ними решен
        // return 'по ТС (' + this.selectedTS.garage_num + ' / ' + this.selectedTS.state_num + ') за ' + this.selectedDate + '     ' + secondsToDateAndTime(dateToND(stringToDate(this.date)) * 24 * 60 * 60) + '   ' + secondsToDateAndTime((dateToND(stringToDate(this.date)) * 24 * 60 * 60) + (24 * 60 * 60) - 1);
        return 'по ТС (' + this.selectedTS.garage_num + ' / ' + this.selectedTS.state_num + ') за ' + this.selectedDate;
      } else {
        return ''
      }
    },

    routes() {
      // формируем массив уникальных идетификаторов маршрута
      let routes_id = [];
      this.$store.getters['kvr/getWorkTransportOnDay'].map(value => {
        if (!routes_id.includes(value.route_id)) {
          routes_id.push(value.route_id);
        }
      });
      // формируем массив маршрутов
      let routes = [];
      routes_id.map(value => {
        let rt = getRoute(value);
        if (rt) {
          routes.push(rt);
        }
      });
      // сортируем полученный массив
      quickSort(routes, (leftItem, rightItem) => {
        return 1 * routeNumSortFn(leftItem.route_num, rightItem.route_num);
      });
      // формируем массив для отображения в комбобокс
      let result_routes = [];
      routes.map(value => {
        result_routes.push({
          id: value.route_id,
          title: value.route_num + '-' + value.route_title
        })
      });
      return result_routes;
    },

    graphs() {
      // формируем массив уникальных графиков
      let graphs = [];
      if (this.selectedRouteId == null) {
        this.$store.getters['kvr/getWorkTransportOnDay'].map(value => {
          if (!graphs.includes(value.graph)) {
            graphs.push(value.graph)
          }
        });
      } else {
        this.$store.getters['kvr/getWorkTransportOnDay'].map(value => {
          if (!graphs.includes(value.graph) && value.route_id == this.selectedRouteId) {
            graphs.push(value.graph)
          }
        });
      }

      // формируем массив для отображения в комбобокс
      let result_graphs = [];
      graphs.map(value => {
        result_graphs.push({
          id: value,
          title: value
        })
      })
      // сортируем полученный массив
      quickSort(result_graphs, (leftItem, rightItem) => {
        return 1 * graphSortFn(leftItem.title, rightItem.title);
      });

      return result_graphs;
    },

    // получаем смены
    shift() {
      let values = [];
      //фильтруем, если выбран только маршрут
      if (this.selectedRouteId != null) {
        // формируем массив строк принадлежащих этому маршруту
        this.$store.getters['kvr/getWorkTransportOnDay'].map(value => {
          if (value.route_id == this.selectedRouteId) {
            values.push(value)
          }
        });
      } else {
        values = this.$store.getters['kvr/getWorkTransportOnDay'];
      }

      let graphs = [];
      // если выбран граф
      if (this.selectedGraph == null) {
        // формируем массив уникальных смен
        values.map(value => {
          if (!graphs.includes(value.shift)) {
            graphs.push(value.shift);
          }
        });
      } else {
        values.map(value => {
          if (!graphs.includes(value.shift) && value.graph == this.selectedGraph) {
            graphs.push(value.shift)
          }
        });
      }
      // формируем массив для отображения в комбобокс
      let result_shifts = [];
      graphs.map(value => {
        result_shifts.push({
          id: value,
          title: value
        })
      })
      // сортируем полученный массив
      quickSort(result_shifts, (leftItem, rightItem) => {
        return 1 * strSortFn(leftItem.id, rightItem.id);
      });

      return result_shifts;
    },

    // получаем ТС
    ts() {
      let values_by_routes = [];
      //фильтруем, если выбран маршрут
      if (this.selectedRouteId != null) {
        // формируем массив строк принадлежащих этому маршруту
        this.$store.getters['kvr/getWorkTransportOnDay'].map(value => {
          if (value.route_id == this.selectedRouteId) {
            values_by_routes.push(value)
          }
        });
      } else {
        values_by_routes = [...this.$store.getters['kvr/getWorkTransportOnDay']];
      }

      let values_by_graph = [];
      //фильтруем, если выбран график
      if (this.selectedGraph != null) {
        values_by_routes.map(value => {
          if (value.graph == this.selectedGraph) {
            values_by_graph.push(value)
          }
        });
      } else {
        values_by_graph = values_by_routes;
      }

      let values_by_smena = [];
      //фильтруем, если выбрана смена
      if (this.selectedShift != null) {
        values_by_graph.map(value => {
          if (value.shift == this.selectedShift) {
            values_by_smena.push(value)
          }
        });
      } else {
        values_by_smena = values_by_graph
      }

      // сортируем полученный массив по гаражному номеру
      quickSort(values_by_smena, (leftItem, rightItem) => {
        return 1 * strSortFn(leftItem.garage_num, rightItem.garage_num);
      });

      // формируем массив для отображения в комбобокс (исключаем дублирование)
      const ts_set = {}
      let result_ts = [];
      values_by_smena.map(value => {
        if (!ts_set[value.transport_id]) {
          ts_set[value.transport_id] = true
          result_ts.push({
            id: value.transport_id,
            title: value.garage_num + ' - ' + value.state_num,
          });
        }
      });

      // возвращаем результат
      return result_ts;
    },

    vehiclesOnMap() {
      return this.historyVehiclesOnMap;
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' && this.id)
    },

    // возвращает массив отметок для отображения на карте
    historyVehiclesOnMap() {
      let arr = [];
      for (let i = 0; i < this.vehicleHistoryArr.length; i++) {
        if (this.vehicleHistoryArr[i].latitude != 0 && this.vehicleHistoryArr[i].longitude != 0) {
          let nav = this.vehicle_history.find(nav => nav.nav_id == this.vehicleHistoryArr[i]);
          if (nav.latitude != 0 && nav.longitude != 0) {
            arr.push(nav);
            // для корректного отображения иконки нужно добавить к навигационной отметке тип транспорта
            arr[arr.length - 1].transport_type_id = this.selectedTS.transport_type_id;
          }
        }
      }
      return arr;
    },
  },

  methods: {

    allOnMap() {
      let arr = [];

      // линия рейса
      this.marshLinesOnMap.forEach(line => {
        if (line.points) {
          line.points.forEach(point => {
            arr.push(point);
          });
        }
      });

      // остановки
      this.stopsOnMap.forEach(stop => {
        arr.push(stop);
      });

      //ТС
      this.vehiclesOnMap.forEach(vehicle => {
        arr.push(vehicle);
      });

      if (arr.length != 0) {
        const bounds = latLngBounds(
            arr.map(point => [point.latitude, point.longitude])
        )
        this.leaflet.fitBounds(bounds);
      }

    },

    // переходим к карте
    changeValue() {
      this.$router.replace("/kvr/map");
    },

    async getVehicleNav() {
      // включаем окно загрузки, если не включен режим первого получения данных ('fetch')
      if (this.isLoading != 'fetch') {
        this.isLoading = true;
      }
      this.vehicleHistoryArr = [];
      this.marshLine = [];
      this.marshZones = [];
      try {

        this.selectedDate = this.date;
        if (this.selectedRouteId) {
          this.selectedTS = this.$store.getters["kvr/getWorkTransportOnDay"].find(value => (value.transport_id == this.selectedTSId) && (this.selectedRouteId == value.route_id));
        } else {
          // сетаем транспортное средство по маршруту по которому делаем запрос
          this.selectedTS = getWorkTransportOnDay(this.selectedTSId);
        }
        let vehicle_history = await this.$store.dispatch('kvr/doFetchVehicleHistory', {
          time_index_begin: dateToND(stringToDate(this.date)) * 24 * 60 * 60,
          time_index_end: (dateToND(stringToDate(this.date)) * 24 * 60 * 60) + (24 * 60 * 60) + (3 * 60 * 60),
          transport_id: this.selectedTS.transport_id
        });
        if (vehicle_history) {
          // for (let i = 0; i < 500; i++){
          //   this.vehicle_history.push(vehicle_history[i])
          // }
          this.vehicle_history = vehicle_history;
        }
        // загружаем линию маршрута, если стоит галочка
        if (this.isShowRouteLine == true && this.selectedRouteId) {
          let marshLine = await this.$store.dispatch('kvr/doFetchRouteTripTypeListFiltered', {
            date_index: dateToND(stringToDate(this.date)),
            route_id: this.selectedRouteId,
            force: true,
          })
          if (marshLine)
            this.marshLine = marshLine;
        }
        // загружаем зоны остановок маршрута, если стоит галочка
        if (this.isShowRouteZonesLine == true && this.selectedRouteId) {
          let marshZones = await this.$store.dispatch('kvr/doFetchRouteStopZoneList', {
            date_index: dateToND(stringToDate(this.date)),
            route_id: this.selectedRouteId,
            force: true,
          })
          if (marshZones) {
            this.marshZones = marshZones;
          }
        }
      } finally {
        // отключаем окно загрузки, если не включен режим первого получения данных ('fetch')
        if (this.isLoading != 'fetch') {
          this.isLoading = false;
        }
      }
    },

    // клик с удержанием клавиши Ctrl
    onCtrlSelect(nav_id) {
      this.selectedRowNavId = nav_id;
      let ind = this.vehicleHistoryArr.findIndex(nav => nav.nav_id == nav_id);
      // если массив выбранных строк содержит nav_id, то удаляем его оттуда
      if (ind != -1) {
        this.vehicleHistoryArr.splice(ind, 1);
        // добавляем, если индекса нет
      } else {
        this.vehicleHistoryArr.push(nav_id)
      }
    },

    // клик с удержанием клавиши Shift
    onShiftSelect(index) {
      this.vehicleHistoryArr = [];
      // если уже есть выбранная(сохраненная) строка -> делаем выбранными все строки между выбранными
      if (this.selectedRowNavId || this.selectedRowNavId == 0) {
        // ищем индекс выбранной строки
        let ind = this.vehicle_history.findIndex(value => value.nav_id == this.selectedRowNavId);
        // если индекс выбранной строки больше индекса сохраненной строки
        if (index >= ind) {
          for (let i = ind; i <= index; i++) {
            this.vehicleHistoryArr.push(this.vehicle_history[i].nav_id);
          }
          // если индекс выбранной строки меньше индекса сохраненной строки
        } else if (ind > index) {
          for (let i = index; i <= ind; i++) {
            this.vehicleHistoryArr.push(this.vehicle_history[i].nav_id);
          }
        }
      } else {
        this.selectedRowNavId = this.vehicle_history[index].nav_id;
      }
    },

    // клик без удержания каких-либо клавиш
    onSelect(nav_id) {
      this.selectedRowNavId = nav_id;
      this.vehicleHistoryArr = [];
      this.vehicleHistoryArr.push(nav_id);
    },

    // очистка выделения в таблице навигации
    onClearSelect() {
      this.vehicleHistoryArr = [];
    },

    // отображение диалога "Информация о ТС"
    onVehicleInfo(vehicle) {
      this.selectedVehicle = vehicle;
      if (this.selectedTS) {
        if (!this.selectedVehicle['depot_title']) {
          this.selectedVehicle['depot_title'] = this.selectedTS.depot_title
        }
        if (!this.selectedVehicle['transport_type_title']) {
          this.selectedVehicle['transport_type_title'] = this.selectedTS.transport_type_title
        }
        if (!this.selectedVehicle['model_title']) {
          this.selectedVehicle['model_title'] = this.selectedTS.model_title
        }
        if (!this.selectedVehicle['garage_num']) {
          this.selectedVehicle['garage_num'] = this.selectedTS.garage_num
        }
        if (!this.selectedVehicle['state_num']) {
          this.selectedVehicle['state_num'] = this.selectedTS.state_num
        }
        if (!this.selectedVehicle['route_num']) {
          this.selectedVehicle['route_num'] = getRoute(this.selectedTS.route_id).route_num
        }
        if (!this.selectedVehicle['graph']) {
          this.selectedVehicle['graph'] = this.selectedTS.graph
        }
        if (!this.selectedVehicle['shift']) {
          this.selectedVehicle['shift'] = this.selectedTS.shift
        }
        if (!this.selectedVehicle['depot_title']) {
          this.selectedVehicle['depot_title'] = getDepot(this.selectedTS.depot_id).long_name
        }
        if (!this.selectedVehicle['transport_type_title']) {
          this.selectedVehicle['transport_type_title'] = getTransportType(this.selectedTS.transport_type_id).long_name
        }
        if (!this.selectedVehicle['model_title']) {
          this.selectedVehicle['model_title'] = getModel(this.selectedTS.model_id).long_name
        }
      }
      this.$router.replace("/kvr/history/show/" + vehicle.transport_id)
    },

    // создана карта
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // отменили операцию
    onRejectOperation() {
      this.selectedVehicle = {};
      // возвращаемся на страницу
      this.$router.replace("/kvr/history");
    },

    // проверяем, что все транспортные средства попадают в зону карты
    isVehiclesInTheMap() {
      const bounds = this.leaflet.getBounds()
      return this.vehiclesOnMap.every(v => bounds.contains([v.latitude, v.longitude]))
    },

    // запускаем обновление данных за указанный день
    async refreshData() {
      // включаем окно загрузки, если не включен режим первого получения данных ('fetch')
      if (this.isLoading != 'fetch') {
        this.isLoading = true;
      }
      try {
        await this.$store.dispatch('kvr/doFetchWorkTransportOnDay', {
          date_index: dateToND(stringToDate(this.date)),
        })
      } finally {
        // отключаем окно загрузки, если не включен режим первого получения данных ('fetch')
        if (this.isLoading != 'fetch') {
          this.isLoading = false;
        }
        if (this.op != 'edit') {
          this.selectedRow = null;
        }
      }
    }
  },

  watch: {
    // вызывается при выборе даты
    async date() {
      // при изменение даты - запрашиваем данные по маршрутам
      await this.refreshData();
    },

    // вызывается при выборе маршрута
    selectedRouteId() {
      if (this.isLoading != 'fetch') {
        // при изменении выбранного маршрута необходимо сбросить выбор графика, смены и ТС
        this.selectedGraph = null;
        this.selectedShift = null;
        this.selectedTSId = null;
      }
    },

    // вызывается при выборе графика
    selectedGraph() {
      // при изменении выбранного графика необходимо сбросить выбор графика, смены и ТС
      this.selectedShift = null;
      this.selectedTSId = null;
    },

    // вызывается при выборе смены
    selectedShift() {
      // при изменении выбранной смены необходимо сбросить выбор ТС
      this.selectedTSId = null;
    },

    // срабатывает, когда меняется список транспортных средств на карте
    vehiclesOnMap(newList) {
      // первый вызов после команды - позиционируем карту, чтобы влезли транспортные средства
      if (this.firstWatchAfterCommand) {
        if (newList.length === 1) {
          this.leaflet.setView([newList[0].latitude, newList[0].longitude], this.$store.getters['settings/getDefaultVehicleZoom']);
        } else if (newList.length > 1) {
          const bounds = latLngBounds(
              newList.map(vehicle => [vehicle.latitude, vehicle.longitude])
          )
          this.leaflet.fitBounds(bounds);
        }
        // сбрасываем флаг первого вызова
        this.firstWatchAfterCommand = false
        // выходим
        return
      }
      // если отслеживать не надо - выходим
      if (!this.keepWatch) return

      // отслеживаем, чтобы все транспортные средства попадали в карту если выбрана вкадка "Онлайн"
      if (!this.isVehiclesInTheMap()) {
        if (newList.length === 1) {
          this.leaflet.setView([newList[0].latitude, newList[0].longitude], this.leaflet.getZoom());
        } else if (newList.length > 1) {
          const bounds = latLngBounds(
              newList.map(vehicle => [vehicle.latitude, vehicle.longitude])
          )
          this.leaflet.fitBounds(bounds);
        }
      }
    },

    // смещаем карту для отрисовки всех ТС истории
    historyVehiclesOnMap(newList) {
      if (!this.isVehiclesInTheMap()) {
        if (newList.length === 1) {
          this.leaflet.setView([newList[0].latitude, newList[0].longitude], this.leaflet.getZoom());
        } else if (newList.length > 1) {
          const bounds = latLngBounds(
              newList.map(vehicle => [vehicle.latitude, vehicle.longitude])
          )
          this.leaflet.fitBounds(bounds);
        }
      }
    },
  },

  // вызывается при создании компонента
  async created() {
    if (this.date_index && this.transport_id && this.route_id
        && this.fact_trip_time_index_begin && this.fact_trip_time_index_end) {
      this.selectedRouteId = this.route_id;
      this.date = ndToDateString(this.date_index);
      this.selectedTSId = Number(this.transport_id);
    }
    // входим в специальный режим получения данных
    this.isLoading = 'fetch';
    // получаем маршруты
    await this.$store.dispatch('kvr/doFetchRoutes');
    // получаем предприятия
    await this.$store.dispatch('kvr/doFetchDepots');
    // получаем типы транспорта
    await this.$store.dispatch('ref/doFetchTransportTypes');
    // получаем типы транспорта
    await this.$store.dispatch('ref/doFetchModels');
    // перезапрашиваются данные
    await this.refreshData();
    // если страница открыта после нажатия кнопки "Рейс на карту"
    if (this.date_index && this.transport_id && this.route_id
        && this.fact_trip_time_index_begin && this.fact_trip_time_index_end) {
      await this.getVehicleNav();
      // в зависимости от времени ищем индекс начала интервала
      let ind = this.vehicle_history.findIndex(value => dateToSeconds(new Date(value.time_nav.slice(0, -1))) >= this.fact_trip_time_index_begin);
      this.selectedRowNavId = this.vehicle_history[ind].nav_id;
      let index = this.vehicle_history.findIndex(value => dateToSeconds(new Date(value.time_nav.slice(0, -1))) > this.fact_trip_time_index_end);
      this.onShiftSelect(index - 1);
      // this.selectedTSId = Number(this.transport_id);
    }
    this.isLoading = false;
  },

};

</script>

<style scoped>

</style>
