<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-xl modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white" style="border: 0">
          <modal-title>Выбор парков</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>


        <!-- Форма с кнопками над левой таблицей-->
        <crud-form
            :buttonsExist="{add: false, edit: false, delete: false, refresh: false}"
            @onFilterChanged="onFilterChanged"
            style="margin:0;"
        >

          <template #leftCustomButton>
            <!--          Поставить галочки всем ТС на экране-->
            <button style="padding: 0px; border-width: 0px; background-color: white; margin-top: 3px;"
                    @click="setAllSelected">
              <i :class='selectedItems.length === depots.length ? "fas fa-check-square fa-2xl fa-2x" : "far fa-check-square fa-2xl fa-2x"'
                 title="Выделить всё"
                 :style='(selectedItems.length === depots.length ? "color: #007BFF" : "color: #000000")'
              ></i>
            </button>
          </template>
          <template #leftCustomButton2>
            <!--            Снять галочки со всех ТС -->
            <button style="padding: 0px; border-width: 0px; background-color: white; margin-top: 3px;"
                    @click="setAllClear">
              <i :class='selectedItems.length === 0 ? "fas fa-square fa-2x" : "far fa-square fa-2x"'
                 title="Снять все"
                 :style='(selectedItems.length === 0 ? "color: #007BFF" : "color: #000000")'
              ></i>
            </button>
          </template>
          <template #leftCustomButton3>
            <!--            Снять галочки с тех ТС, что были выделенны, проставить на те, где были сняты -->
            <button style="padding: 0px; border-width: 0px; background-color: white; margin-top: 3px;"
                    @click="setAllSwitch">
              <i :class='(selectedItems.length !== 0 && selectedItems.length !== depots.length) ?  "fab fa-flipboard fa-2x" : "fab fa-flipboard fa-2x"'
                 :style='((selectedItems.length !== 0 && selectedItems.length !== depots.length) ? "color: #007BFF" : "color: #000000")'
              ></i>
            </button>
          </template>

        </crud-form>
        <modal-body>
          <form>
            <div class="table-container">
              <order-depots-edit-table
                  :selectedRow="selectedRow"
                  :depots="filteredDepots"
                  :columnList="columnList"
                  :filterText="filterText"
                  @onRowSelect="onRowSelect"
              ></order-depots-edit-table>
            </div>
          </form>
        </modal-body>

        <modal-footer>
          <button @click="onSubmit" class="btn btn-primary" :disabled="selectedItems.length == 0">Ок</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import OrderDepotsEditTable from "@/components/kvr/manual_orders/OrderDepotsEditTable";
import {getLegalForm} from "@/store/RefHelpers";
import {getOrderAgency} from "@/store/KvrHelpers";

export default {
  components: {OrderDepotsEditTable},
  emits: ['onSubmit', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      // выбранная строка
      selectedRow: null,
      activeOnly: false,
      filterText: '',
      // колонки
      columnList: [
          {
            field: "__isSelected",
            caption: "Вкл.",
            isSortable: false,
            sortType: 'boolean',
            displayType: 'checkbox',
            headerAlign: 'center',
            dataAlign: 'center',
            inputStyle: {
              width: '18px',
              height: '18px',
              marginTop: '5px'
            },
          },
          {
            field: "depot_id",
            caption: "Код",
            sortType: 'number',
            isFilterable: true,
            show: true,
          },
          {
            field: "long_name",
            caption: "Полное наименование",
            sortType: 'string',
            isFilterable: true,
            show: true,
          },
          {
            field: "short_name",
            caption: "Краткое наименование",
            sortType: 'string',
            isFilterable: true,
            show: true,
          },
          {
            field: "__orgName",
            caption: "Организация",
            sortType: 'string',
            isFilterable: true,
            show: true,
          },
          {
            field: "__legalFormName",
            caption: "Организационно-правовая форма",
            sortType: 'string',
            show: false,
          },
        ]
    };
  },

  computed: {

    // выбранные строки
    selectedItems() {
      let arr = []
      for (let i = 0; i < this.depots.length; i++) {
        if (this.depots[i].__isSelected) {
          arr.push(this.depots[i]);
        }
      }
      return arr;
    },

    depots() {
      let arr = [];
      this.$store.getters['kvr/getOrderDepots'].map(value => {
        if (!value.is_partial_access) {
          // сетаем предприятия выбранными
          value.__isSelected = true;
          // получаем органицазию
          let org = getOrderAgency(value.org_id);
          if (org) {
            value.__orgName = org.long_name;
          }
          // получаем организацонно-правовую форму
          let lf = getLegalForm(value.legal_form_id);
          if (lf) {
            value.__legalFormName = lf.long_name;
          }
          value.__cellStyle = {
            paddingTop: '0',
            paddingBottom: '0',
            paddingLeft: '6px',
            paddingRight: '6px'
          };
          arr.push(value);
        }
      })
      return arr;
    },

    filteredDepots(){
      // отфильтрованные маршруты
      let filteredDepots = [];
      // в случае текста в поле "фильтр" фильтруем маршруты
      if (this.filterText != '') {
        let str;
        filteredDepots = this.depots.filter((row) => {
          return this.columnList.some((col) => {
            let item = col.subField ? row[col.subField][col.field] : row[col.field];
            if (col.isFilterable && col.show) {
              if (item == null) {
                str = '';
              } else {
                str = String(item).toLowerCase();
              }
              return str.includes(this.filterText.toLowerCase());
            } else {
              return false;
            }
          });
        });
      } else {
        filteredDepots = [];
        this.depots.map(value => {
          filteredDepots.push(value)
        })
      }
      return filteredDepots;
    }

  },

  methods: {

    // нажата кнопка "Ок"
    onSubmit(){
      this.$emit('onSubmit', this.selectedItems)
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },


    // установить все галочки
    setAllSelected() {
      if (this.filterText != ''){
        this.filteredDepots.map(value => {
          value.__isSelected = true;
        })
      } else {
        this.depots.map(value => {
          value.__isSelected = true;
        })
      }
    },

    // снять все галочки
    setAllClear() {
      if (this.filterText != ''){
        this.filteredDepots.map(value => {
          value.__isSelected = false;
        })
      } else {
        this.depots.map(value => {
          value.__isSelected = false;
        })
      }
    },

    // переключить галочки
    setAllSwitch() {
      if (this.filterText != ''){
        this.filteredDepots.map(value => {
          value.__isSelected = !value.__isSelected;
        });
      } else {
        this.depots.map(value => {
          value.__isSelected = !value.__isSelected;
        });
      }
    },

  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}

.table-container {
  height: 500px;
}
</style>