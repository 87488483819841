<template>
    <!--    Карта-->
    <leaflet-map
        :zoom="zoom"
        :center="center"
        :contextMenu="mapContextMenu"
        :options="mapOptions"
        @onMapCreated="onMapCreated"
    >

      <!-- Элементы управления-->
      <leaflet-control-group>
        <!-- Базовые слои-->
        <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
      </leaflet-control-group>

      <!-- Линия типа рейса -->
      <base-polyline
          v-for="tripTypeLine in tripTypeLines"
          state="original"
          :key="tripTypeLine.polyline_id"
          :polyline="tripTypeLine"
      >
      </base-polyline>

      <!-- Зоны посадки высадки -->
      <base-zone
          v-for="(tripTypeZone) in tripTypeZones"
          :state="tripTypeZone === selectedTripTypeZone && isEditMode ? 'edit' : tripTypeZone === selectedTripTypeZone ? 'active' : 'original'"
          :key="tripTypeZone.trip_type_zone_id"
          :zone="tripTypeZone.zone"
          @onZoneChanged="$emit('onZoneChanged', $event)"
      >
      </base-zone>

      <!-- Остановки трассы -->
      <stop-marker
          v-for="stop in stops"
          type="3"
          :key="stop.stop_id"
          :stop="stop"
          :tooltip="stop.long_name"
          :contextMenu="stopContextMenu"
          :meta="stop.stop_id"
      ></stop-marker>

    </leaflet-map>
</template>

<script>

import {getStop} from "@/store/MultipassHelpers";

export default {
  props: [
      // линии типов рейсов
      'tripTypeLines',
      // зоны посадки высадки
      'tripTypeZones',
      // конечный остановки
      'stops',
      // активная зона
      'selectedTripTypeZone',
      // режим редактирования
      'isEditMode',
  ],
  emits: [
      // добавление зоны
      'onAddTripTypeZone',
      // изменение зоны
      'onZoneChanged'
  ],
  computed: {
    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom']
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter']
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },
  },
  data() {
    return {
      // карта
      leaflet: null,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // контекстное меню карты
      mapContextMenu: {
        contextmenu: true,
        contextmenuWidth: 180,
        contextmenuItems: []
      },
      // контекстное меню остановки
      stopContextMenu: {
        contextmenu: true,
        contextmenuInheritItems: false,
        contextmenuWidth: 180,
        contextmenuItems: [
          {
            name: 'addTripTypeZone',
            text: 'Добавить зону',
            callback: (event) => {
              if (event.relatedTarget && event.relatedTarget.__meta != null) {
                const stop = getStop(Number(event.relatedTarget.__meta));
                if (stop) {
                  this.$emit('onAddTripTypeZone', stop)
                }
              }
            }
          },
        ]
      },
    }
  },

  methods: {
    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },
  }
}
</script>
