import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ЖУРНАЛА УПРАВЛЯЮЩИХ ВОЗДЕЙСТВИЙ

export default {

    actions: {

       doFetchManagementJournal(state, payload) {
            return DoGet(
                '/trn/getManagementJournal/' + payload.date_index,// + '?depot_id=' + payload.depot_id + '&route_id=' + payload.route_id,
                '',
                null,
                'Запрос записей из журнала управляющих воздействий',
                false
            );
        }

    }

}