import ExternalSystemsIntegrationStore from "@/store/integration/ExternalSystemsIntegrationStore";
import EntityTypesIntegrationStore from "@/store/integration/EntityTypesIntegrationStore";
import LinkedEntitiesIntegrationStore from "@/store/integration/LinkedEntitiesIntegrationStore";

//  ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ KVR

export default {
    namespaced: true,
    modules:{
        ExternalSystemsIntegrationStore,
        EntityTypesIntegrationStore,
        LinkedEntitiesIntegrationStore,
    }
}