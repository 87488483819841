import {DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ ВЫПОЛНЕНИЕ КОМАНДЫ ЭКСПЕРТА

export default {
    actions: {

        // запрос списка объектов
        doFetchExecCommand: (context, payload) => {
            return DoPost(
                '/kvr-expert/execCommand',
                payload,
                null,
                'Выполнить команду эксперта',
                false,
                payload && payload.force,
                context.state.expertsCache,
                context.state.expertsCacheTimeout
            )
        },
    }
}
