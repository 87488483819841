<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-role-method-links"> </edit-table-panel>
</template>

<script>
import {getModule} from "@/store/SystemHelpers";

export default {
  props: ['id', 'module_id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__checked",
          caption: "Вкл",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.__checked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "__moduleName",
          caption: "Модуль",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "method_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "http_method",
          caption: "Http-метод",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "method_name",
          caption: "Метод",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "desc",
          caption: "Описание метода",
          sortType: 'string',
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      // фильтруем методы в зависимости от выбранного модуля
      const methods = this.$store.getters["system/getMethods"].filter(
          module => module.module_id === this.module_id || !this.module_id
      );
      const links = this.$store.getters["auth/getRoleMethodLinks"];
      return methods.map(method => {

        const module = getModule(method.module_id);

        method.__id = method.method_id;
        method.__moduleName = module && module.module_name || '';
        method.__checked = links.some(link => link.role_id == this.id && link.method_id == method.method_id);

        return method;
      });
    },
  },
};
</script>
