<template>
  <!--  <transition name="slide">-->
  <div class="panel overflow-hidden" :style="lineGraphDialogStyle">

    <button
        @click="delLeftTrip($event)"
        style="vertical-align: top; height: 100% !important; top: 0 !important; width: 11.31px !important; border: 0px !important; padding: 0px !important; background-color: red !important; font-weight: bolder !important; border-bottom-right-radius: 10px !important; border-top-right-radius: 10px !important;"
        title="Удаление рейса слева"
    >&lt;
    </button>
    <span style="color: white !important;">|</span>
    <button
        @click="startTrafficGraph($event)"
        style="vertical-align: top; height: 100% !important; width: 7.56px !important; border: 0px !important; padding: 0px !important; background-color: dodgerblue !important; font-weight: bolder !important; border-bottom-left-radius: 10px !important; border-bottom-right-radius: 10px !important; border-top-left-radius: 10px !important; border-top-right-radius: 10px !important;"
        title="Запуск движения на графике"
    >&darr;
    </button>
    <span
        style="color: white !important;">|</span>
    <button
        @click="minusMin()"
        style="vertical-align: top; height: 100% !important; width: 8px !important; border: 0px !important; padding: 0px !important; background-color: green !important; font-weight: bolder !important; border-bottom-left-radius: 10px !important; border-top-left-radius: 10px !important;"
        title="Уменьшение стоянки"
    >&ndash;
    </button>
    <input-time-trip-begin

        v-model="specifiedTrip.trip_complex.step_time_begin"
        size="5"
        maxlength="5"
        @blur="setTripBegin()"
        style="vertical-align: top; height: 100% !important; border: 0px !important; padding: 0px !important; width: 40px !important; min-width: 40px !important; max-width: 40px !important; line-height: 0px !important;"
        title="Корректировка времени убытия"
    ></input-time-trip-begin>
    <input-integer

        v-model="specifiedTrip.trip_complex.stop_time"
        size="3"
        maxlength="3"
        @blur="setStop($event, specifiedTrip.trip_complex)"
        style="vertical-align: top; height: 100% !important; border: 0px !important; padding: 0px !important; width: 30px !important; min-width: 30px !important; max-width: 30px !important; line-height: 0px !important; text-align: center !important; color: green !important;"
        title="Корректировка стоянки"
    ></input-integer>
    <button
        @click="plusMin($event, specifiedTrip.trip_complex)"
        style="vertical-align: top; height: 100% !important; width: 11.31px !important; border: 0px !important; padding: 0px !important; background-color: green !important; font-weight: bolder !important; border-bottom-right-radius: 10px !important; border-top-right-radius: 10px !important;"
        title="Увеличение стоянки"
    >+
    </button>
    <span v-if="specifiedTrip.trip_complex.null_trip_type_id > 0">
      <button
          @click="openTripTypeDialog($event, specifiedTrip)"
          style="vertical-align: top; height: 100% !important; width: 10.95px !important; border: 0px !important; padding: 0px !important; background-color: yellowgreen !important; border-bottom-left-radius: 10px !important; border-top-left-radius: 10px !important;"
          :title="getTripTypeTitle(specifiedTrip.trip_complex, 'null')"
      >{{ specifiedTrip.trip_complex.__null_direction_title }}
      </button>
    </span>
    <span v-else>
        <label
            class="placeholder-td">>
        </label>
    </span>
    <span v-if="getTripTypeShort(specifiedTrip.trip_complex.__trip_type_short).length > 1">
      <label
          class="placeholder-td">{{
          ((specifiedTrip.trip_complex.__trip_type_short && specifiedTrip.trip_complex.__trip_type_short.length) > 2 ? "" : "_")
        }}
      </label>
      <button
          @click="openTripTypeDialog($event, specifiedTrip)"
          style="vertical-align: top; height: 100% !important; border: 0px !important; padding: 0px !important; background-color: #e0e0e0 !important; font-family: monospace !important; border-bottom-left-radius: 10px !important; border-bottom-right-radius: 10px !important; border-top-left-radius: 10px !important; border-top-right-radius: 10px !important;"
          :title="getTripTypeTitle(specifiedTrip.trip_complex, 'tripType')"
      >{{ specifiedTrip.trip_complex.__trip_type_short }}
      </button>
    </span>
    <span v-else>
      <label
          class="placeholder-td"
      >{{((specifiedTrip.trip_complex.__schedule_event_title && specifiedTrip.trip_complex.__schedule_event_title.length > 1) ? "_" : "__")}}
      </label>
      <button
          @click="openTripTypeDialog($event, specifiedTrip)"
          style="vertical-align: top; height: 100% !important; border: 0px !important; padding: 0px !important; background-color: #e0e0e0 !important; font-family: monospace !important; border-bottom-left-radius: 10px !important; border-bottom-right-radius: 10px !important; border-top-left-radius: 10px !important; border-top-right-radius: 10px !important;"
          :title="getTripTypeTitle(specifiedTrip.trip_complex, 'scheduleEvent')"
      >{{ specifiedTrip.trip_complex.__schedule_event_title }}
      </button>
    </span>
    <button
        @click="extensionTrafficGraph($event, specifiedTrip.trip_complex)"
        style="vertical-align: top; height: 100% !important; border: 0px !important; padding: 0px !important; background-color: dodgerblue !important; font-weight: bolder !important; border-bottom-left-radius: 10px !important; border-top-left-radius: 10px !important;"
        title="Продление движения по графику"
    >&gt;
    </button>

  </div>
  <!--  </transition>-->
</template>

<script>

import InputTimeTripBegin from "@/components/rmtgen/InputTimeTripBegin";
import InputInteger from "@/components/ui/custom/InputInteger";
import {getStop} from "@/store/MultipassHelpers";
import {getScheduleEvent} from "@/store/RmtHelpers";

export default {
  name: "LineGraphDialog",
  props: ["columnItem", "lineGraphDialogStyle", "tripTypeCards"],
  emits: ['doDelLeftTrip', 'doStartTrafficGraph', 'doEditGraph', 'doMinusMin', 'doEditTripBegin', 'doEditStop', 'doPlusMin', 'doExtensionTrafficGraph', 'startTrafficGraph', 'openTripTypeDialog'],
  components: {InputInteger, InputTimeTripBegin},
  data() {
    return {
      tableMode: '',
      tripsRow: {
        __row_id: 1,
      },

      // выбранный рейс
      specifiedTrip: null,
    }
  },

  // watch: {
  //
  //   columnItem(newValue) {
  //     console.log(123123123123123123)
  //     this.specifiedTrip = {...newValue};
  //   }
  //
  // },

  methods: {

    delLeftTrip() {
      console.log(this.specifiedTrip, "отправляем запрос на удаление рейса слева");
      this.$emit('doDelLeftTrip', this.specifiedTrip, 0, 0);
    },

    minusMin() {
      console.log(this.specifiedTrip, "отправляем запрос на уменьшение стоянки");
      this.$emit('doMinusMin', this.specifiedTrip, 0, 0);
    },

    plusMin() {
      console.log(this.specifiedTrip, "отправляем запрос на увеличение стоянки");
      this.$emit('doPlusMin', this.specifiedTrip, 0, 0);
    },

    extensionTrafficGraph() {
      console.log(this.specifiedTrip, "отправляем запрос на продление движения по графику");
      this.$emit('doExtensionTrafficGraph', this.specifiedTrip, 0, 0);
    },

    setTripBegin() {
      if (!this.isLoading) {
        if (!Number.isInteger(Number(this.specifiedTrip.trip_complex.step_time_begin))) {
          this.specifiedTrip.trip_complex.step_time_begin = this.columnItem.trip_complex.step_time_begin;
        } else {
          console.log()
          if ((Number(this.specifiedTrip.trip_complex.step_time_begin) >= 0) && (this.specifiedTrip.trip_complex.step_time_begin != this.columnItem.trip_complex.step_time_begin)) {
            // отправляем запрос на редактирование
            console.log("отправляем запрос на редактирование времени отправления");
            this.$emit('doEditTripBegin', this.specifiedTrip, 0, 0);
          } else {
            this.specifiedTrip.trip_complex.step_time_begin = this.columnItem.trip_complex.step_time_begin;
          }
        }
      }
    },

    setStop() {
      if (!this.isLoading) {
        if (!Number.isInteger(Number(this.specifiedTrip.trip_complex.stop_time))) {
          this.specifiedTrip.trip_complex.stop_time = this.columnItem.trip_complex.stop_time;
        } else {
          if ((Number(this.specifiedTrip.trip_complex.stop_time) >= 0) && (this.specifiedTrip.trip_complex.stop_time != this.columnItem.trip_complex.stop_time)) {
            // отправляем запрос на редактирование
            console.log("отправляем запрос на редактирование времени остановки");
            this.$emit('doEditStop', this.specifiedTrip, 0, 0);
          } else {
            this.specifiedTrip.trip_complex.stop_time = this.columnItem.trip_complex.stop_time;
          }
        }
      }
    },

    startTrafficGraph(e) {
      this.$emit('startTrafficGraph', e, this.specifiedTrip);
    },

    openTripTypeDialog(e) {
      this.$emit('openTripTypeDialog', e, this.specifiedTrip);
    },

    // получаем тип рейса без букв направления
    getTripTypeShort(trip_type) {
      if (trip_type == undefined) {
        return "00"
      }
      return (trip_type.includes("AB", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type.includes("BA", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type)
    },

    // получаем текст для всплывающих подсказок
    getTripTypeTitle(trip_complex, type) {
      if (type == 'null') {
        let firstStop = getStop(this.tripTypeCards[trip_complex.null_trip_type_id][0].stop_id);
        let lastStop = getStop(this.tripTypeCards[trip_complex.null_trip_type_id][this.tripTypeCards[trip_complex.null_trip_type_id].length - 1].stop_id);
        return '(' + this.getTripTypeShort(trip_complex.null_trip_type_sign) + ') ' + firstStop.long_name + ' - ' + lastStop.long_name;
      } else if (type == 'tripType' && trip_complex.trip_type_id != 0) {
        let firstStop = getStop(this.tripTypeCards[trip_complex.trip_type_id][0].stop_id);
        let lastStop = getStop(this.tripTypeCards[trip_complex.trip_type_id][this.tripTypeCards[trip_complex.trip_type_id].length - 1].stop_id);
        return firstStop.long_name + ' - ' + lastStop.long_name;
      } else if (type == 'scheduleEvent') {

        if (trip_complex.schedule_event_id != 0) {
          let scheduleEvent = getScheduleEvent(trip_complex.schedule_event_id);
          return scheduleEvent.long_name;
        }
      }
    },

  },

  created() {

    // данное решение может вызывать проблемы
    let specifiedTrip = JSON.parse(JSON.stringify(this.columnItem));

    specifiedTrip.trip_complex.__trip_type_short = (specifiedTrip.trip_complex.trip_type_sign.includes("AB", specifiedTrip.trip_complex.trip_type_sign.length - 2) ? (specifiedTrip.trip_complex.trip_type_sign.substring(0, specifiedTrip.trip_complex.trip_type_sign.length - 2).includes("00", 0) ? "00" : specifiedTrip.trip_complex.trip_type_sign.substring(0, specifiedTrip.trip_complex.trip_type_sign.length - 2)) : specifiedTrip.trip_complex.trip_type_sign.includes("BA", specifiedTrip.trip_complex.trip_type_sign.length - 2) ? (specifiedTrip.trip_complex.trip_type_sign.substring(0, specifiedTrip.trip_complex.trip_type_sign.length - 2).includes("00", 0) ? "00" : specifiedTrip.trip_complex.trip_type_sign.substring(0, specifiedTrip.trip_complex.trip_type_sign.length - 2)) : specifiedTrip.trip_complex.trip_type_sign);
    if (specifiedTrip.trip_complex.schedule_event_id == 0) {
      specifiedTrip.trip_complex.__schedule_event_title = "КР";
    } else if (specifiedTrip.trip_complex.schedule_event_id == 4) {
      specifiedTrip.trip_complex.__schedule_event_title = "Т";
    } else if (specifiedTrip.trip_complex.schedule_event_id == 5) {
      specifiedTrip.trip_complex.__schedule_event_title = "О";
    } else if (specifiedTrip.trip_complex.schedule_event_id == 6) {
      specifiedTrip.trip_complex.__schedule_event_title = "Р";
    } else if (specifiedTrip.trip_complex.schedule_event_id == 7) {
      specifiedTrip.trip_complex.__schedule_event_title = "П";
    } else if (specifiedTrip.trip_complex.schedule_event_id == 8) {
      specifiedTrip.trip_complex.__schedule_event_title = "З";
    } else if (specifiedTrip.trip_complex.schedule_event_id == 9) {
      specifiedTrip.trip_complex.__schedule_event_title = "К";
    } else if (specifiedTrip.trip_complex.schedule_event_id == 10) {
      specifiedTrip.trip_complex.__schedule_event_title = "С";
    } else if (specifiedTrip.trip_complex.schedule_event_id == 11) {
      specifiedTrip.trip_complex.__schedule_event_title = "Е";
    } else if (specifiedTrip.trip_complex.schedule_event_id == 12) {
      specifiedTrip.trip_complex.__schedule_event_title = "В";
    } else if (specifiedTrip.trip_complex.schedule_event_id == 13) {
      specifiedTrip.trip_complex.__schedule_event_title = "И";
    } else {
      specifiedTrip.trip_complex.__schedule_event_title = "";
    }
    if (specifiedTrip.trip_complex.null_direction == 0) {
      specifiedTrip.trip_complex.__null_direction_title = ">";
    } else {
      specifiedTrip.trip_complex.__null_direction_title = "<";
    }

    this.specifiedTrip = specifiedTrip;
  }


}
</script>

<style scoped>

.panel {
  position: absolute;
  max-width: 165px;
  border: 1px solid black;
  width: 160px;
  height: 26px;
  /*top: 500px;*/
  /*left: 500px;*/
  /*padding-top: 2px;*/
  /*padding-bottom: 2px;*/
  background-color: white;
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); !* Параметры тени *!*/
  font-size: 90%;
  z-index: 100;
  white-space: nowrap;
}

.placeholder-td {
  opacity: 0.0;
}

</style>