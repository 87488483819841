<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable ">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Информация о ТС</modal-title>
          <button-close class="text-white" @click="$emit('onRejectOperation')"></button-close>
        </modal-header>
        <modal-body>

          <table class="table table-sm table-striped">
            <tbody>
            <tr>
              <td>Парк</td>
              <td>{{ this.item.depot_title }}</td>
            </tr>
            <tr>
              <td>Тип ТС</td>
              <td>{{ this.item.transport_type_title }}</td>
            </tr>
            <tr>
              <td>Модель</td>
              <td>{{ this.item.model_title }}</td>
            </tr>
            <tr>
              <td>Гаражный №</td>
              <td>{{ this.item.garage_num }}</td>
            </tr>
            <tr>
              <td>Гос. №</td>
              <td>{{ this.item.state_num }}</td>
            </tr>
            <tr>
              <td>Маршрут</td>
              <td>{{ this.item.route_num }}</td>
            </tr>
            <tr>
              <td>График</td>
              <td>{{ this.item.graph }}</td>
            </tr>
            <tr>
              <td>Смена</td>
              <td>{{ this.item.shift }}</td>
            </tr>
            <tr>
              <td>Радиостанция</td>
              <td>{{ this.item.radio_num + ' (' + getRadioModelTitle(this.item) + ')' }}</td>
            </tr>
            <tr>
              <td>Время</td>
              <td>{{ getTimeFormat }}</td>
            </tr>
            <tr>
              <td>Широта</td>
              <td>{{ this.item.latitude }}</td>
            </tr>
            <tr>
              <td>Долгота</td>
              <td>{{ this.item.longitude }}</td>
            </tr>
            <tr>
              <td>Скорость</td>
              <td>{{ this.item.speed }}</td>
            </tr>
            </tbody>
          </table>

        </modal-body>
        <modal-footer>
          <button @click="$emit('onRejectOperation')" class="btn btn-primary">Закрыть</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import {getDateTimeFormat} from "@/lib";
import {getRadioModel} from "@/store/NavHelpers";


export default {
  props: ['item', 'op'],
  emits: ['onOK', 'onRejectOperation'],
  mixins: [ValidateFormMixin],
  // data() {
  //   return {
  //     transport_id: this.item.transport_id,
  //     transport_type_id: this.item.transport_type_id,
  //     transport_type_title: this.item.transport_type_title,
  //     model_id: this.item.model_id,
  //     model_title: this.item.model_title,
  //     transport_class_id: this.item.transport_class_id,
  //     transport_class_title: this.item.transport_class_title,
  //     state_num: this.item.state_num,
  //     garage_num: this.item.garage_num,
  //     special_header: this.item.special_header,
  //     radio_id: this.item.radio_id,
  //     radio_num: this.item.radio_num,
  //     radio_model_id: this.item.radio_model_id,
  //     radio_model_title: this.item.radio_model_title,
  //     time_nav: this.item.time_nav,
  //     time_insert: this.item.time_insert,
  //     time_delta: this.item.time_delta,
  //     latitude: this.item.latitude,
  //     longitude: this.item.longitude,
  //     speed: this.item.speed,
  //     angle: this.item.angle,
  //     is_invalid: this.item.is_invalid,
  //     is_history: this.item.is_history,
  //     is_ign: this.item.is_ign,
  //     is_call: this.item.is_call,
  //     is_sos: this.item.is_sos,
  //
  //   };
  // },

  computed: {
    getTimeFormat() {
      return getDateTimeFormat(this.item.time_nav);
    }
  },
  methods: {
    getRadioModelTitle(item) {
      if (item.radio_model_title) {
        return item.radio_model_title;
      } else {
        return getRadioModel(item.radio_model_id) ? getRadioModel(item.radio_model_id).radio_model_title : '';
      }
    },
  }
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>
