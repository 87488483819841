import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДАТЧИКОВ

export default {
    state() {
        return {
            // список объектов
            sensors: [],
            // время обновления кэша new Date().getTime()
            sensorsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            sensorsCacheTimeout: null
        }
    },
    getters: {

        // вернуть список объектов
        getSensors(state) {
            return state.sensors;
        },

        // вернуть список аналоговых датчиков с дополнительными полями
        getAnalogSensors(state) {
            let arr = [];
            // добавляем в массив датчики заданного типа
            state.sensors.forEach(value => {
                if (value.sensor_type_id == 0) {
                    value.title = value.sensor_sign + ' - ' +value.sensor_title;
                    value.value = value.sensor_sign;
                    value.id = value.sensor_id;
                    arr.push(value);
                }
            })
            // сортируем полученные датчики
            arr.sort(function (a, b) {
                if(a.sensor_sign.toLowerCase() < b.sensor_sign.toLowerCase()) { return -1; }
                if(a.sensor_sign.toLowerCase() > b.sensor_sign.toLowerCase()) { return 1; }
                return 0;
            });
            return arr;
        },

        // вернуть список дискретных датчиков с дополнительными полями
        getDiscreteSensors(state) {
            let arr = [];
            // добавляем в массив датчики заданного типа
            state.sensors.forEach(value => {
                if (value.sensor_type_id == 1) {
                    value.title = value.sensor_sign + ' - ' +value.sensor_title;
                    value.value = value.sensor_sign;
                    value.id = value.sensor_id;
                    arr.push(value);
                }
            })
            // сортируем полученные датчики
            arr.sort(function (a, b) {
                if(a.sensor_sign.toLowerCase() < b.sensor_sign.toLowerCase()) { return -1; }
                if(a.sensor_sign.toLowerCase() > b.sensor_sign.toLowerCase()) { return 1; }
                return 0;
            });
            return arr;
        }
    },
    mutations: {
        // установить новый список объектов
        setSensors(state, payload) {
            state.sensors = payload;
            state.sensorsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchSensors: (context, payload) => {
            return DoGet(
                '/nav/sensors',
                'nav/setSensors',
                [],
                'Запрос датчиков',
                false,
                payload && payload.force,
                context.state.sensorsCache,
                context.state.sensorsCacheTimeout
            )
        },

        // запрос объекта
        doFetchSensor: (context, payload) => {
            return DoGet(
                '/nav/sensors/' + payload.sensor_id,
                null,
                [],
                'Запрос датчика',
                false,
                payload && payload.force,
                context.state.sensorsCache,
                context.state.sensorsCacheTimeout
            )
        },

        // очищает кэш
        doClearSensors: (context, payload) => {
            return DoClearCache(
                'nav/setSensors',
                [],
                payload && payload.force,
                context.state.sensorsCache,
                context.state.sensorsCacheTimeout
            )
        },

    }
}