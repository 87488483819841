<template>

  <align-container>

    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/nav">Телематика</router-link></span>
        <span> / </span>
        <span>Группы датчиков</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <div class="container position-relative h-100">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">
            <crud-form
                :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            >
            </crud-form>

          </align-panel>
          <!-- Таблица  -->
          <align-panel align="all">
            <sensor-groups-edit-table
                :filterText="filterText"
                :isLoading="isLoading"
                :selectedRow="selectedRow"
                @onRowSelect="onRowSelect"
            ></sensor-groups-edit-table>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import SensorGroupsEditTable from "@/components/nav/sensor_groups/SensorGroupsEditTable";

export default {
  mixins: [PageMixin],

  components: {
    SensorGroupsEditTable,
  },

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }
  },
  methods: {

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('nav/doFetchSensorGroups', {force})

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

