<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span>Транспортные предприятия</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!--        Выбор даты начала  -->
            <template #leftCustomButton>
              <input-date
                  id="startDate"
                  v-model="startDate"
                  class="form-control"
                  @change="setDate"
              >
              </input-date>
            </template>

            <!--        Снять галочки со всех маршрутов  -->
            <template #leftCustomButton2>
              <input-date
                  id="endDate"
                  v-model="endDate"
                  class="form-control"
                  @change="setDate"
              >
              </input-date>
            </template>

            <!-- Кнопка связь  -->
            <template #leftCustomButton3>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Аналитические формы
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="payTransProcStatusByTransportLink">Журнал обработки
                    транзакций за {{ endDate }}
                  </dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <!--                  <dropdown-item router-link :to="payTransProcByDepotLink">По оплаченным поездкам-->
                  <!--                    суммарно-->
                  <!--                  </dropdown-item>-->
                  <dropdown-item router-link :to="payTransByDepotDayLink">По оплаченным поездкам по
                    датам
                  </dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item router-link :to="payTransByRouteLink">По оплаченным поездкам по
                    маршрутам суммарно
                  </dropdown-item>
                  <dropdown-item router-link :to="payTransByRouteDayLink">По оплаченным поездкам по
                    маршрутам по датам
                  </dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Группы-->
            <template #leftCustomButton4>
              <depot-groups-select class="form-control" v-model="depotGroupId"
                                   @change="setDepotGroupId"></depot-groups-select>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <asop-depots-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :depotGroupId="depotGroupId"
              :selectedRow="selectedRow"
              :payTransByDepot="payTransByDepot"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></asop-depots-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <depot-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
  >
  </depot-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import DepotDialog from "@/components/ref/depots/DepotDialog";
import DepotGroupsSelect from "@/components/ref/depot_groups/DepotGroupsSelect";
import PageMixin from "@/pages/share/PageMixin";
import {dateToND, dateToString, getDateMinusMonth, ndToDate, stringToDate} from "@/lib";
import DropdownDivider from "@/components/ui/bootstrap/dropdown/DropdownDivider";
import AsopDepotsEditTable from "@/components/asmpp/depots/AsopDepotsEditTable";

export default {
  mixins: [PageMixin],

  components: {
    AsopDepotsEditTable,
    DropdownDivider,
    DepotDialog,
    DepotGroupsSelect
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      depotGroupId: null,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // оригинальный список строк
      depots: [],
      // режим отметки строк
      chosenRows: '',
      // дата начала периода
      startDate: dateToString(getDateMinusMonth(ndToDate(dateToND() - 1))),
      // дата конца периода
      endDate: dateToString(ndToDate(dateToND() - 1)),

      payTransByDepot: [],
    }
  },

  computed: {

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show')
    },

    // данные, которые отображаются в диалоговом окне
    dialogRow() {
      return this.selectedRow;
    },

    // условие отключения кнопки перехода к рейсам
    linkDisabled() {
      return !this.selectedRow;
    },

    // переходим к результатам обработки платежных транзакций по ТС парка за сутки
    payTransProcStatusByTransportLink() {
      if (this.selectedRow) {
        return "/asmpp/asopDepots/" + this.selectedRow.depot_id + "/" + dateToND(stringToDate(this.endDate)) + "/payTransProcStatusByTransport";
      } else {
        return '';
      }
    },

    // переходим к показателям пассажиропотока по оплаченным поездкам по парку
    payTransProcByDepotLink() {
      if (this.selectedRow) {
        return "/asmpp/asopDepots/" + this.selectedRow.depot_id + "/" + dateToND(stringToDate(this.startDate)) + "/" + dateToND(stringToDate(this.endDate)) + "/payTransByDepot";
      } else {
        return '';
      }
    },

    // переходим к показателям пассажиропотока по оплаченным поездкам по парку с группировкой по датам за выбранный период
    payTransByDepotDayLink() {
      if (this.selectedRow) {
        return "/asmpp/asopDepots/" + this.selectedRow.depot_id + "/" + dateToND(stringToDate(this.startDate)) + "/" + dateToND(stringToDate(this.endDate)) + "/payTransByDepotDay";
      } else {
        return '';
      }
    },

    // переходим к показателям пассажиропотока по оплаченным поездкам по парку с группировкой по датам за выбранный период
    payTransByRouteLink() {
      if (this.selectedRow) {
        return "/asmpp/asopDepots/" + this.selectedRow.depot_id + "/" + dateToND(stringToDate(this.startDate)) + "/" + dateToND(stringToDate(this.endDate)) + "/payTransByRoute";
      } else {
        return '';
      }
    },

    // переходим к показателям пассажиропотока по оплаченным поездкам по парку с группировкой по датам за выбранный период
    payTransByRouteDayLink() {
      if (this.selectedRow) {
        return "/asmpp/asopDepots/" + this.selectedRow.depot_id + "/" + dateToND(stringToDate(this.startDate)) + "/" + dateToND(stringToDate(this.endDate)) + "/payTransByRouteDay";
      } else {
        return '';
      }
    },
  },


  methods: {

    // нажата конпка "Просмотр"
    onTableShow() {
      this.$router.replace("/asmpp/asopDepots/show/" + this.selectedRow.depot_id);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      if (item)
        this.$store.commit('asmpp/setAsopSelectedDepotId', item.depot_id);
      this.selectedRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/asmpp/asopDepots");
    },

    // сохраняем DepotId в стор
    setDepotGroupId() {
      this.$store.commit('asmpp/setAsopDepotsDepotGroupId', this.depotGroupId);
    },

    // сохраняем даты в стор
    async setDate() {
      this.$store.commit('asmpp/setStartDate', this.startDate);
      this.$store.commit('asmpp/setEndDate', this.endDate);

      // обновляем данные
      this.isLoading = true;
      try {
        if (this.depots.length != 0) {
          let depot_id_list = [];
          this.depots.forEach(value => {
            depot_id_list.push(value.depot_id)
          })
          // получаем строки таблицы
          let payTransByDepot = await this.$store.dispatch('asmpp/doFetchPayTransByDepot', {
            depot_id_list: depot_id_list,
            date_index_begin: dateToND(new Date(this.startDate)),
            date_index_end: dateToND(new Date(this.endDate)),
          });
          if (payTransByDepot)
            this.payTransByDepot = payTransByDepot;
        }
      } finally {
        this.isLoading = false;
      }
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = "fetch";
      try {

        // проверяем есть ли даты и в сторе, если есть, то подставляем
        let startDate = this.$store.getters['asmpp/getStartDate'];
        let endDate = this.$store.getters['asmpp/getEndDate'];
        let depotGroupId = this.$store.getters['asmpp/getAsopDepotsDepotGroupId'];

        if (startDate) {
          this.startDate = startDate;
        }
        if (endDate) {
          this.endDate = endDate;
        }
        if (depotGroupId) {
          this.depotGroupId = depotGroupId;
        }


        // getAsopDepotsDepotGroupId
        await this.$store.dispatch('ref/doFetchLegalForms');
        await this.$store.dispatch('ref/doFetchOrganizations');
        await this.$store.dispatch('ref/doFetchDepotGroups');
        await this.$store.dispatch('ref/doFetchDepotGroupLinks');
        let depots = await this.$store.dispatch('ref/doFetchDepots', {force});
        if (depots) {
          this.depots = depots;
          let depot_id_list = [];
          this.depots.forEach(value => {
            depot_id_list.push(value.depot_id)
          })
          // получаем строки таблицы
          let payTransByDepot = await this.$store.dispatch('asmpp/doFetchPayTransByDepot', {
            depot_id_list: depot_id_list,
            date_index_begin: dateToND(new Date(this.startDate)),
            date_index_end: dateToND(new Date(this.endDate)),
          });
          if (payTransByDepot)
            this.payTransByDepot = payTransByDepot;

        }

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
