<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/kvr">КВР</router-link></span>
        <span> / </span>
        <span>Список маршрутов</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">

          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, check: true, uncheck: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
              @onCheck="onTableCheck"
              @onUncheck="onTableUncheck"
          >

            <template #leftCustomButton>
              <route-groups-select class="form-control" v-model="routeGroupId"></route-groups-select>
            </template>

          </crud-form>

        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <routes-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :routeGroupId="routeGroupId"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
          ></routes-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import RoutesEditTable from "@/components/kvr/routes/RoutesEditTable";
import RouteGroupsSelect from "@/components/multipass/route_groups/RouteGroupsSelect";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    RoutesEditTable,
    RouteGroupsSelect
  },

  props: [
    // операция show передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      //группа
      routeGroupId: null,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true,
        check: (!!this.selectedRow && !this.selectedRow.is_included),
        uncheck: (!!this.selectedRow && this.selectedRow.is_included)
      }
    },
  },
  methods: {

    // нажата кнопка Включить
    onTableCheck() {
      this.includeRoute();
    },

    // нажата кнопка Выключить
    onTableUncheck() {
      this.excludeRoute();
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // включаем маршрут в расчет модуля КВР
    async includeRoute() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('kvr/doIncludeRouteExt', {
          route_id: this.selectedRow.route_id,
        });

      }
      finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // включаем маршрут в расчет модуля КВР
    async excludeRoute() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('kvr/doExcludeRouteExt', {
          route_id: this.selectedRow.route_id,
        });

      }
      finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchTransportTypes');
        await this.$store.dispatch('multipass/doFetchRouteKinds');
        await this.$store.dispatch('multipass/doFetchRouteTypes');
        await this.$store.dispatch('multipass/doFetchTransferKinds');
        await this.$store.dispatch('multipass/doFetchRouteStatuses');
        await this.$store.dispatch('multipass/doFetchRouteGroups');
        await this.$store.dispatch('multipass/doFetchRouteGroupLinks');
        await this.$store.dispatch('kvr/doFetchRoutesExt', {force});

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

