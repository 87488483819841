<template>

  <div class="container position-relative h-100 overflow-auto">
    <change-password-form class="m-2" @onChangePassword="onChangePassword"></change-password-form>
    <profile-info-form class="m-2" :item="profile" @onRefresh="onRefresh"></profile-info-form>
  </div>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import ProfileInfoForm from "@/components/auth/users/ProfileInfoForm";
import ChangePasswordForm from "@/components/auth/users/ChangePasswordForm";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixin: [PageMixin],

  components: {
    ProfileInfoForm,
    ChangePasswordForm
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    // текущий профиль
    profile() {
      return this.$store.getters['auth/getProfile'];
    }
  },

  methods: {
    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = true;
      try {

        await this.$store.dispatch('auth/doFetchProfile', { force });

      }
      finally {
        this.isLoading = false;
      }
    },

    // обновление профиля
    onRefresh() {
      this.refreshData(false)
    },

    // изменение пароля
    async onChangePassword(data) {
      this.isLoading = true;
      try {

        await this.$store.dispatch('auth/doChangeUserPassword', data);

      }
      finally {
        this.isLoading = false;
      }
    }
  },

  // вызывается при создании компонента
  async created() {
    // перезапрашиваются данные
    await this.refreshData();
  },
}
</script>
