<template>
  <edit-table-panel :columnList="columnList" name="tbl-ref-details-ind"></edit-table-panel>
</template>

<script>

export default {
  data() {
    return {
      columnList:  [
        {
          field: "__id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "ind_last_name",
          subField: "detail",
          caption: "Фамилия",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "ind_first_name",
          subField: "detail",
          caption: "Имя",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "ind_middle_name",
          subField: "detail",
          caption: "Отчество",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "ind_birthday",
          subField: "detail",
          caption: "Дата рождения",
          sortType: 'string',
          isFilterable: false,
          show: false,
        },
        {
          field: "ind_inn",
          subField: "detail",
          caption: "ИНН",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "ind_legal_address",
          subField: "detail",
          caption: "Адрес регистрации",
          sortType: 'string',
          isFilterable: true,
          show: false,
        },
        {
          field: "ind_actual_address",
          subField: "detail",
          caption: "Адрес фактического проживания",
          sortType: 'string',
          isFilterable: true,
          show: false,
        },
        {
          field: "ind_phone_num",
          subField: "detail",
          caption: "Номера телефонов",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "ind_email",
          subField: "detail",
          caption: "Электронная почта",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__indIdentityType",
          subField: "detail",
          caption: "Тип документа",
          sortType: 'string',
          show: false,
        },
        {
          field: "ind_identity_data",
          subField: "detail",
          caption: "Информация из документа",
          sortType: 'string',
          show: false,
        },
        {
          field: "__parent_detail_title",
          caption: "Верхн. уровень",
          sortType: 'string',
        }
      ]
    }
  },
};
</script>
