<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-multipass-route-groups"></edit-table-panel>
</template>

<script>
export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "route_group_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "route_group_name",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        }
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      return this.$store.getters["multipass/getRouteGroups"].map(group => {
        group.__id = group.route_group_id;

        return group;
      });
    },
  },
};
</script>
