<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Организация</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="orgId">Код:</label>
              <input-integer
                id="orgId"
                class="form-control w-25"
                v-model="orgId"
                readonly
              />
            </div>
            <div class="form-group">
              <label for="longName">Полное наименование:</label><req-field />
              <input-string
                  class="form-control"
                  id="longName"
                  v-model="longName"
                  ref="longName"
                  :readonly="op === 'show'"
                  required
                  error="Введите полное наименование"
              />
            </div>
            <div class="form-group">
              <label for="shortName">Краткое наименование:</label><req-field />
              <input-string
                  class="form-control"
                  id="shortName"
                  v-model="shortName"
                  ref="shortName"
                  :readonly="op === 'show'"
                  required
                  error="Введите краткое наименование"
              />
            </div>
            <div class="form-group">
              <label for="isAgency">Перевозчик:</label>
              <input-switch
                  id="isAgency"
                  v-model="isAgency"
                  ref="isAgency"
                  :readonly="op === 'show'"
              />
            </div>
            <div class="form-group">
              <label for="parentOrgId">Вышестоящая организация:</label>
              <organizations-select
                  class="form-control"
                  id="parentOrgId"
                  v-model="parentOrgId"
                  ref="parentOrgId"
                  :readonly="op === 'show'"
              />
            </div>
            <div class="form-group">
              <label for="level">Уровень в иерархии:</label>
              <input-integer
                  class="form-control w-25"
                  id="level"
                  v-model="level"
                  ref="level"
                  :readonly="op === 'show'"
                  :min-value="0"
              />
            </div>
            <div class="form-group">
              <label for="legalFormId">Организационно-правовая форма:</label>
              <legal-forms-select
                  class="form-control"
                  id="legalFormId"
                  v-model="legalFormId"
                  ref="legalFormId"
                  :readonly="op === 'show'"
              />
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import LegalFormsSelect from "@/components/ref/legal_forms/LegalFormsSelect";
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import OrganizationsSelect from "@/components/ref/organizations/OrganizationsSelect";

export default {
  components: {OrganizationsSelect, LegalFormsSelect},
  props: ['item', 'op'],
    emits: ['onOK', 'onCancel'],
    mixins: [ValidateFormMixin],
    data() {
        return {
          orgId: this.item.org_id,
          shortName: this.item.short_name,
          longName: this.item.long_name,
          legalFormId: this.item.legal_form_id,
          isAgency: this.item.is_agency === false ? false : true,
          level: this.item.level,
          parentOrgId: this.item.parent_org_id
        };
    },
    methods: {
        onSubmit() {
          if (this.validateForm(this.$refs)) {
              this.$emit('onOK', {
                org_id: this.op === 'add' ? undefined : this.orgId,
                short_name: this.shortName,
                long_name: this.longName,
                legal_form_id: this.legalFormId,
                is_agency: this.isAgency,
                level: this.level,
                parent_org_id: this.parentOrgId
              })
          }
        },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>