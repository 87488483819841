<template>
  <align-container>
    <align-panel align="top">
      <page-header>
        <span><router-link to="/kvr">КВР</router-link></span>
        <span> / </span>
        <span><router-link to="/kvr/workTasks">Мониторинг</router-link></span>
        <span> / </span>
        <span>Информация по ТС ({{ getTsHeader }}) за {{ getDateForHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <!--    разделяем страницу на правую и левую стороны  -->
      <align-container>
        <align-panel align="left" width="60%" :gap="3">
          <!--        разделяем левую часть на верхнюю и нижнюю  -->
          <align-container>
            <align-panel align="top" height="50%">
              <!--            панель "Задания"  -->
              <align-container>
                <!-- Форма с кнопками -->
                <align-panel align="top" :gap="3">
                  <card class="text-left">
                    <card-body class="p-1">
                      <div class="form-row">
                        <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                          <p style="margin: 0px 10px 0 14px; font-size: 1.2rem; font-weight: bold">Задания</p>
                        </div>
                        <div class="col"></div>
                        <div class="col-0" style="margin-bottom: 0 !important;">
                          <button
                              type="button"
                              class="btn btn-secondary btn-sm"
                              @click="onTasksTableRefresh"
                              title="Обновить"
                          >
                            <i class="fas fa-sync-alt" style="right: 7px"></i>
                          </button>
                        </div>
                      </div>
                    </card-body>
                  </card>
                </align-panel>
                <!--     таблица заданий         -->
                <align-panel align="all">
                  <vehicle-work-tasks-edit-table
                      :filterText="filterText"
                      :isLoading="isLoading"
                      :selectedRow="selectedVehicleWorkTasksRow"
                      :rows="vehicleWorkTasks"
                      @onRowSelect="onTasksRowSelect"
                  ></vehicle-work-tasks-edit-table>
                </align-panel>
              </align-container>
            </align-panel>
            <!--            панель "Рейсы"-->
            <align-panel align="all">
              <align-container>
                <!--     Форма с кнопками            -->
                <align-panel align="top" :gap="3">
                  <card class="text-left">
                    <card-body class="p-1">
                      <div class="form-row">
                        <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                          <p style="margin: 0px 10px 0 14px;  font-size: 1.2rem; font-weight: bold">Рейсы</p>
                        </div>
                        <!-- Кнопка Команда-->
                        <div class="col-0" style="margin-bottom: 0 !important;">
                          <dropdown>
                            <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                            :disabled="commandDisabled"
                                            style="padding-top: 0; padding-bottom: 0; height: 31px;">
                              Команда
                            </button-toggler>
                            <dropdown-menu ref="collapse">
                              <dropdown-item @click.prevent="onShowTripCardItems">Отметки на рейсе
                              </dropdown-item>
                              <dropdown-item @click.prevent="onTripTypeOnMap">Рейс на карту
                              </dropdown-item>
                            </dropdown-menu>
                          </dropdown>
                        </div>

                        <div class="col-0" style="margin-bottom: 0 !important;">
                          <input-checkbox class="mt-2" v-model="isGrouped" id="isGrouped"
                                          style="margin-top: 4px !important;" @change="viewChange">Сгруппировать
                          </input-checkbox>
                        </div>
                        <div class="col-0" style="margin-bottom: 0 !important;">
                          <input-checkbox class="mt-2" v-model="isRecognizedOnly" id="isRecognizedOnly"
                                          style="margin-top: 4px !important;" @change="viewChange">Только распознанные
                          </input-checkbox>
                        </div>
                        <div class="col"></div>
                        <div class="col-0" style="margin-bottom: 0 !important;">
                          <button
                              type="button"
                              class="btn btn-secondary btn-sm"
                              @click="onWorkTaskFactTripsUpdate"
                              title="Обновить"
                          >
                            <i class="fas fa-sync-alt" style="right: 7px"></i>
                          </button>
                        </div>
                      </div>
                    </card-body>
                  </card>
                </align-panel>
                <!--    таблица рейсов            -->
                <align-panel align="all">
                  <work-task-fact-trips-edit-table
                      :filterText="filterText"
                      :isLoading="isLoading"
                      :selectedRow="selectedWorkTaskFactTripsRow"
                      :rows="getWorkTaskFactTrips"
                      @onRowSelect="onWorkTaskFactTripsRowSelect"
                  ></work-task-fact-trips-edit-table>
                </align-panel>
                <align-panel align="bottom">
                  <!-- Форма с кнопками под левой таблицей-->
                  <card class="text-right">
                    <card-body class="p-1">
                      <div class="form-row">
                        <!--          кладем div с классом "col", чтобы он заполнил пространство слева от "Итого распознано"-->
                        <div class="col"></div>
                        <div class="input-group mb-3 col-0" style="margin-bottom: 0 !important; width: 250px; ">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-total-ind-time" style="height: 31px">Итого распознано</span>
                          </div>
                          <input disabled="true" style="width: 50px; height: 31px" type="text" class="form-control"
                                 aria-describedby="inputGroup-sizing-default" v-model="recognizedCount">
                        </div>
                      </div>
                    </card-body>
                  </card>
                </align-panel>
              </align-container>
            </align-panel>
          </align-container>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <align-panel align="top" :gap="3">
              <card class="text-left">
                <card-body class="p-1">
                  <div class="form-row">
                    <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">

                      <p style="margin: 0px 10px 0 14px; font-size: 1.2rem; font-weight: bold">Фактические отметки</p>
                    </div>
                    <div class="col-0" style="margin-bottom: 0 !important;">
                      <input-checkbox class="mt-2" v-model="isReisOnly" id="isReisOnly"
                                      style="margin-top: 4px !important;">
                        Только принадлежащие рейсу
                      </input-checkbox>
                    </div>
                    <div class="col"></div>
                    <div class="col-0" style="margin-bottom: 0 !important;">
                      <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          @click="onCPNavsUpdate"
                          title="Обновить"
                          :style="'marginLeft: 5px'"
                      >
                        <i class="fas fa-sync-alt" style="right: 7px"></i>
                      </button>
                    </div>
                  </div>
                </card-body>
              </card>
            </align-panel>
            <align-panel align="all">
              <work-task-c-p-nav-edit-table
                  :filterText="filterText"
                  :isLoading="isLoading"
                  :rows="workTaskCPNavs"
                  :selectedRow="selectedWorkTaskCPNavRow"
                  :cpnav_id_list="selectedWorkTaskFactTripsRow ? selectedWorkTaskFactTripsRow.cpnav_id_list : []"
                  :isReisOnly="isReisOnly"
                  @onRowSelect="onFactMarksRowSelect"
              ></work-task-c-p-nav-edit-table>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!--  Диалог "Отметки на рейсе"-->
  <trip-card-items-dialog
      v-if="isDialogVisible"
      :rows="tripCardItems"
      @onCancel="onRejectOperation"
      @onRefresh="getTripCard"
  ></trip-card-items-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>
</template>

<script>

import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import VehicleWorkTasksEditTable from "@/components/kvr/information_by_ts/VehicleWorkTasksEditTable";
import WorkTaskCPNavEditTable from "@/components/kvr/information_by_ts/WorkTaskCPNavEditTable";
import WorkTaskFactTripsEditTable from "@/components/kvr/information_by_ts/WorkTaskFactTripsEditTable";
import {ndToDateString, numSortFn, quickSort, workTaskFactTripSortFn} from "@/lib";
import TripCardItemsDialog from "@/components/kvr/trip_card_items/TripCardItemsDialog";

export default {
  name: "informationByTsPage",
  components: {
    TripCardItemsDialog,
    WorkTaskFactTripsEditTable,
    WorkTaskCPNavEditTable,
    VehicleWorkTasksEditTable, AlignPanel, AlignContainer
  },
  props: [
    // индекс даты в часовом поясе системы (количество дней с 01.01.2000)
    'date_index',
    // идентификатор транспортного средства
    'transport_id',
    // операция show передается через роутер
    'op',
    // идентификатор фактического рейса
    'fact_trip_id',
  ],
  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка задания
      selectedVehicleWorkTasksRow: null,
      // выбранная строка рейса
      selectedWorkTaskFactTripsRow: null,
      // выбранная строка Фактичских отметок
      selectedWorkTaskCPNavRow: null,
      // отображать спиннер
      isLoading: false,
      // задания
      vehicleWorkTasks: [],
      // рейсы
      workTaskFactTrips: [],
      // фактические отметки
      workTaskCPNavs: [],
      // галочка "Только принадлежащие рейсу"
      isReisOnly: false,
      // галочка "Сгруппировать" - когда true показывает все рейсы без базового кода
      isGrouped: false,
      // галочка "Только распознанные" - когда true оставляет только распознанные рейсы
      isRecognizedOnly: false,
      // строки карточки фактического рейса
      tripCardItems: [],
    }
  },

  computed: {

    // условие отключения кнопки "Команда"
    commandDisabled() {
      return !this.selectedWorkTaskFactTripsRow;
    },

    //подсчет распознаных рейсов
    recognizedCount() {
      let i = 0
      this.workTaskFactTrips.map(value => {
        if (value.is_recognized == true) {
          i++;
        }
      })
      return i;
    },

    // список рейсов, вернет отфильтрованный, если стоят галочки "Сгруппировать" и "Только распознанные"
    getWorkTaskFactTrips() {
      let afterGroupingArr = [];
      //  если стоит галочка "Сгруппировать"
      this.workTaskFactTrips.map(reis => {
        if (this.isGrouped) {
          if (!reis.parent_fact_trip_id || reis.parent_fact_trip_id == '') {
            afterGroupingArr.push(reis);
          }
        } else {
          afterGroupingArr.push(reis);
        }
      });
      let afterRecognizingArr = [];
      afterGroupingArr.map(reis => {
        if (this.isRecognizedOnly) {
          if (reis.is_recognized) {
            afterRecognizingArr.push(reis);
          }
        } else {
          afterRecognizingArr.push(reis);
        }
      });
      return afterRecognizingArr;
    },

    // возвращает данные по выбранному ТС
    getTsHeader(){
      if(this.vehicleWorkTasks[0]){
        return this.vehicleWorkTasks[0].garage_num + ' / ' + this.vehicleWorkTasks[0].state_num;
      } else {
        return '';
      }
    },

    // возвращает дату для заголовка
    getDateForHeader() {
      return ndToDateString(this.date_index);
    },

    // Видимость диалога "Отметки на рейсе"
    isDialogVisible() {
      return this.op === 'show';
    }

  },

  methods: {

    // нажата кнопка "Рейс на карту"
    onTripTypeOnMap(){
      this.$router.push('/kvr/history/' + this.date_index + '/' + this.selectedVehicleWorkTasksRow.transport_id  + '/' + this.selectedVehicleWorkTasksRow.route_id + '/' + this.selectedWorkTaskFactTripsRow.trip_time_index_begin
          + '/' + this.selectedWorkTaskFactTripsRow.trip_time_index_end);
    },

    // нажата конпка "Просмотр"
    async onShowTripCardItems() {
      await this.getTripCard();
      let fact_trip_id = (this.selectedWorkTaskFactTripsRow.fact_trip_id != null || this.selectedWorkTaskFactTripsRow.fact_trip_id != undefined) ?
          this.selectedWorkTaskFactTripsRow.fact_trip_id : "";
      this.$router.replace('/kvr/workTasks/' + this.date_index + '/' + this.transport_id + '/informationByTs/show/' + fact_trip_id);
    },

    // обновить рейсы
    async onWorkTaskFactTripsUpdate() {
      this.isLoading = true;
      this.workTaskFactTrips = [];
      try {
        let workTaskFactTrips = await this.$store.dispatch('kvr/getWorkTaskFactTrips', {
          work_task_id: this.selectedVehicleWorkTasksRow.work_task_id,
          date_index: this.date_index,
        });
        if (workTaskFactTrips) {
          this.workTaskFactTrips = workTaskFactTrips;
          // делаем выбранной первую строку в таблице рейсов
          this.selectedWorkTaskFactTripsRow = this.workTaskFactTrips[0];
        }
      } finally {
        this.isLoading = false;
      }
    },

    // обновить фактические отметки
    async onCPNavsUpdate() {
      this.isLoading = true;
      this.workTaskCPNavs = [];
      try {
        // получаем фактические отметки
        let workTaskCPNavs = await this.$store.dispatch('kvr/doFetchWorkTaskCPNavs', {
          work_task_id: this.selectedVehicleWorkTasksRow.work_task_id,
          date_index: this.date_index,
        });
        if (workTaskCPNavs)
          this.workTaskCPNavs = workTaskCPNavs;
      } finally {
        this.isLoading = false;
      }
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице заданий
    async onTasksRowSelect(item) {
      this.selectedVehicleWorkTasksRow = item;
      this.workTaskFactTrips = [];
      this.workTaskCPNavs = [];
      this.isLoading = true;
      try {
        // получаем рейсы
        let workTaskFactTrips = await this.$store.dispatch('kvr/getWorkTaskFactTrips', {
          work_task_id: this.selectedVehicleWorkTasksRow.work_task_id,
          date_index: this.date_index,
        });
        if (workTaskFactTrips) {
          this.workTaskFactTrips = workTaskFactTrips;
          // сортируем полученный массив
          quickSort(this.workTaskFactTrips, (leftItem, rightItem) => {
            return 1 * workTaskFactTripSortFn(leftItem, rightItem);
          });
          // делаем выбранной первую строку в таблице рейсов
          this.selectedWorkTaskFactTripsRow = this.workTaskFactTrips[0];
        }
        // получаем фактические отметки
        let workTaskCPNavs = await this.$store.dispatch('kvr/doFetchWorkTaskCPNavs', {
          work_task_id: this.selectedVehicleWorkTasksRow.work_task_id,
          date_index: this.date_index,
        });
        if (workTaskCPNavs)
          this.workTaskCPNavs = workTaskCPNavs;
      } finally {
        this.isLoading = false;
      }
    },

    // выбрана новая строка в таблице рейсов
    onWorkTaskFactTripsRowSelect(item) {
      this.selectedWorkTaskFactTripsRow = item;
    },

    // выбрана новая строка в таблице навигации
    onFactMarksRowSelect(item) {
      this.selectedWorkTaskCPNavRow = item;
    },

    // обновление данных (после обновление заданий по цепочке обновляются все данные на странице)
    async onTasksTableRefresh() {
      this.vehicleWorkTasks = [];
      this.workTaskFactTrips = [];
      this.workTaskCPNavs = [];
      this.isLoading = true;
      try {
        // получаем задания
        let vehicleWorkTasks = await this.$store.dispatch('kvr/doFetchWorkTasksByDateAndTsId', {
          date_index: this.date_index,
          transport_id: this.transport_id,
        });
        if (vehicleWorkTasks) {
          this.vehicleWorkTasks = vehicleWorkTasks;
          // сортируем полученный массив
          quickSort(this.vehicleWorkTasks, (leftItem, rightItem) => {
            return 1 * numSortFn(leftItem.time_index_begin, rightItem.time_index_begin);
          });
          await this.onTasksRowSelect(this.vehicleWorkTasks[0]);
        }
      } finally {
        this.isLoading = false;
      }
    },

    viewChange() {
      if (this.getWorkTaskFactTrips.length != 0) {
        let i = 0;
        for (let l = 0; l < this.getWorkTaskFactTrips.length; l++) {
          if (this.getWorkTaskFactTrips[l].fact_trip_id == this.selectedWorkTaskFactTripsRow.fact_trip_id) {
            i++;
            break;
          }
        }
        if (this.selectedWorkTaskFactTripsRow && i != 1) {
          this.selectedWorkTaskFactTripsRow = this.getWorkTaskFactTrips[0];
        }
      }
    },

    async getTripCard() {
      this.isLoading = true;
      try {
        let fact_trip_id = (this.selectedWorkTaskFactTripsRow.fact_trip_id != null || this.selectedWorkTaskFactTripsRow.fact_trip_id != undefined) ?
            this.selectedWorkTaskFactTripsRow.fact_trip_id : "";
        let tripType = await this.$store.dispatch('kvr/doFetchTripCardByFactTripId', {
          fact_trip_id: fact_trip_id,
          date_index: this.date_index
        })
        if (tripType) {
          this.tripCardItems = tripType;
        }
      } finally {
        this.isLoading = false;
      }
    },

    onRejectOperation() {
      this.tripCardItems = [];
      this.$router.replace('/kvr/workTasks/' + this.date_index + '/' + this.transport_id + '/informationByTS');
    },

  },

  async created() {

    // получаем маршруты
    await this.$store.dispatch('kvr/doFetchRoutes');

    await this.onTasksTableRefresh();
    // если открыт диалог к карточкой типа рейса, загружаем её
    if (this.op == 'show') {
      let row = this.getWorkTaskFactTrips.find(value => value.fact_trip_id == this.fact_trip_id);
      if (row) {
        this.selectedWorkTaskFactTripsRow = row;
      }
      let tripType = await this.$store.dispatch('kvr/doFetchTripCardByFactTripId', {
        fact_trip_id: this.fact_trip_id,
        date_index: this.date_index
      });
      if (tripType) {
        this.tripCardItems = tripType;
      }
    }
  }

}
</script>

<style scoped>

</style>
