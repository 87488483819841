<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-asmpp-routes"> </edit-table-panel>
</template>

<script>

import {getTransportType} from "@/store/RefHelpers";
import {getRouteKind, getRouteStatus, getRouteType, getTransferKind} from "@/store/MultipassHelpers";

export default {
  props: ['isLoading', 'routeGroupId', 'filterText', 'chosenRows', 'routes'],
  emits: ['selection'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__isSelected",
          caption: "",
          isSortable: false,
          sortType: 'boolean',
          displayType: 'checkbox',
          headerAlign: 'center',
          dataAlign: 'center',
          inputStyle: {
            width: '18px',
            height: '18px',
            position: 'relative'
          },
          cellStyle: {
            verticalAlign: 'middle !important',
            paddingBottom: "6.5px"
          }
        },
        {
          field: "route_id",
          caption: "Код",
          sortType: 'number',
          show: true,
          isFilterable: true,
        },
        {
          field: "route_num",
          caption: "Номер",
          sortType: 'routeNum',
          isFilterable: true,
          show: true,
          sorted: 'asc'
        },
        {
          field: "route_title",
          caption: "Наименование",
          sortType: 'string',
          show: true,
          isFilterable: true,
        },
        {
          field: "reg_num",
          caption: "Рег.номер",
          sortType: 'string',
          show: false
        },
        {
          field: "__transportTypeName",
          caption: "Вид транспорта",
          sortType: 'string',
        },
        {
          field: "__routeKindName",
          caption: "Вид маршрута",
          sortType: 'string',
          show: false
        },
        {
          field: "__routeTypeName",
          caption: "Тип маршрута",
          sortType: 'string',
        },
        {
          field: "__transferKindName",
          caption: "Вид сообщения",
          sortType: 'string',
        },
        {
          field: "__routeStatusName",
          caption: "Статус",
          sortType: 'string',
        },
        {
          field: "open_date",
          caption: "Дата открытия",
          sortType: 'string',
        },
        {
          field: "close_date",
          caption: "Дата закрытия",
          sortType: 'string',
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          show: false
        },
        {
          field: "area_classifier_id",
          caption: "Код ОКАТО (ОКТМО)",
          sortType: 'string',
          show: false
        },
        {
          field: "is_social",
          caption: "Соц.",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_social) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      // получаем все маршруты
      let allRoutes = this.routes;
      allRoutes.map(route => {
        // если маршруты только получены или взведен тригер снятия всех галочек
        if ((route.__isSelected == null && route.__isSelected == undefined) || this.chosenRows == 'clearAll') {
          // снимаем все галочки слева
          route.__isSelected = false;
        }
      });

      let routes;
      // фильтруем маршруты в случае выбранной группы
      const links = this.$store.getters["multipass/getRouteGroupLinks"];
      if (this.routeGroupId) {
        routes = allRoutes.filter(route => {
          route.__id = route.route_id
            return links.some(link => link.route_id == route.route_id && link.route_group_id == this.routeGroupId)
        });
      } else {
        routes = allRoutes.map(route => {
          route.__id = route.route_id;
          return route;
        });
      }

      // дозаполняем сущности маршрутов полями для которых есть только id
      routes.map(route => {

        const transportType = getTransportType(route.transport_type_id);
        const routeKind = getRouteKind(route.route_kind_id);
        const routeType = getRouteType(route.route_type_id)
        const transferKind = getTransferKind(route.transfer_kind_id);
        const routeStatus = getRouteStatus(route.route_status_id);

        route.__transportTypeName = transportType && transportType.long_name || '';
        route.__routeKindName = routeKind && routeKind.long_name || '';
        route.__routeTypeName = routeType && routeType.long_name || '';
        route.__transferKindName = transferKind && transferKind.long_name || '';
        route.__routeStatusName = routeStatus && routeStatus.long_name || '';

        return route
      });
      // отфильтрованные маршруты
      let filteredRoutes = [];
      // в случае текста в поле "фильтр" фильтруем маршруты
      if (this.filterText != '') {
        let str;
        filteredRoutes = routes.filter((row) => {
          return this.columnList.some((col) => {
            let item = col.subField ? row[col.subField][col.field] : row[col.field];
            if (col.isFilterable && col.show) {
              if (item == null) {
                str = '';
              } else {
                str = String(item).toLowerCase();
              }

              return str.includes(this.filterText.toLowerCase());
            } else {
              return false;
            }
          });
        });
      } else {
        filteredRoutes = [];
        routes.map(value => {
          filteredRoutes.push(value)
        })
      }
      // в случае, если нажата кнопка "выбрать все строки на экране" т.е. отфильтрованные строки
      // сетаем отфильтрованные маршруты выбранными
      if (this.chosenRows == 'selectFiltered'){
        filteredRoutes.map(value => {
          value.__isSelected = true;
        })
      }
      // сбрасываем режим для галочек (поле __isSelected)
      this.$emit('selection', allRoutes)

      return filteredRoutes;
    },
  },
};
</script>
