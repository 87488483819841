<template>

  <input-date
      class="form-control"
      style="width: 160px"
      v-model="date"
  ></input-date>
  <input-time
      class="form-control"
      style="width: 160px"
      v-model="time"
  ></input-time>

</template>

<script>
import InputDate from "@/components/ui/custom/InputDate";
import InputTime from "@/components/ui/custom/InputTime";
import {dateToND, dateToSeconds, secondsToDate} from "@/lib";

export default {
  // !! компонент возвращает дату и время в секундах с 01.01.2000 !!
  components: {InputTime, InputDate},
  props: {
    'model-value': {
      required: true
    },
    'error': {
      type: String
    },
    'required': {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:model-value'],
  data() {
    return {
      isInvalid: false,
      errorText: this.error,
      dateVal: this.getDate(),
      timeVal: this.getTime(),
    }
  },
  computed: {

    date: {
      get() {
        return this.dateVal
      },
      set(value) {
        this.dateVal = value;
        this.$emit('update:model-value', dateToSeconds(new Date(this.dateVal)) + this.timeVal)
      }
    },

    time: {
      get() {
        return this.timeVal
      },
      set(value) {
        this.timeVal = value;
        this.$emit('update:model-value', dateToSeconds(new Date(this.dateVal)) + this.timeVal)
      }
    },

  },
  methods: {

    //получаем дату из значения в секундах
    getDate(){
      return secondsToDate(this.modelValue);
    },

    //получаем время из значения в секундах
    getTime(){
      // получаем дни с 2020-01-01 в секундах
      let daysInSeconds = dateToND(new Date((this.modelValue + (10957 * 24 * 60 * 60)) * 1000))* 24 * 3600;
      // время в секундах с начала дня
      let currentTime = this.modelValue - daysInSeconds;
      return currentTime;
    },

    // проверка валидности
    isValid() {
      // разраешаем пустые строки, если допускается пустое значение
      if (this.dateVal === null || this.timeVal === null) {
        return !this.required
      }
      // все остальное можно
      return true;
    },

    // при получении фокуса
    onFocus() {
      // сбрасываем инвалидность
      this.isInvalid = false;
      this.errorText = this.error;
    },

    // при потере фокуса
    onBlur() {
      this.validate()
    },

    // при вводе значений
    onInput() {
      this.validate();
    },

    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },
    alarm(message) {
      this.errorText = message;
      this.isInvalid = true;
    }
  },

  mounted() {
    // следим за изменением модели
    this.$watch(() => this.modelValue, (value) => {
      // пришел null
      if (value === null) {
        // трогаем текстовую строку, только если она противоречит
        if (!this.isCorrelate(this.$refs.inputRef.value)) {
          this.$refs.inputRef.value = '';
          this.lastText = '';
        }
        // выходим
        return
      }
    })
  }
}
</script>
