import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДАННЫХ ПАССАЖИРОПОТОКА ЗА СУТКИ ПО ТС ЗА ПЕРИОД ДАТ

export default {
    actions: {
        // запрос списка объектов
        doFetchPassengerTrafficDayByTransport: (context, payload) => {
            return DoGet(
                '/asmpp/passengerTrafficDayByTransport?depot_id=' + payload.depot_id +
                '&date_index_begin=' + payload.date_index_begin + '&date_index_end=' + payload.date_index_end,
                null,
                [],
                'Запрос данных пассажиропотока за сутки по тс за период дат',
                false,
                true
            )
        },
    }
}
