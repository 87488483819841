<template>
    <general-link
        ref="link"
        class="nav-link dropdown-toggle"
        target-method="toggle"
        v-click-away="onClickAway"
    ><slot></slot></general-link>
</template>

<script>
import { directive } from "vue3-click-away";

export default {
    directives: {
      ClickAway: directive
    },
    methods: {
      // при щелчке вне ссылки - закрываем видимость
      onClickAway() {
        this.$refs.link.execTargetMethod('deactivate');
      }
    }    
}
</script>
