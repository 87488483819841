<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Выбор файла</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <input-file
                  @onFileListChanged="onFileListChanged"
              />
            </div>
            <modal-footer>
              <label style="color: red" v-if="!!file && !isFileOk">Неподдерживаемый формат файла</label>
              <button type="button" v-on:click="submitFile" class="btn btn-primary" :disabled="!file || !isFileOk">Загрузить</button>
            </modal-footer>
          </form>
        </modal-body>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import { getFileFormatByFiletype} from "@/store/DocHelpers";

export default {
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  data() {
    return {
      file: null,
      isFileOk: false,
    };
  },
  methods: {
    // выбор файла
    onFileListChanged(file) {
      let lastDotIndex = file.name.lastIndexOf('.');
      let format_name =  file.name.substring(lastDotIndex+1, file.name.length);
      let format = getFileFormatByFiletype(format_name);
      this.file = file;
      if (format) {
        this.isFileOk = true;
      } else {
        this.isFileOk = false;
      }
    },

    // отправка файла
    submitFile() {
      this.$emit('onOK', {
        file: this.file,
      })
    },
  },

};
</script>

<style scoped>
form label {
  font-weight: bold;
}

button {
  justify-content: flex-end;
}
</style>