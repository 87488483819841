import { createStore } from 'vuex';
import MessageStore from './message/MessageStore';
import MainStore from './main/MainStore';
import RefStore from './ref/RefStore';
import AuthStore from './auth/AuthStore';
import SystemStore from './system/SystemStore';
import MultipassStore from "./multipass/MultipassStore";
import SettingsStore from "@/store/settings/SettingsStore";
import GisStore from "@/store/gis/GisStore";
import RmtStore from "@/store/rmt/RmtStore";
import AccessStore from "@/store/access/AccessStore";
import DocStore from "@/store/doc/DocStore";
import ClipboardStore from "@/store/main/ClipboardStore";
import ReporterStore from "@/store/reporter/ReporterStore";
import NavStore from "@/store/nav/NavStore";
import AsmppStore from "@/store/asmpp/AsmppStore";
import KvrStore from "@/store/kvr/KvrStore";
import LoggerStore from "@/store/logger/LoggerStore";
import RmtGenStore from "@/store/rmtgen/RmtGenStore";
import IntegrationStore from "@/store/integration/IntegrationStore";
import KvrExpertStore from "@/store/kvr_expert/KvrExpertStore";
import TrnStore from "@/store/trn/TrnStore";

export default createStore({
    modules: {
        MessageStore,
        MainStore,
        auth: AuthStore,
        ref: RefStore,
        system: SystemStore,
        multipass: MultipassStore,
        settings: SettingsStore,
        gis: GisStore,
        rmt: RmtStore,
        access: AccessStore,
        doc: DocStore,
        ClipboardStore,
        reporter: ReporterStore,
        nav: NavStore,
        asmpp: AsmppStore,
        kvr: KvrStore,
        "kvr-expert": KvrExpertStore,
        logger: LoggerStore,
        rmtgen: RmtGenStore,
        integration: IntegrationStore,
        trn: TrnStore,

    }
});