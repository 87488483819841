<template>
  <svg :height="height" :width="width" style="background-color: #000000" @mousemove="mousemoveEvent">

    <!--      линии разметки верхней шкалы  -->
    <line
        v-for="(value, index) in (hoursArray.length * 60)"
        :key="index"
        :x1="getXCoord(index)"
        :y1="getYTopCoord(y1,index)"
        :x2="getXCoord(index)"
        :y2="y1"
        style="stroke:rgb(175,175,175);stroke-width:2"/>

    <!--      линии разметки нижней шкалы  -->
    <line
        v-for="(value, index) in (hoursArray.length * 60)"
        :key="index"
        :x1="getXCoord(index)"
        :y1="getYBottomCoord(y2, index)"
        :x2="getXCoord(index)"
        :y2="y2"
        style="stroke:rgb(255,255,255);stroke-width:2"/>

    <!--    вертикальная линия часов из массива -->
    <line
        v-for="(value, index) in (hoursArray)"
        :key="index"
        :x1="getXTextCoord(-1, index, 0)"
        :y1="25"
        :x2="getXTextCoord(-1, index, 0)"
        :y2="height"
        style="stroke-linecap: square; stroke:rgb(255,255,255);stroke-width:1; stroke-dasharray: 20; d:M5 40 l215 0;"/>


    <!--      значение часов из массива -->
    <text
        v-for="(value, index) in (hoursArray)"
        :key="index"
        :x="getXTextCoord(value, index, 0)"
        :y="20"
        fill="rgb(0,255,6)"
        font-size="19px"
        style="font-weight: bold;"
    >{{ value }}
    </text>


    <!--    Линии КП (верхняя половина полотна)         -->
    <g v-for="(cpValue, index) in (cpArr)"
       :key="index"
    >
      <g
          v-if="((cpValue.direction == sawViewType) || (sawViewType == 2) || cpValue.isFinal) && cpValue.__isVisible && ((cpValue.direction == 0 && cpValue.percent > 50) || (cpValue.direction == 1 && cpValue.percent < 50))"
      >
        <line
            v-if="cpValue.percent != 0 && cpValue.percent != 100 && cpValue.isFinal"
            :x1="0"
            :y1="getYByPercent(cpValue.percent)"
            :x2="width"
            :y2="getYByPercent(cpValue.percent)"
            :style="'stroke-width: 6; stroke:black; z-index: 1'"
            @click="clickOnCpLine(cpValue)"
        />

        <line
            v-if="cpValue.percent != 0 && cpValue.percent != 100 "
            :x1="(cpValue.direction == 0 && !cpValue.isFinal) ? 0 : width"
            :y1="getYByPercent(cpValue.percent)"
            :x2="(cpValue.direction == 0 && !cpValue.isFinal) ? width : 0"
            :y2="getYByPercent(cpValue.percent)"
            :style="getCPLineStyle(cpValue)"
            @click="clickOnCpLine(cpValue)"
        />
      </g>
    </g>

    <!--    Линии КП (нижняя половина полотна)         -->
    <g v-for="(cpValue, index) in (cpArr)"
       :key="index"
    >
      <g
          v-if="((cpValue.direction == sawViewType) || (sawViewType == 2) || cpValue.isFinal) && cpValue.__isVisible && ((cpValue.direction == 0 && cpValue.percent <= 50) || (cpValue.direction == 1 && cpValue.percent >= 50))"
      >
        <line
            v-if="cpValue.percent != 0 && cpValue.percent != 100 && cpValue.isFinal"
            :x1="0"
            :y1="getYByPercent(cpValue.percent)"
            :x2="width"
            :y2="getYByPercent(cpValue.percent)"
            :style="'stroke-width: 6; stroke:black; z-index: 1'"
            @click="clickOnCpLine(cpValue)"
        />

        <line
            v-if="cpValue.percent != 0 && cpValue.percent != 100 "
            :x1="(cpValue.direction == 0 && !cpValue.isFinal) ? 0 : width"
            :y1="getYByPercent(cpValue.percent)"
            :x2="(cpValue.direction == 0 && !cpValue.isFinal) ? width : 0"
            :y2="getYByPercent(cpValue.percent)"
            :style="getCPLineStyle(cpValue)"
            @click="clickOnCpLine(cpValue)"
        />
      </g>
    </g>


    <!--    Подписи КП          -->
    <g v-for="cpValue in (cpValuesForGraphText)"
       :key="cpValue.index + cpValue.name"
    >
      <g
          v-if="((cpValue.direction == sawViewType) || (sawViewType == 2) || cpValue.isFinal) && cpValue.__isVisible"
      >
        <!--      Подписи КП         -->
        <text
            v-if="cpValue.index || cpValue.index == 0"
            :x="(cpValue.index % 2 == 0) ? 5 : ((this.oneDiv * 60)/2)"
            :y="getYByPercent(cpValue.percent) + 3"
            :transform="'rotate(-30 ' + ((cpValue.index % 2 == 0) ? 5 : ((this.oneDiv * 60)/2)) + ' ' + (getYByPercent(cpValue.percent) + 3) + ')'"
            fill="white"
            font-size="12px"
        >{{ cpValue.__name }}
        </text>
      </g>
    </g>

    <!--    В цикле отрисовываем все графики -->
    <g v-for="(trip_columns, ind) in graphs"
       :key="ind">

      <!--    В цикле отрисовываем все рейсы по каждому графику -->
      <g v-for="(line, index) in trip_columns.trip_table_columns"
         :key="index">


        <!--            проверка на параметры отображения-->

        <!--        Линия стоянки-->
        <path v-if="line.trip_complex.schedule_event_id == 1" :d="getPath(line)" fill="transparent"
              :style="'stroke-linecap: square; ' + getReisLineStyle(line)" @click="reisLineClick(line)"/>
        <!--        Линия рейсов, если нет массива КП -->
        <line
            v-if="(!line.trip_complex.cp_time_list || !line.trip_complex.cp_length_list) && line.trip_complex.schedule_event_id == 1"
            :x1="getXLineStart(line)"
            :y1="getYByDirection(line.trip_complex.direction, 'start')"
            :x2="getX(line.trip_complex.step_time_end)"
            :y2="getYByDirection(line.trip_complex.direction, 'end')"
            :style="'stroke-linecap: square; ' + getReisLineStyle(line)"
            @click="reisLineClick(line)"
        />


        <g
            v-else-if="line.trip_complex.schedule_event_id == 1"
        >
          <!--          <path :d="getPath(line)" fill="transparent" :style="'stroke-linecap: square; ' + getReisLineStyle(line)"/>-->

          <!--          Линии рейсов из массива КП-->
          <g
              v-for="(time, index) in line.trip_complex.cp_time_list"
              :key="index"
          >
            <!--            проверка на параметры отображения-->

            <line
                v-if="index != line.trip_complex.cp_time_list.length-1"
                :x1="getX(time)"
                :y1="getYByPercent(line.trip_complex.cp_length_list[index])"
                :x2="getX(line.trip_complex.cp_time_list[index+1])"
                :y2="getYByPercent(line.trip_complex.cp_length_list[index+1])"
                :style="'stroke-linecap: square; ' + getReisLineStyle(line)"
                @click="reisLineClick(line)"
            />

          </g>
        </g>
      </g>
    </g>
    <!--    </g>-->


    <!--    В цикле отрисовываем все Линии начала рейсов (оранжевые) direction 1    :y2="getYLineStart(1,y1-50)"-->
    <g v-for="(line, index) in matrixLines1"
       :key="index">
      <!--          Линии начала рейсов (оранжевые) -->
      <line
          :x1="getX(line.time)"
          :y1="getYByPercent(line.percent)"
          :x2="getX(line.time)"
          :y2="returnYOfIntervalLines(1, line)"
          :style="'stroke-linecap: square; stroke:rgb(255,98,0); stroke-dasharray: 5; d:M5 10 l215 0; ' + getHoursLineStyle(1,  line.percent) "
      />
    </g>

    <!--    В цикле отрисовываем все Линии начала рейсов (оранжевые) direction 0  :y2="getYLineStart(0,y2+50)" s-->
    <g v-for="(line, index) in matrixLines0"
       :key="index">
      <!--          Линии начала рейсов (оранжевые) -->
      <line
          :x1="getX(line.time)"
          :y1="getYByPercent(line.percent)"
          :x2="getX(line.time)"
          :y2="returnYOfIntervalLines(0, line)"
          :style="'stroke-linecap: square; stroke:rgb(255,98,0); stroke-dasharray: 5; d:M5 10 l215 0; ' + getHoursLineStyle(0,  line.percent) "
      />
    </g>


    <!--    &lt;!&ndash;      Резерв            &ndash;&gt;-->
    <!--    <text-->
    <!--        v-for="(value, index) in (hoursArray)"-->
    <!--        :key="index"-->
    <!--        :x="getXTextCoord(value, index, 0) - 5"-->
    <!--        :y="50"-->
    <!--        fill="white"-->
    <!--        font-size="13px"-->
    <!--        style="font-weight: bold;"-->
    <!--    >{{ value }}-->
    <!--    </text>-->


    <!--      время между рейсами текст сверху (интервалы)    -->
    <text
        v-for="(value, index) in (reisStartLinesTextDirection1)"
        :key="index"
        :x="value.coord"
        :y="y1 - getStartTimesY(1,value)"
        font-size="13px"
        :style="'font-weight: bold; z-index: 101 !important;' + getStartTimesStyle(1, value)"
    >{{ (value.value) }}
    </text>

    <!--      время между рейсами текст снизу (интервалы)   -->
    <text
        v-for="(value, index) in (reisStartLinesTextDirection0)"
        :key="index"
        :x="value.coord"
        :y="y2 + getStartTimesY(0,value)"
        font-size="13px"
        :style="'font-weight: bold; z-index: 101 !important;' + getStartTimesStyle(0, value)"
    >{{ (value.value) }}
    </text>


    <!--      Стоянки текст (не выбранные)        -->
    <g v-for="(value, index) in (stopDuration)" :key="index">

      <text
          v-if="(!selectedLine || value.graph != selectedLine.trip_complex.graph)"
          :x="value.coordX"
          :y="value.coordY"
          fill="white"
          font-size="13px"
          style="font-weight: bold; z-index: 101 !important;"
      >{{ value.value }}
      </text>

    </g>

    <!--      Стоянки текст (выбранные)        -->
    <g v-for="(value, index) in (stopDuration)"
       :key="index">

      <text
          v-if="(selectedLine && value.graph == selectedLine.trip_complex.graph)"
          :x="value.coordX"
          :y="value.coordY"
          fill="rgb(255,0,0)"
          font-size="15px"
          style="font-weight: bold; z-index: 101 !important;"
      >{{ value.value }}
      </text>

    </g>

    <!--    Верхний курсор, который двигается за мышкой по временной шкале-->
    <line
        id="svgCursor1"
        :y1="y1-4"
        :y2="y1-14"
        style="stroke:rgb(247,255,0);stroke-width:2; z-index: 100"/>

    <!--    Нижний курсор, который двигается за мышкой по временной шкале -->
    <line
        id="svgCursor2"
        :y1="y2+4"
        :y2="y2+14"
        style="stroke:rgb(255,0,0);stroke-width:2; z-index: 100"/>

  </svg>
</template>

<script>

import {getSignTitle} from "@/lib";
import {getStop} from "@/store/MultipassHelpers";

export default {
  name: "SawScheduleGraphSVG",
  props: [
    // массив графиков
    'graphs',
    // стартовая координата x, от которой идет расчет
    'start_x',
    // величина одного деления
    'oneDiv',
    // массив часов
    'hoursArray',
    // массив КП
    'cpArr',
    // вид графика
    'sawViewType'],
  data() {
    return {
      height: 799,
      width: 8000,
      // объект в котором хранятся координаты начала всех длительностей линий верхней линии
      topTextLine: [],
      // объект в котором хранятся координаты начала всех длительностей линий верхней линии
      bottomTextLine: [],
      startTimeInPx: null,
      // первое время на линейке часов в минутах от начала суток
      layoutStartTime: null,
      //сущность в которой хранятся все координаты предполагаемого конца надписей на графике
      textCoords: {},
      // координата y верхней линейки
      y1: 140,
      // координата y нижней линейки
      y2: 667,
      // выбранный рейс
      selectedLine: null,
      // выбранная линия КП
      selectedCp: {},
      // массив линий, выбранных в матрице по direction 1
      matrixLines1: [],
      // массив линий, выбранных в матрице по direction 0
      matrixLines0: [],
    }
  },

  computed: {

    // координаты остановок
    stopDuration() {
      let result = [];
      // заранее рассчитываем координаты надписей, чтобы избежать наложений
      // существует три линии надписей сверху вниз над графиком (надписи идут аналогично снизу вверх под графиком):
      // 1. время между рейсами
      // 2. Резерв
      // 3. Длительность остановки
      for (let i = 0; i < this.graphs.length; i++) {
        for (let z = 0; z < this.graphs[i].trip_table_columns.length; z++) {
          // проверяем, что данный рейс не тех.операция
          if (this.graphs[i].trip_table_columns[z].trip_complex.schedule_event_id == 1) {
            // длительность остановки
            let stopTime = this.graphs[i].trip_table_columns[z].trip_complex.stop_time;
            // рассчитываем координаты расположения надписи
            let stopTimeXCoord = this.getX(this.graphs[i].trip_table_columns[z].trip_complex.step_time_begin - ((stopTime / 2) * 60));
            // корректируем положение надписи
            if (stopTime > 9) {
              stopTimeXCoord = stopTimeXCoord - 8;
            } else {
              stopTimeXCoord = stopTimeXCoord - 4;
            }
            // направление
            let direction = this.graphs[i].trip_table_columns[z].trip_complex.direction;
            let graph = this.graphs[i].trip_table_columns[z].trip_complex.graph;

            let yValue = 0;
            if (direction == 0) {
              let startY = this.graphs[i].trip_table_columns[z].trip_complex.cp_length_list ? this.getYByPercent(this.graphs[i].trip_table_columns[z].trip_complex.cp_length_list[0]) : this.y2;
              yValue = startY + 28;
            } else {
              let startY = this.graphs[i].trip_table_columns[z].trip_complex.cp_length_list ? this.getYByPercent(this.graphs[i].trip_table_columns[z].trip_complex.cp_length_list[0]) : this.y1;
              yValue = startY - 18;
            }

            // добавляем в массив
            result.push(
                {
                  value: stopTime,
                  coordX: stopTimeXCoord,
                  coordY: yValue,
                  direction: direction,
                  graph: graph,
                }
            );
          }
        }
      }
      return result;
    },

    // координаты времени между началом рейсов направления 0
    direction0startTimes() {
      // данные по началу рейсов direction 0
      let res = {};
      let result = [];
      if (this.graphs.length != 0) {
        // для нахождения время между рейсами необходимо
        for (let i = 0; i < this.graphs.length; i++
        ) {
          // для начала рассчитываем стоянки для рейсов с direction 0
          for (let z = 0; z < this.graphs[i].trip_table_columns.length; z++) {
            if (this.graphs[i].trip_table_columns[z].trip_complex.direction == 0) {
              // величина времени начала
              let startTime = 0;
              // величина процента по y
              let cpPercent = 0;
              if (this.graphs[i].trip_table_columns[z].trip_complex.cp_time_list && this.graphs[i].trip_table_columns[z].trip_complex.cp_time_list.length >= 2) {
                startTime = this.graphs[i].trip_table_columns[z].trip_complex.cp_time_list[0];
                cpPercent = this.graphs[i].trip_table_columns[z].trip_complex.cp_length_list[0];
              } else {
                startTime = this.graphs[i].trip_table_columns[z].trip_complex.step_time_begin;
                cpPercent = 0;
              }

              // проверяем есть ли такой массив
              if (res[cpPercent]) {
                res[cpPercent].push(startTime);
              } else {
                res[cpPercent] = [];
                res[cpPercent].push(startTime);
              }
            }
          }
        }

        Object.keys(res).forEach(val => {
          res[val].sort(function (a, b) {
            return a - b;
          });
          for (let z = 1; z < res[val].length; z++) {
            let value = (res[val][z] - res[val][z - 1]);
            let coord = this.getX(res[val][z - 1] + ((res[val][z] - res[val][z - 1]) / 2));
            value = Number(value / 60).toFixed(0);
            // корректируем положение надписи
            if (value > 9) {
              coord = coord - 8;
            } else {
              coord = coord - 4;
            }
            result.push({
              value: value,
              coord: coord,
            });
          }
        })
      }
      return result;
    },

    direction1startTimes() {
      // данные по началу рейсов direction 1
      let res = {};
      let result = [];
      // для нахождения время между рейсами необходимо
      for (let i = 0; i < this.graphs.length; i++) {
        // для начала рассчитываем стоянки для рейсов с direction 0
        for (let z = 0; z < this.graphs[i].trip_table_columns.length; z++) {
          if (this.graphs[i].trip_table_columns[z].trip_complex.direction == 1) {
            // величина времени начала
            let startTime = 0;
            // величина процента по y
            let cpPercent = 0;
            if (this.graphs[i].trip_table_columns[z].trip_complex.cp_time_list && this.graphs[i].trip_table_columns[z].trip_complex.cp_time_list.length >= 2) {
              startTime = this.graphs[i].trip_table_columns[z].trip_complex.cp_time_list[0];
              cpPercent = this.graphs[i].trip_table_columns[z].trip_complex.cp_length_list[0];
            } else {
              startTime = this.graphs[i].trip_table_columns[z].trip_complex.step_time_begin;
              cpPercent = 0;
            }

            // проверяем есть ли такой массив
            if (res[cpPercent]) {
              res[cpPercent].push(startTime);
            } else {
              res[cpPercent] = [];
              res[cpPercent].push(startTime);
            }
          }
        }
      }

      Object.keys(res).forEach(val => {
        res[val].sort(function (a, b) {
          return a - b;
        });
        for (let z = 1; z < res[val].length; z++) {
          let value = (res[val][z] - res[val][z - 1]);
          let coord = this.getX(res[val][z - 1] + ((res[val][z] - res[val][z - 1]) / 2));
          value = Number(value / 60).toFixed(0);
          // корректируем положение надписи
          if (value > 9) {
            coord = coord - 8;
          } else {
            coord = coord - 4;
          }
          result.push({
            value: value,
            coord: coord,
            cpPercent: Number(val),
          });
        }
      });
      return result;
    },

    cpValuesForGraphText() {
      let i = 0;
      let arr = [];
      this.cpArr.map(value => {
        let findValue = arr.find(cp => (value.stop_id == cp.stop_id) && (value.percent == cp.percent));
        if (!findValue && value.__isVisible) {
          let stop = getStop(value.stop_id);
          if (stop) {
            let stopTitlePartOne = '';
            let stopTitlePartTwo = '';
            if (stop.short_name.length <= 16) {
              stopTitlePartOne = stop.short_name.substring(0, 17);
            } else {
              stopTitlePartOne = stop.short_name.substring(0, 12);
              stopTitlePartTwo = '…' + stop.short_name.substring(stop.short_name.length - 4, stop.short_name.length);
            }
            value.__name = stopTitlePartOne + stopTitlePartTwo;
            value.index = i;
            i++;
          }
        }
        arr.push(value);
      });
      return arr;
    },

    // фильтруем данные, чтобы отображение линии начала рейсов соответствовало выбранному в матрице
    reisStartLinesTextDirection1() {
      let selectedCP = [];
      // сначала необходимо определить какие КП выбраны, для этого кладем их в массив в виде сущности
      // {trip_type_sign: выбранный тип рейса, percent: процент выбранного КП, по которому будет производиться расчет}
      //
      this.cpArr.forEach(cp => {
        Object.keys(cp).forEach(key => {
          if (cp.tripTypes.includes(key) && cp[key] == true && cp.direction == 1) {
            selectedCP.push({
              trip_type_sign: key,
              percent: cp.percent,
              direction: cp.direction
            })
          }
        })
      });
      return this.directionLines(selectedCP, 1);
    },

    // фильтруем данные, чтобы отображение линии начала рейсов соответствовало выбранному в матрице
    reisStartLinesTextDirection0() {
      let selectedCP = [];
      // сначала необходимо определить какие КП выбраны, для этого кладем их в массив в виде сущности
      // {trip_type_sign: выбранный тип рейса, percent: процент выбранного КП, по которому будет производиться расчет}
      //
      this.cpArr.forEach(cp => {
        Object.keys(cp).forEach(key => {
          if (cp.tripTypes.includes(key) && cp[key] == true && cp.direction == 0) {
            selectedCP.push({
              trip_type_sign: key,
              percent: cp.percent,
              direction: cp.direction
            })
          }
        })
      });
      return this.directionLines(selectedCP, 0);
    }

  },

  methods: {

    // метод возвращает координату y для конца оранжевой линии (оранжевая линия удлинняется при выделении КП)
    returnYOfIntervalLines(direction, line) {
      if (this.selectedCp && this.selectedCp.percent == line.percent) {
        if (direction == 1) {
          return this.y1 - 70;
        } else if (direction == 0) {
          return this.y2 + 70;
        }
      } else {
        if (direction == 1) {
          return this.y1 - 50;
        } else if (direction == 0) {
          return this.y2 + 50;
        }
      }
    },

    directionLines(selectedCPArr, direction) {
      // данные по началу рейсов direction 1
      let res = {};
      let result = [];

      if (direction == 1) {
        // обнуляем оранжевые линии направления 1
        this.matrixLines1 = [];
      } else {
        // обнуляем оранжевые линии направления 0
        this.matrixLines0 = [];
      }

      if (selectedCPArr.length == 0) {
        return [];
      }


      // для нахождения время между рейсами необходимо
      for (let i = 0; i < this.graphs.length; i++) {

        // для начала рассчитываем стоянки для рейсов
        selectedCPArr.forEach(value => {

          for (let z = 0; z < this.graphs[i].trip_table_columns.length; z++) {
            let reisSign = getSignTitle(this.graphs[i].trip_table_columns[z].trip_complex.trip_type_sign).replaceAll('_', '').replaceAll('<', '').replaceAll('>', '').replaceAll(' ', '');
            if (this.graphs[i].trip_table_columns[z].trip_complex.direction == direction && reisSign == value.trip_type_sign) {
              // величина времени начала
              let startTime = 0;

              if (this.graphs[i].trip_table_columns[z].trip_complex.cp_time_list && this.graphs[i].trip_table_columns[z].trip_complex.cp_time_list.length >= 2) {
                let percentIndex = this.graphs[i].trip_table_columns[z].trip_complex.cp_length_list.indexOf(value.percent);
                startTime = this.graphs[i].trip_table_columns[z].trip_complex.cp_time_list[percentIndex];
              } else {
                startTime = this.graphs[i].trip_table_columns[z].trip_complex.step_time_begin;
              }

              // проверяем есть ли такой массив
              if (res[value.percent]) {
                res[value.percent].push(startTime);
              } else {
                res[value.percent] = [];
                res[value.percent].push(startTime);
              }
            }
          }
        })
      }

      Object.keys(res).forEach(val => {
        res[val].sort(function (a, b) {
          return a - b;
        });

        for (let z = 0; z < res[val].length; z++) {

          // добавляем данные в массив для отрисовки линий
          if (direction == 1 && ((direction == this.sawViewType) || (this.sawViewType == 2))) {
            this.matrixLines1.push({
              percent: val,
              time: res[val][z],
            });
          } else if (direction == 0 && ((direction == this.sawViewType) || (this.sawViewType == 2))) {
            this.matrixLines0.push({
              percent: val,
              time: res[val][z],
            });
          }

          if (z == 0) {
            continue;
          }
          if ((direction == this.sawViewType) || (this.sawViewType == 2)) {
            let value = (res[val][z] - res[val][z - 1]);
            let coord = this.getX(res[val][z - 1] + ((res[val][z] - res[val][z - 1]) / 2));
            value = Number(value / 60).toFixed(0);
            // корректируем положение надписи
            if (value > 9) {
              coord = coord - 8;
            } else {
              coord = coord - 4;
            }
            result.push({
              value: value,
              coord: coord,
              cpPercent: Number(val),
            });
          }
        }
      })
      return result;
    },

    reisLineClick(line) {
      this.selectedLine = line;
    },

    getStartTimesY(direction, value) {
      if (direction == this.selectedCp.direction && value.cpPercent == this.selectedCp.percent) {
        return 75;
      } else {
        return 51;
      }
    },

    getStartTimesStyle(direction, value) {
      if (direction == this.selectedCp.direction && value.cpPercent == this.selectedCp.percent) {
        return 'fill: red'; //rgb(255,98,0);
      } else {
        return 'fill: white;';
      }
    },

    // getYLineStart(direction, value) {
    //   let line = null;
    //   line = this.cpArr.find(val => (val.percent == value) && direction == val.direction);
    //
    //   if (line) {
    //     if (line.direction == 0) {
    //       if (line.isSelected) {
    //         return this.y1 + 85
    //       } else {
    //         return this.y1 + 50
    //       }
    //     } else {
    //       if (line.isSelected) {
    //         return this.y1 - 85
    //       } else {
    //         return this.y1 - 50
    //       }
    //     }
    //   }
    //   return ''
    // },

    getHoursLineStyle(direction, percent) {
      if (this.selectedCp && this.selectedCp.direction == direction && this.selectedCp.percent == percent) {
        return 'stroke-width: 3;'
      } else {
        return 'stroke-width: 2;'
      }
    },

    clickOnCpLine(line) {
      if (line.direction == this.selectedCp.direction && line.percent == this.selectedCp.percent) {
        this.selectedCp = {};
      } else {
        this.selectedCp = line;
      }
    },

    mousemoveEvent(event) {
      if (event.offsetX > this.start_x) {
        document.getElementById('svgCursor1').setAttribute('x1', event.offsetX);
        document.getElementById('svgCursor1').setAttribute('x2', event.offsetX);
        document.getElementById('svgCursor2').setAttribute('x1', event.offsetX);
        document.getElementById('svgCursor2').setAttribute('x2', event.offsetX);
      } else {
        document.getElementById('svgCursor1').setAttribute('x1', this.start_x);
        document.getElementById('svgCursor1').setAttribute('x2', this.start_x);
        document.getElementById('svgCursor2').setAttribute('x1', this.start_x);
        document.getElementById('svgCursor2').setAttribute('x2', this.start_x);
      }
    },

    // получение стиля
    getCPLineStyle(cpValue) {
      let isCPSelected = false;
      if (this.selectedCp && cpValue.direction == this.selectedCp.direction && cpValue.percent == this.selectedCp.percent) {
        isCPSelected = true;
      }
      if (cpValue.isFinal) {
        return (isCPSelected ? "stroke-width: 2;" : "stroke-width: 1; stroke-dasharray: 10 1;") + " stroke:rgb(255,255,255);";
      }
      if (cpValue.direction == 0) {
        return (isCPSelected ? " stroke-width: 3;" : " stroke-width: 1;") + "stroke:rgb(35,152,0)";
      } else if (cpValue.direction == 1) {
        return (isCPSelected ? " stroke-width: 3;" : " stroke-width: 1;") + "stroke:rgb(229,41,255)";
      }
    },

    getPath(line) {
      // начальная точка дуги
      let startX = this.getX(line.trip_complex.step_time_begin - (line.trip_complex.stop_time * 60));
      // промежуточная начальная точка дуги
      let interPointStartX = startX + 5;
      // конечная точка дуги
      let endX = this.getX(line.trip_complex.step_time_begin);
      // промежуточная конечная точка дуги
      let interPointEndX = this.getX(line.trip_complex.step_time_begin) - 5;

      // y
      let startY = 0;
      let endY = 0;
      let interPointStartY = 0;
      let interPointEndY = 0;
      if (line.trip_complex.direction == 0) {
        startY = line.trip_complex.cp_length_list ? this.getYByPercent(line.trip_complex.cp_length_list[0]) : this.y2;
        endY = line.trip_complex.cp_length_list ? this.getYByPercent(line.trip_complex.cp_length_list[0]) : this.y2;
        interPointStartY = startY + 20;
        interPointEndY = endY + 20;
      } else {
        startY = line.trip_complex.cp_length_list ? this.getYByPercent(line.trip_complex.cp_length_list[0]) : this.y1;
        endY = line.trip_complex.cp_length_list ? this.getYByPercent(line.trip_complex.cp_length_list[0]) : this.y1;
        interPointStartY = startY - 20;
        interPointEndY = endY - 20;
      }

      return `M${startX} ${startY} C ${interPointStartX} ${interPointStartY}, ${interPointEndX} ${interPointEndY}, ${endX} ${endY}`
    },

    getYByPercent(percent) {
      let value = this.y2 - this.y1;
      let valueByPercent = 0;

      valueByPercent = value * (percent / 100);

      return this.y2 - valueByPercent;
    },

    getYByDirection(direction, coord) {
      if ((coord == 'start' && direction == 0) || (coord == 'end' && direction == 1)) {
        return this.y2;
      } else {
        return this.y1;
      }
    },

    getXLineStart(graph) {
      if (graph.trip_complex.null_step_time_begin != 0 && graph.trip_complex.null_step_time_end != 0 && (graph.trip_complex.null_step_time_end > graph.trip_complex.step_time_begin)) {
        graph.trip_complex.step_time_begin = graph.trip_complex.null_step_time_end;
      }
      return this.getX(graph.trip_complex.step_time_begin);
    },

    getXTextCoord(value, index, addValue) {
      let val = (this.start_x + (index * this.oneDiv * 60) + (this.oneDiv * addValue));
      if (value == -1) {
        return val;
      } else if (value < 10) {
        return (val - 5);
      } else {
        return (val - 9);
      }
    },

    // получаем x координаты для шкалы с учетом количества часов
    getYTopCoord(y, index) {
      if (index == 0 || (index % 10 === 0)) {
        return y - 8 + 'px';
      } else if (index % 5 === 0) {
        return y - 6 + 'px';
      } else {
        return y - 4 + 'px';
      }
    },

    // получаем x координаты для шкалы с учетом количества часов
    getYBottomCoord(y, index) {
      if (index == 0 || (index % 10 === 0)) {
        return y + 8 + 'px';
      } else if (index % 5 === 0) {
        return y + 6 + 'px';
      } else {
        return y + 4 + 'px';
      }
    },

    // получаем x координаты для шкалы с учетом количества часов
    getXCoord(index) {
      return ((index * this.oneDiv) + this.start_x);
    },

    // получаем рассчитанные заранее координаты текста длительности остановки
    getStopTextX(index) {
      return this.textCoords[index].stop_length_coord;
    },

    // получаем рассчитанные заранее координаты текста названия нулевого рейса
    getNullTextX(index) {
      return this.textCoords[index].null_name_coord;
    },

    // получаем рассчитанные заранее координаты текста названия рейса или названия техоперации
    getNameTextX(index) {
      return this.textCoords[index].name_coord;
    },

    // получаем координату х для текста к нулевому рейсу
    getNullTextXStart(line, index) {
      if (index != this.graphs.length - 1) {
        return this.getX(line.trip_complex.null_step_time_begin) + (index == 0 ? -10 : 0);
      } else {
        return this.getX(line.trip_complex.null_step_time_end) + 3;
      }
    },

    // получаем тип рейса без букв направления
    getTripTypeShort(trip_type) {
      return (trip_type.includes("AB", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type.includes("BA", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type)
    },

    // получаем тип рейса без букв направления для нулевого рейса
    getNullTripTypeShort(line) {
      if (line.trip_complex.null_direction == 0) {
        return ">";
      } else {
        return "<";
      }
    },

    getReisLineStyle(line) {
      if (line.trip_complex.schedule_event_id != 1) {
        return 'stroke-width:8; stroke:rgb(196, 223, 162);'
      }

      if (this.selectedLine && line.trip_complex.graph == this.selectedLine.trip_complex.graph) {
        return 'stroke-width:2; stroke: white;'
      }

      return 'stroke-width:2;' + (line.trip_complex.direction == 0 ? 'stroke:rgb(35,152,0, 0.6)' : 'stroke:rgb(229,41,255, 0.6);');
    },

    getStopColor(line, index) {
      if (index == 0 || index == this.graphs.length - 1 || (line.trip_complex.null_step_time_begin != 0 && line.trip_complex.null_step_time_end != 0 && line.trip_complex.schedule_event_id != 1)) {
        return 'stroke:rgb(0,81,255)'
      }
      return (line.trip_complex.direction == 0 ? 'stroke:rgb(35,152,0)' : 'stroke:rgb(229,41,255);');
    },

    getLineXStart(line) {
      if (line.trip_complex.null_step_time_begin != 0 && line.trip_complex.null_step_time_end != 0 && (line.trip_complex.null_step_time_end > line.trip_complex.step_time_begin)) {
        line.trip_complex.step_time_begin = line.trip_complex.null_step_time_end;
      }
      return this.getX(line.trip_complex.step_time_begin);
    },

    getX(value) {
      return (((value / 60) - this.layoutStartTime) * this.oneDiv) + this.start_x;
    },

    getStopXStart(line, index) {
      let value = 0;
      // при наличие стоянки и нулевого всегда строго располагаем нулевой, потом стоянка, однако, если нулевой и стоянка
      // привязаны к техоперации или нулевой техоперации, то располагаем их сначала стоянка, потом нулевой
      if (index == 0 || (line.trip_complex.null_step_time_begin == 0 && line.trip_complex.null_step_time_end == 0) || line.trip_complex.schedule_event_id == 1) {
        value = line.trip_complex.step_time_begin - (line.trip_complex.stop_time * 60);
      } else {
        // в случае когда это не первый рейс и есть нулевой перед техоперацией, тогда нужно иначе посчитать начало стоянки
        value = line.trip_complex.null_step_time_begin - (line.trip_complex.stop_time * 60);
      }
      return (((value / 60) - this.layoutStartTime) * this.oneDiv) + this.start_x;
    },

    getStopXEnd(line, index) {
      let value = 0;
      if (index == 0 || (line.trip_complex.null_step_time_begin == 0 && line.trip_complex.null_step_time_end == 0) || line.trip_complex.schedule_event_id == 1) {
        value = line.trip_complex.step_time_begin;
      } else {
        // в случае когда это не первый рейс или есть нулевой перед техоперацией, тогда нужно иначе посчитать начало стоянки
        value = line.trip_complex.null_step_time_begin;
      }

      return (((value / 60) - this.layoutStartTime) * this.oneDiv) + this.start_x;
    },

    getLineXEnd(value) {
      return (((value / 60) - this.layoutStartTime) * this.oneDiv) + this.start_x;
    },

    getXLayoutCoord(index, addValue) {
      let val = (this.start_x + (index * this.oneDiv * 60) + (this.oneDiv * addValue));
      return val;
    },

    getColor(value) {
      if (value.type.includes('change')) {
        return 'red';
      } else if (value.type.includes('gray')) {
        return 'gray';
      } else {
        return 'black';
      }
    },

    getStyle(value) {

      if (value.type == 'start' || value.type == 'stop') {
        // делаем линии поверх которых будет текст невидимыми (белыми)
        return 'stroke-linecap: square; stroke:rgb(255,255,255);stroke-width:2';
      } else {
        if (value.type == 'line') {
          return 'stroke-linecap: square; stroke:rgb(0,0,0);stroke-width:2';
        } else if (value.type == 'line-gray') {
          return 'stroke-linecap: square; stroke:rgb(129,129,129);stroke-width:2';
        }
      }
    },

    getLineStartInPx(index) {
      let startX = this.start_x;
      for (let i = 0; i <= index; i++) {
        startX = startX + ((this.graphLine[i].length / 60) * 10 * this.oneDiv);
      }
      return startX;
    },

    //Конвертирует и возвращает время без секунд из значения в секундах
    getTimeFormat(time) {
      if (time != null && time !== '' && Number.isFinite(Number(time))) {
        time = Number(time);
        // часы
        let hours = Math.trunc(time / 3600);
        // минуты
        let minutes = Math.trunc((time - (hours * 3600)) / 60);
        //Возвращаем отформатированное время
        let result = (hours === 0 ? '00' : hours < 10 ? ('0' + hours) : hours) + ':' + (minutes === 0 ? '00' : (minutes < 10 ? ('0' + minutes) : minutes));
        return result;
      } else {
        return '';
      }
    },

    getScheduleEventColor(line) {
      if (line.trip_complex.schedule_event_id == 0) {
        return 'rgb(0,81,255)'
      } else {
        return 'rgb(255,0,0)'
      }
    },

    getScheduleEventText(line) {
      let value = '';
      // определяем букву
      if (line.trip_complex.schedule_event_id == 0) {
        value = "КР";
      } else if (line.trip_complex.schedule_event_id == 4) {
        value = "Т";
      } else if (line.trip_complex.schedule_event_id == 5) {
        value = "О";
      } else if (line.trip_complex.schedule_event_id == 6) {
        value = "Р";
      } else if (line.trip_complex.schedule_event_id == 7) {
        value = "П";
      } else if (line.trip_complex.schedule_event_id == 8) {
        value = "З";
      } else if (line.trip_complex.schedule_event_id == 9) {
        value = "К";
      } else if (line.trip_complex.schedule_event_id == 10) {
        value = "С";
      } else if (line.trip_complex.schedule_event_id == 11) {
        value = "Е";
      } else if (line.trip_complex.schedule_event_id == 12) {
        value = "В";
      } else if (line.trip_complex.schedule_event_id == 13) {
        value = "И";
      } else {
        value = "";
      }
      // добавляем длительность
      if (line.trip_complex.schedule_event_id != 0 && line.trip_complex.schedule_event_id != 10) {
// !!!!!!!!!!!!!!!!!!!!!!!!!! сделать через библиотеку в lib !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        let time = line.trip_complex.step_time_end - line.trip_complex.step_time_begin;
        // часы
        let hours = Math.trunc(time / 3600);
        // минуты
        let minutes = Math.trunc((time - (hours * 3600)) / 60);
        //Возвращаем отформатированное время
        let timeString = (hours === 0 ? '00' : hours < 10 ? ('0' + hours) : hours) + ':' + (minutes === 0 ? '00' : (minutes < 10 ? ('0' + minutes) : minutes));
        value += ' (' + timeString + ')';
      }
      return value;
    },

  },

  created() {

    // крайняя левая точка линейки часов т.е. это как бы ноль по шкале x
    this.layoutStartTime = (this.hoursArray[0] * 60);

  }
}
</script>

<style scoped>

</style>