<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable modal-lg">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Настройка таблицы</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
            <table class="table">
            <thead>
                <tr>
                    <th class="p-1">Вкл.</th>
                    <th class="p-1">Колонка</th>
                    <th class="p-1">Данные</th>
                    <th class="p-1">Заголовок</th>
                    <th class="p-1">Фильтр</th>
                    <th class="p-1">Сортировка</th>
                    <th class="p-1">Порядок</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(col, index) in columns" :key="col.field">
                    <td class="align-middle p-1">  
                        <input type="checkbox" v-model="col.show">
                    </td>
                    <td class="align-middle  p-1">{{ col.caption }}</td>
                    <td class="align-middle  p-1">
                        <select v-model="col.dataAlign">
                            <option value="left">Лево</option>
                            <option value="center">Центр</option>
                            <option value="right">Право</option>
                        </select>
                    </td>
                    <td class="align-middle p-1">
                        <select v-model="col.headerAlign">
                            <option value="left">Лево</option>
                            <option value="center">Центр</option>
                            <option value="right">Право</option>                            
                        </select>                            
                    </td>
                    <td class="align-middle p-1">
                      <input type="checkbox" v-model="col.isFilterable">
                    </td>
                    <td class="align-middle p-1">
                      <input type="checkbox" v-model="col.isSortable" :disabled="disableSort">
                    </td>
                    <td class="align-middle p-1">
                        <button 
                            class="btn bg-info mr-1 text-white"
                            @click="columnUp(index)"
                        ><i class="fas fa-arrow-up"></i></button>
                        <button 
                            class="btn bg-info text-white"
                            @click="columnDown(index)"
                        ><i class="fas fa-arrow-down"></i></button>
                    </td>
                </tr>
            </tbody>
            </table>  
        </modal-body>
        <modal-footer>
            <button @click="$emit('onRestore')" class="btn btn-warning">Восстановить</button>
            <button @click="$emit('onSubmit')" class="btn btn-primary ml-auto">Сохранить</button>          
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
export default {
    props: ['columns', 'disableSort'],
    emits: ['onCancel', 'onSubmit', 'onRestore', 'onColumnUp', 'onColumnDown'],
    methods: {
        // вызывается при перемещении колонки налево
        columnUp(index) {
            if (index > 0) {
                this.$emit('onColumnUp', index);
            }
        },
        // вызывается при перемещении колонки направо
        columnDown(index) {
            if (index < this.columns.length - 1) {
                this.$emit('onColumnDown', index);                
            }
        }
    }
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }

    .btn:focus {
        box-shadow: unset;
    }
</style>