<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/ref">Справочники</router-link></span>
        <span> / </span>
        <span><router-link to="/ref/personnel">Работники транспортных предприятий</router-link></span>
        <span> / </span>
        <span>Состояния работника {{ personnelPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >
          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <personnel-states-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :rows="rows"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
          ></personnel-states-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import {getPersonnel} from "@/store/RefHelpers";
import PersonnelStatesEditTable from "@/components/ref/personnel_states/PersonnelStatesEditTable";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    PersonnelStatesEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    //  идентификатор работника
    "personnel_id",
  ],

  data() {
    return {
      // код профессии
      personnelJobId: 1,
      // текст фильтра
      filterText: '',
      // строки с данными
      rows: [],
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }    
  },

  computed: {
      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            refresh: true
          }
      },

      personnel(){
        return getPersonnel(this.personnel_id);
      },
  },
  methods: {

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData();
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchDepots')
        await this.$store.dispatch('ref/doFetchPersonnelJobs')
        await this.$store.dispatch('ref/doFetchPersonnel')
        const rows = await this.$store.dispatch('ref/doFetchPersonnelStates', {
          personnel_id: this.personnel_id
        }); if (!rows) return;
        this.rows = rows;

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
