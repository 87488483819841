<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" :disableSettings="true"
                    name="tbl-kvr-routes"></edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading', 'filterText', 'chosenRows', 'routes', 'disableSelect'],
  emits: ['selection'],
  data() {
    return {};
  },
  computed: {

    // колонки
    columnList() {
      return [
        {
          field: "__isSelected",
          caption: "Вкл.",
          isSortable: false,
          sortType: 'boolean',
          displayType: 'checkbox',
          headerAlign: 'center',
          dataAlign: 'center',
          inputStyle: {
            width: '18px',
            height: '18px',
            marginTop: '5px',
          },
          inputAttrs: {
            disabled: this.disableSelect,
          }
        },
        {
          field: "route_num",
          caption: "№",
          sortType: 'routeNum',
          isFilterable: true,
          show: true,
          cellStyle: {
            verticalAlign: 'middle',
          },
        },
        {
          field: "route_title",
          caption: "Наименование",
          sortType: 'string',
          show: true,
          isFilterable: true,
          cellStyle: {
            verticalAlign: 'middle',
          },
        },
        {
          field: "reg_num",
          caption: "Рег.номер",
          sortType: 'string',
          show: true,
          cellStyle: {
            verticalAlign: 'middle',
          },
        },
        {
          field: "route_id",
          caption: "Код",
          sortType: 'number',
          show: true,
          isFilterable: true,
          cellStyle: {
            verticalAlign: 'middle',
          },
        },
      ]
    },

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      // получаем все маршруты
      let routes = this.routes;
      routes.map(route => {
        // если маршруты только получены или взведен тригер снятия всех галочек
        if ((route.__isSelected == null && route.__isSelected == undefined) || this.chosenRows == 'clearAll') {
          // снимаем все галочки слева
          route.__isSelected = false;
          route.__id = route.route_id;
        }
      });

      // отфильтрованные маршруты
      let filteredRoutes = [];
      // в случае текста в поле "фильтр" фильтруем маршруты
      if (this.filterText != '') {
        let str;
        filteredRoutes = routes.filter((row) => {
          return this.columnList.some((col) => {
            let item = col.subField ? row[col.subField][col.field] : row[col.field];
            if (col.isFilterable && col.show) {
              if (item == null) {
                str = '';
              } else {
                str = String(item).toLowerCase();
              }

              return str.includes(this.filterText.toLowerCase());
            } else {
              return false;
            }
          });
        });
      } else {
        filteredRoutes = [];
        routes.map(value => {
          value.__cellStyle = {
            paddingTop: '0',
            paddingBottom: '0',
            paddingLeft: '6px',
            paddingRight: '6px'
          };
          filteredRoutes.push(value)
        })
      }
      // в случае, если нажата кнопка "выбрать все строки на экране" т.е. отфильтрованные строки
      // сетаем отфильтрованные маршруты выбранными
      if (this.chosenRows == 'selectFiltered') {
        filteredRoutes.map(value => {
          value.__isSelected = true;
        })
      }
      // сбрасываем режим для галочек (поле __isSelected)
      this.$emit('selection', routes)

      return filteredRoutes;
    },
  },
};
</script>
