import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ИТОГОВЫХ РАСПИСАНИЙ

export default {
    actions: {
        // запрос списка объектов
        doFetchScheduleTimeTables: (context, payload) => {
            return DoGet(
                '/rmt/scheduleTimeTables?schedule_graph_id=' + payload.schedule_graph_id,
                null,
                [],
                'Запрос итогового расписания',
                false,
                true,
            )
        },
    }
}
