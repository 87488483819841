<template>

  <!-- html -->
  <th
      class="text-nowrap overflow-hidden"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-if="col.displayType === 'html' && col.displayFn && (col.isCaption || row.__isCaption)"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span v-html="col.displayFn(row, rowIndex)"></span></th>

  <td
      class="text-nowrap overflow-hidden"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'html' && col.displayFn"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span v-html="col.displayFn(row, rowIndex)"></span></td>

  <th
      class="text-nowrap overflow-hidden"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'html' && (col.isCaption || row.__isCaption)"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span v-html="col.subField ? row[col.subField][col.field] : row[col.field]"></span></th>

  <td
      class="text-nowrap overflow-hidden"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'html'"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span v-html="col.subField ? row[col.subField][col.field] : row[col.field]"></span></td>

  <!-- text -->
  <th
      class="text-nowrap overflow-hidden"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'text' && col.displayFn && (col.isCaption || row.__isCaption)"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span>{{ col.displayFn(row, rowIndex) }}</span></th>

  <td
      class="text-nowrap overflow-hidden"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'text' && col.displayFn"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span>{{ col.displayFn(row, rowIndex) }}</span></td>

  <th
      class="text-nowrap overflow-hidden"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'text' && (col.isCaption || row.__isCaption)"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span>{{ col.subField ? row[col.subField][col.field] : row[col.field] }}</span></th>

  <td
      class="overflow-hidden"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'text'"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center',
                'text-nowrap' : col.noWrapEnable != true }"
  ><span>{{ col.subField ? row[col.subField][col.field] : row[col.field] }}</span></td>

  <!-- Переключатель -->
  <td
      v-else-if="col.displayType === 'checkbox'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-checkbox-simple
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value"
    ></input-checkbox-simple>
  </td>

  <!-- Переключатель изолированный т.е. клик по нему не активирует выделение строки таблицы
       сделан по причине некорректной работы карты при обычном чекбоксе -->
  <td
      v-else-if="col.displayType === 'checkboxIsolated'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-checkbox-simple
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value"
        @click.stop
    ></input-checkbox-simple>
  </td>


  <!-- Ввод пробега -->
  <td
      v-else-if="col.displayType === 'length'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-length
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-length>
  </td>

  <!-- Ввод целого числа -->
  <td
      v-else-if="col.displayType === 'integer'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-integer
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-integer>
  </td>

  <!-- Ввод числа с плавающей точкой -->
  <td
      v-else-if="col.displayType === 'float'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-float
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-float>
  </td>

  <!-- Ввод денежной суммы -->
  <td
      v-else-if="col.displayType === 'currency'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-currency
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-currency>
  </td>

  <!-- Ввод длительности в минутах -->
  <td
      v-else-if="col.displayType === 'minute'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-minute
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-minute>
  </td>

  <!-- Ввод времени в формате чч:мм -->
  <td
      v-else-if="col.displayType === 'time'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-time
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-time>
  </td>

  <!-- Кнопка -->
  <td
      v-else-if="col.displayType === 'button'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
<!--    v-html="{...(col.buttonCaption || {}),...(row.__buttonCaption || {}),...(row[col.field+'__buttonCaption'] || {}),...value}"-->
    <button
        v-html="col.buttonCaption ? col.buttonCaption :(row.__buttonCaption ? row.__buttonCaption : (row[col.field+'__buttonCaption'] ? row[col.field+'__buttonCaption'] : value))"
        v-bind="{...(col.buttonAttrs || {}),...(row.__buttonAttrs || {}),...(row[col.field+'__buttonAttrs'] || {})}"
        :style="{...(col.buttonStyle || {}),...(row.__buttonStyle || {}),...(row[col.field+'__buttonStyle'] || {})}"
        @click="$event.stopPropagation();$emit('onCellButtonClick', col, row, rowIndex)"
        v-show="row[col.field+'__show'] != undefined ? row[col.field+'__show'] : true"
    >
    </button>
  </td>

  <!-- Выпадающий список ( itemList - специальный параметр для передачи списка значений в комбобокс, в то время как
   в field передается также поле из строки в которое заполняется выбранное value )   /*:itemList=row[col.itemList]*/
   row.__itemList - "__itemList" - поле, в которое кладутся строки для комбобокса,
   row[col.field+'__itemList'] -  field+'__itemList' - должны образовать поле, в котором в строке лежит список строк   -->
  <td
      v-else-if="col.displayType === 'inputSelect'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-select
        v-model="value"
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        :itemList="[...(col.itemList || []),...(row.__itemList || []),...(row[col.field+'__itemList'] || [])]"
    >
    </input-select>
  </td>

  <!-- Текстовое поле с выпадающим списком вариантов зависимости от введенного текста в выпадающем списке
  ( itemList - специальный параметр для передачи списка значений в комбобокс, в то время как
   в field передается также поле из строки в которое заполняется выбранное value )   /*:itemList=row[col.itemList]*/
   row.__itemList - "__itemList" - поле, в которое кладутся строки для комбобокса,
   row[col.field+'__itemList'] -  field+'__itemList' - должны образовать поле, в котором в строке лежит список строк   -->
  <td
      v-else-if="col.displayType === 'lookupInputSelect'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <lookup-input-select
        v-model="value"
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        :itemList="[...(col.itemList || []),...(row.__itemList || []),...(row[col.field+'__itemList'] || [])]"
    >
    </lookup-input-select>
  </td>


  <!-- Ввод пробега -->
  <td
      v-else-if="col.displayType === 'inputString'"
      v-bind="{...(col.cellAttrs || {}),...(row.__cellAttrs || {}),...(row[col.field+'__cellAttrs'] || {})}"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-string
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-string>
  </td>

</template>

<script>

export default {

  props: [
      // колонка
      'col',
      // строка
      'row',
      // индекс колонки
      'colIndex',
      // индекс строки (для footer-а - [-1])
      'rowIndex'
  ],
  emits: ['onCellChanged', 'onCellButtonClick'],
  computed: {
    value: {
      get() {
        return this.row[this.col.field];
      },
      set(value) {
        this.$emit('onCellChanged', this.col, this.row, value);
      }
    },
  },
}
</script>

<style scoped>

</style>