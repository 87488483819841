<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Параметры "{{ eventName }}"</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="row" v-if="!isChanging">
              <div class="col-3">
                <div class="form-group">
                  <label for="route">Маршрут:</label>
                  <input-string
                      id="route"
                      class="form-control"
                      v-model="routeTitle"
                      autocomplete="off"
                      readonly
                  />
                </div>
              </div>
            </div>

            <div class="row" v-else>

              <div class="col-5">
                <div class="form-group">
                  <label for="routeId">Маршрут:</label>
                  <req-field/>
                  <lookup-input-select
                      id="routeId"
                      ref="routesFromRecSchVars"
                      class="form-control"
                      v-model="routeId"
                      autocomplete="off"
                      :itemList="routesFromRecSchVars"
                      caption="Маршрут"
                      @change="setScheduleVariants"
                      required
                      :readonly="op === 'show'"
                  />
                </div>
              </div>

              <div class="col-4">
                <div class="form-group">
                  <label for="ScheduleVariantID">Вариант:</label>
                  <req-field/>
                  <input-select
                      id="scheduleVariantId"
                      ref="scheduleVariantId"
                      class="form-control"
                      v-model="scheduleVariantId"
                      :itemList="recSchVars"
                      caption="Вариант"
                      @change="getRecTripTypes"
                      required
                      :readonly="op === 'show'"
                  />
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <label for="graph">График:</label>
                  <req-field/>
                  <input-integer
                      id="graph"
                      ref="graph"
                      class="form-control"
                      v-model="graph"
                      autocomplete="off"
                      :min-value="1"
                      error="Введите целое число больше 0"
                      required
                      :readonly="op === 'show'"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <input-checkbox
                  id="isChanging"
                  v-model="isChanging"
                  :readonly="op === 'show'"
                  @change="getRecipientScheduleVariants"
              >Переключение
              </input-checkbox>
            </div>

            <div class="form-group">
              <label for="tripTypeId">Тип рейса:</label>
              <input-select
                  id="tripTypeId"
                  class="form-control"
                  v-model="tripTypeId"
                  ref="tripTypeId"
                  caption="Выберите тип рейса..."
                  :itemList="isChanging == true ? recTripTypes : tripTypeList"
                  :readonly="op === 'show'"
                  required
                  error="Выберите тип рейса"
              />
            </div>

            <div class="form-group">
              <label for="transportationKindId">Вид перевозок:</label>
              <transportation-kinds-select
                  id="transportationKindId"
                  v-model="transportationKindId"
                  class="form-control"
                  ref="transportationKindId"
                  :readonly="op === 'show'"
              />
            </div>

            <label>Длительность рейса:</label>

            <div class="row">
              <div class="form-group col">
                <input-radio class="mb-1" id="isManualNormFalse" v-model='isManualNorm' :value="false"
                             :readonly="op === 'show'">
                  Взять из справочника
                </input-radio>
              </div>
              <div class="form-group col">
                <input-radio class="mb-1" id="isManualNormTrue" v-model='isManualNorm' :value="true"
                             :readonly="op === 'show'">
                  Задать вручную (мин.)
                </input-radio>
                <input-minute
                    id="eventDuration"
                    class="form-control"
                    ref="eventDuration"
                    v-model="eventDuration"
                    :required="isManualNorm"
                    :readonly="!isManualNorm || op === 'show'"
                    :autofocus="isManualNorm"
                    error="Введите длительность"
                />
              </div>
            </div>

            <div>
              <div class="row">
                <div class="form-group col">
                  <input-checkbox
                      id="isSkippedTurnBegin"
                      v-model="isSkippedTurnBegin"
                      :readonly="op === 'show'"
                  >Блок. разворот в начале
                  </input-checkbox>
                  <input-checkbox
                      id="isSkippedTurnEnd"
                      v-model="isSkippedTurnEnd"
                      :readonly="op === 'show'"
                  >Блок. разворот в конце
                  </input-checkbox>
                </div>
                <div class="form-group col">
                  <input-checkbox
                      id="isBlockedNorm"
                      v-model="isBlockedNorm"
                      :readonly="op === 'show'"
                  >Блок. времена
                  </input-checkbox>
                  <input-checkbox
                      id="isBlockedStop"
                      v-model="isBlockedStop"
                      :readonly="op === 'show'"
                  >Блок. стоянки
                  </input-checkbox>
                </div>
              </div>
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onCancel" class="btn btn-primary">Отмена</button>
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputString from "@/components/ui/custom/InputString";
import TransportationKindsSelect from "@/components/rmt/transportation_kinds/TransportationKindsSelect";
import {getScheduleEvent} from "@/store/RmtHelpers";
import {getSignTitle, signSortFn} from "@/lib";
import InputMinute from "@/components/ui/custom/InputMinute";
import LookupInputSelect from "@/components/ui/custom/LookupInputSelect";
import InputInteger from "@/components/ui/custom/InputInteger";

export default {
  components: {InputInteger, LookupInputSelect, InputMinute, InputString, TransportationKindsSelect},
  props: ['item', 'op', 'route', 'tripTypes', 'schedule_variant_id'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    if (this.validateForm(this.$refs)) {
      return {
        tripTypeId: this.item.trip_type_id,
        transportationKindId: this.item.transportation_kind_id,
        isManualNorm: this.item.is_manual_norm,
        eventDuration: this.item.event_duration,
        isSkippedTurnBegin: this.item.is_skipped_turn_begin,
        isSkippedTurnEnd: this.item.is_skipped_turn_end,
        isBlockedNorm: this.item.is_blocked_norm,
        isBlockedStop: this.item.is_blocked_stop,

        // поставленна ли галочка "Переключение"
        isChanging: this.item.recipient_route_id ? true : false,
        // выбранное в селекторе routeId
        routeId: this.item.recipient_route_id,
        // выбранный в выпадающем списке scheduleVariantId
        scheduleVariantId: this.item.recipient_schedule_variant_id,
        // значение графика
        graph: this.item.recipient_graph,
        // список доступных вариантов расписаний для переключения
        recipientScheduleVariants: [],
        // список маршрутов (без повторений)
        routesFromRecSchVars: [],
        // список вариантов расписания, отфильтрованнх по выбранному маршруту, доступных для переключения
        recSchVars: [],
        // спиоск TripType -ов, полученных  для выбранного переключенного scheduleVariant
        recTripTypes: [],
        // отображение спинера
        isLoading: false
      };
    }
  },

  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          ...this.item,
          trip_type_id: this.tripTypeId,
          transportation_kind_id: this.transportationKindId,
          is_manual_norm: this.isManualNorm,
          event_duration: this.eventDuration,
          is_skipped_turn_begin: this.isSkippedTurnBegin,
          is_skipped_turn_end: this.isSkippedTurnEnd,
          is_blocked_norm: this.isBlockedNorm,
          is_blocked_stop: this.isBlockedStop,

          // переменные, которые заполняются при переключении
          recipient_route_id: this.routeId,
          recipient_schedule_variant_id: this.scheduleVariantId,
          recipient_graph: this.graph
        })
      }
    },

    //отменяем добавление
    onCancel() {
      this.$emit('onCancel')
    },

    // запрос списка доступных вариантов расписаний для переключения
    async getRecipientScheduleVariants() {

      // если галочка поставлена:
      if (this.isChanging) {

        this.recipientScheduleVariants = [];
        this.routesFromRecSchVars = [];
        this.recipientScheduleVariants = [];
        this.recTripTypes = [];

        // запрос вариантов расписний
        this.recipientScheduleVariants = await this.$store.dispatch('rmt/doGetRecipientScheduleVariants', {
          schedule_variant_id: this.schedule_variant_id,
        });
        let idArr = [];
        // формируем список маршрутов для отображения в LookUpInputSelect без повторений
        this.recipientScheduleVariants.map(value => {
          // если такой id ещё не встречался, добавляем маршрут в массив
          if (!idArr.includes(value.route_id)) {
            let route = {
              id: value.route_id,
              title: value.route_num + ' - ' + value.route_title,
              value: value.route_num,
            }
            this.routesFromRecSchVars.push(route)
            idArr.push(value.route_id)
          }
        })

      } else {
        // если галочка снята очищаем массив
        this.recipientScheduleVariants = [];
        // присваиваем null переменным, которые не используются из-за снятия галочки "Переключение"
        this.routeId = null;
        this.scheduleVariantId = null;
        this.graph = null;
        this.tripTypeId = null;
      }
    },

    // после выбора маршрута в селекторе, заполняем комбобокс с вариантами расписаний
    setScheduleVariants() {
      this.recSchVars = [];

      // если идет загрузка т.е. загружается уже заполненый вариант (при открытии show или edit)
      // то сбрасывать эти параметры не нужно
      if (!this.isLoading && this.op != 'show') {
        this.tripTypeId = null;
        this.scheduleVariantId = null;
        this.recTripTypes = [];
      }
      // добавляем в селектор только варианты расписанй с выбранным в селекторе routeId
      this.recipientScheduleVariants.map(value => {
        if (this.routeId == value.route_id) {
          let scheduleVariant = {
            id: value.schedule_variant_id,
            title: value.schedule_variant_id + ' - ' + value.schedule_variant_date_begin + '-' + value.schedule_variant_date_end,
          }
          this.recSchVars.push(scheduleVariant)
        }
      })
    },

    // получаем новые TripTypes для выбранных значений в селекторах при активации галочки "Переключение"
    async getRecTripTypes() {
      this.recTripTypes = [];

      let route_variant_id = null;
      // определяем выбранный в селекторах ScheduleVariant  route_variant_id
      this.recipientScheduleVariants.map(value => {
        if (this.routeId == value.route_id && this.scheduleVariantId == value.schedule_variant_id) {
          route_variant_id = value.route_variant_id
        }
      })

      // загружаем типы рейса
      let tripTypes = await this.$store.dispatch('multipass/doFetchTripTypes', {
        route_variant_id: route_variant_id,
        force: true,
      });
      if (!tripTypes) return;
      tripTypes.sort(signSortFn);
      // получаем ивент для последующей фильтрации tripType
      const event = getScheduleEvent(this.item.schedule_event_id);
      // добавляем типы рейса в массив для отображения в выпадающем списке
      tripTypes.map(
          item => {
            if ((item.is_production && event.schedule_event_type_id === 1) || (!item.is_production && event.schedule_event_type_id === 2)) {
              this.recTripTypes.push({
                id: item.trip_type_id,
                title: getSignTitle(item.trip_type_sign) + ' - ' + item.title,
              })
            }
          }
      )
    }

  },

  computed: {
    // наименование события для заголовка
    eventName() {
      return getScheduleEvent(this.item.schedule_event_id).long_name;
    },

    // заголовок маршрута для отображения
    routeTitle() {
      return this.route.route_num;
    },

    // список типов рейсов для выбора
    tripTypeList() {
      const event = getScheduleEvent(this.item.schedule_event_id);

      return this.tripTypes.filter(
          item => (item.is_production && event.schedule_event_type_id === 1) || (!item.is_production && event.schedule_event_type_id === 2)
      ).map(
          item => {
            return {
              id: item.trip_type_id,
              title: getSignTitle(item.trip_type_sign) + ' - ' + item.title,
            }
          }
      )
    },
  },

  // в случае, если при открытии диалога галочка Переключение уже стоит,
  // необходимо загрузить соответствующие варианты расписаний для переключения
  async created() {

    if (this.isChanging == true) {
      this.isLoading = true;
      // запрос списка доступных вариантов расписаний для переключения
      await this.getRecipientScheduleVariants();
      // заполняем комбобокс с вариантами расписаний
      await this.setScheduleVariants();
      // получаем TripTypes для выбранных значений маршрута и варианта расписаний
      await this.getRecTripTypes();
      this.isLoading = false;
    }
  }

}
</script>

<style scoped>

form label {
  font-weight: bold;
}

</style>