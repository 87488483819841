<template>
  <edit-table-panel :columnList="columnList" :headerGroupList="headerGroupList" :disableSettings="true" :rowList="rowList"
                    :rowsPerPage="100000" name="tbl-kvr-result-trips"></edit-table-panel>
</template>

<script>

import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getLength, getSignTitle, secondsToTime} from "@/lib";
import {getTransportClass} from "@/store/RefHelpers";
import {getAgency, getDepot, getMarkReasons} from "@/store/KvrHelpers";

export default {
  components: {EditTablePanel},
  props: ['rows', 'selectedTripTypeSign'],
  data() {
    return {
      // колонки
      columnList: [
        // План
        {
          field: 'sign',
          caption: 'Тип',
          sortType: 'String',
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'length_plan',
          caption: 'Пробег',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.length_plan) {
              return getLength(row.length_plan);
            } else if (row.length_plan == 0) {
              return 0;
            }
            return ''
          },
        },
        {
          field: 'plan_transport_class',
          caption: 'Класс ТС',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
        },
        {
          field: 'plan_graph',
          caption: 'Граф.',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
        },
        {
          field: 'plan_int',
          caption: 'Инт.',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.plan_int) {
              return Math.round((row.plan_int/60));
            } else if (row.plan_int == 0){
              return 0;
            }
            return ''
          },
        },
        {
          field: 'plan_trip_time_index_begin',
          caption: 'Отпр.',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.plan_trip_time_index_begin) {
              return secondsToTime(row.plan_trip_time_index_begin);
            }
            return ''
          },
        },
        // Факт
        {
          field: 'fact_trip_time_index_begin',
          caption: 'Отпр.',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.fact_trip_time_index_begin) {
              return secondsToTime(row.fact_trip_time_index_begin);
            }
            return ''
          },
        },
        {
          field: 'delta',
          caption: 'Откл.',
          sortType: 'number',
          isSortable: false,
          show: true,
          displayFn(row) {
            if (row.delta) {
              return Math.round((row.delta/60));
            } else if (row.delta == 0){
              return 0;
            }
            return ''
          },
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'fact_int',
          caption: 'Инт.',
          sortType: 'number',
          isSortable: false,
          show: true,
          displayFn(row) {
            if (row.fact_int) {
              return Math.round((row.fact_int/60));
            } else if (row.fact_int == 0){
              return 0;
            }
            return ''
          },
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'fact_graph',
          caption: 'Граф.',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'fact_transport_class',
          caption: 'Класс ТС',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'garage_num',
          caption: 'Гар. №',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'state_num',
          caption: 'Гос. №',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'length_fact',
          caption: 'Пробег',
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.length_fact) {
              return getLength(row.length_fact);
            } else if (row.length_fact == 0) {
              return 0;
            }
            return ''
          },
        },
        {
          field: 'fact_org_title',
          caption: 'Перевозчик',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'fact_depot_title',
          caption: 'Парк',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        // нарушения
        {
          field: 'is_track_penalty',
          caption: 'Трасса',
          sortType: 'String',
          isSortable: false,
          dataAlign: 'center',
          headerAlign: 'center',
          show: true,
          displayType: 'html',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.is_track_penalty) {
              return '<i class="fas fa-check">'
            } else if (row.is_track_penalty == null){
              return 'н/д'
            }
            return ''
          },
        },
        {
          field: 'is_speed_penalty',
          caption: 'Скорость',
          sortType: 'String',
          isSortable: false,
          dataAlign: 'center',
          headerAlign: 'center',
          show: true,
          displayType: 'html',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.is_speed_penalty) {
              return '<i class="fas fa-check">'
            } else if (row.is_speed_penalty == null){
              return 'н/д'
            }
            return ''
          },
        },
        {
          field: 'is_class_penalty',
          caption: 'Класс',
          sortType: 'String',
          isSortable: false,
          show: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.is_class_penalty) {
              return '<i class="fas fa-check">'
            } else if (row.is_class_penalty == null){
              return 'н/д'
            }
            return ''
          },
        },
        {
          field: 'mark_reason_title',
          caption: '',
          sortType: 'String',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'trip_order_num',
          caption: '№',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          dataAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },

      ],
      headerGroupList: [
        {
          headerGroupCaption: 'План',
          headerGroupSpan: 6,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Факт',
          headerGroupSpan: 10,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Нарушения',
          headerGroupSpan: 3,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Причина незачета',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Прочие',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },

      ],
    }
  },

  computed: {

    rowList() {
      let filteredRows = [];
      // фильтруем по типу рейса, если требуется
      if (this.selectedTripTypeSign != null) {
        this.rows.map(value => {
          if (this.selectedTripTypeSign == value.trip_type_sign) {
            filteredRows.push(value);
          }
        })
      } else {
        filteredRows = this.rows;
      }

      return filteredRows.map(resultTrip => {
        // resultTrip.__id = resultTrip.plan_trip_id;
        // plan_trip_id - скорее всего не уникален, может быть null, формируем уникальный __id
        resultTrip.__id = resultTrip.date_index + '_' + resultTrip.route_id + '_' + resultTrip.trip_order_num;

        // определяем плановый класс ТС
        if(resultTrip.plan_transport_class_id == null) {
          resultTrip.plan_transport_class = '-';
        } else {
          let plan_transport_class = getTransportClass(resultTrip.plan_transport_class_id);
          if (plan_transport_class) {
            resultTrip.plan_transport_class = plan_transport_class.short_name;
          }
        }

        // определяем фактический класс ТС
        if(resultTrip.fact_transport_class_id == null) {
          resultTrip.fact_transport_class = '-';
        } else {
          let fact_transport_class = getTransportClass(resultTrip.fact_transport_class_id);
          if (fact_transport_class){
            resultTrip.fact_transport_class = fact_transport_class.short_name;
          }
        }

        // определяем организацию
        let agency = getAgency(resultTrip.fact_org_id);
        if(agency){
          resultTrip.fact_org_title = agency.short_name;
        }
        // определяем парк
        let depot = getDepot(resultTrip.fact_depot_id);
        if(depot){
          resultTrip.fact_depot_title = depot.short_name;
        }
        // определяем причину незачета рейса
        let mark_reason = getMarkReasons(resultTrip.mark_reason_id);
        if(mark_reason){
          resultTrip.mark_reason_title = mark_reason.mark_reason_title;
        }

        // задаем цвет щаднего фона строк
        if (resultTrip.trip_mark == 0) {
          if (resultTrip.fact_trip_id) {
            resultTrip.__rowStyle = {
              backgroundColor: '#ffece8'
            }
          } else {
            resultTrip.__rowStyle = {
              backgroundColor: '#f8f19b'
            }
          }
        } else if (resultTrip.trip_mark == 1) {
          resultTrip.__rowStyle = {
            backgroundColor: '#daffd7'
          }
        } else if (resultTrip.trip_mark == 2) {
          resultTrip.__rowStyle = {
            backgroundColor: '#a4ff9c'
          }
        }

        // получаем сигнатуру рейса
        resultTrip.sign = getSignTitle(resultTrip.trip_type_sign);

        // задаем стиль ячеек
        resultTrip.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px'
        };

        return resultTrip;
      });
    }

  }
}
</script>

<style scoped>

</style>