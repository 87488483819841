<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Установить статус</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <form>

            <div class="form-group">
              <label for="routeVariantStatusId">Статус варианта маршрута:</label><ReqField />
              <route-variant-statuses-select
                  id="routeVariantStatusId"
                  v-model="routeVariantStatusId"
                  class="form-control"
                  ref="routeVariantStatusId"
                  required
                  error="Выберите статус варианта маршрута"
              />
            </div>

          </form>

        </modal-body>
        <modal-footer>
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import RouteVariantStatusesSelect from "@/components/multipass/route_variant_statuses/RouteVariantStatusesSelect";

export default {
  components: {
    RouteVariantStatusesSelect,
    ReqField
  },
  props: ['item'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
      return {
        routeVariantId: this.item.route_variant_id,
        routeVariantStatusId: this.item.route_variant_status_id,
      };
  },
  methods: {
        onSubmit() {
          if (this.validateForm(this.$refs)) {
            this.$emit('onOK', {
              route_variant_id: this.routeVariantId,
              route_variant_status_id: this.routeVariantStatusId,
            })
          }
        },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>