<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-models"></edit-table-panel>
</template>

<script>
import {getEmissionStandard, getEngineFuelType, getTransportClass, getTransportType} from "@/store/RefHelpers";

export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "model_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__transportTypeName",
          caption: "Вид транспорта",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__transportClassName",
          caption: "Класс ТС",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__engineFuelTypeName",
          caption: "Тип топлива",
          sortType: 'string',
          show: false
        },
        {
          field: "__emissionStandardName",
          caption: "Экологический класс",
          sortType: 'string',
          show: false
        },
        {
          field: "door_count",
          caption: "Кол-во дверей",
          sortType: 'number',
          show: false
        },
        {
          field: "pass_count_seat",
          caption: "Кол-во сидячих мест",
          sortType: 'number',
          show: false
        },
        {
          field: "pass_count",
          caption: "Общее кол-во мест",
          sortType: 'number',
          show: false
        },
        {
          field: "inside_area_useful",
          caption: "Полезная площадь",
          sortType: 'number',
          show: false
        },
        {
          field: "weight_with_pass",
          caption: "Вес с пассажирами",
          sortType: 'number',
          show: false
        },
        {
          field: "length",
          caption: "Длина",
          sortType: 'number',
          show: false
        },
        {
          field: "width",
          caption: "Ширина",
          sortType: 'number',
          show: false
        },
        {
          field: "height",
          caption: "Высота",
          sortType: 'number',
          show: false
        },
        {
          field: "max_speed",
          caption: "Макс.скорость",
          sortType: 'number',
          show: false
        },
        {
          field: "is_air_conditioning",
          caption: "Кондиционер",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_air_conditioning) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_bike_mount",
          caption: "Крепление велосипедов",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_bike_mount) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_air_heating",
          caption: "Отопитель",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_air_heating) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_ramp",
          caption: "Пандус",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_ramp) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_low_floor",
          caption: "Низкопольный",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_low_floor) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
        {
          field: "is_instructor_seat",
          caption: "Место инструктора",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_instructor_seat) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      const models = this.$store.getters["ref/getModels"];
      return models.map(model => {
        const transportType = getTransportType(model.transport_type_id);
        const transportClass = getTransportClass(model.transport_class_id);
        const engineFuelType = getEngineFuelType(model.engine_fuel_type_id);
        const emissionStandard = getEmissionStandard(model.emission_standard_id);

        model.__id = model.model_id;
        model.__transportTypeName = transportType && transportType.long_name || '';
        model.__transportClassName = transportClass && transportClass.short_name || '';
        model.__engineFuelTypeName = engineFuelType && engineFuelType.long_name || '';
        model.__emissionStandardName = emissionStandard && emissionStandard.long_name || ''

        return model;
      });
    },
  },
};
</script>
