import {DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ТРАСС ТИПОВ РЕЙСОВ

export default {

    actions: {
        // запрос объекта
        doFetchTripTypeCard: (context, payload) => {
            let url = `/multipass/tripTypeCards?trip_type_id=${payload.trip_type_id}`
            if (payload.crop_turn) {
                url = `/multipass/tripTypeCards?trip_type_id=${payload.trip_type_id}&crop_turn=1`
            }

            return DoGet(
                url,
                null,
                {},
                'Запрос трассы типа рейса',
                false,
                true,
            )
        },

        // запросить список объектов
        doFetchTripTypeCards: (context, payload) => {
            return DoPost(
                '/multipass/tripTypeCards',
                payload,
                null,
                'Запрос трасс типов рейсов',
                false
            )
        },

        // сохранить трассу типа рейса
        doSaveTripTypeCard: (context, payload) => {
            return DoPut(
                '/multipass/tripTypeCards/'+payload.trip_type_id,
                payload.tripTypeCard,
                null,
                'Установка трассы типа рейса'
            )
        },
    }
}
