<template>
  <align-container>
    <align-panel align="top" height="50%">
      <div class="card h-100 vehicle-button-panel">
        <align-container>
          <align-panel align="top">
            <div class="card-header p-1">
              <div class="form-group row" style="margin-bottom: 0">
                <div class="col-0" style="margin-bottom: 0px">
                  <input-date
                      id="date"
                      autocomplete="off"
                      class="form-control"
                      v-model="date"
                      style="margin-left: 15px; width: 150px; height: 30px; padding: 0px 5px;"
                  ></input-date>
                </div>
                <div class="col" style="margin-bottom: 0px; padding: 0px"></div>
                <div class="col-0" style="margin-bottom: 0px; margin-right: 5px">
                  <div v-if="isLoadingVehicles" class="spinner-border text-primary"
                       style="height: 29px; width: 29px;"></div>
                </div>
                <div class="col-0" style="margin-bottom: 0px; margin-right: 15px">
                  <button
                      type="button"
                      class="btn btn-secondary btn-sm"
                      @click="getNavTransportOnDay"
                      title="Загрузить"
                      :disabled="isLoadingVehicles || isLoadingNav"
                  >
                    <i class="fas fa-sync-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </align-panel>

          <align-panel align="all">
            <div class="card-body">
              <align-container>
                <align-panel align="top">
                  <nav-menu class="nav-tabs" mode="single" style="height: 30px">
                    <nav-item>
                      <nav-link target-ref="pane1" target-method="select" active
                                style="padding-top: 2px; padding-bottom: 2px">Все
                      </nav-link>
                    </nav-item>
                    <!--                    <nav-item>-->
                    <!--                      <nav-link target-ref="pane2" target-method="select" style="padding-top: 2px; padding-bottom: 2px">-->
                    <!--                        Наряд-->
                    <!--                      </nav-link>-->
                    <!--                    </nav-item>-->
                  </nav-menu>
                </align-panel>

                <align-panel align="all" style="overflow-x: hidden; overflow-y: auto">
                  <tab-content mode="single">
                    <tab-pane active ref="pane1" class="p-2"
                              style="padding: 0 !important;">

                      <table class="table table-sm">
                        <thead class="thead-light sticky-top" style="font-size: small;">
                        <tr>
                          <th scope="col">Гар. №</th>
                          <th scope="col">Гос. №</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="ts in navTransportArr"
                            :key="ts.transport_id"
                            :class="{'bg-primary text-white': ts === selectedRow}"
                            @click="setSelected(ts)"
                            @dblclick="getVehicleNav"
                            style="cursor: pointer; font-size: small;  user-select: none;"
                        >
                          <td style=" padding-top: 1px !important; padding-bottom: 1px !important;"
                          >{{ ts.garage_num.length > 10 ? ts.garage_num.substring(0, 10) : ts.garage_num }}
                          </td>
                          <td style=" padding-top: 1px !important; padding-bottom: 1px !important;"
                          >{{ ts.state_num.length > 10 ? ts.state_num.substring(0, 10) : ts.state_num }}
                          </td>
                        </tr>

                        </tbody>
                      </table>
                    </tab-pane>
                    <tab-pane active ref="pane2" class="p-2"
                              style="padding: 0 !important;">
                    </tab-pane>

                  </tab-content>

                </align-panel>
              </align-container>
            </div>
          </align-panel>
        </align-container>
      </div>
    </align-panel>

    <!--  -------------------------- Линия "разделения" между верхней и нижней таблицей --------------------------------  -->

    <align-panel align="all">
      <div class="card h-100 vehicle-button-panel">
        <align-container>
          <align-panel align="all">
            <align-container>
              <align-panel align="top">
                <div class="card-header" style="padding: 4px">
                  <div class="form-group row" style="margin-bottom: 0px;">
                    <div class="col" style="margin-bottom: 0px; padding: 0"></div>
                    <div class="col-0" style="margin-bottom: 0px; margin-right: 5px">
                      <div v-if="isLoadingNav" class="spinner-border text-primary"
                           style="height: 29px; width: 29px;"></div>
                    </div>
                    <div class="col-0" style="margin-bottom: 0px; margin-right: 15px">
                      <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          @click="getVehicleNav"
                          title="Загрузить"
                          :disabled="!selectedRow || isLoadingNav"
                      >
                        <i class="fas fa-sync-alt" style="right: 7px"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </align-panel>
              <div class="card-body">
                <align-panel id="table" align="all" style="overflow-y: auto; overflow-x: hidden">
                  <table class="table table-sm">
                    <thead class="thead-light sticky-top" style="font-size: small;">
                    <tr>
                      <th class="text-center" scope="col">Скор.</th>
                      <th class="text-center" scope="col">Время</th>
                      <th class="text-center" scope="col">Ист.</th>
                      <th class="text-center" scope="col">Датч.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(nav, index)  in vehicleHistory"
                        :key="index"
                        :class="{'bg-primary text-white': include(index) }"
                        @click="select($event, index)"
                        :style="getRowStyle(nav)"
                    >
                      <td style=" padding-top: 1px !important; padding-bottom: 1px !important;"
                      >{{ nav.speed }}
                      </td>
                      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
                      >{{ getTime(nav.time_nav) }}
                      </td>
                      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
                      >{{ nav.is_history == true ? getTime(nav.time_insert) : '' }}
                      </td>
                      <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;">
                        <div class="form-group row" style="margin-bottom: 0px; margin-left: 5px">
                          <div v-if="nav.is_ign" style="margin-right: 1px">
                            <img :src="imgUrl('ign')"/>
                          </div>
                          <div v-if="nav.is_call" style="margin-right: 1px">
                            <img :src="imgUrl('mic')"/>
                          </div>
                          <div v-if="nav.is_sos">
                            <img :src="imgUrl('sos1')"/>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </align-panel>
              </div>
            </align-container>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>
</template>

<script>
import InputDate from "@/components/ui/custom/InputDate";
import {dateToString, getTimeFormatFromDateString} from "@/lib";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import ign_btn from "@/components/kvr/map/images/ign.png";
import mic_btn from "@/components/kvr/map/images/mic.png";
import sos1_btn from "@/components/kvr/map/images/sos1.png";

export default {
  name: "HistPanel",
  components: {AlignPanel, AlignContainer, InputDate},
  props: ['navTransportArr', 'selectedRow', 'vehicleHistory', 'selectedNavs', 'isLoadingVehicles', 'isLoadingNav'],
  data() {
    return {
      date: dateToString(),
    }
  },
  methods: {

    // получаем стиль строк
    getRowStyle(nav) {
      if (nav.is_hist && !nav.is_invalid) {
        return 'color: blue; cursor: pointer; font-size: small; user-select: none;'
      } else if (nav.is_invalid) {
        return 'color: red; cursor: pointer; font-size: small; user-select: none;'
      } else {
        return 'cursor: pointer; font-size: small; user-select: none;'
      }
    },

    setSelected(ts) {
      if (!(this.isLoadingVehicles || this.isLoadingNav)) {
        this.$emit('onRowSelect', ts);
      }
    },

    select(e, index) {
      if (!(this.isLoadingVehicles || this.isLoadingNav)) {
        if (e.ctrlKey && !e.shiftKey) {
          this.$emit('onCtrlSelect', index);
        } else if (!e.ctrlKey && e.shiftKey) {
          this.$emit('onShiftSelect', index);
        } else if ((!e.ctrlKey && !e.shiftKey)) {
          this.$emit('onSelect', index);
        } else {
          this.$emit('onClearSelect', index);
        }
      }
    },

    getNavTransportOnDay() {
      this.$emit('getNavTransportOnDay', this.date);
    },

    getVehicleNav() {
      this.$emit('getVehicleNav', this.date);
      var element = document.getElementById('table');
      element.scrollTop = 0;
    },

    getTime(time_nav) {
      return getTimeFormatFromDateString(time_nav)
    },

    include(index) {
      return this.selectedNavs.includes(index);
    },

    imgUrl(btn) {
      if (btn === 'ign') {
        return ign_btn;
      } else if (btn === 'mic') {
        return mic_btn;
      } else if (btn === 'sos1') {
        return sos1_btn;
      } else return '';
    }

  },

}
</script>

<style scoped>

table {
  border-collapse: separate;
  border-spacing: 0;
}

td {
  border-top: 0;
  border-bottom: 1px solid #dee2e6;
}

tbody {
  display: block;
}

thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

</style>