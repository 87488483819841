<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-asmpp-passenger-traffic-day-by-transport"> </edit-table-panel>
</template>

<script>


import {getDepot, getTransport} from "@/store/RefHelpers";
import {getRoute} from "@/store/MultipassHelpers";
import {secondsToDate} from "@/lib";

export default {
  props: ['isLoading','rowArr', 'routes', 'scheduleGraphs'],
  emits: ['setLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__depot",
          caption: "Предпр.",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__transport",
          caption: "Гар.№ТС",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "__route_arr",
          caption: "Маршруты",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "graphs",
          caption: "Граф",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center'
        },
        {
          field: "__date",
          caption: "Дата",
          sortType: 'string',
          isFilterable: true,
          show: true,
        },
        {
          field: "__week_day",
          caption: "День нед.",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "fact_trip_count",
          caption: "Кол. рейсов",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "passenger_count",
          caption: "Кол. пасс.",
          sortType: 'string',
          isFilterable: true
        },
        {
          field: "passenger_traffic",
          caption: "Пасс.*Км",
          sortType: 'string',
          isFilterable: true,
          displayFn(row) {
            if (row.passenger_traffic) {
              return Number(row.passenger_traffic).toFixed(1);
            }
            return 0
          },
        },
        {
          field: "passenger_trip_length_avg",
          caption: "Дл.средн.",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.passenger_trip_length_avg) {
              return Number(row.passenger_trip_length_avg).toFixed(1);
            }
            return 0
          },
        },
        {
          field: "passenger_inside_max",
          caption: "Макс.(пасс.)",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "passenger_inside_avg",
          caption: "Ср.наполн.",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.passenger_inside_avg) {
              return Number(row.passenger_inside_avg).toFixed(1);
            }
            return 0
          },
        },
        {
          field: "pay_sum",
          caption: "Сумма оплат",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "pay_tran_count",
          caption: "Кол оплат",
          sortType: 'number',
          isFilterable: true
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      let rows = this.rowArr;

      rows.map(async row => {
        row.__id = row.trip_id;
        row.__depot = getDepot(row.depot_id).long_name;
        let transport = getTransport(row.transport_id);
        row.__transport = transport.garage_num;
        let __route_arr = [];
        // заполняем новый массив маршрутов
        row.route_ids.map(rID => {
          __route_arr.push(getRoute(rID).route_num)
        })
        row.__route_arr =  __route_arr;
        row.__date = secondsToDate(row.date_index*24*60*60);

        if (row.weekday_num == 1) {
          row.__week_day = "Пн."
        } else if (row.weekday_num == 2) {
          row.__week_day = "Вт."
        } else if (row.weekday_num == 3) {
          row.__week_day = "Ср."
        } else if (row.weekday_num == 4) {
          row.__week_day = "Чт."
        } else if (row.weekday_num == 5) {
          row.__week_day = "Пт."
        } else if (row.weekday_num == 6) {
          row.__week_day = "Сб."
        } else if (row.weekday_num == 7) {
          row.__week_day = "Вс."
        }

        return row;
      });

      return rows;
    },
  }
};
</script>
