<template>
  <!--  РОЛИ КОНКРЕТНОГО МЕТОДА-->
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-method-role-links"> </edit-table-panel>
</template>

<script>
export default {
  props: ['id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__checked",
          caption: "Вкл",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.__checked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "role_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "role_name",
          caption: "Роль",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "desc",
          caption: "Описание роли",
          sortType: 'string',
          show: false
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      const roles = this.$store.getters["auth/getRoles"];
      const links = this.$store.getters["auth/getRoleMethodLinks"];
      return roles.map(role => {

        role.__id = role.role_id;
        role.__checked =  links.some(link => link.method_id == this.id && link.role_id == role.role_id)

        return role;
      });
    },
  },
};
</script>
