import {DoClearCache, DoDelete, DoGet, DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ СВЯЗЕЙ МЕТОДОВ С РОЛЯМИ

export default {
    state() {
        return {
            // список связей
            roleMethodLinks: [],
            // время обновления кэша new Date().getTime()
            roleMethodLinksCache: null,
            // время жизни кэша в мс (null - бесконечно)
            roleMethodLinksCacheTimeout: null
        }
    },
    getters: {
        // вернуть список связей
        getRoleMethodLinks(state) {
            return state.roleMethodLinks;
        },
    },
    mutations: {
        // установить новый список связей
        setRoleMethodLinks(state, payload) {
            state.roleMethodLinks = payload;
            state.roleMethodLinksCache = new Date().getTime()
        },
        // связать объекты
        addMethodToRole(state, payload) {
            state.roleMethodLinks.push(payload);
        },
        // разорвать связь объектов
        delMethodFromRole(state, payload) {
            const index = state.roleMethodLinks.findIndex(
                link => link.role_id == payload.role_id && link.method_id == payload.method_id
            );
            state.roleMethodLinks.splice(index, 1);
        },
    },
    actions: {
        // запрос список связей
        doFetchRoleMethodLinks: (context, payload) => {
            return DoGet(
                '/auth/roleMethodLinks',
                'auth/setRoleMethodLinks',
                [],
                'Запрос связей методов с ролями',
                false,
                payload && payload.force,
                context.state.roleMethodLinksCache,
                context.state.roleMethodLinksCacheTimeout
            )
        },

        // очищает кэш
        doClearRoleMethodLinks: (context, payload) => {
            return DoClearCache(
                'auth/setRoleMethodLinks',
                [],
                payload && payload.force,
                context.state.roleMethodLinksCache,
                context.state.roleMethodLinksCacheTimeout
            )
        },

        // связать объекты
        doAddMethodToRole: (context, payload) => {
            return DoPost(
                '/auth/roleMethodLinks',
                payload,
                'auth/addMethodToRole',
                'Добавление метода к роли'
            )
        },

        // удалить связь объектов
        doDelMethodFromRole: (context, payload) => {
            return DoDelete(
                '/auth/roleMethodLinks/'+payload.role_id+'/'+payload.method_id,
                'auth/delMethodFromRole',
                'Удаление метода у роли'
            )
        },
    }
}