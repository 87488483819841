<template>
  <!--  ТАБЛИЦА МАРШРУТОВ С ПОМЕТКОЙ ДЛЯ КАЖДОЙ ГРУППЫ-->
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-multipass-group-route-links"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getTransportType} from "@/store/RefHelpers";
import {getRouteKind, getRouteStatus, getRouteType, getTransferKind} from "@/store/MultipassHelpers";
export default {
  components: {EditTablePanel},
  props: ['id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__checked",
          caption: "Вкл",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.__checked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "route_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "route_num",
          caption: "Номер",
          sortType: 'routeNum',
          isFilterable: true,
        },
        {
          field: "route_title",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "reg_num",
          caption: "Рег.номер",
          sortType: 'string',
          show: false
        },
        {
          field: "__transportTypeName",
          caption: "Вид транспорта",
          sortType: 'string',
        },
        {
          field: "__routeKindName",
          caption: "Вид маршрута",
          sortType: 'string',
        },
        {
          field: "__routeTypeName",
          caption: "Тип маршрута",
          sortType: 'string',
        },
        {
          field: "__transferKindName",
          caption: "Вид сообщения",
          sortType: 'string',
        },
        {
          field: "__routeStatusName",
          caption: "Статус",
          sortType: 'string',
        },
        {
          field: "open_date",
          caption: "Дата открытия",
          sortType: 'string',
        },
        {
          field: "close_date",
          caption: "Дата закрытия",
          sortType: 'string',
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          show: false
        },
        {
          field: "area_classifier_id",
          caption: "Код ОКАТО (ОКТМО)",
          sortType: 'string',
          show: false
        },
        {
          field: "is_social",
          caption: "Соц.",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_social) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      const routes = this.$store.getters["multipass/getRoutes"];
      const links = this.$store.getters["multipass/getRouteGroupLinks"];
      return routes.map(route => {

        route.__id = route.route_id;
        const transportType = getTransportType(route.transport_type_id);
        const routeKind = getRouteKind(route.route_kind_id);
        const routeType = getRouteType(route.route_type_id)
        const transferKind = getTransferKind(route.transfer_kind_id);
        const routeStatus = getRouteStatus(route.route_status_id);

        route.__transportTypeName = transportType && transportType.long_name || '';
        route.__routeKindName = routeKind && routeKind.long_name || '';
        route.__routeTypeName = routeType && routeType.long_name || '';
        route.__transferKindName = transferKind && transferKind.long_name || '';
        route.__routeStatusName = routeStatus && routeStatus.long_name || '';
        route.__checked = links.some(link => link.route_group_id == this.id && link.route_id == route.route_id);

        return route;
      });
    },
  },
};
</script>
