import store from './Store';
import {quickFind} from "@/lib";

// вернуть маршрут
export function getRoute(route_id) {
    return store.getters["multipass/getRoutes"].find(route => route.route_id == route_id);
}

// вернуть вид маршрута
export function getRouteKind(route_kind_id) {
    return store.getters["multipass/getRouteKinds"].find(kind => kind.route_kind_id == route_kind_id);
}

// вернуть тип маршрута
export function getRouteType(route_type_id) {
    return store.getters["multipass/getRouteTypes"].find(type => type.route_type_id == route_type_id);
}

// вернуть вид сообщения
export function getTransferKind(transfer_kind_id) {
    return store.getters["multipass/getTransferKinds"].find(kind => kind.transfer_kind_id == transfer_kind_id);
}

// вернуть статус маршрута
export function getRouteStatus(route_status_id) {
    return store.getters["multipass/getRouteStatuses"].find(status => status.route_status_id == route_status_id);
}

// вернуть статус варианта маршрута
export function getRouteVariantStatus(route_variant_status_id) {
    return store.getters["multipass/getRouteVariantStatuses"].find(status => status.route_variant_status_id == route_variant_status_id);
}

//Вернуть группу маршрутов
export function getRouteGroup(route_group_id) {
    return store.getters["multipass/getRouteGroups"].find(group => group.route_group_id == route_group_id)
}

// вернуть тип тарифа
export function getTariffType(tariff_type_id) {
    return store.getters["multipass/getTariffTypes"].find(type => type.tariff_type_id == tariff_type_id);
}

// вернуть вид тарифа
export function getTariffKind(tariff_kind_id) {
    return store.getters["multipass/getTariffKinds"].find(kind => kind.tariff_kind_id == tariff_kind_id);
}


// вернуть тарифную зональную систему
export function getTariffZoneSystem(tariff_zone_system_id) {
    return store.getters["multipass/getTariffZoneSystems"].find(sys => sys.tariff_zone_system_id == tariff_zone_system_id);
}

// вернуть остановку
export function getStop(stop_id) {
    const res = quickFind({stop_id}, store.getters["multipass/getStops"], (l, r) => {
        if (l.stop_id < r.stop_id) return(-1)
            else if (l.stop_id > r.stop_id) return(1)
                else return 0
    });
    if (res.Result) return(store.getters["multipass/getStops"][res.Index])
        else return null;
}

// вернуть индекс остановки
export function getStopIndex(stop_id) {
    const res = quickFind({stop_id}, store.getters["multipass/getStops"], (l, r) => {
        if (l.stop_id < r.stop_id) return(-1)
        else if (l.stop_id > r.stop_id) return(1)
        else return 0
    });
    if (res.Result) return(res.Index)
        else return (-1)
}

// функция сортировки по широте
export function latitudeSortFn (l , r) {
    if ((l.latitude || 0) < (r.latitude || 0)) return(-1)
    else if ((l.latitude || 0) > (r.latitude || 0)) return(1)
    else return(0)
}

// функция сортировки по долготе
export function longitudeSortFn (l , r) {
    if ((l.longitude || 0) < (r.longitude || 0)) return(-1)
    else if ((l.longitude || 0) > (r.longitude || 0)) return(1)
    else return(0)
}

// возвращает список остановок в заданном квадрате
export function getStopsInRectangle(minLat, maxLat, minLong, maxLong) {
    const result = [];

    const stopLatitudes = store.getters["multipass/getStopLatitudes"];
    const stopLongitudes = store.getters["multipass/getStopLongitudes"];

    // определяем минимальные и максимальные индексы по широте и долготе
    const minLatIndex = quickFind({latitude: minLat}, stopLatitudes, latitudeSortFn).Index;
    const maxLatIndex = quickFind({latitude: maxLat}, stopLatitudes, latitudeSortFn).Index;
    const minLongIndex = quickFind({longitude: minLong}, stopLongitudes, longitudeSortFn).Index;
    const maxLongIndex = quickFind({longitude: maxLong}, stopLongitudes, longitudeSortFn).Index;

    for (let i = minLatIndex; i <= maxLatIndex; i++) {
        for (let j = minLongIndex; j <= maxLongIndex; j++) {
            if (stopLatitudes[i] === stopLongitudes[j]) {
                result.push(stopLatitudes[i]);
                break;
            }
        }
    }

    return result;
}

// вернуть тип остановки
export function getStopType(stop_type_id) {
    return store.getters["multipass/getStopTypes"].find(type => type.stop_type_id == stop_type_id);
}

// вернуть автостанцию
export function getStopstation(stopstation_id) {
    return store.getters["multipass/getStopstations"].find(stopstation => stopstation.stopstation_id == stopstation_id);
}

// вернуть тип автстанции
export function getStopstationType(stopstation_type_id) {
    return store.getters["multipass/getStopstationTypes"].find(type => type.stopstation_type_id == stopstation_type_id);
}

// вернуть остановочный павильон
export function getStopPavilion(stop_pavilion_id) {
    return store.getters["multipass/getStopPavilions"].find(stopPavilion => stopPavilion.stop_pavilion_id == stop_pavilion_id);
}

// вернуть тип остановочного павильона
export function getStopPavilionType(stop_pavilion_type_id) {
    return store.getters["multipass/getStopPavilionTypes"].find(type => type.stop_pavilion_type_id == stop_pavilion_type_id);
}

// вернуть вид остановочного павильона
export function getStopPavilionKinds(stop_pavilion_kind_id) {
    return store.getters["multipass/getStopPavilionKinds"].find(type => type.stop_pavilion_kind_id == stop_pavilion_kind_id);
}