<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Доступ пользователя к данным</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>

            <div class="form-group">
              <label for="uid">Код:</label>
              <input-integer
                  id="uid"
                  class="form-control w-25"
                  v-model="accessCategoryId"
                  readonly
              />
            </div>

            <div class="form-group">
              <label for="accessCategoryId">Категория доступа:</label>
              <ReqField/>

              <access-categories-select
                  id="accessCategoryId"
                  v-model="accessCategoryId"
                  class="form-control"
                  ref="accessCategoryId"
                  :readonly="op === 'show' || op === 'edit'"
                  required
                  error="Выберите категорию доступа"
              />
            </div>

            <label for="transportTypeGroupId">Вид транспорта:</label>
            <div class="form-group row">
              <div class="form-group col">
                <transport-type-groups-select
                    id="transportTypeGroupId"
                    v-model="transportTypeGroupId"
                    class="form-control"
                    ref="transportTypeGroupId"
                    :readonly="op === 'show'"
                />
              </div>

              <div class="form-check col">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type='radio' name='isTransportTypeBlackList'
                         id='isTransportTypeBlackListTrue'
                         value="1" v-model='isTransportTypeBlackList' :disabled="op === 'show'"/>
                  <label class="form-check-label" for='isTransportTypeBlackListTrue'>ч/с</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type='radio' name='isTransportTypeBlackList'
                         id='isTransportTypeBlackListFalse'
                         value="2" v-model='isTransportTypeBlackList' :disabled="op === 'show'"/>
                  <label class="form-check-label" for='isTransportTypeBlackListFalse'>б/с</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type='radio' name='isTransportTypeBlackList'
                         id='isTransportTypeBlackListDash'
                         value="0" v-model='isTransportTypeBlackList' :disabled="op === 'show'"/>
                  <label class="form-check-label" for='isTransportTypeBlackListDash'>Нет</label>
                </div>
              </div>
            </div>

            <label for="depotGroupId">Предприятия:</label>
            <div class="form-group row">
              <div class="form-group col">
                <depot-groups-select
                    id="depotGroupId"
                    v-model="depotGroupId"
                    class="form-control"
                    ref="depotGroupId"
                    :readonly="op === 'show'"
                />
              </div>

              <div class="form-check col">
                <div class="form-check form-check-inline" >
                  <input class="form-check-input" id='isDepotBlackListTrue' name='isDepotBlackList'
                         value=1 type='radio' v-model='isDepotBlackList' :disabled="op === 'show'"/>
                  <label class="form-check-label" for='isDepotBlackListTrue'>ч/с</label>
                </div>
                <div class="form-check form-check-inline" >
                  <input class="form-check-input" id='isDepotBlackListFalse' name='isDepotBlackList'
                         value=2 type='radio' v-model='isDepotBlackList' :disabled="op === 'show'"/>
                  <label class="form-check-label" for='isDepotBlackListFalse'>б/с</label>
                </div>
                <div class="form-check form-check-inline" >
                  <input class="form-check-input" id='isDepotBlackListDash' name='isDepotBlackList'
                         value=0 type='radio' v-model='isDepotBlackList' :disabled="op === 'show'"/>
                  <label class="form-check-label" for='isDepotBlackListDash'>Нет</label>
                </div>
              </div>
            </div>

            <label for="routeGroupId">Маршруты:</label>
            <div class="form-group row">
              <div class="form-group col">
                <route-groups-select
                    id="routeGroupId"
                    v-model="routeGroupId"
                    class="form-control"
                    ref="routeGroupId"
                    :readonly="op === 'show'"
                />
              </div>

              <div class="form-check col">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" id='isRouteBlackListTrue' name='isRouteBlackList'
                         value=1 type='radio' v-model='isRouteBlackList' :disabled="op === 'show'" />
                  <label class="form-check-label" for='isRouteBlackListTrue'>ч/с</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" id='isRouteBlackListFalse' name='isRouteBlackList'
                         value=2 type='radio' v-model='isRouteBlackList' :disabled="op === 'show'" />
                  <label class="form-check-label" for='isRouteBlackListFalse'>б/с</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" id='isRouteBlackListDash' name='isRouteBlackList'
                         value=0 type='radio' v-model='isRouteBlackList' :disabled="op === 'show'" />
                  <label class="form-check-label" for='isRouteBlackListDash'>Нет</label>
                </div>
              </div>
            </div>

            <label for="transportGroupId">Транспорт:</label>
            <div class="form-group row">
              <div class="form-group col">
                <transport-groups-select
                    id="transportGroupId"
                    v-model="transportGroupId"
                    class="form-control"
                    ref="transportGroupId"
                    :readonly="op === 'show'"
                />
              </div>

              <div class="form-check col">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" id='isTransportBlackListTrue' name='isTransportBlackList'
                         value=1 type='radio' v-model='isTransportBlackList' :disabled="op === 'show'"/>
                  <label class="form-check-label" for='isTransportBlackListTrue'>ч/с</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" id='isTransportBlackListFalse' name='isTransportBlackList'
                         value=2 type='radio' v-model='isTransportBlackList' :disabled="op === 'show'"/>
                  <label class="form-check-label" for='isTransportBlackListFalse'>б/с</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" id='isTransportBlackListDash' name='isTransportBlackList'
                         value=0 type='radio' v-model='isTransportBlackList' :disabled="op === 'show'"/>
                  <label class="form-check-label" for='isTransportBlackListDash'>Нет</label>
                </div>
              </div>
            </div>

          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import InputInteger from "@/components/ui/custom/InputInteger";
import AccessCategoriesSelect from "@/components/access/access_categories/AccessCategoriesSelect";
import TransportTypeGroupsSelect from "@/components/ref/transport_type_groups/TransportTypeGroupsSelect";
import TransportGroupsSelect from "@/components/ref/transport_groups/TransportGroupsSelect";
import DepotGroupsSelect from "@/components/ref/depot_groups/DepotGroupsSelect";
import RouteGroupsSelect from "@/components/multipass/route_groups/RouteGroupsSelect";

export default {
  components: {
    RouteGroupsSelect,
    DepotGroupsSelect,
    TransportGroupsSelect,
    TransportTypeGroupsSelect,
    AccessCategoriesSelect,
    InputInteger,
    ReqField
  },
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      uid: this.uid,
      accessCategoryId: this.item.access_category_id,
      transportTypeGroupId: this.item.transport_type_group_id,
      isTransportTypeBlackList: this.item.is_transport_type_black_list != null ? (this.item.is_transport_type_black_list === true ? "1" : "2") : "0",
      transportGroupId: this.item.transport_group_id,
      isTransportBlackList: this.item.is_transport_black_list != null ? (this.item.is_transport_black_list === true ? "1" : "2") : "0",
      depotGroupId: this.item.depot_group_id,
      isDepotBlackList: this.item.is_depot_black_list != null ? (this.item.is_depot_black_list === true ? "1" : "2") : "0",
      routeGroupId: this.item.route_group_id,
      isRouteBlackList: this.item.is_route_black_list != null ? (this.item.is_route_black_list === true ? "1" : "2") : "0",
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {

        let transportTypeBlackList = null;
        if (this.isTransportTypeBlackList === '1') {
          transportTypeBlackList = true;
        } else if (this.isTransportTypeBlackList === '2') {
          transportTypeBlackList = false;
        }

        let transportBlackList = null;
        if (this.isTransportBlackList === '1') {
          transportBlackList = true;
        } else if (this.isTransportBlackList === '2') {
          transportBlackList = false;
        }

        let depotBlackList = null;
        if (this.isDepotBlackList === '1') {
          depotBlackList = true;
        } else if (this.isDepotBlackList === '2') {
          depotBlackList = false;
        }

        let routeBlackList = null;
        if (this.isRouteBlackList === '1') {
          routeBlackList = true;
        } else if (this.isRouteBlackList === '2') {
          routeBlackList = false;
        }

        this.$emit('onOK', {
          uid: this.uid,
          access_category_id: this.accessCategoryId,
          transport_type_group_id: this.transportTypeGroupId,
          is_transport_type_black_list: transportTypeBlackList,
          transport_group_id: this.transportGroupId,
          is_transport_black_list: transportBlackList,
          depot_group_id: this.depotGroupId,
          is_depot_black_list: depotBlackList,
          route_group_id: this.routeGroupId,
          is_route_black_list: routeBlackList
        })
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>