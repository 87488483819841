<template>

  <align-container style="height: 2000px">

    <align-panel id="table" align="all" style="overflow-y: auto; overflow-x: hidden">

      <table class="table table-sm">
        <thead class="thead-light sticky-top" style="font-size: small;">
        <tr>
          <th class="text-center" scope="col">Скор.</th>
          <th class="text-center" scope="col">Время</th>
          <th class="text-center" scope="col">Ист.</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="nav in getRows"
            :key="nav.nav_id"
            :class="{'bg-primary text-white': include(nav.nav_id)}"
            @click="select($event, nav.nav_id)"
            :style="getRowStyle(nav)"
        >
          <td style=" padding-top: 1px !important; padding-bottom: 1px !important;"
          >{{ nav.speed }}
          </td>
          <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
          >{{ nav.value }}
          </td>
          <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;"
          >{{ nav.speed }}
          </td>
          <td class="text-center" style=" padding-top: 1px !important; padding-bottom: 1px !important;">
          </td>
        </tr>
        </tbody>
      </table>
    </align-panel>
  </align-container>

  <auth-form></auth-form>

  <!--    <input-string-->
  <!--      v-model="selected"-->
  <!--      style=" resize: both !important;-->
  <!--              overflow: scroll;-->
  <!--              border: 1px solid black;"-->
  <!--      required-->
  <!--    >-->
  <!--    </input-string>-->

  <!--    <p>{{selected}}</p>-->
  <!--    <p>{{typeof selected }}</p>-->
  <!--  class="cont"-->

  <!--  <lookup-input-select-->
  <!--      caption="Введите что-нибудь"-->
  <!--      :itemList="rowList"-->
  <!--      v-model="selectedRow"-->
  <!--      required-->
  <!--  >-->
  <!--  </lookup-input-select>-->

  <!--  <p>{{selectedRow}}</p>-->
  <!--  <p>{{typeof selectedRow }}</p>-->


  <!--  <input-currency-->
  <!--      v-model="intValue"-->
  <!--      required-->
  <!--  ></input-currency>-->
  <!--  <p>{{intValue}}</p>-->
  <!--  <p>{{typeof intValue }}</p>-->

  <!--  <input-select :itemList="list" caption="Выберите значение" v-model="selected"></input-select>-->
  <!--  <input-button-option :itemList="list" v-model="selected" required></input-button-option>-->
  <!--  <p>{{selected}}</p>-->
  <!--  <p>{{typeof selected }}</p>-->


  <!--  <div>-->
  <!--    <input-minute v-model="price"></input-minute>-->
  <!--    <p>{{price}}</p>-->
  <!--    <p>{{typeof price }}</p>-->
  <!--  </div>-->

  <!--  <div>-->
  <!--    <input-currency v-model="price"></input-currency>-->
  <!--    <p>{{price}}</p>-->
  <!--    <p>{{typeof price }}</p>-->
  <!--  </div>-->

  <!--  <div>-->
  <!--    <div>-->
  <!--      <label>File-->
  <!--        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>-->
  <!--      </label>-->
  <!--      <button v-on:click="submitFile()">Submit</button>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  <div>-->
  <!--    <input-sign v-model="raceType"></input-sign>-->
  <!--    <p>{{raceType}}</p>-->
  <!--    <p>{{typeof raceType }}</p>-->
  <!--  </div>-->


  <!--  <leaflet-map-->
  <!--      style="height: 450px; width: 100%"-->
  <!--      :center="[55.725937300000005, 37.65464410000001]"-->
  <!--      zoom="15"-->
  <!--      :options="{doubleClickZoom: false}"-->
  <!--      :contextMenu="mapContextMenu"-->
  <!--  >-->
  <!--    <leaflet-zoom-control :visible="isZoomControl" :options="zoomControlOptions"></leaflet-zoom-control>-->
  <!--    <leaflet-attribution-control :visible="isAttrControl"></leaflet-attribution-control>-->
  <!--    <leaflet-scale-control :visible="isScaleControl"></leaflet-scale-control>-->
  <!--    <leaflet-layers-control :visible="isLayersControl"></leaflet-layers-control>-->

  <!--    <leaflet-tile-layer-->
  <!--        v-for="(layer, index) in baseLayers"-->
  <!--        :key="layer.title"-->
  <!--        :urlTemplate="layer.urlTemplate"-->
  <!--        :title="layer.title"-->
  <!--        :options="layer.options"-->
  <!--        :visible="index === 1"-->
  <!--    ></leaflet-tile-layer>-->

  <!--&lt;!&ndash;    <leaflet-marker&ndash;&gt;-->
  <!--&lt;!&ndash;        v-if="MarkerAlive"&ndash;&gt;-->
  <!--&lt;!&ndash;        :latLng="markerCoord"&ndash;&gt;-->
  <!--&lt;!&ndash;        :visible="isMarker"&ndash;&gt;-->
  <!--&lt;!&ndash;        :options="markerOptions"&ndash;&gt;-->
  <!--&lt;!&ndash;        tooltip="проверка"&ndash;&gt;-->
  <!--&lt;!&ndash;    >&ndash;&gt;-->
  <!--&lt;!&ndash;    </leaflet-marker>&ndash;&gt;-->

  <!--&lt;!&ndash;    <leaflet-marker&ndash;&gt;-->
  <!--&lt;!&ndash;      :latlng="markerLatLng"&ndash;&gt;-->
  <!--&lt;!&ndash;      :visible="markerVisible"&ndash;&gt;-->
  <!--&lt;!&ndash;      :options="markerOptions"&ndash;&gt;-->
  <!--&lt;!&ndash;      :icon="markerIcon"&ndash;&gt;-->
  <!--&lt;!&ndash;    >&ndash;&gt;-->

  <!--&lt;!&ndash;    </leaflet-marker>&ndash;&gt;-->


  <!--&lt;!&ndash;    <leaflet-circle&ndash;&gt;-->
  <!--&lt;!&ndash;        :latlng="circleLatLng"&ndash;&gt;-->
  <!--&lt;!&ndash;        :radius="circleRadius"&ndash;&gt;-->
  <!--&lt;!&ndash;        :visible="circleVisible"&ndash;&gt;-->
  <!--&lt;!&ndash;        :options="circleOptions"&ndash;&gt;-->
  <!--&lt;!&ndash;    >&ndash;&gt;-->
  <!--&lt;!&ndash;    </leaflet-circle>&ndash;&gt;-->

  <!--&lt;!&ndash;    <leaflet-polyline&ndash;&gt;-->
  <!--&lt;!&ndash;      :latlngs="polylineLatLngs"&ndash;&gt;-->
  <!--&lt;!&ndash;      :options="polylineOptions"&ndash;&gt;-->
  <!--&lt;!&ndash;      :visible="polylineVisible"&ndash;&gt;-->
  <!--&lt;!&ndash;    >&ndash;&gt;-->
  <!--&lt;!&ndash;    </leaflet-polyline>&ndash;&gt;-->

  <!--&lt;!&ndash;    <leaflet-polygon&ndash;&gt;-->
  <!--&lt;!&ndash;        :latlngs="polygonLatLngs"&ndash;&gt;-->
  <!--&lt;!&ndash;        :options="polygonOptions"&ndash;&gt;-->
  <!--&lt;!&ndash;        :visible="polygonVisible"&ndash;&gt;-->
  <!--&lt;!&ndash;    >&ndash;&gt;-->
  <!--&lt;!&ndash;    </leaflet-polygon>&ndash;&gt;-->

  <!--&lt;!&ndash;        <polyline-zone-element&ndash;&gt;-->
  <!--&lt;!&ndash;          :zoneElement="zoneElement"&ndash;&gt;-->
  <!--&lt;!&ndash;          state="edit"&ndash;&gt;-->
  <!--&lt;!&ndash;          visible&ndash;&gt;-->
  <!--&lt;!&ndash;          events="click dblclick"&ndash;&gt;-->
  <!--&lt;!&ndash;          @onLeafletEvent="onLeafletEvent"&ndash;&gt;-->
  <!--&lt;!&ndash;        >&ndash;&gt;-->
  <!--&lt;!&ndash;        </polyline-zone-element>&ndash;&gt;-->


  <!--      <point-zone-element-->
  <!--        :zoneElement="zoneElement"-->
  <!--        state="edit"-->
  <!--        visible-->
  <!--        events="click dblclick"-->
  <!--        @onLeafletEvent="onLeafletEvent"-->
  <!--      >-->
  <!--      </point-zone-element>-->


  <!--    &lt;!&ndash;    <polygon-zone-element&ndash;&gt;-->
  <!--&lt;!&ndash;      :zoneElement="zoneElement"&ndash;&gt;-->
  <!--&lt;!&ndash;      state="edit"&ndash;&gt;-->
  <!--&lt;!&ndash;      visible&ndash;&gt;-->
  <!--&lt;!&ndash;      events="click dblclick"&ndash;&gt;-->
  <!--&lt;!&ndash;      @onLeafletEvent="onLeafletEvent"&ndash;&gt;-->
  <!--&lt;!&ndash;    >&ndash;&gt;-->
  <!--&lt;!&ndash;    </polygon-zone-element>&ndash;&gt;-->

  <!--&lt;!&ndash;    <circle-zone-element&ndash;&gt;-->
  <!--&lt;!&ndash;        :zoneElement="zoneElement"&ndash;&gt;-->
  <!--&lt;!&ndash;        state="edit"&ndash;&gt;-->
  <!--&lt;!&ndash;        visible&ndash;&gt;-->
  <!--&lt;!&ndash;        events="click dblclick"&ndash;&gt;-->
  <!--&lt;!&ndash;        @onLeafletEvent="onLeafletEvent"&ndash;&gt;-->
  <!--&lt;!&ndash;    >&ndash;&gt;-->
  <!--&lt;!&ndash;    </circle-zone-element>&ndash;&gt;-->


  <!--&lt;!&ndash;    <base-polyline&ndash;&gt;-->
  <!--&lt;!&ndash;      :polyline="polyline"&ndash;&gt;-->
  <!--&lt;!&ndash;      visible&ndash;&gt;-->
  <!--&lt;!&ndash;    >&ndash;&gt;-->

  <!--&lt;!&ndash;    </base-polyline>&ndash;&gt;-->


  <!--&lt;!&ndash;    <stop-marker&ndash;&gt;-->
  <!--&lt;!&ndash;        v-for="(stop, index) in stops"&ndash;&gt;-->
  <!--&lt;!&ndash;        :key="stop.stop_id"&ndash;&gt;-->
  <!--&lt;!&ndash;        :stop="stop"&ndash;&gt;-->
  <!--&lt;!&ndash;        :visible="isMarker"&ndash;&gt;-->
  <!--&lt;!&ndash;        :contextMenu="contextMenu"&ndash;&gt;-->
  <!--&lt;!&ndash;        events="click"&ndash;&gt;-->
  <!--&lt;!&ndash;        @leaflet-event="markerEvent($event, index)"&ndash;&gt;-->
  <!--&lt;!&ndash;    ></stop-marker>&ndash;&gt;-->

  <!--&lt;!&ndash;        <stop-marker&ndash;&gt;-->
  <!--&lt;!&ndash;            ref="marker"&ndash;&gt;-->
  <!--&lt;!&ndash;            :stop="stops[1]"&ndash;&gt;-->
  <!--&lt;!&ndash;            :visible="isMarker"&ndash;&gt;-->
  <!--&lt;!&ndash;            events="click"&ndash;&gt;-->
  <!--&lt;!&ndash;            @leaflet-event="markerEvent($event, index)"&ndash;&gt;-->
  <!--&lt;!&ndash;        ></stop-marker>&ndash;&gt;-->


  <!--  </leaflet-map>-->
  <!--&lt;!&ndash;  <button @click="isZoomControl=!isZoomControl">Toggle Zoom Control</button>&ndash;&gt;-->
  <!--&lt;!&ndash;  <button @click="isAttrControl=!isAttrControl">Toggle Attr Control</button>&ndash;&gt;-->
  <!--&lt;!&ndash;  <button @click="isScaleControl=!isScaleControl">Toggle Scale Control</button>&ndash;&gt;-->
  <!--&lt;!&ndash;  <button @click="isLayersControl=!isLayersControl">Toggle Layers Control</button>&ndash;&gt;-->

  <!--&lt;!&ndash;  <button @click="isBaseLayer=!isBaseLayer">Toggle Base Layer</button>&ndash;&gt;-->
  <!--&lt;!&ndash;  <button @click="toggleMarker">Toggle Marker</button>&ndash;&gt;-->
  <!--&lt;!&ndash;  <button @click="MarkerAlive = !MarkerAlive">Toggle Marker Alive</button>&ndash;&gt;-->
  <!--&lt;!&ndash;  <button @click="setMarkerOptions">Set Marker Options</button>&ndash;&gt;-->
  <!--&lt;!&ndash;  <button @click="markerState='edit'">Marker State</button>&ndash;&gt;-->
  <!--&lt;!&ndash;  <button @click="markerType='1'">Marker Type</button>&ndash;&gt;-->

  <!--  <button @click="testMarker">TestMarker</button>-->
  <!--  <button @click="testCircle">Test Circle</button>-->
  <!--  <button @click="testPolyline">Test Polyline</button>-->
  <!--  <button @click="testPolygon">Test Polygon</button>-->
  <!--&lt;!&ndash;  <button @click="polyline.__selected = 'active'">Hook Polyline</button>&ndash;&gt;-->
</template>

<script>

//import stops from './stops';

import {postFileRequest} from "@/store/Requests";
import store from "@/store/Store";
import AuthForm from "@/components/main/AuthForm";

const wait = function (ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export default {
  components: {AuthForm},
  computed: {
    // baseLayerValues() {
    //   let res = {}
    //   for (index in this.baseLayers) {
    //     res[this.baseLayers[index].title]
    //   }
    // }
    getStyle() {
      return 'height: 100px; width:' + this.selected + 'px; background-color: #6f42c1; resize: both;';
    },

    getRows() {
      let arr = [];
      for (let i = 1; i < 5000; i++) {
        arr.push({
          nav_id: i,
          value: 'Строка ' + i,
          speed: i * 5,
        });
      }
      return arr;
    }

  },
  data() {
    return {
      // список выбранных навигационных отметок
      vehicleHistoryArr: [],
      selected: 200,
      intValue: '-120',
      list: [
        {id: 1, title: 'Вариант 1'},
        {id: 2, title: 'Вариант 2'},
        {id: 3, title: 'Вариант 3'},
      ],

      price: 0,
      file: '',
      filterText: '',
      raceType: null,
      columnList: [
        {
          field: "id",
          caption: "Код",
          isKey: true,
          isSortable: true,
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "name",
          caption: "Наименование",
          isKey: false,
          isSortable: true,
          sortType: 'string',
          isFilterable: true,
          displayFn(row) {
            return '_' + row.name + '_';
          }
        },
        {
          field: "__button",
          displayType: "button",
          buttonCaption: "Привет",
          buttonClick(row) {
            console.log(`Привет ${row.name}`)
          }
        },
        {
          field: "__button",
          displayType: "button",
          buttonCaption: "Пока",
          buttonClick(row) {
            console.log(`Пока ${row.name}`)
          }
        }
      ],

      rowList: [
        {id: 1, title: 'Максим'},
        {id: 2, title: 'Иван'},
        {id: 3, title: 'Антон'},
        {id: 4, title: 'Вадим'},
        {id: 5, title: 'Павел'},
        {id: 6, title: 'Гоша'},
        {id: 7, title: 'Евгений'},
        {id: 8, title: 'Алеша'},
        {id: 9, title: 'Зиновий'},
        {id: 10, title: 'Кирилл'},
      ],

      selectedRow: 3,

      sign: '-100',
      okatoValue: "12",
      baseLayers: [
        {
          title: "OpenStreetMap",
          urlTemplate: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          options: {attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'},
        },
        {
          title: "Спутник.Карты",
          urlTemplate: "http://{s}.tiles.maps.sputnik.ru/{z}/{x}/{y}.png",
          options: {attribution: "&copy; <a href='http://corp.sputnik.ru/maps'>Спутник</a> contributors"},
        }
      ],
      mapContextMenu: {
        contextmenu: true,
        contextmenuItems: [],
      },
      contextMenu: {
        contextmenu: true,
        contextmenuInheritItems: false,
        contextmenuItems: [{
          text: 'Marker item',
          index: 0
        }, {
          separator: true,
          index: 1
        }]
      },
      isZoomControl: true,
      zoomControlOptions: {},
      isAttrControl: true,
      isScaleControl: true,
      isLayersControl: true,

      isBaseLayer: true,
      openmapOptions: {attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'},
      sputnikOptions: {attribution: "&copy; <a href='http://corp.sputnik.ru/maps'>Спутник</a> contributors"},

      isMarker: false,
      MarkerAlive: true,
      markerCoord: [55.725937300000005, 37.65464410000001],
      markerState: 0,
      markerType: 0,

      markerLatLng: [55.725937300000005, 37.65464410000001],
      markerIcon: null,
      markerOptions: {},
      markerVisible: false,

      circleLatLng: [55.725937300000005, 37.65464410000001],
      circleRadius: 100,
      circleVisible: false,
      circleOptions: {},

      polylineLatLngs: [[55.725937300000005, 37.65464410000001], [55.825937300000005, 37.75464410000001]],
      polylineOptions: {},
      polylineVisible: false,

      polygonLatLngs: [[55.725937300000005, 37.65464410000001], [55.825937300000005, 37.75464410000001], [55.925937300000005, 37.95464410000001]],
      polygonOptions: {},
      polygonVisible: false,

      polyline: {
        polyline_id: 0,
        polyline_type_id: 0,
        polyline_title: 'Тест',
        length: 0,
        note: '',
        __selected: "edit",
        points: [
          {
            polyline_id: 0,
            order_num: 1,
            latitude: 55.825937300000005,
            longitude: 37.65464410000001,
          },
          {
            polyline_id: 0,
            order_num: 2,
            latitude: 55.725937300000005,
            longitude: 37.65464410000001,
          },
          {
            polyline_id: 0,
            order_num: 3,
            latitude: 55.725937300000005,
            longitude: 37.85464410000001,
          },
        ]
      },

      zoneElement: {
        zone_element_id: 0,
        zone_element_type_id: 4,
        zone_id: 0,
        order_num: 0,
        parameter_size: 200,
        points: [
          {
            zone_element_id: 0,
            order_num: 1,
            latitude: 55.825937300000005,
            longitude: 37.65464410000001,
          },
          {
            zone_element_id: 0,
            order_num: 2,
            latitude: 55.725937300000005,
            longitude: 37.65464410000001,
          },
          {
            zone_element_id: 0,
            order_num: 3,
            latitude: 55.725937300000005,
            longitude: 37.85464410000001,
          },
        ]
      },

      // interface POLYLINE {
//   polyline_id: number;
//   polyline_type_id: number;
//   polyline_title: string;
//   length: number;
//   note: string;
//   points: POLYLINE_POINT[]
// }

// interface POLYLINE_POINT {
//   polyline_id: number;
//   order_num: number;
//   latitude: number;
//   longitude: number;
// }

    }
  },
  methods: {

    // получаем стиль строк
    getRowStyle(nav) {
      if (nav.is_hist && !nav.is_invalid) {
        return 'color: blue; cursor: pointer; font-size: small; user-select: none;'
      } else if (nav.is_invalid) {
        return 'color: red; cursor: pointer; font-size: small; user-select: none;'
      } else {
        return 'cursor: pointer; font-size: small; user-select: none;'
      }
    },

    select(e, nav_id) {
      if (!this.isLoading) {
        if (e.ctrlKey && !e.shiftKey) {
          this.$emit('onCtrlSelect', nav_id);
        } else if (!e.ctrlKey && e.shiftKey) {
          let index = this.getRows.findIndex(value => value.nav_id == nav_id);
          this.onShiftSelect(index);
        } else if ((!e.ctrlKey && !e.shiftKey)) {
          this.onSelect(nav_id);
        } else {
          this.$emit('onClearSelect', nav_id);
        }
      }
    },

    include(nav_id) {
      return this.vehicleHistoryArr.includes(nav_id);
    },

    // клик без удержания каких-либо клавиш
    onSelect(index) {
      this.selectedRowNavId = index;
      this.vehicleHistoryArr = [];
      this.vehicleHistoryArr.push(index);
    },

    // клик с удержанием клавиши Shift
    onShiftSelect(index) {
      this.vehicleHistoryArr = [];
      // если уже есть выбранная(сохраненная) строка -> делаем выбранными все строки между выбранными
      if (this.selectedRowNavId || this.selectedRowNavId == 0) {
        // ищем индекс выбранной строки
        let ind = this.getRows.findIndex(value => value.nav_id == this.selectedRowNavId);
        // если индекс выбранной строки больше индекса сохраненной строки
        if (index >= ind) {
          for (let i = ind; i <= index; i++) {
            this.vehicleHistoryArr.push(this.getRows[i].nav_id);
          }
          // если индекс выбранной строки меньше индекса сохраненной строки
        } else if (ind > index) {
          for (let i = index; i <= ind; i++) {
            this.vehicleHistoryArr.push(this.vehicle_history[i].nav_id);
          }
        }
      } else {
        this.selectedRowNavId = this.vehicle_history[index].nav_id;
      }
    },

    onLeafletEvent(e) {
      console.log(e)
    },

    onFilterChanged({newValue}) {
      this.filterText = newValue
    },

    onRowSelect(row) {
      this.selectedRow = row;
    },

    onRowDblClick(row) {
      console.log('dbl', row);
    },

    onColumnContextClick(col) {
      console.log('colcontext', col);
    },

    onColumnClick(col) {
      console.log('col', col);
    },

    setMarkerOptions() {
      this.markerOptions = {
        icon: null,
        draggable: false
      }
    },
    markerEvent(event) {
      if (event.type === 'click') {
        event.targetComponent.stop.__selected = true;
      }
    },
    toggleMarker() {
      if (this.isMarker) {
        this.isMarker = false;
      } else {
        this.isMarker = true;
      }
    },
    async testMarker() {

      this.markerVisible = true;
      await wait(1000);
      this.markerLatLng[0] = this.markerLatLng[0] + 0.001
      await wait(1000);
      this.markerLatLng[1] = this.markerLatLng[1] + 0.001
      await wait(1000);
      //this.markerIcon= icons[0][0]
      await wait(1000);
      //this.markerIcon= icons[0][1]
      await wait(1000);
      //this.markerIcon= icons[0][2]
      await wait(1000);
      //this.markerIcon= icons[0][3]
      await wait(1000);
      this.markerOptions = {draggable: true}
      await wait(5000);
      this.markerVisible = false;

      // await wait (1000);
      // this.markerOptions = StopIcon[0][0]
      // await wait(1000)
      // this.markerOptions = StopIcon[0][1]
      // await wait(1000)
      // this.markerOptions = StopIcon[0][2]
      // await wait(1000)
      // this.markerOptions = StopIcon[0][3]

    },
    // тестируем круг
    async testCircle() {
      this.circleVisible = true;
      await wait(1000);
      this.circleLatLng[0] = this.circleLatLng[0] + 0.001
      await wait(1000);
      this.circleLatLng[1] = this.circleLatLng[1] + 0.001
      await wait(1000);
      this.circleRadius = this.circleRadius + 100;
      await wait(1000);
      this.circleOptions = {color: 'red'}
      await wait(1000);
      this.circleOptions = {}
      await wait(1000);
      this.circleOptions = {draggable: true}
      await wait(5000);
      this.circleVisible = false;
    },

    // тестируем линию
    async testPolyline() {
      this.polylineVisible = true;
      await wait(1000);
      this.polylineLatLngs.push([55.725937300000005, 37.85464410000001]);
      // this.circleLat= this.circleLat + 0.001
      await wait(1000);
      // this.circleLng= this.circleLng + 0.001
      // await wait (1000);
      // this.circleRadius= this.circleRadius + 100;
      // await wait (1000);
      this.polylineOptions = {color: 'red'}
      await wait(1000);
      this.polylineOptions = {}
      await wait(1000);
      this.polylineOptions = {draggable: true}
      await wait(5000);
      this.polylineVisible = false;
    },

    async testPolygon() {
      this.polygonVisible = true;
      await wait(1000);
      this.polygonLatLngs.push([55.725937300000005, 37.85464410000001]);
      // this.circleLat= this.circleLat + 0.001
      await wait(1000);
      // this.circleLng= this.circleLng + 0.001
      // await wait (1000);
      // this.circleRadius= this.circleRadius + 100;
      // await wait (1000);
      this.polygonOptions = {color: 'red'}
      await wait(1000);
      this.polygonOptions = {}
      await wait(1000);
      this.polygonOptions = {draggable: true}
      await wait(5000);
      this.polygonVisible = false;
    },

    handleFileUpload() {
      console.log('Новый файл', this.$refs.file.files);
      this.file = this.$refs.file.files[0];
    },

    submitFile() {
      console.log('Отправка файла')

      postFileRequest('http://192.168.20.179:7001/multipass/stopDocFiles/81', [this.file], store.getters.getTokenId)
    }
  },
}
</script>

<style scoped>

.cont {
  background-color: magenta;
  width: 10rem;
  height: 10rem;
  padding: 5rem;
  resize: both;
  overflow: auto;
}

</style>
