<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Группа транспортных средств</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="transportGroupId">Код:</label>
              <input-integer
                id="transportGroupId"
                class="form-control w-25"
                v-model="transportGroupId"
                readonly
              />
            </div>
            <div class="form-group">
              <label for="transportGroupName">Наименование:</label><req-field />
              <input-string
                  class="form-control"
                  id="transportGroupName"
                  v-model="transportGroupName"
                  ref="transportGroupName"
                  :readonly="op === 'show'"
                  required
                  error="Введите наименование"
              />
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  mixins: [ValidateFormMixin],
  props: ['item', 'op'],
    emits: ['onOK', 'onCancel'],
    data() {
      return {
        transportGroupId: this.item.transport_group_id,
        transportGroupName: this.item.transport_group_name,
      };
    },
    methods: {
      onSubmit() {
          if (this.validateForm(this.$refs)) {
            this.$emit('onOK', {
              transport_group_id: this.op === 'add' ? undefined : this.transportGroupId,
              transport_group_name: this.transportGroupName
            })
          }
      },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>