<template>
    <toast>
        <toast-header :class=classValue>  
            <strong class="mr-auto">{{ caption }}</strong>
            <button-close class="text-white" @click="$emit('onClose')"></button-close>
        </toast-header>
        <toast-body>
            <slot></slot>
        </toast-body>  
    </toast>
</template>

<script>
export default {
    // тип сообщения: 'error' - ошибка, 'ok' - успешно, 'info' - информация (по умолчанию)
    props: ['caption', 'type'],
    emits: ['onClose'],
    computed: {
        classValue() {
            if (this.type === 'error') {
                return "bg-danger text-white";
            }
            else
            if (this.type === 'ok') {
                return "bg-success text-white";
            }
            else {
                return "bg-primary text-white";
            }
        },     
    }    
}
</script>

<style scoped>
    .toast {
        width: 300px;
    }
</style>
