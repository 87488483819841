import {DoClearCache, DoDelete, DoGet, DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ СВЯЗЕЙ ПОЛЬЗОВАТЕЛЯ С РОЛЯМИ

export default {
    state() {
        return {
            // список связей
            userRoleLinks: [],
            // время обновления кэша new Date().getTime()
            userRoleLinksCache: null,
            // время жизни кэша в мс (null - бесконечно)
            userRoleLinksCacheTimeout: null
        }
    },
    getters: {
        // вернуть список связей
        getUserRoleLinks(state) {
            return state.userRoleLinks;
        },
    },
    mutations: {
        // установить новый список связей
        setUserRoleLinks(state, payload) {
            state.userRoleLinks = payload;
            state.userRoleLinksCache = new Date().getTime()
        },
        // связать объекты
        addUserToRole(state, payload) {
            state.userRoleLinks.push(payload)
        },
        // удалить связь объектов
        delUserFromRole(state, payload) {
            const index = state.userRoleLinks.findIndex(
                link => link.role_id == payload.role_id && link.uid == payload.uid
            );
            state.userRoleLinks.splice(index, 1);
        },
    },
    actions: {
        // запрос списка связей
        doFetchUserRoleLinks: (context, payload) => {
            return DoGet(
                '/auth/userRoleLinks',
                'auth/setUserRoleLinks',
                [],
                'Запрос связей пользователей с ролями',
                false,
                payload && payload.force,
                context.state.userRoleLinksCache,
                context.state.userRoleLinksCacheTimeout
            )
        },

        // очищает кэш
        doClearUserRoleLinks: (context, payload) => {
            return DoClearCache(
                'auth/setUserRoleLinks',
                [],
                payload && payload.force,
                context.state.userRoleLinksCache,
                context.state.userRoleLinksCacheTimeout
            )
        },

        // связывание объектов
        doAddUserToRole:(context, payload) => {
            return DoPost(
                '/auth/userRoleLinks',
                payload,
                'auth/addUserToRole',
                'Добавление роли пользователю'
            )
        },

        // удаление связи объектов
        doDelUserFromRole: (context, payload) => {
            return DoDelete(
                '/auth/userRoleLinks/'+payload.role_id+'/'+payload.uid,
                'auth/delUserFromRole',
                'Удаление роли у пользователя'
            )
        },
    }
}