<template>
  <!--  <input-select ref="select" v-model="value" caption="Выберите водителя..." :itemList="items"></input-select>-->
  <lookup-input-select ref="select" v-model="value" caption="" :itemList="items"
                       error=""></lookup-input-select>

</template>

<script>
export default {
  props: ['model-value', 'depot_id', 'isThisRowSelected'],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      let arr = [];

      if (this.$store.getters['trn/getOrderDriversByDepotId'][this.depot_id]) {
        // если эта строка в режиме редактирования и выбранная, то заполняем выпадающий список
        if (this.isThisRowSelected) {
          this.$store.getters['trn/getOrderDriversByDepotId'][this.depot_id].map(driver => {
            arr.push({
              id: driver.driver_id,
              title: driver.last_name + ' ' + driver.first_name.substring(0, 1) + '.' + driver.middle_name.substring(0, 1) + '.',
              value: driver.last_name + ' ' + driver.first_name.substring(0, 1) + '.' + driver.middle_name.substring(0, 1) + '.',
            });
          });
        } else {
          let driver = this.$store.getters['trn/getOrderDriversByDepotId'][this.depot_id].find(driver => this.modelValue == driver.driver_id);
          if (driver) {
            arr.push({
              id: driver.driver_id,
              title: driver.last_name + ' ' + driver.first_name.substring(0, 1) + '.' + driver.middle_name.substring(0, 1) + '.',
              value: driver.last_name + ' ' + driver.first_name.substring(0, 1) + '.' + driver.middle_name.substring(0, 1) + '.',
            });
          }
        }
      }
      return arr;
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
