import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ПРИЧИН НЕЗАЧЕТА РЕЙСА

export default {
    state() {
        return {
            // список объектов
            markReasons: [],
            // время обновления кэша new Date().getTime()
            markReasonsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            markReasonsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getMarkReasons(state) {
            return state.markReasons;
        },
    },
    mutations: {
        // установить новый список объектов
        setMarkReasons(state, payload) {
            state.markReasons = payload;
            state.markReasonsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchMarkReasons: (context, payload) => {
            return DoGet(
                '/kvr/markReasons',
                'kvr/setMarkReasons',
                [],
                'Запрос списка причин незачета рейса',
                false,
                payload && payload.force,
                context.state.markReasonsCache,
                context.state.markReasonsCacheTimeout
            )
        },

        // запрос списка объектов
        doFetchMarkReason: (context, payload) => {
            return DoGet(
                '/kvr/markReasons?mark_reason_id=' + payload.mark_reason_id,
                'kvr/setMarkReasons',
                [],
                'Запрос причины незачета рейса',
                false,
                payload && payload.force,
                context.state.markReasonsCache,
                context.state.markReasonsCacheTimeout
            )
        },

        // очищает кэш
        doClearMarkReasons: (context, payload) => {
            return DoClearCache(
                'kvr/setMarkReasons',
                [],
                payload && payload.force,
                context.state.markReasonsCache,
                context.state.markReasonsCacheTimeout
            )
        },
    }
}