<template>
  <edit-table-panel :columnList="columns" :rowList="rowList"
                    name="tbl-asmpp-fact-trip-card-exts-aggregated-network"></edit-table-panel>
</template>

<script>

import {getRoute, getStop} from "@/store/MultipassHelpers";

export default {
  props: ['isLoading', 'rowArr', 'tripTypes', 'aggregationLevel'],
  emits: ['setLoading'],
  data() {
    return {
      columnList: [
        {
          field: "passenger_in",
          caption: "Вход",
          sortType: 'string',
          isFilterable: false,
        },
        {
          field: "passenger_out",
          caption: "Выход",
          sortType: 'string',
          isFilterable: false,
        },
        {
          field: "passenger_inside_avg",
          caption: "Наполнение",
          sortType: 'number',
          isFilterable: false,
        },
        {
          field: "fact_trip_count",
          caption: "Количество рейсов",
          sortType: 'number',
          isFilterable: false,
        },
      ]
    }
  },
  computed: {

    columns() {
      let columnsArr = [];
      if (this.aggregationLevel == 'route') {
        columnsArr.push(
            {
              field: "stop_id",
              caption: "Код остановки",
              sortType: 'number',
              isFilterable: false,
            },
            {
              field: "__stop",
              caption: "Остановка",
              sortType: 'string',
              isFilterable: true,
              dataAlign: 'left',
              headerAlign: 'left'
            },
            {
              field: "__route",
              caption: "Маршрут",
              sortType: 'routeNum',
              isFilterable: false,
              dataAlign: 'left',
              headerAlign: 'left',
            },

        )
      }
      if (this.aggregationLevel == 'trip_type') {
        columnsArr.push(
            {
              field: "__route",
              caption: "Маршрут",
              sortType: 'routeNum',
              isFilterable: false,
              dataAlign: 'left',
              headerAlign: 'left',
            },
            {
              field: "tripTypeSign",
              caption: "Тип рейса",
              sortType: 'string',
              isFilterable: false,
              dataAlign: 'left',
              headerAlign: 'left',
            },
            {
              field: "__stop",
              caption: "Остановка",
              sortType: 'string',
              isFilterable: true,
              dataAlign: 'left',
              headerAlign: 'left'
            })
      }
      if (this.aggregationLevel == 'stop') {
        columnsArr.push(
            {
              field: "__stop",
              caption: "Остановка",
              sortType: 'string',
              isFilterable: true,
              dataAlign: 'left',
              headerAlign: 'left'
            },
            {
              field: "stop_id",
              caption: "Код остановки",
              sortType: 'number',
              isFilterable: false,
            },
          )
      }
      this.columnList.map(value => {
        columnsArr.push(value)
      })
      return columnsArr;
    },

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      let rows = this.rowArr;
      rows.map(row => {
        // row.__id = row.trip_id;
        let route = getRoute(row.route_id);
        if (route) {
          row.__route = route.route_num;
        }
        let stop = getStop(row.stop_id)
        if (stop) {
          row.__stop = stop.long_name;
        }
        return row;
      });
      return rows;
    },
  }
};
</script>
