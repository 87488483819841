<template>
  <div class="btn-group btn-group-sm d-block">
    <button
        type="button"
        class="btn"
        :class="yesClassValue"
        :disabled="readonly"
        @click="onYesClick"
    >Да</button>
    <button
        type="button"
        class="btn"
        :class="noClassValue"
        :disabled="readonly"
        @click="onNoClick"
    >Нет</button>
  </div>
</template>

<script>
export default {
  props: {
    'model-value': {
      required: true
    },
    'readonly': {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value'],
  computed: {
    yesClassValue() {
      return this.modelValue ? 'btn-success' : 'btn-secondary'
    },
    noClassValue() {
      return !this.modelValue ? 'btn-danger' : 'btn-secondary'
    }
  },
  methods: {
    // вызывается при щелчке на Yes
    onYesClick() {
      if (!this.modelValue) {
        this.$emit('update:model-value', true);
      }
    },
    // вызывается при щелчке на No
    onNoClick() {
      if (this.modelValue) {
        this.$emit('update:model-value', false);
      }
    },
    // вызывается для проверки формы
    validate() {
      return true
    },
  },
  created() {
    this.$watch(() => this.modelValue, ()=> {
      if (this.modelValue !== true) {
        this.$emit('update:model-value', false);
      }
    }, {immediate: true});
  }
}
</script>
