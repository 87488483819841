import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ТИПОВ ОСТАНОВОЧНЫХ ПАВИЛЬОНОВ

export default {
    state() {
        return {
            // список объектов
            stopPavilionTypes: [],
            // время обновления кэша new Date().getTime()
            stopPavilionTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            stopPavilionTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getStopPavilionTypes(state) {
            return state.stopPavilionTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setStopPavilionTypes(state, payload) {
            state.stopPavilionTypes = payload;
            state.stopPavilionTypesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchStopPavilionTypes: (context, payload) => {
            return DoGet(
                '/multipass/stopPavilionTypes',
                'multipass/setStopPavilionTypes',
                [],
                'Запрос типов остановочных павильонов',
                false,
                payload && payload.force,
                context.state.stopPavilionTypesCache,
                context.state.stopPavilionTypesCacheTimeout
            )
        },

        // очищает кэш
        doClearStopPavilionTypes: (context, payload) => {
            return DoClearCache(
                'multipass/stopPavilionTypes',
                [],
                payload && payload.force,
                context.state.stopPavilionTypesCache,
                context.state.stopPavilionTypesCacheTimeout
            )
        },
    }
}