<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" :rowsPerPage="100000" name="tbl-kvr-order-depots"></edit-table-panel>
</template>

<script>
import {getLegalForm, getOrganization} from "@/store/RefHelpers";

export default {
  props: ['isLoading', 'chosenRows', 'depots', 'columnList'],
  data() {
    return {};
  },
  computed: {

    // строки таблицы
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      // получаем все предприятия
      let allDepots = this.depots;
      allDepots.map(depot => {
        // если парки только получены или взведен тригер снятия всех галочек
        if ((depot.__isSelected == null && depot.__isSelected == undefined) || this.chosenRows == 'clearAll') {
          // снимаем все галочки слева
          depot.__isSelected = false;
        }
      });
      return allDepots.map(depot => {
        const legalForm = getLegalForm(depot.legal_form_id);
        const org = getOrganization(depot.org_id);
        depot.__id = depot.depot_id;
        depot.__legalFormName = legalForm && legalForm.long_name || '';
        depot.__orgName = org && org.long_name || '';
        return depot;
      });

    },
  },
};
</script>
