<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable modal-xl">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>{{caption}}</modal-title>
          <button-close class="text-white" @click="$emit('onClose')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <!-- Таблица  -->
            <div class="table-container">
              <edit-table-panel :headerVisible="false" :columnList="columnList" :rowsPerPage="5000" :rowList="rows" name="tbl-rmt-errors"></edit-table-panel>
            </div>
          </form>
        </modal-body>
        <modal-footer>
          <a v-if="isContinueButtonActive" style="margin-right: 23px">При выполнении команды получены предупреждения, вы уверены, что хотите выполнить команду ?</a>
          <button v-if="isContinueButtonActive" @click="$emit('onContinue')" class="btn btn-primary">Продолжить</button>
          <button @click="$emit('onClose')" class="btn btn-primary">Закрыть</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

export default {
  props: ['rowList', 'caption'],
  emits: ['onClose', 'onContinue'],
  data() {
    return {
      // активна ли кнопка повторного запроса с параметром force == true
      isContinueButtonActive: true,
      // колонки
      columnList: [
        {
          field: 'error_status',
          caption: '',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          isSortable: false,
          displayFn(row) {
            if (row.error_status == 'ALARM') {
              return '<i class="fas fa-exclamation-circle" style="color: #dc3545">'
            } else if (row.error_status == 'WARN') {
              return '<i class="fas fa-exclamation" style="color: #ffc107">'
            } else if (row.error_status == 'INFO' || row.error_status == 'OK') {
              return '<i class="fas fa-info-circle" style="color: #0f9600"></i>'
            }
            return ''
          },
        },
          {
          field: "error_message",
          caption: "Ошибка",
          sortType: 'string',
          isFilterable: false,
          isSortable: false,
          noWrapEnable: true,
        },

      ],
    };
  },
  computed:{

    rows(){
      let rowArr = [];
      // задаем стиль строк для прешедших ошибок и предупреждений
      this.rowList.map(value => {
        if (value.error_status == 'ALARM'){
          value.__rowStyle = {
            // backgroundColor: '#ff6565'
            // color: '#dc3545',
            // fontWeight: 'bold'
            // border: "2px solid #dc3545",
          }
          this.isContinueButtonActive = false;
        } else if (value.error_status == 'WARN') {
          value.__rowStyle = {
            // backgroundColor: '#ff9263'
            // color: '#ffc107',
            // fontWeight: 'bold'
          }
        }
        value['error_status__cellStyle'] = {
          verticalAlign: 'middle !important',
        };
        rowArr.push(value);
      })
      // если не пришел хоть один ALARM, то снизу должна быть только одна кнопка "Закрыть"
      // т.к. дальнейшее действие невозможно
      return rowArr;
    }

  }


};
</script>

<style scoped>
form label {
  font-weight: bold;
}

.table-container{
  height: 400px;
}
</style>