import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ТРАНСПОРТА

export default {
    state() {
        return {
            // список объектов
            transport: [],
            // время обновления кэша new Date().getTime()
            transportCache: null,
            // время жизни кэша в мс (null - бесконечно)
            transportCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getTransport(state) {
            return state.transport;
        },
    },
    mutations: {
        // установить новый список объектов
        setTransport(state, payload) {
            state.transport = payload;
            state.transportCache = new Date().getTime()
        },
        // добавить объект
        addTransport(state, payload) {
            state.transport.push(payload)
        },
        // изменить объект
        editTransport(state, payload) {
            const index = state.transport.findIndex(transport => transport.transport_id == payload.transport_id);
            if (index >= 0) {
                state.transport[index] = payload;
            }
        },
        // удалить объект
        delTransport(state, payload) {
            const index = state.transport.findIndex(transport => transport.transport_id == payload.transport_id);
            state.transport.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchTransport: (context, payload) => {
            return DoGet(
                '/ref/transport',
                'ref/setTransport',
                [],
                'Запрос списка транспортных средств',
                false,
                payload && payload.force,
                context.state.transportCache,
                context.state.transportCacheTimeout
            )
        },

        // очищает кэш
        doClearTransport: (context, payload) => {

            return DoClearCache(
                'ref/setTransport',
                [],
                payload && payload.force,
                context.state.transportCache,
                context.state.transportCacheTimeout
            )
        },

        // добавление объекта
        doAddTransport: (context, payload) => {
            return DoPost(
                '/ref/transport',
                payload,
                'ref/addTransport',
                'Добавление транспортного средства'
            )
        },

        // изменение объекта
        doEditTransport: (context, payload) => {
            return DoPut(
                '/ref/transport/'+payload.transport_id+'/'+payload.date_index_begin,
                payload,
                'ref/editTransport',
                'Изменение транспортного средства'
            )
        },

        // удаление объекта
        doDelTransport: (context, payload) => {
            return DoDelete(
                '/ref/transport/'+payload.transport_id,
                'ref/delTransport',
                'Удаление транспортного средства'
            )
        },

        // списание объекта
        doDiscardTransport: (context, payload) => {
            return DoPut(
                '/ref/discardTransport/' + payload.transport_id + '/' + payload.date_index_begin,
                null,
                'ref/editTransport',
                'Списание транспортного средства'
            )
        },

        // восстановление объекта
        doRestoreTransport: (context, payload) => {
            return DoPut(
                '/ref/restoreTransport/' + payload.transport_id,
                null,
                'ref/editTransport',
                'Восстановление транспортного средства'
            )
        }

    }
}
