<template>
  <modal-content>
    <modal-header class="bg-primary text-white overflow-auto">
      <h5 class="modal-text">Сформированные отчеты</h5>
      <button-close class="button-close text-white" @click="closeReportPanel"></button-close>
    </modal-header>
    <modal-body style="padding: 0; overflow-y: auto; height: 209px;">
      <form  v-on:submit.prevent>
        <!-- Таблица  -->
        <table class="table" style="margin-bottom: 0; ">
          <tbody>
          <tr v-for="(value, index) in rowListFixed" :key="index" >
            <td :style="getBackgroundColor(value)">
              <button
                  v-if="!value.is_temp || value.is_error"
                  class="btn btn-danger btn-sm"
                  title="Удалить отчет"
                  :style="getColor(value)"
                  @click="onDelete(value)"
              >
                <i class="fas fa-trash"></i>
              </button>
                <div v-else class="spinner-border text-primary" style="height: 29px; width: 29px;"></div>
            </td>
            <td :style="'text-align: center;' + getBackgroundColor(value)">
              {{ getTimeForTable(value) }}
            </td>
            <td :style="'white-space: normal !important; height: 41.5px; ' + getBackgroundColor(value)">
              {{ value.title }}
            </td>
            <td :style="'white-space: normal !important; ' + getBackgroundColor(value)">
              <input-select :style="getBackgroundColor(value)" v-if="!value.is_temp" :itemList="value.formats" v-model="value.format" @change="resetButtonStyle(value)"></input-select>
            </td>
            <td :style="getBackgroundColor(value)"><button v-if="!value.is_temp" :class="value.show_class == '' || !value.show_class ? 'btn btn-outline-dark btn-sm': value.show_class" title="Просмотр" @click="showItem(value)" :disabled="isShowButtonDisabled(value)"><i class="fas fa-eye"></i></button></td>
            <td :style="getBackgroundColor(value)"><button v-if="!value.is_temp" :class="value.class == '' || !value.class ? 'btn btn-outline-dark btn-sm': value.class" title="Загрузить" @click="downloadItem(value)"><i class="fas fa-download"></i></button></td>
          </tr>
          </tbody>
        </table>

      </form>
    </modal-body>
  </modal-content>
</template>

<script>

import {getReport} from "@/store/SystemHelpers";
import ButtonClose from "@/components/ui/bootstrap/button/ButtonClose";
import {getTimeFormat} from "@/lib";
import InputSelect from "@/components/ui/custom/InputSelect";

export default {
  components: {InputSelect, ButtonClose, },
  props: ['rowList'],
  emits: ['onClose', 'setMenu'],
  data() {
    return {
      selectedRow: null,
      rowListClasses: [],
      // задаем цвет серый цвет в отчетах без данных (no_data == true)
      color: '#dedcdc',
      // цвет ожидания отчета
      temp_color: '#b2b2b2'
    }
  },
  computed: {

    rowListFixed() {
      // для корректной работы комбобокса в таблице положим форматы в соответствующую структуру
      let formats = [];
      this.rowList.forEach(row => {
        if(row.formats) {
          row.formats.forEach(value => {
            if(!value.id) {
              formats.push({
                id: value,
                title: value
              })
              row.formats = formats;
            }
          })
          formats = [];
        } else {
          row.formats = formats;
        }
      })
      let rows = []
      this.rowList.forEach(value => {
        // value['__itemList'] = value.formats;
        let title = (getReport(value.report_id).report_code == '' ? '' : (getReport(value.report_id).report_code + ' - ')) +  getReport(value.report_id).short_name
        // получение отчета из стора и присваивание по report_id названия
        value['title'] = title;
        // value['user_time__cellStyle'] = {
        //   verticalAlign: 'middle !important',
        // };
        // value['title__cellStyle'] = {
        //   verticalAlign: 'middle !important',
        // };
        // value['format__cellStyle'] = {
        //   verticalAlign: 'middle !important',
        // };

        // если отчет на сервере пустой красим строку в серый цвет
        value['user_time'] = new Date(value.user_time);
        rows.unshift(value);
      })

      return rows;
    }
  },
  methods: {

    // сбрасываем стили кнопок
    resetButtonStyle(value){
      value.show_class = 'btn btn-outline-dark btn-sm';
      value.class = 'btn btn-outline-dark btn-sm';
    },

    // получаем активна ли кнопка отобразить отчет
    isShowButtonDisabled(value){

      if(value.format != undefined && (['html', 'pdf', 'json'].includes(value.format))){
        return false;
      }
      return true;
    },

    // получаем цвет бекграунда строки
    getColor(value){
      if(value.no_data) {
        return 'color: ' + this.color;
      } else if (value.is_error) {
        return 'color: ' + this.temp_color;
      } else {
        return '';
      }
    },

    // получаем цвет бекграунда строки
    getBackgroundColor(value){
      if (value.is_temp) {
        return 'background-color: ' + this.temp_color;
      } else if(value.no_data) {
        return 'background-color:' + this.color;
      } else {
        return '';
      }
    },

    getTimeForTable(value){
      return getTimeFormat( value.user_time.getHours()*3600 + value.user_time.getMinutes()*60);
    },

    // закрытие панели отчетов
    closeReportPanel() {
      this.$store.commit('hideReports');
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    onDelete(item) {
      this.$store.dispatch('reporter/doDelReport', item)
      this.$emit('setMenu')
    },

    // открываем файл для просмотра
    async showItem(item) {
      this.isLoading = true;
      item.show_class = 'btn btn-warning btn-sm';
      try {
        let result = await this.$store.dispatch('reporter/doFetchReportShowing', {
          uuid: item.uuid,
          fmt: item.format,
          file_name: item.title
        });

        // в зависимости от ответа перекрашиваем кнопку загрузки
        if (result == false) {
          item.show_class = 'btn btn-danger btn-sm';
        } else {
          item.show_class = 'btn btn-success btn-sm';
        }

      } finally {
        this.isLoading = false;
      }
    },

    // загружаем объект
    async downloadItem(item) {
      this.isLoading = true;
      item.class = 'btn btn-warning btn-sm';
      try {
        let result = await this.$store.dispatch('reporter/doFetchReport', {
          uuid: item.uuid,
          fmt: item.format,
          file_name: item.title
        });

        // в зависимости от ответа перекрашиваем кнопку загрузки
        if(result == false) {
          item.class = 'btn btn-danger btn-sm';
        } else {
          item.class = 'btn btn-success btn-sm';
        }

      } finally {
        this.isLoading = false;
      }
    },

  }
};
</script>

<style scoped>

td {
  padding: 5px 5px 5px 5px;
  vertical-align: middle;
}

form label {
  font-weight: bold;
}

.bg-primary {
  height: 40px;
  padding: 0;
}

.modal-text {
  margin-top: 7px;
  margin-left: 10px;
}

.button-close {
  margin-top: 5px;
  margin-right: 10px;
  padding: 0;
}

.table-container {
  height: 178px;
  vertical-align: middle;
}
/* Настройки скролбара*/
/*::-webkit-scrollbar {*/
/*  !*display: block;*!*/
/*  width: 10px;*/
/*}*/
</style>