import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ВИДОВ МАРШРУТОВ

export default {
    state() {
        return {
            // список объектов
            routeKinds: [],
            // время обновления кэша new Date().getTime()
            routeKindsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            routeKindsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRouteKinds(state) {
            return state.routeKinds;
        },
    },
    mutations: {
        // установить новый список объектов
        setRouteKinds(state, payload) {
            state.routeKinds = payload;
            state.routeKindsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос список объектов
        doFetchRouteKinds: (context, payload) => {

            return DoGet(
                '/multipass/routeKinds',
                'multipass/setRouteKinds',
                [],
                'Запрос видов маршрутов',
                false,
                payload && payload.force,
                context.state.routeKindsCache,
                context.state.routeKindsCacheTimeout
            )
        },

        // очищает кэш
        doClearRouteKinds: (context, payload) => {

            return DoClearCache(
                'multipass/setRouteKinds',
                [],
                payload && payload.force,
                context.state.routeKindsCache,
                context.state.routeKindsCacheTimeout
            )
        },
    }
}