<template>
  <!-- Карта-->
  <leaflet-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
  >

    <!-- Элементы управления-->
    <leaflet-control-group>
      <!-- Базовые слои-->
      <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
    </leaflet-control-group>

<!--    <leaflet-marker-cluster-group visible>-->

      <vehicle-marker
          v-for="(vehicle) in vehicles"
          :vehicle="vehicle"
          :key="vehicle.transport_id"
          :titleType="tab > 0 ? 'timenav' : 'auto'"
          color="2"
          events="dblclick"
          @onLeafletEvent="onLeafletEvent($event, vehicle)"
      ></vehicle-marker>


<!--    </leaflet-marker-cluster-group>-->

  </leaflet-map>
</template>

<script>

export default {
  // список ТС
  props: [
    'vehicles',
    'tab'
  ],
  // список событий
  emits: ['onVehicleInfo'],
  computed: {

    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom']
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter']
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },
  },

  data() {
    return {
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // границы карты
      minLat: 0,
      maxLat: 0,
      minLong: 0,
      maxLong: 0,
      // текущий масштаб
      curZoom: 0,
      // нет контекстного меню остановки
      noContextMenu: {
        contextmenu: false,
      },
    }
  },

  methods: {

    // срабатывает при щелчке на ТС
    onLeafletEvent(event, vehicle) {
      // вызываем событие при двойном щелчке на ТС
      if (event.type === 'dblclick') {
        this.$emit('onVehicleInfo', vehicle)
      }
    },

  },
}
</script>
