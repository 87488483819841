<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Остановочный пункт</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <nav-menu class="nav-tabs" mode="single">
            <nav-item>
              <nav-link target-ref="pane1" target-method="select" active>Основные</nav-link>
            </nav-item>
            <nav-item>
              <nav-link target-ref="pane2" target-method="select">Дополнительные</nav-link>
            </nav-item>
          </nav-menu>
          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>

                <div class="row">

                  <div class="form-group col">
                    <label for="stopId">Код:</label>
                    <input-integer
                        id="stopId"
                        type="number"
                        v-model="stopId"
                        class="form-control"
                        readonly
                    />
                  </div>

                  <div class="form-group col">
                    <label for="regNum">Рег.номер:</label>
                    <input-string
                        id="regNum"
                        v-model="regNum"
                        class="form-control"
                        :readonly="op === 'show'"
                    />
                  </div>

                </div>

                <div class="form-group">
                  <label for="longName">Полное наименование:</label><req-field />
                  <input-string
                      id="longName"
                      v-model="longName"
                      class="form-control"
                      ref="longName"
                      :readonly="op === 'show'"
                      required
                      error="Введите полное наименование"
                  />
                </div>

                <div class="form-group">
                  <label for="shortName">Краткое наименование:</label><req-field />
                  <input-string
                      id="shortName"
                      v-model="shortName"
                      class="form-control"
                      ref="shortName"
                      :readonly="op === 'show'"
                      required
                      error="Введите краткое наименование"
                  />
                </div>

                <div class="form-group">
                  <label for="latitude">Широта:</label>
                  <input-float
                      id="latitude"
                      v-model="latitude"
                      class="form-control"
                      ref="latitude"
                      :readonly="op === 'show'"
                      :min-value="-90"
                      :max-value="90"
                      error="Введите широту от -90 до 90"
                  />
                </div>

                <div class="form-group">
                  <label for="longitude">Долгота:</label>
                  <input-float
                      id="longitude"
                      v-model="longitude"
                      class="form-control"
                      ref="longitude"
                      :readonly="op === 'show'"
                      :min-value="-180"
                      :max-value="180"
                      error="Введите долготу от -180 до 180"
                  />
                </div>

              </form>
            </tab-pane>
            <tab-pane ref="pane2" class="p-2">
              <form>

                <div class="form-group">
                  <label for="stopTypeId">Тип остановочного пункта:</label><req-field />
                  <stop-types-select
                      id="stopTypeId"
                      v-model="stopTypeId"
                      class="form-control"
                      ref="stopTypeId"
                      :readonly="op === 'show'"
                      required
                      error="Выберите тип остановочного пункта"
                  />
                </div>

                <div class="form-group" v-if="stopTypeId === 3">
                  <label for="depotId">Транспортное предприятие:</label><req-field />
                  <depots-select
                      id="depotId"
                      v-model="depotId"
                      class="form-control"
                      ref="depotId"
                      :readonly="op === 'show'"
                      required
                      error="Выберите предприятие"
                  />
                </div>

                <div class="form-group"  v-if="stopTypeId === 2">
                  <label for="stopstationId">Автостанция:</label><req-field />
                  <stopstations-select
                      id="stopstationId"
                      v-model="stopstationId"
                      class="form-control"
                      ref="stopstationId"
                      :readonly="op === 'show'"
                      required
                      error="Выберите автостанцию"
                  />
                </div>

                <div class="form-group">
                  <label for="areaClassifierId">Код ОКАТО (ОКТМО):</label>
                  <input-okato
                      id="areaClassifierId"
                      v-model="areaClassifierId"
                      class="form-control"
                      ref="areaClassifierId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="address">Адрес:</label>
                  <input-string
                      id="address"
                      v-model="address"
                      class="form-control"
                      ref="address"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="note">Примечание:</label>
                  <input-string
                      id="note"
                      v-model="note"
                      class="form-control"
                      ref="note"
                      :readonly="op === 'show'"
                  />
                </div>

                <input-checkbox
                    id="isEven"
                    v-model="isEven"
                    :readonly="op === 'show'"
                >На четной стороне улицы</input-checkbox>
              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputFloat from "@/components/ui/custom/InputFloat";
import InputString from "@/components/ui/custom/InputString";
import InputInteger from "@/components/ui/custom/InputInteger";
import StopTypesSelect from "@/components/multipass/stop_types/StopTypesSelect";
import DepotsSelect from "@/components/ref/depots/DepotsSelect";
import StopstationsSelect from "@/components/multipass/stopstations/StopstationsSelect";

export default {
  components: {StopstationsSelect, DepotsSelect, StopTypesSelect, InputInteger, InputString, InputFloat},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
      return {
        stopId: this.item.stop_id,
        shortName: this.item.short_name,
        longName: this.item.long_name,
        stopTypeId: this.item.stop_type_id,
        latitude: this.item.latitude,
        longitude: this.item.longitude,
        regNum: this.item.reg_num,
        note: this.item.note,
        areaClassifierId: this.item.area_classifier_id,
        address: this.item.address,
        isEven: this.item.is_even,
        stopstationId: this.item.stopstation_id,
        depotId: this.item.depot_id,
      };
  },
  methods: {
      onSubmit() {
        if (this.validateForm(this.$refs)) {
            this.$emit('onOK', {
              stop_id: this.op === 'add' ? undefined : this.stopId,
              short_name: this.shortName,
              long_name: this.longName,
              stop_type_id: this.stopTypeId,
              latitude: this.latitude,
              longitude: this.longitude,
              reg_num: this.regNum,
              note: this.note,
              area_classifier_id: this.areaClassifierId,
              address: this.address,
              is_even: this.isEven,
              stopstation_id: this.stopstationId,
              depot_id: this.depotId,
            })
        }
      },
  },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>