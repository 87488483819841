<template>
    <teleport to="body">
        <div style="position: absolute; bottom: 10px; right: 10px; z-index: 2048">
            <message-item 
                v-for="message in messages"
                :key="message.id" 
                :type="message.type"
                :caption="message.caption"
                @onClose="onClose(message.id)"
            >{{ message.message }}</message-item>
        </div>
    </teleport>
</template>

<script>
import MessageItem from './MessageItem.vue';

export default {
    components: {
        MessageItem
    },
    computed: {
        messages() {
            return this.$store.getters.getMessages;
        }
    },
    methods: {
        onClose(id) {
            this.$store.dispatch('doDelMessage', {
                id
            })
        }
    }
}
</script>
