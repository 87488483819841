<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-depots"></edit-table-panel>
</template>

<script>
import {getLegalForm, getOrganization} from "@/store/RefHelpers";

export default {
  props: ['isLoading', 'depotGroupId', 'payTransByDepot'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "depot_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__orgName",
          caption: "Организация",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__legalFormName",
          caption: "Организационно-правовая форма",
          sortType: 'string',
          show: false
        },
        {
          field: "time_zone",
          caption: "Часовой пояс (мин)",
          sortType: 'number',
          show: false
        },
        {
          field: "__pay_tran_count",
          caption: "Сумм. кол. оплат",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "__pay_tran_count_avg_by_day",
          caption: "Ср. кол. оплат за сутки",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.__pay_tran_count_avg_by_day) {
              return Number(row.__pay_tran_count_avg_by_day).toFixed(2);
            }
            return 0
          },
        },
        {
          field: "__pay_tran_count_avg_by_transport",
          caption: "Ср. кол. оплат за сутки по ТС",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.__pay_tran_count_avg_by_transport) {
              return Number(row.__pay_tran_count_avg_by_transport).toFixed(2);
            }
            return 0
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      let depots;
      const links = this.$store.getters["ref/getDepotGroupLinks"];
      if (this.depotGroupId) {
        depots = this.$store.getters["ref/getDepots"].filter(depot => {
          return links.some(link => link.depot_id == depot.depot_id && link.depot_group_id == this.depotGroupId)
        });
      } else {
        depots = this.$store.getters["ref/getDepots"];
      }

      let result = depots.map(depot => {
        const legalForm = getLegalForm(depot.legal_form_id);
        const org = getOrganization(depot.org_id);
        let pay_tran = this.payTransByDepot.find(value => value.depot_id == depot.depot_id);

        depot.__pay_tran_count = pay_tran.pay_tran_count;
        depot.__pay_tran_count_avg_by_day = pay_tran.pay_tran_count_avg_by_day ;
        depot.__pay_tran_count_avg_by_transport = pay_tran.pay_tran_count_avg_by_transport;

        depot.__id = depot.depot_id;
        depot.__legalFormName = legalForm && legalForm.long_name || '';
        depot.__orgName = org && org.long_name || '';

        return depot
      });

      if (this.$router.currentRoute._value.fullPath.substring(0, 6) === '/asmpp') {
        let selectedDepotId = this.$store.getters['asmpp/getAsopSelectedDepotId'];
        if (selectedDepotId) {
          let selectedRow = result.find(depot => depot.depot_id == selectedDepotId);
          this.$emit('onRowSelect', selectedRow)
        }
      }

      return result;
    },
  },
};
</script>
