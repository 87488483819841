import { DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ШАГОВ РАСПОРЯДКА ГРАФИКА (ВЫХОДА) ДВИЖЕНИЯ

export default {
    actions: {
        // запрос списка объектов
        doFetchScheduleGraphSteps: (context, payload) => {
            return DoGet(
                '/rmt/scheduleGraphSteps?schedule_graph_id=' + payload.schedule_graph_id,
                null,
                [],
                'Запрос шагов распорядка',
                false,
                true
            )
        },

        // добавление объекта
        doAddScheduleGraphStep: (context, payload) => {
            return DoPost(
                '/rmt/scheduleGraphSteps',
                payload,
                null,
                'Добавление (вставка) шага распорядка',
                false
            )
        },

        // изменение объекта
        doEditScheduleGraphStep: (context, payload) => {
            return DoPut(
                '/rmt/scheduleGraphSteps/'+payload.schedule_graph_step_id,
                payload,
                null,
                'Изменение шага распорядка',
                false
            )
        },

        // удаление объекта
        doDelScheduleGraphStep: (context, payload) => {
            return DoDelete(
                '/rmt/scheduleGraphSteps/'+payload.schedule_graph_step_id,
                null,
                'Удаление шага распорядка',
                false
            )
        }
    }
}
