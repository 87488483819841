import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ СОСТОЯИЙ ТРАНСПОРТА

export default {
    actions: {
        // запрос списка объектов
        doFetchTransportStates: (context, payload) => {
            return DoGet(
                '/ref/transportStates?transport_id=' + payload.transport_id,
                null,
                [],
                'Запрос состояний по транспортному средству',
                false,
                true,
            )
        },
    }
}
