<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/admin">Администрирование</router-link></span>
        <span> / </span>
        <span>Типы связанных сущностей</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <div class="container position-relative h-100">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">
            <crud-form
                :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
                :buttonsEnabled="crudButtonsEnabled"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            >
            </crud-form>
          </align-panel>

          <!-- Таблица  -->
          <align-panel align="all">
            <entity-types-edit-table
                :filterText="filterText"
                :isLoading="isLoading"
                :selectedRow="selectedRow"
                @onRowSelect="onRowSelect"
            ></entity-types-edit-table>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import EntityTypesEditTable from "@/components/integration/entity_types/EntityTypesEditTable";

export default {
  mixins: [PageMixin],

  components: {
    EntityTypesEditTable,

  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: false,
        edit: false,
        delete: false,
        refresh: true
      }
    },

  },
  methods: {

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('integration/doFetchEntityTypes', {force})

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

