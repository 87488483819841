<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span>Остановочный пассажиропоток</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="crudButtonsExist"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
              :filterExists="false"
          >

            <!-- Выбор даты начала периода  -->
            <template #preLeftCustomButton>
              <div class="form-group row" style="margin-bottom: 0;">
                <p style="margin-bottom: 0; margin-left: 14px; margin-right: 10px; margin-top: 6px">Период</p>
                <input-date
                    id="startDate"
                    class="form-control"
                    autocomplete="off"
                    v-model="startDate"
                    style="width: 151px; margin-right: 15px;"
                    @change="setDate"
                >
                </input-date>
              </div>
            </template>

            <!-- Выбор даты конца периода   -->
            <template #preLeftCustomButton2>
              <input-date
                  id="endDate"
                  class="form-control"
                  autocomplete="off"
                  v-model="endDate"
                  @change="setDate"
              >
              </input-date>
            </template>

            <!-- Выбор времени начала    -->
            <template #preLeftCustomButton3>
              <div class="form-group row" style="margin-bottom: 0;">
                <p style="margin-bottom: 0; margin-left: 14px; margin-right: 10px; margin-top: 6px">Период суток с</p>
                <input-time
                    id="startTime"
                    class="form-control"
                    style="max-width: 70px;"
                    autocomplete="off"
                    v-model="startTime"
                >
                </input-time>
              </div>
            </template>

            <!-- Выбор времени конца   -->
            <template #leftCustomButton>
              <div class="form-group row" style="margin-bottom: 0;">
                <p style="margin-bottom: 0; margin-left: 29px; margin-right: 10px; margin-top: 6px">по</p>
                <input-time
                    id="endTime"
                    class="form-control"
                    style="max-width: 70px; margin-right: 15px;"
                    autocomplete="off"
                    v-model="endTime"
                >
                </input-time>
              </div>
            </template>

            <template #leftCustomButton2>
              <route-groups-select class="form-control" v-model="selectedRouteGroup"
                                   id="selectedRouteGroup"></route-groups-select>
            </template>

            <template #leftCustomButton3>
              <routes-select class="form-control" v-model="selectedRoute"
                             id="selectedRoute" :itemList="routes"></routes-select>
            </template>

          </crud-form>

          <crud-form
              :buttonsExist="secondCrudButtonsExist"
              :buttonsEnabled="secondCrudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Детализация    -->
            <template #preLeftCustomButton>
              <div class="form-group row" style="margin-bottom: 0;">
                <p style="margin-bottom: 0; margin-left: 14px; margin-right: 10px; margin-top: 6px">Детализация</p>
                <input-select
                    id="aggregationLevel"
                    class="form-control"
                    v-model="aggregationLevel"
                    :item-list="aggregationLevelArr"
                    title="Детализация"
                    caption="Выберите детализацию..."
                    style="width: 220px; margin-right: 15px;"
                    @change="clearRows"
                >
                </input-select>
              </div>
            </template>


            <!-- Дни недели              -->
            <template #preLeftCustomButton2>
              <div class="form-group row" style="margin-bottom: 0;">
                <p style="margin-bottom: 0; margin-left: 14px; margin-right: 10px; margin-top: 6px">Дни недели:</p>
                <dropdown style=" margin-right: 15px;">
                  <button-toggler target-ref="collapse2" class="btn dropdown-toggle"
                                  style=" border: 1px solid #ced4da; webkit-appearance: menulist !important;">
                    {{ getWeekDayName }}
                  </button-toggler>
                  <dropdown-menu ref="collapse2" @click.capture.stop>
                    <form class="px-4 py-3"
                          style="padding-top: 0 !important; padding-bottom: 0 !important;padding-right: 0 !important;">
                      <div class="form-group" style="margin-bottom: 0">
                        <input-checkbox
                            id="alLWeek"
                            :model-value="isAllWeekSelected"
                            @change="allWeekClick"
                        >Вся неделя
                        </input-checkbox>
                        <input-checkbox
                            v-for="weekDay in weekday_num"
                            :key="String(weekDay.id)"
                            :id="String(weekDay.id)"
                            v-model="weekDay.isSelected"
                        ><label style="margin-bottom: 0">{{ weekDay.title }}</label>
                        </input-checkbox>
                      </div>
                    </form>
                  </dropdown-menu>
                </dropdown>
              </div>
            </template>

            <template #preLeftCustomButton3>
              <button @click="getStations" type="button" :disabled="isButtonDisabled"
                      class="btn dropdown-toggle btn-primary">
                Загрузка
              </button>
            </template>

          </crud-form>

        </align-panel>
        <align-panel align="all">
          <align-container>
            <!-- Таблица  -->
            <align-panel align="all" :gap="3">
              <fact-trip-card-exts-aggregated-network-edit-table
                  :filterText="filterText"
                  :isLoading="isLoading"
                  :selectedRow="selectedRow"
                  :rowArr="rows"
                  :tripTypes="tripTypes"
                  :aggregationLevel="aggregationLevel"
                  @onRowSelect="onRowSelect"
                  @setLoading="setLoading"
              ></fact-trip-card-exts-aggregated-network-edit-table>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <stop-dialog
      v-if="isDialogVisible"
      :item=dialogRow
      :op="op"
      @onCancel="onRejectOperation"
  >
  </stop-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import StopDialog from "@/components/multipass/stops/StopDialog";
import PageMixin from "@/pages/share/PageMixin";
import InputDate from "@/components/ui/custom/InputDate";
import {dateToND, dateToString, getDateMinusMonth, getSignTitle} from "@/lib";
import InputTime from "@/components/ui/custom/InputTime";
import RouteGroupsSelect from "@/components/multipass/route_groups/RouteGroupsSelect";
import RoutesSelect from "@/components/multipass/routes/RoutesSelect";
import InputSelect from "@/components/ui/custom/InputSelect";
import InputCheckbox from "@/components/ui/custom/InputCheckbox";
import FactTripCardExtsAggregatedNetworkEditTable
  from "@/components/asmpp/fact_trip_card_exts_aggregated_network/FactTripCardExtsAggregatedNetworkEditTable";

export default {
  mixins: [PageMixin],

  components: {
    FactTripCardExtsAggregatedNetworkEditTable,
    InputCheckbox,
    InputSelect,
    RoutesSelect,
    RouteGroupsSelect,
    InputTime,
    InputDate,
    StopDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // карта
      leaflet: null,
      // отображать спиннер
      isLoading: false,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // данные, которые отображаются в диалоговом окне
      dialogRow: {},
      // выбранная в комбобоксе группа маршрутов
      selectedRouteGroup: null,
      // выбранный в комбобоксе маршрут
      selectedRoute: null,
      // параметр запроса: дата начала периода
      startDate: dateToString(getDateMinusMonth()),
      // параметр запроса: дата конца периода
      endDate: dateToString(),
      // параметр запроса: время начала
      startTime: 10800,
      // параметр запроса: время конца
      endTime: 97140,
      // варианты уровней диетализации
      aggregationLevelArr: [
        {
          id: 'trip_type',
          title: 'до типа рейса'
        },
        {
          id: 'route',
          title: 'до маршрута'
        },
        {
          id: 'stop',
          title: 'до остановки'
        }
      ],
      // дни недели
      weekday_num: [
        {
          id: 1,
          title: "Пн.",
          isSelected: true
        },
        {
          id: 2,
          title: "Вт.",
          isSelected: true
        },
        {
          id: 3,
          title: "Ср.",
          isSelected: true
        },
        {
          id: 4,
          title: "Чт.",
          isSelected: true
        },
        {
          id: 5,
          title: "Пт.",
          isSelected: true
        },
        {
          id: 6,
          title: "Сб.",
          isSelected: true
        },
        {
          id: 7,
          title: "Вс.",
          isSelected: true
        },
      ],
      //выбранные дни недели
      selectedWeekDay: false,
      // уровень детализации
      aggregationLevel: 'route',
      // тип рейса
      tripTypes: [],
      // список рейсов
      rows: [],

      show: false
    }
  },

  computed: {

    // получаем выделенны ли все дни в неделе
    isAllWeekSelected() {
      if (this.getSelectedWeekDays.length == this.weekday_num.length) {
        return true;
      } else {
        return false;
      }
    },

    // вазвращаем string со всеми выделенными днями недели
    getWeekDayName() {
      let weekdayName = '';
      // если выделенны все дни недели возвращаем соответствующее значение
      if (this.isAllWeekSelected) {
        return 'Вся неделя'
      } else {
        // в цикле формируем String со всеми выделенными днями недели
        for (let i = 0; i < this.getSelectedWeekDays.length; i++) {
          weekdayName = weekdayName + this.weekday_num[this.getSelectedWeekDays[i] - 1].title
          if (i != this.getSelectedWeekDays.length - 1) {
            weekdayName = weekdayName + ", "
          }
        }
      }
      return weekdayName;
    },

    // получение выбранных дней недели в выпадающем списке
    getSelectedWeekDays() {
      let selectedWeekDays = [];
      for (let i = 0; i < this.weekday_num.length; i++) {
        if (this.weekday_num[i].isSelected == true) {
          selectedWeekDays.push(this.weekday_num[i].id);
        }
      }
      return selectedWeekDays;
    },

    // включение crud-кнопок
    crudButtonsExist() {
      return {
        add: false,
        edit: false,
        delete: false,
        save: false,
        cancel: false,
        refresh: false,
      }
    },

    // включение crud-кнопок
    secondCrudButtonsExist() {
      return {
        add: false,
        edit: false,
        delete: false,
        save: false,
        cancel: false,
        refresh: true,
      }
    },

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // состояние crud-кнопок
    secondCrudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    routes() {
      let routesArr;
      const links = this.$store.getters["multipass/getRouteGroupLinks"];
      if (this.selectedRouteGroup) {
        routesArr = this.$store.getters["multipass/getRoutes"].filter(route => {
          if (links.some(link => link.route_id == route.route_id && link.route_group_id == this.selectedRouteGroup)) {
            return route;
          }
        });
      } else {
        routesArr = this.$store.getters["multipass/getRoutes"]
      }
      routesArr = routesArr.map(route => {
        return {
          id: route.route_id,
          title: route.route_num + ' - ' + route.route_title
        }
      })

      return routesArr;
    },

    isButtonDisabled() {
      if (this.aggregationLevel != null && (this.selectedRoute != null || this.selectedRouteGroup != null)) {
        return false;
      } else {
        return true;
      }
    },

    // получаем маршруты для запроса
    getSelectedRoutesIds() {
      let selectedRoutesArr = [];
      if (this.selectedRoute) {
        selectedRoutesArr.push(this.selectedRoute)
      } else {
        this.routes.map(value => {
          selectedRoutesArr.push(value.id);
        })
      }
      return selectedRoutesArr;
    }
  },
  methods: {

    // сохраняем даты в стор
    setDate() {
      this.$store.commit('asmpp/setStartDate', this.startDate);
      this.$store.commit('asmpp/setEndDate', this.endDate);
    },

    clearRows() {
      this.rows = [];
    },

    // нажата кнопка Вся неделя
    allWeekClick() {
      // если выбрана вся неделя, то снимаем все галочки
      if (this.isAllWeekSelected) {
        this.weekday_num.map(value => {
          value.isSelected = false;
        })
      } else {
        // если выбрана не вся неделя, то ставим все галочки
        this.weekday_num.map(value => {
          value.isSelected = true;
        })
      }
    },

    // Нажата кнопка "загрузить"
    async getStations() {
      this.isLoading = true;
      try {
        let rows = await this.$store.dispatch('asmpp/doFetchFactTripCardExtsAggregatedNetwork', {
          route_id_list: this.getSelectedRoutesIds,
          date_index_begin: dateToND(new Date(this.startDate)),
          date_index_end: dateToND(new Date(this.endDate)),
          sec_index_begin: this.startTime,
          sec_index_end: this.endTime,
          weekday_num_list: this.getSelectedWeekDays,
          aggregation_level: this.aggregationLevel,
        });
        if (!rows) {
          return
        } else {
          this.rows = rows
          //получили все строки, заполняем
          if (this.aggregationLevel == 'trip_type') {
            for (let i = 0; i < this.rows.length; i++) {
              let tripType = this.tripTypes.find(value => value.trip_type_id == rows[i].trip_type_id);
              if (!tripType) {
                tripType = await this.$store.dispatch('multipass/doFetchTripType', {
                  trip_type_id: this.rows[i].trip_type_id,
                });
                this.tripTypes.push(tripType)
              }
              this.rows[i].tripTypeSign = getSignTitle(tripType.trip_type_sign);
            }
          }
        }
      } finally {
        this.isLoading = false;
      }
    },

    setLoading(item) {
      this.isLoading = item;
    },

    // выбрана новая остановка в таблице
    async onRowSelect(item) {
      this.selectedRow = item;
    },

    // создана карта
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // нажата конпка "Просмотр"
    onTableShow(item = this.selectedRow) {
      this.dialogRow = item;
      this.$router.replace("/asmpp/stops/show/" + item.stop_id);
    },

    // нажата кнопка "Обновить" в таблице
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
      this.getStations();
    },
    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/asmpp/stops");
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // проверяем есть ли даты в сторе, если есть, то подставляем
        let startDate = this.$store.getters['asmpp/getStartDate'];
        let endDate = this.$store.getters['asmpp/getEndDate'];
        if (startDate) {
          this.startDate = startDate;
        }
        if (endDate) {
          this.endDate = endDate;
        }

        await this.$store.dispatch('multipass/doFetchRoutes')
        await this.$store.dispatch('multipass/doFetchRouteGroupLinks')
        await this.$store.dispatch('multipass/doFetchRouteGroups')
        await this.$store.dispatch('multipass/doFetchStops')

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};

</script>

