<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Автостанция</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <nav-menu class="nav-tabs" mode="single">
            <nav-item>
              <nav-link target-ref="pane1" target-method="select" active>Основные</nav-link>
            </nav-item>
            <nav-item>
              <nav-link target-ref="pane2" target-method="select">Дополнительные</nav-link>
            </nav-item>
          </nav-menu>
          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>

                <div class="row">

                  <div class="form-group col">
                    <label for="stopstationId">Код:</label>
                    <input-integer
                        id="stopstationId"
                        type="number"
                        v-model="stopstationId"
                        class="form-control"
                        readonly
                    />
                  </div>

                  <div class="form-group col">
                    <label for="regNum">Рег.номер:</label>
                    <input-string
                        id="regNum"
                        v-model="regNum"
                        class="form-control"
                        :readonly="op === 'show'"
                    />
                  </div>

                </div>

                <div class="form-group">
                  <label for="longName">Полное наименование:</label><req-field />
                  <input-string
                      id="longName"
                      v-model="longName"
                      class="form-control"
                      ref="longName"
                      :readonly="op === 'show'"
                      required
                      error="Введите полное наименование"
                  />
                </div>

                <div class="form-group">
                  <label for="shortName">Краткое наименование:</label><req-field />
                  <input-string
                      id="shortName"
                      v-model="shortName"
                      class="form-control"
                      ref="shortName"
                      :readonly="op === 'show'"
                      required
                      error="Введите краткое наименование"
                  />
                </div>

                <div class="form-group">
                  <label for="latitude">Широта:</label>
                  <input-float
                      id="latitude"
                      v-model="latitude"
                      class="form-control"
                      ref="latitude"
                      :readonly="op === 'show'"
                      :min-value="-90"
                      :max-value="90"
                      error="Введите широту от -90 до 90"
                  />
                </div>

                <div class="form-group">
                  <label for="longitude">Долгота:</label>
                  <input-float
                      id="longitude"
                      v-model="longitude"
                      class="form-control"
                      ref="longitude"
                      :readonly="op === 'show'"
                      :min-value="-180"
                      :max-value="180"
                      error="Введите долготу от -180 до 180"
                  />
                </div>


              </form>
            </tab-pane>
            <tab-pane ref="pane2" class="p-2">
              <form>

                <div class="form-group">
                  <label for="stopstationTypeId">Тип автостанции:</label>
                  <stopstation-types-select
                      id="stopstationTypeId"
                      v-model="stopstationTypeId"
                      class="form-control"
                      ref="stopstationTypeId"
                      :readonly="op === 'show'"
                      error="Выберите тип остановочного пункта"
                  />
                </div>


                <div class="form-group">
                  <label for="areaClassifierId">Код ОКАТО (ОКТМО):</label>
                  <input-okato
                      id="areaClassifierId"
                      v-model="areaClassifierId"
                      class="form-control"
                      ref="areaClassifierId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="address">Адрес:</label>
                  <input-string
                      id="address"
                      v-model="address"
                      class="form-control"
                      ref="address"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="note">Примечание:</label>
                  <input-string
                      id="note"
                      v-model="note"
                      class="form-control"
                      ref="note"
                      :readonly="op === 'show'"
                  />
                </div>

              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputFloat from "@/components/ui/custom/InputFloat";
import InputString from "@/components/ui/custom/InputString";
import InputInteger from "@/components/ui/custom/InputInteger";
import StopstationTypesSelect from "@/components/multipass/stopstation_types/StopstationTypesSelect";


export default {
  components: {StopstationTypesSelect, InputInteger, InputString, InputFloat},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
      return {
        stopstationId: this.item.stopstation_id,
        shortName: this.item.short_name,
        longName: this.item.long_name,
        stopstationTypeId: this.item.stopstation_type_id,
        latitude: this.item.latitude,
        longitude: this.item.longitude,
        regNum: this.item.reg_num,
        note: this.item.note,
        areaClassifierId: this.item.area_classifier_id,
        address: this.item.address,
      };
  },
  methods: {
      onSubmit() {
        if (this.validateForm(this.$refs)) {
            this.$emit('onOK', {
              stopstation_id: this.op === 'add' ? undefined : this.stopstationId,
              short_name: this.shortName,
              long_name: this.longName,
              stopstation_type_id: this.stopstationTypeId,
              latitude: this.latitude,
              longitude: this.longitude,
              reg_num: this.regNum,
              note: this.note,
              area_classifier_id: this.areaClassifierId,
              address: this.address,
            })
        }
      },
  },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>