<template>
    <transition name="slide">
        <div class="left-menu overflow-auto" v-if="isLeftMenuShow" v-click-away="onAutoHide">
          <component :is="curMenu"></component>
        </div>
    </transition>
</template>

<script>
import { directive } from "vue3-click-away";

export default {
    directives: {
        ClickAway: directive
    },
    data() {
        return {
          curMenu: null
        }
    },
    computed: {
        // условие отображения левого меню
        isLeftMenuShow() {
          return this.$store.getters.getIsLeftMenuShow && this.curMenu;
        }
    },
    methods: {
        // убирать окно при щелчке в другое место (пока не будем это делать)
        onAutoHide() {
            //this.$store.commit('hideLeftMenu');
        },
        setMenu(path) {
             if (path.substring(0,4) === '/ref') {
               this.curMenu='ref-left-menu-list'
            }
            else
            if (path.substring(0,10) === '/multipass') {
              this.curMenu='multipass-left-menu-list'
            }
            else            
            if (path.substring(0,6) === '/admin') {
              this.curMenu='admin-left-menu-list'
            }
            else
            if (path.substring(0,7) === '/rmtgen') {
              this.curMenu='rmtgen-left-menu-list'
            }
            else
            if (path.substring(0,4) === '/rmt') {
              this.curMenu='rmt-left-menu-list'
            }
            else
            if (path.substring(0,4) === '/nav') {
              this.curMenu='nav-left-menu-list'
            }
            else
            if (path.substring(0,6) === '/asmpp') {
              this.curMenu='asmpp-left-menu-list'
            }
            else
            if (path.substring(0,4) === '/kvr') {
              this.curMenu='kvr-left-menu-list'
            }
            else
            if (path.substring(0,4) === '/trn') {
              this.curMenu='trn-left-menu-list'
            }
            else {
              this.curMenu = null
            }                    
        }
    },
    created() {
        // следим за изменением меню
        this.$watch(() => this.$route.path, () => {
            this.setMenu(this.$route.path);
        })
    }    
}
</script>

<style scoped>
    .left-menu {
        position: fixed;
        top: 60px;
        bottom: 5px;
        left: 5px;
        width: 250px;
        padding: 5px;
        background-color: white;
        border-radius: 3px;
        box-shadow: 0 0 10px rgba(0,0,0,0.5); /* Параметры тени */
        font-size: 90%;
    }

    .slide-enter-active,
    .slide-leave-active {
        transition: all 0.5s ease;
    }

    .slide-enter-from,
    .slide-leave-to {
        left: -250px;
    }

    .slide-enter-to,
    .slide-leave-from {
        left: 5px;        
    }
</style>