<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-multipass-routes"> </edit-table-panel>
</template>

<script>

import {getTransportType} from "@/store/RefHelpers";
import {getRouteKind, getRouteStatus, getRouteType, getTransferKind} from "@/store/MultipassHelpers";
import {dateToND, stringToDate} from "@/lib";

export default {
  props: ['isLoading', 'routeGroupId', 'isClosedRoutesHidden'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "route_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "route_num",
          caption: "Номер",
          sortType: 'routeNum',
          isFilterable: true,
          sorted: 'asc'
        },
        {
          field: "route_title",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "reg_num",
          caption: "Рег.номер",
          sortType: 'string',
          show: false
        },
        {
          field: "__transportTypeName",
          caption: "Вид транспорта",
          sortType: 'string',
        },
        {
          field: "__routeKindName",
          caption: "Вид маршрута",
          sortType: 'string',
        },
        {
          field: "__routeTypeName",
          caption: "Тип маршрута",
          sortType: 'string',
        },
        {
          field: "__transferKindName",
          caption: "Вид сообщения",
          sortType: 'string',
        },
        {
          field: "__routeStatusName",
          caption: "Статус",
          sortType: 'string',
        },
        {
          field: "open_date",
          caption: "Дата открытия",
          sortType: 'string',
        },
        {
          field: "close_date",
          caption: "Дата закрытия",
          sortType: 'string',
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          show: false
        },
        {
          field: "area_classifier_id",
          caption: "Код ОКАТО (ОКТМО)",
          sortType: 'string',
          show: false
        },
        {
          field: "is_social",
          caption: "Соц.",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_social) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      let routes;
      const links = this.$store.getters["multipass/getRouteGroupLinks"];
      if (this.routeGroupId) {
        routes = this.$store.getters["multipass/getRoutes"].filter(route => {
            route.__id = route.route_id
            return links.some(link => link.route_id == route.route_id && link.route_group_id == this.routeGroupId)
        });
      }
      else {
        routes = this.$store.getters["multipass/getRoutes"].map(route => {
          route.__id = route.route_id;
          return route;
        });
      }

      if(this.isClosedRoutesHidden){
        routes = routes.filter(route => {
          return (route.close_date == null || (dateToND(stringToDate(route.close_date)) < dateToND(stringToDate(route.open_date))))
        });
      }

      let result = routes.map(route => {

        const transportType = getTransportType(route.transport_type_id);
        const routeKind = getRouteKind(route.route_kind_id);
        const routeType = getRouteType(route.route_type_id)
        const transferKind = getTransferKind(route.transfer_kind_id);
        const routeStatus = getRouteStatus(route.route_status_id);

        route.__transportTypeName = transportType && transportType.long_name || '';
        route.__routeKindName = routeKind && routeKind.long_name || '';
        route.__routeTypeName = routeType && routeType.long_name || '';
        route.__transferKindName = transferKind && transferKind.long_name || '';
        route.__routeStatusName = routeStatus && routeStatus.long_name || '';

        return route
      });

      if (this.$router.currentRoute._value.fullPath.substring(0, 6) === '/asmpp') {
        let selectedRouteId = this.$store.getters['asmpp/getAsopSelectedRouteId'];
        if (selectedRouteId) {
          let selectedRow = result.find(route => route.route_id == selectedRouteId);
          this.$emit('onRowSelect', selectedRow)
        }
      }

      return result;
    },
  },
};
</script>
