<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Параметры "{{ eventName }}"</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="transportationKindId">Вид перевозок:</label>
              <transportation-kinds-select
                  id="transportationKindId"
                  v-model="transportationKindId"
                  class="form-control"
                  ref="transportationKindId"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="row">
              <div class="form-group col">
                <input-radio class="mb-1" id='eventDurationRadio' :value="3" v-model='eventTypeId' :readonly="op === 'show'">
                  Время в минутах
                </input-radio>
                <input-minute
                    id="eventDuration"
                    class="form-control"
                    v-model="eventDuration"
                    autocomplete="off"
                    ref="eventDuration"
                    :readonly="op === 'show' || eventTypeId !== 3"
                    :required="eventTypeId === 3"
                    error="Введите время в минутах"
                    autofocus="eventTypeId === 3"
                />
              </div>
              <div class="form-group col">
                <input-radio class="mb-1" id='timeDurationRadio' :value="4" v-model='eventTypeId' :readonly="op === 'show'">
                  Время в часах и минутах
                </input-radio>
                <input-time
                    id="timeDuration"
                    class="form-control"
                    v-model="eventDuration"
                    ref="timeDuration"
                    :readonly="op === 'show' || eventTypeId !== 4"
                    :required="eventTypeId === 4"
                    error="Введите время в формате чч:мм"
                    :autofocus="eventTypeId === 4"
                />
              </div>
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import {getScheduleEvent} from "@/store/RmtHelpers";
import TransportationKindsSelect from "@/components/rmt/transportation_kinds/TransportationKindsSelect";
import InputRadio from "@/components/ui/custom/InputRadio";

export default {
  components: {InputRadio, TransportationKindsSelect},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      // для интервальных рейсов используется форма с долгой стоянкой
      eventTypeId: getScheduleEvent(this.item.schedule_event_id).schedule_event_type_id === 6 ? 4 : getScheduleEvent(this.item.schedule_event_id).schedule_event_type_id,
      eventDuration: this.item.event_duration,
      transportationKindId: this.item.transportation_kind_id,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          ...this.item,
          event_duration: this.eventDuration,
          transportation_kind_id: this.transportationKindId,
        })
      }
    },
  },
  computed: {
    eventName() {
      return getScheduleEvent(this.item.schedule_event_id).long_name;
    },
  },

}
</script>

<style scoped>

form label {
  font-weight: bold;
}

</style>