<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">

      <page-header>
        <span><router-link to="/rmt">Расписания</router-link></span>
        <span> / </span>
        <span><router-link to="/rmt/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to=routeVariantsLink>Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleVariantsLink>Варианты расписаний {{ routeVariantPageHeader }}</router-link></span>
        <span> / </span>
        <span>Периоды суток (для норм на интервалы движения) {{ scheduleVariantPageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <div class="container position-relative h-100">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">
            <crud-form
                :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
                :buttonsEnabled="crudButtonsEnabled"
                @onAdd="onTableAdd"
                @onEdit="onTableEdit"
                @onDelete="onTableDelete"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            >

              <!-- Кнопка связь-->
              <template #leftCustomButton>
                <dropdown>
                  <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary">Связь</button-toggler>
                  <dropdown-menu ref="collapse2">
                    <dropdown-item router-link :to="intNormInts">Нормы на интервалы движения</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>

            </crud-form>
          </align-panel>

          <!-- Таблица  -->
          <align-panel align="all">
            <norm-periods-edit-table
                :filterText="filterText"
                :isLoading="isLoading"
                :selectedRow="selectedRow"
                :rowList="rowList"
                @onRowSelect="onRowSelect"
                @onRowDblClick="onTableShow"
            ></norm-periods-edit-table>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <norm-period-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </norm-period-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить период суток кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import NormPeriodsEditTable from "@/components/rmt/norm_periods/NormPeriodsEditTable";
import NormPeriodDialog from "@/components/rmt/norm_periods/NormPeriodDialog";
import {getTimeFormat} from "@/lib";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    NormPeriodDialog,
    NormPeriodsEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
    // идентификатор варианта расписания
    "schedule_variant_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // вариант расписания
      scheduleVariant: {},
      // периоды времени
      intPeriods: [],
    }
  },

  computed: {

    rowList() {
      // получаем периоды
      let periods = this.intPeriods;
      periods.map(period => {
        period.__id = period.int_norm_period_id
        period.__time_begin = getTimeFormat(period.time_begin)
        period.__time_end = getTimeFormat(period.time_end)
      })
      return periods;
    },

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {}
      } else return this.selectedRow;
    },

    // ссылка для перехода к вариантам маршрутов
    routeVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants`
    },

    // ссылка для перехода к вариантам расписаний
    scheduleVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`;
    },

    // ссылка для перехода к нормы времени на движение
    intNormInts() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/intNormInts`;
    },

  },
  methods: {

    // загружаем Периоды
    async downloadIntNormPeriods() {
      await this.refreshIntNormPeriods(true);
    },

    // запускаем обновление периодов
    async refreshIntNormPeriods() {
      // запрос маршрута
      const intPeriods = await this.$store.dispatch('rmt/doFetchIntNormPeriods', {
        schedule_variant_id: this.schedule_variant_id,
      });
      if (!intPeriods) return;
      this.intPeriods = intPeriods;
    },

    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/intNormPeriods/add`);
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/intNormPeriods/edit/${this.selectedRow.int_norm_period_id}`);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/intNormPeriods/del/${this.selectedRow.int_norm_period_id}`);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // выполнено двойное нажатие строки в таблице (открываем просмотр)
    onTableShow() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/intNormPeriods/show/${this.selectedRow.int_norm_period_id}`);
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/intNormPeriods`);
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/intNormPeriods`);
    },

    // подтверждено изменения в диалоге
    onConfirmDelete(item) {
      this.delItem(item)
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/intNormPeriods`);
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        item['schedule_variant_id'] = Number(this.schedule_variant_id);
        // запрос на сервер
        await this.$store.dispatch('rmt/doAddIntNormPeriod', item);
        // перезапрашиваем таблицу
        await this.downloadIntNormPeriods();

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      try {

        item['schedule_variant_id'] = Number(this.schedule_variant_id);
        item['int_norm_period_id'] = this.selectedRow.__id
        // запрос на сервер
        await this.$store.dispatch('rmt/doEditIntNormPeriod', item);
        // перезапрашиваем таблицу
        await this.downloadIntNormPeriods();

      } finally {
        this.isLoading = false;
      }
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        await this.downloadIntNormPeriods();

        // запрос маршрута
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        }); if (!route) return;
        this.route = route;

        // запрос варианта маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id,
        }); if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // запрос варианта расписания
        const scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
          schedule_variant_id: this.schedule_variant_id,
        }); if (!scheduleVariant) return;
        this.scheduleVariant = scheduleVariant;

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрос на сервер
        await this.$store.dispatch('rmt/doDelIntNormPeriod', this.selectedRow);
        // перезапрашиваем таблицу
        await this.downloadIntNormPeriods();

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData(true);
  },
};
</script>
