<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-rmt-schedule-event"> </edit-table-panel>
</template>

<script>


import {getScheduleEventType} from "@/store/RmtHelpers";

export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "schedule_event_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "__scheduleEventTypeName",
          caption: "Тип",
          sortType: 'number',
          isFilterable: true,
          show: false
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
          show: false
        },
        {
          field: "is_total_ind",
          caption: "Нарядное время",
          sortType: 'boolean',
          dataAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_total_ind) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_line_ind",
          caption: "Линейное время",
          sortType: 'boolean',
          dataAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_line_ind) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_null_ind",
          caption: "Нулевое время",
          sortType: 'boolean',
          dataAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_null_ind) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_work_ind",
          caption: "Рабочее время",
          sortType: 'boolean',
          dataAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_work_ind) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_dinner_ind",
          caption: "Обеденное время",
          sortType: 'boolean',
          dataAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_dinner_ind) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_plan_stay_ind",
          caption: "Время планового отстоя",
          sortType: 'boolean',
          dataAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_plan_stay_ind) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_stay_ind",
          caption: "Отстойное время",
          sortType: 'boolean',
          dataAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_stay_ind) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_res_ind",
          caption: "Резервное время",
          sortType: 'boolean',
          dataAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_res_ind) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["rmt/getScheduleEvents"].map(schedule_event => {
        const scheduleEvent = getScheduleEventType(schedule_event.schedule_event_type_id);
        schedule_event.__scheduleEventTypeName = scheduleEvent.long_name;
        schedule_event.__id = schedule_event.schedule_event_id;
        return schedule_event;
      });
    },
  }
};
</script>
