<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Транспортное средство</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <nav-menu class="nav-tabs" mode="single">
            <nav-item>
              <nav-link target-ref="pane1" target-method="select" active>Основные</nav-link>
            </nav-item>
            <nav-item>
              <nav-link target-ref="pane2" target-method="select">Дополнительные</nav-link>
            </nav-item>
          </nav-menu>
          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>
                <div class="row">

                  <div class="form-group col">
                    <label for="transportId">Код:</label>
                    <input-integer
                        id="transportId"
                        class="form-control"
                        v-model="transportId"
                        readonly
                    />
                  </div>
                  <div class="form-group col">
                    <label for="dateIndexBegin">Нач. действ.:</label>
                    <req-field/>
                    <input-date
                        class="form-control"
                        id="dateIndexBegin"
                        v-model="dateIndexBegin"
                        ref="dateIndexBegin"
                        :readonly="op === 'show'"
                        required
                        error="Введите дату начала действия"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="isDisabled">Отключено:</label>
                    <input-switch
                        id="isDisabled"
                        v-model="isDisabled"
                        :readonly="op === 'show'"
                    >Отключено
                    </input-switch>
                  </div>

                </div>

                <div class="form-group">
                  <label for="transportTypeId">Вид транспорта:</label>
                  <req-field/>
                  <transport-types-select
                      class="form-control"
                      id="transportTypeId"
                      v-model="transportTypeId"
                      ref="transportTypeId"
                      :readonly="op === 'show'"
                      required
                      error="Выберите вид транспорта"
                  />
                </div>

                <div class="form-group">
                  <label for="stateNum">Государственный номер:</label>
                  <req-field/>
                  <input-string
                      class="form-control"
                      id="stateNum"
                      v-model="stateNum"
                      ref="stateNum"
                      :readonly="op === 'show'"
                      required
                      error="Введите государственный номер"
                  />
                </div>

                <div class="form-group">
                  <label for="garageNum">Гаражный номер:</label>
                  <req-field/>
                  <input-string
                      class="form-control"
                      id="garageNum"
                      v-model="garageNum"
                      ref="garageNum"
                      :readonly="op === 'show'"
                      required
                      error="Введите гаражный номер"
                  />
                </div>

                <div class="form-group">
                  <label for="depotId">Транспортное предприятие:</label>
                  <req-field/>
                  <depots-select
                      class="form-control"
                      id="depotId"
                      v-model="depotId"
                      ref="depotId"
                      :readonly="op === 'show'"
                      required
                      error="Выберите транспортное предприятие"
                  />
                </div>

                <div class="form-group">
                  <label for="modelId">Модель:</label>
                  <model-select
                      class="form-control"
                      id="modelId"
                      v-model="modelId"
                      ref="modelId"
                      :transportTypeId="transportTypeId"
                      :readonly="op === 'show'"
                      @change="modelConfirmShow"
                  />
                </div>

                <div class="form-group" v-if="isModelConfirmVisible">
                  <div class="form-group col" style="margin: 0">
                    <p>Скопировать соответствующие поля из выбранной модели ?</p>
                  </div>
                  <div class="form-group row" style="margin: 0">
                    <div class="form-group col-2" style="margin: 0">
                      <button
                          type="button"
                          class="btn btn-success"
                          @click="getModelData"
                      >Да
                      </button>
                    </div>
                    <div class="form-group col" style="margin: 0; padding-left: 0px">
                      <button
                          type="button"
                          class="btn btn-danger"
                          @click="modelConfirmHide"
                      >Нет
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="transportClassId">Класс транспортного средства:</label>
                  <transport-classes-select
                      class="form-control"
                      id="transportClassId"
                      v-model="transportClassId"
                      ref="transportClassId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="specialHeader">Специальный заголовок:</label>
                  <input-string
                      class="form-control"
                      id="specialHeader"
                      v-model="specialHeader"
                      ref="specialHeader"
                      :readonly="op === 'show'"
                  />
                </div>

              </form>
            </tab-pane>
            <tab-pane ref="pane2" class="p-2">
              <form>

                <div class="form-group">
                  <label for="engineFuelTypeId">Тип топлива:</label>
                  <engine-fuel-types-select
                      class="form-control"
                      id="engineFuelTypeId"
                      v-model="engineFuelTypeId"
                      ref="engineFuelTypeId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="emissionStandardId">Экологический класс:</label>
                  <emission-standards-select
                      class="form-control"
                      id="emissionStandardId"
                      v-model="emissionStandardId"
                      ref="emissionStandardId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="vin">VIN-номер:</label>
                  <input-string
                      class="form-control"
                      id="vin"
                      v-model="vin"
                      ref="vin"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="row">

                  <div class="form-group col">
                    <label for="depotDivision">Колонна:</label>
                    <input-integer
                        class="form-control"
                        id="depotDivision"
                        v-model="depotDivision"
                        ref="depotDivision"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>

                  <div class="form-group col">
                    <label for="manufactureYear">Год выпуска:</label>
                    <input-integer
                        class="form-control"
                        id="manufactureYear"
                        v-model="manufactureYear"
                        ref="manufactureYear"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>

                </div>

                <div class="row">
                  <div class="form-group col">
                    <label for="passCountSeat">Кол-во сидячих мест:</label>
                    <input-integer
                        class="form-control"
                        id="passCountSeat"
                        v-model="passCountSeat"
                        ref="passCountSeat"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="passCount">Общее кол-во мест:</label>
                    <input-integer
                        class="form-control"
                        id="passCount"
                        v-model="passCount"
                        ref="passCount"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col">
                    <label for="doorCount">Кол-во дверей:</label>
                    <input-integer
                        class="form-control"
                        id="doorCount"
                        v-model="doorCount"
                        ref="doorCount"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="insideAreaUseful">Полезная площадь, кв.м:</label>
                    <input-float
                        class="form-control"
                        id="insideAreaUseful"
                        v-model="insideAreaUseful"
                        ref="insideAreaUseful"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col">
                    <label for="weightWithPass">Вес с пассажирами, кг:</label>
                    <input-float
                        class="form-control"
                        id="weightWithPass"
                        v-model="weightWithPass"
                        ref="weightWithPass"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="maxSpeed">Макс. скорость, км/ч:</label>
                    <input-float
                        class="form-control"
                        id="maxSpeed"
                        v-model="maxSpeed"
                        ref="maxSpeed"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col">
                    <label for="length">Длина, мм:</label>
                    <input-float
                        class="form-control"
                        id="length"
                        v-model="length"
                        ref="length"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="width">Ширина, мм:</label>
                    <input-float
                        class="form-control"
                        id="width"
                        v-model="width"
                        ref="width"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="height">Высота, мм:</label>
                    <input-float
                        class="form-control"
                        id="height"
                        v-model="height"
                        ref="height"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <input-checkbox
                      id="isAirConditioning"
                      v-model="isAirConditioning"
                      :readonly="op === 'show'"
                  >Наличие кондиционера
                  </input-checkbox>

                  <input-checkbox
                      id="isBikeMount"
                      v-model="isBikeMount"
                      :readonly="op === 'show'"
                  >Наличие крепления для велосипедов
                  </input-checkbox>

                  <input-checkbox
                      id="isAirHeating"
                      v-model="isAirHeating"
                      :readonly="op === 'show'"
                  >Наличие отопителя
                  </input-checkbox>

                  <input-checkbox
                      id="isRamp"
                      v-model="isRamp"
                      :readonly="op === 'show'"
                  >Наличие пандуса
                  </input-checkbox>

                  <input-checkbox
                      id="isLowFloor"
                      v-model="isLowFloor"
                      :readonly="op === 'show'"
                  >Низкопольный
                  </input-checkbox>

                  <input-checkbox
                      id="isInstructorSeat"
                      v-model="isInstructorSeat"
                      :readonly="op === 'show'"
                  >Место для инструктора
                  </input-checkbox>

                  <input-checkbox
                      id="isTemperatureSensor"
                      v-model="isTemperatureSensor"
                      :readonly="op === 'show'"
                  >Датчик температуры
                  </input-checkbox>

                  <input-checkbox
                      id="isNoiseSensor"
                      v-model="isNoiseSensor"
                      :readonly="op === 'show'"
                  >Датчик шума
                  </input-checkbox>

                  <input-checkbox
                      id="isSmokeSensor"
                      v-model="isSmokeSensor"
                      :readonly="op === 'show'"
                  >Датчик дыма
                  </input-checkbox>

                  <input-checkbox
                      id="isFuelSensor"
                      v-model="isFuelSensor"
                      :readonly="op === 'show'"
                  >Датчик топлива
                  </input-checkbox>

                  <input-checkbox
                      id="isPaySystem"
                      v-model="isPaySystem"
                      :readonly="op === 'show'"
                  >Оплата проезда
                  </input-checkbox>

                  <input-checkbox
                      id="isPassengerCount"
                      v-model="isPassengerCount"
                      :readonly="op === 'show'"
                  >Подсчет пассажиров
                  </input-checkbox>

                  <input-checkbox
                      id="isVideoRecordSystem"
                      v-model="isVideoRecordSystem"
                      :readonly="op === 'show'"
                  >Система видеонаблюдения
                  </input-checkbox>

                  <input-checkbox
                      id="isMultimedia"
                      v-model="isMultimedia"
                      :readonly="op === 'show'"
                  >Мультимедия
                  </input-checkbox>

                  <input-checkbox
                      id="isEboard"
                      v-model="isEboard"
                      :readonly="op === 'show'"
                  >Информационное табло
                  </input-checkbox>

                  <input-checkbox
                      id="isAutoinformer"
                      v-model="isAutoinformer"
                      :readonly="op === 'show'"
                  >Автоинформатор
                  </input-checkbox>

                  <input-checkbox
                      id="isTachograph"
                      v-model="isTachograph"
                      :readonly="op === 'show'"
                  >Тахограф
                  </input-checkbox>

                </div>

              </form>
            </tab-pane>
          </tab-content>

        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>

</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import TransportTypesSelect from "@/components/ref/transport_types/TransportTypesSelect";
import TransportClassesSelect from "@/components/ref/transport_classes/TransportClassesSelect";
import EngineFuelTypesSelect from "@/components/ref/engine_fuel_types/EngineFuelTypesSelect";
import EmissionStandardsSelect from "@/components/ref/emission_standards/EmissionStandardsSelect";
import DepotsSelect from "@/components/ref/depots/DepotsSelect";
import ModelSelect from "@/components/ref/models/ModelSelect";
import InputSwitch from "@/components/ui/custom/InputSwitch";
import {dateToND, dateToString, ndToDate} from "@/lib";
import {getModel} from "@/store/RefHelpers";

export default {
  components: {
    InputSwitch,
    ModelSelect,
    DepotsSelect, EmissionStandardsSelect, EngineFuelTypesSelect, TransportClassesSelect, TransportTypesSelect
  },
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      transportId: this.item.transport_id,
      transportTypeId: this.item.transport_type_id,
      depotId: this.item.depot_id,
      modelId: this.item.model_id,
      transportClassId: this.item.transport_class_id,
      engineFuelTypeId: this.item.engine_fuel_type_id,
      emissionStandardId: this.item.emission_standard_id,
      vin: this.item.vin,
      stateNum: this.item.state_num,
      garageNum: this.item.garage_num,
      specialHeader: this.item.special_header,
      depotDivision: this.item.depot_division,
      doorCount: this.item.door_count,
      passCountSeat: this.item.pass_count_seat,
      passCount: this.item.pass_count,
      insideAreaUseful: this.item.inside_area_useful,
      weightWithPass: this.item.weight_with_pass,
      length: this.item.length,
      width: this.item.width,
      height: this.item.height,
      maxSpeed: this.item.max_speed,
      manufactureYear: this.item.manufacture_year,
      isAirConditioning: this.item.is_air_conditioning,
      isBikeMount: this.item.is_bike_mount,
      isAirHeating: this.item.is_air_heating,
      isRamp: this.item.is_ramp,
      isLowFloor: this.item.is_low_floor,
      isInstructorSeat: this.item.is_instructor_seat,
      isTemperatureSensor: this.item.is_temperature_sensor,
      isNoiseSensor: this.item.is_noise_sensor,
      isSmokeSensor: this.item.is_smoke_sensor,
      isFuelSensor: this.item.is_fuel_sensor,
      isPaySystem: this.item.is_pay_system,
      isPassengerCount: this.item.is_passenger_count,
      isVideoRecordSystem: this.item.is_video_record_system,
      isMultimedia: this.item.is_multimedia,
      isEboard: this.item.is_eboard,
      isAutoinformer: this.item.is_autoinformer,
      isTachograph: this.item.is_tachograph,
      isDisabled: this.item.is_disabled,
      dateIndexBegin: this.op == 'show' ? dateToString(ndToDate(this.item.date_index_begin)) : dateToString(),
      dateIndexEnd: null,

      // видно ли всплывающее оповещение при выборе модели
      isModelConfirmVisible: false,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          transport_id: this.op === 'add' ? undefined : this.transportId,
          transport_type_id: this.transportTypeId,
          depot_id: this.depotId,
          model_id: this.modelId,
          transport_class_id: this.transportClassId,
          engine_fuel_type_id: this.engineFuelTypeId,
          emission_standard_id: this.emissionStandardId,
          vin: this.vin,
          state_num: this.stateNum,
          garage_num: this.garageNum,
          special_header: this.specialHeader,
          depot_division: this.depotDivision,
          door_count: this.doorCount,
          pass_count_seat: this.passCountSeat,
          pass_count: this.passCount,
          inside_area_useful: this.insideAreaUseful,
          weight_with_pass: this.weightWithPass,
          length: this.length,
          width: this.width,
          height: this.height,
          max_speed: this.maxSpeed,
          manufacture_year: this.manufactureYear,
          is_air_conditioning: this.isAirConditioning,
          is_bike_mount: this.isBikeMount,
          is_air_heating: this.isAirHeating,
          is_ramp: this.isRamp,
          is_low_floor: this.isLowFloor,
          is_instructor_seat: this.isInstructorSeat,
          is_temperature_sensor: this.isTemperatureSensor,
          is_noise_sensor: this.isNoiseSensor,
          is_smoke_sensor: this.isSmokeSensor,
          is_fuel_sensor: this.isFuelSensor,
          is_pay_system: this.isPaySystem,
          is_passenger_count: this.isPassengerCount,
          is_video_record_system: this.isVideoRecordSystem,
          is_multimedia: this.isMultimedia,
          is_eboard: this.isEboard,
          is_autoinformer: this.isAutoinformer,
          is_tachograph: this.isTachograph,
          is_disabled: this.isDisabled,
          date_index_begin: dateToND(new Date(this.dateIndexBegin)),
          date_index_end: null
        })
      }
    },

    // скрываем / показываем вопрос о подстановке данных в диалог при выборе модели
    modelConfirmShow() {
      this.isModelConfirmVisible = true;
    },

    // показываем вопрос о подстановке данных в диалог при выборе модели
    modelConfirmHide() {
      this.isModelConfirmVisible = false;
    },


    // подставляем данные из выбранной модели
    getModelData() {
      // получаем модель
      let model = getModel(this.modelId);
      console.log(model)
      // this.transportTypeId = model.transport_type_id;
      this.transportClassId = model.transport_class_id;
      this.engineFuelTypeId = model.engine_fuel_type_id;
      this.emissionStandardId = model.emission_standard_id;
      this.doorCount = model.door_count;
      this.passCountSeat = model.pass_count_seat;
      this.passCount = model.pass_count;
      this.insideAreaUseful = model.inside_area_useful;
      this.weightWithPass = model.weight_with_pass;
      this.length = model.length;
      this.width = model.width;
      this.height = model.height;
      this.maxSpeed = model.max_speed;
      this.isAirConditioning = model.is_air_conditioning;
      this.isBikeMount = model.is_bike_mount;
      this.isAirHeating = model.is_air_heating;
      this.isRamp = model.is_ramp;
      this.isLowFloor = model.is_low_floor;
      this.isInstructorSeat = model.is_instructor_seat;
      // скрываем диалог
      this.isModelConfirmVisible = false;
    }
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}

</style>