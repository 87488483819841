<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-kvr-work-tasks" :style="'tr-padding: 0'"></edit-table-panel>
</template>

<script>

import {
  getModel,
  getTransportClass,
  getTransportType
} from "@/store/RefHelpers";
import {secondsToTime} from "@/lib";
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getAgency, getDepot} from "@/store/KvrHelpers";

export default {
  components: {EditTablePanel},
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__route_num",
          caption: "№ марш.",
          sortType: 'routeNum',
          isFilterable: true,
        },
        {
          field: "__route_title",
          caption: "Наименование маршрута",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "graph",
          caption: "Гр.",
          sortType: 'number',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "shift",
          caption: "См.",
          sortType: 'number',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "__time_begin",
          caption: "Нач.",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "__time_end",
          caption: "Оконч.",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "__transport_type_name",
          caption: "Тип тр.",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "state_num",
          caption: "Гос.№",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "garage_num",
          caption: "Гар.№",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "__transport_class_name",
          caption: "Класс ТС",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "__modelName",
          caption: "Модель",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__organisation",
          caption: "Перевозчик",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__depot_name",
          caption: "Парк",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "route_id",
          caption: "Код марш.",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "__reg_num",
          caption: "Реестр. номер",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "work_task_id",
          caption: "Код задания",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      return this.$store.getters["kvr/getWorkTasks"].map(workTask => {

        let model = getModel(workTask.model_id)
        let transport_type = getTransportType(workTask.transport_type_id);
        let transport_class = getTransportClass(workTask.transport_class_id)
        let depot = getDepot(workTask.depot_id);
        let org = {};
        if(depot) {
          org = getAgency(depot.org_id);
        }

        if(transport_class)
          workTask.__transport_class_name = transport_class.short_name;
        if(transport_type)
          workTask.__transport_type_name = transport_type.long_name;
        if (model)
          workTask.__modelName = model.long_name;
        if(depot)
          workTask.__depot_name = depot.long_name;
        if(org)
          workTask.__organisation = org.long_name;

        workTask.__time_begin = secondsToTime(workTask.time_index_begin);
        workTask.__time_end = secondsToTime(workTask.time_index_end);

        workTask.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px'
        };

        return workTask;
      });
    },
  }
};
</script>