<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/tariffZoneSystems">Тарифные зональные системы</router-link></span>
        <span> / </span>
        <span><router-link :to="tariffZonesLink">Тарифные зоны {{ tariffZoneSystemPageHeader }}</router-link></span>
        <span> / </span>
        <span>Зоны тарифных зон</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="tableButtonsExist"
              :buttonsEnabled="tableButtonsEnabled"
              :filterExists="false"
              @onRefresh="onTableRefresh"
              @onEdit="onTableEdit"
              @onSave="onTableSave"
              @onCancel="onTableCancel"
          >
          </crud-form>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <!-- Таблица  -->
            <align-panel align="left" width="30%" :gap="3">
              <tariff-zone-zones-edit-table
                  :rowList="tariffZones"
                  :selectedRow="selectedRow"
                  :isLoading="isLoading"
                  @onRowSelect="onRowSelect"
                  @onMapCreated="onMapCreated"
              ></tariff-zone-zones-edit-table>
            </align-panel>
            <!-- Карта  -->
            <align-panel align="all">
              <tariff-zone-zones-map
                  :tariffZoneZones="tariffZoneZones"
                  :selectedTariffZoneZone="selectedZone"
                  :isEditMode="isEditMode"
                  :zoneVisibles="zoneVisibles"
                  @onMapCreated="onMapCreated"
                  @onZoneChanged="onZoneChanged"
              >
              </tariff-zone-zones-map>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- В случпе, если зона не задана выводим предложение создать зону -->
  <confirm-message-box
      v-if="isAddConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmAdd"
  >Зона отстутствует, софрмировать зону с кодом {{ id }} в центре экрана ?</confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import {addLat, addLong} from "@/components/ui/leaflet/LeafletHelpers";
import {editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";
import TariffZoneZonesEditTable from "@/components/multipass/tariff_zone_zones/TariffZoneZonesEditTable";
import TariffZoneZonesMap from "@/components/multipass/tariff_zone_zones/TariffZoneZonesMap";
import {getTariffZoneSystem} from "@/store/MultipassHelpers";

export default {
  mixins: [PageMixin],

  components: {
    TariffZoneZonesMap,
    TariffZoneZonesEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор тарифной зональной системы
    "tariff_zone_system_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // отображение диалога с предложением создать зону в центре экрана
      isCreateDialogVisible: false,
      // карта
      leaflet: null,
      // режим редактирования
      isEditMode: false,
      // список строк
      tariffZoneZones: [],
      // выбранная строка
      selectedRow: null,
      // выбранная зона
      selectedZone: null,
      // отображать спиннер
      isLoading: false,
      // текущая тарифная зональная сисетма
      tariffZoneSystem: {},
      // тарифные зоны текущей тарифной зональной системы
      tariffZones: [],
      // исходная зона для восстановления
      originZone: null,
      // признак модификации
      isModified: false,
    }
  },


  computed: {

    // определение видимости зон на карте исходя из галочек в таблице
    zoneVisibles(){
      let visibles = [];
      this.tariffZones.map(value => {
        visibles.push(value.is_visible)
      })
      return visibles;
    },

    // включенные кнопки для таблицы
    tableButtonsExist() {
      return {
        edit: !this.isEditMode,
        save: this.isEditMode,
        cancel: this.isEditMode,
        refresh: true,
      }
    },

    // активные кнопки для таблицы
    tableButtonsEnabled() {
      return {
        edit: !!this.selectedRow,
        save: this.isModified,
        cancel: true,
        refresh: true,
      }
    },

    // условие отображения подтверждения удаления
    isAddConfirmVisible() {
      return this.op === 'add' && !!this.selectedRow;
    },
    // переходим к тарифным зонам
    tariffZonesLink() {
      return '/multipass/tariffZoneSystems/' + this.tariff_zone_system_id + '/tariffZones';
    },
  },

  methods: {

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // перезапрашиваются данные
      this.refreshData();
    },

    // вызывается, когда добавляется объект
    onTableAdd(item) {
      this.addItem(item)
    },

    // вызывается, когда нажата кнопка Изменить
    onTableEdit() {
      // входим в режим редактирования
      this.isEditMode = true;
      // сбрасываем признак изменения
      this.isModified = false;
      // запоминаем исходную зону
      this.originZone = {...this.selectedZone}
    },

    // нажата кнопка подтверждения добавления зоны в центре карты
    onConfirmAdd() {
      // возвращаемся к таблице
      this.$router.replace(`/multipass/tariffZoneSystems/${this.tariff_zone_system_id}/tariffZones/tariffZoneZones`);
      // добавляем зону
      this.addItem();
    },

    // отменили операцию
    onRejectOperation() {
      this.$router.replace(`/multipass/tariffZoneSystems/${this.tariff_zone_system_id}/tariffZones/tariffZoneZones`);
    },

    // нажата кнопка Сохранить в режиме редактирования
    onTableSave() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // сохраняем текущий элемент
      this.saveItem();
    },

    // нажата кнопка Отменить в режиме редактирования
    onTableCancel() {
      // выходим из режима редактирования
      this.isEditMode = false;
      // восстанавливаем текущий элемент
      this.restoreItem();
    },

    // вызывается при редактировании зоны
    onZoneChanged(zone) {
      this.selectedZone = editMutation(this.tariffZoneZones, 'zone_id', zone);
      // устанавливаем признак изменения
      this.isModified = true;
    },

    // вызывается при выделении строки
    async onRowSelect(item) {

      // сменился элемент в режиме редактирования
      if (this.selectedRow && item !== this.selectedRow && this.isEditMode) {
        // восстанавливаем элемент
        await this.restoreItem();
        // сбрасываем режим редактирования
        this.isEditMode = false;
      }

      // определяем активный элемент таблицы
      this.selectedRow = item;
      // получаем индекс выбранной строки
      let index = this.tariffZones.indexOf(this.selectedRow);
      // по индексу выбранной строки получаем выбранную зону трифной зоны
      this.selectedZone = this.tariffZoneZones[index]

      // перемещаем карту к активной зоне
      if (this.leaflet && this.selectedZone && this.selectedZone.elements && this.selectedZone.latitude && this.selectedZone.longitude) {
        const minStopZoom = this.$store.getters['settings/getMinStopZoom'];
        const zoom = this.leaflet.getZoom();
        if (zoom >= minStopZoom)
          this.leaflet.setView([this.selectedZone.latitude, this.selectedZone.longitude], zoom)
        else
          this.leaflet.setView([this.selectedZone.latitude, this.selectedZone.longitude], minStopZoom)
      } else {
        // если у выбранной тарифной зоны нет зоны для отображения на карте предлагаем позльзователю создать зону в центре карты
        await this.$router.replace(`/multipass/tariffZoneSystems/${this.tariff_zone_system_id}/tariffZones/tariffZoneZones/add/${this.selectedZone.zone_id}`);
      }
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {
        // запрашиваем тарифную зональную систему
        if (!await this.$store.dispatch('multipass/doFetchTariffZoneSystems')) return;
        const tariffZoneSystem = getTariffZoneSystem(this.tariff_zone_system_id);if (!tariffZoneSystem) return;
        this.tariffZoneSystem = tariffZoneSystem;
        // Запрашиваем тарифные зоны, исходя из тарифной зональной системы
        let tariff_zones = await this.$store.dispatch('multipass/doFetchTariffZones', {
          tariff_zone_system_id: this.tariff_zone_system_id
        });
        if (!tariff_zones) return;
        this.tariffZones = tariff_zones;

        // формируем массив id тарифных зон, а также проставляем всем им видимость
        let tariff_zone_id_list = []
        this.tariffZones.map((value) => {
          tariff_zone_id_list.push(Number(value.tariff_zone_id));
          value.is_visible = true
        })

        // запрашиваем зоны тарифных зон
        let tariffZoneZones = await this.$store.dispatch('multipass/doFetchTariffZoneZones',{
          tariff_zone_id_list:{tariff_zone_id_list}
        });
        if(!tariffZoneZones) return;
        this.tariffZoneZones = tariffZoneZones;
        // делаем все зоны тарифных зон видимыми
        this.tariffZoneZones.map(value => {
          value.is_visible = true
        })

      } finally {
        this.selectedRow = null;
        this.selectedZone = null;
        this.isLoading = false;
      }
    },

    // добавляем объект
    async addItem() {
      this.isLoading = true;
      try {

        // координаты остановки
        const coord = [this.leaflet.getCenter().lat, this.leaflet.getCenter().lng];

        // координаты точек
        const point1 = addLong(addLat(coord, -50), -50);
        const point2 = addLong(addLat(coord, 50), -50);
        const point3 = addLong(addLat(coord, 50), 50);
        const point4 = addLong(addLat(coord, -50), 50);

        // получаем координаты центра карты
        this.selectedZone.latitude = this.leaflet.getCenter().lat;
        this.selectedZone.longitude = this.leaflet.getCenter().lng;

        // формируем точки зоны
        this.selectedZone.elements = [
              {
                zone_element_id: null,
                zone_element_type_id: 3,
                zone_id: this.selectedZone.zone_id,
                order_num: 1,
                parameter_size: 0,
                points: [
                  {
                    zone_element_id: null,
                    order_num: 1,
                    latitude: point1[0],
                    longitude: point1[1],
                  },
                  {
                    zone_element_id: null,
                    order_num: 2,
                    latitude: point2[0],
                    longitude: point2[1],
                  },
                  {
                    zone_element_id: null,
                    order_num: 3,
                    latitude: point3[0],
                    longitude: point3[1],
                  },
                  {
                    zone_element_id: null,
                    order_num: 4,
                    latitude: point4[0],
                    longitude: point4[1],
                  }
                ]
              }
            ]

        // запрос на сервер
        const newTariffZone = await this.$store.dispatch('multipass/doSetUpTariffZoneZone', {
          zone: this.selectedZone,
          tariff_zone_id: this.selectedRow.tariff_zone_id
        });
        // мутируем массив
        this.selectedZone = editMutation(this.tariffZoneZones, 'zone_id', newTariffZone);
      }
      finally {
        this.isLoading = false;
      }
    },

    // восстанавливаем текущий объект
    restoreItem() {
      this.selectedZone = editMutation(this.tariffZoneZones, 'zone_id', this.originZone);
    },

    // сохраняем текущий объект
    async saveItem() {
      this.isLoading = true;
      try {

        // запрос на сервер
        const newTariffZone = await this.$store.dispatch('multipass/doSetUpTariffZoneZone', {
          zone: this.selectedZone,
          tariff_zone_id: this.selectedRow.tariff_zone_id
        });
        // мутируем массив
        this.selectedZone = editMutation(this.tariffZoneZones, 'zone_id', newTariffZone);

      }
      finally {
        this.isLoading = false;
      }
    }
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

