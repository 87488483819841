<template>
  <edit-table-panel :columnList="columnList" :rowList="rows" :disableSettings="true"
                    name="tbl-multipass-trip-type-card"></edit-table-panel>
</template>

<script>

import {getStop} from "@/store/MultipassHelpers";

export default {
  emits: ['onDeleteStop'],
  props: ['isEditMode', 'rowList'],
  computed: {
    columnList() {
      let colList = [
        {
          field: "__delStop",
          caption: " ",
          displayType: 'button',
          buttonCaption: '<i class="fas fa-trash"></i>',
          buttonAttrs: {
            'class': "btn btn-danger btn-sm",
            'title': 'Удалить остановку из трассы'
          },
          buttonClick: (row) => {
            this.$emit('onDeleteStop', row.order_num);
          },
          show: this.isEditMode,
          isSortable: false,
        },
        {
          field: "order_num",
          caption: "№",
          sortType: 'number',
          isSortable: false,
          displayFn(row, rowIndex) {
            return String(rowIndex + 1);
          }
        },
        {
          field: "__stopName",
          caption: "Наименование",
          isSortable: false,
          sortType: 'string',
        }
      ]
      if (this.isEditMode) {
        colList.push(
            {
              field: "length",
              caption: "Пробег,км",
              isSortable: false,
              sortType: 'number',
              displayType: 'length',
              inputAttrs: {
                disabled: !this.isEditMode,
                required: true,
                style: 'width: 80px'
              }
            },
            {
              field: "is_control_point",
              caption: "КП",
              isSortable: false,
              sortType: 'boolean',
              displayType: 'checkbox',
              headerAlign: 'center',
              dataAlign: 'center',
              inputAttrs: {
                readonly: !this.isEditMode,
              }
            },
            {
              field: "is_skipped",
              caption: "Разв.",
              isSortable: false,
              sortType: 'boolean',
              isFilterable: false,
              displayType: 'checkbox',
              headerAlign: 'center',
              dataAlign: 'center',
              inputAttrs: {
                readonly: !this.isEditMode,
              }
            },
        )
      } else {
        colList.push(
          {
            field: "length",
            caption: "Пробег,км",
            isSortable: false,
            sortType: 'String',
            displayType: 'String',
            inputAttrs: {
              disabled: !this.isEditMode,
              required: true,
              style: 'width: 80px'
            },
            displayFn(row) {
              return Number(row.length / 1000)
            }
          },
          {
            field: "is_control_point",
            caption: "КП",
            isSortable: false,
            sortType: 'boolean',
            displayType: 'html',
            headerAlign: 'center',
            dataAlign: 'center',
            inputAttrs: {
              readonly: !this.isEditMode,
            },
            displayFn(row) {
              if (row.is_control_point) {
                return '<i class="fas fa-check" style="color: #007bff">'
              }
              return ''
            },
          },
          {
            field: "is_skipped",
            caption: "Разв.",
            isSortable: false,
            sortType: 'boolean',
            isFilterable: false,
            displayType: 'html',
            headerAlign: 'center',
            dataAlign: 'center',
            inputAttrs: {
              readonly: !this.isEditMode,
            },
            displayFn(row) {
              if (row.is_skipped) {
                return '<i class="fas fa-check" style="color: #007bff">'
              }
              return ''
            },
          },
        )
      }

      colList.push(
        {
          field: "stop_id",
          caption: "Код",
          isSortable: false,
          sortType: 'number',
          isFilterable: false
        }
      )
      return colList
    },

    rows() {
      return this.rowList.map(item => {
        const stop = getStop(item.stop_id);

        item.__id = item.order_num;
        item.__stopName = stop && stop.long_name || '';
        return item;
      })
    }
  },
};
</script>
