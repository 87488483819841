import {postRequest} from "@/store/Requests";
import {getDateTimeFormat, getPasswordHash} from "@/lib";
import router from "@/router/Router";
import {DoGetRaw} from "@/store/Actions";
import version from "../../version";

// ГЛАВНОЕ ХРАНИЛИЩЕ MAIN

export default {
    state() {
        return {
            // логин
            login: '',
            // токен
            tokenId: '',
            // левое меню открыто
            isLeftMenuShow: false,
            // версия сервера
            serverVersion:'',
            // время сервера
            serverTime: '',
            // название системы
            systemСaption: '',
            // признак успешной загрузки приложения
            isLoaded: false,
            // изначальный путь
            loadedUrl: null,
            // критическая ошибка
            isPanic: false,
            // текст критической ошибки
            panicMessage: '',
            // отчеты открыты
            isReportsShow: true,
        }
    },
    getters: {
        // доступ к логину
        getLogin(state) {
            return state.login;
        },
        // доступ к токену
        getTokenId(state) {
            return state.tokenId
        },
        // признак авторизации
        getIsAuth(state) {                        
            return state.login !== '' && state.tokenId !== '';
        },
        // признак открытия левого меню
        getIsLeftMenuShow(state) {
            return state.isLeftMenuShow;
        },
        // признак открытия панели отчетов
        getIsReportsShow(state) {
            return state.isReportsShow;
        },
        // версия сервера
        getServerVersion(state) {
            return state.serverVersion;
        },
        // время сервера
        getServerTime(state) {
            return getDateTimeFormat(state.serverTime);
        },
        // название системы
        getSystemСaption(state) {
            return state.systemСaption;
        },
        // версия клиента
        getClientVersion() {
            return version.MAJOR + '.' + version.BUILD;
        },
        // признак загрузки настроек
        getIsLoaded(state) {
            return state.isLoaded;
        },
        // изначальный url-адрес
        getLoadedUrl(state) {
            return state.loadedUrl;
        },
        // наличие критической ошибки
        getIsPanic(state) {
            return state.isPanic;
        },
        // текст критической ошибки
        getPanicMessage(state) {
            return state.panicMessage;
        }
    },
    mutations: {
        // установка параметров авторизации
        setAuth(state, payload) {
            state.login = payload.login;
            state.tokenId = payload.tokenId;
            localStorage.setItem('login', payload.login);
            localStorage.setItem('tokenId', payload.tokenId);
        },
        // сбрасываем параметры авторизации
        clearAuth(state) {
            state.login = '';
            state.tokenId = '';
            localStorage.removeItem('login');
            localStorage.removeItem('tokenId');
        },
        // открываем левое меню
        showLeftMenu(state) {
            state.isLeftMenuShow = true;
        },
        // закрываем левое меню
        hideLeftMenu(state) {
            state.isLeftMenuShow = false;
        },
        // переключаем левое меню
        toggleLeftMenu(state) {
            state.isLeftMenuShow = !state.isLeftMenuShow;
        },
        // открываем левое меню
        showReports(state) {
            state.isReportsShow = true;
        },
        // закрываем левое меню
        hideReports(state) {
            state.isReportsShow = false;
        },
        // переключаем левое меню
        toggleReports(state) {
            state.isReportsShow = !state.isReportsShow;
        },
        // сетаем версию сервера
        setServerStatus(state, payload) {
            state.serverVersion = payload.version;
            state.serverTime = payload.server_time;
            state.systemСaption = payload.system_caption;
        },
        // устанавливаем изначальный url-адрес
        setLoadedUrl(state, payload) {
            state.loadedUrl = payload.loadedUrl;
        },
        // фиксируем успешную загрузку
        setLoaded(state) {
            state.isLoaded = true;
        },
        // устанавливаем критическую ошибку
        setPanic(state, payload) {
            state.isPanic = true;
            state.panicMessage = payload.panicMessage;
        }
    },
    actions: {
        // вход в систему
        doLogin: async function(context, payload) {
            // базовый адрес
            const baseUrl = context.getters['settings/getBaseUrl'];

            try {
                // отправляем запрос
                const response = await postRequest (
                    baseUrl + '/login',
                    {
                        login: payload.login,
                        password: getPasswordHash(payload.login, payload.password)
                    }
                );

                // проверяем ответ на корректность
                if (response.data && response.data.result && response.data.result.login && response.data.result['token_id']) {
                    const login = response.data.result.login;
                    const tokenId = response.data.result['token_id'];

                    // сохраняем данные авторизации
                    context.commit('setAuth', {
                        login,
                        tokenId
                    })

                    // // загружаем справочники
                    await context.dispatch('downloadDirectories');

                    // отправляем сообщение об успешном входе
                    await context.dispatch('doAddMessage', {
                        caption: 'Вход в систему',
                        message: 'Успешный вход в систему',
                        type: 'ok'
                    })
                    // переходим в корень или по изначальному пути
                    await router.replace(context.getters.getLoadedUrl ?  context.getters.getLoadedUrl : '/');

                    // фиксируем успех
                    return true;
                }
                else {
                    // ругаемся
                    await context.dispatch('doAddMessage', {
                        caption: 'Вход в систему',
                        message: 'Неправильная структура ответа от сервера',
                        type: 'error'
                    });

                    // выходим с ошибкой
                    return false;
                }
            }
            catch(e) {
                // ругаемся
                await context.dispatch('doAddMessage', {
                    caption: 'Вход в систему',
                    message: e.message,
                    type: 'error'
                });

                // выходим с ошибкой
                return false;
            }
        },

        // выход из системы
        doLogout: async function(context) {
            // базовый адрес
            const baseUrl = context.getters['settings/getBaseUrl'];

            try {
                // отправляем запрос
                await postRequest (
                    baseUrl + '/logout',
                    undefined,
                    context.getters.getTokenId
                );

                // сбрасываем данные авторизации
                await context.commit('clearAuth');

                // очищаем изначальный путь, если он был
                if(context.getters.getLoadedUrl){
                    // ** ругается, если в значение передать null
                    await context.dispatch('doSetLoadedUrl', '');
                }

                // отправляем сообщение об успешном выходе
                await context.dispatch('doAddMessage', {
                    caption: 'Выход из системы',
                    message: 'Успешный выход из системы',
                    type: 'ok'
                })

                // переходим в авторизацию
                await router.replace('/auth');

                // фиксируем успех
                return true;
            }
            catch(e) {
                // сбрасываем данные авторизации
                await context.commit('clearAuth');

                // ругаемся
                await context.dispatch('doAddMessage', {
                    caption: 'Выход из системы',
                    message: e.message,
                    type: 'error'
                })

                // переходим в авторизацию
                await router.replace('/auth');

                // выходим с ошибкой
                return false;
            }
        },

        // обновление сессии
        doUpdateSession: async function(context) {
            // базовый адрес
            const baseUrl = context.getters['settings/getBaseUrl'];

            try {

                // вызываем запрос
                await postRequest (
                    baseUrl + '/updateSession',
                    undefined,
                    context.getters.getTokenId
                );

                // успешное обновление сессии
                // await context.dispatch('doAddMessage', {
                //     caption: 'Обновление сессии',
                //     message: 'Успешное обновление сессии',
                //     type: 'ok'
                // });

                // фиксируем успех
                return true;

            }
            catch(e) {

                // ругаемся
                await context.dispatch('doAddMessage', {
                    caption: 'Обновление сессии',
                    message: e.message,
                    type: 'error'
                });

                // выходим с ошибкой
                return false;
            }
        },

        // автоматический вход в систему
        doAutoLogin: async function(context) {

            // замускаем таймер автообновления (раз в минуту)
            setInterval(() => {
                if (context.getters.getIsAuth) {
                    context.dispatch('doUpdateSession');
                }
            }, 60000)

            // извлаекаем данные из локального хранилища
            const login = localStorage.getItem('login');
            const tokenId = localStorage.getItem('tokenId');

            // есть данные в локальном хранилище
            if (login && tokenId) {

                // сохраняем их в нашем хранилище
                context.commit('setAuth', {
                    login,
                    tokenId
                });

                // вызываем обновление сессии
                let status = await context.dispatch('doUpdateSession');
                // загружаем справочники
                if (status != false) {
                    await context.dispatch('downloadDirectories');
                }

                return status;
            }

            return false;
        },

        // запрос версии сервера
        doFetchServerStatus: (context) => {
            const baseUrl = context.getters['settings/getBaseUrl'];

            return DoGetRaw (
                baseUrl + '/status',
                'setServerStatus',
                'Запрос статуса сервера',
                false,
            )
        },

        // устанавливаем панику
        doSetPanic: async (context, payload) => {
            // устанавливаем панику
            context.commit('setPanic', payload);
        },

        // устанавливаем признак загрузки
        doSetLoaded:  (context) => {
            context.commit('setLoaded');
        },

        // устанавливаем начальный путь
        doSetLoadedUrl:  (context, payload) => {
            context.commit('setLoadedUrl', payload);
        },

        // загружаем справочники либо при обновлении страницы (автологин), либо при авторизации пользователя
        downloadDirectories: async (context) => {
            // загружаем отчеты
            await context.dispatch('system/doFetchReports', {force : true});
            // загружаем доступные модули
            await context.dispatch('auth/doFetchAllowedModules', {force : true});
            // загружаем настройки пользователя
            await context.dispatch('auth/doFetchClientSettings', {
                client_set_name : 'trn',
                force : true
            });
            // загружаем запрос версии сервера
            await context.dispatch('doFetchServerStatus', {force : true});
            // await context.dispatch('auth/doEditEditTableClientSettings', {
            //     client_set_name : 'trn',
            //     settings:{}
            // });
        }
    }
}