<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span>Варианты маршрута {{ routePageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка Команда-->
            <template #leftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                :disabled="commandDisabled">Команда
                </button-toggler>
                <dropdown-menu ref="collapse">
                  <dropdown-item @click="onChangeEndDate" v-if="selectedRow">Изменить окончание</dropdown-item>
                  <dropdown-divider v-if="setStatusExists"></dropdown-divider>
                  <dropdown-item @click="onSetStatus" v-if="setStatusExists">Установить статус</dropdown-item>
                  <dropdown-divider v-if="setStatusExists"></dropdown-divider>
                  <dropdown-item @click="onActivate" v-if="activateExists">Ввести в действие</dropdown-item>
                  <dropdown-item @click="onDeactivate" v-if="deactivateExists">Вывести из действия</dropdown-item>
                  <dropdown-divider v-if="activateExists || deactivateExists"></dropdown-divider>
                  <dropdown-item @click="onCopy" v-if="selectedRow">Копировать</dropdown-item>
                  <dropdown-item @click="onPaste" v-if="deactivatePaste">Вставить</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Связь
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="tripTypesLink">Типы рейсов</dropdown-item>
                  <dropdown-item router-link :to="depotsLink">Предприятия на маршруте</dropdown-item>
                  <dropdown-item router-link :to="tripTypeZonesLink">Зоны посадки-высадки</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item router-link :to="scheduleVariantsLink">Варианты расписаний</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item router-link :to="routeVariantDocsLink">Документы</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <route-variants-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :rows="rows"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></route-variants-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <route-variant-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </route-variant-dialog>

  <!-- Установка статуса -->
  <route-variant-status-dialog
      v-if="isStatusDialogVisible"
      :item="selectedRow"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </route-variant-status-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить вариант маршрута с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Подтверждение ввода в действие -->
  <confirm-message-box
      v-if="isActivateConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >Вы уверены, что хотите ввести в действие вариант маршрута с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Подтверждение вывода из действия -->
  <confirm-message-box
      v-if="isDeactivateConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >Вы уверены, что хотите вывести из действия вариант маршрута с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Диалог со списком ошибок -->
  <errors-with-status-dialog
      v-if="isErrorsDialogVisible"
      :rowList="errors"
      @onClose="errors=[]"
      @onContinue="onContinue"
      caption="Ошибки варианта маршрута"
  >
  </errors-with-status-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import RouteVariantsEditTable from "@/components/multipass/route_variants/RouteVariantsEditTable";
import RouteVariantDialog from "@/components/multipass/route_variants/RouteVariantDialog";
import RouteVariantStatusDialog from "@/components/multipass/route_variants/RouteVariantStatusDialog";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";
import store from "@/store/Store";

export default {
  mixins: [PageMixin],

  components: {
    RouteVariantStatusDialog,
    RouteVariantsEditTable,
    RouteVariantDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрута
    "route_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // маршрут
      route: {},
      // список вариантов маршрута
      rows: [],
      // список ошибок варианта маршрута
      errors: [],
      // тип объекта для определения активности кнопки вставить
      objType: 'RouteVariant',
      // значение статуса (заполняется, для повторного вызова команды)
      route_variant_status_id: null
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add' || this.op === 'paste' || this.op === 'change') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения диалогового окна установки статуса
    isStatusDialogVisible() {
      return (this.op === 'status' && !!this.selectedRow)
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // условие отображения подтверждения ввода в действие
    isActivateConfirmVisible() {
      return this.op === 'activate' && !!this.selectedRow;
    },

    // условие отображения подтверждения вывода из действия
    isDeactivateConfirmVisible() {
      return this.op === 'deactivate' && !!this.selectedRow;
    },

    // условие отключения кнопки - Команды
    commandDisabled() {
      return (!this.selectedRow && !this.deactivatePaste);
    },

    // условие отображения команды Установить статус
    setStatusExists() {
      return this.selectedRow
    },

    // условие отображения меню Ввести в действие
    activateExists() {
      return this.selectedRow && !this.selectedRow.is_working
    },

    // условие отображения меню Вывести из действия
    deactivateExists() {
      return this.selectedRow && !!this.selectedRow.is_working
    },

    // условие отображения кнопки "вставить"
    // если тип объекта в буфере обмена совпадает с типом объекта на текущей странице
    deactivatePaste() {
      return (store.getters.getType == this.objType);
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // ссылка для перехода к типам рейса
    tripTypesLink() {
      if (this.selectedRow) return `/multipass/routes/${this.route_id}/routeVariants/${this.selectedRow.route_variant_id}/tripTypes`
      else return '';
    },

    // ссылка для перехода к предприятиям на маршруте
    depotsLink() {
      if (this.selectedRow) return `/multipass/routes/${this.route_id}/routeVariants/${this.selectedRow.route_variant_id}/depots`
      else return '';
    },

    // ссылка для перехода к вариантам расписаний
    scheduleVariantsLink() {
      if (this.selectedRow) return `/rmt/routes/${this.route_id}/routeVariants/${this.selectedRow.route_variant_id}/scheduleVariants`
      else return '';
    },

    // ссылка для перехода к доументам
    routeVariantDocsLink() {
      if (this.selectedRow) return `/multipass/routes/${this.route_id}/routeVariants/${this.selectedRow.route_variant_id}/routeVariantDocs`
      else return '';
    },

    // ссылка для перехода к зонам посадки-высадки
    tripTypeZonesLink() {
      if (this.selectedRow) return `/multipass/routes/${this.route_id}/routeVariants/${this.selectedRow.route_variant_id}/tripTypeZones`
      else return '';
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {}
      } else return this.selectedRow;
    },

// условие отображения диалога со списком ошибок
    isErrorsDialogVisible() {
      if (this.errors.length > 0) {
        for(let i = 0; i < this.errors.length; i++) {
          if (this.errors[i].error_status == 'ALARM') {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },

  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/add`);
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/edit/${this.selectedRow.route_variant_id}`);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/del/${this.selectedRow.route_variant_id}`);
    },

    // нажата кнопка Просмотр
    onTableShow() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/show/${this.selectedRow.route_variant_id}`);
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants`);
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item);
      } else if (this.op === 'edit') {
        this.editItem(item);
      } else if (this.op === 'status') {
        this.setItemStatus(item);
      } else if (this.op === 'activate') {
        this.activateItem(item);
      } else if (this.op === 'deactivate') {
        this.deactivateItem(item);
      } else if (this.op === 'paste') {
        this.pasteItem(item);
      } else if (this.op === 'change') {
        this.changeItem(item);
      }
      // возвращаемся на страницу
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants`);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    onChangeEndDate() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/change/${this.selectedRow.route_variant_id}`);
    },

    // команда установить статус
    onSetStatus() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/status/${this.selectedRow.route_variant_id}`);
    },

    // команда ввести в действие варианта маршрута
    onActivate() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/activate/${this.selectedRow.route_variant_id}`);
    },

    // команда вывести из действия варианта маршрута
    onDeactivate() {
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/deactivate/${this.selectedRow.route_variant_id}`);
    },

    // нажата кнопка "копировать"
    onCopy() {
      // сохраняем type и выбранную строку в store
      store.dispatch('doSetClipboard', {
        type: 'RouteVariant',
        object: this.selectedRow
      });

      // выводим сообщение о том, что данные сохранены в буфер обмена
      store.dispatch('doAddMessage', {
        caption: 'Вариант маршрута',
        message: 'Данные сохранены в буфер обмена',
        type: 'ok'
      }, {root: true});
    },

    // нажата кнопка "вставить"
    onPaste() {
      // получаем из стора сохраненный объект
      this.selectedRow = store.getters.getObject;
      // открываем диалог копирования (редактирования)
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants/paste`);
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace(`/multipass/routes/${this.route_id}/routeVariants`);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('multipass/doFetchTariffTypes')
        await this.$store.dispatch('multipass/doFetchTariffZoneSystems')
        await this.$store.dispatch('ref/doFetchOrganizations')
        await this.$store.dispatch('multipass/doFetchRouteVariantStatuses')

        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        });
        if (!route) return;
        this.route = route;

        const rows = await this.$store.dispatch('multipass/doFetchRouteVariants', {
          route_id: this.route_id,
          force: true
        });
        if (!rows) return;
        this.rows = rows;

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        item.route_id = Number(this.route_id);
        // запрашиваем сервер
        const newItem = await this.$store.dispatch('multipass/doAddRouteVariant', item);
        // мутируем массив
        this.selectedRow = addMutation(this.rows, 'route_variant_id', newItem);

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        item.route_id = Number(this.route_id);
        // запрашиваем сервер
        const newItem = await this.$store.dispatch('multipass/doEditRouteVariant', item);
        // мутируем массив
        this.selectedRow = editMutation(this.rows, 'route_variant_id', newItem);

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем дату окончания действия варианта маршрута
    async changeItem(item) {
      this.isLoading = true;
      try {
        item.route_id = Number(this.route_id);
        // запрашиваем сервер
        const newItem = await this.$store.dispatch('multipass/doChangeRouteVariant', item);
        // мутируем массив
        this.selectedRow = editMutation(this.rows, 'route_variant_id', newItem);

      } finally {
        this.isLoading = false;
      }
    },

    // устанавливаем статус
    async setItemStatus(item) {
      this.isLoading = true;
      try {
        // запрашиваем сервер
        // после установки статуса получаем ошибки
        let errors = await this.$store.dispatch('multipass/doSetRouteVariantStatus', item);

        // если массив ошибок не пуст
        // отображаем их в соответствующем диалоге
        if (errors.length > 0) {
          this.errors = errors;
          this.route_variant_status_id = item.route_variant_status_id;
        } else {
          // в случае, если ошибок нет, делаем запрос на сервер для получения обновленного значения статуса
          // получаем "обновленный" вариант маршрута
          const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
            route_variant_id: this.selectedRow.route_variant_id
          });
          // мутируем массив
          this.selectedRow = editMutation(this.rows, 'route_variant_id', routeVariant);
          // отображаем сообщение об успешном выполнении операции
          await store.dispatch('doAddMessage', {
            caption: 'Установка статуса варианта маршрута',
            message: 'Команда успешно выполнена',
            type: 'ok'
          }, {root: true})
        }
      } finally {
        this.isLoading = false;
      }
    },

    // если не смотря на предупреждения пользователь нажал "продолжить"
    // отправляем повторный запрос с force == true
    async onContinue() {
      let routeVariant = null;
      // список ошибок
      let errors = [];

      if (this.route_variant_status_id == 'activate') {
        // активируем повторно
        let item = {
          route_variant_id: this.selectedRow.route_variant_id,
          force: true,
        }
        errors = await this.$store.dispatch('multipass/doActivateRouteVariant', item)
        this.route_variant_status_id = null;
      } else if (this.route_variant_status_id == 'deactivate') {
        // деактивируем повторно
        let item = {
          route_variant_id: this.selectedRow.route_variant_id,
          force: true,
        }
        errors = await this.$store.dispatch('multipass/doDeactivateRouteVariant', item)
        this.route_variant_status_id = null;
      } else {
        let item = {
          route_variant_id: this.selectedRow.route_variant_id,
          route_variant_status_id: this.route_variant_status_id,
          force: true,
        }
        // запрос на сервер
        errors = await this.$store.dispatch('multipass/doSetRouteVariantStatus', item);
      }
      // параметр, который задает отображать ли окно с ошибками повторно
      let isErrorsDialogShowing = 0;
      // проверяем есть в вернувшемся массиве ошибки, если да, выводим сообщение повторно
      for (let i = 0; i < errors.length; i++) {
        if (errors[i].error_status == 'ALARM') {
          isErrorsDialogShowing = 1;
          break;
        }
      }
      if (isErrorsDialogShowing == 1) {
        this.errors = errors;
      } else {
        //делаем запрос на сервер для получения обновленного значения статуса
        // получаем "обновленный вариант маршрута"
        routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.selectedRow.route_variant_id
        });
        // мутируем массив
        this.selectedRow = editMutation(this.rows, 'route_variant_id', routeVariant);
        // если ошибок нет, следовательно можно очистить массив сохраненных ошибок
        // и сохраненный статус
        this.errors = [];
        this.route_variant_status_id = null;
      }
    },

    // вводим в действие вариант маршрута
    async activateItem() {
      this.isLoading = true;
      try {

        // запрашиваем сервер
        let errors = await this.$store.dispatch('multipass/doActivateRouteVariant', {
          route_variant_id: this.selectedRow.route_variant_id,
          force: false
        });

        // если массив ошибок не пуст
        // отображаем их в соответствующем диалоге
        if (errors.length > 0) {
          this.errors = errors;
          this.route_variant_status_id = 'activate';
        } else {
          // в случае, если ошибок нет
          // получаем "обновленный" вариант маршрута
          const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
            route_variant_id: this.selectedRow.route_variant_id
          });
          // мутируем массив
          this.selectedRow = editMutation(this.rows, 'route_variant_id', routeVariant);
          // отображаем сообщение об успешном выполнении операции
          await store.dispatch('doAddMessage', {
            caption: 'Установка статуса варианта маршрута',
            message: 'Команда успешно выполнена',
            type: 'ok'
          }, {root: true})
        }
      } finally {
        this.isLoading = false;
      }
    },

    // выводим из действия вариант маршрута
    async deactivateItem() {
      this.isLoading = true;
      try {

        // запрашиваем сервер
        let errors = await this.$store.dispatch('multipass/doDeactivateRouteVariant', {
          route_variant_id: this.selectedRow.route_variant_id
        });

        // если массив ошибок не пуст
        // отображаем их в соответствующем диалоге
        if (errors.length > 0) {
          this.errors = errors;
          this.route_variant_status_id = 'deactivate';
        } else {
          // в случае, если ошибок нет, делаем запрос на сервер для ввода в действие варианта маршрута
          // получаем "обновленный" вариант маршрута
          const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
            route_variant_id: this.selectedRow.route_variant_id
          });
          // мутируем массив
          this.selectedRow = editMutation(this.rows, 'route_variant_id', routeVariant);
          // отображаем сообщение об успешном выполнении операции
          await store.dispatch('doAddMessage', {
            caption: 'Установка статуса варианта маршрута',
            message: 'Команда успешно выполнена',
            type: 'ok'
          }, {root: true})
        }
      } finally {
        this.isLoading = false;
      }
    },

    // добавляем объект при вставке
    async pasteItem(item) {
      this.isLoading = true;
      try {
        // сетаем текущий rote_id
        item.route_id = Number(this.route_id);
        // запрашиваем сервер
        const copiedItem = await this.$store.dispatch('multipass/doCopyRouteVariant', item);
        // мутируем массив
        this.selectedRow = addMutation(this.rows, 'route_variant_id', copiedItem);
      } finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрашиваем сервер
        const oldItem = await this.$store.dispatch('multipass/doDelRouteVariant', this.selectedRow);
        // мутируем массив
        this.selectedRow = delMutation(this.rows, 'route_variant_id', oldItem);

      } finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
