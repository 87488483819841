<template>

  <align-container>

    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/kvr">КВР</router-link></span>
        <span> / </span>
        <span>Мониторинг</span>
      </page-header>
    </align-panel>
    <align-panel align="all">

      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <template #leftCustomButton>
              <p style="margin-bottom: 0px; margin-top: 6px">Дата наряда</p>
            </template>

            <!--        Выбор даты наряда  -->
            <template #leftCustomButton2>
              <input-date
                  id="startDate"
                  v-model="selectedOrderDate"
                  class="form-control"
                  style="max-width: 150px"
                  @change="downloadWorkTasks"
              >
              </input-date>
            </template>

            <!--            кнопка "Информация"-->
            <template #leftCustomButton3>
              <dropdown>
                <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                :disabled="linkDisabled">Информация
                </button-toggler>
                <dropdown-menu ref="collapse">
                  <dropdown-item router-link :to="informationByRouteLink" @click="setDate">Информация по маршруту</dropdown-item>
                  <dropdown-item router-link :to="informationByTsLink" @click="setDate">Информация по ТС</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>
        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <work-tasks-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
          ></work-tasks-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import {dateToND, dateToString, quickSort, workTaskSortFn} from "@/lib";
import WorkTasksEditTable from "@/components/kvr/work_tasks/WorkTasksEditTable";
import {getRoute} from "@/store/KvrHelpers";


export default {
  mixins: [PageMixin],

  components: {
    WorkTasksEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // выбранная дата
      selectedOrderDate: dateToString(),
      // Задания на работу
      workTasks: [],
    }
  },

  computed: {

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // переход к информации по ТС
    informationByTsLink() {
      if (this.selectedRow) return '/kvr/workTasks/' + dateToND(new Date(this.selectedOrderDate)) + '/' + this.selectedRow.transport_id + '/informationByTs';
      return '';
    },

    // переход к информации по Маршруту
    informationByRouteLink() {
      if (this.selectedRow) return '/kvr/workTasks/' + dateToND(new Date(this.selectedOrderDate)) + '/' + this.selectedRow.route_id + '/informationByRoute';
      return '';
    },

  },

  methods: {

    // загрузка заданий на работу
    async downloadWorkTasks() {
      try {
        this.isLoading = true;
        this.selectedRow = null;

        let rows = await this.$store.dispatch('kvr/doFetchWorkTasks', {
          date_index: dateToND(new Date(this.selectedOrderDate)),
          force: true,
        });
        if (rows){
          this.workTasks = rows;
          this.workTasks.map(workTask => {
            let route = getRoute(workTask.route_id);
            if(route) {
              workTask.__route_num = route.route_num;
              workTask.__reg_num = route.reg_num;
              workTask.__route_title = route.route_title;
            }
          })
          // сортируем массив
          quickSort(this.workTasks, (leftItem, rightItem) => {
            return 1 * workTaskSortFn(leftItem, rightItem);
          });
        }

      } finally {
        this.isLoading = false;
      }
    },

    // сохраняем даты в стор
    setDate() {
      this.$store.commit('kvr/setSelectedOrderDate', this.selectedOrderDate);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      this.refreshData();
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        let orderDate = this.$store.getters['kvr/getSelectedOrderDate'];
        if(orderDate) {
          this.selectedOrderDate = orderDate;
        }

        await this.$store.dispatch('ref/doFetchModels');
        await this.$store.dispatch('kvr/doFetchRoutes');
        await this.$store.dispatch('ref/doFetchTransportClasses');
        await this.$store.dispatch('ref/doFetchTransportTypes');
        await this.$store.dispatch('kvr/doFetchAgencies');
        await this.$store.dispatch('kvr/doFetchDepots');

        await this.downloadWorkTasks();

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

