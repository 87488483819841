<template>

  <accordion mode="single">

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group1">
          Основные
        </button-target>
      </card-header>
    </card>
    <collapse ref="group1" active>
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/nav/radios"
          >Радиостанции</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/nav/radioModels"
          >Модели радиостанций</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/nav/map"
          >Карта</left-menu-item>
        </li>
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group2">
          Администрирование
        </button-target>
      </card-header>
    </card>
    <collapse ref="group2">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/nav/navDayStates"
          >Данные
          </left-menu-item>
        </li>
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group3">
          Дополнительные
        </button-target>
      </card-header>
    </card>
    <collapse ref="group3">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/nav/sensors"
          >Датчики</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/nav/sensorGroups"
          >Группы датчиков</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/nav/params"
          >Измеряемые параметры</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/nav/paramGroups"
          >Группы измеряемых параметров</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/nav/radioProtocols"
          >Эфирные протоколы</left-menu-item>
        </li>
      </ul>
    </collapse>

  </accordion>
</template>
