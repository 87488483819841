<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/ref">Справочники</router-link></span>
        <span> / </span>
        <span>Работники транспортных предприятий</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка Команда-->
            <template #leftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                :disabled="commandDisabled">
                  Команда
                </button-toggler>
                <dropdown-menu ref="collapse">
                  <dropdown-item @click="onDiscardPersonnel" v-if="discardPersonnel">Исключить работника
                  </dropdown-item>
                  <dropdown-item @click="onRestorePersonnel" v-if="restorePersonnel">Восстановить работника
                  </dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">Связь</button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="personnelStatesLink">Состояния</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Фильтр профессий-->
            <template #leftCustomButton3>
              <personnel-jobs-select class="form-control" v-model="personnelJobId"></personnel-jobs-select>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <personnel-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :personnelJobId="personnelJobId"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></personnel-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <personnel-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </personnel-dialog>

  <!-- Подтверждение удаления -->
  <personnel-del-dialog
      :id="id"
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  ></personnel-del-dialog>

  <!-- Подтверждение исключения -->
  <personnel-discard-dialog
      :id="id"
      v-if="isDiscardConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDiscard"
  ></personnel-discard-dialog>

  <!-- Подтверждение восстановления -->
  <confirm-message-box
      v-if="isRestoreConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmRestore"
  >Вы уверены, что хотите восстановить работника с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>
  
</template>

<script>
import PersonnelEditTable from "@/components/ref/personnel/PersonnelEditTable";
import PersonnelJobsSelect from "@/components/ref/personnel_jobs/PersonnelJobsSelect";
import PersonnelDialog from "@/components/ref/personnel/PersonnelDialog";
import PersonnelDelDialog from "@/components/ref/personnel/PersonnelDelDialog";
import PageMixin from "@/pages/share/PageMixin";
import PersonnelDiscardDialog from "@/components/ref/personnel/PersonnelDiscardDialog";

export default {
  mixins: [PageMixin],

  components: {
    PersonnelDiscardDialog,
    PersonnelDelDialog,
    PersonnelDialog,
    PersonnelJobsSelect,
    PersonnelEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op", 
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // код профессии
      personnelJobId: 1,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }    
  },

  computed: {
      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            add: true, 
            edit: !!this.selectedRow, 
            delete: !!this.selectedRow, 
            refresh: true
          }
      },

      // условие отображения диалогового окна
      isDialogVisible() {
          return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
      },

      // условие отображения подтверждения удаления
      isDelConfirmVisible() {
          return this.op === 'del' && !!this.selectedRow;
      },

      // ссылка для перехода к состояниям работников
      personnelStatesLink() {
        if (this.selectedRow) return '/ref/personnel/' + this.selectedRow.personnel_id + '/personnelStates';
        else return '';
      },

      // условие отображения подтверждения исключения
      isDiscardConfirmVisible() {
        return this.op === 'discard' && !!this.selectedRow;
      },

      // условие отображения подтверждения восстановления
      isRestoreConfirmVisible() {
        return this.op === 'restore' && !!this.selectedRow;
      },

      // условие отключения кнопки - Команды
      commandDisabled() {
        return !this.setStatusExists;
      },

      // условие отображения команды Установить статус
      setStatusExists() {
        return this.selectedRow;
      },

      // условие отображения меню исключить работника
      discardPersonnel() {
        return this.selectedRow && !this.selectedRow.date_index_end
      },

      // условие отображения меню восстановить работника
      restorePersonnel() {
        return this.selectedRow && !!this.selectedRow.date_index_end
      },

      // условие отключения кнопки - Связь
      linkDisabled() {
        return !this.selectedRow;
      },

      // данные, которые отображаются в диалоговом окне
      dialogRow() {
        return this.op === 'add' ? {
          personnel_job_id: 1,
          transport_type_id_list: [],
        } : this.selectedRow;
      },
  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/ref/personnel/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/ref/personnel/edit/" + this.selectedRow.personnel_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/ref/personnel/del/" + this.selectedRow.personnel_id);
    },

    // нажата конпка "Просмотр"
    onTableShow() {
      this.$router.replace("/ref/personnel/show/" + this.selectedRow.personnel_id);
    },

    // нажата кнопка Исключить работника
    onDiscardPersonnel() {
      this.$router.replace("/ref/personnel/discard/" + this.selectedRow.personnel_id);
    },

    // нажата конпка Восстановить работника
    onRestorePersonnel() {
      this.$router.replace("/ref/personnel/restore/" + this.selectedRow.personnel_id);
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/ref/personnel");
    },

    // подтверждено исключение
    onConfirmDiscard(item) {
      // списываем элемент
      this.discardItem(item);
      // возвращаемся на страницу
      this.$router.replace("/ref/personnel");
    },

    // подтверждено восстановление
    onConfirmRestore() {
      // восстанавлмиваем элемент
      this.restoreItem();
      // возвращаемся на страницу
      this.$router.replace("/ref/personnel");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/ref/personnel");
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/ref/personnel");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchDepots');
        await this.$store.dispatch('ref/doFetchPersonnelJobs');
        await this.$store.dispatch('ref/doFetchPersonnel', { force });
        await this.$store.dispatch('ref/doFetchTransportTypes');

      }
      finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('ref/doAddPersonnel', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('ref/doEditPersonnel', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('ref/doDelPersonnel', this.selectedRow);
        this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // Исключаем объект
    async discardItem(item) {
      this.isLoading = true;
      try {

        await this.$store.dispatch('ref/doDiscardPersonnel', item);
        this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // Восстанавливаем объект
    async restoreItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('ref/doRestorePersonnel', this.selectedRow);
        this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

