export default {
    state() {
        return {
            // счетчик сообщений
            messageCounter: 4,
            // максимальное количество сообщений
            maxMessageCount: 5,
            // список сообщений
            messages: [
                // {id: 1, caption: 'Заголовок', message: 'Тело сообщения', type: 'ok'},
                // {id: 2, caption: 'Заголовок 2', message: 'Тело сообщения 2', type: 'error'},
                // {id: 3, caption: 'Заголовок 3', message: 'Тело сообщения 3', type: 'info'}
            ]
        }
    },
    getters: {
        // вернуть список сообщений
        getMessages(state) {
            return state.messages;
        }
    },
    mutations: {
        // добавляет сообщение к списку
        addMessage(state, payload) {
            state.messages.push(payload);
        },
        // удаляет сообщение из списка
        delMessage(state, payload) {
            const id = payload.id;
            state.messages = state.messages.filter(msg => msg.id !== id);
        }
    },
    actions: {
        // добавляет сообщение к списку
        doAddMessage(context, payload) {

            // удаляем лишнее сообщение
            if (context.state.messages.length >= context.state.maxMessageCount) {
                context.dispatch('doDelMessage', {
                    id: context.state.messages[0].id
                });
            }

            // добавляем новое сообщение
            const id = context.state.messageCounter++;
            // в зависимости от типа назначаем время жизни
            let timeOut = 20000;
            if (payload.type === 'ok') {
                timeOut = 1500;
            }
            else
            if (payload.type === 'info') {
                timeOut = 3000;
            }
            payload.id = id;
            context.commit('addMessage', payload);
            payload.timer = setTimeout(()=>{
                context.commit('delMessage', {
                    id
                })
            }, timeOut);
        },
        // удаляет сообщение из списка
        doDelMessage(context, payload) {
            const id = payload.id;
            const msg =  context.state.messages.find(msg => msg.id === id);
            if (msg) {
                if (msg.timer) {
                    clearTimeout(msg.timer);                    
                }
                context.commit('delMessage', payload);
            }            
        }
    }
}
