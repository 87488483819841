<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/ref">Справочники</router-link></span>
        <span> / </span>
        <span><router-link to="/ref/transportGroups">Группы транспортных средств</router-link></span>
        <span> / </span>
        <span>Транспортные средства в группе {{ transportGroupPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{check: true, uncheck: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onCheck="onTableCheck"
              @onUncheck="onTableUncheck"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          ></crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <group-transport-links-edit-table
              :id="id"
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
          ></group-transport-links-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import GroupTransportLinksEditTable
  from "@/components/ref/transport_group_links/GroupTransportLinksEditTable";

import {getTransportGroup} from "@/store/RefHelpers";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    GroupTransportLinksEditTable,
  },

  props: [
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false
    }    
  },

  computed: {
      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            check: !!this.transportGroup && !!this.selectedRow && !this.selectedRow.__checked,
            uncheck: !!this.transportGroup &&  !!this.selectedRow && this.selectedRow.__checked,
            refresh: true
          }
      },

      // текущая группа
      transportGroup() {
        return getTransportGroup(this.id);
      }
  },
  methods: {
    // нажата кнопка Включить
    onTableCheck() {
      this.linkObjects();
    },

    // нажата кнопка Выключить
    onTableUncheck() {
      this.unlinkObjects();
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchTransport');
        await this.$store.dispatch('ref/doFetchTransportGroups');
        await this.$store.dispatch('ref/doFetchTransportTypes')
        await this.$store.dispatch('ref/doFetchTransportClasses')
        await this.$store.dispatch('ref/doFetchModels')
        await this.$store.dispatch('ref/doFetchDepots')
        await this.$store.dispatch('ref/doFetchTransportGroupLinks', { force });

      }
      finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // связываем объекты
    async linkObjects() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('ref/doAddTransportToGroup', {
          transport_group_id: this.transportGroup.transport_group_id,
          transport_id: this.selectedRow.transport_id
        })

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем связь объектов
    async unlinkObjects() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('ref/doDelTransportFromGroup', {
          transport_group_id: this.transportGroup.transport_group_id,
          transport_id: this.selectedRow.transport_id
        })

      }
      finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
