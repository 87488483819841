<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" :disableSettings="true"
                    :rowsPerPage="100000" name="tbl-kvr-work-task-cpnav"></edit-table-panel>
</template>

<script>

import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {secondsToTime} from "@/lib";

export default {
  components: {EditTablePanel},
  props: ['rows', 'cpnav_id_list', 'isReisOnly'],
  data(){
    return {
      // колонки
      columnList: [
        {
          field: 'stop_title',
          caption: 'Остановка',
          sortType: 'String',
          isFilterable: true,
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'time_index_in',
          caption: 'Въезд',
          sortType: '',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerAlign: 'center',
          dataAlign: 'center',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.time_index_in) {
              return secondsToTime(row.time_index_in);
            }
            return ''
          },
        },
        {
          field: 'time_index_out',
          caption: 'Выезд',
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerAlign: 'center',
          dataAlign: 'center',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.time_index_out) {
              return secondsToTime(row.time_index_out);
            }
            return ''
          },
        },
        {
          field: 'stop_id',
          caption: 'Код',
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__id',
          caption: 'Код отм.',
          sortType: 'number',
          isFilterable: false,
          isSortable: false,
          show: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        }
      ]
    }
  },

  watch:{

    cpnav_id_list(){

    }
  },
  computed:{

    rowList(){

      let arr = [];

      this.rows.map(cpNav =>{
        //  если стоит галочка "Только принадлежащие рейсу"
        if(this.isReisOnly == true){
          // то возвращаем только принадлежащие рейсу отметки
          if(this.cpnav_id_list.includes(cpNav.cpnav_id)) {
            arr.push(cpNav);
          }
          //  если галочка не стоит, то возвращаем все
        } else {
          arr.push(cpNav);
        }
      })

      return arr.map(cpNav =>{
        cpNav.__id = cpNav.cpnav_id;
        cpNav.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px'
        };

        if(this.cpnav_id_list && this.cpnav_id_list.includes(cpNav.cpnav_id)) {
          cpNav.__rowStyle = {
            backgroundColor: this.$store.getters['settings/getProductionReisColor2']
          }
        } else {
          cpNav.__rowStyle = null;
        }
        return cpNav;
      });
    }

  }
}
</script>

<style scoped>

</style>