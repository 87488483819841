import {DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ НОРМ НА ИНТЕРВАЛЫ ДВИЖЕНИЯ

export default {
    actions: {

        // запрос списка объектов
        doFetchIntNormInts: (context, payload) => {
            return DoGet(
                '/rmt/intNormInts?schedule_variant_id=' + payload.schedule_variant_id,
                null,
                [],
                'Запрос норм на интервалы',
                false,
                true,
            )
        },

        // изменение объекта
        doEditIntNormInts: (context, payload) => {
            return DoPut(
                '/rmt/intNormInts/' + payload.schedule_variant_id,
                payload.norms,
                null,
                'Изменение норм на интервалы'
            )
        },

        // 	Очистить нормы на интервал
        doClearIntNormInt: (context, payload) => {
            return DoPut(
                '/rmt/clearIntNormInt/' + payload.schedule_variant_id,
                payload,
                null,
                'Очистка норм на интервалы'
            )
        },

        // 	Обновить нормы на интервал
        doUpdateIntNormInt: (context, payload) => {
            return DoPut(
                '/rmt/softClearIntNormInt/' + payload.schedule_variant_id,
                payload,
                null,
                'Обновление норм на интервалы'
            )
        },

        // 	проверка норм на интервал
        doCheckIntNormInt: (context, payload) => {
            return DoPut(
                '/rmt/checkIntNormInt/' + payload.schedule_variant_id,
                payload,
                null,
                'Проверка норм на интервалы',
                false,
            )
        },
    }
}
