import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ТИПОВ СВЯЗАННЫХ СУЩНОСТЕЙ

export default {
    state() {
        return {
            // список объектов
            entityTypes: [],
            // время обновления кэша new Date().getTime()
            entityTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            entityTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getEntityTypes(state) {
            return state.entityTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setEntityTypes(state, payload) {
            state.entityTypes = payload;
            state.entityTypesCache = new Date().getTime()
        },
    },
    actions: {

        // запрос списка объектов
        doFetchEntityTypes: (context, payload) => {
            return DoGet(
                '/integration/entityTypes',
                'integration/setEntityTypes',
                [],
                'Запрос типов связанных сущностей',
                false,
                payload && payload.force,
                context.state.entityTypesCache,
                context.state.entityTypesCacheTimeout
            )
        },

        // запрос списка объектов
        doFetchEntityType: (context, payload) => {
            return DoGet(
                '/integration/entityTypes/' + payload.entity_type_id,
                'integration/setEntityTypes',
                [],
                'Запрос типа связанных сущностей',
                false,
                payload && payload.force,
                context.state.entityTypesCache,
                context.state.entityTypesCacheTimeout
            )
        },
    }
}