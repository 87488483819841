<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header class="page-header">
        <span><router-link to="/rmt">Расписания</router-link></span>
        <span> / </span>
        <span><router-link to="/rmt/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to=routeVariantsLink>Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleVariantsLink>Варианты расписаний {{ routeVariantPageHeader }}</router-link></span>
        <span> / </span>
        <span>Нормы времени на движение {{ scheduleVariantPageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <align-panel align="left" width="30%" :gap="3">
          <align-container>
            <align-panel align="top">
              <!-- Форма с кнопками над левой таблицей -->
              <crud-form
                  :buttonsExist="leftPanelButtonsExist"
                  :buttonsEnabled="leftPanelButtonsEnabled"
                  :filterExists="false"
                  @onRefresh="onTableRefresh"
              >
                <!-- Кнопка команда-->
                <template #leftCustomButton>
                  <dropdown>
                    <button-toggler target-ref="collapse1" class="btn dropdown-toggle btn-primary">Команда</button-toggler>
                    <dropdown-menu ref="collapse1">
                      <dropdown-item @click="onClearTimeNormTime">Очистить нормы</dropdown-item>
                      <dropdown-divider></dropdown-divider>
                      <dropdown-item @click="onUpdateTimeNormTime">Обновить нормы</dropdown-item>
                    </dropdown-menu>
                  </dropdown>
                </template>

                <!-- Кнопка связь-->
                <template #leftCustomButton2>
                  <dropdown>
                    <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary">Связь
                    </button-toggler>
                    <dropdown-menu ref="collapse2">
                      <dropdown-item router-link :to="timeNormPeriodsLink">Периоды суток</dropdown-item>
                    </dropdown-menu>
                  </dropdown>
                </template>
              </crud-form>
            </align-panel>

            <!-- Левая таблица -->
            <align-panel align="all">
              <trip-types-edit-table
                  :tripTypes="tripTypes"
                  :selectedRow="selectedRow"
                  @onRowSelect="onRowSelect"
              ></trip-types-edit-table>
            </align-panel>
          </align-container>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <align-panel align="top">
              <!-- Форма с кнопками над правой таблице -->
              <crud-form
                  :buttonsExist="rightPanelButtonsExist"
                  :buttonsEnabled="rightPanelButtonsEnabled"
                  :filterExists="false"
                  @onEdit="onTableEdit"
                  @onCancel="onTableCancel"
                  @onSave="onTableSave"
                  @onRefresh="onRowSelect(selectedRow)"
              >
                <!-- Кнопка команда-->
                <template #leftCustomButton>
                  <dropdown>
                    <button-toggler target-ref="collapse3" class="btn dropdown-toggle btn-primary" :disabled="!selectedRow">Команда</button-toggler>
                    <dropdown-menu ref="collapse3">
                      <dropdown-item @click="onClearTimeNormTimeByTripType">Очистить нормы</dropdown-item>
                      <dropdown-divider></dropdown-divider>
                      <dropdown-item @click="onUpdateTimeNormTimeByTripType">Обновить нормы</dropdown-item>
                    </dropdown-menu>
                  </dropdown>
                </template>

                <template #rightCustomButton>
                  <input-button-option
                      v-model="normType"
                      :readonly="!selectedRow"
                      :itemList="[{id:0, title: 'Движение'}, {id:1, title: 'Стоянка'},]"
                      required
                  ></input-button-option>
                </template>
              </crud-form>
            </align-panel>

            <!-- Правая таблица  -->
            <align-panel align="all">
              <norms-edit-table
                  :columnList="normColumns"
                  :rowList="normRows"
                  :footer="footer"
              ></norms-edit-table>
            </align-panel>
            <align-panel align="bottom">
              <!-- Форма с кнопками под правой таблицей-->
              <card>
                <card-body class="p-1">
                  <div class="d-flex justify-content-center">
                    <input-button-option :itemList="showModeList" v-model="showMode" :readonly="!selectedRow" required></input-button-option>
                  </div>
                </card-body>
              </card>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Подтверждение очистки норм для типа рейса -->
  <confirm-message-box
      v-if="isClearTripTypeConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmNormCommand('clr', selectedRow.trip_type_id)"
  >Вы уверены, что хотите очистить нормы для типа рейса с кодом {{ selectedRow.trip_type_id }} ?
  </confirm-message-box>

  <!-- Подтверждение обновления норм для типа рейса -->
  <confirm-message-box
      v-if="isUpdateTripTypeConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmNormCommand('upd', selectedRow.trip_type_id)"
  >Вы уверены, что хотите обновить нормы для типа рейса с кодом {{ selectedRow.trip_type_id }} ?
  </confirm-message-box>

  <!-- Подтверждение очистки норм для варианта расписания -->
  <confirm-message-box
      v-if="isClearConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmNormCommand('clr')"
  >Вы уверены, что хотите очистить нормы для варианта расписания с кодом {{ schedule_variant_id }} ?
  </confirm-message-box>

  <!-- Подтверждение обновления норм для варианта расписаний-->
  <confirm-message-box
      v-if="isUpdateConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmNormCommand('upd')"
  >Вы уверены, что хотите обновить нормы для варианта расписания с кодом {{ schedule_variant_id }} ?
  </confirm-message-box>

  <!--    Окно с ошибкой   -->
  <error-message-box
      v-if="errorCode > 0"
      @onClose="$router.go(-1)"
  >

    <template v-if="errorCode === 1" #leftCustomButton>
      <button class="btn btn-success" @click="$router.push(timeNormPeriodsLink)">К периодам суток...</button>
    </template>
    <template v-else-if="errorCode === 2 || errorCode === 3" #leftCustomButton>
      <button class="btn btn-success" @click="$router.push(tripTypesLink)">К типам рейсов...</button>
    </template>
    <template v-else-if="errorCode === 4" #leftCustomButton>
      <button class="btn btn-danger" @click="onConfirmNormCommand('clr')">Очистить нормы...</button>
      <button class="btn btn-danger" @click="onConfirmNormCommand('upd')">Обновить нормы...</button>
    </template>

    {{ errorMessage }}
  </error-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import TripTypesEditTable from "@/components/rmt/time_norm_times/TripTypesEditTable";
import NormsEditTable from "@/components/rmt/time_norm_times/NormsEditTable";
import {getTimeFormat} from "@/lib";
import InputButtonOption from "@/components/ui/custom/InputButtonOption";
import PageMixin from "@/pages/share/PageMixin";
import {getStop} from "@/store/MultipassHelpers";

export default {
  mixins: [PageMixin],

  components: {
    InputButtonOption,
    NormsEditTable,
    TripTypesEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
    // идентификатор варианта расписания
    "schedule_variant_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // вариант расписания
      scheduleVariant: {},
      // периоды
      timePeriods: [],
      // типы рейсов
      tripTypes: [],
      // карточки рейса
      tripTypeCard: [],
      // нормы
      timeNormTimes: [],
      // строки для отображения в таблице
      normRows: [],
      // режим редактирования правой таблицы
      isEditMode: false,
      // тип норматива: норматив на движение (0) на стоянку (1)
      normType: 0,
      // код проверки
      errorCode: 0,
      // отображать спиннер
      isLoading: false,
      // активный элемент левой таблицы
      selectedRow: null,
      // режим отображения норм  (правой таблицы)
      showMode: 2,
      // список режимов отображения норм
      showModeList: [
        {id: 1, title: 'Нормы на рейс'},
        {id: 2, title: 'Нормы на КП'},
        {id: 3, title: 'Нормы на остановках'}
      ]
    }
  },

  computed: {

    // включенные кнопки для левой таблицы
    leftPanelButtonsExist() {
      return {
        delete: false,
        refresh: true,
      }
    },

    // активные кнопки для левой таблицы
    leftPanelButtonsEnabled() {
      return {
        delete: false,
        refresh: true,
      }
    },

    // включенные кнопки для правой таблицы
    rightPanelButtonsExist() {
      return {
        edit: !this.isEditMode,
        save: this.isEditMode,
        cancel: this.isEditMode,
        refresh: true,
      }
    },

    // активные кнопки для правой таблицы
    rightPanelButtonsEnabled() {
      return {
        edit: (!this.isEditMode && this.selectedRow),
        save: this.isEditMode,
        cancel: this.isEditMode,
        refresh: this.selectedRow,
      }
    },

    // ссылка для перехода к вариантам маршрутов
    routeVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants`
    },

    // ссылка для перехода к вариантам расписаний
    scheduleVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`;
    },

    // ссылка для перехода к типам рейсов
    tripTypesLink() {
      return `/multipass/routes/${this.route_id}/routeVariants/${this.route_variant_id}/tripTypes`;
    },

    // ссылка для перехода к периодам суток
    timeNormPeriodsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/timeNormPeriods`
    },

    // условие отображения подтверждения удаления норм по типу рейса
    isClearTripTypeConfirmVisible() {
      return this.op === 'clr' && !!this.id;
    },

    // условие отображения подтверждения удаления норм по типу рейса
    isUpdateTripTypeConfirmVisible() {
      return this.op === 'upd' && !!this.id;
    },

    // условие отображения подтверждения удаления норм по варианту расписания
    isClearConfirmVisible() {
      return this.op === 'clr' && !this.id;
    },

    // условие отображения подтверждения обновления норм по варианту расписания
    isUpdateConfirmVisible() {
      return this.op === 'upd' && !this.id;
    },

    // текст ошибки
    errorMessage() {
      if (this.errorCode === 1)
        return 'Периоды суток отсутствуют.';
      else if (this.errorCode === 2)
        return 'Типы рейсов отсутствуют.';
      else if (this.errorCode === 3)
        return 'Трасса одного из типов рейса отсутствует.';
      else if (this.errorCode === 4)
        return 'Текущая таблица норм не соответствует действительности.';
      else
        return '';
    },

    // колонки для правой таблицы
    normColumns() {
      let columns = [];

      // колонки под нормативы
      if (this.normType === 0) {
        // при указании норм времени
        columns = this.timePeriods.map((period, index) => {
          return {
            field: '__time_' + index,
            caption: getTimeFormat(period.time_begin) + " - " + getTimeFormat(period.time_end),
            isSortable: false,
            displayType: 'minute',
            dataAlign: 'center',
            headerAlign: 'center',
            inputStyle: {
              maxWidth: '5rem'
            },
            footerAlign: 'center',
            footerCellStyle: {
              color: 'white',
              backgroundColor: '#343a40',
              fontWeight: 'bold',
            }
          }
        });
      }
      else {
        // при указании норм на стоянки
        columns = this.timePeriods.map((period, index) => {
          return {
            field: '__stop_' + index,
            caption: getTimeFormat(period.time_begin) + " - " + getTimeFormat(period.time_end),
            isSortable: false,
            displayType: 'minute',
            dataAlign: 'center',
            headerAlign: 'center',
            footerAlign: 'center',
            inputStyle: {
              maxWidth: '5rem'
            },
            footerCellStyle: {
              color: 'white',
              backgroundColor: '#343a40',
              fontWeight: 'bold',
            }
          }
        });
      }

      // первая колонка - название остановоки
      columns.unshift({
        field: "__stationName",
        caption: 'Остановка',
        isSortable: false,
        sortType: 'string',
        footerCellStyle: {
          color: 'white',
          backgroundColor: '#343a40',
          fontWeight: 'bold',
        }
      });

      // последняя колонка - признак КП
      columns.push(
        {
          field: '__is_control_point',
          caption: 'КП',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          isSortable: false,
          displayFn(row) {
            if (row.__is_control_point) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          footerCellStyle: {
            color: 'white',
            backgroundColor: '#343a40',
            fontWeight: 'bold',
          }
        }
      );

      return columns;
    },

    // подвал таблицы
    footer() {
      if (!this.selectedRow || this.isLoading)
        return null
      else {
        const footer = {
          __stationName: 'Итого:',
        }

        // вычисляем сумму нормативов
        this.timePeriods.forEach((period, periodIndex) => {
          let timeSum = 0;
          let stopSum = 0;
          this.normRows.forEach((row) => {
            timeSum += row['__time_' + periodIndex] ? row['__time_' + periodIndex] : 0;
            stopSum += row['__stop_' + periodIndex] ? row['__stop_' + periodIndex] : 0;
          })
          footer['__time_' + periodIndex] = timeSum / 60;
          footer['__stop_' + periodIndex] = stopSum / 60;
        });

        return footer;
      }
    }
  },

  methods: {

    // вызывается, когда нажата кнопка Обновить
    onTableRefresh() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // перезапрашиваются данные
      this.refreshData();
    },

    // вызывается при выделении строки
    onRowSelect(item) {
      // определяем активный элемент таблицы
      this.selectedRow = item;
      // запрашиваем данные по типу рейса
      this.refreshTripType();
    },

    // вызывается, когда таблица переходит в режим реактирования
    onTableEdit() {
      this.isEditMode = true;
    },

    // вызывается при сохранении изменений норм
    onTableSave() {
      // выходим из режима редактирования
      this.isEditMode = false;
      // сохраняем данные на сервер
      this.saveTimeNormTimes();
    },

    // вызывается при отмене внесенных изменений
    onTableCancel() {
      // выходим из режима редактирования
      this.isEditMode = false;
      // возвращаем строки в исходное состояние
      this.updateNormRows();
    },

    // отменяем операцию
    onRejectOperation() {
      this.$router.replace('/rmt/routes/' + this.route_id + '/routeVariants/' + this.route_variant_id + '/scheduleVariants/' + this.schedule_variant_id + '/timeNormTimes');
    },

    // вызывается при обновлении норм по варианту расписания
    onUpdateTimeNormTime() {
      this.$router.replace('/rmt/routes/' + this.route_id + '/routeVariants/' + this.route_variant_id + '/scheduleVariants/' + this.schedule_variant_id + '/timeNormTimes/upd');
    },

    // вызывается при очистки норм по варианту расписания
    onClearTimeNormTime() {
      this.$router.replace('/rmt/routes/' + this.route_id + '/routeVariants/' + this.route_variant_id + '/scheduleVariants/' + this.schedule_variant_id + '/timeNormTimes/clr');
    },

    // вызывается при обновлении норм по типу рейса
    onUpdateTimeNormTimeByTripType() {
      this.$router.replace('/rmt/routes/' + this.route_id + '/routeVariants/' + this.route_variant_id + '/scheduleVariants/' + this.schedule_variant_id + '/timeNormTimes/upd/' + this.selectedRow.trip_type_id);
    },

    // вызывается при очистки норм по типу рейса
    onClearTimeNormTimeByTripType() {
      this.$router.replace('/rmt/routes/' + this.route_id + '/routeVariants/' + this.route_variant_id + '/scheduleVariants/' + this.schedule_variant_id + '/timeNormTimes/clr/' + this.selectedRow.trip_type_id);
    },

    // подтверждение команды очистки или обновления норм
    async onConfirmNormCommand(op, trip_type_id) {
      // возвращаемся на страницу
      this.$router.replace('/rmt/routes/' + this.route_id + '/routeVariants/' + this.route_variant_id + '/scheduleVariants/' + this.schedule_variant_id + '/timeNormTimes',);
      // запускаем команду
      if (trip_type_id) {
        // очистка норм по типу рейса
        if (op === 'clr') {
          // выходим из режима редактирования
          this.isEditMode = false;
          // отправляем команду на серверу
          await this.clearTimeNormTimeByTripType();
        }
        // обновление норм по типу рейса
        else {
          // выходим из режима редактирования
          this.isEditMode = false;
          // отправляем команду на серверу
          await this.updateTimeNormTimeByTripType();
        }
      }
      else {
        // очистка норм по варианту расписания
        if (op === 'clr') {
          // выходим из режима редактирования
          this.isEditMode = false;
          // отправляем команду на серверу
          await this.clearTimeNormTime();
          // обновляем данные
          await this.refreshData();
        }
        // обновление норм по варианту расписания
        else {
          // выходим из режима редактирования
          this.isEditMode = false;
          // отправляем команду на серверу
          await this.updateTimeNormTime();
          // обновляем данные
          await this.refreshData();
        }
      }
    },

    // формирует и возвращает результирующую таблицу для сохранения
    createResultNormTable() {
      const rows = [];
      this.tripTypeCard.forEach((cardItem, itemIndex) => {
        this.timePeriods.forEach((period, periodIndex) => {
          rows.push({
            time_norm_period_id: period.time_norm_period_id,
            trip_type_id: cardItem.trip_type_id,
            trip_type_order_num: cardItem.order_num,
            time_norm: this.normRows[itemIndex]['__time_' + periodIndex],
            stop_norm: this.normRows[itemIndex]['__stop_' + periodIndex],
          });
        })
      })

      return rows;
    },

    // возвращает норматив для указанного периуда суток и остановки рейса
    getTimeNorm(period, cardItem) {
      return this.timeNormTimes.find(norm => {
        return norm.time_norm_period_id === period.time_norm_period_id &&
            norm.trip_type_id === cardItem.trip_type_id &&
            norm.trip_type_order_num === cardItem.order_num;
      })
    },

    // обновляет список строк таблицы
    updateNormRows() {
      // если строка не выбрана - очищаем таблицу
      if (!this.selectedRow) {
        this.normRows = [];
        return
      }

      const rows = [];
      this.tripTypeCard.forEach((item, itemIndex) => {
        const stop = getStop(item.stop_id);

        // заполняем название остановки и признак КП
        const row = {
          __stationName: stop && stop.long_name || '',
          __is_control_point: item.is_control_point,
        }

        // заполняем времена нормативов
        this.timePeriods.forEach((period, periodIndex) => {
          const norm = this.getTimeNorm(period, item);
          row['__time_' + periodIndex] = norm ? norm.time_norm : null;
          row['__stop_' + periodIndex] = norm ? norm.stop_norm : null;
        });

        // режим редактирования выключен
        if (!this.isEditMode) {
          // только для чтения
          row["__inputAttrs"] = {
            readonly: true,
          };
          // серый цвет фона
          row["__inputStyle"] = {
            backgroundColor: '#efefef'
          };
        }
        else
        // включен режим на рейс - оставляем для редактирования только первую строчку
        if (this.showMode === 1 && itemIndex > 0) {
          // только для чтения
          row["__inputAttrs"] = {
            readonly: true,
          };
          // серый цвет фона
          row["__inputStyle"] = {
            backgroundColor: '#efefef'
          };
        }
        else
        // включен режим по КП - оставляем для редатирования только контрольные пункты
        if (this.showMode === 2 && !item.is_control_point) {
          // только для чтения
          row["__inputAttrs"] = {
            readonly: true,
          };
          // серый цвет фона
          row["__inputStyle"] = {
            backgroundColor: '#efefef'
          };
        }

        rows.push(row);
      })

      // формируем строки таблицы
      this.normRows = rows;
    },

    // загружаем с сервер основные данные
    async refreshData() {
      this.isLoading = 'fetch';
      try {
        // сбрасываем данные в правой таблице
        this.tripTypeCard = [];
        this.normRows = [];
        this.timeNormTimes = [];

        // загружаем список остановок
        await this.$store.dispatch('multipass/doFetchStops');

        // запрос маршрута
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        }); if (!route) return;
        this.route = route;

        // запрос варианта маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id,
        }); if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // запрос варианта расписания
        const scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
          schedule_variant_id: this.schedule_variant_id,
        }); if (!scheduleVariant) return;
        this.scheduleVariant = scheduleVariant;

        // загружаем типы рейса
        let tripTypes = await this.$store.dispatch('multipass/doFetchTripTypes', {
          route_variant_id: this.route_variant_id,
          force: true,
        }); if (!tripTypes) return [];
        this.tripTypes = tripTypes;

        // загружаем периоды суток
        const timePeriods = await this.$store.dispatch('rmt/doFetchTimeNormPeriods', {
          schedule_variant_id: this.schedule_variant_id,
        }); if (!timePeriods) return;
        this.timePeriods = timePeriods;

        // запускаем проверку нормативов
        const checkResult = await this.checkNorms();
        // если ошибка выходим
        if (!checkResult) return;
        // формируем код ошибки
        this.errorCode = checkResult.check_code;

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // загружаем с сервера данные по типу рейса
    async refreshTripType() {
        this.isLoading = 'fetch';
        try {

          // сбрасываем данные в правой таблице
          this.tripTypeCard = [];
          this.normRows = [];
          this.timeNormTimes = [];

          // запрашиваем карточку
          const tripTypeCard = await this.$store.dispatch('multipass/doFetchTripTypeCard', {
            trip_type_id: this.selectedRow.trip_type_id,
            crop_turn: true,
            force: true
          }); if (!tripTypeCard) return;
          this.tripTypeCard = tripTypeCard;

          // загружаем нормы
          const timeNormTimes = await this.$store.dispatch('rmt/doFetchTimeNormTimes', {
            schedule_variant_id: this.schedule_variant_id,
            trip_type_id:  this.selectedRow.trip_type_id,
          }); if (!timeNormTimes) return;
          this.timeNormTimes = timeNormTimes;

          // обновляем список строк
          await this.updateNormRows();

        }
        finally {
          this.isLoading = false;
        }
    },

    // сохраняем новые нормативы на сервере
    async saveTimeNormTimes() {
      this.isLoading = true;
      try {

        // сохраняем данные на сервере
        const timeNormTimes = await this.$store.dispatch('rmt/doEditTimeNormTimes', {
          schedule_variant_id: this.schedule_variant_id,
          trip_type_id: this.selectedRow.trip_type_id,
          norms: this.createResultNormTable(),
        }); if (!timeNormTimes) return;
        this.timeNormTimes = timeNormTimes;

        // обновляем список строк
        await this.updateNormRows();

      } finally {
        this.isLoading = false;
      }
    },

    // запускаем очистку норм по типу рейса
    async clearTimeNormTimeByTripType() {
      this.isLoading = true;
      try {

        // вызываем команду на сервере
        const timeNormTimes = await this.$store.dispatch('rmt/doClearTimeNormTime', {
          schedule_variant_id: this.schedule_variant_id,
          trip_type_id: this.selectedRow.trip_type_id,
        }); if (!timeNormTimes) return;
        this.timeNormTimes = timeNormTimes;

        // обновляем список строк
        await this.updateNormRows();

      } finally {
        this.isLoading = false;
      }
    },

    // запускаем обновление норм по типу рейса
    async updateTimeNormTimeByTripType() {
      this.isLoading = true;
      try {

        // вызываем команду на сервере
        const timeNormTimes = await this.$store.dispatch('rmt/doUpdateTimeNormTime', {
          schedule_variant_id: this.schedule_variant_id,
          trip_type_id: this.selectedRow.trip_type_id,
        }); if (!timeNormTimes) return;
        this.timeNormTimes = timeNormTimes;

        // обновляем список строк
        await this.updateNormRows();

      } finally {
        this.isLoading = false;
      }
    },

    // запускаем очистку норм по варианту расписания
    async clearTimeNormTime() {
      this.isLoading = true;
      try {

        // вызываем команду на сервере
        await this.$store.dispatch('rmt/doClearTimeNormTime', {
          schedule_variant_id: this.schedule_variant_id,
        });

      } finally {
        this.isLoading = false;
      }
    },

    // запускаем обновление норм по варианту расписания
    async updateTimeNormTime() {
      this.isLoading = true;
      try {

        // вызываем команду на сервере
        await this.$store.dispatch('rmt/doUpdateTimeNormTime', {
          schedule_variant_id: this.schedule_variant_id,
        });

      } finally {
        this.isLoading = false;
      }
    },

    // запрашиваем проверку нормативов
    async checkNorms() {
      return await this.$store.dispatch('rmt/doCheckTimeNormTime', {
        schedule_variant_id: this.schedule_variant_id,
      });
    },
  },

  watch: {
    // обновляем строки в случае изменения режима отображения
    showMode() {
      if (!this.isLoading)
        this.updateNormRows();
    },

    // обновляем строки в случае изменения режима редактирования
    isEditMode() {
      if (!this.isLoading)
        this.updateNormRows();
    }
  },

  // вызывается при создании компонента
  created() {
    // запрашиваем данные
    this.refreshData();
  }
};
</script>
