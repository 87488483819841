<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/Nav">Телематика</router-link></span>
        <span> / </span>
        <span>Управление данными</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <div class="container position-relative h-100">
        <align-container>
          <align-panel align="left" width="35%" :gap="3">
            <align-container>
              <!-- Форма с кнопками -->
              <align-panel align="top">

                <crud-form
                    :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
                    :buttonsEnabled="crudButtonsEnabled"
                    :filterExists="false"
                    @onRefresh="onTableRefresh"
                >

                  <template #leftCustomButton>
                    <label style="margin-top: 7px">Активный раздел</label>
                  </template>

                  <!-- Кнопка Команда-->
                  <template #leftCustomButton2>
                    <dropdown>
                      <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                      :disabled="!firstSectionSelectedRow">
                        Команда
                      </button-toggler>
                      <dropdown-menu ref="collapse">
                        <dropdown-item @click="moveFromFirstToFourth">Перенести <i
                            class="far fa-arrow-alt-circle-right"/>
                        </dropdown-item>
                        <dropdown-divider></dropdown-divider>
                        <dropdown-item @click="doCloseNav">{{ dropdownText }}</dropdown-item>
                      </dropdown-menu>
                    </dropdown>
                  </template>

                </crud-form>

              </align-panel>

              <!-- Таблица  -->
              <align-panel align="all">
                <first-section-edit-table
                    :isLoading="isLoading"
                    :rowList="firstSectionRows"
                    :selectedRow="firstSectionSelectedRow"
                    @onRowSelect="onFirstSectionRowSelect"
                ></first-section-edit-table>
              </align-panel>
            </align-container>
          </align-panel>

          <align-panel align="all" width="33%">
            <align-container>
              <!-- Форма с кнопками -->
              <align-panel align="top">
                <crud-form
                    :buttonsExist="{add: false, edit: false, delete: false, refresh: false}"
                    :buttonsEnabled="crudButtonsEnabled"
                    @onRefresh="onTableRefresh"
                    :filterExists="false"
                >

                  <template #leftCustomButton>
                    <label style="margin-top: 7px">Раздел на диске</label>
                  </template>

                  <template #leftCustomButton2>
                    <button class="btn btn-success" @click="moveFromFourthToSecond"
                            :disabled="!fourthSectionSelectedRow"
                    >Копировать <i class="far fa-arrow-alt-circle-right"/></button>
                  </template>

                </crud-form>
              </align-panel>
              <!-- Таблица  -->
              <align-panel align="all">

                <section-edit-table
                    :isLoading="isLoading"
                    :rowList="fourthSectionRows"
                    :selectedRow="fourthSectionSelectedRow"
                    @onRowSelect="onFourthSectionRowSelect"
                ></section-edit-table>

              </align-panel>
            </align-container>
          </align-panel>

          <align-panel align="right" width="33%" :gap="3">
            <align-container>
              <!-- Форма с кнопками -->
              <align-panel align="top">
                <crud-form
                    :buttonsExist="{add: false, edit: false, delete: false,  refresh: false}"
                    :buttonsEnabled="crudButtonsEnabled"
                    @onRefresh="onTableRefresh"
                    :filterExists="false"
                >

                  <template #leftCustomButton>
                    <label style="margin-top: 7px">Раздел с диска</label>
                  </template>

                  <template #leftCustomButton2>
                    <button class="btn btn-danger" @click="moveFromSecondToFourth"
                            :disabled="!secondSectionSelectedRow"
                    >Удалить <i class="fas fa-trash"/></button>
                  </template>

                </crud-form>
              </align-panel>
              <!-- Таблица  -->
              <align-panel align="all">

                <section-edit-table
                    :isLoading="isLoading"
                    :rowList="secondSectionRows"
                    :selectedRow="secondSectionSelectedRow"
                    @onRowSelect="onSecondSectionRowSelect"
                ></section-edit-table>

              </align-panel>
            </align-container>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import FirstSectionEditTable from "@/components/nav/nav_day_states/FirstSectionEditTable";
import SectionEditTable from "@/components/nav/nav_day_states/SectionEditTable";
import {delMutation, editMutation} from "@/store/Actions";

export default {
  mixins: [PageMixin],

  components: {
    SectionEditTable,
    FirstSectionEditTable,
    AlignPanel,
  },

  props: [
    // операция show передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // выбранная строка первой секции
      firstSectionSelectedRow: null,
      // выбранная строка второй секции
      secondSectionSelectedRow: null,
      // выбранная строка четвертой секции
      fourthSectionSelectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // список строк по первой таблице
      firstSectionRows: [],
      // список строк по второй таблице
      secondSectionRows: [],
      // список строк по четвертой таблице
      fourthSectionRows: [],

      // дни покраснели и ждут переноса
      datesArr: [],
      // красные дни перенеслись и ждут обеления
      datesSecondArr: [],

      mainTimer: null,
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true,
      }
    },

    dropdownText() {
      if (this.firstSectionSelectedRow) {
        if (this.firstSectionSelectedRow.nav_closed) {
          return 'Открыть навигацию'
        } else {
          return 'Закрыть навигацию'
        }
      } else {
        return '';
      }
    }

  },
  methods: {

    getStyle(value, table) {

      if (this.secondSectionSelectedRow && table == 2 && value.date_index == this.secondSectionSelectedRow.date_index) {
        return "background-color: #007bff !important; color: #fff !important;"
      }

      if (this.fourthSectionSelectedRow && table == 4 && value.date_index == this.fourthSectionSelectedRow.date_index) {
        return "background-color: #007bff !important; color: #fff !important;"
      }

      if (value.prev_section != value.section || value.next_section != value.section) {
        return "background-color: #ff4b4b !important;"
      }
    },

    setTimer(newValue) {
      if(newValue) {
        this.datesArr.push(newValue);
      }

      // взводим интервал, который будет перезапрашивать строки левой таблицы каждые 60 секунд
      this.mainTimer = setInterval(async () => {

        for (const dayValue of this.datesSecondArr) {
          let value = await this.$store.dispatch('nav/doFetchDayStatesByDate', dayValue);
          if(value.section == dayValue.section) {
            if (dayValue.section == 1) {
              editMutation(this.firstSectionRows, "date_index", value);
            } else if (dayValue.section == 4) {
              editMutation(this.fourthSectionRows, "date_index", value);
            } if (dayValue.section == 2) {
              editMutation(this.secondSectionRows, "date_index", value);
            }
            if(value.section == value.next_section && value.section == value.prev_section) {
              delMutation(this.datesSecondArr, "date_index", dayValue);
            }
          }
          if(this.datesArr.length == 0 && this.datesSecondArr.length == 0) {
            this.stopTimer();
          }
        }


        for (const dayValue of this.datesArr) {
          let value = await this.$store.dispatch('nav/doFetchDayStatesByDate', dayValue);
          if(value.section != dayValue.section) {
            if (value.section == 1) {
              this.firstSectionRows.push(value);
            } else if (value.section == 4) {
              this.fourthSectionRows.push(value);
            } if (value.section == 2) {
              this.secondSectionRows.push(value);
            }
            if (dayValue.section == 1) {
              delMutation(this.firstSectionRows, "date_index", dayValue);
            } else if (dayValue.section == 4) {
              delMutation(this.fourthSectionRows, "date_index", dayValue);
            } if (dayValue.section == 2) {
              delMutation(this.secondSectionRows, "date_index", dayValue);
            }
            if(value.section != value.prev_section) {
              this.datesSecondArr.push(value);
            } else {
              if (dayValue.section == 1) {
                editMutation(this.firstSectionRows, "date_index", value);
              } else if (dayValue.section == 4) {
                editMutation(this.fourthSectionRows, "date_index", value);
              } if (dayValue.section == 2) {
                editMutation(this.secondSectionRows, "date_index", value);
              }
            }
            delMutation(this.datesArr, "date_index", dayValue);
            if(this.datesArr.length == 0 && this.datesSecondArr.length == 0) {
              this.stopTimer();
            }
          }
        }
      }, 60000);
    },

    // перемещение дня между первой и четвертой секцией
    async moveFromFirstToFourth() {
      this.isLoading = true;
      try {
        let newValue = await this.$store.dispatch('nav/doMoveDayStates', {
          date_index: this.firstSectionSelectedRow.date_index,
          section: 1,
          next_section: 4,
        });
        if(newValue){
          editMutation(this.firstSectionRows, 'date_index', newValue);
          // взводим таймер
          this.setTimer(newValue);
        }
      } finally {
        this.isLoading = false;
        this.firstSectionSelectedRow = null;
      }
    },

    // перемещение дня между второй и четвертой секцией
    async moveFromSecondToFourth() {
      this.isLoading = true;
      try {
        let newValue = await this.$store.dispatch('nav/doMoveDayStates', {
          date_index: this.secondSectionSelectedRow.date_index,
          section: 2,
          next_section: 4,
        });
        if(newValue){
          editMutation(this.secondSectionRows, 'date_index', newValue);
          // взводим таймер
          this.setTimer(newValue);
        }
      } finally {
        this.isLoading = false;
        this.secondSectionSelectedRow = null;
      }
    },

    // перемещение дня между второй и четвертой секцией
    async moveFromFourthToSecond() {
      this.isLoading = true;
      try {
        let newValue = await this.$store.dispatch('nav/doMoveDayStates', {
          date_index: this.fourthSectionSelectedRow.date_index,
          section: 4,
          next_section: 2,
        });
        if(newValue){
          editMutation(this.fourthSectionRows, 'date_index', newValue);
          // взводим таймер
          this.setTimer(newValue);
        }
      } finally {
        this.isLoading = false;
        this.fourthSectionSelectedRow = null;
      }
    },

    // Закрыть/открыть навигацию
    async doCloseNav() {
      this.isLoading = true;
      try {
        let newValue = await this.$store.dispatch('nav/doCloseNavDayStates', {
          date_index: this.firstSectionSelectedRow.date_index,
          nav_closed: !this.firstSectionSelectedRow.nav_closed,
        });
        if (newValue) {
          editMutation(this.firstSectionRows, 'date_index', newValue);
          this.firstSectionSelectedRow = newValue;
        }
      } finally {
        this.isLoading = false;
      }
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // выбрана новая строка в таблице первой секции
    onFirstSectionRowSelect(item) {
      this.firstSectionSelectedRow = item;
    },

    // выбрана новая строка в таблице
    onSecondSectionRowSelect(item) {
      this.secondSectionSelectedRow = item;
    },

    // выбрана новая строка в таблице
    onFourthSectionRowSelect(item) {
      this.fourthSectionSelectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        let firstSectionRows = await this.$store.dispatch('nav/doFetchDayStates', {section: 1});
        if (firstSectionRows) {
          this.firstSectionRows = firstSectionRows;
        }

        // проверяем есть ли дни на перенос
        this.firstSectionRows.forEach(dayValue => {
          if (dayValue.prev_section == dayValue.section && dayValue.next_section != dayValue.section) {
            // проверяем заведен ли таймер
            if (!this.mainTimer) {
              // если таймер на заведен, заводим его
              this.setTimer(dayValue);
            } else {
              this.datesArr.push(dayValue);
            }
          }
        })

        let secondSectionRows = await this.$store.dispatch('nav/doFetchDayStates', {section: 2});
        if (secondSectionRows) {
          this.secondSectionRows = secondSectionRows;
        }

        // проверяем есть ли дни на перенос или перенесенные дни, но ещё окрашенные в красный
        this.secondSectionRows.forEach(dayValue => {
          if (dayValue.prev_section == dayValue.section && dayValue.next_section != dayValue.section) {
            // проверяем заведен ли таймер
            if (!this.mainTimer) {
              // если таймер на заведен, заводим его
              this.setTimer(dayValue);
            } else {
              this.datesArr.push(dayValue);
            }
          } else if (dayValue.prev_section != dayValue.section && dayValue.next_section == dayValue.section) {
            // проверяем заведен ли таймер
            if (!this.mainTimer) {
              // если таймер на заведен, заводим его
              this.datesSecondArr.push(dayValue);
              this.setTimer();
            } else {
              this.datesSecondArr.push(dayValue);
            }
          }
        })


        let fourthSectionRows = await this.$store.dispatch('nav/doFetchDayStates', {section: 4});
        if (fourthSectionRows) {
          this.fourthSectionRows = fourthSectionRows;
        }

        // проверяем есть ли дни на перенос или перенесенные дни, но ещё окрашенные в красный
        this.fourthSectionRows.forEach(dayValue => {
          if (dayValue.prev_section == dayValue.section && dayValue.next_section != dayValue.section) {
            // проверяем заведен ли таймер
            if (!this.mainTimer) {
              // если таймер на заведен, заводим его
              this.setTimer(dayValue);
            } else {
              this.datesArr.push(dayValue);
            }
          } else if (dayValue.prev_section != dayValue.section && dayValue.next_section == dayValue.section) {
            // проверяем заведен ли таймер
            if (!this.mainTimer) {
              // если таймер на заведен, заводим его
              this.datesSecondArr.push(dayValue);
              this.setTimer();
            } else {
              this.datesSecondArr.push(dayValue);
            }
          }
        })

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
        this.firstSectionSelectedRow = null;
        this.secondSectionSelectedRow = null;
        this.fourthSectionSelectedRow = null;
      }
    },

    stopTimer() {
      // убиваем таймер
      if (this.mainTimer) {
        clearInterval(this.mainTimer);
        this.mainTimer = null;
      }
    }

  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },

  unmounted() {
    this.stopTimer();
  }

};
</script>