<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-asmpp-fact-trip-exts"></edit-table-panel>
</template>

<script>


import {getDepot, getTransport, getTransportClass} from "@/store/RefHelpers";
import {getSignTitle, getTimeWithSecondsFormat, secondsToDateAndTime} from "@/lib";

export default {
  props: ['isLoading', 'rowArr', 'routes', 'filterRoute', 'filterRouteVariant', 'filterTrip'],
  emits: ['setLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "fact_trip_ext_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "__depot",
          caption: "Предпр.",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__route",
          caption: "Маршрут",
          sortType: 'number',
          isFilterable: true,
          show: true,
        },
        {
          field: "graph",
          caption: "Граф",
          sortType: 'number',
          isFilterable: true,
        },
        // {
        //   field: "__routeVariant",
        //   caption: "Период",
        //   sortType: 'string',
        //   isFilterable: true,
        //   dataAlign: 'center',
        //   headerAlign: 'center',
        //   show: false,
        // },
        {
          field: "__tripType",
          caption: "Тип рейса",
          sortType: 'sign',
          sorted: 'asc',
          isFilterable: true,
          displayFn(row) {
            return getSignTitle(row.__tripType)
          },
        },
        {
          field: "__time_index_begin",
          caption: "Время отправления",
          sortType: 'string',
          isFilterable: true
        },
        {
          field: "__week_day",
          caption: "День нед.",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "__fact_trip_duration",
          caption: "Вр.рейса",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "passenger_count",
          caption: "Кол. пасс.",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "passenger_traffic",
          caption: "Пасс.*Км",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.passenger_traffic) {
              return Number(row.passenger_traffic).toFixed(1);
            }
            return 0
          },
        },
        {
          field: "passenger_trip_length_avg",
          caption: "Дл.средн.",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.passenger_trip_length_avg) {
              return Number(row.passenger_trip_length_avg).toFixed(1);
            }
            return 0
          },
        },
        {
          field: "passenger_inside_max",
          caption: "Макс.(пасс.)",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "passenger_inside_avg",
          caption: "Ср.наполн.",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.passenger_inside_avg) {
              return Number(row.passenger_inside_avg).toFixed(1);
            }
            return ''
          },
        },
        {
          field: "pay_sum",
          caption: "Сумма оплат",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "pay_tran_count",
          caption: "Кол оплат",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "__transport",
          caption: "Гар.№ТС",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "__ts_capacity",
          caption: "Класс ТС",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },

        // {
        //   field: "apc_error_count",
        //   caption: "Кол-во посылок с ошибкой",
        //   sortType: 'string',
        //   isFilterable: true,
        //   show: false
        // },
        // {
        //   field: "undetected_stop_count",
        //   caption: "Кол-во нераспознанных остановок",
        //   sortType: 'string',
        //   isFilterable: true,
        // },
        // {
        //   field: "unstopped_stop_count",
        //   caption: "Количество остановок без открытия дверей",
        //   sortType: 'string',
        //   isFilterable: true,
        // },
        // {
        //   field: "fact_trip_disbalance",
        //   caption: "Рейсовый дисбаланс",
        //   sortType: 'string',
        //   isFilterable: true,
        // },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      let rows = this.rowArr;
      if (rows.length != 0) {
        rows.map(async trip => {
          trip.__id = trip.trip_id;
          let depot = getDepot(trip.depot_id);
          if (depot)
            trip.__depot = depot.long_name;
          let transport = getTransport(trip.transport_id);
          if (transport) {
            trip.__transport = transport.garage_num;
            let transportClass = getTransportClass(transport.transport_class_id);
            if (transportClass)
              trip.__ts_capacity = transportClass.short_name;
          }
          // переводим время из секунд
          trip.__time_index_begin = secondsToDateAndTime(trip.time_index_begin);
          trip.__fact_trip_duration = getTimeWithSecondsFormat(trip.fact_trip_duration);

          if (trip.weekday_num == 1) {
            trip.__week_day = "Пн."
          } else if (trip.weekday_num == 2) {
            trip.__week_day = "Вт."
          } else if (trip.weekday_num == 3) {
            trip.__week_day = "Ср."
          } else if (trip.weekday_num == 4) {
            trip.__week_day = "Чт."
          } else if (trip.weekday_num == 5) {
            trip.__week_day = "Пт."
          } else if (trip.weekday_num == 6) {
            trip.__week_day = "Сб."
          } else if (trip.weekday_num == 7) {
            trip.__week_day = "Вс."
          }

          return trip;
        });
      }

      return rows;
    },
  }
};
</script>
