<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-multipass-stopstations"></edit-table-panel>
</template>

<script>

import {getStopstationType} from "@/store/MultipassHelpers";

export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "stopstation_id",
          caption: "Код",
          sorted: 'asc',
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
          show: false,
        },
        {
          field: "__stopstationTypeName",
          caption: "Тип автостанции",
          sortType: 'string'
        },
        {
          field: "latitude",
          caption: "Широта",
          sortType: 'number',
          show: false,
        },
        {
          field: "longitude",
          caption: "Долгота",
          sortType: 'number',
          show: false,
        },
        {
          field: "reg_num",
          caption: "Рег.номер",
          sortType: 'string',
          show: false,
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          show: false,
        },
        {
          field: "area_classifier_id",
          caption: "Код ОКАТО(ОКТМО)",
          sortType: 'string',
          show: false,
        },
        {
          field: "address",
          caption: "Адрес",
          sortType: 'string',
          show: false,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      return this.$store.getters["multipass/getStopstations"].map((stopstation) => {
        stopstation.__id = stopstation.stopstation_id;
        const stopstationType = getStopstationType(stopstation.stopstation_type_id);
        stopstation.__stopstationTypeName = stopstationType && stopstationType.short_name || '';

        return stopstation;
      });
    },
  },
};
</script>
