<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Транспортное предприятие</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="depotId">Код:</label>
              <input-integer
                  id="depotId"
                  type="number"
                  class="form-control w-25"
                  v-model="depotId"
                  readonly
              />
            </div>
            <div class="form-group">
              <label for="longName">Полное наименование:</label>
              <req-field/>
              <input-string
                  class="form-control"
                  id="longName"
                  v-model="longName"
                  ref="longName"
                  :readonly="op === 'show'"
                  required
                  error="Введите полное наименование"
              />
            </div>
            <div class="form-group">
              <label for="shortName">Краткое наименование:</label>
              <req-field/>
              <input-string
                  class="form-control"
                  id="shortName"
                  v-model="shortName"
                  ref="shortName"
                  :readonly="op === 'show'"
                  required
                  error="Введите краткое наименование"
              />
            </div>
            <div class="form-group">
              <label for="orgId">Организация:</label>
              <organizations-select
                  class="form-control"
                  id="orgId"
                  v-model="orgId"
                  ref="orgId"
                  :readonly="op === 'show'"
              />
            </div>
            <div class="form-group">
              <label for="legalFormId">Организационно-правовая форма:</label>
              <legal-forms-select
                  class="form-control"
                  id="legalFormId"
                  v-model="legalFormId"
                  ref="legalFormId"
                  :readonly="op === 'show'"
              />
            </div>
            <div class="form-group">
              <label for="timeZone">Часовой пояс (мин.):</label>
              <input-integer
                  class="form-control w-25"
                  id="timeZone"
                  v-model="timeZone"
                  ref="timeZone"
                  :readonly="op === 'show'"
                  :min-value="-720"
                  :max-value="720"
                  error="Введите значение часового пояса от -720 до 720"
              />
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import LegalFormsSelect from "@/components/ref/legal_forms/LegalFormsSelect";
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import OrganizationsSelect from "@/components/ref/organizations/OrganizationsSelect";
import ReqField from "@/components/ui/custom/ReqField";

export default {
  components: {ReqField, OrganizationsSelect, LegalFormsSelect},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      depotId: this.item.depot_id,
      shortName: this.item.short_name,
      longName: this.item.long_name,
      legalFormId: this.item.legal_form_id,
      timeZone: this.item.time_zone,
      orgId: this.item.org_id,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          depot_id: this.op === 'add' ? undefined : this.depotId,
          short_name: this.shortName,
          long_name: this.longName,
          time_zone: this.timeZone,
          legal_form_id: this.legalFormId,
          org_id: this.orgId
        })
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>