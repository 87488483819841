import AsmppTitlePage from '../pages/asmpp/AsmppTitlePage.vue';
import RoutesPage from "@/pages/asmpp/RoutesPage";
import BalanceTypesPage from "@/pages/asmpp/BalanceTypesPage";
import FactTripExtsPage from "@/pages/asmpp/routes/FactTripExtsPage";
import TripTypeCompositesPage from "@/pages/asmpp/TripTypeCompositesPage";
import TripTypeCompositeCardsPage from "@/pages/asmpp/trip_type_composites/TripTypeCompositeCardsPage";
import PassengerTrafficFactTripByHoursPage from "@/pages/asmpp/routes/fact_trips/PassengerTrafficFactTripByHoursPage";
import DepotsPage from "@/pages/asmpp/DepotsPage";
import PassengerTrafficDayByTransportPage from "@/pages/asmpp/depots/PassengerTrafficDayByTransportPage";
import PassengerTrafficDayByGraphPage from "@/pages/asmpp/routes/PassengerTrafficDayByGraphPage";
import FactTripCardExtsAggregatedPage from "@/pages/asmpp/routes/fact_trips/FactTripCardExtsAggregatedPage";
import FactTripCardExtsAggregatedNetworkPage from "@/pages/asmpp/FactTripCardExtsAggregatedNetworkPage";
import PassengerTrafficDayByTransportNavSumPage from "@/pages/asmpp/depots/PassengerTrafficDayByTransportNavSumPage";
import FactTripCardExtsPage from "@/pages/asmpp/routes/fact_trips/FactTripCardExtsPage";
import AsopDepotsPage from "@/pages/asmpp/AsopDepotsPage";
import PayTransProcStatusByTransportPage from "@/pages/asmpp/asop_depots/PayTransProcStatusByTransportPage";
import PayTransByDepotPage from "@/pages/asmpp/asop_depots/PayTransByDepotPage";
import PayTransByDepotDayPage from "@/pages/asmpp/asop_depots/PayTransByDepotDayPage";
import PayTransByRoutePage from "@/pages/asmpp/asop_depots/PayTransByRoutePage";
import PayTransByRouteDayPage from "@/pages/asmpp/asop_depots/PayTransByRouteDayPage";
import AsopRoutesPage from "@/pages/asmpp/AsopRoutesPage";
import PayTransByGraphPage from "@/pages/asmpp/asop_routes/PayTransByGraphPage";
import PayTransByGraphDayPage from "@/pages/asmpp/asop_routes/PayTransByGraphDayPage";
import MainPage from "@/pages/main/MainPage";

export default {
    path: '/asmpp',
    component: MainPage,
    children: [
        {
            path: 'asopRoutes/:route_id(\\d+)?/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/payTransByGraphDay',
            component: PayTransByGraphDayPage,
            props: true
        },
        {
            path: 'asopRoutes/:route_id(\\d+)?/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/payTransByGraph',
            component: PayTransByGraphPage,
            props: true
        },
        {
            path: 'asopRoutes/:op(show)/:id(\\d+)?',
            component: AsopRoutesPage,
            props: true
        },
        {
            path: 'asopRoutes',
            component: AsopRoutesPage
        },
        {
            path: 'asopDepots/:depot_id(\\d+)?/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/payTransByRouteDay',
            component: PayTransByRouteDayPage,
            props: true
        },
        {
            path: 'asopDepots/:depot_id(\\d+)?/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/payTransByRoute',
            component: PayTransByRoutePage,
            props: true
        },
        {
            path: 'asopDepots/:depot_id(\\d+)?/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/payTransByDepotDay',
            component: PayTransByDepotDayPage,
            props: true
        },
        {
            path: 'asopDepots/:depot_id(\\d+)?/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/payTransByDepot',
            component: PayTransByDepotPage,
            props: true
        },
        {
            path: 'asopDepots/:depot_id(\\d+)?/:date_index(\\d+)?/payTransProcStatusByTransport',
            component: PayTransProcStatusByTransportPage,
            props: true
        },
        {
            path: 'asopDepots/:op(show)/:id(\\d+)?',
            component: AsopDepotsPage,
            props: true
        },
        {
            path: 'asopDepots',
            component: AsopDepotsPage
        },
        {
            path: 'routes/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/:routes_id(\\d+)*/factTripExts/:fact_trip_ext_id(\\d+)*/factTripCardExtsAggregated',
            component: FactTripCardExtsAggregatedPage,
            props: true
        },
        {
            path: 'routes/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/:routes_id(\\d+)*/factTripExts/:fact_trip_ext_id(\\d+)?/factTripCardExts',
            component: FactTripCardExtsPage,
            props: true
        },
        {
            path: 'routes/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/:routes_id(\\d+)*/passengerTrafficDayByGraph',
            component: PassengerTrafficDayByGraphPage,
            props: true
        },
        {
            path: 'depots/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/:depots_id(\\d+)*/passengerTrafficDayByTransportNavSum',
            component: PassengerTrafficDayByTransportNavSumPage,
            props: true
        },
        {
            path: 'depots/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/:depots_id(\\d+)*/passengerTrafficDayByTransport',
            component: PassengerTrafficDayByTransportPage,
            props: true
        },
        {
            path: 'depots/:op(show)/:id(\\d+)?',
            component: DepotsPage,
            props: true
        },
        {
            path: 'depots',
            component: DepotsPage
        },
        {
            path: 'routes/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/:routes_id(\\d+)*/factTripExts/:fact_trips_id(\\d+)*/passengerTrafficFactTripByHours',
            component: PassengerTrafficFactTripByHoursPage,
            props: true
        },
        {
            path: 'routes/:date_index_begin(\\d+)?/:date_index_end(\\d+)?/:routes_id(\\d+)*/factTripExts',
            component: FactTripExtsPage,
            props: true
        },
        {
            path: 'tripTypeComposites/:trip_type_composite_id(\\d+)?/tripTypeCompositeCards/:op(add)/:id(\\d+)?',
            component: TripTypeCompositeCardsPage,
            props: true
        },
        {
            path: 'tripTypeComposites/:trip_type_composite_id(\\d+)?/tripTypeCompositeCards',
            component: TripTypeCompositeCardsPage,
            props: true
        },
        {
            path: 'tripTypeComposites/:op(show|add|del|edit)/:id(\\d+)?',
            component: TripTypeCompositesPage,
            props: true
        },
        {
            path: 'tripTypeComposites',
            component: TripTypeCompositesPage
        },
        {
            path: 'balanceTypes',
            component: BalanceTypesPage
        },
        {
            path: 'factTripCardExtsAggregatedNetwork/:op(show)/:id(\\d+)?',
            component: FactTripCardExtsAggregatedNetworkPage,
            props: true
        },
        {
            path: 'factTripCardExtsAggregatedNetwork',
            component: FactTripCardExtsAggregatedNetworkPage
        },
        {
            path: 'routes/:op(show)/:id(\\d+)?',
            component: RoutesPage,
            props: true
        },
        {
            path: 'routes',
            component: RoutesPage
        },
        {
            path: '',
            component: AsmppTitlePage
        },
        {
            path: ':pathMatch(.*)*',
            component: AsmppTitlePage
        }
    ]
}