<template>
  <!-- Карта-->
  <leaflet-map
      :zoom="zoom"
      :center="center"
      :contextMenu="mapContextMenu"
      :options="mapOptions"
  >

    <!-- Элементы управления-->
    <leaflet-control-group>
      <!-- Базовые слои-->
      <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
    </leaflet-control-group>

    <!-- Граф-->
    <leaflet-graph
        :state="isEditMode ? 'edit' : 'original'"
    >
    </leaflet-graph>

  </leaflet-map>

</template>

<script>

export default {
  props: [
      // режим редактирования
      'isEditMode'
  ],

  computed: {
    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom']
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter']
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },

    // контекстное меню карты
    mapContextMenu() {
        return {
          contextmenu: true,
          contextmenuWidth: 140,
          contextmenuItems: []
        }
    },
  },

  data() {
    return {
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
    }
  },
}
</script>
