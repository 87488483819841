import {DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ СТОИМОСТИ ПРОЕЗДА МЕЖДУ ЗОНАМИ

export default {

    actions: {
        // запрос списка объектов
        doFetchTariffZoneFares: (context, payload) => {
            return DoGet(
                '/multipass/tariffZoneFares?tariff_zone_system_id=' + payload.tariff_zone_system_id,
                null,
                [],
                'Запрос cтоимости проезда между зонами',
                false,
                true
            )
        },

        // изменение объекта
        doEditTariffZoneFare: (context, payload) => {
            return DoPut(
                '/multipass/tariffZoneFares/' + payload.tariff_zone_system_id,
                payload.fares,
                null,
                'Установка стоимости проезда между зонами'
            )
        },
    }
}
