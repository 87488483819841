import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ТИПОВ ОСТАНОВОЧНЫХ ПУКНТОВ

export default {
    state() {
        return {
            // список объектов
            stopTypes: [],
            // время обновления кэша new Date().getTime()
            stopTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            stopTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getStopTypes(state) {
            return state.stopTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setStopTypes(state, payload) {
            state.stopTypes = payload;
            state.stopTypesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchStopTypes: (context, payload) => {
            return DoGet(
                '/multipass/stopTypes',
                'multipass/setStopTypes',
                [],
                'Запрос типов остановочных пукнтов',
                false,
                payload && payload.force,
                context.state.stopTypesCache,
                context.state.stopTypesCacheTimeout
            )
        },

        // очищает кэш
        doClearStopTypes: (context, payload) => {
            return DoClearCache(
                'multipass/setStopTypes',
                [],
                payload && payload.force,
                context.state.stopTypesCache,
                context.state.stopTypesCacheTimeout
            )
        },
    }
}