<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/nav">Телематика</router-link></span>
        <span> / </span>
        <span>Радиостанции</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка Команда-->
            <template #leftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                :disabled="commandDisabled">
                  Команда
                </button-toggler>
                <dropdown-menu ref="collapse">
                  <dropdown-item @click="onDiscardRadio" v-if="discardRadio">Списать радиостанцию
                  </dropdown-item>
                  <dropdown-item @click="onRestoreRadio" v-if="restoreRadio">Восстановить радиостанцию
                  </dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Связь
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="radioStatesLink">Состояния</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <radios-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></radios-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <radio-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </radio-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      :id="id"
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить радиостанцию с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Подтверждение списания -->
  <radio-discard-dialog
      :id="id"
      v-if="isDiscardConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDiscard"
  ></radio-discard-dialog>

  <!-- Подтверждение восстановления -->
  <confirm-message-box
      v-if="isRestoreConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmRestore"
  >Вы уверены, что хотите восстановить радиостанцию с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import RadiosEditTable from "@/components/nav/radio/RadiosEditTable";
import RadioDialog from "@/components/nav/radio/RadioDialog";
import RadioDiscardDialog from "@/components/nav/radio/RadioDiscardDialog";

export default {
  mixins: [PageMixin],

  components: {
    RadioDiscardDialog,
    RadioDialog,
    RadiosEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // условие отображения подтверждения списания
    isDiscardConfirmVisible() {
      return this.op === 'discard' && !!this.selectedRow;
    },

    // условие отображения подтверждения восстановления
    isRestoreConfirmVisible() {
      return this.op === 'restore' && !!this.selectedRow;
    },

    // условие отключения кнопки - Команды
    commandDisabled() {
      return !this.setStatusExists;
    },

    // условие отображения команды - Установить статус
    setStatusExists() {
      return this.selectedRow;
    },

    // условие отображения меню списать
    discardRadio() {
      return this.selectedRow && !this.selectedRow.time_index_end
    },

    // условие отображения меню восстановить
    restoreRadio() {
      return this.selectedRow && !!this.selectedRow.time_index_end
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // данные, которые отображаются в диалоговом окне
    dialogRow() {
      return this.op === 'add' ? {} : this.selectedRow;
    },

    // ссылка для перехода к состояниям радио
    radioStatesLink() {
      if (this.selectedRow) return '/nav/radios/' + this.selectedRow.radio_id + '/radioStates'
      else return '';
    },

  },
  methods: {

    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/nav/radios/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/nav/radios/edit/" + this.selectedRow.radio_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/nav/radios/del/" + this.selectedRow.radio_id);
    },

    // нажата кнопка "Просмотр"
    onTableShow() {
      this.$router.replace("/nav/radios/show/" + this.selectedRow.radio_id);
    },

    // нажата кнопка - Списать радиостанцию
    onDiscardRadio() {
      this.$router.replace("/nav/radios/discard/" + this.selectedRow.radio_id);
    },

    // нажата кнопка - Восстановить радиостанцию
    onRestoreRadio() {
      this.$router.replace("/nav/radios/restore/" + this.selectedRow.radio_id);
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/nav/radios");
    },

    // подтверждено списание
    onConfirmDiscard(item) {
      // списываем элемент
      this.discardItem(item);
      // возвращаемся на страницу
      this.$router.replace("/nav/radios");
    },

    // подтверждено восстановление
    onConfirmRestore() {
      // восстанавлмиваем элемент
      this.restoreItem();
      // возвращаемся на страницу
      this.$router.replace("/nav/radios");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/nav/radios");
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/nav/radios");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {
        await this.$store.dispatch('nav/doFetchRadios', {force})
        await this.$store.dispatch('nav/doFetchRadioProtocols', {force})
        await this.$store.dispatch('nav/doFetchRadioModels', {force})
        await this.$store.dispatch('ref/doFetchTransport', {force})
        await this.$store.dispatch('nav/doFetchSensors', {force})
        await this.$store.dispatch('nav/doFetchParams', {force})
        await this.$store.dispatch('ref/doFetchDepots', {force})

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('nav/doAddRadio', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('nav/doEditRadio', {
          item: item,
          time_index_begin: item.discardDate,
        });
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('nav/doDelRadio', this.selectedRow);
        this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // Списываем объект
    async discardItem(item) {
      this.isLoading = true;
      try {

        await this.$store.dispatch('nav/doDiscardRadio', {
          radio_id: this.selectedRow.radio_id,
          time_index_begin: item,
        });
        this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // Восстанавливаем объект
    async restoreItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('nav/doRestoreRadio', this.selectedRow);
        this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

  },

// вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  }
  ,
}
;
</script>

