<template>
  <transition name="slide">
    <div class="panel overflow-hidden" v-if="isReportsShow" v-click-away="onAutoHide">
      <modal-content>
        <modal-header class="bg-primary text-white overflow-hidden">
          <h5 class="modal-text">Настройки</h5>
          <div class="col"></div>
          <custom-slider-switch
              v-model="direction"
              style="margin-left: 10px"
          ></custom-slider-switch>
          <div class="col"></div>
          <button-close class="button-close text-white" @click="onClose"></button-close>
        </modal-header>
        <modal-body style="padding: 0; overflow-y: auto; overflow-x: auto; max-height: 780px">

          <table class="table table-sm table-striped" style="margin-bottom: 0px">
            <thead>
            <tr>
              <th scope="col">Отобр.</th>
              <th scope="col">КП</th>
              <!--              В цикле отображаем остальные колонки, их количество зависит от КП-->
              <th scope="col"
                  v-for="(col, index) in tripTypesArr"
                  :key="index"
                  style="padding: 0px; text-align: center !important;"
              >
                <button
                    type="button"
                    class="btn btn-light btn-sm"
                    :style="ttButtonStyle(col) == 0 ? '' : (ttButtonStyle(col) == 1 ? 'background: linear-gradient(to bottom right, white , #007bff );' : 'background: #007bff; color: white')"
                    @click="setAllColSelected(col)"
                >
                  {{ col.sign }}
                </button>
              </th>

            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in items" :key="index" style="background-color: white">
              <td
                  :class="'text-center'"
                  :style="item.isFinal ? 'background-color: rgb(145,145,145)' : 'background-color: white'"
              >
                <input-checkbox-simple
                    v-model="item.__isVisible"
                    :disabled="item.isFinal"
                ></input-checkbox-simple>
              </td>
              <td
                  style="padding: 0;"
              >
                <button
                    type="button"
                    class="btn btn-light btn-sm"
                    @click="setAllRowSelected(item)"
                    :style="cpButtonStyle(item) == 0 ? '' : (cpButtonStyle(item) == 1 ? 'background: linear-gradient(to bottom right, white , #007bff);' : 'background: #007bff; color: white')"
                >
                  {{ item.__name }}
                </button>
              </td>
              <td
                  v-for="(tripType, index) in tripTypesArr"
                  :key="index"
                  :class="'text-center'"
              >
                <input-checkbox-simple
                    v-model="item[tripType.sign]"
                    :disabled="!item.tripTypes.includes(tripType.sign)"
                ></input-checkbox-simple>
              </td>
            </tr>
            </tbody>
          </table>

        </modal-body>
      </modal-content>
    </div>
  </transition>
</template>

<script>
import {directive} from "vue3-click-away";
import {getSignTitle} from "@/lib";
import CustomSliderSwitch from "@/components/ui/custom/CustomSliderSwitch";
import {getStop} from "@/store/MultipassHelpers";

export default {
  components: {CustomSliderSwitch},
  directives: {
    ClickAway: directive
  },
  props: ['isMatrixDialogVisible', 'itemsList', 'tripTypes', 'base_trip_type_short'],
  data() {
    return {
      direction: 0,
    }
  },

  watch: {

    // tripTypes() {
    //   // определяем первоначальную расстановку галочек
    //   // а именно конечные основного рейса
    //   this.$nextTick(() => {
    //     this.itemsList.forEach(row => {
    //       this.tripTypesArr.forEach(tripType => {
    //         if(this.base_trip_type_short == tripType.sign && row.tripTypes.includes(tripType.sign) && row.isFinal) {
    //           row[tripType.sign] = true;
    //         } else {
    //           row[tripType.sign] = false;
    //         }
    //       })
    //     })
    //   })
    // },

    base_trip_type_short(newValue) {
      let is_direction_0 = 0;
      let is_direction_1 = 0;
      this.itemsList.forEach(row => {
        this.tripTypesArr.forEach(tripType => {
          if(newValue == tripType.sign && row.tripTypes.includes(tripType.sign) && row.isFinal) {
            if(row.direction == 0 && is_direction_0 == 0) {
              is_direction_0 = 1;
              row[tripType.sign] = true;
            } else if(row.direction == 1 && is_direction_1 == 0) {
              is_direction_1 = 1;
              row[tripType.sign] = true;
            } else {
              row[tripType.sign] = false;
            }
          } else {
            row[tripType.sign] = false;
          }

        })
      })
    }

  },

  computed: {

    //
    tripTypesArr() {
      let result = [];
      this.tripTypes.forEach(value => {
        let sign = getSignTitle(value.trip_type_sign).replaceAll('_', '').replaceAll('<', '').replaceAll('>', '').replaceAll(' ', '');
        if (value.direction == this.direction) {
          result.push({
            sign: sign,
            direction: value.direction,
          });
        }
      });
      return result;
    },

    // условие отображения панели "Сформированные отчеты"
    isReportsShow() {
      if (!this.isMatrixDialogVisible) {
        return false;
      } else {
        return true;
      }
    },

    items() {
      let arr = [];
      this.itemsList.map(value => {
        let stop = getStop(value.stop_id);
        if (value.direction == this.direction && stop) {
          if (stop.short_name) {
            let stopTitlePartOne = '';
            let stopTitlePartTwo = '';
            if (stop.short_name.length <= 16) {
              stopTitlePartOne = stop.short_name.substring(0, 17);
            } else {
              stopTitlePartOne = stop.short_name.substring(0, 12);
              stopTitlePartTwo = '…' + stop.short_name.substring(stop.short_name.length - 4, stop.short_name.length);
            }
            value.__name = stopTitlePartOne + stopTitlePartTwo;
          }
          arr.push(value);
        }
      });
      // сортируем если direction == 1 т.к. отображать надо в порядке от 100 до 0

      return arr;
    },

  },

  methods: {

    ttButtonStyle(col) {
      // количество выбранных галочек в строке
      let selectedCount = 0;
      // количество доступных для выбора галочек в столбце
      let checkCount = 0;

      this.items.forEach(row => {
        if (row[col.sign] == true) {
          selectedCount++;
        }
        if(row.tripTypes.includes(col.sign)){
          checkCount++;
        }
      });

      if (selectedCount == 0) {
        return 0;
      } else if ((selectedCount > 0) && (selectedCount != checkCount)) {
        return 1;
      } else if (selectedCount == checkCount) {
        return 2;
      }
    },

    // определяем все ли tripType выбраны в строке, в зависимости от этого возвращаем разный стиль
    cpButtonStyle(row) {
      // количество выбранных галочек в строке
      let selectedCount = 0;
      // количество доступных для выбора галочек в строке
      let checkCount = row.tripTypes.length;

      this.tripTypesArr.forEach(tripType => {
        if (row[tripType.sign] == true) {
          selectedCount++;
        }
      });
      if (selectedCount == 0) {
        return 0;
      } else if ((selectedCount > 0) && (selectedCount != checkCount)) {
        return 1;
      } else if (selectedCount == checkCount) {
        return 2;
      }
    },

    // onClick(row, tripType) {
    //   // console.log(tripType)
    // },

    setAllRowSelected(row) {
      if (this.cpButtonStyle(row) == 2) {
        this.tripTypesArr.forEach(tripType => {
          // не трогаем галочки, которые не принадлежат к выбранному рейсу
          if(row.tripTypes.includes(tripType.sign))
            row[tripType.sign] = false;
        });
      } else {
        this.tripTypesArr.forEach(tripType => {
          // не трогаем галочки, которые не принадлежат к выбранному рейсу
          if(row.tripTypes.includes(tripType.sign))
            row[tripType.sign] = true;
        });
      }
    },

    setAllColSelected(col) {
      if (this.ttButtonStyle(col) == 2) {
        this.items.forEach(row => {
          // не трогаем галочки, которые не принадлежат к выбранному рейсу
          if(row.tripTypes.includes(col.sign))
            row[col.sign] = false;
        });
      } else {
        this.items.forEach(row => {
          // не трогаем галочки, которые не принадлежат к выбранному рейсу
          if(row.tripTypes.includes(col.sign))
            row[col.sign] = true;
        });
      }
    },

    onClose() {
      this.$emit('onClose');
    },

    // убирать окно при щелчке в другое место (пока не будем это делать)
    onAutoHide() {
      this.$emit('onClose');
    },

  },


  // watch: {
  //
  //   base_trip_type_short(newValue) {
  //     console.log('newValue', newValue)
  //     this.itemsList.forEach(row => {
  //       this.tripTypesArr.forEach(tripType => {
  //         console.log('row[tripType.sign]', row[tripType.sign])
  //         if(newValue == row[tripType.sign]) {
  //           row[tripType.sign] = true;
  //         } else {
  //           row[tripType.sign] = false;
  //         }
  //
  //       })
  //     })
  //   }
  //
  // },

}
</script>

<style scoped>


.panel {
  position: fixed;
  max-height: 820px;
  bottom: 5px;
  right: 5px;
  min-width: 600px;
  max-width: 1900px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Параметры тени */
  font-size: 90%;
  z-index: 100;
  transition: height 5s ease-in-out;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  right: -800px;
}

.slide-enter-to,
.slide-leave-from {
  right: 5px;
}

.bg-primary {
  height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.input {
  margin-top: 1px;
}

</style>