<template>
  <svg id="ruler" :height="height" :width="width" style="border: 1px solid black;">
    <line
        v-for="(value, index) in (hoursArr.length * 10)"
        :key="index"
        :x1="getXCoord(index)"
        :y1="getYCoord(index)"
        :x2="getXCoord(index)"
        :y2="start_y + 2"
        style="stroke:rgb(0,0,0); stroke-width:1"/>
    <text
        v-for="(value, index) in (hoursArr)"
        :key="index"
        :x="getXTextCoord(value, index)"
        :y="start_y - 10"
        fill="black"
    >{{ value }}
    </text>
    <text
        x="2"
        :y="start_y + 20"
        fill="gray"
        font-size="smaller"
    >Кол. ТС:
    </text>
    <!--       текст по массиву ТС                   -->
    <text
        v-for="(value, index) in (tsCountArr)"
        :key="index"
        :x="getXTsCoord(value, index)"
        :y="start_y + 20"
        fill="gray"
        font-size="smaller"
    >{{ value }}
    </text>
  </svg>
</template>

<script>

// отрисовка панельки с линейкой часов для графика во всплывающем окне в rmtGen

import {nextTick} from "vue";

export default {
  name: "RulerSVG",
  emits: ['updateRulerWidth'],
  props: [
  // массив часов верхней линейки
    'hoursArr',
  // массив ТС для отображения количества ТС по каждому графику
    'tsCountArr',
// (получаем некоторые из значений ниже из родителя т.к. это значение нужно передать и в панельку на которой отрисовываются графики)
  // размер одного деления линейки часов (одно деление - 6 минут)
    'oneDiv',
  // Отступ слева для начала линейки
    'start_x',
  // Отступ сверху для начала линейки
    'start_y',
  // ширина окна c графиком
    'width',
  // высота окна с графиком
    'height',
  ],
  watch : {

    async hoursArr() {
      await nextTick();
      // проверяем полностью ли влезла линейка сверху т.к. из-за того, что добавляются 8 часов к часам пришедшим с сервера
      // графики никогда по x  не будут выходить за значения линейки
      let svg = document.getElementById("ruler");
      let bbox = svg.getBBox();
      this.$emit('updateRulerWidth',(bbox.width + this.start_x));
      svg.setAttribute("width", (bbox.width + this.start_x) + "px");
    }

  },
  methods: {

    // получаем x координаты для шкалы с учетом количества часов
    getXCoord(index) {
      return ((index * this.oneDiv) + this.start_x);
    },

    // получаем x координаты для шкалы с учетом количества часов
    getYCoord(index) {
      if (index == 0 || (index % 10 === 0)) {
        return this.start_y - 7 + 'px';
      } else if (index % 5 === 0) {
        return this.start_y - 5 + 'px';
      } else {
        return this.start_y - 3 + 'px';
      }
    },

    getXTextCoord(value, index) {
      let val = (this.start_x + (index * this.oneDiv * 10));
      if (value < 10) {
        return (val - 4) + 'px';
      } else {
        return (val - 8) + 'px';
      }
    },

    getXTsCoord(value, index) {
      let val = (this.start_x + (15) + (index * this.oneDiv * 10));
      if (value < 10) {
        return (val - 4) + 'px';
      } else {
        return (val - 8) + 'px';
      }
    },

  },


  async mounted() {

  },
}
</script>

<style scoped>

</style>