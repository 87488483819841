<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-rmt-schedule-variants"></edit-table-panel>
</template>

<script>


import {getMovementKind, getScheduleType, getScheduleVariantStatus, getSeason} from "@/store/RmtHelpers";

export default {
  props: ['isLoading', 'scheduleVariants'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "schedule_variant_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "route_variant_id",
          caption: "Код варианта маршрута",
          sortType: 'number',
          show: false
        },
        {
          field: "__seasonName",
          caption: "Сезон",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "__scheduleTypeName",
          caption: "Тип расписания",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "date_begin",
          caption: "Дата начала",
          sortType: 'string',
          sorted: 'dsc'
        },
        {
          field: "date_end",
          caption: "Дата окончания",
          sortType: 'string',
        },
        {
          field: "__scheduleVariantStatusName",
          caption: "Статус",
          sortType: 'number',
        },
        {
          field: "__movementKindName",
          caption: "Вид движения",
          sortType: 'number',
        },
        {
          field: "reg_num",
          caption: "Регистрационный номер",
          sortType: 'string',
          show: false,
        },
        {
          field: "date_insert",
          caption: "Дата составления",
          sortType: 'string',
          show: false,
        },
        {
          field: "__regulationPointName",
          caption: "Пункт регулирования",
          sortType: 'number',
          show: false,
        },
        {
          field: "is_working",
          caption: "Действует",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_working) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          show: false,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      const scheduleVariants = this.scheduleVariants;

      return scheduleVariants.map(schVar => {

        schVar.__id = schVar.route_variant_id;

        const season = getSeason(schVar.season_id);
        const scheduleType = getScheduleType(schVar.schedule_type_id);
        const scheduleVariantStatus = getScheduleVariantStatus(schVar.schedule_variant_status_id);
        const movementKind = getMovementKind(schVar.movement_kind_id);
        let regulationPoint = "";
        if(schVar.regulation_point == 0){
          regulationPoint = "AB";
        } else if (schVar.regulation_point == 1) {
          regulationPoint = "A";
        } else if (schVar.regulation_point == 2) {
          regulationPoint = "B";
        }

        schVar.__seasonName = season && season.long_name || '';
        schVar.__scheduleTypeName = scheduleType && scheduleType.long_name || '';
        schVar.__scheduleVariantStatusName = scheduleVariantStatus && scheduleVariantStatus.long_name || '';
        schVar.__movementKindName = movementKind && movementKind.long_name || '';
        schVar.__regulationPointName = regulationPoint;

        return schVar
      });
    },
  },
};
</script>
