import MultipassTitlePage from '../pages/multipass/MultipassTitlePage.vue';
import StopsPage from "@/pages/multipass/StopsPage";
import RouteKindsPage from "@/pages/multipass/RouteKindsPage";
import StopTypesPage from "@/pages/multipass/StopTypesPage";
import StopstationTypesPage from "@/pages/multipass/StopstationTypesPage";
import TariffTypesPage from "@/pages/multipass/TariffTypesPage";
import TransferKindsPage from "@/pages/multipass/TransferKindsPage";
import RouteTypesPage from "@/pages/multipass/RouteTypesPage";
import RoutesPage from "@/pages/multipass/RoutesPage";
import RouteVariantsPage from "@/pages/multipass/routes/RouteVariantsPage";
import TripTypesPage from "@/pages/multipass/routes/route_variants/TripTypesPage";
import TripTypeCardPage from "@/pages/multipass/routes/route_variants/trip_types/TripTypeCardPage";
import RouteGroupsPage from "@/pages/multipass/RouteGroupsPage";
import GroupRouteLinksPage from "@/pages/multipass/routes_groups/GroupRouteLinksPage";
import RouteGroupLinksPage from "@/pages/multipass/routes/RouteGroupLinksPage";
import RouteVariantDepotLinksPage from "@/pages/multipass/routes/route_variants/RouteVariantDepotLinksPage";
import StopPavilionKindsPage from "@/pages/multipass/StopPavilionKindsPage";
import StopPavilionTypesPage from "@/pages/multipass/StopPavilionTypesPage";
import TariffZoneSystemsPage from "@/pages/multipass/TariffZoneSystemsPage";
import TariffZonesPage from "@/pages/multipass/tariff_zone_systems/TariffZonesPage";
import StopstationsPage from "@/pages/multipass/StopstationsPage";
import StopPavilionsPage from "@/pages/multipass/stops/StopPavilionsPage";
import StopDocsPage from "@/pages/multipass/stops/StopDocsPage";
import StopPavilionDocsPage from "@/pages/multipass/stops/stop_pavilions/StopPavilionDocsPage";
import StopstationDocsPage from "@/pages/multipass/stopstations/StopstationDocsPage";
import RouteDocsPage from "@/pages/multipass/routes/RouteDocsPage";
import RouteVariantDocsPage from "@/pages/multipass/routes/route_variants/RouteVariantDocsPage";
import StopDocFilesPage from "@/pages/multipass/stops/stop_docs/StopDocFilesPage";
import StopPavilionDocFilesPage from "@/pages/multipass/stops/stop_pavilions/stop_pavilion_docs/StopPavilionDocFilesPage";
import StopstationDocFilesPage from "@/pages/multipass/stopstations/stopstation_docs/StopstationDocFilesPage";
import RouteDocFilesPage from "@/pages/multipass/routes/route_docs/RouteDocFilesPage";
import RouteVariantDocFilesPage
    from "@/pages/multipass/routes/route_variants/route_variant_docs/RouteVariantDocFilesPage";
import TariffZoneFaresPage from "@/pages/multipass/tariff_zone_systems/TariffZoneFaresPage";
import StopZonesPage from "@/pages/multipass/stops/StopZonesPage";
import TripTypeZonesPage from "@/pages/multipass/routes/route_variants/trip_type_zones/TripTypeZonesPage";
import StopstationZonesPage from "@/pages/multipass/stopstations/StopstationZonesPage";
import GraphPage from "@/pages/multipass/GraphPage";
import TariffZoneZonesPage from "@/pages/multipass/tariff_zone_systems/tariff_zones/TariffZoneZonesPage";
import TariffKindsPage from "@/pages/multipass/TariffKindsPage";
import MainPage from "@/pages/main/MainPage";

export default {
    path: '/multipass',
    component: MainPage,
    children: [
        {
            path: 'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/routeVariantDocs/:route_variant_doc_id(\\d+)?/routeVariantDocFiles/:op(add|del)/:id(\\d+)?',
            component: RouteVariantDocFilesPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/routeVariantDocs/:route_variant_doc_id(\\d+)?/routeVariantDocFiles',
            component: RouteVariantDocFilesPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/routeVariantDocs/:op(show|add|del|edit)/:id(\\d+)?',
            component: RouteVariantDocsPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/routeVariantDocs',
            component: RouteVariantDocsPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/tripTypeZones/:op(del|edit)/:id(\\d+)?',
            component: TripTypeZonesPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/tripTypeZones',
            component: TripTypeZonesPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeDocs/:route_doc_id(\\d+)?/routeDocFiles/:op(add|del)/:id(\\d+)?',
            component: RouteDocFilesPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeDocs/:route_doc_id(\\d+)?/routeDocFiles',
            component: RouteDocFilesPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeDocs/:op(show|add|del|edit)/:id(\\d+)?',
            component: RouteDocsPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeDocs',
            component: RouteDocsPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/tripTypes',
            component: TripTypesPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/depots',
            component: RouteVariantDepotLinksPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeVariants/:op(show|add|del|edit|status|activate|deactivate|paste|change)/:id(\\d+)?',
            component: RouteVariantsPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeVariants',
            component: RouteVariantsPage,
            props: true
        },
        {
            path: 'routes/:op(show|add|del|edit|status)/:id(\\d+)?',
            component: RoutesPage,
            props: true
        },
        {
            path: 'routes/:id(\\d+)?/routeGroupLinks',
            component: RouteGroupLinksPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/tripTypes/:trip_type_id(\\d+)?/tripTypeCard',
            component: TripTypeCardPage,
            props: true
        },
        {
            path: 'routes/:route_id(\\d+)?/routeVariants/:route_variant_id(\\d+)?/tripTypes/:op(show|add|del|edit|status|activate|deactivate|paste)/:id(\\d+)?',
            component: TripTypesPage,
            props: true
        },
        {
            path: 'routes',
            component: RoutesPage,
        },
        {
            path: 'stops/:stop_id(\\d+)?/stopDocs/:stop_doc_id(\\d+)?/stopDocFiles/:op(add|del)/:id(\\d+)?',
            component: StopDocFilesPage,
            props: true
        },
        {
            path: 'stops/:stop_id(\\d+)?/stopDocs/:stop_doc_id(\\d+)?/stopDocFiles',
            component: StopDocFilesPage,
            props: true
        },
        {
            path: 'stops/:stop_id(\\d+)?/stopPavilions/:stop_pavilion_id(\\d+)?/stopPavilionDocs/:stop_pavilion_doc_id(\\d+)?/stopPavilionDocFiles/:op(add|del)/:id(\\d+)?',
            component: StopPavilionDocFilesPage,
            props: true
        },
        {
            path: 'stops/:stop_id(\\d+)?/stopPavilions/:stop_pavilion_id(\\d+)?/stopPavilionDocs/:stop_pavilion_doc_id(\\d+)?/stopPavilionDocFiles',
            component: StopPavilionDocFilesPage,
            props: true
        },
        {
            path: 'stops/:stop_id(\\d+)?/stopPavilions/:stop_pavilion_id(\\d+)?/stopPavilionDocs/:op(show|add|del|edit)/:id(\\d+)?',
            component: StopPavilionDocsPage,
            props: true
        },
        {
            path: 'stops/:stop_id(\\d+)?/stopPavilions/:stop_pavilion_id(\\d+)?/stopPavilionDocs',
            component: StopPavilionDocsPage,
            props: true
        },
        {
            path: 'stops/:stop_id(\\d+)?/stopDocs/:op(show|add|del|edit)/:id(\\d+)?',
            component: StopDocsPage,
            props: true
        },
        {
            path: 'stops/:stop_id(\\d+)?/stopDocs',
            component: StopDocsPage,
            props: true
        },
        {
            path: 'stops/:stop_id(\\d+)?/stopZones',
            component: StopZonesPage,
            props: true
        },
        {
            path: 'stops/:stop_id(\\d+)?/stopPavilions/:op(show|add|del|edit)/:id(\\d+)?',
            component: StopPavilionsPage,
            props: true
        },
        {
            path: 'stops/:stop_id(\\d+)?/stopPavilions',
            component: StopPavilionsPage,
            props: true
        },
        {
            path: 'stops/:op(show|add|del|edit)/:id(\\d+)?',
            component: StopsPage,
            props: true
        },
        {
            path: 'stops',
            component: StopsPage
        },
        {
            path: 'stopstations/:stopstation_id(\\d+)?/stopstationDocs/:stopstation_doc_id(\\d+)?/stopstationDocFiles/:op(add|del)/:id(\\d+)?',
            component: StopstationDocFilesPage,
            props: true
        },
        {
            path: 'stopstations/:stopstation_id(\\d+)?/stopstationDocs/:stopstation_doc_id(\\d+)?/stopstationDocFiles',
            component: StopstationDocFilesPage,
            props: true
        },
        {
            path: 'stopstations/:stopstation_id(\\d+)?/stopstationDocs/:op(show|add|del|edit)/:id(\\d+)?',
            component: StopstationDocsPage,
            props: true
        },
        {
            path: 'stopstations/:stopstation_id(\\d+)?/stopstationZones',
            component: StopstationZonesPage,
            props: true
        },
        {
            path: 'stopstations/:stopstation_id(\\d+)?/stopstationDocs',
            component: StopstationDocsPage,
            props: true
        },
        {
            path: 'stopstations/:op(show|add|del|edit)/:id(\\d+)?',
            component: StopstationsPage,
            props: true
        },
        {
            path: 'stopstations',
            component: StopstationsPage
        },
        {
            path: 'tariffZoneSystems/:tariff_zone_system_id(\\d+)?/tariffZoneFares/:op(show|add|del|edit)/:id(\\d+)?',
            component: TariffZoneFaresPage,
            props: true
        },
        {
            path: 'tariffZoneSystems/:tariff_zone_system_id(\\d+)?/tariffZoneFares',
            component: TariffZoneFaresPage,
            props: true
        },
        {
            path: 'tariffZoneSystems/:tariff_zone_system_id(\\d+)?/tariffZones/tariffZoneZones/:op(add|del|edit)/:id(\\d+)?',
            component: TariffZoneZonesPage,
            props: true
        },
        {
            path: 'tariffZoneSystems/:tariff_zone_system_id(\\d+)?/tariffZones/tariffZoneZones',
            component: TariffZoneZonesPage,
            props: true
        },
        {
            path: 'tariffZoneSystems/:tariff_zone_system_id(\\d+)?/tariffZones/:op(show|add|del|edit)/:id(\\d+)?',
            component: TariffZonesPage,
            props: true
        },
        {
            path: 'tariffZoneSystems/:tariff_zone_system_id(\\d+)?/tariffZones',
            component: TariffZonesPage,
            props: true
        },
        {
            path: 'tariffZoneSystems/:op(show|add|del|edit)/:id(\\d+)?',
            component: TariffZoneSystemsPage,
            props: true
        },
        {
            path: 'tariffZoneSystems',
            component: TariffZoneSystemsPage,
        },
        {
            path: 'routeGroups/:id(\\d+)?/groupRouteLinks',
            component: GroupRouteLinksPage,
            props: true
        },
        {
            path: 'routeGroups/:op(show|add|del|edit)/:id(\\d+)?',
            component: RouteGroupsPage,
            props: true
        },
        {
            path: 'routeGroups',
            component: RouteGroupsPage
        },
        {
            path: 'routeKinds',
            component: RouteKindsPage
        },
        {
            path: 'stopTypes',
            component: StopTypesPage
        },
        {
            path: 'stopPavilionKinds',
            component: StopPavilionKindsPage
        },
        {
            path: 'stopPavilionTypes',
            component: StopPavilionTypesPage
        },
        {
            path: 'stopstationTypes',
            component: StopstationTypesPage
        },
        {
            path: 'tariffTypes',
            component: TariffTypesPage
        },
        {
            path: 'tariffKinds',
            component: TariffKindsPage
        },
        {
            path: 'transferKinds',
            component: TransferKindsPage
        },
        {
            path: 'routeTypes/:op(show|add|del|edit)/:id(\\d+)?',
            component: RouteTypesPage,
            props: true
        },
        {
            path: 'routeTypes',
            component: RouteTypesPage
        },
        {
            path: 'graph',
            component: GraphPage
        },
        {
            path: '',
            component: MultipassTitlePage
        },
        {
            path: ':pathMatch(.*)*',
            component: MultipassTitlePage
        }
    ]
}