import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПОВ ПОЛЬЗОВАТЕЛЕЙ К ДАННЫМ

export default {

    actions: {
        // запрос списка объектов
        doFetchUserAccesses: (context, payload) => {
            return DoGet(
                '/access/userAccesses?uid=' + payload.uid,
                null,
                [],
                'Запрос доступа пользователя к данным категорий',
                false,
                true,
            )
        },

        // добавление объекта
        doAddUserAccess: (context, payload) => {
            return DoPost(
                '/access/userAccesses',
                payload,
                null,
                'Добавление доступа пользователя к данным категории'
            )
        },

        // изменение объекта
        doEditUserAccess: (context, payload) => {
            return DoPut(
                '/access/userAccesses/'+ payload.uid + '/' + payload.access_category_id,
                payload,
                null,
                'Изменение доступа пользователя к данным категории'
            )
        },

        // удаление объекта
        doDelUserAccess: (context, payload) => {
            return DoDelete(
                '/access/userAccesses/'+ payload.uid + '/' + payload.access_category_id,
                null,
                'Удаление доступа пользователя к данным категории'
            )
        }
    }
}
