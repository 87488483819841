<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span>Список маршрутов</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">

          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!--          Поставить галочки всем маршрутам на экране-->
            <template #preLeftCustomButton>
              <button style="padding: 0px; border-width: 0px; background-color: white; margin-top: 0.2rem"
                      @click="setAllSelected">
                <i :class='getSelectedRoutes.length == routes.length ? "fas fa-check-square fa-2xl fa-2x" : "far fa-check-square fa-2xl fa-2x"'
                   title="Выделить всё"
                   :style='getSelectedRoutes.length == routes.length ? "color: #007BFF" : "color: #000000"'></i>
              </button>
            </template>

            <!--            Снять галочки со всех маршщрутов   <font-awesome-icon icon="fas fa-check-square" /> -->
            <template #preLeftCustomButton2>
              <button style="padding: 0px; border-width: 0px; background-color: white; margin-top: 0.2rem"
                      @click="setAllClear">
                <i :class='getSelectedRoutes.length == 0 ? "fas fa-square fa-2x" : "far fa-square fa-2x"'
                   title="Снять все" :style='getSelectedRoutes.length == 0 ? "color: #007BFF" : "color: #000000"'></i>
              </button>
            </template>

            <!--        Выбор даты начала  -->
            <template #leftCustomButton>
              <input-date
                  id="startDate"
                  v-model="startDate"
                  class="form-control"
                  @change="setDate"
              >
              </input-date>
            </template>

            <!--        Выбор даты конца-->
            <template #leftCustomButton2>
              <input-date
                  id="endDate"
                  v-model="endDate"
                  class="form-control"
                  @change="setDate"
              >
              </input-date>
            </template>

            <!-- Кнопка связь  -->
            <template #leftCustomButton4>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Аналитические формы ({{ getSelectedRoutes.length }} {{ getRouteTitle }} из {{ routes.length }})
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="passengerTrafficDayByGraphPageLink">Пассажиропоток за
                    сутки по графикам
                  </dropdown-item>
                  <dropdown-item router-link :to="factTripExtsLink">Пассажиропоток по рейсам
                  </dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>


            <!-- Группы-->
            <template #leftCustomButton5>
              <route-groups-select class="form-control" v-model="routeGroupId"
                                   @change="setRouteGroupId"></route-groups-select>
            </template>


          </crud-form>

        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <asmpp-routes-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :routeGroupId="routeGroupId"
              :selectedRow="selectedRow"
              :chosenRows="chosenRows"
              :routes="routes"
              @selection="setSelection"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></asmpp-routes-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр  -->
  <route-dialog
      v-if="isDialogVisible"
      :item="selectedRow"
      :op="op"
      @onCancel="onRejectOperation"
  >
  </route-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import RouteDialog from "@/components/multipass/routes/RouteDialog";
import RouteGroupsSelect from "@/components/multipass/route_groups/RouteGroupsSelect";
import PageMixin from "@/pages/share/PageMixin";
import AsmppRoutesEditTable from "@/components/asmpp/routes/AsmppRoutesEditTable";
import InputDate from "@/components/ui/custom/InputDate";
import {dateToND, dateToString, getDateMinusMonth, ndToDate, sklonenie} from "@/lib";

export default {
  mixins: [PageMixin],

  components: {
    InputDate,
    AsmppRoutesEditTable,
    RouteDialog,
    RouteGroupsSelect
  },

  props: [
    // операция show передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      //группа
      routeGroupId: null,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // оригинальный список строк
      routes: [],
      // режим отметки строк
      chosenRows: '',
      // дата начала периода
      startDate: dateToString(getDateMinusMonth(ndToDate(dateToND() - 1))),
      // дата конца периода
      endDate: dateToString(ndToDate(dateToND() - 1)),
    }
  },

  computed: {

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show')
    },

    // условие отключения кнопки перехода к рейсам
    linkDisabled() {
      return this.getSelectedRoutes.length == 0
    },

    // получаем выбранные галочкой маршруты
    getSelectedRoutes() {
      return this.routes.filter(route => {
        if (route.__isSelected == true) {
          return route;
        }
      })
    },

    getRouteTitle() {
      return sklonenie(this.getSelectedRoutes.length, ['маршрут', 'маршрута', 'маршрутов'])
    },


    // ссылка для перехода к рейсам
    factTripExtsLink() {
      if (!this.linkDisabled) {
        let routeIDs = [];
        this.routes.map(rt => {
          if (rt.__isSelected)
            routeIDs.push(rt.route_id)
        });

        let routeForm = '';
        routeIDs.map(rt => {
          routeForm = routeForm + '/' + rt;
        });

        return '/asmpp/routes/' + dateToND(new Date(this.startDate)) + '/' + dateToND(new Date(this.endDate)) + routeForm + '/factTripExts';
      } else {
        return '';
      }
    },

    // ссылка для перехода к пассажиропотоку за сутки по графикам расписания маршрута за период дат
    passengerTrafficDayByGraphPageLink() {
      if (!this.linkDisabled) {
        let routeIDs = [];
        this.routes.map(rt => {
          if (rt.__isSelected)
            routeIDs.push(rt.route_id);
        });

        let routeForm = '';
        routeIDs.map(rt => {
          routeForm = routeForm + '/' + rt;
        });

        return '/asmpp/routes/' + dateToND(new Date(this.startDate)) + '/' + dateToND(new Date(this.endDate)) + routeForm + '/passengerTrafficDayByGraph';
      } else {
        return '';
      }
    },

  },

  methods: {

    // сохраняем даты в стор
    setDate() {
      this.$store.commit('asmpp/setStartDate', this.startDate);
      this.$store.commit('asmpp/setEndDate', this.endDate);
    },

    // сохраняем routeGroupId в store
    setRouteGroupId() {
      this.$store.commit('asmpp/setRoutesRouteGroupId', this.routeGroupId);
    },

    // ставим все строки выбранными
    setAllSelected() {
      this.chosenRows = 'selectFiltered';
    },

    // ставим все строки невыбранными
    setAllClear() {
      this.chosenRows = 'clearAll';
    },

    // убираем режим выделения или очищения галочек строк
    setSelection() {
      this.chosenRows = '';
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // выполнено двойное нажатие строки в таблице (открываем просмотр)
    onTableShow(item) {
      this.$router.replace("/asmpp/routes/show/" + item.route_id);
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/asmpp/routes");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        // проверяем есть ли даты в сторе, если есть, то подставляем
        let startDate = this.$store.getters['asmpp/getStartDate'];
        let endDate = this.$store.getters['asmpp/getEndDate'];
        let routeGroupId = this.$store.getters['asmpp/getRoutesRouteGroupId'];
        if (startDate) {
          this.startDate = startDate;
        }
        if (endDate) {
          this.endDate = endDate;
        }
        if (routeGroupId) {
          this.routeGroupId = routeGroupId;
        }

        await this.$store.dispatch('ref/doFetchTransportTypes');
        await this.$store.dispatch('multipass/doFetchRouteKinds');
        await this.$store.dispatch('multipass/doFetchRouteTypes');
        await this.$store.dispatch('multipass/doFetchTransferKinds');
        await this.$store.dispatch('multipass/doFetchRouteStatuses');
        await this.$store.dispatch('multipass/doFetchRouteGroups');
        await this.$store.dispatch('multipass/doFetchRouteGroupLinks');
        await this.$store.dispatch('multipass/doFetchRoutes', {force});
        let routes = this.$store.getters["multipass/getRoutes"];
        if (routes)
          this.routes = routes;
      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

