<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-multipass-trip-types"> </edit-table-panel>
</template>

<script>

import {getStop} from "@/store/MultipassHelpers";
import {getSignTitle} from "@/lib";

export default {
  props: ['isLoading', 'rows'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "trip_type_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "title",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true
        },
        {
          field: "trip_type_sign",
          caption: "Тип рейса",
          sortType: 'sign',
          sorted: 'asc',
          dataAlign: 'left',
          headerAlign: 'left',
          displayType: 'html',
          displayFn(row) {
            return getSignTitle(row.trip_type_sign)
          },
        },
        {
          field: "__stop_begin_name",
          caption: "Начальная остановка",
          sortType: 'string',
        },
        {
          field: "__stop_end_name",
          caption: "Конечная остановка",
          sortType: 'string',
        },
        {
          field: "is_production",
          caption: "Производ.",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_production) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "weight",
          caption: "Вес",
          sortType: 'number',
        },
        {
          field: "length",
          caption: "Длина,км.",
          sortType: 'number',
          dataAlign: 'right',
          headerAlign: 'right',
          displayFn(row) {
            if (Number.isFinite(row.length)) {
              return Number(row.length / 1000).toFixed(3);
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.rows.map(type => {
        const stopBegin = getStop(type.stop_id_begin);
        const stopEnd = getStop(type.stop_id_end);

        type.__id = type.trip_type_id;
        type.__stop_begin_name = stopBegin && stopBegin.long_name || '';
        type.__stop_end_name = stopEnd && stopEnd.long_name || '';

        if (type.is_production == true) {
          type["__rowStyle"] = {
            backgroundColor: this.$store.getters["settings/getProductionReisColor"],
          }
        } else {
          type["__rowStyle"] = {
            backgroundColor: this.$store.getters["settings/getTechnicalReisColor"],
          }
        }
        return type
      });
    },
  },
};
</script>
