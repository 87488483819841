import store from './Store';

// вернуть модуль
export function getModule(module_id) {
    return store.getters["system/getModules"].find(module => module.module_id == module_id);
}

// вернуть отчет
export function getReport(report_id) {
    return store.getters["system/getReports"].find(reportGroup => reportGroup.report_id == report_id);
}

// вернуть группу отчетов
export function getReportGroup(report_group_id) {
    return store.getters["system/getReportGroups"].find(reportGroup => reportGroup.report_group_id == report_group_id);
}
