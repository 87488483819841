import {DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ ТРАНСПОРТНЫХ СРЕДСТВ НА МАРШРУТАХ

export default {
    state() {
        return {
            // список транмпортных средств на маршрутах
            curWorkTransport: [],
            // время обновления кэша new Date().getTime()
            curWorkTransportCache: null,
            // время жизни кэша в мс (null - бесконечно)
            curWorkTransportCacheTimeout: null
        }
    },
    getters: {
        // вернуть список маршрутов
        getCurWorkTransport(state) {
            return state.curWorkTransport
        },
    },
    mutations: {
        // установить новый список маршрутов
        setCurWorkTransport(state, payload) {
            state.curWorkTransport.push(payload);
            state.curWorkTransportCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка
        doFetchCurWorkTransport: (context, payload) => {
            return DoPost(
                '/kvr/getCurWorkTransport',
                null,
                'kvr/setCurWorkTransport',
                'Запрос списка транспортных средств на маршрутах',
                false,
                payload && payload.force,
                context.state.curWorkTransportCache,
                context.state.curWorkTransportCacheTimeout
            )
        },
    }
}