import store from './Store';

// вернуть тип документа
export function getDocType(doc_type_id) {
    return store.getters["doc/getDocumentTypes"].find(doc => doc.doc_type_id == doc_type_id);
}

// вернуть формат файла
export function getFileFormat(file_format_id) {
    return store.getters["doc/getFileFormats"].find(format => format.file_format_id == file_format_id);
}

// вернуть формат файла
export function getFileFormatByFiletype(file_format_name) {
    return store.getters["doc/getFileFormats"].find(format => format.file_format_name == file_format_name);
}