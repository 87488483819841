<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-kvr-contract-route-links"> </edit-table-panel>
</template>

<script>
import {getTransportType} from "@/store/RefHelpers";
import {kvrSortFn, quickSort} from "@/lib";

export default {
  props: ['id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__checked",
          caption: "Вкл",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.__checked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "route_num",
          caption: "Номер",
          sortType: 'routeNum',
          isFilterable: true,
        },
        {
          field: "route_title",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__transportTypeName",
          caption: "Вид транспорта",
          sortType: 'string',
        },
        {
          field: "reg_num",
          caption: "Рег.номер",
          sortType: 'string',
          show: false
        },
        {
          field: "route_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },

      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      const routes = this.$store.getters["kvr/getRoutesExt"];
      const links = this.$store.getters["kvr/getContractRouteLinks"];

      let rows =  routes.map(route => {
        const transportType = getTransportType(route.transport_type_id);
        route.__transportTypeName = transportType && transportType.long_name || '';
        route.__id = route.depot_group_id;
        route.__checked = links.some(link => link.contract_id == this.id && link.route_id == route.route_id)
        return route;
      });

      // сортируем массив
      quickSort(rows, (leftItem, rightItem) => {
        return 1 * kvrSortFn(leftItem, rightItem);
      });

      return rows;
    },
  },
};
</script>
