<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-access-access_categories"> </edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "access_category_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["access/getAccessCategories"].map(accessCategories => {
        accessCategories.__id = accessCategories.access_category_id;
        return accessCategories;
      });
    },
  }
};
</script>
