import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОКУМЕНТОВ АВТОСТАНЦИЙ

export default {
    actions: {
        // запрос объекта
        doFetchStopstationDoc: (context, payload) => {
            return DoGet(
                '/multipass/stopstationDocs/' + payload.stopstation_doc_id,
                null,
                {},
                'Запрос документа автостанции',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchStopstationDocs: (context, payload) => {
            return DoGet(
                '/multipass/stopstationDocs?stopstation_id=' + payload.stopstation_id,
                null,
                [],
                'Запрос документов автостанции',
                false,
                true,
            )
        },

        // добавление объекта
        doAddStopstationDoc: (context, payload) => {
            return DoPost(
                '/multipass/stopstationDocs',
                payload,
                null,
                'Добавление документа автостанции'
            )
        },

        // изменение объекта
        doEditStopstationDoc: (context, payload) => {
            return DoPut(
                '/multipass/stopstationDocs/'+ payload.stopstation_doc_id,
                payload,
                null,
                'Изменение документа автостанции'
            )
        },

        // удаление объекта
        doDelStopstationDoc: (context, payload) => {
            return DoDelete(
                '/multipass/stopstationDocs/'+ payload.stopstation_doc_id,
                null,
                'Удаление документа автостанции'
            )
        }
    }
}