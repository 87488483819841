<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to="routeVariantsLink">Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span>Предприятия на маршруте {{ routeVariantPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{check: true, uncheck: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onCheck="onTableCheck"
              @onUncheck="onTableUncheck"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          ></crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <route-depot-links-edit-table
              :id="route_variant_id"
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :rows="rows"
              @onRowSelect="onRowSelect"
          ></route-depot-links-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import RouteDepotLinksEditTable from "@/components/multipass/route_variant_depot_links/RouteVariantDepotLinksEditTable";
import {addMutation, delMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    RouteDepotLinksEditTable,
  },

  props: [
    // идентификатор редактируемого объекта
    "id",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // связь варианта маршрута с предприятими
      rows: [],
    }
  },

  computed: {
      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            check: !!this.routeVariant && !!this.selectedRow && !this.selectedRow.__checked,
            uncheck: !!this.routeVariant &&  !!this.selectedRow && this.selectedRow.__checked,
            refresh: true
          }
      },
      // ссылка на варианта маршрута
      routeVariantsLink(){
        return `/multipass/routes/${this.route_id}/routeVariants`
      }
  },
  methods: {
    // нажата кнопка Включить
    onTableCheck() {
      this.linkObjects();
    },

    // нажата кнопка Выключить
    onTableUncheck() {
      this.unlinkObjects();
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchDepots');
        await this.$store.dispatch('ref/doFetchOrganizations');

        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id
        }); if (!route) return;
        this.route = route;

        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id,
          force: force
        }); if (!routeVariant) return;
        this.routeVariant = routeVariant;

        const rows = await this.$store.dispatch('multipass/doFetchRouteVariantDepotLinks', {
          route_variant_id: this.route_variant_id,
          force: true
        }); if (!rows) return;
        this.rows = rows;

      }
      finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // связываем объекты
    async linkObjects() {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('multipass/doAddDepotToRouteVariant', {
          route_variant_id: this.routeVariant.route_variant_id,
          depot_id: this.selectedRow.depot_id
        });
        // мутируем массив
        addMutation(this.rows, 'depot_id', newItem);

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем связь объектов
    async unlinkObjects() {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('multipass/doDelDepotFromRouteVariant', {
          route_variant_id: this.routeVariant.route_variant_id,
          depot_id: this.selectedRow.depot_id
        });
        // мутируем массив
        delMutation(this.rows, 'depot_id', newItem);

      }
      finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

