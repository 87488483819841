import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДАННЫХ СИСТЕМНЫХ СОБЫТИЙ

export default {
    actions: {
        // запрос списка объектов
        doFetchSystemEvents: () => {
            return DoGet(
                '/logger/systemEvents',
                null,
                [],
                'Запрос списка всех системных событий',
                false,
                true
            )
        },

        // запрос отфильтрованного списка объектов
        doFetchSystemEventsFiltered: (context, payload) => {
            let url = '/logger/systemEvents';
            // если есть хоть один дополнительный параметр добавляем в запрос "&"
            if (payload.last_count || payload.module_id ||  payload.title || payload.service_name) {
                url = url + "?";
            }
            // если указанно количество последних записей
            if(payload.last_count) {
                url = url + "last_count=" + payload.last_count + "&";
            }
            // если указан идентификатор модуля
            if(payload.module_id) {
                url = url + "module_id=" + payload.module_id + "&";
            }
            // если указанно количество последних записей
            if(payload.title) {
                url = url + "title=" + payload.title + "&";
            }
            // если указан идентификатор модуля
            if(payload.service_name) {
                url = url + "service_name=" + payload.service_name;
            }
            // если url, в результате работы логики выше, заканчивается на "&" удаляем его
            if (url.charAt(url.length-1) == '&') {
                url = url.substring(0, (url.length-1));
            }

            return DoGet(
                url,
                null,
                [],
                'Запрос отфильтрованного списка системных событий',
                false,
                true
            )
        },
    }
}
