import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ТИПОВ БАЛАНСИРОВКИ ПАССАЖИРОПОТОКА В РЕЙСЕ

export default {
    state() {
        return {
            // список объектов
            balanceTypes: [],
            // время обновления кэша new Date().getTime()
            balanceTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            balanceTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getBalanceTypes(state) {
            return state.balanceTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setBalanceTypes(state, payload) {
            state.balanceTypes = payload;
            state.balanceTypesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос список объектов
        doFetchBalanceTypes: (context, payload) => {
            return DoGet(
                '/asmpp/balanceTypes',
                'asmpp/setBalanceTypes',
                [],
                'Запрос типов тарифов',
                false,
                payload && payload.force,
                context.state.balanceTypesCache,
                context.state.balanceTypesCacheTimeout
            )
        },

        // очищает кэш
        doClearBalanceTypes: (context, payload) => {
            return DoClearCache(
                'asmpp/setBalanceTypes',
                [],
                payload && payload.force,
                context.state.balanceTypesCache,
                context.state.balanceTypesCacheTimeout
            )
        },
    }
}