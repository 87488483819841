import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ТРАНСПОРТНЫХ ПРЕДПРИЯТИЙ

export default {
    state() {
        return {
            // список объектов
            linkedEntities: [],
            // время обновления кэша new Date().getTime()
            linkedEntitiesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            linkedEntitiesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getLinkedEntities(state) {
            return state.linkedEntities;
        },
    },
    mutations: {
        // установить новый список объектов
        setLinkedEntities(state, payload) {
            state.linkedEntities = payload;
            state.linkedEntitiesCache = new Date().getTime()
        },
        // добавить объект
        addLinkedEntity(state, payload) {
            state.linkedEntities.push(payload)
        },
        // изменить объект
        editLinkedEntity(state, payload) {
            const index = state.linkedEntities.findIndex(linkEnt => linkEnt.linked_entity_id == payload.linked_entity_id);
            if (index >= 0) {
                state.linkedEntities[index] = payload;
            }
        },
        // удалить объект
        delLinkedEntity(state, payload) {
            const index = state.linkedEntities.findIndex(linkEnt => linkEnt.linked_entity_id == payload.linked_entity_id);
            state.linkedEntities.splice(index, 1);
        }
    },
    actions: {

        // запрос списка объектов
        doFetchLinkedEntity: (context, payload) => {
            return DoGet(
                '/integration/linkedEntities/' + payload.linked_entity_id,
                'integration/setLinkedEntities',
                [],
                'Запрос списка всех связей с сущностями внешней системы',
                false,
                true
            )
        },

        // запрос отфильтрованного списка объектов
        doFetchFilteredLinkedEntity: (context, payload) => {
            // формируем фильтрационный url
            let url = '/integration/linkedEntities?external_system_id=' + payload.external_system_id;
            if (payload.src_num) {
                url += ('&src_num=' + payload.src_num);
            }
            if (payload.entity_type_id) {
                url += ('&entity_type_id=' + payload.entity_type_id);
            }
            if (payload.local_id) {
                url += ('&local_id=' + payload.local_id);
            }
            if (payload.external_id) {
                url += ('&external_id=' + payload.external_id);
            }
            return DoGet(
                url,
                'integration/setLinkedEntities',
                [],
                'Запрос отфильтрованного списка связей с сущностями внешней системы',
                false,
                true
            )
        },

        // запрос объекта
        doFetchLinkedEntities: (context, payload) => {
            return DoGet(
                '/integration/linkedEntities??external_system_id=' + payload.external_system_id,
                null,
                {},
                'Запрос связи с сущностью внешней системы',
                false,
                payload && payload.force,
                context.state.linkedEntitiesCache,
                context.state.linkedEntitiesCacheTimeout
            )
        },

        // очищает кэш
        doClearLinkedEntities: (context, payload) => {

            return DoClearCache(
                'integration/setLinkedEntities',
                [],
                payload && payload.force,
                context.state.linkedEntitiesCache,
                context.state.linkedEntitiesCacheTimeout
            )
        },

        // добавление объекта
        doAddLinkedEntity: (context, payload) => {
            return DoPost(
                '/integration/linkedEntities',
                payload,
                'integration/addLinkedEntity',
                'Добавление связи с сущностью внешней системы'
            )
        },

        // изменение объекта
        doEditLinkedEntity: (context, payload) => {
            return DoPut(
                '/integration/linkedEntities/' + payload.linked_entity_id,
                payload,
                'integration/editLinkedEntity',
                'Изменение связи с сущностью внешней системы'
            )
        },

        // удаление объекта
        doDelLinkedEntity: (context, payload) => {
            return DoDelete(
                '/integration/linkedEntities/' + payload.linked_entity_id,
                'integration/delLinkedEntity',
                'Удаление связи с сущностью внешней системы'
            )
        }

    }
}