<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Вариант маршрута</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>

            <div class="form-group">
              <label for="routeVariantId">Код:</label>
              <input-integer
                  id="routeVariantId"
                  class="form-control w-25"
                  v-model="routeVariantId"
                  readonly
              />
            </div>

            <div class="row">

              <div class="form-group col">
                <label for="dateBegin">Дата начала:</label><ReqField />
                <input-date
                    id="dateBegin"
                    v-model="dateBegin"
                    class="form-control"
                    ref="dateBegin"
                    :readonly="op === 'show' || op === 'change'"
                    required
                    error="Укажите дату начала действия варианта"
                />
              </div>

              <div class="form-group col">
                <label for="dateEnd">Дата окончания:</label>
                <input-date
                    id="dateEnd"
                    v-model="dateEnd"
                    class="form-control"
                    ref="dateEnd"
                    :readonly="op === 'show'"
                />
              </div>

            </div>

            <div class="form-group">
              <label for="tariffTypeId">Тип тарифа:</label>
              <tariff-types-select
                  id="tariffTypeId"
                  v-model="tariffTypeId"
                  class="form-control"
                  ref="tariffTypeId"
                  :readonly="op === 'show' || op === 'change'"
              />
            </div>

            <div class="form-group">
              <label for="tariffZoneSystemId">Тарифная зональная система:</label>
              <tariff-zone-systems-select
                  id="tariffZoneSystemId"
                  v-model="tariffZoneSystemId"
                  class="form-control"
                  ref="tariffZoneSystemId"
                  :readonly="op === 'show' || op === 'change'"
              />
            </div>

            <div class="form-group">
              <label for="orgId">Организация:</label>
              <organizations-select
                  id="orgId"
                  v-model="orgId"
                  class="form-control"
                  ref="orgId"
                  :readonly="op === 'show' || op === 'change'"
              />
            </div>

            <div class="form-group">
              <label for="note">Примечание:</label>
              <input-string
                  id="note"
                  v-model="note"
                  class="form-control"
                  ref="note"
                  :readonly="op === 'show' || op === 'change'"
              />
            </div>

          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import TariffTypesSelect from "@/components/multipass/tariff_types/TariffTypesSelect";
import TariffZoneSystemsSelect from "@/components/multipass/tariff_zone_systems/TariffZoneSystemsSelect";
import OrganizationsSelect from "@/components/ref/organizations/OrganizationsSelect";
import InputInteger from "@/components/ui/custom/InputInteger";

export default {
    components: {
      InputInteger,
      OrganizationsSelect,
      TariffZoneSystemsSelect,
      TariffTypesSelect, ReqField
    },
    props: ['item', 'op'],
    emits: ['onOK', 'onCancel'],
    mixins: [ValidateFormMixin],
    data() {
        return {
          routeVariantId: this.item.route_variant_id,
          dateBegin: this.item.date_begin,
          dateEnd: this.item.date_end,
          routeVariantStatusId: this.item.route_variant_status_id,
          isWorking: this.item.is_working,
          note: this.item.note,
          orgId: this.item.org_id,
          tariffTypeId: this.item.tariff_type_id,
          tariffZoneSystemId: this.item.tariff_zone_system_id,
        };
    },
    methods: {
        onSubmit() {
          if (this.validateForm(this.$refs)) {

            // проверяем дату окончания
            if (this.dateBegin && this.dateEnd && this.dateEnd < this.dateBegin) {
              this.$refs.dateEnd.alarm('Дата окончания не может быть меньше даты начала.');
              return
            }

            this.$emit('onOK', {
              route_variant_id: this.op === 'add' ? undefined : this.routeVariantId,
              date_begin: this.dateBegin,
              date_end: this.dateEnd,
              route_variant_status_id: this.routeVariantStatusId,
              is_working: this.isWorking,
              note: this.note,
              org_id: this.orgId,
              tariff_type_id: this.tariffTypeId,
              tariff_zone_system_id: this.tariffZoneSystemId
            })
          }
        },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>