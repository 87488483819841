<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-multipass-stops"></edit-table-panel>
</template>

<script>

import {getStopType} from "@/store/MultipassHelpers";

export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "stop_id",
          caption: "Код",
          sorted: 'asc',
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
          show: false,
        },
        {
          field: "__stopTypeName",
          caption: "Тип остановки",
          sortType: 'string'
        },
        {
          field: "latitude",
          caption: "Широта",
          sortType: 'number',
          show: false,
        },
        {
          field: "longitude",
          caption: "Долгота",
          sortType: 'number',
          show: false,
        },
        {
          field: "reg_num",
          caption: "Рег.номер",
          sortType: 'string',
          show: false,
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          show: false,
        },
        {
          field: "area_classifier_id",
          caption: "Код ОКАТО(ОКТМО)",
          sortType: 'string',
          show: false,
        },
        {
          field: "address",
          caption: "Адрес",
          sortType: 'string',
          show: false,
        },
        {
          field: "is_even",
          caption: "Четн.",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_even) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      return this.$store.getters["multipass/getStops"].map((stop) => {
        stop.__id = stop.stop_id;
        const stopType = getStopType(stop.stop_type_id);
        stop.__stopTypeName = stopType && stopType.long_name || '';

        return stop;
      });
    },
  },
};
</script>
