<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Установить статус</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <form>

            <div class="form-group">
              <label for="routeStatusId">Статус маршрута:</label><ReqField />
              <route-statuses-select
                  id="routeStatusId"
                  v-model="routeStatusId"
                  class="form-control"
                  ref="routeStatusId"
                  required
                  error="Выберите статус маршрута"
              />
            </div>

          </form>

        </modal-body>
        <modal-footer>
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import RouteStatusesSelect from "@/components/multipass/route_statuses/RouteStatusesSelect";

export default {
  components: {
    RouteStatusesSelect, ReqField
  },
  props: ['item'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
      return {
        routeId: this.item.route_id,
        routeStatusId: this.item.route_status_id,
      };
  },
  methods: {
        onSubmit() {
          if (this.validateForm(this.$refs)) {
            this.$emit('onOK', {
              route_id: this.routeId,
              route_status_id: this.routeStatusId,
            })
          }
        },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>