<template>
  <input-select ref="select" v-model="value" :itemList="items"></input-select>
</template>

<script>
export default {
  props: ['model-value', 'isThisRowSelected'],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      let arr = [];
      // if (this.isThisRowSelected) {
        this.$store.getters['kvr/getOrderDepots'].map(depot => {
          let name = '';
          if (depot.long_name.length > 40) {
            name = depot.long_name.substring(0, 40);
          } else {
            name = depot.long_name;
          }
          arr.push({
            id: depot.depot_id,
            title: name,
          });
        });
      // } else {
      //   let depot = this.$store.getters['kvr/getOrderDepots'].find(value => value.depot_id == this.modelValue)
      //   let name = '';
      //   if (depot.long_name.length > 40) {
      //     name = depot.long_name.substring(0, 40);
      //   } else {
      //     name = depot.long_name;
      //   }
      //   if (depot) {
      //     arr.push({
      //       id: depot.depot_id,
      //       title: name,
      //     });
      //   }
      // }
      return arr;
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
