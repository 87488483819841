<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-asmpp-pay-trans-by-graph"></edit-table-panel>
</template>

<script>

export default {
  props: ['isLoading', 'payTransByGraph'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "graph",
          caption: "График",
          sortType: 'String',
          isFilterable: true,
        },
        {
          field: "fact_trip_count",
          caption: "Кол. рейсов",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "pay_tran_count",
          caption: "Сумм. кол. оплат",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "pay_tran_count_avg_by_day",
          caption: "Ср. кол. оплат за сутки",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.pay_tran_count_avg_by_day) {
              return Number(row.pay_tran_count_avg_by_day).toFixed(2);
            }
            return 0
          },
        },
        {
          field: "pay_tran_count_avg_by_transport",
          caption: "Ср. кол. оплат за сутки по ТС",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.pay_tran_count_avg_by_transport) {
              return Number(row.pay_tran_count_avg_by_transport).toFixed(2);
            }
            return 0
          },
        },
        {
          field: "pay_tran_count_avg_by_trip",
          caption: "Ср. кол. оплат за рейс",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.pay_tran_count_avg_by_trip) {
              return Number(row.pay_tran_count_avg_by_trip).toFixed(2);
            }
            return 0
          },
        },
        {
          field: "pay_tran_by_km",
          caption: "КПД-Пасс/км",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.pay_tran_by_km) {
              return Number(row.pay_tran_by_km).toFixed(2);
            }
            return 0
          },
        },
        {
          field: "pay_tran_by_hour",
          caption: "КПД-Пасс/час",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.pay_tran_by_hour) {
              return Number(row.pay_tran_by_hour).toFixed(2);
            }
            return 0
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch' || this.payTransByGraph.length == 0) {
        return []
      }

      return this.payTransByGraph.map((tran, index) => {
        
        tran.__id = index;

        return tran;
      });
    },
  },
};
</script>
