import { DoDelete, DoGet, DoGetFile, DoPostFile} from "@/store/Actions";

// ХРАНИЛИЩЕ ФАЙЛОВ ДОКУМЕНТОВ АВТОСТАНЦИЙ

export default {
    actions: {
        // запрос списка объектов
        doFetchStopstationDocFile: (context, payload) => {
            return DoGetFile(
                '/multipass/stopstationDocFiles/' + payload.stopstation_doc_id + "/" + payload.document_file_id,
                null,
                'Загрузка файла документа автостанции',
                false,
                payload.file_name,
            )
        },

        // запрос списка объектов
        doFetchStopstationDocFiles: (context, payload) => {
            return DoGet(
              '/multipass/stopstationDocFiles/' + payload.stopstation_doc_id,
              null,
              [],
              'Запрос файлов документов автостанций',
              false,
              true,
            )
        },

        // добавление объекта
        doAddStopstationDocFile: (context, payload) => {
            return DoPostFile(
                '/multipass/stopstationDocFiles/' + payload.stopstation_doc_id,
                payload.file.file,
                null,
                'Добавление файла документа автостанции'
            )
        },

        // удаление объекта
        doDelStopstationDocFile: (context, payload) => {
            return DoDelete(
                '/multipass/stopstationDocFiles/'+ payload.stopstation_doc_id + "/" + payload.document_file_id,
                null,
                'Удаление файла документа автостанции'
            )
        }
    }
}