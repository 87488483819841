import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ КОМПОЗИТОВ ТИПОВ РЕЙСОВ

export default {
    state() {
        return {
            // список объектов
            tripTypeComposites: [],
            // время обновления кэша new Date().getTime()
            tripTypeCompositesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            tripTypeCompositesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getTripTypeComposites(state) {
            return state.tripTypeComposites;
        },
    },
    mutations: {
        // установить новый список объектов
        setTripTypeComposites(state, payload) {
            state.tripTypeComposites = payload;
            state.tripTypeCompositesCache = new Date().getTime()
        },
        // добавить объект
        addTripTypeComposite(state, payload) {
            state.tripTypeComposites.push(payload)
        },
        // изменить объект
        editTripTypeComposite(state, payload) {
            const index = state.tripTypeComposites.findIndex(tripTypeComposite => tripTypeComposite.trip_type_composite_id == payload.trip_type_composite_id);
            if (index >= 0) {
                state.tripTypeComposites[index] = payload;
            }
        },
        // удалить объект
        delTripTypeComposite(state, payload) {
            const index = state.tripTypeComposites.findIndex(tripTypeComposite => tripTypeComposite.trip_type_composite_id == payload.trip_type_composite_id);
            state.tripTypeComposites.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchTripTypeComposites: (context, payload) => {
            return DoGet(
                '/asmpp/tripTypeComposites',
                'asmpp/setTripTypeComposites',
                [],
                'Запрос композитов типов рейсов',
                false,
                payload && payload.force,
                context.state.tripTypeCompositesCache,
                context.state.tripTypeCompositesCacheTimeout
            )
        },

        // очищает кэш
        doClearTripTypeComposites: (context, payload) => {
            return DoClearCache(
                'asmpp/setTripTypeComposites',
                [],
                payload && payload.force,
                context.state.tripTypeCompositesCache,
                context.state.tripTypeCompositesCacheTimeout
            )
        },

        // запрос списка объектов
        doFetchTripTypeComposite: (context, payload) => {
            return DoGet(
                '/asmpp/tripTypeComposites/' + payload.trip_type_composite_id,
                null,
                [],
                'Запрос композита типов рейсов',
                false,
                true
            )
        },

        // добавление объекта
        doAddTripTypeComposite: (context, payload) => {
            return DoPost(
                '/asmpp/tripTypeComposites',
                payload,
                'asmpp/addTripTypeComposite',
                'Добавление композита типов рейсов'
            )
        },

        // изменение объекта
        doEditTripTypeComposite: (context, payload) => {
            return DoPut(
                '/asmpp/tripTypeComposites/'+payload.trip_type_composite_id,
                payload,
                'asmpp/editTripTypeComposite',
                'Изменение композита типов рейсов'
            )
        },

        // удаление объекта
        doDelTripTypeComposite: (context, payload) => {
            return DoDelete(
                '/asmpp/tripTypeComposites/'+payload.trip_type_composite_id,
                'asmpp/delTripTypeComposite',
                'Удаление композита типов рейса'
            )
        }
    }
}
