import LeafletEventedMixin from "@/components/ui/leaflet/mixins/LeafletEventedMixin";
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import {toRaw} from "vue";

// УПРАВЛЕНИЕ ОВЕРЛЕЯМИ

export default {
    mixins: [LeafletEventedMixin, LeafletMapMixin],
    emits: ['onLeafletContextMenuItemClick'],
    props: {
        // опции слоя
        options: {
            type: Object,
            default: () => {}
        },
        // всплывающая подсказка слоя
        tooltip: {

        },
        // контекстное меню слоя
        contextMenu: {
            type: Object
        },
        // произвольные данные - добавляются к leaflet-компоненту в поле __meta
        meta: {
            required: false
        },
        // заголовок слоя в элементе управления слоями (если пустая строка - не регистрироваться)
        layer_control_title: {
            type: String,
            default: ""
        },
        // заголовк элемента (для отладки)
        logTitle: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            // тип объекта
            leafletObjectType: 'LeafletOverlay',
            // оригинальный объект (проксированный)
            proxyOrigin: null,
             // локальное контекстное меню
            localContextMenu: null,
            // слой для вставки оверлеев
            groupLayer: null,
            // элемент для управления слоями
            layerControl: null,
            // таймер для пересоздания
            reCreateTimer: null
        }
    },
    computed: {
        // оригинальный объект
        origin() {
            return toRaw(this.proxyOrigin)
        },
    },
    methods: {
        // создание слоя (реализуется в потомках)
        createLayer() {},
        // уничтожения слоя  (реализуется в потомках)
        destroyLayer() {},
        // установить видимость слоя
        setVisibility(visible) {
            // отображаем на карте
            if (visible && !this.origin) {
                // создаем слой
                const layer = this.createLayer()
                // логируем
                //console.log('Создан слой <'+this.curComponent.leafletObjectType+'>: '+this.logTitle);
                // вставляем слой на карту
                if (layer) {
                    this.groupLayer.origin.addLayer(layer);
                    // регистрируем слой
                    if (this.layer_control_title && this.layerControl) {
                        this.layerControl.registerOverlay(this.layer_control_title, layer)
                    }
                    // логируем
                    //console.log('Отображен слой <'+this.curComponent.leafletObjectType+'>: '+this.logTitle);
                }
                // фиксируем запуск элемента (для гуппы)
                this.$nextTick(() => {
                    this.isGroupReady = true;
                })
            }
            // скрываем с карты
            else if (!visible && this.origin) {
                // убираем регистрацию
                if (this.layer_control_title && this.layerControl) {
                    this.layerControl.unregisterOverlay(this.layer_control_title)
                }
                // удаляем слой из карты
                this.groupLayer.origin.removeLayer(this.origin);
                // логируем
                //console.log('Скрыт слой <'+this.curComponent.leafletObjectType+'>: '+this.logTitle);
                // удаляем слой
                this.destroyLayer();
                // логируем
                //console.log('Удален слой <'+this.curComponent.leafletObjectType+'>: '+this.logTitle);
            }
        },
        // пересоздание элемента управления
        reCreateLayer() {
            // таймер уже создан
            if (this.reCreateTimer) return;

            this.reCreateTimer = setTimeout(() => {
                // скрываем элемент
                this.setVisibility(false);
                this.reCreateTimer = setTimeout(() => {
                    // отображаем элемент заново
                    this.setVisibility(true)
                    this.reCreateTimer = null
                }, 0)
            }, 0)
        },
    },
    // монтируем слой
    mounted() {
        // ждем создание родителя
        this.getParent().parentReady().then((parent_list) => {
            // ищем слой для вставки
            this.groupLayer = this.getGroupLayer(parent_list);
            // ищем элемент управления слоями
            this.layerControl = this.getLayerControl(parent_list);

            // отображаем слой
            this.setVisibility(true)

            // наблюдатель за опциями
            this.$watch(() => this.options, () => {
                if (this.reCreateTimer) return;
                //console.log('Требуется изменить опции <'+this.curComponent.leafletObjectType+'>: '+ this.logTitle)
                this.reCreateLayer();
            }, {deep: false});

            // наблюдатель за подсказкой
            this.$watch(() => this.tooltip, (after, before) => {
                if (this.reCreateTimer) return;
                if (this.isTooltipOptionEqual(before, after)) {
                    if (!this.isTooltipContentEqual(before, after)) {
                        //console.log('Требуется изменить содержимое подсказки <'+this.curComponent.leafletObjectType+'>: '+ this.logTitle)
                        if (typeof(toRaw(after)) === 'object')
                            this.origin.setTooltipContent(toRaw(after).getContent())
                        else
                            this.origin.setTooltipContent(toRaw(after))
                    }
                    return;
                }
                //console.log('Требуется изменить подсказку <'+this.curComponent.leafletObjectType+'>: '+ this.logTitle)
                this.reCreateLayer();
            }, {deep: false});

            // наблюдатель за контекстным меню
            this.$watch(() => this.contextMenu, () => {
                if (this.reCreateTimer) return;
                //console.log('Требуется изменить контекстное меню <'+this.curComponent.leafletObjectType+'>: '+ this.logTitle)
                this.reCreateLayer();
            }, {deep: false});
        })
    },
    // демонтируем слой
    unmounted() {
        // отменяем таймер перерисовки
        if (this.reCreateTimer) {
            clearTimeout(this.reCreateTimer);
            this.reCreateTimer = null
        }
        // скрываем слой
        this.setVisibility(false)
    },
    // формируем пустой шаблон
    render() {
        return null
    }
}
