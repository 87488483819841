<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-rmt-seasons"> </edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "season_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["rmt/getSeasons"].map(season => {
        season.__id = season.season_id;
        return season;
      });
    },
  }
};
</script>
