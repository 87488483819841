<template>
  <input-select ref="select" v-model="value" caption="Выберите причину..." :itemList="items"></input-select>
</template>

<script>
export default {
  props: [
    'model-value',
    'exp_command_type_id'
  ],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      let itemsArr = [];
      this.$store.getters['kvr-expert/getExpCommandReasons'].map(item => {
        if (this.exp_command_type_id != null) {
          if(this.exp_command_type_id == item.exp_command_type_id) {
            itemsArr.push({
              id: item.exp_command_reason_id,
              title: item.long_name
            })
          }
        } else {
          itemsArr.push( {
            id: item.exp_command_reason_id,
            title: item.long_name
          })
        }
      });
      return itemsArr;
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
