<template>
  <edit-table-panel :disableSettings="true" name="tbl-multipass-tariff-zone-fares"></edit-table-panel>
</template>

<script>

export default {

};

</script>
