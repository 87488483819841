<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Период суток</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group row">
              <div class="form-group col">
                <label for="timeBegin">Время начала:</label>
                <input-time
                    id="timeBegin"
                    v-model="timeBegin"
                    class="form-control"
                    ref="timeBegin"
                    readonly
                />
              </div>

              <div class="form-group col">
                <label for="timeEnd">Время окончания:</label>
                <ReqField/>
                <input-time
                    id="timeEnd"
                    v-model="timeEnd"
                    class="form-control"
                    ref="timeEnd"
                    :readonly="op === 'show'"
                    required
                />
              </div>
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import InputTime from "@/components/ui/custom/InputTime";


export default {
  components: {
    InputTime,
    ReqField
  },
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      normPeriodId: this.item.__id,
      timeBegin: this.item.time_begin,
      timeEnd: this.item.time_end,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          __id: this.normPeriodId,
          time_begin: this.timeBegin,
          time_end: this.timeEnd,
        })
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>