<template>
  <edit-table-panel :columnList="columns" :disableSort="true" name="tbl-rmt-schedule-graph-step-card" :rowsPerPage="1000"> </edit-table-panel>
</template>

<script>

import {getTimeFormat} from "@/lib";

export default {
  props: ['cardEditMode'],

  computed: {

    columns() {
      const columns = [
          {
          field: "trip_type_order_num",
          caption: "№ п/п",
          sortType: 'number',
          isSortable: false
        },
        {
          field: "__name",
          caption: "Остановка",
          sortType: 'string',
          isSortable: false,
          cellStyle: {
            maxWidth: '10rem'
          },
        }
      ];

      //в режиме редактирования отображается текстовое поле, в режиме отображения отображается просто текст
      if (this.cardEditMode === 1 || this.cardEditMode === 2) {
        columns.push(
            {
              field: "plan_time",
              caption: "Время",
              sortType: 'string',
              dataAlign: 'center',
              headerAlign: 'center',
              displayType: 'time',
              isSortable: false,
              inputStyle: {
                maxWidth: '5rem'
              },
              inputAttrs: {
                required: true
              }
            }
        )
      } else {
        columns.push(
            {
              field: "plan_time",
              caption: "Время",
              sortType: 'string',
              dataAlign: 'center',
              headerAlign: 'center',
              displayType: 'String',
              isSortable: false,
              displayFn(row) {
                return getTimeFormat(row.plan_time)
              }
            }
        )
      }
      columns.push(
          {
            field: "__length",
            caption: "Пробег",
            sortType: 'number',
            dataAlign: 'right',
            headerAlign: 'right',
            isSortable: false
          }
      )
      columns.push(
          {
            field: "next_stop_duration",
            caption: "Длит.",
            sortType: 'number',
            dataAlign: 'center',
            headerAlign: 'center',
            isSortable: false,
            show: false,
            displayFn(row) {
              return  row.next_stop_duration != null ? Number(row.next_stop_duration / 60) : ''
            }
          }
      )
      //в режиме редактирования отображается текстовое поле, в режиме отображения отображается просто текст
      if (this.cardEditMode === 0) {
        columns.push(
            {
              field: "stop_time",
              caption: "Стоянка",
              sortType: 'number',
              dataAlign: 'center',
              headerAlign: 'center',
              displayType: 'minute',
              isSortable: false,
              inputStyle: {
                maxWidth: '5rem'
              }
            }
        )
      } else {
        columns.push(
            {
              field: "stop_time",
              caption: "Стоянка",
              sortType: 'number',
              dataAlign: 'center',
              headerAlign: 'center',
              displayType: 'String',
              isSortable: false,
              displayFn(row) {
                return  row.stop_time != null ? Number(row.stop_time / 60) : ''
              }
            }
        )
      }
      columns.push(
          {
            field: "__stop_id",
            caption: "Код",
            sortType: 'string',
            isSortable: false
          },
          {
            field: '__is_control_point',
            caption: 'КП',
            dataAlign: 'center',
            headerAlign: 'center',
            displayType: 'html',
            isSortable: false,
            displayFn(row) {
              if (row.__is_control_point) {
                return '<i class="fas fa-check">'
              }
              return ''
            },
          }
      )

      return columns;
    },
  }
}

</script>
