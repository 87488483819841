import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ТАРИФНЫХ ЗОНАЛЬНЫХ СИСТЕМ

export default {
    state() {
        return {
            // список объектов
            tariffZoneSystems: [],
            // время обновления кэша new Date().getTime()
            tariffZoneSystemsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            tariffZoneSystemsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getTariffZoneSystems(state) {
            return state.tariffZoneSystems;
        },
    },
    mutations: {
        // установить новый список объектов
        setTariffZoneSystems(state, payload) {
            state.tariffZoneSystems = payload;
            state.tariffZoneSystemsCache = new Date().getTime()
        },
        // добавить объект
        addTariffZoneSystem(state, payload) {
            state.tariffZoneSystems.push(payload)
        },
        // изменить объект
        editTariffZoneSystem(state, payload) {
            const index = state.tariffZoneSystems.findIndex(sys => sys.tariff_zone_system_id == payload.tariff_zone_system_id);
            if (index >= 0) {
                state.tariffZoneSystems[index] = payload;
            }
        },
        // удалить объект
        delTariffZoneSystem(state, payload) {
            const index = state.tariffZoneSystems.findIndex(sys => sys.tariff_zone_system_id == payload.tariff_zone_system_id);
            state.tariffZoneSystems.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchTariffZoneSystems: (context, payload) => {
            return DoGet(
                '/multipass/tariffZoneSystems',
                'multipass/setTariffZoneSystems',
                [],
                'Запрос тарифных зональных систем',
                false,
                payload && payload.force,
                context.state.tariffZoneSystemsCache,
                context.state.tariffZoneSystemsCacheTimeout
            )
        },

        // очищает кэш
        doClearTariffZoneSystems: (context, payload) => {
            return DoClearCache(
                'multipass/setTariffZoneSystems',
                [],
                payload && payload.force,
                context.state.tariffZoneSystemsCache,
                context.state.tariffZoneSystemsCacheTimeout
            )
        },

        // добавление объекта
        doAddTariffZoneSystem: (context, payload) => {
            return DoPost(
                '/multipass/tariffZoneSystems',
                payload,
                'multipass/addTariffZoneSystem',
                'Добавление тарифной зональной системы'
            )
        },

        // изменение объекта
        doEditTariffZoneSystem: (context, payload) => {
            return DoPut(
                '/multipass/tariffZoneSystems/'+payload.tariff_zone_system_id,
                payload,
                'multipass/editTariffZoneSystem',
                'Изменение тарифной зональной системы'
            )
        },

        // удаление объекта
        doDelTariffZoneSystem: (context, payload) => {
            return DoDelete(
                '/multipass/tariffZoneSystems/'+payload.tariff_zone_system_id,
                'multipass/delTariffZoneSystem',
                'Удаление тарифной зональной системы'
            )
        }
    }
}
