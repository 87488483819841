import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПНЫХ ДЛЯ ФОРМИРОВАНИЯ НАРЯДА ТРАНСПОРТНЫХ СРЕДСТВ


export default {
    state() {
        return {
            // список объектов
            orderVehicles: [],
            // список объектов, отфильтрованный по depot_id
            orderVehiclesByDepot: [],
            // время обновления кэша new Date().getTime()
            orderVehiclesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            orderVehiclesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getOrderVehicles(state) {
            return state.orderVehicles;
        },

        // вернуть список объектов, отфильтрованный по depot_id
        getOrderVehiclesByDepotId(state) {
            return state.orderVehiclesByDepot;
        },
    },
    mutations: {
        // установить новый список объектов
        setOrderVehicles(state, payload) {
            state.orderVehicles = payload;
            let arr = [];
            payload.map(value => {
                if (arr[value.depot_id]) {
                    arr[value.depot_id].push(value);
                } else {
                    arr[value.depot_id] = [];
                    arr[value.depot_id].push(value);
                }
            });
            state.orderVehiclesByDepot = arr;
            state.orderVehiclesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchOrderVehicles: (context, payload) => {
            return DoGet(
                '/trn/getOrderVehicles/' + payload.date_index,
                'trn/setOrderVehicles',
                [],
                'Запрос списка доступных для формирования наряда транспортных средств',
                false,
                payload && payload.force,
                context.state.orderVehiclesCache,
                context.state.orderVehiclesCacheTimeout
            )
        },

        // очищает кэш
        doClearOrderVehicles: (context, payload) => {
            return DoClearCache(
                'trn/setOrderVehicles',
                [],
                payload && payload.force,
                context.state.orderVehiclesCache,
                context.state.orderVehiclesCacheTimeout
            )
        },
    }
}