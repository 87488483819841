import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ МОДЕЛЕЙ

export default {
    state() {
        return {
            // список объектов
            models: [],
            // время обновления кэша new Date().getTime()
            modelsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            modelsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getModels(state) {
            return state.models;
        },
    },
    mutations: {
        // установить новый список объектов
        setModels(state, payload) {
            state.models = payload;
            state.modelsCache = new Date().getTime()
        },
        // добавить объект
        addModel(state, payload) {
            state.models.push(payload)
        },
        // изменить объект
        editModel(state, payload) {
            const index = state.models.findIndex(model => model.model_id == payload.model_id);
            if (index >= 0) {
                state.models[index] = payload;
            }
        },
        // удалить объект
        delModel(state, payload) {
            const index = state.models.findIndex(model => model.model_id == payload.model_id);
            state.models.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchModels: (context, payload) => {
            return DoGet(
                '/ref/models',
                'ref/setModels',
                [],
                'Запрос моделей',
                false,
                payload && payload.force,
                context.state.modelsCache,
                context.state.modelsCacheTimeout
            )
        },

        // очищает кэш
        doClearModels: (context, payload) => {

            return DoClearCache(
                'ref/setModels',
                [],
                payload && payload.force,
                context.state.modelsCache,
                context.state.modelsCacheTimeout
            )
        },

        // добавление объекта
        doAddModel: (context, payload) => {
            return DoPost(
                '/ref/models',
                payload,
                'ref/addModel',
                'Добавление модели'
            )
        },

        // изменение объекта
        doEditModel: (context, payload) => {
            return DoPut(
                '/ref/models/'+payload.model_id,
                payload,
                'ref/editModel',
                'Изменение модели'
            )
        },

        // удаление объекта
        doDelModel: (context, payload) => {
            return DoDelete(
                '/ref/models/'+payload.model_id,
                'ref/delModel',
                'Удаление модели'
            )
        }
    }
}