<template>
  <!--  ТАБЛИЦА ПРЕДПРИЯТИЙ С ПОМЕТКОЙ ДЛЯ КАЖДОГО ВАРИАНТА МАРШРУТА-->
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-multipass-route-variant-depot-links"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getLegalForm, getOrganization} from "@/store/RefHelpers";

export default {
  components: {EditTablePanel},
  props: ['id', 'isLoading', 'rows'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__checked",
          caption: "Вкл",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.__checked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "depot_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__orgName",
          caption: "Организация",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__legalFormName",
          caption: "Организационно-правовая форма",
          sortType: 'string',
          show: false
        },
        {
          field: "time_zone",
          caption: "Часовой пояс (мин)",
          sortType: 'number',
          show: false
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      const depots = this.$store.getters["ref/getDepots"];
      return depots.map(depot => {
        const legalForm = getLegalForm(depot.legal_form_id);
        const org = getOrganization(depot.org_id);

        depot.__id = depot.depot_id;
        depot.__legalFormName = legalForm && legalForm.long_name || '';
        depot.__orgName =  org && org.long_name || '';
        depot.__checked = this.rows.some(link => link.route_variant_id == this.id && link.depot_id == depot.depot_id);

        return depot
      });
    },
  },
};
</script>
