<template>

  <line-graph-dialog
    style="position: absolute; x: 500; y: 500;"
  ></line-graph-dialog>



<!--  &lt;!&ndash; Карта&ndash;&gt;-->
<!--  <leaflet-map-->
<!--      title="Карта"-->
<!--      id="exported-map"-->
<!--      :zoom="10"-->
<!--      :prefer-canvas="true"-->
<!--      :center="[56.830267,35.945075]"-->
<!--      @click.prevent="click"-->
<!--      @onMapCreated="onMapCreated"-->
<!--  >-->

<!--    &lt;!&ndash; Элементы управления&ndash;&gt;-->
<!--    <leaflet-control-group>-->
<!--      &lt;!&ndash; Базовые слои&ndash;&gt;-->
<!--      <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>-->
<!--    </leaflet-control-group>-->
<!--    <leaflet-layer-group title="Слой1">-->

<!--      <leaflet-layer-group title="Слой1-Слой2">-->

<!--        <leaflet-layer-group title="Слой1-Слой2-Слой3">-->

<!--          <leaflet-feature-group title="Слой1-Слой2-Слой3-FeatureGroup4"></leaflet-feature-group>-->

<!--        </leaflet-layer-group >-->


<!--      </leaflet-layer-group >-->

<!--    </leaflet-layer-group>-->

    <!-- Элементы управления-->
<!--    <leaflet-zoom-control title="ZoomControl"></leaflet-zoom-control>-->
<!--    <leaflet-attribution-control title="AttributionControl"></leaflet-attribution-control>-->
<!--    <leaflet-scale-control title="ScaleControl"></leaflet-scale-control>-->
<!--    <leaflet-layers-control title="LayersControl">-->

<!--      <leaflet-layer-group title="LayersControl-Слой1" layer_control_title="Слой5"></leaflet-layer-group >-->
<!--      <leaflet-layer-group title="LayersControl-Слой2" layer_control_title="Слой6"></leaflet-layer-group >-->
<!--      <leaflet-layer-group title="LayersControl-Слой3" layer_control_title="Слой7"></leaflet-layer-group >-->


<!--      <leaflet-tile-layer-->
<!--          title="LayersControl-OpenStreetMap"-->
<!--          visible-->
<!--          layer_control_title="OpenStreetMap"-->
<!--          urlTemplate="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"-->
<!--      >-->
<!--      </leaflet-tile-layer>-->

<!--      <leaflet-yandex-layer-->
<!--          title="LayersControl-Yandex"-->
<!--          layer_control_title="Yandex"-->
<!--      >-->

<!--      </leaflet-yandex-layer>-->

<!--    </leaflet-layers-control>-->


<!--    <stop-marker-->
<!--      v-for="stop in stops"-->
<!--      :key="stop.stop_id"-->
<!--      :stop="stop"-->
<!--      :tooltip="stop.long_name"-->
<!--      state="edit"-->
<!--    >-->
<!--    </stop-marker>-->



<!--    <leaflet-layer-group title="MainGroup">-->
<!--&lt;!&ndash;      <leaflet-marker-cluster-group title="MainGroup-ClusterGroup">&ndash;&gt;-->
<!--&lt;!&ndash;          <leaflet-marker&ndash;&gt;-->
<!--&lt;!&ndash;              v-for="marker in m"&ndash;&gt;-->
<!--&lt;!&ndash;              :key="marker.transport_id"&ndash;&gt;-->
<!--&lt;!&ndash;              :lat="marker.latitude"&ndash;&gt;-->
<!--&lt;!&ndash;              :lng="marker.longitude"&ndash;&gt;-->
<!--&lt;!&ndash;              :title="String(marker.transport_id)"&ndash;&gt;-->
<!--&lt;!&ndash;              :tooltip="marker.transport_id"&ndash;&gt;-->
<!--&lt;!&ndash;              events="dblclick"&ndash;&gt;-->
<!--&lt;!&ndash;              @onLeafletEvent="onLeafletEvent(marker)"&ndash;&gt;-->
<!--&lt;!&ndash;          >&ndash;&gt;-->
<!--&lt;!&ndash;          </leaflet-marker>&ndash;&gt;-->
<!--&lt;!&ndash;      </leaflet-marker-cluster-group>&ndash;&gt;-->
<!--    </leaflet-layer-group>-->

<!--  </leaflet-map>-->

<!--  <canvas id="canvas" style=" "></canvas>-->

</template>

<script>
import LineGraphDialog from "@/components/rmtgen/LineGraphDialog";
export default {
  name: "TestPage4",
  components: {LineGraphDialog},
  // components: {LeafletMap},
  computed: {
    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },
  },
  data() {
    return {
      is_visible: true,
      m: [],
      m1: [
        {
          "transport_id": 2,
          "latitude": 56.858335,
          "longitude": 35.920763,
        },
        {
          "transport_id": 3,
          "latitude": 56.830270,
          "longitude": 35.945075,
        },
        {
          "transport_id": 4,
          "latitude": 56.870867,
          "longitude": 35.869808,
        },
      ],
      m2: [
        {
          "transport_id": 2,
          "latitude": 56.858335,
          "longitude": 35.93,
        },
        {
          "transport_id": 3,
          "latitude": 56.830270,
          "longitude": 35.945075,
        },
        {
          "transport_id": 4,
          "latitude": 56.870867,
          "longitude": 35.869808,
        },
      ],
      markers: [
        {
          "transport_id": 2,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У658СО69",
          "garage_num": "10013",
          "special_header": "",
          "radio_id": 264,
          "radio_num": "194900149",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:33Z",
          "time_insert": "2022-10-13T13:14:38.608766Z",
          "time_delta": 22,
          "latitude": 56.858335,
          "longitude": 35.920763,
          "speed": 16,
          "angle": 286,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 3,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У792СО69",
          "garage_num": "10039",
          "special_header": "",
          "radio_id": 12,
          "radio_num": "194900183",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:22Z",
          "time_insert": "2022-10-13T13:14:26.024018Z",
          "time_delta": 33,
          "latitude": 56.830267,
          "longitude": 35.945075,
          "speed": 0,
          "angle": 295,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 4,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У670СО69",
          "garage_num": "10016",
          "special_header": "",
          "radio_id": 180,
          "radio_num": "194900123",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:43Z",
          "time_insert": "2022-10-13T13:14:48.682385Z",
          "time_delta": 12,
          "latitude": 56.870867,
          "longitude": 35.869808,
          "speed": 0,
          "angle": 107,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 5,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У633СО69",
          "garage_num": "10006",
          "special_header": "",
          "radio_id": 103,
          "radio_num": "194900122",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:25Z",
          "time_insert": "2022-10-13T13:14:30.943621Z",
          "time_delta": 30,
          "latitude": 56.844907,
          "longitude": 35.801387,
          "speed": 4,
          "angle": 82,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 6,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У787СО69",
          "garage_num": "10038",
          "special_header": "",
          "radio_id": 256,
          "radio_num": "194900155",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:33Z",
          "time_insert": "2022-10-13T13:14:38.593926Z",
          "time_delta": 22,
          "latitude": 56.812047,
          "longitude": 35.882465,
          "speed": 0,
          "angle": 0,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 7,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У796СО69",
          "garage_num": "10041",
          "special_header": "",
          "radio_id": 521,
          "radio_num": "194900157",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:22Z",
          "time_insert": "2022-10-13T13:14:27.229725Z",
          "time_delta": 33,
          "latitude": 56.780955,
          "longitude": 36.114393,
          "speed": 0,
          "angle": 265,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 8,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У717СО69",
          "garage_num": "10022",
          "special_header": "",
          "radio_id": 388,
          "radio_num": "194900160",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:24Z",
          "time_insert": "2022-10-13T13:14:28.339212Z",
          "time_delta": 31,
          "latitude": 56.807923,
          "longitude": 35.918032,
          "speed": 31,
          "angle": 350,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 9,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У655СО69",
          "garage_num": "10011",
          "special_header": "",
          "radio_id": 482,
          "radio_num": "194900152",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:21Z",
          "time_insert": "2022-10-13T13:14:25.816623Z",
          "time_delta": 34,
          "latitude": 56.890548,
          "longitude": 35.885513,
          "speed": 33,
          "angle": 105,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 10,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У761СО69",
          "garage_num": "10033",
          "special_header": "",
          "radio_id": 58,
          "radio_num": "194900154",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:38Z",
          "time_insert": "2022-10-13T13:14:43.61862Z",
          "time_delta": 17,
          "latitude": 56.87591,
          "longitude": 35.908785,
          "speed": 33,
          "angle": 13,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 11,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У659СО69",
          "garage_num": "10014",
          "special_header": "",
          "radio_id": 393,
          "radio_num": "194900158",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:38Z",
          "time_insert": "2022-10-13T13:14:42.917865Z",
          "time_delta": 17,
          "latitude": 56.880552,
          "longitude": 36.001035,
          "speed": 0,
          "angle": 80,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 12,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У776СО69",
          "garage_num": "10037",
          "special_header": "",
          "radio_id": 613,
          "radio_num": "194900159",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:28Z",
          "time_insert": "2022-10-13T13:14:33.438445Z",
          "time_delta": 27,
          "latitude": 56.83453,
          "longitude": 35.925805,
          "speed": 41,
          "angle": 35,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 13,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У748СО69",
          "garage_num": "10027",
          "special_header": "",
          "radio_id": 213,
          "radio_num": "194900089",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:13:26Z",
          "time_insert": "2022-10-13T13:13:39.531661Z",
          "time_delta": 89,
          "latitude": 56.861668,
          "longitude": 35.895858,
          "speed": 36,
          "angle": 268,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 14,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У671СО69",
          "garage_num": "10017",
          "special_header": "",
          "radio_id": 181,
          "radio_num": "194900086",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:41Z",
          "time_insert": "2022-10-13T13:14:46.014462Z",
          "time_delta": 14,
          "latitude": 56.79711,
          "longitude": 36.023985,
          "speed": 0,
          "angle": 272,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 15,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У651СО69",
          "garage_num": "10010",
          "special_header": "",
          "radio_id": 631,
          "radio_num": "194900072",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:22Z",
          "time_insert": "2022-10-13T13:14:26.108015Z",
          "time_delta": 33,
          "latitude": 56.834117,
          "longitude": 35.90037,
          "speed": 0,
          "angle": 305,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 16,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У725СО69",
          "garage_num": "10023",
          "special_header": "",
          "radio_id": 146,
          "radio_num": "194900127",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:33Z",
          "time_insert": "2022-10-13T13:14:38.597718Z",
          "time_delta": 22,
          "latitude": 56.815833,
          "longitude": 35.920138,
          "speed": 0,
          "angle": 5,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 17,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У750СО69",
          "garage_num": "10028",
          "special_header": "",
          "radio_id": 214,
          "radio_num": "194900082",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:41Z",
          "time_insert": "2022-10-13T13:14:46.192956Z",
          "time_delta": 14,
          "latitude": 56.844528,
          "longitude": 35.91769,
          "speed": 7,
          "angle": 125,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 19,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У645СО69",
          "garage_num": "10009",
          "special_header": "",
          "radio_id": 315,
          "radio_num": "194900153",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:47Z",
          "time_insert": "2022-10-13T13:14:49.811281Z",
          "time_delta": 8,
          "latitude": 56.8841,
          "longitude": 35.858813,
          "speed": 0,
          "angle": 8,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 20,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У754СО69",
          "garage_num": "10031",
          "special_header": "",
          "radio_id": 614,
          "radio_num": "194900125",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:47Z",
          "time_insert": "2022-10-13T13:14:51.205037Z",
          "time_delta": 8,
          "latitude": 56.837663,
          "longitude": 35.95905,
          "speed": 33,
          "angle": 143,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 21,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У799СО69",
          "garage_num": "10042",
          "special_header": "",
          "radio_id": 433,
          "radio_num": "194900073",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:20Z",
          "time_insert": "2022-10-13T13:14:24.371711Z",
          "time_delta": 35,
          "latitude": 56.834972,
          "longitude": 35.905768,
          "speed": 28,
          "angle": 211,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 23,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У693СО69",
          "garage_num": "10020",
          "special_header": "",
          "radio_id": 632,
          "radio_num": "194900075",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:47Z",
          "time_insert": "2022-10-13T13:14:49.772833Z",
          "time_delta": 8,
          "latitude": 56.82464,
          "longitude": 35.97393,
          "speed": 9,
          "angle": 145,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 24,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У620СО69",
          "garage_num": "10003",
          "special_header": "",
          "radio_id": 493,
          "radio_num": "194900077",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:44Z",
          "time_insert": "2022-10-13T13:14:49.802005Z",
          "time_delta": 11,
          "latitude": 56.900695,
          "longitude": 35.928273,
          "speed": 23,
          "angle": 171,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 26,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У621СО69",
          "garage_num": "10004",
          "special_header": "",
          "radio_id": 494,
          "radio_num": "194900150",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:49Z",
          "time_insert": "2022-10-13T13:14:51.341008Z",
          "time_delta": 6,
          "latitude": 56.941487,
          "longitude": 35.849983,
          "speed": 28,
          "angle": 56,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 27,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У682СО69",
          "garage_num": "10019",
          "special_header": "",
          "radio_id": 47,
          "radio_num": "194900128",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:44Z",
          "time_insert": "2022-10-13T13:14:49.548659Z",
          "time_delta": 11,
          "latitude": 56.906965,
          "longitude": 35.901438,
          "speed": 46,
          "angle": 326,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 28,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У635СО69",
          "garage_num": "10007",
          "special_header": "",
          "radio_id": 79,
          "radio_num": "194900083",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:35Z",
          "time_insert": "2022-10-13T13:14:40.844063Z",
          "time_delta": 20,
          "latitude": 56.806783,
          "longitude": 35.918535,
          "speed": 0,
          "angle": 9,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 30,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У707СО69",
          "garage_num": "10021",
          "special_header": "",
          "radio_id": 573,
          "radio_num": "194900129",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:47Z",
          "time_insert": "2022-10-13T13:14:51.247356Z",
          "time_delta": 8,
          "latitude": 56.825087,
          "longitude": 35.818098,
          "speed": 0,
          "angle": 0,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 31,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У657СО69",
          "garage_num": "10012",
          "special_header": "",
          "radio_id": 183,
          "radio_num": "194900080",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:51Z",
          "time_insert": "2022-10-13T13:14:53.849661Z",
          "time_delta": 4,
          "latitude": 56.826098,
          "longitude": 35.85054,
          "speed": 21,
          "angle": 273,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 32,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У774СО69",
          "garage_num": "10036",
          "special_header": "",
          "radio_id": 331,
          "radio_num": "194900124",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:41Z",
          "time_insert": "2022-10-13T13:14:46.180369Z",
          "time_delta": 14,
          "latitude": 56.821543,
          "longitude": 35.976873,
          "speed": 22,
          "angle": 318,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 33,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У771СО69",
          "garage_num": "10035",
          "special_header": "",
          "radio_id": 125,
          "radio_num": "194900179",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:22Z",
          "time_insert": "2022-10-13T13:14:25.998294Z",
          "time_delta": 33,
          "latitude": 56.924768,
          "longitude": 35.892213,
          "speed": 0,
          "angle": 149,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 34,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У606СО69",
          "garage_num": "10002",
          "special_header": "",
          "radio_id": 428,
          "radio_num": "194900126",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:28Z",
          "time_insert": "2022-10-13T13:14:33.557799Z",
          "time_delta": 27,
          "latitude": 56.843877,
          "longitude": 35.920162,
          "speed": 0,
          "angle": 307,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 35,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У639СО69",
          "garage_num": "10008",
          "special_header": "",
          "radio_id": 166,
          "radio_num": "194900071",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:20Z",
          "time_insert": "2022-10-13T13:14:24.465177Z",
          "time_delta": 35,
          "latitude": 56.878128,
          "longitude": 35.823145,
          "speed": 0,
          "angle": 106,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 36,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У800СО69",
          "garage_num": "10043",
          "special_header": "",
          "radio_id": 13,
          "radio_num": "194900081",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:25Z",
          "time_insert": "2022-10-13T13:14:29.758625Z",
          "time_delta": 30,
          "latitude": 56.819338,
          "longitude": 35.885577,
          "speed": 0,
          "angle": 215,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 37,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У736СО69",
          "garage_num": "10025",
          "special_header": "",
          "radio_id": 14,
          "radio_num": "194900078",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:49Z",
          "time_insert": "2022-10-13T13:14:53.827004Z",
          "time_delta": 6,
          "latitude": 56.796885,
          "longitude": 36.04985,
          "speed": 0,
          "angle": 354,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 38,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У765СО69",
          "garage_num": "10034",
          "special_header": "",
          "radio_id": 600,
          "radio_num": "194900088",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:30Z",
          "time_insert": "2022-10-13T13:14:34.574495Z",
          "time_delta": 25,
          "latitude": 56.884422,
          "longitude": 35.858682,
          "speed": 0,
          "angle": 145,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 39,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У794СО69",
          "garage_num": "10040",
          "special_header": "",
          "radio_id": 184,
          "radio_num": "194900079",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:25Z",
          "time_insert": "2022-10-13T13:14:29.751469Z",
          "time_delta": 30,
          "latitude": 56.873425,
          "longitude": 35.819498,
          "speed": 0,
          "angle": 197,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 41,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У681СО69",
          "garage_num": "10018",
          "special_header": "",
          "radio_id": 574,
          "radio_num": "194900076",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:20Z",
          "time_insert": "2022-10-13T13:14:24.534961Z",
          "time_delta": 35,
          "latitude": 56.881755,
          "longitude": 35.827805,
          "speed": 0,
          "angle": 113,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 42,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У746СО69",
          "garage_num": "10026",
          "special_header": "",
          "radio_id": 568,
          "radio_num": "194900131",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:33Z",
          "time_insert": "2022-10-13T13:14:36.946285Z",
          "time_delta": 22,
          "latitude": 56.849043,
          "longitude": 35.828708,
          "speed": 18,
          "angle": 76,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 43,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "У622СО69",
          "garage_num": "10005",
          "special_header": "",
          "radio_id": 257,
          "radio_num": "194900087",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:44Z",
          "time_insert": "2022-10-13T13:14:49.04047Z",
          "time_delta": 11,
          "latitude": 56.81989,
          "longitude": 35.920905,
          "speed": 30,
          "angle": 186,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 44,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н322СР69",
          "garage_num": "10085",
          "special_header": "",
          "radio_id": 240,
          "radio_num": "194900231",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:44Z",
          "time_insert": "2022-10-13T13:14:48.985927Z",
          "time_delta": 11,
          "latitude": 56.8547,
          "longitude": 35.906598,
          "speed": 0,
          "angle": 180,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 46,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н253СР69",
          "garage_num": "10065",
          "special_header": "",
          "radio_id": 483,
          "radio_num": "194900148",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:48Z",
          "time_insert": "2022-10-13T13:14:51.286272Z",
          "time_delta": 7,
          "latitude": 56.864835,
          "longitude": 35.917757,
          "speed": 2,
          "angle": 320,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 47,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н246СР69",
          "garage_num": "10062",
          "special_header": "",
          "radio_id": 52,
          "radio_num": "194900244",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:49Z",
          "time_insert": "2022-10-13T13:14:53.92739Z",
          "time_delta": 6,
          "latitude": 56.847148,
          "longitude": 35.839757,
          "speed": 26,
          "angle": 342,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 48,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н248СР69",
          "garage_num": "10063",
          "special_header": "",
          "radio_id": 160,
          "radio_num": "194900228",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:25Z",
          "time_insert": "2022-10-13T13:14:29.656448Z",
          "time_delta": 30,
          "latitude": 56.840222,
          "longitude": 35.8592,
          "speed": 28,
          "angle": 115,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 49,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н306СР69",
          "garage_num": "10080",
          "special_header": "",
          "radio_id": 285,
          "radio_num": "1949000229",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:19Z",
          "time_insert": "2022-10-13T13:14:24.637232Z",
          "time_delta": 36,
          "latitude": 56.840938,
          "longitude": 35.815363,
          "speed": 42,
          "angle": 75,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 50,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н212СР69",
          "garage_num": "10048",
          "special_header": "",
          "radio_id": 633,
          "radio_num": "194900230",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:28Z",
          "time_insert": "2022-10-13T13:14:32.095723Z",
          "time_delta": 27,
          "latitude": 56.860122,
          "longitude": 35.750362,
          "speed": 0,
          "angle": 3,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 51,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н281СР69",
          "garage_num": "10072",
          "special_header": "",
          "radio_id": 628,
          "radio_num": "194900163",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:30Z",
          "time_insert": "2022-10-13T13:14:33.512482Z",
          "time_delta": 25,
          "latitude": 56.837657,
          "longitude": 35.959282,
          "speed": 27,
          "angle": 323,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 52,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н346СР69",
          "garage_num": "10089",
          "special_header": "",
          "radio_id": 308,
          "radio_num": "194900214",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:49Z",
          "time_insert": "2022-10-13T13:14:53.091306Z",
          "time_delta": 6,
          "latitude": 56.867418,
          "longitude": 35.904682,
          "speed": 0,
          "angle": 283,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 53,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н231СР69",
          "garage_num": "10056",
          "special_header": "",
          "radio_id": 634,
          "radio_num": "194900161",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:36Z",
          "time_insert": "2022-10-13T13:14:41.816844Z",
          "time_delta": 19,
          "latitude": 56.884283,
          "longitude": 35.858575,
          "speed": 0,
          "angle": 319,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 54,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н309СР69",
          "garage_num": "10081",
          "special_header": "",
          "radio_id": 205,
          "radio_num": "194900246",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:48Z",
          "time_insert": "2022-10-13T13:14:51.334709Z",
          "time_delta": 7,
          "latitude": 56.839365,
          "longitude": 35.862895,
          "speed": 49,
          "angle": 110,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 55,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н232СР69",
          "garage_num": "10057",
          "special_header": "",
          "radio_id": 622,
          "radio_num": "194900164",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:22Z",
          "time_insert": "2022-10-13T13:14:26.102174Z",
          "time_delta": 33,
          "latitude": 56.780165,
          "longitude": 36.037098,
          "speed": 59,
          "angle": 157,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 56,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н355СР69",
          "garage_num": "10091",
          "special_header": "",
          "radio_id": 105,
          "radio_num": "194900168",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:33Z",
          "time_insert": "2022-10-13T13:14:40.892052Z",
          "time_delta": 22,
          "latitude": 56.847355,
          "longitude": 35.94247,
          "speed": 24,
          "angle": 42,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 57,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н228СР69",
          "garage_num": "10054",
          "special_header": "",
          "radio_id": 522,
          "radio_num": "194900169",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:44Z",
          "time_insert": "2022-10-13T13:14:49.022457Z",
          "time_delta": 11,
          "latitude": 56.888692,
          "longitude": 35.903343,
          "speed": 0,
          "angle": 191,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 58,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н218СР69",
          "garage_num": "10051",
          "special_header": "",
          "radio_id": 551,
          "radio_num": "194900196",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:25Z",
          "time_insert": "2022-10-13T13:14:28.393711Z",
          "time_delta": 30,
          "latitude": 56.896738,
          "longitude": 36.046608,
          "speed": 20,
          "angle": 34,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 59,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н208СР69",
          "garage_num": "10045",
          "special_header": "",
          "radio_id": 66,
          "radio_num": "194900181",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:25Z",
          "time_insert": "2022-10-13T13:14:28.360778Z",
          "time_delta": 30,
          "latitude": 56.870563,
          "longitude": 35.880633,
          "speed": 20,
          "angle": 109,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 60,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н266СР69",
          "garage_num": "10067",
          "special_header": "",
          "radio_id": 406,
          "radio_num": "194900177",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:43Z",
          "time_insert": "2022-10-13T13:14:48.760077Z",
          "time_delta": 12,
          "latitude": 56.900192,
          "longitude": 35.822427,
          "speed": 0,
          "angle": 310,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 61,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н275СР69",
          "garage_num": "10069",
          "special_header": "",
          "radio_id": 345,
          "radio_num": "194900165",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:49Z",
          "time_insert": "2022-10-13T13:14:53.816858Z",
          "time_delta": 6,
          "latitude": 56.793632,
          "longitude": 35.911225,
          "speed": 0,
          "angle": 0,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 62,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н315СР69",
          "garage_num": "10083",
          "special_header": "",
          "radio_id": 484,
          "radio_num": "194900237",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:25Z",
          "time_insert": "2022-10-13T13:14:28.357508Z",
          "time_delta": 30,
          "latitude": 56.825127,
          "longitude": 35.859635,
          "speed": 28,
          "angle": 282,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 63,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н271СР69",
          "garage_num": "10068",
          "special_header": "",
          "radio_id": 442,
          "radio_num": "194900182",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:36Z",
          "time_insert": "2022-10-13T13:14:41.829098Z",
          "time_delta": 19,
          "latitude": 56.887322,
          "longitude": 35.92857,
          "speed": 0,
          "angle": 0,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 64,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н243СР69",
          "garage_num": "10061",
          "special_header": "",
          "radio_id": 172,
          "radio_num": "194900188",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:49Z",
          "time_insert": "2022-10-13T13:14:53.121262Z",
          "time_delta": 6,
          "latitude": 56.940255,
          "longitude": 36.114113,
          "speed": 71,
          "angle": 252,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 67,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н249СР69",
          "garage_num": "10064",
          "special_header": "",
          "radio_id": 279,
          "radio_num": "194900145",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:30Z",
          "time_insert": "2022-10-13T13:14:34.611681Z",
          "time_delta": 25,
          "latitude": 56.84129,
          "longitude": 35.926592,
          "speed": 5,
          "angle": 301,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 68,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н298СР69",
          "garage_num": "10076",
          "special_header": "",
          "radio_id": 273,
          "radio_num": "194900052",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:35Z",
          "time_insert": "2022-10-13T13:14:40.757959Z",
          "time_delta": 20,
          "latitude": 56.858452,
          "longitude": 35.918835,
          "speed": 0,
          "angle": 106,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 69,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н362СР69",
          "garage_num": "10094",
          "special_header": "",
          "radio_id": 584,
          "radio_num": "194900144",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:44Z",
          "time_insert": "2022-10-13T13:14:49.580961Z",
          "time_delta": 11,
          "latitude": 56.799422,
          "longitude": 36.04221,
          "speed": 51,
          "angle": 117,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 70,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н227СР69",
          "garage_num": "10053",
          "special_header": "",
          "radio_id": 84,
          "radio_num": "194900147",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:30Z",
          "time_insert": "2022-10-13T13:14:33.524301Z",
          "time_delta": 25,
          "latitude": 56.841992,
          "longitude": 35.844058,
          "speed": 0,
          "angle": 337,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 71,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н325СР69",
          "garage_num": "10086",
          "special_header": "",
          "radio_id": 407,
          "radio_num": "194900235",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:46Z",
          "time_insert": "2022-10-13T13:14:49.706021Z",
          "time_delta": 9,
          "latitude": 56.850963,
          "longitude": 35.85231,
          "speed": 0,
          "angle": 192,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 74,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н303СР69",
          "garage_num": "10078",
          "special_header": "",
          "radio_id": 265,
          "radio_num": "194900238",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:24Z",
          "time_insert": "2022-10-13T13:14:28.42672Z",
          "time_delta": 31,
          "latitude": 56.893608,
          "longitude": 35.930138,
          "speed": 37,
          "angle": 186,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 75,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н350СР69",
          "garage_num": "10090",
          "special_header": "",
          "radio_id": 15,
          "radio_num": "194900194",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:30Z",
          "time_insert": "2022-10-13T13:14:34.642344Z",
          "time_delta": 25,
          "latitude": 56.868728,
          "longitude": 35.893493,
          "speed": 0,
          "angle": 277,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 76,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н255СР69",
          "garage_num": "10066",
          "special_header": "",
          "radio_id": 258,
          "radio_num": "194900185",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:49Z",
          "time_insert": "2022-10-13T13:14:53.067945Z",
          "time_delta": 6,
          "latitude": 56.877903,
          "longitude": 35.607063,
          "speed": 35,
          "angle": 53,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 78,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н363СР69",
          "garage_num": "10095",
          "special_header": "",
          "radio_id": 126,
          "radio_num": "194900195",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:43Z",
          "time_insert": "2022-10-13T13:14:48.712883Z",
          "time_delta": 12,
          "latitude": 56.813135,
          "longitude": 35.881538,
          "speed": 5,
          "angle": 334,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 79,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н229СР69",
          "garage_num": "10055",
          "special_header": "",
          "radio_id": 91,
          "radio_num": "194900190",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:49Z",
          "time_insert": "2022-10-13T13:14:53.070818Z",
          "time_delta": 6,
          "latitude": 56.903607,
          "longitude": 35.905098,
          "speed": 0,
          "angle": 336,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 80,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н321СР69",
          "garage_num": "10084",
          "special_header": "",
          "radio_id": 24,
          "radio_num": "194900211",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:22Z",
          "time_insert": "2022-10-13T13:14:27.207666Z",
          "time_delta": 33,
          "latitude": 56.858643,
          "longitude": 35.905825,
          "speed": 15,
          "angle": 266,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 81,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н366СР69",
          "garage_num": "10097",
          "special_header": "",
          "radio_id": 563,
          "radio_num": "194900212",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:14Z",
          "time_insert": "2022-10-13T13:14:18.12254Z",
          "time_delta": 41,
          "latitude": 56.852763,
          "longitude": 36.001235,
          "speed": 31,
          "angle": 271,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 82,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н360СР69",
          "garage_num": "10093",
          "special_header": "",
          "radio_id": 366,
          "radio_num": "194900186",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:22Z",
          "time_insert": "2022-10-13T13:14:25.951512Z",
          "time_delta": 33,
          "latitude": 56.834702,
          "longitude": 35.898683,
          "speed": 4,
          "angle": 123,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 83,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н291СР69",
          "garage_num": "10075",
          "special_header": "",
          "radio_id": 250,
          "radio_num": "194900180",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:22Z",
          "time_insert": "2022-10-13T13:14:25.745074Z",
          "time_delta": 33,
          "latitude": 56.898057,
          "longitude": 35.907797,
          "speed": 47,
          "angle": 190,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 84,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н367СР69",
          "garage_num": "10098",
          "special_header": "",
          "radio_id": 25,
          "radio_num": "194900085",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:36Z",
          "time_insert": "2022-10-13T13:14:41.764401Z",
          "time_delta": 19,
          "latitude": 56.854745,
          "longitude": 35.927437,
          "speed": 15,
          "angle": 132,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 85,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н305СР69",
          "garage_num": "10079",
          "special_header": "",
          "radio_id": 98,
          "radio_num": "194900227",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:36Z",
          "time_insert": "2022-10-13T13:14:41.635831Z",
          "time_delta": 19,
          "latitude": 56.859647,
          "longitude": 35.750303,
          "speed": 0,
          "angle": 270,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 86,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н364СР69",
          "garage_num": "10096",
          "special_header": "",
          "radio_id": 485,
          "radio_num": "194900245",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:49Z",
          "time_insert": "2022-10-13T13:14:53.908293Z",
          "time_delta": 6,
          "latitude": 56.865203,
          "longitude": 35.918307,
          "speed": 0,
          "angle": 123,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 87,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н209СР69",
          "garage_num": "10046",
          "special_header": "",
          "radio_id": 301,
          "radio_num": "194900239",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:32Z",
          "time_insert": "2022-10-13T13:14:36.911161Z",
          "time_delta": 23,
          "latitude": 56.848262,
          "longitude": 35.94476,
          "speed": 0,
          "angle": 325,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 88,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н280СР69",
          "garage_num": "10071",
          "special_header": "",
          "radio_id": 585,
          "radio_num": "194900226",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:33Z",
          "time_insert": "2022-10-13T13:14:35.709238Z",
          "time_delta": 22,
          "latitude": 56.846197,
          "longitude": 35.884578,
          "speed": 2,
          "angle": 98,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 90,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н224СР69",
          "garage_num": "10052",
          "special_header": "",
          "radio_id": 193,
          "radio_num": "194900191",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:24Z",
          "time_insert": "2022-10-13T13:14:27.344287Z",
          "time_delta": 31,
          "latitude": 56.911215,
          "longitude": 35.610785,
          "speed": 29,
          "angle": 284,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 91,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н278СР69",
          "garage_num": "10070",
          "special_header": "",
          "radio_id": 454,
          "radio_num": "194900215",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:32Z",
          "time_insert": "2022-10-13T13:14:35.673075Z",
          "time_delta": 23,
          "latitude": 56.854638,
          "longitude": 35.862578,
          "speed": 0,
          "angle": 260,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 92,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н386СР69",
          "garage_num": "10099",
          "special_header": "",
          "radio_id": 53,
          "radio_num": "194900170",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:25Z",
          "time_insert": "2022-10-13T13:14:28.397063Z",
          "time_delta": 30,
          "latitude": 56.843603,
          "longitude": 35.917133,
          "speed": 20,
          "angle": 216,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 93,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н286СР69",
          "garage_num": "10073",
          "special_header": "",
          "radio_id": 106,
          "radio_num": "194900213",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:49Z",
          "time_insert": "2022-10-13T13:14:53.911525Z",
          "time_delta": 6,
          "latitude": 56.847547,
          "longitude": 35.94273,
          "speed": 0,
          "angle": 33,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 94,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н341СР69",
          "garage_num": "10088",
          "special_header": "",
          "radio_id": 608,
          "radio_num": "194900210",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:49Z",
          "time_insert": "2022-10-13T13:14:53.884692Z",
          "time_delta": 6,
          "latitude": 56.844217,
          "longitude": 35.917915,
          "speed": 14,
          "angle": 214,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 95,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н242СР69",
          "garage_num": "10060",
          "special_header": "",
          "radio_id": 586,
          "radio_num": "194900162",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:22Z",
          "time_insert": "2022-10-13T13:14:27.278407Z",
          "time_delta": 33,
          "latitude": 56.812455,
          "longitude": 35.880548,
          "speed": 0,
          "angle": 45,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 96,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н358СР69",
          "garage_num": "10092",
          "special_header": "",
          "radio_id": 302,
          "radio_num": "194900243",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:44Z",
          "time_insert": "2022-10-13T13:14:49.558498Z",
          "time_delta": 11,
          "latitude": 56.839632,
          "longitude": 35.930393,
          "speed": 0,
          "angle": 142,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 97,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н210СР69",
          "garage_num": "10047",
          "special_header": "",
          "radio_id": 188,
          "radio_num": "194900240",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:35Z",
          "time_insert": "2022-10-13T13:14:38.556681Z",
          "time_delta": 20,
          "latitude": 56.867415,
          "longitude": 35.904968,
          "speed": 11,
          "angle": 282,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 98,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н216СР69",
          "garage_num": "10050",
          "special_header": "",
          "radio_id": 417,
          "radio_num": "194900166",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:33Z",
          "time_insert": "2022-10-13T13:14:37.051436Z",
          "time_delta": 22,
          "latitude": 56.884738,
          "longitude": 35.901655,
          "speed": 0,
          "angle": 13,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 99,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 1,
          "model_title": "429260",
          "transport_class_id": 3,
          "transport_class_title": "Средний Класс",
          "state_num": "Н290СР69",
          "garage_num": "10074",
          "special_header": "",
          "radio_id": 538,
          "radio_num": "194900167",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:07:20Z",
          "time_insert": "2022-10-13T13:07:24.383641Z",
          "time_delta": 455,
          "latitude": 56.884323,
          "longitude": 35.858685,
          "speed": 0,
          "angle": 333,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 100,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 13,
          "model_title": "529265",
          "transport_class_id": 4,
          "transport_class_title": "Большой Класс",
          "state_num": "Н276СР69",
          "garage_num": "10504",
          "special_header": "",
          "radio_id": 266,
          "radio_num": "194900208",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:43Z",
          "time_insert": "2022-10-13T13:14:49.535177Z",
          "time_delta": 12,
          "latitude": 56.886702,
          "longitude": 35.929188,
          "speed": 0,
          "angle": 14,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 101,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 13,
          "model_title": "529265",
          "transport_class_id": 4,
          "transport_class_title": "Большой Класс",
          "state_num": "Н289СР69",
          "garage_num": "10505",
          "special_header": "",
          "radio_id": 267,
          "radio_num": "194900204",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:43Z",
          "time_insert": "2022-10-13T13:14:48.753639Z",
          "time_delta": 12,
          "latitude": 56.833517,
          "longitude": 35.89911,
          "speed": 0,
          "angle": 19,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 102,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 13,
          "model_title": "529265",
          "transport_class_id": 4,
          "transport_class_title": "Большой Класс",
          "state_num": "Н204СР69",
          "garage_num": "10500",
          "special_header": "",
          "radio_id": 363,
          "radio_num": "194900050",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:32Z",
          "time_insert": "2022-10-13T13:14:36.863508Z",
          "time_delta": 23,
          "latitude": 56.49518,
          "longitude": 35.997997,
          "speed": 0,
          "angle": 166,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        },
        {
          "transport_id": 103,
          "transport_type_id": 1,
          "transport_type_title": "Автобус",
          "depot_id": 2,
          "depot_title": "Верхневолжское АТП",
          "model_id": 13,
          "model_title": "529265",
          "transport_class_id": 4,
          "transport_class_title": "Большой Класс",
          "state_num": "Н263СР69",
          "garage_num": "10503",
          "special_header": "",
          "radio_id": 196,
          "radio_num": "194900051",
          "radio_model_id": 1,
          "radio_model_title": "Навигатор 07/08",
          "time_nav": "2022-10-13T13:14:44Z",
          "time_insert": "2022-10-13T13:14:49.04901Z",
          "time_delta": 11,
          "latitude": 56.995992,
          "longitude": 35.906702,
          "speed": 77,
          "angle": 9,
          "is_invalid": false,
          "is_history": false,
          "is_ign": false,
          "is_call": false,
          "is_sos": false
        }
      ],
      stops:  [
          {
            "stop_id": 1,
            "stop_type_id": 1,
            "short_name": "Зверосовхоз (Старое Мелко",
            "long_name": "Зверосовхоз (Старое Мелково)",
            "latitude": 56.656832,
            "longitude": 36.441984,
            "reg_num": "",
            "note": "Zverosovhoz (Staroe Melkovo)",
            "area_classifier_id": "28230",
            "address": null,
            "is_even": false,
            "stopstation_id": null,
            "depot_id": null,
            "__id": 1,
            "__stopTypeName": "Обычная остановка"
          },
          {
            "stop_id": 2,
            "stop_type_id": 1,
            "short_name": "Зверосовхоз (Старое Мелко",
            "long_name": "Зверосовхоз (Старое Мелково)",
            "latitude": 56.656822,
            "longitude": 36.442078,
            "reg_num": "",
            "note": "Zverosovhoz (Staroe Melkovo)",
            "area_classifier_id": "28230",
            "address": null,
            "is_even": null,
            "stopstation_id": null,
            "depot_id": null,
            "__id": 2,
            "__stopTypeName": "Обычная остановка"
          },
          {
            "stop_id": 3,
            "stop_type_id": 1,
            "short_name": "Река Орлик",
            "long_name": "Река Орлик",
            "latitude": 56.7195629,
            "longitude": 36.31274419,
            "reg_num": "",
            "note": "Reka Orlik",
            "area_classifier_id": null,
            "address": null,
            "is_even": null,
            "stopstation_id": null,
            "depot_id": null,
            "__id": 3,
            "__stopTypeName": "Обычная остановка"
          },
          {
            "stop_id": 4,
            "stop_type_id": 1,
            "short_name": "Поворот на Игуменку",
            "long_name": "Поворот на Игуменку",
            "latitude": 56.7236169,
            "longitude": 36.29899019,
            "reg_num": "",
            "note": "Povorot na Igumenku",
            "area_classifier_id": null,
            "address": null,
            "is_even": null,
            "stopstation_id": null,
            "depot_id": null,
            "__id": 4,
            "__stopTypeName": "Обычная остановка"
          },
          {
            "stop_id": 5,
            "stop_type_id": 1,
            "short_name": "Микулино",
            "long_name": "Микулино",
            "latitude": 56.451344,
            "longitude": 35.606648,
            "reg_num": "",
            "note": "Mikulino",
            "area_classifier_id": null,
            "address": null,
            "is_even": null,
            "stopstation_id": null,
            "depot_id": null,
            "__id": 5,
            "__stopTypeName": "Обычная остановка"
          },
          {
            "stop_id": 6,
            "stop_type_id": 1,
            "short_name": "Плетенинское",
            "long_name": "Плетенинское",
            "latitude": 56.448296,
            "longitude": 35.571114,
            "reg_num": "",
            "note": "Pleteninskoe",
            "area_classifier_id": null,
            "address": null,
            "is_even": null,
            "stopstation_id": null,
            "depot_id": null,
            "__id": 6,
            "__stopTypeName": "Обычная остановка"
          },
          {
            "stop_id": 7,
            "stop_type_id": 1,
            "short_name": "Плетенинское",
            "long_name": "Плетенинское",
            "latitude": 56.448237,
            "longitude": 35.571558,
            "reg_num": "",
            "note": "Pleteninskoe",
            "area_classifier_id": null,
            "address": null,
            "is_even": null,
            "stopstation_id": null,
            "depot_id": null,
            "__id": 7,
            "__stopTypeName": "Обычная остановка"
          },
          {
            "stop_id": 8,
            "stop_type_id": 1,
            "short_name": "Микулино",
            "long_name": "Микулино",
            "latitude": 56.451239,
            "longitude": 35.607034,
            "reg_num": "",
            "note": "Mikulino",
            "area_classifier_id": null,
            "address": null,
            "is_even": null,
            "stopstation_id": null,
            "depot_id": null,
            "__id": 8,
            "__stopTypeName": "Обычная остановка"
          },
          {
            "stop_id": 9,
            "stop_type_id": 1,
            "short_name": "Знаменка",
            "long_name": "Знаменка",
            "latitude": 56.731749,
            "longitude": 35.373774,
            "reg_num": "",
            "note": "Znamenka",
            "area_classifier_id": null,
            "address": null,
            "is_even": null,
            "stopstation_id": null,
            "depot_id": null,
            "__id": 9,
            "__stopTypeName": "Обычная остановка"
          },
          {
            "stop_id": 10,
            "stop_type_id": 1,
            "short_name": "Коленицы",
            "long_name": "Коленицы",
            "latitude": 56.72364243,
            // "longitude": 35.34230619,
            "reg_num": "",
            "note": "Kolenitsyi",
            "area_classifier_id": null,
            "address": null,
            "is_even": null,
            "stopstation_id": null,
            "depot_id": null,
            "__id": 10,
            "__stopTypeName": "Обычная остановка"
          },
        ],
      canvas : null,
      context: null,
      imageURL: '',
      leaflet: null,
    }
  },
  methods: {

    onMapCreated(leaflet) {
      console.log('leaflet', leaflet)
      this.leaflet = leaflet;
    },

    click() {

      this.canvas = document.getElementById("canvas");
      let map = document.getElementById('exported-map');
      this.context = this.canvas.getContext('2d');
      this.canvas.width = map.offsetWidth;
      this.canvas.height = map.offsetHeight;

      // this.leaflet._canvasCtx.drawImage() canvasLayer().drawCanvas({canvas: canvas, bounds: map.getBounds(), size: map.getSize(), pixelRatio: L.Browser.retina ? 2 : 1});

      // this.context.drawImage(map, 0, 0, map.offsetWidth, map.offsetHeight);
      // const dataURL = this.canvas.toDataURL();
      // console.log(dataURL);

      // const mapImage = new Image();
      // console.log('map.src12312321321312', this.leaflet.toDataURL());
      // mapImage.src = this.leaflet.src;
      // mapImage.onload = () => {
      // console.log('mapImage', mapImage)
      // this.context.drawImage(mapImage, 0, 0, map.offsetWidth, map.offsetHeight);
      // const url = URL.createObjectURL(new Blob([this.canvas.toDataURL()], { type: 'image/png' }));
      // const link = document.createElement('a');
      // link.download = 'map.png';
      // link.href = url;
      // document.body.appendChild(link);
      // link.click();

      // const url = URL.createObjectURL(new Blob([dataURL]));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'map');
      // link.click();
      // URL.revokeObjectURL(url);

      // const dataURL = this.canvas.toDataURL();
      // console.log(dataURL);
    },

    onLeafletEvent(item) {
      console.error(item.state_num)
    },

  },
  mounted() {
    // setInterval(() => {
    //   if (this.m === this.m1) this.m = this.m2
    //     else this.m = this.m1;
    // },3000)


  //   const url = URL.createObjectURL(new Blob([this.canvas.toDataURL()], { type: 'image/png' }));
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.download = 'map.png';
  //   link.click();
  //   URL.revokeObjectURL(url);
  }
}
</script>
