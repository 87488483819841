import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ СОБЫТИЙ

export default {
    state() {
        return {
            // список объектов
            scheduleEvents: [],
            // время обновления кэша new Date().getTime()
            scheduleEventsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            scheduleEventsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getScheduleEvents(state) {
            return state.scheduleEvents;
        },
    },
    mutations: {
        // установить новый список объектов
        setScheduleEvents(state, payload) {
            state.scheduleEvents = payload;
            state.scheduleEventsCache = new Date().getTime()
        },
        // добавить объект
        addScheduleEvent(state, payload) {
            state.scheduleEvents.push(payload)
        },
        // изменить объект
        editScheduleEvent(state, payload) {
            const index = state.scheduleEvents.findIndex(scheduleEvent => scheduleEvent.schedule_event_id == payload.schedule_event_id);
            if (index >= 0) {
                state.scheduleEvents[index] = payload;
            }
        },
        // удалить объект
        delScheduleEvent(state, payload) {
            const index = state.scheduleEvents.findIndex(scheduleEvent => scheduleEvent.schedule_event_id == payload.schedule_event_id);
            state.scheduleEvents.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchScheduleEvents: (context, payload) => {
            return DoGet(
                '/rmt/scheduleEvents',
                'rmt/setScheduleEvents',
                [],
                'Запрос событий',
                false,
                payload && payload.force,
                context.state.scheduleEventsCache,
                context.state.scheduleEventsCacheTimeout
            )
        },

        // очищает кэш
        doClearScheduleEvents: (context, payload) => {
            return DoClearCache(
                'rmt/setScheduleEvents',
                [],
                payload && payload.force,
                context.state.scheduleEventsCache,
                context.state.scheduleEventsCacheTimeout
            )
        },

        // добавление объекта
        doAddScheduleEvent: (context, payload) => {
            return DoPost(
                '/rmt/scheduleEvents',
                payload,
                'rmt/addScheduleEvent',
                'Добавление события'
            )
        },

        // изменение объекта
        doEditScheduleEvent: (context, payload) => {
            return DoPut(
                '/rmt/scheduleEvents/'+payload.schedule_event_id,
                payload,
                'rmt/editScheduleEvent',
                'Изменение события'
            )
        },

        // удаление объекта
        doDelScheduleEvent: (context, payload) => {
            return DoDelete(
                '/rmt/scheduleEvents/'+payload.schedule_event_id,
                'rmt/delScheduleEvent',
                'Удаление события'
            )
        }
    }
}
