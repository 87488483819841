// ХРАНИЛИЩЕ ПОКАЗАТЕЛЕЙ ПАССАЖИРОПТОКА ПО ОПЛАЧЕННЫМ ПОЕЗДКАМ ПО ПАРКУ ЗА ПЕПРИОД ДАТ С ГРУППИРОВКОЙ ПО ДНЯМ

import {DoPost} from "@/store/Actions";

export default {
    actions: {
        // запрос списка объектов
        doFetchPayTransByDepotDay: (context, payload) => {
            return DoPost(
                '/asmpp/asop/payTransByDepotDay',
                payload,
                null,
                'Запрос показателей пассажиропотока по оплаченным поездкам по парку за период дат с группировкой по дням',
                false,
                true
            )
        },
    }
}
