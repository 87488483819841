import { DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОКУМЕНТОВ ВАРИАНТОВ МАРШРУТОВ

export default {

    actions: {
        // запрос объекта
        doFetchRouteVariantDoc: (context, payload) => {
            return DoGet(
                '/multipass/routeVariantDocs/' + payload.route_variant_doc_id,
                null,
                {},
                'Запрос документа варианта маршрута',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchRouteVariantDocs: (context, payload) => {
            return DoGet(
                '/multipass/routeVariantDocs?route_variant_id=' + payload.route_variant_id,
                null,
                [],
                'Запрос документов варианта маршрута',
                false,
                true,
            )
        },

        // добавление объекта
        doAddRouteVariantDoc: (context, payload) => {
            return DoPost(
                '/multipass/routeVariantDocs',
                payload,
                null,
                'Добавление документа варианта машрута'
            )
        },

        // изменение объекта
        doEditRouteVariantDoc: (context, payload) => {
            return DoPut(
                '/multipass/routeVariantDocs/'+ payload.route_variant_doc_id,
                payload,
                null,
                'Изменение документа варианта маршрута'
            )
        },

        // удаление объекта
        doDelRouteVariantDoc: (context, payload) => {
            return DoDelete(
                '/multipass/routeVariantDocs/'+ payload.route_variant_doc_id,
                null,
                'Удаление документа варианта маршрута'
            )
        }
    }
}
