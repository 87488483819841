import store from './Store';

// вернуть команду
export function getCommand(exp_command_id) {
    return store.getters["kvr-expert/getExpCommands"].find(command => command.exp_command_id == exp_command_id);
}

// вернуть причины команды эксперта
export function getCommandReason(exp_command_reason_id) {
    return store.getters["kvr-expert/getExpCommandReasons"].find(command => command.exp_command_reason_id == exp_command_reason_id);
}

// вернуть причины команды эксперта
export function getExpert(uid) {
    return store.getters["kvr-expert/getExperts"].find(expert => expert.uid == uid);
}
