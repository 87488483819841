<template>
  <edit-table-panel :columnList="columnList" :headerGroupList="headerGroupList" :disableSettings="true" :rowList="rowList" :rowsPerPage="100000"
                    name="tbl-kvr-vehicle-result-graphs"></edit-table-panel>
</template>

<script>

import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getTimeFormat, secondsToDateAndTime} from "@/lib";
import {getManageDriverTrn, getManagementAction, getManageRouteTrn, getManageVehicleTrn} from "@/store/TrnHelpers";

export default {
  components: {EditTablePanel},
  props: ['tableRows'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: '__management_time_index',
          caption: 'Упр.',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          dataAlign: 'center',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__route_num',
          caption: 'Мрш',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'graph',
          caption: 'Вх',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          dataAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'shift',
          caption: 'См',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          dataAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__transport_old',
          caption: 'Ном. ТС',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__driver_old',
          caption: 'Таб. ном.',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__action',
          caption: 'Наименование',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__management_time_begin',
          caption: 'Нач.',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          dataAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__management_time_end',
          caption: 'Оконч.',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__deflection',
          caption: 'Расп.',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
        },
        {
          field: '__transport',
          caption: 'Ном. ТС',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__driver',
          caption: 'Таб. ном.',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__extra_time',
          caption: 'Время',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'extra_length',
          caption: 'Пробег',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
      ],

      headerGroupList: [
        {
          headerGroupCaption: 'Время',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Текущие',
          headerGroupSpan: 5,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Управление',
          headerGroupSpan: 3,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Отк.',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Новые',
          headerGroupSpan: 2,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Добавленное',
          headerGroupSpan: 2,
          headerGroupAlign: 'center',
        },

      ],
    }
  },

  computed: {

    rowList() {
      return this.tableRows.map(row => {
        row.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px',
          userSelect: 'none',
        };

        row.__management_time_index = secondsToDateAndTime(row.management_time_index);
        row.__management_time_begin = getTimeFormat(row.management_time_begin);
        row.__management_time_end = getTimeFormat(row.management_time_end);
        row.__extra_time = getTimeFormat(row.extra_time);

        let route = getManageRouteTrn(row.route_id);
        if (route)
          row.__route_num = route.route_num;

        let ts = getManageVehicleTrn(row.old_transport_id);
        if(ts)
          row.__transport_old = (ts.garage_num ? ts.garage_num : ''); //+ '/' + (ts.state_num ? ts.state_num : ts.state_num);

        let ts_old = getManageVehicleTrn(row.transport_id);
        if(ts_old)
          row.__transport = (ts_old.garage_num ? ts_old.garage_num : '');

        let driver = getManageDriverTrn(row.driver_id);
        if(driver)
          row.__driver = driver.personnel_num;

        let driver_old = getManageDriverTrn(row.old_driver_id);
        if(driver_old)
          row.__driver_old = driver_old.personnel_num;


        let action = getManagementAction(row.management_action_id);

        if(action)
          row.__action = action.short_name;

        if (Number.isInteger(row.deflection / 60)) {
          row.__deflection = row.deflection / 60;
        } else {
          row.__deflection = (row.deflection / 60).toFixed(2);

        }


        // let action = getManagementAction(row.management_action_id);
        // if(action)
        //   row.__action = action.short_name;

        return row;
      });
    }

  }
}
</script>

<style scoped>

</style>