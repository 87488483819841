import {DoDelete, DoGet, DoGetFile, DoPostFile} from "@/store/Actions";

// ХРАНИЛИЩЕ ФАЙЛОВ ДОКУМЕНТОВ ОСТАНОВОЧНЫХ ПАВИЛИОНОВ

export default {

    actions: {
        // запрос объекта
        doFetchStopPavilionDocFile: (context, payload) => {
            return DoGetFile(
                '/multipass/stopPavilionDocFiles/' + payload.stop_pavilion_doc_id + "/" + payload.document_file_id,
                null,
                'Загрузка файла',
                false,
                payload.file_name,
            )
        },

        // запрос списка объектов
        doFetchStopPavilionDocFiles: (context, payload) => {
            return DoGet(
                '/multipass/stopPavilionDocFiles/' + payload.stop_pavilion_doc_id,
                null,
                [],
                'Запрос файлов документа остановочного павилиона',
                false,
                true,
                null,
                null
            )
        },

        // добавление объекта
        doAddStopPavilionDocFile: (context, payload) => {
            return DoPostFile(
                '/multipass/stopPavilionDocFiles/' + payload.stop_pavilion_doc_id,
                payload.file.file,
                null,
                'Добавление файла документа остановочного павилиона'
            )
        },

        // удаление объекта
        doDelStopPavilionDocFile: (context, payload) => {
            return DoDelete(
                '/multipass/stopPavilionDocFiles/'+ payload.stop_pavilion_doc_id + "/" + payload.document_file_id,
                null,
                'Удаление файла документа остановочного павилиона'
            )
        }
    }
}