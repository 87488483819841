import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ЗАДАНИЙ МОНИТОРИНГОВОЙ ТАБЛИЦЫ


export default {
    state() {
        return {
            // список объектов
            monitoringTasks: [],
            // время обновления кэша new Date().getTime()
            monitoringTasksCache: null,
            // время жизни кэша в мс (null - бесконечно)
            monitoringTasksCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getMonitoringTasks(state) {
            return state.monitoringTasks;
        },

    },
    mutations: {
        // установить новый список объектов
        setMonitoringTasks(state, payload) {
            state.monitoringTasks = payload;
            state.monitoringTasksCache = new Date().getTime();
        },
    },
    actions: {
        // запрос списка объектов
        doFetchMonitoringTasks: (context, payload) => {
            return DoGet(
                '/trn/getMonitoringTasks/' + payload.date_index,
                'trn/setMonitoringTasks',
                [],
                'Запрос списка задач мониторинговой таблицы',
                false,
                payload && payload.force,
                context.state.monitoringTasksCache,
                context.state.monitoringTasksCacheTimeout
            )
        },

        // очищает кэш
        doClearMonitoringTasks: (context, payload) => {
            return DoClearCache(
                'trn/setMonitoringTasks',
                [],
                payload && payload.force,
                context.state.monitoringTasksCache,
                context.state.monitoringTasksCacheTimeout
            )
        },
    }
}