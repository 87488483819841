export default {

    state() {
        return {
            // дата начала интервала, выбранная в какой-то из страниц модуля asmpp
            startDate: null,
            // дата конца интервала, выбранная в какой-то из страниц модуля asmpp
            endDate: null,
            //  depotGroupId, выбранный в панельке depots
            depotGroupId: null,
            //  depotGroupId, выбранный в панельке routes
            routeGroupId: null,
            //  selectedDepotId, выбранный в панельке AsopDepots
            asopSelectedDepotId: null,
            //  depotGroupId, выбранный в панельке AsopDepots
            asopDepotGroupId: null,
            //  selectedRoute, выбранный в панельке AsopRoutes
            asopSelectedRoute: null,
            //  selectedRouteId, выбранный в панельке AsopDepots
            asopSelectedRouteId: null,
            //  routeGroupId, выбранный в панельке AsopRoutes
            asopRouteGroupId: null,
            //  , выбранный в панельке factTripExts
            filterTrip: null,
        }
    },
    getters:{

        // возвращаем дату начала интервала, выбранную в панельке depots
        getStartDate(state){
            return state.startDate;
        },

        // возвращаем дату конца интервала, выбранную в панельке depots
        getEndDate(state){
            return state.endDate;
        },

        // возвращаем depotGroupId, выбранный в панельке depots
        getDepotsDepotGroupId(state){
            return state.depotGroupId;
        },

        // возвращаем depot, выбранный в панельке asopDepots
        getAsopSelectedDepotId(state){
            return state.asopSelectedDepotId;
        },

        // возвращаем depotGroupId, выбранный в панельке asopDepots
        getAsopDepotsDepotGroupId(state){
            return state.asopDepotGroupId;
        },

        // возвращаем routeGroupId, выбранный в панельке routes
        getRoutesRouteGroupId(state){
            return state.routeGroupId;
        },

        // возвращаем asopSelectedRouteId, выбранный в панельке asopRoutes
        getAsopSelectedRouteId(state){
            return state.asopSelectedRouteId;
        },

        // возвращаем routeGroupId, выбранный в панельке asopRoutes
        getAsopRoutesRouteGroupId(state){
            return state.asopRouteGroupId;
        },

        // возвращаем filterTrip, выбранную в панельке factTripExts
        getFilterTrip(state) {
            return state.filterTrip;
        }

    },
    mutations:{

        // задаем дату начала интервала, выбранную в панельке depots
        setStartDate(state, payload){
            state.startDate = payload;
        },

        // задаем дату конца интервала, выбранную в панельке depots
        setEndDate(state, payload){
            state.endDate = payload;
        },

        // задаем depotGroupId, выбранный в панельке depots
        setDepotsDepotGroupId(state, payload) {
            state.depotGroupId = payload;
        },

        // задаем depot, выбранный в панельке AsopDepots
        setAsopSelectedDepotId(state, payload) {
            state.asopSelectedDepotId = payload;
        },

        // задаем depotGroupId, выбранный в панельке AsopDepots
        setAsopDepotsDepotGroupId(state, payload) {
            state.asopDepotGroupId = payload;
        },

        // задаем routeGroupId, выбранную в панельке asopRoutes
        setRoutesRouteGroupId(state, payload){
            state.routeGroupId = payload;
        },

        // задаем routeId, выбранную в панельке asopRoutes
        setAsopSelectedRouteId(state, payload){
            state.asopSelectedRouteId = payload;
        },

        // задаем routeGroupId, выбранную в панельке asopRoutes
        setAsopRoutesRouteGroupId(state, payload){
            state.asopRouteGroupId = payload;
        },

        // задаем filterTrip, выбранную в панельке factTripExts
        setFilterTrip(state, payload) {
            state.filterTrip = payload;
        }


    }
}