<template>

  <accordion mode="single">

    <ul class="p-0 m-0" style="list-style-type: none;">
      <li>
        <full-left-menu-item
            to="/ref/organizations"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Организации
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/ref/depots"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Предприятия
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/ref/transportTypes"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Виды транспорта
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/ref/models"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Модели
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/ref/transport"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Транспортные средства
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/ref/personnel"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Работники
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/ref/transportClasses"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Классы транспортных средств
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/ref/engineFuelTypes"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Типы топлива
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item
            to="/ref/emissionStandards"
        >
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Экологические классы
            </div>
          </div>
        </full-left-menu-item>
      </li>
      <li>
        <full-left-menu-item
            to="/ref/legalForms">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Организационно-правовые формы предприятий
            </div>
          </div>
        </full-left-menu-item>
      </li>
      <li>
        <full-left-menu-item
            to="/ref/areaClassifier"
            style="display: inline-block;">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Классификатор объектов административно-территориального деления
            </div>
          </div>
        </full-left-menu-item>
      </li>
    </ul>
  </accordion>

</template>

<style scoped>

p {
  margin-bottom: 0;
}


</style>