import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ПОКАЗАТЕЛЕЙ

export default {
    state() {
        return {
            // список объектов
            indicators: [],
            // время обновления кэша new Date().getTime()
            indicatorsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            indicatorsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getIndicators(state) {
            return state.indicators;
        },
    },
    mutations: {
        // установить новый список объектов
        setIndicators(state, payload) {
            state.indicators = payload;
            state.indicatorsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchIndicators: (context, payload) => {
            return DoGet(
                '/rmt/indicators',
                'rmt/setIndicators',
                [],
                'Запрос показатей',
                false,
                payload && payload.force,
                context.state.indicatorsCache,
                context.state.indicatorsCacheTimeout
            )
        },

        // очищает кэш
        doClearIndicators: (context, payload) => {
            return DoClearCache(
                'rmt/setIndicators',
                [],
                payload && payload.force,
                context.state.indicatorsCache,
                context.state.indicatorsCacheTimeout
            )
        },
    }
}
