import {DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ КАРТОЧЕК РАСПОРЯДКА ГРАФИКА (ВЫХОДА) ДВИЖЕНИЯ

export default {
    actions: {
        // Получить карточку распорядка графика движения
        doFetchScheduleGraphStepCard: (context, payload) => {
            return DoGet(
                '/rmt/scheduleGraphStepCards?schedule_graph_step_id=' + payload.schedule_graph_step_id,
                null,
                [],
                'Запрос карточки распорядка движения',
                false,
                true,
            )
        },

        // Получить список карточек распорядка графика движения
        doFetchScheduleGraphStepCards: (context, payload) => {
            return DoPost(
                '/rmt/scheduleGraphStepCards',
                payload,
                null,
                'Запрос карточек распорядка движения',
                false,
                true
            )
        },

        // изменение объекта
        doEditScheduleGraphStepCard: (context, payload) => {
            return DoPut(
                '/rmt/scheduleGraphStepCards/'+payload.schedule_graph_step_id+'/'+payload.mode,
                payload.card,
                null,
                'Изменение карточки распорядка движения'
            )
        },
    }
}
