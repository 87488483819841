<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">

      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span><router-link to="/asmpp/depots">Список предприятий</router-link></span>
        <span> / </span>
        <span>Пассажиропоток за сутки по ТС {{depotsPageHeader}}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">

          <crud-form
              :buttonsExist="{ refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >
            <template #preLeftCustomButton>
              <button @click="onReport0003" type="button"
                      class="btn btn-primary">
                Отчет
              </button>
            </template>

<!--            &lt;!&ndash; Кнопка перехода к Рейсовому пассажиропотоку по часам суток   :disabled="linkDisabled" &ndash;&gt;-->
<!--            <template #leftCustomButton>-->
<!--              <button  @click="passengerTrafficFactTripByHoursLink" type="button" class="btn dropdown-toggle btn-primary" >-->
<!--                Рейсовый пассажиропоток по часам суток-->
<!--              </button>-->
<!--            </template>-->

          </crud-form>

        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <passenger-traffic-day-by-transport-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :rowArr="rows"
              :depots="depots"
              @onRowSelect="onRowSelect"
              @setLoading="setLoading"
          ></passenger-traffic-day-by-transport-edit-table>
        </align-panel>
        <align-panel align="bottom">
          <!-- Форма с кнопками под правой таблицей-->
          <card>
            <card-body class="p-1">
              <table class="table table-sm" style="margin-bottom:0">
                <thead class="thead-dark">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Кол рейсов</th>
                  <th scope="col">Кол пасс.</th>
                  <th scope="col">Пасс.*Км</th>
                  <th scope="col">Сумма оплат</th>
                  <th scope="col">Кол оплат</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in getBottomPanelItems" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.fact_trip_count }}</td>
                  <td>{{ item.passenger_count }}</td>
                  <td>{{ item.passenger_traffic }}</td>
                  <td>{{ item.pay_sum }}</td>
                  <td>{{ item.pay_tran_count }}</td>
                </tr>
                </tbody>
              </table>
            </card-body>
          </card>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Оповещение о том, Что выбрано больше 1 предприятия -->
  <error-message-box
      v-if="isErrorMessageVisible"
      @onClose="onCloseMessageBox"
  >Получение отчета невозможно, необходимо выбрать одно предприятие.
  </error-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>


import PageMixin from "@/pages/share/PageMixin";
import PassengerTrafficDayByTransportEditTable
  from "@/components/asmpp/passenger_traffic_day_by_transport/PassengerTrafficDayByTransportEditTable";
import {getDepot} from "@/store/RefHelpers";

export default {
  components: {PassengerTrafficDayByTransportEditTable},
  mixins: [PageMixin],

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрутов
    "depots_id",
    // дата начала периода
    "date_index_begin",
    // дата конца периода
    "date_index_end",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // предприятия
      depots: [],
      // список рейсов
      rows: [],
      // видимость уведомления об ошибке (когда выбрано более одного предприятия)
      isErrorMessageVisible: false,
    }
  },

  computed: {

    // расчет параметров для таблицы "Итого" снизу
    getBottomPanelItems(){
      let items = [];
      let fact_trip_count = 0;
      let passenger_count = 0;
      let passenger_traffic = 0;
      let pay_sum = 0;
      let pay_tran_count = 0;

      for(let i = 0; i < this.rows.length; i++) {
        fact_trip_count = fact_trip_count + this.rows[i].fact_trip_count;
        passenger_count = passenger_count + this.rows[i].passenger_count;
        passenger_traffic = passenger_traffic + this.rows[i].passenger_traffic;
        pay_sum = pay_sum + this.rows[i].pay_sum;
        pay_tran_count = pay_tran_count +  this.rows[i].pay_tran_count
        ;
      }
      items.push({
        name: "Суммарно",
        fact_trip_count: fact_trip_count,
        passenger_count: passenger_count,
        passenger_traffic: Number(passenger_traffic).toFixed(1),
        pay_sum: pay_sum,
        pay_tran_count: pay_tran_count,
      })
      return items;
    },

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // условие отключения кнопки перехода к рейсовому пассажиропотоку
    linkDisabled() {
      return !this.selectedRow;
    },

  },
  methods: {

    setLoading(item) {
      this.isLoading = item;
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // закрываем информационное сообщение
    onCloseMessageBox() {
      this.isErrorMessageVisible = false;
    },

    // нажата кнопка запроса отчета
    onReport0003(){
      if(this.depots_id.length > 1) {
        this.isErrorMessageVisible = true;
        return;
      }
      // вносим временный отчет
      this.$store.commit('reporter/addReport', {report_id: 3});
      // отправляем на сервер запрос на формирование отчета
      this.$store.dispatch('asmpp/doMakeReport0003', {
        depot_id: Number(this.depots_id[0]),
        date_index_begin: Number(this.date_index_begin),
        date_index_end: Number(this.date_index_end),
      });
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        this.depots = [];

        await this.$store.dispatch('multipass/doFetchRoutes');
        await this.$store.dispatch('ref/doFetchDepots');
        await this.$store.dispatch('ref/doFetchTransport');

        let rows = [];

        for (let i = 0; i < this.depots_id.length; i++) {
          // загружаем пассажиропоток по маршруту
          let depotRows = await this.$store.dispatch('asmpp/doFetchPassengerTrafficDayByTransport', {
            depot_id: this.depots_id[i],
            date_index_begin: this.date_index_begin,
            date_index_end: this.date_index_end,
          });
          if (depotRows) {
            rows.push(...depotRows)
            // запрос предприятия
            const depot = getDepot(this.depots_id[i]);

            if (depot && this.depots.findIndex(value => value.depot_id == depot.depot_id) == -1) {
              this.depots.push(depot);
            }
          }
        }

        this.rows = rows;

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
