<template>

  <accordion mode="toggle" style="background-color: #343a40;">

    <card v-if="isRef">
      <card-header class="m-0 p-0 mb-1 bg-info" >
        <button-target class="btn btn-block text-left text-white" @click="is1selected = !is1selected" target-method="select" target-ref="group1">
          <i class="fas fa-minus" v-if="is1selected == true"></i>
          <i class="fas fa-plus" v-else></i> Справочники
        </button-target>
      </card-header>
    </card>
    <collapse ref="group1">
      <ul class="p-0 m-0">
        <ref-full-left-menu-list style="margin-left: 20px"></ref-full-left-menu-list>
      </ul>
    </collapse>

    <card v-if="isMultipass">
      <card-header class="m-0 p-0 mb-1 bg-info" >
        <button-target class="btn btn-block text-left text-white" @click="is2selected = !is2selected" target-method="select" target-ref="group2">
          <i class="fas fa-minus" v-if="is2selected == true"></i>
          <i class="fas fa-plus" v-else></i>  Маршруты
        </button-target>
      </card-header>
    </card>
    <collapse ref="group2">
      <ul class="p-0 m-0">
        <multipass-full-left-menu-list style="margin-left: 20px"></multipass-full-left-menu-list>
      </ul>
    </collapse>

    <card v-if="isRmt">
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" @click="is3selected = !is3selected" target-method="select" target-ref="group3">
          <i class="fas fa-minus" v-if="is3selected == true"></i>
          <i class="fas fa-plus" v-else></i>  Расписания
        </button-target>
      </card-header>
    </card>
    <collapse ref="group3">
      <ul class="p-0 m-0">
        <rmt-full-left-menu-list style="margin-left: 20px"></rmt-full-left-menu-list>
      </ul>
    </collapse>

    <card v-if="isNav">
    <card-header class="m-0 p-0 mb-1 bg-info">
      <button-target class="btn btn-block text-left text-white" @click="is4selected = !is4selected" target-method="select" target-ref="group4">
        <i class="fas fa-minus" v-if="is4selected == true"></i>
        <i class="fas fa-plus" v-else></i>  Телематика
      </button-target>
    </card-header>
  </card>

    <card v-if="isTrn">
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" @click="is5selected = !is5selected " target-method="select" target-ref="group5">
          <i class="fas fa-minus" v-if="is5selected == true"></i>
          <i class="fas fa-plus" v-else></i>  Диспетчерская
        </button-target>
      </card-header>
    </card>

    <card v-if="isKvr">
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" @click="is6selected = !is6selected" target-method="select" target-ref="group6">
          <i class="fas fa-minus" v-if="is6selected == true"></i>
          <i class="fas fa-plus" v-else></i>  КВР
        </button-target>
      </card-header>
    </card>

    <card v-if="isAsmpp">
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" @click="is7selected = !is7selected" target-method="select" target-ref="group7">
          <i class="fas fa-minus" v-if="is7selected == true"></i>
          <i class="fas fa-plus" v-else></i>  Пассажиропоток
        </button-target>
      </card-header>
    </card>

    <card v-if="isAsip">
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" @click="is8selected = !is8selected" target-method="select" target-ref="group8">
          <i class="fas fa-minus" v-if="is8selected == true"></i>
          <i class="fas fa-plus" v-else></i>  Информирование
        </button-target>
      </card-header>
    </card>

    <card v-if="isPriority">
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" @click="is9selected = !is9selected" target-method="select" target-ref="group9">
          <i class="fas fa-minus" v-if="is9selected == true"></i>
          <i class="fas fa-plus" v-else></i>  Приоритет
        </button-target>
      </card-header>
    </card>

    <card v-if="isAdmin">
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" @click="is10selected = !is10selected" target-method="select" target-ref="group10">
          <i class="fas fa-minus" v-if="is10selected == true"></i>
          <i class="fas fa-plus" v-else></i>  Администрирование
        </button-target>
      </card-header>
    </card>
    <collapse ref="group10">
      <ul class="p-0 m-0">
        <admin-full-left-menu-list style="margin-left: 20px"></admin-full-left-menu-list>
      </ul>
    </collapse>

  </accordion>
</template>
<script>

import RefFullLeftMenuList from "@/components/ui/menu/fullleftmenu/RefFullLeftMenuList";
import MultipassFullLeftMenuList from "@/components/ui/menu/fullleftmenu/MultipassFullLeftMenuList";
import RmtFullLeftMenuList from "@/components/ui/menu/fullleftmenu/RmtFullLeftMenuList";
import AdminFullLeftMenuList from "@/components/ui/menu/fullleftmenu/AdminFullLeftMenuList";
import CardHeader from "@/components/ui/bootstrap/card/CardHeader";
export default {
  components: {CardHeader, AdminFullLeftMenuList, RmtFullLeftMenuList, MultipassFullLeftMenuList, RefFullLeftMenuList},
  data(){
    // так как может раскрываться сразу несколько списков, и смена картинки с
    // плюса на минус должна происходить отдельно для каждого списка
    // вводим флаг на открытие каждого списка
    return {
      is1selected: false,
      is2selected: false,
      is3selected: false,
      is4selected: false,
      is5selected: false,
      is6selected: false,
      is7selected: false,
      is8selected: false,
      is9selected: false,
      is10selected: false
    }
  },
  computed:{
    isRef() {
      return this.isAuth;
    },
    isMultipass() {
      return this.isAuth;
    },
    isRmt() {
      return this.isAuth;
    },
    isNav() {
      return this.isAuth;
    },
    isTrn() {
      return this.isAuth;
    },
    isKvr() {
      return this.isAuth;
    },
    isAsmpp() {
      return this.isAuth;
    },
    isAsip() {
      return this.isAuth;
    },
    isPriority() {
      return this.isAuth;
    },
    isAdmin() {
      return this.isAuth;
    },
    isAuth() {
      return this.$store.getters.getIsAuth;
    },
  }
}
</script>

<style scoped>

  .card {
    background-color: #343a40;
    border-color: #343a40 !important;
  }

  .btn:focus{
    box-shadow: 0 0 0 #343a40;
  }

  .btn {
    border-color: #343a40;
    background-color: #343a40;
  }

  .card-header {
    border-color: #343a40;
    background-color: #343a40 !important; /* при удалении этого параметра каждая карточка (заголовок выпадающего списка) как бы подчеркивается*/
  }

</style>