
// ХРАНИЛИЩЕ СКОРРЕКТИРОВАННЫХ ИТОГОВЫХ ДАННЫХ ПО РЕЙСАМ


import {DoGet} from "@/store/Actions";

export default {

    actions: {

        // запрос данных
        doFetchExpResultTrips: (context, payload) => {
            // формируем url
            let url = '/kvr-expert/getExpResultTrips?date_index_begin=' + payload.date_index_begin + '&date_index_end=' + payload.date_index_end
            if (payload.time_begin) {
                url = url + '&time_begin=' + payload.time_begin;
            }
            if(payload.time_end) {
                url = url + '&time_end=' + payload.time_end;
            }
            if (payload.route_id) {
                url = url + '&route_id=' + payload.route_id;
            }
            if(payload.fact_org_id) {
                url = url + '&fact_org_id=' + payload.fact_org_id;
            }

            return DoGet(
                url,
                null,
                [],
                'Запрос скорректированных итоговых данные по рейсам',
                false,
            )
        }

    }
}