<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Вариант расписания</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <nav-menu class="nav-tabs" mode="single">
            <nav-item>
              <nav-link target-ref="pane1" target-method="select" active>Основные</nav-link>
            </nav-item>
            <nav-item>
              <nav-link target-ref="pane2" target-method="select">Дополнительные</nav-link>
            </nav-item>
          </nav-menu>
          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">

              <form>
                <div class="form-group">
                  <label for="scheduleVariantId">Код:</label>
                  <input-integer
                      id="scheduleVariantId"
                      class="form-control w-25"
                      v-model="scheduleVariantId"
                      readonly
                  />
                </div>

                <div class="form-group">
                  <label for="seasonId">Cезон:</label>
                  <req-field/>
                  <seasons-select
                      id="seasonId"
                      v-model="seasonId"
                      class="form-control"
                      ref="seasonId"
                      :readonly="op === 'show' || op === 'change'"
                      required
                  />
                </div>

                <div class="form-group">
                  <label for="scheduleTypeId">Тип расписания:</label>
                  <req-field/>
                  <schedule-types-select
                      id="scheduleTypeId"
                      v-model="scheduleTypeId"
                      class="form-control"
                      ref="scheduleTypeId"
                      :readonly="op === 'show' || op === 'change'"
                      required
                  />
                </div>

                <div class="row">
                  <div class="form-group col">
                    <label for="dateBegin">Дата начала:</label>
                    <ReqField/>
                    <input-date
                        id="dateBegin"
                        v-model="dateBegin"
                        class="form-control"
                        ref="dateBegin"
                        :readonly="op === 'show' || op === 'change'"
                        required
                        error="Укажите дату начала действия варианта"
                    />
                  </div>

                  <div class="form-group col">
                    <label for="dateEnd">Дата окончания:</label>
                    <input-date
                        id="dateEnd"
                        v-model="dateEnd"
                        class="form-control"
                        ref="dateEnd"
                        :readonly="op === 'show'"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="movementKindId">Вид движения:</label>
                  <req-field/>
                  <movement-kinds-select
                      id="movementKindId"
                      v-model="movementKindId"
                      class="form-control"
                      ref="movementKindId"
                      :readonly="op === 'show' || op === 'change'"
                      required
                  />
                </div>

              </form>
            </tab-pane>
            <tab-pane ref="pane2" class="p-2">
              <form>
                <div class="form-group">
                  <label for="regNum">Регистрационный номер:</label>
                  <input-string
                      id="regNum"
                      v-model="regNum"
                      class="form-control w-25"
                      ref="regNum"
                      autocomplete="off"
                      :readonly="op === 'show' || op === 'change'"
                  />
                </div>

                <div class="row">
                  <div class="form-group col">
                    <label for="dateInsert">Дата составления:</label>
                    <input-date
                        id="dateInsert"
                        v-model="dateInsert"
                        class="form-control"
                        ref="dateInsert"
                        :readonly="op === 'show' || op === 'change'"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="regulationPoint">Пункт регулирования:</label>
                  <input-select
                      caption="Выберите пункт регулирования..."
                      id="regulationPoint"
                      :itemList="[
                    {
                      id:0,
                      title:'AB'
                    },
                    {
                      id:1,
                      title:'A'
                    },
                    {
                      id:2,
                      title:'B'
                    }
                  ]"
                      class="form-control"
                      ref="regulationPoint"
                      :readonly="op === 'show' || op === 'change'"
                      v-model="regulationPoint"
                  />
                </div>

                <div class="form-group">
                  <label for="note">Примечание:</label>
                  <input-string
                      id="note"
                      v-model="note"
                      class="form-control"
                      ref="note"
                      autocomplete="off"
                      :readonly="op === 'show' || op === 'change'"
                  />
                </div>
              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import InputInteger from "@/components/ui/custom/InputInteger";
import ScheduleTypesSelect from "@/components/rmt/schedule_types/ScheduleTypesSelect";
import SeasonsSelect from "@/components/rmt/seasons/SeasonsSelect";
import MovementKindsSelect from "@/components/rmt/movement_kinds/MovementKindsSelect";

export default {
  components: {
    MovementKindsSelect,
    SeasonsSelect,
    ScheduleTypesSelect,
    InputInteger,
    ReqField
  },
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      scheduleVariantId: this.item.schedule_variant_id,
      seasonId: this.item.season_id,
      scheduleTypeId: this.item.schedule_type_id,
      dateBegin: this.item.date_begin,
      dateEnd: this.item.date_end,
      movementKindId: this.item.movement_kind_id,
      regNum: this.item.reg_num,
      dateInsert: this.item.date_insert,
      regulationPoint: this.item.regulation_point,
      note: this.item.note,


    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {

        // проверяем дату окончания
        if (this.dateBegin && this.dateEnd && this.dateEnd < this.dateBegin) {
          this.$refs.dateEnd.alarm('Дата окончания не может быть меньше даты начала.');
          return
        }

        this.$emit('onOK', {
          schedule_variant_id: this.scheduleVariantId,
          season_id: this.seasonId,
          schedule_type_id: this.scheduleTypeId,
          date_begin: this.dateBegin,
          date_end: this.dateEnd,
          movement_kind_id: this.movementKindId,
          reg_num: this.regNum,
          date_insert: this.dateInsert,
          regulation_point: this.regulationPoint,
          note: this.note,

        })
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>