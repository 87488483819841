<template>

  <accordion mode="single">

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group1">
          Основные
        </button-target>
      </card-header>
    </card>
    <collapse ref="group1" active>
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/routes"
          >Список маршрутов</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/stops"
          >Остановочные пункты</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/stopstations"
          >Автостанции</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/tariffZoneSystems"
          >Тарифные зональные системы</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/graph"
          >Граф УДС</left-menu-item>
        </li>
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group2">
          Группы
        </button-target>
      </card-header>
    </card>
    <collapse ref="group2">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/routeGroups"
          >Группы маршрутов</left-menu-item>
        </li>
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group3">
          Дополнительные
        </button-target>
      </card-header>
    </card>
    <collapse ref="group3">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/routeKinds"
          >Виды маршрутов</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/routeTypes"
          >Типы маршрутов</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/transferKinds"
          >Виды сообщений</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/tariffTypes"
          >Типы тарифов</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/tariffKinds"
          >Виды тарифов</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/stopstationTypes"
          >Типы автостанций</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/stopTypes"
          >Типы остановочных пунктов</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/stopPavilionKinds"
          >Виды остановочных павильонов</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/stopPavilionTypes"
          >Типы остановочных павильонов</left-menu-item>
        </li>
      </ul>
    </collapse>

  </accordion>
</template>
