<template>
  <input class="form-control-file" type="file" id="file" ref="file"  v-on:change="fileListChanged"/>
</template>

<script>
export default {
  props: {
    'error': {
      type: String
    },
    'required': {
      type: Boolean,
      default: false
    }
  },

  emits: ['onFileListChanged'],

  data() {
    return {
      isInvalid: false,
      errorText: this.error
    }
  },

  methods: {
    fileListChanged() {
      this.file = this.$refs.file.files[0];
      this.$emit('onFileListChanged', this.file)
    }
  },
}
</script>
