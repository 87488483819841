

//  ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ ТРН

import OrderDepotsTrnStore from "@/store/trn/OrderDepotsTrnStore";
import OrderRoutesTrnStore from "@/store/trn/OrderRoutesTrnStore";
import OrderVehiclesTrnStore from "@/store/trn/OrderVehiclesTrnStore";
import OrderConductorsTrnStore from "@/store/trn/OrderConductorsTrnStore";
import OrderDriversTrnStore from "@/store/trn/OrderDriversTrnStore";
import MasterOrdersTrnStore from "@/store/trn/MasterOrdersTrnStore";
import BaseOrdersTrnStore from "@/store/trn/BaseOrdersTrnStore";
import ManageConductorsTrnStore from "@/store/trn/ManageConductorsTrnStore";
import ManageDepotsTrnStore from "@/store/trn/ManageDepotsTrnStore";
import ManageDriversTrnStore from "@/store/trn/ManageDriversTrnStore";
import ManageRoutesTrnStore from "@/store/trn/ManageRoutesTrnStore";
import ManageVehiclesTrnStore from "@/store/trn/ManageVehiclesTrnStore";
import MonitoringTaskCardsTrnStore from "@/store/trn/MonitoringTaskCardsTrnStore";
import MonitoringTasksTrnStore from "@/store/trn/MonitoringTasksTrnStore";
import PointMarksTrnStore from "@/store/trn/PointMarksTrnStore";
import PointModesTrnStore from "@/store/trn/PointModesTrnStore";
import WorkDefectsTrnStore from "@/store/trn/WorkDefectsTrnStore";
import ExecuteManagementTrnStore from "@/store/trn/ExecuteManagementTrnStore";
import ManagementJournalTrnStore from "@/store/trn/ManagementJournalTrnStore";
import ManagementActionsTrnStore from "@/store/trn/ManagementActionsTrnStore";

export default {
    namespaced: true,
    modules:{
        OrderDepotsTrnStore,
        OrderRoutesTrnStore,
        OrderVehiclesTrnStore,
        OrderConductorsTrnStore,
        OrderDriversTrnStore,
        MasterOrdersTrnStore,
        BaseOrdersTrnStore,
        ManageConductorsTrnStore,
        ManageDepotsTrnStore,
        ManageDriversTrnStore,
        ManageRoutesTrnStore,
        ManageVehiclesTrnStore,
        MonitoringTaskCardsTrnStore,
        MonitoringTasksTrnStore,
        PointMarksTrnStore,
        PointModesTrnStore,
        WorkDefectsTrnStore,
        ExecuteManagementTrnStore,
        ManagementJournalTrnStore,
        ManagementActionsTrnStore,
    }

}