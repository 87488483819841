import {DoClearCache, DoDelete, DoGet, DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ СВЯЗЕЙ ПОЛЬЗОВАТЕЛЕЙ С ГРУППАМИ

export default {
    state() {
        return {
            // список связей
            userGroupLinks: [],
            // время обновления кэша new Date().getTime()
            userGroupLinksCache: null,
            // время жизни кэша в мс (null - бесконечно)
            userGroupLinksCacheTimeout: null
        }
    },
    getters: {
        // вернуть список связей
        getUserGroupLinks(state) {
            return state.userGroupLinks
        },
    },
    mutations: {
        // установить новый список связей
        setUserGroupLinks(state, payload) {
            state.userGroupLinks = payload;
            state.userGroupLinksCache = new Date().getTime()
        },
        // связать объекты
        addUserToGroup(state, payload) {
            state.userGroupLinks.push(payload)
        },
        // удалить связь объектов
        delUserFromGroup(state, payload) {
            const index = state.userGroupLinks.findIndex(
                link => link.user_group_id == payload.user_group_id && link.uid == payload.uid
            );
            state.userGroupLinks.splice(index, 1);
        },
    },
    actions: {
        // запрос списка связей
        doFetchUserGroupLinks: (context, payload) => {
            return DoGet(
                '/auth/userGroupLinks',
                'auth/setUserGroupLinks',
                [],
                'Запрос связей пользователей с группами',
                false,
                payload && payload.force,
                context.state.userGroupLinksCache,
                context.state.userGroupLinksCacheTimeout
            )
        },

        // очищает кэш
        doClearUserGroupLinks: (context, payload) => {
            return DoClearCache(
                'auth/setUserGroupLinks',
                [],
                payload && payload.force,
                context.state.userGroupLinksCache,
                context.state.userGroupLinksCacheTimeout
            )
        },

        // связывание объектов
        doAddUserToGroup: (context, payload) => {
            return DoPost(
                '/auth/userGroupLinks',
                payload,
                'auth/addUserToGroup',
                'Добавление пользователя в группу'
            )
        },

        // удаление связт объектов
        doDelUserFromGroup: (context, payload) => {
            return DoDelete(
                '/auth/userGroupLinks/'+payload.user_group_id+'/'+payload.uid,
                'auth/delUserFromGroup',
                'Удаление пользователя из группы'
            )
        },
    }
}