<template>
  <div class="container position-relative h-100">
    <h2 class="text-center">Модуль "Информирование"</h2>
    <p class="text-center">Информирование пассажиров о работе общественного транспорта.</p>
  </div>
</template>

<script>
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixin: [PageMixin],
}

</script>
