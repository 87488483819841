import {DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ УПРАВЛЯЮЩИХ ВОЗДЕЙСТВИЙ

export default {


    actions: {

        executeManagementTimeCorrection(state, payload) {
            return DoPut(
                '/trn/executeManagementTimeCorrection',
                payload,
                null,
                'Выполнить управляющее воздействие (тип: Коррекция времени)',
                true
            )

        }

    }

}