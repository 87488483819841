import {DoGet} from "@/store/Actions";
import store from "@/store/Store";

// ХРАНИЛИЩЕ ЗОН ОСТАНОВОЧНЫХ ПУНКТОВ

export default {
    state() {
        return {
            // список зон
            routeStopZoneList: {},
            // время обновления кэша new Date().getTime()
            routeStopZoneListCache: null,
            // время жизни кэша в мс (null - бесконечно)
            routeStopZoneListCacheTimeout: null
        }
    },
    getters: {
        // вернуть список маршрутов
        getRouteStopZoneList(state) {
            return state.routeStopZoneList;
        },
    },
    mutations: {
        // установить новый список маршрутов
        addRouteStopZoneList(state, payload) {
            state.routeStopZoneList[payload.route_id] = payload.route_stop_zone_list;
            state.routeStopZoneListCache = new Date().getTime();
        },
    },
    actions: {
        // запрос списка  'kvr/setRouteStopZoneList'
        doFetchRouteStopZoneList: async (context, payload) => {
            let response = await DoGet(
                '/kvr/getRouteStopZoneList/' + payload.date_index + '/' + payload.route_id,
                null,
                [],
                'Запрос зон остановочных пунктов маршрута',
                false,
                payload && payload.force,
                context.state.routeStopZoneListCache,
                context.state.routesCacheTimeout
            );
            store.commit('kvr/addRouteStopZoneList', {
                route_id: payload.route_id,
                route_stop_zone_list: response,
            });
            return response;
        },
    }
}