<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Модель радиостанции</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <nav-menu class="nav-tabs" mode="single">
            <nav-item>
              <nav-link target-ref="pane1" target-method="select" active>Основные</nav-link>
            </nav-item>
            <nav-item>
              <nav-link target-ref="pane2" target-method="select">Дополнительные</nav-link>
            </nav-item>
          </nav-menu>
          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>
                <div class="form-group">
                  <label for="radioModelId">Код:</label>
                  <input-integer
                      id="radioModelId"
                      class="form-control w-25"
                      v-model="radioModelId"
                      readonly
                  />
                </div>
                <div class="form-group">
                  <label for="radioModelTitle">Наименование:</label><req-field />
                  <input-string
                      class="form-control"
                      id="radioModelTitle"
                      v-model="radioModelTitle"
                      ref="radioModelTitle"
                      :readonly="op === 'show'"
                      required
                      error="Введите наименование"
                  />
                </div>
                <div class="form-group">
                  <label for="radioProtocolId">Код эфирного протокола:</label><req-field />
                  <radio-protocols-select
                      class="form-control"
                      id="radioProtocolId"
                      v-model="radioProtocolId"
                      ref="radioProtocolId"
                      :readonly="op === 'show'"
                      required
                      error="Введите код эфирного протокола"
                  />
                </div>
                <div class="form-group">
                  <label for="radioModelMedia">Среда передачи данных:</label><req-field />
                  <input-select
                      class="form-control"
                      id="radioModelMedia"
                      v-model="radioModelMedia"
                      :itemList="itemList"
                      caption="Выберите среду передачи данных..."
                      ref="radioModelMedia"
                      required
                      :readonly="op === 'show'"
                  />
                </div>
                <div class="form-group">
                  <label for="manufacturer">Производитель:</label>
                  <input-string
                      class="form-control"
                      id="manufacturer"
                      v-model="manufacturer"
                      ref="manufacturer"
                      :readonly="op === 'show'"
                  />
                </div>

              </form>
            </tab-pane>
            <tab-pane ref="pane2" class="p-2">
              <form>

                <div class="form-group">
                  <input-checkbox
                      id="isDriverDisplay"
                      v-model="isDriverDisplay"
                      :readonly="op === 'show'"
                  >Возможность подключения дисплея водителя</input-checkbox>

                  <input-checkbox
                      id="isPhotoRequest"
                      v-model="isPhotoRequest"
                      :readonly="op === 'show'"
                  >Возможность запроса фотографии</input-checkbox>

                  <input-checkbox
                      id="isTemperatureSensor"
                      v-model="isTemperatureSensor"
                      :readonly="op === 'show'"
                  >Подключение датчика температуры</input-checkbox>

                  <input-checkbox
                      id="isNoiseSensor"
                      v-model="isNoiseSensor"
                      :readonly="op === 'show'"
                  >Подключение датчика шума</input-checkbox>

                  <input-checkbox
                      id="isSmokeSensor"
                      v-model="isSmokeSensor"
                      :readonly="op === 'show'"
                  >Подключение датчика дыма</input-checkbox>

                  <input-checkbox
                      id="isFuelSensor"
                      v-model="isFuelSensor"
                      :readonly="op === 'show'"
                  >Подключение внешнего датчика топлива</input-checkbox>

                  <input-checkbox
                      id="isPaySystem"
                      v-model="isPaySystem"
                      :readonly="op === 'show'"
                  >Подключение аппаратуры системы оплаты проезда</input-checkbox>

                  <input-checkbox
                      id="isPassengerCount"
                      v-model="isPassengerCount"
                      :readonly="op === 'show'"
                  >Подключение аппаратуры подсчета пассажиров</input-checkbox>

                  <input-checkbox
                      id="isRecordSystem"
                      v-model="isRecordSystem"
                      :readonly="op === 'show'"
                  >Подключение системы видеонаблюдения</input-checkbox>

                  <input-checkbox
                      id="isMultimedia"
                      v-model="isMultimedia"
                      :readonly="op === 'show'"
                  >Подключение мультимедийной системы</input-checkbox>

                  <input-checkbox
                      id="isEboard"
                      v-model="isEboard"
                      :readonly="op === 'show'"
                  >Подключение маршрутоуказателей или информационного табло</input-checkbox>

                  <input-checkbox
                      id="isAutoinformer"
                      v-model="isAutoinformer"
                      :readonly="op === 'show'"
                  >Подключение автоинформатора</input-checkbox>

                  <input-checkbox
                      id="isTachograph"
                      v-model="isTachograph"
                      :readonly="op === 'show'"
                  >Подключение тахографа</input-checkbox>

                  <div class="form-group">
                    <label for="note">Примечание:</label>
                    <input-string
                        class="form-control"
                        id="note"
                        v-model="note"
                        ref="note"
                        :readonly="op === 'show'"
                    />
                  </div>

                </div>
              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputSelect from "@/components/ui/custom/InputSelect";
import RadioProtocolsSelect from "@/components/nav/radio_protocols/RadioProtocolsSelect";

export default {
  components: {RadioProtocolsSelect, InputSelect},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
      return {
        radioModelId: this.item.radio_model_id,
        radioModelTitle: this.item.radio_model_title,
        radioProtocolId: this.item.radio_protocol_id,
        radioModelMedia: this.item.radio_model_media,
        manufacturer: this.item.manufacturer,
        isDriverDisplay: this.item.is_driver_display,
        isPhotoRequest: this.item.is_photo_request,
        isTemperatureSensor: this.item.is_temperature_sensor,
        isNoiseSensor: this.item.is_noise_sensor,
        isSmokeSensor: this.item.is_smoke_sensor,
        isFuelSensor: this.item.is_fuel_sensor,
        isPaySystem: this.item.is_pay_system,
        isPassengerCount: this.item.is_passenger_count,
        isRecordSystem: this.item.is_record_system,
        isMultimedia: this.item.is_multimedia,
        isEboard: this.item.is_eboard,
        isAutoinformer: this.item.is_autoinformer,
        isTachograph: this.item.is_tachograph,
        note: this.item.note,

        itemList:[
          {
            id:0,
            title:'GSM/GPRS'
          },
          {
            id:1,
            title:'УКВ'
          },
      ]
      };
  },
  methods: {
      onSubmit() {
        if (this.validateForm(this.$refs)) {
            this.$emit('onOK', {
              radio_model_id: this.op === 'add' ? undefined : this.radioModelId,
              radio_model_title: this.radioModelTitle,
              radio_protocol_id: this.radioProtocolId,
              radio_model_media: this.radioModelMedia,
              manufacturer: this.manufacturer,
              is_driver_display: this.isDriverDisplay,
              is_photo_request: this.isPhotoRequest,
              is_temperature_sensor: this.isTemperatureSensor,
              is_noise_sensor: this.isNoiseSensor,
              is_smoke_sensor: this.isSmokeSensor,
              is_fuel_sensor: this.isFuelSensor,
              is_pay_system: this.isPaySystem,
              is_passenger_count: this.isPassengerCount,
              is_record_system: this.isRecordSystem,
              is_multimedia: this.isMultimedia,
              is_eboard: this.isEboard,
              is_autoinformer: this.isAutoinformer,
              is_tachograph: this.isTachograph,
              note: this.note,
            })
        }
      },
  },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>