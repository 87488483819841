<template>
  <div class="organizations-panel overflow-hidden">
    <align-container>
      <align-panel align="top">
        <div class="form-group row" style="margin-left: 5px; margin-bottom: 0; margin-bottom: 2px ">

          <div class="col-0">
            <!--          Поставить галочки всем ТС на экране-->
            <button style="padding: 0px; border-width: 0px; background-color: white; margin-right: 5px; margin-top: 3px;"
                    @click="setAllSelected">
              <i :class='selectedItems.length === organizations.length ? "fas fa-check-square fa-2xl fa-2x" : "far fa-check-square fa-2xl fa-2x"'
                 title="Выделить всё"
                 :style='isAllOrganizations == true ? "color: #a2a2a2" : (selectedItems.length === organizations.length ? "color: #007BFF" : "color: #000000")'
              ></i>
            </button>
          </div>
          <div class="col-0">
            <!--            Снять галочки со всех ТС -->
            <button style="padding: 0px; border-width: 0px; background-color: white; margin-right: 5px; margin-top: 3px;"
                    @click="setAllClear">
              <i :class='selectedItems.length === 0 ? "fas fa-square fa-2x" : "far fa-square fa-2x"'
                 title="Снять все"
                 :style='isAllOrganizations == true ? "color: #a2a2a2" : (selectedItems.length === 0 ? "color: #007BFF" : "color: #000000")'
              ></i>
            </button>
          </div>
          <div class="col-0">
            <!--            Снять галочки с тех ТС, что были выделенны, проставить на те, где были сняты -->
            <button style="padding: 0px; border-width: 0px; background-color: white; margin-top: 3px;"
                    @click="setAllSwitch">
              <i :class='(selectedItems.length !== 0 && selectedItems.length !== organizations.length) ?  "fab fa-flipboard fa-2x" : "fab fa-flipboard fa-2x"'
                 :style='isAllOrganizations == true ? "color: #a2a2a2" : ((selectedItems.length !== 0 && selectedItems.length !== organizations.length) ? "color: #007BFF" : "color: #000000")'
              ></i>
            </button>
          </div>
          <div class="col-0">
            <button
                type="button"
                :class="isAllOrganizations ? 'btn btn-success' : 'btn btn-secondary'"
                style="margin-left: 5px"
                @click="allOrganizationsClick"
            >Все предприятия
            </button>
          </div>

          <div class="col" style="padding: 0; margin-right: 20px">
            <input
                type="text"
                class="form-control"
                id="inlineFormInput"
                placeholder="Фильтр..."
                style="margin-left: 5px; margin-right: 10px"
                @input="onFilterChanged"
            />
          </div>
        </div>
      </align-panel>

      <align-panel align="all" style="overflow-y: auto; overflow-x: hidden">

        <form class="form">

          <!-- Таблица  -->
          <organizations-edit-table
              :organizations="organizations"
              :filterText="filterText"
              :selectedRow="selectedRow"
              :disableSelect="isAllOrganizations"
              @onRowSelect="onRowSelect"
          ></organizations-edit-table>

        </form>

      </align-panel>
    </align-container>
  </div>
</template>

<script>

import AlignPanel from "@/components/ui/custom/AlignPanel";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import OrganizationsEditTable from "@/components/kvr/reports/OrganizationsEditTable";


export default {
  components: {OrganizationsEditTable, AlignContainer, AlignPanel,},
  props: ['organizations', 'isAllOrganizations'],
  data() {
    return {
      // текст фильтра
      filterText: '',
      // задержка при изменении фильтра (мс)
      filterDelay: 300,
      // выбранная строка
      selectedRow: {}
    }
  },

  computed: {
    // выбранные элементы
    selectedItems() {
      let arr = []
      for (let i = 0; i < this.organizations.length; i++) {
        if (this.organizations[i].__isSelected) {
          arr.push(this.organizations[i]);
        }
      }
      return arr;
    },

  },

  methods: {

    // нажата кнопка "Все предприятия"
    allOrganizationsClick() {
      this.$emit('allOrganizationsChange');
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // установить все галочки
    setAllSelected() {
      this.organizations.map(value => {
        value.__isSelected = true;
      })
    },

    // снять все галочки
    setAllClear() {
      this.organizations.map(value => {
        value.__isSelected = false;
      })
    },

    // переключить галочки
    setAllSwitch() {
      this.organizations.map(value => {
        value.__isSelected = !value.__isSelected;
      })
    },

    // вызывается при изменении фильтра
    onFilterChanged(e) {
      const value = e.target.value.trim();
      setTimeout(() => {
        if (value === e.target.value.trim()) {
          // текст фильтра
          this.filterText = e.target.value.trim();
        }
      }, this.filterDelay);
    }
  },

}
</script>

<style scoped>


</style>