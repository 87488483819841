<template>
  <edit-table-panel :columnList="columnList" name="tbl-doc-files"></edit-table-panel>
</template>

<script>

import {getDateTimeFormat} from "@/lib";

export default {
  emits: ['onDownloadFile'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
          show: true
        },
        {
          field: "file_name",
          caption: "Имя файла",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__fileFormatName",
          caption: "Формат файла",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "file_size",
          caption: "Размер файла",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "time_insert",
          caption: "Время внесения файла",
          sortType: 'string',
          isFilterable: true,
          displayFn(row){
            return getDateTimeFormat(row.time_insert)
          }
        },
        {
          field: "__download",
          caption: "Получить файл",
          displayType: 'button',
          buttonCaption: '<i class="fas fa-download"></i>',
          buttonAttrs: {
            'class': "btn btn-outline-dark",
            'title': 'Загрузить файл'
          },
          buttonClick: (row) => {
            this.$emit('onDownloadFile', row);
          },
          show: true,
          isSortable: false,
        },
      ],
    };
  },

};
</script>
