import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДАННЫХ СИСТЕМНЫХ СОБЫТИЙ

export default {
    actions: {
        // запрос списка объектов
        doFetchSystemEventDetails: (context, payload) => {
            return DoGet(
                '/logger/systemEventDetails/' + payload.system_event_id,
                null,
                [],
                'Запрос списка элементов системного события',
                false,
                true
            )
        },
    }
}