<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/admin">Администрирование</router-link></span>
        <span> / </span>
        <span>Пользователи</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <div class="container position-relative h-100">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">
            <crud-form
                :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
                :buttonsEnabled="crudButtonsEnabled"
                @onAdd="onTableAdd"
                @onEdit="onTableEdit"
                @onDelete="onTableDelete"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            >
              <!-- Кнопка Команда-->
              <template #leftCustomButton>
                <dropdown>
                  <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                  :disabled="commandDisabled">Команда
                  </button-toggler>
                  <dropdown-menu ref="collapse">
                    <dropdown-item @click="onBlockUser" v-if="blockExists">Заблокировать</dropdown-item>
                    <dropdown-item @click="onUnblockUser" v-if="unblockExists">Разблокировать</dropdown-item>
                    <dropdown-divider></dropdown-divider>
                    <dropdown-item @click="onSetPassword" v-if="setPasswordExists">Установить пароль</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>

              <!-- Кнопка связь-->
              <template #leftCustomButton2>
                <dropdown>
                  <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary"
                                  :disabled="linkDisabled">Связь
                  </button-toggler>
                  <dropdown-menu ref="collapse2">
                    <dropdown-item router-link :to="userRoleLink">Роли</dropdown-item>
                    <dropdown-item router-link :to="userGroupLink">Группы</dropdown-item>
                    <dropdown-divider></dropdown-divider>
                    <dropdown-item router-link :to="userAccessesLink">Доступ к данным</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>

              <!-- Выбор группы пользователей-->
              <template #leftCustomButton3>
                <user-groups-select class="form-control" v-model="user_group_id"></user-groups-select>
              </template>

            </crud-form>
          </align-panel>

          <!-- Таблица  -->
          <align-panel align="all">
            <users-edit-table
                :filterText="filterText"
                :user_group_id="user_group_id"
                :selectedRow="selectedRow"
                :isLoading="isLoading"
                @onRowSelect="onRowSelect"
                @onRowDblClick="onTableShow"
            ></users-edit-table>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <user-dialog
      v-if="isDialogVisible"
      :item="op === 'add' ? {} : selectedRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </user-dialog>

  <!-- Установка пароля-->
  <set-password-dialog
      v-if="isSetPasswordDialogVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmPwdSet"
  ></set-password-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить пользователя с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Подтверждение блокирования -->
  <confirm-message-box
      v-if="isBlockConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmBlock"
  >Вы уверены, что хотите заблокировать пользователя с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Подтверждение разблокирования -->
  <confirm-message-box
      v-if="isUnblockConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmUnblock"
  >Вы уверены, что хотите разблокировать пользователя с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>


</template>

<script>
import UsersEditTable from "../../components/auth/users/UsersEditTable";
import UserDialog from "../../components/auth/users/UserDialog";
import SetPasswordDialog from "@/components/auth/users/SetPasswordDialog";
import UserGroupsSelect from "@/components/auth/user_groups/UserGroupsSelect";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    UsersEditTable,
    UserDialog,
    SetPasswordDialog,
    UserGroupsSelect
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // выбранная группа
      user_group_id: null
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения диалого установки пароля
    isSetPasswordDialogVisible() {
      return this.op === 'setpass' && !!this.selectedRow
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // условие отображения подтверждения блокировки
    isBlockConfirmVisible() {
      return this.op === 'block' && !!this.selectedRow;
    },

    // условие отображения подтверждения разблокировки
    isUnblockConfirmVisible() {
      return this.op === 'unblock' && !!this.selectedRow;
    },

    // условие отображения меню блокировать
    blockExists() {
      return this.selectedRow && !this.selectedRow.is_blocked
    },

    // условие отображения меню разблокировать
    unblockExists() {
      return this.selectedRow && !!this.selectedRow.is_blocked
    },

    // условие отображения меню Установить пароль
    setPasswordExists() {
      return this.selectedRow
    },

    // условие отключения кнопки - Команды
    commandDisabled() {
      return !this.blockExists && !this.unblockExists && !this.setPasswordExists;
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // ссылка для перехода к ролям пользователя
    userRoleLink() {
      if (this.selectedRow) return '/admin/users/userRoleLinks/' + this.selectedRow.uid
      else return '';
    },

    // ссылка для перехода к группам пользователя
    userGroupLink() {
      if (this.selectedRow) return '/admin/users/userGroupLinks/' + this.selectedRow.uid
      else return '';
    },

    // ссылка для перехода к категориям доступа
    userAccessesLink() {
      if (this.selectedRow) return '/admin/users/userAccesses/' + this.selectedRow.uid
      else return '';
    }
  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/admin/users/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/admin/users/edit/" + this.selectedRow.uid);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/admin/users/del/" + this.selectedRow.uid);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // выполнено двойное нажатие строки в таблице (открываем просмотр)
    onTableShow(item) {
      this.$router.replace("/admin/users/show/" + item.uid);
    },

    // заблокировать пользователя
    onBlockUser() {
      this.$router.replace("/admin/users/block/" + this.selectedRow.uid);
    },

    // разблокировать пользователя
    onUnblockUser() {
      this.$router.replace("/admin/users/unblock/" + this.selectedRow.uid);
    },

    // установить пароль
    onSetPassword() {
      this.$router.replace("/admin/users/setpass/" + this.selectedRow.uid);
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/admin/users");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('auth/doFetchUserGroups')
        await this.$store.dispatch('auth/doFetchUserGroupLinks')
        await this.$store.dispatch('auth/doFetchUsers', {force})

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/admin/users");
    },

    // подстверждена блокировка элемента
    onConfirmBlock() {
      // удаляем элемент
      this.blockItem();
      // возвращаемся на страницу
      this.$router.replace("/admin/users");
    },

    // подстверждена блокировка элемента
    onConfirmUnblock() {
      // удаляем элемент
      this.unBlockItem();
      // возвращаемся на страницу
      this.$router.replace("/admin/users");
    },

    // подстверждена смена пароля
    onConfirmPwdSet(data) {
      // меняем пароль
      this.setPassword(data);
      // возвращаемся на страницу
      this.$router.replace("/admin/users");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/admin/users");
    },

    async addItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('auth/doAddUser', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('auth/doEditUser', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('auth/doDelUser', this.selectedRow)
        this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // блокировать объект
    async blockItem() {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('auth/doBlockUser', this.selectedRow)
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // разблокировать объект
    async unBlockItem() {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('auth/doUnblockUser', this.selectedRow)
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // установить пароль
    async setPassword(data) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('auth/doResetUserPassword', {
          ...data,
          uid: this.selectedRow.uid,
          login: this.selectedRow.login
        });
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    }
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

