import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ВАРИАНТОВ МАРШРУТА

export default {
    actions: {
        // запрос объекта
        doFetchRouteVariant: (context, payload) => {
            return DoGet(
                '/multipass/routeVariants/'+payload.route_variant_id,
                null,
                {},
                'Запрос варианта маршрута',
                false,
                false,
            )
        },

        // запрос списка объектов
        doFetchRouteVariants: (context, payload) => {
            return DoGet(
                '/multipass/routeVariants?route_id='+payload.route_id,
                null,
                [],
                'Запрос вариантов маршрута',
                false,
                true,
            )
        },

        // запрос списка всех объектов
        doFetchAllRouteVariants: () => {
            return DoGet(
                '/multipass/routeVariants',
                null,
                [],
                'Запрос вариантов маршрута',
                false,
                true,
            )
        },

        // добавление объекта
        doAddRouteVariant: (context, payload) => {
            return DoPost(
                '/multipass/routeVariants',
                payload,
                null,
                'Добавление варианта маршрута'
            )
        },

        // изменение объекта
        doEditRouteVariant: (context, payload) => {
            return DoPut(
                '/multipass/routeVariants/'+payload.route_variant_id,
                payload,
                null,
                'Изменение варианта маршрута'
            )
        },

        // изменение даты окончания действия варианта маршрута
        doChangeRouteVariant: (context, payload) => {
            return DoPut(
                '/multipass/setRouteVariantDateEnd/'+payload.route_variant_id,
                payload,
                null,
                'Изменение варианта маршрута'
            )
        },

        // установка статуса варианта маршрута
        doSetRouteVariantStatus: (context, payload) => {
            return DoPut(
                '/multipass/setRouteVariantStatus/'+payload.route_variant_id,
                {
                    route_variant_status_id: payload.route_variant_status_id,
                    force: payload.force
                },
                null,
                'Установка статуса варианта маршрута',
                false
            )
        },

        // вводим в действие вариант маршрута
        doActivateRouteVariant: (context, payload) => {
            return DoPut(
                '/multipass/activateRouteVariant/'+payload.route_variant_id,
                {force: payload.force},
                null,
                'Ввод в действие варианта маршрута',
                false
            )
        },

        // выводим из действия вариант маршрута
        doDeactivateRouteVariant: (context, payload) => {
            return DoPut(
                '/multipass/deactivateRouteVariant/'+payload.route_variant_id,
                null,
                null,
                'Вывод из действия варианта маршрута',
                false
            )
        },

        // удаление объекта
        doDelRouteVariant: (context, payload) => {
            return DoDelete(
                '/multipass/routeVariants/' + payload.route_variant_id,
                null,
                'Удаление варианта маршрута'
            )
        },

        // копирование объекта
        doCopyRouteVariant: (context, payload) => {
            return DoPost(
                '/multipass/copyRouteVariant/' + payload.route_variant_id,
                payload,
                null,
                'Копирование варианта маршрута'
            )
        },

    }
}
