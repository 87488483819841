<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Группа пользователей</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="userGroupId">Код:</label>
              <input-integer
                id="userGroupId"
                type="number"
                class="form-control w-25"
                v-model="userGroupId"
                readonly
              />
            </div>

            <div class="form-group">
              <label for="userGroupName">Наименование:</label><req-field />
              <input-string
                  class="form-control"
                  id="userGroupName"
                  v-model="userGroupName"
                  ref="userGroupName"
                  autocomplete="off"
                  :readonly="op === 'show'"
                  required
                  error="Введите наименование"
              />
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  mixins: [ValidateFormMixin],
  props: ['item', 'op'],
    emits: ['onOK', 'onCancel'],
    data() {
        return {
            userGroupId: this.item.user_group_id,
            userGroupName: this.item.user_group_name
        };
    },
    methods: {
        onSubmit() {
            if (this.validateForm(this.$refs)) {
                this.$emit('onOK', {
                    user_group_id: this.op === 'add' ? undefined : this.userGroupId,
                    user_group_name: this.userGroupName,
                })
            }
        },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>