import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ТИПОВ РЕЙСА

export default {
    actions: {

        // запрос объекта
        doFetchTripType: (context, payload) => {
            return DoGet(
                '/multipass/tripTypes/' + payload.trip_type_id,
                null,
                {},
                'Запрос типа рейса',
                false,
                true,
            )
        },

        // запрос всех объектов
        doFetchAllTripTypes: () => {
            return DoGet(
                '/multipass/tripTypes',
                null,
                {},
                'Запрос типа рейса',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchTripTypes: (context, payload) => {
            return DoGet(
                '/multipass/tripTypes?route_variant_id=' + payload.route_variant_id,
                null,
                [],
                'Запрос типов рейсов',
                false,
                true,
            )
        },

        // добавление объекта
        doAddTripType: (context, payload) => {
            return DoPost(
                '/multipass/tripTypes',
                payload,
                null,
                'Добавление типа рейса'
            )
        },

        // изменение объекта
        doEditTripType: (context, payload) => {
            return DoPut(
                '/multipass/tripTypes/' + payload.trip_type_id,
                payload,
                null,
                'Изменение типа рейса'
            )
        },

        // удаление объекта
        doDelTripType: (context, payload) => {
            return DoDelete(
                '/multipass/tripTypes/' + payload.trip_type_id,
                null,
                'Удаление типа рейса'
            )
        },

        // копирование объекта
        doCopyTripType: (context, payload) => {
            return DoPost(
                '/multipass/copyTripType/' + payload.trip_type_id,
                payload,
                null,
                'Копирование типа рейса'
            )
        },

    }
}
