<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span><router-link to="/asmpp/tripTypeComposites">Композитные типы рейсов</router-link></span>
        <span> / </span>
        <span>Карточка композитного рейса {{ tripTypeCompositesHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: !isTableEditMode, edit: !isTableEditMode, save: isTableEditMode, cancel: isTableEditMode, delete: false, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onSave="onTableSave"
              @onCancel="onTableCancel"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >
          </crud-form>
        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <trip-type-composite-cards-edit-table
              :isTableEditMode="isTableEditMode"
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :rowList="rows"
              @onDeleteCard="onDeleteCard"
              @onRowSelect="onRowSelect"
          ></trip-type-composite-cards-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <trip-type-composite-card-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
      :order="rows.length+1"
      :trip_type_composite_id="trip_type_composite_id"
  >
  </trip-type-composite-card-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import TripTypeCompositeCardsEditTable
  from "@/components/asmpp/trip_type_composite_cards/TripTypeCompositeCardsEditTable";
import TripTypeCompositeCardDialog from "@/components/asmpp/trip_type_composite_cards/TripTypeCompositeCardDialog";
import {getSignTitle} from "@/lib";
import {getStop} from "@/store/MultipassHelpers";

export default {
  components: {TripTypeCompositeCardDialog, TripTypeCompositeCardsEditTable},
  mixins: [PageMixin],
  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id",
    //идентификатор композита типов рейсов
    "trip_type_composite_id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // родительский композит
      tripTypeComposite: {},
      // отображать спиннер
      isLoading: false,
      // строки
      rows: [],
      // режим редактирования таблицы
      isTableEditMode: false,
      // полученные с сервера карточки
      originalCards: []
    }
  },

  computed: {

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !this.isTableEditMode,
        save: this.isTableEditMode,
        cancel: this.isTableEditMode,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {}
      } else return this.selectedRow;
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

  },

  methods: {

    // удаляем карточку
    onDeleteCard(order_num) {
      // ищем индекс
      const index = this.rows.findIndex(
          item => item.order_num === order_num
      );
      this.rows.splice(index, 1);
      for (let i = 1; i < this.rows.length; i++) {
        this.rows[i].order_num = i + 1;
      }
      // сбрасываем курсор
      this.selectedRow = null;
    },

    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/asmpp/tripTypeComposites/" + this.trip_type_composite_id + "/tripTypeCompositeCards/add");
    },

    // вызывается, когда таблица переходит в режим редактирования
    onTableEdit() {
      this.isTableEditMode = true;
    },

    // вызывается при сохранении таблицы
    onTableSave() {
      // снимаем режим редактирования
      this.isTableEditMode = false;
      // сохраняем карточку на сервере
      this.saveArray();
    },

    // вызывается при отмене режима редактирования таблицы
    onTableCancel() {
      // сбрасываем режим редактирования
      this.isTableEditMode = false;
      // восстанавливаем данные
      this.rows = this.originalCards.map(item => {
        return {...item}
      });
      this.selectedRow = null;
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/asmpp/tripTypeComposites/" + this.trip_type_composite_id + "/tripTypeCompositeCards");
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.saveArray(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/asmpp/tripTypeComposites/" + this.trip_type_composite_id + "/tripTypeCompositeCards");
    },

    // добавляем объект
    async saveArray(item) {
      this.isLoading = true;
      try {
        if (item) {
          let tripType = await this.$store.dispatch('multipass/doFetchTripType', {
            trip_type_id: item.trip_type_id,
          });
          item.__tripType = getSignTitle(tripType.trip_type_sign);
          item.__tripTypeOrderNumBegin = item.trip_type_order_num_begin ? getStop(item.trip_type_order_num_begin).long_name : '';
          item.__tripTypeOrderNumEnd = item.trip_type_order_num_end ? getStop(item.trip_type_order_num_end).long_name : '';
          this.rows.push(item);
          this.selectedRow = item;
        }
        let val = {
          itemArr: this.rows,
          trip_type_composite_id: this.trip_type_composite_id
        }
        let answr = await this.$store.dispatch('asmpp/doEditTripTypeCompositeCard', val);
        if (answr == false) {
          this.rows = this.originalCards.map(item => {
            return {...item}
          });
        } else {
          this.originalCards = this.rows.map(item => {
            return {...item}
          });
        }
      } finally {
        this.isLoading = false;
      }
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {
        // получаем маршруты
        await this.$store.dispatch('multipass/doFetchRoutes', {force});
        // получаем остановки
        await this.$store.dispatch('multipass/doFetchStops');
        // получаем родительский композит
        let tripTypeComposite = await this.$store.dispatch('asmpp/doFetchTripTypeComposite', {
          trip_type_composite_id: this.trip_type_composite_id,
        });
        if (tripTypeComposite) {
          this.tripTypeComposite = tripTypeComposite
        }
        // получаем карточки композита
        let rowArr = await this.$store.dispatch('asmpp/doFetchTripTypeCompositeCards', {
          trip_type_composite_id: this.trip_type_composite_id,
          force: force
        });
        console.log('rowArr',rowArr)
        for (const item of rowArr) {
          let tripType = await this.$store.dispatch('multipass/doFetchTripType', {
            trip_type_id: item.trip_type_id,
            force: force
          });
          item.__tripType = getSignTitle(tripType.trip_type_sign)

          item.__tripTypeOrderNumBegin = item.trip_type_order_num_begin ? getStop(item.trip_type_order_num_begin).long_name : '';
          item.__tripTypeOrderNumEnd = item.trip_type_order_num_end ? getStop(item.trip_type_order_num_end).long_name : '';

        }

        if (rowArr) {
          this.rows = rowArr
          this.originalCards = rowArr.map(item => {
            return {...item}
          });
        }

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

