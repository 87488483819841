<template>
  <input
      type="text"
      v-model.trim="value"
      v-bind="$attrs"
      maxlength="5"
      ref="inputRef"
      :class="{ 'is-invalid': isInvalid }"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      autocomplete="off"
      style="min-width: 60px"
  >
  <div class="invalid-feedback" v-if="!!errorText">{{ errorText }}</div>
  <div class="invalid-feedback" v-else>Введите время.</div>
</template>

<script>

import {getTimeFormat} from "@/lib";

const regex = new RegExp("^([0-9][0-9]):([0-5][0-9])$");

export default {
  // !! компонент возвращает время в секундах !!
  props: {
    'model-value': {
      required: true
    },
    'error': {
      type: String
    },
    'required': {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:model-value'],
  data() {
    return {
      isInvalid: false,
      errorText: this.error,
      tempValue: false
    }
  },
  computed: {
    value: {
      get() {
        if (this.modelValue == null || this.tempValue == '-1') {
          return '';
        } else if (this.tempValue.length == 1) {
          return getTimeFormat(this.modelValue).substring(0, 1)
        } else if (this.tempValue.length == 2) {
          return getTimeFormat(this.modelValue).substring(0, 3)
        } else if (this.tempValue.length == 3) {
          return getTimeFormat(this.modelValue).substring(0, 3)
        } else if (this.tempValue.length == 4) {
          return getTimeFormat(this.modelValue).substring(0, 4)
        } else if (this.tempValue.length == 5) {
          return getTimeFormat(this.modelValue).substring(0, 5)
        }
        return getTimeFormat(this.modelValue);
      },
      set(value) {
        this.tempValue = value;
        if (value.length == 0) {
          this.$emit('update:model-value', null)
        } else if (value.length == 1) {
          let result = Number(String((Number(value)) * 10).substring(0, 2) * 3600);
          this.$emit('update:model-value', result)
        } else if (value.length == 2) {
          let result = Number(value.substring(0, 2) * 3600);
          this.$emit('update:model-value', result)
        } else if (value.length == 4) {
          //не даем вводить значения минут больше 60
          if (Number(value.substring(3, 4) >= 6)) {
            this.tempValue = value.substring(0, 3);
            let result = Number(value.substring(0, 2) * 3600);
            this.$emit('update:model-value', result)
          } else {
            let result = Number(value.substring(0, 2) * 3600) + Number(value.substring(3, 4) * 600);
            this.$emit('update:model-value', result)
          }
        } else if (typeof (value) === 'string'){
          //не даем вводить значения минут больше 60
          if (Number(value.substring(3, 4) >= 6)) {
            this.tempValue = value.substring(0, 3) + value.substring(4, 5);
            let res = Number(value.substring(0, 2) * 3600) + Number(value.substring(4, 5) * 600);
            this.$emit('update:model-value', res)
          } else if (!regex.test(String(this.modelValue))){
            let result = Number(value.substring(0, 2) * 3600) + Number(value.substring(3, 5) * 60);
            this.$emit('update:model-value', result)
          }
        } else if (value.length < 5) {
          console.log('wrong format')
        } else {
          this.$emit('update:model-value', null)
        }
      }
    },
  },
  methods: {
    // проверка валидности
    isValid() {
      // разраешаем пустые строки, если допускается пустое значение
      if (this.modelValue === null || this.modelValue === '') {
        return !this.required
      }
      // все остальное можно
      return true;
    },
    // при получении фокуса
    onFocus() {
      // сбрасываем инвалидность
      this.isInvalid = false;
      this.errorText = this.error;
    },
    // при потере фокуса
    onBlur() {
      this.validate()
      this.tempValue = '00:00'
    },
    // при вводе значений
    onInput(e) {
      let value = this.$refs.inputRef.value;
      if (!Number(e.data) && e.inputType == "insertText" && e.data != ':' && e.data != 0) {
        //если поле остается пустым, записываем спец. код, чтобы вывести ""
        if (this.tempValue.substring(0,this.tempValue.length-1) === ''){
          this.tempValue = '-1';
        } else {
          this.$refs.inputRef.value = this.tempValue.substring(0,this.tempValue.length-1);
        }
      }
      if ( getTimeFormat(this.modelValue).substring(0, 3) == value && e.inputType == "deleteContentBackward") {
        this.tempValue = this.tempValue.substring(0, 2);
        this.$forceUpdate();
      }
      this.validate();
    },
    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },
    alarm(message) {
      this.errorText = message;
      this.isInvalid = true;
    }
  },
  created() {
    // на всякий случай - меняем undefined на ''
    this.$watch(() => this.modelValue, () => {
      if (typeof (this.modelValue) === 'string') { //&& !regex.test(String(this.modelValue))) {
        this.$emit('update:model-value', null);
      } else if (this.modelValue === undefined) {
        this.$emit('update:model-value', null);
      }
    }, {immediate: true});
    // следим за изменением текста
    this.$watch(() => this.error, () => {
      this.errorText = this.error;
    })
  },
  mounted() {
    if (this.$attrs.autofocus !== undefined && this.$attrs.autofocus !== false) {
      this.$refs.inputRef.focus();
    }
  }
}
</script>

<style scoped>

input::placeholder {
  color: #a9a9a9;
}

</style>