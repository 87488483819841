import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ОТЧЕТОВ

export default {
    state() {
        return {
            // список объектов
            reports: [],
            // время обновления кэша new Date().getTime()
            reportsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            reportsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getReports(state) {
            return state.reports
        }
    },
    mutations: {
        // установить новый список объектов
        setReports(state, payload) {
            state.reports = payload;
            state.reportsCache = new Date().getTime();
        }
    },
    actions: {
        // запрос списка объектов
        doFetchReports: (context, payload) => {
            return DoGet(
                '/system/reports',
                'system/setReports',
                [],
                'Запрос отчетов',
                false,
                payload && payload.force,
                context.state.reportsCache,
                context.state.reportsCacheTimeout
            )
        },

        // очищает кэш
        doClearReports: (context, payload) => {
            return DoClearCache(
                'system/setReports',
                [],
                payload && payload.force,
                context.state.reportsCache,
                context.state.reportsCacheTimeout
            )
        },
    }
}