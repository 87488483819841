import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ВИДОВ ПЕРЕВОЗОК

export default {
    state() {
        return {
            // список объектов
            transportationKinds: [],
            // время обновления кэша new Date().getTime()
            transportationKindsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            transportationKindsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getTransportationKinds(state) {
            return state.transportationKinds;
        },
    },
    mutations: {
        // установить новый список объектов
        setTransportationKinds(state, payload) {
            state.transportationKinds = payload;
            state.transportationKindsCache = new Date().getTime()
        },

    },
    actions: {
        // запрос списка объектов
        doFetchTransportationKinds: (context, payload) => {
            return DoGet(
                '/rmt/transportationKinds',
                'rmt/setTransportationKinds',
                [],
                'Запрос видов перевозок',
                false,
                payload && payload.force,
                context.state.transportationKindsCache,
                context.state.transportationKindsCacheTimeout
            )
        },

        // очищает кэш
        doClearTransportationKinds: (context, payload) => {
            return DoClearCache(
                'rmt/setTransportationKinds',
                [],
                payload && payload.force,
                context.state.transportationKindsCache,
                context.state.transportationKindsCacheTimeout
            )
        },
    }
}
