import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ВАРИАНТОВ РИСПИСАНИЙ

export default {
    actions: {
        // запрос объекта
        doFetchScheduleVariant: (context, payload) => {
            return DoGet(
                '/rmt/scheduleVariants/' + payload.schedule_variant_id,
                null,
                {},
                'Запрос варианта расписания',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchScheduleVariants: (context, payload) => {
            return DoGet(
                '/rmt/scheduleVariants?route_variant_id=' + payload.route_variant_id,
                null,
                [],
                'Запрос вариантов расписания',
                false,
                true,
            )
        },

        // запрос списка всех объектов
        doFetchAllScheduleVariants: () => {
            return DoGet(
                '/rmt/scheduleVariants',
                null,
                [],
                'Запрос вариантов расписания',
                false,
                true,
            )
        },

        // добавление объекта
        doAddScheduleVariant: (context, payload) => {
            return DoPost(
                '/rmt/scheduleVariants',
                payload,
                null,
                'Добавление варианта расписания'
            )
        },

        // изменение объекта
        doEditScheduleVariant: (context, payload) => {
            return DoPut(
                '/rmt/scheduleVariants/' + payload.schedule_variant_id,
                payload,
                null,
                'Изменение варианта расписания'
            )
        },

        // изменение объекта
        doChangeScheduleVariant: (context, payload) => {
            return DoPut(
                '/rmt/setScheduleVariantDateEnd/' + payload.schedule_variant_id,
                payload,
                null,
                'Изменение даты окончания действия варианта расписания'
            )
        },

        // установка статуса варианта расписаний
        doSetScheduleVariantStatus: (context, payload) => {
            return DoPut(
                '/rmt/setScheduleVariantStatus/' + payload.schedule_variant_id,
                {
                    schedule_variant_status_id: payload.schedule_variant_status_id,
                    force: payload.force
                },
                null,
                'Установка статуса варианта расписания',
                false
            )
        },

        // вводим в действие вариант расписаний
        doActivateScheduleVariant: (context, payload) => {
            return DoPut(
                '/rmt/activateScheduleVariant/' + payload.schedule_variant_id,
                {force: payload.force},
                null,
                'Ввод в действие варианта расписания',
                false
            )
        },

        // выводим из действия вариант расписаний
        doDeactivateScheduleVariant: (context, payload) => {
            return DoPut(
                '/rmt/deactivateScheduleVariant/' + payload.schedule_variant_id,
                {force: payload.force},
                null,
                'Вывод из действия варианта расписания',
                false
            )
        },

        // удаление объекта
        doDelScheduleVariant: (context, payload) => {
            return DoDelete(
                '/rmt/scheduleVariants/' + payload.schedule_variant_id,
                null,
                'Удаление варианта расписания'
            )
        },

        // копирование объекта
        doCopyScheduleVariant: (context, payload) => {
            return DoPost(
                '/rmt/copyScheduleVariant/' + payload.schedule_variant_id,
                payload,
                null,
                'Копирование варианта расписания'
            )
        },

        // запрос списка доступных вариантов расписаний для переключения
        doGetRecipientScheduleVariants: (context, payload) => {
            return DoGet(
                '/rmt/recipientScheduleVariants/' + payload.schedule_variant_id,
                null,
                {},
                'Запрос списка доступных вариантов расписаний для переключения',
                false,
                true,
            )
        },

    }
}
