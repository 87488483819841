<template>
  <input
      type="text"
      v-bind="$attrs"
      :class="{ 'is-invalid': isInvalid && !readonly && !disabled }"
      :readonly="readonly"
      :disabled="disabled"
      ref="inputRef"
      autocomplete="off"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
  >
  <div class="invalid-feedback">{{ error }}</div>
</template>

<script>
export default {
  props: {
    // модель
    'model-value': {
      required: true
    },
    // минимальное разрешенное значение
    'min-value': {
      type: Number,
    },
    // исключать минимальное значение из диапазона
    'min-value-exclude': {
      type: Boolean,
      default: false
    },
    // максимальное разрешенное значение
    'max-value': {
      type: Number
    },
    // исключать максимальное значение из диапазона
    'max-value-exclude': {
      type: Boolean,
      default: false
    },
    // требуется ввод значения
    'required': {
      type: Boolean,
      default: false
    },
    // только для чтения
    'readonly': {
      type: Boolean,
      default: false,
    },
    // отключен
    'disabled': {
      type: Boolean,
      default: false,
    },
    // текст ошибки
    'error': {
      type: String,
      default: 'Введите длительность'
    },
  },

  emits: ['update:model-value'],

  data() {
    return {
      // признак ошибки
      isInvalid: false,
      // последнее корректное значение
      lastText: '',
    }
  },

  methods: {
    // проверка валидности
    isValid() {
      // в этих режимах на значения не смотрим
      if (this.readonly || this.disabled) return true;

      // разраешаем пустые строки, если допускается пустое значение
      if (this.modelValue === null)
        return !this.required

      const newValue = Number(this.modelValue);
      if (!Number.isInteger(newValue))
        return false;

      // проверяем минимальные значения
      if (Number.isFinite(this.minValue)) {
        if (this.minValueExclude) {
          if (newValue <= this.minValue)
            return false;
        }
        else {
          if (newValue < this.minValue)
            return false;
        }
      }

      // проверяем максимальные значения
      if (Number.isFinite(this.maxValue)) {
        if (this.maxValueExclude) {
          if (newValue >= this.maxValue)
            return false;
        }
        else {
          if (newValue > this.maxValue)
            return false;
        }
      }

      return true;
    },

    // при получении фокуса - сбрасываем инвалидность
    onFocus() {
      this.isInvalid = false;
    },

    // при потере фокуса - проверяем значение
    onBlur() {
      this.$refs.inputRef.value = this.lastText;
      this.validate();
    },

    // при вводе значений
    onInput() {
      // запрашиваем текущее значение
      const value = this.$refs.inputRef.value.trim();

      // пустая строка
      if (value === '') {
        this.$refs.inputRef.value = '';
        this.lastText = '';
        this.$emit('update:model-value', null);
        return
      }

      // целое число
      if (Number.isInteger(Number(value))) {
        this.$refs.inputRef.value = String(Number(value));
        this.lastText = String(Number(value));
        this.$emit('update:model-value', Number(value) * 60);
        return
      }

      // пришло непонятно чего - меняем текст назад
      this.$refs.inputRef.value = this.lastText;
    },

    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },
  },

  mounted() {
    // следим за изменением модели
    this.$watch(() => this.modelValue, (value) => {

      // пришла строка, которую можно преобразовать в число
      if (typeof(value) === 'string' && Number.isFinite(Number(value))) {
        this.$emit('update:model-value', Number(value));
        return
      }

      // пришло число
      if (typeof(value) === 'number' && Number.isFinite(value)) {
        let changed = false;
        // не разрешаем отрицательные числа
        if (value < 0) {
          value = Math.abs(value);
          changed = true;
        }
        // если пришло дробное число - отрезаем дробную часть
        if (!Number.isInteger(value)) {
          value = Math.trunc(value)
          changed = true;
        }
        // если что-то изменилось - передергиваем значение
        if (changed) {
          this.$emit('update:model-value', value);
          return
        }

        this.$refs.inputRef.value = String(value / 60);
        this.lastText = String(value / 60);

        return
      }

      // пришло непонятно чего
      this.$refs.inputRef.value = '';
      this.lastText = '';
      this.$emit('update:model-value', null);
    }, {immediate: true})
  },
}
</script>

<style scoped>

input::placeholder {
  color: #a9a9a9;
}

</style>