<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-rmt-regimes"> </edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "regime_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "prefix",
          caption: "Префикс номера графика",
          sortType: 'number',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["rmt/getRegimes"].map(regime => {
        regime.__id = regime.regime_id;
        return regime;
      });
    },
  }
};
</script>
