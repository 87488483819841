<template>
  <edit-table-panel :columnList="columnList" :rowsPerPage="1000" :selectedRow="selectedRow" :rowList="rows" :disableSettings="true" name="tbl-nav-section"> </edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading', 'rowList', 'selectedRow'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "day_date",
          caption: "Дата",
          sortType: 'String',
          sorted: 'dsc',
          displayType: 'String',
          isSortable: false,
        },
      ],
    };
  },
  computed: {
    rows() {
      if (this.isLoading === 'fetch') {
        return []
      }
      return this.rowList.map(value => {
        if (value.prev_section != value.section || value.next_section != value.section) {
          value.__cellStyle = {
            backgroundColor: '#ff8080',
          }
          if(this.selectedRow && value.date_index == this.selectedRow.date_index){
            value.__cellStyle = {};
          }
        }
        value.__id = value.date_index;
        return value;
      });
    },
  },
};
</script>
