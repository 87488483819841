import AccessCategoriesAccessStore from "@/store/access/AccessCategoriesAccessStore";
import UserAccessesAccessStore from "@/store/access/UserAccessesAccessStore";


// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ ACCESS

export default {
    modules: {
        AccessCategoriesAccessStore,
        UserAccessesAccessStore
    },
    namespaced: true,
}