<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span>Автостанции</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="crudButtonsExist"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
              @onSave="onTableSave"
              @onCancel="onTableCancel"
          >

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">Связь</button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="stopstationZonesLink">Зона автостанции</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item router-link :to="stopstationDocsLink">Документы</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <!-- Таблица  -->
            <align-panel align="left" width="50%" :gap="3">
              <stopstations-edit-table
                  :selectedRow="selectedRow"
                  :filterText="filterText"
                  @onRowSelect="onRowSelect"
                  @onRowDblClick="onTableShow"
              ></stopstations-edit-table>
            </align-panel>
            <!-- Карта  -->
            <align-panel align="all">
              <stopstations-map
                  :selectedStopstation="selectedRow"
                  :isMoveMode="isMoveMode"
                  @onMapCreated="onMapCreated"
                  @onAdd="onTableAdd"
                  @onEdit="onTableEdit"
                  @onDelete="onTableDelete"
                  @onShow="onTableShow"
                  @onStopstationSelect="onStopstationSelect"
                  @onMove="onMoveMode"
                  @onStopstationChanged="onStopstationChanged"
              >
              </stopstations-map>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <stopstation-dialog
      v-if="isDialogVisible"
      :item=dialogRow
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </stopstation-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить автостанцию с кодом {{ id }} ?</confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import StopstationsMap from "@/components/multipass/stopstations/StopstationsMap";
import StopstationsEditTable from "@/components/multipass/stopstations/StopstationsEditTable";
import StopstationDialog from "@/components/multipass/stopstations/StopstationDialog";
import store from "@/store/Store";
import PageMixin from "@/pages/share/PageMixin";
import {editMutation} from "@/store/Actions";

export default {
  mixins: [PageMixin],

  components: {
    StopstationsEditTable,
    StopstationsMap,
    StopstationDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op", 
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // карта
      leaflet: null,
      // отображать спиннер
      isLoading: false,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // исходная автостанция
      originStopstation: null,
      // признак модификации
      isModified: false,
      // режим перемещения
      isMoveMode: false,
      // данные, которые отображаются в диалоговом окне
      dialogRow: {},
    }
  },

  computed: {
      // включение crud-кнопок
      crudButtonsExist() {
        return {
          add: !this.isMoveMode,
          edit: !this.isMoveMode,
          delete: !this.isMoveMode,
          save: this.isMoveMode,
          cancel: this.isMoveMode,
          refresh: true
        }
      },

      // состояние crud-кнопок
      crudButtonsEnabled() {
        return {
          add: true,
          edit: !!this.selectedRow,
          delete: !!this.selectedRow,
          save: this.isModified,
          cancel: true,
          refresh: true
        }
      },

      // условие отображения диалогового окна
      isDialogVisible() {
          return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
      },

      // условие отображения подтверждения удаления
      isDelConfirmVisible() {
          return this.op === 'del' && !!this.selectedRow;
      },

      // условие отключения кнопки - Связь
      linkDisabled() {
        return !this.selectedRow;
      },

      //путь к зоне автостанции
      stopstationZonesLink() {
        if (this.selectedRow) return '/multipass/stopstations/'+ this.selectedRow.stopstation_id +'/stopstationZones';
        else return '';
      },

      //путь к остановочным павильонам
      stopstationDocsLink(){
        if (this.selectedRow) return '/multipass/stopstations/'+ this.selectedRow.stopstation_id +'/stopstationDocs';
        else return '';
      },
  },
  methods: {
    // создана карта
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // нажата кнопка "Добавить"
    onTableAdd(item = {}) {
      this.dialogRow = item;
      this.$router.replace("/multipass/stopstations/add");
    },

    // нажата кнопка "Изменить"
    onTableEdit(item = this.selectedRow) {
      this.dialogRow = item;
      this.$router.replace("/multipass/stopstations/edit/" + item.stopstation_id);
    },

    // нажата кнопка Удалить
    onTableDelete(item = this.selectedRow) {
      this.$router.replace("/multipass/stopstations/del/" + item.stopstation_id);
    },

    // нажата конпка "Просмотр"
    onTableShow(item = this.selectedRow) {
      this.dialogRow = item;
      this.$router.replace("/multipass/stopstations/show/" + item.stopstation_id);
    },

    // нажата кнопка "Сохранить" (в режиме перемещения)
    async onTableSave() {
      // сохраняем изменения
      await this.editItem(this.selectedRow);
      // снимаем режим редактирования
      this.isMoveMode = false;
    },

    // нажата кнопка "Отменить" (в режиме перемещения)
    async onTableCancel() {
      // отменяем изменения
      await this.restoreItem();
      // снимаем режим редактирования
      this.isMoveMode = false;
    },

    // нажата кнопка "Обновить" в таблице
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // выбрано меню "Переместить"
    onMoveMode() {
      // запоминаем остановку
      this.originStopstation = {...this.selectedRow};
      // сбрасываем признак изменения
      this.isModified = false;
      // включаем режим перемещения
      this.isMoveMode = true;
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/multipass/stopstations");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      }
      else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/multipass/stopstations");
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/multipass/stopstations");
    },

    // автостанция переместилась
    onStopstationChanged(stopstation) {
      this.selectedRow = editMutation(store.getters["multipass/getStopstations"], 'stopstation_id', stopstation);
      // устанавливаем признак изменения
      this.isModified = true;
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    async onRowSelect(item) {
      // включен режим перемещения
      if (this.selectedRow && this.isMoveMode && this.selectedRow !== item) {
        // сбрасываем режим редактирования
        this.isMoveMode = false;
        // отменяем изменения
        await this.restoreItem();
      }
      // выделяем новую автостанцию
      this.selectedRow = item;
      // ругаемся
      if (!item.latitude || !item.longitude) {
        store.dispatch('doAddMessage', {
          caption: 'Отображение автостанции',
          message: 'Не указаны координаты',
          type: 'error'
        }, {root: true});
        // выходим
        return;
      }

      // перемещаем карту к нужной автостанции
      if (this.leaflet && item.latitude && item.longitude) {
        const minStopZoom = this.$store.getters['settings/getMinStopZoom'];
        const zoom = this.leaflet.getZoom();
        if (zoom >= minStopZoom)
          this.leaflet.setView([item.latitude, item.longitude], zoom)
        else
          this.leaflet.setView([item.latitude, item.longitude], minStopZoom)
      }
    },

    // вызывается при щелчке на автостанции в карте
    async onStopstationSelect(item) {
      // включен режим перемещения
      if (this.selectedRow && this.isMoveMode && this.selectedRow !== item) {
        // сбрасываем режим перемещения
        this.isMoveMode = false;
        // отменяем изменения
        await this.restoreItem();
      }
      // выделяем новую автостанцию
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading='fetch';
      try {

        await this.$store.dispatch('multipass/doFetchStopstationTypes')
        await this.$store.dispatch('multipass/doFetchStopstations', { force })

      }
      finally {
        this.isLoading=false;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        const stopstation = await this.$store.dispatch('multipass/doAddStopstation', item);
        if (stopstation) this.selectedRow = stopstation;
          else this.selectedRow = null;
      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const stopstation = await this.$store.dispatch('multipass/doEditStopstation', item);
        if (stopstation) this.selectedRow = stopstation;
        else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('multipass/doDelStopstation', this.selectedRow);
        this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // восстанавливаем объект
    async restoreItem() {
      this.selectedRow =  editMutation(store.getters["multipass/getStopstations"], 'stopstation_id', this.originStopstation);
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
