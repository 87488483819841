import StopsMultipassStore from "@/store/multipass/StopsMultipassStore";
import StopstationsMultipassStore from "@/store/multipass/StopstationsMultipassStore";
import StopTypesMultipassStore from "@/store/multipass/StopTypesMultipassStore";
import RouteKindsMultipassStore from "@/store/multipass/RouteKindsMultipassStore";
import RouteTypesMultipassStore from "@/store/multipass/RouteTypesMultipassStore";
import StopstationTypesMultipassStore from "@/store/multipass/StopstationTypesMultipassStore";
import TariffTypesMultipassStore from "@/store/multipass/TariffTypesMultipassStore";
import RouteStatusesMultipassStore from "@/store/multipass/RouteStatusesMultipassStore";
import TransferKindsMultipassStore from "@/store/multipass/TransferKindsMultipassStore";
import RoutesMultipassStore from "@/store/multipass/RoutesMultipassStore";
import RouteVariantsMultipassStore from "@/store/multipass/RouteVariantsMultipassStore";
import RouteVariantStatusesMultipassStore from "@/store/multipass/RouteVariantStatusesMultipassStore";
import TariffZoneSystemsMultipassStore from "@/store/multipass/TariffZoneSystemsMultipassStore";
import TariffZonesMultipassStore from "@/store/multipass/TariffZonesMultipassStore";
import TripTypesMultipassStore from "@/store/multipass/TripTypesMultipassStore";
import TripTypeBaseLineMultipassStore from "@/store/multipass/TripTypeBaseLineMultipassStore";
import TripTypeLineMultipassStore from "@/store/multipass/TripTypeLineMultipassStore";
import TripTypeCardMultipassStore from "@/store/multipass/TripTypeCardMultipassStore";
import RouteGroupsMultipassStore from "@/store/multipass/RouteGroupsMultipassStore";
import RouteGroupLinksMultipassStore from "@/store/multipass/RouteGroupLinksMultipassStore";
import RouteVariantDepotLinksMultipassStore from "@/store/multipass/RouteVariantDepotLinksMultipassStore";
import StopPavilionKindsMultipassStore from "@/store/multipass/StopPavilionKindsMultipassStore";
import StopPavilionTypesMultipassStore from "@/store/multipass/StopPavilionTypesMultipassStore";
import StopPavilionsMultipassStore from "@/store/multipass/StopPavilionsMultipassStore";
import StopDocsMultipassStore from "@/store/multipass/StopDocsMultipassStore";
import StopPavilionDocsMultipassStore from "@/store/multipass/StopPavilionDocsMultipassStore";
import StopstationDocsMultipassStore from "@/store/multipass/StopstationDocsMultipassStore";
import RouteDocsMultipassStore from "@/store/multipass/RouteDocsMultipassStore";
import RouteVariantDocsMultipassStore from "@/store/multipass/RouteVariantDocsMultipassStore";
import StopDocFilesMultipassStore from "@/store/multipass/StopDocFilesMultipassStore";
import StopPavilionDocFilesMultipassStore from "@/store/multipass/StopPavilionDocFilesMultipassStore";
import StopstationDocFilesMultipassStore from "@/store/multipass/StopstationDocFilesMultipassStore";
import RouteDocFilesMultipassStore from "@/store/multipass/RouteDocFilesMultipassStore";
import RouteVariantDocFilesMultipassStore from "@/store/multipass/RouteVariantDocFilesMultipassStore";
import TariffZoneFaresMultipassStore from "@/store/multipass/TariffZoneFaresMultipassStore";
import StopZonesMultipassStore from "@/store/multipass/StopZonesMultipassStore";
import TripTypeZonesMultipassStore from "@/store/multipass/TripTypeZonesMultipassStore";
import StopstationZonesMultipassStore from "@/store/multipass/StopstationZonesMultipassStore";
import TariffZoneZonesMultipassStore from "@/store/multipass/TariffZoneZonesMultipassStore";
import TariffKindsMultipassStore from "@/store/multipass/TariffKindsMultipassStore";

// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ MULTIPASS

export default {
    namespaced: true,
    modules: {
        StopsMultipassStore,
        StopstationsMultipassStore,
        StopTypesMultipassStore,
        RouteKindsMultipassStore,
        RouteTypesMultipassStore,
        StopstationTypesMultipassStore,
        TariffTypesMultipassStore,
        RouteStatusesMultipassStore,
        TransferKindsMultipassStore,
        RoutesMultipassStore,
        RouteVariantsMultipassStore,
        RouteVariantStatusesMultipassStore,
        TariffZoneSystemsMultipassStore,
        TariffZonesMultipassStore,
        TripTypesMultipassStore,
        TripTypeBaseLineMultipassStore,
        TripTypeLineMultipassStore,
        TripTypeCardMultipassStore,
        RouteGroupsMultipassStore,
        RouteGroupLinksMultipassStore,
        RouteVariantDepotLinksMultipassStore,
        StopPavilionKindsMultipassStore,
        StopPavilionTypesMultipassStore,
        StopPavilionsMultipassStore,
        StopDocsMultipassStore,
        StopPavilionDocsMultipassStore,
        StopstationDocsMultipassStore,
        RouteDocsMultipassStore,
        RouteVariantDocsMultipassStore,
        StopDocFilesMultipassStore,
        StopPavilionDocFilesMultipassStore,
        StopstationDocFilesMultipassStore,
        RouteDocFilesMultipassStore,
        RouteVariantDocFilesMultipassStore,
        TariffZoneFaresMultipassStore,
        StopZonesMultipassStore,
        TripTypeZonesMultipassStore,
        StopstationZonesMultipassStore,
        TariffZoneZonesMultipassStore,
        TariffKindsMultipassStore,
    }
}
