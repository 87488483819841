import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ИЗМЕРЯЕМЫХ ПАРАМЕТРОВ

export default {
    state() {
        return {
            // список объектов
            params: [],
            // время обновления кэша new Date().getTime()
            paramsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            paramsCacheTimeout: null
        }
    },
    getters: {

        // вернуть список объектов
        getParams(state) {
            return state.params;
        },

        // вернуть список аналоговых параметров с дополнительными полями
        getAnalogParams(state) {
            let arr = [];
            // добавляем в массив параметров заданного типа
            state.params.forEach(value => {
                if (value.param_type_id == 0) {
                    value.title = value.param_sign + ' - ' + value.param_title;
                    value.value = value.param_sign;
                    value.id = value.param_id;
                    arr.push(value);
                }
            })
            // сортируем полученные параметры
            arr.sort(function (a, b) {
                if(a.param_sign.toLowerCase() < b.param_sign.toLowerCase()) { return -1; }
                if(a.param_sign.toLowerCase() > b.param_sign.toLowerCase()) { return 1; }
                return 0;
            });
            return state.params;
        },

        // вернуть список дискретных с дополнительными полями
        getDiscreteParams(state) {
            let arr = [];
            // добавляем в массив параметров заданного типа
            state.params.forEach(value => {
                if (value.param_type_id == 1) {
                    value.title = value.param_sign + ' - ' + value.param_title;
                    value.value = value.param_sign;
                    value.id = value.param_id;
                    arr.push(value);
                }
            })
            // сортируем полученные параметры
            arr.sort(function (a, b) {
                if(a.param_sign.toLowerCase() < b.param_sign.toLowerCase()) { return -1; }
                if(a.param_sign.toLowerCase() > b.param_sign.toLowerCase()) { return 1; }
                return 0;
            });
            return state.params;
        }
    },
    mutations: {
        // установить новый список объектов
        setParams(state, payload) {
            state.params = payload;
            state.paramsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchParams: (context, payload) => {
            return DoGet(
                '/nav/params',
                'nav/setParams',
                [],
                'Запрос параметров',
                false,
                payload && payload.force,
                context.state.paramsCache,
                context.state.paramsCacheTimeout
            )
        },

        // запрос объекта
        doFetchParam: (context, payload) => {
            return DoGet(
                '/nav/params/' + payload.param_id,
                null,
                [],
                'Запрос параметра',
                false,
                payload && payload.force,
                context.state.paramsCache,
                context.state.paramsCacheTimeout
            )
        },

        // очищает кэш
        doClearParams: (context, payload) => {
            return DoClearCache(
                'nav/setParams',
                [],
                payload && payload.force,
                context.state.paramsCache,
                context.state.paramsCacheTimeout
            )
        },

    }
}