import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ПЕРИОДОВ СУТОК (для норм на интервалы движения)

export default {
    actions: {
        // запрос списка объектов
        doFetchIntNormPeriods: (context, payload) => {
            return DoGet(
                '/rmt/intNormPeriods?schedule_variant_id=' + payload.schedule_variant_id,
                null,
                [],
                'Запрос периодов суток',
                false,
                true,
            )
        },

        // добавление объекта
        doAddIntNormPeriod: (context, payload) => {
            return DoPost(
                '/rmt/intNormPeriods',
                payload,
                null,
                'Добавление периода суток'
            )
        },

        // изменение объекта
        doEditIntNormPeriod: (context, payload) => {
            return DoPut(
                '/rmt/intNormPeriods/' + payload.int_norm_period_id,
                payload,
                null,
                'Изменение периода суток'
            )
        },

        // удаление объекта
        doDelIntNormPeriod: (context, payload) => {
            return DoDelete(
                '/rmt/intNormPeriods/' + payload.int_norm_period_id,
                null,
                'Удаление периода суток'
            )
        }
    }
}
