import NavTitlePage from '../pages/nav/NavTitlePage.vue';
import SensorsPage from "@/pages/nav/SensorsPage";
import SensorGroupsPage from "@/pages/nav/SensorGroupsPage";
import ParamsPage from "@/pages/nav/ParamsPage";
import ParamGroupsPage from "@/pages/nav/ParamGroupsPage";
import RadioProtocolsPage from "@/pages/nav/RadioProtocolsPage";
import RadioModelsPage from "@/pages/nav/RadioModelsPage";
import RadiosPage from "@/pages/nav/RadiosPage";
import MapPage from "@/pages/nav/MapPage";
import RadioStatesPage from "@/pages/nav/radios/RadioStatesPage";
import NavDayStatesPage from "@/pages/nav/NavDayStatesPage";
import MainPage from "@/pages/main/MainPage";


export default {
    path: '/nav', 
    component: MainPage,
    children: [
        {
            path: 'navDayStates',
            component: NavDayStatesPage,
            props: true,
        },
        {
            path: 'map/:op(show)/:id(\\d+)?',
            component: MapPage,
            props: true
        },
        {
            path:'map',
            component: MapPage,
        },
        {
            path: 'radios/:radio_id(\\d+)/radioStates',
            component: RadioStatesPage,
            props: true
        },
        {
            path: 'radios/:op(show|add|del|edit|discard|restore)/:id(\\d+)?',
            component: RadiosPage,
            props: true
        },
        {
            path: 'radios',
            component: RadiosPage
        },
        {
            path: 'radioModels/:op(show|add|del|edit)/:id(\\d+)?',
            component: RadioModelsPage,
            props: true
        },
        {
            path: 'radioModels',
            component: RadioModelsPage
        },
        {
            path:'radioProtocols',
            component: RadioProtocolsPage
        },
        {
            path:'params',
            component: ParamsPage
        },
        {
            path:'paramGroups',
            component: ParamGroupsPage
        },
        {
            path:'sensorGroups',
            component: SensorGroupsPage
        },
        {
            path:'sensors',
            component: SensorsPage
        },
        {
            path: '',
            component: NavTitlePage
        },
        {
            path: ':pathMatch(.*)*',
            component: NavTitlePage
        }                
    ]
}