import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ТРАНСПОРТНЫХ ПРЕДПРИЯТИЙ

export default {
    state() {
        return {
            // список объектов
            depots: [],
            // время обновления кэша new Date().getTime()
            depotsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            depotsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getDepots(state) {
            return state.depots;
        },
    },
    mutations: {
        // установить новый список объектов
        setDepots(state, payload) {
            state.depots = payload;
            state.depotsCache = new Date().getTime()
        },
        // добавить объект
        addDepot(state, payload) {
            state.depots.push(payload)
        },
        // изменить объект
        editDepot(state, payload) {
            const index = state.depots.findIndex(depot => depot.depot_id == payload.depot_id);
            if (index >= 0) {
                state.depots[index] = payload;
            }
        },
        // удалить объект
        delDepot(state, payload) {
            const index = state.depots.findIndex(depot => depot.depot_id == payload.depot_id);
            state.depots.splice(index, 1);
        }
    },
    actions: {
        // запрос объекта
        doFetchDepot: (context, payload) => {
            return DoGet(
                '/ref/depots/' + payload.depot_id,
                null,
                {},
                'Запрос транспортного предприятия',
                false,
                true
            )
        },

        // запрос списка объектов
        doFetchDepots: (context, payload) => {
            return DoGet(
                '/ref/depots',
                'ref/setDepots',
                [],
                'Запрос транспортных предприятий',
                false,
                payload && payload.force,
                context.state.depotsCache,
                context.state.depotsCacheTimeout
            )
        },

        // очищает кэш
        doClearDepots: (context, payload) => {

            return DoClearCache(
                'ref/setDepots',
                [],
                payload && payload.force,
                context.state.depotsCache,
                context.state.depotsCacheTimeout
            )
        },

        // добавление объекта
        doAddDepot: (context, payload) => {
            return DoPost(
                '/ref/depots',
                payload,
                'ref/addDepot',
                'Добавление транспортного предприятия'
            )
        },

        // изменение объекта
        doEditDepot: (context, payload) => {
            return DoPut(
                '/ref/depots/'+payload.depot_id,
                payload,
                'ref/editDepot',
                'Изменение транспортного предприятия'
            )
        },

        // удаление объекта
        doDelDepot: (context, payload) => {
            return DoDelete(
                '/ref/depots/'+payload.depot_id,
                'ref/delDepot',
                'Удаление транспортного предприятия'
            )
        }
    }
}