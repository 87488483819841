<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span><router-link to="/asmpp/asopDepots">Транспортные предприятия</router-link></span>
        <span> / </span>
        <span>Показатели пассажиропотока по оплаченным поездкам {{depotPageHeader}} за период дат {{ getDateForHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <pay-trans-by-depot-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
              :payTransByDepot="payTransByDepot"
          ></pay-trans-by-depot-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>


import PageMixin from "@/pages/share/PageMixin";
import {ndToDateString} from "@/lib";
import PayTransByDepotEditTable from "@/components/asmpp/pay_trans_by_depot/PayTransByDepotEditTable";

export default {
  mixins: [PageMixin],

  components: {
    PayTransByDepotEditTable,
  },

  props: [
    // идентификатор редактируемого объекта
    "depot_id",
    // выбранная дата начала
    "date_index_begin",
    // выбранная дата начала
    "date_index_end"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // выбранное предприятие
      depot: {},
      // строки таблицы
      payTransByDepot: [],
    }
  },

  computed: {

    // получение даты для заголовка
    getDateForHeader() {
      return 'c ' + ndToDateString(this.date_index_begin) + ' по ' + ndToDateString(this.date_index_end);
    },

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },
  },
  methods: {

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = "fetch";
      try {

        // получаем выбранное предприятие
        let depot = await this.$store.dispatch('ref/doFetchDepot', { depot_id: this.depot_id})
        if (depot)
          this.depot = depot;

        // получаем строки таблицы
        let payTransByDepot = await this.$store.dispatch('asmpp/doFetchPayTransByDepot', {
          depot_id_list: [Number(this.depot_id)],
          date_index_begin: Number(this.date_index_begin),
          date_index_end: Number(this.date_index_end),
        });
        if(payTransByDepot)
          this.payTransByDepot = payTransByDepot;

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
