import RegisterBootstrap from './bootstrap/RegisterBootstrap';
import RegisterCustom from './custom/RegisterCustom';
import RegisterMenu from "./menu/RegisterMenu";
import RegisterLeaflet from "@/components/ui/leaflet/RegisterLeaflet";

export default function (app) {
    // регистрируем Bootstrap-компоненты
    RegisterBootstrap(app);

    // регистрируем Leaflet-компоненты
    RegisterLeaflet(app);

    // регистрируем Пользовтаельские компоненты
    RegisterCustom(app);

    // регистрируем меню
    RegisterMenu(app);
}
