<template>
  <align-container>
    <align-panel align="all" style="overflow: auto; margin-bottom: 3px">
      <edit-table
        v-bind="$attrs"
        :columnList="columns"
        :rowList="rows"
        :disableSort="disableSort"
        @onColumnClick="onColumnClick"
        @onColumnContextClick="onColumnContextClick"
      ></edit-table>
    </align-panel>
    <align-panel v-if="pageCount > 1" align="bottom">
      <table-pager
          :pageCount="pageCount"
          :curPage="curPage"
          @onPageChanged="onPageChanged"
      ></table-pager>
    </align-panel>
  </align-container>

  <!-- Настройка таблицы -->
  <edit-table-dialog
      v-if="showSettings"
      :columns="columns"
      :disableSort="disableSort"
      @onColumnUp="onColumnUp"
      @onColumnDown="onColumnDown"
      @onCancel="onCancelSettings"
      @onSubmit="onSaveSettings"
      @onRestore="onRestoreSettings"
  ></edit-table-dialog>
</template>

<script>
import EditTableDialog from '../custom/EditTableDialog';
import {boolSortFn, graphSortFn, numSortFn, quickSort, routeNumSortFn, signSortFn, strSortFn} from "@/lib";

export default {
  inheritAttrs: false,

  components: {
    EditTableDialog
  },

  props: {

    // Название таблицы (нужно для сохранения настроек)
    name: {
      type: String,
      default: 'default-table'
    },

    // Список колонок
    columnList: {
      type: Array,
      default: () => []
    },

    // Список строк
    rowList: {
      type: Array,
      default: () => []
    },

    // Количество строк на странице
    rowsPerPage: {
      type: Number,
      default: 50
    },

    // Строка с фильтром
    filterText: {
      type: String,
      default: ''
    },

    // Отключить возможность выбирать колонки
    disableSettings: {
      type: Boolean,
      default: false
    },

    // Полность отключить возможность сортировки
    disableSort: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // текущая страница
      curPage: 1,
      // колонки для отображения
      columns: [],
      // отображать настройки
      showSettings: false,
    }
  },

  computed: {

    // результирующий набор строк (с учетом сортировки и фильтрации)
    resultRows() {

      // без фильрации
      let rows;
      if (this.filterText === '') {
        rows = [].concat(this.rowList)
      }
      // с фильтрацией
      else {
        let str;
        rows = this.rowList.filter(( row ) => {

          return this.columns.some(( col ) => {

            let item = col.subField ? row[col.subField][col.field] : row[col.field];
            if (col.isFilterable && col.show) {
              if (item == null)
                str = '';
              else
                str = String(item).toLowerCase();

              return str.includes(this.filterText.toLowerCase());
            }
            else {
              return false;
            }
          });
        });
      }
      // сортируем результат
      const sindex = this.sortedIndex
      if (sindex >= 0) {
        // определяем направление сортировки
        const sdir = this.columns[sindex].sorted === 'asc' ? 1 : -1
        // определяем функцию для сортировки
        let fn = null;
        if (this.columns[sindex].sortFn) {
          // пользовательская функция сортировки
          fn = this.columns[sindex].sortFn;
        }
        else {
          // стандартные функции
          if (this.columns[sindex].sortType === 'number') {
            fn = numSortFn;
          }
          else
          if (this.columns[sindex].sortType === 'string') {
            fn = strSortFn;
          }
          else
          if (this.columns[sindex].sortType === 'boolean') {
            fn = boolSortFn;
          }
          else
          if (this.columns[sindex].sortType === 'sign') {
            fn = signSortFn;
          }
          else
          if (this.columns[sindex].sortType === 'routeNum') {
            fn = routeNumSortFn;
          }
          else
          if (this.columns[sindex].sortType === 'graph') {
            fn = graphSortFn;
          }
        }
        // сортируем данные
        if (fn) {
          quickSort(rows, (l, r) => {
            const leftItem = this.columns[sindex].subField ? l[this.columns[sindex].subField][this.columns[sindex].field] : l[this.columns[sindex].field]
            const rightItem = this.columns[sindex].subField ? r[this.columns[sindex].subField][this.columns[sindex].field] : r[this.columns[sindex].field]
            return sdir * fn(leftItem, rightItem);
          });
        }
      }

      // возвращаем результат
      return rows;
    },

    // строки для отображения
    rows() {
      // определяем начальный и конечный индексы
      let startIndex = (this.curPage - 1) * this.rowsPerPage;
      let endIndex = startIndex + this.rowsPerPage - 1;
      if (endIndex >= this.resultRows.length) endIndex = this.resultRows.length - 1;

      // формируем массив
      const rows = [];
      for (let i=startIndex; i <= endIndex; i++) {
        rows.push(this.resultRows[i])
      }

      // возвращаем результат
      return rows;
    },

    // количество страниц
    pageCount() {
      return this.resultRows.length % this.rowsPerPage === 0
          ? Math.floor(this.resultRows.length / this.rowsPerPage)
          : Math.ceil(this.resultRows.length / this.rowsPerPage);
    },

    // индекс сортированной колонки
    sortedIndex() {
      return this.columns.findIndex(col => col.sorted === 'asc' || col.sorted === 'dsc');
    },

  },

  watch: {
    // следим за изменением количества страниц
    pageCount() {
      // сбрасываем номер страницы
      this.curPage = 1;
    }
  },

  methods: {

    // вызывается при переходе на новую страницу
    onPageChanged(newPage) {
      this.curPage = newPage;
    },

    // вызывается при щелчке правой кнопки мыши на заголовке
    onColumnContextClick() {
      this.showSettings = !this.disableSettings;
    },

    // вызывается при отмене настроек
    onCancelSettings() {
      this.showSettings = false;
    },

    // вызывается при сохранении настроек
    onSaveSettings() {
      // localStorage.setItem(this.name, JSON.stringify(this.columns))
      // передаем в store название таблицы и настройки
      this.$store.dispatch('auth/doEditEditTableClientSettings', {
          client_set_name : 'trn',
          value:  this.columns,
          name: this.name
      });
      this.showSettings = false;
    },

    // восстанавливаем настройки таблицы
    onRestoreSettings() {
      // localStorage.removeItem(this.name);
      // передаем в store название таблицы настройки которой нужно сбросить
      this.$store.dispatch('auth/doDeleteEditTableClientSettings', {
        client_set_name : 'trn',
        name: this.name
      });
      this.updateColumns();
      this.showSettings = false;
    },

    // вызывается при смещении колонки влево
    onColumnUp(index) {
      const c = this.columns[index];
      this.columns[index] = this.columns[index - 1];
      this.columns[index - 1] = c;
    },

    // вызывается при смещении колонки вправо
    onColumnDown(index) {
      const c = this.columns[index];
      this.columns[index] = this.columns[index + 1];
      this.columns[index + 1] = c;
    },

    // обновление списка колонок
    updateColumns() {

      // достаем данные из хранилища
      let storageColumns = null;
      // if (localStorage.getItem(this.name)) {
      //   storageColumns = JSON.parse(localStorage.getItem(this.name));
      // }

      if (this.$store.getters['auth/getEditTableClientSettings'] && this.$store.getters['auth/getEditTableClientSettings'][this.name]) {
        storageColumns = this.$store.getters['auth/getEditTableClientSettings'][this.name];
      }

      // формируем новый список колонок
      const newColumns = this.columnList.map(( col ) => {

        // создаем копию колонки
        const newColumn = {...col};

        // если в хранилище что-то было
        if (storageColumns) {
          // ищем колонку по имени поля
          const storageCol = storageColumns.find(col => newColumn.field === col.field);
          // если нашли
          if (storageCol) {
            if (typeof(storageCol.show) === 'boolean')
              newColumn.show = storageCol.show;
            if (storageCol.dataAlign === 'left' || storageCol.dataAlign === 'right' || storageCol.dataAlign === 'center')
              newColumn.dataAlign = storageCol.dataAlign;
            if (storageCol.headerAlign === 'left' || storageCol.headerAlign === 'right' || storageCol.headerAlign === 'center')
              newColumn.headerAlign = storageCol.headerAlign;
            if (typeof(storageCol.isSortable) === 'boolean')
              newColumn.isSortable = storageCol.isSortable;
            if (typeof(storageCol.isFilterable) === 'boolean')
              newColumn.isFilterable = storageCol.isFilterable;
          }
        }

        // возвращаем новую колонку
        return newColumn;
      });

      // выстраиваем колонки в нужной последовательности
      if (storageColumns) {
        for (let i = storageColumns.length - 1; i >= 0 ; i--) {
          const fname = storageColumns[i].field;
          const index = newColumns.findIndex(col => col.field === fname)
          if (index >= 0) {
            const col = newColumns[index];
            newColumns.splice(index, 1);
            newColumns.unshift(col);
          }
        }
      }

      // присваиваем новый список базовому значению
      this.columns = newColumns;
    },

    // выбрана колонка для сортировки
    onColumnClick(column) {
      // если колонка не сортируется - выходим
      if (!column.isSortable) return;

      // меняем значения сортировки
      this.columns.forEach((col) => {
        if (column === col) {
          if (!col.sorted || col.sorted === 'dsc') {
            col.sorted = 'asc'
          }
          else {
            col.sorted = 'dsc'
          }
        }
        else {
          col.sorted = false;
        }
      })
    },

    // очистить сортировку
    clearSorted() {
      this.columns.forEach((col) => {
        if (col.sorted) {
          col.sorted = false;
        }
      });
    },
  },

  // вызывается при создании компонента
  created() {
    // следим за изменением списка колонок
    this.$watch(() => this.columnList, () => {
      this.updateColumns();
    }, {immediate: true})
  }
}
</script>
