<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>График движения</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <form>
            <div class="row">
              <div class="form-group col">
                <label for="scheduleGraphId">Код:</label>
                <input-integer
                    id="scheduleGraphId"
                    class="form-control"
                    v-model="scheduleGraphId"
                    readonly
                />
              </div>

              <div class="form-group col">
                <label for="graph">График:</label>
                <req-field/>
                <input-integer
                    id="graph"
                    v-model="graph"
                    class="form-control"
                    ref="graph"
                    autocomplete="off"
                    required
                    error="Введите график"
                    :readonly="op === 'show'"
                    :min-value="0"
                    :exclude-negative="true"
                    :min-value-exclude="true"
                />
              </div>

              <div class="form-group col">
                <label for="shift">Смена:</label>
                <req-field/>
                <input-string
                    id="shift"
                    v-model="shift"
                    class="form-control"
                    ref="shift"
                    maxlength="1"
                    autocomplete="off"
                    :readonly="op === 'show'"
                    required
                    error="Введите смену"
                />
              </div>
            </div>

            <div class="row">

              <div class="form-group col">
                <label for="timeBegin">Время начала работы:</label>
                <req-field/>
                <input-time
                    id="timeBegin"
                    v-model="timeBegin"
                    class="form-control"
                    ref="timeBegin"
                    autocomplete="off"
                    :readonly="op === 'show'"
                    required
                    error="Введите время"
                />
              </div>

              <div class="form-group col">
                <label for="timeEnd">Время окончания работы:</label>
                <input-time
                    id="timeEnd"
                    v-model="timeEnd"
                    class="form-control"
                    ref="timeEnd"
                    autocomplete="off"
                    readonly
                />
              </div>
            </div>

            <div class="form-group">
              <label for="depotId">Предприятие:</label>
              <req-field/>
              <depots-select
                  id="depotId"
                  v-model="depotId"
                  class="form-control"
                  ref="depotId"
                  :readonly="op === 'show'"
                  required
                  :max_length="40"
                  error="Выберите предприятие"
              />
            </div>

            <div class="form-group">
              <label for="graphControlMode">Режим контроля на графике:</label><req-field/>
              <input-select
                  caption="Выберите режим контроля..."
                  id="graphControlMode"
                  :itemList="[
                    {
                      id:1,
                      title:'По расписанию'
                    },
                    {
                      id:2,
                      title:'По интервалам'
                    },
                    {
                      id:3,
                      title:'Смешанный режим'
                    }
                  ]"
                  class="form-control"
                  ref="graphControlMode"
                  :readonly="op === 'show'"
                  v-model="graphControlMode"
                  required
                  error="Выберите режим"
              />
            </div>

            <div class="form-group">
              <label for="transportClassId">Класс ТС:</label>
              <transport-classes-select
                  id="transportClassId"
                  v-model="transportClassId"
                  class="form-control"
                  ref="transportClassId"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="transportationKindId">Вид перевозок:</label>
              <transportation-kinds-select
                  id="transportationKindId"
                  v-model="transportationKindId"
                  class="form-control"
                  ref="transportationKindId"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="regimeId">Режим работы водителей:</label>
              <regimes-select
                  id="regimeId"
                  v-model="regimeId"
                  class="form-control"
                  ref="regimeId"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ReqField from "@/components/ui/custom/ReqField";
import InputInteger from "@/components/ui/custom/InputInteger";
import InputTime from "@/components/ui/custom/InputTime";
import DepotsSelect from "@/components/ref/depots/DepotsSelect";
import TransportClassesSelect from "@/components/ref/transport_classes/TransportClassesSelect";
import TransportationKindsSelect from "@/components/rmt/transportation_kinds/TransportationKindsSelect";
import RegimesSelect from "@/components/rmt/regimes/RegimesSelect";
import {getTimeFormat} from "@/lib";


export default {
  components: {
    InputTime,
    RegimesSelect,
    TransportationKindsSelect,
    TransportClassesSelect,
    DepotsSelect,
    InputInteger,
    ReqField
  },
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      scheduleGraphId: this.item.schedule_graph_id,
      depotId: this.item.depot_id,
      transportClassId: this.item.transport_class_id,
      transportationKindId: this.item.transportation_kind_id,
      regimeId: this.item.regime_id,
      graphControlMode: this.item.graph_control_mode,
      graph: this.item.graph,
      shift: this.item.shift,
      timeBegin: this.item.time_begin,
      timeEnd: this.item.time_end,

    };
  },
  methods: {

    getTime(){
      getTimeFormat(this.timeBegin);
    },

    onSubmit() {
      if (this.validateForm(this.$refs)) {

        this.$emit('onOK', {
          schedule_graph_id: this.scheduleGraphId,
          depot_id: this.depotId,
          transport_class_id: this.transportClassId,
          transportation_kind_id: this.transportationKindId,
          regime_id: this.regimeId,
          graph_control_mode: this.graphControlMode,
          graph: this.graph,
          shift: this.shift,
          time_begin: this.timeBegin,
          time_end: this.timeEnd

        })
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>