<template>
  <input-select ref="select" v-model="value" caption="Выберите тип документа..." :itemList="items"></input-select>
</template>

<script>
export default {
  props: ['model-value',
    'table_name'
  ],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      let result = [];
      this.$store.getters['doc/getDocumentTypes'].map(item => {
        if (this.table_name === item.table_name) {
          result.push({
            id: item.doc_type_id,
            title: item.doc_type_name
          });
        }
      });
      return result;
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
