<template>
  <!-- Карта-->
  <leaflet-map
      :zoom="zoom"
      :center="center"
      :contextMenu="mapContextMenu"
      :options="mapOptions"
      @onMapCreated="onMapCreated"
  >

    <!-- Элементы управления-->
    <leaflet-control-group>
      <!-- Базовые слои-->
      <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
    </leaflet-control-group>

    <!-- автостанции-->
    <stopstation-marker
        v-for="stopstation in stopstations"
        :key="stopstation.stopstation_id"
        :stopstation="stopstation"
        :tooltip="stopstation.long_name + ' (#'+stopstation.stopstation_id+')'"
        :state="stopstation === selectedStopstation && isMoveMode ? 'edit': stopstation === selectedStopstation ? 'active': 'original'"
        :contextMenu="stopstation === selectedStopstation && !isMoveMode ? stopstationContextMenu: noContextMenu"
        events="click dblclick"
        @onLeafletEvent="onLeafletEvent($event, stopstation)"
        @onStopstationChanged="$emit('onStopstationChanged', $event)"
    ></stopstation-marker>

  </leaflet-map>
</template>

<script>

export default {

  props: [
    // выбранная автостанция
    'selectedStopstation',
    // режим перемещения
    'isMoveMode'
  ],
  // список событий
  emits: [
    // Команда "добавить автосанцию"
    'onAdd',
    // Команда "изменить автостанцию"
    'onEdit',
    // Команда "удалить автостанцию"
    'onDelete',
    // Команда "переместить автостанцию"
    'onMove',
    // двойной щелчек на автостанции
    'onShow',
    // вызывается при щелчке на автостанции
    'onStopstationSelect',
    // вызывается при перемещении автостанции
    'onStopstationChanged'
  ],
  computed: {

    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom']
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter']
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },

    // минимальный масштаб для отображения остановок
    minStopZoom() {
      return this.$store.getters['settings/getMinStopZoom']
    },

    // список автостанций
    stopstations() {
      return this.$store.getters['multipass/getStopstations'];
    },

    // контекстное меню карты
    mapContextMenu() {
      if (this.isMoveMode) {
        return {
          contextmenu: true,
          contextmenuWidth: 140,
          contextmenuItems: []
        }
      }
      else {
        return {
          contextmenu: true,
          contextmenuWidth: 140,
          contextmenuItems: [
            {
              text: 'Добавить',
              callback: (event) => {
                const latlng = event.latlng;
                this.$emit('onAdd', {
                  latitude: latlng.lat,
                  longitude: latlng.lng
                })
              }
            }
          ]
        }
      }
    },
  },

  data() {
    return {
      // карта
      leaflet: null,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // контекстное меню автостанции
      stopstationContextMenu: {
        contextmenu: true,
        contextmenuInheritItems: false,
        contextmenuWidth: 140,
        contextmenuItems: [
          {
            text: 'Изменить',
            callback: () => {
              this.$emit('onEdit', this.selectedStopstation)
            }
          },
          {
            text: 'Удалить',
            callback: () => {
              this.$emit('onDelete', this.selectedStopstation)
            }
          },
          {
            separator: true
          },
          {
            text: 'Переместить',
            callback: () => {
              this.$emit('onMove', this.selectedStopstation)
            }
          },
        ]
      },
      // нет контекстного меню автостанции
      noContextMenu: {
        contextmenu: false,
      },
    }
  },

  methods: {

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // срабатывает при щелчке на автостанцию
    onLeafletEvent(event, stopstation) {
      // при щелчке выделяем выделяем автостанцию
      if (event.type === 'click') {
        this.$emit('onStopstationSelect', stopstation);
      }
      // вызываем событие при двойном щелчке на автостанцию
      else if (event.type === 'dblclick') {
        this.$emit('onShow', stopstation)
      }
    }
  },
}
</script>
