<template>
  <confirm-message-box
      @onCancel="$emit('onCancel')"
      @onOK="delItem"
  ><p>Удалить транспортное средство c кодом {{ id }} ?</p>
<!--    <form class="form-inline">-->
<!--      <label for="delDate" class="mr-2">Дата удаления:</label>-->
<!--      <input-date-->
<!--          class="form-control"-->
<!--          id="delDate"-->
<!--          v-model="delDate"-->
<!--          ref="delDate"-->
<!--          required-->
<!--          error="Введите дату удаления"-->
<!--      />-->
<!--    </form>-->
  </confirm-message-box>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  emits: ['onCancel', 'onOK'],
  props: ['id'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      // delDate: dateToString()
    }
  },
  methods: {
    delItem() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          transport_id: this.id,
          // date_index_begin: dateToND(new Date(this.delDate))
        })
      }
    }
  },
}
</script>
