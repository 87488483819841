<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/nav">Телематика</router-link></span>
        <span> / </span>
        <span>Модели радиостанций</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">
            <crud-form
                :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
                :buttonsEnabled="crudButtonsEnabled"
                @onAdd="onTableAdd"
                @onEdit="onTableEdit"
                @onDelete="onTableDelete"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            >
            </crud-form>
          </align-panel>
          <!-- Таблица  -->
          <align-panel align="all">
            <radio-models-edit-table
                :filterText="filterText"
                :isLoading="isLoading"
                :routeGroupId="routeGroupId"
                :selectedRow="selectedRow"
                @onRowSelect="onRowSelect"
                @onRowDblClick="onTableShow"
            ></radio-models-edit-table>
          </align-panel>
        </align-container>
    </align-panel>
  </align-container>
  <!-- Просмотр, изменение и добавление -->
  <radio-model-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </radio-model-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить модель радиостанции с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import RadioModelsEditTable from "@/components/nav/radio_models/RadioModelsEditTable";
import RadioModelDialog from "@/components/nav/radio_models/RadioModelDialog";

export default {
  components: {RadioModelDialog, RadioModelsEditTable},
  mixins: [PageMixin],

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      //группа
      routeGroupId: null,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {}
      } else return this.selectedRow;
    }
  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/nav/radioModels/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/nav/radioModels/edit/" + this.selectedRow.radio_model_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/nav/radioModels/del/" + this.selectedRow.radio_model_id);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // выполнено двойное нажатие строки в таблице (открываем просмотр)
    onTableShow(item) {
      this.$router.replace("/nav/radioModels/show/" + item.radio_model_id);
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/nav/radioModels");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('nav/doFetchRadioModels', {force})
        await this.$store.dispatch('nav/doFetchRadioProtocols', {force})

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/nav/radioModels");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/nav/radioModels");
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('nav/doAddRadioModel', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('nav/doEditRadioModel', item)
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('nav/doDelRadioModel', this.selectedRow);

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

