<template>
  <edit-table-panel :columnList="columnList" :rowList="rows" :disableSettings="true" name="tbl-multipass-tariff-type-zones"> </edit-table-panel>
</template>

<script>

export default {
  props: ['isLoading', 'rowList'],
  computed: {
    columnList() {
      return [
        {
          field: "tariff_zone_id",
          caption: "Код",
          sortType: 'number',
          isSortable: false,
        },
        {
          field: "long_name",
          caption: "Наименование",
          sortType: 'string',
          isSortable: false,
        },
        {
          field: "is_visible",
          caption: "Вид",
          isSortable: false,
          sortType: 'boolean',
          displayType: 'checkbox',
          headerAlign: 'center',
          dataAlign: 'center',
        },
      ]
    },
    rows() {
      if (this.isLoading === 'fetch') {
        return []
      }
      return this.rowList.map((zone, index) => {
        zone.__id = zone.tariff_zone_id;
        // задаем цвета в зависимости от индекса зоны в массиве
        // (цвета предопределены заранее в SettingsStore.js
        // и соответствуют цвету зоны на карте)
        zone.__rowStyle = {
          backgroundColor: this.$store.getters['settings/getZonesColors'][index] + '80',
        }
        return zone
      });
    }
  },
};
</script>
