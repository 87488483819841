import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";
import {clearArrContains} from "@/lib";

// ХРАНИЛИЩЕ РАДИОСТАНЦИЙ

export default {
    state() {
        return {
            // список объектов
            radios: [],
            // время обновления кэша new Date().getTime()
            radiosCache: null,
            // время жизни кэша в мс (null - бесконечно)
            radiosCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRadios(state) {
            return state.radios;
        },
    },
    mutations: {
        // установить новый список объектов
        setRadios(state, payload) {
            state.radios = payload;
            state.radiosCache = new Date().getTime()
        },
        // добавить объект
        addRadio(state, payload) {
            state.radios.push(payload)
        },
        // изменить объект
        editRadio(state, payload) {
            const index = state.radios.findIndex(radioModel => radioModel.radio_id == payload.radio_id);
            if (index >= 0) {
                state.radios[index] = payload;
            }
        },
        // удалить объект
        delRadio(state, payload) {
            const index = state.radios.findIndex(radioModel => radioModel.radio_id == payload.radio_id);
            state.radios.splice(index, 1);
        }
    },
    actions: {

        // запрос списка объектов
        doFetchRadios: (context, payload) => {
            return DoGet(
                '/nav/radios',
                'nav/setRadios',
                [],
                'Запрос радиостанций',
                false,
                payload && payload.force,
                context.state.radiosCache,
                context.state.radiosCacheTimeout
            )
        },

        // запрос списка объектов
        doFetchRadio: (context, payload) => {
            return DoGet(
                '/nav/radios/' + payload.radio_id,
                null,
                [],
                'Запрос радиостанции',
                false,
                payload && payload.force,
                context.state.radiosCache,
                context.state.radiosCacheTimeout
            )
        },

        // очищает кэш
        doClearRadios: (context, payload) => {
            return DoClearCache(
                'nav/setRadios',
                [],
                payload && payload.force,
                context.state.radiosCache,
                context.state.radiosCacheTimeout
            )
        },

        // добавление объекта
        doAddRadio: (context, payload) => {
            return DoPost(
                '/nav/radios',
                payload,
                'nav/addRadio',
                'Добавление радиостанции'
            )
        },

        // изменение объекта
        doEditRadio: (context, payload) => {
            payload.item.analog_cell_definitions = clearArrContains(payload.item.analog_cell_definitions);
            payload.item.discrete_cell_definitions = clearArrContains(payload.item.discrete_cell_definitions);
            console.log(payload.item)
            return DoPut(
                '/nav/radios/' + payload.item.radio_id + '/' + payload.time_index_begin,
                payload.item,
                'nav/editRadio',
                'Изменение радиостанции'
            )
        },

        // удаление объекта
        doDelRadio: (context, payload) => {
            return DoDelete(
                '/nav/radios/'+payload.radio_id,
                'nav/delRadio',
                'Удаление радиостанции'
            )
        },

        // списание объекта
        doDiscardRadio: (context, payload) => {
            console.log('payload', payload)
            return DoPut(
                '/nav/discardRadio/' + payload.radio_id + '/' + payload.time_index_begin,
                null,
                'nav/editRadio',
                'Списание радиостанции'
            )
        },

        // восстановление объекта
        doRestoreRadio: (context, payload) => {
            return DoPut(
                '/nav/restoreRadio/' + payload.radio_id,
                null,
                'nav/editRadio',
                'Восстановление радиостанции'
            )
        }

    }
}
