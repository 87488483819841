import { DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ЗОН АВТОСТАНЦИЙ

export default {
    actions: {

        // запрос объекта
        doFetchStopstationZone: (context, payload) => {
            // выполняем запрос
            return DoGet(
                '/multipass/stopstationZones?stopstation_id='+payload.stopstation_id,
                null,
                {},
                'Запрос зоны автостанции',
                false,
                true,
                null,
                null
            )
        },

        // изменение объекта
        doEditStopstationZone: (context, payload) => {
            return DoPut(
                '/multipass/stopstationZones/'+payload.stopstation_id,
                payload.zone,
                null,
                'Установка зоны автостанции'
            )
        },
    }
}