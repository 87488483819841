import { DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОКУМЕНТОВ КОНТРАКТОВ

export default {

    actions: {
        // запрос объекта
        doFetchContractDoc: (context, payload) => {
            return DoGet(
                '/kvr/contractDocs/' + payload.contract_doc_id,
                null,
                {},
                'Запрос документа контракта',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchContractDocs: (context, payload) => {
            return DoGet(
                '/kvr/contractDocs?contract_id=' + payload.contract_id,
                null,
                [],
                'Запрос документов контракта',
                false,
                true,
            )
        },

        // добавление объекта
        doAddContractDoc: (context, payload) => {
            return DoPost(
                '/kvr/contractDocs',
                payload,
                null,
                'Добавление документа контракта'
            )
        },

        // изменение объекта
        doEditContractDoc: (context, payload) => {
            return DoPut(
                '/kvr/contractDocs/'+ payload.contract_doc_id,
                payload,
                null,
                'Изменение документа контракта'
            )
        },

        // удаление объекта
        doDelContractDoc: (context, payload) => {
            return DoDelete(
                '/kvr/contractDocs/'+ payload.contract_doc_id,
                null,
                'Удаление документа контракта'
            )
        }
    }
}
