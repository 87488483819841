import {DoGet, DoPut,} from "@/store/Actions";

// ХРАНИЛИЩЕ МАРШРУТОВ ДЛЯ РАСЧЕТА МОДУЛЯ КВР

export default {
    state() {
        return {
            // список маршрутов
            routes: [],
            // время обновления кэша new Date().getTime()
            routesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            routesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список маршрутов
        getRoutesExt(state) {
            return state.routes
        },
    },
    mutations: {
        // установить новый список маршрутов
        setRoutesExt(state, payload) {
            state.routes = payload;
            state.routesCache = new Date().getTime()
        },
        // редактирование маршрута
        editRouteExt(state, payload) {
            const index = state.routes.findIndex(route => route.route_id == payload.route_id);
            if (index >= 0) {
                state.routes[index] = payload;
            }
        },

    },
    actions: {
        // запрос списка
        doFetchRoutesExt: (context, payload) => {
            return DoGet(
                '/kvr/routeExts',
                'kvr/setRoutesExt',
                [],
                'Запрос списка маршрутов для модуля КВР',
                false,
                payload && payload.force,
                context.state.routesCache,
                context.state.routesCacheTimeout
            )
        },

        // связывание объектов
        doIncludeRouteExt: (context, payload) => {
            return DoPut(
                '/kvr/includeRoute/' + payload.route_id,
                null,
                'kvr/editRouteExt',
                'Включение маршрута в расчет модуля КВР'
            )
        },

        // удаление связи объектов
        doExcludeRouteExt: (context, payload) => {
            return DoPut(
                '/kvr/excludeRoute/' + payload.route_id,
                null,
                'kvr/editRouteExt',
                'Исключение маршрута из расчета модуля КВР'
            )
        },
    }
}