<template>
  <card>
    <card-header class="bg-info text-white">
      Изменение пароля
    </card-header>
    <card-body>
      <form>
        <div class="form-group">
          <label for="oldPassword">Старый пароль:</label><req-field />
          <input-string
              class="form-control"
              id="oldPassword"
              v-model="oldPassword"
              ref="oldPassword"
              type="password"
              autocomplete="off"
              required
              error="Введите старый пароль"
          />
        </div>
        <div class="form-group">
          <label for="password">Новый пароль:</label><req-field />
          <input-string
              class="form-control"
              id="password"
              v-model="password"
              ref="password"
              type="password"
              autocomplete="off"
              required
              error="Введите новый пароль"
          />
        </div>
        <div class="form-group">
          <label for="repeatPassword">Повтор нового пароля:</label><req-field />
          <input-string
              class="form-control"
              id="repeatPassword"
              v-model="repeatPassword"
              ref="repeatPassword"
              type="password"
              autocomplete="off"
              required
              error="Введите новый пароль ещё раз"
          />
        </div>
        <button type="button" class="btn btn-primary" @click="onChangePassword">Изменить пароль</button>
      </form>
    </card-body>
  </card>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  mixins: [ValidateFormMixin],
  emits: ['onChangePassword'],
  data() {
    return {
      oldPassword: "",
      password: "",
      repeatPassword: "",
    };
  },
  methods: {
    onChangePassword() {
      // проверяем форму
      if (this.validateForm(this.$refs)) {
        // проверяем совпадение пароля
        if (this.password !== this.repeatPassword) {
          this.$refs.repeatPassword.alarm('Пароли не совпадают')
          return
        }
        // формируем событие
        this.$emit('onChangePassword', {
          old_password: this.oldPassword,
          new_password: this.password
        });
        // сбрасываем значения
        this.oldPassword = "";
        this.password = "";
        this.repeatPassword = "";
      }
    },
  },
}
</script>
