<template>
  <svg :height="height" :width="width" style="background-color: #f5f5f5">
    <line
        :x1="0"
        :y1="height"
        :x2="width"
        :y2="height"
        style="stroke-linecap: square; stroke:rgb(0,0,0);stroke-width:1; stroke-dasharray:5,5; d:M5 40 l215 0;"
    />
    <g v-for="(val, ind) in [0,1,2,3,4,5,6,7,8,9,10,11]" :key="ind">
      <line
          v-for="(value, index) in (hoursArray)"
          :key="index"
          :x1="getXLayoutCoord(index, val*5)"
          :y1="0"
          :x2="getXLayoutCoord(index, val*5)"
          :y2="height"
          :style="'stroke:rgb(253,223,105); stroke-linecap: square;' + ( val == 0 ? 'stroke-width:3' : 'stroke-width:1' )"/>
    </g>

    <!--    В цикле отрисовываем все рейсы-->
    <g v-for="(line, index) in graph"
       :key="index">

      <!--      Линия рейса-->
      <line
          v-if="line.trip_complex.schedule_event_id != 0"
          :x1="getLineXStart(line)"
          :y1="height"
          :x2="getLineXEnd(line.trip_complex.step_time_end)"
          :y2="height"
          :style="'stroke-linecap:butt;' + getReisLineStyle(line) "
      />

      <!--      Название рейса   -->
      <text
          :x="getNameTextX(index)"
          :y="height-12"
          :font-size="font_size"
          style="font-weight: bold"
          :fill="'rgb(0,0,0)'"
      >
        {{ getTripTypeShort(line.trip_complex.trip_type_sign) }}
      </text>

      <!--      Линия стоянки -->
      <line
          :x1="getStopXStart(line, index)"
          :y1="height"
          :x2="getStopXEnd(line, index)"
          :y2="height"
          :style="' stroke-linecap:butt; stroke-width:' + tech_op_width + ';' + getStopColor(line, index) "
      />

      <!--      Линии-засечки КП -->
      <line
          v-for="(cp_line, index) in line.trip_complex.cp_time_list"
          :key="index"
          :x1="getXStart(cp_line)"
          :y1="height"
          :x2="getXStart(cp_line)"
          :y2="height-(tech_op_width/2)"
          :style="'stroke-linecap:butt; stroke-width:1;' + (line.trip_complex.direction == 0 ? 'stroke:rgb(35,152,0)' : 'stroke:rgb(229,41,255);')  "
      />

      <!--      Название нулевого рейса   -->
      <text
          v-if="line.trip_complex.null_step_time_begin && line.trip_complex.null_step_time_end"
          :x="getNullTextX(index)"
          :y="height-12"
          :font-size="font_size"
          style="font-weight: bold"
          :fill="'rgb(0,81,255)'"
      >
        {{ getNullTripTypeShort(graph[index]) }}
      </text>

      <!--      Линия нулевого рейса -->
      <line
          v-if="line.trip_complex.null_step_time_begin && line.trip_complex.null_step_time_end"
          :x1="getXStart(line.trip_complex.null_step_time_begin)"
          :y1="height"
          :x2="getLineXEnd(line.trip_complex.null_step_time_end)"
          :y2="height"
          :style="'stroke-linecap:butt; stroke:rgb(0,81,255);stroke-width:' + null_width"
      />

      <!--      Длительности остановок -->
      <text
          :x="getStopTextX(index)"
          :y="height-10"
          :font-size="font_size"
      >
        {{ line.trip_complex.stop_time }}
      </text>

      <!--      Название тех. операции и её длительность -->
      <text
          v-if="line.trip_complex.schedule_event_id != 1"
          :x="getNameTextX(index)"
          :y="height-12"
          :font-size="font_size"
          :fill="getScheduleEventColor(line)"
          style="font-weight: bold"
      >
        {{ getScheduleEventText(line) }}
      </text>

    </g>

    <!--    номер строки с расписанием  window.scrollY`  -->
    <text
        :id="'scheduleNumber'+ graph_num"
        :x="0"
        :y="height-6"
        style="font-weight: bold"
        :font-size="font_size+7"
    >
      {{ graph_num }}
    </text>

    <!--      Линия-засечка перед первым нулевым рейсом -->
    <line
        :x1="getXStart(graph[0].trip_complex.null_step_time_begin)"
        :y1="height"
        :x2="getXStart(graph[0].trip_complex.null_step_time_begin)"
        :y2="height-8"
        style="stroke-linecap:butt; stroke-width:0.5; stroke:rgb(0,81,255)"
    />

    <!--      Линия-засечка после последнего нулевого рейса -->
    <line
        :x1="getXStart(graph[graph.length-1].trip_complex.null_step_time_end)"
        :y1="height"
        :x2="getXStart(graph[graph.length-1].trip_complex.null_step_time_end)"
        :y2="height-8"
        style="stroke-linecap:butt; stroke-width:0.5; stroke:rgb(0,81,255)"
    />

    <!--      Линии интервалов -->
    <line
        v-for="(line,index) in intervalLines"
        :key="index"
        :x1="getXStart(line)"
        :y1="(height)"
        :x2="getXStart(line)"
        :y2="0"
        style="stroke-dasharray: 5 2 ;stroke-width:1; stroke-linecap:butt; stroke:rgb(255,0,0)"
    />

<!--    <foreignObject x="200" y="1" width="160" height="40">-->
<!--      <line-graph-dialog-->
<!--          v-if="true"-->
<!--          :columnItem="graph[0]"-->
<!--          :realeaseDialogStyle="''"-->

<!--      >-->
<!--      </line-graph-dialog>-->
<!--    </foreignObject>-->

  </svg>
</template>

<script>
import {getTimeFormat} from "@/lib";

export default {
  name: "LineScheduleGraphSVG",
  props: ['graph', 'width', 'height', 'start_x', 'oneDiv', 'hoursArray', 'graph_num', 'intervalLines'],
  data() {
    return {

      // объект в котором хранятся координаты начала всех длительностей линий верхней линии
      topTextLine: [],
      // объект в котором хранятся координаты начала всех длительностей линий верхней линии
      bottomTextLine: [],
      startTimeInPx: null,
      // первое время на линейке часов в минутах от начала суток
      layoutStartTime: null,
      //сущность в которой хранятся все координаты предполагаемого конца надписей на графике
      textCoords: {},
      // ширина нулевого рейса
      null_width: 4,
      // ширина рейса
      reis_width: 8,
      // ширина линии техоперации
      tech_op_width: 16,
      // размер шрифта основных надписей
      font_size: 12,
    }
  },
  methods: {

    // получаем рассчитанные заранее координаты текста длительности остановки
    getStopTextX(index) {
      return this.textCoords[index].stop_length_coord;
    },

    // получаем рассчитанные заранее координаты текста названия нулевого рейса
    getNullTextX(index) {
      return this.textCoords[index].null_name_coord;
    },

    // получаем рассчитанные заранее координаты текста названия рейса или названия техоперации
    getNameTextX(index) {
      return this.textCoords[index].name_coord;
    },

    // получаем координату х для текста к нулевому рейсу
    getNullTextXStart(line, index) {
      if (line.trip_complex.schedule_graph_step_id != 0) {
        return this.getXStart(line.trip_complex.null_step_time_begin) + (index == 0 ? -10 : 0);
      } else {
        return this.getXStart(line.trip_complex.null_step_time_end) + 3;
      }
    },

    // получаем тип рейса без букв направления
    getTripTypeShort(trip_type) {
      return (trip_type.includes("AB", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type.includes("BA", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type)
    },

    // получаем тип рейса без букв направления для нулевого рейса
    getNullTripTypeShort(line) {
      if (line.trip_complex.null_direction == 0) {
        return ">";
      } else {
        return "<";
      }
    },

    getReisLineStyle(line) {
      if (line.trip_complex.schedule_event_id != 1) {
        return 'stroke-width:' + this.tech_op_width + '; stroke:rgb(196, 223, 162);'
      }
      return 'stroke-width:' + this.reis_width + ';' + (line.trip_complex.direction == 0 ? 'stroke:rgb(35,152,0)' : 'stroke:rgb(229,41,255);');
    },

    getStopColor(line, index) {
      if (index == 0 || index == this.graph.length - 1 || (line.trip_complex.null_step_time_begin != 0 && line.trip_complex.null_step_time_end != 0 && line.trip_complex.schedule_event_id != 1)) {
        return 'stroke:rgb(0,81,255)'
      }
      return (line.trip_complex.direction == 0 ? 'stroke:rgb(35,152,0)' : 'stroke:rgb(229,41,255);');
    },

    getLineXStart(line) {
      if (line.trip_complex.null_step_time_begin != 0 && line.trip_complex.null_step_time_end != 0 && (line.trip_complex.null_step_time_end > line.trip_complex.step_time_begin)) {
        line.trip_complex.step_time_begin = line.trip_complex.null_step_time_end;
      }
      return this.getXStart(line.trip_complex.step_time_begin);
    },

    getXStart(value) {
      return (((value / 60) - this.layoutStartTime) * this.oneDiv) + this.start_x;
    },

    getStopXStart(line, index) {
      let value = 0;
      // при наличие стоянки и нулевого всегда строго располагаем нулевой, потом стоянка, однако, если нулевой и стоянка
      // привязаны к техоперации или нулевой техоперации, то располагаем их сначала стоянка, потом нулевой
      if (index == 0 || (line.trip_complex.null_step_time_begin == 0 && line.trip_complex.null_step_time_end == 0) || line.trip_complex.schedule_event_id == 1) {
        value = line.trip_complex.step_time_begin - (line.trip_complex.stop_time * 60);
      } else {
        // в случае когда это не первый рейс и есть нулевой перед техоперацией, тогда нужно иначе посчитать начало стоянки
        value = line.trip_complex.null_step_time_begin - (line.trip_complex.stop_time * 60);
      }
      return (((value / 60) - this.layoutStartTime) * this.oneDiv) + this.start_x;
    },

    getStopXEnd(line, index) {
      let value = 0;
      if (index == 0 || (line.trip_complex.null_step_time_begin == 0 && line.trip_complex.null_step_time_end == 0) || line.trip_complex.schedule_event_id == 1) {
        value = line.trip_complex.step_time_begin;
      } else {
        // в случае когда это не первый рейс или есть нулевой перед техоперацией, тогда нужно иначе посчитать начало стоянки
        value = line.trip_complex.null_step_time_begin;
      }

      return (((value / 60) - this.layoutStartTime) * this.oneDiv) + this.start_x;
    },

    getLineXEnd(value) {
      return (((value / 60) - this.layoutStartTime) * this.oneDiv) + this.start_x;
    },

    getXLayoutCoord(index, addValue) {
      let val = (this.start_x + (index * this.oneDiv * 60) + (this.oneDiv * addValue));
      return val;
    },

    getColor(value) {
      if (value.type.includes('change')) {
        return 'red';
      } else if (value.type.includes('gray')) {
        return 'gray';
      } else {
        return 'black';
      }
    },

    getStyle(value) {

      if (value.type == 'start' || value.type == 'stop') {
        // делаем линии поверх которых будет текст невидимыми (белыми)
        return 'stroke-linecap: square; stroke:rgb(255,255,255); stroke-width:2';
      } else {
        if (value.type == 'line') {
          return 'stroke-linecap: square; stroke:rgb(0,0,0); stroke-width:2';
        } else if (value.type == 'line-gray') {
          return 'stroke-linecap: square; stroke:rgb(129,129,129); stroke-width:2';
        }
      }
    },

    getLineStartInPx(index) {
      let startX = this.start_x;
      for (let i = 0; i <= index; i++) {
        startX = startX + ((this.graphLine[i].length / 60) * 10 * this.oneDiv);
      }
      return startX;
    },

    //Конвертирует и возвращает время без секунд из значения в секундах
    getTimeFormat(time) {
      if (time != null && time !== '' && Number.isFinite(Number(time))) {
        time = Number(time);
        // часы
        let hours = Math.trunc(time / 3600);
        // минуты
        let minutes = Math.trunc((time - (hours * 3600)) / 60);
        //Возвращаем отформатированное время
        let result = (hours === 0 ? '00' : hours < 10 ? ('0' + hours) : hours) + ':' + (minutes === 0 ? '00' : (minutes < 10 ? ('0' + minutes) : minutes));
        return result;
      } else {
        return '';
      }
    },

    getScheduleEventColor(line) {
      if (line.trip_complex.schedule_event_id == 0) {
        return 'rgb(0,81,255)'
      } else {
        return 'rgb(255,0,0)'
      }
    },

    getScheduleEventText(line) {
      let value = '';
      // определяем букву
      if (line.trip_complex.schedule_event_id == 0) {
        value = "КР";
      } else if (line.trip_complex.schedule_event_id == 4) {
        value = "Т";
      } else if (line.trip_complex.schedule_event_id == 5) {
        value = "О";
      } else if (line.trip_complex.schedule_event_id == 6) {
        value = "Р";
      } else if (line.trip_complex.schedule_event_id == 7) {
        value = "П";
      } else if (line.trip_complex.schedule_event_id == 8) {
        value = "З";
      } else if (line.trip_complex.schedule_event_id == 9) {
        value = "К";
      } else if (line.trip_complex.schedule_event_id == 10) {
        value = "С";
      } else if (line.trip_complex.schedule_event_id == 11) {
        value = "Е";
      } else if (line.trip_complex.schedule_event_id == 12) {
        value = "В";
      } else if (line.trip_complex.schedule_event_id == 13) {
        value = "И";
      } else {
        value = "";
      }
      // добавляем длительность
      if (line.trip_complex.schedule_event_id != 0 && line.trip_complex.schedule_event_id != 10) {
        let time = line.trip_complex.step_time_end - line.trip_complex.step_time_begin;
        value += ' (' + getTimeFormat(time) + ')';
      }
      return value;
    },

    getPreviousReisParams(i) {
      let comp_coord = '';
      let reis_type = '';
      if (this.textCoords[i - 1].name_coord >= this.textCoords[i - 1].stop_length_coord && this.textCoords[i - 1].name_coord >= this.textCoords[i - 1].null_name_coord) {
        comp_coord = this.textCoords[i - 1].name_coord;
        reis_type = 'name_coord';
      } else if (this.textCoords[i - 1].stop_length_coord >= this.textCoords[i - 1].name_coord && this.textCoords[i - 1].stop_length_coord >= this.textCoords[i - 1].null_name_coord) {
        comp_coord = this.textCoords[i - 1].stop_length_coord;
        reis_type = 'stop_length_coord';
      } else if (this.textCoords[i - 1].null_name_coord >= this.textCoords[i - 1].name_coord && this.textCoords[i - 1].null_name_coord >= this.textCoords[i - 1].stop_length_coord) {
        comp_coord = this.textCoords[i - 1].null_name_coord;
        reis_type = 'null_name_coord';
      }
      return {
        comp_coord: comp_coord,
        reis_type: reis_type
      }
    }

  },

  created() {

    // крайняя левая точка линейки часов т.е. это как бы ноль по шкале x
    this.layoutStartTime = (this.hoursArray[0] * 60);

    // // прежде всего необходимо определеить время начала графика чтобы определеить отступ слева
    // let startTime = this.graph[0].step_time_begin;

    // заранее рассчитываем координаты надписей, чтобы избежать наложений
    // предположительно порядок надписей всегда такой:
    // 1. Длительность остановки
    // 2. Название нулевого
    // 3.Название рейса ИЛИ название тех.операции

    for (let i = 0; i < this.graph.length; i++) {
      let line = this.graph[i];
      let stop_length_coord = 0;
      let null_name_coord = 0;
      let name_coord = 0;
      // определяем координаты длительность остановки
      if (i == 0) {
        stop_length_coord = this.getStopXStart(line, i);
      } else {
        stop_length_coord = this.getStopXStart(line, i);
        // проверяем не пересекается ли длительность остановки с текстом предыдущего рейса
        // при этом, учтем то, что может не быть надписи рейса или нулевого рейса путем сравнения координат
        // самые большие координаты текста в предыдущем рейсе
        let comp_coord = 0;
        // тип текста предыдущих координат
        let reis_type = 0;

        let params = this.getPreviousReisParams(i);
        comp_coord = params.comp_coord;
        reis_type = params.reis_type;

        if (reis_type == 'name_coord') {
          // если предыдущей была техоперация
          if (this.graph[i - 1].trip_complex.schedule_event_id != 1) {
            if ((comp_coord + (String(this.getScheduleEventText(this.graph[i - 1])).length * 8)) > stop_length_coord) {
              stop_length_coord = (comp_coord + (String(this.getScheduleEventText(this.graph[i - 1])).length * 8));
            }
          } else {
            // если предыдущим был рейс
            if ((this.textCoords[i - 1].name_coord + (String(this.getTripTypeShort(this.graph[i - 1].trip_complex.trip_type_sign)).length * 8)) > stop_length_coord) {
              stop_length_coord = (comp_coord + (String(this.getTripTypeShort(this.graph[i - 1].trip_complex.trip_type_sign)).length * 8));
            }
          }
        } else if (reis_type == 'stop_length_coord') {
          if ((comp_coord + (String(this.graph[i - 1].trip_complex.stop_time).length * 8)) > stop_length_coord) {
            stop_length_coord = (comp_coord + (String(this.graph[i - 1].trip_complex.stop_time).length * 8));
          }
        } else if (reis_type == 'null_name_coord') {
          if ((comp_coord + (String(this.getNullTripTypeShort(this.graph[i - 1])).length * 8)) > stop_length_coord) {
            stop_length_coord = (comp_coord + (String(this.getNullTripTypeShort(this.graph[i - 1])).length * 8));
          }
        }
      }

/////////
///////// определяем координаты названия нулевого
      if (line.trip_complex.null_step_time_begin && line.trip_complex.null_step_time_end) {
        // если нулевой до остановки
        if (this.graph[i].trip_complex.schedule_event_id != 1) {
          if (i == 0) {
            // для первого нулевого рейса нет смысла считать отступы т.к. это первый элемент на графике
            null_name_coord = this.getNullTextXStart(line, i);
          } else {
            null_name_coord = this.getNullTextXStart(line, i);
            // проверяем не пересекается ли нулевой рейс с текстом длительности остановки, сдвигаем, если пересекается сдвигаем
            if ((stop_length_coord + (String(this.graph[i].trip_complex.stop_time).length * 8)) > null_name_coord) {
              null_name_coord = (stop_length_coord + (String(this.graph[i].trip_complex.stop_time).length * 8));
            }
          }
          // если нулевой после остановки
        } else {
          if (i == 0) {
            // для первого нулевого рейса нет смысла считать отступы т.к. это первый элемент на графике
            null_name_coord = this.getNullTextXStart(line, i);
          } else {
            null_name_coord = this.getNullTextXStart(line, i);
            // самые большие координаты текста в предыдущем рейсе
            let comp_coord = 0;
            // тип текста предыдущих координат
            let reis_type = 0;
            let params = this.getPreviousReisParams(i);
            comp_coord = params.comp_coord;
            reis_type = params.reis_type;

            if (reis_type == 'name_coord') {
              // если предыдущей была техоперация
              if (this.graph[i - 1].trip_complex.schedule_event_id != 1) {
                if ((comp_coord + (String(this.getScheduleEventText(this.graph[i - 1])).length * 8)) > null_name_coord) {
                  null_name_coord = (comp_coord + (String(this.getScheduleEventText(this.graph[i - 1])).length * 8));
                }
              } else {
                // если предыдущим был рейс
                if ((comp_coord + (String(this.getTripTypeShort(this.graph[i - 1].trip_complex.trip_type_sign)).length * 8)) > null_name_coord) {
                  null_name_coord = (comp_coord + (String(this.getTripTypeShort(this.graph[i - 1].trip_complex.trip_type_sign)).length * 8));
                }
              }
            } else if (reis_type == 'stop_length_coord') {
              if ((comp_coord + (String(this.graph[i - 1].trip_complex.stop_time).length * 8)) > null_name_coord) {
                null_name_coord = (comp_coord + (String(this.graph[i - 1].trip_complex.stop_time).length * 8));
              }
            } else if (reis_type == 'null_name_coord') {
              if ((comp_coord + (String(this.getNullTripTypeShort(this.graph[i - 1])).length * 8)) > null_name_coord) {
                null_name_coord = (comp_coord + (String(this.getNullTripTypeShort(this.graph[i - 1])).length * 8));
              }
            }

            // // проверяем не нужно ли сдвинуть название остановки
            if((null_name_coord + (String(this.getNullTripTypeShort(this.graph[i])).length * 8)) > stop_length_coord) {
              stop_length_coord = (null_name_coord + (String(this.getNullTripTypeShort(this.graph[i])).length * 8));
            }

          }
        }
      }

/////////
/////////// определяем координаты Названия рейса ИЛИ названия тех.операции
      // определяем рейс или техоперация
      if (line.trip_complex.schedule_event_id != 1) {
        // если техоперация
        if (i == 0 && this.graph[i].trip_complex.schedule_graph_step_id != 0) {
          name_coord = this.getXStart(line.trip_complex.step_time_begin);
          // необходимо проверить на пересечение с остановкой
          if ((stop_length_coord + (String(this.graph[i].trip_complex.stop_time).length * 8)) > name_coord) {
            name_coord = (stop_length_coord + (String(this.graph[i].trip_complex.stop_time).length * 8));
          }
        } else {
          if (null_name_coord > stop_length_coord) {
            name_coord = this.getXStart(line.trip_complex.step_time_begin);
            // проверяем не пересекается ли нулевой рейс с текстом длительности остановки, сдвигаем, если пересекается сдвигаем
            if ((null_name_coord + (String(this.getNullTripTypeShort(this.graph[i])).length * 8)) > name_coord) {
              name_coord = (null_name_coord + (String(this.getNullTripTypeShort(this.graph[i])).length * 8));
            }
          } else {
            name_coord = this.getXStart(line.trip_complex.step_time_begin);
            // необходимо проверить на пересечение с остановкой
            if ((stop_length_coord + (String(this.graph[i].trip_complex.stop_time).length * 8)) > name_coord) {
              name_coord = (stop_length_coord + (String(this.graph[i].trip_complex.stop_time).length * 8));
            }
          }
        }
        // если это название рейса
      } else {
        name_coord = this.getXStart(line.trip_complex.step_time_begin);
        // необходимо проверить на пересечение с остановкой
        if ((stop_length_coord + (String(this.graph[i].trip_complex.stop_time).length * 8)) > name_coord) {
          name_coord = (stop_length_coord + (String(this.graph[i].trip_complex.stop_time).length * 8));
        }
      }


      // кладем рассчитанные координаты в соотв сущность
      this.textCoords[i] = {
        stop_length_coord: stop_length_coord,
        null_name_coord: null_name_coord,
        name_coord: name_coord
      }
    }
  }
}
</script>

<style scoped>

</style>