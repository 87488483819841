<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/tariffZoneSystems">Тарифные зональные системы</router-link></span>
        <span> / </span>
        <span>Стоимости проезда между зонами {{ tariffZoneSystemPageHeader }} </span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="tableButtonsExist"
              :buttonsEnabled="tableButtonsEnabled"
              :filterExists="false"
              @onEdit="onTableEdit"
              @onCancel="onTableCancel"
              @onRefresh="onTableRefresh"
              @onSave="onTableSave"
          >
          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <tariff-zone-fares-edit-table
              :columnList="columnList"
              :rowList="rowList"
          ></tariff-zone-fares-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import {getTariffZoneSystem} from "@/store/MultipassHelpers";
import TariffZoneFaresEditTable from "@/components/multipass/tariff_zone_fares/TariffZoneFaresEditTable";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    TariffZoneFaresEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор тарифные зональные системы
    "tariff_zone_system_id",
  ],

  data() {
    return {
      // список строк
      rowList: [],
      // отображать спиннер
      isLoading: false,
      // режим редактирования
      isEditMode: false,
      // массив тарифных зон
      tariffZones: [],
      // стоимости между зонами
      tariffZoneFares: [],
    }
  },

  computed: {

    // включенные кнопки для таблицы
    tableButtonsExist() {
      return {
        edit: !this.isEditMode,
        save: this.isEditMode,
        cancel: this.isEditMode,
        refresh: true,
      }
    },

    // активные кнопки для таблицы
    tableButtonsEnabled() {
      return {
        edit: !this.isEditMode,
        save: this.isEditMode,
        cancel: this.isEditMode,
        refresh: true,
      }
    },

    // список колонок
    columnList() {

      // формируем список колонок
      const columns = this.tariffZones.map((zone, index) => {
        return {
          field: "__fare"+index,
          caption: zone.long_name,
          isSortable: false,
          sortType: 'number',
          displayType: 'currency',
          inputAttrs: {
            disabled: !this.isEditMode,
          },
          inputStyle: {
            width: '80px',
          }
        }
      });

      columns.unshift({
        field: "__firstColumn",
        caption: ' ',
        isSortable: false,
        sortType: 'string',
        isCaption: true
      });

      return columns;
    },

    // текущий трифная зональная система
    tariffZoneSystem() {
      return getTariffZoneSystem(this.tariff_zone_system_id);
    },
  },
  methods: {

    // нажата кнопка Изменить
    onTableEdit() {
      this.isEditMode = true;
    },

    // нажата кнопка Сохранить
    onTableSave() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // сохраняем данные
      this.saveTariffZoneFares();
    },

    // нажата кнопка Отменить
    onTableCancel() {
      // снимаем режим редактирования
      this.isEditMode = false;
      // обновляем таблицу
      this.restoreTariffZoneFares();
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // сбрасываем редактирование
      this.isEditMode = false;
      // перезапрашиваются данные
      this.refreshData();
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // запрашиваем зональные системы
        await this.$store.dispatch('multipass/doFetchTariffZoneSystems');

        // запрашиваем тарифные зоны
        const tariffZones = await this.$store.dispatch('multipass/doFetchTariffZones', {
          tariff_zone_system_id: this.tariff_zone_system_id,
        }); if (!tariffZones) return;
        this.tariffZones = tariffZones;

        // запрашиваем тарифы
        await this.refreshTariffZoneFares();

      } finally {
        this.isLoading = false;
      }
    },

    // обновляем тарифы
    async refreshTariffZoneFares() {
      // запрашиваем данные
      const tariffZoneFares = await this.$store.dispatch('multipass/doFetchTariffZoneFares', {
        tariff_zone_system_id: this.tariff_zone_system_id,
      }); if (!tariffZoneFares) return;
      this.tariffZoneFares = tariffZoneFares;

      // обновляем список строк
      await this.updateRowList();
    },

    // вернуть стоимости проезда между зонами
    getTariffZoneFare(begin_tariff_zone_id, end_tariff_zone_id) {
      return this.tariffZoneFares.find(tz => ((tz.begin_tariff_zone_id == begin_tariff_zone_id) && (tz.end_tariff_zone_id == end_tariff_zone_id)));
    },

    // обновляем список строк
    async updateRowList() {
      // формируем список строк
      this.rowList = this.tariffZones.map((zoneFrom, rowIndex) => {
        const row = {
          __id: zoneFrom.tariff_zone_id,
          __firstColumn: zoneFrom.long_name
        }

        this.tariffZones.forEach((zoneTo, colIndex) => {
          row['__fare'+ colIndex] =  (this.getTariffZoneFare(zoneFrom.tariff_zone_id, zoneTo.tariff_zone_id) || {fare: 0}).fare || 0;
          if (colIndex === rowIndex) {
            row['__fare'+ colIndex+'__cellStyle']= {
              backgroundColor: '#ccc'
            }
            row['__fare'+ colIndex+'__inputAttrs'] = {
              disabled: true,
            }
          }
        })

        return row;
      });
    },

    // сохраняем стоимости на сервере
    async saveTariffZoneFares() {
      this.isLoading = true;
      try {

        // формируем массив для сохранения
        const faresToSave = [];
        this.tariffZones.map((zoneFrom, indexFrom) => {
          this.tariffZones.forEach((zoneTo, indexTo) => {
            faresToSave.push({
              tariff_zone_system_id: Number(this.tariff_zone_system_id),
              begin_tariff_zone_id: zoneFrom.tariff_zone_id,
              end_tariff_zone_id: zoneTo.tariff_zone_id,
              fare: Number(this.rowList[indexFrom]['__fare'+ indexTo]) || 0
            })
          });
        });

        // сохраняем данные
        const newFares = await this.$store.dispatch('multipass/doEditTariffZoneFare', {
          tariff_zone_system_id: Number(this.tariff_zone_system_id),
          fares: faresToSave
        }); if (!newFares) return;
        this.tariffZoneFares = newFares;

        // обновляем список строк
        await this.updateRowList();

      } finally {
        this.isLoading = false;
      }
    },

    // восстанавливаем стоимости
    async restoreTariffZoneFares() {
      this.isLoading = true;
      try {

        // обновляем список строк
        await this.updateRowList();

      } finally {
        this.isLoading = false;
      }
    }
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
}


</script>

