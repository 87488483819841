<template>
  <edit-table-panel :columnList="columnList" :rowList="rowArr" name="tbl-asmpp-passenger-traffic-fact-trip-by-hours"> </edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading','rowArr'],
  emits: ['setLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "hour",
          caption: "Час",
          sortType: 'number',
          sorted: 'asc',
          isFilterable: true,
        },
        {
          field: "fact_trip_count",
          caption: "Кол-во рейсов",
          sortType: 'number',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
        },
        {
          field: "passenger_count_avg",
          caption: "Ср.знач.«перевезено за рейс».",
          sortType: 'number',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayFn(row) {
            if (row.passenger_count_avg) {
              return Number(row.passenger_count_avg).toFixed(1);
            }
            return ''
          },
        },
        {
          field: "passenger_count_deviation",
          caption: "СКО«перевезено за рейс»",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayFn(row) {
            if (row.passenger_count_deviation) {
              return Number(row.passenger_count_deviation).toFixed(1);
            }
            return ''
          },
        },
        {
          field: "passenger_trip_length_avg_avg",
          caption: "Ср.знач.«средняя длина поездки»",
          sortType: 'number',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayFn(row) {
            if (row.passenger_trip_length_avg_avg) {
              return Number(row.passenger_trip_length_avg_avg).toFixed(1);
            }
            return ''
          },
        },
        {
          field: "passenger_trip_length_avg_deviation",
          caption: "СКО«средняя длина поездки»",
          sortType: 'number',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayFn(row) {
            if (row.passenger_trip_length_avg_deviation) {
              return Number(row.passenger_trip_length_avg_deviation).toFixed(1);
            }
            return ''
          },
        },
        {
          field: "passenger_inside_max_avg",
          caption: "Ср.знач.«максимальное наполнение салона»",
          sortType: 'number',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayFn(row) {
            if (row.passenger_inside_max_avg) {
              return Number(row.passenger_inside_max_avg).toFixed(1);
            }
            return ''
          },
        },
        {
          field: "passenger_inside_max_deviation",
          caption: "СКО«максимальное наполнение салона»",
          sortType: 'number',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayFn(row) {
            if (row.passenger_inside_max_deviation) {
              return Number(row.passenger_inside_max_deviation).toFixed(1);
            }
            return ''
          },
        },
        {
          field: "pay_sum",
          caption: "Ср.знач.«оплачено за проезд»",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayFn(row) {
            if (row.pay_sum) {
              return Number(row.pay_sum).toFixed(1);
            }
            return ''
          },
        },
        {
          field: "pay_sum_deviation",
          caption: "СКО«оплачено за проезд»",
          sortType: 'number',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayFn(row) {
            if (row.pay_sum_deviation) {
              return Number(row.pay_sum_deviation).toFixed(1);
            }
            return ''
          },
        },

        {
          field: "pay_tran_count",
          caption: "Ср.знач.«количество пассажиров, оплативших проезд»",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayFn(row) {
            if (row.pay_tran_count) {
              return Number(row.pay_tran_count).toFixed(1);
            }
            return ''
          },
        },
        {
          field: "pay_tran_count_deviation",
          caption: "СКО«количество пассажиров, оплативших проезд»",
          sortType: 'number',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          displayFn(row) {
            if (row.pay_tran_count_deviation) {
              return Number(row.pay_tran_count_deviation).toFixed(1);
            }
            return ''
          },
        },

      ],
    };
  },
  computed: {

    // rowList() {
    //   if (this.isLoading === 'fetch') {
    //     return []
    //   }
    //
    //   return [
    //     {
    //       hour: 5,
    //       fact_trip_count: 3,
    //       passenger_traffic: 3.3,
    //       passenger_traffic_deviation: 3.4,
    //       passenger_trip_length_avg: 2.2,
    //       passenger_trip_length_avg_deviation: 2.5,
    //       passenger_inside_max: 6,
    //       passenger_inside_max_deviation: 65,
    //       passenger_inside_avg: 6.8,
    //       passenger_inside_avg_deviation: 77.78,
    //       pay_sum: 877,
    //       pay_sum_deviation: 43.1,
    //       pay_count: 6.7,
    //       pay_count_deviation: 7.6,
    //     },
    //     {
    //       hour:6,
    //       fact_trip_count: 5,
    //       passenger_traffic: 8.3,
    //       passenger_traffic_deviation: 9.4,
    //       passenger_trip_length_avg: 24.2,
    //       passenger_trip_length_avg_deviation: 3.5,
    //       passenger_inside_max: 5,
    //       passenger_inside_max_deviation: 45,
    //       passenger_inside_avg: 6.8,
    //       passenger_inside_avg_deviation: 99.78,
    //       pay_sum: 17,
    //       pay_sum_deviation: 42.1,
    //       pay_count: 3.7,
    //       pay_count_deviation: 4.6,
    //     },
    //     {
    //       hour: 7,
    //       fact_trip_count: 6,
    //       passenger_traffic: 7.3,
    //       passenger_traffic_deviation: 12.4,
    //       passenger_trip_length_avg: 23.2,
    //       passenger_trip_length_avg_deviation: 42.5,
    //       passenger_inside_max: 5,
    //       passenger_inside_max_deviation: 75,
    //       passenger_inside_avg: 12.8,
    //       passenger_inside_avg_deviation: 17.78,
    //       pay_sum: 847,
    //       pay_sum_deviation: 4.1,
    //       pay_count: 61.7,
    //       pay_count_deviation: 71.6,
    //     },
    //     {
    //       hour: 8,
    //       fact_trip_count: 1,
    //       passenger_traffic: 1.2,
    //       passenger_traffic_deviation: 1.2,
    //       passenger_trip_length_avg: 3.21,
    //       passenger_trip_length_avg_deviation: 21.5,
    //       passenger_inside_max: 1,
    //       passenger_inside_max_deviation: 61,
    //       passenger_inside_avg: 18,
    //       passenger_inside_avg_deviation: 44.78,
    //       pay_sum: 84,
    //       pay_sum_deviation: 4.1,
    //       pay_count: 1.7,
    //       pay_count_deviation: 1.6,
    //     },
    //     {
    //       hour: 9,
    //       fact_trip_count: 8,
    //       passenger_traffic: 1,
    //       passenger_traffic_deviation: 7.47,
    //       passenger_trip_length_avg: 7.6,
    //       passenger_trip_length_avg_deviation: 1.54,
    //       passenger_inside_max: 8,
    //       passenger_inside_max_deviation: 15,
    //       passenger_inside_avg: 1.1,
    //       passenger_inside_avg_deviation: 1.1,
    //       pay_sum: 81,
    //       pay_sum_deviation: 1.1,
    //       pay_count: 1.7,
    //       pay_count_deviation: 1.6,
    //     }
    //
    //   ];
    // },
  }
};
</script>
