<template>
  <modal>
    <modal-dialog :class="getModalDialogClass">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Радиостанция</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body style="height: 642px; overflow: hidden; z-index: 3 !important;">
          <form class="form">
            <align-container>
              <align-panel align="top">
                <nav-menu class="nav-tabs" mode="single" @change="getModalDialogClass">
                  <nav-item>
                    <nav-link target-ref="pane1" target-method="select" active @click="currentTab = 1">Основные
                    </nav-link>
                  </nav-item>
                  <nav-item>
                    <nav-link target-ref="pane2" target-method="select" @click="currentTab = 2">Модемы</nav-link>
                  </nav-item>
                  <nav-item>
                    <nav-link target-ref="pane3" target-method="select" @click="currentTab = 3">Дискретные</nav-link>
                  </nav-item>
                  <nav-item>
                    <nav-link target-ref="pane4" target-method="select" @click="currentTab = 4">Аналоговые</nav-link>
                  </nav-item>
                </nav-menu>
              </align-panel>
              <align-panel align="all" style="overflow-y: auto; overflow-x: hidden">
                <tab-content mode="single">
                  <tab-pane active ref="pane1" class="p-2"
                            style="padding: 0 !important;">
                    <form>

                      <div class="form-group"
                           style=" margin-bottom: 0; padding-right: 6px; padding-left: 8px;">

                        <label for="discardDate" v-if="op === 'edit'">Дата и время:</label>
                        <div class="form-group row" style="padding-left: 15px" v-if="op === 'edit'">
                          <input-date-and-time
                              v-model="discardDate"
                              ref="discardDate"
                              :required="op === 'edit'"
                              error="Введите дату списания"
                          />
                        </div>

                        <label for="radioId">Код:</label>
                        <div class="form-group row" style="margin-bottom: 0">
                          <div class="form-group col-3">
                            <input-integer
                                id="radioId"
                                class="form-control"
                                v-model="radioId"
                                readonly
                            />
                          </div>
                          <div class="form-group col">
                            <input-checkbox
                                id="isDisabled"
                                v-model="isDisabled"
                                :readonly="op === 'show'"
                                style="margin-top: 7px"
                            >Радиостанция отключена
                            </input-checkbox>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="radioNum">Опросный номер радиостанции:</label>
                          <input-string
                              class="form-control"
                              id="radioNum"
                              v-model="radioNum"
                              ref="radioNum"
                              :readonly="op === 'show'"
                          />
                        </div>

                        <div class="form-group">
                          <label for="radioProtocolId">Эфирный протокол:</label>
                          <req-field/>
                          <radio-protocols-select
                              class="form-control"
                              id="radioProtocolId"
                              v-model="radioProtocolId"
                              ref="radioProtocolId"
                              :readonly="op === 'show'"
                              required
                              error="Введите код эфирного протокола"
                          />
                        </div>

                        <div class="form-group">
                          <label for="radioGateNum">Номер шлюза:</label>
                          <req-field/>
                          <input-integer
                              class="form-control"
                              id="radioGateNum"
                              v-model="radioGateNum"
                              ref="radioGateNum"
                              required
                              :readonly="op === 'show'"
                              :min-value="0"
                          />
                        </div>

                        <div class="form-group">
                          <label for="radioModelId">Модель:</label>
                          <req-field/>
                          <radio-models-select
                              class="form-control"
                              id="radioModelId"
                              v-model="radioModelId"
                              ref="radioModelId"
                              required
                              :readonly="op === 'show'"
                          />
                        </div>

                        <div class="form-group">
                          <label for="depotId">Предприятие:</label>
                          <req-field/>
                          <depots-select
                              class="form-control"
                              id="depotId"
                              v-model="depotId"
                              ref="depotId"
                              required
                              :readonly="op === 'show'"
                          />
                        </div>

                        <div class="form-group">
                          <label for="transportId">Транспортное средство:</label>
                          <transport-select
                              class="form-control"
                              id="transportId"
                              v-model="transportId"
                              ref="transportId"
                              :readonly="op === 'show'"
                          />
                        </div>

                        <div class="form-group">
                          <label for="radioPriority">Приоритетность радиостанций:</label>
                          <input-integer
                              class="form-control"
                              id="radioPriority"
                              v-model="radioPriority"
                              ref="radioPriority"
                              :readonly="op === 'show'"
                              :min-value="0"
                          />
                        </div>

                        <div class="form-group" style="padding-bottom: 16px; margin-bottom: 0px">
                          <label for="note">Примечание:</label>
                          <input-string
                              class="form-control"
                              id="note"
                              v-model="note"
                              ref="note"
                              :readonly="op === 'show'"
                          />
                        </div>
                      </div>
                    </form>
                  </tab-pane>

                  <tab-pane ref="pane2" class="p-2"
                            style="padding: 0 !important;">
                    <form>
                      <div class="form-group"
                           style="margin-bottom: 0">
                        <div class="card bg-light mb-3"
                             style="margin-right: 8px; margin-top: 16px; margin-left: 8px;">
                          <div class="card-header" style="margin-bottom: 0px; font-size: 18px; font-weight: bold;">
                            Первый модем
                          </div>
                          <div style="padding-right: 8px; padding-left: 8px ">
                            <div class="form-group">
                              <label for="firstModem.phone_num" style="margin-top: 8px;">Номер телефона:</label>
                              <input-string
                                  class="form-control"
                                  id="firstModem.phone_num"
                                  v-model="firstModem.phone_num"
                                  ref="firstModem.phone_num"
                                  :readonly="op === 'show'"
                              />
                            </div>

                            <div class="form-group">
                              <label for="firstModem.sim">Номер SIM-карты:</label>
                              <input-string
                                  class="form-control"
                                  id="firstModem.sim"
                                  v-model="firstModem.sim"
                                  ref="firstModem.sim"
                                  :readonly="op === 'show'"
                              />
                            </div>

                            <div class="form-group">
                              <label for="firstModem.imei">Номер IMEI модема:</label>
                              <input-string
                                  class="form-control"
                                  id="firstModem.imei"
                                  v-model="firstModem.imei"
                                  ref="firstModem.imei"
                                  :readonly="op === 'show'"
                              />
                            </div>

                            <div class="form-group">
                              <label for="firstModem.channel_num">Канал связи:</label>
                              <input-string
                                  class="form-control"
                                  id="firstModem.channel_num"
                                  v-model="firstModem.channel_num"
                                  ref="firstModem.channel_num"
                                  :readonly="op === 'show'"
                              />
                            </div>

                            <div class="form-group" style="margin-bottom: 0px;">
                              <input-checkbox
                                  id="firstModem.is_income_call"
                                  v-model="firstModem.is_income_call"
                                  :readonly="op === 'show'"
                              >Прием входящих GSM звонков
                              </input-checkbox>
                            </div>

                            <div class="form-group">
                              <input-checkbox
                                  id="firstModem.is_income_sms"
                                  v-model="firstModem.is_income_sms"
                                  :readonly="op === 'show'"
                              >Прием входящих SMS сообщений
                              </input-checkbox>
                            </div>
                          </div>
                        </div>

                        <div class="card bg-light mb-3"
                             style="margin-right: 8px; margin-top: 20px;  margin-left: 8px;">
                          <div class="card-header" style="margin-bottom: 0px; font-size: 18px; font-weight: bold;">
                            Второй модем
                          </div>
                          <div style="padding-right: 8px; padding-left: 8px">
                            <div class="form-group">
                              <label for="secondModem.phone_num" style="margin-top: 8px;">Номер телефона:</label>
                              <input-string
                                  class="form-control"
                                  id="secondModem.phone_num"
                                  v-model="secondModem.phone_num"
                                  ref="secondModem.phone_num"
                                  :readonly="op === 'show'"
                              />
                            </div>

                            <div class="form-group">
                              <label for="secondModem.sim">Номер SIM-карты:</label>
                              <input-string
                                  class="form-control"
                                  id="secondModem.sim"
                                  v-model="secondModem.sim"
                                  ref="secondModem.sim"
                                  :readonly="op === 'show'"
                              />
                            </div>

                            <div class="form-group">
                              <label for="secondModem.imei">Номер IMEI модема:</label>
                              <input-string
                                  class="form-control"
                                  id="secondModem.imei"
                                  v-model="secondModem.imei"
                                  ref="secondModem.imei"
                                  :readonly="op === 'show'"
                              />
                            </div>

                            <div class="form-group">
                              <label for="secondModem.channel_num">Канал связи:</label>
                              <input-string
                                  class="form-control"
                                  id="secondModem.channel_num"
                                  v-model="secondModem.channel_num"
                                  ref="secondModem.channel_num"
                                  :readonly="op === 'show'"
                              />
                            </div>

                            <div class="form-group" style="margin-bottom: 0px;">
                              <input-checkbox
                                  id="secondModem.is_income_call"
                                  v-model="secondModem.is_income_call"
                                  :readonly="op === 'show'"
                              >Прием входящих GSM звонков
                              </input-checkbox>
                            </div>

                            <div class="form-group" style="margin-top: 0px;">
                              <input-checkbox
                                  id="secondModem.is_income_sms"
                                  v-model="secondModem.is_income_sms"
                                  :readonly="op === 'show'"
                              >Прием входящих SMS сообщений
                              </input-checkbox>
                            </div>
                          </div>
                        </div>

                        <div style="padding-bottom: 16px;">
                          <div class="card bg-light"
                               style="margin: 20px 8px 0px;">
                            <div class="card-header" style="margin-bottom: 0px; font-size: 18px; font-weight: bold;">
                              Третий модем
                            </div>
                            <div style="padding-right: 8px; padding-left: 8px ">
                              <div class="form-group">
                                <label for="thirdModem.phone_num" style="margin-top: 8px;">Номер телефона:</label>
                                <input-string
                                    class="form-control"
                                    id="thirdModem.phone_num"
                                    v-model="thirdModem.phone_num"
                                    ref="thirdModem.phone_num"
                                    :readonly="op === 'show'"
                                />
                              </div>

                              <div class="form-group">
                                <label for="thirdModem.sim">Номер SIM-карты:</label>
                                <input-string
                                    class="form-control"
                                    id="thirdModem.sim"
                                    v-model="thirdModem.sim"
                                    ref="thirdModem.sim"
                                    :readonly="op === 'show'"
                                />
                              </div>

                              <div class="form-group" style="margin-top: 0px;">
                                <label for="thirdModem.imei">Номер IMEI модема:</label>
                                <input-string
                                    class="form-control"
                                    id="thirdModem.imei"
                                    v-model="thirdModem.imei"
                                    ref="thirdModem.imei"
                                    :readonly="op === 'show'"
                                />
                              </div>

                              <div class="form-group">
                                <label for="thirdModem.channel_num">Канал связи:</label>
                                <input-string
                                    class="form-control"
                                    id="thirdModem.channel_num"
                                    v-model="thirdModem.channel_num"
                                    ref="thirdModem.channel_num"
                                    :readonly="op === 'show'"
                                />
                              </div>

                              <div class="form-group" style="margin-bottom: 0px;">
                                <input-checkbox
                                    id="thirdModem.is_income_call"
                                    v-model="thirdModem.is_income_call"
                                    :readonly="op === 'show'"
                                >Прием входящих GSM звонков
                                </input-checkbox>
                              </div>

                              <div class="form-group" style="margin-top: 0px;">
                                <input-checkbox
                                    id="thirdModem.is_income_sms"
                                    v-model="thirdModem.is_income_sms"
                                    :readonly="op === 'show'"
                                >Прием входящих SMS сообщений
                                </input-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </tab-pane>

                  <tab-pane ref="pane3" class="p-2">
                    <form>
                      <div class="form-group">
                        <cell-definitions-edit-table
                            :columnList="discreteCellDefinitionsColumns"
                            :rowList="discreteCellDefinitions"
                            style="margin-bottom: 0px"
                        >
                        </cell-definitions-edit-table>
                      </div>
                    </form>
                  </tab-pane>
                  <tab-pane ref="pane4" class="p-2">
                    <form>
                      <div class="form-group">
                        <cell-definitions-edit-table
                            style="overflow-x: hidden; margin-bottom: 0px"
                            :columnList="analogCellDefinitionsColumns"
                            :rowList="analogCellDefinitions"
                        >
                        </cell-definitions-edit-table>
                      </div>
                    </form>
                  </tab-pane>
                </tab-content>
              </align-panel>
            </align-container>
          </form>

        </modal-body>
        <modal-footer v-if="op !== 'show'" style="z-index: 2 !important;">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import RadioProtocolsSelect from "@/components/nav/radio_protocols/RadioProtocolsSelect";
import RadioModelsSelect from "@/components/nav/radio_models/RadioModelsSelect";
import CellDefinitionsEditTable from "@/components/nav/radio/CellDefinitionsEditTable";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import TransportSelect from "@/components/ref/transport/TransportSelect";
import DepotsSelect from "@/components/ref/depots/DepotsSelect";
import {dateToSeconds} from "@/lib";

export default {
  components: {
    DepotsSelect,
    TransportSelect,
    AlignPanel, AlignContainer, CellDefinitionsEditTable, RadioModelsSelect, RadioProtocolsSelect
  },
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      radioId: this.item.radio_id,
      radioNum: this.item.radio_num,
      radioProtocolId: this.item.radio_protocol_id,
      radioGateNum: this.item.radio_gate_num,
      radioModelId: this.item.radio_model_id,
      depotId: this.item.depot_id,
      transportId: this.item.transport_id,
      radioPriority: this.item.radio_priority,
      // modems: this.item.modems,
      discreteCellDefinitions: this.item.discrete_cell_definitions ? this.item.discrete_cell_definitions : [],
      analogCellDefinitions: this.item.analog_cell_definitions ? this.item.analog_cell_definitions : [],
      isDisabled: this.item.is_disabled,
      note: this.item.note,
      firstModem: (this.item.modems && this.item.modems[0]) ? this.item.modems[0] : {
        phone_num: null,
        sim: null,
        imei: null,
        channel_num: null,
        is_income_call: null,
        is_income_sms: null
      },
      secondModem: (this.item.modems && this.item.modems[1]) ? this.item.modems[1] : {
        phone_num: null,
        sim: null,
        imei: null,
        channel_num: null,
        is_income_call: null,
        is_income_sms: null
      },
      thirdModem: (this.item.modems && this.item.modems[2]) ? this.item.modems[2] : {
        phone_num: null,
        sim: null,
        imei: null,
        channel_num: null,
        is_income_call: null,
        is_income_sms: null
      },
      currentTab: 1,
      timeIndexBegin: this.item.time_index_begin,
      timeIndexEnd: this.item.time_index_end,
      // curDate: new Date(),
      discardDate: dateToSeconds(),
    };
  },
  methods: {

    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          radio_id: this.op === 'add' ? undefined : this.radioId,
          radio_num: this.radioNum,
          radio_protocol_id: this.radioProtocolId,
          radio_gate_num: this.radioGateNum,
          radio_model_id: this.radioModelId,
          depot_id: this.depotId,
          transport_id: this.transportId,
          radio_priority: this.radioPriority,
          modems: [this.firstModem, this.secondModem, this.thirdModem],
          discrete_cell_definitions: this.discreteCellDefinitions,
          analog_cell_definitions: this.analogCellDefinitions,
          is_disabled: this.isDisabled,
          note: this.note,
          time_index_begin: this.timeIndexBegin,
          time_index_end: this.timeIndexEnd,
          discardDate: this.discardDate,
        })
      }
    },

    getDiscreteCellDefinitionsRows() {
      if (this.discreteCellDefinitions.length != 0) {
        for (let i = 0; i < 64; i++) {
          this.discreteCellDefinitions[i]['number'] = i;
          this.discreteCellDefinitions[i]['sensor_id__itemList'] = this.$store.getters['nav/getDiscreteSensors'];
          this.discreteCellDefinitions[i]['param_id__itemList'] = this.$store.getters['nav/getDiscreteParams'];
        }
      } else {
        for (let i = 0; i < 64; i++) {
          this.discreteCellDefinitions.push(
              {
                number: i,
                sensor_id: null,
                param_id: null,
                threshold: null,
                is_reverse: false,
                sensor_id__itemList: this.$store.getters['nav/getDiscreteSensors'],
                param_id__itemList: this.$store.getters['nav/getDiscreteParams'],
                is_reverse__cellStyle: {
                  verticalAlign: 'middle !important',
                },
                sensor_id__cellStyle: {
                  verticalAlign: 'middle !important',
                },
                param_id__cellStyle: {
                  verticalAlign: 'middle !important',
                },
                number__cellStyle: {
                  verticalAlign: 'middle !important',
                },
                threshold__cellStyle: {
                  verticalAlign: 'middle !important',
                }

              }
          )
        }
      }
    },

    getAnalogCellDefinitionsRows() {
      if (this.analogCellDefinitions.length != 0) {
        for (let i = 0; i < 64; i++) {
          this.analogCellDefinitions[i]['number'] = i;
          this.analogCellDefinitions[i]['sensor_id__itemList'] = this.$store.getters['nav/getAnalogSensors'];
          this.analogCellDefinitions[i]['param_id__itemList'] = this.$store.getters['nav/getAnalogParams'];
        }
      } else {
        for (let i = 0; i < 64; i++) {
          this.analogCellDefinitions.push(
              {
                number: i,
                sensor_id: null,
                param_id: null,
                sensor_id__itemList: this.$store.getters['nav/getAnalogSensors'],
                param_id__itemList: this.$store.getters['nav/getAnalogParams'],
                sensor_id__cellStyle: {
                  verticalAlign: 'middle !important',
                },
                param_id__cellStyle: {
                  verticalAlign: 'middle !important',
                },
                number__cellStyle: {
                  verticalAlign: 'middle !important',
                },
              }
          )
        }
      }
    },

  },
  computed: {

    getModalDialogClass() {
      if (this.currentTab == 3 || this.currentTab == 4) {
        return "modal-dialog-centered modal-lg modal-dialog-scrollable";
      } else {
        return "modal-dialog-centered modal-dialog-scrollable";
      }
    },

    analogCellDefinitionsColumns() {
      return [
        {
          field: "number",
          caption: "№",
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false,
          inputAttrs: {
            disabled: this.op === 'show'
          },
        },
        {
          field: "sensor_id",
          caption: "Датчик",
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'lookupInputSelect',
          isFilterable: false,
          isSortable: false,
          inputAttrs: {
            caption: "Выберите датчик...",
            disabled: this.op === 'show',
            isOverflowActive: true
          },
          inputStyle: {
            height: '30px',
          }
        },
        {
          field: "param_id",
          caption: "Параметр",
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'lookupInputSelect',
          isFilterable: false,
          isSortable: false,
          inputAttrs: {
            caption: "Выберите параметр...",
            disabled: this.op === 'show',
            isOverflowActive: true
          },
          inputStyle: {
            height: '30px',
          }
        },
      ]
    },

    discreteCellDefinitionsColumns() {
      return [
        {
          field: "number",
          caption: "№",
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false,
          inputAttrs: {
            disabled: this.op === 'show'
          },
        },
        {
          field: "sensor_id",
          caption: "Датчик",
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'lookupInputSelect',
          isFilterable: false,
          isSortable: false,
          inputAttrs: {
            caption: "Выберите датчик...",
            disabled: this.op === 'show',
            isOverflowActive: true
          },
          inputStyle: {
            height: '30px',
          }
        },
        {
          field: "param_id",
          caption: "Параметр",
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'lookupInputSelect',
          isFilterable: false,
          isSortable: false,
          inputAttrs: {
            caption: "Выберите параметр...",
            disabled: this.op === 'show',
            isOverflowActive: true
          },
          inputStyle: {
            height: '30px',
          }
        },
        {
          field: "threshold",
          displayType: "float",
          caption: "Порог срабатывания",
          dataAlign: 'center',
          headerAlign: 'center',
          isFilterable: false,
          isSortable: false,
          inputAttrs: {
            disabled: this.op === 'show'
          },
        },
        {
          field: "is_reverse",
          caption: "Обр. сраб.",
          isSortable: false,
          sortType: 'boolean',
          displayType: 'checkbox',
          headerAlign: 'center',
          dataAlign: 'center',
          inputAttrs: {
            disabled: this.op === 'show'
          },
        },
      ]
    },
  },

  created() {
    this.getDiscreteCellDefinitionsRows();
    this.getAnalogCellDefinitionsRows();
  }

};
</script>

<style scoped>
form label {
  font-weight: bold;
}

</style>