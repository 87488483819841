<template>
  <align-container>
    <align-panel align="top">
      <page-header>
        <span><router-link to="/kvr">Диспетчерская</router-link></span>
        <span> / </span>
        <span><router-link to="/kvr/workTasks">Диспетчер системы</router-link></span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <!--    разделяем страницу на верхнюю и нижнюю часть  -->
      <align-container>
        <align-panel align="top" height="80%" :gap="3">

          <align-container>
            <align-panel align="top" :gap="3">
              <!-- Форма с кнопками над верхней таблицей-->
              <card class="text-left">
                <card-body class="p-1">
                  <div class="form-row">

                    <div class="col-0" style="margin-bottom: 0 !important;">
                      <input-date
                          id="startDate"
                          v-model="selectedDate"
                          class="form-control"
                          style="max-width: 150px; max-height: 31px;"
                          @change="refreshData"
                      >
                      </input-date>
                    </div>

                    <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                      <input
                          type="text"
                          class="form-control"
                          id="inlineFormInput"
                          placeholder="Фильтр..."
                          style="max-height: 31px; font-size: 14px;"
                          @input="onFilterChanged"
                      />
                    </div>

                    <!-- Кнопка Отчеты-->
                    <div v-if="currentTab == 1" class="col-0" style="margin-bottom: 0 !important;">
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onReports"
                          title="Отчеты"
                          disabled
                      >
                        Отчеты
                      </button>
                    </div>

                    <!-- Выпадающий список фильтр парков -->
                    <div v-if="currentTab == 1" class="col-0" style="margin-bottom: 0 !important;">
                      <input-select
                          class="form-control"
                          style="width: 160px; max-height: 31px; padding: 0;"
                          v-model="selectedParkId"
                          :itemList="depotsArr"
                          caption="Выбор парка..."
                      ></input-select>
                    </div>

                    <!-- Выпадающий список На карту -->
                    <div v-if="currentTab == 1" class="col-0">
                      <dropdown>
                        <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                        style="max-height: 31px; font-size: 14px; padding-bottom: 10px; padding-top: 4px"
                                        disabled>
                          На карту
                        </button-toggler>
                        <dropdown-menu ref="collapse">
                          <dropdown-item>ТС
                          </dropdown-item>
                          <dropdown-item>Маршрут
                          </dropdown-item>
                        </dropdown-menu>
                      </dropdown>
                    </div>

                    <!-- Кнопка На связь -->
                    <div v-if="currentTab == 1" class="col-0">
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onConnection"
                          title="На связь"
                          disabled
                      >
                        На связь
                      </button>
                    </div>

                    <!-- Выпадающий список Отправка сообщения -->
                    <div v-if="currentTab == 1" class="col-0">
                      <dropdown>
                        <button-toggler target-ref="collapse1" class="btn dropdown-toggle btn-primary"
                                        style="max-height: 31px; font-size: 14px; padding-bottom: 10px; padding-top: 4px"
                                        disabled>
                          Отправка сообщения
                        </button-toggler>
                        <dropdown-menu ref="collapse1">
                          <dropdown-item>Формализованное
                          </dropdown-item>
                          <dropdown-item>Неформализованное
                          </dropdown-item>
                        </dropdown-menu>
                      </dropdown>
                    </div>

                    <!-- Кнопка Отметки по водителю -->
                    <div v-if="currentTab == 1" class="col-0">
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onConnection"
                          title="Отметки по водителю"
                          disabled
                      >
                        Отметки по водителю
                      </button>
                    </div>

                    <!-- Кнопка Отметки по выходу -->
                    <div v-if="currentTab == 1" class="col-0">
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onMonitoringTaskCardDialog"
                          title="Отметки по выходу"
                          :disabled="!selectedMonitoringTask"
                      >
                        Отметки по выходу
                      </button>
                    </div>

                    <div class="col">

                    </div>

                    <div class="col-0" style="margin-bottom: 0 !important;">
                      <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          @click="refreshData"
                          title="Обновить"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                    </div>
                  </div>
                </card-body>
              </card>

            </align-panel>

            <align-panel v-if="currentTab == 1" align="all">

              <dispatching-panel
                  :monitoringTasks="rows"
                  :selectedRow="selectedMonitoringTask"
                  @onSelectedRow="setSelectedMonitoringTask"
                  @onMonitoringTaskCardDialog="onMonitoringTaskCardDialog"
              ></dispatching-panel>

            </align-panel>

            <align-panel v-if="currentTab == 5" align="all">

              <management-journal-panel
                  :rows="managementJournalRows"
                  :selected_date="selectedDate"
              >
              </management-journal-panel>

            </align-panel>

            <align-panel align="bottom">
              <card class="text-left">
                <nav-menu class="nav-pills" mode="single">
                  <nav-item>
                    <nav-link target-ref="pane1" target-method="select" @click="currentTab = 0">Нарушения
                    </nav-link>
                  </nav-item>
                  <nav-item>
                    <nav-link target-ref="pane2" target-method="select" active @click="currentTab = 1">Текущий Наряд
                    </nav-link>
                  </nav-item>
                  <nav-item>
                    <nav-link target-ref="pane3" target-method="select" @click="currentTab = 2">Изменение наряда
                    </nav-link>
                  </nav-item>
                  <nav-item>
                    <nav-link target-ref="pane4" target-method="select" @click="currentTab = 3">События</nav-link>
                  </nav-item>
                  <nav-item>
                    <nav-link target-ref="pane2" target-method="select" @click="currentTab = 4">Резерв</nav-link>
                  </nav-item>
                  <nav-item>
                    <nav-link target-ref="pane3" target-method="select" @click="currentTab = 5">Архив Управления
                    </nav-link>
                  </nav-item>
                  <nav-item>
                    <nav-link target-ref="pane4" target-method="select" @click="currentTab = 6">Отметки на ККП
                    </nav-link>
                  </nav-item>
                  <nav-item>
                    <nav-link target-ref="pane2" target-method="select" @click="currentTab = 7">Отметки в парке
                    </nav-link>
                  </nav-item>
                  <nav-item>
                    <nav-link target-ref="pane3" target-method="select" @click="currentTab = 8">Открытие/Закрытие
                    </nav-link>
                  </nav-item>
                </nav-menu>
              </card>
            </align-panel>
          </align-container>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <align-panel align="top" :gap="3">
              <!-- Форма с кнопками над нижней таблицей-->
              <card class="text-left">
                <card-body class="p-1">
                  <div class="form-row">
                    <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                      <p style="margin: 0px; font-size: 0.8rem;">Нет навигации от ТС, открывающего движение; Нет отметки
                        в зоне ККП от ТС, открывающего движение.;</p>
                    </div>
                    <div class="col"></div>
                  </div>
                </card-body>
              </card>
            </align-panel>
            <align-panel align="all">

            </align-panel>

          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <monitoring-task-card-dialog
      v-if="isMonitoringTaskCardDialogVisible"
      :selectedMonitoringTask="selectedMonitoringTask"
      @onCancel="onRejectOperation"
      @onLoading="onLoading"
  ></monitoring-task-card-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import {
  dateToND,
  dateToString,
  monitoringTasksSortFn,
  quickSort,
  stringToDate,
} from "@/lib";
import PageMixin from "@/pages/share/PageMixin";
import {getManageDepotTrn, getManageRouteTrn} from "@/store/TrnHelpers";
import DispatchingPanel from "@/components/trn/dispatching/DispatchingPanel";
import MonitoringTaskCardDialog from "@/components/trn/dispatching/MonitoringTaskCardDialog";
import ManagementJournalPanel from "@/components/trn/dispatching/ManagementJournalPanel";

export default {
  name: "informationByTsPage",
  mixins: [PageMixin],
  components: {
    ManagementJournalPanel,
    DispatchingPanel,
    MonitoringTaskCardDialog,
    AlignPanel, AlignContainer
  },
  props: [
    // параметр отображения, передается через роутер
    'op',
    //
    'monitoring_task_id'
  ],
  data() {
    return {
      // отображать спиннер
      isLoading: false,
      // выбранная вкладка
      currentTab: 1,
      // массив парков для фильтра
      parks_arr: [],
      // выбранный парк
      selectedParkId: null,
      // выбранная дата
      selectedDate: dateToString(),
      // строки пришедшие с сервера
      monitoringTasks: [],
      // строки журнала
      managementJournalRows: [],
      // выбранная строка
      selectedMonitoringTask: null,
    }
  },

  watch: {

    currentTab() {
      this.refreshData();
    }

  },

  computed: {

    isMonitoringTaskCardDialogVisible() {
      return (this.op === 'monitoringTaskCard' && this.selectedMonitoringTask);
    },

    depotsArr() {
      if (this.parks_arr.length != 0) {
        return this.parks_arr.map(value => {
          let park = getManageDepotTrn(value);
          return {
            id: value,
            title: park ? park.long_name : '',
          }
        });
      }
      return [];
    },

    rows() {
      let arr = [];
      this.monitoringTasks.forEach(value => {

        // если выбран парк
        if (this.selectedParkId) {
          if (this.selectedParkId != value.fact_depot_id) {
            return
          }
        }

        // делаем фильтрацию по тексту
        // str = String(item).toLowerCase();
        // str.includes(this.filterText.toLowerCase());
        arr.push(value);
      });

      return arr;
    }

  },

  methods: {

    onLoading(value) {
      this.isLoading = value;
    },

    setSelectedMonitoringTask(item) {
      this.selectedMonitoringTask = item;
    },

    clearSelection() {
      this.selectedRow = null;
    },

    onMonitoringTaskCardDialog() {
      this.$router.push('/trn/dispatching/monitoringTaskCard/' + this.selectedMonitoringTask.monitoring_task_id);
    },

    onRejectOperation() {
      this.$router.push('/trn/dispatching');
    },

    async refreshData() {
      this.isLoading = true;
      try {
        if (this.currentTab == 1) {
          // Запрос предприятий
          await this.$store.dispatch('trn/doFetchManageDepots');
          // Запрос маршрутов
          await this.$store.dispatch('trn/doFetchManageRoutes');
          // Запрос ТС
          await this.$store.dispatch('trn/doFetchManageVehicles', {
            date_index: dateToND(stringToDate(this.selectedDate)),
            force: true,
          });
          // Запрос водителей
          await this.$store.dispatch('trn/doFetchManageDrivers', {
            date_index: dateToND(stringToDate(this.selectedDate)),
            force: true,
          });
          // загружаем строки таблицы
          let rows = await this.$store.dispatch('trn/doFetchMonitoringTasks', {
            date_index: dateToND(stringToDate(this.selectedDate)),
            force: true,
          });

          rows.forEach(value => {
            // формируем список парков
            if (!this.parks_arr.includes(value.plan_depot_id)) {
              this.parks_arr.push(value.plan_depot_id);
            }

            // вносим номер маршрута
            let route = getManageRouteTrn(value.route_id);
            if (route)
              value.__route_num = route.route_num
          })

          // сортируем полученный массив строк
          quickSort(rows, (leftItem, rightItem) => {
            return 1 * monitoringTasksSortFn(leftItem, rightItem);
          });

          this.monitoringTasks = rows;

        } else if (this.currentTab == 5) {

          let rows = await this.$store.dispatch('trn/doFetchManagementJournal', {
            date_index: dateToND(stringToDate(this.selectedDate)),
          });

          if(rows)
            this.managementJournalRows = rows

        }

        this.selectedRow = null;
      } finally {
        this.isLoading = false;
      }
    }

  },

  async created() {
    if (this.monitoring_task_id) {
      this.$router.replace('/trn/dispatching');
    }
    await this.refreshData();
    await this.$store.dispatch('trn/doFetchManagementActions');
  },


}
</script>

<style scoped>

.nav-link {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

</style>
