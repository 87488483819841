import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ПРОФИЛЯ ПОЛЬЗОВАТЕЛЯ

export default {
    state() {
        return {
            // профиль пользователя
            profile: {},
            // время обновления кэша new Date().getTime()
            profileCache: null,
            // время жизни кэша в мс (null - бесконечно)
            profileCacheTimeout: null
        }
    },
    getters: {
        // вернуть профиль пользователя
        getProfile(state) {
            return state.profile;
        }
    },
    mutations: {
        // установить профиль пользователя
        setProfile(state, payload) {
            state.profile = payload;
            state.profileCache = new Date().getTime();
        }
    },
    actions: {
        doFetchProfile: (context, payload) => {
            return DoGet(
                '/auth/profile',
                'auth/setProfile',
                {},
                'Запрос профиля пользователя',
                false,
                payload && payload.force,
                context.state.profileCache,
                context.state.profileCacheTimeout
            )
        },

        // очищает кэш
        doClearProfile: (context, payload) => {
            return DoClearCache(
                'auth/setProfile',
                {},
                payload && payload.force,
                context.state.profileCache,
                context.state.profileCacheTimeout
            )
        },
    }
}