import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ГРУПП ВИДОВ ТРАНСПОРТА

export default {
    state() {
        return {
            // список объектов
            transportTypeGroups: [],
            // время обновления кэша new Date().getTime()
            transportTypeGroupsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            transportTypeGroupsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getTransportTypeGroups(state) {
            return state.transportTypeGroups;
        },
    },
    mutations: {
        // установить новый список объектов
        setTransportTypeGroups(state, payload) {
            state.transportTypeGroups = payload;
            state.transportTypeGroupsCache = new Date().getTime()
        },
        // добавить объект
        addTransportTypeGroup(state, payload) {
            state.transportTypeGroups.push(payload)
        },
        // изменить объект
        editTransportTypeGroup(state, payload) {
            const index = state.transportTypeGroups.findIndex(group => group.transport_type_group_id == payload.transport_type_group_id);
            if (index >= 0) {
                state.transportTypeGroups[index] = payload;
            }
        },
        // удалить объект
        delTransportTypeGroup(state, payload) {
            const index = state.transportTypeGroups.findIndex(group => group.transport_type_group_id == payload.transport_type_group_id);
            state.transportTypeGroups.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchTransportTypeGroups: (context, payload) => {
            return DoGet(
                '/ref/transportTypeGroups',
                'ref/setTransportTypeGroups',
                [],
                'Запрос групп видов транспорта',
                false,
                payload && payload.force,
                context.state.transportTypeGroupsCache,
                context.state.transportTypeGroupsCacheTimeout
            )
        },

        // очищает кэш
        doClearTransportTypeGroups: (context, payload) => {

            return DoClearCache(
                'ref/setTransportTypeGroups',
                [],
                payload && payload.force,
                context.state.transportTypeGroupsCache,
                context.state.transportTypeGroupsCacheTimeout
            )
        },

        // добавление объекта
        doAddTransportTypeGroup: (context, payload) => {
            return DoPost(
                '/ref/transportTypeGroups',
                payload,
                'ref/addTransportTypeGroup',
                'Добавление группы видов транспорта'
            )
        },

        // изменение объекта
        doEditTransportTypeGroup: (context, payload) => {
            return DoPut(
                '/ref/transportTypeGroups/'+payload.transport_type_group_id,
                payload,
                'ref/editTransportTypeGroup',
                'Изменение группы видов транспорта'
            )
        },

        // удаление объекта
        doDelTransportTypeGroup: (context, payload) => {
            return DoDelete(
                '/ref/transportTypeGroups/'+payload.transport_type_group_id,
                'ref/delTransportTypeGroup',
                'Удаление группы видов транспорта'
            )
        }
    }
}