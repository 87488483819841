<template>
  <!-- Карта-->
  <leaflet-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
  >

    <!-- Элементы управления-->
    <leaflet-control-group>
      <!-- Базовые слои-->
      <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
    </leaflet-control-group>

    <!--  Зона остановки -->
    <base-zone
        v-for="(zone) in stopZones"
        :key="zone.zone_id"
        :zone="zone.stop_zone"
    >
    </base-zone>

    <!-- Линия типа рейса-->
    <trip-type-line-polyline
        v-for="(line) in lines"
        :key="line.polyline_id"
        :polyline="line"
        :originalOptions="{
          color: line.direction == 0 ? 'red' : 'blue',
          opacity: 1.0,
          draggable: false,
          weight: 5.0,
        }"
        :position="line.direction == 0 ? 'front' : 'back'"
    >
    </trip-type-line-polyline>

    <!--  Обычные остановки-->
    <stop-marker
        v-for="stop in stops"
        :key="stop.stop_id"
        :stop="stop"
        :tooltip="stop.long_name + ' (#'+ stop.stop_id+')'"
        type="0"
        state="original"
    ></stop-marker>

    <!--     ТС на карте-->
    <vehicle-marker
        v-for="vehicle in vehiclesOnMap"
        :vehicle="vehicle"
        :key="vehicle.transport_id"
        color="2"
        events="dblclick"
        @onLeafletEvent="onLeafletEvent($event, vehicle)"
        :titleType="tooltip"
    ></vehicle-marker>

  </leaflet-map>
</template>

<script>

import {dateToND} from "@/lib";

export default {
  // список ТС
  props: [
    'vehiclesOnMap',
    'routeTripTypeList',
    'selectedRoutesStopZones',
    'selectedReisDirections',
    'selectedMarshLines',
    'inscriptions'
  ],

  watch: {

    routeTripTypeList() {
      this.selectedTripTypes();
    },

    selectedRoutesStopZones() {
      this.selectedRouteStopZone();
    },

  },

  // список событий
  emits: ['openVehicleInfoDialog'],
  //
  computed: {

    //получаем тип надписей
    tooltip() {
      if (this.inscriptions == 0) {
        return 'none';
      } else if (this.inscriptions == 1) {
        return 'special';
      } else if (this.inscriptions == 2) {
        return 'garage';
      } else if (this.inscriptions == 3) {
        return 'state';
      }
      return ''
    },

    // получаем Линии
    lines() {
      let arr = [];
      for (let i = 0; i < this.routeTripTypeList.length; i++) {
        let route_id = this.routeTripTypeList[i].route_id;
        let tripTypes = [];
        tripTypes = this.$store.getters['kvr/getRouteTripTypeList'][route_id];
        if (tripTypes != undefined && tripTypes.trip_types && tripTypes.trip_types.length != 0) {
          // не отображаем линии, если в меню сверху выбрано не отображать линии
          tripTypes.trip_types.forEach(value => {
            if (this.selectedReisDirections == value.trip_type.direction || this.selectedReisDirections == 2) {
              value.trip_type_line['direction'] = value.trip_type.direction;
              if (this.selectedMarshLines == 1 && value.trip_type.trip_type_sign.substring(0, 2) == "00") {
                arr.push(value.trip_type_line);
              } else if (this.selectedMarshLines == 2 && value.trip_type.trip_type_sign.substring(0, 2) != "00") {
                arr.push(value.trip_type_line);
              } else if (this.selectedMarshLines == 3) {
                arr.push(value.trip_type_line);
              }
            }
          });
        }
      }
      return arr;
    },

    // получаем остановки
    stops() {
      let arr = [];
      for (let i = 0; i < this.routeTripTypeList.length; i++) {
        let route_id = this.routeTripTypeList[i].route_id;
        let tripTypes = [];
        tripTypes = this.$store.getters['kvr/getRouteTripTypeList'][route_id];
        if (tripTypes != undefined && tripTypes.trip_types && tripTypes.trip_types.length != 0) {
          // не отображаем линии, если в меню сверху выбрано не отображать линии
          tripTypes.trip_types.map(value => {
            // проверяем, что остановки принадлежат отображаемому направлению
            if (this.selectedReisDirections == value.trip_type.direction || this.selectedReisDirections == 2) {
              // отображаем только остановки принадлежащие отображаемым рейсам
              if (this.selectedMarshLines == 1 && value.trip_type.trip_type_sign.substring(0, 2) == "00") {
                if(value.trip_type_card) {
                  // находим остановки принадлежащие отображаемому рейсу
                  value.trip_type_card.map(card => {
                    let st = tripTypes.stops.find(stop => stop.stop_id == card.stop_id);
                    if (st) {
                      if (!arr.find(stop => stop.stop_id == st.stop_id)) {
                        arr.push(st);
                      }
                    }
                  });
                }
              } else if (this.selectedMarshLines == 2 && value.trip_type.trip_type_sign.substring(0, 2) != "00") {
                if(value.trip_type_card) {
                  // находим остановки принадлежащие отображаемому рейсу
                  value.trip_type_card.map(card => {
                    let st = tripTypes.stops.find(stop => stop.stop_id == card.stop_id);
                    if (st) {
                      if (!arr.find(stop => stop.stop_id == st.stop_id)) {
                        arr.push(st);
                      }
                    }
                  });
                }
              } else if (this.selectedMarshLines == 3) {
                  if(value.trip_type_card) {
                  // находим остановки принадлежащие отображаемому рейсу
                  value.trip_type_card.map(card => {
                    let st = tripTypes.stops.find(stop => stop.stop_id == card.stop_id);
                    if (st) {
                      if (!arr.find(stop => stop.stop_id == st.stop_id)) {
                        arr.push(st);
                      }
                    }
                  });
                }
              }
            }
          });
        }
      }
      return arr;
    },

    // получаем зоны
    stopZones() {
      let stopsArr = [];
      for (let i = 0; i < this.routeTripTypeList.length; i++) {
        let route_id = this.routeTripTypeList[i].route_id;
        let tripTypes = [];
        tripTypes = this.$store.getters['kvr/getRouteTripTypeList'][route_id];
        if (tripTypes != undefined && tripTypes.trip_types && tripTypes.trip_types.length != 0) {
          // определяем какие остановки попадают на карту
          tripTypes.trip_types.map(value => {
            // проверяем, что остановки принадлежат отображаемому направлению
            if (this.selectedReisDirections == value.trip_type.direction || this.selectedReisDirections == 2) {

              if (this.selectedMarshLines == 1 && value.trip_type.trip_type_sign.substring(0, 2) == "00") {
                if(value.trip_type_card) {
                  value.trip_type_card.map(card => {
                    let st = tripTypes.stops.find(stop => stop.stop_id == card.stop_id);
                    if (st) {
                      if (!stopsArr.find(stop => stop.stop_id == st.stop_id)) {
                        stopsArr.push(st);
                      }
                    }
                  });
                }
              } else if (this.selectedMarshLines == 2 && value.trip_type.trip_type_sign.substring(0, 2) != "00") {
                if(value.trip_type_card) {
                  value.trip_type_card.map(card => {
                    let st = tripTypes.stops.find(stop => stop.stop_id == card.stop_id);
                    if (st) {
                      if (!stopsArr.find(stop => stop.stop_id == st.stop_id)) {
                        stopsArr.push(st);
                      }
                    }
                  });
                }
              } else if (this.selectedMarshLines == 3) {

                if(value.trip_type_card) {
                  value.trip_type_card.map(card => {
                    let st = tripTypes.stops.find(stop => stop.stop_id == card.stop_id);
                    if (st) {
                      if (!stopsArr.find(stop => stop.stop_id == st.stop_id)) {
                        stopsArr.push(st);
                      }
                    }
                  });
                }
              }
            }
          });
        }
      }
      let zonesArr = [];
      stopsArr.forEach(stop => {
        let zone = this.selectedStopZonesArr.find(zn => stop.stop_id == zn.stop_id);
        if (zone)
          zonesArr.push(zone);
      });
      return zonesArr;
    },

    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom']
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter']
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },
  },

  data() {
    return {
      selectedTripTypesArr: [],
      selectedStopZonesArr: [],
      // карта
      leaflet: null,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // границы карты
      minLat: 0,
      maxLat: 0,
      minLong: 0,
      maxLong: 0,
      // текущий масштаб
      curZoom: 0,
      // объект со слоями для элемента управления
      baseLayersObj: {},
      // нет контекстного меню остановки
      noContextMenu: {
        contextmenu: false,
      },
      vehicle: {
        latitude: 55.725937300000005,
        longitude: 37.65464410000001,
        angle: 45
      }
    }
  },

  methods: {

    // возвращает список выбранных зон маршрутов
    async selectedTripTypes() {
      this.selectedTripTypesArr = [];
      for (let i = 0; i < this.routeTripTypeList.length; i++) {
        let route_id = this.routeTripTypeList[i].route_id;
        let tripTypes = [];
        tripTypes = this.$store.getters['kvr/getRouteTripTypeList'][route_id];
        if (tripTypes == undefined) {
          await this.$store.dispatch('kvr/doFetchRouteTripTypeListFiltered', {
            date_index: dateToND(),
            route_id: route_id,
            force: true,
          });
        }
      }
    },

    // возвращает список выбранных зон маршрутов
    async selectedRouteStopZone() {
      this.selectedStopZonesArr = [];
      let arr = []
      for (let i = 0; i < this.selectedRoutesStopZones.length; i++) {
        let route_id = this.selectedRoutesStopZones[i].route_id;
        if (this.selectedRoutesStopZones[i].__isZones) {
          let zones = [];
          zones = this.$store.getters['kvr/getRouteStopZoneList'][route_id];
          if (zones != undefined) {
            if (zones.stop_zones) {
              zones.stop_zones.map(value => {
                arr.push(value);
              });
            }
          } else {
            let zonesFromServer = await this.$store.dispatch('kvr/doFetchRouteStopZoneList', {
              date_index: dateToND(),
              route_id: route_id,
              force: true,
            });
            if (zonesFromServer.stop_zones) {
              zonesFromServer.stop_zones.map(value => {
                arr.push(value);
              });
            }
          }
        }
      }

      this.selectedStopZonesArr = arr;
    },

    // вызывается при создании базового слоя
    onBaseLayerCreated(layer) {
      this.$nextTick(() => {
        this.baseLayersObj = {
          ...this.baseLayersObj,
          [layer.title]: layer.origin
        }
      })
    },

    // срабатывает при щелчке на ТС
    onLeafletEvent(event, vehicle) {
      // вызываем событие при двойном щелчке на ТС
      if (event.type === 'dblclick') {
        this.$emit('openVehicleInfoDialog', vehicle)
      }
    },

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;

      // функция определяет границы карты и текущий zoom
      const getMapValues = () => {
        // определяем границы карты
        const bounds = this.leaflet.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        this.minLat = southWest.lat;
        this.maxLat = northEast.lat;
        this.minLong = southWest.lng;
        this.maxLong = northEast.lng;
        // определяем текущий zoom
        this.curZoom = leaflet.getZoom();
      }

      getMapValues();
      leaflet.on('moveend', () => {
        getMapValues();
      });
    },

  },
}

</script>
