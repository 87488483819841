<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white" style="background-color: #343a40">
          <modal-title>Разделение наряда на работу</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="splitTime">Время разделения:</label>
              <req-field/>
              <input-time
                  id="splitTime"
                  class="form-control w-25"
                  :class="{ 'is-invalid': !isTimeValid }"
                  error="Время разделения должно соответствовать времени наряда"
                  @focus="onFocus"
                  v-model="splitTime"
              ></input-time>
            </div>
            <div class="form-group">
              <label for="newTransportId">Новое ТС:</label>
              <order-vehicles-select
                  id="newTransportId"
                  class="form-control"
                  :class="{ 'is-invalid': !isFormValid }"
                  error="Не допускается выбор водителя, без выбора ТС"
                  @focus="onFocus"
                  :depot_id="selectedManualOrder.fact_depot_id"
                  v-model="newTransportId"
              ></order-vehicles-select>
            </div>
            <div class="form-group">
              <label for="newTransportId">Новый водитель:</label>
              <order-drivers-select
                  id="newDriverId"
                  class="form-control"
                  :depot_id="selectedManualOrder.fact_depot_id"
                  v-model="newDriverId"
              ></order-drivers-select>
            </div>
          </form>
        </modal-body>
        <modal-footer>
          <button @click="onSubmit" class="btn btn-primary">Разделить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import OrderVehiclesSelect from "@/components/kvr/manual_orders/OrderVehiclesSelect";
import OrderDriversSelect from "@/components/kvr/manual_orders/OrderDriversSelect";

export default {
  components: {OrderDriversSelect, OrderVehiclesSelect},
  emits: ['onOK', 'onCancel'],
  props: ['selectedManualOrder'],
  data() {
    return {
      // время разделения
      splitTime: "",
      // id нового ТС
      newTransportId: null,
      // id нового водителя
      newDriverId: null,
      // отображение ошибки связанной со временем разделения (когда введенное время не входит в интервал обозначенный в наряде)
      isTimeValid: true,
      // отображение ошибки связанной с тем, что выбран водитель, но не выбрана ТС
      isFormValid: true,
    };
  },

  methods: {
    // проверить форму на ошибки
    validateForm() {
      this.isTimeValid = true;
      this.isFormValid = true;
      // добавляем ко времени количество секунд с 01.01.2020 для корректного сравнения
      let daysInSeconds = this.selectedManualOrder.date_index * 24 * 60 * 60;
      // суммируем время с начала дня плюс количество дней с 01.01.2020 в секундах.
      let summSeconds = daysInSeconds + this.splitTime;
      // проверяем введенное время, оно должно быть в интервале, указанном в наряде
      if ((summSeconds < (this.selectedManualOrder.time_index_begin)) || (summSeconds > this.selectedManualOrder.time_index_end)) {
        this.isTimeValid = false;
        return false;
      }
      // проверяем, что в случае выбора водителя выбрано и ТС. Не допускается выбор водителя без ТС
      if (!this.newTransportId && this.newDriverId) {
        this.isFormValid = false;
        return false;
      }
      return true;
    },

    onFocus() {
      // сбрасываем признак проверки (при фокусировки любого элемента)
      this.isTimeValid = true;
    },
    onSubmit() {
      // проверяем форму
      if (this.validateForm()) {
        // формируем событие
        this.$emit('onOK', {
          split_time_index: this.splitTime + (this.selectedManualOrder.date_index * 24 * 60 * 60),
          new_transport_id: this.newTransportId,
          new_driver_id: this.newDriverId,
        });
      }
    },
  },

  computed: {
  }
};
</script>
