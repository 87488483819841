<template>
  <line-schedule-ruler-s-v-g
      :hoursArray="hoursArray"
      :oneDiv="oneDiv"
      :start_x="start_x"
      :start_y="start_y"
      :width="width"
      :height="rulerHeight"
      :intervals="reisStartLinesText"
      :intervalLines=getIntervalLines(0)
      @updateWidth="updateWidth"
      style="position: sticky; top: 0"
  >
  </line-schedule-ruler-s-v-g>

  <div :style="' overflow-y: hidden; overflow-x: hidden; width: ' + width + 'px'">
    <line-schedule-graph-s-v-g
        v-for="(graph, index) in schedulesArr"
        :id="'svg' + index"
        :key="index"
        :graph="graph.trip_table_columns"
        :graph_num="graph.trip_table_columns[0].trip_complex.graph"
        :hoursArray="hoursArray"
        :height="height"
        :width="width"
        :start_x="start_x"
        :oneDiv="oneDiv"
        :intervalLines="getIntervalLines(index)"
        @mousemove="mousemoveEvent"
        @click="onClick($event, index)"
    >
    </line-schedule-graph-s-v-g>
  </div>

  <line-graph-dialog
      v-if="!!selectedReis"
      :columnItem="selectedReis"
      :lineGraphDialogStyle="lineGraphDialogStyle"
      :tripTypeCards="tripTypeCards"
      v-click-away="hideDialog"
      @doDelLeftTrip="doDelLeftTrip"
      @doMinusMin="doMinusMin"
      @doPlusMin="doPlusMin"
      @doEditStop="doEditStop"
      @doExtensionTrafficGraph="doExtensionTrafficGraph"
      @startTrafficGraph="startTrafficGraph"
      @openTripTypeDialog="openTripTypeDialog"
      @doEditTripBegin="doEditTripBegin"
  >
  </line-graph-dialog>

  <release-from-park-dialog
      v-if="isRealeaseDialogVisible"
      id="release_from_park_dialog"
      :isDialogVisible="isRealeaseDialogVisible"
      :dialogStyle="realeaseDialogStyle"
      :dialogSpecifiedTrip="dialogSpecifiedTrip"
      :direction="direction"
      :dialogTitle="dialogTitle"
      :tripTypes="tripTypes"
      @hideDialog="hideReleaseDialog"
      @setGraph="setGraph"
  >
  </release-from-park-dialog>

  <appointment-dialog
      v-if="isAppointmentDialogVisible"
      id="appointment_dialog"
      :direction="direction"
      :dialogStyle="appointmentDialogStyle"
      :tripTypes="tripTypes"
      :dialogSpecifiedTrip="dialogSpecifiedTrip"
      @hideDialog="hideAppointmentDialog"
      @changeTripType="changeTripType"
  >
  </appointment-dialog>

</template>

<script>
import LineScheduleGraphSVG from "@/components/ui/svg/line_schedule/LineSheduleGraphSVG";
import LineScheduleRulerSVG from "@/components/ui/svg/line_schedule/LineScheduleRulerSVG";
import LineGraphDialog from "@/components/rmtgen/LineGraphDialog";
import {directive} from "vue3-click-away";
import ReleaseFromParkDialog from "@/components/rmtgen/ReleaseFromParkDialog";
import AppointmentDialog from "@/components/rmtgen/AppointmentDialog";
import {getSignTitle} from "@/lib";


export default {
  name: "LineScheduleGraphPanel",
  components: {
    ReleaseFromParkDialog,
    AppointmentDialog, LineGraphDialog, LineScheduleRulerSVG, LineScheduleGraphSVG
  },
  props: ['schedule_graph_set_id', 'schedulesArr', 'tripTypeCards', 'route_variant_id', 'tripTypes', 'hoursArray', 'cpArr', 'selectedDirection'],
  emits: ['doDelLeftTrip', 'doStartTrafficGraph', 'doEditGraph', 'doMinusMin', 'doEditTripBegin', 'doEditStop', 'doPlusMin', 'doExtensionTrafficGraph', 'doChangeTripType'],
  directives: {
    ClickAway: directive
  },
  data() {
    return {
      // массив часов для верхней шкалы
      /*hoursArray: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        //24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52
      ],*/
      // ширина графика
      width: 6000,
      // высота графика
      height: 25,
      // высота "полоски с линейкой времени"
      rulerHeight: 55,
      // отступ слева начала линейки часов и он же задает начальную точку отсчета линии графика
      start_x: 5,
      // высота линии графика; от нее в компонентах также может отсчитываться местоположение подписей к графику
      start_y: 32,
      // сколько пикселей занимает одно деление линейки вверху графика (соотв. 6 минут)
      oneDiv: 3,

      // выбранный рейс для отображения в линейном графике
      selectedReis: null,
      // стиль для задания параметров отображения диалога
      lineGraphDialogStyle: null,
      // отступ слева для окна редактирования
      leftValue: null,
      // стиль для задания параметров отображения диалога выпуск из парка
      realeaseDialogStyle: null,
      // видимость диалога Выпуск из парка
      isRealeaseDialogVisible: null,
      //
      appointmentDialogStyle: null,
      // видимость диалога Выпуск из парка
      isAppointmentDialogVisible: null,
      // название диалога
      dialogTitle: 'Выпуск из парка',
      // направление выбранной ячейки
      direction: '',
      // настройки диалога
      dialogSpecifiedTrip: null,
      // линии временных интервалов, отображенные согласно матрице
      intervalsLines: [],
    }
  },

  computed: {

    // фильтруем данные, чтобы отображение линии начала рейсов соответствовало выбранному в матрице
    reisStartLinesText() {
      let selectedCP = [];
      // сначала необходимо определить какие КП выбраны, для этого кладем их в массив в виде сущности
      // {trip_type_sign: выбранный тип рейса, percent: процент выбранного КП, по которому будет производиться расчет}
      //

      this.cpArr.forEach(cp => {
        Object.keys(cp).forEach(key => {
          if (cp.tripTypes.includes(key) && cp[key] == true) {
            selectedCP.push({
              trip_type_sign: key,
              percent: cp.percent,
              direction: cp.direction
            })
          }
        })
      });

      return this.directionLines(selectedCP);
    }

  },

  methods: {

    getIntervalLines(row_num) {
      let arr = [];
      // в зависимости от номера строки отрисовываем линии интервалов
      let keysArr = Object.keys(this.intervalsLines);
      for (let i = Object.keys(this.intervalsLines).length - 1; i >= row_num ; i--) {
        this.intervalsLines[keysArr[i]].forEach(value => {
          arr.push(value);
        });
      }

      return arr;
    },

    directionLines(selectedCPArr) {
      // данные по началу рейсов direction 1
      let res = {};
      let result = [];
      let arr = {};
      // if (direction == 1) {
      //   // обнуляем оранжевые линии направления 1
      //   this.matrixLines1 = [];
      // } else {
      //   // обнуляем оранжевые линии направления 0
      //   this.matrixLines0 = [];
      // }

      if (selectedCPArr.length == 0) {
        return [];
      }

      // для нахождения время между рейсами необходимо
      for (let i = 0; i < this.schedulesArr.length; i++) {
        // для начала рассчитываем стоянки для рейсов
        selectedCPArr.forEach(value => {

          for (let z = 0; z < this.schedulesArr[i].trip_table_columns.length; z++) {
            let reisSign = getSignTitle(this.schedulesArr[i].trip_table_columns[z].trip_complex.trip_type_sign).replaceAll('_', '').replaceAll('<', '').replaceAll('>', '').replaceAll(' ', '');
            if (this.schedulesArr[i].trip_table_columns[z].trip_complex.direction == value.direction && value.direction == this.selectedDirection && reisSign == value.trip_type_sign) {
              // величина времени начала
              let startTime = 0;

              if (this.schedulesArr[i].trip_table_columns[z].trip_complex.cp_time_list && this.schedulesArr[i].trip_table_columns[z].trip_complex.cp_time_list.length >= 2) {
                let percentIndex = this.schedulesArr[i].trip_table_columns[z].trip_complex.cp_length_list.indexOf(value.percent);
                startTime = this.schedulesArr[i].trip_table_columns[z].trip_complex.cp_time_list[percentIndex];
              } else {
                startTime = this.schedulesArr[i].trip_table_columns[z].trip_complex.step_time_begin;
              }

              if (arr[this.schedulesArr[i].row_num]) {
                arr[this.schedulesArr[i].row_num].push(startTime);
              } else {
                arr[this.schedulesArr[i].row_num] = [];
                arr[this.schedulesArr[i].row_num].push(startTime);
              }

              // проверяем есть ли такой массив
              if (res[value.percent]) {
                res[value.percent].push(startTime);
              } else {
                res[value.percent] = [];
                res[value.percent].push(startTime);
              }
            }
          }
        })
      }

      this.intervalsLines = arr;

      Object.keys(res).forEach(val => {
        res[val].sort(function (a, b) {
          return a - b;
        });

        for (let z = 0; z < res[val].length; z++) {

          // // добавляем данные в массив для отрисовки линий
          // if (direction == 1 && ((direction == this.sawViewType) || (this.sawViewType == 2))) {
          //   this.matrixLines1.push({
          //     percent: val,
          //     time: res[val][z],
          //   });
          // } else if (direction == 0 && ((direction == this.sawViewType) || (this.sawViewType == 2))) {
          //   this.matrixLines0.push({
          //     percent: val,
          //     time: res[val][z],
          //   });
          // }
          if (z == 0) {
            continue;
          }

          let value = (res[val][z] - res[val][z - 1]);
          let coord = this.getX(res[val][z - 1] + ((res[val][z] - res[val][z - 1]) / 2));

          value = Number(value / 60).toFixed(0);
          // корректируем положение надписи
          if (value > 9) {
            coord = coord - 8;
          } else {
            coord = coord - 4;
          }
          result.push({
            value: value,
            coord: coord,
            cpPercent: Number(val),
          });
        }

      })
      return result;
    },

    getX(value) {
      return (((value / 60) - (this.hoursArray[0] * 60)) * this.oneDiv) + this.start_x;
    },

    doDelLeftTrip(specifiedTrip, row, col) {
      this.$emit('doDelLeftTrip', specifiedTrip, row, col);
    },

    doMinusMin(specifiedTrip, row, col) {
      this.$emit('doMinusMin', specifiedTrip, row, col);
    },

    doEditTripBegin(specifiedTrip, row, col) {
      this.$emit('doEditTripBegin', specifiedTrip, row, col);
    },

    doEditStop(specifiedTrip, row, col) {
      this.$emit('doEditStop', specifiedTrip, row, col);
    },

    doPlusMin(specifiedTrip, row, col) {
      this.$emit('doPlusMin', specifiedTrip, row, col);
    },

    doExtensionTrafficGraph(specifiedTrip, row, col) {
      this.$emit('doExtensionTrafficGraph', specifiedTrip, row, col);
    },

    changeTripType(specifiedTrip, changedTripType, selected_schedule_event_id, selected_null_trip_type_id, selected_production_trip_type_id) {
      // let specifiedTrip = this.colItem.trip_complex;
      console.log(specifiedTrip, "отправляем запрос на изменение типа рейса");
      this.$emit('doChangeTripType', specifiedTrip, changedTripType, selected_schedule_event_id, selected_null_trip_type_id, selected_production_trip_type_id);
    },

    startTrafficGraph(e, colItem) {

      let specifiedTrip = colItem.trip_complex;
      // переменная, в которой хранится в какую четверть экрана кликнул пользователь
      let quarter = 0;
      // определяем куда пользователь кликнул
      let domRect = e.srcElement.getBoundingClientRect();
      // определяем размеры окна
      // определяем ширину
      let windowWidth = window.innerWidth;
      // определяем высоту
      let windowHeight = window.innerHeight;
      // определяем в какую четверть экрана кликнул пользователь
      if (domRect.left < (windowWidth / 2)) {
        // если по ширине пользователь кликнул на левую половину экрана, значит исходя из высоты он может кликнуть в II или III четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это III четверть
          quarter = 3;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это II четверть
          quarter = 2;
        }
      } else {
        // если по ширине пользователь кликнул на правую половину экрана, значит исходя из высоты он может кликнуть в I или IV четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это IV четверть
          quarter = 4;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это I четверть
          quarter = 1;
        }
      }

      let rulerEl = document.getElementById('schedule_ruler');

      // исходя из того в какую четверть экрана кликнул пользователь задаем настройки отображения всплывающего диалога
      if (quarter == 1) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top + 26 - rulerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue - 340) + 'px;';
      } else if (quarter == 2) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top + 26 - rulerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue) + 'px;';
      } else if (quarter == 3) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top - 187 - rulerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue) + 'px;';
      } else if (quarter == 4) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top - 187 - rulerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue - 340) + 'px;';
      }

      if (colItem.column_num % 2 == 0) {
        this.direction = 'AB';
      } else {
        this.direction = 'BA';
      }

      this.dialogSpecifiedTrip = specifiedTrip.trip_complex;

      this.isAppointmentDialogVisible = false;
      this.isRealeaseDialogVisible = true;

      console.log(specifiedTrip, "отправляем запрос на запуск движения на графике");
      this.$emit('doStartTrafficGraph', specifiedTrip, this.selectedRow, this.selectedCol);
    },

    openTripTypeDialog(e, colItem) {
      // переменная, в которой хранится в какую четверть экрана кликнул пользователь
      let quarter = 0;
      // определяем куда пользователь кликнул
      let domRect = e.srcElement.getBoundingClientRect();
      // определяем размеры окна
      // определяем ширину
      let windowWidth = window.innerWidth;
      // определяем высоту
      let windowHeight = window.innerHeight;
      // определяем в какую четверть экрана кликнул пользователь
      if (domRect.left < (windowWidth / 2)) {
        // если по ширине пользователь кликнул на левую половину экрана, значит исходя из высоты он может кликнуть в II или III четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это III четверть
          quarter = 3;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это II четверть
          quarter = 2;
        }
      } else {
        // если по ширине пользователь кликнул на правую половину экрана, значит исходя из высоты он может кликнуть в I или IV четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это IV четверть
          quarter = 4;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это I четверть
          quarter = 1;
        }
      }

      let containerEl = document.getElementById('schedule_ruler');

      // исходя из того в какую четверть экрана кликнул пользователь задаем настройки отображения всплывающего диалога
      if (quarter == 1) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top + 26 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue - 340) + 'px;';
      } else if (quarter == 2) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top + 26 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue) + 'px;';
      } else if (quarter == 3) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top - 187 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue) + 'px;';
      } else if (quarter == 4) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top - 187 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(this.leftValue - 340) + 'px;';
      }

      if (colItem.column_num % 2 == 0) {
        this.direction = 'AB';
      } else {
        this.direction = 'BA';
      }

      this.dialogSpecifiedTrip = colItem.trip_complex;

      this.isRealeaseDialogVisible = false;
      this.isAppointmentDialogVisible = true;

    },

    setGraph(specifiedTrip, graph, startTime, continuationTripsCount) {
      if (!this.isLoading) {
        if (!Number.isInteger(Number(graph))) {
          specifiedTrip.graph = this.selectedTrip.graph;
        } else {
          if ((Number(graph) > 0) && (Number(graph) <= 999) && (graph != specifiedTrip.graph)) {
            // отправляем запрос на редактирование
            console.log("отправляем запрос на редактирование номера графика");
            this.$emit('doEditGraph', specifiedTrip, this.selectedRow, this.selectedCol);
          } else {
            console.log(graph, startTime, continuationTripsCount);
            specifiedTrip.graph = this.selectedTrip.graph;
          }
        }
        // this.selectedTrip = null;
        // this.selectedRow = 0;
        // this.selectedCol = 0;
        // скрываем диалог
        this.isRealeaseDialogVisible = false;
      }
    },

    // диалог редактирования рейса  &&
    hideDialog(e) {
      if (!document.getElementById('release_from_park_dialog') && !document.getElementById('release_from_park_dialog')) {
        this.selectedReis = null;
        this.isAppointmentDialogVisible = false;
        this.isRealeaseDialogVisible = false;
        this.leftValue = null;
      } else if ((document.getElementById('release_from_park_dialog') && !document.getElementById('release_from_park_dialog').contains(e.target)) ||
          (document.getElementById('release_from_park_dialog') && !document.getElementById('release_from_park_dialog').contains(e.target))
      ) {
        this.selectedReis = null;
        this.isAppointmentDialogVisible = false;
        this.isRealeaseDialogVisible = false;
        this.leftValue = null;
      }
    },

    // скрываем диалог Выпуска из парка
    hideReleaseDialog() {
      this.isRealeaseDialogVisible = false;
      this.realeaseDialogStyle = '';
    },

    // скрываем диалог Выпуска из парка
    hideAppointmentDialog() {
      this.isAppointmentDialogVisible = false;
      this.appointmentDialogStyle = '';
    },

    onClick(e, index) {
      // определяем координату х клика пользователя, чтобы определить в какой рейс нажал пользователь
      let x = e.offsetX;
      // получаем график на который кликнул пользователь
      let reises = this.schedulesArr[index].trip_table_columns;
      // определяем в цикле на какой рейс кликнул пользователь
      let layoutStartTime = (this.hoursArray[0] * 60);

      for (let i = 0; i < reises.length; i++) {
        let selReis = (((reises[i].trip_complex.step_time_begin / 60) - layoutStartTime) * this.oneDiv) + this.start_x;
        let nextReis = null;
        if (i != (reises.length - 1)) {
          nextReis = (((reises[i + 1].trip_complex.step_time_begin / 60) - layoutStartTime) * this.oneDiv) + this.start_x;
        }
        if (i == 0 && x < selReis) {
          console.log('Ничего не делаем');
          break;
        }
        if ((x > selReis && x < nextReis) || ((i == (reises.length - 1)) && x >= selReis)) {
          console.log('Выбранный рейс под номером: ', i);
          this.leftValue = selReis;
          // по индексу можем получить html элемент по которому кликнул пользователь, для определения отступа сверху
          let el = document.getElementById('svg' + index);
          let rulerEl = document.getElementById('schedule_ruler');
          let topCoord = el.getBoundingClientRect().top;
          this.lineGraphDialogStyle = 'top: ' + Math.round(topCoord - 9 - rulerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(selReis) + 'px;';
          this.selectedReis = reises[i];
          break;
        }
      }
    },

    mousemoveEvent(event) {
      // this.cursorPosition = event.offsetX;
      if (event.offsetX > this.start_x) {
        document.getElementById('svgCursor').setAttribute('x1', event.offsetX);
        document.getElementById('svgCursor').setAttribute('x2', event.offsetX);
      } else {
        document.getElementById('svgCursor').setAttribute('x1', this.start_x);
        document.getElementById('svgCursor').setAttribute('x2', this.start_x);
      }
      // window.requestAnimationFrame(this.mousemoveEvent);
    },

    updateWidth(newValue) {
      this.width = newValue;
    },

  },

}
</script>

<style scoped>

</style>