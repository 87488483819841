// ХРАНИЛИЩЕ ПОКАЗАТЕЛЕЙ ПАССАЖИРОПТОКА ПО ОПЛАЧЕННЫМ ПОЕЗДКАМ ПО ГРАФИКАМ МАРШРУТА ЗА ПЕПРИОД ДАТ

import {DoPost} from "@/store/Actions";

export default {
    actions: {
        // запрос списка объектов
        doFetchPayTransByGraph: (context, payload) => {
            return DoPost(
                '/asmpp/asop/payTransByGraph',
                payload,
                null,
                'Запрос показателей пассажиропотока по оплаченным поездкам по графикам маршрута за период дат',
                false,
                true
            )
        },
    }
}
