<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-kvr-organizations"></edit-table-panel>
</template>

<script>
import {getLegalForm} from "@/store/RefHelpers";

export default {
  props: ['isLoading', 'filterText', 'chosenRows', 'organizations', 'disableSelect'],
  data() {
    return {};
  },
  computed: {
    // колонки
    columnList() {
      return [
        {
          field: "__isSelected",
          caption: "Вкл.",
          isSortable: false,
          sortType: 'boolean',
          displayType: 'checkbox',
          headerAlign: 'center',
          dataAlign: 'center',
          inputStyle: {
            width: '18px',
            height: '18px',
            marginTop: '5px'
          },
          inputAttrs: {
            disabled: this.disableSelect,
          }
        },
        {
          field: "org_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
          show: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
          show: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
          show: true,
        },
        {
          field: "__legalFormName",
          caption: "Организационно-правовая форма",
          sortType: 'string',
          show: false
        },
        {
          field: "is_agency",
          caption: "Перевозчик",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_agency) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
      ]
    },

    // строки таблицы
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      let allOrganizations = this.organizations;

      allOrganizations.map(org => {
        // если парки только получены или взведен триггер снятия всех галочек
        if ((org.__isSelected == null && org.__isSelected == undefined) || this.chosenRows == 'clearAll') {
          // снимаем все галочки слева
          org.__isSelected = false;
        }
      });

      // отфильтрованные организации
      let filteredOrganizations = [];
      // в случае текста в поле "фильтр" фильтруем парки
      if (this.filterText != '') {
        let str;
        filteredOrganizations = allOrganizations.filter((row) => {
          return this.columnList.some((col) => {
            let item = col.subField ? row[col.subField][col.field] : row[col.field];
            if (col.isFilterable && col.show) {
              if (item == null) {
                str = '';
              } else {
                str = String(item).toLowerCase();
              }
              return str.includes(this.filterText.toLowerCase());
            } else {
              return false;
            }
          });
        });
      } else {
        filteredOrganizations = [];
        allOrganizations.map(value => {
          filteredOrganizations.push(value)
        })
      }

      filteredOrganizations.map(org => {
        const legalForm = getLegalForm(org.legal_form_id);
        org.__id = org.org_id;
        org.__legalFormName = legalForm && legalForm.long_name || '';
        org.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px'
        };
        return org
      });

      // в случае, если нажата кнопка "выбрать все строки на экране" т.е. отфильтрованные строки
      // сетаем отфильтрованные парки выбранными
      if (this.chosenRows == 'selectFiltered') {
        filteredOrganizations.map(value => {
          value.__isSelected = true;
        })
      }
      // сбрасываем режим для галочек (поле __isSelected)
      this.$emit('selection', allOrganizations)

      return filteredOrganizations;
    },
  },
};
</script>
