<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable"
                  style="max-width: 84% !important; max-height: 96% !important;">
      <modal-content style=" height: 100%">
        <modal-header class="bg-primary text-white">

          <modal-title><i class="fas fa-info-circle fa-lg"></i> Анализ расписания</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body style="padding: 0; overflow-y: hidden">
          <align-container>
            <align-panel align="all" >
              <schedule-graph-panel
                @setIsLoading="setIsLoading"
                :schedule_graph_set_id="schedule_graph_set_id"
                :schedulesArr="schedulesArr"
                :hoursArray="hoursArray"
              >
              </schedule-graph-panel>
            </align-panel>
          </align-container>
        </modal-body>
<!--        <modal-footer v-if="op !== 'show'">-->
<!--          <button @click="onSubmit" class="btn btn-primary">Ок</button>-->
<!--        </modal-footer>-->
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>



import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import ScheduleGraphPanel from "@/components/rmtgen/ScheduleGraphPanel";

export default {
  components: {
    ScheduleGraphPanel,
    AlignPanel,
    AlignContainer,

  },
  props: ['item', 'op', 'schedule_graph_set_id', 'schedulesArr', 'hoursArray'],
  emits: ['onOK', 'onCancel', 'setIsLoading'],
  data() {
    return {
      selectedRow: null,
    }
  },
  methods: {

    setIsLoading(value) {
      this.$emit('setIsLoading', value);
    }

  }
};
</script>

<style scoped>
form label {
  font-weight: bold;
}

</style>