import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ВИДОВ ОСТАНОВОЧНЫХ ПАВИЛЬОНОВ

export default {
    state() {
        return {
            // список объектов
            stopPavilionKinds: [],
            // время обновления кэша new Date().getTime()
            stopPavilionKindsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            stopPavilionKindsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getStopPavilionKinds(state) {
            return state.stopPavilionKinds;
        },
    },
    mutations: {
        // установить новый список объектов
        setStopPavilionKinds(state, payload) {
            state.stopPavilionKinds = payload;
            state.stopPavilionKindsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchStopPavilionKinds: (context, payload) => {
            return DoGet(
                '/multipass/stopPavilionKinds',
                'multipass/setStopPavilionKinds',
                [],
                'Запрос видов остановочных павильонов',
                false,
                payload && payload.force,
                context.state.stopPavilionKindsCache,
                context.state.stopPavilionKindsCacheTimeout
            )
        },

        // очищает кэш
        doClearStopPavilionKinds: (context, payload) => {
            return DoClearCache(
                'multipass/setStopPavilionKinds',
                [],
                payload && payload.force,
                context.state.stopPavilionKindsCache,
                context.state.stopPavilionKindsCacheTimeout
            )
        },
    }
}