<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Реквизиты</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <form>
            <div class="row">
              <div class="form-group col">
                <label for="legalDetailId">Код:</label>
                <input-integer
                    id="legalDetailId"
                    class="form-control"
                    v-model="legalDetailId"
                    readonly
                />
              </div>
              <div class="form-group col">
                <label for="parentDetailId">Верхн. уровень: </label>
                <input-select
                    caption="Выберите реквизиты..."
                    id="parentDetailId"
                    :itemList="parentList"
                    class="form-control"
                    ref="parentDetailId"
                    :readonly="op === 'show'"
                    v-model="parentDetailId"
                />
              </div>
            </div>
          </form>

          <form v-if="isIndividual">

            <div class="form-group">
              <label for="indLastName">Фамилия:</label><req-field/>
              <input-string
                  class="form-control"
                  id="indLastName"
                  v-model="indLastName"
                  ref="indLastName"
                  autocomplete="off"
                  :readonly="op === 'show'"
                  required
                  error="Введите фамилию"
              />
            </div>

            <div class="form-group">
              <label for="indFirstName">Имя:</label><req-field/>
              <input-string
                  class="form-control"
                  id="indFirstName"
                  v-model="indFirstName"
                  ref="indFirstName"
                  required
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="indMiddleName">Отчество / второе имя:</label>
              <input-string
                  class="form-control"
                  id="indMiddleName"
                  v-model="indMiddleName"
                  ref="indMiddleName"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="indBirthday">Дата рождения:</label>
              <input-date
                  class="form-control"
                  id="indBirthday"
                  v-model="indBirthday"
                  ref="indBirthday"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="indInn">ИНН физического лица:</label>
              <input-string
                  class="form-control"
                  id="indInn"
                  v-model="indInn"
                  ref="indInn"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="indLegalAddress">Адрес регистрации:</label>
              <input-string
                  class="form-control"
                  id="indLegalAddress"
                  v-model="indLegalAddress"
                  ref="indLegalAddress"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="indActualAddress">Адрес фактического проживания:</label>
              <input-string
                  class="form-control"
                  id="indActualAddress"
                  v-model="indActualAddress"
                  ref="indActualAddress"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="indPhoneNum">Номера телефонов (через запятую):</label>
              <input-string
                  class="form-control"
                  id="indPhoneNum"
                  v-model="indPhoneNum"
                  ref="indPhoneNum"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="indEmail">Адреса электронной почты (через запятую):</label>
              <input-string
                  class="form-control"
                  id="indEmail"
                  v-model="indEmail"
                  ref="indEmail"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="indIdentityType">Тип документа удостоверяющего личность:</label>
              <input-select
                  caption="Выберите тип документа..."
                  id="indIdentityType"
                  :itemList="[
                      {
                        id:1,
                        title:'Паспорт'
                      },
                      {
                        id:2,
                        title:'Военный билет'
                      },
                  ]"
                  class="form-control"
                  ref="indIdentityType"
                  :readonly="op === 'show'"
                  v-model="indIdentityType"
              />
            </div>

            <div class="form-group">
              <label for="indIdentityData">Информация из документа:</label>
              <input-textarea
                  class="form-control"
                  id="indIdentityData"
                  v-model="indIdentityData"
                  ref="indIdentityData"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

          </form>
          <form v-else>

            <div class="form-group">
              <label for="orgTitle">Название организации:</label>
              <req-field/>
              <input-string
                  class="form-control"
                  id="orgTitle"
                  v-model="orgTitle"
                  ref="orgTitle"
                  autocomplete="off"
                  :readonly="op === 'show'"
                  :required="!isIndividual"
                  error="Введите название организации"
              />
            </div>

            <div class="form-group">
              <label for="orgOgrn">ОГРН:</label>
              <input-string
                  class="form-control"
                  id="orgOgrn"
                  v-model="orgOgrn"
                  ref="orgOgrn"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="orgInn">ИНН:</label>
              <input-string
                  class="form-control"
                  id="orgInn"
                  v-model="orgInn"
                  ref="orgInn"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="orgKpp">КПП:</label>
              <input-string
                  class="form-control"
                  id="orgKpp"
                  v-model="orgKpp"
                  ref="orgKpp"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="orgLegalAddress">Юридический адрес:</label>
              <input-string
                  class="form-control"
                  id="orgLegalAddress"
                  v-model="orgLegalAddress"
                  ref="orgLegalAddress"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="orgActualAddress">Фактический адрес:</label>
              <input-string
                  class="form-control"
                  id="orgActualAddress"
                  v-model="orgActualAddress"
                  ref="orgActualAddress"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="orgOkpo">ОКПО:</label>
              <input-string
                  class="form-control"
                  id="orgOkpo"
                  v-model="orgOkpo"
                  ref="orgOkpo"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="orgOkved">ОКВЭД:</label>
              <input-string
                  class="form-control"
                  id="orgOkved"
                  v-model="orgOkved"
                  ref="orgOkved"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="orgPhoneNum">Номера телефонов (через запятую):</label>
              <input-string
                  class="form-control"
                  id="orgPhoneNum"
                  v-model="orgPhoneNum"
                  ref="orgPhoneNum"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

            <div class="form-group">
              <label for="orgEmail">Адреса электронной почты (через запятую):</label>
              <input-string
                  class="form-control"
                  id="orgEmail"
                  v-model="orgEmail"
                  ref="orgEmail"
                  autocomplete="off"
                  :readonly="op === 'show'"
              />
            </div>

          </form>

        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  props: ['item', 'op', 'isIndividual', 'parentList', 'parentField'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {

      legalDetailId: this.item.__id,
      parentDetailId: this.item[this.parentField],

      //данные на вкладке физ лицо
      indFirstName: this.item.detail.ind_first_name,
      indMiddleName: this.item.detail.ind_middle_name,
      indLastName: this.item.detail.ind_last_name,
      indBirthday: this.item.detail.ind_birthday,
      indInn: this.item.detail.ind_inn,
      indLegalAddress: this.item.detail.ind_legal_address,
      indActualAddress: this.item.detail.ind_actual_address,
      indPhoneNum: this.item.detail.ind_phone_num,
      indEmail: this.item.detail.ind_email,
      indIdentityType: this.item.detail.ind_identity_type,
      indIdentityData: this.item.detail.ind_identity_data,

      //данные на вкладке Организация
      orgTitle: this.item.detail.org_title,
      orgOgrn: this.item.detail.org_ogrn,
      orgInn: this.item.detail.org_inn,
      orgKpp: this.item.detail.org_kpp,
      orgLegalAddress: this.item.detail.org_legal_address,
      orgActualAddress: this.item.detail.org_actual_address,
      orgOkpo: this.item.detail.org_okpo,
      orgOkved: this.item.detail.org_okved,
      orgPhoneNum: this.item.detail.org_phone_num,
      orgEmail: this.item.detail.org_email,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK',
            {
              [this.parentField]: this.parentDetailId,
              detail: {
                //данные на вкладке физ лицо
                ind_first_name: this.isIndividual && this.indFirstName || null,
                ind_middle_name: this.isIndividual && this.indMiddleName || null,
                ind_last_name: this.isIndividual && this.indLastName || null,
                ind_birthday: this.isIndividual && this.indBirthday || null,
                ind_inn: this.isIndividual && this.indInn || null,
                ind_legal_address: this.isIndividual && this.indLegalAddress || null,
                ind_actual_address: this.isIndividual && this.indActualAddress || null,
                ind_phone_num: this.isIndividual && this.indPhoneNum || null,
                ind_email: this.isIndividual && this.indEmail || null,
                ind_identity_type: this.isIndividual && this.indIdentityType || null,
                ind_identity_data: this.isIndividual && this.indIdentityData || null,

                //данные на вкладке организация
                org_title: !this.isIndividual && this.orgTitle || null,
                org_ogrn: !this.isIndividual && this.orgOgrn || null,
                org_inn: !this.isIndividual && this.orgInn || null,
                org_kpp: !this.isIndividual && this.orgKpp || null,
                org_legal_address: !this.isIndividual && this.orgLegalAddress || null,
                org_actual_address: !this.isIndividual && this.orgActualAddress || null,
                org_okpo: !this.isIndividual && this.orgOkpo || null,
                org_okved: !this.isIndividual && this.orgOkved || null,
                org_phone_num: !this.isIndividual && this.orgPhoneNum || null,
                org_email: !this.isIndividual && this.orgEmail || null
              }
            }
        )
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>