<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-methods"></edit-table-panel>
</template>

<script>
import {getModule} from "@/store/SystemHelpers";
import EditTablePanel from "@/components/ui/custom/EditTablePanel";

export default {
  components: {EditTablePanel},
  props: ['module_id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "method_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "__moduleName",
          caption: "Модуль",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "http_method",
          caption: "Http-метод",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "method_name",
          caption: "Метод",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "desc",
          caption: "Описание метода",
          sortType: 'string',
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      // фильтруем методы в зависимости от выбранного модуля
      const methods = this.$store.getters["system/getMethods"].filter(
          module => module.module_id === this.module_id || !this.module_id
      );
      return methods.map(method => {
        const module = getModule(method.module_id);

        method.__id = method.method_id;
        method.__moduleName = module && module.module_name || ''

        return method;
      });
    },
  },
};
</script>
