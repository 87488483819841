<template>
  <error-message-box>{{  panicMessage }}</error-message-box>
</template>

<script>
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixin: [PageMixin],

  computed: {
    panicMessage() {
      return this.$store.getters['getPanicMessage'];
    }
  }
}
</script>
