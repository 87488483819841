<template>
  <transition name="slide">
    <div class="report-panel overflow-hidden" v-click-away="onAutoHide"
         :style="dialogStyle + ' backgroundColor: #ffdbe1;'">
      <div style="width: 100%; background-color: #17a2b8">
        <label style="margin-left: 5px; margin-bottom: 0px; font-weight: bold; color: white">{{dialogTitle}}</label>
      </div>
      <div class="row" style="margin: 0; padding: 0;">
        <div class="col" style="padding: 0;">
          <div class="row" style="margin: 0;">
            <div class="form-group col" style="margin: 0; padding-right: 2px">
              <label for="graph" style="margin: 0;">№ графика</label>
              <input-integer
                  id="graph"
                  class="form-control"
                  v-model="graph"
                  style="height: 25px; padding-top: 0px; padding-bottom: 0px"
              />
            </div>
            <div class="form-group col" style="margin: 0;padding-left: 2px">
              <label for="startTime" style="margin: 0;">Время вып.</label>
              <input-integer
                  id="startTime"
                  class="form-control"
                  v-model="startTime"
                  style="height: 25px; padding-top: 0px; padding-bottom: 0px"
              />
            </div>
          </div>
          <div class="row" style="margin: 0;">
            <div class="form-group col" style="text-align: center; margin-bottom: 0;">
              <label for="startTime" style="margin: 0;">Продлить на:</label>
              <input-integer
                  id="startTime"
                  class="form-control"
                  v-model="continuationTripsCount"
                  style="height: 25px; padding-top: 0px; padding-bottom: 0px; width: 60px; margin-left: 76px"
              />
              <label for="startTime" style="margin: 0;">кругорейсов</label>
            </div>
          </div>
          <button
              type="button"
              class="btn btn-success btn-sm"
              style="height: 31px; margin-left: 80px; margin-top: 12px"
              @click="onSetGraphs"
          >
            Выпустить
          </button>
        </div>
        <div class="col" style="padding-left: 0px">
          <div class="row" style="margin: 0; padding: 0;">
            <div class="col" style="padding: 0;">
              <label for="startTime" style="margin: 0;">Нулевой</label>
              <ul style="overflow-y: auto; height: 90px; padding: 0; margin: 0 5px 0 0;overflow-x: hidden">
<!--                class="list-group"-->
                <li
                    v-for="tripType in nullItemList"
                    :key=tripType.trip_type_id
                    :class="selected_null_trip_type_id == tripType.trip_type_id ? 'list-group-item active' : 'list-group-item'"
                    @click="setSelectedNullTripType(tripType.trip_type_id)"
                    :title="getTripTypeTittle(tripType, 'null')"
                >{{ getTripTypeShort(tripType.trip_type_sign) }}</li>
                <li
                    :class="selected_null_trip_type_id == 0 ? 'list-group-item active' : 'list-group-item'"
                    @click="setSelectedNullTripType(0)"
                >Без нулевого</li>
              </ul>
            </div>
            <div class="col" style="padding: 0;">
              <label for="startTime" style="margin: 0;">Тип произв. рейса</label>
              <ul class="list-group" style="overflow-y: auto; height: 90px; padding: 0; margin: 0">
<!--                class="list-group"-->
                <li
                    v-for="tripType in productionItemList"
                    :key=tripType.trip_type_id
                    :class="selected_production_trip_type_id == tripType.trip_type_id ? 'list-group-item active' : 'list-group-item'"
                    @click="setSelectedProductionTripType(tripType.trip_type_id)"
                    :title="getTripTypeTittle(tripType, 'tripType')"
                >{{ getTripTypeShortFnc(tripType.trip_type_sign) }}</li>
              </ul>
            </div>
          </div>
          <button
              type="button"
              class="btn btn-danger btn-sm"
              style="height: 31px; margin-left: 81px; margin-top: 15px"
              @click="onAutoHide"
          >
            Отменить
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {directive} from "vue3-click-away";
import InputInteger from "@/components/ui/custom/InputInteger";
import {getSignTitle} from "@/lib";
import {getStop} from "@/store/MultipassHelpers";

export default {
  components: {InputInteger},
  directives: {
    ClickAway: directive
  },
  emits: ['hideDialog', 'setGraph'],
  props: ['dialogStyle', 'direction', 'dialogTitle', 'tripTypes', 'dialogSpecifiedTrip'],
  data() {
    return {
      // график
      graph: '',
      // Время выпуска
      startTime: '',
      // количество кругорейсов для продления
      continuationTripsCount: '',
      // массив для списка нулевых рейсов
      nullItemList: [],
      // выбранный нулевой рейс
      selected_null_trip_type_id: null,
      // массив производственных рейсов
      productionItemList: [],
      // выбранный производственный рейс
      selected_production_trip_type_id: null,
    }
  },

  methods: {

    // получаем текст для всплывающих подсказок
    getTripTypeTittle(trip_type, type) {
      if (type == 'null') {
        let firstStop = getStop(trip_type.stop_id_begin);
        let lastStop = getStop(trip_type.stop_id_end);
        return '(' + trip_type.trip_type_sign + ') ' + firstStop.long_name + ' - ' + lastStop.long_name;
      } else if (type == 'tripType' && trip_type.trip_type_id != 0) {
        let firstStop = getStop(trip_type.stop_id_begin);
        let lastStop = getStop(trip_type.stop_id_end);
        return firstStop.long_name + ' - ' + lastStop.long_name;
      }
    },

  // получаем тип рейса без букв направления
    getTripTypeShort(trip_type) {
      return (trip_type.includes("AB", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type.includes("BA", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type)
    },

    getTripTypeShortFnc(trip_type_sign) {
      return getSignTitle(trip_type_sign);
    },

    setSelectedNullTripType(trip_type_id) {
      this.selected_null_trip_type_id = trip_type_id;
    },

    setSelectedProductionTripType(trip_type_id) {
      this.selected_production_trip_type_id = trip_type_id;
    },

    onSetGraphs() {

      let specifiedTrip = this.dialogSpecifiedTrip;

      this.$emit('setGraph', specifiedTrip, this.graph, this.startTime, this.continuationTripsCount);
    },

    // убирать окно при щелчке в другое место (пока не будем это делать)
    onAutoHide() {
      this.$emit('hideDialog');
    },
  },

  async created() {
    this.tripTypes.forEach(value => {
      if((value.trip_type_sign[0] == '-' || value.trip_type_sign[0] == '_') && (value.trip_type_sign.includes(this.direction))) {
        this.nullItemList.push(value);
      } else if ((value.trip_type_sign[0] != '-' || value.trip_type_sign[0] != '_') && (value.trip_type_sign.includes(this.direction))){
        this.productionItemList.push(value);
      }
    })
  }

}
</script>

<style scoped>

li {
  padding: 0;
  white-space: nowrap;
  cursor: pointer;
}

ul {
  overflow-x: hidden;
  background-color: white;
}

.report-panel {
  position: absolute;
  max-height: 250px;
  height: 185px;
  /*bottom: 5px;*/
  /*right: 5px;*/
  width: 500px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Параметры тени */
  font-size: 90%;
  z-index: 100;
}

/*.slide-enter-active,*/
/*.slide-leave-active {*/
/*  transition: all 0.5s ease;*/
/*}*/

/*.slide-enter-from,*/
/*.slide-leave-to {*/
/*  right: -800px;*/
/*}*/

/*.slide-enter-to,*/
/*.slide-leave-from {*/
/*  right: 5px;*/
/*}*/
</style>