import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПНЫХ ДЛЯ УПРАВЛЕНИЯ МАРШРУТОВ


export default {
    state() {
        return {
            // список объектов
            manageRoutes: [],
            // время обновления кэша new Date().getTime()
            manageRoutesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            manageRoutesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getManageRoutes(state) {
            return state.manageRoutes;
        },
    },
    mutations: {
        // установить новый список объектов
        setManageRoutes(state, payload) {
            state.manageRoutes = payload;
            state.manageRoutesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchManageRoutes: (context, payload) => {
            return DoGet(
                '/trn/getManageRoutes',
                'trn/setManageRoutes',
                [],
                'Запрос списка доступных для управления маршрутов',
                false,
                payload && payload.force,
                context.state.manageDepotsCache,
                context.state.manageDepotsCacheTimeout
            )
        },

    }
}