<template>

<!--  <div-->
<!--      id="pane"-->
<!--      class="split-pane"-->
<!--      :class="{ 'is-dragging': dragging }"-->
<!--      @mousemove="dragMove"-->
<!--      @mouseup="dragEnd"-->
<!--      @mouseleave="dragEnd"-->
<!--  >-->
<!--    <div id="left" class="split-pane-item" :style="{ width: splitLeft, backgroundColor: 'green'  }">-->
<!--&lt;!&ndash;      <slot name="left" />&ndash;&gt;<div class="cont2"></div>-->
<!--    </div>-->
<!--    <div class="split-pane-gutter-v" @mousedown.prevent="dragStart"></div>-->
<!--    <div id="right" class="split-pane-item" :style="{ width: splitRight, backgroundColor: 'red' }">-->
<!--&lt;!&ndash;      <slot name="right" />&ndash;&gt;<div class="cont"></div>-->
<!--    </div>-->
<!--  </div>-->


  <align-container
      id="paneHorizontal"
      :class="{ 'is-dragging-h': draggingHorizontal }"
      @mousemove="dragMoveHorizontal"
      @mouseup="dragEndHorizontal"
      @mouseleave="dragEndHorizontal"
  >
    <align-panel align="top" id="top" :style="{ height: splitTop, 'z-index':'1' }">
      <align-container
          id="pane"
          :class="{ 'is-dragging': draggingVertical }"
          @mousemove="dragMove"
          @mouseup="dragEnd"
          @mouseleave="dragEnd"
      >
        <align-panel align="left" class="left" id="left" :style="{ width: splitLeft, backgroundColor: 'green', 'z-index':'2' }">
          <!--      <slot name="left" />-->
        </align-panel>
        <align-panel align="all" :style="{left: splitLeft, right: splitRight, 'z-index':'55'}"><div class="split-pane-gutter-v" @mousedown.prevent="dragStart"></div></align-panel>
        <align-panel align="right"  class="right" id="right" :style="{ width: splitRight, backgroundColor: 'red', 'z-index':'2'}">
          <!--      <slot name="right" />-->
        </align-panel>
      </align-container>
    </align-panel>
    <align-panel align="all" :style="{top: splitTop, bottom: splitBottom, 'z-index':'55'}"><div class="split-pane-gutter-h" @mousedown.prevent="dragStartHorizontal"></div></align-panel>
    <align-panel align="bottom" class="bottom" id="bottom"  :style="{ height: splitBottom, backgroundColor: 'blue', 'z-index':'1'}"></align-panel>

  </align-container>


<!--<div>-->
<!--  <align-container style="background-color: rgba(255,255,0,0.27);" name="container 1">-->
<!--        <align-panel style="background-color: red" align="top" name="panel 1">-->
<!--          <p>Решение ввести нерабочие дни в Москве поможет сорвать несколько циклов заражения коронавирусом в городе. Об этом сообщается в официальном Telegram-канале главного врача больницы в Коммунарке Дениса Проценко.</p>-->
<!--        </align-panel>-->
<!--      <align-panel style="background-color: blue" align="all" name="panel 2">-->

<!--        <align-container style="background-color: green" name="container 2">-->
<!--          <align-panel align="all" style="background-color: yellow">-->
<!--            <div class="h-100 w-75" style="background-color: white; border: 5px blue"> </div>-->
<!--          </align-panel>-->
<!--          <align-panel v-if="isShow" align="bottom" style="background-color: maroon">-->
<!--            Предложение ваше принимается-->
<!--          </align-panel>-->
<!--        </align-container>-->


<!--        <align-container style="background-color: green" name="container 2">-->
<!--          <align-panel style="background-color: yellow" align="top" name="panel 3">-->
<!--            <p>Решение ввести нерабочие дни в Москве поможет сорвать несколько циклов заражения коронавирусом в городе. Об этом сообщается в официальном Telegram-канале главного врача больницы в Коммунарке Дениса Проценко.</p>-->
<!--          </align-panel>-->
<!--          <align-panel style="background-color: maroon" align="all" name="panel 4">-->

<!--            <align-container style="background-color: honeydew" name="container 3">-->
<!--              <align-panel style="background-color: palegoldenrod" align="top" name="panel 5">-->
<!--                <p>Решение ввести нерабочие дни в Москве поможет сорвать несколько циклов заражения коронавирусом в городе. Об этом сообщается в официальном Telegram-канале главного врача больницы в Коммунарке Дениса Проценко.</p>-->
<!--              </align-panel>-->
<!--              <align-panel style="background-color: magenta" align="all" name="panel 6">-->


<!--              </align-panel>-->
<!--            </align-container>-->


<!--          </align-panel>-->
<!--        </align-container>-->

<!--      </align-panel>-->
<!--  </align-container>-->

  <!--    <align-panel style="background-color: red" align="top" height="10px">-->

  <!--    </align-panel>-->

  <!--    <align-panel style="background-color: green" align="top" height="20px">-->

  <!--    </align-panel>-->

  <!--    <align-panel style="background-color: blue" align="top" height="auto">-->
  <!--      На рисунке выше посмотрим вначале на clientHeight, её посчитать проще всего. Прокрутки нет, так что это в точности то, что внутри рамок: CSS-высота 200px плюс верхнее и нижнее поля padding (по 20px), итого 240px.-->
  <!--      На рисунке нижний padding заполнен текстом, но это неважно: по правилам он всегда входит в clientHeight.-->
  <!--      Теперь clientWidth – ширина содержимого здесь не равна CSS-ширине, её часть «съедает» полоса прокрутки. Поэтому в clientWidth входит не CSS-ширина, а реальная ширина содержимого 284px плюс левое и правое поля padding (по 20px), итого 324px.-->
  <!--    </align-panel>-->

  <!--    <align-panel style="background-color: red" align="bottom" height="10px">-->

  <!--    </align-panel>-->

  <!--    <align-panel style="background-color: green" align="bottom" height="20px">-->

  <!--    </align-panel>-->

  <!--    <align-panel style="background-color: blue" align="bottom" height="auto">-->
  <!--      {{value}}-->
  <!--    </align-panel>-->

  <!--    <align-panel style="background-color: gray" align="all">-->

  <!--    </align-panel>-->
<!--</div>-->
</template>

<script>
// export default {
//   name: "TestPage2",
//   data() {
//     return {
//       value: '',
//       isShow: false,
//     }
//   },
//   mounted() {
//     setTimeout(()=>{
//       this.isShow = true
//     }, 3000);
//
//     setTimeout(()=>{
//       this.isShow = false
//     }, 6000);
//   }
// }

import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
export default {
  name: 'VueSplitPane',
  components: {AlignPanel, AlignContainer},
  data() {
    return {
      gutterVertical: 2,
      gutterHorizontal: 2,
      splitVertical: 50,
      splitHorizontal: 50,
      draggingVertical: false,
      draggingHorizontal: false,
      startSplitVertical: 0,
      startHorizontal: 0,
      startX: 0,
      startY: 0,
    };
  },
  computed: {
    splitLeft: function () {
      return `calc(${this.splitVertical}% - ${this.gutterVertical}px)`;
    },
    splitRight: function () {
      return `calc(${100 - this.splitVertical}% - ${this.gutterVertical}px)`;
    },
    splitTop: function () {
      return `calc(${this.splitHorizontal}% - ${this.gutterHorizontal}px)`;
    },
    splitBottom: function () {
      return `calc(${100 - this.splitHorizontal}% - ${this.gutterHorizontal}px)`;
    },
  },
  methods: {
    dragStart(e) {
        this.draggingVertical = true;
        this.startX = e.pageX;
        this.startSplitVertical = this.splitVertical;
    },
    dragMove(e) {
      if (this.draggingVertical) {
        const dx = e.pageX - this.startX;
        var element = document.getElementById('pane');
        const totalWidth = element.offsetWidth;
        this.splitVertical = this.startSplitVertical + (dx / totalWidth) * 100;
      }
    },
    dragEnd() {
      this.draggingVertical = false;
    },

    dragStartHorizontal(e) {
      console.log('START - DRAGGING')
      this.draggingHorizontal = true;
      this.startY = e.pageY;
      this.startSplitHorizontal = this.splitHorizontal;
    },
    dragMoveHorizontal(e) {
      if (this.draggingHorizontal) {
        const dy = e.pageY - this.startY;
        var element = document.getElementById('paneHorizontal');
        const totalWidth = element.offsetHeight;
        this.splitHorizontal = this.startSplitHorizontal + (dy / totalWidth) * 100;
      }
    },
    dragEndHorizontal() {
      console.log('END - DRAGGING')
      this.draggingHorizontal = false;
    },
  },
};
</script>

<style scoped>

/*.top,*/
/*.left,*/
/*.bottom,*/
/*.right {*/
/*  background-clip: padding-box;*/
/*  border-radius: 10px;*/
/*  border: 1px solid #ffffff;*/
/*}*/
/*.split-pane {*/
/*  !*display: flex;*!*/
/*  flex-direction: row;*/
/*  height: 80%;*/
/*}*/
/*.split-pane-item,*/
/*.split-pane-gutter-v {*/
/*  height: 100%;*/
/*}*/
.split-pane-gutter-v {
  background: #ffffff;
  /*opacity: 0.9;*/
  box-sizing: border-box;
  background-clip: padding-box;
  width: 4px;
  height: 100%;
  margin: 0 !important;
  /*margin: 0 -5px;*/
  /*border-left: 5px solid rgba(255, 255, 255, 0);*/
  /*border-right: 5px solid rgba(255, 255, 255, 0);*/
  cursor: col-resize;
}
/*.split-pane-gutter-v:hover,*/
/*.split-pane-gutter-v:focus {*/
/*  border-left: 5px solid rgba(0, 0, 0, 0.5);*/
/*  border-right: 5px solid rgba(0, 0, 0, 0.5);*/
/*  transition: all 0.5s ease;*/
/*}*/
.is-dragging {
  cursor: col-resize;
}

.split-pane-gutter-v:before {
  display: block;
  content: "";
  width: 2px;
  height: 50px;
  position: relative;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -1px;
  border-left: 2px solid #ccc;
  /*border-right: 1px solid #ccc;*/
}

.split-pane-gutter-v:hover:before {
  border-color: #9d9d9d;
}

.is-dragging-h {
  cursor: row-resize ;
}

.split-pane-gutter-h {
  background: #ffffff;
  /*opacity: 0.9;*/
  box-sizing: border-box;
  background-clip: padding-box;
  width: 100%;
  height: 4px;
  margin: 0 !important;
  /*margin: 0 -5px;*/
  /*border-top: 5px solid rgba(255, 255, 255, 0);*/
  /*border-bottom: 5px solid rgba(255, 255, 255, 0);*/
  cursor: row-resize;
}

.split-pane-gutter-h:before {
  display: block;
  content: "";
  width: 50px;
  height: 2px;
  position: relative;
  top: 25%;
  left: 50%;
  /*margin-bottom: 4px;*/
  margin-left: -25px;
  border-top: 2px solid #ccc;
  /*border-bottom: 1px solid #ccc;*/
}

.split-pane-gutter-h:hover:before {
  border-color: #9d9d9d;
}

</style>