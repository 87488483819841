import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ МОДЕЛЕЙ РАДИОСТАНЦИЙ

export default {
    state() {
        return {
            // список объектов
            radioModels: [],
            // время обновления кэша new Date().getTime()
            radioModelsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            radioModelsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRadioModels(state) {
            return state.radioModels;
        },
    },
    mutations: {
        // установить новый список объектов
        setRadioModels(state, payload) {
            state.radioModels = payload;
            state.radioModelsCache = new Date().getTime()
        },
        // добавить объект
        addRadioModel(state, payload) {
            state.radioModels.push(payload)
        },
        // изменить объект
        editRadioModel(state, payload) {
            const index = state.radioModels.findIndex(radioModel => radioModel.radio_model_id == payload.radio_model_id);
            if (index >= 0) {
                state.radioModels[index] = payload;
            }
        },
        // удалить объект
        delRadioModel(state, payload) {
            const index = state.radioModels.findIndex(radioModel => radioModel.radio_model_id == payload.radio_model_id);
            state.radioModels.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchRadioModels: (context, payload) => {
            return DoGet(
                '/nav/radioModels',
                'nav/setRadioModels',
                [],
                'Запрос моделей радиостанций',
                false,
                payload && payload.force,
                context.state.radioModelsCache,
                context.state.radioModelsCacheTimeout
            )
        },

        // очищает кэш
        doClearRadioModels: (context, payload) => {
            return DoClearCache(
                'nav/setRadioModels',
                [],
                payload && payload.force,
                context.state.radioModelsCache,
                context.state.radioModelsCacheTimeout
            )
        },

        // добавление объекта
        doAddRadioModel: (context, payload) => {
            return DoPost(
                '/nav/radioModels',
                payload,
                'nav/addRadioModel',
                'Добавление модели радиостанции'
            )
        },

        // изменение объекта
        doEditRadioModel: (context, payload) => {
            return DoPut(
                '/nav/radioModels/'+payload.radio_model_id,
                payload,
                'nav/editRadioModel',
                'Изменение модели радиостанции'
            )
        },

        // удаление объекта
        doDelRadioModel: (context, payload) => {
            return DoDelete(
                '/nav/radioModels/'+payload.radio_model_id,
                'nav/delRadioModel',
                'Удаление модели радиостанции'
            )
        }
    }
}
