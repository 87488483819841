<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span>Типы маршрутов</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <div class="container position-relative h-100">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">
            <crud-form
                :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
                :buttonsEnabled="crudButtonsEnabled"
                @onAdd="onTableAdd"
                @onEdit="onTableEdit"
                @onDelete="onTableDelete"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            >
            </crud-form>
          </align-panel>
          <!-- Таблица  -->
          <align-panel align="all">
            <route-types-edit-table
                :filterText="filterText"
                :isLoading="isLoading"
                :selectedRow="selectedRow"
                @onRowSelect="onRowSelect"
                @onRowDblClick="onTableShow"
            ></route-types-edit-table>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <route-type-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </route-type-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить тип маршрута с кодом {{ id }} ?</confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>
  
</template>

<script>
import RouteTypesEditTable from "@/components/multipass/route_types/RouteTypesEditTable";
import RouteTypeDialog from "@/components/multipass/route_types/RouteTypeDialog";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    RouteTypesEditTable,
    RouteTypeDialog,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op", 
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }    
  },

  computed: {
      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            add: true, 
            edit: !!this.selectedRow, 
            delete: !!this.selectedRow, 
            refresh: true
          }
      },

      // условие отображения диалогового окна
      isDialogVisible() {
          return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
      },

      // условие отображения подтверждения удаления
      isDelConfirmVisible() {
          return this.op === 'del' && !!this.selectedRow;
      },

      // данные, которые отображаются в диалоговом окне
      dialogRow() {
        return this.op === 'add' ? {} : this.selectedRow;
      },
  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
        this.$router.replace("/multipass/routeTypes/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
        this.$router.replace("/multipass/routeTypes/edit/" + this.selectedRow.route_type_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
        this.$router.replace("/multipass/routeTypes/del/" + this.selectedRow.route_type_id);
    },

    // нажата конпка "Просмотр"
    onTableShow() {
      this.$router.replace("/multipass/routeTypes/show/" + this.selectedRow.route_type_id);
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/multipass/routeTypes");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      }
      else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/multipass/routeTypes");
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/multipass/routeTypes");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('multipass/doFetchRouteTypes', { force })

      }
      finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('multipass/doAddRouteType', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('multipass/doEditRouteType', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('multipass/doDelRouteType', this.selectedRow);
        this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
