import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ КЛАССОВ ТРАНСПОРТНЫХ СРЕДСТВ

export default {
    state() {
        return {
            // список объектов
            transportClasses: [],
            // время обновления кэша new Date().getTime()
            transportClassesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            transportClassesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getTransportClasses(state) {
            return state.transportClasses;
        },
    },
    mutations: {
        // установить новый список объектов
        setTransportClasses(state, payload) {
            state.transportClasses = payload;
            state.transportClassesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchTransportClasses: (context, payload) => {
            return DoGet(
                '/ref/transportClasses',
                'ref/setTransportClasses',
                [],
                'Запрос классов транспортных средств',
                false,
                payload && payload.force,
                context.state.transportClassesCache,
                context.state.transportClassesCacheTimeout
            )
        },

        // очищает кэш
        doClearTransportClasses: (context, payload) => {

            return DoClearCache(
                'ref/setTransportClasses',
                [],
                payload && payload.force,
                context.state.transportClassesCache,
                context.state.transportClassesCacheTimeout
            )
        },
    }
}