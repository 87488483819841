<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Событие</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>
                <div class="row">

                  <div class="form-group col">
                    <label for="scheduleEventId">Код:</label>
                    <input-integer
                        id="scheduleEventId"
                        class="form-control w-25"
                        v-model="scheduleEventId"
                        readonly
                    />
                  </div>

                </div>

                <div class="form-group">
                  <label for="scheduleEventTypeId">Тип события:</label>
                  <req-field/>
                  <schedule-event-types-select
                      id="scheduleEventTypeId"
                      v-model="scheduleEventTypeId"
                      class="form-control"
                      ref="scheduleEventTypeId"
                      autocomplete="off"
                      :readonly="op === 'show'"
                      required
                      error="Введите тип события"
                  />
                </div>

                <div class="form-group">
                  <label for="longName">Полное наименование:</label>
                  <req-field/>
                  <input-string
                      id="longName"
                      v-model="longName"
                      class="form-control"
                      ref="longName"
                      autocomplete="off"
                      :readonly="op === 'show'"
                      required
                      error="Введите полное наименование"
                  />
                </div>

                <div class="form-group">
                  <label for="shortName">Краткое наименование:</label>
                  <req-field/>
                  <input-string
                      id="shortName"
                      v-model="shortName"
                      class="form-control"
                      ref="shortName"
                      autocomplete="off"
                      :readonly="op === 'show'"
                      required
                      error="Введите краткое наименование"
                  />
                </div>

                <div>
                  <label>Признак влияния на:</label>
                </div>

                <div class="row">
                  <div class="form-group col">
                    <input-checkbox
                        id="isTotalInd"
                        v-model="isTotalInd"
                        :readonly="op === 'show'"
                    >Нарядное время
                    </input-checkbox>

                    <input-checkbox
                        id="isLineInd"
                        v-model="isLineInd"
                        :readonly="op === 'show'"
                    >Линейное время
                    </input-checkbox>

                    <input-checkbox
                        id="isNullInd"
                        v-model="isNullInd"
                        :readonly="op === 'show'"
                    >Нулевое время
                    </input-checkbox>

                    <input-checkbox
                        id="isWorkInd"
                        v-model="isWorkInd"
                        :readonly="op === 'show'"
                    >Рабочее время
                    </input-checkbox>

                  </div>

                  <div class="form-group col">

                    <input-checkbox
                        id="isDinnerInd"
                        v-model="isDinnerInd"
                        :readonly="op === 'show'"
                    >Обеденное время
                    </input-checkbox>

                    <input-checkbox
                        id="isPlanStayInd"
                        v-model="isPlanStayInd"
                        :readonly="op === 'show'"
                    >Время планового отстоя
                    </input-checkbox>

                    <input-checkbox
                        id="isStayInd"
                        v-model="isStayInd"
                        :readonly="op === 'show'"
                    >Отстойное время
                    </input-checkbox>

                    <input-checkbox
                        id="isResInd"
                        v-model="isResInd"
                        :readonly="op === 'show'"
                    >Резервное время
                    </input-checkbox>
                  </div>
                </div>
              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ScheduleEventTypesSelect from "@/components/rmt/schedule_event_types/ScheduleEventTypesSelect";

export default {
  components: {ScheduleEventTypesSelect},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      scheduleEventId: this.item.schedule_event_id,
      scheduleEventTypeId: this.item.schedule_event_type_id,
      shortName: this.item.short_name,
      longName: this.item.long_name,
      isTotalInd: this.item.is_total_ind,
      isLineInd: this.item.is_line_ind,
      isNullInd: this.item.is_null_ind,
      isWorkInd: this.item.is_work_ind,
      isDinnerInd: this.item.is_dinner_ind,
      isPlanStayInd: this.item.is_plan_stay_ind,
      isStayInd: this.item.is_stay_ind,
      isResInd: this.item.is_res_ind,
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {

        this.$emit('onOK', {
          schedule_event_id: this.scheduleEventId,
          schedule_event_type_id: this.scheduleEventTypeId,
          short_name: this.shortName,
          long_name: this.longName,
          is_total_ind: this.isTotalInd,
          is_line_ind: this.isLineInd,
          is_null_ind: this.isNullInd,
          is_work_ind: this.isWorkInd,
          is_dinner_ind: this.isDinnerInd,
          is_plan_stay_ind: this.isPlanStayInd,
          is_stay_ind: this.isStayInd,
          is_res_ind: this.isResInd,
        })
      }
    },
  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>
