import { DoDelete, DoGet, DoGetFile, DoPostFile} from "@/store/Actions";

// ХРАНИЛИЩЕ ФАЙЛОВ ДОКУМЕНТОВ ВАРИАНТОВ РАСПИСАНИЙ

export default {
    actions: {
        // запрос списка объектов
        doFetchScheduleVariantDocFile: (context, payload) => {
            return DoGetFile(
                '/rmt/scheduleVariantDocFiles/' + payload.schedule_variant_doc_id + "/" + payload.document_file_id,
                null,
                'Загрузка файла',
                false,
                payload.file_name,
            )
        },

        // запрос списка объектов
        doFetchScheduleVariantDocFiles: (context, payload) => {
            return DoGet(
                '/rmt/scheduleVariantDocFiles/' + payload.schedule_variant_doc_id,
                null,
                [],
                'Запрос файлов документа варианта расписания',
                false,
                true,
            )
        },

        // добавление объекта
        doAddScheduleVariantDocFile: (context, payload) => {
            return DoPostFile(
                '/rmt/scheduleVariantDocFiles/' + payload.schedule_variant_doc_id,
                payload.file.file,
                null,
                'Добавление файла документа варианта расписания'
            )
        },

        // удаление объекта
        doDelScheduleVariantDocFile: (context, payload) => {
            return DoDelete(
                '/rmt/scheduleVariantDocFiles/'+ payload.schedule_variant_doc_id + "/" + payload.document_file_id,
                null,
                'Удаление файла документа варианта расписания'
            )
        }
    }
}