import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ СОСТОЯИЙ РАДИОСТАНЦИЙ

export default {
    actions: {
        // запрос списка объектов
        doFetchRadioStates: (context, payload) => {
            return DoGet(
                '/nav/radioStates?radio_id=' + payload.radio_id,
                null,
                [],
                'Запрос состояний по радиостанции',
                false,
                true,
            )
        },
    }
}
