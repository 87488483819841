import { DoDelete, DoGet, DoGetFile, DoPostFile} from "@/store/Actions";

// ХРАНИЛИЩЕ ФАЙЛОВ ДОКУМЕНТОВ ВАРИАНТОВ МАРШРУТА

export default {

    actions: {
        // запрос объекта
        doFetchRouteVariantDocFile: (context, payload) => {
            return DoGetFile(
                '/multipass/routeVariantDocFiles/' + payload.route_variant_doc_id + "/" + payload.document_file_id,
                null,
                'Загрузка файла',
                false,
                payload.file_name,
            )
        },

        // запрос списка объектов
        doFetchRouteVariantDocFiles: (context, payload) => {
            return DoGet(
                '/multipass/routeVariantDocFiles/' + payload.route_variant_doc_id,
                null,
                [],
                'Запрос файлов документа варианта маршрута',
                false,
                true,
            )
        },

        // добавление объекта
        doAddRouteVariantDocFile: (context, payload) => {
            return DoPostFile(
                '/multipass/routeVariantDocFiles/' + payload.route_variant_doc_id,
                payload.file.file,
                null,
                'Добавление файла документа варианта маршрута'
            )
        },

        // удаление объекта
        doDelRouteVariantDocFile: (context, payload) => {
            return DoDelete(
                '/multipass/routeVariantDocFiles/'+ payload.route_variant_doc_id + "/" + payload.document_file_id,
                null,
                'Удаление файла документа варианта маршрута'
            )
        }
    }
}