<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to="routeVariantsLink">Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span>Документы варианта маршрута {{ routeVariantPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Связь
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="routeVariantDocFilesLink">Прикрепленные файлы</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <docs-edit-table
              :filterText="filterText"
              :selectedRow="selectedRow"
              :rowList="rowList"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></docs-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <doc-dialog
      table_name="tb_route_variant"
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </doc-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить документ с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import DocsEditTable from "@/components/doc/documents/DocsEditTable";
import DocDialog from "@/components/doc/documents/DocDialog";
import {getDocType} from "@/store/DocHelpers";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    DocDialog,
    DocsEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // документы
      docs: [],
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {doc: {}}
      } else return this.selectedRow;
    },

    //получаем строки таблицы в формате общего документа
    rowList() {
      if (this.isLoading === 'fetch') return [];

      return this.docs.map(doc => {
        const docType = getDocType(doc.doc.doc_type_id);

        doc.__id = doc.route_variant_doc_id;
        doc.doc.__docTypeName = docType.doc_type_name;
        return doc;
      });
    },

    // ссылка на варианты маршрута
    routeVariantsLink() {
      return `/multipass/routes/${this.route_id}/routeVariants`
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    //переход к файлам документа варианта маршрута
    routeVariantDocFilesLink() {
      if (this.selectedRow) return "/multipass/routes/" + this.route_id + "/routeVariants/" + this.route_variant_id + "/routeVariantDocs/" + this.selectedRow.route_variant_doc_id + "/routeVariantDocFiles";
      else return '';
    }
  },

  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeVariants/" + this.route_variant_id + "/routeVariantDocs/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeVariants/" + this.route_variant_id + "/routeVariantDocs/edit/" + this.selectedRow.route_variant_doc_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeVariants/" + this.route_variant_id + "/routeVariantDocs/del/" + this.selectedRow.route_variant_doc_id);
    },

    // нажата конпка "Просмотр"
    onTableShow() {
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeVariants/" + this.route_variant_id + "/routeVariantDocs/show/" + this.selectedRow.route_variant_doc_id);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      this.refreshData();
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      }
      else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeVariants/" + this.route_variant_id + "/routeVariantDocs");
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeVariants/" + this.route_variant_id + "/routeVariantDocs");
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/multipass/routes/" + this.route_id + "/routeVariants/" + this.route_variant_id + "/routeVariantDocs");
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // запрос маршрута
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        }); if (!route) return;
        this.route = route;

        // запрос варианта маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id,
        }); if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // типы документов
        await this.$store.dispatch('doc/doFetchDocumentTypes');

        // документы
        const docs = await this.$store.dispatch('multipass/doFetchRouteVariantDocs', {
          route_variant_id: this.route_variant_id
        }); if (!docs) return;
        this.docs = docs;

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        // добавляем поля
        item.route_variant_doc_id = null;
        item.route_variant_id = Number(this.route_variant_id);
        // запрос на сервер
        const doc = await this.$store.dispatch('multipass/doAddRouteVariantDoc', item);
        // мутируем массив
        this.selectedRow = addMutation(this.docs, 'route_variant_doc_id', doc);

      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        // добавляем поля
        item.route_variant_doc_id = this.selectedRow.route_variant_doc_id;
        item.route_variant_id = this.selectedRow.route_variant_id;
        // запрос на сервер
        const doc = await this.$store.dispatch('multipass/doEditRouteVariantDoc', item);
        // мутируем массив
        this.selectedRow = editMutation(this.docs, 'route_variant_doc_id', doc);

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрос на сервер
        const doc = await this.$store.dispatch('multipass/doDelRouteVariantDoc', this.selectedRow)
        // мутируем массив
        this.selectedRow = delMutation(this.docs, 'route_variant_doc_id', doc);

      }
      finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
