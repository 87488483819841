<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Работник транспортного предприятия</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <nav-menu class="nav-tabs" mode="single">
            <nav-item>
              <nav-link target-ref="pane1" target-method="select" active>Основные</nav-link>
            </nav-item>
            <nav-item>
              <nav-link target-ref="pane2" target-method="select">Дополнительные</nav-link>
            </nav-item>
          </nav-menu>
          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>
                <div class="row">

                  <div class="form-group col">
                    <label for="personnelId">Код:</label>
                    <input-integer
                        id="personnelId"
                        class="form-control"
                        v-model="personnelId"
                        readonly
                    />
                  </div>
                  <div class="form-group col">
                    <label for="dateIndexBegin">Нач. действ.:</label>
                    <req-field/>
                    <input-date
                        class="form-control"
                        id="dateIndexBegin"
                        v-model="dateIndexBegin"
                        ref="dateIndexBegin"
                        :readonly="op === 'show'"
                        required
                        error="Введите дату начала действия"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="isDisabled">Отключено:</label>
                    <input-switch
                        id="isDisabled"
                        v-model="isDisabled"
                        :readonly="op === 'show'"
                    >Отключено
                    </input-switch>
                  </div>

                </div>

                <div class="row">
                  <div class="form-group col">
                    <label for="personnelNum">Таб.номер:</label>
                    <req-field/>
                    <input-string
                        class="form-control"
                        id="personnelNum"
                        v-model="personnelNum"
                        ref="personnelNum"
                        :readonly="op === 'show'"
                        required
                        error="Введите табельный номер"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="depotDivision">Колонна:</label>
                    <input-integer
                        class="form-control"
                        id="depotDivision"
                        v-model="depotDivision"
                        ref="depotDivision"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="lastName">Фамилия:</label>
                  <req-field/>
                  <input-string
                      class="form-control"
                      id="lastName"
                      v-model="lastName"
                      ref="lastName"
                      :readonly="op === 'show'"
                      required
                      error="Введите фамилию"
                  />
                </div>

                <div class="form-group">
                  <label for="firstName">Имя:</label>
                  <req-field/>
                  <input-string
                      class="form-control"
                      id="firstName"
                      v-model="firstName"
                      ref="firstName"
                      :readonly="op === 'show'"
                      required
                      error="Введите имя"
                  />
                </div>

                <div class="form-group">
                  <label for="middleName">Отчество:</label>
                  <input-string
                      class="form-control"
                      id="middleName"
                      v-model="middleName"
                      ref="middleName"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="personnelJobId">Профессия:</label>
                  <req-field/>
                  <personnel-jobs-select
                      class="form-control"
                      id="personnelJobId"
                      v-model="personnelJobId"
                      ref="personnelJobId"
                      :readonly="op === 'show'"
                      required
                      error="Выберите профессию"
                  />
                </div>

                <div class="form-group">
                  <label for="depotId">Транспортное предприятие:</label>
                  <req-field/>
                  <depots-select
                      class="form-control"
                      id="depotId"
                      v-model="depotId"
                      ref="depotId"
                      :readonly="op === 'show'"
                      required
                      error="Выберите транспортное предприятие"
                  />
                </div>

              </form>
            </tab-pane>
            <tab-pane ref="pane2" class="p-2">
              <form>
                <div class="form-group">
                  <label for="licenseNumber">Номер водительского удостоверения:</label>
                  <input-string
                      class="form-control"
                      id="licenseNumber"
                      v-model="licenseNumber"
                      ref="licenseNumber"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="phoneNum">Телефон:</label>
                  <input-string
                      class="form-control"
                      id="phoneNum"
                      v-model="phoneNum"
                      ref="phoneNum"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="email">Email:</label>
                  <input-string
                      class="form-control"
                      id="email"
                      v-model="email"
                      ref="email"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="form-group">
                  <label for="birthday">Дата рождения:</label>
                  <input-date
                      class="form-control"
                      id="birthday"
                      v-model="birthday"
                      ref="birthday"
                      :readonly="op === 'show'"
                  />
                </div>

                <input-checkbox
                    id="isForeman"
                    v-model="isForeman"
                    :readonly="op === 'show'"
                    style="margin-left: 5px"
                >Бригадир
                </input-checkbox>

                <input-checkbox
                    id="isInstructor"
                    v-model="isInstructor"
                    :readonly="op === 'show'"
                    style="margin-left: 5px"
                >Инструктор
                </input-checkbox>

                <input-checkbox
                    id="isTrainee"
                    v-model="isTrainee"
                    :readonly="op === 'show'"
                    style="margin-left: 5px"
                >Стажер
                </input-checkbox>

                <label for="transportType" style="margin-top: 8px; margin-bottom: 0px">Виды транспорта:</label>
                <div class="form-group"
                     style="margin-bottom: 0; max-height: 154px; overflow-x: hidden; overflow-y: auto">
                  <input-checkbox
                      v-for="transportType in transportTypes"
                      :key="transportType.transport_type_id"
                      :id="transportType.transport_type_id.toString()"
                      v-model="transportType.__isSelected"
                      style="margin-left: 5px"
                      :readonly="op === 'show'"
                  >{{ transportType.long_name }}
                  </input-checkbox>
                </div>

              </form>
            </tab-pane>
          </tab-content>

        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputSwitch from "@/components/ui/custom/InputSwitch";
import {dateToND, dateToString, ndToDate} from "@/lib";
import InputDate from "@/components/ui/custom/InputDate";
import DepotsSelect from "@/components/ref/depots/DepotsSelect";
import PersonnelJobsSelect from "@/components/ref/personnel_jobs/PersonnelJobsSelect";
import ReqField from "@/components/ui/custom/ReqField";

export default {
  components: {
    ReqField,
    PersonnelJobsSelect,
    DepotsSelect,
    InputDate,
    InputSwitch,
  },
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel', 'transportTypes'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      personnelId: this.item.personnel_id,
      depotId: this.item.depot_id,
      personnelJobId: this.item.personnel_job_id,
      personnelNum: this.item.personnel_num,
      firstName: this.item.first_name,
      middleName: this.item.middle_name,
      lastName: this.item.last_name,
      depotDivision: this.item.depot_division,
      birthday: this.item.birthday,
      email: this.item.email,
      phoneNum: this.item.phone_num,
      licenseNumber: this.item.license_number,
      transportTypeIdList: this.item.transport_type_id_list,
      isForeman: this.item.is_foreman,
      isTrainee: this.item.is_trainee,
      isInstructor: this.item.is_instructor,
      isDisabled: this.item.is_disabled,
      dateIndexBegin: this.op == 'show' ? dateToString(ndToDate(this.item.date_index_begin)) : dateToString(),
      dateIndexEnd: null
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.$emit('onOK', {
          personnel_id: this.op === 'add' ? undefined : this.personnelId,
          depot_id: this.depotId,
          personnel_job_id: this.personnelJobId,
          personnel_num: this.personnelNum,
          first_name: this.firstName,
          middle_name: this.middleName,
          last_name: this.lastName,
          depot_division: this.depotDivision,
          birthday: this.birthday,
          email: this.email,
          phone_num: this.phoneNum,
          license_number: this.licenseNumber,
          transport_type_id_list: this.selectedTransportTypes,
          is_foreman: this.isForeman,
          is_trainee: this.isTrainee,
          is_instructor: this.isInstructor,
          is_disabled: this.isDisabled,
          date_index_begin: dateToND(new Date(this.dateIndexBegin)),
          date_index_end: null,
        })
      }
    },
  },
  computed: {
    transportTypes() {
      let arr = [];
      this.$store.getters['ref/getTransportTypes'].map(value => {
        if (this.transportTypeIdList.includes(value.transport_type_id)) {
          value.__isSelected = true;
        } else {
          value.__isSelected = false;
        }
        arr.push(value)
      });
      return arr;
    },

    selectedTransportTypes(){
      let arr = [];
      this.transportTypes.map(value => {
        if(value.__isSelected) {
          arr.push(value.transport_type_id);
        }
      });
      return arr;
    }

  }
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>