import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ГРУПП ДАТЧИКОВ

export default {
    state() {
        return {
            // список объектов
            sensorGroups: [],
            // время обновления кэша new Date().getTime()
            sensorGroupsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            sensorGroupsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getSensorGroups(state) {
            return state.sensorGroups;
        }
    },
    mutations: {
        // установить новый список объектов
        setSensorGroups(state, payload) {
            state.sensorGroups = payload;
            state.sensorGroupsCache = new Date().getTime()
        },
    },
    actions: {

        // запрос списка объектов
        doFetchSensorGroups: (context, payload) => {
            return DoGet(
                '/nav/sensorGroups',
                'nav/setSensorGroups',
                [],
                'Запрос групп датчиков',
                false,
                payload && payload.force,
                context.state.sensorGroupsCache,
                context.state.sensorGroupsCacheTimeout
            )
        },

        // запрос объекта
        doFetchSensorGroup: (context, payload) => {
            return DoGet(
                '/nav/sensorGroups/' + payload.sensor_group_id,
                null,
                [],
                'Запрос группы датчика',
                false,
                payload && payload.force,
                context.state.sensorGroupsCache,
                context.state.sensorGroupsCacheTimeout
            )
        },

        // очищает кэш
        doClearSensorGroups: (context, payload) => {
            return DoClearCache(
                'nav/setSensorGroups',
                [],
                payload && payload.force,
                context.state.sensorGroupsCache,
                context.state.sensorGroupsCacheTimeout
            )
        },

    }
}