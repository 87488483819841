import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ПРИЧИН КОМАНД ЭКСПЕРТА

export default {
    state() {
        return {
            // список объектов
            expCommandReasons: [],
            // время обновления кэша new Date().getTime()
            expCommandReasonsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            expCommandReasonsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getExpCommandReasons(state) {
            return state.expCommandReasons;
        },
    },
    mutations: {
        // установить новый список объектов
        setExpCommandReasons(state, payload) {
            state.expCommandReasons = payload;
            state.expCommandReasonsCache = new Date().getTime()
        },
    },
    actions: {

        // запрос списка объектов
        doFetchExpCommandReasons: (context, payload) => {
            return DoGet(
                '/kvr-expert/expCommandReasons',
                'kvr-expert/setExpCommandReasons',
                [],
                'Получить список всех причин команд эксперта',
                false,
                payload && payload.force,
                context.state.expCommandReasonsCache,
                context.state.expCommandReasonsCacheTimeout
            )
        },

        // запрос списка объектов
        doFetchExpCommandReason: (context, payload) => {
            return DoGet(
                '/kvr-expert/expCommandReasons/' + payload.exp_command_reason_id,
                'kvr-expert/setExpCommandReasons',
                [],
                'Получить причину команды эксперта',
                false,
                payload && payload.force,
                context.state.expCommandReasonsCache,
                context.state.expCommandReasonsCacheTimeout
            )
        },

        // очищает кэш
        doClearExpCommandReasons: (context, payload) => {
            return DoClearCache(
                'kvr-expert/setExpCommandReasons',
                [],
                payload && payload.force,
                context.state.expCommandReasonsCache,
                context.state.expCommandReasonsCacheTimeout
            )
        },
    }
}
