<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-ref-group-transport-links"> </edit-table-panel>
</template>

<script>
import {
  getDepot,
  getModel,
  getTransportClass,
  getTransportType
} from "@/store/RefHelpers";

export default {
  props: ['id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__checked",
          caption: "Вкл",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.__checked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "transport_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "__transportTypeName",
          caption: "Вид транспорта",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "state_num",
          caption: "Гос.номер",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "garage_num",
          caption: "Гараж.номер",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__modelName",
          caption: "Модель",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__transportClassName",
          caption: "Класс ТС",
          sortType: 'string',
          isFilterable: true,
          show: false
        },
        {
          field: "__depotName",
          caption: "Предприятие",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      const transport = this.$store.getters["ref/getTransport"];
      const links = this.$store.getters["ref/getTransportGroupLinks"];
      return transport.map(trn => {

        const model = getModel(trn.model_id);
        const transportType = getTransportType(trn.transport_type_id);
        const transportClass = getTransportClass(trn.transport_class_id);
        const depot = getDepot(trn.depot_id);

        trn.__id = trn.transport_id;
        trn.__modelName = model && model.long_name || '';
        trn.__transportTypeName = transportType && transportType.long_name || '';
        trn.__transportClassName = transportClass && transportClass.short_name || '';
        trn.__depotName = depot && depot.long_name || '';
        trn.__checked = links.some(link => link.transport_group_id == this.id && link.transport_id == trn.transport_id);

        return trn;
      });
    },
  },
};
</script>
