import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПНЫХ ДЛЯ УПРАВЛЕНИЯ ВОДИТЕЛЕЙ


export default {
    state() {
        return {
            // список объектов
            manageDrivers: [],
            // время обновления кэша new Date().getTime()
            manageDriversCache: null,
            // время жизни кэша в мс (null - бесконечно)
            manageDriversCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getManageDrivers(state) {
            return state.manageDrivers;
        },

    },
    mutations: {
        // установить новый список объектов
        setManageDrivers(state, payload) {
            state.manageDrivers = payload;
            state.manageDriversCache = new Date().getTime();
        },
    },
    actions: {
        // запрос списка объектов
        doFetchManageDrivers: (context, payload) => {
            return DoGet(
                '/trn/getManageDrivers/' + payload.date_index,
                'trn/setManageDrivers',
                [],
                'Запрос списка доступных для управления водителей',
                false,
                payload && payload.force,
                context.state.manageDriversCache,
                context.state.manageDriversCacheTimeout
            )
        },

        // очищает кэш
        doClearManageDrivers: (context, payload) => {
            return DoClearCache(
                'trn/setManageDrivers',
                [],
                payload && payload.force,
                context.state.manageDriversCache,
                context.state.manageDriversCacheTimeout
            )
        },
    }
}