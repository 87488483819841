<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Модель транспортного средства</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>

          <nav-menu class="nav-tabs" mode="single">
            <nav-item>
              <nav-link target-ref="pane1" target-method="select" active>Основные</nav-link>
            </nav-item>
            <nav-item>
              <nav-link target-ref="pane2" target-method="select">Дополнительные</nav-link>
            </nav-item>
          </nav-menu>
          <tab-content mode="single">
            <tab-pane active ref="pane1" class="p-2">
              <form>
                <div class="form-group">
                  <label for="modelId">Код:</label>
                  <input-integer
                      id="modelId"
                      class="form-control w-25"
                      v-model="modelId"
                      readonly
                  />
                </div>
                <div class="form-group">
                  <label for="longName">Полное наименование:</label><req-field />
                  <input-string
                      class="form-control"
                      id="longName"
                      v-model="longName"
                      ref="longName"
                      :readonly="op === 'show'"
                      required
                      error="Введите полное наименование"
                  />
                </div>
                <div class="form-group">
                  <label for="shortName">Краткое наименование:</label><req-field />
                  <input-string
                      class="form-control"
                      id="shortName"
                      v-model="shortName"
                      ref="shortName"
                      :readonly="op === 'show'"
                      required
                      error="Введите краткое наименование"
                  />
                </div>
                <div class="form-group">
                  <label for="transportTypeId">Вид транспорта:</label>
                  <transport-types-select
                      class="form-control"
                      id="transportTypeId"
                      v-model="transportTypeId"
                      ref="transportTypeId"
                      :readonly="op === 'show'"
                  />
                </div>
                <div class="form-group">
                  <label for="transportClassId">Класс транспортного средства:</label>
                  <transport-classes-select
                      class="form-control"
                      id="transportClassId"
                      v-model="transportClassId"
                      ref="transportClassId"
                      :readonly="op === 'show'"
                  />
                </div>

              </form>
            </tab-pane>
            <tab-pane ref="pane2" class="p-2">
              <form>
                <div class="form-group">
                  <label for="engineFuelTypeId">Тип топлива:</label>
                  <engine-fuel-types-select
                      class="form-control"
                      id="engineFuelTypeId"
                      v-model="engineFuelTypeId"
                      ref="engineFuelTypeId"
                      :readonly="op === 'show'"
                  />
                </div>
                <div class="form-group">
                  <label for="emissionStandardId">Экологический класс:</label>
                  <emission-standards-select
                      class="form-control"
                      id="emissionStandardId"
                      v-model="emissionStandardId"
                      ref="emissionStandardId"
                      :readonly="op === 'show'"
                  />
                </div>

                <div class="row">
                  <div class="form-group col">
                    <label for="passCountSeat">Кол-во сидячих мест:</label>
                    <input-integer
                        class="form-control"
                        id="passCountSeat"
                        v-model="passCountSeat"
                        ref="passCountSeat"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="passCount">Общее кол-во мест:</label>
                    <input-integer
                        class="form-control"
                        id="passCount"
                        v-model="passCount"
                        ref="passCount"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col">
                    <label for="doorCount">Кол-во дверей:</label>
                    <input-integer
                        class="form-control"
                        id="doorCount"
                        v-model="doorCount"
                        ref="doorCount"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="insideAreaUseful">Полезная площадь, кв.м:</label>
                    <input-float
                        class="form-control"
                        id="insideAreaUseful"
                        v-model="insideAreaUseful"
                        ref="insideAreaUseful"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col">
                    <label for="weightWithPass">Вес с пассажирами, кг:</label>
                    <input-float
                        class="form-control"
                        id="weightWithPass"
                        v-model="weightWithPass"
                        ref="weightWithPass"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="maxSpeed">Макс. скорость, км/ч:</label>
                    <input-float
                        class="form-control"
                        id="maxSpeed"
                        v-model="maxSpeed"
                        ref="maxSpeed"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col">
                    <label for="length">Длина, мм:</label>
                    <input-float
                        class="form-control"
                        id="length"
                        v-model="length"
                        ref="length"
                        :readonly="op === 'show'"
                        :min-value="-1"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="width">Ширина, мм:</label>
                    <input-float
                        class="form-control"
                        id="width"
                        v-model="width"
                        ref="width"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                  <div class="form-group col">
                    <label for="height">Высота, мм:</label>
                    <input-float
                        class="form-control"
                        id="height"
                        v-model="height"
                        ref="height"
                        :readonly="op === 'show'"
                        :min-value="1"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <input-checkbox
                      id="isAirConditioning"
                      v-model="isAirConditioning"
                      :readonly="op === 'show'"
                  >Наличие кондиционера</input-checkbox>

                  <input-checkbox
                      id="isBikeMount"
                      v-model="isBikeMount"
                      :readonly="op === 'show'"
                  >Наличие крепления для велосипедов</input-checkbox>

                  <input-checkbox
                      id="isAirHeating"
                      v-model="isAirHeating"
                      :readonly="op === 'show'"
                  >Наличие отопителя</input-checkbox>

                  <input-checkbox
                      id="isRamp"
                      v-model="isRamp"
                      :readonly="op === 'show'"
                  >Наличие пандуса</input-checkbox>

                  <input-checkbox
                      id="isLowFloor"
                      v-model="isLowFloor"
                      :readonly="op === 'show'"
                  >Низкопольный</input-checkbox>

                  <input-checkbox
                      id="isInstructorSeat"
                      v-model="isInstructorSeat"
                      :readonly="op === 'show'"
                  >Место для инструктора</input-checkbox>

                </div>
              </form>
            </tab-pane>
          </tab-content>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import TransportTypesSelect from "@/components/ref/transport_types/TransportTypesSelect";
import TransportClassesSelect from "@/components/ref/transport_classes/TransportClassesSelect";
import EngineFuelTypesSelect from "@/components/ref/engine_fuel_types/EngineFuelTypesSelect";
import EmissionStandardsSelect from "@/components/ref/emission_standards/EmissionStandardsSelect";

export default {
  components: {EmissionStandardsSelect, EngineFuelTypesSelect, TransportClassesSelect, TransportTypesSelect},
  props: ['item', 'op'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
      return {
        modelId: this.item.model_id,
        shortName: this.item.short_name,
        longName: this.item.long_name,
        transportTypeId: this.item.transport_type_id,
        transportClassId: this.item.transport_class_id,
        engineFuelTypeId: this.item.engine_fuel_type_id,
        emissionStandardId: this.item.emission_standard_id,
        doorCount: this.item.door_count,
        passCountSeat: this.item.pass_count_seat,
        passCount: this.item.pass_count,
        insideAreaUseful: this.item.inside_area_useful,
        weightWithPass: this.item.weight_with_pass,
        length: this.item.length,
        width: this.item.width,
        height: this.item.height,
        maxSpeed: this.item.max_speed,
        isAirConditioning: this.item.is_air_conditioning,
        isBikeMount: this.item.is_bike_mount,
        isAirHeating: this.item.is_air_heating,
        isRamp: this.item.is_ramp,
        isLowFloor: this.item.is_low_floor,
        isInstructorSeat: this.item.is_instructor_seat,
      };
  },
  methods: {
      onSubmit() {
        if (this.validateForm(this.$refs)) {
            this.$emit('onOK', {
              model_id: this.op === 'add' ? undefined : this.modelId,
              short_name: this.shortName,
              long_name: this.longName,
              transport_type_id: this.transportTypeId,
              transport_class_id: this.transportClassId,
              engine_fuel_type_id: this.engineFuelTypeId,
              emission_standard_id: this.emissionStandardId,
              door_count: this.doorCount,
              pass_count_seat: this.passCountSeat,
              pass_count: this.passCount,
              inside_area_useful: this.insideAreaUseful,
              weight_with_pass: this.weightWithPass,
              length: this.length,
              width: this.width,
              height: this.height,
              max_speed: this.maxSpeed,
              is_air_conditioning: this.isAirConditioning,
              is_bike_mount: this.isBikeMount,
              is_air_heating: this.isAirHeating,
              is_ramp: this.isRamp,
              is_low_floor: this.isLowFloor,
              is_instructor_seat: this.isInstructorSeat,
            })
        }
      },
  },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>