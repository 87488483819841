import {DoClearCache, DoDelete, DoGet, DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ СВЯЗЕЙ ТРАНСПОРТНЫХ ПРЕДПРИЯТИЙ С ГРУППАМИ

export default {
    state() {
        return {
            // список связей
            depotGroupLinks: [],
            // время обновления кэша new Date().getTime()
            depotGroupLinksCache: null,
            // время жизни кэша в мс (null - бесконечно)
            depotGroupLinksCacheTimeout: null
        }
    },
    getters: {
        // вернуть список связей
        getDepotGroupLinks(state) {
            return state.depotGroupLinks
        },
    },
    mutations: {
        // установить новый список связей
        setDepotGroupLinks(state, payload) {
            state.depotGroupLinks = payload;
            state.depotGroupLinksCache = new Date().getTime()
        },
        // связать объекты
        addDepotToGroup(state, payload) {
            state.depotGroupLinks.push(payload)
        },
        // удалить связь объектов
        delDepotFromGroup(state, payload) {
            const index = state.depotGroupLinks.findIndex(
                link => link.depot_group_id == payload.depot_group_id && link.depot_id == payload.depot_id
            );
            state.depotGroupLinks.splice(index, 1);
        },
    },
    actions: {
        // запрос списка связей
        doFetchDepotGroupLinks: (context, payload) => {
            return DoGet(
                '/ref/depotGroupLinks',
                'ref/setDepotGroupLinks',
                [],
                'Запрос связей траспортных предприятий с группами',
                false,
                payload && payload.force,
                context.state.depotGroupLinksCache,
                context.state.depotGroupLinksCacheTimeout                
            )
        },

        // очищает кэш
        doClearDepotGroupLinks: (context, payload) => {

            return DoClearCache(
                'ref/setDepotGroupLinks',
                [],
                payload && payload.force,
                context.state.depotGroupLinksCache,
                context.state.depotGroupLinksCacheTimeout
            )
        },

        // связывние объектов
        doAddDepotToGroup: (context, payload) => {
            return DoPost(
                '/ref/depotGroupLinks',
                payload,
                'ref/addDepotToGroup',
                'Добавление транспортного предприятия в группу'
            )
        },

        // удаление связи объектов
        doDelDepotFromGroup: (context, payload) => {
            return DoDelete(
                '/ref/depotGroupLinks/'+payload.depot_group_id+'/'+payload.depot_id,
                'ref/delDepotFromGroup',
                'Удаление транспортного предприятия из группы'
            )
        },
    }
}