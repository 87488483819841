
// ХРАНИЛИЩЕ ИТОГОВЫХ ДАННЫХ ПО ГРАФИКАМ


import {DoGet} from "@/store/Actions";

export default {

    actions: {

        // запрос данных
        doFetchResultGraphs: (context, payload) => {
            return DoGet(
                '/kvr/getResultGraphs/' + payload.date_index + '/' +payload.route_id,
                null,
                [],
                'Запрос итоговых данных по графикам',
                false,
            )
        }

    }
}