<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-asmpp-pay-trans-by-depot-day"></edit-table-panel>
</template>

<script>

import {ndToDateString} from "@/lib";

export default {
  props: ['isLoading', 'payTranByDepotDay'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__date",
          caption: "Дата",
          sortType: 'number',
          sorted: 'asc',
          isFilterable: true,
        },
        {
          field: "__weekday",
          caption: "День недели",
          sortType: 'String',
          isFilterable: true,
        },
        {
          field: "fact_trip_count",
          caption: "Кол. рейсов",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "transport_count",
          caption: "Кол. ТС",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "pay_tran_count",
          caption: "Сумм. кол. оплат",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "pay_tran_count_avg_by_transport",
          caption: "Ср. кол. оплат за сутки по ТС",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.pay_tran_count_avg_by_transport) {
              return Number(row.pay_tran_count_avg_by_transport).toFixed(2);
            }
            return 0
          },
        },
        {
          field: "pay_tran_count_avg_by_trip",
          caption: "Ср. кол. оплат за рейс",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.pay_tran_count_avg_by_trip) {
              return Number(row.pay_tran_count_avg_by_trip).toFixed(2);
            }
            return 0
          },
        },
        {
          field: "pay_tran_by_km",
          caption: "КПД-Пасс/км",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.pay_tran_by_km) {
              return Number(row.pay_tran_by_km).toFixed(2);
            }
            return 0
          },
        },
        {
          field: "pay_tran_by_hour",
          caption: "КПД-Пасс/час",
          sortType: 'number',
          isFilterable: true,
          displayFn(row) {
            if (row.pay_tran_by_hour) {
              return Number(row.pay_tran_by_hour).toFixed(2);
            }
            return 0
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.payTranByDepotDay.map((tran, index) => {

        tran.__date = ndToDateString(tran.date_index);
        if (tran.weekday_num == 1) {
          tran.__weekday = "Пн."
        } else if (tran.weekday_num == 2) {
          tran.__weekday = "Вт."
        } else if (tran.weekday_num == 3) {
          tran.__weekday = "Ср."
        } else if (tran.weekday_num == 4) {
          tran.__weekday = "Чт."
        } else if (tran.weekday_num == 5) {
          tran.__weekday = "Пт."
        } else if (tran.weekday_num == 6) {
          tran.__weekday = "Сб."
        } else if (tran.weekday_num == 7) {
          tran.__weekday = "Вс."
        }
        tran.__id = index;


        return tran;
      });
    },
  },
};
</script>
