import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ МЕТОДОВ

export default {
    state() {
        return {
            // список объектов
            methods: [],
            // время обновления кэша new Date().getTime()
            methodsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            methodsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getMethods(state) {
            return state.methods
        }
    },
    mutations: {
        // установить новый список объектов
        setMethods(state, payload) {
            state.methods = payload;
            state.methodsCache = new Date().getTime();
        }
    },
    actions: {
        // запрос списка объектов
        doFetchMethods: (context, payload) => {
            return DoGet(
                '/system/methods',
                'system/setMethods',
                [],
                'Запрос методов',
                false,
                payload && payload.force,
                context.state.methodsCache,
                context.state.methodsCacheTimeout
            )
        },

        // очищает кэш
        doClearMethods: (context, payload) => {
            return DoClearCache(
                'system/setMethods',
                [],
                payload && payload.force,
                context.state.methodsCache,
                context.state.methodsCacheTimeout
            )
        },
    }
}