import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ОСТАНОВОЧНЫХ ПАВИЛИОНОВ

export default {
    actions: {
        // запрос объекта
        doFetchStopPavilion: (context, payload) => {
            return DoGet(
                '/multipass/stopPavilions/' + payload.stop_pavilion_id,
                null,
                {},
                'Запрос остановочного павилиона',
                false,
                true,
                null,
                null
            )
        },

        // запрос списка объектов
        doFetchStopPavilions: (context, payload) => {
            return DoGet(
                '/multipass/stopPavilions?stop_id=' + payload.stop_id,
                null,
                [],
                'Запрос остановочных павилионов',
                false,
                true,
                null,
                null
            )
        },

        // добавление объекта
        doAddStopPavilion: (context, payload) => {
            return DoPost(
                '/multipass/stopPavilions',
                payload,
                null,
                'Добавление остановочного павилиона'
            )
        },

        // изменение объекта
        doEditStopPavilion: (context, payload) => {
            return DoPut(
                '/multipass/stopPavilions/'+ payload.stop_pavilion_id,
                payload,
                null,
                'Изменение остановочного павилиона'
            )
        },

        // удаление объекта
        doDelStopPavilion: (context, payload) => {
            return DoDelete(
                '/multipass/stopPavilions/'+ payload.stop_pavilion_id,
                null,
                'Удаление остановочного павилиона'
            )
        }
    }
}