import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДАННЫХ НЕОБРАБОТАННОГО ПАССАЖИРОПОТОКА ЗА СУТКИ ПО ТС ОТ ДАТЧИКОВ ЗА ПЕРИОД ДАТ

export default {
    actions: {
        // запрос списка объектов
        doFetchPassengerTrafficDayByTransportNavSum: (context, payload) => {
            return DoGet(
                '/asmpp/passengerTrafficDayByTransportNavSum?depot_id=' + payload.depot_id +
                '&date_index=' + payload.date_index,
                null,
                [],
                'Запрос данных необработанного пассажиропотока за сутки по тс за период дат',
                false,
                true
            )
        },
    }
}
