import {DoPost} from "@/store/Actions";
import store from "@/store/Store";


// ХРАНИЛИЩЕ ОТЧЕТОВ МОДУЛЯ KVR

export default {
    actions: {
        // запрос отчета 0005
        doMakeReport0005: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0005',
                payload,
                null,
                'Запрос на формирование отчета о выполнении параметров пассажирских перевозок (квр-1)',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0006
        doMakeReport0006: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0006',
                payload,
                null,
                'Сформировать сводный отчет нарушений параметров пассажирских перевозок (квр-2).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0007
        doMakeReport0007: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0007',
                payload,
                null,
                'Сформировать отчет о выполнении автобусо-километров по маршрутам (квр-3).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0008
        doMakeReport0008: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0008',
                payload,
                null,
                'Сформировать отчет точности выполнения расписания отправления в рейс на маршруте (квр-4).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0009
        doMakeReport0009: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0009',
                payload,
                null,
                'Сформировать отчет точности выполнения расписания отправления в рейс на маршруте (квр-5).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0010
        doMakeReport0010: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0010',
                payload,
                null,
                'Сформировать отчет нарушений отправления в рейс по маршруту (квр-6).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0011
        doMakeReport0011: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0011',
                payload,
                null,
                'Сформировать отчет нарушений открытия движения на маршруте (квр-7).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0012
        doMakeReport0012: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0012',
                payload,
                null,
                'Сформировать отчет нарушений закрытия движения на маршруте (квр-8).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0013
        doMakeReport0013: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0013',
                payload,
                null,
                'Сформировать отчет нарушений схемы движения на маршруте (квр-9).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0014
        doMakeReport0014: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0014',
                payload,
                null,
                'Сформировать отчет об осуществлении регулярных перевозок по регулируемым тарифам (квр-10).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0015
        doMakeReport0015: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0015',
                payload,
                null,
                'Сформировать отчет о фактической транспортной работе (квр-11).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0016
        doMakeReport0016: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0016',
                payload,
                null,
                'Сформировать итоговый отчет о выполнении плана перевозок (квр-12).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0017
        doMakeReport0017: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0017',
                payload,
                null,
                'Сформировать итоговый отчет о работе маршрутов (квр-13).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0018
        doMakeReport0018: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0018',
                payload,
                null,
                'Сформировать итоговый отчет о работе маршрутов (квр-14).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0019
        doMakeReport0019: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0019',
                payload,
                null,
                'Сформировать итоговый отчет о работе предприятий на маршрутах (квр-15).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0020
        doMakeReport0020: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0020',
                payload,
                null,
                'Сформировать итоговый отчет о выполнении работы на маршрутах (квр-16).',
                false,
                true,
            );
            console.log(response)
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0021
        doMakeReport0021: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0021',
                payload,
                null,
                'Сформировать итоговый отчет о выполнении работы предприятиями на маршрутах (квр-17).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0022
        doMakeReport0022: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id;
            let response = await DoPost(
                '/kvr/reports/makeReport0022',
                payload,
                null,
                'Сформировать отчет о количественных и качественных показателях выполнения работ (квр-18).',
                false,
                true,
            );

            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0023
        doMakeReport0023: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id;
            let response = await DoPost(
                '/kvr/reports/makeReport0023',
                payload,
                null,
                'Сформировать отчет об объеме выполненной транспортной работы (квр-19).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0024
        doMakeReport0024: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0024',
                payload,
                null,
                'Сформировать оперативную справку о работе пассажирского транспорта. Сводная по предприятиям (квр-20).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0025
        doMakeReport0025: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0025',
                payload,
                null,
                'Сформировать отчет по выполнению плана перевозок. Сводная по предприятиям (квр-21).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0026
        doMakeReport0026: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0026',
                payload,
                null,
                'Сформировать оперативную справку выполнения плана перевозок. Сводная по предприятиям (квр-22).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0027
        doMakeReport0027: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0027',
                payload,
                null,
                'Сформировать оперативную справку о работе пассажирского транспорта. Сводная по маршрутам (квр-23).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0028
        doMakeReport0028: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0028',
                payload,
                null,
                'Сформировать оперативную справку о работе пассажирского транспорта. Сводная по регионам (квр-24).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0029
        doMakeReport0029: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0029',
                payload,
                null,
                'Сформировать отчет об объеме выполненной транспортной работы (квр-19к).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0030
        doMakeReport0030: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0030',
                payload,
                null,
                'Сформировать отчет об объеме выполненной транспортной работы (квр-19п).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0031
        doMakeReport0031: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0031',
                payload,
                null,
                'Сформировать отчет о фактической транспортной работе (квр-11и).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0044
        doMakeReport0044: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0044',
                payload,
                null,
                'Сформировать оперативную справку о работе пассажирского транспорта. Сводная по графикам (квр-25).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0044
        doMakeReport0045: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id ;
            let response = await DoPost(
                '/kvr/reports/makeReport0045',
                payload,
                null,
                'Сформировать отчет о фактическом прохождении транспортными средствами остановочного пункта (квр-26).',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

    }
}
