<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">

      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span><router-link to="/asmpp/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span>Пассажиропоток за сутки по графикам {{ routesWithDatePageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">

          <crud-form
              :buttonsExist="{ refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!--            &lt;!&ndash; Кнопка перехода к Рейсовому пассажиропотоку по часам суток   :disabled="linkDisabled" &ndash;&gt;-->
            <!--            <template #leftCustomButton>-->
            <!--              <button  @click="passengerTrafficFactTripByHoursLink" type="button" class="btn dropdown-toggle btn-primary" >-->
            <!--                Рейсовый пассажиропоток по часам суток-->
            <!--              </button>-->
            <!--            </template>-->

          </crud-form>

        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <passenger-traffic-day-by-graph-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :rowArr="rows"
              :routes="routes"
              @onRowSelect="onRowSelect"
              @setLoading="setLoading"
          ></passenger-traffic-day-by-graph-edit-table>
        </align-panel>
        <align-panel align="bottom">
          <!-- Форма с кнопками под правой таблицей-->
          <card>
            <card-body class="p-1">
              <table class="table table-sm" style="margin-bottom:0">
                <thead class="thead-dark">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Кол. пасс.</th>
                  <th scope="col">Пасс.*Км</th>
                  <th scope="col">Сумма оплат</th>
                  <th scope="col">Кол оплат</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in getBottomPanelItems" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.passenger_count }}</td>
                  <td>{{ item.passenger_traffic }}</td>
                  <td>{{ item.pay_sum }}</td>
                  <td>{{ item.pay_tran_count }}</td>
                </tr>
                </tbody>
              </table>
            </card-body>
          </card>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>


import PageMixin from "@/pages/share/PageMixin";
import PassengerTrafficDayByGraphEditTable
  from "@/components/asmpp/passenger_traffic_day_by_graph/PassengerTrafficDayByGraphEditTable";

export default {
  components: {PassengerTrafficDayByGraphEditTable},
  mixins: [PageMixin],

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрутов
    "routes_id",
    // дата начала периода
    "date_index_begin",
    // дата конца периода
    "date_index_end",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // маршруты
      routes: [],
      // список рейсов
      rows: [],
    }
  },

  computed: {

    // расчет параметров для таблицы "Итого" снизу
    getBottomPanelItems(){
      let items = [];
      let passenger_count = 0;
      let passenger_traffic = 0;
      let pay_sum = 0;
      let pay_tran_count = 0;

      for(let i = 0; i < this.rows.length; i++) {
        passenger_count = passenger_count + this.rows[i].passenger_count;
        passenger_traffic = passenger_traffic + this.rows[i].passenger_traffic;
        pay_sum = pay_sum + this.rows[i].pay_sum;
        pay_tran_count = pay_tran_count +  this.rows[i].pay_tran_count
        ;
      }
      items.push({
        name: "Суммарно",
        passenger_count: passenger_count,
        passenger_traffic: Number(passenger_traffic).toFixed(1),
        pay_sum: pay_sum,
        pay_tran_count: pay_tran_count,
      })
      return items;
    },

    isErrorsDialogVisible() {
      return this.marshVariantsErrorArr.length != 0;
    },

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // условие отключения кнопки перехода к рейсовому пассажиропотоку
    linkDisabled() {
      return !this.selectedRow;
    },

  },
  methods: {

    setLoading(item) {
      this.isLoading = item;
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {
        this.routes = [];
        let rows = [];
        for (let i = 0; i < this.routes_id.length; i++) {
          // загружаем пассажиропоток по маршруту
          let routeRows = await this.$store.dispatch('asmpp/doFetchPassengerTrafficDayByGraph', {
            route_id: this.routes_id[i],
            date_index_begin: this.date_index_begin,
            date_index_end: this.date_index_end,
          });
          rows.push(...routeRows)

          // запрос маршрута
          const route = await this.$store.dispatch('multipass/doFetchRoute', {
            route_id: this.routes_id[i],
          });
          if (!route) return;
          this.routes.push(route);

        }

        this.rows = rows;

        await this.$store.dispatch('ref/doFetchDepots');
        await this.$store.dispatch('ref/doFetchTransport');

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
