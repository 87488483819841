<template>
  <align-container id="container">
    <align-panel align="all">
      <div>
        <align-container>
          <align-panel align="all">
            <align-container>
              <div class="card-body">
                <align-panel align="all" style="overflow-y: auto; overflow-x: auto">
                  <table border="1" cellpadding="0" style="overflow-y: auto;">
                    <!--  line-height: 1; -->
                    <thead>
                    <tr
                        v-for="headRow in headList"
                        :key="headRow.__head_id"
                        align="center"
                    >
                      <th
                          v-for="headCol in headRow.__cols"
                          :key="headCol.column_num"
                      >
<!--                        Первая часть названия остановки в заголовке таблицы-->
                        <div v-if="headCol.column_num % 2 === 0" class="text-center"
                             style="background: lightgreen !important;">
                          <label>{{
                              headCol.trip_complex.__stop_title_begin_part_one
                            }}{{ headCol.trip_complex.__stop_title_begin_part_two }}</label>
                        </div>
<!--                        Вторая часть названия остановки в заголовке таблицы-->
                        <div v-else class="text-center" style="background: lightpink !important;">
                          <label>{{
                              headCol.trip_complex.__stop_title_begin_part_one
                            }}{{ headCol.trip_complex.__stop_title_begin_part_two }}</label>
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="tripsRow in rowList"
                        :key="tripsRow.__row_id"
                    >
                      <td
                          v-for="colItem in tripsRow.__cols"
                          :key="colItem.column_num"
                          class="text-left"
                          :style="'padding-top: 2px !important; padding-bottom: 2px !important; width: ' + tdWidth + 'px'"
                          @click="selectTrip($event, colItem.trip_complex, tripsRow.__row_id, colItem.column_num)"
                      >
                        <button v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                                @click="delLeftTrip($event, colItem.trip_complex)"
                                style="height: 50% !important; border: 0px !important; padding: 0px !important; background-color: red !important; font-weight: bolder !important; border-bottom-right-radius: 10px !important; border-top-right-radius: 10px !important;"
                                title="Удаление рейса слева"
                        >&lt;
                        </button>
                        <span v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                              style="color: white !important;">|</span>
                        <button v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                                @click="startTrafficGraph($event, colItem)"
                                style="height: inherit !important; border: 0px !important; padding: 0px !important; background-color: dodgerblue !important; font-weight: bolder !important; border-bottom-left-radius: 10px !important; border-bottom-right-radius: 10px !important; border-top-left-radius: 10px !important; border-top-right-radius: 10px !important;"
                                title="Запуск движения на графике"
                        >&darr;
                        </button>
                        <span v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                              style="color: white !important;">|</span>
                        <button v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                                @click="minusMin($event, colItem.trip_complex)"
                                style="height: inherit !important; border: 0px !important; padding: 0px !important; background-color: green !important; font-weight: bolder !important; border-bottom-left-radius: 10px !important; border-top-left-radius: 10px !important;"
                                title="Уменьшение стоянки"
                        >&ndash;
                        </button>
                        <input-time-trip-begin
                            v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                            v-model="colItem.trip_complex.step_time_begin"
                            size="5"
                            maxlength="5"
                            @blur="setTripBegin($event, colItem.trip_complex)"
                            style="height: inherit !important; border: 0px !important; padding: 0px !important; width: 40px !important; min-width: 40px !important; max-width: 40px !important; line-height: 0px !important;"
                            title="Корректировка времени убытия"
                        ></input-time-trip-begin>
                        <input-integer
                            v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                            v-model="colItem.trip_complex.stop_time"
                            size="3"
                            maxlength="3"
                            @blur="setStop($event, colItem.trip_complex)"
                            style="height: inherit !important; border: 0px !important; padding: 0px !important; width: 30px !important; min-width: 30px !important; max-width: 30px !important; line-height: 0px !important; text-align: center !important; color: green !important;"
                            title="Корректировка стоянки"
                        ></input-integer>
                        <button v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                                @click="plusMin($event, colItem.trip_complex)"
                                style="height: inherit !important; border: 0px !important; padding: 0px !important; background-color: green !important; font-weight: bolder !important; border-bottom-right-radius: 10px !important; border-top-right-radius: 10px !important;"
                                title="Увеличение стоянки"
                        >+
                        </button>
                        <span v-if="colItem.trip_complex.null_trip_type_id > 0">
                          <button v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                                  @click="openTripTypeDialog($event, colItem)"
                                  style="height: inherit !important; border: 0px !important; padding: 0px !important; background-color: yellowgreen !important; border-bottom-left-radius: 10px !important; border-top-left-radius: 10px !important;"
                                  :title="getTripTypeTitle(colItem.trip_complex, 'null')"
                          >{{ colItem.trip_complex.__null_direction_title }}</button>
                        </span>
                        <span v-else>
                          <label v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                                 class="placeholder-td">></label>
                        </span>
                        <span v-if="getTripTypeShort(colItem.trip_complex.__trip_type_short).length > 1">
                          <label v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                                 class="placeholder-td">{{
                              (colItem.trip_complex.__trip_type_short.length > 2 ? "" : "_")
                            }}</label>
                          <button v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                                  @click="openTripTypeDialog($event, colItem)"
                                  style="height: inherit !important; border: 0px !important; padding: 0px !important; background-color: #e0e0e0 !important; font-family: monospace !important; border-bottom-left-radius: 10px !important; border-bottom-right-radius: 10px !important; border-top-left-radius: 10px !important; border-top-right-radius: 10px !important;"
                                  :title="getTripTypeTitle(colItem.trip_complex, 'tripType')"
                          >{{ colItem.trip_complex.__trip_type_short }}
                          </button>
                        </span>
                        <span v-else>
                          <label v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                                 class="placeholder-td">{{
                              (colItem.trip_complex.__schedule_event_title.length > 1 ? "_" : "__")
                            }}</label>
                          <button v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                                  @click="openTripTypeDialog($event, colItem)"
                                  style="height: inherit !important; border: 0px !important; padding: 0px !important; background-color: #e0e0e0 !important; font-family: monospace !important; border-bottom-left-radius: 10px !important; border-bottom-right-radius: 10px !important; border-top-left-radius: 10px !important; border-top-right-radius: 10px !important;"
                                  :title="getTripTypeTitle(colItem.trip_complex, 'scheduleEvent')"
                          >{{ colItem.trip_complex.__schedule_event_title }}
                          </button>
                        </span>
                        <button v-if="((tripsRow.__row_id == selectedRow) && (colItem.column_num == selectedCol))"
                                @click="extensionTrafficGraph($event, colItem.trip_complex)"
                                style="height: inherit !important; border: 0px !important; padding: 0px !important; background-color: dodgerblue !important; font-weight: bolder !important; border-bottom-left-radius: 10px !important; border-top-left-radius: 10px !important;"
                                title="Продление движения по графику"
                        >&gt;
                        </button>
<!--         placeholder вместо названия графика                -->
                        <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol))"
                               class="placeholder-td">{{
                            colItem.trip_complex.graph_title.length > 1 ? (colItem.trip_complex.graph_title.length > 2 ? "" : "_") : (colItem.trip_complex.graph_title.length > 0 ? "__" : "___")
                          }}</label>
<!--         текст с названием графика                 -->
                        <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol))"
                               class="simple-graph">{{ colItem.trip_complex.graph_title }}</label>
<!--         placeholder для корректного отображения формы                  -->
                        <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol))"
                               class="placeholder-td">_</label>
<!--         Время графика                  -->
                          <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol)) && colItem.trip_complex.graph != 0"
                          >{{ colItem.trip_complex.__time_begin }}</label>
                          <label v-else-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol))"
                                 class="placeholder-td">{{
                              "_____"
                            }}</label>

                        <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol))"
                               class="placeholder-td">{{
                            ((this.tableMode == 'stand' || this.tableMode == 'standAndInterval') && (colItem.trip_complex.graph != 0)) ?
                                (colItem.trip_complex.stop_time > 9 ? (colItem.trip_complex.stop_time > 99 ? "" : "_") : "__") : "___"
                          }}</label>
                        <label
                            v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol)) && (this.tableMode == 'stand' || this.tableMode == 'standAndInterval') && (colItem.trip_complex.graph != 0)"
                            class="stop-time">{{ colItem.trip_complex.stop_time }}</label>

                        <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol))"
                               class="placeholder-td">{{
                            ((this.tableMode == 'interval' || this.tableMode == 'standAndInterval') && (colItem.trip_complex.graph != 0) && (colItem.trip_complex.interval_before > 0)) ?
                                (colItem.trip_complex.interval_before > 9 ? (colItem.trip_complex.interval_before > 99 ? "" : "_") : "__") : "___"
                          }}</label>
                        <label
                            v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol)) && (this.tableMode == 'interval' || this.tableMode == 'standAndInterval') && (colItem.trip_complex.graph != 0) && (colItem.trip_complex.interval_before > 0)"
                            class="interval-time">{{ colItem.trip_complex.interval_before }}</label>
                        <span v-if="colItem.trip_complex.null_trip_type_id > 0">
                          <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol))"
                                 class="null-trip"
                                 style="font-weight: bolder !important;"
                                 :title="getTripTypeTitle(colItem.trip_complex, 'null')"
                          >{{ colItem.trip_complex.__null_direction_title }}</label>
                        </span>
                        <span v-else>
                          <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol))"
                                 class="placeholder-td">_</label>
                        </span>
                        <span v-if="getTripTypeShort(colItem.trip_complex.trip_type_sign).length > 1">
                          <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol))"
                                 class="type-trip-to"
                                 :title="getTripTypeTitle(colItem.trip_complex, 'tripType')"
                          >{{
                              getTripTypeShort(colItem.trip_complex.trip_type_sign)
                            }}</label>
                          <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol))"
                                 class="placeholder-td"
                          >{{
                              getTripTypeShort(colItem.trip_complex.trip_type_sign).length == 3 ? "" : "___"
                            }}</label>
                        </span>
                        <span v-else>
                          <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol)) && (colItem.trip_complex.graph == 0)"
                                 class="placeholder-td"
                                 :title="getTripTypeTitle(colItem.trip_complex, 'tripType')"
                          >___
                          </label>
<!--                          placeholder вместо шага по расписанию -->
                          <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol)) && colItem.trip_complex.graph != 0"
                                 class="placeholder-td"
                                 :title="getTripTypeTitle(colItem.trip_complex, 'tripType')"
                          >{{
                              (colItem.trip_complex.__schedule_event_title).length > 1 ? "_" : "__"
                            }}</label>
<!--                          Название шага по расписанию -->
                          <label v-if="((tripsRow.__row_id != selectedRow) || (colItem.column_num != selectedCol)) && colItem.trip_complex.graph != 0"
                                 class="type-trip-to"
                                 :title="getTripTypeTitle(colItem.trip_complex, 'scheduleEvent')"
                          >{{
                              colItem.trip_complex.__schedule_event_title
                            }}</label>
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </align-panel>
              </div>
            </align-container>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>

  <release-from-park-dialog
      v-if="isRealeaseDialogVisible"
      :isDialogVisible="isRealeaseDialogVisible"
      :dialogStyle="realeaseDialogStyle"
      :direction="direction"
      :dialogTitle="dialogTitle"
      :tripTypes="tripTypes"
      :dialogSpecifiedTrip="selectedTrip"
      @hideDialog="hideReleaseDialog"
      @setGraph="setGraph"
  >
  </release-from-park-dialog>

  <appointment-dialog
      v-if="isAppointmentDialogVisible"
      :direction="direction"
      :dialogStyle="appointmentDialogStyle"
      :tripTypes="tripTypes"
      :dialogSpecifiedTrip="selectedTrip"
      @hideDialog="hideAppointmentDialog"
      @changeTripType="changeTripType"
  >
  </appointment-dialog>

</template>

<script>

import {getTimeFormat} from "@/lib";
import InputInteger from "@/components/ui/custom/InputInteger";
import InputTimeTripBegin from "@/components/rmtgen/InputTimeTripBegin";
import {getStop} from "@/store/MultipassHelpers";
import ReleaseFromParkDialog from "@/components/rmtgen/ReleaseFromParkDialog";
import AppointmentDialog from "@/components/rmtgen/AppointmentDialog";
import {getScheduleEvent} from "@/store/RmtHelpers";
import {nextTick} from "vue";

export default {
  components: {
    AppointmentDialog,
    ReleaseFromParkDialog,
    InputInteger,
    InputTimeTripBegin,
  },
  emits: ['doEditGraph', 'doEditTripBegin', 'doEditStop', 'doDelLeftTrip', 'doStartTrafficGraph', 'doMinusMin', 'doPlusMin', 'doChangeTripType', 'doExtensionTrafficGraph'],
  props: ['rows', 'tableMode', 'route_variant_id', 'dialogTitle', 'tripTypes', 'greenStop', 'pinkStop', 'tripTypeCards'],
  data() {
    return {
      // выбранный рейс
      selectedTrip: null,
      selectedRow: 0,
      selectedCol: 0,
      errorsArr: [],
      // видимость диалога Выпуск из парка
      isRealeaseDialogVisible: null,
      // видимость диалога Выпуск из парка
      isAppointmentDialogVisible: null,
      // позиция диалога
      realeaseDialogStyle: '',
      // позиция диалога
      appointmentDialogStyle: '',
      // направление выбранной ячейки
      direction: '',
      // настройки превыбора в диалогах
      dialogSettings: {},
      // ширина ячейки
      tdWidth: 0,
    }
  },

  watch: {

    async rows() {
      await nextTick();
      let maxWidth = 0;
      let arr = document.getElementsByTagName("td");
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].offsetWidth > maxWidth) {
          maxWidth = arr[i].offsetWidth;
        }
      }
      this.tdWidth = maxWidth+1;
    }

  },

  computed: {

    rowList() {
      let resultArr = [];
      let colColumn = 0;
      // формируем итоговый список строк
      this.rows.map(tripsRow => {
        // определяем название конечной остановки
        tripsRow.__row_id = tripsRow.row_num;
        let columnArray = tripsRow.trip_table_columns;
        for (let colItem of columnArray) {
          // Object.keys(colItem.trip_complex).forEach(key => {
          //   if(key === "trip_type_sign") {
          //     colItem.trip_complex[key] = "";
          //   } else if (key === "graph_title") {
          //     colItem.trip_complex[key] = "";
          //   } else {
          //     colItem.trip_complex[key] = 0;
          //   }
          // })
          colItem.trip_complex.__time_begin = getTimeFormat(colItem.trip_complex.step_time_begin);
          colItem.trip_complex.__trip_type_short = (colItem.trip_complex.trip_type_sign.includes("AB", colItem.trip_complex.trip_type_sign.length - 2) ? (colItem.trip_complex.trip_type_sign.substring(0, colItem.trip_complex.trip_type_sign.length - 2).includes("00", 0) ? "00" : colItem.trip_complex.trip_type_sign.substring(0, colItem.trip_complex.trip_type_sign.length - 2)) : colItem.trip_complex.trip_type_sign.includes("BA", colItem.trip_complex.trip_type_sign.length - 2) ? (colItem.trip_complex.trip_type_sign.substring(0, colItem.trip_complex.trip_type_sign.length - 2).includes("00", 0) ? "00" : colItem.trip_complex.trip_type_sign.substring(0, colItem.trip_complex.trip_type_sign.length - 2)) : colItem.trip_complex.trip_type_sign);
          if (colItem.trip_complex.schedule_event_id == 0) {
            colItem.trip_complex.__schedule_event_title = "КР";
          } else if (colItem.trip_complex.schedule_event_id == 4) {
            colItem.trip_complex.__schedule_event_title = "Т";
          } else if (colItem.trip_complex.schedule_event_id == 5) {
            colItem.trip_complex.__schedule_event_title = "О";
          } else if (colItem.trip_complex.schedule_event_id == 6) {
            colItem.trip_complex.__schedule_event_title = "Р";
          } else if (colItem.trip_complex.schedule_event_id == 7) {
            colItem.trip_complex.__schedule_event_title = "П";
          } else if (colItem.trip_complex.schedule_event_id == 8) {
            colItem.trip_complex.__schedule_event_title = "З";
          } else if (colItem.trip_complex.schedule_event_id == 9) {
            colItem.trip_complex.__schedule_event_title = "К";
          } else if (colItem.trip_complex.schedule_event_id == 10) {
            colItem.trip_complex.__schedule_event_title = "С";
          } else if (colItem.trip_complex.schedule_event_id == 11) {
            colItem.trip_complex.__schedule_event_title = "Е";
          } else if (colItem.trip_complex.schedule_event_id == 12) {
            colItem.trip_complex.__schedule_event_title = "В";
          } else if (colItem.trip_complex.schedule_event_id == 13) {
            colItem.trip_complex.__schedule_event_title = "И";
          } else {
            colItem.trip_complex.__schedule_event_title = "";
          }
          if (colItem.trip_complex.null_direction == 0) {
            colItem.trip_complex.__null_direction_title = ">";
          } else {
            colItem.trip_complex.__null_direction_title = "<";
          }
          columnArray[colItem.column_num - 1] = colItem;
        }
        tripsRow.__cols = columnArray;
        resultArr.push(tripsRow);
        colColumn = resultArr[0].trip_table_columns.length;
      });
      let prevTimeA = 0;
      let prevTimeB = 0;
      for (let i = 0; i < colColumn; i++) {
        for (let j = 0; j < resultArr.length; j++) {
          if (resultArr[j].trip_table_columns[i].trip_complex.graph > 0) {
            if (resultArr[j].trip_table_columns[i].trip_complex.direction > 0) {
              if (prevTimeB === 0) {
                prevTimeB = resultArr[j].trip_table_columns[i].trip_complex.step_time_begin;
                resultArr[j].trip_table_columns[i].trip_complex.interval_before = 0;
              } else {
                if (resultArr[j].trip_table_columns[i].trip_complex.schedule_event_id != 1) {
                  resultArr[j].trip_table_columns[i].trip_complex.interval_before = 0;
                } else {
                  resultArr[j].trip_table_columns[i].trip_complex.interval_before = Math.round( (resultArr[j].trip_table_columns[i].trip_complex.step_time_begin - prevTimeB) / 60);
                  prevTimeB = resultArr[j].trip_table_columns[i].trip_complex.step_time_begin;
                }
              }
            } else {
              if (prevTimeA === 0) {
                prevTimeA = resultArr[j].trip_table_columns[i].trip_complex.step_time_begin;
                resultArr[j].trip_table_columns[i].trip_complex.interval_before = 0;
              } else {
                if (resultArr[j].trip_table_columns[i].trip_complex.schedule_event_id != 1) {
                  resultArr[j].trip_table_columns[i].trip_complex.interval_before = 0;
                } else {
                  resultArr[j].trip_table_columns[i].trip_complex.interval_before = Math.round((resultArr[j].trip_table_columns[i].trip_complex.step_time_begin - prevTimeA) / 60);
                  prevTimeA = resultArr[j].trip_table_columns[i].trip_complex.step_time_begin;
                }
              }
            }
          }
        }
      }
      return resultArr;
    },

    headList() {
      let headArr = [];
      let greenStopBegin = null;
      let pinkStopBegin = null;
      let stopTitlePartOne = "";
      let stopTitlePartTwo = "";

      // получаем остановки
      if (this.greenStop) {
        greenStopBegin = getStop(this.greenStop.stop_id);
      }
      if (this.pinkStop) {
        pinkStopBegin = getStop(this.pinkStop.stop_id);
      }

      // формируем итоговый список строк ()
      this.rows.map(headRow => {
        if (headRow.row_num == 1) {
          headRow.__head_id = headRow.row_num;
          let headColArray = headRow.trip_table_columns;

          for (let headColItem of headColArray) {

            if (headColItem.column_num % 2 === 0) {
              if (!this.greenStop) {
                stopTitlePartOne = "Конечная А";
              } else {
                stopTitlePartOne = '';
                stopTitlePartTwo = '';
                if (greenStopBegin.short_name.length <= 16) {
                  stopTitlePartOne = greenStopBegin.short_name.substring(0, 17);
                }else {
                  stopTitlePartOne = greenStopBegin.short_name.substring(0, 12);
                  stopTitlePartTwo = '…' + greenStopBegin.short_name.substring(greenStopBegin.short_name.length - 4, greenStopBegin.short_name.length);
                }
              }
            } else {
              if (!this.greenStop) {
                stopTitlePartOne = "Конечная Б";
              } else {
                stopTitlePartOne = '';
                stopTitlePartTwo = '';
                if (pinkStopBegin.short_name.length <= 16) {
                  stopTitlePartOne = pinkStopBegin.short_name.substring(0, 17);
                }else {
                  stopTitlePartOne = pinkStopBegin.short_name.substring(0, 12);
                  stopTitlePartTwo = '…' + pinkStopBegin.short_name.substring(pinkStopBegin.short_name.length - 4, pinkStopBegin.short_name.length);
                }
              }
            }

            headColItem.trip_complex.__stop_title_begin_part_one = stopTitlePartOne;
            headColItem.trip_complex.__stop_title_begin_part_two = stopTitlePartTwo;
            headColArray[headColItem.column_num - 1] = headColItem;
          }
          headRow.__cols = headColArray;
          headArr.push(headRow);
        }
      });
      return headArr;
    },

    // headList() {
    //   let headArr = [];
    //   let stopBegin = null;
    //   let stopTitlePartOne = "";
    //   let stopTitlePartTwo = "";
    //   // формируем итоговый список строк
    //   this.rows.map(headRow => {
    //     if (headRow.row_num == 1) {
    //       headRow.__head_id = headRow.row_num;
    //       let headColArray = headRow.trip_table_columns;
    //       for (let headColItem of headColArray) {
    //         stopBegin = getStop(headColItem.trip_complex.stop_id_begin);
    //         if (!stopBegin) {
    //           if (headColItem.column_num % 2 === 0) {
    //             stopTitlePartOne = "Конечная А";
    //           }
    //           else {
    //             stopTitlePartOne = "Конечная Б";
    //           }
    //         }
    //         else {
    //           stopTitlePartOne = stopBegin.short_name.substring(0, 12);
    //           stopTitlePartTwo = stopBegin.short_name.substring(stopBegin.short_name.length - 4, stopBegin.short_name.length);
    //         }
    //         headColItem.trip_complex.__stop_title_begin_part_one = stopTitlePartOne;
    //         headColItem.trip_complex.__stop_title_begin_part_two = stopTitlePartTwo;
    //         headColArray[headColItem.column_num - 1] = headColItem;
    //       }
    //       headRow.__cols = headColArray;
    //       headArr.push(headRow);
    //     }
    //   });
    //   return headArr;
    // },

  },

  methods: {

    // получаем текст для всплывающих подсказок
    getTripTypeTitle(trip_complex, type) {
      if (type == 'null') {
        let firstStop = getStop(this.tripTypeCards[trip_complex.null_trip_type_id][0].stop_id);
        let lastStop = getStop(this.tripTypeCards[trip_complex.null_trip_type_id][this.tripTypeCards[trip_complex.null_trip_type_id].length - 1].stop_id);
        return '(' + this.getTripTypeShort(trip_complex.null_trip_type_sign) + ') ' + firstStop.long_name + ' - ' + lastStop.long_name;
      } else if (type == 'tripType' && trip_complex.trip_type_id != 0) {
        let firstStop = getStop(this.tripTypeCards[trip_complex.trip_type_id][0].stop_id);
        let lastStop = getStop(this.tripTypeCards[trip_complex.trip_type_id][this.tripTypeCards[trip_complex.trip_type_id].length - 1].stop_id);
        return firstStop.long_name + ' - ' + lastStop.long_name;
      } else if (type == 'scheduleEvent') {

        if(trip_complex.schedule_event_id != 0){
          let scheduleEvent = getScheduleEvent(trip_complex.schedule_event_id);
          return scheduleEvent.long_name;
        }
      }
    },

    // скрываем диалог Выпуска из парка
    hideReleaseDialog() {
      this.isRealeaseDialogVisible = false;
      this.realeaseDialogStyle = '';
    },

    // скрываем диалог Назначения ТО
    hideAppointmentDialog() {
      this.isAppointmentDialogVisible = false;
      this.appointmentDialogStyle = '';
    },

    // получаем время текстом для таблицы
    getTimeInFormat(seconds) {
      return getTimeFormat(seconds)
    },

    // получаем тип рейса без букв направления для таблицы
    getTripTypeShort(trip_type) {
      return (trip_type.includes("AB", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type.includes("BA", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type)
    },

    getScheduleEventTitle(schedule_event_id) {
      let schedule_event = getScheduleEvent(schedule_event_id);
      if (schedule_event) {
        return schedule_event.short_name;
      }
      return '';
    },

    selectTrip(e, selectedTripComplex, rowNum, colNum) {
      if (!this.isLoading) {
        this.selectedTrip = {...selectedTripComplex};
        this.selectedRow = rowNum;
        this.selectedCol = colNum;
      }
    },

    setGraph(specifiedTrip, graph, startTime, continuationTripsCount) {
      if (!this.isLoading) {
        if (!Number.isInteger(Number(graph)) ) {
          specifiedTrip.graph = this.selectedTrip.graph;
        } else {
          if ((Number(graph) > 0) && (Number(graph) <= 999) && (graph != this.selectedTrip.graph)) {
            // отправляем запрос на редактирование
            console.log("отправляем запрос на редактирование номера графика");
            this.$emit('doEditGraph', specifiedTrip, this.selectedRow, this.selectedCol);
          } else {
            console.log(graph, startTime, continuationTripsCount);
            specifiedTrip.graph = this.selectedTrip.graph;
          }
        }
        this.selectedTrip = null;
        this.selectedRow = 0;
        this.selectedCol = 0;
        // скрываем диалог
        this.isRealeaseDialogVisible = false;
      }
    },

    setTripBegin(e, specifiedTrip) {
      if (!this.isLoading) {
        if (!Number.isInteger(Number(specifiedTrip.step_time_begin))) {
          specifiedTrip.step_time_begin = this.selectedTrip.step_time_begin
        } else {
          if ((Number(specifiedTrip.step_time_begin) >= 0) && (specifiedTrip.step_time_begin != this.selectedTrip.step_time_begin)) {
            // отправляем запрос на редактирование
            console.log("отправляем запрос на редактирование времени отправления");
            this.$emit('doEditTripBegin', specifiedTrip, this.selectedRow, this.selectedCol);
          } else {
            specifiedTrip.step_time_begin = this.selectedTrip.step_time_begin
          }
        }
        this.selectedTrip = null;
        this.selectedRow = 0;
        this.selectedCol = 0;
      }
    },

    setStop(e, specifiedTrip) {
      if (!this.isLoading) {
        if (!Number.isInteger(Number(specifiedTrip.stop_time))) {
          specifiedTrip.stop_time = this.selectedTrip.stop_time
        } else {
          if ((Number(specifiedTrip.stop_time) >= 0) && (specifiedTrip.stop_time != this.selectedTrip.stop_time)) {
            // отправляем запрос на редактирование
            console.log("отправляем запрос на редактирование времени остановки");
            this.$emit('doEditStop', specifiedTrip, this.selectedRow, this.selectedCol);
          } else {
            specifiedTrip.stop_time = this.selectedTrip.stop_time
          }
        }
        this.selectedTrip = null;
        this.selectedRow = 0;
        this.selectedCol = 0;
      }
    },

    delLeftTrip(e, specifiedTrip) {
      console.log(specifiedTrip, "отправляем запрос на удаление рейса слева");
      this.$emit('doDelLeftTrip', specifiedTrip, this.selectedRow, this.selectedCol);
    },

    startTrafficGraph(e, colItem) {
      let specifiedTrip = colItem.trip_complex;
      // переменная, в которой хранится в какую четверть экрана кликнул пользователь
      let quarter = 0;
      // определяем куда пользователь кликнул
      let domRect = e.srcElement.getBoundingClientRect();
      // определяем размеры окна
      // определяем ширину
      let windowWidth = window.innerWidth;
      // определяем высоту
      let windowHeight = window.innerHeight;
      // определяем в какую четверть экрана кликнул пользователь
      if (domRect.left < (windowWidth / 2)) {
        // если по ширине пользователь кликнул на левую половину экрана, значит исходя из высоты он может кликнуть в II или III четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это III четверть
          quarter = 3;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это II четверть
          quarter = 2;
        }
      } else {
        // если по ширине пользователь кликнул на правую половину экрана, значит исходя из высоты он может кликнуть в I или IV четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это IV четверть
          quarter = 4;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это I четверть
          quarter = 1;
        }
      }

      let containerEl = document.getElementById('container');

      // исходя из того в какую четверть экрана кликнул пользователь задаем настройки отображения всплывающего диалога
      if (quarter == 1) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top + 29 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(domRect.left - 350) + 'px;';
      } else if (quarter == 2) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top + 29 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(domRect.left - 20) + 'px;';
      } else if (quarter == 3) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top - 189 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(domRect.left - 20) + 'px;';
      } else if (quarter == 4) {
        this.realeaseDialogStyle = 'top: ' + Math.round(domRect.top - 189 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(domRect.left - 350) + 'px;';
      }

      if (colItem.column_num % 2 == 0) {
        this.direction = 'AB';
      } else {
        this.direction = 'BA';
      }

      this.dialogSettings = {
        trip_type_id: specifiedTrip.trip_type_id,
        schedule_event_id: specifiedTrip.schedule_event_id,
        null_trip_type_id: specifiedTrip.null_trip_type_id,
      }

      this.isRealeaseDialogVisible = true;
      console.log(specifiedTrip, "отправляем запрос на запуск движения на графике");
      this.$emit('doStartTrafficGraph', specifiedTrip, this.selectedRow, this.selectedCol);
    },

    minusMin(e, specifiedTrip) {
      console.log(specifiedTrip, "отправляем запрос на уменьшение стоянки");
      this.$emit('doMinusMin', specifiedTrip, this.selectedRow, this.selectedCol);
    },

    plusMin(e, specifiedTrip) {
      console.log(specifiedTrip, "отправляем запрос на увеличение стоянки");
      this.$emit('doPlusMin', specifiedTrip, this.selectedRow, this.selectedCol);
    },

    openTripTypeDialog(e, colItem) {
      console.log('colItem', colItem)
      // переменная, в которой хранится в какую четверть экрана кликнул пользователь
      let quarter = 0;
      // определяем куда пользователь кликнул
      let domRect = e.srcElement.getBoundingClientRect();
      // определяем размеры окна
      // определяем ширину
      let windowWidth = window.innerWidth;
      // определяем высоту
      let windowHeight = window.innerHeight;
      // определяем в какую четверть экрана кликнул пользователь
      if (domRect.left < (windowWidth / 2)) {
        // если по ширине пользователь кликнул на левую половину экрана, значит исходя из высоты он может кликнуть в II или III четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это III четверть
          quarter = 3;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это II четверть
          quarter = 2;
        }
      } else {
        // если по ширине пользователь кликнул на правую половину экрана, значит исходя из высоты он может кликнуть в I или IV четверть
        if (domRect.top > (windowHeight / 2)) {
          // если по высоте пользователь кликнул в нижнюю половину экрана - значит это IV четверть
          quarter = 4;
        } else {
          // если по высоте пользователь кликнул в верхнюю половину экрана - значит это I четверть
          quarter = 1;
        }
      }

      let containerEl = document.getElementById('container');

      // исходя из того в какую четверть экрана кликнул пользователь задаем настройки отображения всплывающего диалога
      if (quarter == 1) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top + 29 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(domRect.left - 457) + 'px;';
      } else if (quarter == 2) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top + 29 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(domRect.left - 20) + 'px;';
      } else if (quarter == 3) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top - 189 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(domRect.left - 20) + 'px;';
      } else if (quarter == 4) {
        this.appointmentDialogStyle = 'top: ' + Math.round(domRect.top - 189 - containerEl.getBoundingClientRect().top) + 'px; left: ' + Math.round(domRect.left - 457) + 'px;';
      }

      if (colItem.column_num % 2 == 0) {
        this.direction = 'AB';
      } else {
        this.direction = 'BA';
      }

      this.dialogSettings = {
        trip_type_id: colItem.trip_complex.trip_type_id,
        schedule_event_id: colItem.trip_complex.schedule_event_id,
        null_trip_type_id: colItem.trip_complex.null_trip_type_id,
      }

      this.isAppointmentDialogVisible = true;

    },

    changeTripType(specifiedTrip, changedTripType, selected_schedule_event_id, selected_null_trip_type_id, selected_production_trip_type_id) {
      // let specifiedTrip = colItem.trip_complex;
      console.log(specifiedTrip, "отправляем запрос на изменение типа рейса");
      this.$emit('doChangeTripType', specifiedTrip, changedTripType, selected_schedule_event_id, selected_null_trip_type_id, selected_production_trip_type_id);
    },

    extensionTrafficGraph(e, specifiedTrip) {
      console.log(specifiedTrip, "отправляем запрос на продление движения по графику");
      this.$emit('doExtensionTrafficGraph', specifiedTrip, this.selectedRow, this.selectedCol);
    },

  }
}
</script>

<style scoped>

span {
  margin: 0;
  white-space: nowrap;
}

th {
  margin: 0;
  white-space: nowrap;
  position: sticky;
  top: 0px;
  z-index: 10 !important;
}

td {
  vertical-align: middle;
  white-space: nowrap;
}

.placeholder-td {
  opacity: 0.0;
}

label {
  font-family: monospace;
  margin-bottom: 0rem;
}

.simple-graph {
  color: dodgerblue;
}

.stop-time {
  color: green;
}

.interval-time {
  color: red;
}

.type-trip-to {
  color: gray;
}

.null-trip {
  color: mediumblue;
}

</style>