<template>
  <input type="checkbox" :disabled="readonly" v-model="value">
</template>

<script>
export default {
  props: {
    'model-value': {
      required: true
    },
    'readonly': {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
  },
  methods: {
    // вызывается для проверки формы
    validate() {
      return true
    },
  },
  created() {
    this.$watch(() => this.modelValue, ()=> {
      if (this.modelValue !== true) {
        this.$emit('update:model-value', false);
      }
    }, {immediate: true});
  }
}
</script>
