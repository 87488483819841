import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ МАРШРУТОВ

export default {
    state() {
        return {
            // список объектов
            routes: [],
            // время обновления кэша new Date().getTime()
            routesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            routesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRoutes(state) {
            return state.routes;
        },
    },
    mutations: {
        // установить новый список объектов
        setRoutes(state, payload) {
            state.routes = payload;
            state.routesCache = new Date().getTime()
        },
        // добавить объект
        addRoute(state, payload) {
            state.routes.push(payload)
        },
        // изменить объект
        editRoute(state, payload) {
            const index = state.routes.findIndex(route => route.route_id == payload.route_id);
            if (index >= 0) {
                state.routes[index] = payload;
            }
        },
        // удалить объект
        delRoute(state, payload) {
            const index = state.routes.findIndex(route => route.route_id == payload.route_id);
            state.routes.splice(index, 1);
        }
    },
    actions: {
        // запрос объекта
        doFetchRoute: (context, payload) => {
            return DoGet(
                '/multipass/routes/' + payload.route_id,
                null,
                {},
                'Запрос маршрута',
                false,
                false,
            )
        },

        // запрос списка объектов
        doFetchRoutes: (context, payload) => {
            return DoGet(
                '/multipass/routes',
                'multipass/setRoutes',
                [],
                'Запрос маршрутов',
                false,
                payload && payload.force,
                context.state.routesCache,
                context.state.routesCacheTimeout
            )
        },

        // очищает кэш
        doClearRoutes: (context, payload) => {
            return DoClearCache(
                'multipass/setRoutes',
                [],
                payload && payload.force,
                context.state.routesCache,
                context.state.routesCacheTimeout
            )
        },

        // добавление объекта
        doAddRoute: (context, payload) => {
            return DoPost(
                '/multipass/routes',
                payload,
                'multipass/addRoute',
                'Добавление маршрута'
            )
        },

        // изменение объекта
        doEditRoute: (context, payload) => {
            return DoPut(
                '/multipass/routes/'+payload.route_id,
                payload,
                'multipass/editRoute',
                'Изменение маршрута'
            )
        },

        // установка статуса маршрута
        doSetRouteStatus: (context, payload) => {
            return DoPut(
                '/multipass/setRouteStatus/'+payload.route_id,
                {
                    route_status_id: payload.route_status_id,
                    force: payload.force
                },
                null,
                'Установка статуса маршрута',
                false,
            )
        },

        // удаление объекта
        doDelRoute: (context, payload) => {
            return DoDelete(
                '/multipass/routes/'+payload.route_id,
                'multipass/delRoute',
                'Удаление маршрута'
            )
        }
    }
}
