import store from './Store';

// вернуть группу датчиков
export function getSensorGroup(sensor_group_id) {
    return store.getters["nav/getSensorGroups"].find(sensorGroup => sensorGroup.sensor_group_id == sensor_group_id);
}

// вернуть группу параметров
export function getParamGroup(param_group_id) {
    return store.getters["nav/getSensorGroups"].find(sensorGroup => sensorGroup.param_group_id == param_group_id);
}

// вернуть эфирный протокол
export function getRadioProtocol(radio_protocol_id) {
    return store.getters["nav/getRadioProtocols"].find(radioProtocol => radioProtocol.radio_protocol_id == radio_protocol_id);
}

// вернуть модель радиостанции
export function getRadioModel(radio_model_id) {
    return store.getters["nav/getRadioModels"].find(radioModel => radioModel.radio_model_id == radio_model_id);
}