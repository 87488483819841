import ScheduleVariantsRmtStore from "@/store/rmt/ScheduleVariantsRmtStore";
import SeasonsRmtStore from "@/store/rmt/SeasonsRmtStore";
import ScheduleTypesRmtStore from "@/store/rmt/ScheduleTypesRmtStore";
import ScheduleVariantStatusesRmtStore from "@/store/rmt/ScheduleVariantStatusesRmtStore";
import MovementKindsRmtStore from "@/store/rmt/MovementKindsRmtStore";
import ScheduleGraphSetsRmtStore from "@/store/rmt/ScheduleGraphSetsRmtStore";
import TransportationKindsRmtStore from "@/store/rmt/TransportationKindsRmtStore";
import RegimesRmtStore from "@/store/rmt/RegimesRmtStore";
import ScheduleEventTypesRmtStore from "@/store/rmt/ScheduleEventTypesRmtStore";
import ScheduleGraphsRmtStore from "@/store/rmt/ScheduleGraphsRmtStore";
import ScheduleEventsRmtStore from "@/store/rmt/ScheduleEventsRmtStore";
import IndicatorsRmtStore from "@/store/rmt/IndicatorsRmtStore";
import ScheduleVariantDocsRmtStore from "@/store/rmt/ScheduleVariantDocsRmtStore";
import ScheduleVariantDocFilesRmtStore from "@/store/rmt/ScheduleVariantDocFilesRmtStore";
import TimeNormPeriodsRmtStore from "@/store/rmt/TimeNormPeriodsRmtStore";
import TimeNormTimesRmtStore from "@/store/rmt/TimeNormTimesRmtStore";
import IntNormPeriodsRmtStore from "@/store/rmt/IntNormPeriodsRmtStore";
import IntNormIntsRmtStore from "@/store/rmt/IntNormIntsRmtStore";
import ScheduleGraphStepsRmtStore from "@/store/rmt/ScheduleGraphStepsRmtStore";
import ScheduleGraphStepCardsRmtStore from "@/store/rmt/ScheduleGraphStepCardsRmtStore";
import ScheduleTimeTableRmtStore from "@/store/rmt/ScheduleTimeTableRmtStore";
import ScheduleGraphStepTripTypesRmtStore from "@/store/rmt/ScheduleGraphStepTripTypesRmtStore";
import ReportsRmtStore from "@/store/rmt/ReportsRmtStore";
import HolidaysRmtStore from "@/store/rmt/HolidaysRmtStore";

// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ RMT

export default {
    namespaced: true,
    modules: {
        ScheduleVariantsRmtStore,
        SeasonsRmtStore,
        ScheduleTypesRmtStore,
        ScheduleVariantStatusesRmtStore,
        MovementKindsRmtStore,
        ScheduleGraphSetsRmtStore,
        TransportationKindsRmtStore,
        RegimesRmtStore,
        ScheduleEventTypesRmtStore,
        ScheduleGraphsRmtStore,
        ScheduleEventsRmtStore,
        IndicatorsRmtStore,
        ScheduleVariantDocsRmtStore,
        ScheduleVariantDocFilesRmtStore,
        TimeNormPeriodsRmtStore,
        IntNormPeriodsRmtStore,
        TimeNormTimesRmtStore,
        IntNormIntsRmtStore,
        ScheduleGraphStepsRmtStore,
        ScheduleGraphStepCardsRmtStore,
        ScheduleTimeTableRmtStore,
        ScheduleGraphStepTripTypesRmtStore,
        ReportsRmtStore,
        HolidaysRmtStore
    }
}