import {DoClearCache, DoDelete, DoGet, DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ СВЯЗЕЙ ТС С ГРУППАМИ

export default {
    state() {
        return {
            // список связей
            transportGroupLinks: [],
            // время обновления кэша new Date().getTime()
            transportGroupLinksCache: null,
            // время жизни кэша в мс (null - бесконечно)
            transportGroupLinksCacheTimeout: null
        }
    },
    getters: {
        // вернуть список связей
        getTransportGroupLinks(state) {
            return state.transportGroupLinks
        },
    },
    mutations: {
        // установить новый список связей
        setTransportGroupLinks(state, payload) {
            state.transportGroupLinks = payload;
            state.transportGroupLinksCache = new Date().getTime()
        },
        // связать объекты
        addTransportToGroup(state, payload) {
            state.transportGroupLinks.push(payload)
        },
        // удалить связь объектов
        delTransportFromGroup(state, payload) {
            const index = state.transportGroupLinks.findIndex(
                link => link.transport_group_id == payload.transport_group_id && link.transport_id == payload.transport_id
            );
            state.transportGroupLinks.splice(index, 1);
        },
    },
    actions: {
        // запрос списка связей
        doFetchTransportGroupLinks: (context, payload) => {
            return DoGet(
                '/ref/transportGroupLinks',
                'ref/setTransportGroupLinks',
                [],
                'Запрос связей транспортных средств с группами',
                false,
                payload && payload.force,
                context.state.transportGroupLinksCache,
                context.state.transportGroupLinksCacheTimeout
            )
        },

        // очищает кэш
        doClearTransportGroupLinks: (context, payload) => {

            return DoClearCache(
                'ref/setTransportGroupLinks',
                [],
                payload && payload.force,
                context.state.transportGroupLinksCache,
                context.state.transportGroupLinksCacheTimeout
            )
        },

        // связывние объектов
        doAddTransportToGroup: (context, payload) => {
            return DoPost(
                '/ref/transportGroupLinks',
                payload,
                'ref/addTransportToGroup',
                'Добавление транспортного средства в группу'
            )
        },

        // удаление связи объектов
        doDelTransportFromGroup: (context, payload) => {
            return DoDelete(
                '/ref/transportGroupLinks/'+payload.transport_group_id+'/'+payload.transport_id,
                'ref/delTransportFromGroup',
                'Удаление транспортного средства из группы'
            )
        },
    }
}