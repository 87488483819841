import {DoPost} from "@/store/Actions";
import store from "@/store/Store";

// ХРАНИЛИЩЕ ОТЧЕТОВ МОДУЛЯ RMT

export default {
    actions: {
        // запрос отчета 0001
        doMakeReport0001: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id;
            let response = await DoPost(
                '/rmt/reports/makeReport0001',
                payload,
                null,
                'Запрос на формирование отчета о плановых показателях варианта расписания',
                false,
                true,
            );
            if(response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },

        // запрос отчета 0002
        doMakeReport0002: async (context, payload) => {
            let order_id = store.getters['reporter/getReports'][store.getters['reporter/getReports'].length - 1].order_id;
            let response = await DoPost(
                '/rmt/reports/makeReport0002',
                payload,
                null,
                'Запрос на формирование планового расписания движения по графику',
                false,
                true,
            );
            if (response) {
                response['order_id'] = order_id;
                store.commit('reporter/editReport', response);
            } else {
                store.commit('reporter/editErrorReport', {order_id: order_id});
            }
            return response;
        },
    }
}
