import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ТИПОВ ТОПЛИВА

export default {
    state() {
        return {
            // список объектов
            engineFuelTypes: [],
            // время обновления кэша new Date().getTime()
            engineFuelTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            engineFuelTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getEngineFuelTypes(state) {
            return state.engineFuelTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setEngineFuelTypes(state, payload) {
            state.engineFuelTypes = payload;
            state.engineFuelTypesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchEngineFuelTypes: (context, payload) => {
            return DoGet(
                '/ref/engineFuelTypes',
                'ref/setEngineFuelTypes',
                [],
                'Запрос типов топлива',
                false,
                payload && payload.force,
                context.state.engineFuelTypesCache,
                context.state.engineFuelTypesCacheTimeout
            )
        },

        // очищает кэш
        doClearEngineFuelTypes: (context, payload) => {

            return DoClearCache(
                'ref/setEngineFuelTypes',
                [],
                payload && payload.force,
                context.state.engineFuelTypesCache,
                context.state.engineFuelTypesCacheTimeout
            )
        },        
    }
}