<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-asmpp-pay-trans-proc-status-by-transport"></edit-table-panel>
</template>

<script>

import {getExternalSystem} from "@/store/AdminHelpers";
import {ndToDateString} from "@/lib";
import {getTransport} from "@/store/RefHelpers";

export default {
  props: ['isLoading', 'payTransByTransport'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__date_index",
          caption: "Дата",
          sortType: 'number',
          isFilterable: true,
          show:false
        },
        {
          field: "__date_index_file",
          caption: "Индекс даты файла",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "transport_num",
          caption: "ТС в АСОП",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__external_system",
          caption: "Внешняя система",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "state_num",
          caption: "Гос.номер",
          sortType: 'string',
        },
        {
          field: "garage_num",
          caption: "Гараж.номер",
          sortType: 'string',
        },
        {
          field: "pay_tran_count",
          caption: "Кол-во транзакций",
          sortType: 'number',
        },
        {
          field: "__import_status",
          caption: "Замечания",
          sortType: 'string',
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch' || this.payTransByTransport.length == 0) {
        return []
      }

      return this.payTransByTransport.map((tran, index) => {

        let transport = getTransport(tran.transport_id);
        if (transport) {
          tran.state_num = transport.state_num;
          tran.garage_num = transport.garage_num;
        }

        const extSystem = getExternalSystem(tran.external_system_id);
        tran.__external_system = extSystem.external_system_title;
        if (tran.import_status_code == 0) {
          tran.__import_status = 'успешно';
        } else if (tran.import_status_code == 1) {
          tran.__import_status = 'нет в АСОП';
        } else {
          tran.__import_status = '';
        }
        tran.__id = index;
        tran.__date_index = ndToDateString(tran.date_index);
        tran.__date_index_file = ndToDateString(tran.date_index_file);

        return tran;
      });
    },
  },
};
</script>
