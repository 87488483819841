<template>
    <!--    Карта-->
    <leaflet-map
        :zoom="zoom"
        :center="center"
        :contextMenu="mapContextMenu"
        :options="mapOptions"
        @onMapCreated="onMapCreated"
    >

      <!-- Элементы управления-->
      <leaflet-control-group>
        <!-- Базовые слои-->
        <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
      </leaflet-control-group>

      <!--  Зона автостанции -->
      <base-zone
          :zone="zone"
          :state="isEditMode ? 'edit' : 'active'"
          @onZoneChanged="$emit('onZoneChanged', $event)"
      >
      </base-zone>

      <!--  Остановка -->
      <stopstation-marker
          :stopstation="stopstation"
          :tooltip="stopstation.long_name"
          stopType="0"
      ></stopstation-marker>

    </leaflet-map>
</template>

<script>

export default {
  props: [
    // зона
    'zone',
    // автостанция
    'stopstation',
    // режим редактирования
    'isEditMode',
  ],

  // список событий
  emits: [
    // вызывается при изменении зоны
    'onZoneChanged'
  ],

  computed: {
    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom']
    },
    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter']
    },
    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers']
    },
  },

  data() {
    return {
      // карта
      leaflet: null,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // контекстное меню карты
      mapContextMenu:  {
        contextmenu: true,
        contextmenuWidth: 180,
        contextmenuItems: [

        ]
      },
    }
  },

  methods: {
    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },
  },
}
</script>
