<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/stops">Остановочные пункты</router-link></span>
        <span> / </span>
        <span>Зона остановочного пункта {{ stopPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="crudButtonsExist"
              :buttonsEnabled="crudButtonsEnabled"
              :filterExists="false"
              @onEdit="onTableEdit"
              @onSave="onTableSave"
              @onCancel="onTableCancel"
              @onRefresh="onTableRefresh"
          >
          </crud-form>
        </align-panel>
        <!-- Карта  -->
        <align-panel align="all">
          <stop-zones-map
              :zone="zone"
              :stop="stop"
              :isEditMode="isEditMode"
              @onMapCreated="onMapCreated"
              @onZoneChanged="onZoneChanged"
          >
          </stop-zones-map>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import StopZonesMap from "@/components/multipass/stop_zones/StopZonesMap";
import {getStop} from "@/store/MultipassHelpers";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    StopZonesMap
  },

  props: [
    // идентификатор остановочного пункта
    "stop_id",
  ],

  data() {
    return {
      // карта
      leaflet: null,
      // отображать спиннер
      isLoading: false,
      // режим редактирования
      isEditMode: false,
      // зона остановочного пункта
      zone: null,
      // принак изменения
      isModified: false,
      // исходная зона остановочного пункта
      originZone: null,
      // остановка
      stop: {},
    }
  },

  computed: {
      // вимдимость crud-кнопок
      crudButtonsExist() {
        return {
          refresh: true,
          edit: !this.isEditMode,
          save: this.isEditMode,
          cancel: this.isEditMode,
        }
      },

      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            refresh: true,
            edit: true,
            save: this.isModified,
            cancel: true,
          }
      },
  },
  methods: {
    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // при нажатии кнопки Изменить
    onTableEdit() {
      // устанавливаем режим редактирования
      this.isEditMode = true;
      // сбрасываем модификацию
      this.isModified = false;
      // запоминаем исходную зону
      this.originZone = {...this.zone}
    },

    // вызывается при нажатии конпки сохранить
    onTableSave() {
      // зона должны быть как минимум из одного элемента
      if (this.zone.elements.length === 0) {
        this.$store.dispatch('doAddMessage', {
          caption: 'Установка зоны остановочного пункта',
          message: 'Отсуствуют элементы зоны',
          type: 'error'
        },{root: true});

        return;
      }
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // сохраняем данные на сервер
      this.saveItem();
    },

    // вызывается при нажатии конопки отменить
    onTableCancel() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // восстанавливаем данные
      this.restoreItem();
    },

    // вызывается при нажатии конопки обновить
    onTableRefresh() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // перезапрашиваются данные
      this.refreshData();
    },

    // вызывается при изменении зоны
    onZoneChanged(zone) {
      this.zone = zone;
      // устанавливаем режим модификации
      this.isModified = true;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // запрашиваем список остановок
        if (!await this.$store.dispatch('multipass/doFetchStops')) return;
        const stop = getStop(this.stop_id);if (!stop) return;
        this.stop = stop;

        // запрашиваем зону остановочного пункта
        const zone = await this.$store.dispatch('multipass/doFetchStopZone', {
          stop_id: this.stop_id,
        }); if (!zone) return;
        this.zone = zone;

        // позиционируем карту
        if (this.leaflet && stop && stop.latitude && stop.longitude) {
          const minStopZoom = this.$store.getters['settings/getMinStopZoom'];
          const zoom = this.leaflet.getZoom();
          if (zoom >= minStopZoom)
            this.leaflet.setView([stop.latitude, stop.longitude], zoom)
          else
            this.leaflet.setView([stop.latitude, stop.longitude], minStopZoom)
        }
      }
      finally {
        this.isLoading = false;
      }
    },

    // восстанавливаем объект
    async restoreItem() {
      this.zone = this.originZone;
    },

    // сохраняем объект
    async saveItem() {
      this.isLoading = true;
      try {

        const zone = await this.$store.dispatch('multipass/doEditStopZone', {
          stop_id: this.stop_id,
          zone: this.zone
        });
        if (zone) this.zone = zone
          else this.zone = null;

      }
      finally {
        this.isLoading = false;
      }
    }
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
