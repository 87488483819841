<template>
<slot></slot>
</template>

<script>
import {MarkerClusterGroup} from "leaflet.markercluster";
import LeafletOverlayMixin from "@/components/ui/leaflet/mixins/LeafletOverlayMixin";
import LeafletGroupMixin from "@/components/ui/leaflet/mixins/LeafletGroupMixin";
import {toRaw} from "vue";

// ГРУППА СЛОЕВ (С ГРУППИРОВКОЙ)

export default {
  mixins: [LeafletGroupMixin, LeafletOverlayMixin],
  props: {
    // опции группы
    options: {
      type: Object,
      default: () => {
        return {
          removeOutsideVisibleBounds: false,
          showCoverageOnHover: false
        }
      }
    },
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletMarkerClusterGroup',
    }
  },
  methods: {
    // создание слоя
    createLayer() {
      // создаем слой
      const layer = new MarkerClusterGroup(toRaw(this.options));
      //const layer = new LayerGroup();
      layer.__meta = this.meta;
      // подписываемся на события слоя
      this.subscribe(layer);
      // возвращаем слой
      this.proxyOrigin = layer;
      return layer
    },
    destroyLayer() {
      // удаляем подписку на события
      this.unsubscribe(this.origin);
      // убиваем ссылку
      this.proxyOrigin = null;
    },
  },
  // монтируем слой
  mounted() {
    // ждем создания родителя
    this.getParent().parentReady().then(() => {
      // наблюдатель за опциями
      this.$watch(() => this.options, () => {
        //console.log('Требуется изменить опции группы: '+ this.origin)
        this.reCreateLayer();
      }, {deep: false});
    })
  },
}
</script>
