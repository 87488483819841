<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-multipass-route-types"> </edit-table-panel>
</template>

<script>
export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "route_type_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      return this.$store.getters["multipass/getRouteTypes"].map(typ => {
        typ.__id = typ.route_type_id;

        return typ;
      });
    },
  },
};
</script>
