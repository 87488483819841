import RefTitlePage from '../pages/ref/RefTitlePage.vue';

import TransportTypesPage from '../pages/ref/TransportTypesPage.vue'
import TransportClassesPage from "@/pages/ref/TransportClassesPage";
import EngineFuelTypesPage from "@/pages/ref/EngineFuelTypesPage";
import EmissionStandardsPage from "@/pages/ref/EmissionStandardsPage";
import LegalFormsPage from "@/pages/ref/LegalFormsPage";
import TransportTypeGroupsPage from "@/pages/ref/TransportTypeGroupsPage";
import TransportGroupsPage from "@/pages/ref/TransportGroupsPage";
import DepotGroupsPage from "@/pages/ref/DepotGroupsPage";
import DepotsPage from "@/pages/ref/DepotsPage";
import OrganizationsPage from "@/pages/ref/OrganizationsPage";
import ModelsPage from "@/pages/ref/ModelsPage";
import TransportPage from "@/pages/ref/TransportPage";
import TransportTypeGroupLinksPage from "@/pages/ref/transport_types/TransportTypeGroupLinksPage";
import GroupTransportTypeLinksPage from "@/pages/ref/transport_type_groups/GroupTransportTypeLinksPage";
import TransportGroupLinksPage from "@/pages/ref/transport/TransportGroupLinksPage";
import GroupTransportLinksPage from "@/pages/ref/transport_groups/GroupTransportLinksPage";
import DepotGroupLinksPage from "@/pages/ref/depots/DepotGroupLinksPage";
import GroupDepotLinksPage from "@/pages/ref/depot_groups/GroupDepotLinksPage";
import PersonnelPage from "@/pages/ref/PersonnelPage";
import AreaClassifierPage from "@/pages/ref/AreaClassifierPage";
import TransportStatesPage from "@/pages/ref/transport/TransportStatesPage";
import PersonnelStatesPage from "@/pages/ref/personnel/PersonnelStatesPage";
import DepotDetailsPage from "@/pages/ref/depots/DepotDetailsPage";
import OrganizationDetailsPage from "@/pages/ref/organizations/OrganizationDetailsPage";

import MainPage from "@/pages/main/MainPage";

export default {
    path: '/ref', 
    component: MainPage,
    children: [
        {
            path: 'organizations/:org_id(\\d+)?/organizationDetails/:op(show|add|del|edit)/:id(\\d+)?',
            component: OrganizationDetailsPage,
            props: true
        },
        {
            path: 'organizations/:org_id(\\d+)?/organizationDetails',
            component: OrganizationDetailsPage,
            props: true
        },
        {
            path: 'organizations/:op(show|add|del|edit)/:id(\\d+)?',
            component: OrganizationsPage,
            props: true
        },
        {
            path: 'organizations',
            component: OrganizationsPage
        },
        {
            path: 'depots/:depot_id(\\d+)?/depotDetails/:op(show|add|del|edit)/:id(\\d+)?',
            component: DepotDetailsPage,
            props: true
        },
        {
            path: 'depots/:depot_id(\\d+)?/depotDetails',
            component: DepotDetailsPage,
            props: true
        },
        {
            path: 'depots/:id(\\d+)?/depotGroupLinks',
            component: DepotGroupLinksPage,
            props: true
        },
        {
            path: 'depots/:op(show|add|del|edit)/:id(\\d+)?',
            component: DepotsPage,
            props: true
        },
        {
            path: 'depots',
            component: DepotsPage
        },
        {
            path: 'transportTypes/:id(\\d+)?/transportTypeGroupLinks',
            component: TransportTypeGroupLinksPage,
            props: true
        },
        {
            path: 'transportTypes/:op(show|add|del|edit)/:id(\\d+)?',
            component: TransportTypesPage,
            props: true
        },
        {
            path: 'transportTypes',
            component: TransportTypesPage
        },
        {
            path: 'models/:op(show|add|del|edit)/:id(\\d+)?',
            component: ModelsPage,
            props: true
        },
        {
            path: 'models',
            component: ModelsPage
        },
        {
            path: 'transport/:transport_id(\\d+)/transportStates',
            component: TransportStatesPage,
            props: true
        },
        {
            path: 'transport/:id(\\d+)?/transportGroupLinks',
            component: TransportGroupLinksPage,
            props: true
        },
        {
            path: 'transport/:op(show|add|del|edit|discard|restore)/:id(\\d+)?',
            component: TransportPage,
            props: true
        },
        {
            path: 'transport',
            component: TransportPage
        },
        {
            path: 'personnel/:personnel_id(\\d+)/personnelStates',
            component: PersonnelStatesPage,
            props: true
        },
        {
            path: 'personnel/:op(show|add|del|edit|discard|restore)/:id(\\d+)?',
            component: PersonnelPage,
            props: true
        },
        {
            path: 'personnel',
            component: PersonnelPage
        },
        {
            path: 'transportTypeGroups/:id(\\d+)?/groupTransportTypeLinks',
            component: GroupTransportTypeLinksPage,
            props: true
        },
        {
            path: 'transportTypeGroups/:op(show|add|del|edit)/:id(\\d+)?',
            component: TransportTypeGroupsPage,
            props: true
        },
        {
            path: 'transportTypeGroups',
            component: TransportTypeGroupsPage
        },
        {
            path: 'transportGroups/:id(\\d+)?/groupTransportLinks',
            component: GroupTransportLinksPage,
            props: true
        },
        {
            path: 'transportGroups/:op(show|add|del|edit)/:id(\\d+)?',
            component: TransportGroupsPage,
            props: true
        },
        {
            path: 'transportGroups',
            component: TransportGroupsPage
        },
        {
            path: 'depotGroups/:id(\\d+)?/groupDepotLinks',
            component: GroupDepotLinksPage,
            props: true
        },
        {
            path: 'depotGroups/:op(show|add|del|edit)/:id(\\d+)?',
            component: DepotGroupsPage,
            props: true
        },
        {
            path: 'depotGroups',
            component: DepotGroupsPage
        },
        {
            path: 'transportClasses',
            component: TransportClassesPage
        },
        {
            path: 'engineFuelTypes',
            component: EngineFuelTypesPage
        },
        {
            path: 'emissionStandards',
            component: EmissionStandardsPage
        },
        {
            path: 'legalForms',
            component: LegalFormsPage
        },
        {
            path: 'areaClassifier/:op(show|edit)/:id(\\d+)?',
            component: AreaClassifierPage,
            props: true
        },
        {
            path: 'areaClassifier',
            component: AreaClassifierPage
        },
        {
            path: '',
            component: RefTitlePage
        },
        {
            path: ':pathMatch(.*)*',
            component: RefTitlePage
        }
    ]
}