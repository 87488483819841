<template>


  <accordion mode="single">

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group1">
          Основные
        </button-target>
      </card-header>
    </card>
    <collapse ref="group1" active>
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/users"
          >Пользователи</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/roles"
          >Роли</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/systemEvents"
          >Системные события</left-menu-item>
        </li>
      </ul>
    </collapse>

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group2">
          Группы
        </button-target>
      </card-header>
    </card>
    <collapse ref="group2">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/userGroups"
          >Группы пользователей</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/multipass/routeGroups"
          >Группы маршрутов</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/depotGroups"
          >Группы предприятий</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/transportGroups"
          >Группы транспортных средств</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/transportTypeGroups"
          >Группы видов транспорта</left-menu-item>
        </li>
      </ul>
    </collapse>

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group3">
          Интеграция
        </button-target>
      </card-header>
    </card>
    <collapse ref="group3">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/externalSystems"
          >Внешние системы</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/entityTypes"
          >Типы связанных сущностей</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/linkedEntities"
          >Связи с сущностями внешних систем</left-menu-item>
        </li>
      </ul>
    </collapse>

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group4">
          Дополнительные
        </button-target>
      </card-header>
    </card>
    <collapse ref="group4">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/methods"
          >Методы</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/modules"
          >Модули</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/reports"
          >Отчеты</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/reportGroups"
          >Группы отчетов</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/accessCategories"
          >Категории доступа</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/admin/mainParams"
          >Глобальные параметры</left-menu-item>
        </li>
      </ul>
    </collapse>

  </accordion>
</template>
