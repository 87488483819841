<template>
  <edit-table-panel :columnList="columnList" :headerGroupList="headerGroupList" :disableSettings="true" :rowList="rowList" :rowsPerPage="100000"
                    name="tbl-kvr-vehicle-result-graphs"></edit-table-panel>
</template>

<script>

import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getTimeFormat} from "@/lib";

export default {
  components: {EditTablePanel},
  props: ['tableRows'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: 'trip_num',
          caption: '№',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          dataAlign: 'center',
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'stop_short_name',
          caption: 'КП',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__plan_time',
          caption: 'План',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          dataAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__fact_time',
          caption: 'Факт',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          dataAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__deflection',
          caption: 'Рсп',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'point_mark_id',
          caption: 'на КП',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'time_start',
          caption: 'Событие',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: '__deflection__',
          caption: 'Отк.',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          dataAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'reis',
          caption: 'Движ.',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },        {
          field: 'time_end',
          caption: 'Маневр',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },{
          field: 'time_end',
          caption: 'Маневр',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'reis',
          caption: 'Прб.',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'reis',
          caption: 'Врм.',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'reis',
          caption: '(Вид деятел.)',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerAlign: 'center',
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },

      ],
      headerGroupList: [
        {
          headerGroupCaption: 'Рейс',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Назв.',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Время КП',
          headerGroupSpan: 2,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Отк.',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Отмет.',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Управление',
          headerGroupSpan: 2,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Режим',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Обед/Отстой',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'КП/Мрш',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Нул. Рейс',
          headerGroupSpan: 2,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Мрш - Вх - См',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },

      ],
    }
  },

  computed: {

    rowList() {
      return this.tableRows.map(row => {
        row.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px',
          userSelect: 'none',
        };

        row.__plan_time = getTimeFormat(row.plan_time);
        row.__fact_time = getTimeFormat(row.fact_time);

        if(row.plan_time && row.fact_time) {
          row.__deflection = (row.plan_time - row.fact_time) > 0 ? '+' + ((row.plan_time - row.fact_time)/60) : ((row.plan_time - row.fact_time)/60);
        } else {
          row.__deflection = '';
        }

        if(row.deflection == null) {
          row.deflection = 0;
        } else {
          row.__deflection__ = row.deflection / 60;
        }

        // if (resultTrip.is_done == true && resultTrip.is_class == true) {
        //   resultTrip.__rowStyle = {
        //     backgroundColor: '#a4ff9c'
        //   }
        // } else if (resultTrip.is_done == true && resultTrip.is_class == false) {
        //   resultTrip.__rowStyle = {
        //     backgroundColor: '#daffd7'
        //   }
        // } else {
        //   resultTrip.__rowStyle = {
        //     backgroundColor: '#f8f19b'
        //   }
        // }

        return row;
      });
    }

  }
}
</script>

<style scoped>

</style>