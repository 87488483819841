<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Пользователь</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group">
              <label for="uid">Код:</label>
              <input-integer
                id="uid"
                class="form-control w-25"
                v-model="uid"
                readonly
              />
            </div>

            <div class="form-group">
              <label for="login">Логин:</label><req-field />
              <input-string
                  class="form-control"
                  id="login"
                  ref="login"
                  v-model.trim="login"
                  autocomplete="off"
                  :readonly="op === 'show' || login === 'trn_coordinator_admin'"
                  required
                  error="Введите логин"
              />
            </div>

            <div class="form-group">
              <label for="lastName">Фамилия:</label><req-field />
              <input-string
                  class="form-control"
                  id="lastName"
                  ref="lastName"
                  v-model.trim="lastName"
                  autocomplete="off"
                  :readonly="op === 'show' || login === 'trn_coordinator_admin'"
                  required
                  error="Введите фамилию"
              />
            </div> 

            <div class="form-group">
              <label for="firstName">Имя:</label>
              <input-string
                  class="form-control"
                  id="firstName"
                  ref="firstName"
                  v-model.trim="firstName"
                  autocomplete="off"
                  :readonly="op === 'show' || login === 'trn_coordinator_admin'"
              />
            </div>    

            <div class="form-group">
              <label for="middleName">Отчество:</label>
              <input-string
                  class="form-control"
                  id="middleName"
                  ref="middleName"
                  v-model.trim="middleName"
                  autocomplete="off"
                  :readonly="op === 'show' || login === 'trn_coordinator_admin'"
              />
            </div>

            <div class="form-group">
              <label for="org">Организация:</label>
              <input-string
                  class="form-control"
                  id="org"
                  ref="org"
                  v-model.trim="org"
                  autocomplete="off"
                  :readonly="op === 'show' || login === 'trn_coordinator_admin'"
              />
            </div>

            <div class="form-group">
              <label for="email">Email:</label>
              <input-string
                  class="form-control"
                  id="email"
                  v-model.trim="email"
                  ref="email"
                  autocomplete="off"
                  :readonly="op === 'show' || login === 'trn_coordinator_admin'"
              />
            </div>

            <div class="form-group">
              <label for="phoneNum">Телефон:</label>
              <input-string
                  class="form-control"
                  id="phoneNum"
                  v-model.trim="phoneNum"
                  ref="phoneNum"
                  autocomplete="off"
                  :readonly="op === 'show' || login === 'trn_coordinator_admin'"
              />
            </div>

            <div class="form-group">
              <label for="birthday">Дата рождения:</label>
              <input-date
                  class="form-control"
                  id="birthday"
                  v-model="birthday"
                  ref="birthday"
                  autocomplete="off"
                  :readonly="op === 'show' || login === 'trn_coordinator_admin'"
              />
            </div>
          </form>
        </modal-body>
        <modal-footer v-if="op !== 'show'">
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  mixins: [ValidateFormMixin],
  props: ['item', 'op'],
    emits: ['onOK', 'onCancel'],
    data() {
        return {
            uid: this.item.uid,
            login: this.item.login,
            firstName: this.item.first_name,
            middleName: this.item.middle_name,
            lastName: this.item.last_name,
            org: this.item.org,
            email: this.item.email,
            phoneNum: this.item.phone_num,
            birthday: this.item.birthday,
        };
    },
    methods: {
        onSubmit() {
            if (this.validateForm(this.$refs)) {
                this.$emit('onOK', {
                    uid: this.op === 'add' ? undefined : this.uid,
                    login: this.login,
                    first_name: this.firstName,
                    middle_name: this.middleName,
                    last_name: this.lastName,
                    birthday: this.birthday,
                    email: this.email,
                    phone_num: this.phoneNum,
                    org: this.org
                })
            }
        },
    },
};
</script>

<style scoped>
    form label {
        font-weight: bold;
    }
</style>