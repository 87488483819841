<template>
  <div>
    <!--    Карточка с отчетами раздела "Маршруты"-->
    <div class="card bg-light">
      <div class="card-header" style="font-size: 18px; font-weight: bold; padding: 0px 0px 0px 8px;">
        Маршруты
      </div>
      <div style="padding-right: 8px; padding-left: 8px">

        <!--    подраздел "Пробеги"-->
        <div class="form">
          <label style="margin-top: 8px; font-weight: bold;">Пробеги</label>
          <div :class="id == '0039' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0039')"
               title="Итоговый отчет о выполнении работы на маршрутах">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о выполнении работы на маршрутах</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КЭ-16</a>
            </div>
          </div>
          <div :class="id == '0040' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0040')"
               title="Итоговый отчет о выполнении работы предприятиями на маршрутах">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о выполнении работы предприятиями на маршрутах</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КЭ-17</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Рейсы"-->
        <div class="form">
          <label style="margin-top: 8px; font-weight: bold;">Рейсы</label>
          <div :class="id == '0037' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0037')"
               title="Итоговый отчет о работе маршрутов">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о работе маршрутов</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КЭ-14</a>
            </div>
          </div>
          <div :class="id == '0038' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0038')"
               title="Итоговый отчет о работе предприятий на маршрутах">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о работе предприятий на маршрутах</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КЭ-15</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Сводные"-->
        <div class="form" style="margin-bottom: 8px">
          <label style="margin-top: 8px; font-weight: bold;">Сводные</label>
          <div :class="id == '0035' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0035')"
               title="Итоговый отчет о выполнении плана перевозок">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о выполнении плана перевозок</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КЭ-12</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--    Карточка с отчетами раздела "Перевозчики"  -->
    <div class="card bg-light">
      <div class="card-header" style="font-size: 18px; font-weight: bold; padding: 0px 0px 0px 8px;">
        Перевозчики
      </div>
      <div style="padding-right: 8px; padding-left: 8px ">

        <!--    подраздел "Оперативные"  -->
        <div class="form">
          <label style="margin-top: 8px; font-weight: bold;">Оперативные</label>
          <div :class="id == '0043' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0043')"
               title="Оперативная справка выполнения плана перевозок. Сводная по предприятиям">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Оперативная справка выполнения плана перевозок. Сводная по предприятиям</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КЭ-22</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Пробеги"-->
        <div class="form" style="margin-bottom: 8px">
          <label style="margin-top: 8px; font-weight: bold;">Пробеги</label>

          <div :class="id == '0032' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0032')"
               title="Отчет об осуществлении регулярных перевозок по регулируемым тарифам пассажиров и багажа по муниципальным маршрутам регулярных перевозок">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет об осуществлении регулярных перевозок по регулируемым тарифам пассажиров и багажа по
                муниципальным маршрутам регулярных перевозок</a>
            </div>
            <div class="form-group col-1" style="margin: 0; padding: 0;">
              <a>КЭ-10</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Рейсы"  -->
        <div class="form">
          <label style="margin-top: 8px; font-weight: bold;">Рейсы</label>
          <div :class="id == '0033' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0033')"
               title="Отчет о фактической транспортной работе">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет о фактической транспортной работе</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КЭ-11</a>
            </div>
          </div>
          <div :class="id == '0034' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0034')"
               title="Отчет о фактической транспортной работе">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет о фактической транспортной работе</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КЭ-11и</a>
            </div>
          </div>
          <div :class="id == '0036' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0036')"
               title="Итоговый отчет о работе маршрутов">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Итоговый отчет о работе маршрутов</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КЭ-13</a>
            </div>
          </div>
          <div :class="id == '0042' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0042')"
               title="Отчет по выполнению плана перевозок">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет по выполнению плана перевозок. Сводная по предприятиям</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КЭ-21</a>
            </div>
          </div>
        </div>

        <!--    подраздел "Сводные"  -->
        <div class="form" style="margin-bottom: 8px">
          <label style="margin-top: 8px; font-weight: bold;">Сводные</label>
          <div :class="id == '0041' ? 'form-group selected-row' : 'form-group row'" @click="setRowSelected('0041')"
               title="Отчет об объеме выполненной транспортной работы">
            <div class="form-group col" style="overflow-x: hidden; margin: 0;">
              <a>Отчет об объеме выполненной транспортной работы</a>
            </div>
            <div class="form-group col-1" style="margin: 0;padding: 0;">
              <a>КЭ-19</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  props: [
    // идентификатор выбранного отчета
    "id",
  ],

  methods: {

    setRowSelected(id) {
      this.$router.replace('/kvr/kvrExpertReports/' + id);
    }

  }
}
</script>

<style scoped>

a {
  font-size: small;
  white-space: nowrap;
  padding: 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  border-top: 1px solid #bdbdbd;
  /*#817e7e;*/
  cursor: pointer;
}

.selected-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  border-top: 1px solid #bdbdbd;
  background-color: #9adef5;
  cursor: pointer;
}

.col {
  padding-right: 0;
  margin-right: 10px !important;
  padding-left: 10px !important;
}

.col-1 {
  /*margin-right: 5px !important;*/
  min-width: 44px;
  max-width: 44px;
}

.form {
  border-bottom: 2px solid #bdbdbd;
  margin-bottom: 0px;
}

.card {
  margin-bottom: 5px;
}

</style>