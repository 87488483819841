<template>

  <align-container>

    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/nav">Телематика</router-link></span>
        <span> / </span>
        <span>Эфирные протоколы</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >
          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <radio-protocols-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
          ></radio-protocols-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import RadioProtocolsEditTable from "@/components/nav/radio_protocols/RadioProtocolsEditTable";

export default {
  mixins: [PageMixin],

  components: {
    RadioProtocolsEditTable,
  },

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }
  },
  methods: {

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('nav/doFetchRadioProtocols', {force})

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

