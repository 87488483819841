<template>
  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/nav">Телематика</router-link></span>
        <span> / </span>
        <span>Карта</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>

        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: false}"
              :filterExists="false"
          >

            <!-- Галочка Отслеживать -->
            <template #leftCustomButton >
              <input-checkbox
                  v-if="tab != 1"
                  id="keep-watch"
                  v-model="keepWatch"
              ><label style="margin-bottom: 0">Отслеживать</label>
              </input-checkbox>
              <!-- при скрытии галочки, вставляем эту заглушку, чтобы сохранить размер крудформы -->
              <div
                  v-else
                  style="height: 24px">
              </div>
            </template>

          </crud-form>
        </align-panel>
        <align-panel align="all">
          <align-container>

            <!-- Карта  -->
            <align-panel align="all" style="z-index: 0 !important;">
              <nav-map
                  :vehicles="getVehiclesOnMap"
                  :tab="tab"
                  @onMapCreated="onMapCreated"
                  @onVehicleInfo="onVehicleInfo"
              >
              </nav-map>
            </align-panel>

            <!-- Правая панель  -->
            <align-panel align="right" width="17rem" :gap="3">
              <align-container>

                <align-panel align="top" width="3rem">

                  <nav-menu class="nav-tabs" mode="single">
                    <nav-item>
                      <nav-link target-ref="pane1" target-method="select" active @click="tab = 0">Онлайн
                      </nav-link>
                    </nav-item>
                    <nav-item>
                      <nav-link target-ref="pane2" target-method="select" @click="tab = 1">История</nav-link>
                    </nav-item>
                  </nav-menu>
                </align-panel>

                <align-panel align="all" width="3rem">

                  <tab-content mode="single">
                    <!-- ОнЛайн -->
                    <tab-pane active ref="pane1" class="p-2">

                      <align-container>
                        <align-panel align="all" :gap="5">

                          <vehicle-button-panel
                              @onHeaderMouseDown="onNoNariadHeaderMouseDown"
                              @onHeaderMouseUp="onNoNariadHeaderMouseUp"
                          >
                            <template #header>
                              Вне наряда:
                            </template>

                            <vehicle-button
                                v-for="vehicle in noNariadVehicles"
                                :vehicle="vehicle"
                                :key="vehicle.transport_id"
                                :titleType="noNariadVehiclesMode"
                                @onVehicleInfo="onVehicleInfo"
                                @onVehicleOnTheMap="onVehicleOnTheMap"
                                @onPanelOnTheMap="onNoNariadPanelOnTheMap"
                                @onAllOnTheMap="onAllOnTheMap"
                                @onSelectGroup="onSelectGroup"
                            ></vehicle-button>

                          </vehicle-button-panel>
                        </align-panel>
                        <align-panel align="bottom" height="25%">
                          <vehicle-button-panel
                              @onHeaderMouseDown="onSpecialVehiclesHeaderMouseDown"
                              @onHeaderMouseUp="onSpecialVehiclesHeaderMouseUp"
                          >
                            <template #header>
                              Специальные:
                            </template>

                            <vehicle-button
                                v-for="vehicle in specialVehicles"
                                :vehicle="vehicle"
                                :key="vehicle.transport_id"
                                :titleType="specialVehiclesMode"
                                @onVehicleInfo="onVehicleInfo"
                                @onVehicleOnTheMap="onVehicleOnTheMap"
                                @onPanelOnTheMap="onSpecialPanelOnTheMap"
                                @onAllOnTheMap="onAllOnTheMap"
                                @onSelectGroup="onSelectGroup"
                            ></vehicle-button>

                          </vehicle-button-panel>
                        </align-panel>
                      </align-container>
                    </tab-pane>

                    <!-- История -->
                    <tab-pane ref="pane2" class="p-2">
                      <align-container>
                        <align-panel align="all">
                          <hist-panel
                              :navTransportArr="nav_transport_arr"
                              :vehicleHistory="vehicle_history"
                              :selectedRow="selectedTS"
                              :selectedNavs="vehicleHistoryArr"
                              :isLoadingVehicles="isLoadingVehicles"
                              :isLoadingNav="isLoadingNav"
                              @getNavTransportOnDay="getNavTransportOnDay"
                              @onRowSelect="onRowSelect"
                              @onCtrlSelect="onCtrlSelect"
                              @onShiftSelect="onShiftSelect"
                              @onClearSelect="onClearSelect"
                              @onSelect="onSelect"
                              @getVehicleNav="getVehicleNav"
                          >
                          </hist-panel>
                        </align-panel>
                      </align-container>
                    </tab-pane>

                  </tab-content>
                </align-panel>
              </align-container>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!--  Диалог "Информация о ТС"  -->
  <vehicle-information-dialog
      v-if="isDialogVisible"
      :item="selectedVehicle"
      :op="op"
      @onRejectOperation="onRejectOperation"
  >
  </vehicle-information-dialog>

  <!--  Диалог "Выбор группы ТС"  -->
  <group-on-the-map-dialog
      v-if="isGroupOnTheMapDialogVisible"
      :vehicles="vehicles"
      :isGroupOnTheMapDialogVisible="isGroupOnTheMapDialogVisible"
      @onRejectOperation="this.isGroupOnTheMapDialogVisible = false"
      @onSelectGroup="onGroupOnTheMap"
  >
  </group-on-the-map-dialog>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import NavMap from "@/components/nav/map/NavMap";
import VehicleButtonPanel from "@/components/nav/map/VehicleButtonPanel";
import VehicleButton from "@/components/nav/map/VehicleButton";
import VehicleInformationDialog from "@/components/nav/map/VehicleInformationDialog";
import GroupOnTheMapDialog from "@/components/nav/map/GroupOnTheMapDialog";
import {latLngBounds} from "leaflet";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import HistPanel from "@/components/nav/map/HistPanel";
import {dateToND} from "@/lib";

export default {
  mixins: [PageMixin],

  components: {
    HistPanel,
    AlignPanel,
    GroupOnTheMapDialog,
    NavMap,
    VehicleButtonPanel,
    VehicleButton,
    VehicleInformationDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // карта
      leaflet: null,
      // таймер
      mainTimer: null,
      // данные, которые отображаются в диалоговом окне
      selectedVehicle: {},
      // первый вызов после команды
      firstWatchAfterCommand: false,
      // первое обновление данных
      firstRefresh: true,
      // отслеживать перемещение транспортных средств
      keepWatch: false,
      // список транспортных средств
      vehicles: [],
      // выбранный список транспортных средств
      selectedVehicleIds: [],
      // режим отображения Специальных ТС
      specialVehiclesMode: 'auto',
      // режим отображения Вне наряда
      noNariadVehiclesMode: 'auto',
      // режим отображения диалога "Выбор группы ТС"
      isGroupOnTheMapDialogVisible: false,
      // список активных транспортных средств за указанный день
      nav_transport_arr: [],
      // История движения транспортного средства за указанное время
      vehicle_history: [],
      // выбранное ТС
      selectedTS: null,
      // список выбранных навигационных отметок
      vehicleHistoryArr: [],
      // переменная в которой хранится индекс строки (для корректной работы выделения строк с удержанием клавиши Shift)
      selectedRowIndex: [],
      // отображение спинера при загрузке ТС за сутки
      isLoadingVehicles: false,
      // отображение спинера при загрузке навигации по ТС
      isLoadingNav: false,
      // номер выбранной вкладки
      tab: 0,
    }
  },

  computed: {

    getVehiclesOnMap(){
      if (this.tab == 0){
        return this.vehiclesOnMap;
      } else {
        return this.historyVehiclesOnMap;
      }
    },

    // вне наряда
    noNariadVehicles() {
      return this.vehicles.filter((item) => !item.special_header)
    },

    // специальные
    specialVehicles() {
      return this.vehicles.filter((item) => item.special_header)
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' && this.id)
    },

    // определяем какие ТС попадут на карту
    // т.к. это computed он в реальном времени обновляет ТС на карте, в том числе и при фильтрации
    vehiclesOnMap() {
      const list = []
      this.vehicles.forEach(vehicle => {
        if (this.selectedVehicleIds.indexOf(vehicle.transport_id) >= 0 && vehicle.latitude && vehicle.longitude) {
            list.push(vehicle)
        }
      })
      return list
    },

    historyVehiclesOnMap() {
      let arr = [];
      for (let i = 0;i < this.vehicleHistoryArr.length; i++) {
        arr.push(this.vehicle_history[this.vehicleHistoryArr[i]]);
        // для корректного отображения иконки нужно добавить к навигационной отметке тип транспорта
        arr[i].transport_type_id = this.selectedTS.transport_type_id;
      }
      return arr;
    },

  },

  methods: {

    async getVehicleNav(date) {
      this.isLoadingNav = true;
      this.vehicleHistoryArr = [];
      try {

        let vehicle_history = await this.$store.dispatch('nav/doFetchVehicleHistory', {
          time_index_begin: dateToND(new Date(date)) * 24 * 60 * 60,
          time_index_end: (dateToND(new Date(date)) * 24 * 60 * 60) + (24 * 60 * 60) - 1,
          transport_id: this.selectedTS.transport_id
        });
        if (vehicle_history)
          this.vehicle_history = vehicle_history;

      } finally {
        this.isLoadingNav = false;
      }
    },

    onRowSelect(selectedRow) {
      this.selectedTS = selectedRow;
    },

    // клик с удержанием клавиши Ctrl
    onCtrlSelect(index) {
      this.selectedRowIndex = index;
      let ind = this.vehicleHistoryArr.findIndex(navInd => navInd == index);
      // если массив выбранных строк содержит индекс, то удаляем его оттуда
      if (ind != -1) {
        this.vehicleHistoryArr.splice(ind, 1);
        // добавляем, если индекса нет
      } else {
        this.vehicleHistoryArr.push(index)
      }
    },

    // клик с удержанием клавиши Shift
    onShiftSelect(index) {
      this.vehicleHistoryArr = [];
      // если уже есть выбранная(сохраненная) строка -> делаем выбранными все строки между выбранными
      if (this.selectedRowIndex || this.selectedRowIndex == 0 ) {
        // если индекс выбранной строки больше индекса сохраненной строки
        if (index >= this.selectedRowIndex) {
          for (let i = this.selectedRowIndex; i <= index; i++) {
            this.vehicleHistoryArr.push(i);
          }
          // если индекс выбранной строки меньше индекса сохраненной строки
        } else if (this.selectedRowIndex > index) {
          for (let i = index; i <= this.selectedRowIndex; i++) {
            this.vehicleHistoryArr.push(i);
          }
        }
      } else {
        this.selectedRowIndex = index;
      }
    },

    // клик без удержания каких-либо клавиш
    onSelect(index) {
      this.selectedRowIndex = index;
      this.vehicleHistoryArr = [];
      this.vehicleHistoryArr.push(index);
    },

    // получение списка активных транспорттных средств за указанный день
    async getNavTransportOnDay(date) {
      this.isLoadingVehicles = true;
      this.selectedTS = null;
      try {
        this.vehicle_history = [];
        let nav_transport_arr = await this.$store.dispatch('nav/doFetchNavTransportOnDay', {
          date_index: dateToND(new Date(date))
        });
        if (nav_transport_arr) {
          nav_transport_arr.sort((v1, v2) => v1.garage_num > v2.garage_num ? 1 : v1.garage_num < v2.garage_num ? -1 : 0)
          this.nav_transport_arr = nav_transport_arr;
        }

      } finally {
        this.isLoadingVehicles = false;
      }
    },

    // очистка выделения в таблице навигации
    onClearSelect(){
      this.vehicleHistoryArr = [];
    },

    // вывод транспортного средства на карту
    onVehicleOnTheMap(vehicle) {
      // одно всгда отсеживаем
      this.keepWatch = true
      // устанавливаем флаг первого вызова
      this.firstWatchAfterCommand = true
      // формируем идентификатор для отображения
      this.selectedVehicleIds = [vehicle.transport_id]
    },

    // все транспортные средства панели вне наряда на карту
    onNoNariadPanelOnTheMap() {
      // не отслеживаем
      this.keepWatch = false
      // устанавливаем флаг первого вызова
      this.firstWatchAfterCommand = true
      // формируем идентификаторы для отображения
      this.selectedVehicleIds = this.noNariadVehicles.map(vehicle => vehicle.transport_id)
    },

    // все транспортные средства панели спеиальные на карту
    onSpecialPanelOnTheMap() {
      // не отслеживаем
      this.keepWatch = false
      // устанавливаем флаг первого вызова
      this.firstWatchAfterCommand = true
      // формируем идентификаторы для отображения
      this.selectedVehicleIds = this.specialVehicles.map(vehicle => vehicle.transport_id)
    },

    // выбранная группа на карту
    onGroupOnTheMap(vehicleList) {
      // не отслеживаем
      this.keepWatch = false
      // скрываем диалоговое окно
      this.isGroupOnTheMapDialogVisible = false
      // устанавливаем флаг первого вызова
      this.firstWatchAfterCommand = true
      // формируем идентификаторы для отображения
      this.selectedVehicleIds = vehicleList.map(vehicle => vehicle.transport_id)
    },

    // все на карту
    onAllOnTheMap() {
      // не отслеживаем
      this.keepWatch = false
      // устанавливаем флаг первого вызова
      this.firstWatchAfterCommand = true
      // формируем идентификаторы для отображения
      this.selectedVehicleIds = this.vehicles.map(vehicle => vehicle.transport_id)
    },

    // открывает диалоговое окно для выбора группы
    onSelectGroup() {
      this.isGroupOnTheMapDialogVisible = true;
    },

    // "отпущен" заголовок панельки Вне наряда
    onNoNariadHeaderMouseDown(e) {
      if (e.button === 0) {
        this.noNariadVehiclesMode = 'garage'
      } else if (e.button === 2) {
        this.noNariadVehiclesMode = 'state'
      }
    },

    // нажат заголовок панельки Вне наряда
    onNoNariadHeaderMouseUp() {
      this.noNariadVehiclesMode = 'auto'
    },

    // "отпущен" заголовок панельки Специальные
    onSpecialVehiclesHeaderMouseDown(e) {
      if (e.button === 0) {
        this.specialVehiclesMode = 'garage'
      } else if (e.button === 2) {
        this.specialVehiclesMode = 'state'
      }
    },

    // нажат заголовок панельки Специальные
    onSpecialVehiclesHeaderMouseUp() {
      this.specialVehiclesMode = 'auto'
    },

    // отображение диалога "Информация о ТС"
    onVehicleInfo(vehicle) {
      this.selectedVehicle = vehicle;
      if(this.selectedTS) {
        if(!this.selectedVehicle['depot_title']) {
          this.selectedVehicle['depot_title'] = this.selectedTS.depot_title
        }
        if(!this.selectedVehicle['transport_type_title']) {
          this.selectedVehicle['transport_type_title'] = this.selectedTS.transport_type_title
        }
        if(!this.selectedVehicle['model_title']) {
          this.selectedVehicle['model_title'] = this.selectedTS.model_title
        }
        if(!this.selectedVehicle['garage_num']) {
          this.selectedVehicle['garage_num'] = this.selectedTS.garage_num
        }
        if(!this.selectedVehicle['state_num']) {
          this.selectedVehicle['state_num'] = this.selectedTS.state_num
        }
      }
      this.$router.replace("/nav/map/show/" + vehicle.transport_id)
    },

    // создана карта
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // отменили операцию
    onRejectOperation() {
      this.selectedVehicle = {};
      // возвращаемся на страницу
      this.$router.replace("/nav/map");
    },

    // проверяем, что все транспортные средства попадают в зону карты
    isVehiclesInTheMap() {
      const bounds = this.leaflet.getBounds()
      return this.vehiclesOnMap.every(v => bounds.contains([v.latitude, v.longitude]))
    },

    // запускаем обновление данных
    async refreshData() {
      const vehicles = await this.$store.dispatch('nav/doFetchCurNav', {})
      if (vehicles) {
        vehicles.sort((v1, v2) => v1.garage_num > v2.garage_num ? 1 : v1.garage_num < v2.garage_num ? -1 : 0);
        this.vehicles = vehicles
        // первое обновление - все на карты
        if (this.firstRefresh && this.leaflet) {
          // все на карту
          this.onAllOnTheMap()
          // сбрасываем флаг первого обновления
          this.firstRefresh = false
        }
      }
    },
  },

  watch: {
    // срабатывает, когда меняется список транспортных средств на карте
    vehiclesOnMap(newList) {
      // первый вызов после команды - позиционируем карту, чтобы влезли транспортные средства
      if (this.firstWatchAfterCommand) {
        if (newList.length === 1) {
          this.leaflet.setView([newList[0].latitude, newList[0].longitude], this.$store.getters['settings/getDefaultVehicleZoom'])
        } else if (newList.length > 1) {
          const bounds = latLngBounds(
              newList.map(vehicle => [vehicle.latitude, vehicle.longitude])
          )
          this.leaflet.fitBounds(bounds);
        }
        // сбрасываем флаг первого вызова
        this.firstWatchAfterCommand = false
        // выходим
        return
      }

      // если отслеживать не надо - выходим
      if (!this.keepWatch) return

      // отслеживаем, чтобы все транспортные средства попадали в карту если выбрана вкадка "Онлайн"
      if (!this.isVehiclesInTheMap() && this.tab == 0) {
        if (newList.length === 1) {
          this.leaflet.setView([newList[0].latitude, newList[0].longitude], this.leaflet.getZoom())
        } else if (newList.length > 1) {
          const bounds = latLngBounds(
              newList.map(vehicle => [vehicle.latitude, vehicle.longitude])
          )
          this.leaflet.fitBounds(bounds);
        }
      }
    },

    historyVehiclesOnMap(newList) {
      if (newList.length === 1) {
        this.leaflet.setView([newList[0].latitude, newList[0].longitude], this.leaflet.getZoom())
      } else if (newList.length > 1) {
        const bounds = latLngBounds(
            newList.map(vehicle => [vehicle.latitude, vehicle.longitude])
        )
        this.leaflet.fitBounds(bounds);
      }
    },

    tab() {
      if (this.getVehiclesOnMap.length === 1) {
        this.leaflet.setView([this.getVehiclesOnMap[0].latitude, this.getVehiclesOnMap[0].longitude], this.leaflet.getZoom())
      } else if (this.getVehiclesOnMap.length > 1) {
        const bounds = latLngBounds(
            this.getVehiclesOnMap.map(vehicle => [vehicle.latitude, vehicle.longitude])
        )
        this.leaflet.fitBounds(bounds);
      }
    }
  },

  // вызывается при создании компонента
  async created() {
    // перезапрашиваются данные
    await this.refreshData();
  },

  mounted() {
    // запускаем таймер
    this.mainTimer = setInterval(() => {
      // перезапрашиваются данные
      this.refreshData();
    }, 20000)
  },

  unmounted() {
    // убиваем таймер
    if (this.mainTimer) {
      clearInterval(this.mainTimer);
      this.mainTimer = null;
    }
  }
};

</script>

<style scoped>

</style>
