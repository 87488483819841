<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-xl modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Выбор варианта расписания</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>

        <div class="form-group" style="margin-top: 5px; margin-bottom: 5px; margin-left: 5px">
          <input-checkbox
              id="isDisabled"
              v-model="activeOnly"
              :readonly="op === 'show'"
          >Только активные
          </input-checkbox>
        </div>

        <modal-body>
          <form>
            <div class="table-container">
              <schedule-variants-edit-table
                  :selectedRow="selectedRow"
                  :scheduleVariants="scheduleVariantArr"
                  @onRowSelect="onRowSelect"
              ></schedule-variants-edit-table>
            </div>
          </form>
        </modal-body>

        <modal-footer v-if="op !== 'show'">
          <button @click="onClear" class="btn btn-primary" style="margin-right: 872px">Очистить</button>
          <button @click="onSubmit" class="btn btn-primary" :disabled="!selectedRow">Ок</button>
          <button @click="$emit('onCancel')" class="btn btn-primary">Отмена</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ScheduleVariantsEditTable from "@/components/kvr/master_orders/ScheduleVariantsEditTable";



export default {
  components: {ScheduleVariantsEditTable},
  props: ['item', 'op', 'scheduleVariants'],
  emits: ['onOK', 'onCancel', 'onClear'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      // выбранная строка
      selectedRow: null,
      activeOnly: false,

    };
  },

  computed: {

    scheduleVariantArr() {
      if (this.activeOnly == false){
        return this.scheduleVariants
      } else {
        return this.scheduleVariants.filter(schVar => {
          return schVar.is_working == true;
        })
      }
    }

  },

  methods: {

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    onSubmit() {
      this.$emit('onOK', this.selectedRow.schedule_variant_id)
    },

    onClear() {
      this.$emit('onClear', null)
    },

  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}

.table-container {
  height: 500px;
}
</style>