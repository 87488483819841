<script>
import {CircleMarker} from 'leaflet'
import LeafletPathMixin from "@/components/ui/leaflet/mixins/LeafletPathMixin";
import {toRaw} from "vue";

// ОКРУЖНОСТЬ-МАРКЕР

export default {
  mixins: [LeafletPathMixin],
  props: {
    // координаты центра круга
    latlng: {
      type: Array,
      required: true,
    },
    // радиус круга (пиксели)
    radius: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletCircleMarker',
    }
  },
  methods: {
    // создание слоя
    createLayer() {
      // создаем слоя
      const layer = new CircleMarker(toRaw(this.latlng), {
        ...toRaw(this.options),
        radius: toRaw(this.radius),
        bubblingMouseEvents: false
      });
      layer.__meta = this.meta;
      // всплывающая подсказка
      if (this.tooltip && Object.keys(this.tooltip).length != 0) {
        layer.bindTooltip(toRaw(this.tooltip));
      }
      // контекстное меню
      if (this.contextMenu) {
        layer.bindContextMenu(toRaw(this.contextMenu));
      }
      this.subscribe(layer);
      this.proxyOrigin = layer;
      return layer;
    },
    destroyLayer() {
      // удаляем подписку на события
      this.unsubscribe(this.origin);
      // отключаем контекстное меню от слоя
      this.origin.unbindContextMenu();
      // отключаем подсказку от слоя
      this.origin.unbindTooltip();
      // убиваем ссылку
      this.proxyOrigin = null;
    },
  },
  // монтируем слой
  mounted() {
    // ждем создание родителя
    this.getParent().parentReady().then(() => {

      // наблюдатель за координатами
      this.$watch(() => this.latlng, (after, before) => {
        if (this.reCreateTimer) return;
        if (this.isLatLngEqual(before, after)) return;
        //console.log('Требуется сместить центр круга: <'+this.curComponent.leafletObjectType+'>: '+this.logTitle)
        this.origin.setLatLng(toRaw(this.latlng));
      }, {deep: false});

      // наблюдатель за радиусом
      this.$watch(() => this.radius, () => {
        if (this.reCreateTimer) return;
        //console.log('Требуется изменить радиус круга: <'+this.curComponent.leafletObjectType+'>: '+this.logTitle)
        this.origin.setRadius(toRaw(this.radius));
      }, {deep: false});
    });
  },
}
</script>
