<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-xl modal-dialog-scrollable"
                  style="max-width: 1850px; max-height: 900px">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Отметки выхода</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>

        <modal-body style="overflow-y: hidden">
          <form>
            <div style="min-height: 711px; height: 100%; ">
              <align-container>
                <align-panel
                    align="top"

                >
                  <!--                  Делаем две строки crud form верхняя для кнопок, нижняя для информации           -->
                  <crud-form
                      :buttonsExist="{add: false, edit: false, delete: false, refresh: false}"
                      :filterExists="false"
                  >

                    <template #preLeftCustomButton>
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onSurvey"
                          title="На связь"
                          disabled
                      >
                        На связь
                      </button>
                    </template>

                    <template #preLeftCustomButton2>
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onSurvey"
                          title="На карту"
                          disabled
                      >
                        На карту
                      </button>
                    </template>

                    <template #preLeftCustomButton3>
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onSurvey"
                          title="Трек на карту"
                          disabled
                      >
                        Трек на карту
                      </button>
                    </template>

                    <template #preLeftCustomButton4>
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onSurvey"
                          title="Фактические отметки"
                          disabled
                      >
                        Фактические отметки
                      </button>
                    </template>

                    <template #preLeftCustomButton5>
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onSurvey"
                          title="Отправка сообщения"
                          disabled
                      >
                        Отправка сообщения
                      </button>
                    </template>

                    <template #leftCustomButton>
                      <div style="margin-top: 3px">
                        <a>Рейсы -/- Обед -/-</a>
                      </div>
                    </template>

                    <template #leftCustomButton2>
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onSurvey"
                          title="Прогноз"
                          disabled
                      >
                        Прогноз
                      </button>
                    </template>

                    <template #leftCustomButton3>
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onSurvey"
                          title="Интервал"
                          disabled
                      >
                        Интервал
                      </button>
                    </template>

                    <template #leftCustomButton4>
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onSurvey"
                          title="Режимы"
                          disabled
                      >
                        Режимы
                      </button>
                    </template>

                    <template #leftCustomButton5>
                      <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onSurvey"
                          title="Управления"
                          disabled
                      >
                        Управления
                      </button>
                    </template>

                    <template #rightCustomButton>
                      <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          @click="refreshData"
                          title="Обновить"
                      >
                        <i class="fas fa-sync-alt" style="right: 7px"></i>
                      </button>
                    </template>

                  </crud-form>
                  <crud-form
                      :buttonsExist="{add: false, edit: false, delete: false, refresh: false}"
                      :filterExists="false"
                  >

                    <template #preLeftCustomButton>
                      <div class="row" style="margin: 0">
                        <div style="margin-top: 4px">
                          Маршрут
                        </div>
                        <input-string
                            class="form-control"
                            id="routeTitle"
                            v-model="routeTitle"
                            disabled
                            style="width: 50px;height: 31px; padding-left: 2px; padding-right: 2px; margin-left: 10px"
                        ></input-string>
                      </div>
                    </template>

                    <template #preLeftCustomButton2>
                      <div class="row" style="margin: 0">
                        <div style="margin-top: 4px">
                          Выход
                        </div>
                        <input-string
                            class="form-control"
                            id="graphTitle"
                            v-model="graphTitle"
                            disabled
                            style="width: 50px;height: 31px; padding-left: 2px; padding-right: 2px; margin-left: 10px"
                        ></input-string>
                      </div>
                    </template>

                    <template #preLeftCustomButton3>
                      <div class="row" style="margin: 0">
                        <div style="margin-top: 4px">
                          Смена
                        </div>
                        <input-string
                            class="form-control"
                            id="titleFilterText"
                            v-model="shiftTitle"
                            disabled
                            style="width: 50px;height: 31px; padding-left: 2px; padding-right: 2px; margin-left: 10px"
                        ></input-string>
                      </div>
                    </template>

                    <template #preLeftCustomButton4>
                      <i class="fas fa-wheelchair" style="right: 7px"></i>
                    </template>

                    <template #preLeftCustomButton5>
                      <div class="row" style="margin: 0">
                        <div style="margin-top: 4px">
                          Гаражный №
                        </div>
                        <input-string
                            class="form-control"
                            id="titleFilterText"
                            v-model="vehicleGarageNum"
                            disabled
                            style="width: 120px;height: 31px; padding-left: 2px; padding-right: 2px; margin-left: 10px"
                        ></input-string>
                      </div>
                    </template>

                    <template #leftCustomButton>
                      <div class="row" style="margin: 0">
                        <div style="margin-top: 4px">
                          Гос. №
                        </div>
                        <input-string
                            class="form-control"
                            id="titleFilterText"
                            v-model="vehicleStateNum"
                            disabled
                            style="width: 120px;height: 31px; padding-left: 2px; padding-right: 2px; margin-left: 10px"
                        ></input-string>
                      </div>
                    </template>

                    <template #leftCustomButton2>
                      <div class="row" style="margin: 0">
                        <div style="margin-top: 4px">
                          Модель
                        </div>
                        <input-string
                            class="form-control"
                            id="titleFilterText"
                            v-model="vehicleModel"
                            disabled
                            style="width: 120px;height: 31px; padding-left: 2px; padding-right: 2px; margin-left: 10px"
                        ></input-string>
                      </div>
                    </template>

                    <template #leftCustomButton3>
                      <div class="row" style="margin: 0">
                        <div style="margin-top: 4px">
                          Таб. № водителя
                        </div>
                        <input-string
                            class="form-control"
                            id="titleFilterText"
                            v-model="driverPersonelNum"
                            disabled
                            style="width: 120px;height: 31px; padding-left: 2px; padding-right: 2px; margin-left: 10px"
                        ></input-string>
                      </div>
                    </template>

                    <template #rightCustomButton>
                      <div class="row" style="margin: 0">
                        <div style="margin-top: 4px">
                          Дата
                        </div>
                        <input-string
                            class="form-control"
                            id="titleFilterText"
                            v-model="monitoringTaskDate"
                            disabled
                            style="width: 120px;height: 31px; padding-left: 2px; padding-right: 2px; margin-left: 10px"
                        ></input-string>
                      </div>
                    </template>

                  </crud-form>

                </align-panel>
                <align-panel align="all">
                  <monitoring-task-card-edit-table
                      :tableRows="tableRows"
                      @onRowSelect="select"
                      @onRowRightClick="rowRightClick"
                      :selectedRows="selectedRows"
                  >
                  </monitoring-task-card-edit-table>
                </align-panel>
              </align-container>
            </div>
          </form>
        </modal-body>

        <modal-footer style="justify-content: flex-start !important;">
          <div>

            <div class="row">

              <div class="col" v-if="isButtonVisible" style="width: 200px; margin-left: 16px; padding: 0;">

                <button

                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTimeCorrectionDialog(1)"
                    :title="getManagementActionTittle(1)"
                    :disabled="isSingleSelectionButtonDisabled"
                    style="height: 31px; width: 200px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  {{getManagementActionTittle(1)}}
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTimeCorrectionDialog(2)"
                    :title="getManagementActionTittle(2)"
                    :disabled="isSingleSelectionButtonDisabled"
                    style="height: 31px; width: 200px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  {{getManagementActionTittle(2)}}
                </button>

              </div>
              <div class="col" v-else style="width: 200px; margin-left: 16px; padding: 0;">
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTimeCorrectionDialog(3)"
                    :title="getManagementActionTittle(3)"
                    :disabled="isMultipleSelectionButtonDisabled"
                    style="height: 31px; width: 200px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  {{getManagementActionTittle(3)}}
                </button>
              </div>

              <!--              Разделитель              -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>


              <div class="col" style="width: 230px; margin-left: 5px; padding: 0;">

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTimeCorrectionDialog(4)"
                    :title="getManagementActionTittle(4)"
                    :disabled="isSingleSelectionButtonDisabled"
                    style="height: 31px; width: 230px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  {{getManagementActionTittle(4)}}
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTimeCorrectionDialog(5)"
                    :title="getManagementActionTittle(5)"
                    :disabled="isSingleSelectionButtonDisabled"
                    style="height: 31px; width: 230px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  {{getManagementActionTittle(5)}}
                </button>

              </div>

              <!--              Разделитель              -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>

              <div class="col" style="width: 45px !important; margin-left: 5px; padding: 0;">

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  ИН
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  НВ
                </button>

              </div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  НБ
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  СНВ
                </button>

              </div>

              <!--              Разделитель           -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  ДВ
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  УДВ
                </button>

              </div>

              <!--              Разделитель           -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  ЗК
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  ВК
                </button>

              </div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  РВТ
                </button>

              </div>

              <!--              Разделитель           -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  Р
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  УР
                </button>

              </div>

              <!--              Разделитель           -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  О
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  УО
                </button>

              </div>

              <!--              Разделитель           -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  РК
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  СС
                </button>
              </div>

              <!--              Разделитель           -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  НС
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  УС
                </button>
              </div>
              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  ОС
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  ИКС
                </button>
              </div>

              <!--              Разделитель           -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  СВ
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  ВП
                </button>
              </div>

              <!--              Разделитель           -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  ВМ
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  УМ
                </button>
              </div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  ДКП
                </button>

              </div>

              <!--              Разделитель           -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  ВКП
                </button>

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px; margin-top: 10px"
                >
                  УКП
                </button>
              </div>

              <!--              Разделитель           -->
              <div style="height: 72px; width: 1px; background-color: lightslategray; margin-left: 5px"></div>

              <div class="col" style="width: 45px; margin-left: 5px; padding: 0;">

                <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="onTasksTableRefresh"
                    title="Обновить"
                    style="height: 31px; width: 45px; padding-left: 3px; padding-right: 3px; padding-top: 3px"
                >
                  ПРС
                </button>

              </div>
            </div>

          </div>
        </modal-footer>
      </modal-content>

      <time-correction-dialog
        v-if="managementActionSelectedId"
        :selectedRow="selectedRows[0]"
        :management_action_id="managementActionSelectedId"
        @onCancel="onTimeCorrectionDialog(false)"
        @onOK="timeCorrection"
      >
      </time-correction-dialog>

    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import CrudForm from "@/components/ui/custom/CrudForm";
import InputString from "@/components/ui/custom/InputString";
import MonitoringTaskCardEditTable from "@/components/trn/dispatching/MonitoringTaskCardEditTable";
import {getManageDriverTrn, getManagementAction, getManageRouteTrn, getManageVehicleTrn} from "@/store/TrnHelpers";
import {getModel} from "@/store/RefHelpers";
import {ndToDateString} from "@/lib";
import TimeCorrectionDialog from "@/components/trn/dispatching/TimeCorrectionDialog";


export default {
  components: {TimeCorrectionDialog, InputString, CrudForm, AlignPanel, AlignContainer, MonitoringTaskCardEditTable},
  props: ['item', 'op', 'selectedMonitoringTask'],
  emits: ['onCancel', 'onLoading'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      // выбранная строка
      selectedRows: [],
      // строки таблицы
      tableRows: [],

      routeTitle: '',
      graphTitle: '',
      shiftTitle: '',

      vehicleGarageNum: '',
      vehicleStateNum: '',
      vehicleModel: '',
      driverPersonelNum: '',
      monitoringTaskDate: '',
      onEscape: null,

      managementActionSelectedId: null,
    };
  },

  computed: {

    isSingleSelectionButtonDisabled() {
      if (this.selectedRows.length == 1) {
        return false;
      }
      return true;
    },

    isMultipleSelectionButtonDisabled() {
      if (this.selectedRows.length > 1) {
        return false;
      }
      return true;
    },

    isButtonVisible() {
      if (this.selectedRows.length <= 1) {
        return true;
      }
      return false;
    },

  },

  methods: {

    getManagementActionTittle(management_action_id){
      let action = getManagementAction(management_action_id);
      if (action) {
        return action.short_name;
      }
      return '';
    },

    // отправляем воздействие "Начало коррекции"
    async timeCorrection(value) {
      try {
        value['date_index'] = this.selectedMonitoringTask.date_index;
        let list = [];
        value['monitoring_task_id'] = this.selectedRows[0].monitoring_task_id;
        if (this.selectedRows.length == 1) {

          value['order_num_begin'] = this.selectedRows[0].order_num;
          value['order_num_end'] = this.selectedRows[0].order_num;
        } else {
          this.selectedRows.forEach(value => {
            list.push(value.monitoring_task_id);
          });
          value['order_num_begin'] = this.selectedRows[0].order_num;
          value['order_num_end'] = this.selectedRows[this.selectedRows.length - 1].order_num;
        }

        await this.$store.dispatch('trn/executeManagementTimeCorrection', value);

      } finally {
        this.managementActionSelectedId = null;
        this.selectedRows = [];
        await this.refreshData();
      }
    },

    onTimeCorrectionDialog(value) {
      this.managementActionSelectedId = value;
    },

    rowRightClick(row) {
      if(this.selectedRows.length != 0 && this.selectedRows[0].monitoring_task_id == row.monitoring_task_id) {
        this.onTimeCorrectionDialog(1);
      }
    },

    // выбрана строка в таблице Рейсов
    // onRowSelect(item) {
    select(selectedRow, e) {
      if (!this.isLoading) {
        // if (e.ctrlKey && !e.shiftKey) {
        //   // при выборе строки через Ctrl добавляем в начало массива т.к. для отрисовки это не имеет значения, но в случае,
        //   // если после выбора строки через Ctrl пользователь попытается выбрать строки через Shift, то выделение будет от
        //   // последней выбраной при помощи Ctrl строки, а Shift всегда выделяет от первой выделенной с Shift строки.
        //   let index = this.selectedRows.findIndex(value => (value.monitoring_task_id == selectedRow.monitoring_task_id &&
        //       value.order_num == selectedRow.order_num ));
        //   if (index >= 0) {
        //     this.selectedRows.splice(index, 1);
        //   } else {
        //     this.selectedRows.unshift(selectedRow);
        //   }
        // } else
        if (!e.ctrlKey && e.shiftKey) {
          // определяем есть ли выбранная строка
          if (this.selectedRows.length != 0) {
            let firstSelectedRow = this.selectedRows[0];
            this.selectedRows = [];
            // определяем индекс от которого считать выделенные строки
            let firstIndex = this.tableRows.findIndex(value => (value.monitoring_task_id == firstSelectedRow.monitoring_task_id &&
                value.order_num == firstSelectedRow.order_num));
            // определяем индекс выделенного строки до которой будет выделение
            let secondIndex = this.tableRows.findIndex(value => (value.monitoring_task_id == selectedRow.monitoring_task_id &&
                value.order_num == selectedRow.order_num));
            if (firstIndex >= 0 && secondIndex >= 0 && firstIndex < secondIndex) {
              this.selectedRows.unshift(this.tableRows[firstIndex]);
              for (let i = firstIndex + 1; i <= secondIndex; i++) {
                this.selectedRows.push(this.tableRows[i]);
              }
            } else if (firstIndex >= 0 && secondIndex >= 0 && firstIndex > secondIndex) {
              this.selectedRows.push(this.tableRows[secondIndex]);
              for (let i = secondIndex + 1; i <= firstIndex; i++) {
                this.selectedRows.unshift(this.tableRows[i]);
              }
            }
          } else {
            this.selectedRows.push(selectedRow);
          }
        } else if ((!e.ctrlKey && !e.shiftKey)) {
          this.selectedRows = [];
          this.selectedRows.push(selectedRow);
        } else {
          this.selectedRows = [];
        }

      }
    },

    async refreshData() {
      this.$emit('onLoading', true);
      try {
        let tableRows = await this.$store.dispatch('trn/doFetchMonitoringTaskCard', {
          date_index: this.selectedMonitoringTask.date_index,
          monitoring_task_id: this.selectedMonitoringTask.monitoring_task_id,
        });
        if (tableRows) {
          this.tableRows = tableRows;
        }
      } finally {
        this.$emit('onLoading', false);
      }
    }

  },

  mounted() {
    this.onEscape = (e) => {
      if (e.keyCode === 27) {
        this.$emit('onCancel');
      }
    }
    document.addEventListener('keydown', this.onEscape)
  },

  unmounted() {
    document.removeEventListener('keydown', this.onEscape)
  },

  async created() {

    await this.refreshData();

    await this.$store.dispatch('ref/doFetchModels');

    let route = getManageRouteTrn(this.selectedMonitoringTask.route_id);
    if (route) {
      this.routeTitle = route.route_num;
    }


    this.graphTitle = this.selectedMonitoringTask.graph;


    this.shiftTitle = this.selectedMonitoringTask.shift;

    let ts = getManageVehicleTrn(this.selectedMonitoringTask.transport_id);


    if (ts) {
      this.vehicleGarageNum = ts.garage_num;
      this.vehicleStateNum = ts.state_num;


      let model = getModel(ts.model_id);
      if (model) {
        this.vehicleModel = model.long_name;
      }
    }
    // вносим водителя
    let driver = getManageDriverTrn(this.selectedMonitoringTask.driver_id);
    if (driver) {
      this.driverPersonelNum = driver.personnel_num;
    }

    this.monitoringTaskDate = ndToDateString(this.selectedMonitoringTask.date_index);

  }
}
</script>

<style scoped>
form label {
  font-weight: bold;
}

.table-container {
  height: 500px;
}
</style>