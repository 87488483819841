<template>
  <input-select ref="select" v-model="value" caption="Выберите водителя..." :itemList="items"></input-select>
</template>

<script>
export default {
  props: ['model-value', 'depot_id'],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      let arr = []
      this.$store.getters['kvr/getOrderDrivers'].map(driver => {
        if (this.depot_id == driver.depot_id) {
          arr.push({
            id: driver.driver_id,
            title: driver.personnel_num + ' — ' + driver.last_name + ' ' + driver.first_name.substring(0, 1) + '.' + driver.middle_name.substring(0, 1) + '.',
          });
        }
      });
      return arr;
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
