<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-multipass-tariff-zone"> </edit-table-panel>
</template>

<script>

export default {
  props: ['rows', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "tariff_zone_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "tariff_zone_num",
          caption: "Номер тарифной зоны",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.rows.map(zone => {
        zone.__id = zone.tariff_zone_system_id;
        return zone;
      });
    },
  }
};
</script>
