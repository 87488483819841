import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ЭКСПЕРТОВ

export default {
    state() {
        return {
            // список объектов
            experts: [],
            // время обновления кэша new Date().getTime()
            expertsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            expertsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getExperts(state) {
            return state.experts;
        },
    },
    mutations: {
        // установить новый список объектов
        setExperts(state, payload) {
            state.experts = payload;
            state.expertsCache = new Date().getTime()
        },
    },
    actions: {

        // запрос списка объектов
        doFetchExperts: (context, payload) => {
            return DoGet(
                '/kvr-expert/getExperts',
                'kvr-expert/setExperts',
                [],
                'Получить список экспертов',
                false,
                payload && payload.force,
                context.state.expertsCache,
                context.state.expertsCacheTimeout
            )
        },

        // очищает кэш
        doClearExperts: (context, payload) => {
            return DoClearCache(
                'kvr-expert/setExperts',
                [],
                payload && payload.force,
                context.state.expertsCache,
                context.state.expertsCacheTimeout
            )
        },
    }
}
