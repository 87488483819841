<template>
  <edit-table-panel :columnList="columnList" :rowList="rows" name="tbl-multipass-stop-pavilions"></edit-table-panel>
</template>

<script>

import {getStopPavilionKinds, getStopPavilionType} from "@/store/MultipassHelpers";

export default {
  props: [
      // признак загрузки
      'isLoading',
      // список строк
      'rowList'
  ],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "stop_pavilion_id",
          caption: "Код",
          sorted: 'asc',
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
          show: false,
        },
        {
          field: "__stopPavilionKindName",
          caption: "Вид",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__stopPavilionTypeName",
          caption: "Тип",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "latitude",
          caption: "Широта",
          sortType: 'number',
          show: false,
        },
        {
          field: "longitude",
          caption: "Долгота",
          sortType: 'number',
          show: false,
        },
        {
          field: "length",
          caption: "Длина",
          sortType: 'number',
          show: false,
        },
        {
          field: "width",
          caption: "Ширина",
          sortType: 'number',
          show: false,
        },
        {
          field: "height",
          caption: "Высота",
          sortType: 'number',
          show: false,
        },
        {
          field: "inside_area_useful",
          caption: "Площадь для пассажиров",
          sortType: 'number',
          show: false,
        },
        {
          field: "total_area",
          caption: "Общая площадь",
          sortType: 'number',
          show: false,
        },
        {
          field: "is_video_record_system",
          caption: "видеонаблюдение",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_video_record_system) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false,
        },
        {
          field: "is_multimedia",
          caption: "Мультимедийняа система",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_multimedia) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false,
        },
        {
          field: "is_eboard",
          caption: "Информационное табло",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_eboard) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false,
        },
        {
          field: "is_handicapped",
          caption: "Алаптирован для маломолбильных групп граждан",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_handicapped) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          show: false,
        },
      ],
    };
  },
  computed: {
    rows() {
      if (this.isLoading === 'fetch') {
        return []
      }
      return this.rowList.map((stopPavilion) => {

        stopPavilion.__id = stopPavilion.stop_pavilion_id;
        const stopPavilionType = getStopPavilionType(stopPavilion.stop_pavilion_type_id);
        const stopPavilionKind = getStopPavilionKinds(stopPavilion.stop_pavilion_kind_id);

        stopPavilion.__stopPavilionTypeName = stopPavilionType && stopPavilionType.long_name || '';
        stopPavilion.__stopPavilionKindName = stopPavilionKind && stopPavilionKind.long_name || '';

        return stopPavilion;
      });
    },
  },
};
</script>
