
// ХРАНИЛИЩЕ ГРАФИКОВ

export default {
    actions: {
        // запрос набора графиков (для табличного отображения)
        // doFetchGraphs (context, payload) {
        doFetchGraphs () {
            return [
                // {
                //     graphLine: [
                //         {
                //             type: 'before',
                //             length: 54,
                //         },
                //         {
                //             type: 'line',
                //             length: 100,
                //         },
                //         {
                //             type: 'planstay',
                //             length: 5,
                //         },
                //         {
                //             type: 'line',
                //             length: 30,
                //         },
                //         {
                //             type: 'change',
                //             length: 5,
                //         },
                //         {
                //             type: 'line-gray',
                //             length: 180,
                //         },
                //         {
                //             type: 'inttrips-gray',
                //             length: 68,
                //         },
                //         {
                //             type: 'line-gray',
                //             length: 206,
                //         },
                //     ],
                //     number: 0,
                //     stayTime: 165,
                //     workTime: 1048,
                //     nullTrip: '',
                //     textAfter: '(19:31, 10:59)',
                //     textAfterAddition: '(8:26, 12:34)'
                // },

                {
                    graphLine: [
                        {
                            type: 'before',
                            length: 54,
                        },
                        {
                            type: 'line',
                            length: 359,
                        },
                        {
                            type: 'maintenance',
                            length: 97,
                        },
                        {
                            type: 'line',
                            length: 239,
                        },
                        {
                            type: 'change',
                            length: 5,
                        },
                        {
                            type: 'line-gray',
                            length: 239,
                        },
                        {
                            type: 'special-gray',
                            length: 68,
                        },
                        {
                            type: 'line-gray',
                            length: 206,
                        },
                    ],
                    number: 1,
                    stayTime: 165,
                    workTime: 1048,
                    nullTrip: '',
                    textAfter: '(9:58, 7:30)'
                },
                {
                    graphLine: [
                        {
                            type: 'before',
                            length: 50,
                        },
                        {
                            type: 'line',
                            length: 313,
                        },
                        {
                            type: 'refuel',
                            length: 69,
                        },
                        {
                            type: 'line',
                            length: 239,
                        },
                        {
                            type: 'change',
                            length: 13,
                        },
                        {
                            type: 'line-gray',
                            length: 259,
                        },
                        {
                            type: 'switching-gray',
                            length: 52,
                        },
                        {
                            type: 'line-gray',
                            length: 314,
                        },
                    ],
                    number: 2,
                    stayTime: 121,
                    workTime: 1125,
                    nullTrip: '',
                    textAfter: '(9:12, 7:30)'
                },
                {
                    graphLine: [
                        {
                            type: 'before',
                            length: 96,
                        },
                        {
                            type: 'line',
                            length: 180,
                        },
                        {
                            type: 'res',
                            length: 196,
                        },
                        {
                            type: 'line',
                            length: 238,
                        },
                        {
                            type: 'stay',
                            length: 35,
                        },
                        {
                            type: 'line',
                            length: 253,
                        },
                    ],
                    number: 9,
                    stayTime: 231,
                    workTime: 971,
                    nullTrip: '',
                    textAfter: '(9:31, 10:99)',
                    textAfterAddition: '10:47'
                }
            ]
        },

    }
}
