<template>
  <div class="form-check">
    <input class="form-check-input" type="radio" :id="id" :value="value" :disabled="readonly" v-model="val">
    <label class="form-check-label" :for="id"><slot></slot></label>
  </div>
</template>

<script>
export default {
  props: {
    'id': {
      type: String,
      required: true
    },
    'model-value': {
      required: true
    },
    'value': {
      required: true
    },
    'readonly': {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value'],
  computed: {
    val: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
  },
  methods: {
    // вызывается для проверки формы
    validate() {
      return true
    },
  },
}
</script>
