<template>
  <!--  <select-->
  <!--      v-model="value"-->
  <!--      v-bind="$attrs"-->
  <!--      @change="onChange"-->
  <!--      :class="{ 'is-invalid': isInvalid}"-->
  <!--      :disabled="readonly"-->
  <!--      @focus="onFocus"-->
  <!--      @blur="onBlur">-->
  <!--    <option-->
  <!--        v-for="item in items"-->
  <!--        :key="item.id"-->
  <!--        :value="item.id"-->
  <!--        :disabled="this.required && item.id === null"-->
  <!--    >{{ item.title }}</option>-->
  <!--  </select>-->
  <dropdown>
    <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
    >{{ title }}
    </button-toggler>
    <dropdown-menu ref="collapse" style="width: 110%">
      <dropdown-item v-for="val in itemList" :key="val" @click="value=val.id" style="padding-left: 5px; padding-right: 5px;"
      >
        <div class="form-group row" style="margin: 0;">
          <div v-if="isCheckShowed(val)" class="form-group col-0" style="margin: 0; padding: 0px; width: 16px">
            <i class="fas fa-check"
               style="padding-left: 6px; padding-right: 6px; vertical-align: middle; "></i>
          </div>
          <div class="form-group col" style="margin: 0;  padding: 0px;">
            <p :style="isCheckShowed(val) ? 'padding-top: 0px; padding-left: 10px; padding-right: 0px; padding-bottom: 0px;' : 'padding-left: 27px'" style="margin: 0;">{{ val.title }}</p>
          </div>
        </div>
      </dropdown-item>
    </dropdown-menu>
  </dropdown>

  <div class="invalid-feedback" v-if="!!errorText">{{ errorText }}</div>
  <div class="invalid-feedback" v-else>Выберите значение</div>
</template>

<script>
export default {
  emits: ['update:model-value', 'change'],
  props: {
    'model-value': {
      required: true
    },
    // массив элементов [{id: any, title: string}]
    itemList: {
      type: Array,
      required: true
    },
    // название
    title: {
      type: String,
      required: true
    },
    // заголовок
    caption: {
      type: String,
      // required: true
      default: null
    },
    // требуется ввод значения
    'required': {
      type: Boolean,
      default: false
    },
    // только для чтения
    'readonly': {
      type: Boolean,
      default: false
    },
    'error': {
      type: String
    },
    // максимальная длина title
    max_length: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isInvalid: false,
      errorText: this.error
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:model-value', value);
        this.$emit('change')
      }
    },
    items() {
      const newItems = this.itemList.map((item) => {
        let it = {...item};
        if (this.max_length != 0 && it.title.length > this.max_length) {
          it.title = it.title.substring(0, this.max_length);
        }
        return it;
      });
      // формируем нулевое значение, если оно есть
      if (this.caption != null) {
        newItems.unshift({
          id: null,
          title: this.caption
        })
      } else {
        this.$emit('update:model-value', this.itemList[0].id);
      }
      // возвращаем результат
      return newItems
    },
  },

  methods: {
    isCheckShowed(val) {
      return val.id == this.value;
    },
    // проверка валидности
    isValid() {
      // проверяем разрешены ли пустые значения
      if (this.modelValue == null) {
        return !this.required
      }
      // все остальное можно
      return true;
    },
    // при получении фокуса
    onFocus() {
      // сбрасываем инвалидность
      this.isInvalid = false;
      this.errorText = this.error;
    },
    // при потере фокуса
    onBlur() {
      this.validate()
    },
    // при вводе значений
    onChange() {
      this.$nextTick(this.validate);
    },
    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },
    alarm(message) {
      this.errorText = message;
      this.isInvalid = true;
    },

  },
  created() {
    // на всякий случай - меняем undefined на null
    this.$watch(() => this.modelValue, () => {
      if (this.modelValue === undefined) {
        this.$emit('update:model-value', null);
      }
    }, {immediate: true});
    // следим за изменением текста
    this.$watch(() => this.error, () => {
      this.errorText = this.error;
    })
  }
}
</script>
