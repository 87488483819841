import {DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ТРАНСОПРТНЫХ СРЕДСТВ НА МАРШРУТАХ ЗА УКАЗАННЫЙ ДЕНЬ

export default {
    state() {
        return {
            // список танспортных средств на маршрутах за указанный день
            workTransportOnDay: [],
            // время обновления кэша new Date().getTime()
            workTransportOnDayCache: null,
            // время жизни кэша в мс (null - бесконечно)
            workTransportOnDayCacheTimeout: null
        }
    },
    getters: {
        // вернуть список
        getWorkTransportOnDay(state) {
            return state.workTransportOnDay
        },
    },
    mutations: {
        // установить новый список
        setWorkTransportOnDay(state, payload) {
            state.workTransportOnDay = payload;
            state.workTransportOnDayCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка
        doFetchWorkTransportOnDay: (context, payload) => {
            return DoGet(
                '/kvr/getWorkTransportOnDay/' + payload.date_index,
                'kvr/setWorkTransportOnDay',
                [],
                'Запрос транспортных средств на маршрутах за указанный день',
                false,
                true,
            )
        },
    }
}
