import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ АВТОСТАНЦИЙ

export default {
    state() {
        return {
            // список объектов
            stopstations: [],
            // время обновления кэша new Date().getTime()
            stopstationsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            stopstationsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getStopstations(state) {
            return state.stopstations;
        },
    },
    mutations: {
        // установить новый список объектов
        setStopstations(state, payload) {
            state.stopstations = payload;
            state.stopstationsCache = new Date().getTime()
        },
        // добавить объект
        addStopstation(state, payload) {
            state.stopstations.push(payload)
        },
        // изменить объект
        editStopstation(state, payload) {
            const index = state.stopstations.findIndex(stopstation => stopstation.stopstation_id == payload.stopstation_id);
            if (index >= 0) {
                state.stopstations[index] = payload;
            }
        },
        // удалить объект
        delStopstation(state, payload) {
            const index = state.stopstations.findIndex(stopstation => stopstation.stopstation_id == payload.stopstation_id);
            state.stopstations.splice(index, 1);
        },
    },
    actions: {
        // запрос объекта
        doFetchStopstation: (context, payload) => {
            return DoGet(
                '/multipass/stopstations/'+payload.stopstation_id,
                null,
                {},
                'Запрос автостанции',
                false,
                true,
            )
        },

        // запрос списка объектов
        doFetchStopstations: (context, payload) => {
            return DoGet(
                '/multipass/stopstations',
                'multipass/setStopstations',
                [],
                'Запрос автостанций',
                false,
                payload && payload.force,
                context.state.stopstationsCache,
                context.state.stopstationsCacheTimeout
            )
        },

        // очищает кэш
        doClearStopstations: (context, payload) => {
            return DoClearCache(
                'multipass/setStopstations',
                [],
                payload && payload.force,
                context.state.stopstationsCache,
                context.state.stopstationsCacheTimeout
            )
        },

        // добавление объекта
        doAddStopstation: (context, payload) => {
            return DoPost(
                '/multipass/stopstations',
                payload,
                'multipass/addStopstation',
                'Добавление автостанции'
            )
        },

        // изменение объекта
        doEditStopstation: (context, payload) => {
            return DoPut(
                '/multipass/stopstations/' + payload.stopstation_id,
                payload,
                'multipass/editStopstation',
                'Изменение автостанции'
            )
        },

        // удаление объекта
        doDelStopstation: (context, payload) => {
            return DoDelete(
                '/multipass/stopstations/'+payload.stopstation_id,
                'multipass/delStopstation',
                'Удаление автостанции'
            )
        },

        // обновление объекта
        doUpdateStopstation: (context, payload) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const updatedStopstation = {...payload};
                    context.commit('editStopstation', updatedStopstation)
                    resolve(updatedStopstation);
                },0)
            })
        }
    }
}