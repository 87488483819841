
// ХРАНИЛИЩЕ ЗАПИСЕЙ ЖУРНАЛА КОМАНД ЭКСПЕРТА


import {DoGet} from "@/store/Actions";

export default {

    actions: {

        // запрос данных
        doFetchExpCommandLogs: (context, payload) => {
            // формируем url
            let url = '/kvr-expert/getExpCommandLog?date_index_begin=' + payload.date_index_begin + '&date_index_end=' + payload.date_index_end
            if (payload.route_id) {
                url = url + '&route_id=' + payload.route_id;
            }
            if(payload.uid) {
                url = url + '&uid=' + payload.uid;
            }
            if(payload.is_trip_date_index) {
                url = url + '&is_trip_date_index';
            }

            return DoGet(
                url,
                null,
                [],
                'Запрос записей из журнала команд эксперта',
                false,
            )
        }

    }
}