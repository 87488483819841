<template>
    <modal>
      <modal-dialog class="modal-dialog-centered">
        <modal-content>
          <modal-header class="bg-primary text-white">
            <modal-title>Установка пароля:</modal-title>
            <button-close class="text-white" @click="$emit('onCancel')"></button-close>
          </modal-header>
          <modal-body>
            <form>
              <div class="form-group">
                <label for="password">Новый пароль</label><req-field />
                <input-string
                    class="form-control"
                    id="password"
                    ref="password"
                    v-model="password"
                    type="password"
                    autocomplete="off"
                    required
                    error="Введите пароль"
                />
              </div>
              <div class="form-group">
                <label for="repeatPassword">Повтор нового пароля:</label><req-field />
                <input-string
                    class="form-control"
                    id="repeatPassword"
                    ref="repeatPassword"
                    v-model="repeatPassword"
                    type="password"
                    autocomplete="off"
                    required
                    error="Введите пароль ещё раз"
                />
              </div>
            </form>
          </modal-body>
          <modal-footer>
            <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
          </modal-footer>
        </modal-content>
      </modal-dialog>
    </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";

export default {
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      password: "",
      repeatPassword: "",
    };
  },

  methods: {
    onSubmit() {
      // проверяем форму
      if (this.validateForm(this.$refs)) {
        // проверяем совпадение паролей
        if (this.password !== this.repeatPassword) {
          this.$refs.repeatPassword.alarm('Пароли не совпадают');
          return
        }

        // формируем событие
        this.$emit('onOK', {
          new_password: this.password,
        });
        // сбрасываем значения
        this.password = "";
        this.repeatPassword = "";
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>