<template>
  <align-container>
    <align-panel align="top">
      <page-header>
        <span><router-link to="/kvr">КВР</router-link></span>
        <span> / </span>
        <span><router-link to="/kvr/kvrExpertAnalysis">Анализ</router-link></span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <align-panel align="top" :gap="3">
          <!-- Форма с кнопками над левой таблицей-->
          <card class="text-left">
            <card-body class="p-1">
              <div class="form-row">

                <div class="col-0" style="margin-bottom: 0 !important;">
                  <button
                      type="button"
                      class="btn btn-success btn-sm"
                      @click="onRefreshExpResultTrips"
                      title="Загрузить"
                      style="padding-top: 0; padding-bottom: 0; height: 31px;"
                  >
                    <a style="font-size: 16px">Загрузить</a>
                  </button>
                </div>

                <!--             дата с                -->
                <div class="col-0" style="margin-bottom: 0 !important; padding-right: 0; vertical-align: middle">
                  <p style="margin: 4px 0 0 0;"> Дата c</p>
                </div>
                <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                  <input-date
                      class="form-control"
                      v-model="startDate"
                      style="padding: 0 5px; height: 31px; width: 115px;"
                      @change="changeStartDate()"
                  >
                  </input-date>
                </div>

                <!--             дата по                -->
                <div class="col-0"
                     style="margin-bottom: 0 !important; padding-right: 0; padding-left: 0; vertical-align: middle">
                  <p style="margin: 4px 0 0 0;">по</p>
                </div>
                <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                  <input-date
                      class="form-control"
                      v-model="endDate"
                      style="padding: 0 5px; height: 31px; width: 115px;"
                      @change="changeEndDate()"
                  >
                  </input-date>
                </div>

                <div class="col-0"
                     style="margin-bottom: 0 !important; padding-right: 0; padding-left: 0; vertical-align: middle">
                  <input-checkbox
                      class="mt-2"
                      id="isTimeEditDisabled"
                      v-model="isTimeEditDisabled"
                      @change="setTime"
                      style="margin-top: 4px !important;"
                  ></input-checkbox>
                </div>

                <!--             время с                -->
                <div class="col-0"
                     style="margin-bottom: 0 !important; padding-right: 0; padding-left: 0; vertical-align: middle">
                  <p style="margin: 4px 0 0 0;">Время с</p>
                </div>
                <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                  <input-time
                      v-model="startTime"
                      class="form-control"
                      style="padding-left: 8px; padding-right: 8px; height: 31px; width: 60px"
                      :disabled="!isTimeEditDisabled"
                      @change="changeStartTime"
                  >
                  </input-time>
                </div>

                <!--             время по                -->
                <div class="col-0"
                     style="margin-bottom: 0 !important; padding-right: 0; padding-left: 0; vertical-align: middle">
                  <p style="margin: 4px 0 0 0;">по</p>
                </div>
                <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                  <input-time
                      v-model="endTime"
                      class="form-control"
                      style="padding-left: 8px; padding-right: 8px; height: 31px; width: 60px "
                      :disabled="!isTimeEditDisabled"
                      @change="changeEndTime"
                  >
                  </input-time>
                </div>

                <!--             выбор предприятия     -->
                <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                  <agencies-select
                      id="depot"
                      v-model="kvrDepot"
                      class="form-control"
                      style="padding: 0 5px; height: 31px;"
                      mode="kvr"
                  >
                  </agencies-select>
                </div>

                <!--             выбор маршрута     -->
                <div class="col-0" style="margin-bottom: 0 !important; vertical-align: middle">
                  <routes-select
                      id="route"
                      v-model="kvrRoute"
                      class="form-control"
                      style="padding: 0 5px; height: 31px; width: 200px"
                      mode="kvr"
                  >
                  </routes-select>
                </div>

                <!-- Кнопка Команда   -->
                <div class="col-0" style="margin-bottom: 0 !important;">
                  <dropdown>
                    <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                    :disabled="commandDisabled"
                                    style="padding-top: 0; padding-bottom: 0; height: 31px;">
                      Команда
                    </button-toggler>
                    <dropdown-menu ref="collapse">
                      <dropdown-item @click.prevent="onShowTripCardItems">Отметки на рейсе
                      </dropdown-item>
                      <dropdown-item router-link :to="onTripTypeOnMap">Рейс на карту
                      </dropdown-item>
                      <dropdown-item router-link :to="informationByTsLink">Информация по ТС
                      </dropdown-item>
                    </dropdown-menu>
                  </dropdown>
                </div>

                <!-- Кнопка Зачет   -->
                <div class="col-0" style="margin-bottom: 0 !important;">
                  <dropdown>
                    <button-toggler target-ref="collapse1" class="btn dropdown-toggle btn-success"
                                    :disabled="actionsDisabled"
                                    style="padding-top: 0; padding-bottom: 0; height: 31px;">
                      Зачет
                    </button-toggler>
                    <dropdown-menu ref="collapse1">
                      <dropdown-item @click="setExpCommand(1)">Зачет рег. рейса
                      </dropdown-item>
                      <dropdown-item @click="setExpCommand(2)">Зачет факт. рейса
                      </dropdown-item>
                      <dropdown-divider>
                      </dropdown-divider>
                      <dropdown-item @click="setExpCommand(7)">Зачет план. пробега
                      </dropdown-item>
                      <dropdown-item @click="setExpCommand(8)">Зачет факт. пробега
                      </dropdown-item>
                    </dropdown-menu>
                  </dropdown>
                </div>

                <!-- Кнопка Отмена   -->
                <div class="col-0" style="margin-bottom: 0 !important;">
                  <dropdown>
                    <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-danger"
                                    :disabled="actionsDisabled"
                                    style="padding-top: 0; padding-bottom: 0; height: 31px;">
                      Отмена
                    </button-toggler>
                    <dropdown-menu ref="collapse2">
                      <dropdown-item @click="setExpCommand(3)">Отмена рег. рейса
                      </dropdown-item>
                      <dropdown-item @click="setExpCommand(4)">Отмена факт. рейса
                      </dropdown-item>
                      <dropdown-item @click="setExpCommand(5)">Отмена план. рейса
                      </dropdown-item>
                      <dropdown-divider>
                      </dropdown-divider>
                      <dropdown-item @click="setExpCommand(9)">Отмена факт. пробега
                      </dropdown-item>
                      <dropdown-divider>
                      </dropdown-divider>
                      <dropdown-item @click="setExpCommand(6)">Отмена команды
                      </dropdown-item>
                    </dropdown-menu>
                  </dropdown>
                </div>

                <div class="col"></div>

              </div>
            </card-body>
          </card>
        </align-panel>
        <align-panel align="all">
          <!--  таблица с рейсами -->
          <exp-result-trips-edit-table
              :selectedRow="selectedRow"
              @onRowSelect="select"
              :selectedRows="selectedRows"
              :rows="resultTrips"
          >
          </exp-result-trips-edit-table>
        </align-panel>
      </align-container>
    </align-panel>

  </align-container>

  <analysis-dialog
      v-if="selected_exp_command_id != null"
      :selected_exp_command_id="selected_exp_command_id"
      :selectedRow="this.selectedRows[0]"
      @onSubmit="execCommand"
      @onCancel="onRejectExpCommandExec"
  ></analysis-dialog>

  <trip-card-items-dialog
      v-if="isDialogVisible"
      :rows="tripCardItems"
      @onCancel="onRejectOperation"
      @onRefresh="getTripCard"
  ></trip-card-items-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>
</template>

<script>

import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import PageMixin from "@/pages/share/PageMixin";
import InputDate from "@/components/ui/custom/InputDate";
import ExpResultTripsEditTable from "@/components/kvr/kvr_expert_analysis/ExpResultTripsEditTable";
import InputTime from "@/components/ui/custom/InputTime";
import DropdownDivider from "@/components/ui/bootstrap/dropdown/DropdownDivider";
import AgenciesSelect from "@/components/kvr/agencies/AgenciesSelect";
import RoutesSelect from "@/components/multipass/routes/RoutesSelect";
import {clearArrContains, dateToND, dateToString, stringToDate} from "@/lib";
import InputCheckbox from "@/components/ui/custom/InputCheckbox";
import AnalysisDialog from "@/components/kvr/kvr_expert_analysis/AnalysisDialog";
import TripCardItemsDialog from "@/components/kvr/trip_card_items/TripCardItemsDialog";

export default {
  name: "informationByTsPage",
  mixins: [PageMixin],
  components: {
    TripCardItemsDialog,
    AnalysisDialog,
    InputCheckbox,
    RoutesSelect,
    AgenciesSelect,
    DropdownDivider,
    InputTime,
    ExpResultTripsEditTable,
    InputDate,
    AlignPanel, AlignContainer
  },
  props: [
    // операция show передается через роутер
    'op',
    // идентификатор планового рейса
    'plan_trip_id',
  ],
  data() {
    return {
      // отображать спиннер
      isLoading: false,
      // дата с
      startDate: dateToString(),
      // дата по
      endDate: dateToString(),
      // активно ли редактирование времени
      isTimeEditDisabled: false,
      // время с
      startTime: '',
      // время по
      endTime: '',
      // выбранный перевозчик
      kvrDepot: null,
      // выбранный маршрут
      kvrRoute: null,
      // выбранная строка в таблице рейсов
      selectedRow: null,
      // строки карточки фактического рейса
      tripCardItems: [],
      // строки таблицы
      resultTrips: [],
      // выделенные строки
      selectedRows: [],
      // выбранная команда эксперта
      selected_exp_command_id: null,
    }
  },

  computed: {

    isDialogVisible() {
      return this.op === 'show';
    },

    // условие отключения кнопки "Команда"
    commandDisabled() {
      return this.selectedRows.length != 1;
    },

    // условие отключения кнопки "Команда"
    actionsDisabled() {
      return this.selectedRows.length == 0;
    },

    // переход к информации по ТС
    informationByTsLink() {
      if (this.selectedRows.length == 1) return '/kvr/workTasks/' + this.selectedRows[0].date_index + '/' + this.selectedRows[0].transport_id + '/informationByTs';
      return '';
    },

    // нажата кнопка "Рейс на карту"
    onTripTypeOnMap() {
      if (this.selectedRows.length == 1) return '/kvr/history/' + this.selectedRows[0].date_index + '/' + this.selectedRows[0].transport_id + '/' + this.selectedRows[0].route_id + '/' + this.selectedRows[0].fact_trip_time_index_begin + '/' + this.selectedRows[0].fact_trip_time_index_end;
      return '';
    },

  },

  methods: {

    // отправляем запрос
    async execCommand(selectedItem) {
      try {
        this.isLoading = true;
        // необходимо дополнить элемент выбранными рейсами
        // формируем параметр result_trips: Array<{date_index: number; route_id: number; trip_order_num: number}>
        // date_index - Индекс даты в часовом поясе системы (количество дней с 01.01.2000).
        // route_id - Идентификатор маршрута.
        // trip_order_num - Порядковый номер рейса в итоговых данных (начиная с 1).
        let result_trips = [];

        this.selectedRows.forEach(value => {
          let trip = {
            date_index: value.date_index,
            route_id: value.route_id,
            trip_order_num: value.trip_order_num,
          }
          result_trips.push(trip);
        });
        // добавляем рейсы в элемент для запроса
        selectedItem.result_trips = result_trips;

        clearArrContains(selectedItem.result_trips);

        await this.$store.dispatch('kvr-expert/doFetchExecCommand', selectedItem)

      } finally {
        this.selected_exp_command_id = null;
        await this.onRefreshExpResultTrips();
        this.isLoading = false;
        this.selectedRows = [];
      }
    },

    // закрываем диалог
    onRejectExpCommandExec() {
      this.selected_exp_command_id = null;
    },

    // сетаем выбранную команду эксперта (открывается диалог)
    setExpCommand(exp_command_id) {
      this.selected_exp_command_id = exp_command_id;
    },

    // выбрана строка в таблице Рейсов
    // onRowSelect(item) {
    select(selectedRow, e) {
      if (!this.isLoading) {
        if (e.ctrlKey && !e.shiftKey) {
          // при выборе строки через Ctrl добавляем в начало массива т.к. для отрисовки это не имеет значения, но в случае,
          // если после выбора строки через Ctrl пользователь попытается выбрать строки через Shift, то выделение будет от
          // последней выбраной при помощи Ctrl строки, а Shift всегда выделяет от первой выделенной с Shift строки.
          let index = this.selectedRows.findIndex(value => (value.date_index == selectedRow.date_index &&
              value.route_id == selectedRow.route_id && value.trip_order_num == selectedRow.trip_order_num));
          if (index >= 0) {
            this.selectedRows.splice(index, 1);
          } else {
            this.selectedRows.unshift(selectedRow);
          }
        } else if (!e.ctrlKey && e.shiftKey) {
          // определяем есть ли выбранная строка
          if (this.selectedRows.length != 0) {
            let firstSelectedRow = this.selectedRows[0];
            this.selectedRows = [];
            // определяем индекс от которого считать выделенные строки
            let firstIndex = this.resultTrips.findIndex(value => (value.date_index == firstSelectedRow.date_index &&
                value.route_id == firstSelectedRow.route_id && value.trip_order_num == firstSelectedRow.trip_order_num));
            // определяем индекс выделенного строки до которой будет выделение
            let secondIndex = this.resultTrips.findIndex(value => (value.date_index == selectedRow.date_index &&
                value.route_id == selectedRow.route_id && value.trip_order_num == selectedRow.trip_order_num));
            if (firstIndex >= 0 && secondIndex >= 0 && firstIndex < secondIndex) {
              this.selectedRows.unshift(this.resultTrips[firstIndex]);
              for (let i = firstIndex+1; i <= secondIndex; i++) {
                this.selectedRows.push(this.resultTrips[i]);
              }
            } else if (firstIndex >= 0 && secondIndex >= 0 && firstIndex > secondIndex) {
              this.selectedRows.push(this.resultTrips[secondIndex]);
              for (let i = secondIndex+1; i <= firstIndex; i++) {
                this.selectedRows.unshift(this.resultTrips[i]);
              }
            }

          } else {
            this.selectedRows.push(selectedRow);
          }
        } else if ((!e.ctrlKey && !e.shiftKey)) {
          this.selectedRows = [];
          this.selectedRows.push(selectedRow);
        } else {
          this.selectedRows = [];
        }

      }
    },

    setTime() {
      if (this.isTimeEditDisabled == true) {
        this.startTime = 0;
        this.endTime = 0;
      } else {
        this.startTime = '';
        this.endTime = '';
      }
    },

    async onRefreshExpResultTrips() {
      try {

        this.isLoading = true;
        let payload = {};
        payload['date_index_begin'] = dateToND(stringToDate(this.startDate));
        payload['date_index_end'] = dateToND(stringToDate(this.endDate));
        if (this.isTimeEditDisabled && this.startTime && this.startTime != '') {
          payload['time_begin'] = this.startTime;
        }
        if (this.isTimeEditDisabled && this.endTime && this.endTime != '') {
          payload['time_end'] = this.endTime;
        }
        if (this.kvrDepot) {
          payload['fact_org_id'] = this.kvrDepot;
        }
        if (this.kvrRoute) {
          payload['route_id'] = this.kvrRoute;
        }
        this.resultTrips = await this.$store.dispatch('kvr-expert/doFetchExpResultTrips', payload);

      } finally {
        this.selectedRows = [];
        this.isLoading = false;
      }

    },

    // Изменение даты начала
    changeStartDate() {
      this.endDate = this.startDate;
    },

    // Изменение даты конца
    changeEndDate() {
      if (dateToND(stringToDate(this.startDate)) > dateToND(stringToDate(this.endDate))) {
        this.startDate = this.endDate;
      }
    },

    // Изменение времени начала
    changeStartTime() {
      this.endTime = this.startTime;
    },

    // Изменение времени конца
    changeEndTime() {
      if (this.startTime > this.endTime) {
        this.startTime = this.endTime;
      }
    },

    clearSelection() {
      this.selectedRow = null;
    },

    // выбрана строка в таблице Рейсов
    onRowGraphSelect(item) {
      this.selectedGraphRow = item;
    },

    // нажата конпка "Просмотр"
    async onShowTripCardItems() {
      await this.getTripCard();
      let plan_trip_id = (this.selectedRows[0].plan_trip_id != null || this.selectedRows[0].plan_trip_id != undefined) ? this.selectedRows[0].plan_trip_id : "";
      await this.$router.replace('/kvr/kvrExpertAnalysis/show/' + plan_trip_id);
    },

    onRejectOperation() {
      this.tripCardItems = [];
      this.$router.replace('/kvr/kvrExpertAnalysis');
    },

    // получение карточки фактического рейса
    async getTripCard() {
      this.isLoading = true;
      try {
        let plan_trip_id = (this.selectedRows[0].plan_trip_id != null || this.selectedRows[0].plan_trip_id != undefined) ? this.selectedRows[0].plan_trip_id : "";
        let tripType = await this.$store.dispatch('kvr/doFetchTripCardByPlanTripId', {
          plan_trip_id: plan_trip_id,
          date_index: this.selectedRows[0].date_index
        });
        if (tripType) {
          this.tripCardItems = tripType;
        }
      } finally {
        this.isLoading = false;
      }
    },

  },

  async created() {
    // загружаем перевозчиков
    await this.$store.dispatch('kvr/doFetchAgencies');
    // загружаем маршруты
    await this.$store.dispatch('kvr/doFetchRoutes');
    // загружаем транспортные предприятия
    await this.$store.dispatch('kvr/doFetchDepots');
    // загружаем перевозчики
    await this.$store.dispatch('kvr/doFetchAgencies');
    // загружаем виды транспорта
    await this.$store.dispatch('ref/doFetchTransportTypes');
    // загружаем экспертов
    await this.$store.dispatch('kvr-expert/doFetchExpCommands');
    // загружаем экспертов
    await this.$store.dispatch('kvr-expert/doFetchExpCommandReasons');
    // загружаем класс ТС
    await this.$store.dispatch('ref/doFetchTransportClasses');
    // загружаем причины незачета рейса
    await this.$store.dispatch('kvr/doFetchMarkReasons');
    // // получаем данные по итоговым графикам
    // await this.onRefresfResultGraphs();
    // загрузка маршрутов
    await this.$store.dispatch('kvr/doFetchRoutes');

    await this.$store.dispatch('kvr-expert/doFetchExperts');
  }

}
</script>

<style scoped>

</style>
