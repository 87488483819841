import ContractsKvrStore from "@/store/kvr/ContractsKvrStore";
import ContractRouteLinksKvrStore from "@/store/kvr/ContractRouteLinksKvrStore";
import ContractDocsKvrStore from "@/store/kvr/ContractDocsKvrStore";
import ContractDocFilesKvrStore from "@/store/kvr/ContractDocFilesKvrStore";
import MasterOrdersKvrStore from "@/store/kvr/MasterOrdersKvrStore";
import RoutesKvrStore from "@/store/kvr/RoutesKvrStore";
import VehicleWorkTasksKvrStore from "@/store/kvr/VehicleWorkTasksKvrStore";
import WorkTaskCPNavKvrStore from "@/store/kvr/WorkTaskCPNavKvrStore";
import WorkTaskFactTripsKvrStore from "@/store/kvr/WorkTaskFactTripsKvrStore";
import ReportsKvrStore from "@/store/kvr/ReportsKvrStore";
import AgenciesKvrStore from "@/store/kvr/AgenciesKvrStore";
import RoutesExtKvrStore from "@/store/kvr/RoutesExtKvrStore";
import DepotsKvrStore from "@/store/kvr/DepotsKvrStore";
import TripCardItemsKvrStore from "@/store/kvr/TripCardsKvrStore";
import ResultGraphsKvrStore from "@/store/kvr/ResultGraphsKvrStore";
import ResultTripsKvrStore from "@/store/kvr/ResultTripsKvrStore";
import CurWorkTransportKvrStore from "@/store/kvr/CurWorkTransportKvrStore";
import CurWorkNavKvrStore from "@/store/kvr/CurWorkNavKvrStore";
import RouteTripTypeListKvrStore from "@/store/kvr/RouteTripTypeListKvrStore";
import RouteStopZoneListKvrStore from "@/store/kvr/RouteStopZoneListKvrStore";
import WorkTransportOnDayKvrStore from "@/store/kvr/WorkTransportOnDayKvrStore";
import ManualOrdersKvrStore from "@/store/kvr/ManualOrdersKvrStore";
import OrderAgenciesKvrStore from "@/store/kvr/OrderAgenciesKvrStore";
import OrderDepotsKvrStore from "@/store/kvr/OrderDepotsKvrStore";
import OrderDriversKvrStore from "@/store/kvr/OrderDriversKvrStore";
import OrderRoutesKvrStore from "@/store/kvr/OrderRoutesKvrStore";
import OrderVehiclesKvrStore from "@/store/kvr/OrderVehiclesKvrStore";
import TempDataKvrStore from "@/store/kvr/temp_data/TempDataKvrStore";
import DayStatesKvrStore from "@/store/kvr/DayStatesKvrStore";
import HistKvrStore from "@/store/kvr/HistKvrStore";
import MarkReasonsKvrStore from "@/store/kvr/MarkReasonsKvrStore";

//  ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ KVR

export default {
    namespaced: true,
    modules:{
        TempDataKvrStore,

        ContractsKvrStore,
        ContractRouteLinksKvrStore,
        ContractDocsKvrStore,
        ContractDocFilesKvrStore,
        MasterOrdersKvrStore,
        RoutesExtKvrStore,
        VehicleWorkTasksKvrStore,
        WorkTaskCPNavKvrStore,
        WorkTaskFactTripsKvrStore,
        ReportsKvrStore,
        AgenciesKvrStore,
        RoutesKvrStore,
        DepotsKvrStore,
        TripCardItemsKvrStore,
        ResultGraphsKvrStore,
        ResultTripsKvrStore,
        CurWorkTransportKvrStore,
        CurWorkNavKvrStore,
        RouteTripTypeListKvrStore,
        RouteStopZoneListKvrStore,
        WorkTransportOnDayKvrStore,
        ManualOrdersKvrStore,
        OrderAgenciesKvrStore,
        OrderDepotsKvrStore,
        OrderDriversKvrStore,
        OrderRoutesKvrStore,
        OrderVehiclesKvrStore,
        DayStatesKvrStore,
        HistKvrStore,
        MarkReasonsKvrStore,

    }

}