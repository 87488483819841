<template>
  <button
      type="button"
      class="btn"
      @click="execTargetMethod()"
  ><slot></slot></button>
</template>

<script>
import TargetMixin from "@/components/ui/bootstrap/mixins/TargetMixin";

export default {
  mixins: [TargetMixin],
}
</script>
