<template>
  <edit-table-panel :columnList="columnList" :rowList="rows" name="tbl-admin-system-events" :rowsPerPage="1500"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getDateTimeFormat, numSortFn, quickSort,} from "@/lib";
export default {
  components: {EditTablePanel},
  props: ['isLoading','rowList', 'selRow', 'filterText'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__time_insert",
          caption: "Время",
          sortType: 'string',
          isSortable: false,
        },
        {
          field: "event_result",
          caption: "Статус",
          sortType: 'string',
          isSortable: false,
          isFilterable: true,
        },
        {
          field: "event_result_num",
          caption: "Рез-т",
          sortType: 'string',
          isSortable: false,
        },
        {
          field: "title",
          caption: "Содержание",
          sortType: 'number',
          isSortable: false,
          isFilterable: true,
        },
        {
          field: "system_event_detail_id",
          caption: "Код",
          sortType: 'string',
          isSortable: true,
          show: false,
        },
      ],
    };
  },

  computed: {
    rows() {
      let detailsArr = [];
      let tempDate = '';
      // // сортируем массив
      // так как время приходит одинаковое, сортируем по id т.к. он всегда приходит в порядке возрастания
      quickSort(this.rowList, (leftItem, rightItem) => {
        return 1 * numSortFn(leftItem.system_event_detail_id, rightItem.system_event_detail_id);
      });

      this.rowList.forEach(row => {
        if(tempDate != row.time_insert.substring(0,10)){
          tempDate = row.time_insert.substring(0,10);
          detailsArr.push({
            __time_insert: tempDate,
            event_result: '',
            event_result_num: '',
            title: '',
            system_event_detail_id: '',
            __cellStyle : {
              paddingLeft: '4px',
              paddingRight: '4px',
              paddingTop: '4px',
              paddingBottom: '4px',
              fontSize: 'small',
            }
          });
        }

        row.__id = row.system_event_detail_id;
        row.__time_insert = getDateTimeFormat(row.time_insert).substring(11);
        row.__cellStyle = {
          paddingLeft: '4px',
          paddingRight: '4px',
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: 'small',
          backgroundColor: (this.selRow && (row.system_event_detail_id == this.selRow.system_event_detail_id)) ? '#007bff' : this.getBackgroundColor(row)
        };

        if (this.filterText != '') {
          if(row.event_result.toLowerCase().includes(this.filterText.toLowerCase())  || row.title.toLowerCase().includes(this.filterText.toLowerCase())) {
            detailsArr.push(row);
          }
        } else {
          detailsArr.push(row);
        }
      })

      return detailsArr;
    }
  },

  methods: {

    getBackgroundColor(row) {

      if(row.event_result == "DEBUG") {
        return 'white';
      } else if (row.event_result == "OK") {
        return this.$store.getters['settings/getProductionReisColor'];
      } else if (row.event_result == "WARN") {
        return this.$store.getters['settings/getShortEventColor'];
      } else if (row.event_result == "ALARM" || row.event_result == "ERROR") {
        return this.$store.getters['settings/getTechnicalReisColor'];
      } else if (row.event_result == "INFO") {
        return this.$store.getters['settings/getGrayZones'];
      } else if (row.event_result == "EXEC") {
        return this.$store.getters['settings/getLongEventColor'];
      } else {
        return 'white';
      }

    }

  }
};
</script>
