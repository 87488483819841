import UsersAuthStore from "@/store/auth/UsersAuthStore";
import UserGroupsAuthStore from "@/store/auth/UserGroupsAuthStore";
import UserRoleLinksAuthStore from "@/store/auth/UserRoleLinksAuthStore";
import UserGroupLinksAuthStore from "@/store/auth/UserGroupLinksAuthStore";
import RoleMethodLinksAuthStore from "@/store/auth/RoleMethodLinksAuthStore";
import ProfileAuthStore from "@/store/auth/ProfileAuthStore";
import RolesAuthStore from "@/store/auth/RolesAuthStore";
import ModulesAuthStore from "@/store/auth/AllowedModulesAuthStore";
import ClientSettingsAuthStore from "@/store/auth/ClientSettingsAuthStore";

// ЦЕНТРАЛЬНОЕ ХРАНИЛИЩЕ AUTH

export default {
    modules: {
        UsersAuthStore,
        RolesAuthStore,
        UserGroupsAuthStore,
        UserRoleLinksAuthStore,
        UserGroupLinksAuthStore,
        RoleMethodLinksAuthStore,
        ProfileAuthStore,
        ModulesAuthStore,
        ClientSettingsAuthStore,
    },
    namespaced: true,
}