<template>
  <input-select ref="select" v-model="value" caption="Выберите маршрут..." :itemList="items"></input-select>
</template>

<script>
import {kvrSortFn, quickSort} from "@/lib";

export default {
  props: [
    'model-value',
    // из какого справочника брать данные (по умолчанию из ref)
    'mode'
  ],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      let routes = [];
      if(this.mode && this.mode == 'kvr') {
        routes = this.$store.getters['kvr/getRoutes'];
      } else {
        routes = this.$store.getters['multipass/getRoutes'];
      }

     // сортируем массив
      quickSort(routes, (leftItem, rightItem) => {
        return 1 * kvrSortFn(leftItem, rightItem);
      })
      // придаем маршрутам необходимую форму
      return routes.map(item => {
        return {
          id: item.route_id,
          title: item.route_num + ' - ' + item.route_title
        }
      });
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
