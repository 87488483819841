<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable ">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Выбор группы ТС</modal-title>
          <button-close class="text-white" @click="$emit('onRejectOperation')"></button-close>
        </modal-header>
        <modal-body>

          <div class="text-right">
            <!--          Поставить галочки всем ТС на экране-->
            <button style="padding: 0px; border-width: 0px; background-color: white; margin-right: 5px "
                    @click="setAllSelected">
              <i :class='selectedItems.length === itemList.length ? "fas fa-check-square fa-2xl fa-2x" : "far fa-check-square fa-2xl fa-2x"'
                 title="Выделить всё"
                 :style='selectedItems.length === itemList.length ? "color: #007BFF" : "color: #000000"'
              ></i>
            </button>

            <!--            Снять галочки со всех ТС -->
            <button style="padding: 0px; border-width: 0px; background-color: white; margin-right: 5px "
                    @click="setAllClear">
              <i :class='selectedItems.length === 0 ? "fas fa-square fa-2x" : "far fa-square fa-2x"'
                 title="Снять все"
                 :style='selectedItems.length === 0 ? "color: #007BFF" : "color: #000000"'
              ></i>
            </button>

            <!--            Снять галочки с тех ТС, что были выделенны, проставить на те, где были сняты -->
            <button style="padding: 0px; border-width: 0px; background-color: white; "
                    @click="setAllSwitch">
              <i :class='(selectedItems.length !== 0 && selectedItems.length !== itemList.length) ?  "fab fa-flipboard fa-2x" : "fab fa-flipboard fa-2x"'
                 :style='(selectedItems.length !== 0 && selectedItems.length !== itemList.length) ? "color: #007BFF" : "color: #000000"'
              ></i>
            </button>

          </div>

          <table class="table table-sm table-striped">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Гар. №</th>
              <th scope="col">Гос. №</th>
              <th scope="col">Доп. инф.</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in itemList" :key="item.transport_id">
              <td
                  :class="'text-center'"
              >
                <input-checkbox-simple
                    v-model="item.__isSelected"
                ></input-checkbox-simple>
              </td>
              <td>{{ item.garage_num }}</td>
              <td>{{ item.state_num }}</td>
              <td></td>
            </tr>
            </tbody>
          </table>

        </modal-body>
        <modal-footer>
          <button @click="$emit('onSelectGroup', selectedItems)" class="btn btn-primary">ОК</button>
          <button @click="$emit('onRejectOperation')" class="btn btn-primary">Закрыть</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

export default {
  props: ['vehicles'],

  emits: [
    'onSelectGroup',
    'onRejectOperation'
  ],

  computed: {
    // выбранные элементы
    selectedItems() {
      return this.itemList.filter(v => !!v.__isSelected)
    }
  },

  data() {
    return {
      // список для отображения
      itemList: [],
    }
  },

  methods: {
    // установить все галочки
    setAllSelected() {
      this.itemList.map(value => {
        value.__isSelected = true;
      })
    },

    // снять все галочки
    setAllClear() {
      this.itemList.map(value => {
        value.__isSelected = false;
      })
    },

    // переключить галочки
    setAllSwitch() {
      this.itemList.map(value => {
        value.__isSelected = !value.__isSelected;
      })
    },
  },

  created() {
    this.itemList = [...this.vehicles]
  }
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>