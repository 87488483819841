<template>
    <div class="card text-center mb-1" :class="{active: isActive}" @click="onAutoHide">
        <div class="card-body p-1">
            <router-link :to="to">
                <i v-if="iconClass" :class="iconClass"></i>
                <p class="m-0 text-break">
                    <slot></slot>
                </p>
            </router-link>                
        </div>
    </div>    
</template>

<script>
export default {
    props: ['iconClass', 'to'],
    computed: {
        isActive() {
            const p1 = this.to.split('/');
            const p2 = this.$route.path.split('/');
            return p1[0] === p2[0] && p1[1] === p2[1] && p1[2] === p2[2]
        }
    },
    methods: {
        onAutoHide() {
            this.$store.commit('hideLeftMenu');
        }        
    }
}
</script>

<style scoped>
    a {
        color: black;
        display: block
    }

    a:hover {
        text-decoration: none;
    }

   .card:hover {
        border-color: #007bff;
    }

    .card.active {
        background-color: #007bff;
    }

    .card.active a {
        text-decoration: none;
        color: #fff;
    }
</style>