import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ГРУПП МАРШРУТОВ

export default {
    state() {
        return {
            // список объектов
            routeGroups: [],
            // время обновления кэша new Date().getTime()
            routeGroupsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            routeGroupsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRouteGroups(state) {
            return state.routeGroups;
        },
    },
    mutations: {
        // установить новый список объектов
        setRouteGroups(state, payload) {
            state.routeGroups = payload;
            state.routeGroupsCache = new Date().getTime()
        },
        // добавить объект
        addRouteGroup(state, payload) {
            state.routeGroups.push(payload)
        },
        // изменить объект
        editRouteGroup(state, payload) {
            const index = state.routeGroups.findIndex(group => group.route_group_id == payload.route_group_id);
            if (index >= 0) {
                state.routeGroups[index] = payload;
            }
        },
        // удалить объект
        delRouteGroup(state, payload) {
            const index = state.routeGroups.findIndex(group => group.route_group_id == payload.route_group_id);
            state.routeGroups.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchRouteGroups: (context, payload) => {
            return DoGet(
                '/multipass/routeGroups',
                'multipass/setRouteGroups',
                [],
                'Запрос групп маршрутов',
                false,
                payload && payload.force,
                context.state.routeGroupsCache,
                context.state.routeGroupsCacheTimeout
            )
        },

        // очищает кэш
        doClearRouteGroups: (context, payload) => {

            return DoClearCache(
                'multipass/setRouteGroups',
                [],
                payload && payload.force,
                context.state.routeGroupsCache,
                context.state.routeGroupsCacheTimeout
            )
        },

        // добавление объекта
        doAddRouteGroup: (context, payload) => {
            return DoPost(
                '/multipass/routeGroups',
                payload,
                'multipass/addRouteGroup',
                'Добавление группы маршрутов'
            )
        },

        // изменение объекта
        doEditRouteGroup: (context, payload) => {
            return DoPut(
                '/multipass/routeGroups/'+payload.route_group_id,
                payload,
                'multipass/editRouteGroup',
                'Изменение группы маршрутов'
            )
        },

        // удаление объекта
        doDelRouteGroup: (context, payload) => {
            return DoDelete(
                '/multipass/routeGroups/'+payload.route_group_id,
                'multipass/delRouteGroup',
                'Удаление группы маршрутов'
            )
        }
    }
}