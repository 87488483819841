import {DoPost} from "@/store/Actions";

// ХРАНИЛИЩЕ АГРЕГИРОВАННЫХ ДАННЫХ ПО ОСТАНОВОЧНОМУ ПАССАЖИРОПОТОКУ

export default {
    actions: {
        // запрос списка объектов
        doFetchFactTripCardExtsAggregated: (context, payload) => {
            return DoPost(
                '/asmpp/factTripCardExts/getAggregated',
                payload,
                null,
                'Запрос агрегированных данных по остановочному пассажиропотоку',
                false,
                true
            )
        },
    }
}
