<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span><router-link to="/asmpp/asopRoutes">Список маршрутов</router-link></span>
        <span> / </span>
        <span>Показатели пассажиропотока по оплаченным поездкам {{routePageHeader}} за период дат {{ getDateForHeader }} с группировкой по датам</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >
          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <pay-trans-by-graph-day-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
              :payTransByGraphDay="payTransByGraphDay"
          ></pay-trans-by-graph-day-edit-table>
        </align-panel>
        <align-panel align="bottom">
          <!-- Форма с кнопками под правой таблицей-->
          <card>
            <card-body class="p-1">
              <table class="table table-sm" style="margin-bottom:0">
                <thead class="thead-dark">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Кол. рейсов</th>
                  <th scope="col">Сумм. кол. оплат</th>
                  <th scope="col">Ср. кол. оплат за рейс</th>
                  <th scope="col">КПД-Пасс/км</th>
                  <th scope="col">КПД-Пасс/час</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in getBottomPanelItems" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.fact_trip_count }}</td>
                  <td>{{ item.pay_tran_count }}</td>
                  <td>{{ item.pay_tran_count_avg_by_trip }}</td>
                  <td>{{ item.pay_tran_by_km }}</td>
                  <td>{{ item.pay_tran_by_hour }}</td>
                </tr>
                </tbody>
              </table>
            </card-body>
          </card>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>


import PageMixin from "@/pages/share/PageMixin";
import {ndToDateString} from "@/lib";
import PayTransByGraphDayEditTable from "@/components/asmpp/pay_trans_by_graph_day/PayTransByGraphDayEditTable";

export default {
  mixins: [PageMixin],

  components: {
    PayTransByGraphDayEditTable,
  },

  props: [
    // идентификатор редактируемого объекта
    "route_id",
    // выбранная дата начала
    "date_index_begin",
    // выбранная дата начала
    "date_index_end"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // выбранное предприятие
      route: {},
      // строки таблицы
      payTransByGraphDay: [],
    }
  },

  computed: {

    // расчет параметров для таблицы "Итого" снизу
    getBottomPanelItems(){
      let items = [];
      let fact_trip_count = 0;
      let pay_tran_count = 0;
      let pay_tran_count_avg_by_trip = 0;
      let pay_tran_by_km = 0;
      let pay_tran_by_hour = 0;

      for(let i = 0; i < this.payTransByGraphDay.length; i++) {
        fact_trip_count = fact_trip_count + this.payTransByGraphDay[i].fact_trip_count;
        pay_tran_count = pay_tran_count +  this.payTransByGraphDay[i].pay_tran_count;
        pay_tran_count_avg_by_trip = pay_tran_count_avg_by_trip +  this.payTransByGraphDay[i].pay_tran_count_avg_by_trip;
        pay_tran_by_km = pay_tran_by_km +  this.payTransByGraphDay[i].pay_tran_by_km;
        pay_tran_by_hour = pay_tran_by_hour +  this.payTransByGraphDay[i].pay_tran_by_hour;
      }
      items.push({
        name: "Суммарно",
        fact_trip_count: fact_trip_count,
        pay_tran_count: pay_tran_count,
        pay_tran_count_avg_by_trip: Number(pay_tran_count_avg_by_trip).toFixed(2),
        pay_tran_by_km: Number(pay_tran_by_km).toFixed(2),
        pay_tran_by_hour: Number(pay_tran_by_hour).toFixed(2),
      });

      return items;
    },


    // получение даты для заголовка
    getDateForHeader() {
      return 'c ' + ndToDateString(this.date_index_begin) + ' по ' + ndToDateString(this.date_index_end);
    },

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },
  },
  methods: {

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = "fetch";
      try {

        // получаем выбранное предприятие
        let route = await this.$store.dispatch('multipass/doFetchRoute', { route_id: this.route_id})
        if (route)
          this.route = route;

        // получаем строки таблицы
        let payTransByGraphDay = await this.$store.dispatch('asmpp/doFetchPayTransByGraphDay', {
          route_id: Number(this.route_id),
          date_index_begin: Number(this.date_index_begin),
          date_index_end: Number(this.date_index_end),
        });
        if(payTransByGraphDay)
          this.payTransByGraphDay = payTransByGraphDay;

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
