<template>
  <!--  ТАБЛИЦА ГРУПП С ПОМЕТКОЙ ДЛЯ КАЖДОГО ПОЛЬЗОВАТЕЛЯ-->
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-user-group-links"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
export default {
  components: {EditTablePanel},
  props: ['id', 'isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__checked",
          caption: "Вкл",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.__checked) {
              return '<i class="fas fa-check">'
            }
            return ''
          }
        },
        {
          field: "user_group_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "user_group_name",
          caption: "Группа",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return [];
      }

      const groups = this.$store.getters["auth/getUserGroups"];
      const links = this.$store.getters["auth/getUserGroupLinks"];
      return groups.map(group => {

        group.__id = group.user_group_id;
        group.__checked = links.some(link => link.uid == this.id && link.user_group_id == group.user_group_id)

        return group;
      });
    },
  },
};
</script>
