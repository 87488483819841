<template>
  <div class="container position-relative h-100">
    <h2 class="text-center">Модуль "Контроль выполнения транспортной работы"</h2>
    <p class="text-center">Учет выполнения транспортной работы в соотвествии с контрактом.</p>
  </div>
</template>

<script>
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixin: [PageMixin],
}

</script>
