<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-asmpp-fact-trip-card-exts"> </edit-table-panel>
</template>

<script>

import {getSignTitle, secondsToDateAndTime} from "@/lib";
import {getStop} from "@/store/MultipassHelpers";

export default {
  props: ['isLoading','rowArr', 'tripTypes'],
  emits: ['setLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "tripTypeSign",
          caption: "Тип рейса",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center',
          show: false
        },
        {
          field: "order_num",
          caption: "№",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center'
        },
        {
          field: "__stop",
          caption: "Остановка",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center'
        },
        {
          field: "__time_index",
          caption: "Время",
          sortType: 'string',
          isFilterable: true,
          dataAlign: 'center',
          headerAlign: 'center'
        },
        {
          field: "passenger_in",
          caption: "Кол вошедших",
          sortType: 'string',
          isFilterable: true
        },
        {
          field: "passenger_out",
          caption: "Кол вышедших",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "passenger_inside",
          caption: "Наполнение",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "is_unstopped",
          caption: "Проезд мимо",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_unstopped) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      let rows = this.rowArr;

      rows.map(row => {
        row.__id = row.fact_stop_id;
        row.__stop = getStop(row.stop_id).long_name;
        row.tripTypeSign = getSignTitle(this.tripTypes.find(value => row.trip_type_id == value.trip_type_id));
        row.__time_index = secondsToDateAndTime(row.time_index)
        return row;
      });
      return rows;
    },
  }
};
</script>
