<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/ref">Справочники</router-link></span>
        <span> / </span>
        <span>Транспортные средства</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!-- Кнопка Команда-->
            <template #leftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                :disabled="commandDisabled">
                  Команда
                </button-toggler>
                <dropdown-menu ref="collapse">
                  <dropdown-item @click="onDiscardTransport" v-if="discardTransport">Списать ТС
                  </dropdown-item>
                  <dropdown-item @click="onRestoreTransport" v-if="restoreTransport">Восстановить ТС
                  </dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                  Связь
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="transportGroupLink">Группы</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item router-link :to="transportStatesLink">Состояния</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <!-- Фильтр групп-->
            <template #leftCustomButton3>
              <transport-groups-select class="form-control" v-model="transportGroupId"></transport-groups-select>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <transport-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :transportGroupId="transportGroupId"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></transport-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <transport-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </transport-dialog>

  <!-- Подтверждение удаления -->
  <transport-del-dialog
      :id="id"
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  ></transport-del-dialog>

  <!-- Подтверждение списания -->
  <transport-discard-dialog
      :id="id"
      v-if="isDiscardConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDiscard"
  ></transport-discard-dialog>

  <!-- Подтверждение восстановления -->
  <confirm-message-box
      v-if="isRestoreConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmRestore"
  >Вы уверены, что хотите восстановить транспортное средство с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import TransportEditTable from "@/components/ref/transport/TransportEditTable";
import TransportDialog from "@/components/ref/transport/TransportDialog";
import TransportDelDialog from "@/components/ref/transport/TransportDelDialog";
import TransportGroupsSelect from "@/components/ref/transport_groups/TransportGroupsSelect";
import PageMixin from "@/pages/share/PageMixin";
import TransportDiscardDialog from "@/components/ref/transport/TransportDiscardDialog";

export default {
  mixins: [PageMixin],

  components: {
    TransportDiscardDialog,
    TransportEditTable,
    TransportDialog,
    TransportDelDialog,
    TransportGroupsSelect
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // группа ТС
      transportGroupId: null,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // условие отображения подтверждения списания
    isDiscardConfirmVisible() {
      return this.op === 'discard' && !!this.selectedRow;
    },

    // условие отображения подтверждения восстановления
    isRestoreConfirmVisible() {
      return this.op === 'restore' && !!this.selectedRow;
    },

    // условие отключения кнопки - Команды
    commandDisabled() {
      return !this.setStatusExists;
    },

    // условие отображения команды Установить статус
    setStatusExists() {
      return this.selectedRow;
    },

    // условие отображения меню списать транспортое средство
    discardTransport() {
      return this.selectedRow && !this.selectedRow.date_index_end
    },

    // условие отображения меню восстановить транспортное средство
    restoreTransport() {
      return this.selectedRow && !!this.selectedRow.date_index_end
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // ссылка для перехода к группам ТС
    transportGroupLink() {
      if (this.selectedRow) return '/ref/transport/' + this.selectedRow.transport_id + '/transportGroupLinks'
      else return '';
    },

    // ссылка для перехода к состояниям ТС
    transportStatesLink() {
      if (this.selectedRow) return '/ref/transport/' + this.selectedRow.transport_id + '/transportStates'
      else return '';
    },

    // данные, которые отображаются в диалоговом окне
    dialogRow() {
      return this.op === 'add' ? {} : this.selectedRow;
    },
  },

  methods: {

    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/ref/transport/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/ref/transport/edit/" + this.selectedRow.transport_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/ref/transport/del/" + this.selectedRow.transport_id);
    },

    // нажата конпка "Просмотр"
    onTableShow() {
      this.$router.replace("/ref/transport/show/" + this.selectedRow.transport_id);
    },

    // нажата кнопка Списать ТС
    onDiscardTransport() {
      this.$router.replace("/ref/transport/discard/" + this.selectedRow.transport_id);
    },

    // нажата конпка Восстановить ТС
    onRestoreTransport() {
      this.$router.replace("/ref/transport/restore/" + this.selectedRow.transport_id);
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/ref/transport");
    },

    // подтверждено списание
    onConfirmDiscard(item) {
      // списываем элемент
      this.discardItem(item);
      // возвращаемся на страницу
      this.$router.replace("/ref/transport");
    },

    // подтверждено восстановление
    onConfirmRestore() {
      // восстанавлмиваем элемент
      this.restoreItem();
      // возвращаемся на страницу
      this.$router.replace("/ref/transport");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/ref/transport");
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/ref/transport");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchTransportTypes')
        await this.$store.dispatch('ref/doFetchTransportClasses')
        await this.$store.dispatch('ref/doFetchEngineFuelTypes')
        await this.$store.dispatch('ref/doFetchEmissionStandards')
        await this.$store.dispatch('ref/doFetchTransportGroups')
        await this.$store.dispatch('ref/doFetchTransportGroupLinks')
        await this.$store.dispatch('ref/doFetchModels')
        await this.$store.dispatch('ref/doFetchDepots')
        await this.$store.dispatch('ref/doFetchTransport', {force})

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('ref/doAddTransport', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('ref/doEditTransport', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('ref/doDelTransport', this.selectedRow);
        this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // Списываем объект
    async discardItem(item) {
      this.isLoading = true;
      try {

        await this.$store.dispatch('ref/doDiscardTransport', item);
        this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // Восстанавливаем объект
    async restoreItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('ref/doRestoreTransport', this.selectedRow);
        this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

  },

// вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  }
  ,
}
;
</script>

