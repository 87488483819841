<template>
  <navbar-menu class="fixed-top navbar-dark bg-dark navbar-expand">
    <button class="left-menu-toggler" type="button" @click="onLeftMenuTogglerClick">
      <span class="navbar-toggler-icon"></span>
    </button>
    <navbar-brand router-link to="/">Навитранс.Клиент{{systemСaption}}</navbar-brand>
    <navbar-collapse>
      <navbar-nav class="mr-auto">
        <nav-item v-if="isRef">
          <nav-link router-link to="/ref" auto-active="route">Справочники</nav-link>
        </nav-item>
        <nav-item v-if="isMultipass">
          <nav-link router-link to="/multipass" auto-active="route">Маршруты</nav-link>
        </nav-item>
        <nav-item v-if="isRmt">
          <nav-link router-link to="/rmt" auto-active="route">Расписания</nav-link>
        </nav-item>
        <nav-item v-if="isRmtGen">
          <nav-link router-link to="/rmtgen" auto-active="route">Генератор</nav-link>
        </nav-item>
        <nav-item v-if="isNav">
          <nav-link router-link to="/nav" auto-active="route">Телематика</nav-link>
        </nav-item>
        <nav-item v-if="isTrn">
          <nav-link router-link to="/trn" auto-active="route">Диспетчерская</nav-link>
        </nav-item>
        <nav-item v-if="isKvr">
          <nav-link router-link to="/kvr" auto-active="route">КВР</nav-link>
        </nav-item>
        <nav-item v-if="isAsmpp">
          <nav-link router-link to="/asmpp" auto-active="route">Пассажиропоток</nav-link>
        </nav-item>
        <nav-item v-if="isAsip">
          <nav-link router-link to="/asip" auto-active="route">Информирование</nav-link>
        </nav-item>
        <nav-item v-if="isPriority">
          <nav-link router-link to="/priority" auto-active="route">Приоритет</nav-link>
        </nav-item>
        <nav-item v-if="isAdmin">
          <nav-link router-link to="/admin" auto-active="route">Администрирование</nav-link>
        </nav-item>
      </navbar-nav>
      <navbar-nav v-if="isAuth">
        <nav-dropdown-item>
          <nav-toggler-link target-ref="userMenu">
            <i class="fas fa-user mr-2"></i>{{ login }}
          </nav-toggler-link>
          <dropdown-menu ref="userMenu" class="dropdown-menu-right">
            <dropdown-item router-link to="/profile">Профиль</dropdown-item>
            <dropdown-divider></dropdown-divider>
            <dropdown-item @click="onReportsShow">Отчеты</dropdown-item>
            <dropdown-divider></dropdown-divider>
            <dropdown-item router-link to="/about">О программе</dropdown-item>
            <dropdown-divider></dropdown-divider>
            <dropdown-item @click="onLogout">Выход</dropdown-item>
          </dropdown-menu>
        </nav-dropdown-item>
      </navbar-nav>
    </navbar-collapse>
  </navbar-menu>
</template>

<script>


import {getAllowedModules} from "@/store/AuthHelpers";

export default {
  props: ['hideMenu'],
  computed: {
    isRef() {
      return this.hideMenu == true ? false : (this.isAuth == true ? (getAllowedModules(101) ? true : false) : false);
    },
    isMultipass() {
      return this.hideMenu == true ? false : (this.isAuth == true ? (getAllowedModules(102) ? true : false) : false);
    },
    isTrn() {
      return this.hideMenu == true ? false : (this.isAuth == true ? (getAllowedModules(105) ? true : false) : false);
    },
    isNav() {
      return this.hideMenu == true ? false : (this.isAuth == true ? (getAllowedModules(104) ? true : false) : false);
    },
    isRmt() {
      return this.hideMenu == true ? false : (this.isAuth == true ? (getAllowedModules(103) ? true : false) : false);
    },
    isRmtGen() {
      return this.hideMenu == true ? false : (this.isAuth == true ? (getAllowedModules(201) ? true : false) : false);
    },
    isKvr() {
      return this.hideMenu == true ? false : (this.isAuth == true ? (getAllowedModules(106) ? true : false) : false);
    },
    isAsmpp() {
      return this.hideMenu == true ? false : (this.isAuth == true ? (getAllowedModules(107) ? true : false) : false);
    },
    isAsip() {
      return this.hideMenu == true ? false : (this.isAuth == true ? (getAllowedModules(108) ? true : false) : false);
    },
    isPriority() {
      return this.hideMenu == true ? false : (this.isAuth == true ? (getAllowedModules(109) ? true : false) : false);
    },
    isAdmin() {
      return this.hideMenu == true ? false : (this.isAuth == true ? (getAllowedModules(4) ? true : false) : false);
    },
    login() {
      return this.$store.getters.getLogin;
    },
    isAuth() {
      return this.$store.getters.getIsAuth;
    },

    // Название системы
    systemСaption() {
      if (this.$store.getters["getSystemСaption"] && this.$store.getters["getSystemСaption"] != ''){
        return '.' + this.$store.getters["getSystemСaption"];
      }else {
        return '';
      }
    },
  },

  methods: {
    onLeftMenuTogglerClick() {
      this.$store.commit('toggleLeftMenu');
    },
    onReportsShow() {
      this.$store.commit('toggleReports');
    },
    onLogout() {
      this.$store.dispatch('doLogout');
    }
  },
}
</script>

<style scoped>
.left-menu-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
</style>