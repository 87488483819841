import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ТИПОВ СОБЫТИЙ

export default {
    state() {
        return {
            // список объектов
            scheduleEventTypes: [],
            // время обновления кэша new Date().getTime()
            scheduleEventTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            scheduleEventTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getScheduleEventTypes(state) {
            return state.scheduleEventTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setScheduleEventTypes(state, payload) {
            state.scheduleEventTypes = payload;
            state.scheduleEventTypesCache = new Date().getTime()
        },

    },
    actions: {
        // запрос списка объектов
        doFetchScheduleEventTypes: (context, payload) => {
            return DoGet(
                '/rmt/scheduleEventTypes',
                'rmt/setScheduleEventTypes',
                [],
                'Запрос типов событий',
                false,
                payload && payload.force,
                context.state.scheduleEventTypesCache,
                context.state.scheduleEventTypesCacheTimeout
            )
        },

        // очищает кэш
        doClearScheduleEventTypes: (context, payload) => {
            return DoClearCache(
                'rmt/setScheduleEventTypes',
                [],
                payload && payload.force,
                context.state.scheduleEventTypesCache,
                context.state.scheduleEventTypesCacheTimeout
            )
        },
    }
}
