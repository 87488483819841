import {DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ БАЗОВЫХ ЛИНИЙ ТИПОВ РЕЙСОВ

export default {
    actions: {
        // запрос объекта
        doFetchTripTypeBaseLine: (context, payload) => {
            return DoGet(
                '/multipass/tripTypeBaseLines?trip_type_id='+payload.trip_type_id,
                null,
                {},
                'Запрос базовой линии типа рейса',
                false,
                true,
            )
        },

        // сохранить базовую линии типа рейса
        doSaveTripTypeBaseLine: (context, payload) => {
            return DoPut(
                '/multipass/tripTypeBaseLines/'+payload.trip_type_id,
                payload.polyline,
                null,
                'Установка базовой линии типа рейса'
            )
        },
    }
}
