<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span>Остановочные пункты</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="crudButtonsExist"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
              @onSave="onTableSave"
              @onCancel="onTableCancel"
          >

            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">Связь</button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="stopZonesLink">Зона остановочного пункта</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item router-link :to="stopPavilionsLink">Остановочные павильоны</dropdown-item>
                  <dropdown-divider></dropdown-divider>
                  <dropdown-item router-link :to="stopDocsLink">Документы</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>

            <template #rightCustomButton>
              <input-checkbox class="mt-2" v-model="isAllStops" id="allStops">Все остановки</input-checkbox>
            </template>

          </crud-form>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <!-- Таблица  -->
            <align-panel align="left" width="50%" :gap="3">
              <stops-edit-table
                  :selectedRow="selectedRow"
                  :filterText="filterText"
                  @onRowSelect="onRowSelect"
                  @onRowDblClick="onTableShow"
              ></stops-edit-table>
            </align-panel>
            <!-- Карта  -->
            <align-panel align="all">
              <stops-map
                  :isAllStops="isAllStops"
                  :selectedStop="selectedRow"
                  :isMoveMode="isMoveMode"
                  @onMapCreated="onMapCreated"
                  @onAdd="onTableAdd"
                  @onEdit="onTableEdit"
                  @onDelete="onTableDelete"
                  @onShow="onTableShow"
                  @onStopSelect="onStopSelect"
                  @onMove="onMoveMode"
                  @onStopChanged="onStopChanged"
              >
              </stops-map>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <stop-dialog
      v-if="isDialogVisible"
      :item=dialogRow
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </stop-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить остановочный пункт с кодом {{ id }} ?</confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import StopDialog from "@/components/multipass/stops/StopDialog";
import StopsMap from "@/components/multipass/stops/StopsMap";
import StopsEditTable from "@/components/multipass/stops/StopsEditTable";
import store from "@/store/Store";
import PageMixin from "@/pages/share/PageMixin";
import {toRaw} from "vue";
import {editMutation} from "@/store/Actions";

export default {
  mixins: [PageMixin],

  components: {
    StopsEditTable,
    StopsMap,
    StopDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op", 
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // карта (проксированная)
      proxyLeaflet: null,
      // отображать спиннер
      isLoading: false,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // исходная остановка
      originStop: null,
      // признак изменения
      isModified: false,
      // отображать все остановки
      isAllStops: false,
      // режим перемещения
      isMoveMode: false,
      // данные, которые отображаются в диалоговом окне
      dialogRow: {},
    }
  },

  computed: {
      // карта
      leaflet() {
        return toRaw(this.proxyLeaflet)
      },
      // включение crud-кнопок
      crudButtonsExist() {
        return {
          add: !this.isMoveMode,
          edit: !this.isMoveMode,
          delete: !this.isMoveMode,
          save: this.isMoveMode,
          cancel: this.isMoveMode,
          refresh: true
        }
      },

      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            add: true, 
            edit: !!this.selectedRow, 
            delete: !!this.selectedRow,
            save: this.isModified,
            cancel: true,
            refresh: true
          }
      },

      // условие отображения диалогового окна
      isDialogVisible() {
          return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
      },

      // условие отображения подтверждения удаления
      isDelConfirmVisible() {
          return this.op === 'del' && !!this.selectedRow;
      },

      // условие отключения кнопки - Связь
      linkDisabled() {
        return !this.selectedRow;
      },

      // путь к остановочным павильонам
      stopPavilionsLink(){
        if (this.selectedRow) return '/multipass/stops/'+ this.selectedRow.stop_id +'/stopPavilions';
        else return '';
      },

      // путь к зоне остановочного пункта
      stopZonesLink() {
        if (this.selectedRow) return '/multipass/stops/'+ this.selectedRow.stop_id +'/stopZones';
        else return '';
      },

      // путь к документам остановочных пунктов
      stopDocsLink(){
        if (this.selectedRow) return '/multipass/stops/'+ this.selectedRow.stop_id +'/stopDocs';
        else return '';
      },
  },
  methods: {
    // создана карта
    onMapCreated(leaflet) {
      this.proxyLeaflet = leaflet;
    },

    // нажата кнопка "Добавить"
    onTableAdd(item = {}) {
      this.dialogRow = {...item, stop_type_id: 1};
      this.$router.replace("/multipass/stops/add");
    },

    // нажата кнопка "Изменить"
    onTableEdit(item = this.selectedRow) {
      this.dialogRow = item;
      this.$router.replace("/multipass/stops/edit/" + item.stop_id);
    },

    // нажата конпка "Просмотр"
    onTableShow(item = this.selectedRow) {
      this.dialogRow = item;
      this.$router.replace("/multipass/stops/show/" + item.stop_id);
    },

    // нажата кнопка "Удалить"
    onTableDelete(item = this.selectedRow) {
      this.$router.replace("/multipass/stops/del/" + item.stop_id);
    },

    // нажата кнопка "Сохранить" (в режиме перемещения)
    async onTableSave() {
      // сохраняем изменения
      await this.editItem(this.selectedRow);
      // снимаем режим редактирования
      this.isMoveMode = false;
    },

    // нажата кнопка "Отменить" (в режиме перемещения)
    async onTableCancel() {
      // отменяем изменения
      await this.restoreItem();
      // снимаем режим редактирования
      this.isMoveMode = false;
    },

    // нажата кнопка "Обновить" в таблице
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // выбрано меню "Переместить"
    onMoveMode() {
      // запоминаем исходную остановку
      this.originStop = {...this.selectedRow};
      // сбрасываем признак изменения
      this.isModified = false;
      // включаем режим перемещения
      this.isMoveMode = true;
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/multipass/stops");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      }
      else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/multipass/stops");
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/multipass/stops");
    },

    // остановка переместилась
    onStopChanged(stop) {
      this.selectedRow = editMutation(store.getters["multipass/getStops"], 'stop_id', stop);
      // устанавливаем признак изменения
      this.isModified = true;
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая остановка в таблице
    async onRowSelect(item) {
      // включен режим перемещения
      if (this.selectedRow && this.isMoveMode && this.selectedRow !== item) {
        // сбрасываем режим редактирования
        this.isMoveMode = false;
        // отменяем изменения
        await this.restoreItem();
      }
      // выделяем новую остановку
      this.selectedRow = item;
      // ругаемся
      if (!item.latitude || !item.longitude) {
        store.dispatch('doAddMessage', {
          caption: 'Отображение остановки',
          message: 'Не указаны координаты',
          type: 'error'
        }, {root: true});
        // выходим
        return;
      }

      // перемещаем карту к нужной остановке
      if (this.leaflet && item.latitude && item.longitude) {
        const minStopZoom = this.$store.getters['settings/getMinStopZoom'];
        const zoom = this.leaflet.getZoom();
        if (zoom >= minStopZoom)
          this.leaflet.setView([item.latitude, item.longitude], zoom)
        else
          this.leaflet.setView([item.latitude, item.longitude], minStopZoom)
      }
    },

    // выбрана новая остановка в карте
    async onStopSelect(item) {
      // включен режим перемещения
      if (this.selectedRow && this.isMoveMode && this.selectedRow !== item) {
        // сбрасываем режим перемещения
        this.isMoveMode = false;
        // отменяем изменения
        await this.restoreItem();
      }
      // выделяем новую остановку
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading='fetch';
      try {

        await this.$store.dispatch('multipass/doFetchStopTypes')
        await this.$store.dispatch('multipass/doFetchStopstations')
        await this.$store.dispatch('ref/doFetchDepots')
        await this.$store.dispatch('multipass/doFetchStops', { force })

      }
      finally {
        this.selectedRow = null;
        this.isLoading=false;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        const stop = await this.$store.dispatch('multipass/doAddStop', item);
        if (stop) this.selectedRow = stop;
          else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const stop = await this.$store.dispatch('multipass/doEditStop', item);
        if (stop) this.selectedRow = stop;
          else this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('multipass/doDelStop', this.selectedRow);
        this.selectedRow = null;

      }
      finally {
        this.isLoading = false;
      }
    },

    // восстанавливаем объект
    async restoreItem() {
      this.selectedRow =  editMutation(store.getters["multipass/getStops"], 'stop_id', this.originStop);
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};

</script>
