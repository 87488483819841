import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ВИДОВ ТРАНСПОРТА

export default {
    state() {
        return {
            // список объектов
            transportTypes: [],
            // время обновления кэша new Date().getTime()
            transportTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            transportTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getTransportTypes(state) {
            return state.transportTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setTransportTypes(state, payload) {
            state.transportTypes = payload;
            state.transportTypesCache = new Date().getTime()
        },
        // добавить объект
        addTransportType(state, payload) {
            state.transportTypes.push(payload)
        },
        // изменить объект
        editTransportType(state, payload) {
            const index = state.transportTypes.findIndex(tt => tt.transport_type_id == payload.transport_type_id);
            if (index >= 0) {
                state.transportTypes[index] = payload;
            }
        },
        // удалить объект
        delTransportType(state, payload) {
            const index = state.transportTypes.findIndex(tt => tt.transport_type_id == payload.transport_type_id);
            state.transportTypes.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchTransportTypes: (context, payload) => {
            return DoGet(
                '/ref/transportTypes',
                'ref/setTransportTypes',
                [],
                'Запрос видов транспорта',
                false,
                payload && payload.force,
                context.state.transportTypesCache,
                context.state.transportTypesCacheTimeout
            )
        },

        // очищает кэш
        doClearTransportTypes: (context, payload) => {

            return DoClearCache(
                'ref/setTransportTypes',
                [],
                payload && payload.force,
                context.state.transportTypesCache,
                context.state.transportTypesCacheTimeout
            )
        },

        // добавление объекта
        doAddTransportType: (context, payload) => {
            return DoPost(
                '/ref/transportTypes',
                payload,
                'ref/addTransportType',
                'Добавление вида транспорта'
            )
        },

        // изменение объекта
        doEditTransportType: (context, payload) => {
            return DoPut(
                '/ref/transportTypes/'+payload.transport_type_id,
                payload,
                'ref/editTransportType',
                'Изменение вида транспорта'
            )
        },

        // удаление объекта
        doDelTransportType: (context, payload) => {
            return DoDelete(
                '/ref/transportTypes/'+payload.transport_type_id,
                'ref/delTransportType',
                'Удаление вида транспорта'
            )
        }
    }
}