import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ТИПОВ АВТОСТАНЦИЙ

export default {
    state() {
        return {
            // список объектов
            stopstationTypes: [],
            // время обновления кэша new Date().getTime()
            stopstationTypesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            stopstationTypesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getStopstationTypes(state) {
            return state.stopstationTypes;
        },
    },
    mutations: {
        // установить новый список объектов
        setStopstationTypes(state, payload) {
            state.stopstationTypes = payload;
            state.stopstationTypesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос списка объектов
        doFetchStopstationTypes: (context, payload) => {
            return DoGet(
                '/multipass/stopstationTypes',
                'multipass/setStopstationTypes',
                [],
                'Запрос типов автостанций',
                false,
                payload && payload.force,
                context.state.stopstationTypesCache,
                context.state.stopstationTypesCacheTimeout
            )
        },

        // очищает кэш
        doClearStopstationTypes: (context, payload) => {
            return DoClearCache(
                'multipass/setStopstationTypes',
                [],
                payload && payload.force,
                context.state.stopstationTypesCache,
                context.state.stopstationTypesCacheTimeout
            )
        },
    }
}