import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПНЫХ ДЛЯ ФОРМИРОВАНИЯ НАРЯДА ВОДИТЕЛЕЙ

export default {
    state() {
        return {
            // список объектов
            orderDrivers: [],
            // список объектов отсортированных по depot_id
            orderDriversByDepot: {},
            // время обновления кэша new Date().getTime()
            orderDriversCache: null,
            // время жизни кэша в мс (null - бесконечно)
            orderDriversCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getOrderDrivers(state) {
            return state.orderDrivers;
        },

        // вернуть список объектов, отфильтрованный по depot_id
        getOrderDriversByDepotId(state) {
            return state.orderDriversByDepot;
        },
    },
    mutations: {
        // установить новый список объектов
        setOrderDrivers(state, payload) {
            state.orderDrivers = payload;
            let arr = [];
            payload.map(value => {
                if (arr[value.depot_id]) {
                    arr[value.depot_id].push(value);
                } else {
                    arr[value.depot_id] = [];
                    arr[value.depot_id].push(value);
                }
            });
            state.orderDriversByDepot = arr;
            state.orderDriversCache = new Date().getTime()
        },
    },
    actions: {

        // запрос списка объектов
        doFetchOrderDrivers: (context, payload) => {
            return DoGet(
                '/kvr/getOrderDrivers/' + payload.date_index,
                'kvr/setOrderDrivers',
                [],
                'Получить список доступных для формирования наряда водителей',
                false,
                payload && payload.force,
                context.state.orderDriversCache,
                context.state.orderDriversCacheTimeout
            )
        },

        // очищает кэш
        doClearOrderDrivers: (context, payload) => {
            return DoClearCache(
                'kvr/setOrderDrivers',
                [],
                payload && payload.force,
                context.state.orderDriversCache,
                context.state.orderDriversCacheTimeout
            )
        },
    }
}