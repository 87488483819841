// ХРАНИЛИЩЕ ПОКАЗАТЕЛЕЙ ПАССАЖИРОПТОКА ПО ОПЛАЧЕННЫМ ПОЕЗДКАМ ПО МАРШРУТАМ ПАРКА С ГРУППИРОВКОЙ ПО ДАТАМ ЗА ВЫБРАННЫЙ ПЕРИОД

import {DoPost} from "@/store/Actions";

export default {
    actions: {
        // запрос списка объектов
        doFetchPayTransByRouteDay: (context, payload) => {
            return DoPost(
                '/asmpp/asop/payTransByRouteDay',
                payload,
                null,
                'Запрос показателей пассажиропотока по оплаченным поездкам по маршруту с группировкой по датам за выбранный период',
                false,
                true
            )
        },
    }
}
