<template>
 <edit-table-panel :columnList="columnList" :rowList="rowList" :disableSettings="true"
                   :rowsPerPage="100000" name="tbl-kvr-work-task-fact-trip" ></edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getSignTitle, secondsToTime} from "@/lib";
export default {
  components: {EditTablePanel},
  props: ['rows'],
  data(){
    return {
      columnList:[
        {
          field: '__tripType',
          caption: 'Тип',
          sortType: 'number',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'trip_time_index_begin',
          caption: 'Начало',
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.trip_time_index_begin) {
              return secondsToTime(row.trip_time_index_begin);
            }
            return ''
          },
        },
        {
          field: 'trip_time_index_end',
          caption: 'Оконч.',
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.trip_time_index_end) {
              return secondsToTime(row.trip_time_index_end);
            }
            return ''
          },
        },
        {
          field: 'is_begin',
          caption: 'НКП',
          sortType: 'boolean',
          headerAlign: 'center',
          dataAlign: 'center',
          displayType: 'html',
          isSortable: false,
          displayFn(row) {
            if (row.is_begin) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'is_end',
          caption: 'ККП',
          sortType: 'boolean',
          headerAlign: 'center',
          dataAlign: 'center',
          displayType: 'html',
          isSortable: false,
          displayFn(row) {
            if (row.is_end) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'cp_count_plan',
          caption: 'План КП',
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'cp_count_fact',
          caption: 'Факт КП',
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: "length_fact",
          caption: "Пробег(км)",
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.length_fact) {
              return row.length_fact/1000;
            } else if (row.length_fact == 0) {
              return 0;
            }
            return ''
          },
        },
        {
          field: 'is_recognized',
          caption: 'Распозн.',
          sortType: 'boolean',
          headerAlign: 'center',
          dataAlign: 'center',
          displayType: 'html',
          isSortable: false,
          displayFn(row) {
            if (row.is_recognized) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'parent_fact_trip_id',
          caption: 'Код род. рейса',
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: "__id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
      ]
    }
  },

  computed: {

    rowList(){

      return this.rows.map(reis => {
        reis.__id = reis.fact_trip_id;
        reis.__tripType = getSignTitle(reis.trip_type_sign);
        reis.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px'
        };
        return reis;
      })
    }

  }
}
</script>

<style scoped>

</style>