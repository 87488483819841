<template>
  <edit-table-panel :columnList="columnList" :rowList="rows" name="tbl-admin-system-events" :rowsPerPage="1000"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getDateTimeFormat} from "@/lib";
import {getModule} from "@/store/SystemHelpers";
export default {
  components: {EditTablePanel},
  props: ['isLoading', 'rowList'],
  emits: ['onSetModule', 'onSetService'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "title",
          caption: "Содержание",
          sortType: 'number',
          isSortable: false,
        },
        {
          field: "event_result",
          caption: "Статус",
          sortType: 'string',
          isSortable: false,
        },
        {
          field: "event_result_num",
          caption: "Рез-т",
          headerAlign: 'center',
          dataAlign: 'center',
          sortType: 'string',
          isSortable: false,
        },
        {
          field: "__time_begin",
          caption: "Начало",
          sortType: 'string',
          isSortable: false,
        },
        {
          field: "__time_end",
          caption: "Окончание",
          sortType: 'string',
          isSortable: false,
        },
        {
          field: "duration",
          caption: "Продолж.",
          sortType: 'string',
          dataAlign: 'right',
          isSortable: false,
          displayFn(row) {
            if (row.duration) {
              return Number(row.duration/1000).toLocaleString('ru-RU');
            }
            return 0
          },
        },
        {
          field: "service_name",
          caption: "Сервис",
          sortType: 'string',
          isSortable: false,
          headerAlign: 'right',
          dataAlign: 'right',
          displayType: 'button',
          buttonAttrs: {
            'style': "border: 0px; backgroundColor: inherit; color: inherit; padding-bottom: 2px; padding-top: 0",
          },
          buttonClick: (row) => {
            this.$emit('onSetService', row.service_name);
          },
        },
        {
          field: "__module",
          caption: "Модуль",
          sortType: 'string',
          isSortable: false,
          displayType: 'button',
          buttonAttrs: {
            'style': "border: 0px; backgroundColor: inherit; color: inherit; padding-bottom: 2px; padding-top: 0",
          },
          buttonClick: (row) => {
            this.$emit('onSetModule', row.module_id);
          },
        },
        {
          field: "system_event_id",
          caption: "Код",
          sortType: 'string',
          isSortable: true,
          show: false,
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.rowList.map(row => {

        row.__id = row.system_event_id;
        row.__time_begin = getDateTimeFormat(row.time_begin);
        row.__time_end = getDateTimeFormat(row.time_end);
        row.__module = getModule(row.module_id).module_name;
        row.__cellStyle = {
          padding: '0',
          fontSize: 'small'
        }
        return row;
      })
    }
  }
};
</script>
