import {DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ЗОН ПОСАДКИ ВЫСАДКИ

export default {
    actions: {
        // запрос объекта
        doFetchTripTypeZone: (context, payload) => {
            return DoGet(
                '/multipass/tripTypeZones/'+payload.trip_type_zone_id,
                null,
                {},
                'Запрос зоны посадки-высадки',
                false,
                true,
            );
        },

        // запрос списка объектов
        doFetchTripTypeZones: (context, payload) => {
            return DoGet(
                '/multipass/tripTypeZones?route_variant_id='+payload.route_variant_id,
                null,
                [],
                'Запрос зон посадки-высадки',
                false,
                true,
            );
        },

        // добавление объекта
        doAddTripTypeZone: (context, payload) => {
            return DoPost(
                '/multipass/tripTypeZones',
                payload,
                null,
                'Добавление зоны посадки-высадки'
            )
        },

        // изменение объекта
        doEditTripTypeZone: (context, payload) => {
            return DoPut(
                '/multipass/tripTypeZones/'+payload.trip_type_zone_id,
                payload,
                null,
                'Изменение зоны посадки-высадки'
            )
        },

        // удаление объекта
        doDelTripTypeZone: (context, payload) => {
            return DoDelete(
                '/multipass/tripTypeZones/'+payload.trip_type_zone_id,
                null,
                'Удаление зоны посадки высадки'
            )
        }
    }
}
