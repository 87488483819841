import {DoClearCache, DoGet, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ КЛАССИФИКАТОРА ОБЪЕКТОВ АДМИНИСТРАТИВНО-ТЕРРИТОРИАЛЬНОГО ДЕЛЕНИЯ

export default {
    state() {
        return {
            // список объектов
            areaClassifier: [],
            // время обновления кэша new Date().getTime()
            areaClassifierCache: null,
            // время жизни кэша в мс (null - бесконечно)
            areaClassifierCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getAreaClassifier(state) {
            return state.areaClassifier;
        },
    },
    mutations: {
        // установить новый список объектов
        setAreaClassifier(state, payload) {
            state.areaClassifier = payload;
            state.areaClassifierCache = new Date().getTime()
        },
        // изменить объект
        editAreaClassifier(state, payload) {
            const index = state.areaClassifier.findIndex(cls => cls.area_classifier_id == payload.area_classifier_id);
            if (index >= 0) {
                state.areaClassifier[index] = payload;
            }
        },
    },
    actions: {
        // запрос списка объектов
        doFetchAreaClassifier: (context, payload) => {
            return DoGet(
                '/ref/areaClassifier',
                'ref/setAreaClassifier',
                [],
                'Запрос объектов АТД',
                false,
                payload && payload.force,
                context.state.areaClassifierCache,
                context.state.areaClassifierCacheTimeout
            )
        },

        // очищает кэш
        doClearAreaClassifier: (context, payload) => {

            return DoClearCache(
                'ref/setAreaClassifier',
                [],
                payload && payload.force,
                context.state.areaClassifierCache,
                context.state.areaClassifierCacheTimeout
            )
        },

        // изменение объекта
        doEditAreaClassifier: (context, payload) => {
            return DoPut(
                '/ref/areaClassifier/'+payload.area_classifier_id,
                payload,
                'ref/editAreaClassifier',
                'Изменение объекта АТД'
            )
        },
    }
}