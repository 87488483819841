// ХРАНИЛИЩЕ КОЛИЧЕСТВА МАШИН ПО КАЖДОМУ ЧАСУ

export default {
    actions: {
        // запрос набора графиков (для табличного отображения)
        // doFetchHours (context, payload) {
        doFetchHours() {
            let arr = [
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
                24,
                1,
                2,
                3,
                4,
                // 5,
                // 6,
                // 7,
                // 8,
                // 9,
                // 10,
                // 11,
                // 12,
                // 13,
                // 14,
                // 15,
                // 16,
                // 17,
                // 18,
                // 19,
                // 20,
                // 21,
                // 22,
                // 23,
                // 24,
                // 1,
                // 2,
                // 3,
                // 4,
                // 5,
                // 6,
                // 7,
                // 8,
                // 9,
                // 10,
                // 11,
                // 12,
                // 13,
                // 14,
                // 15,
                // 16,
                // 17,
                // 18,
                // 19,
                // 20,
                // 21,
                // 22,
                // 23,
                // 24,
            ];
            // добавляем 8 часов к пришедшим часам
            // for (let i = 1; i <= 8; i++){
            //     // смотрим какой час последний
            //     let lastHour = arr[arr.length-1];
            //     if (lastHour == 24) {
            //         arr.push(1)
            //     } else {
            //         arr.push(lastHour + 1);
            //     }
            // }
            return arr;
        },

    }
}
