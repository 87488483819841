<template>
  <align-container>
          <align-panel align="all">

            <management-journal-edit-table
              :tableRows="rows"
            >
            </management-journal-edit-table>

          </align-panel>

        </align-container>

</template>

<script>


import ManagementJournalEditTable from "@/components/trn/dispatching/ManagementJournalEditTable";
import {dateToND, stringToDate} from "@/lib";
export default {
  name: "ManagementJournalPanel",
  components: {ManagementJournalEditTable},
  props: ['selected_date', 'rows'],
  emits: ['onSelectedRow'],
  data() {
    return {

    }
  },

  computed: {},



  methods: {

  },

  async created() {

    await this.$store.dispatch('trn/doFetchManageRoutes');
    await this.$store.dispatch('trn/doFetchManageVehicles',{
      date_index: dateToND(stringToDate(this.selected_date)),
    });

    await this.$store.dispatch('trn/doFetchManageDrivers',{
      date_index: dateToND(stringToDate(this.selected_date)),
    });



  }


}
</script>

<style scoped>

</style>