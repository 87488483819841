<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">

      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span><router-link to="/asmpp/depots">Список предприятий</router-link></span>
        <span> / </span>
        <span>Диагностика оборудования {{ depotsPageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">

          <crud-form
              :buttonsExist="{ refresh: true }"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <template #preLeftCustomButton>
              <button @click="onReport0004" type="button"
                      class="btn btn-primary">
                Отчет
              </button>
            </template>

          </crud-form>
        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <align-container>
            <align-panel align="all">

              <passenger-traffic-day-by-transport-nav-sum-edit-table
                  :filterText="filterText"
                  :isLoading="isLoading"
                  :selectedRow="selectedRow"
                  :rowArr="rows"
                  :depots="depots"
                  @onRowSelect="onRowSelect"
                  @setLoading="setLoading"
              ></passenger-traffic-day-by-transport-nav-sum-edit-table>

            </align-panel>
            <align-panel align="bottom">
              <!-- Форма с кнопками под правой таблицей-->
              <card>
                <card-body class="p-1">
                  <table class="table table-sm" style="margin-bottom:0">
                    <thead class="thead-dark">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Перев. (вх.)</th>
                      <th scope="col">Перев. (вых.)</th>
                      <th scope="col">КПД-ПЛ</th>
                      <th scope="col">КПД-ВЛ</th>
                      <th scope="col">Количество оплат</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in getBottomPanelItems" :key="index">
                      <td>{{ item.name }}</td>
                      <td>{{ item.passenger_in }}</td>
                      <td>{{ item.passenger_out }}</td>
                      <td>{{ item.passengers_by_km }}</td>
                      <td>{{ item.passengers_by_hour }}</td>
                      <td>{{ item.pay_count_sum }}</td>
                    </tr>
                    </tbody>
                  </table>
                </card-body>
              </card>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

  <!-- Оповещение о том, Что выбрано больше 1 предприятия -->
  <error-message-box
      v-if="isErrorMessageVisible"
      @onClose="onCloseMessageBox"
  >Получение отчета невозможно, необходимо выбрать одно предприятие и одну дату.
  </error-message-box>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import PassengerTrafficDayByTransportNavSumEditTable
  from "@/components/asmpp/passenger_traffic_day_by_transport_nav_sum/PassengerTrafficDayByTransportNavSumEditTable";
import {getDepot} from "@/store/RefHelpers";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";

export default {
  components: {AlignPanel, AlignContainer, PassengerTrafficDayByTransportNavSumEditTable},
  mixins: [PageMixin],

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрутов
    "depots_id",
    // дата начала периода
    "date_index_begin",
    // дата конца периода
    "date_index_end",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // предприятия
      depots: [],
      // список рейсов
      rows: [],
      // видимость уведомления об ошибке (когда выбрано более одного предприятия)
      isErrorMessageVisible: false,
    }
  },

  computed: {

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // расчет параметров для таблицы "Итого" снизу
    getBottomPanelItems(){
      let items = [];
      let passenger_in_sum = 0;
      let passenger_out_sum = 0;
      let passengers_by_km_sum = 0;
      let passengers_by_hour_sum = 0;
      let pay_count_sum = 0;

      for(let i = 0; i < this.rows.length; i++) {
        passenger_in_sum = passenger_in_sum + this.rows[i].passenger_in_sum;
        passenger_out_sum = passenger_out_sum + this.rows[i].passenger_out_sum;
        passengers_by_km_sum = passengers_by_km_sum + this.rows[i].passengers_by_km;
        passengers_by_hour_sum = passengers_by_hour_sum + this.rows[i].passengers_by_hour;
        pay_count_sum = pay_count_sum +  this.rows[i].pay_tran_count
        ;
      }
      items.push({
        name: "Суммарно",
        passenger_in: passenger_in_sum,
        passenger_out: passenger_out_sum,
        passengers_by_km: '-',
        passengers_by_hour: '-',
        pay_count: pay_count_sum,
      })
      items.push({
        name: "Среднее",
        passenger_in: this.rows != 0 ? Math.round(parseFloat(passenger_in_sum/this.rows.length) * 100) / 100 : 0 ,
        passenger_out: this.rows != 0 ? Math.round(parseFloat(passenger_out_sum/this.rows.length) * 100) / 100 : 0,
        passengers_by_km: this.rows != 0 ? Math.round(parseFloat(passengers_by_km_sum/this.rows.length) * 100) / 100 : 0,
        passengers_by_hour: this.rows != 0 ? Math.round(parseFloat(passengers_by_hour_sum/this.rows.length) * 100) / 100 : 0,
        pay_count: this.rows != 0 ? Math.round(parseFloat(pay_count_sum/this.rows.length) * 100) / 100 : 0,
    })
      return items;
    }
  },

  methods: {

    // закрываем информационное сообщение
    onCloseMessageBox() {
      this.isErrorMessageVisible = false;
    },

    // установка флага загрузки
    setLoading(item) {
      this.isLoading = item;
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        this.depots = [];

        await this.$store.dispatch('multipass/doFetchRoutes');
        await this.$store.dispatch('ref/doFetchDepots');
        await this.$store.dispatch('ref/doFetchTransport');

        let rows = [];
        for (let date_index = this.date_index_begin; date_index <= this.date_index_end; date_index++) {
          for (let i = 0; i < this.depots_id.length; i++) {
            // загружаем пассажиропоток по маршруту
            let depotRows = await this.$store.dispatch('asmpp/doFetchPassengerTrafficDayByTransportNavSum', {
              depot_id: this.depots_id[i],
              date_index: date_index,
              force: true,
            });
            if (depotRows) {
              rows.push(...depotRows)
              // запрос предприятия
              const depot = getDepot(this.depots_id[i]);

              if (depot && this.depots.findIndex(value => value.depot_id == depot.depot_id) == -1) {
                this.depots.push(depot);
              }
            }
          }
        }
        this.rows = rows;
      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },



    // нажата кнопка запроса отчета
    onReport0004(){
      if(this.depots_id.length > 1 || (this.date_index_begin != this.date_index_end)) {
        this.isErrorMessageVisible = true;
        return;
      }
      // вносим временный отчет
      this.$store.commit('reporter/addReport', {report_id: 4});
      // отправляем на сервер запрос на формирование отчета
      this.$store.dispatch('asmpp/doMakeReport0004', {
        depot_id: Number(this.depots_id[0]),
        date_index: Number(this.date_index_begin),
      });
    },

  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
