<template>

  <accordion mode="single">

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group1">
          Основные
        </button-target>
      </card-header>
    </card>
    <collapse ref="group1" active>
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/organizations"
          >Организации</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/depots"
          >Предприятия</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/transportTypes"
          >Виды транспорта</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/models"
          >Модели</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/transport"
          >Транспортные средства</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/personnel"
          >Работники</left-menu-item>
        </li>
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group2">
          Группы
        </button-target>
      </card-header>
    </card>
    <collapse ref="group2">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/depotGroups"
          >Группы предприятий</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/transportGroups"
          >Группы транспортных средств</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/transportTypeGroups"
          >Группы видов транспорта</left-menu-item>
        </li>
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group3">
          Дополнительные
        </button-target>
      </card-header>
    </card>
    <collapse ref="group3">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/transportClasses"
          >Классы транспортных средств</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/engineFuelTypes"
          >Типы топлива</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/emissionStandards"
          >Экологические классы</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/legalForms"
          >Организационно-правовые формы предприятий</left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/ref/areaClassifier"
          >Классификатор объектов административно-территориального деления</left-menu-item>
        </li>
      </ul>
    </collapse>

  </accordion>

</template>
