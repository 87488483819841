<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-rmt-schedule-types"> </edit-table-panel>
</template>

<script>


export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "schedule_type_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "long_name",
          caption: "Полное наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
          show: false
        },
        {
          field: "is_monday",
          caption: "Пн.",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_monday) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_tuesday",
          caption: "Вт.",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_tuesday) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_wednesday",
          caption: "Ср.",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_wednesday) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_thursday",
          caption: "Чт.",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_thursday) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_friday",
          caption: "Пт.",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_friday) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_saturday",
          caption: "Сб.",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_saturday) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_sunday",
          caption: "Вс.",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_sunday) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
        {
          field: "is_holiday",
          caption: "Пр.",
          sortType: 'boolean',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'html',
          displayFn(row) {
            if (row.is_holiday) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },

      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["rmt/getScheduleTypes"].map(schedule_type => {
        schedule_type.__id = schedule_type.season_id;
        return schedule_type;
      });
    },
  }
};
</script>
