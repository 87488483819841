<template>
  <edit-table-panel :columnList="columnList" name="tbl-documents-docs"></edit-table-panel>
</template>

<script>

import {getDateTimeFormat} from "@/lib";

export default {
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "__id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "title",
          subField: "doc",
          caption: "Заголовок документа",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__docTypeName",
          subField: "doc",
          caption: "Тип документа",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "doc_date",
          subField: "doc",
          caption: "Дата документа",
          sortType: 'string',
        },
        {
          field: "time_insert",
          subField: "doc",
          caption: "Время внесения документа",
          sortType: 'string',
          displayFn(row){
            return getDateTimeFormat(row.doc.time_insert)
          }
        },
        {
          field: "time_update",
          subField: "doc",
          caption: "Время обновления документа",
          sortType: 'string',
          displayFn(row){
            return getDateTimeFormat(row.doc.time_update)
          }
        },
      ],
    };
  }
};
</script>
