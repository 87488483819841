<template>
  <edit-table-panel :columnList="columnList" :headerGroupList="headerGroupList" :disableSettings="true"
                    :rowsPerPage="100000" :rowList="rows" name="tbl-kvr-trip-card-items"></edit-table-panel>
</template>

<script>


import {secondsToTime} from "@/lib";

export default {
  props: ['isLoading', 'rowList'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "order_num",
          caption: "№ п/п",
          sortType: 'number',
          isSortable: false,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: "stop_id",
          caption: "Код",
          sortType: 'number',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: "stop_title",
          caption: "Название",
          sortType: 'number',
          show: true,
          isSortable: false,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          cellStyle: {
            maxWidth: '190px',
            whitespace: 'nowrap !important'
          },
        },
        {
          field: "plan_time_index",
          caption: "План",
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          displayFn(row) {
            if (row.plan_time_index) {
              return secondsToTime(row.plan_time_index);
            }
            return ''
          },
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: "fact_time_index",
          caption: "Факт",
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          displayFn(row) {
            if (row.fact_time_index) {
              return secondsToTime(row.fact_time_index);
            }
            return ''
          },
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: "delta",
          caption: "Откл.",
          sortType: 'number',
          isFilterable: true,
          isSortable: false,
          displayFn(row) {
            if (row.delta) {
              if (row.delta == 0) {
                return '0';
              }
              return Math.round((row.delta / 60));
            }
            return ''
          },
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
      ],
      headerGroupList: [
        {
          headerGroupCaption: '',
          headerGroupSpan: 1,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Остановка',
          headerGroupSpan: 2,
          headerGroupAlign: 'center',
        },
        {
          headerGroupCaption: 'Время',
          headerGroupSpan: 3,
          headerGroupAlign: 'center',
        }
      ],
    };
  },

  computed: {

    rows() {
      return this.rowList.map(resultTrip => {

        resultTrip.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px'
        };

        // если нет плана однозначно возвращаем строку желтого цвета
        if(!resultTrip.plan_time_index) {
          resultTrip.__rowStyle = {
            backgroundColor: '#f8f19b'
          }
          return resultTrip;
        }

        // задаем цвет заднего фона строк
        if (resultTrip.mark == 0) {
          if (resultTrip.fact_time_index) {
            resultTrip.__rowStyle = {
              backgroundColor: '#ffece8'
            }
          } else {
            resultTrip.__rowStyle = {
              backgroundColor: '#f8f19b'
            }
          }
        } else if (resultTrip.mark == 1) {
          resultTrip.__rowStyle = {
            backgroundColor: '#daffd7'
          }
        } else if (resultTrip.mark == 2) {
          resultTrip.__rowStyle = {
            backgroundColor: '#a4ff9c'
          }
        }

        return resultTrip;
      });
    },
  },
};
</script>
