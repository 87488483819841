<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>{{getManagementActionTittle}}</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>

            <div v-if="![2,5].includes(management_action_id)" class="form-group">
              <label for="deflection">Сместить на (мин.):</label>
              <div class="row">
                <input-integer
                    class="form-control"
                    id="deflection"
                    v-model="deflection"
                    ref="deflection"
                    required
                    style="width: 90%; margin-left: 15px"
                    @change="changeDeflection()"
                />
                <div class="col" style="padding: 0">
                  <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      @click="reduceDeviation()"
                      style="height: 19px; width: 20px; padding: 2px 2px; flex-direction: column; align-items: flex-start; display: flex;"
                  >
                    <i class="fas fa-arrow-alt-circle-up"></i>
                  </button>
                  <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      @click="increaseDeviation()"

                      style="height: 19px; width: 20px; padding: 2px 2px 2px 2px; flex-direction: column; align-items: flex-start; display: flex;"
                  >
                    <i class="fas fa-arrow-alt-circle-down"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="startTime">Время:</label>
              <input-time
                  class="form-control"
                  id="startTime"
                  v-model="startTime"
                  ref="startTime"
                  :readonly="[2,5].includes(management_action_id)"
                  @change="recalculateDeviation"
              />
            </div>
            <div v-if="![2,5].includes(management_action_id)" class="form-group">
              <label for="baseTime">Базовое время:</label>
              <input-time
                  class="form-control"
                  id="baseTime"
                  v-model="baseTime"
                  ref="baseTime"
                  readonly
              />
            </div>

          </form>
        </modal-body>
        <modal-footer>
          <button v-if="[4].includes(management_action_id)" :disabled="isFactButtonDisabled" @click="onFact" class="btn btn-primary">Факт</button>
          <button @click="onSubmit" class="btn btn-primary">ОК</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>
import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputTime from "@/components/ui/custom/InputTime";
import {getManagementAction} from "@/store/TrnHelpers";

export default {
  components: {InputTime,},
  props: ['selectedRow', 'management_action_id'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {

      startTime: this.selectedRow.fact_time ? this.selectedRow.fact_time : this.selectedRow.plan_time,
      baseTime: this.selectedRow ? this.selectedRow.plan_time : 0,
      // deflection: this.startTime - this.baseTime,
      deflection: (this.selectedRow.fact_time ? this.selectedRow.fact_time : this.selectedRow.plan_time) - (this.selectedRow ? this.selectedRow.plan_time : 0)
    };
  },

  watch: {


  },

  computed: {

    getManagementActionTittle(){
      let action = getManagementAction(this.management_action_id);
      if (action) {
        return action.short_name;
      }
      return '';
    },

    isFactButtonDisabled(){
      return !this.selectedRow.fact_time;
    }

  },

  methods: {



    changeDeflection() {
      this.startTime = this.baseTime + (this.deflection * 60);
    },

    reduceDeviation() {
      this.deflection -= 1;
      this.startTime -= 60;
    },

    increaseDeviation() {
      this.deflection += 1;
      this.startTime += 60;
    },

    recalculateDeviation() {
      this.deflection = (this.startTime - this.baseTime)/60;
    },

    onFact() {
      this.deflection = (this.selectedRow.fact_time - this.baseTime);
      if (this.validateForm(this.$refs)) {
        if(this.management_action_id == 4) {
          this.$emit('onOK', {
            deflection: this.deflection,
            management_action_id: this.management_action_id,
          });
        }
      }
    },

    onSubmit() {
      if (this.validateForm(this.$refs)) {
        if(this.management_action_id == 1) {
          this.$emit('onOK', {
            deflection: this.deflection * 60,
            begin_action_type: 0,
            end_action_type: 0,
            management_action_id: this.management_action_id,
          });
        } else if (this.management_action_id == 2) {
          this.$emit('onOK', {
            // deflection: this.deflection,
            begin_action_type: 0,
            // end_action_type: 0,
            management_action_id: this.management_action_id,
          });
        } else if ([3, 4, 5].includes(this.management_action_id)) {
          this.$emit('onOK', {
            deflection: this.deflection * 60,
            management_action_id: this.management_action_id,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
form label {
  font-weight: bold;
}
</style>