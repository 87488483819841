// ХРАНИЛИЩЕ АГРЕГИРОВАННЫХ ДАННЫХ ПО ОСТАНОВОЧНОМУ ПАССАЖИРОПОТОКУ НА СЕТИ

import {DoPost} from "@/store/Actions";

export default {
    actions: {
        // запрос списка объектов
        doFetchFactTripCardExtsAggregatedNetwork: (context, payload) => {
            return DoPost(
                '/asmpp/factTripCardExts/getAggregatedOnNetwork',
                payload,
                null,
                'Запрос агрегированных данных пассажиропотока по остановочным пунктам',
                false,
                true
            )
        },
    }
}
