import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ОРГАНИЗАЦИОННО-ПРАВОВЫХ ФОРМ

export default {
    state() {
        return {
            // список объектов
            legalForms: [],
            // время обновления кэша new Date().getTime()
            legalFormsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            legalFormsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getLegalForms(state) {
            return state.legalForms;
        },
    },
    mutations: {
        // установить новый список объектов
        setLegalForms(state, payload) {
            state.legalForms = payload;
            state.legalFormsCache = new Date().getTime()
        },
    },
    actions: {
        // запрос список объектов
        doFetchLegalForms: (context, payload) => {

            return DoGet(
                '/ref/legalForms',
                'ref/setLegalForms',
                [],
                'Запрос организационно-правовых форм предприятий',
                false,
                payload && payload.force,
                context.state.legalFormsCache,
                context.state.legalFormsCacheTimeout
            )
        },

        // очищает кэш
        doClearLegalForms: (context, payload) => {

            return DoClearCache(
                'ref/setLegalForms',
                [],
                payload && payload.force,
                context.state.legalFormsCache,
                context.state.legalFormsCacheTimeout
            )
        },
    }
}