
// ХРАНИЛИЩЕ КОЛИЧЕСТВА ТС ПО КАЖДОМУ ЧАСУ

export default {
    actions: {
        // запрос набора графиков (для табличного отображения)
        // doFetchTsCountArr (context, payload) {
        doFetchTsCountArr() {
            return [0, 10, 12, 22, 24, 22, 21, 19, 22, 20, 24, 25, 23, 24, 25, 25, 24, 17, 5, 5, 5, 3]
        },

    }
}
