<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-nav-radio-protocols"> </edit-table-panel>
</template>

<script>

export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "radio_protocol_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "radio_protocol_title",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "radio_protocol_desc",
          caption: "Описание",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "is_driver_display_support",
          caption: "Работа с дисплеем",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_driver_display_support) {
              return '<i class="fas fa-check">'
            } else if (row.is_driver_display_support == 0) {
              return 0;
            }
            return ''
          },
        },
        {
          field: "is_photo_request_support",
          caption: "Запрос фотографии",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_photo_request_support) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["nav/getRadioProtocols"].map(radioProtocol => {
        radioProtocol.__id = radioProtocol.radio_protocol_id;

        return radioProtocol;
      });
    },
  }
};
</script>
