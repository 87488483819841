// ХРАНИЛИЩЕ ПОКАЗАТЕЛЕЙ ПАССАЖИРОПТОКА ПО ОПЛАЧЕННЫМ ПОЕЗДКАМ ПО ГРАФИКАМ МАРШРУТА С ГРУППИРОВКОЙ ПО ДАТАМ ЗА ВЫБРАННЫЙ ПЕРИОД

import {DoPost} from "@/store/Actions";

export default {
    actions: {
        // запрос списка объектов
        doFetchPayTransByGraphDay: (context, payload) => {
            return DoPost(
                '/asmpp/asop/payTransByGraphDay',
                payload,
                null,
                'Запрос показателей пассажиропотока по оплаченным поездкам по графикам маршрута с группировкой по датам за выбранный период',
                false,
                true
            )
        },
    }
}
