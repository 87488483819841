<template>


  <accordion mode="single">

    <ul class="p-0 m-0" style="list-style-type: none;">

      <li>
        <full-left-menu-item to="/rmt/routes">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Список маршрутов
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/rmt/seasons">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Сезоны
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/rmt/scheduleTypes">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Типы расписаний
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/rmt/regimes">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Режимы работы водителя
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/rmt/scheduleEvents">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              События
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/rmt/movementKinds">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Виды движения
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/rmt/transportationKinds">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Виды перевозок
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/rmt/scheduleEventTypes">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Типы событий
            </div>
          </div>
        </full-left-menu-item>
      </li>

      <li>
        <full-left-menu-item to="/rmt/indicators">
          <div class="form-row">
            <div class="col-0 align-self-center">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </div>
            <div class="col">
              Показатели
            </div>
          </div>
        </full-left-menu-item>
      </li>
    </ul>

  </accordion>
</template>
