<template>

  <input-select
      class="form-control"
      style="width: 160px; max-height: 24px; padding: 0; "
      v-model="quarter"
      :itemList="quarters"
      caption="Выберите квартал..."
  ></input-select>
  <input-select
      class="form-control"
      style="width: 100px; max-height: 24px; padding: 0;"
      v-model="year"
      :itemList="years"
      caption="Выберите год..."
  ></input-select>

</template>

<script>

import {getQuarterBorders, getQuarters} from "@/lib";
import InputSelect from "@/components/ui/custom/InputSelect";

export default {
  // !! компонент возвращает дату принадлежащую выбранному кварталу
  components: {InputSelect},
  props: {
    'model-value': {
      required: true
    },
    'error': {
      type: String
    },
    'required': {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:model-value', 'changeQuarters'],
  data() {
    return {
      isInvalid: false,
      errorText: this.error,
      quart: this.getQuarters(),
      selectedYear: this.getYear(),
      quarters: [
        {
          id: 1,
          title: 'I - квартал'
        },
        {
          id: 2,
          title: 'II - квартал'
        },
        {
          id: 3,
          title: 'III - квартал'
        },
        {
          id: 4,
          title: 'IV - квартал'
        }
      ]
    }
  },
  computed: {

    years() {
      let years = [];
      let curYear = (new Date()).getFullYear();
      for (let i = curYear-10; i <= curYear+10; i++){
        years.push({id:i, title: i});
      }
      return years;
    },

    quarter: {
      get() {
        return this.quart;
      },
      set(value) {
        this.quart = value;
        // емититься должен результат функции, которая будет обрабатывать выбранный квартал
        //this.$emit('update:model-value', dateToSeconds(new Date(this.dateVal)) + this.timeVal)
        this.$emit('changeQuarters', getQuarterBorders(this.year, this.quart));
      }
    },

    year: {
      get() {
        return this.selectedYear
      },
      set(value) {
        this.selectedYear = value;
        // емититься должен результат функции, которая будет обрабатывать выбранный квартал
        this.$emit('changeQuarters', getQuarterBorders(this.year, this.quart));
      }
    },

  },
  methods: {

    getQuarters(){
      return getQuarters(new Date(this.modelValue))
    },

    getYear(){
      return this.modelValue.substring(0,4);
    },

    // проверка валидности
    isValid() {
      // разраешаем пустые строки, если допускается пустое значение
      if (this.dateVal === null || this.timeVal === null) {
        return !this.required
      }
      // все остальное можно
      return true;
    },

    // при получении фокуса
    onFocus() {
      // сбрасываем инвалидность
      this.isInvalid = false;
      this.errorText = this.error;
    },

    // при потере фокуса
    onBlur() {
      this.validate()
    },

    // при вводе значений
    onInput() {
      this.validate();
    },

    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },
    alarm(message) {
      this.errorText = message;
      this.isInvalid = true;
    }
  },

  mounted() {
    // следим за изменением модели
    this.$watch(() => this.modelValue, (value) => {
      // пришел null
      if (value === null) {
        // трогаем текстовую строку, только если она противоречит
        if (!this.isCorrelate(this.$refs.inputRef.value)) {
          this.$refs.inputRef.value = '';
          this.lastText = '';
        }
        // выходим
        return
      }
    })
  }
}
</script>
