<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-nav-sensors"> </edit-table-panel>
</template>

<script>


import {getSensorGroup} from "@/store/NavHelpers";

export default {
  props: ['isLoading', 'sensorGroupId'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "sensor_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "sensor_group",
          caption: "Группа",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "sensor_sign",
          caption: "Сигнатура датчика",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__sensor_type",
          caption: "Тип",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "sensor_title",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "sensor_desc",
          caption: "Описание",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "sensor_units",
          caption: "Единицы измерения",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      let sensors = [];
      if (this.sensorGroupId) {
        sensors =  this.$store.getters["nav/getSensors"].filter(sensor =>{
          return sensor.sensor_group_id == this.sensorGroupId;
        });
      } else {
        sensors =  this.$store.getters["nav/getSensors"];
      }

      return sensors.map(sensor => {
        sensor.__id = sensor.sensor_id;
        if (sensor.sensor_type_id == 0){
          sensor.__sensor_type = 'Аналоговый';
        } else if (sensor.sensor_type_id == 1){
          sensor.__sensor_type = 'Дискретный';
        }
        // присваиваем название группы
        if (getSensorGroup(sensor.sensor_group_id)) {
          sensor.sensor_group = getSensorGroup(sensor.sensor_group_id).sensor_group_name;
        }
        return sensor;
      });
    },
  }
};
</script>
