<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span>Граф улично-дорожной сети</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :filterExists="false"
              :buttonsExist="crudButtonsExist"
              :buttonsEnabled="crudButtonsEnabled"
              @onEdit="onTableEdit"
              @onDone="onTableDone"
          >

          </crud-form>
        </align-panel>
        <!-- Карта  -->
        <align-panel align="all">
          <graph-map
              :isEditMode="isEditMode"
          >
          </graph-map>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import GraphMap from "@/components/gis/graph/GraphMap";

export default {
  mixins: [PageMixin],

  components: {
    GraphMap
  },

  data() {
    return {
      // режим редактирования
      isEditMode: false,
    }
  },

  computed: {
      // включение crud-кнопок
      crudButtonsExist() {
        return {
          edit: !this.isEditMode,
          done: this.isEditMode,
          refresh: false
        }
      },

      // состояние crud-кнопок
      crudButtonsEnabled() {
        return {
          edit: true,
          done: true,
          refresh: true
        }
      },
  },
  methods: {
    // нажата кнопка "Изменить"
    onTableEdit() {
      this.isEditMode = true;
    },

    // нажата кнопка "Завершить" (в режиме редактирования)
    onTableDone() {
      this.isEditMode = false;
    },
  },
}
</script>
