import { DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ РЕКВИЗИТОВ ТРАНСПОРТНЫХ ПРЕДПРИЯТИЙ

export default {
    actions: {
        // запрос списка объектов
        doFetchDepotDetails: (context, payload) => {
            if (payload.depot_id) {
                return DoGet(
                    '/ref/depotDetails?depot_id=' + payload.depot_id,
                    null,
                    [],
                    'Запрос реквизитов транспортных предприятий',
                    false,
                    true,
                )
            } else {
                return DoGet(
                    '/ref/depotDetails',
                    null,
                    [],
                    'Запрос реквизитов транспортных предприятий',
                    false,
                    true,
                )
            }
        },

        // добавление объекта
        doAddDepotDetail: (context, payload) => {
            return DoPost(
                '/ref/depotDetails',
                payload,
                null,
                'Добавление реквизита транспортного предприятия'
            )
        },

        // изменение объекта
        doEditDepotDetail: (context, payload) => {
            return DoPut(
                '/ref/depotDetails/'+payload.depot_detail_id,
                payload,
                null,
                'Изменение реквизита транспортного предприятия'
            )
        },

        // удаление объекта
        doDelDepotDetail: (context, payload) => {
            return DoDelete(
                '/ref/depotDetails/'+payload.depot_detail_id,
                null,
                'Удаление реквизита транспортного предприятия'
            )
        }
    }
}
