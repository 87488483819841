<script>
import {Yandex} from 'leaflet';
import LeafletBaseLayerMixin from "@/components/ui/leaflet/mixins/LeafletBaseLayerMixin";
import {toRaw} from "vue";

// БАЗОВЫЙ СЛОЙ КАРТ YANDEX

export default {
  mixins: [LeafletBaseLayerMixin],
  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletYandexLayer',
    }
  },
  methods: {
    // создание слоя
    createLayer() {
      const layer = new Yandex(toRaw(this.options));
      layer.__meta = this.meta;
      this.subscribe(layer);
      this.proxyOrigin = layer;
      return(layer)
    },
    // уничтожения слоя
    destroyLayer() {
      this.unsubscribe(this.origin);
      this.proxyOrigin = null;
    },
  },
}
</script>
