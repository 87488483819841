import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ПЕРСОНАЛА

export default {
    state() {
        return {
            // список объектов
            personnel: [],
            // время обновления кэша new Date().getTime()
            personnelCache: null,
            // время жизни кэша в мс (null - бесконечно)
            personnelCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getPersonnel(state) {
            return state.personnel;
        },
    },
    mutations: {
        // установить новый список объектов
        setPersonnel(state, payload) {
            state.personnel = payload;
            // state.personnel = [];
            // for(let i = 0; i < 250; i++) {
            //     let val = payload[i];
            //     console.log('val', val)
            //     state.personnel.push(val);
            // }
            // console.log('state.personnel', payload)
            state.personnelCache = new Date().getTime()
        },
        // добавить объект
        addPersonnel(state, payload) {
            state.personnel.push(payload)
        },
        // изменить объект
        editPersonnel(state, payload) {
            const index = state.personnel.findIndex(personnel => personnel.personnel_id == payload.personnel_id);
            if (index >= 0) {
                state.personnel[index] = payload;
            }
        },
        // удалить объект
        delPersonnel(state, payload) {
            const index = state.personnel.findIndex(personnel => personnel.personnel_id == payload.personnel_id);
            state.personnel.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchPersonnel: (context, payload) => {
            return DoGet(
                '/ref/personnel',
                'ref/setPersonnel',
                [],
                'Запрос списка персонала',
                false,
                payload && payload.force,
                context.state.personnelCache,
                context.state.personnelCacheTimeout
            )
        },

        // очищает кэш
        doClearPersonnel: (context, payload) => {

            return DoClearCache(
                'ref/setPersonnel',
                [],
                payload && payload.force,
                context.state.personnelCache,
                context.state.personnelCacheTimeout
            )
        },

        // добавление объекта
        doAddPersonnel: (context, payload) => {
            return DoPost(
                '/ref/personnel',
                payload,
                'ref/addPersonnel',
                'Добавление работника'
            )
        },

        // изменение объекта
        doEditPersonnel: (context, payload) => {
            return DoPut(
                '/ref/personnel/'+payload.personnel_id+'/'+payload.date_index_begin,
                payload,
                'ref/editPersonnel',
                'Изменение данных работника'
            )
        },

        // удаление объекта
        doDelPersonnel: (context, payload) => {
            return DoDelete(
                '/ref/personnel/'+payload.personnel_id,
                'ref/delPersonnel',
                'Удаление работника'
            )
        },

        // исключение объекта
        doDiscardPersonnel: (context, payload) => {
            return DoPut(
                '/ref/discardPersonnel/' + payload.personnel_id + '/' + payload.date_index_begin,
                null,
                'ref/editPersonnel',
                'Исключение работника'
            )
        },

        // восстановление объекта
        doRestorePersonnel: (context, payload) => {
            return DoPut(
                '/ref/restorePersonnel/' + payload.personnel_id,
                null,
                'ref/editPersonnel',
                'Восстановление работника'
            )
        }

    }
}