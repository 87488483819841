<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/tariffZoneSystems">Тарифные зональные системы</router-link></span>
        <span> / </span>
        <span>Тарифные зоны {{ tariffZoneSystemPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >
            <!-- Кнопка связь-->
            <template #leftCustomButton2>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary">
                  Связь
                </button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="tariffZoneZones">Зоны тарифных зон</dropdown-item>

                </dropdown-menu>
              </dropdown>
            </template>

          </crud-form>
        </align-panel>
        <!-- Таблица  -->
        <align-panel align="all">
          <tariff-zones-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :rows="rows"
              :selectedRow="selectedRow"
              @onRowSelect="onRowSelect"
              @onRowDblClick="onTableShow"
          ></tariff-zones-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <tariff-zone-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </tariff-zone-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить тарифную зону с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import {getTariffZoneSystem} from "@/store/MultipassHelpers";
import TariffZonesEditTable from "@/components/multipass/tariff_zones/TariffZonesEditTable";
import TariffZoneDialog from "@/components/multipass/tariff_zones/TariffZoneDialog";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    TariffZonesEditTable,
    TariffZoneDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор тарифные зональные системы
    "tariff_zone_system_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // список строк
      rows: [],
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // список зон тарифных зон
      tariff_zone_id_list: []
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // ссылка для перехода к Тарифным зонам
    tariffZoneZones() {
      return '/multipass/tariffZoneSystems/' + this.tariff_zone_system_id + '/tariffZones/tariffZoneZones';
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения диалогового окна установки статуса
    isStatusDialogVisible() {
      return (this.op === 'status' && !!this.selectedRow)
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // условие отображения подтверждения ввода в действие
    isActivateConfirmVisible() {
      return this.op === 'activate' && !!this.selectedRow;
    },

    // условие отображения подтверждения вывода из действия
    isDeactivateConfirmVisible() {
      return this.op === 'deactivate' && !!this.selectedRow;
    },

    // условие отключения кнопки - Команды
    commandDisabled() {
      return !this.setStatusExists;
    },

    // условие отображения команды Установить статус
    setStatusExists() {
      return this.selectedRow
    },

    // текущий трифная зона
    tariffZoneSystem() {
      return getTariffZoneSystem(this.tariff_zone_system_id);
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {
          tariff_zone_system_id: this.tariff_zone_system_id
        }
      } else return this.selectedRow;
    }
  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace(`/multipass/tariffZoneSystems/${this.tariff_zone_system_id}/tariffZones/add`);
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace(`/multipass/tariffZoneSystems/${this.tariff_zone_system_id}/tariffZones/edit/${this.selectedRow.tariff_zone_id}`);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace(`/multipass/tariffZoneSystems/${this.tariff_zone_system_id}/tariffZones/del/${this.selectedRow.tariff_zone_id}`);
    },

    // нажата кнопка Просмотр
    onTableShow() {
      this.$router.replace(`/multipass/tariffZoneSystems/${this.tariff_zone_system_id}/tariffZones/show/${this.selectedRow.tariff_zone_id}`);
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace(`/multipass/tariffZoneSystems/${this.tariff_zone_system_id}/tariffZones`);
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace(`/multipass/tariffZoneSystems/${this.tariff_zone_system_id}/tariffZones`);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace(`/multipass/tariffZoneSystems/${this.tariff_zone_system_id}/tariffZones`);
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('multipass/doFetchTariffZoneSystems')
        const rows = await this.$store.dispatch('multipass/doFetchTariffZones', {
          tariff_zone_system_id: this.tariff_zone_system_id
        });
        if (!rows) return;
        this.rows = rows;

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        // добавляем поля
        item.tariff_zone_system_id = Number(this.tariff_zone_system_id);
        // запрос на сервер
        const newItem = await this.$store.dispatch('multipass/doAddTariffZone', item);
        // мутируем массив
        this.selectedRow = addMutation(this.rows, 'tariff_zone_id', newItem);

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        // добавляем поля
        item.tariff_zone_system_id = Number(this.tariff_zone_system_id);
        // запрос на сервер
        const newItem = await this.$store.dispatch('multipass/doEditTariffZone', item);
        // мутируем массив
        this.selectedRow = editMutation(this.rows, 'tariff_zone_id', newItem);

      } finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        // запрос на сервер
        const oldItem = await this.$store.dispatch('multipass/doDelTariffZone', this.selectedRow)
        // мутируем массив
        this.selectedRow = delMutation(this.rows, 'tariff_zone_id', oldItem);

      } finally {
        this.isLoading = false;
      }
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData(true);
  },
};
</script>

