<template>
  <edit-table-panel :columnList="columnList" name="tbl-ref-details-org"></edit-table-panel>
</template>

<script>

export default {
  data() {
    return {
      columnList:  [
        {
          field: "__id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "org_title",
          subField: "detail",
          caption: "Название",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "org_ogrn",
          subField: "detail",
          caption: "ОГРН",
          sortType: 'string',
          show: false,
        },
        {
          field: "org_inn",
          subField: "detail",
          caption: "ИНН",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "org_kpp",
          subField: "detail",
          caption: "КПП",
          sortType: 'string',
          show: false,
        },
        {
          field: "org_legal_address",
          subField: "detail",
          caption: "Юр.адрес",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "org_actual_address",
          subField: "detail",
          caption: "Факт.адрес",
          sortType: 'string',
          isFilterable: true,
          show: false,
        },
        {
          field: "org_okpo",
          subField: "detail",
          caption: "ОКПО",
          sortType: 'string',
          show: false,
        },
        {
          field: "org_okved",
          subField: "detail",
          caption: "ОКВЭД",
          sortType: 'string',
          show: false,
        },
        {
          field: "org_phone_num",
          subField: "detail",
          caption: "Номера телефонов",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "org_email",
          subField: "detail",
          caption: "Электронная почта",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__parent_detail_title",
          caption: "Верхн. уровень",
          sortType: 'string',
        }
      ]
    }
  },
};
</script>
