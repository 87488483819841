import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ УПРАВЛЯЮЩИХ ВОЗДЕЙСТВИЙ

export default {
    state() {
        return {
            // список объектов
            managementActions: [],
            // время обновления кэша new Date().getTime()
            managementActionsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            managementActionsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getManagementActions(state) {
            return state.managementActions;
        },

    },
    mutations: {
        // установить новый список объектов
        setManagementActions(state, payload) {
            state.managementActions = payload;
            state.managementActionsCache = new Date().getTime();
        },
    },
    actions: {
        // запрос списка объектов
        doFetchManagementActions: (context, payload) => {
            return DoGet(
                '/trn/managementActions',
                'trn/setManagementActions',
                [],
                'Запрос списка управляющих воздействий',
                false,
                payload && payload.force,
                context.state.managementActionsCache,
                context.state.managementActionsCacheTimeout
            )
        },

        doFetchManagementAction: (context, payload) => {
            return DoGet(
                '/trn/managementActions/' + payload.management_action_id,
                '',
                [],
                'Запрос управляющего воздействия',
                false,
                payload && payload.force,
                context.state.managementActionsCache,
                context.state.managementActionsCacheTimeout
            )
        },

        // очищает кэш
        doClearManagementActions: (context, payload) => {
            return DoClearCache(
                'trn/setManagementActions',
                [],
                payload && payload.force,
                context.state.managementActionsCache,
                context.state.managementActionsCacheTimeout
            )
        },
    }
}