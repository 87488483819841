<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/stopstations">Автостанции</router-link></span>
        <span> / </span>
        <span>Зона автостанции {{ stopstationPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="crudButtonsExist"
              :buttonsEnabled="crudButtonsEnabled"
              :filterExists="false"
              @onEdit="onTableEdit"
              @onSave="onTableSave"
              @onCancel="onTableCancel"
              @onRefresh="onTableRefresh"
          >
          </crud-form>
        </align-panel>
        <!-- Карта  -->
        <align-panel align="all">
          <stopstation-zones-map
              :zone="zone"
              :stopstation="stopstation"
              :isEditMode="isEditMode"
              @onMapCreated="onMapCreated"
              @onZoneChanged="onZoneChanged"
          >
          </stopstation-zones-map>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import StopstationZonesMap from "@/components/multipass/stopstation_zones/StopstationZonesMap";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    StopstationZonesMap,
  },

  props: [
    // идентификатор остановочного пункта
    "stopstation_id",
  ],

  data() {
    return {
      // карта
      leaflet: null,
      // отображать спиннер
      isLoading: false,
      // режим редактирования
      isEditMode: false,
      // зона автостанции
      zone: null,
      // исходная зона
      originZone: null,
      // признак изменения
      isModified: false,
      // автостанция
      stopstation: {},
    }
  },

  computed: {
      // вимдимость crud-кнопок
      crudButtonsExist() {
        return {
          refresh: true,
          edit: !this.isEditMode,
          save: this.isEditMode,
          cancel: this.isEditMode,
        }
      },

      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            refresh: true,
            edit: true,
            save: this.isModified,
            cancel: true,
          }
      },
  },
  methods: {

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // при нажатии кнопки Изменить
    onTableEdit() {
      // устанавливаем режим редактирования
      this.isEditMode = true;
      // запоминаем исходную зону
      this.originZone = {...this.zone};
      // сбрасываем признак модификации
      this.isModified = false;
    },

    // вызывается при нажатии конпки сохранить
    onTableSave() {
      // зона должны быть как минимум из одного элемента
      if (this.zone.elements.length === 0) {
        this.$store.dispatch('doAddMessage', {
          caption: 'Установка зоны автостанции',
          message: 'Отсуствуют элементы зоны',
          type: 'error'
        },{root: true});

        return;
      }
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // сохраняем данные на сервер
      this.saveItem();
    },

    // вызывается при нажатии конопки отменить
    onTableCancel() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // восстанавливаем данные
      this.restoreItem();
    },

    // вызывается при нажатии конопки обновить
    onTableRefresh() {
      // сбрасываем режим редактирования
      this.isEditMode = false;
      // перезапрашиваются данные
      this.refreshData();
    },

    // вызывается при изменении зоны
    onZoneChanged(zone) {
      this.zone = zone;
      // устанавливаем признак модификации
      this.isModified = true
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading='fetch';
      try {

        // запрашиваем автостанцию
        const stopstation = await this.$store.dispatch('multipass/doFetchStopstation', {
          stopstation_id: this.stopstation_id
        }); if (!stopstation) return;
        this.stopstation = stopstation;

        // запрашиваем зону автостанции
        const zone = await this.$store.dispatch('multipass/doFetchStopstationZone', {
          stopstation_id: this.stopstation_id,
        }); if (!zone) return;
        this.zone = zone;

        // позиционируем карту
        if (this.leaflet && stopstation && stopstation.latitude && stopstation.longitude) {
          const minStopZoom = this.$store.getters['settings/getMinStopZoom'];
          const zoom = this.leaflet.getZoom();
          if (zoom >= minStopZoom)
            this.leaflet.setView([stopstation.latitude, stopstation.longitude], zoom)
          else
            this.leaflet.setView([stopstation.latitude, stopstation.longitude], minStopZoom)
        }
      }
      finally {
        this.isLoading=false;
      }
    },

    // восстанавливаем объект
    async restoreItem() {
      this.zone = this.originZone
    },

    // сохраняем объект
    async saveItem() {
      this.isLoading = true;
      try {

        const zone = await this.$store.dispatch('multipass/doEditStopstationZone', {
          stopstation_id: this.stopstation_id,
          zone: this.zone
        });
        if (zone) this.zone = zone
          else this.zone = null;

      }
      finally {
        this.isLoading = false;
      }
    }
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
