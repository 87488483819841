<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">

      <page-header>
        <span><router-link to="/rmt">Расписания</router-link></span>
        <span> / </span>
        <span><router-link to="/rmt/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span><router-link :to=routeVariantsLink>Варианты маршрута {{ routePageHeader }}</router-link></span>
        <span> / </span>
        <span><router-link :to=scheduleVariantsLink>Варианты расписаний {{ routeVariantPageHeader }}</router-link></span>
        <span> / </span>
        <span>Наборы графиков {{ scheduleVariantPageHeader }}</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <div class="container position-relative h-100">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">
            <crud-form
                :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
                :buttonsEnabled="crudButtonsEnabled"
                @onAdd="onTableAdd"
                @onEdit="onTableEdit"
                @onDelete="onTableDelete"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            >

              <!-- Кнопка Отчеты-->
              <template #leftCustomButton>
                <dropdown>
                  <button-toggler target-ref="collapse3" class="btn dropdown-toggle btn-primary"
                                  :disabled="linkDisabled">
                    Отчеты
                  </button-toggler>
                  <dropdown-menu ref="collapse3">
                    <dropdown-item @click="onReport0001">Плановые показатели работы</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>

              <!-- Кнопка Команда-->
              <template #leftCustomButton2>
                <dropdown>
                  <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                  :disabled="commandDisabled">
                    Команда
                  </button-toggler>
                  <dropdown-menu ref="collapse">
                    <dropdown-item @click="onMarkScheduleGraphSet" v-if="activateExists">Поставить отметку</dropdown-item>
                    <dropdown-item @click="onUnmarkScheduleGraphSet" v-if="deactivateExists">Снять отметку</dropdown-item>
                    <dropdown-divider v-if="activateExists || deactivateExists" ></dropdown-divider>
                    <dropdown-item @click="onCopy" v-if="selectedRow">Копировать</dropdown-item>
                    <dropdown-item @click="onPaste" v-if="deactivatePaste">Вставить</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>

              <!-- Кнопка связь-->
              <template #leftCustomButton3>
                <dropdown>
                  <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary"
                                  :disabled="linkDisabled">
                    Связь
                  </button-toggler>
                  <dropdown-menu ref="collapse2">
                    <dropdown-item router-link :to="scheduleGraphsLink">Графики движения</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>

            </crud-form>

          </align-panel>

          <!-- Таблица  -->
          <align-panel align="all">
            <schedule-graph-sets-edit-table
                :filterText="filterText"
                :isLoading="isLoading"
                :selectedRow="selectedRow"
                :scheduleGraphSets="scheduleGraphSets"
                @onRowSelect="onRowSelect"
                @onRowDblClick="onTableShow"
            ></schedule-graph-sets-edit-table>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <schedule-graph-set-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </schedule-graph-set-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить набор графиков с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Подтверждение отметки набора графиков -->
  <confirm-message-box
      v-if="isActivateConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onMarkDialog(true)"
  >Вы уверены, что хотите отметить набор графиков с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Подтверждение снятия отметки с набора графиков  -->
  <confirm-message-box
      v-if="isDeactivateConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onMarkDialog(false)"
  >Вы уверены, что хотите снять отметку с набора графиков с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import ScheduleGraphSetDialog from "@/components/rmt/schedule_graph_sets/ScheduleGraphSetDialog";
import ScheduleGraphSetsEditTable from "@/components/rmt/schedule_graph_sets/ScheduleGraphSetsEditTable";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";
import store from "@/store/Store";

export default {
  mixins: [PageMixin],

  components: {
    ScheduleGraphSetsEditTable,
    ScheduleGraphSetDialog,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
    // идентификатор варианта расписания
    "schedule_variant_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false,
      // маршрут
      route: {},
      // вариант маршрута
      routeVariant: {},
      // вариант расписания
      scheduleVariant: {},
      // наборы графиков
      scheduleGraphSets: [],
      // тип объекта для определения активности кнопки вставить
      objType: 'ScheduleGraphSets'
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add' || this.op === 'paste') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // условие отображения подтверждения отметки
    isActivateConfirmVisible() {
      return this.op === 'activate' && !!this.selectedRow;
    },

    // условие отображения подтверждения снятия ортметки
    isDeactivateConfirmVisible() {
      return this.op === 'deactivate' && !!this.selectedRow;
    },

    // условие отключения кнопки - Команды
    commandDisabled() {
      return (!this.selectedRow && !this.deactivatePaste);
    },

    // условие отображения меню отметки
    activateExists() {
      return this.selectedRow && !this.selectedRow.is_working
    },

    // условие отображения меню снять отметку
    deactivateExists() {
      return this.selectedRow && !!this.selectedRow.is_working
    },

    // условие отображения кнопки "вставить"
    // если тип объекта в буфере обмена совпадает с типом объекта на текущей странице
    deactivatePaste() {
      return (store.getters.getType == this.objType);
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {}
      } else return this.selectedRow;
    },

    // ссылка для перехода к вариантам маршрутов
    routeVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants`
    },

    // ссылка для перехода к вариантам расписаний
    scheduleVariantsLink() {
      return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants`;
    },

    // ссылка для перехода к графикам
    scheduleGraphsLink() {
      if (this.selectedRow) return `/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.selectedRow.schedule_variant_id}/scheduleGraphSets/${this.selectedRow.schedule_graph_set_id}/scheduleGraphs`;
      else return '';
    },

  },
  methods: {

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      } else if (this.op === 'paste') {
        this.pasteItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/`);
    },

    // подтверждено изменение в диалоге
    onConfirmDelete(item) {
      this.delItem(item)
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/`);
    },

    // подтверждено ввод в действие или вывод
    async onMarkDialog(item) {
      if (item === true) {
        // отметка набора графиков
        await this.markScheduleGraphSet()
      } else if (item === false) {
        // снятие отметки с набора графиков
        await this.unmarkScheduleGraphSet()
      }
      // запрос графика
      await this.getScheduleGrapSets();
      // возвращаемся на страницу
      await this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/`);
    },

    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/add`);
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/edit/${this.selectedRow.schedule_graph_set_id}`);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/del/${this.selectedRow.schedule_graph_set_id}`);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // выполнено двойное нажатие строки в таблице (открываем просмотр)
    onTableShow() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/show/${this.selectedRow.schedule_graph_set_id}`);
    },

    // команда отметить набор графиков
    onMarkScheduleGraphSet() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/activate/${this.selectedRow.schedule_graph_set_id}`);
    },

    // команда снять отметитку с набора графиков
    onUnmarkScheduleGraphSet() {
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/deactivate/${this.selectedRow.schedule_graph_set_id}`);
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets`);
    },

    // нажата кнопка "копировать"
    onCopy(){
      // сохраняем type и выбранную строку в store
      store.dispatch('doSetClipboard', {
        type: 'ScheduleGraphSets',
        object: this.selectedRow
      });

      // выводим сообщение о том, что данные сохранены в буфер обмена
      store.dispatch('doAddMessage', {
        caption: 'Набор графиков',
        message: 'Данные сохранены в буфер обмена',
        type: 'ok'
      }, {root: true});
    },

    // нажата кнопка "вставить"
    onPaste(){
      // получаем из стора сохраненный объект
      this.selectedRow = store.getters.getObject;
      // открываем диалог копирования (редактирования)
      this.$router.replace(`/rmt/routes/${this.route_id}/routeVariants/${this.route_variant_id}/scheduleVariants/${this.schedule_variant_id}/scheduleGraphSets/paste`);
    },

    // нажата кнопка отчета "Плановые показатели работы"
    onReport0001(){
      // вносим временный отчет
      this.$store.commit('reporter/addReport', {report_id: 1});
      // отправляем на сервер запрос на формирование отчета
      this.$store.dispatch('rmt/doMakeReport0001', {schedule_graph_set_id: this.selectedRow.schedule_graph_set_id});
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {

        // запрос маршрута
        const route = await this.$store.dispatch('multipass/doFetchRoute', {
          route_id: this.route_id,
        }); if (!route) return;
        this.route = route;

        // запрос варианта маршрута
        const routeVariant = await this.$store.dispatch('multipass/doFetchRouteVariant', {
          route_variant_id: this.route_variant_id,
        }); if (!routeVariant) return;
        this.routeVariant = routeVariant;

        // запрос варианта расписания
        const scheduleVariant = await this.$store.dispatch('rmt/doFetchScheduleVariant', {
          schedule_variant_id: this.schedule_variant_id,
        }); if (!scheduleVariant) return;
        this.scheduleVariant = scheduleVariant;

        // запрос графика
        await this.getScheduleGrapSets();

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // запрос графика
    async getScheduleGrapSets() {
      const scheduleGraphSets = await this.$store.dispatch('rmt/doFetchScheduleGraphSets', {
        schedule_variant_id: this.schedule_variant_id,
      });
      if (!scheduleGraphSets) return;
      this.scheduleGraphSets = scheduleGraphSets;
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        item['schedule_variant_id'] = Number(this.schedule_variant_id);
        const schGrSet = await this.$store.dispatch('rmt/doAddScheduleGraphSet', item);
        // мутируем массив
        this.selectedRow = addMutation(this.scheduleGraphSets, 'schedule_graph_set_id', schGrSet);

      } finally {
        this.isLoading = false;
      }
    },

    // добавляем объект при вставке
    async pasteItem(item) {
      this.isLoading = true;
      try {
        // сетаем текущий schedule_variant_id
        item['schedule_variant_id'] = Number(this.schedule_variant_id);
        // запрашиваем сервер
        const copiedItem = await this.$store.dispatch('rmt/doCopyScheduleGraphSet', item);
        // мутируем массив
        this.selectedRow = addMutation(this.scheduleGraphSets, 'schedule_graph_set_id', copiedItem);
      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        item['schedule_variant_id'] = Number(this.schedule_variant_id);
        const schGrSet = await this.$store.dispatch('rmt/doEditScheduleGraphSet', item);
        // мутируем массив
        this.selectedRow = editMutation(this.scheduleGraphSets, 'schedule_graph_set_id', schGrSet);

      } finally {
        this.isLoading = false;
      }
    },

    // Отмечаем набор графиков
    async markScheduleGraphSet() {
      this.isLoading = true;
      try {

        const schGrSet = await this.$store.dispatch('rmt/doMarkScheduleGraphSet', {
          schedule_graph_set_id: this.selectedRow.schedule_graph_set_id
        });
        // мутируем массив
        this.selectedRow = editMutation(this.scheduleGraphSets, 'schedule_graph_set_id', schGrSet);

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // Снимаем отметку с набора графиков
    async unmarkScheduleGraphSet() {
      this.isLoading = true;
      try {

        const schGrSet = await this.$store.dispatch('rmt/doUnmarkScheduleGraphSet', {
          schedule_graph_set_id: this.selectedRow.schedule_graph_set_id
        });
        // мутируем массив
        this.selectedRow = editMutation(this.scheduleGraphSets, 'schedule_graph_set_id', schGrSet);

      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        const schGrSet = await this.$store.dispatch('rmt/doDelScheduleGraphSet', this.selectedRow)
        this.selectedRow = delMutation(this.scheduleGraphSets, 'schedule_graph_set_id', schGrSet);

      } finally {
        this.isLoading = false;
      }
    }
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
