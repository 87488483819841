<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span>Композитные типы рейсов</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <div class="container position-relative h-100">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">
            <crud-form
                :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
                :buttonsEnabled="crudButtonsEnabled"
                @onAdd="onTableAdd"
                @onEdit="onTableEdit"
                @onDelete="onTableDelete"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            >

              <!-- Кнопка связь-->
              <template #leftCustomButton>
                <dropdown>
                  <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">
                    Связь
                  </button-toggler>
                  <dropdown-menu ref="collapse2">
                    <dropdown-item router-link :to="tripTypeCompositeCardsLink">Карточки композитов типов рейсов</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>

            </crud-form>
          </align-panel>
          <!-- Таблица  -->
          <align-panel align="all">
            <trip-type-composites-edit-table
                :filterText="filterText"
                :isLoading="isLoading"
                :selectedRow="selectedRow"
                @onRowSelect="onRowSelect"
            ></trip-type-composites-edit-table>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <trip-type-composite-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </trip-type-composite-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить композит типов рейсов с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import TripTypeCompositesEditTable from "@/components/asmpp/trip_type_composites/TripTypeCompositesEditTable";
import TripTypeCompositeDialog from "@/components/asmpp/trip_type_composites/TripTypeCompositeDialog";

export default {
  components: {TripTypeCompositeDialog, TripTypeCompositesEditTable,},
  mixins: [PageMixin],
  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },
    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // запись для подстановки в диалог
    dialogRow() {
      if (this.op === 'add') {
        return {}
      } else return this.selectedRow;
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    },

    // ссылка для перехода к вариантам расписаний
    tripTypeCompositeCardsLink() {
      if (this.selectedRow) return '/asmpp/tripTypeComposites/' + this.selectedRow.trip_type_composite_id + '/tripTypeCompositeCards'
      else return '';
    },

  },

  methods: {

    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/asmpp/tripTypeComposites/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/asmpp/tripTypeComposites/edit/" + this.selectedRow.trip_type_composite_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/asmpp/tripTypeComposites/del/" + this.selectedRow.trip_type_composite_id);
    },
    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/asmpp/tripTypeComposites");
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/asmpp/tripTypeComposites");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/asmpp/tripTypeComposites");
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('asmpp/doAddTripTypeComposite', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('asmpp/doEditTripTypeComposite', item)
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('asmpp/doDelTripTypeComposite', this.selectedRow);

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('asmpp/doFetchTripTypeComposites', {force})

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

