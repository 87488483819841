<template>
  <div class="card h-100 vehicle-button-panel">
    <div class="card-header p-1">
      <strong @mousedown.prevent="onMouseDown" @mouseup.prevent="onMouseUp" @contextmenu.prevent>
        <slot name="header"></slot>
      </strong>
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "VehicleButtonPanel",
  emits: [
      // мышь нажата
      'onHeaderMouseDown',
      // мышь отпущена
      'onHeaderMouseUp',
  ],
  methods: {
    onMouseDown(e) {
      this.$emit('onHeaderMouseDown', e)
    },
    onMouseUp(e) {
      this.$emit('onHeaderMouseUp', e)
    }
  }
}

</script>

<style scoped>
  .vehicle-button-panel .card-header strong {
    cursor: pointer;
  }

  .vehicle-button-panel .card-body {
    overflow-y: auto !important;
    overflow-x: hidden;
    padding: 0.1rem;
  }
</style>
