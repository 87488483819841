<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-rmt-holidays"> </edit-table-panel>
</template>

<script>


import {getRoute, getRouteGroup} from "@/store/MultipassHelpers";
import {secondsToDate} from "@/lib";

export default {
  props: ['isLoading'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "holiday_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "date",
          caption: "Дата",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "route",
          caption: "Маршрут",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "route_group",
          caption: "Группа маршрутов",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "__week_day",
          caption: "День нед.",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "note",
          caption: "Примечание",
          sortType: 'string',
          isFilterable: true,
        },
      ],
    };
  },
  computed: {

    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }

      return this.$store.getters["rmt/getHolidays"].map(holiday => {
        holiday.__id = holiday.holiday_id;

        let route = getRoute(holiday.route_id)
        if(route)
          holiday.route = route.route_num;

        let route_group = getRouteGroup(holiday.route_group_id)
        if(route_group)
          holiday.route_group = route_group.route_group_name;

        holiday.date = secondsToDate(holiday.date_index*24*60*60)

        if (holiday.week_day_num == 1) {
          holiday.__week_day = "Пн."
        } else if (holiday.week_day_num == 2) {
          holiday.__week_day = "Вт."
        } else if (holiday.week_day_num == 3) {
          holiday.__week_day = "Ср."
        } else if (holiday.week_day_num == 4) {
          holiday.__week_day = "Чт."
        } else if (holiday.week_day_num == 5) {
          holiday.__week_day = "Пт."
        } else if (holiday.week_day_num == 6) {
          holiday.__week_day = "Сб."
        } else if (holiday.week_day_num == 7) {
          holiday.__week_day = "Вс."
        }

        return holiday;
      });
    },
  }
};
</script>
