<template>
  <div>
    <!-- Форма авторизации -->
    <auth-form @onEnter="onEnter"></auth-form>
    <!-- Спинер -->
    <loading v-if="isLoading"></loading>
  </div>
</template>

<script>
import AuthForm from '../../components/main/AuthForm';
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixin: [PageMixin],

  components: {
    AuthForm
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async onEnter(data) {
      this.isLoading = true;
      try {

        await this.$store.dispatch("doLogin", data)

      }
      finally {
        this.isLoading = false;
      }
    }
  },
};
</script>
