<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-lg modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white" style="border: 0">
          <modal-title>{{ dialogTitle }}</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>

        <modal-body>
          <form>
            <div class="form-group row" style="margin: 0 0 20px;">
              <div class="col">
                <label for="reasonId">Причина</label>
                <exp-command-reasons-select
                    id="reasonId"
                    class="form-control"
                    v-model="reasonId"
                    :exp_command_type_id="commandType"
                    style="margin: 0"
                    ref="reasonId"
                    required
                    error="Выберите причину"
                />
              </div>
              <div class="col">
                <div v-if="!length_hidden.includes(selected_exp_command_id)">

                  <div class="form-group row">
                    <div class="col-0">
                      <input-checkbox
                          v-if="!length_check_hidden.includes(selected_exp_command_id)"
                          id="is_length_active"
                          style="margin-top: 39px; margin-left: 10px; "
                          v-model="is_length_active"
                      >
                      </input-checkbox>
                    </div>
                    <div class="col">
                      <label for="is_length_active">Факт. пробег(км)</label>
                      <input-float
                          id="fact_length"
                          class="form-control w-75"
                          v-model="fact_length"
                          :readonly="!is_length_active"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row" style="margin: 0">
              <div class="col">
                <label for="comment">Комментарий</label>
                <input-textarea
                    v-model="comment"
                    class="form-control"
                    style="width: 100%; height: 200px; min-height: 200px"
                >
                </input-textarea>
              </div>
            </div>
          </form>
        </modal-body>

        <modal-footer>
          <button @click="onSubmit" class="btn btn-primary" :disabled="isOkButtonDisabled">Ок</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import ExpCommandReasonsSelect from "@/components/kvr/exp_command_reasons/ExpCommandReasonsSelect";
import InputCheckbox from "@/components/ui/custom/InputCheckbox";
import InputTextarea from "@/components/ui/custom/InputTextarea";
import {getCommand} from "@/store/KvrExpertHelpers";
import InputFloat from "@/components/ui/custom/InputFloat";

export default {
  components: {InputFloat, InputTextarea, InputCheckbox, ExpCommandReasonsSelect},
  emits: ['onSubmit', 'onCancel'],
  props: ['selected_exp_command_id', 'selectedRow'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      // выбранная причина
      reasonId: null,
      // активно ли редактирование пробега
      is_length_active: (this.selected_exp_command_id == 8 || this.selected_exp_command_id == 9) ? true : false,
      // фактический пробег
      fact_length: this.selectedRow ? (this.selectedRow.length_fact / 1000) : 0,
      // комментарий
      comment: '',
      // массив id комманд, при которых скрыт факт пробег
      length_hidden: [5, 6, 7],
      // скрыта галочка видимости пробега
      length_check_hidden: [8,9],
    };
  },

  computed: {

    isOkButtonDisabled() {
      if (this.reasonId == null || (this.fact_length == null && this.is_length_active == true) || (this.fact_length < 0 && this.is_length_active == true)) {
        return true;
      }
      return false;
    },

    dialogTitle() {
      let command = getCommand(this.selected_exp_command_id);
      if (command)
        return command.long_name;
      return '';
    },

    commandType() {
     let command = getCommand(this.selected_exp_command_id);
      if (command)
        return command.exp_command_type_id;
      return null;
    }


  },

  methods: {

    // нажата кнопка "Ок"
    onSubmit() {
      // определяем пробег
      let length = null;
      // если редактирование доступно
      if (!this.length_hidden.includes(this.selected_exp_command_id)) {
        if (this.is_length_active && this.fact_length != null) {
          // переводим пробег в метры
          length = this.fact_length * 1000;
        }
      }

      // формируем "тело" запроса
      let selectedItem = {
        exp_command_id: this.selected_exp_command_id,
        exp_command_reason_id : this.reasonId,
        exp_command_comment: this.comment,
        exp_command_length_fact: length == null ? null : Number(length),
      }

      this.$emit('onSubmit', selectedItem)
    },

  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}

.table-container {
  height: 500px;
}
</style>