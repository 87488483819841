<template>

  <accordion mode="single">

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group1">
          Технолог
        </button-target>
      </card-header>
    </card>
    <collapse ref="group1" active>
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/trn"
          >Подачи
          </left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/reserved"
          >Резервные кругорейсы
          </left-menu-item>
        </li>
      </ul>
    </collapse>

    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group2">
          Нарядчик
        </button-target>
      </card-header>
    </card>
    <collapse ref="group2">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/trn/masterOrders"
          >Мастер наряд
          </left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/trn/baseOrders"
          >Наряд
          </left-menu-item>
        </li>
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group3">
          Диспетчер
        </button-target>
      </card-header>
    </card>
    <collapse ref="group3" >
      <ul class="p-0 m-0">

        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/trn/dispatching"
          >Диспетчер
          </left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/trn/map"
          >Карта
          </left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/trn/ovals"
          >Овалы
          </left-menu-item>
        </li>
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/trn/reports"
          >Отчеты
          </left-menu-item>
        </li>
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group4">
          Аналитик
        </button-target>
      </card-header>
    </card>
    <collapse ref="group4">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/trn/trnDayStates"
          >Отчеты
          </left-menu-item>
        </li>
      </ul>
    </collapse>


    <card>
      <card-header class="m-0 p-0 mb-1 bg-info">
        <button-target class="btn btn-block text-left text-white" target-method="select" target-ref="group5">
          Администрирование
        </button-target>
      </card-header>
    </card>
    <collapse ref="group5">
      <ul class="p-0 m-0">
        <li>
          <left-menu-item
              icon-class="fas fa-arrow-alt-circle-right"
              to="/trn/trnDayStates"
          >Данные
          </left-menu-item>
        </li>
      </ul>
    </collapse>

  </accordion>
</template>
