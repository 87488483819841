import GeneralLink from "@/components/ui/bootstrap/general/GeneralLink";

import NavbarMenu from './navbar/NavbarMenu';
import NavbarBrand from './navbar/NavbarBrand';
import NavbarCollapse from './navbar/NavbarCollapse';
import NavbarNav from './navbar/NavbarNav';
import NavbarToggler from './navbar/NavbarToggler';

import NavMenu from './nav/NavMenu.vue';
import NavLink from './nav/NavLink.vue';
import NavItem from './nav/NavItem.vue';
import NavTogglerLink from './nav/NavTogglerLink.vue';
import NavDropdownItem from './nav/NavDropdownItem.vue';
import TabContent from "@/components/ui/bootstrap/nav/TabContent";
import TabPane from "@/components/ui/bootstrap/nav/TabPane";

import Dropdown from './dropdown/Dropdown.vue';
import DropdownMenu from './dropdown/DropdownMenu.vue';
import DropdownItem from './dropdown/DropdownItem.vue';
import DropdownDivider from './dropdown/DropdownDivider.vue';

import Modal from './modal/Modal.vue';
import ModalDialog from './modal/ModalDialog.vue';
import ModalContent from './modal/ModalContent.vue';
import ModalHeader from './modal/ModalHeader.vue';
import ModalTitle from './modal/ModalTitle.vue';
import ModalBody from './modal/ModalBody.vue';
import ModalFooter from './modal/ModalFooter.vue';

import Toast from './toast/Toast.vue';
import ToastHeader from './toast/ToastHeader.vue';
import ToastBody from './toast/ToastBody.vue';

import Breadcrumb from './breadcrumb/Breadcrumb.vue';
import BreadcrumbItem from './breadcrumb/BreadcrumbItem.vue';

import Card from './card/Card.vue';
import CardBody from './card/CardBody.vue';
import CardFooter from './card/CardFooter.vue';
import CardHeader from './card/CardHeader.vue';
import CardText from './card/CardText.vue';
import CardTitle from './card/CardTitle.vue';

import ButtonToggler from './button/ButtonToggler.vue';
import ButtonTarget from './button/ButtonTarget.vue';
import ButtonClose from './button/ButtonClose.vue';

import Alert from './alert/Alert';

import Collapse from './collapse/Collapse';
import Accordion from './collapse/Accordion';

export default function(app) {
    app.component('general-link', GeneralLink);

    app.component('navbar-menu', NavbarMenu);
    app.component('navbar-brand', NavbarBrand);
    app.component('navbar-collapse', NavbarCollapse);
    app.component('navbar-nav', NavbarNav);
    app.component('navbar-toggler', NavbarToggler);

    app.component('nav-menu', NavMenu);
    app.component('nav-link', NavLink);
    app.component('nav-item', NavItem);
    app.component('nav-toggler-link', NavTogglerLink);
    app.component('nav-dropdown-item', NavDropdownItem);
    app.component('tab-content', TabContent);
    app.component('tab-pane', TabPane);

    app.component('dropdown', Dropdown);
    app.component('dropdown-menu', DropdownMenu);
    app.component('dropdown-item', DropdownItem);
    app.component('dropdown-divider', DropdownDivider);

    app.component('modal', Modal);
    app.component('modal-dialog', ModalDialog);    
    app.component('modal-content', ModalContent);
    app.component('modal-header', ModalHeader);
    app.component('modal-title', ModalTitle);
    app.component('modal-body', ModalBody);
    app.component('modal-footer', ModalFooter);
    
    app.component('toast', Toast);
    app.component('toast-header', ToastHeader);
    app.component('toast-body', ToastBody);
    
    app.component('breadcrumb', Breadcrumb);
    app.component('breadcrumb-item', BreadcrumbItem);
    
    app.component('card', Card);
    app.component('card-body', CardBody);
    app.component('card-footer', CardFooter);
    app.component('card-header', CardHeader);
    app.component('card-text', CardText);
    app.component('card-title', CardTitle);

    app.component('button-target', ButtonTarget);
    app.component('button-toggler', ButtonToggler);
    app.component('button-close', ButtonClose);

    app.component('alert', Alert);

    app.component('accordion', Accordion);
    app.component('collapse', Collapse);
}
