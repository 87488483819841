import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ СТАТУСОВ МАРШРУТОВ

export default {
    state() {
        return {
            // список объектов
            routeStatuses: [],
            // время обновления кэша new Date().getTime()
            routeStatusesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            routeStatusesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRouteStatuses(state) {
            return state.routeStatuses;
        },
    },
    mutations: {
        // установить новый список объектов
        setRouteStatuses(state, payload) {
            state.routeStatuses = payload;
            state.routeStatusesCache = new Date().getTime()
        },
    },
    actions: {
        // запрос список объектов
        doFetchRouteStatuses: (context, payload) => {
            return DoGet(
                '/multipass/routeStatuses',
                'multipass/setRouteStatuses',
                [],
                'Запрос статусов маршрутов',
                false,
                payload && payload.force,
                context.state.routeStatusesCache,
                context.state.routeStatusesCacheTimeout
            )
        },

        // очищает кэш
        doClearRouteStatuses: (context, payload) => {
            return DoClearCache(
                'multipass/setRouteStatuses',
                [],
                payload && payload.force,
                context.state.routeStatusesCache,
                context.state.routeStatusesCacheTimeout
            )
        },
    }
}