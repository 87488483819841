import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";
import {getPasswordHash} from "@/lib";

// ХРАНИЛИЩЕ ПОЛЬЗОВАТЕЛЕЙ

export default {
    state() {
        return {
            // пользователи
            users: [],
            // время обновления кэша new Date().getTime()
            usersCache: null,
            // время жизни кэша в мс (null - бесконечно)
            usersCacheTimeout: null
        }
    },
    getters: {
        // вернуть список пользователей
        getUsers(state) {
            return state.users;
        },
    },
    mutations: {
        // установить новый список пользователей
        setUsers(state, payload) {
            state.users = payload;
            state.usersCache = new Date().getTime()
        },
        // добавить пользователя
        addUser(state, payload) {
            state.users.push(payload)
        },
        // изменить пользователя
        editUser(state, payload) {
            const index = state.users.findIndex(us => us.uid == payload.uid);
            if (index >= 0) {
                state.users[index] = payload;
            }
        },
        // удалить пользователя
        delUser(state, payload) {
            const index = state.users.findIndex(us => us.uid == payload.uid);
            state.users.splice(index, 1);
        },
    },
    actions: {
        // запрос списка пользователей
        doFetchUsers: (context, payload) => {
            return DoGet(
                '/auth/users',
                'auth/setUsers',
                [],
                'Запрос пользователей',
                false,
                payload && payload.force,
                context.state.usersCache,
                context.state.usersCacheTimeout
            )
        },

        // очищает кэш
        doClearUsers: (context, payload) => {
            return DoClearCache(
                'auth/setUsers',
                [],
                payload && payload.force,
                context.state.usersCache,
                context.state.usersCacheTimeout
            )
        },

        // добавление пользователя
        doAddUser: (context, payload) => {
            return DoPost(
                '/auth/users',
                payload,
                'auth/addUser',
                'Добавление пользователя'
            )
        },

        // изменение пользователя
        doEditUser: (context, payload) => {
            return DoPut(
                '/auth/users/'+payload.uid,
                payload,
                'auth/editUser',
                'Изменение данных пользователя'
            )
        },

        // удаление пользователя
        doDelUser: (context, payload) => {
            return DoDelete(
                '/auth/users/'+payload.uid,
                'auth/delUser',
                'Удаление пользователя'
            )
        },

        // блокировка пользователя
        doBlockUser: (context, payload) => {
            return DoPut(
                '/auth/blockUser/'+payload.uid,
                payload,
                'auth/editUser',
                'Блокировка пользователя'
            )
        },

        // разблокировка пользователя
        doUnblockUser: (context, payload) => {
            return DoPut(
                '/auth/unblockUser/'+payload.uid,
                payload,
                'auth/editUser',
                'Разблокировка пользователя'
            )
        },

        // установка пароля
        doResetUserPassword:(context, payload) => {
            return DoPut(
                '/auth/resetUserPassword/'+payload.uid,
                {
                    new_password: getPasswordHash(payload.login,payload.new_password)
                },
                'auth/editUser',
                'Установка пароля пользователя'
            )
        },

        // изменение пароля самим пользователем
        doChangeUserPassword:(context, payload) => {
            const login = context.rootGetters.getLogin;

            return DoPut(
                '/auth/changeUserPassword',
                {
                    old_password: getPasswordHash(login,payload.old_password),
                    new_password: getPasswordHash(login,payload.new_password)
                },
                null,
                'Изменение пароля пользователя'
            )
        }
    }
}