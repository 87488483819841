<template>
  <edit-table-panel :columnList="columnList" :disableSettings="true" :rowList="rowList" :rowsPerPage="100000"
                    name="tbl-kvr-vehicle-result-graphs"></edit-table-panel>
</template>

<script>

import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getTransportClass} from "@/store/RefHelpers";

export default {
  components: {EditTablePanel},
  props: ['rows'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: '__id',
          caption: 'График',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'plan_class_id',
          caption: 'План. Класс ТС',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'fact_class_id',
          caption: 'Факт. Класс ТС',
          sortType: 'String',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          }
        },
        {
          field: 'fact_percent',
          caption: '% выполнения',
          sortType: 'number',
          isSortable: false,
          isFilterable: true,
          show: true,
          headerStyle: {
            'padding-top': '0',
            'padding-bottom': '0',
            'padding-right': '0',
          },
          displayFn(row) {
            if (row.fact_percent) {
              return Math.trunc(row.fact_percent * 1000)/10;
              // return Number(row.fact_percent * 100).toFixed(1);
            } else if (row.fact_percent == 0) {
              return 0;
            }
            return ''
          },
        },
      ],
    }
  },

  computed: {

    rowList() {
      return this.rows.map(resultTrip => {
        resultTrip.__id = resultTrip.plan_graph;
        resultTrip.fact_class_id = getTransportClass(resultTrip.fact_transport_class_id).short_name;
        resultTrip.plan_class_id = getTransportClass(resultTrip.plan_transport_class_id).short_name;
        resultTrip.__cellStyle = {
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '6px',
          paddingRight: '6px'
        };

        if (resultTrip.is_done == true && resultTrip.is_class == true) {
          resultTrip.__rowStyle = {
            backgroundColor: '#a4ff9c'
          }
        } else if (resultTrip.is_done == true && resultTrip.is_class == false) {
          resultTrip.__rowStyle = {
            backgroundColor: '#daffd7'
          }
        } else {
          resultTrip.__rowStyle = {
            backgroundColor: '#f8f19b'
          }
        }

        return resultTrip;
      });
    }

  }
}
</script>

<style scoped>

</style>