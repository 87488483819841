<template>

  <align-container>
    <!-- Хлебные крошки              -->
    <align-panel align="top">

      <page-header>
        <span><router-link to="/asmpp">Пассажиропоток</router-link></span>
        <span> / </span>
        <span><router-link to="/asmpp/routes">Список маршрутов</router-link></span>
        <span> / </span>
        <span @click="passengerTrafficFactTripByHoursLink" style="cursor: pointer"> Маршрутный пассажиропоток {{
            routesWithDatePageHeader
          }}</span>
        <span> / </span>
        <span>Остановочный пассажиропоток</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{ refresh: true}"
              :buttonsEnabled="crudButtonsEnabled"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
          >

            <!--            &lt;!&ndash; Кнопка связь  &ndash;&gt;-->
            <!--            <template #leftCustomButton>-->
            <!--              <dropdown>-->
            <!--                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">-->
            <!--                  Аналитические формы-->
            <!--                </button-toggler>-->
            <!--                <dropdown-menu ref="collapse2">-->
            <!--&lt;!&ndash;                  <dropdown-item router-link :to="tripTypeCompositeCardsLink">Карточки композитов типов рейсов</dropdown-item>&ndash;&gt;-->
            <!--                </dropdown-menu>-->
            <!--              </dropdown>-->
            <!--            </template>-->

          </crud-form>
        </align-panel>

        <!-- Таблица  -->
        <align-panel align="all">
          <fact-trip-card-exts-edit-table
              :filterText="filterText"
              :isLoading="isLoading"
              :selectedRow="selectedRow"
              :rowArr="rows"
              :tripTypes="tripTypes"
              @onRowSelect="onRowSelect"
              @setLoading="setLoading"
          ></fact-trip-card-exts-edit-table>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>


import PageMixin from "@/pages/share/PageMixin";
import FactTripCardExtsEditTable from "@/components/asmpp/fact_trip_card_exts/FactTripCardExtsEditTable";


export default {
  components: {FactTripCardExtsEditTable},
  mixins: [PageMixin],

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрутов
    "routes_id",
    // дата начала периода
    "date_index_begin",
    // дата конца периода
    "date_index_end",
    // идентификатор рейса
    "fact_trip_ext_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер      
      isLoading: false,
      // маршруты
      routes: [],
      // тип рейса
      tripTypes: [],
      // список рейсов
      rows: [],

    }
  },

  computed: {

    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        refresh: true
      }
    },

    // условие отключения кнопки - Аналитические формы
    linkDisabled() {
      return !this.selectedRow;
    },

  },
  methods: {

    passengerTrafficFactTripByHoursLink() {
      let routeForm = '';
      this.routes_id.map(rt => {
        routeForm = routeForm + '/' + rt;
      })
      let factTrips = '';
      this.rows.map(row => {
        factTrips = factTrips + '/' + row.fact_trip_ext_id;
      })
      this.$router.push('/asmpp/routes/' + this.date_index_begin + '/' + this.date_index_end + routeForm + '/factTripExts')
    },

    setLoading(item) {
      this.isLoading = item;
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading = 'fetch';
      try {
        this.routes = [];
        for (let i = 0; i < this.routes_id.length; i++) {
          // запрос маршрута
          const route = await this.$store.dispatch('multipass/doFetchRoute', {
            route_id: this.routes_id[i],
          });
          if (!route) return;
          this.routes.push(route);
        }

        let rows = await this.$store.dispatch('asmpp/doFetchFactTripCardExts', {
          fact_trip_ext_id: this.fact_trip_ext_id
        })
        if (!rows) {
          return
        } else {
          this.rows = rows
        }
        await this.$store.dispatch('multipass/doFetchStops')

        let tripType = await this.$store.dispatch('multipass/doFetchAllTripTypes')
        if (!tripType) {
          return
        } else {
          this.tripTypes.push(tripType);
        }
      } finally {
        this.isLoading = false;
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
