<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/admin">Администрирование</router-link></span>
        <span> / </span>
        <span>Группы пользователей</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <div class="container position-relative h-100">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">

            <crud-form
                :buttonsExist="{add: true, edit: true, delete: true, refresh: true}"
                :buttonsEnabled="crudButtonsEnabled"
                @onAdd="onTableAdd"
                @onEdit="onTableEdit"
                @onDelete="onTableDelete"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            >

              <template #leftCustomButton>
                <dropdown>
                  <button-toggler target-ref="collapse" class="btn dropdown-toggle btn-primary"
                                  :disabled="linkDisabled">Связь
                  </button-toggler>
                  <dropdown-menu ref="collapse">
                    <dropdown-item router-link :to="groupUserLink">Пользователи</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </template>

            </crud-form>
          </align-panel>

          <!-- Таблица  -->
          <align-panel align="all">
            <user-groups-edit-table
                :filterText="filterText"
                :isLoading="isLoading"
                :selectedRow="selectedRow"
                @onRowSelect="onRowSelect"
                @onRowDblClick="onTableShow"
            ></user-groups-edit-table>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <user-group-dialog
      v-if="isDialogVisible"
      :item="op === 'add' ? {} : selectedRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </user-group-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить группу пользователей с кодом {{ id }} ?
  </confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import UserGroupsEditTable from "../../components/auth/user_groups/UserGroupsEditTable";
import UserGroupDialog from "../../components/auth/user_groups/UserGroupDialog";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    UserGroupsEditTable,
    UserGroupDialog,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false
    }
  },

  computed: {
    // состояние crud-кнопок
    crudButtonsEnabled() {
      return {
        add: true,
        edit: !!this.selectedRow,
        delete: !!this.selectedRow,
        refresh: true
      }
    },

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
    },

    // условие отображения подтверждения удаления
    isDelConfirmVisible() {
      return this.op === 'del' && !!this.selectedRow;
    },

    // ссылка для перехода к пользователям
    groupUserLink() {
      if (this.selectedRow) return '/admin/userGroups/groupUserLinks/' + this.selectedRow.user_group_id
      else return '';
    },

    // условие отключения кнопки - Связь
    linkDisabled() {
      return !this.selectedRow;
    }
  },
  methods: {
    // нажата кнопка Добавить
    onTableAdd() {
      this.$router.replace("/admin/userGroups/add");
    },

    // нажата кнопка Изменить
    onTableEdit() {
      this.$router.replace("/admin/userGroups/edit/" + this.selectedRow.user_group_id);
    },

    // нажата кнопка Удалить
    onTableDelete() {
      this.$router.replace("/admin/userGroups/del/" + this.selectedRow.user_group_id);
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // выполнено двойное нажатие строки в таблице (открываем просмотр)
    onTableShow(item) {
      this.$router.replace("/admin/userGroups/show/" + item.user_group_id);
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/admin/userGroups");
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('auth/doFetchUserGroups', {force})

      } finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/admin/userGroups");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      } else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/admin/userGroups");
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('auth/doAddUserGroup', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {

        const newItem = await this.$store.dispatch('auth/doEditUserGroup', item);
        if (newItem) this.selectedRow = newItem;
        else this.selectedRow = null;

      } finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('auth/doDelUserGroup', this.selectedRow);

      }
      finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

