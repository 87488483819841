<template>
    <modal>
        <modal-dialog class="modal-dialog-centered">
            <modal-content>
                <modal-header class="bg-danger text-white py-2">
                    <modal-title>
                        <slot name="title">Ошибка</slot>
                    </modal-title>	
                </modal-header>
                <modal-body>
                    <p>
                        <slot></slot>
                    </p>
                </modal-body>
                <modal-footer>
                  <!-- Пользовательская левая кнопка -->
                  <slot v-if="$slots.leftCustomButton3" name="leftCustomButton3"></slot>
                  <slot v-if="$slots.leftCustomButton2" name="leftCustomButton2"></slot>
                  <slot v-if="$slots.leftCustomButton" name="leftCustomButton"></slot>
                  <!-- Кнопка закрыть -->
                  <button class="btn btn-primary" @click="$emit('onClose')">Закрыть</button>
                </modal-footer>
            </modal-content>
        </modal-dialog>
    </modal>
</template>

<script>
export default {
    emits: ['onClose']
}
</script>
