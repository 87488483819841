<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/multipass">Маршруты</router-link></span>
        <span> / </span>
        <span><router-link to="/multipass/stops">Остановочные пункты</router-link></span>
        <span> / </span>
        <span>Остановочные павильоны {{ stopPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <align-container>
        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="crudButtonsExist"
              :buttonsEnabled="crudButtonsEnabled"
              @onAdd="onTableAdd"
              @onEdit="onTableEdit"
              @onDelete="onTableDelete"
              @onRefresh="onTableRefresh"
              @onFilterChanged="onFilterChanged"
              @onSave="onTableSave"
              @onCancel="onTableCancel"
          >
            <!-- Кнопка связь-->
            <template #leftCustomButton>
              <dropdown>
                <button-toggler target-ref="collapse2" class="btn dropdown-toggle btn-primary" :disabled="linkDisabled">Связь</button-toggler>
                <dropdown-menu ref="collapse2">
                  <dropdown-item router-link :to="docsLink">Документы</dropdown-item>
                </dropdown-menu>
              </dropdown>
            </template>
          </crud-form>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <!-- Таблица  -->
            <align-panel align="left" width="50%" :gap="3">
              <stop-pavilions-edit-table
                  :rowList="stopPavilions"
                  :selectedRow="selectedRow"
                  :filterText="filterText"
                  @onRowSelect="onRowSelect"
                  @onRowDblClick="onTableShow"
              ></stop-pavilions-edit-table>
            </align-panel>
            <!-- Карта  -->
            <align-panel align="all">
              <stop-pavilions-map
                  :stop="stop"
                  :stopPavilions="stopPavilions"
                  :selectedStopPavilion="selectedRow"
                  :isMoveMode="isMoveMode"
                  @onMapCreated="onMapCreated"
                  @onAdd="onTableAdd"
                  @onEdit="onTableEdit"
                  @onDelete="onTableDelete"
                  @onShow="onTableShow"
                  @onStopPavilionSelect="onStopPavilionSelect"
                  @onMove="onMoveMode"
                  @onStopPavilionChanged="onStopPavilionChanged"
              >
              </stop-pavilions-map>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!-- Просмотр, изменение и добавление -->
  <stop-pavilions-dialog
      v-if="isDialogVisible"
      :item=dialogRow
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </stop-pavilions-dialog>

  <!-- Подтверждение удаления -->
  <confirm-message-box
      v-if="isDelConfirmVisible"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDelete"
  >Вы уверены, что хотите удалить остановочный павилион с кодом {{ id }} ?</confirm-message-box>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>

import StopPavilionsEditTable from "@/components/multipass/stop_pavilions/StopPavilionsEditTable";
import StopPavilionsDialog from "@/components/multipass/stop_pavilions/StopPavilionDialog";
import StopPavilionsMap from "@/components/multipass/stop_pavilions/StopPavilionsMap";
import store from "@/store/Store";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    StopPavilionsMap,
    StopPavilionsDialog,
    StopPavilionsEditTable,
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор остановочного пункта
    "stop_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // карта
      leaflet: null,
      // отображать спиннер
      isLoading: false,
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // режим перемещения
      isMoveMode: false,
      // данные, которые отображаются в диалоговом окне
      dialogRow: {},
      // текущая остановка
      stop: {},
      // список павилионов
      stopPavilions: [],
      // оригинальный павильон
      originPavilion: null,
      // признак изменения
      isModified: false,
    }
  },

  computed: {
      // включение crud-кнопок
      crudButtonsExist() {
        return {
          add: !this.isMoveMode,
          edit: !this.isMoveMode,
          delete: !this.isMoveMode,
          save: this.isMoveMode,
          cancel: this.isMoveMode,
          refresh: true
        }
      },

      // состояние crud-кнопок
      crudButtonsEnabled() {
        return {
          add: true,
          edit: !!this.selectedRow,
          delete: !!this.selectedRow,
          save: this.isModified,
          cancel: true,
          refresh: true
        }
      },

      // условие отображения диалогового окна
      isDialogVisible() {
          return (this.op === 'show' || this.op === 'edit' || this.op === 'add') && (!!this.selectedRow || this.op === 'add')
      },

      // условие отображения подтверждения удаления
      isDelConfirmVisible() {
          return this.op === 'del' && !!this.selectedRow;
      },

      // условие отключения кнопки - Связь
      linkDisabled() {
        return !this.selectedRow;
      },

      //путь к документам остановочного павильона
      docsLink(){
        if (this.selectedRow) return '/multipass/stops/'+ this.stop_id +'/stopPavilions/' + this.selectedRow.stop_pavilion_id + '/stopPavilionDocs';
        else return '';
      },
  },
  methods: {
    // создана карта
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // нажата кнопка Добавить
    onTableAdd(item = {}) {
      this.dialogRow = item;
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopPavilions/add");
    },

    // нажата кнопка Изменить
    onTableEdit(item = this.selectedRow) {
      this.dialogRow = item;
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopPavilions/edit/" + item.stop_pavilion_id);
    },

    // нажата кнопка Удалить
    onTableDelete(item = this.selectedRow) {
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopPavilions/del/" + item.stop_pavilion_id);
    },

    // нажата конпка "Просмотр"
    onTableShow(item = this.selectedRow) {
      this.dialogRow = item;
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopPavilions/show/" + item.stop_pavilion_id);
    },

    // нажата кнопка "Сохранить" (в режиме перемещения)
    async onTableSave() {
      // сохраняем изменения
      await this.editItem(this.selectedRow);
      // снимаем режим редактирования
      this.isMoveMode = false;
    },

    // нажата кнопка "Отменить" (в режиме перемещения)
    async onTableCancel() {
      // отменяем изменения
      await this.restoreItem();
      // снимаем режим редактирования
      this.isMoveMode = false;
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // выбрано меню "Переместить"
    onMoveMode() {
      // запоминаем состояние павильона
      this.originPavilion = {...this.selectedRow};
      // сбрасываем признак изменения
      this.isModified = false;
      // включаем режим перемещения
      this.isMoveMode = true;
    },

    // подтверждено удаление элемента
    onConfirmDelete() {
      // удаляем элемент
      this.delItem();
      // возвращаемся на страницу
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopPavilions");
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'add') {
        this.addItem(item)
      }
      else if (this.op === 'edit') {
        this.editItem(item)
      }
      // возвращаемся на страницу
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopPavilions");
    },

    // отменили операцию
    onRejectOperation() {
      // возвращаемся на страницу
      this.$router.replace("/multipass/stops/" + this.stop_id + "/stopPavilions");
    },

    // павильон переместилась
    onStopPavilionChanged(stopPavilion) {
      // сбрасываем признак изменения
      this.isModified = true;
      // обновляем значение в таблице
      this.selectedRow = editMutation(this.stopPavilions, 'stop_pavilion_id', stopPavilion);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    async onRowSelect(item) {
      // включен режим перемещения
      if (this.selectedRow && this.isMoveMode && this.selectedRow !== item) {
        // сбрасываем режим редактирования
        this.isMoveMode = false;
        // отменяем изменения
        await this.restoreItem();
      }
      // выделяем новый павильон
      this.selectedRow = item;
      // ругаемся
      if (!item.latitude || !item.longitude) {
        store.dispatch('doAddMessage', {
          caption: 'Отображение остановочного павильона',
          message: 'Не указаны координаты',
          type: 'error'
        }, {root: true});
        // выходим
        return;
      }

      // перемещаем карту к нужному павильону
      if (this.leaflet && item.latitude && item.longitude) {
        const minStopZoom = this.$store.getters['settings/getMinStopZoom'];
        const zoom = this.leaflet.getZoom();
        if (zoom >= minStopZoom)
          this.leaflet.setView([item.latitude, item.longitude], zoom)
        else
          this.leaflet.setView([item.latitude, item.longitude], minStopZoom)
      }
    },

    // вызывается при щелчке на павилион в карте
    async onStopPavilionSelect(item) {
      // включен режим перемещения
      if (this.selectedRow && this.isMoveMode && this.selectedRow !== item) {
        // сбрасываем режим перемещения
        this.isMoveMode = false;
        // отменяем изменения
        await this.restoreItem();
      }
      // выделяем новый павильон
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading='fetch';
      try {
        await this.$store.dispatch('multipass/doFetchStopPavilionTypes')

        await this.$store.dispatch('multipass/doFetchStopPavilionKinds')

        const stop = await this.$store.dispatch('multipass/doFetchStop', {
          stop_id: this.stop_id
        }); if (!stop) return;
        this.stop = stop;

        // перемещаем карту к нужной остановки
        if (this.leaflet && stop.latitude && stop.longitude) {
          const minStopZoom = this.$store.getters['settings/getMinStopZoom'];
          const zoom = this.leaflet.getZoom();
          if (zoom >= minStopZoom)
            this.leaflet.setView([stop.latitude, stop.longitude], zoom)
          else
            this.leaflet.setView([stop.latitude, stop.longitude], minStopZoom)
        }

        const stopPavilions = await this.$store.dispatch('multipass/doFetchStopPavilions', {
          stop_id: this.stop_id
        }); if (!stopPavilions) return;
        this.stopPavilions = stopPavilions
      }
      finally {
        this.selectedRow = null;
        this.isLoading=false;
      }
    },

    // добавляем объект
    async addItem(item) {
      this.isLoading = true;
      try {
        // добавляем поля
        item.stop_id = Number(this.stop_id);
        // запрос на сервер
        const stopPavilion = await this.$store.dispatch('multipass/doAddStopPavilion', item);
        // мутируем массив
        this.selectedRow = addMutation(this.stopPavilions, 'stop_pavilion_id', stopPavilion);
      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем объект
    async editItem(item) {
      this.isLoading = true;
      try {
        // добавляем поля
        item.stop_id = Number(this.stop_id);
        // запрос на сервер
        const stopPavilion = await this.$store.dispatch('multipass/doEditStopPavilion', item);
        // мутируем массив
        this.selectedRow = editMutation(this.stopPavilions, 'stop_pavilion_id', stopPavilion);
      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем объект
    async delItem() {
      this.isLoading = true;
      try {
        // запрос на сервер
        const stopPavilion = await this.$store.dispatch('multipass/doDelStopPavilion', this.selectedRow)
        // мутируем массив
        this.selectedRow = delMutation(this.stopPavilions, 'stop_pavilion_id', stopPavilion);
      }
      finally {
        this.isLoading = false;
      }
    },

    // восстанавливаем объект
    async restoreItem() {
      this.selectedRow = editMutation(this.stopPavilions, 'stop_pavilion_id', this.originPavilion)
    },
  },
  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>
