<template>
  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/kvr">КВР</router-link></span>
        <span> / </span>
        <span>Карта</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>

        <!-- Форма с кнопками -->
        <align-panel align="top">
          <crud-form
              :buttonsExist="{add: false, edit: false, delete: false, refresh: false}"
              :filterExists="false"
          >

            <template #leftCustomButton style="height: 24px; margin-top: 0px; margin-bottom: 0px">
              <!-- Выбор режима отображения  -->
              <input-select-with-check
                  :itemList="[{id:0,title:'Текущий наряд'},{id:1,title:'История'}]"
                  v-model="viewMode"
                  title="Текущий наряд"
                  @change="changeValue"
              >
              </input-select-with-check>
            </template>

            <!-- Кнопка "Отображать маршруты"   -->
            <template #leftCustomButton2 style="height: 24px; margin-top: 0px; margin-bottom: 0px">
              <button
                  class="btn btn-primary"
                  @click="onRoutesShowButtonClick"
              >Маршруты
              </button>
            </template>

            <!-- Кнопка "Линии маршрутов"   -->
            <template #leftCustomButton3 style="height: 24px; margin-top: 0px; margin-bottom: 0px">
              <input-select-with-check
                  :itemList="marshLinesVariants"
                  v-model="selectedMarshLines"
                  title="Линии маршрутов"
              >
              </input-select-with-check>
            </template>

            <!-- Кнопка "Направления рейсов"   -->
            <template #leftCustomButton4 style="height: 24px; margin-top: 0px; margin-bottom: 0px">
              <input-select-with-check
                  :itemList="reisDirectionsVariants"
                  v-model="selectedReisDirections"
                  title="Направления рейсов"
              >
              </input-select-with-check>
            </template>

            <!-- Кнопка "Надписи"   -->
            <template #leftCustomButton5 style="height: 24px; margin-top: 0px; margin-bottom: 0px">
              <input-select-with-check
                  :itemList="inscriptionsVariants"
                  v-model="inscriptions"
                  title="Надписи"
              >
              </input-select-with-check>
            </template>

            <!-- Галочка Отслеживать -->
            <template #rightCustomButton>
              <input-checkbox
                  id="keep-watch"
                  v-model="keepWatch"
                  style="margin-top: 7px"
              ><label style="margin-bottom: 0">Отслеживать</label>
              </input-checkbox>
            </template>

            <!-- Кнопка Маршруты -->
            <template #rightCustomButton2>
              <button
                  class="btn btn-primary"
                  @click="allOnMap"
              >Все на карту
              </button>
            </template>

          </crud-form>
        </align-panel>
        <align-panel align="all">
          <align-container>
            <!-- Карта  -->
            <align-panel align="all" style="z-index: 0 !important;">
              <nav-map
                  :vehiclesOnMap="vehiclesOnMap"
                  :routeTripTypeList="selectedRoutes"
                  :selectedRoutesStopZones="selectedRoutesStopZones"
                  :selectedReisDirections="selectedReisDirections"
                  :selectedMarshLines="selectedMarshLines"
                  :inscriptions="inscriptions"
                  @onMapCreated="onMapCreated"
                  @openVehicleInfoDialog="onVehicleInfo"
              >
              </nav-map>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>
    </align-panel>
  </align-container>

  <!--  Диалог "Информация о ТС"  -->
  <vehicle-information-dialog
      v-if="isDialogVisible"
      :item="selectedVehicle"
      :op="op"
      @onRejectOperation="onRejectOperation"
  >
  </vehicle-information-dialog>

  <!--  Панель маршрутов слева-->
  <routes-panel
      :isRoutesPanelShow="isRoutesPanelVisible"
      :routes="routes"
      @onRouteSelected="tripTypeLineToMap"
      @closeRoutesDialog="onRoutesShowButtonClick"
  >
  </routes-panel>

</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import NavMap from "@/components/kvr/map/NavMap";
import VehicleInformationDialog from "@/components/kvr/map/VehicleInformationDialog";
import {latLngBounds} from "leaflet";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import RoutesPanel from "@/components/kvr/map/RoutesPanel";
import InputSelectWithCheck from "@/components/ui/custom/InputSelectWithCheck";
import {getDepot, getRoute} from "@/store/KvrHelpers";
import {getModel, getTransportType} from "@/store/RefHelpers";

export default {
  mixins: [PageMixin],

  components: {
    InputSelectWithCheck,
    RoutesPanel,
    AlignPanel,
    NavMap,
    VehicleInformationDialog
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      viewMode: 0,
      // Линии маршрутов
      selectedMarshLines: 1,
      // варианты для выпадающего списка "Линии маршрутов"
      marshLinesVariants:
          [
            {id: 0, title: 'Не отображать'},
            {id: 1, title: 'Основные рейсы'},
            {id: 2, title: 'Не основные рейсы'},
            {id: 3, title: 'Все рейсы'}
          ],
      // Направления рейсов
      selectedReisDirections: 2,
      // варианты для выпадающего списка "Направления рейсов"
      reisDirectionsVariants:
          [
            {id: 0, title: 'Прямые'},
            {id: 1, title: 'Обратные'},
            {id: 2, title: 'Все'},
          ],
      // выбранная строка в выпадающем списке "надписи"
      inscriptions: 1,
      // варианты для выпадающего списка "надписи"
      inscriptionsVariants:
          [
            {id: 0, title: 'Не отображать'},
            {id: 1, title: 'Маршр.-График'},
            {id: 2, title: 'Гараж. номер'},
            {id: 3, title: 'Гос. номер'}
          ],
      // карта
      leaflet: null,
      // таймер для навигации
      navTimer: null,
      // таймер для информации о ТС
      transportTimer: null,
      // данные, которые отображаются в диалоговом окне
      selectedVehicle: {},
      // первый вызов после команды
      firstWatchAfterCommand: false,
      // первое обновление данных
      firstRefresh: true,
      // отслеживать перемещение транспортных средств
      keepWatch: false,
      // список транспортных средств
      vehiclesNav: [],
      // выбранный список транспортных средств
      selectedVehicleIds: [],
      // видимость диалога с маршрутами
      isRoutesPanelVisible: false,
      // список маршрутов
      routes: [],
      // трансопртные средства, работающие на маршрутах
      transport: [],
    }
  },

  computed: {

    // условие отображения диалогового окна
    isDialogVisible() {
      return (this.op === 'show' && this.id)
    },

    // определяем какие ТС попадут на карту
    // т.к. это computed он в реальном времени обновляет ТС на карте, в том числе и при фильтрации
    vehiclesOnMap() {
      const list = []
      // если ни один маршрут не выбран, то отображаем все:
      if (this.selectedRoutes.length == 0) {
        this.vehiclesNav.forEach(vehicle => {
          if (vehicle.latitude && vehicle.longitude) {
            list.push(vehicle)
          }
        })
      } else {
        let selectedTS = []
        // определяем какие ТС ездят по выбранным маршрутам
        this.transport.map(ts => {
          let route = this.selectedRoutes.find(selectedRoute => ts.route_id == selectedRoute.route_id);
          if (route)
            selectedTS.push(ts.transport_id);
        });
        // отфильтровываем нав отметки по ТС
        this.vehiclesNav.forEach(nav => {
          if (nav.latitude && nav.longitude && selectedTS.includes(nav.transport_id)) {
            list.push(nav)
          }
        })
      }
      // формируем подпись к ТС
      list.map(nav => {
        let transport = this.transport.find(transport => transport.transport_id == nav.transport_id);
        if (transport) {
          nav.garage_num = transport.garage_num;
          nav.state_num = transport.state_num;
          let route = this.routes.find(rt => rt.route_id == transport.route_id);
          if (route)
            nav.special_header = route.route_num + ' - ' + transport.graph;
        }
      })
      return list
    },

    // список выбранных маршрутов
    selectedRoutes() {
      let arr = []
      for (let i = 0; i < this.routes.length; i++) {
        if (this.routes[i].__isSelected) {
          arr.push(this.routes[i]);
        }
      }
      return arr;
    },

    // список выбранных зон
    selectedRoutesStopZones() {
      let arr = []
      for (let i = 0; i < this.routes.length; i++) {
        if (this.routes[i].__isZones) {
          arr.push(this.routes[i]);
        }
      }
      return arr;
    },
  },

  methods: {

    // нажата кнопка "Все на карту"
    allOnMap() {
      let arr = [];
      // формируем список всех элементов на карте
      // 1. Маршруты и остановки
      let routes = this.selectedRoutes;
      routes.forEach(route => {
        // получаем tripType
        let routeValue = this.$store.getters['kvr/getRouteTripTypeList'][route.route_id];
        // добавляем координаты точек линии рейса
        routeValue.trip_types.forEach(tt => {
          if (this.selectedReisDirections == tt.direction || this.selectedReisDirections == 2) {
            if (this.selectedMarshLines == 1 && tt.trip_type.trip_type_sign.substring(0, 2) == "00") {
              if(tt.trip_type_line.points) {
                tt.trip_type_line.points.forEach(point => {
                  arr.push(point);
                });
              }
              if (tt.trip_type_card) {
                tt.trip_type_card.map(card => {
                  let st = routeValue.stops.find(stop => stop.stop_id == card.stop_id);
                  if (st) {
                    if (!arr.find(stop => stop.stop_id == st.stop_id)) {
                      arr.push(st);
                    }
                  }
                });
              }
            } else if (this.selectedMarshLines == 2 && tt.trip_type.trip_type_sign.substring(0, 2) != "00") {
              if(tt.trip_type_line.points) {
                tt.trip_type_line.points.forEach(point => {
                  arr.push(point);
                });
              }
              if (tt.trip_type_card) {
                tt.trip_type_card.map(card => {
                  let st = routeValue.stops.find(stop => stop.stop_id == card.stop_id);
                  if (st) {
                    if (!arr.find(stop => stop.stop_id == st.stop_id)) {
                      arr.push(st);
                    }
                  }
                });
              }
            } else if (this.selectedMarshLines == 3) {
              if(tt.trip_type_line.points) {
                tt.trip_type_line.points.forEach(point => {
                  arr.push(point);
                });
              }
              if (tt.trip_type_card) {
                tt.trip_type_card.map(card => {
                  let st = routeValue.stops.find(stop => stop.stop_id == card.stop_id);
                  if (st) {
                    if (!arr.find(stop => stop.stop_id == st.stop_id)) {
                      arr.push(st);
                    }
                  }
                });
              }
            }
          }
        });
      });

      //ТС
      this.vehiclesOnMap.forEach(vehicle => {
        arr.push(vehicle);
      });

      if (arr.length != 0) {
        const bounds = latLngBounds(
            arr.map(point => [point.latitude, point.longitude])
        )
        this.leaflet.fitBounds(bounds);
      }

    },

    // масштабировать линию типа рейса в экран
    async tripTypeLineToMap(route) {
      let arr = [];
      // получаем типы рейса
      let tripTypes = this.$store.getters['kvr/getRouteTripTypeList'][route.route_id];
      if (tripTypes.trip_types != null) {
        // масштабировать линию типа рейса в экран
        for (let i = 0; i < tripTypes.trip_types.length; i++) {
          if (tripTypes.trip_types && tripTypes.trip_types[i] && tripTypes.trip_types[i].trip_type_line && tripTypes.trip_types[i].trip_type_line.points && this.leaflet) {
            tripTypes.trip_types[i].trip_type_line.points.forEach(point => {
              arr.push(point);
            });
          }
        }
        const bounds = latLngBounds(
            arr.map(point => [point.latitude, point.longitude])
        )
        this.leaflet.fitBounds(bounds);
      }
    },

    // переходим к истории движения
    changeValue() {
      this.$router.replace("/kvr/history");
    },

    // нажата кнопка "Маршруты"
    onRoutesShowButtonClick() {
      this.isRoutesPanelVisible = !this.isRoutesPanelVisible;
    },

    // все на карту
    onAllOnTheMap() {
      // не отслеживаем
      this.keepWatch = false
      // устанавливаем флаг первого вызова
      this.firstWatchAfterCommand = true
      // формируем идентификаторы для отображения
      this.selectedVehicleIds = this.vehiclesNav.map(vehicle => vehicle.transport_id)
    },

    // отображение диалога "Информация о ТС"
    onVehicleInfo(vehicle) {
      this.selectedVehicle = vehicle;
      let selectedTS = this.transport.find(ts => ts.transport_id == vehicle.transport_id);
      if (selectedTS) {
        if (!this.selectedVehicle['depot_title']) {
          this.selectedVehicle['depot_title'] = getDepot(selectedTS.depot_id).long_name;
          if (!this.selectedVehicle['transport_type_title']) {
            this.selectedVehicle['transport_type_title'] = getTransportType(selectedTS.transport_type_id).long_name;
          }
          if (!this.selectedVehicle['model_title']) {
            this.selectedVehicle['model_title'] = getModel(selectedTS.model_id).long_name;
          }
          if (!this.selectedVehicle['garage_num']) {
            this.selectedVehicle['garage_num'] = this.selectedTS.garage_num;
          }
          if (!this.selectedVehicle['state_num']) {
            this.selectedVehicle['state_num'] = this.selectedTS.state_num;
          }
          if (!this.selectedVehicle['route_id']) {
            this.selectedVehicle['route_num'] = getRoute(selectedTS.route_id).route_num;
          }
          if (!this.selectedVehicle['graph']) {
            this.selectedVehicle['graph'] = selectedTS.graph;
          }
          if (!this.selectedVehicle['shift']) {
            this.selectedVehicle['shift'] = selectedTS.shift;
          }
        }
        this.$router.replace("/kvr/map/show/" + vehicle.transport_id)
      }
    },

    // создана карта
    onMapCreated(leaflet) {
      this.leaflet = leaflet;
    },

    // отменили операцию
    onRejectOperation() {
      this.selectedVehicle = {};
      // возвращаемся на страницу
      this.$router.replace("/kvr/map");
    },

    // проверяем, что все транспортные средства попадают в зону карты
    isVehiclesInTheMap() {
      const bounds = this.leaflet.getBounds();
      return this.vehiclesOnMap.every(v => bounds.contains([v.latitude, v.longitude]));
    },

    // запускаем обновление данных
    async refreshDataNav() {
      const vehiclesNav = await this.$store.dispatch('kvr/doFetchCurWorkNav', {route_id_list: null});
      if (vehiclesNav) {
        this.vehiclesNav = vehiclesNav;
        // первое обновление - все на карты
        if (this.firstRefresh && this.leaflet) {
          // все на карту
          this.onAllOnTheMap();
          // сбрасываем флаг первого обновления
          this.firstRefresh = false;
        }
      }
    },

    // запускаем обновление данных
    async refreshWorkTransport() {
      const transport = await this.$store.dispatch('kvr/doFetchCurWorkTransport', {route_id_list: null});
      if (transport) {
        this.transport = transport;
      }
    },
  },

  watch: {

    // срабатывает, когда меняется список транспортных средств на карте
    vehiclesOnMap(newList) {
      // первый вызов после команды - позиционируем карту, чтобы влезли транспортные средства
      if (this.firstWatchAfterCommand) {
        if (newList.length === 1) {
          this.leaflet.setView([newList[0].latitude, newList[0].longitude], this.$store.getters['settings/getDefaultVehicleZoom'])
        } else if (newList.length > 1) {
          const bounds = latLngBounds(
              newList.map(vehicle => [vehicle.latitude, vehicle.longitude])
          )
          this.leaflet.fitBounds(bounds);
        }
        // сбрасываем флаг первого вызова
        this.firstWatchAfterCommand = false
        // выходим
        return
      }

      // если отслеживать не надо - выходим
      if (!this.keepWatch) return

      // отслеживаем, чтобы все транспортные средства попадали в карту если выбрана вкадка "Онлайн"
      if (!this.isVehiclesInTheMap()) {
        if (newList.length === 1) {
          this.leaflet.setView([newList[0].latitude, newList[0].longitude], this.leaflet.getZoom())
        } else if (newList.length > 1) {
          const bounds = latLngBounds(
              newList.map(vehicle => [vehicle.latitude, vehicle.longitude])
          )
          this.leaflet.fitBounds(bounds);
        }
      }
    },
  },

  // вызывается при создании компонента
  async created() {
    if (this.op == 'show') {
      await this.$router.push("/kvr/map");
    }
    // запрос маршрутов
    let routes = await this.$store.dispatch('kvr/doFetchRoutes');
    if (routes) {
      this.routes = routes;
    }
    // загружаем перевозчиков
    await this.$store.dispatch('kvr/doFetchDepots');
    // загружаем транспортные предприятия
    await this.$store.dispatch('kvr/doFetchAgencies');
    // загружаем класс ТС
    await this.$store.dispatch('ref/doFetchTransportClasses');
    // загружаем тип ТС
    await this.$store.dispatch('ref/doFetchTransportTypes');
    // загружаем модели ТС
    await this.$store.dispatch('ref/doFetchModels');
    // загружаем модели радиостанций
    await this.$store.dispatch('nav/doFetchRadioModels');
    // запрос информации о ТС
    await this.refreshWorkTransport();
    // перезапрашиваются навигационные отметки
    await this.refreshDataNav();
  },

  mounted() {
    // запускаем таймер обновления Транспортных средств на маршрутах
    this.transportTimer = setInterval(() => {
      // перезапрашиваются данные
      this.refreshWorkTransport();
    }, 60000);
    // запускаем таймер обновления навигации
    this.navTimer = setInterval(() => {
      // перезапрашиваются данные
      this.refreshDataNav();
    }, 20000);
  },

  unmounted() {
    // убиваем таймер навигации
    if (this.navTimer) {
      clearInterval(this.navTimer);
      this.navTimer = null;
    }
    // убиваем таймер
    if (this.transportTimer) {
      clearInterval(this.transportTimer);
      this.transportTimer = null;
    }
  }
};

</script>

<style scoped>

</style>
