import LeftMenuItem from "@/components/ui/menu/LeftMenuItem";

import AdminLeftMenuList from './leftmenu/AdminLeftMenuList'
import MultipassLeftMenuList from './leftmenu/MultipassLeftMenuList'
import RefLeftMenuList from './leftmenu/RefLeftMenuList'
import RmtLeftMenuList from './leftmenu/RmtLeftMenuList'
import NavLeftMenuList from './leftmenu/NavLeftMenuList'
import AsmppLeftMenuList from './leftmenu/AsmppLeftMenuList';
import KvrLeftMenuList from './leftmenu/KvrLeftMenuList'
import TrnLeftMenuList from './leftmenu/TrnLeftMenuList'
import FullLeftMenuList from "@/components/ui/menu/fullleftmenu/FullLeftMenuList";
import FullLeftMenuItem from "@/components/ui/menu/FullLeftMenuItem";
import RmtGenLeftMenuList from './leftmenu/RmtGenLeftMenuList'

// регистрируем пользовательские компоненты
export default function (app) {
    app.component('left-menu-item', LeftMenuItem);
    app.component('full-left-menu-item', FullLeftMenuItem);

    app.component('admin-left-menu-list', AdminLeftMenuList);
    app.component('multipass-left-menu-list', MultipassLeftMenuList);
    app.component('ref-left-menu-list', RefLeftMenuList);
    app.component('rmt-left-menu-list', RmtLeftMenuList);
    app.component('nav-left-menu-list', NavLeftMenuList);
    app.component('asmpp-left-menu-list', AsmppLeftMenuList);
    app.component('kvr-left-menu-list', KvrLeftMenuList);
    app.component('full-left-menu-list', FullLeftMenuList);
    app.component('rmtgen-left-menu-list', RmtGenLeftMenuList);
    app.component('trn-left-menu-list', TrnLeftMenuList);

}
