import {DoClearCache, DoGet} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОСТУПНЫХ ДЛЯ ФОРМИРОВАНИЯ НАРЯДА ПЕРЕВОЗЧИКИ

export default {
    state() {
        return {
            // список объектов
            orderAgencies: [],
            // время обновления кэша new Date().getTime()
            orderAgenciesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            orderAgenciesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getOrderAgencies(state) {
            return state.orderAgencies;
        },
    },
    mutations: {
        // установить новый список объектов
        setOrderAgencies(state, payload) {
            state.orderAgencies = payload;
            state.orderAgenciesCache = new Date().getTime()
        },
    },
    actions: {

        // запрос списка объектов
        doFetchOrderAgencies: (context, payload) => {
            return DoGet(
                '/kvr/getOrderAgencies',
                'kvr/setOrderAgencies',
                [],
                'Получить список доступных для формирования наряда перевозчиков',
                false,
                payload && payload.force,
                context.state.orderAgenciesCache,
                context.state.orderAgenciesCacheTimeout
            )
        },

        // очищает кэш
        doClearOrderAgencies: (context, payload) => {
            return DoClearCache(
                'kvr/setOrderAgencies',
                [],
                payload && payload.force,
                context.state.orderAgenciesCache,
                context.state.orderAgenciesCacheTimeout
            )
        },
    }
}
