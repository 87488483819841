<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-linked-entities"></edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
// import {getLinkedEntityByLocalId} from "@/store/IntegrationHelpers";
// import {getRoute, getRouteVariantStatus} from "@/store/MultipassHelpers";
// import {getSignTitle, getTimeFormat, secondsToDate} from "@/lib";
// import {getScheduleType, getSeason} from "@/store/RmtHelpers";
// import {getRadioModel, getRadioProtocol} from "@/store/NavHelpers";
import {getDepot} from "@/store/RefHelpers";
import {integrationSortFn, quickSort} from "@/lib";

export default {
  components: {EditTablePanel},
  props: ['isLoading', 'selectedEntType', 'tempArr'],
  data() {
    return {};
  },
  computed: {

    columnList() {
      let result = [];
      result.push(
          {
            field: "__id",
            caption: "Код связи ",
            sortType: 'string',
            isFilterable: true,
          },
      )
      ///// REF /////
      if (this.selectedEntType == 101001) {
        result.push(
            {
              field: "local_id",
              caption: "Код в локальной системе",
              sortType: 'number',
              isFilterable: true
            },
            {
              field: "__long_name",
              caption: "Наименование",
              sortType: 'string',
              isFilterable: true,
            },
        )
      }
 //       else if (this.selectedEntType == 101002) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "long_name",
 //              caption: "Наименование",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 101003) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "long_name",
 //              caption: "Наименование",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 101004) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "long_name",
 //              caption: "Наименование",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 101005) {
 //        result.push(
 //            {
 //              field: "transport_id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "transport_type_id",
 //              caption: "Вид транспорта",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "state_num",
 //              caption: "Гос. номер",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "garage_num",
 //              caption: "Гаражный номер",
 //              sortType: 'string',
 //              isFilterable: true,
 //            }
 //        )
 //      } else if (this.selectedEntType == 101006) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "personnel_num",
 //              caption: "Вид транспорта",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "last_name",
 //              caption: "Фамилия",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "personnel_num",
 //              caption: "Табельный номер",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "middle_name",
 //              caption: "Отчество",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "license_number",
 //              caption: "Номер удостоверения",
 //              sortType: 'string',
 //              show: false,
 //            },
 //            {
 //              field: "birthday",
 //              caption: "Дата рождения",
 //              sortType: 'string',
 //              show: false,
 //            },
 //        )
 //      }
 //      ///// MULTIPASS /////
 //      else if (this.selectedEntType == 102001) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "long_name",
 //              caption: "Название",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "latitude",
 //              caption: "Широта",
 //              sortType: 'number',
 //              show: true,
 //            },
 //            {
 //              field: "longitude",
 //              caption: "Долгота",
 //              sortType: 'number',
 //              show: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 102002) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "long_name",
 //              caption: "Название",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 102003) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "route_num",
 //              caption: "Номер",
 //              sortType: 'routeNum',
 //              isFilterable: true,
 //              sorted: 'asc'
 //            },
 //            {
 //              field: "route_title",
 //              caption: "Наименование",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "open_date",
 //              caption: "Дата открытия",
 //              sortType: 'string',
 //            },
 //            {
 //              field: "close_date",
 //              caption: "Дата закрытия",
 //              sortType: 'string',
 //            },
 //        )
 //      } else if (this.selectedEntType == 102004) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "date_begin",
 //              caption: "Дата начала",
 //              sortType: 'string',
 //              sorted: 'dsc'
 //            },
 //            {
 //              field: "date_end",
 //              caption: "Дата окончания",
 //              sortType: 'string',
 //            },
 //            {
 //              field: "__routeVariantStatusName",
 //              caption: "Статус",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 102005) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "title",
 //              caption: "Наименование",
 //              sortType: 'string',
 //              isFilterable: true
 //            },
 //            {
 //              field: "trip_type_sign",
 //              caption: "Тип рейса",
 //              sortType: 'sign',
 //              sorted: 'asc',
 //              dataAlign: 'left',
 //              headerAlign: 'left',
 //              displayType: 'html',
 //              displayFn(row) {
 //                return getSignTitle(row.trip_type_sign)
 //              },
 //            },
 //            {
 //              field: "is_production",
 //              caption: "Производ.",
 //              sortType: 'boolean',
 //              dataAlign: 'center',
 //              headerAlign: 'center',
 //              displayType: 'html',
 //              displayFn(row) {
 //                if (row.is_production) {
 //                  return '<i class="fas fa-check">'
 //                }
 //                return ''
 //              },
 //            },
 //            {
 //              field: "weight",
 //              caption: "Вес",
 //              sortType: 'number',
 //            },
 //            {
 //              field: "length",
 //              caption: "Длина,км.",
 //              sortType: 'number',
 //              dataAlign: 'right',
 //              headerAlign: 'right',
 //              displayFn(row) {
 //                if (Number.isFinite(row.length)) {
 //                  return Number(row.length / 1000).toFixed(3);
 //                }
 //                return ''
 //              },
 //            },
 //        )
 //      } else if (this.selectedEntType == 102006) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "__title",
 //              caption: "Наименование",
 //              sortType: 'string',
 //              isFilterable: true
 //            },
 //            {
 //              field: "__latitude",
 //              caption: "Широта",
 //              sortType: 'number',
 //              show: true,
 //            },
 //            {
 //              field: "__longitude",
 //              caption: "Долгота",
 //              sortType: 'number',
 //              show: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 102007) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "long_name",
 //              caption: "Наименование",
 //              sortType: 'string',
 //              isFilterable: true
 //            },
 //        )
 //      }
 //
 //      ///// RMT /////
 //      else if ([103001, 103002, 103006].includes(this.selectedEntType)) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "long_name",
 //              caption: "Название",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 103003) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "__seasonName",
 //              caption: "Сезон",
 //              sortType: 'number',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "__scheduleTypeName",
 //              caption: "Тип расписания",
 //              sortType: 'number',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "date_begin",
 //              caption: "Дата начала",
 //              sortType: 'string',
 //              sorted: 'dsc'
 //            },
 //            {
 //              field: "date_end",
 //              caption: "Дата окончания",
 //              sortType: 'string',
 //            },
 //        )
 //      } else if (this.selectedEntType == 103004) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "date",
 //              caption: "Дата",
 //              sortType: 'number',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "route",
 //              caption: "Маршрут",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "__week_day",
 //              caption: "День нед.",
 //              sortType: 'number',
 //              isFilterable: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 103005) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "long_name",
 //              caption: "Полное наименование",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "prefix",
 //              caption: "Префикс номера графика",
 //              sortType: 'number',
 //              isFilterable: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 103007) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "title",
 //              caption: "Наименование",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 103008) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "schedule_graph_set_id",
 //              caption: "Код набора графиков",
 //              sortType: 'number',
 //              isFilterable: true,
 //              show: false
 //            },
 //            {
 //              field: "graph",
 //              caption: "График",
 //              sortType: 'number',
 //            },
 //            {
 //              field: "shift",
 //              caption: "Смена",
 //              sortType: 'number',
 //            },
 //            {
 //              field: "time_begin",
 //              caption: "Нач. работы",
 //              headerAlign: 'center',
 //              dataAlign: 'center',
 //              sortType: 'string',
 //              show: true,
 //              displayFn(row){
 //                return getTimeFormat(row.time_begin)
 //              }
 //            },
 //            {
 //              field: "time_end",
 //              caption: "Оконч. работы",
 //              sortType: 'number',
 //              headerAlign: 'center',
 //              dataAlign: 'center',
 //              show: true,
 //              displayFn(row){
 //                return getTimeFormat(row.time_end)
 //              }
 //            },
 //        )
 //      }
 //      //// RMT ////
 //
 //      else if (this.selectedEntType == 104001) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "radio_protocol_title",
 //              caption: "Наименование",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "radio_protocol_desc",
 //              caption: "Описание",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //        )
 //      } else if (this.selectedEntType == 104002) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "radio_model_title",
 //              caption: "Наименование",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "radio_model_title",
 //              caption: "Наименование",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "radio_protocol_id",
 //              caption: "Код эфирного протокола",
 //              sortType: 'number',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "__radio_model_media",
 //              caption: "Среда передачи данных",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "manufacturer",
 //              caption: "Производитель",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //        )
 //      }
 // else if (this.selectedEntType == 104003) {
 //        result.push(
 //            {
 //              field: "__id",
 //              caption: "Код",
 //              sortType: 'number',
 //              isFilterable: true
 //            },
 //            {
 //              field: "radio_num",
 //              caption: "Номер радиостанции",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "radio_protocol",
 //              caption: "Эфирный протокол",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //            {
 //              field: "radio_gate_num",
 //              caption: "Номер шлюза",
 //              sortType: 'string',
 //              isFilterable: true
 //            },
 //            {
 //              field: "radio_model",
 //              caption: "Модель",
 //              sortType: 'string',
 //              isFilterable: true,
 //            },
 //        )
 //      }

      result.push(
          {
            field: "src_num",
            caption: "Источник",
            sortType: 'string',
            isFilterable: true,
          },
          {
            field: "external_id",
            caption: "Идентификатор сущности во внешней системе",
            dataAlign: 'left',
            headerAlign: 'left',
            displayType: 'string',
            isFilterable: true,
            isSortable: false,
          },
          {
            field: "local_checksum",
            caption: "Хеш-сумма сущности в локальной системе",
            dataAlign: 'left',
            headerAlign: 'left',
            displayType: 'string',
            isFilterable: true,
            isSortable: false,
          },
          {
            field: "external_checksum",
            caption: "Хеш-сумма сущности во внешней системе",
            dataAlign: 'left',
            headerAlign: 'left',
            displayType: 'string',
            isFilterable: true,
            isSortable: false,
          },
          // {
          //   field: "__save",
          //   caption: "",
          //   displayType: 'button',
          //   dataAlign: 'center',
          //   buttonCaption: '<i class="fas fa-check"></i>',
          //   buttonAttrs: {
          //     'title': 'Cохранить',
          //     'class': "btn btn-success btn-sm",
          //     'type': "button",
          //   },
          //   buttonClick: (row) => {
          //     this.$emit('saveEntity', row);
          //   },
          //   show: true,
          //   isSortable: false,
          //   cellStyle: {
          //     "vertical-align": "middle",
          //   }
          // },
          // {
          //   field: "__cancel",
          //   caption: "",
          //   displayType: 'button',
          //   dataAlign: 'center',
          //   buttonCaption: '<i class="fas fa-ban"></i>',
          //   buttonAttrs: {
          //     'title': 'Cохранить',
          //     'class': "btn btn-danger btn-sm",
          //     'type': "button"
          //   },
          //   buttonClick: (row) => {
          //     let ent = getLinkedEntityByLocalId(row.__id);
          //     row.external_id = ent.external_id;
          //   },
          //   show: true,
          //   isSortable: false,
          //   cellStyle: {
          //     "vertical-align": "middle",
          //   }
          // }
          )
      return result;
    },

    rowList() {
      let result = [];
      if (this.isLoading === 'fetch') {
        return [];
      }

      // если выбраны предприятия
      if (this.selectedEntType == 101001) {

        this.$store.getters['integration/getLinkedEntities'].map(link => {

          let depot = getDepot(Number(link.local_id));
          link.__id = link.linked_entity_id;
          link.__long_name = depot.long_name;

          result.push(link);
        })
      }

      // сортируем массив
      quickSort(result, (leftItem, rightItem) => {
        return 1 * integrationSortFn(leftItem, rightItem);
      });

      return result;

      // // если выбраны виды транспорта
      // if (this.selectedEntType == 101002) {
      //   return this.$store.getters['ref/getTransportTypes'].map(trType => {
      //     trType.__id = trType.transport_type_id;
      //     let linkEnt = getLinkedEntityByLocalId(trType.transport_type_id);
      //     trType.external_id = linkEnt ? linkEnt.external_id : '';
      //     return trType;
      //   })
      // }
      //
      // // если выбраны классы ТС
      // if (this.selectedEntType == 101003) {
      //   return this.$store.getters['ref/getTransportClasses'].map(trClass => {
      //     trClass.__id = trClass.transport_class_id;
      //     let linkEnt = getLinkedEntityByLocalId(trClass.transport_class_id);
      //     trClass.external_id = linkEnt ? linkEnt.external_id : '';
      //     return trClass;
      //   })
      // }
      //
      // // если выбраны модели ТС
      // if (this.selectedEntType == 101004) {
      //   return this.$store.getters['ref/getModels'].map(model => {
      //     model.__id = model.model_id;
      //     let linkEnt = getLinkedEntityByLocalId(model.model_id);
      //     model.external_id = linkEnt ? linkEnt.external_id : '';
      //     return model;
      //   })
      // }
      //
      // // если выбраны ТС
      // if (this.selectedEntType == 101005) {
      //   return this.$store.getters['ref/getTransport'].map(ts => {
      //     ts.__id = ts.transport_id;
      //     let linkEnt = getLinkedEntityByLocalId(ts.transport_id);
      //     ts.external_id = linkEnt ? linkEnt.external_id : '';
      //
      //     // if (!linkEnt || (linkEnt && ts.external_id && (linkEnt.external_id == ts.external_id))) {
      //     //   ts.__buttonAttrs = {
      //     //     'disabled': true
      //     //   }
      //     // } else if (linkEnt && ts.external_id && (linkEnt.external_id != ts.external_id)) {
      //     //   ts.__buttonAttrs = {
      //     //     'disabled': false
      //     //   }
      //     // }
      //
      //     return ts;
      //   })
      // }
      //
      // // если выбраны работники
      // if (this.selectedEntType == 101006) {
      //   return this.$store.getters['ref/getPersonnel'].map(personnel => {
      //     personnel.__id = personnel.transport_id;
      //     let linkEnt = getLinkedEntityByLocalId(personnel.transport_id);
      //     personnel.external_id = linkEnt ? linkEnt.external_id : '';
      //     return personnel;
      //   })
      // }
      //
      // // если выбраны остановки
      // if (this.selectedEntType == 102001) {
      //   return this.$store.getters['multipass/getStops'].map(stop => {
      //     stop.__id = stop.stop_id;
      //     let linkEnt = getLinkedEntityByLocalId(stop.stop_id);
      //     stop.external_id = linkEnt ? linkEnt.external_id : '';
      //     return stop;
      //   })
      // }
      //
      // // если выбраны описание вида сообщения
      // if (this.selectedEntType == 102002) {
      //   return this.$store.getters['multipass/getTransferKinds'].map(transferKind => {
      //     transferKind.__id = transferKind.transfer_kind_id;
      //     let linkEnt = getLinkedEntityByLocalId(transferKind.transfer_kind_id);
      //     transferKind.external_id = linkEnt ? linkEnt.external_id : '';
      //     return transferKind;
      //   })
      // }
      //
      // // если выбраны остановки
      // if (this.selectedEntType == 102003) {
      //   return this.$store.getters['multipass/getRoutes'].map(route => {
      //     route.__id = route.route_id;
      //     let linkEnt = getLinkedEntityByLocalId(route.route_id);
      //     route.external_id = linkEnt ? linkEnt.external_id : '';
      //     return route;
      //   })
      // }
      //
      // // если выбраны остановки
      // if (this.selectedEntType == 102004) {
      //   return this.tempArr.map(routeVariant => {
      //     routeVariant.__id = routeVariant.route_variant_id;
      //     let linkEnt = getLinkedEntityByLocalId(routeVariant.route_variant_id);
      //     const routeVariantStatus = getRouteVariantStatus(routeVariant.route_variant_status_id);
      //     routeVariant.__routeVariantStatusName = routeVariantStatus && routeVariantStatus.long_name || '';
      //     routeVariant.external_id = linkEnt ? linkEnt.external_id : '';
      //     return routeVariant;
      //   })
      // }
      //
      // // если выбраны типы рейсов
      // if (this.selectedEntType == 102005) {
      //   return this.tempArr.map(tripType => {
      //     tripType.__id = tripType.trip_type_id;
      //     let linkEnt = getLinkedEntityByLocalId(tripType.route_variant_id);
      //     tripType.external_id = linkEnt ? linkEnt.external_id : '';
      //     return tripType;
      //   })
      // }
      //
      // // если выбраны зоны типы рейсов
      // if (this.selectedEntType == 102006) {
      //   let resultArr = [];
      //   this.tempArr.map(tripTypeZone => {
      //     for (let zoneNumb = 0; zoneNumb < tripTypeZone.length; zoneNumb++) {
      //       tripTypeZone[zoneNumb].zone.__id = tripTypeZone[zoneNumb].zone.zone_id;
      //       let linkEnt = getLinkedEntityByLocalId(tripTypeZone[zoneNumb].zone.zone_id);
      //       tripTypeZone[zoneNumb].__title = tripTypeZone[zoneNumb].zone.zone_title;
      //       tripTypeZone[zoneNumb].__latitude = tripTypeZone[zoneNumb].zone.latitude;
      //       tripTypeZone[zoneNumb].__longitude = tripTypeZone[zoneNumb].zone.longitude;
      //       tripTypeZone[zoneNumb].external_id = linkEnt ? linkEnt.external_id : '';
      //       resultArr.push(tripTypeZone[zoneNumb]);
      //     }
      //   })
      //   return resultArr;
      // }
      //
      // // если выбраны типы маршрутов
      // if (this.selectedEntType == 102007) {
      //   return this.$store.getters['multipass/getRouteTypes'].map(routeType => {
      //     routeType.__id = routeType.route_type_id;
      //     let linkEnt = getLinkedEntityByLocalId(routeType.route_type_id);
      //     routeType.external_id = linkEnt ? linkEnt.external_id : '';
      //     return routeType;
      //   })
      // }
      //
      //
      // //// RMT ////
      // else if (this.selectedEntType == 103001) {
      //   return this.$store.getters['rmt/getSeasons'].map(season => {
      //     season.__id = season.season_id;
      //     let linkEnt = getLinkedEntityByLocalId(season.season_id);
      //     season.external_id = linkEnt ? linkEnt.external_id : '';
      //     return season;
      //   })
      // } else if (this.selectedEntType == 103002) {
      //   return this.$store.getters['rmt/getScheduleTypes'].map(scheduleType => {
      //     scheduleType.__id = scheduleType.schedule_type_id;
      //     let linkEnt = getLinkedEntityByLocalId(scheduleType.schedule_type_id);
      //     scheduleType.external_id = linkEnt ? linkEnt.external_id : '';
      //     return scheduleType;
      //   })
      // } else if (this.selectedEntType == 103003) {
      //   return this.tempArr.map(scheduleVariant => {
      //     scheduleVariant.__id = scheduleVariant.schedule_variant_id;
      //     const season = getSeason(scheduleVariant.season_id);
      //     const scheduleType = getScheduleType(scheduleVariant.schedule_type_id);
      //     scheduleVariant.__seasonName = season && season.long_name || '';
      //     scheduleVariant.__scheduleTypeName = scheduleType && scheduleType.long_name || '';
      //     let linkEnt = getLinkedEntityByLocalId(scheduleVariant.schedule_variant_id);
      //     scheduleVariant.external_id = linkEnt ? linkEnt.external_id : '';
      //     return scheduleVariant;
      //   })
      // } else if (this.selectedEntType == 103004) {
      //   return this.$store.getters['rmt/getHolidays'].map(holiday => {
      //     holiday.__id = holiday.holiday_id;
      //     holiday.date = secondsToDate(holiday.date_index*24*60*60)
      //     let linkEnt = getLinkedEntityByLocalId(holiday.holiday_id);
      //     let route = getRoute(holiday.route_id)
      //     if(route)
      //       holiday.route = route.route_num;
      //
      //     if (holiday.week_day_num == 1) {
      //       holiday.__week_day = "Пн."
      //     } else if (holiday.week_day_num == 2) {
      //       holiday.__week_day = "Вт."
      //     } else if (holiday.week_day_num == 3) {
      //       holiday.__week_day = "Ср."
      //     } else if (holiday.week_day_num == 4) {
      //       holiday.__week_day = "Чт."
      //     } else if (holiday.week_day_num == 5) {
      //       holiday.__week_day = "Пт."
      //     } else if (holiday.week_day_num == 6) {
      //       holiday.__week_day = "Сб."
      //     } else if (holiday.week_day_num == 7) {
      //       holiday.__week_day = "Вс."
      //     }
      //
      //     holiday.external_id = linkEnt ? linkEnt.external_id : '';
      //     return holiday;
      //   })
      // } else if (this.selectedEntType == 103005) {
      //   return this.$store.getters['rmt/getRegimes'].map(regime => {
      //     regime.__id = regime.regime_id;
      //     let linkEnt = getLinkedEntityByLocalId(regime.regime_id);
      //     regime.external_id = linkEnt ? linkEnt.external_id : '';
      //     return regime;
      //   })
      // } else if (this.selectedEntType == 103006) {
      //   return this.$store.getters['rmt/getScheduleEvents'].map(scheduleEvent => {
      //     scheduleEvent.__id = scheduleEvent.schedule_event_id;
      //     let linkEnt = getLinkedEntityByLocalId(scheduleEvent.schedule_event_id);
      //     scheduleEvent.external_id = linkEnt ? linkEnt.external_id : '';
      //     return scheduleEvent;
      //   })
      // } else if (this.selectedEntType == 103007) {
      //   return this.tempArr.map(scheduleGraphSet => {
      //     scheduleGraphSet.__id = scheduleGraphSet.schedule_graph_set_id;
      //     let linkEnt = getLinkedEntityByLocalId(scheduleGraphSet.schedule_graph_set_id);
      //     scheduleGraphSet.external_id = linkEnt ? linkEnt.external_id : '';
      //     return scheduleGraphSet;
      //   })
      // }  else if (this.selectedEntType == 103008) {
      //   return this.tempArr.map(scheduleGraph => {
      //     scheduleGraph.__id = scheduleGraph.schedule_graph_id;
      //     let linkEnt = getLinkedEntityByLocalId(scheduleGraph.schedule_graph_id);
      //     scheduleGraph.external_id = linkEnt ? linkEnt.external_id : '';
      //     return scheduleGraph;
      //   })
      // }
      //
      // //// RMT ////
      // else if (this.selectedEntType == 104001) {
      //   return this.$store.getters['nav/getRadioProtocols'].map(radioProtocol => {
      //     radioProtocol.__id = radioProtocol.radio_protocol_id;
      //     let linkEnt = getLinkedEntityByLocalId(radioProtocol.radio_protocol_id);
      //     radioProtocol.external_id = linkEnt ? linkEnt.external_id : '';
      //     return radioProtocol;
      //   })
      // } else if (this.selectedEntType == 104002) {
      //   return this.$store.getters['nav/getRadioModels'].map(radioModel => {
      //     radioModel.__id = radioModel.radio_model_id;
      //     let rmm = '';
      //     if (radioModel.radio_model_media == 0) {
      //       rmm = 'GSM/GPRS';
      //     } else if (radioModel.radio_model_media == 1) {
      //       rmm = 'УКВ';
      //     }
      //     radioModel.__radio_model_media = rmm;
      //     let linkEnt = getLinkedEntityByLocalId(radioModel.radio_model_id);
      //     radioModel.external_id = linkEnt ? linkEnt.external_id : '';
      //     return radioModel;
      //   })
      // } else if (this.selectedEntType == 104003) {
      //   return this.$store.getters['nav/getRadios'].map(radio => {
      //     radio.__id = radio.radio_id;
      //     // загружаем эфирный протокол
      //     let radio_protocol = getRadioProtocol(radio.radio_protocol_id);
      //     radio.radio_protocol = radio_protocol.radio_protocol_title;
      //     // загружаем модель радиостанции
      //     let radio_model = getRadioModel(radio.radio_model_id);
      //     radio.radio_model = radio_model.radio_model_title;
      //     let linkEnt = getLinkedEntityByLocalId(radio.radio_id);
      //     radio.external_id = linkEnt ? linkEnt.external_id : '';
      //     return radio;
      //   })
      // }
    },

  },

  methods: {}
};
</script>
