<template>
  <transition name="slide">
    <div class="report-panel overflow-hidden" v-click-away="onAutoHide"
         :style="dialogStyle + ' background-color: #ffdbe1;'">
      <div style="width: 100%; background-color: #17a2b8">
        <label style="margin-left: 5px; margin-bottom: 0px; font-weight: bold; color: white">Назначение ТО</label>
      </div>
      <div class="row" style="margin: 0; padding: 0;">
        <div class="col-lg-9" style="padding: 0 0 0 5px;">
          <div class="row" style="margin: 0;">
            <div class="col" style="padding: 0;">
              <label for="scheduleEvents" style="margin: 0;">Тех. операции:</label>
              <ul style="overflow-y: auto; height: 90px;padding: 0; margin: 0 5px 0 0;">
                <li
                    v-for="scheduleEvent in scheduleEvents"
                    :key=scheduleEvent.schedule_event_id
                    :id="scheduleEvent.schedule_event_id"
                    :class="selected_schedule_event_id == scheduleEvent.schedule_event_id ? 'list-group-item active' : 'list-group-item'"
                    @click="setSelectedScheduleEvent(scheduleEvent.schedule_event_id)"
                >{{ getScheduleEventListText(scheduleEvent) }}
                </li>
              </ul>
            </div>
            <div class="col" style="padding: 0;">
              <label for="nullItems" style="margin: 0;">Нулевые рейсы:</label>
              <ul style="overflow-y: auto; height: 90px; padding: 0px; margin: 0px">
                <li
                    v-for="tripType in nullItemList"
                    :key="tripType.trip_type_id"
                    :id="tripType.trip_type_id"
                    :class="selected_null_trip_type_id == tripType.trip_type_id ? 'list-group-item active' : 'list-group-item'"
                    @click="setSelectedNullTripType(tripType.trip_type_id)"
                    :title="getTripTypeTittle(tripType, 'null')"
                >{{ getTripTypeShort(tripType.trip_type_sign) }}
                </li>
                <li
                    :class="selected_null_trip_type_id == 0 ? 'list-group-item active' : 'list-group-item'"
                    :id="0"
                    @click="setSelectedNullTripType(0)"
                >Без нулевого
                </li>
              </ul>
            </div>
          </div>
          <div class="row" style="margin: 0;">
            <label for="changedTripType" style="margin: 19px 5px 0;">Выпустить на замену выход:</label>
            <input-select
                class="form-control"
                style="width: 60px; max-height: 24px; padding: 0; margin-top: 18px"
                v-model="changedTripType"
                :itemList="itemList"
                :readonly="!isInputSelectActive"
            ></input-select>

            <input-checkbox
                id="isInputSelectActive"
                style="margin-top: 18px; margin-left: 10px"
                v-model="isInputSelectActive"
                @onChange="getGraphs"
                :readonly="!selected_schedule_event_id"
            ></input-checkbox>

            <button
                type="button"
                class="btn btn-success btn-sm"
                style="height: 31px; margin-left: 8px; margin-top: 15px"
                @click="onSetGraphs"
            >
              Принять
            </button>
          </div>
        </div>
        <div class="col-3" style="padding-left: 0px; padding-right: 5px">
          <label for=" productionItems" style="margin: 0 0 0 15px;">Произв. рейсы</label>
          <ul style="overflow-y: auto; height: 90px; margin: 0 0 0 15px; padding: 0">
            <li
                v-for="tripType in productionItemList"
                :key=tripType.trip_type_id
                :id="tripType.trip_type_id"
                :class="selected_production_trip_type_id == tripType.trip_type_id ? 'list-group-item active' : 'list-group-item'"
                @click="setSelectedProductionTripType(tripType.trip_type_id)"
                :title="getTripTypeTittle(tripType, 'tripType')"
            >{{ getTripTypeShortFnc(tripType.trip_type_sign) }}
            </li>
          </ul>

          <button
              type="button"
              class="btn btn-danger btn-sm"
              style="height: 31px; margin-left: 15px; margin-top: 15px"
              @click="onAutoHide"
          >
            Отменить
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {directive} from "vue3-click-away";
import InputSelect from "@/components/ui/custom/InputSelect";
import InputCheckbox from "@/components/ui/custom/InputCheckbox";
import {getSignTitle} from "@/lib";
import {getStop} from "@/store/MultipassHelpers";


export default {
  components: {InputCheckbox, InputSelect},
  directives: {
    ClickAway: directive
  },
  emits: ['hideDialog', 'changeTripType'],
  props: ['dialogStyle', 'direction', 'tripTypes', 'dialogSpecifiedTrip'],
  data() {
    return {
      // выключен ли выпадающий список "Выпустить на замену выход"
      isInputSelectActive: false,
      // загруженные графики
      itemList: [],
      // Выпустить на замену выход
      changedTripType: null,
      // выбранное событие
      selected_schedule_event_id: this.dialogSpecifiedTrip.schedule_event_id,
      // массив для списка нулевых рейсов
      nullItemList: [],
      // выбранный нулевой рейс
      selected_null_trip_type_id: this.dialogSpecifiedTrip.null_trip_type_id,
      // массив производственных рейсов
      productionItemList: [],
      // выбранный производственный рейс
      selected_production_trip_type_id: this.dialogSpecifiedTrip.trip_type_id,
    }
  },

  watch: {

    // отслеживаем изменения itemList, чтобы если пришел пустой массив, отключить выпадающий список
    itemList() {
      if (this.itemList.length == 0) {
        this.isInputSelectActive = false;
      }
    }

    // isInputSelectActive(newValue, oldValue) {
    //   console.log('newValue', newValue)
    //   if (newValue == true) {
    //     this.isInputSelectActive = oldValue;
    //   }
    // }

  },

  computed: {

    scheduleEvents() {
      let resultArr = [];
      let scheduleEvents = this.$store.getters['rmt/getScheduleEvents'];
      resultArr.push(scheduleEvents.find(value => value.schedule_event_id == 5));
      resultArr.push(scheduleEvents.find(value => value.schedule_event_id == 6));
      resultArr.push(scheduleEvents.find(value => value.schedule_event_id == 12));
      resultArr.push(scheduleEvents.find(value => value.schedule_event_id == 10));
      resultArr.push(scheduleEvents.find(value => value.schedule_event_id == 4));
      resultArr.push(scheduleEvents.find(value => value.schedule_event_id == 8));
      for (let scheduleEvent of this.$store.getters['rmt/getScheduleEvents']) {
        if ([7,9,10,11,13].includes(scheduleEvent.schedule_event_id)) {
          resultArr.push(scheduleEvent);
        }
      }
      return resultArr;
    }

  },

  methods: {

    // получаем тип рейса без букв направления
    getTripTypeShort(trip_type) {
      return (trip_type.includes("AB", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type.includes("BA", trip_type.length - 2) ? (trip_type.substring(0, trip_type.length - 2).includes("00", 0) ? "" : trip_type.substring(0, trip_type.length - 2)) : trip_type)
    },

    getTripTypeShortFnc(trip_type_sign) {
      return getSignTitle(trip_type_sign);
    },

    setSelectedScheduleEvent(selected_schedule_event_id) {
      this.selected_production_trip_type_id = null;
      this.selected_schedule_event_id = selected_schedule_event_id;
    },

    setSelectedNullTripType(trip_type_id) {
      this.selected_production_trip_type_id = null;
      this.selected_null_trip_type_id = trip_type_id;
    },

    setSelectedProductionTripType(trip_type_id) {
      this.selected_null_trip_type_id = null;
      this.selected_schedule_event_id = null;
      this.getGraphs(false);
      this.isInputSelectActive = false;
      this.selected_production_trip_type_id = trip_type_id;
    },

    onSetGraphs() {
      // this.$emit('changeTripType');
      // if(this.selected_production_trip_type_id) {
      //   this.scrollToSelectedRow(this.selected_production_trip_type_id);
      // }

      let specifiedTrip = this.dialogSpecifiedTrip;

      this.$emit('changeTripType', specifiedTrip, this.changedTripType, this.selected_schedule_event_id, this.selected_null_trip_type_id, this.selected_production_trip_type_id);

    },

    // убирать окно при щелчке в другое место (пока не будем это делать)
    onAutoHide() {
      this.$emit('hideDialog');
    },

    getGraphs(newValue) {
      if (newValue == true) {
        //делаем запрос на получение графиков
        this.itemList = [
          {
            id: '1',
            title: '2'
          }
        ]
      } else {
        this.changedTripType = null;
        this.itemList = [
          {
            id: null,
            title: ''
          }
        ]
      }
    },

    getScheduleEventListText(scheduleEvent) {
      let letter = '';
      if (scheduleEvent.schedule_event_id == 4) {
        letter = "Т";
      } else if (scheduleEvent.schedule_event_id == 5) {
        letter = "О";
      } else if (scheduleEvent.schedule_event_id == 6) {
        letter = "Р";
      } else if (scheduleEvent.schedule_event_id == 7) {
        letter = "П";
      } else if (scheduleEvent.schedule_event_id == 8) {
        letter = "З";
      } else if (scheduleEvent.schedule_event_id == 9) {
        letter = "К";
      } else if (scheduleEvent.schedule_event_id == 10) {
        letter = "С";
      } else if (scheduleEvent.schedule_event_id == 11) {
        letter = "Е";
      } else if (scheduleEvent.schedule_event_id == 12) {
        letter = "В";
      } else if (scheduleEvent.schedule_event_id == 13) {
        letter = "И";
      } else {
        letter = "";
      }

      return "(" + letter + ') ' + scheduleEvent.long_name;
    },

    // скролим до выбранного элемента
    scrollToSelectedRow(id) {
      let el = document.getElementById(id);
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    },

    // получаем текст для всплывающих подсказок
    getTripTypeTittle(trip_type, type) {
      if (type == 'null') {
        let firstStop = getStop(trip_type.stop_id_begin);
        let lastStop = getStop(trip_type.stop_id_end);
        return '(' + trip_type.trip_type_sign + ') ' + firstStop.long_name + ' - ' + lastStop.long_name;
      } else if (type == 'tripType' && trip_type.trip_type_id != 0) {
        let firstStop = getStop(trip_type.stop_id_begin);
        let lastStop = getStop(trip_type.stop_id_end);
        return firstStop.long_name + ' - ' + lastStop.long_name;
      }
    },
  },

  async created() {
    this.tripTypes.forEach(value => {
      if ((value.trip_type_sign[0] == '-' || value.trip_type_sign[0] == '_') && (value.trip_type_sign.includes(this.direction))) {
        this.nullItemList.push(value);
      } else if ((value.trip_type_sign[0] != '-' || value.trip_type_sign[0] != '_') && (value.trip_type_sign.includes(this.direction))) {
        this.productionItemList.push(value);
      }
    })
  },

  mounted() {
    if(this.selected_null_trip_type_id || this.selected_null_trip_type_id == 0) {
      this.scrollToSelectedRow(this.selected_null_trip_type_id);
    }
    if(this.selected_schedule_event_id) {
      this.scrollToSelectedRow(this.selected_schedule_event_id);
    }
    if(this.selected_production_trip_type_id) {
      this.scrollToSelectedRow(this.selected_production_trip_type_id);
    }
  }

}
</script>

<style scoped>

li {
  padding: 0;
  white-space: nowrap;
  cursor: pointer;
}

ul {
  overflow-x: hidden;
  background-color: white;
}

.report-panel {
  position: absolute;
  max-height: 250px;
  height: 185px;
  /*bottom: 5px;*/
  /*right: 5px;*/
  width: 500px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Параметры тени */
  font-size: 90%;
  z-index: 100;
}

/*.slide-enter-active,*/
/*.slide-leave-active {*/
/*  transition: all 0.5s ease;*/
/*}*/

/*.slide-enter-from,*/
/*.slide-leave-to {*/
/*  right: -800px;*/
/*}*/

/*.slide-enter-to,*/
/*.slide-leave-from {*/
/*  right: 5px;*/
/*}*/
</style>