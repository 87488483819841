import {DoClearCache, DoDelete, DoGet, DoPost, DoPut} from "@/store/Actions";

// ХРАНИЛИЩЕ ДОРОГ

export default {
    state() {
        return {
            // список объектов
            roads: [],
            // время обновления кэша new Date().getTime()
            roadsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            roadsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRoads(state) {
            return state.roads;
        },
    },
    mutations: {
        // установить новый список объектов
        setRoads(state, payload) {
            state.roads = payload;
            state.roadsCache = new Date().getTime()
        },
        // добавить объект
        addRoad(state, payload) {
            state.roads.push(payload)
        },
        // изменить объект
        editRoad(state, payload) {
            const index = state.roads.findIndex(road => road.road_id == payload.road_id);
            if (index >= 0) {
                state.roads[index] = payload;
            }
        },
        // удалить объект
        delRoad(state, payload) {
            const index = state.roads.findIndex(road => road.road_id == payload.road_id);
            state.roads.splice(index, 1);
        }
    },
    actions: {
        // запрос списка объектов
        doFetchRoads: (context, payload) => {
            return DoGet(
                '/gis/roads',
                'gis/setRoads',
                [],
                'Запрос дорог',
                false,
                payload && payload.force,
                context.state.roadsCache,
                context.state.roadsCacheTimeout
            )
        },

        // очищает кэш
        doClearRoads: (context, payload) => {
            return DoClearCache(
                'gis/setRoads',
                [],
                payload && payload.force,
                context.state.roadsCache,
                context.state.roadsCacheTimeout
            )
        },

        // добавление объекта
        doAddRoad: (context, payload) => {
            return DoPost(
                '/gis/roads',
                payload,
                'gis/addRoad',
                'Добавление дороги'
            )
        },

        // изменение объекта
        doEditRoad: (context, payload) => {
            return DoPut(
                '/gis/roads/'+payload.road_id,
                payload,
                'gis/editRoad',
                'Изменение дороги'
            )
        },

        // удаление объекта
        doDelRoad: (context, payload) => {
            return DoDelete(
                '/gis/roads/'+payload.road_id,
                'gis/delRoad',
                'Удаление дороги'
            )
        }
    }
}
