<template>

  <!--  Узлы графа -->
  <graph-node-marker
      v-for="node in nodes"
      :key="node.node_id"
      :graphNode="node"
      :state="isEdited && node === selectedNode ? 'active' : isEdited ? 'edit': 'original'"
      :contextMenu="isEdited && node === selectedNode ? nodeContextMenu : noContextMenu"
      :meta="node.node_id"
      events="click"
      @onLeafletEvent="onLeafletNodeEvent($event, node)"
      @onGraphNodeChanged="onGraphNodeChanged"
  >
  </graph-node-marker>

  <!--  Ребра графа -->
  <graph-edge
      v-for="item in edgeNodes"
      :key="item.edge.edge_id"
      :node_begin="item.node_begin"
      :node_end="item.node_end"
      :deltaPixels="item.deltaPixels"
      :state="isEdited && item === selectedEdge ? 'edit' : item === selectedEdge ? 'active': 'original'"
      :contextMenu="isEdited && item === selectedEdge ? edgeContextMenu : noContextMenu"
      :meta="item.edge.edge_id"
      events="click dblclick"
      @onLeafletEvent="onLeafletEdgeEvent($event, item)"
  >
  </graph-edge>

  <!-- Просмотр, изменение и добавление -->
  <graph-edges-dialog
      v-if="isDialogVisible"
      :item="dialogRow"
      :op="op"
      @onCancel="onRejectOperation"
      @onOK="onConfirmDialog"
  >
  </graph-edges-dialog>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import LeafletMapMixin from "@/components/ui/leaflet/mixins/LeafletMapMixin";
import {addMutation, delMutation, editMutation} from "@/store/Actions";
import GraphEdgesDialog from "@/components/gis/graph/GraphEdgesDialog";

export default {
  components:{GraphEdgesDialog},
  mixins: [LeafletMapMixin],
  props: {
    // состояние графа: original - обычное, active - активное, edit - редактируемое, disabled - отключенное
    state: {
      type: String,
      default: 'original'
    },

    // расстояние между стрелками (пиксели)
    deltaPixels: {
      type: Number,
      default: 3
    },

  },

  computed: {
    // признак режима редактирования
    isEdited() {
      return this.state === 'edit';
    },

    // минимальный масштаб для отображения графа
    minGraphZoom() {
      return this.$store.getters['settings/getMinGraphZoom']
    },
  },

  data() {
    return {
      // тип объекта
      leafletObjectType: 'LeafletGraph',
      // массив узлов
      nodes: [],
      // массив ребер
      edges: [],
      // объединенный массив узлов и ребер
      edgeNodes: [],
      // выбранный узел
      selectedNode: null,
      // выбранное ребро
      selectedEdge: null,
      // нет контекстного меню
      noContextMenu: {
        contextmenu: false,
      },
      // контекстное меню узла
      nodeContextMenu: {
        contextmenu: true,
        contextmenuInheritItems: false,
        contextmenuWidth: 140,
        contextmenuItems: [
          {
            text: 'Удалить узел',
            callback: (e) => {
              this.delGraphNode(e.relatedTarget.__meta)
            }
          },
          {
            separator: true
          },
          {
            text: 'Соединить ребра',
            callback: (e) => {
              this.spliceGraphEdge({
                node_id: e.relatedTarget.__meta,
              })
            }
          },
        ]
      },
      // контекстное меню ребра
      edgeContextMenu: {
        contextmenu: true,
        contextmenuInheritItems: false,
        contextmenuWidth: 140,
        contextmenuItems: [
          {
            text: 'Изменить ребро',
            callback: (e) => {
              this.dialogRow = this.edges.find(edge => edge.edge_id === e.relatedTarget.__meta)
              this.op = 'edit'
              this.isDialogVisible = true
            }
          },
          {
            separator: true
          },
          {
            text: 'Разбить ребро',
            callback: (e) => {
              this.splitGraphEdge({
                edge_id: e.relatedTarget.__meta,
                latitude: e.latlng.lat,
                longitude: e.latlng.lng,
                with_reverse: false
              })
            }
          },
          {
            text: 'Разбить секцию',
            callback: (e) => {
              this.splitGraphEdge({
                edge_id: e.relatedTarget.__meta,
                latitude: e.latlng.lat,
                longitude: e.latlng.lng,
                with_reverse: true
              })
            }
          },
          {
            separator: true
          },
          {
            text: 'Удалить ребро',
            callback: (e) => {
              this.delGraphEdge(e.relatedTarget.__meta)
            }
          },
        ]
      },
      // видимость диалога
      isDialogVisible: false,
      // данные, которые отображаются в диалоговом окне
      dialogRow: {},
      // операция
      op: null,
      // отображать спиннер
      isLoading: false,
      // карта
      leafletMap: null,
    }
  },

  methods: {
    // ищет узел по идентифкатору
    findNode(node_id) {
      return this.nodes.find(node => node.node_id === node_id)
    },

    // ищем ребро по узлам
    findEdge(node_id_begin, node_id_end) {
      return this.edges.find(edge => edge.node_id_begin === node_id_begin && edge.node_id_end === node_id_end)
    },

    // формирование объединенной таблицы ребер и узлов для отображения
    updateEdgeNodes() {
      const tbl = []

      // перебираем ребра
      this.edges.forEach((edge) => {
        // ищем начальный и конечный узлы
        const node_begin = this.findNode(edge.node_id_begin)
        const node_end = this.findNode(edge.node_id_end)
        // если кого-то не нашли - пропускаем
        if (!node_begin || !node_end) return

        tbl.push({
          deltaPixels: this.findEdge(node_end.node_id, node_begin.node_id) ? this.deltaPixels : 0,
          node_begin: node_begin,
          node_end: node_end,
          edge: edge
        })
      })

      // восстанавливаем выделения
      this.selectedNode = this.selectedNode ? this.nodes.find(node => node.node_id === this.selectedNode.node_id) : null
      this.selectedEdge = this.selectedEdge ? tbl.find(item => item.edge.edge_id === this.selectedEdge.edge.edge_id) : null

      // возвращаем данные
      this.edgeNodes = tbl
    },

    // отменили операцию
    onRejectOperation() {
      this.dialogRow = null
      this.op = null
      this.isDialogVisible = false
    },

    // подтверждено изменения в диалоге
    onConfirmDialog(item) {
      if (this.op === 'edit') {
        this.editGraphEdge(item)
      }
      this.dialogRow = null
      this.op = null
      this.isDialogVisible = false
    },

    // вызывается при перемещении узла
    onGraphNodeChanged(node) {
      this.editGraphNode(node)
    },

    // обрабатываем события узлов
    onLeafletNodeEvent(event, node) {
      if (!this.isEdited) return

      if (event.type === 'click') {
        if (this.selectedNode) {
          // щелчок + ctrl
          if (event.originalEvent.ctrlKey) {
            this.createGraphEdge({
              node_id_begin: this.selectedNode.node_id,
              latitude_begin: null,
              longitude_begin: null,
              node_id_end: node.node_id,
              latitude_end: null,
              longitude_end: null,
              with_reverse: false,
            })
          }
          // щелчок + alt
          else if (event.originalEvent.altKey) {
            this.createGraphEdge({
              node_id_begin: this.selectedNode.node_id,
              latitude_begin: null,
              longitude_begin: null,
              node_id_end: node.node_id,
              latitude_end: null,
              longitude_end: null,
              with_reverse: true,
            })
          }
        }

        this.selectedNode = node;
        this.selectedEdge = null;
      }
    },

    // обрабатываем события ребер
    onLeafletEdgeEvent(event, item) {
      if (!this.isEdited) return

      // при щелчке выделяем ребро
      if (event.type === 'click') {
        this.selectedNode = null;
        this.selectedEdge = item;
      }

      // при двойном щелчке выделяем ребро и
      // отображаем информацию о ребре
      if (event.type === 'dblclick') {
        this.selectedNode = null;
        this.selectedEdge = item;
        // вызываем отображение диалогового окна
        this.dialogRow = item.edge
        this.op = 'show'
        this.isDialogVisible = true
      }
    },

    // вызывается при щелчке на карте
    onMapClick(e) {
      if (!this.isEdited) return
      // для вставки ребра должен быть выделен другой узел
      if (!this.selectedNode) return

      if (e.originalEvent.ctrlKey) {
        this.createGraphEdge({
          node_id_begin: this.selectedNode.node_id,
          latitude_begin: null,
          longitude_begin: null,
          node_id_end: null,
          latitude_end: e.latlng.lat,
          longitude_end: e.latlng.lng,
          with_reverse: false
        })
      }
      else if (e.originalEvent.altKey) {
        this.createGraphEdge({
          node_id_begin: this.selectedNode.node_id,
          latitude_begin: null,
          longitude_begin: null,
          node_id_end: null,
          latitude_end: e.latlng.lat,
          longitude_end: e.latlng.lng,
          with_reverse: true
        })
      }
    },

    // запрашивает текущие данные по графу
    fetchGraph(leaflet) {
      // функция определяет границы карты и текущий zoom
      const getMapValues = async () => {
        try {

          // определяем границы карты
          const bounds = leaflet.getBounds();
          const northEast = bounds.getNorthEast();
          const southWest = bounds.getSouthWest();
          const minLat = southWest.lat;
          const maxLat =  northEast.lat;
          const minLong = southWest.lng;
          const maxLong = northEast.lng;
          // определяем текущий zoom
          const curZoom = leaflet.getZoom();

          // отображаем граф только при определенном масштабе
          if (curZoom < this.minGraphZoom) {
            this.nodes = []
            this.edges = []
            this.updateEdgeNodes()
            return
          }

          // запрашиваем элементы графа
          const res = await this.$store.dispatch('gis/doFetchGraphElements', {
            min_lat: minLat,
            max_lat: maxLat,
            min_long: minLong,
            max_long: maxLong,
          })
          if (res) {
            this.nodes = res.nodes
            this.edges = res.edges
            this.updateEdgeNodes()
          }
          else {
            this.nodes = []
            this.edges = []
            this.updateEdgeNodes()
          }
        }
        catch {
          this.nodes = []
          this.edges = []
          this.updateEdgeNodes()
        }
      }
      getMapValues();
      leaflet.on('moveend', () => {
        getMapValues();
      })
    },

    // обновляем узлы и ребра
    updateModel(graphResult) {

      const newNodes = [...this.nodes]
      graphResult.del_nodes_id.forEach(id => {
        delMutation(newNodes, 'node_id', {node_id: id})
      })
      graphResult.new_nodes.forEach(node => {
        addMutation(newNodes, 'node_id', node)
      })
      graphResult.modify_nodes.forEach(node => {
        editMutation(newNodes, 'node_id', node)
      })

      const newEdges = [...this.edges]
      graphResult.del_edges_id.forEach(id => {
        delMutation(newEdges, 'edge_id', {edge_id: id})
      })
      graphResult.new_edges.forEach(edge => {
        addMutation(newEdges, 'edge_id', edge)
      })
      graphResult.modify_edges.forEach(edge => {
        editMutation(newEdges, 'edge_id', edge)
      })

      this.nodes = newNodes
      this.edges = newEdges

      // пересоздаем массив, для обновления графа
      this.updateEdgeNodes()
    },

    // запускаем обновление данных
    async refreshData() {
      this.isLoading='fetch';
      try {

        await this.$store.dispatch('gis/doFetchRoads')

      }
      finally {
        this.isLoading=false;
      }
    },

    // изменяем узел графа
    async editGraphNode(item) {
      this.isLoading = true;
      try {

        const graphResult = await this.$store.dispatch('gis/doEditGraphNode', item);
        if (graphResult) {
          this.updateModel(graphResult)
        }

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем узел графа
    async delGraphNode(node_id) {
      this.isLoading = true;
      try {

        const graphResult = await this.$store.dispatch('gis/doDelGraphNode', {
          node_id
        });
        if (graphResult) {
          this.updateModel(graphResult)
        }

      }
      finally {
        this.isLoading = false;
      }
    },

    // создаем ребро графа
    async createGraphEdge(createGraphEdge) {
      this.isLoading = true;
      try {

        const graphResult = await this.$store.dispatch('gis/doCreateGraphEdge', createGraphEdge);
        if (graphResult) {
          this.updateModel(graphResult)
          // чтобы выделить новый узел - передаем его в граф
          if (graphResult.new_nodes.length > 0) {
            this.selectedNode = graphResult.new_nodes[0]
          }
        }

      }
      finally {
        this.isLoading = false;
      }
    },

    // изменяем ребро графа
    async editGraphEdge(item) {
      this.isLoading = true;
      try {

        const newEdge = await this.$store.dispatch('gis/doEditGraphEdge', item);
        if (newEdge) {
          // мутируем объект
          editMutation(this.edges, 'edge_id', newEdge)
          // пересоздаем массив, для обновления графа
          this.updateEdgeNodes()
        }

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем ребро графа
    async delGraphEdge(edge_id) {
      this.isLoading = true;
      try {

        const graphResult = await this.$store.dispatch('gis/doDelGraphEdge', {
          edge_id
        });
        if (graphResult) {
          this.updateModel(graphResult)
        }

      }
      finally {
        this.isLoading = false;
      }
    },

    // разбиваем ребро графа
    async splitGraphEdge(splitGraphEdge) {
      this.isLoading = true;
      try {

        const graphResult = await this.$store.dispatch('gis/doSplitGraphEdge', splitGraphEdge);
        if (graphResult) {
          this.updateModel(graphResult)
        }

      }
      finally {
        this.isLoading = false;
      }
    },

    // соединяем ребра графа
    async spliceGraphEdge(spliceGraphEdge) {
      this.isLoading = true;
      try {

        const graphResult = await this.$store.dispatch('gis/doSpliceGraphEdge', spliceGraphEdge);
        if (graphResult) {
          this.updateModel(graphResult)
        }

      }
      finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    // перезапрашиваются данные
    this.refreshData();
  },

  // монтируем слой
  mounted() {
    // ждем создание родителя
    this.getParent().parentReady().then((parent_list) => {
      this.leafletMap = this.getMap(parent_list);
      const map = this.leafletMap.origin;

      // запускаем обновление графа
      this.fetchGraph(map)
      // следим за изменением режима редактирования
      this.$watch(() => this.isEdited, (newValue) => {
        if (newValue) {
          // подписываемся на событие карты
          map.on('click', this.onMapClick);
        }
        else  {
          // сбрасываем выделение
          this.selectedNode = null;
          this.selectedEdge = null;
          // отписываемся от события карты
          map.off('click', this.onMapClick);
        }
      }, { immediate: true });
    })
  },

  // демонтируем слой
  unmounted() {
    // отписываемся от событий
    if (this.leafletMap) {
      this.leafletMap.origin.off('click', this.onMapClick);
    }
  },
}
</script>
