<template>
  <div class="btn-group btn-group d-block">
    <button
        type="button"
        class="btn"
        v-for="item in itemList"
        :class="{
          [activeClass]: Array.isArray(modelValue) ? modelValue.includes(item.id) : modelValue === item.id,
          [unactiveClass]: Array.isArray(modelValue) ? !modelValue.includes(item.id) : modelValue !== item.id
        }"
        :key="item.id"
        :value="item.id"
        @click="buttonClick(item.id)"
        :style="buttonsStyle"
        :title="item.tooltipText ? item.tooltipText : ''"
        :disabled="(this.required && item.id === null) || this.readonly"
    >{{inputType == 'text' ? item.title : ''}}<i v-if='inputType == "button"' :class="item.title"></i></button>
  </div>
</template>

<script>
export default {
  emits: ['update:model-value', 'change'],
  props: {
    'model-value': {
      required: false
    },
    // массив элементов [{id: any, title: string, tooltipText: string}]
    itemList: {
      type: Array,
      required: true
    },
    // требуется ввод значения т.е. кнопку нельзя отжать
    'required': {
      type: Boolean,
      default: false
    },
    // только для чтения
    'readonly': {
      type: Boolean,
      default: false
    },
    // множественный выбор
    'multiple': {
      type: Boolean,
      default: false
    },
    // активный класс
    'active-class': {
      type: String,
      default: 'btn-success'
    },
    // пассивный класс
    'unactive-class': {
      type: String,
      default: 'btn-secondary'
    },
    // тип отображения - если тип отображения image, то вместо title нужно писать класс картинки, которую нужно выводить
    'inputType': {
      type: String,
      default: 'text',
    },
    // стили, которые будут применены к каждой кнопке
    'buttonsStyle':{
      type: String,
      default: '',
    },
  },

  methods: {
    // обработка нажатия клавиши
    buttonClick(value){
      // режим множественного выбора
      if (this.multiple) {
        // нажата активная клавиша
        if (this.modelValue.includes(value)) {
          // запрещаем все отжимать, если стоит режим required
          if (!this.required || this.modelValue.length > 1) {
            this.$emit('update:model-value', this.modelValue.filter(item => item != value))
            this.$emit('change');
          }
        }
        // нажата неактивная клавиша
        else {
          this.$emit('update:model-value', [...this.modelValue, value].sort());
          this.$emit('change');
        }
      }
      // режим одиночного выбора
      else {
        // нажата активная клавиша
        if (value === this.modelValue) {
          // допускается не указывать
          if (!this.required) {
            this.$emit('update:model-value', null);
            this.$emit('change');
          }
        }
        // нажата другая клавиша
        else {
          this.$emit('update:model-value', value);
          this.$emit('change');
        }
      }
    },
  },

  created() {
    this.$watch(() => this.modelValue, ()=> {
      // режим множественного выбора
      if (this.multiple) {
        // меняем не-массив на массив
        if (!Array.isArray(this.modelValue)) {
          this.$emit('update:model-value', []);
        }
      }
      // режим одиночного выбора
      else {
        // значение должно быть string, number, boolean или null
        if (
            typeof(this.modelValue) !== 'string' &&
            typeof(this.modelValue) !== 'number' &&
            typeof(this.modelValue) !== 'boolean' &&
            this.modelValue !== null
        ) {
          this.$emit('update:model-value', null);
        }
      }
    }, {immediate: true});
  }
}
</script>
