<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" name="tbl-admin-reports"> </edit-table-panel>
</template>

<script>
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import {getModule, getReportGroup} from "@/store/SystemHelpers";

export default {
  components: {EditTablePanel},
  props: ['isLoading','reportGroupId'],
  data() {
    return {
      // колонки
      columnList: [
        {
          field: "report_id",
          caption: "Код",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "__module",
          caption: "Модуль",
          sortType: 'number',
          isFilterable: true,
        },
        {
          field: "__report_group",
          caption: "Группа отчетов",
          sortType: 'number',
          isFilterable: true
        },
        {
          field: "short_name",
          caption: "Краткое наименование",
          sortType: 'string',
          isFilterable: true,
          show: false
        },
        {
          field: "long_name",
          caption: "Наименование",
          sortType: 'string',
          isFilterable: true
        },
        {
          field: "report_desc",
          caption: "Описание отчета",
          sortType: 'string',
          isFilterable: true,

        },
        {
          field: "report_code",
          caption: "Номер формы",
          sortType: 'string',
          isFilterable: true,
        },
        {
          field: "is_disabled",
          caption: "Заблок.",
          sortType: 'boolean',
          displayType: 'html',
          displayFn(row) {
            if (row.is_disabled) {
              return '<i class="fas fa-check">'
            }
            return ''
          },
        },
      ],
    };
  },
  computed: {
    rowList() {
      if (this.isLoading === 'fetch') {
        return []
      }
      let reports = [];
      if(this.reportGroupId) {
        reports = this.$store.getters["system/getReports"].filter(report => {
          return report.report_group_id == this.reportGroupId;
        })
      }else {
        reports = this.$store.getters["system/getReports"];
      }

      return reports.map(report => {
        report.__id = report.report_id;
        if (report.report_group_id != null) {
          report.__report_group = getReportGroup(report.report_group_id).report_group_name;
        }
        report.__module = getModule(report.module_id).module_name;
        return report;
      });
    },
  },
};
</script>
