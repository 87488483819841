<template>

  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/ref">Справочники</router-link></span>
        <span> / </span>
        <span><router-link to="/ref/transport">Транспортные средства</router-link></span>
        <span> / </span>
        <span>Группы транспортного средства {{ transportPageHeader }}</span>
      </page-header>
    </align-panel>
    <align-panel align="all">
      <div class="container position-relative h-100">
        <align-container>
          <!-- Форма с кнопками -->
          <align-panel align="top">
            <crud-form
                :buttonsExist="{check: true, uncheck: true, refresh: true}"
                :buttonsEnabled="crudButtonsEnabled"
                @onCheck="onTableCheck"
                @onUncheck="onTableUncheck"
                @onRefresh="onTableRefresh"
                @onFilterChanged="onFilterChanged"
            ></crud-form>
          </align-panel>
          <!-- Таблица  -->
          <align-panel align="all">
            <transport-group-links-edit-table
                :id="id"
                :filterText="filterText"
                :isLoading="isLoading"
                :selectedRow="selectedRow"
                @onRowSelect="onRowSelect"
            ></transport-group-links-edit-table>
          </align-panel>
        </align-container>
      </div>
    </align-panel>
  </align-container>

  <!-- Спиннер -->
  <loading v-if="isLoading"></loading>

</template>

<script>
import TransportGroupLinksEditTable
  from "@/components/ref/transport_group_links/TransportGroupLinksEditTable";

import {getTransport} from "@/store/RefHelpers";
import PageMixin from "@/pages/share/PageMixin";

export default {
  mixins: [PageMixin],

  components: {
    TransportGroupLinksEditTable,
  },

  props: [
    // идентификатор редактируемого объекта
    "id"
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // отображать спиннер
      isLoading: false
    }    
  },

  computed: {
      // состояние crud-кнопок
      crudButtonsEnabled() {
          return {
            check: !!this.transport && !!this.selectedRow && !this.selectedRow.__checked,
            uncheck: !!this.transport &&  !!this.selectedRow && this.selectedRow.__checked,
            refresh: true
          }
      },

      // текущее транспортное средство
      transport() {
        return getTransport(this.id);
      }
  },
  methods: {
    // нажата кнопка Включить
    onTableCheck() {
      this.linkObjects();
    },

    // нажата кнопка Выключить
    onTableUncheck() {
      this.unlinkObjects();
    },

    // нажата кнопка Обновить
    onTableRefresh() {
      // перезапрашиваются данные (принудительно)
      this.refreshData(true);
    },

    // изменился фильтр
    onFilterChanged({newValue}) {
      this.filterText = newValue;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData(force = false) {
      this.isLoading = 'fetch';
      try {

        await this.$store.dispatch('ref/doFetchTransport');
        await this.$store.dispatch('ref/doFetchTransportGroups');
        await this.$store.dispatch('ref/doFetchTransportGroupLinks', { force });

      }
      finally {
        this.selectedRow = null;
        this.isLoading = false;
      }
    },

    // связываем объекты
    async linkObjects() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('ref/doAddTransportToGroup', {
          transport_group_id: this.selectedRow.transport_group_id,
          transport_id: this.transport.transport_id
        });

      }
      finally {
        this.isLoading = false;
      }
    },

    // удаляем связь объектов
    async unlinkObjects() {
      this.isLoading = true;
      try {

        await this.$store.dispatch('ref/doDelTransportFromGroup', {
          transport_group_id: this.selectedRow.transport_group_id,
          transport_id: this.transport.transport_id
        })

      }
      finally {
        this.isLoading = false;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData();
  },
};
</script>

