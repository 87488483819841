<template>
  <align-container>
    <!-- Хлебные крошки -->
    <align-panel align="top">
      <page-header>
        <span><router-link to="/kvr">КВР-Экспертиза</router-link></span>
        <span> / </span>
        <span>Отчеты</span>
      </page-header>
    </align-panel>

    <align-panel align="all">
      <align-container>
        <!-- Панель со списком отчетов  -->
        <align-panel align="left" width="25%" style="overflow-y: auto">
          <reports-list-panel
              :id="id"
          ></reports-list-panel>
        </align-panel>

        <!-- Правая панель  -->
        <align-panel align="all" :gap="3">
          <align-container>
            <!-- панель для выбора параметров  -->
            <align-panel align="top" :gap="3">
              <div class="card bg-light" style="min-width: 791px !important">
                <date-time-chooser-panel
                    v-if="id"
                    :id="id"
                    :isTimeEnable="isTimeEnable"
                    :isSingleTimeEnable="isSingleTimeEnable"
                    :isViewTypeSwitchEnable="isViewTypeSwitchEnable"
                    :isTimePeriodButtonVisible="isTimePeriodButtonVisible"
                    :isTableChangeVisible="isTableChangeVisible"
                    :isSignersPanelsVisible="isSignersPanelsVisible"
                    :stopReports="stopReports"
                    :isReportButtonActive="((selectedRoutesID.length != 0) || (selectedOrganizationIds.length != 0) || isAllRoutes || isAllOrganizations)"
                    @onGetReportClick="onGetReportClick"
                    @tableChange="tableChange"
                ></date-time-chooser-panel>
              </div>
            </align-panel>
            <!-- панель с таблицей  -->
            <align-panel align="all">
              <routes-panel
                  v-if="isRoutesTable && id"
                  :routes="routes"
                  :isAllRoutes="isAllRoutes"
                  :id="id"
                  @allRoutesChange="allRoutesChange"
              ></routes-panel>
              <organizations-panel
                  v-else-if="!isRoutesTable && id"
                  :organizations="organizations"
                  :isAllOrganizations="isAllOrganizations"
                  :id="id"
                  @allOrganizationsChange="allOrganizationsChange"
              ></organizations-panel>
            </align-panel>
          </align-container>
        </align-panel>
      </align-container>

    </align-panel>
  </align-container>


  <!--    Окно с ошибкой-->
  <error-message-box
      v-if="errorMessage != ''"
      @onClose="errorMessage = ''"
  >{{ errorMessage }}
  </error-message-box>


</template>

<script>

import PageMixin from "@/pages/share/PageMixin";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import ReportsListPanel from "@/components/kvr/kvr_expert_reports/ReportsListPanel";
import RoutesPanel from "@/components/kvr/reports/RoutesPanel";
import DateTimeChooserPanel from "@/components/kvr/reports/DateTimeChooserPanel";
import OrganizationsPanel from "@/components/kvr/reports/OrganizationsPanel";
import {dateToND, kvrSortFn, quickSort} from "@/lib";

export default {
  mixins: [PageMixin],

  components: {
    OrganizationsPanel,
    DateTimeChooserPanel,
    RoutesPanel,
    ReportsListPanel,
    AlignPanel,

  },

  props: [
    // идентификатор выбранного отчета
    "id"
  ],

  watch:{
    id(){
      if(this.isRoutesTableVisible){
        this.routes.map(value => {
          value.__isSelected = false;
        });
        this.isAllRoutes = true;
      } else {
        this.organizations.map(value => {
          value.__isSelected = false;
        });
        this.isAllOrganizations = true;
      }
    }
  },

  data() {
    return {
      // список маршрутов
      routes: [],
      // список организаций
      organizations: [],
      // текст ошибки
      errorMessage: '',
      // отправлять запрос по всем маршрутам или нет (если true, то блокируется также таблица с выбором)
      isAllRoutes: true,
      // отправлять запрос по всем предприятиям или нет (если true, то блокируется также таблица с выбором)
      isAllOrganizations: true,
      // id отчетов, у которых формат запроса подразумевает одну выбранную дату
      singleDateReports: ['0043'],
      //id отчетов, у которых формат запроса подразумевает одно поле выбора времени
      singleTimeReports: ['0043',],
      // настройки отображения таблицы в зависимости от выбранного отчета
      routesTableVisibleReports: ['0039', '0040', '0037', '0038', '0035', '0033'],
      // отчет, для которого отображается галочка временной период
      timePeriodButton: ['0036'],
      // отчет, для которого отображается смена таблицы
      viewTableTypeSwitchEnable: ['0033',],
      // отчеты в которых запрос можно сделать как за дату, так и за период
      viewTypeSwitchEnable: ['0043'],
      // отчеты для которых отображаются панельки подписантов
      signersReports: [ '0041'],
      // отчеты, которые делают запрос со stop_id
      stopReports: [],
      // какая таблица отображается
      showedTable: 0,
    }
  },

  computed: {

    isRoutesTable() {
      if((this.isRoutesTableVisible && this.id) ) {
        if ((this.id == '0033' || this.id == '0034') && this.showedTable == 0) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },

    // настройки отображения выбора времени в зависимости от выбранного отчета
    isTimeEnable() {
      if ([].includes(this.id)) {
        return true;
      }
      return false;
    },

    isSignersPanelsVisible() {
      if (this.signersReports.includes(this.id)) {
        return true;
      }
      return false;
    },

    // настройки отображения одного поля ввода времени
    isSingleTimeEnable() {
      if (this.singleTimeReports.includes(this.id)) {
        return true;
      }
      return false;
    },

    // настройки отображения таблицы в зависимости от выбранного отчета
    isRoutesTableVisible() {
      if (this.routesTableVisibleReports.includes(this.id)) {
        return true;
      }
      return false;
    },

    // // настройки отображения окон Временной период
    // isTimePeriodVisible() {
    //   if (this.timePeriodChooserReports.includes(this.id)) {
    //     return true;
    //   }
    //   return false;
    // },

    // настройки отображения переключателя отображения Временного периода
    isTimePeriodButtonVisible() {
      if (this.timePeriodButton.includes(this.id)) {
        return true;
      }
      return false;
    },

    // настройки отображения переключения День-Период
    isViewTypeSwitchEnable() {
      if (this.viewTypeSwitchEnable.includes(this.id)) {
        return false;
      }
      return true;
    },

    // настройки отображения переключения Предприятия-Маршруты
    isTableChangeVisible() {
      if (this.viewTableTypeSwitchEnable.includes(this.id)) {
        return true;
      }
      return false;
    },

    // список выбранных маршрутов
    selectedRoutesID() {
      let routeIds = [];
      this.routes.filter(route => {
        if (route.__isSelected == true) {
          routeIds.push(route.route_id);
        }
      });
      return routeIds;
    },

    // список выбранных организаций
    selectedOrganizationIds() {
      let orgIds = [];
      this.organizations.filter(org => {
        if (org.__isSelected == true) {
          orgIds.push(org.org_id);
        }
      })
      return orgIds;
    }
  },

  methods: {

    tableChange(newValue) {
      this.showedTable = newValue;
    },

    // нажата кнопка "Все маршруты"
    allRoutesChange() {
      this.isAllRoutes = !this.isAllRoutes;
    },

    // нажата кнопка "Все предприятия"
    allOrganizationsChange() {
      this.isAllOrganizations = !this.isAllOrganizations;
    },

    // запускаем обновление данных
    async refreshData() {

      let routes = await this.$store.dispatch('kvr/doFetchRoutes');
      if (routes) {
        this.routes = routes;
        // сортируем массив
        quickSort(this.routes, (leftItem, rightItem) => {
          return 1 * kvrSortFn(leftItem, rightItem);
        });
      }
      let organizations = await this.$store.dispatch('kvr/doFetchAgencies');
      this.organizations = [];
      if (organizations) {
        organizations.map(org => {
          if (org.is_agency) {
            this.organizations.push(org);
          }
        });
      }
    },

    // нажата кнопка "Отчет". (выбранный режим (день или период); выбранные маршруты/предприятия)
    onGetReportClick(values) {
      // проверяем какая таблица отображается
      if (!this.isRoutesTable) {
        // если есть выбранные предприятия
        if (this.isAllOrganizations) {
          values.org_id_list = null;
          // если выбранных предприятий нет
        } else if (this.selectedOrganizationIds.length == 0) {
          this.errorMessage = 'Не выбрано ни одного предприятия';
          return;
          // если нажата кнопка "Все предприятия"
        } else {
          values.org_id_list = this.selectedOrganizationIds;
        }
        // if (['0005', '0006', '0007', '0014', '0015', '0017', '0023', '0024', '0025', '0026', '0028'].includes(this.id)) {
          this.getReportByID(values);
        // }
      } else {
        // если есть выбранные маршруты
        if (this.isAllRoutes) {
          values.route_id_list = null;
          // если выбранных маршрутов нет
        } else if (this.selectedRoutesID.length == 0) {
          this.errorMessage = 'Не выбрано ни одного маршрута';
          return;
          // если нажата кнопка "Все маршруты"
        } else {
          values.route_id_list = this.selectedRoutesID;
        }
        // if (['0008', '0009', '0010', '0011', '0012', '0013', '0016', '0018', '0019', '0020', '0021', '0022', '0027' ].includes(this.id)) {
          this.getReportByID(values);
        // }
      }
    },

    getReportByID(values) {
      // формируем json запроса
      let payload = {};
      // если формат запроса подразумевает одну выбранную дату
      if (this.singleDateReports.includes(this.id)){
        payload = {date_index: dateToND(new Date(values.dateIndex))}
      // если отображается выбор одной даты, однако запрос делается по интервалу т.е. по двум датам
      } else if (values.dateIndex) {
        payload =
            {
              date_index_begin: dateToND(new Date(values.dateIndex)),
              date_index_end: dateToND(new Date(values.dateIndex)),
            };
      // если выбран интервал
      } else {
        payload =
            {
              date_index_begin: dateToND(new Date(values.date_index_begin)),
              date_index_end: dateToND(new Date(values.date_index_end)),
            };
      }
      // в зависимости от отображаемой таблицы соответственно от выбранных элементов
      // добавляем к запросу соответствующий лист значений
      if (this.isRoutesTable) {
        payload['route_id_list'] = values.route_id_list;
      } else {
        payload['org_id_list'] = values.org_id_list;
      }
      // если введен интервал времени, добавляем временной интервал к запросу
      if(values.time_index_begin && values.time_index_end){
        payload['sec_index_begin'] = values.time_index_begin;
        payload['sec_index_end'] = values.time_index_end;
      }
      // если введено время, добавляем его к запросу
      if (this.isSingleTimeEnable){
        payload['sec_index'] = values.time_index;
      }
      // если есть подписанты, добавляем их в запрос
      if(values.signer_enabled) {
        payload['signer_enabled'] = values.signer_enabled;
        payload['signer_name'] = values.signer_name;
        payload['signer_occupation'] = values.signer_occupation;
      }
      if(values.signer_enabled2) {
        payload['signer_enabled2'] = values.signer_enabled2;
        payload['signer_name2'] = values.signer_name2;
        payload['signer_occupation2'] = values.signer_occupation2;
      }
      let tempRep = {...payload};
      tempRep['report_id'] = Number(this.id);
      // вносим временный отчет
      this.$store.commit('reporter/addReport', tempRep);
      // делаем запрос на формирование отчета
      this.$store.dispatch('kvr-expert/doMakeReport' + this.id, payload);
    },
  },

  // вызывается при создании компонента
  async created() {
    // перезапрашиваются данные
    await this.refreshData();
  },
}

</script>

<style scoped>

</style>
